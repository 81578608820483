import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";

const styles = (theme) => ({
  checked: {
    color: theme.palette.primary.main + "!important",
  },
});

const handleOnChange = (name, value, handleChange) => (event) => {
  let finalValue = [];
  if (event.target.checked) {
    finalValue = [...value, name];
  } else {
    const nameIndex = value.indexOf(name);
    finalValue = [...value.slice(0, nameIndex), ...value.slice(nameIndex + 1)];
  }
  handleChange(finalValue);
};

function CheckboxGroup({
  handleChange,
  classes,
  row = true,
  options,
  value = [],
  disabled = false,
  labelRenderer = (o) => o.value,
}) {
  return (
    <FormGroup row={row}>
      {options.map((option, index) => (
        <FormControlLabel
          key={option.value + index}
          control={
            <Checkbox
              checked={value.includes(option.value)}
              onChange={handleOnChange(option.value, value, handleChange)}
              value={option.value}
              classes={{
                checked: classes.checked,
              }}
              disabled={disabled}
            />
          }
          label={labelRenderer(option)}
        />
      ))}
    </FormGroup>
  );
}

CheckboxGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.array,
  value: PropTypes.array,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
  row: PropTypes.bool,
  labelRenderer: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(CheckboxGroup);
