import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import image from "../images/man.svg";
import check from "../images/check.svg";
import { getRouteForActionType } from "../../../common/components/campaigncreator/utils";

const useStyles = makeStyles((theme) => ({
  root: { height: 456, minWidth: 650 },
  heading: {
    fontFamily: "Manrope",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "30px",
    color: "#002845",
    position: "relative",
    top: 40,
    left: 40,
  },
  list: {
    left: 40,
    fontFamily: "Manrope",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#002845",
    padding: 10,
  },
  button: {
    margin: "10 10 10 10",
    background: "#FF7F33",
    borderRadius: "3px",
    fontFamily: "Manrope",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "15px",
    lineHeight: "20px",
    letterSpacing: "0.01em",
    color: "#FFFFFF",
    padding: 15,
    position: "relative",
    left: 40,
    top: 20,
  },
  check: { position: "relative", left: -20, top: "4px", width: 3 },
  text: { display: "inline", position: "relative", top: -18 },
}));

export default function CleanSlate({ appId }) {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.root}
      style={{
        background: "#ffffff",
        border: "1px solid #EAECEE",
        borderRadius: "4px",
      }}
    >
      <Grid item xs={6}>
        <Box
          style={{
            maxWidth: "300px",
          }}
        >
          <Typography className={classes.heading}>
            Create your first Campaign/ Survey!
          </Typography>
          <ul
            style={{
              listStyleType: "none",
              marginTop: "50px",
            }}
          >
            <li className={classes.list}>
              <div className={classes.check}>
                <img src={check} alt="check" />
              </div>
              <div className={classes.text}>
                Get to know how your campaigns have affected the retention,
                uninstalls, session length and many more!
              </div>
            </li>
            <li className={classes.list}>
              <div className={classes.check}>
                <img src={check} alt="check" />
              </div>
              <div className={classes.text}>Create campaign within 30 min!</div>
            </li>
            <li className={classes.list}>
              <div className={classes.check}>
                <img src={check} alt="check" />
              </div>
              <div className={classes.text}>
                Create survey and get to know your users!
              </div>
            </li>
          </ul>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            marginTop: "10px",
            paddingLeft: "40px",
          }}
        >
          <Link
            component={Button}
            variant="contained"
            color="primary"
            size="large"
            style={{
              color: "#FFFFFF",
              textDecorations: "none",
            }}
            to={{
              pathname: `/apps/${appId}/${getRouteForActionType(
                "walkthroughs"
              )}/`,
            }}
          >
            Create Campaign
          </Link>
          <Link
            component={Button}
            variant="contained"
            color="primary"
            size="large"
            style={{
              marginLeft: "4px",
              color: "#FFFFFF",
              textDecorations: "none",
            }}
            to={{
              pathname: `/apps/${appId}/${getRouteForActionType("surveys")}/`,
            }}
          >
            Create Survey
          </Link>
        </Box>
      </Grid>
      <Grid
        item
        xs={6}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={image} alt="text" />
      </Grid>
    </Grid>
  );
}
