import { makePostRequest } from "../../common/actions";

const CONTACT_US_API = "contact-us";

export async function sendContactUsMessage(auth, postBody, queryParams) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    CONTACT_US_API,
    auth,
    auth.appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback,
    "v1/external/",
  );
}
