import React, { useState, useEffect } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { IconButton, Tooltip, Snackbar, Grid } from "@material-ui/core";
import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import EditIcon from "@material-ui/icons/Edit";
import Box from "../../../ReusableComponents/Box";
import Placeholder from "../../../ReusableComponents/Placeholder";
import NickNameDialog from "../../../ReusableComponents/NickNameDialog";
import {
  updateTestDeviceAPI,
  getTestDevicesAPI,
  removeTestDeviceAPI,
  updateDemoTestDeviceAPI,
} from "../../common/actions";
import { useAuth } from "../../../../contexts/AuthContext";
import { Link } from "react-router-dom";
import { isPropertyDefined } from "../../../../api";
import { isExperienceDashboard } from "../../../../config";

export default function TestDevices({ app, nickName: name }) {
  const auth = useAuth();

  const [testDevices, setTestDevices] = useState([]);
  const [count, setCount] = useState(0);
  const [failed, setFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [showDialog, setShowDialog] = useState(false);
  const [nickName, setNickName] = useState(name || "");
  const [model, setModel] = useState("");
  const [deviceId, setDeviceId] = useState("");

  const { app_id: appId } = app;

  const dateFilters = {};

  useEffect(() => {
    getTestDevicesAPI(auth, appId, {}, isExperienceDashboard()).then(
      (response) => {
        setTestDevices(response);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId, count, showDialog]);

  const setDevice = (model, deviceId) => {
    setModel(model);
    setDeviceId(deviceId);
  };

  const onNickDone = () => {
    if (isExperienceDashboard()) {
      updateDemoTestDeviceAPI(
        auth,
        {
          accessCode: auth?.user?.access_code,
        },
        {
          id: deviceId,
          nick_name: nickName,
          model,
        }
      );
    } else {
      updateTestDeviceAPI(auth, appId, deviceId, dateFilters, {
        nick_name: nickName,
      }).then((response) => {
        if (response) {
          setCount(count + 1);
          setFailed(false);
          setErrorMessage("");
        } else {
          setFailed(true);
          setErrorMessage("Failed to set nick name");
        }
      });
    }
    setShowDialog(false);
    setNickName("");
  };

  const onNickCancel = () => {
    setShowDialog(false);
    setNickName("");
  };

  const onNickChange = (event) => {
    setNickName(event.target.value);
  };

  return (
    <>
      <section className="content">
        <Grid container justify="center">
          <Grid item xs={12} md={8}>
            <Box
              icon={<ImportantDevicesIcon />}
              title="Test Devices"
              withPadding
              elevation={0}
              styles={{
                boxShadow: "none",
                border: "1px solid #EAECEE",
                borderRadius: "4px",
              }}
            >
              {
                <List>
                  {testDevices.map((device, index) => {
                    const customId = device.custom_id
                      ? ` (${device.custom_id})`
                      : "";
                    const primary = isPropertyDefined(device, "nick_name")
                      ? device.nick_name
                      : device.model;
                    const secondary = isPropertyDefined(device, "nick_name")
                      ? `${device.id}${customId} · ${device.model}`
                      : `${device.id}${customId}`;
                    return (
                      <ListItem key={device.id}>
                        <ListItemText
                          primary={<strong>{primary}</strong>}
                          secondary={secondary}
                        />
                        <ListItemSecondaryAction>
                          {!isExperienceDashboard() && (
                            <Tooltip
                              id={"explore-test-device" + device.id}
                              title="Explore User"
                              placement="top"
                            >
                              <Link
                                target={"_blank"}
                                to={`/apps/${appId}/users/${device.id}`}
                              >
                                <IconButton aria-label="Explore">
                                  <OpenInNewIcon color="primary" />
                                </IconButton>
                              </Link>
                            </Tooltip>
                          )}
                          <Tooltip
                            id={"edit-test-device" + device.id}
                            title="Click to edit"
                            placement="top"
                          >
                            <IconButton
                              aria-label="Edit"
                              onClick={() => {
                                setDevice(device.model, device.id);
                                setShowDialog(true);
                                setNickName(device.nick_name || "");
                              }}
                            >
                              <EditIcon color="action" />
                            </IconButton>
                          </Tooltip>
                          {!isExperienceDashboard() && (
                            <Tooltip
                              id={"remove-test-device" + device.id}
                              title="Click to remove"
                              placement="top"
                            >
                              <IconButton
                                aria-label="Remove"
                                onClick={() => {
                                  removeTestDeviceAPI(
                                    auth,
                                    appId,
                                    device.id,
                                    dateFilters
                                  ).then((response) => {
                                    if (response) {
                                      setCount(count + 1);
                                      setFailed(false);
                                      setErrorMessage("");
                                    } else {
                                      setFailed(true);
                                      setErrorMessage("Failed to delete");
                                    }
                                  });
                                }}
                              >
                                <DeleteIcon color="error" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })}
                </List>
              }
              {testDevices.length === 0 && (
                <Placeholder
                  text="No devices found. Add your test device from User Explorer"
                  withIcon
                  icon={
                    <ImportantDevicesIcon
                      style={{ width: 100, height: 100, opacity: 0.2 }}
                    />
                  }
                />
              )}
              {showDialog && (
                <NickNameDialog
                  model={model}
                  deviceId={deviceId}
                  nick_name={nickName}
                  onCancel={onNickCancel}
                  onChange={onNickChange}
                  onDone={onNickDone}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </section>
      {failed && (
        <Snackbar open={failed}>
          <strong>{errorMessage}</strong>
        </Snackbar>
      )}
    </>
  );
}

