import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Route, useLocation } from "react-router-dom";
import ApplicationFrame from "../ApplicationFrame";
import { styles } from "../ApplicationFrame/components/Navigation/styling/CustomCSS";

const isNavigationDrawerEnabled = (location) =>
  location.pathname.indexOf("/approve") === -1 &&
  location.pathname.indexOf("/integration") === -1;

const Dashboard = () => {
  const location = useLocation();
  return (
    <div>
      <Route
        path="/apps/:appId"
        render={(props) => (
          <ApplicationFrame
            isNavbarEnabled={isNavigationDrawerEnabled(location)}
            {...props}
          />
        )}
      ></Route>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(Dashboard);
