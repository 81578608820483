import { Box, Button, Grid, Typography } from "@material-ui/core";
import PlayCircleFilled from "@material-ui/icons/PlayCircleFilled";
import Apxor from "apxor";
import React, { useEffect, useMemo, useState } from "react";
import { DEMO_ECOMMERCE_APP_ID } from "../../../../../../config";
import { APXOR_SDK_CATEGORY_CONSTANTS } from "../../../../../../constants/Endpoints";
import CustomMaterialUIDialog from "../../../../../ReusableComponents/CustomMaterialUIDialog";
import { getDemoCampaignsAPI } from "../../../../common/actions";
import useCampaign from "../../../../common/components/campaignsholder/components/use-campaign";
import {
  DEMO_TEMPLATE_CONFIG_TYPES,
  surveyTourTypes,
  walkthroughTourTypes,
} from "../../../../common/components/campaignsholder/constants";

function SingleTourCard({ tourType, campaignConfig }) {
  const { handleDuplicate } = useCampaign(
    campaignConfig,
    () => {},
    tourType.actionType,
    DEMO_ECOMMERCE_APP_ID
  );

  return (
    <Grid item xs={12}>
      <Grid
        container
        spacing={2}
        style={{
          border: "1px solid #EAECEE",
          borderRadius: "4px",
          padding: "20px",
        }}
      >
        <Grid item xs={3}>
          {tourType.actionType === "walkthrough" ? (
            <img
              src="https://storage.googleapis.com/apx_frontend-assets/demo-campaign-video.gif"
              alt="Campaign"
              height="300px"
            />
          ) : (
            <img
              src="https://storage.googleapis.com/apx_frontend-assets/demo-survey-video.gif"
              alt="Survey"
              height="300px"
            />
          )}
        </Grid>
        <Grid item xs={9}>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "start",
            }}
          >
            <Typography
              style={{
                marginTop: "2px",
                fontWeight: "800",
                fontSize: "24px",
                marginBottom: "22px",
              }}
            >
              {tourType.name}
            </Typography>
            <Typography
              style={{
                opacity: "0.6",
                color: "rgba(0, 40, 69, 1)",
                fontSize: "18px",
                fontWeight: "500",
                marginBottom: "22px",
              }}
            >
              {tourType.content}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<PlayCircleFilled fontSize="16px" />}
              style={{
                fontSize: "16px",
                fontWeight: "700",
              }}
              onClick={() => {
                Apxor.logEvent(
                  "TryNudgeClicked",
                  {
                    "name": campaignConfig?.meta?.name ?? "NA",
                    "actionType": tourType.actionType,
                  },
                  APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS
                );
                handleDuplicate(campaignConfig);
              }}
            >
              Try Now
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default function TourTypeSelectionDialog({ auth, open, onClose }) {
  const featureDiscoveryUseCases = useMemo(
    () => [
      walkthroughTourTypes.find(
        (tour) => tour.name === "Search Feature Discovery"
      ),
      surveyTourTypes.find(
        (tour) => tour.name === "Cart Item Deletion - User Feedback Survey"
      ),
    ],
    []
  );

  const [demoWalkthroughs, setDemoWalkthroughs] = useState([]);
  const [demoSurveys, setDemoSurveys] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const [
          { data: demoWalkthroughsFromAPI },
          { data: demoSurveysFromAPI },
        ] = await Promise.all([
          getDemoCampaignsAPI(
            auth,
            DEMO_ECOMMERCE_APP_ID,
            {
              configType: DEMO_TEMPLATE_CONFIG_TYPES.WALKTHROUGHS,
            },
            false
          ),
          getDemoCampaignsAPI(
            auth,
            DEMO_ECOMMERCE_APP_ID,
            {
              configType: DEMO_TEMPLATE_CONFIG_TYPES.SURVEYS,
            },
            false
          ),
        ]);
        setDemoWalkthroughs(demoWalkthroughsFromAPI ?? []);
        setDemoSurveys(demoSurveysFromAPI ?? []);
      } catch (error) {
        setDemoWalkthroughs([]);
        setDemoSurveys([]);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actions = useMemo(
    () => [
      demoWalkthroughs.find(
        (walkthrough) => walkthrough?.meta?.name === "Search Feature Discovery"
      ),
      demoSurveys.find(
        (survey) =>
          survey?.meta?.name === "Cart Item Deletion - User Feedback Survey"
      ),
    ],
    [demoSurveys, demoWalkthroughs]
  );

  return (
    <>
      <CustomMaterialUIDialog
        openFlag={open}
        isFullWidth={false}
        maxWidth="md"
        onClose={onClose}
        onDialogClose={onClose}
        dialogContent={
          <>
            <Grid
              container
              spacing={4}
              style={{
                background: "#ffffff",
              }}
            >
              {featureDiscoveryUseCases.map((tourType, index) => (
                <SingleTourCard
                  tourType={tourType}
                  campaignConfig={actions.find(
                    (action) => action?.meta?.name === tourType.name
                  )}
                  key={index}
                />
              ))}
            </Grid>
          </>
        }
        dialogContentStyles={{
          padding: "20px 60px",
        }}
        dialogActions={
          <Box
            style={{
              minHeight: "20px",
            }}
          ></Box>
        }
        dialogTitleStyles={{
          padding: "20px",
        }}
      />
    </>
  );
}
