import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import CustomColorPicker from "../../../../../../../../ReusableComponents/CustomColorPicker";
import ModuleDivider from "../../../../../../../../ReusableComponents/ModuleDivider";
import MultiSelect from "../../../../../../../../ReusableComponents/MultiSelect";
import ToggleSwitch from "../../../../../../../../ReusableComponents/ToggleSwitch";
import { SET_BACKGROUND_COLOR, SET_MESSAGE_POSITION } from "../../../constants";
import { useTracked } from "../../../store";
import {
  NUDGE_POSITION_ENUM,
  SET_PASSIVE_NUDGE_ANIMATION_TYPE,
} from "../../../constants";
import { TOGGLE_SWITCH_STYLES } from "../../../../../../../common/components/campaigncreator/constants";
import DelayConfig from "./DelayConfig";
import { getUI } from "../../../utils";
import { useTrackedState } from "../../../../../../../common/components/campaigncreator/store";

const useStyles = makeStyles((theme) => ({
  gridItem: (props) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }),
  gridItemVertical: (props) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  }),
  typographySubtitle2: (props) => ({
    fontWeight: 800,
    fontSize: 14,
  }),
  typographyCaption: (props) => ({
    fontWeight: 600,
    fontSize: 12,
  }),
  formControlLabel: (props) => ({
    marginRight: 0,
  }),
}));

export default function NewNudgeConfig({ currentStep }) {
  const classes = useStyles();

  const [state, dispatch] = useTracked();
  const parentState = useTrackedState();
  const {
    config: {
      meta: { isExperiment },
    },
  } = parentState;
  const ui = getUI(currentStep, state.uis, isExperiment).ui || {};
  const { bg_color, position, delay, nudge_config } = ui;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} className={classes.gridItem} style={{ marginTop: 8 }}>
        <Typography className={classes.typographySubtitle2} variant="h6">
          COLOR
        </Typography>
        <CustomColorPicker
          color={bg_color}
          handleColorChange={(color) => {
            dispatch({
              type: SET_BACKGROUND_COLOR,
              currentStep: currentStep,
              value: color.hex,
            });
          }}
        />
      </Grid>
      <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
      <Grid item xs={12} className={classes.gridItem}>
        <Typography className={classes.typographySubtitle2} variant="h6">
          POSITION
        </Typography>
        <MultiSelect
          style={{ maxWidth: 145 }}
          single
          clearable={false}
          isSearchable={false}
          options={NUDGE_POSITION_ENUM}
          value={position}
          handleChange={(value) => {
            dispatch({
              type: SET_MESSAGE_POSITION,
              currentStep: currentStep,
              value: value,
            });
          }}
        />
      </Grid>
      <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
      <Grid item xs={12} className={classes.gridItem}>
        <Typography className={classes.typographySubtitle2} variant="h6">
          ANIMATION
        </Typography>
        <ToggleSwitch
          className={classes.formControlLabel}
          checked={nudge_config?.anim_type === "cycle"}
          handleChange={(checked, e) => {
            const animType = checked ? "cycle" : "none";
            dispatch({
              type: SET_PASSIVE_NUDGE_ANIMATION_TYPE,
              currentStep: currentStep,
              value: animType,
            });
          }}
          style={TOGGLE_SWITCH_STYLES()}
        />
      </Grid>
      <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
      <DelayConfig delay={delay} />
    </Grid>
  );

}