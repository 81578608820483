import {
  Button,
  Card,
  CardContent,
  Dialog,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import momentTimezone from "moment-timezone";
import React, { useEffect, useState } from "react";
import { isSuper } from "../../../../../config";
import {
  ACTIONS,
  ANALYTICS,
  FEATURE_KEY_ENUM,
  ON_APP_STATE_CHANGE,
} from "../../../../../constants";
import { useAuth } from "../../../../../contexts/AuthContext";
import { isAccessable } from "../../../../../utils";
import BetaChip from "../../../../ReusableComponents/BetaChip";
import Box from "../../../../ReusableComponents/Box";
import Loading from "../../../../ReusableComponents/Loading";
import MultiSelect from "../../../../ReusableComponents/MultiSelect";
import RowDiv from "../../../../ReusableComponents/RowDiv";
import Snackbar from "../../../../ReusableComponents/Snackbar";
import ToggleSwitch from "../../../../ReusableComponents/ToggleSwitch";
import { ApxEventHandler } from "../../../common/ApxEventHandler";
import { updateAppTZSettingsAPI, updateFeaturesList } from "./actions";

const timezones = momentTimezone.tz
  .names()
  .map((tzItem) => {
    const zone = momentTimezone.tz.zone(tzItem);
    const formatted = zone.abbr(0) + " - " + tzItem;
    return {
      id: tzItem,
      label: formatted,
      offset: zone.utcOffset(0),
    };
  })
  .map((option) => {
    const rgx = /(^(\+|-)\d+\s- )/;
    const matches = option.label.match(rgx);
    if (matches) {
      const prefix = matches[0];
      option.label = option.label.split(prefix)[1];
    }
    return option;
  });

export default function GeneralSettings({
  app: {
    app_id,
    settings: { timezone: tz = "UTC" },
    features,
  },
}) {
  const auth = useAuth();
  const {
    user: { email },
  } = auth;

  const [timezone, setTimezone] = useState(tz);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [changed, setChanged] = useState(false);
  const [dialog] = useState(false);
  const [featuresToDisable, setFeaturesToDisable] = useState(new Set());
  const [featuresToEnable, setFeaturesToEnable] = useState(
    new Set([...features]),
  );
  const [BC, setBC] = useState(
    isAccessable({ features: features }, FEATURE_KEY_ENUM.ACTIONS),
  );
  const [PI, setPI] = useState(
    isAccessable({ features: features }, FEATURE_KEY_ENUM.ANALYTICS),
  );
  const [savePending, setSavePending] = useState(false);

  useEffect(() => {
    setChanged(true);
  }, [featuresToDisable, featuresToEnable]);

  const handleFeatureToggle = (enabled, item) => {
    if (enabled) {
      setFeaturesToEnable(new Set(featuresToEnable).add(item));
      const newItems = new Set(featuresToDisable);
      newItems.delete(item);
      setFeaturesToDisable(newItems);
    } else {
      setFeaturesToDisable(new Set(featuresToDisable).add(item));
      const newItems = new Set(featuresToEnable);
      newItems.delete(item);
      setFeaturesToEnable(newItems);
    }
  };

  const handleSectionToggle = (enabled, items) => {
    if (enabled) {
      setFeaturesToEnable(new Set([...featuresToEnable, ...items]));
      const newSet = new Set([...featuresToDisable]);
      items.map((eachItem) => newSet.delete(eachItem));
      setFeaturesToDisable(newSet);
    } else {
      setFeaturesToDisable(new Set([...featuresToDisable, ...items]));
      const newSet = new Set([...featuresToEnable]);
      items.map((eachItem) => newSet.delete(eachItem));
      setFeaturesToEnable(newSet);
    }

    if (featuresToEnable.size > features.length || featuresToDisable.size > 0)
      setChanged(true);
  };

  const getFeaturesQueryParams = () => {
    const params = [...featuresToEnable].map((eachFeature) => {
      if (!featuresToDisable.has(eachFeature)) return { features: eachFeature };
      else return null;
    });
    return { ...params };
  };

  return (
    <section className="content">
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <Grid container alignItems={"center"} spacing={2}>
                <Grid
                  item
                  xs={4}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography variant={"h5"}> Timezone </Typography>
                  <BetaChip withGutter />
                </Grid>
                <Grid item xs={3} style={{ textAlign: "left" }}>
                  <Typography variant={"subtitle2"}>
                    Current Timezone:
                  </Typography>
                  <Typography variant={"h6"} style={{ fontWeight: 600 }}>
                    {pending ? (
                      <Loading size={18} />
                    ) : timezone ? (
                      timezone
                    ) : (
                      "UTC"
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <MultiSelect
                    single
                    clearable={false}
                    disabled={pending}
                    value={timezone}
                    handleChange={(value) => setTimezone(value)}
                    options={timezones.map((option) => ({
                      label: option.label,
                      value: option.id,
                    }))}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    disabled={pending || timezone === tz}
                    variant={"contained"}
                    color="primary"
                    onClick={() => {
                      setPending(true);
                      setError(false);
                      setUpdated(false);
                      updateAppTZSettingsAPI(auth, app_id, {
                        timezone: timezone,
                      })
                        .then(() => {
                          setPending(false);
                          setUpdated(true);
                          ApxEventHandler.dispatchEvent({
                            name: ON_APP_STATE_CHANGE,
                          });
                        })
                        .catch((e) => {
                          setPending(false);
                          setError(true);
                        });
                    }}
                  >
                    {pending && (
                      <Loading size={12} style={{ marginRight: 6 }} />
                    )}{" "}
                    Update
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
            {error && (
              <Snackbar open={true} autoHideDuration={3000}>
                Failed to update the timezone. Try again later
              </Snackbar>
            )}
            {updated && (
              <Snackbar open={true} autoHideDuration={3000}>
                Timezone updated to {timezone}.
              </Snackbar>
            )}
          </Card>
        </Grid>
      </Grid>
      {isSuper(email) && (
        <Grid container justifyContent="center" style={{ marginTop: 10 }}>
          <Grid item xs={12} md={8}>
            <Card>
              <CardContent>
                <RowDiv center styles={{ gap: "4px" }}>
                  <SettingsIcon color="primary" />
                  <Typography variant="h5">Features List</Typography>
                </RowDiv>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={8}>
            <Card>
              <CardContent>
                <RowDiv>
                  <Grid item xs={6}>
                    <Box
                      title="Insights"
                      controls={
                        <ToggleSwitch
                          handleChange={(e) => {
                            handleSectionToggle(e, ANALYTICS);
                            setPI(!PI);
                          }}
                          value={"All"}
                          checked={PI}
                        />
                      }
                    >
                      {FEATURE_KEY_ENUM &&
                        isAccessable(
                          { features: features },
                          FEATURE_KEY_ENUM.ANALYTICS,
                        ) && (
                          <List>
                            {Object.keys(FEATURE_KEY_ENUM)
                              .filter(
                                (key) =>
                                  key !== "ANALYTICS" &&
                                  key !== "ACTIONS" &&
                                  ANALYTICS.indexOf(FEATURE_KEY_ENUM[key]) >
                                    -1 &&
                                  key.indexOf("SETTINGS") === -1,
                              )
                              .map((item, index) => {
                                return (
                                  <ListItem
                                    key={FEATURE_KEY_ENUM[item] + index}
                                    divider
                                  >
                                    <ListItemText
                                      primary={<strong>{item}</strong>}
                                    />
                                    <ListItemSecondaryAction>
                                      <ToggleSwitch
                                        handleChange={(e) => {
                                          handleFeatureToggle(
                                            e,
                                            FEATURE_KEY_ENUM[item],
                                          );
                                        }}
                                        value={FEATURE_KEY_ENUM[item]}
                                        checked={
                                          (featuresToEnable &&
                                            featuresToEnable.has(
                                              FEATURE_KEY_ENUM[item],
                                            ) &&
                                            !featuresToDisable.has(item)) ||
                                          false
                                        }
                                        disabled={
                                          !isAccessable(
                                            { features: features },
                                            FEATURE_KEY_ENUM.ANALYTICS,
                                          )
                                        }
                                      />
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                );
                              })}
                          </List>
                        )}
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      title="Nudges"
                      controls={
                        <ToggleSwitch
                          handleChange={(e) => {
                            handleSectionToggle(e, ACTIONS);
                            setBC(!BC);
                          }}
                          value={"All"}
                          checked={BC}
                        />
                      }
                      footer={
                        changed && (
                          <div>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setSavePending(true);
                                updateFeaturesList(
                                  auth,
                                  app_id,
                                  getFeaturesQueryParams(),
                                )
                                  .then(() => {
                                    setSavePending(false);
                                    setChanged(false);
                                  })
                                  .catch((e) => {
                                    setSavePending(false);
                                  });
                              }}
                            >
                              {savePending ? (
                                <Loading size={18} />
                              ) : (
                                "Save Settings"
                              )}
                            </Button>

                            <Dialog
                              status={dialog}
                              handleConfirm={() => {}}
                              title="This will change Enable/Disable the toggled features. Are you sure ?"
                              handleClose={() => {}}
                              confirmLabel="Apply"
                              dialogProps={{
                                maxWidth: "md",
                                fullWidth: true,
                              }}
                            ></Dialog>
                          </div>
                        )
                      }
                    >
                      {FEATURE_KEY_ENUM &&
                        isAccessable(
                          { features: features },
                          FEATURE_KEY_ENUM.ACTIONS,
                        ) && (
                          <List>
                            {Object.keys(FEATURE_KEY_ENUM)
                              .filter(
                                (key) =>
                                  key !== "ANALYTICS" &&
                                  key !== "ACTIONS" &&
                                  ACTIONS.indexOf(FEATURE_KEY_ENUM[key]) > -1,
                              )
                              .map((item, index) => {
                                return (
                                  <ListItem
                                    key={FEATURE_KEY_ENUM[item] + index}
                                    divider
                                  >
                                    <ListItemText
                                      primary={<strong>{item}</strong>}
                                    />
                                    <ListItemSecondaryAction>
                                      <ToggleSwitch
                                        handleChange={(e) =>
                                          handleFeatureToggle(
                                            e,
                                            FEATURE_KEY_ENUM[item],
                                          )
                                        }
                                        value={FEATURE_KEY_ENUM[item]}
                                        checked={
                                          (featuresToEnable &&
                                            featuresToEnable.has(
                                              FEATURE_KEY_ENUM[item],
                                            ) &&
                                            !featuresToDisable.has(item)) ||
                                          false
                                        }
                                        disabled={
                                          !isAccessable(
                                            { features: features },
                                            FEATURE_KEY_ENUM.ACTIONS,
                                          )
                                        }
                                      />
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                );
                              })}
                          </List>
                        )}
                    </Box>
                  </Grid>
                </RowDiv>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </section>
  );
}
