import { Grid } from "@material-ui/core";
import React from "react";
import CustomColorPicker, {
  COLOR_STYLE_ENUM,
} from "../../../../../../../ReusableComponents/CustomColorPicker";

function VideoInAppColorsToolbar({ color, onColorChange }) {
  return (
    <Grid
      container
      spacing={1}
      style={{
        display: "flex",
        background: "#fff",
        boxShadow: "0px 4px 15px 2px rgba(0, 40, 69, 0.14)",
        borderRadius: 3,
        alignItems: "center",
        width: "auto",
      }}
    >
      {color !== "#" && (
        <Grid item>
          <CustomColorPicker
            color={color}
            showColorText={false}
            handleColorChange={(
              selectedColor,
              colorStyle = COLOR_STYLE_ENUM.SOLID
            ) => {
              if (onColorChange) {
                onColorChange(selectedColor, colorStyle);
              }
            }}
            styles={{ padding: 8 }}
            enableGradient={false}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default React.memo(VideoInAppColorsToolbar);
