import { Grid } from "@material-ui/core";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { HIDE_CONFIGURATION_TOOLBARS, SET_MODULE_TYPE } from "../../constants";
import { useTracked } from "../../store";
import { getUI } from "../../utils";
import {
  subscribeToEvent,
  unSubscribeAllConfigurationEvents,
} from "../configuration/EventSubscriber";
import ConfigToolbars from "./components/ConfigToolbars";
import PreviewButtonHolder from "./components/PreviewButtonHolder";
import SimulatorHolder from "./components/SimulatorHolder";

export default function Simulator({ platform, isExperiment }) {
  const [state, dispatch] = useTracked();

  const { currentStep, uis = [], moduleType, template_been_set } = state;

  // FIXME: Watchout this `useMemo`, if you face any inconsistent data binding issues
  const isValidUI = useMemo(() => {
    const ui =
      getUI(currentStep, uis, isExperiment) &&
      getUI(currentStep, uis, isExperiment).ui
        ? getUI(currentStep, uis, isExperiment).ui
        : {};
    return ui && Object.keys(ui).length > 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uis, currentStep, template_been_set]);

  const [orientation] = useState("Portrait");

  const simulatorRef = useRef(null);
  const [target, setTarget] = useState(null);

  const closeModal = useCallback(
    ({ target }) => {
      if (
        simulatorRef &&
        simulatorRef.current &&
        simulatorRef.current.contains(target)
      ) {
        try {
          setTarget(null);
          dispatch({
            type: SET_MODULE_TYPE,
            value: "",
          });
        } catch (e) {}
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [target]
  );

  useEffect(() => {
    const collapseCallback = () => {
      setTarget(null);
      dispatch({
        type: SET_MODULE_TYPE,
        value: "",
      });
    };
    const onNewStepAddSubscriber = subscribeToEvent(
      HIDE_CONFIGURATION_TOOLBARS,
      collapseCallback
    );
    return () => {
      unSubscribeAllConfigurationEvents([onNewStepAddSubscriber]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (target) {
      setTimeout(() => {
        document.addEventListener("click", closeModal, false);
      }, 100);
    } else {
      document.removeEventListener("click", closeModal, false);
    }

    return () => {
      document.removeEventListener("click", closeModal, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target]);

  if (isValidUI) {
    return (
      <Grid
        container
        justify="center"
        alignContent="center"
        alignItems="center"
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          marginTop: 10,
          marginBottom: 10,
          position: "relative",
        }}
        ref={simulatorRef}
      >
        <ConfigToolbars
          currentStep={currentStep}
          moduleType={moduleType}
          platform={platform}
          target={target}
          ui={getUI(currentStep, uis, isExperiment).ui}
        />
        <Grid item align="center" style={{ margin: "auto" }}>
          <SimulatorHolder
            moduleType={moduleType}
            orientation={orientation}
            setTarget={setTarget}
            ui={getUI(currentStep, uis, isExperiment).ui}
            type={getUI(currentStep, uis, isExperiment).ui}
          />
        </Grid>
        <PreviewButtonHolder />
      </Grid>
    );
  }
  return <div></div>;

}