import React, { useState, useMemo } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Typography,
} from "@material-ui/core";
import { useTracked } from "../../../../../../../common/components/campaigncreator/store";
import ColumnDiv from "../../../../../../../../ReusableComponents/ColumnDiv";
import EventRow from "../../../../../../../common/components/campaigncreator/components/context/components/NewContextBuilder/components/EventRow";
import { useCommonConfigStyles } from "../../../../../../../common/components/campaigncreator/utils";
import {
  CONTEXT_EVENT_TYPES_ENUM,
  EVENT_CONDTION_TYPES,
  SET_ADD_CONDITION,
  SET_GOAL_MET_CHECK,
  SET_OPERATOR_AND_SEQUENCE_ENABLED,
  SET_REMOVE_CONDITION,
  SET_SNACKBAR_ERROR_MESSAGE,
  SET_UPDATE_CONDITION,
  TRIGGER_CONFIG,
} from "../../../../../../../common/components/campaigncreator/constants";
import generateShortID from "../../../../../../../../../utils";
import { useAuth } from "../../../../../../../../../contexts/AuthContext";
import CloseIcon from "@material-ui/icons/Close";
import { getDefaultCombineOperator } from "../../../../../../../common/components/campaigncreator/components/context/components/NewContextBuilder";
import BootstrapTooltip from "../../../../../../../../ReusableComponents/BootstrapTooltip";

export default function VideoSettingsDialog({ open, onClose }) {
  const { appId } = useAuth();
  const classes = useCommonConfigStyles();
  const [state, dispatch] = useTracked();

  const {
    config: {
      meta: { platform },
      pre_cs = [],
      pre_cs_seq_enabled = false,
      goal_event = "",
      goal_met_check = false,
      terminate_info: {
        conditions = [],
        sequence_enabled: delete_sequence_enabled = false,
      },
    },
    app_screen_names: customScreenNames = [],
    app_events = [],
    app_screens = [],
    client_events = [],
  } = state;

  const delete_conditions = useMemo(
    () => (goal_met_check ? conditions.slice(1) : conditions),
    [conditions, goal_met_check]
  );

  const [downloadCombineOperator, setDownloadCombineOperator] = useState(
    getDefaultCombineOperator(pre_cs)
  );

  const [deleteCombineOperator, setDeleteCombineOperator] = useState(
    getDefaultCombineOperator(delete_conditions)
  );

  /**
   * FIXME: Can be refactored by importing the next 3 functions from the NewContextBuilder Component, or a custom hook maybe?
   */
  const addCondition = (event_type, conditions_type, is_goal_event = false) => {
    const cs =
      conditions_type.cs === EVENT_CONDTION_TYPES.DOWNLOAD_CS.cs
        ? { len: pre_cs?.length, se: pre_cs_seq_enabled }
        : { len: delete_conditions?.length, se: delete_sequence_enabled };
    const condition = {
      _id: generateShortID(),
      event_type: CONTEXT_EVENT_TYPES_ENUM[event_type],
      context_rule_type: event_type,
      type: "did",
      sequence: cs.se ? cs.len + 1 : -1,
      time_bounds: {
        lower: 0,
        upper: 6000000,
      },
      count_config: {
        count: 1,
        operator: "GTE",
      },
      combine_operator: is_goal_event
        ? "OR"
        : conditions_type.cs === EVENT_CONDTION_TYPES.DOWNLOAD_CS.cs
        ? downloadCombineOperator
        : deleteCombineOperator,
      activity: "",
      details: {
        additional_info: {},
        name: is_goal_event ? goal_event : "",
      },
      ...TRIGGER_CONFIG,
    };
    dispatch({
      type: SET_ADD_CONDITION,
      condition,
      conditions_type,
      is_goal_event,
    });
  };

  const removeCondition = (index, conditions_type) => {
    dispatch({
      type: SET_REMOVE_CONDITION,
      index,
      conditions_type,
    });
  };

  const updateOperator = conditions_type => (
    operator,
    sequence_enabled = false
  ) => {
    if (conditions_type.cs === EVENT_CONDTION_TYPES.DOWNLOAD_CS.cs)
      setDownloadCombineOperator(operator);
    else if (conditions_type.cs === EVENT_CONDTION_TYPES.DELETE_CS.cs)
      setDeleteCombineOperator(operator);
    dispatch({
      type: SET_OPERATOR_AND_SEQUENCE_ENABLED,
      operator,
      enabled: sequence_enabled,
      conditions_type,
    });
  };

  const [goal_met_checkbox_hovered, set_goal_met_checkbox_hovered] = useState(
    false
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="body"
      fullWidth={true}
      maxWidth="lg"
    >
      <Box
        style={{
          padding: "20px",
          position: "relative",
        }}
      >
        <IconButton
          onClick={onClose}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "#A1ADB6",
            padding: 2,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>
          <Typography
            variant="h4"
            style={{
              fontWeight: "bold",
            }}
          >
            Video Settings
          </Typography>
        </DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            style={{
              marginBottom: "2rem",
            }}
          >
            <Typography className={classes.typographyHeading}>
              Download Video :
            </Typography>
            <ColumnDiv
              styles={{
                borderLeft: "1px solid #C5CDD2",
                marginLeft: pre_cs.length > 0 ? 15 : 0,
                paddingTop: pre_cs.length > 0 ? 20 : 0,
                paddingBottom: pre_cs.length > 0 ? 10 : 0,
              }}
            >
              {pre_cs.length > 0 &&
                pre_cs.map((condition, index) => (
                  <EventRow
                    appId={appId}
                    key={condition._id}
                    condition={condition}
                    index={index}
                    dispatch={dispatch}
                    combineOperator={downloadCombineOperator}
                    sequenceEnabled={pre_cs_seq_enabled}
                    updateOperator={updateOperator(
                      EVENT_CONDTION_TYPES.DOWNLOAD_CS,
                    )}
                    customScreenNames={customScreenNames}
                    platform={platform}
                    handleOnChange={(data) => {
                      dispatch({
                        type: SET_UPDATE_CONDITION,
                        index,
                        condition: data,
                        conditions_type: EVENT_CONDTION_TYPES.DOWNLOAD_CS,
                      });
                    }}
                    handleOnDelete={() => {
                      removeCondition(index, EVENT_CONDTION_TYPES.DOWNLOAD_CS);
                    }}
                    appEvents={app_events}
                    appScreenNames={app_screens}
                    clientEvents={client_events}
                  />
                ))}
            </ColumnDiv>
            <Button
              style={{
                marginTop: "1rem",
              }}
              variant="contained"
              color="primary"
              onClick={() => {
                const cannotAdd =
                  app_events.length < 1 &&
                  client_events.length < 1 &&
                  app_screens.length < 1;
                dispatch({
                  type: SET_SNACKBAR_ERROR_MESSAGE,
                  value: cannotAdd ? "No events left to add" : null,
                });
                if (cannotAdd) {
                  return;
                }
                addCondition("APP", EVENT_CONDTION_TYPES.DOWNLOAD_CS);
              }}
            >
              {pre_cs.length > 0 ? "+ Add another event" : "Add Event"}
            </Button>
          </Box>
          <Divider
            style={{
              marginBottom: "1rem",
            }}
          />
          <Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Typography className={classes.typographyHeading}>
                Delete video when :
              </Typography>
              <FormGroup
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  alignItems: "center",
                  marginLeft: "1rem",
                }}
              >
                <BootstrapTooltip
                  placement="top"
                  open={!goal_event && goal_met_checkbox_hovered}
                  title={
                    <>
                      Can't enable this until you have a <b>Goal Event</b>{" "}
                      selected in the <b>Target</b> tab
                    </>
                  }
                >
                  <FormControlLabel
                    onMouseEnter={() => {
                      set_goal_met_checkbox_hovered(true);
                    }}
                    onMouseLeave={() => {
                      set_goal_met_checkbox_hovered(false);
                    }}
                    style={{ marginRight: "3rem" }}
                    disabled={!goal_event}
                    defaultChecked={false}
                    control={
                      <Checkbox
                        color="primary"
                        checked={goal_met_check}
                        onChange={(_e, checked) => {
                          dispatch({
                            type: SET_GOAL_MET_CHECK,
                          });
                          if (checked) {
                            addCondition(
                              "APP",
                              EVENT_CONDTION_TYPES.DELETE_CS,
                              true,
                            );
                          } else {
                            removeCondition(0, EVENT_CONDTION_TYPES.DELETE_CS);
                          }
                        }}
                      />
                    }
                    label="Goal Met"
                  />
                </BootstrapTooltip>
                <FormControlLabel
                  style={{ marginRight: "3rem" }}
                  disabled
                  control={<Checkbox defaultChecked />}
                  label="Campaign frequency exhausted"
                />
                <FormControlLabel
                  disabled
                  control={<Checkbox defaultChecked />}
                  label="Campaign end"
                />
              </FormGroup>
            </Box>
            <ColumnDiv
              styles={{
                borderLeft: "1px solid #C5CDD2",
                marginLeft: delete_conditions.length > 0 ? 15 : 0,
                paddingTop: delete_conditions.length > 0 ? 20 : 0,
                paddingBottom: delete_conditions.length > 0 ? 10 : 0,
              }}
            >
              {delete_conditions.length > 0 &&
                delete_conditions.map((condition, index) => {
                  return (
                    <EventRow
                      appId={appId}
                      key={condition._id}
                      condition={condition}
                      index={index}
                      dispatch={dispatch}
                      combineOperator={deleteCombineOperator}
                      sequenceEnabled={delete_sequence_enabled}
                      updateOperator={updateOperator(
                        EVENT_CONDTION_TYPES.DELETE_CS,
                      )}
                      platform={platform}
                      customScreenNames={customScreenNames}
                      handleOnChange={(data) => {
                        dispatch({
                          type: SET_UPDATE_CONDITION,
                          index: goal_met_check ? index + 1 : index,
                          condition: data,
                          conditions_type: EVENT_CONDTION_TYPES.DELETE_CS,
                        });
                      }}
                      handleOnDelete={() =>
                        removeCondition(
                          goal_met_check ? index + 1 : index,
                          EVENT_CONDTION_TYPES.DELETE_CS,
                        )
                      }
                      appEvents={app_events}
                      appScreenNames={app_screens}
                      clientEvents={client_events}
                      onlyOrOperator={true}
                    />
                  );
                })}
            </ColumnDiv>
            <Button
              style={{
                marginTop: "1rem",
              }}
              variant="contained"
              color="primary"
              onClick={() => {
                const cannotAdd =
                  app_events.length < 1 &&
                  client_events.length < 1 &&
                  app_screens.length < 1;
                dispatch({
                  type: SET_SNACKBAR_ERROR_MESSAGE,
                  value: cannotAdd ? "No events left to add" : null,
                });
                if (cannotAdd) {
                  return;
                }
                addCondition("APP", EVENT_CONDTION_TYPES.DELETE_CS);
              }}
            >
              {delete_conditions.length > 0
                ? "+ Add another event"
                : "Add Event"}
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={onClose}
            autoFocus
          >
            Done
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
