import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Button } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import PersonIcon from "@material-ui/icons/Person";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ClearIcon from "@material-ui/icons/Clear";
import Box from "./Box";
import MultiSelect from "./MultiSelect";
import Switch from "./Switch";
import DonutDistributions from "./DonutDistributions";
import { getAttributeDistributionAPI } from "../common/actions";

const SWITCH_DATA = [
  { name: "Sessions", value: "sessions" },
  { name: "Users", value: "users" },
];

const emptyAttributes = (props) => {
  const attrs = [...props];
  return {
    ...attrs.reduce((a, b) => {
      a[b] = [];
      return a;
    }, {}),
  };
};

const toAttributeFilterModel = (names = {}) => {
  const array = [];
  Object.keys(names).forEach((key) => {
    const values = names[key];
    if (values.length > 0) {
      array.push({
        name: key,
        operator: "EQ",
        value: values,
      });
    }
  });
  return array;
};

export default function UserSessionDistributions({
  appId,
  title,
  icon,
  onFiltersChange,
  queryParams,
  filters,
  userAttributes,
  sessionAttributes,
  withBox = true,
  showUserSessionSwitch = true,
  selectable = true,
}) {
  const auth = useAuth();

  // Local State
  const [userAttrValueMap, setUserAttrValueMap] = useState(
    emptyAttributes(userAttributes)
  );
  const [sessionAttrValueMap, setSessionAttrValueMap] = useState(
    emptyAttributes(sessionAttributes)
  );
  const [selectedUserProps, setSelectedUserProps] = useState([]);
  const [selectedSessionProps, setSelectedSessionProps] = useState([]);
  const [userDistributions, setUserDistributions] = useState([]);
  const [sessionDistributions, setSessionDistributions] = useState([]);
  const [count, setCount] = useState(0);
  const [show, setShow] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [of, setOf] = useState("users");
  const [pendingUserAPI, setPendingUserAPI] = useState(false);
  const [pendingSessionAPI, setPendingSessionAPI] = useState(false);

  // Fetch the distributions upon change of `of`, `queryParams` or `filters` props
  useEffect(() => {
    const userPropsPromises = [];
    const userPropsKeys = [];
    setPendingUserAPI(true);
    selectedUserProps.forEach((prop) => {
      const qParams = { ...queryParams, of: of, attribute: prop };
      userPropsPromises.push(
        getAttributeDistributionAPI(auth, appId, qParams, filters)
      );
      userPropsKeys.push(prop);
    });

    Promise.all(userPropsPromises)
      .then((values) => {
        values.forEach((value, index) => {
          setUserDistributions({
            ...userDistributions,
            [userPropsKeys[index]]: value,
          });
        });
        setPendingUserAPI(false);
      })
      .catch(() => {
        setPendingUserAPI(false);
      });

    const sessionPropsPromises = [];
    const sessionPropsKeys = [];
    setPendingSessionAPI(true);
    selectedSessionProps.forEach((prop) => {
      const qParams = { ...queryParams, of: of, attribute: prop };
      sessionPropsPromises.push(
        getAttributeDistributionAPI(auth, appId, qParams, filters)
      );
      sessionPropsKeys.push(prop);
    });

    Promise.all(sessionPropsPromises)
      .then((values) => {
        values.forEach((value, index) => {
          setSessionDistributions({
            ...sessionDistributions,
            [sessionPropsKeys[index]]: value,
          });
        });
        setPendingSessionAPI(false);
      })
      .catch(() => {
        setPendingSessionAPI(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [of, queryParams, filters]);

  useEffect(() => {
    setUserAttrValueMap(emptyAttributes(userAttributes));
    setSessionAttrValueMap(emptyAttributes(sessionAttributes));
  }, [userAttributes, sessionAttributes]);

  // Clears the filters
  const cancelFilters = () => {
    setUserAttrValueMap(emptyAttributes(userAttributes));
    setSessionAttrValueMap(emptyAttributes(sessionAttributes));
    setFiltersApplied(false);
    setShow(false);
    setCount(0);
    if (onFiltersChange) {
      onFiltersChange([], []);
    }
  };

  const updateFilters = () => {
    setShow(true);
    setFiltersApplied(!filtersApplied);
    if (onFiltersChange) {
      onFiltersChange(
        toAttributeFilterModel(userAttrValueMap),
        toAttributeFilterModel(sessionAttrValueMap)
      );
    }
  };

  /**
   * Updates the respective [property]: [values] map
   *
   * If it is of user props, it updates `userAttrValueMap` map
   * If it is of session props, it updates `sessionAttrValueMap` map
   */
  const donutValueSelectHandler = (propName, data, isUser) => {
    let key = data.key || data.name || "";
    let array = isUser
      ? userAttrValueMap[propName]
      : sessionAttrValueMap[propName];
    if (array.indexOf(key) > -1) {
      array.splice(array.indexOf(key), 1);
      setCount(count - 1);
    } else {
      array.push(key);
      setCount(count + 1);
    }
    setShow(true);
    setFiltersApplied(false);

    if (isUser) {
      setUserAttrValueMap({
        ...userAttrValueMap,
        [propName]: array,
      });
    } else {
      setSessionAttrValueMap({
        ...sessionAttrValueMap,
        [propName]: array,
      });
    }
  };

  /**
   * Whenever user selects a prop or clear a prop this function gets called
   *
   * If the `attributes.length` is 0, clear the filters
   * If not, check if the selected key exists in `selected[User/Session]Props`
   *          If key doesn't exist, fetch the data and update it in respective distribution state
   *          If not, stay silent
   * @param attributes
   * @param isUser
   */
  const handleAttributeChange = (attributes = [], isUser = false) => {
    if (attributes.length > 0) {
      attributes.forEach((attribute) => {
        const qParams = { ...queryParams, of: of, attribute: attribute };
        if (isUser && !selectedUserProps.includes(attribute)) {
          setPendingUserAPI(true);
          getAttributeDistributionAPI(auth, appId, qParams, filters)
            .then((response) => {
              setPendingUserAPI(false);
              setUserDistributions({
                ...userDistributions,
                [attribute]: response,
              });
            })
            .catch(() => {
              setPendingUserAPI(false);
            });
        } else if (!isUser && !selectedSessionProps.includes(attribute)) {
          setPendingSessionAPI(true);
          getAttributeDistributionAPI(auth, appId, qParams, filters)
            .then((response) => {
              setPendingSessionAPI(false);
              setSessionDistributions({
                ...sessionDistributions,
                [attribute]: response,
              });
            })
            .catch(() => {
              setPendingSessionAPI(false);
            });
        }
      });
    } else {
      cancelFilters();
    }

    // Cleanup distributions data and update the select props
    if (isUser) {
      cleanupDistributions(selectedUserProps, attributes, true);
      setSelectedUserProps(attributes);
    } else {
      cleanupDistributions(selectedSessionProps, attributes, false);
      setSelectedSessionProps(attributes);
    }
  };

  /**
   * Cleanup util for distributions
   */
  const cleanupDistributions = (props, attributes, isUser) => {
    props.forEach((prop) => {
      if (attributes.length === 0) {
        if (isUser) {
          setUserDistributions({});
        } else {
          setSessionDistributions({});
        }
      } else if (!attributes.includes(prop)) {
        if (isUser) {
          const distributions = { ...userDistributions };
          delete distributions[prop];
          setUserDistributions(distributions);
        } else {
          const distributions = { ...sessionDistributions };
          delete distributions[prop];
          setSessionDistributions(distributions);
        }
      }
    });
  };

  const contents = (
    <>
      {showUserSessionSwitch && (
        <Switch
          data={SWITCH_DATA}
          value={of}
          handleChange={(value) =>
            /* Whenever the value changes, fetch all user and session distributions selected so far */
            setOf(value)
          }
          containerStyles={{ maxWidth: 200, marginLeft: "auto" }}
        />
      )}
      <Box
        withPadding
        title="User Properties"
        icon={<PersonIcon />}
        controls={
          <MultiSelect
            placeholder="Select Properties"
            value={selectedUserProps}
            options={userAttributes.map((item) => ({
              label: item,
              value: item,
            }))}
            handleChange={(values) =>
              /* Should get the distributions upon every selection of property */
              handleAttributeChange(values, true)
            }
            style={{ maxWidth: 600 }}
          />
        }
      >
        <DonutDistributions
          messageForNoData="No User Properties Selected"
          data={userDistributions}
          handleSelect={(item, values) =>
            donutValueSelectHandler(item, values, true)
          }
          selections={userAttrValueMap}
          notSelectable={!selectable}
          isPending={pendingUserAPI}
        />
      </Box>
      <Box
        withPadding
        title="Session Properties"
        icon={<AccessTimeIcon />}
        controls={
          <MultiSelect
            placeholder="Select Properties"
            value={selectedSessionProps}
            options={sessionAttributes.map((item) => ({
              label: item,
              value: item,
            }))}
            handleChange={(values) =>
              /* Should get the distributions upon every selection of property */
              handleAttributeChange(values, false)
            }
            style={{ maxWidth: 600 }}
          />
        }
      >
        <DonutDistributions
          messageForNoData="No Session Properties Selected"
          data={sessionDistributions}
          handleSelect={(item, values) =>
            donutValueSelectHandler(item, values, false)
          }
          selections={sessionAttrValueMap}
          notSelectable={!selectable}
          isPending={pendingSessionAPI}
        />
      </Box>
    </>
  );

  if (withBox) {
    return (
      <Box
        withPadding
        title={title}
        icon={icon}
        collapsible
        controls={
          count > 0 &&
          show && (
            <Button
              variant="contained"
              color={filtersApplied ? "secondary" : "primary"}
              onClick={(e) => {
                e.stopPropagation();
                if (filtersApplied) {
                  cancelFilters();
                } else {
                  updateFilters();
                }
              }}
            >
              {filtersApplied ? "Cancel " : "Apply "} &nbsp;
              {filtersApplied ? <ClearIcon /> : <FilterListIcon />}
            </Button>
          )
        }
      >
        {contents}
      </Box>
    );
  } else {
    return contents;
  }
}
