import { produce } from "immer";
import { EVENT_TYPE_ENUM } from "../../../../constants";
import {
  USER_PROFILE_STATIC_BASIC_INFO,
  USER_PROFILE_DYNAMIC_BASIC_INFO,
  USER_PROFILE_SESSION_INFO,
  USER_PROFILE_CRASH_INFO,
  USER_PROFILE_SESSIONS_LIST,
  USER_PROFILE_SESSION_TIMELINE,
} from "./constants";

export const initialState = {
  static_basic_info: {},
  dynamic_basic_info: {},
  session_info: {},
  crash_info: {},
  transaction_info: {},
  functionality: 0,
  usability: 0,
  stability: 0,
  performance: 0,
  sessions: {
    meta_info: {},
    session_list: [],
  },
  timeline: [],
  actual_timeline: [],
  dataVersion: 1,
  pagination: {
    page: 1,
    orderBy: null,
    sortOrder: null,
    count: 10, //TODO:
  },
};

function updateUserSessions(currentState, payload, dataVersion = 1) {
  let sessionList = [...currentState.session_list, ...payload];
  if (dataVersion < 2) {
    //Handling Toggle with STATUS here
    sessionList = payload;
  }
  return {
    session_list: sessionList,
    meta_info: {
      total_sessions: payload.length,
      total_pages: 0,
      page_length: payload.length
    },
  };
}

export function stackedTimeline(timeline) {
  if (timeline && timeline.length > 0) {
    const isFirstItemEvent =
      timeline[0].item_type !== EVENT_TYPE_ENUM.NAVIGATION_ITEM;
    let stackedTimeline = timeline.filter((item, index) => {
      //getting all navigation items
      return item.item_type === EVENT_TYPE_ENUM.NAVIGATION_ITEM;
    });
    let navigationItemIndex = -1;
    if (isFirstItemEvent) {
      //adding unknown navigation item if first item is event
      const unKnownNavigationItem = { name: "Unknown View" }; //FIXME: need fix from server itself
      stackedTimeline.splice(0, 0, unKnownNavigationItem);
      navigationItemIndex = 0;
      stackedTimeline[0]["events"] = [];
    }
    timeline.forEach((item, index) => {
      if (item.item_type !== EVENT_TYPE_ENUM.NAVIGATION_ITEM) {
        if (item.item_type === EVENT_TYPE_ENUM.ISSUE_ITEM) {
          //if contains issueItem
          stackedTimeline[navigationItemIndex]["issue"] = item;
        }
        stackedTimeline[navigationItemIndex]["events"].push(item);
      } else {
        stackedTimeline[++navigationItemIndex]["events"] = [];
      }
    });
    return stackedTimeline;
  } else {
    return [];
  }
}

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case USER_PROFILE_STATIC_BASIC_INFO:
      draft.static_basic_info = action.value;
      break;
    case USER_PROFILE_DYNAMIC_BASIC_INFO:
      draft.dynamic_basic_info = action.value;
      break;
    case USER_PROFILE_SESSION_INFO:
      draft.session_info = action.value;
      break;
    case USER_PROFILE_CRASH_INFO:
      draft.crash_info = action.value;
      break;
    case USER_PROFILE_SESSIONS_LIST:
      draft.dataVersion = action.dataVersion;
      draft.sessions = updateUserSessions(
        action.sessions,
        action.value,
        action.dataVersion ? action.dataVersion : draft.dataVersion
      );
      if (action.sortOrder) draft.pagination.sortOrder = action.sortOrder;
      if (action.orderBy) draft.pagination.orderBy = action.orderBy;
      if (action.countPerPage) draft.pagination.count = action.countPerPage;
      break;
    case USER_PROFILE_SESSION_TIMELINE:
      draft.actual_timeline = action.value;
      draft.timeline = stackedTimeline(action.value);
      break;
    default:
      return draft;
  }
});
