/**
 * Created by Rakesh Peela
 * Date: 11-Nov-2019
 * Time: 6:56 PM
 */

import React from "react";
import PropTypes from "prop-types";

export default class DummyInsetContainer extends React.Component {
  render() {
    const { withInnerShadow } = this.props;
    return (
      <div
        style={{
          borderRadius: 4,
          backgroundColor: "#f2f2f2",
          boxShadow: withInnerShadow
            ? "rgba(160, 160, 160, 1) 0px 0px 3px 0px inset"
            : "",
          minHeight: 48,
          padding: 12,
          marginBottom: 12,
          ...this.props.style,
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

DummyInsetContainer.propTypes = {
  withInnerShadow: PropTypes.bool,
};
