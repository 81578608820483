import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Input,
  Link,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { apxTheme } from "../../../../ApplicationFrame/styling/CustomCSS";
import Loading from "../../../../ReusableComponents/Loading";
import Snackbar from "../../../../ReusableComponents/Snackbar";
import {
  SET_AUTH_ERROR,
  SET_COMPANY_NAME,
  SET_EMAIL_ID,
  SET_JOB_TITLE,
  SET_NAME,
} from "../../../constants";
import { useDispatch, useTrackedState } from "../../../store";
import useIntegration from "../../hooks/use-integration";

export default function SignUpForm() {
  const { isSubmitDisabled, sendSetPasswordEmail } = useIntegration();
  const {
    user: { name, email, job_title, company_name },
    auth_error,
    auth_loading,
  } = useTrackedState();
  const dispatch = useDispatch();

  const [mailSent, setMailSent] = useState(false);
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(
    false,
  );

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      if (
        await sendSetPasswordEmail({
          company_name,
          email_id: email,
          job_title,
          name,
        })
      ) {
        setMailSent(true);
      }
    },
    [company_name, email, job_title, name, sendSetPasswordEmail]
  );

  return (
    <>
      {!auth_error && !auth_loading && mailSent && (
        <Snackbar
          onClose={() => {
            setMailSent(false);
          }}
        >
          <strong>
            Please check your mail to set a password and continue.
          </strong>
        </Snackbar>
      )}
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "start",
          marginRight: "24px",
          flex: "1",
        }}
      >
        <label
          htmlFor="name"
          style={{
            fontSize: "13px",
            fontWeight: "500",
            color: "#667A8A",
          }}
        >
          Name
        </label>
        <Input
          name="name"
          size="medium"
          id="name"
          type="text"
          value={name}
          onChange={(e) => {
            dispatch({
              type: SET_NAME,
              payload: e.target.value,
            });
            if (auth_error?.length > 0) {
              dispatch({
                type: SET_AUTH_ERROR,
                payload: "",
              });
            }
          }}
          style={{
            width: "60%",
            marginTop: "4px",
            border: "1px solid #CCD4DA",
            borderRadius: "2px",
            fontWeight: "600",
            fontSize: "14px",
            padding: "4.5px",
          }}
        />
        <label
          htmlFor="email"
          style={{
            marginTop: "16px",
            fontSize: "13px",
            fontWeight: "500",
            color: "#667A8A",
          }}
        >
          Work Email
        </label>
        <Input
          name="email"
          size="medium"
          id="email"
          type="email"
          value={email}
          onChange={(e) => {
            dispatch({
              type: SET_EMAIL_ID,
              payload: e.target.value,
            });
            if (auth_error?.length > 0) {
              dispatch({
                type: SET_AUTH_ERROR,
                payload: "",
              });
            }
          }}
          style={{
            width: "60%",
            marginTop: "4px",
            border: "1px solid #CCD4DA",
            borderRadius: "2px",
            fontWeight: "600",
            fontSize: "14px",
            padding: "4.5px",
          }}
        />
        <label
          htmlFor="job_title"
          style={{
            marginTop: "16px",
            fontSize: "13px",
            fontWeight: "500",
            color: "#667A8A",
          }}
        >
          Job Title
        </label>
        <Input
          name="job_title"
          size="medium"
          id="job_title"
          type="text"
          value={job_title}
          onChange={(e) => {
            dispatch({
              type: SET_JOB_TITLE,
              payload: e.target.value,
            });
            if (auth_error?.length > 0) {
              dispatch({
                type: SET_AUTH_ERROR,
                payload: "",
              });
            }
          }}
          style={{
            width: "60%",
            marginTop: "4px",
            border: "1px solid #CCD4DA",
            borderRadius: "2px",
            fontWeight: "600",
            fontSize: "14px",
            padding: "4.5px",
          }}
        />
        <label
          htmlFor="company_name"
          style={{
            marginTop: "16px",
            fontSize: "13px",
            fontWeight: "500",
            color: "#667A8A",
          }}
        >
          Company Name
        </label>
        <Input
          name="company_name"
          size="medium"
          id="company_name"
          type="text"
          value={company_name}
          onChange={(e) => {
            dispatch({
              type: SET_COMPANY_NAME,
              payload: e.target.value,
            });
            if (auth_error?.length > 0) {
              dispatch({
                type: SET_AUTH_ERROR,
                payload: "",
              });
            }
          }}
          style={{
            width: "60%",
            marginTop: "4px",
            border: "1px solid #CCD4DA",
            borderRadius: "2px",
            fontWeight: "600",
            fontSize: "14px",
            padding: "4.5px",
            marginBottom: "10px",
          }}
        />
        <Box
          style={{
            width: "60%",
            marginBottom: "45px",
          }}
        >
          <FormControlLabel
            value="end"
            control={
              <Checkbox
                color="primary"
                value={termsAndConditionsAccepted}
                onChange={(_e, checked) => {
                  setTermsAndConditionsAccepted(checked);
                }}
              />
            }
            label={
              <Typography
                style={{
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                By signing up you accept the{" "}
                <Link
                  href="https://www.apxor.com/terms-of-use"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: apxTheme.palette.primary.main,
                  }}
                >
                  Terms of Service
                </Link>{" "}
                and{" "}
                <Link
                  href="https://www.apxor.com/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: apxTheme.palette.primary.main,
                  }}
                >
                  Privacy Policy
                </Link>
              </Typography>
            }
            labelPlacement="end"
            style={{
              fontSize: "13px",
              fontWeight: "500",
            }}
          />
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            marginBottom: "12px",
            width: auth_error ? "100%" : "60%",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            disabled={
              isSubmitDisabled ||
              auth_error !== "" ||
              !termsAndConditionsAccepted
            }
            style={{
              width: auth_error ? "30%" : "50%",
              height: 48,
            }}
          >
            {auth_loading ? (
              <Loading size={28} color="#FFFFFF" />
            ) : (
              "Create Account"
            )}
          </Button>
          <span
            style={{
              marginLeft: "12px",
              fontSize: "16px",
              fontWeight: "500",
              color: "#F12D2D",
            }}
          >
            {auth_error !== "" && auth_error}
          </span>
        </Box>
        <Typography
          style={{
            fontSize: "13px",
            fontWeight: "600",
            color: "#4D697D",
          }}
        >
          Have an account already?{" "}
          <Link
            component={RouterLink}
            to="/login"
            style={{
              color: apxTheme.palette.primary.main,
            }}
          >
            Sign In
          </Link>
        </Typography>
      </form>
    </>
  );
}
