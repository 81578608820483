import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
  DateTimePicker,
} from "@material-ui/pickers";
import { ChevronLeft } from "@material-ui/icons";
import { useAuth } from "../../../../contexts/AuthContext";
import ColumnDiv from "../../../ReusableComponents/ColumnDiv";
import RowDiv from "../../../ReusableComponents/RowDiv";
import { useCommonConfigStyles } from "./campaigncreator/utils";
import { TOGGLE_SWITCH_STYLES } from "./campaigncreator/constants";
import ToggleSwitch from "../../../ReusableComponents/ToggleSwitch";
import MultiSelect from "../../../ReusableComponents/MultiSelect";
import { updateCampaignAPI } from "./campaigncreator/actions";
import { getCampaignAPI, manualVariantPublish } from "../actions";
import Loading from "../../../ReusableComponents/Loading";
import Snackbar from "../../../ReusableComponents/Snackbar";
import ActionsTextField from "../../../ReusableComponents/ActionsTextField";

const useStyles = makeStyles((theme) => ({
  formControlLabel: (props) => ({
    marginLeft: 8,
  }),
  gridItem: (props) => ({
    display: "flex",
  }),
  centerGrid: (props) => ({
    display: "flex",
    alignItems: "center",
  }),
  uiPicker: (props) => ({
    marginLeft: 0,
  }),
}));


export default function ABVariantManualPublish({
  campaignConfig: propCampaignConfig,
  campaignId,
  handleCancel,
  handleDone,
  fromListing = false,
}) {
  const auth = useAuth();
  const classes = useStyles();
  const typographyClasses = useCommonConfigStyles();

  const [variant, setVariant] = useState(
    fromListing
      ? propCampaignConfig?.experiment?.publishSettings
          ?.winner_variant_calculated
      : "A"
  );

  const [startDate, setStartDate] = useState(moment().toISOString());
  const [endDate, setEndDate] = useState(moment().add(7, "day").toISOString());

  const [showAtSpecificTime, setShowAtSpecificTime] = useState(false);
  const [startTime, setStartTime] = useState(
    moment().utc(true).format("HH:mm")
  );
  const [endTime, setEndTime] = useState(
    moment().utc(true).add(1, "hour").format("HH:mm")
  );
  const [controlGroupPercent, setControlGroupPercent] = useState(
    propCampaignConfig?.experiment?.publishSettings?.variants?.CG
      ?.distribution ?? 3
  );

  const [campaignConfig, setCampaignConfig] = useState(
    propCampaignConfig ?? {}
  );
  const [fetchingConfig, setFetchingConfig] = useState(
    propCampaignConfig === undefined || propCampaignConfig === null
  );
  const [step, setStep] = useState(0);
  const [error, setError] = useState(null);
  const [apiInProgress, setAPIInProgress] = useState(false);

  const { experiment: { variants = {} } = { variants: {} } } = campaignConfig;

  const publishSettings = {
    winner_variant_suggested: variant,
    time_settings: {
      start_date: startDate,
      end_date: endDate,
      time_limits_in_day: showAtSpecificTime,
      time_limits: {
        start_time: startTime,
        end_time: endTime,
      },
    },
    CG: controlGroupPercent,
  };

  const updatedConfig = {
    ...campaignConfig,
    experiment: {
      ...campaignConfig.experiment,
      isWinnerPublished: false,
      settings: {
        ...campaignConfig.experiment.settings,
        isManualExperiment: true,
      },
      publishSettings,
    },
  };

  useEffect(() => {
    if (Object.keys(propCampaignConfig) === 0) {
      return;
    }

    setFetchingConfig(true);
    getCampaignAPI(auth, auth.appId, campaignId, {
      configType: "walkthrough",
    }).then((response) => {
      setFetchingConfig(false);
      setCampaignConfig(response);
      setControlGroupPercent(response?.experiment?.publishSettings?.CG ?? 3);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const variantOptions = useMemo(() => {
    return Object.keys(variants)
      .filter((name) => name !== "CG" && name !== "controlGroup")
      .map((name) => ({
        label: (
          <RowDiv center styles={{ gap: 8, marginTop: 6, marginBottom: 6 }}>
            <div
              style={{
                background: "#00BDE1",
                padding: "4px 12px",
                color: "#FFFFFF",
                borderRadius: 2,
                fontSize: 16,
                fontWeight: "bold",
              }}
            >
              {name.toUpperCase()}
            </div>
            <Typography
              color="textPrimary"
              className={typographyClasses.typographyCaption}
              style={{ letterSpacing: "0.01em" }}
            >
              {variants[name].name}
            </Typography>
          </RowDiv>
        ),
        value: name,
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variants]);

  const handlePublish = () => {
    setError(null);
    setAPIInProgress(true);
    manualVariantPublish(
      auth,
      {
        messageId: campaignConfig._id,
        isManualPublish: true,
      },
      publishSettings
    )
      .then(() => {
        setAPIInProgress(false);
        handleDone();
      })
      .catch(() => {
        setAPIInProgress(false);
        setError("Failed to publish the campaign");
      });
  };

  return (
    <ColumnDiv styles={{ padding: 16, paddingTop: 0 }}>
      <RowDiv
        center
        styles={{
          cursor: "pointer",
          display: "flex",
          flexDirection: "row",
          marginBottom: 12,
          marginTop: -10,
          marginLeft: -12,
        }}
      >
        {step === 0 && (
          <IconButton style={{ padding: 0 }} onClick={handleCancel}>
            <ChevronLeft style={{ color: "#002845" }} />
          </IconButton>
        )}
        <Typography
          style={{
            fontSize: 25,
            fontWeight: 700,
            lineHeight: "34px",
          }}
        >
          Publish as Campaign{step === 0 ? "" : "?"}
        </Typography>
      </RowDiv>
      {fetchingConfig && (
        <section className="content">
          <Loading size={36} />{" "}
        </section>
      )}
      {!fetchingConfig && step === 0 && (
        <Grid container spacing={2}>
          {/**
           * IF a user comes over from listing page expectation is winner is declared
           * aleady, hence we will not give option to select winner
           *  */}
          {!fromListing && (
            <Grid item xs={12} className={classes.gridItem}>
              <Grid container>
                <Grid item xs={4} className={classes.centerGrid}>
                  <Typography
                    className={typographyClasses.typographyCaption}
                    style={{
                      marginTop: 10,
                      letterSpacing: "0.01em",
                    }}
                  >
                    Select variant
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MultiSelect
                    single
                    clearable={false}
                    isSearchable={false}
                    options={variantOptions}
                    value={variant}
                    handleChange={(value) => {
                      setVariant(value);
                    }}
                    style={{
                      maxWidth: 250,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} className={classes.gridItem}>
            <Grid container>
              <Grid item xs={4}>
                <Typography
                  className={typographyClasses.typographyCaption}
                  style={{
                    marginTop: 10,
                    letterSpacing: "0.01em",
                  }}
                >
                  Start Date <span style={{ fontSize: 12 }}>(in IST)</span>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                  <DateTimePicker
                    className={classes.uiPicker}
                    value={moment(startDate)}
                    ampm={false}
                    onChange={(date) => {
                      const reqDate = moment(date);
                      // dispatch({
                      //   type: SET_TEST_START_DATE,
                      //   date_time: reqDate.toISOString(),
                      //   date: reqDate.format("YYYY-MM-DD"),
                      //   time: reqDate.format("HH:mm"),
                      // });
                      setStartDate(reqDate.toISOString());
                    }}
                    disablePast
                    TextFieldComponent={TextField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" style={{ padding: 5 }}>
                          <CalendarTodayIcon color="primary" />
                          <AccessTimeIcon color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <Grid container>
              <Grid item xs={4}>
                <Typography
                  className={typographyClasses.typographyCaption}
                  style={{
                    marginTop: 10,
                    letterSpacing: "0.01em",
                  }}
                >
                  End Date <span style={{ fontSize: 12 }}>(in IST)</span>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                  <DateTimePicker
                    value={moment(endDate)}
                    disablePast
                    minDate={startDate}
                    minDateMessage="End Date should not be earlier than Start Date"
                    ampm={false}
                    onChange={(date) => {
                      const reqDate = moment(date);
                      // dispatch({
                      //   type: SET_TEST_END_DATE,
                      //   date_time: reqDate.toISOString(),
                      // });
                      setEndDate(reqDate.toISOString());
                    }}
                    TextFieldComponent={TextField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" style={{ padding: 5 }}>
                          <CalendarTodayIcon color="primary" />
                          <AccessTimeIcon color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            className={classes.gridItem}
            style={{
              marginTop: showAtSpecificTime ? 0 : 9,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <RowDiv center>
              <Typography
                className={typographyClasses.typographyCaption}
                style={{ letterSpacing: "0.01em" }}
              >
                Show at specific time of the day
              </Typography>
              <ToggleSwitch
                classeName={classes.formControlLabel}
                styles={{ marginLeft: 8 }}
                checked={showAtSpecificTime}
                handleChange={(checked, e) => {
                  setShowAtSpecificTime(checked);
                }}
                style={TOGGLE_SWITCH_STYLES()}
              />
            </RowDiv>
            {showAtSpecificTime && (
              <ColumnDiv styles={{ marginTop: 16, gap: 10 }}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography
                      style={{
                        marginRight: 8,
                        letterSpacing: "0.01em",
                      }}
                      className={typographyClasses.typographyCaption}
                    >
                      between
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      moment={moment}
                    >
                      <TimePicker
                        format="hh:mm a z"
                        value={moment(startTime, "HH:mm").utc(true)}
                        onChange={(time) => {
                          setStartTime(time.format("HH:mm"));
                        }}
                        style={{ maxWidth: 155 }}
                        TextFieldComponent={TextField}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{ padding: 5 }}
                            >
                              <AccessTimeIcon color="primary" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={4}>
                    <Typography
                      className={typographyClasses.typographyCaption}
                      style={{ letterSpacing: "0.01em" }}
                    >
                      and
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      moment={moment}
                    >
                      <TimePicker
                        format="hh:mm a z"
                        value={moment(endTime, "HH:mm").utc(true)}
                        minDate={moment(startTime, "HH:mm").utc(true)}
                        onChange={(time) => {
                          setEndTime(time.format("HH:mm"));
                        }}
                        style={{ maxWidth: 155 }}
                        TextFieldComponent={TextField}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{ padding: 5 }}
                            >
                              <AccessTimeIcon color="primary" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              </ColumnDiv>
            )}
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <Grid container>
              <Grid item xs={4}>
                <Typography
                  className={typographyClasses.typographyCaption}
                  style={{
                    marginTop: 10,
                    letterSpacing: "0.01em",
                  }}
                >
                  Control group*
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <ActionsTextField
                  value={controlGroupPercent}
                  style={{
                    maxWidth: 72,
                    marginBottom: 0,
                  }}
                  InputProps={{ min: 0, max: 100 }}
                  onChange={(e) =>
                    setControlGroupPercent(Number(e.target.value))
                  }
                  type="number"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            className={classes.gridItem}
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button variant="text" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setStep(1)}
              disabled={controlGroupPercent < 0 || controlGroupPercent > 100}
            >
              Publish
            </Button>
          </Grid>
        </Grid>
      )}
      {step === 1 && (
        <Grid container style={{ gap: 15 }}>
          <Grid item xs={12}>
            <Typography
              className={typographyClasses.typographyHeading}
              style={{
                fontWeight: 500,
                letterSpacing: "unset",
                marginLeft: -10,
              }}
            >
              Are you sure that you want to publish this test as campaign?
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            className={classes.gridItem}
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button variant="text" onClick={() => setStep(0)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{
                color: "#FFF",
                backgroundColor: "#F12D2D",
              }}
              onClick={handlePublish}
            >
              {apiInProgress ? <Loading size={18} /> : "Continue"}
            </Button>
          </Grid>
        </Grid>
      )}
      {error !== null && (
        <Snackbar autoHideDuration={5000} onClose={() => setError(null)}>
          {error ?? "Error! Data couldn't be Fetched! Please try again later"}
        </Snackbar>
      )}
    </ColumnDiv>
  );
}
