import { getDistributions, makePostRequest } from "../../../common/actions";

const COUNT_API = "count";
const TIME_SERIES_API = "time-series";
const EVENT_COUNT_API = "events/count";
const EVENT_TIME_SERIES_API = "events/time-series";
const EVENTS_ATTRIBUTE_DISTRIBUTION_API = "events/distribution";
const TIME_SPENT_API = "session-length/time-series";
const NORMALIZED_TIME_SPENT_API = "normalized-session-length/time-series";

export function countAPI(
  auth,
  appId,
  queryParams,
  filters,
  enableCancellation = true
) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return "NA";
  };
  return makePostRequest(
    COUNT_API,
    auth,
    appId,
    queryParams,
    filters,
    onSuccessCallback,
    onFailureCallback,
    null,
    enableCancellation
  );
}

export function getEventAttributeDistributionAPI(
  auth,
  appId,
  queryParams,
  filters,
  isRaw = false
) {
  return getDistributions(
    EVENTS_ATTRIBUTE_DISTRIBUTION_API,
    auth,
    appId,
    queryParams,
    filters,
    isRaw
  );
}

export function getEventCountAPI(
  auth,
  appId,
  queryParams,
  filters,
  enableCancellation = true
) {
  const onSuccessCallback = (response) => {
    return response;
  };
  const onFailureCallback = (response) => {
    return 0;
  };
  return makePostRequest(
    EVENT_COUNT_API,
    auth,
    appId,
    queryParams,
    filters,
    onSuccessCallback,
    onFailureCallback,
    null,
    enableCancellation
  );
}

export function getTimeSeriesAPI(auth, appId, queryParams, filters) {
  const onSuccessCallback = (response) => {
    return response;
  };
  const onFailureCallback = (response) => {
    return [];
  };
  return makePostRequest(
    TIME_SERIES_API,
    auth,
    appId,
    queryParams,
    filters,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getEventTimeSeriesAPI(auth, appId, queryParams, filters) {
  const onSuccessCallback = (response) => {
    return response;
  };
  const onFailureCallback = (response) => {
    return [];
  };
  return makePostRequest(
    EVENT_TIME_SERIES_API,
    auth,
    appId,
    queryParams,
    filters,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getTimeSpentAPI(
  auth,
  appId,
  queryParams,
  filters,
  normalized = false,
  enableCancellation = true
) {
  const onSuccessCallback = (response) => {
    return response;
  };
  const onFailureCallback = (response) => {
    return [];
  };
  return makePostRequest(
    normalized ? NORMALIZED_TIME_SPENT_API : TIME_SPENT_API,
    auth,
    appId,
    queryParams,
    filters,
    onSuccessCallback,
    onFailureCallback,
    null,
    enableCancellation
  );
}

