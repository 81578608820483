/**
 * Created by jyothi on 12/10/17.
 */
import React, { useEffect, useState, useMemo } from "react";
import { Grid, TextField, Tooltip, Button, Divider } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import PeopleIcon from "@material-ui/icons/People";
import SaveIcon from "@material-ui/icons/Save";
import Box from "../../../../ReusableComponents/Box";
import { DASHBOARD_ENDPOINTS } from "../../../../../constants/Endpoints";
import { Link, useLocation } from "react-router-dom";
import Snackbar from "../../../../ReusableComponents/Snackbar";
import AttributeBuilder from "../../../../ReusableComponents/AttributeBuilder";
import { useAuth } from "../../../../../contexts/AuthContext";
import { useDateFilters } from "../../../common/utils";
import { Operators, ContainsOperators } from "../../../../../constants";
import EventBuilder from "../../../../ReusableComponents/EventBuilder";
import Loading from "../../../../ReusableComponents/Loading";
import Filters from "../../../common/components/Filters";
import { getEventsAPI, getAttributes } from "../../../../common/actions";
import { getSegmentCountAPI, saveSegmentAPI } from "../actions";

export default function NewSegment({ app }) {
  const location = useLocation();
  const locationState = location && location.state ? location.state : {};

  const auth = useAuth();
  const [dateFilters, setDateFilters] = useDateFilters();
  const [version, setVersion] = useState(null);

  const [userAttributes, setUserAttributes] = useState([]);
  const [sessionAttributes, setSessionAttributes] = useState([]);
  const [eventList, setEventList] = useState([]);

  const [name, setName] = useState("");
  const [condition, setCondition] = useState("AND");
  const [count, setCount] = useState(0);
  const [user, setUser] = useState(
    locationState && locationState.user && locationState.user.length > 0
      ? locationState.user
      : []
  );
  const [session, setSession] = useState(
    locationState && locationState.session && locationState.session.length > 0
      ? locationState.session
      : []
  );
  const [didEvents, setDidEvents] = useState(
    locationState && locationState.event && locationState.event.length > 0
      ? locationState.event.filter((e) => e.count.value > 0)
      : []
  );
  const [didNotEvents, setDidNotEvents] = useState(
    locationState && locationState.event && locationState.event.length > 0
      ? locationState.event.filter((e) => e.count.value === 0)
      : []
  );
  const [saveClicked, setSaveClicked] = useState(false);
  const [save_failed, setSaveFailed] = useState(false);
  const [saved, setSaved] = useState({ _id: null });
  const [fetching, setFetching] = useState(false);

  const queryParams = useMemo(() => ({ ...dateFilters, versions: version }), [
    dateFilters,
    version,
  ]);

  const onQueryChange = (selectedEvents, condition, didEvents = false) => {
    if (didEvents) {
      setDidEvents(selectedEvents);
      setCondition(condition || "AND");
    } else {
      setDidNotEvents(selectedEvents);
    }
  };

  // ComponentWillMount, Runs only once
  useEffect(() => {
    getEventsAPI(auth, app.app_id, queryParams).then((response) => {
      setEventList(response);
    });
    getAttributes(auth, app.app_id).then((response) => {
      setUserAttributes(response.user);
      setSessionAttributes(response.session);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSaveClicked(false);
    setFetching(true);

    // FIXME: Cancel the existing API call before making another
    getSegmentCountAPI(auth, app.app_id, queryParams, {
      name,
      condition,
      event: [...didEvents, ...didNotEvents],
      session,
      user,
    })
      .then((count) => {
        setCount(count);
        setFetching(false);
      })
      .catch((err) => {
        setCount(0);
        setFetching(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auth,
    app.app_id,
    queryParams,
    condition,
    didEvents,
    didNotEvents,
    session,
    user,
  ]);

  return (
    <>
      <Filters
        app={app}
        dateFilters={dateFilters}
        showVersion
        onVersionChange={(version) =>
          setVersion(version === "" ? null : version)
        }
        onDateFiltersChange={(start, end) =>
          setDateFilters({
            since: start,
            till: end,
          })
        }
      />
      <Divider style={{ marginBottom: 10 }} />
      <section className="content">
        <Box
          headerWithBorder
          footer={
            <Grid container>
              <Grid item xs={8} md={4}>
                <Typography variant="h6" style={{ fontWeight:600 }}>
                  {"Name of the Segment"}
                </Typography>
                <TextField
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter segment name"
                  margin="dense"
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4} md style={{ marginTop: 26, marginLeft: 10 }}>
                <Button
                  disabled={!(name && name.length > 0)}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setSaveClicked(true);
                    saveSegmentAPI(auth, app.app_id, queryParams, {
                      condition,
                      event: [...didEvents, ...didNotEvents],
                      name,
                      session,
                      user,
                    })
                      .then((response) => {
                        if (response) {
                          setSaved(response);
                        }
                      })
                      .catch((err) => {
                        setSaveFailed(true);
                      });
                  }}
                >
                  <SaveIcon />
                  Save
                </Button>
              </Grid>
            </Grid>
          }
          controls={
            <div style={{ display: "flex", margin: 10 }}>
              <Tooltip
                id="users in segment"
                title="Users in segment"
                placement="top"
              >
                <PeopleIcon color="primary" />
              </Tooltip>
              {!fetching && (
                <Typography variant="h6" style={{ margin: "0 10px" }}>
                  {count}
                </Typography>
              )}
              {fetching && <Loading size={24} />}
            </div>
          }
        >
          {saveClicked && saved && saved._id && (
            <Snackbar
              action={
                <Link
                  key="analyse-button"
                  to={{
                    pathname: `/apps/${app.app_id}/${DASHBOARD_ENDPOINTS.SEGMENTS}/${saved._id}`,
                    state: {
                      segmentId: saved._id,
                    },
                  }}
                >
                  <Button color="primary">Analyse</Button>
                </Link>
              }
            >
              Segment saved successfully..!
            </Snackbar>
          )}
          {saveClicked && save_failed && (
            <Snackbar>Segment saving failed..!</Snackbar>
          )}
          <Box
            title={"Users having"}
            defaultExpanded
            styles={{ border: "none", borderRadius: 0, boxShadow: "none" }}
            headerWithBorder
          >
            <Grid container spacing={0} justify="center">
              <Grid item xs={12} md={12}>
                <AttributeBuilder
                  key={"User Properties"}
                  title={"User Properties"}
                  appId={app.app_id}
                  initialValues={
                    location &&
                    locationState.user &&
                    locationState.user.length > 0
                      ? locationState.user
                      : []
                  }
                  dateFilters={dateFilters}
                  operators={Operators}
                  attributes={userAttributes}
                  handleOnChange={(attrs) => setUser(attrs)}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <AttributeBuilder
                  key={"Session Properties"}
                  title={"Session Properties"}
                  appId={app.app_id}
                  initialValues={
                    location &&
                    locationState.session &&
                    locationState.session.length > 0
                      ? locationState.session
                      : []
                  }
                  dateFilters={dateFilters}
                  operators={Operators}
                  attributes={sessionAttributes}
                  handleOnChange={(attrs) => setSession(attrs)}
                />
              </Grid>
            </Grid>
          </Box>
          <Typography variant="h6" style={{ fontWeight: "bold", margin: 15 }}>
            and
          </Typography>
          <Box
            title={"who did"}
            defaultExpanded
            styles={{ border: "none", borderRadius: 0, boxShadow: "none" }}
            headerWithBorder
          >
            <EventBuilder
              key={"Positive Events"}
              appId={app.app_id}
              events={eventList}
              selectedEvents={
                location &&
                locationState.event &&
                locationState.event.length > 0
                  ? locationState.event.filter((e) => e.count.value > 0)
                  : []
              }
              operators={ContainsOperators}
              onQueryChange={(events, condition) =>
                onQueryChange(events, condition, true)
              }
              showWhereCount={true}
              withBox={true}
            />
          </Box>
          <Typography variant="h6" style={{ fontWeight: "bold", margin: 15 }}>
            and
          </Typography>
          <Box
            title={"who didn't do"}
            defaultExpanded
            styles={{ border: "none", borderRadius: 0, boxShadow: "none" }}
            headerWithBorder
          >
            <EventBuilder
              key={"Negative Events"}
              appId={app.app_id}
              events={eventList}
              selectedEvents={
                location &&
                locationState.event &&
                locationState.event.length > 0
                  ? locationState.event.filter((e) => e.count.value === 0)
                  : []
              }
              countConfig={{ operator: "EQ", value: 0 }}
              operators={ContainsOperators}
              onQueryChange={onQueryChange}
              withBox={true}
              hideCondition={true}
            />
          </Box>
        </Box>
      </section>
    </>
  );
}
