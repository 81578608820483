import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { Typography, Tooltip, withTheme, withStyles } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";

const styles = (theme) => ({
  root: {
    color: theme.palette.secondary.main,
  },
});

function UsersList({ data = [], appId, testDevices = [], classes }) {
  return (
    <List>
      {data.map((user) => {
        const isTestDevice =
          testDevices.findIndex((device) => device.id === user.id) > -1;
        const customUserId = user.custom_user_id
          ? `${user.custom_user_id} - `
          : "";
        return (
          <ListItem
            key={user.id}
            button
            component="a"
            href={`/apps/${appId}/users/${user.id}`}
            target="_blank"
          >
            {isTestDevice ? (
              <Tooltip
                id={"test-device" + user.id}
                title="Test Device"
                placement="top"
              >
                <ListItemIcon className={classes.root}>
                  <ImportantDevicesIcon />
                </ListItemIcon>
              </Tooltip>
            ) : (
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
            )}
            <ListItemText
              primary={
                <Typography
                  variant="subtitle1"
                  noWrap
                >{`${customUserId}${user.id}`}</Typography>
              }
              secondary={
                <span>
                  {user.device} ({user.os})
                </span>
              }
            />
            <ListItemSecondaryAction>
              <Typography style={{ marginTop: 16 }} color="textSecondary">
                {/*<AccessTimeIcon />*/}
                {/*NOTE: session_length has been deprecated by server */}
                {/* <strong>{toTimeSpent(user.session_length)}</strong> in{" "} */}
                <strong>{user.session_count}</strong> sessions
              </Typography>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
}

export default withTheme(withStyles(styles)(UsersList));
