import { Grid, Typography, withStyles } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import ColumnDiv from "../../../../../../../../ReusableComponents/ColumnDiv";
import ViewIDPathSelection from "../../common/ViewIDPathSelection";
import { ACTION_CLASSES } from "../../../constants";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Carousel from "./Carousel";
import { APP_PLATFORMS } from "../../../../../../../../../constants";
import { useTrackedState } from "../../../../../../../common/components/campaigncreator/store";
import VariantCarousel from "./VariantCarousel";

const CustomTab = withStyles(theme => ({
  root: {
    color: "#002845",
    borderRadius: theme.shape.borderRadius,
    textTransform: "capitalize",
    fontSize: 16,
  },
  wrapper: {
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    alignItems: "baseline",
  },
  selected: {
    color: "white",
    backgroundColor: "#002845",
  },
  textColorInherit: {
    "&$selected": {
      color: "white",
    },
  },
  backButton: {
    color: theme.palette.text.primary,
    justifyContent: "flex-start",
    "&:hover": {
      background: "none",
    },
  },
}))(Tab);

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function setProps(index, tag = "") {
  return {
    id: `vertical-tab-${tag}${index}`,
  };
}

const CustomTabs = withStyles(theme => ({
  indicator: {
    backgroundColor: "transparent",
  },
}))(Tabs);

const ActionTypeSelector = ({
  app,
  classes,
  platform,
  isOnboardingMode,
  handleClose,
  handleDone,
  onlyInapps = false,
  currentStep = 0,
  setTemplateSelected,
  experience_type,
}) => {
  const [action, setAction] = useState(
    onlyInapps ? ACTION_CLASSES.INAPP : ACTION_CLASSES.TOOLTIP
  );
  const [step, setStep] = useState(0);
  const [value, setValue] = useState(0);
  const [selectedVariant, setSelectedVariant] = useState(-1);
  const [variantUIs, setVariantUIs] = useState([]);
  const [variantUISelector, setVariantUISelector] = useState(false);

  const state = useTrackedState();
  const {
    config: {
      _id,
      meta: { isExperiment = false },
      experiment: { variants } = {
        variants: {},
      },
    },
  } = state;

  useEffect(() => {
    setVariantUISelector(false);
  }, [action]);

  const variantKeys = useMemo(
    () =>
      Object.keys(variants || {})
        .filter((name) => name !== "controlGroup" && name !== "CG")
        .sort(),
    [variants]
  );

  const carouselHandler = (
    <Carousel
      platform={platform}
      action={action}
      handleDone={handleDone}
      setStep={setStep}
      onlyInapps={onlyInapps}
      currentStep={currentStep}
      setTemplateSelected={setTemplateSelected}
      experience_type={experience_type}
    ></Carousel>
  );

  return (
    <ColumnDiv>
      {step === 0 && (
        <ColumnDiv>
          <div style={{ minHeight: 600 }}>
            <Grid container>
              <Grid item xs={3}>
                <Typography
                  style={{
                    lineHeight: "34px",
                    letterSpacing: "0.02em",
                    color: "#002845",
                    fontSize: "25px",
                    fontWeight: "700",
                    fontStyle: "normal",
                    fontFamily: "Manrope",
                    textAlign: "left",
                    marginTop: "20px",
                    marginBottom: "15px",
                  }}
                >
                  Select Template
                </Typography>{" "}
                <CustomTabs
                  orientation="vertical"
                  value={value}
                  onChange={(_, newValue) => {
                    setValue(newValue);
                    setSelectedVariant(-1);
                  }}
                  initialSelectedIndex={value}
                >
                  <CustomTab
                    label="Tooltips"
                    {...setProps(0)}
                    onClick={() => setAction(ACTION_CLASSES.TOOLTIP)}
                    disabled={onlyInapps ? true : false}
                  />
                  <CustomTab
                    lableStyle={{ float: "left" }}
                    label="In-app Messages"
                    {...setProps(1)}
                    onClick={() => setAction(ACTION_CLASSES.INAPP)}
                  />
                  <CustomTab
                    lableStyle={{ float: "left" }}
                    label="Coachmarks"
                    {...setProps(2)}
                    onClick={() => setAction(ACTION_CLASSES.COACHMARK)}
                    disabled={onlyInapps ? true : false}
                  />
                  {platform !== APP_PLATFORMS.web && (
                    <CustomTab
                      lableStyle={{ float: "left" }}
                      label="Badges"
                      {...setProps(3)}
                      onClick={() => setAction(ACTION_CLASSES.NUDGE)}
                      disabled={onlyInapps ? true : false}
                    />
                  )}
                </CustomTabs>
                {isExperiment && (
                  <>
                    <Typography
                      style={{
                        lineHeight: "34px",
                        letterSpacing: "0.02em",
                        color: "#002845",
                        fontSize: "25px",
                        fontWeight: "700",
                        fontStyle: "normal",
                        fontFamily: "Manrope",
                        textAlign: "left",
                        marginTop: "20px",
                        marginBottom: "15px",
                      }}
                    >
                      Created Variants
                    </Typography>
                    <CustomTabs
                      orientation="vertical"
                      value={selectedVariant}
                      onChange={(_, value) => {
                        setSelectedVariant(value);
                        setValue(-1);
                      }}
                    >
                      {variantKeys.map(
                        (variantName, index) =>
                          variants[variantName].indices.length > 0 && (
                            <CustomTab
                              lableStyle={{ float: "left" }}
                              label={`${variantName.toLocaleUpperCase()} - ${
                                variants[variantName].name
                              }`}
                              {...setProps(index, "variant")}
                              onClick={() => {
                                setVariantUIs(variants[variantName].indices);
                                setVariantUISelector(true);
                              }}
                            />
                          ),
                      )}
                    </CustomTabs>
                  </>
                )}
              </Grid>
              <Grid item xs={9}>
                {selectedVariant === -1 && (
                  <>
                    <TabPanel value={value} index={0}>
                      {carouselHandler}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      {carouselHandler}
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      {carouselHandler}
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                      {carouselHandler}
                    </TabPanel>
                  </>
                )}
                {selectedVariant !== -1 && (
                  <TabPanel>
                    <VariantCarousel
                      campaignId={_id}
                      uiIndices={variantUIs}
                      handleDone={handleDone}
                      setStep={setStep}
                      variantUISelector={variantUISelector}
                    />
                  </TabPanel>
                )}
              </Grid>
            </Grid>
          </div>{" "}
        </ColumnDiv>
      )}
      {step === 1 && (
        <ViewIDPathSelection
          bundle_id={app?.basic_info?.bundle_id ?? ""}
          handleClose={() => {
            setStep(0);
          }}
          handleProceed={(data) => {
            handleDone(data);
          }}
          allowWebViewCheck={true}
        />
      )}
    </ColumnDiv>
  );
};

export default ActionTypeSelector;
