import React, { useState, useEffect, useMemo } from "react";
import { useAuth } from "../../../../contexts/AuthContext";
import { getAttributes } from "../../../common/actions";
import { Operators } from "../../../../constants";
import { Button, Divider, Grid } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AttributeBuilder from "../../../ReusableComponents/AttributeBuilder";
import Filters from "../../common/components/Filters";
import CountBox from "../../common/components/CountBox";
import { randomColorWithIndex } from "../../../../utils";
import { getUninstallDistributionAPI } from "./actions";
import { UninstallTrends, UninstallDistributions } from "./components";
import { useDateFilters } from "../../common/utils";

export default function Uninstalls({ app }) {
  const { app_id: appId } = app;

  const auth = useAuth();

  // Initial values used
  const [userAttributes, setUserAttributes] = useState([]);
  const [sessionAttributes, setSessionAttributes] = useState([]);

  // LocalState
  const [filtersApplied, setFiltersApplied] = useState(true);
  const [user, setUser] = useState([]);
  const [session, setSession] = useState([]);
  const [filters, setFilters] = useState({
    user: [],
    session: [],
    event: [],
  });
  const [cohort, setCohort] = useState(null);
  const [version, setVersion] = useState(null);
  const [dateFilters, setDateFilters] = useDateFilters();

  const queryParams = useMemo(
    () => ({
      ...dateFilters,
      cohortId: cohort,
      versions: version,
    }),
    [dateFilters, cohort, version]
  );

  useEffect(() => {
    getAttributes(auth, appId).then((response) => {
      setUserAttributes(response.user);
      setSessionAttributes(response.session);
    });
    setFiltersApplied(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onApplyButtonClicked = () => {
    setFilters({
      user: user,
      session: session,
      event: [],
    });
    setFiltersApplied(true);
  };

  return (
    <>
      <Filters
        app={app}
        showVersion
        showCohort
        dateFilters={dateFilters}
        onCohortChange={(cohort) => setCohort(cohort)}
        onVersionChange={(version) => setVersion(version)}
        onDateFiltersChange={(since, till) =>
          setDateFilters({ since: since, till: till })
        }
      />
      <Divider />
      <section className="content">
        {
          /* Counts */
          <Grid container spacing={1}>
            <Grid item md lg hidden={{ smDown: true }} />
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <CountBox
                appId={appId}
                queryParams={queryParams}
                filters={filters}
                isUserCount={true}
                subheading="Total Uninstalls"
                api={getUninstallDistributionAPI}
                icon={<ExitToAppIcon htmlColor={randomColorWithIndex(6)} />}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <CountBox
                appId={appId}
                queryParams={{ ...queryParams, start: 0, end: 0 }}
                filters={filters}
                isUserCount={false}
                subheading="Day 0 Uninstalls"
                api={getUninstallDistributionAPI}
                icon={<ExitToAppIcon htmlColor={randomColorWithIndex(8)} />}
              />
            </Grid>
          </Grid>
        }
        <Grid container spacing={1}>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <AttributeBuilder
              key={"Session Properties"}
              appId={app.app_id}
              title={"Session Properties"}
              dateFilters={dateFilters}
              operators={Operators}
              attributes={sessionAttributes}
              handleOnChange={(attrs) => {
                setSession(attrs);
                setFiltersApplied(false);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <AttributeBuilder
              key={"User Properties"}
              appId={app.app_id}
              title={"User Properties"}
              dateFilters={dateFilters}
              operators={Operators}
              attributes={userAttributes}
              handleOnChange={(attrs) => {
                setUser(attrs);
                setFiltersApplied(false);
              }}
            />
          </Grid>
        </Grid>
        <Button
          disabled={filtersApplied}
          variant="contained"
          size="small"
          color="primary"
          onClick={() => {
            setFiltersApplied(true);
            onApplyButtonClicked();
          }}
          style={{ display: "block", margin: "10px 0 10px auto" }}
        >
          Apply
        </Button>
        <UninstallTrends
          appId={appId}
          queryParams={queryParams}
          filters={filters}
        />
        <UninstallDistributions
          appId={appId}
          queryParams={queryParams}
          filters={filters}
        />
      </section>
    </>
  );
}
