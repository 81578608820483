import { makeGetRequest } from "../../../../common/actions";

export const STATIC_BASIC_INFO_API = "users/";
export const DYNAMIC_BASIC_INFO_API = "users/dynamic/";
export const SESSION_INFO_API = "session-info/";
export const CRASH_INFO_API = "crash-info/";
export const SESSION_TIME_SERIES_API = "sessions/";
export const SESSIONS_API = "session-infos";
export const USER_PROFILE_SESSION_TIMELINE_API = "session-timeline";
export const TEST_DEVICES_API = "test-devices";

export function getUserProfileSessionTimeLine(auth, appId, queryParams) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };

  return makeGetRequest(
    USER_PROFILE_SESSION_TIMELINE_API,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getUserProfileSessions(auth, appId, userId, queryParams) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };

  return makeGetRequest(
    userId + "/" + SESSIONS_API,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getUserProfileStaticBasicInfo(
  auth,
  appId,
  userId,
  queryParams
) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };

  return makeGetRequest(
    STATIC_BASIC_INFO_API + userId,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getUserProfileDynamicBasicInfo(
  auth,
  appId,
  userId,
  queryParams
) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };

  return makeGetRequest(
    DYNAMIC_BASIC_INFO_API + userId,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getUserProfileSessionInfo(auth, appId, userId, queryParams) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };

  return makeGetRequest(
    SESSION_INFO_API + userId,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getUserProfileCrashInfo(auth, appId, userId, queryParams) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };

  return makeGetRequest(
    CRASH_INFO_API + userId,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}