import { Typography } from "@material-ui/core";
import React from "react";
import ColumnDiv from "../../../../../../../../../ReusableComponents/ColumnDiv";
import ModuleDivider from "../../../../../../../../../ReusableComponents/ModuleDivider";
import RowDiv from "../../../../../../../../../ReusableComponents/RowDiv";
import TruncatedText from "../../../../../../../../../ReusableComponents/TruncatedText";
import { useSummaryStyles } from "../../../constants";
import RenderPropertyRows from "./RenderPropertyRows";

export default function TargetAudienceSummary({
  audience,
  cohorts,
  goal_event,
  overall_cfg = { events: [], toggleRetDay: false, toggleSession: false },
}) {
  const headerClasses = useSummaryStyles();

  const {
    audience_type,
    cohort_id,
    attributes: { user = [], session = [], hasUser = false, hasSes = false },
  } = audience;
  const { toggleRetDay, toggleSession } = overall_cfg;

  return (
    <ColumnDiv styles={{ marginBottom: 13 }}>
      <Typography className={headerClasses.heading}>
        {audience_type === "ALL" ? "All Users " : "Cohort "}{" "}
        {audience_type === "COHORT"
          ? ": " + cohorts.find(item => item.cohort_id === cohort_id)?.name
          : ""}
      </Typography>
      {overall_cfg.events?.length > 0 && (
        <>
          <RowDiv center applyMargin>
            <Typography className={headerClasses.heading}>
              Users who never did
            </Typography>
            <TruncatedText
              disabled
              value={overall_cfg.events.map(item => item.name).join(", ")}
            />
            <Typography className={headerClasses.body}>
              in their lifetime
            </Typography>
          </RowDiv>
        </>
      )}
      {(toggleRetDay ||
        toggleSession ||
        user.length > 0 ||
        session.length > 0 ||
        hasUser ||
        hasSes) && (
        <ColumnDiv styles={{ gap: 10 }}>
          <ModuleDivider />
          <Typography className={headerClasses.heading}>
            Advanced Filters
          </Typography>
          {(toggleRetDay || toggleSession) && (
            <RowDiv>
              <Typography className={headerClasses.heading}>
                By User Timeline
              </Typography>
              <ColumnDiv styles={{ marginLeft: 20 }}>
                {toggleRetDay && (
                  <Typography className={headerClasses.body}>
                    Retention day between {overall_cfg.ret_day.from} and{" "}
                    {overall_cfg.ret_day.to} of timeline
                  </Typography>
                )}
                {toggleSession && (
                  <Typography className={headerClasses.body}>
                    User session between {overall_cfg.session.from} and{" "}
                    {overall_cfg.session.to} of timeline
                  </Typography>
                )}
              </ColumnDiv>
            </RowDiv>
          )}
          {(hasUser || user.length > 0) && (
            <ColumnDiv>
              <Typography className={headerClasses.heading}>
                By User Properties
              </Typography>
              <RowDiv styles={{ marginLeft: 30 }}>
                <RenderPropertyRows info={user} />
              </RowDiv>
            </ColumnDiv>
          )}
          {(hasSes || session.length > 0) && (
            <ColumnDiv>
              <Typography className={headerClasses.heading}>
                By Session Properties
              </Typography>
              <RowDiv styles={{ marginLeft: 30 }}>
                <RenderPropertyRows info={session} />
              </RowDiv>
            </ColumnDiv>
          )}
        </ColumnDiv>
      )}
    </ColumnDiv>
  );
}
