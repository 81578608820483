import {
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import React, { useEffect, useMemo, useState } from "react";
import { useAuth } from "../../../../../../contexts/AuthContext";
import ColumnDiv from "../../../../../ReusableComponents/ColumnDiv";
import RowDiv from "../../../../../ReusableComponents/RowDiv";
import Autocomplete from "../../../../../ReusableComponents/Autocomplete";

import { getTestDevicesAPI } from "../../../actions";
import DownloadApxorAppContent from "../../DownloadApxorAppContent";
import {
  UPDATE_TEST_DEVICES,
  SET_DEFAULT_TEST_DEVICES,
  SET_ENABLE_TESTING,
  SET_SELECTED_TEST_VARIANT,
} from "../constants";
import { useTracked } from "../store";
import TriggerSummary from "./summary/components/configuration/components/TriggerSummary";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import MultiSelect from "../../../../../ReusableComponents/MultiSelect";
import { isExperienceDashboard } from "../../../../../../config";

const styles = (theme) => ({
  deviceListItem: {
    marginTop: 4,
    marginBottom: 4,
    borderRadius: 4,
    padding: 0,
    background: "transparent",
  },
  gutters: {
    padding: "0px !important",
  },
  backButton: {
    padding: 0,
    maxHeight: 30,
    justifyContent: "flex-start",
    color: "#002845",
    "&:hover": {
      background: "none",
    },
  },
  deviceListItem_checkBox: {
    padding: 4,
  },
  deviceListItem_text: {
    padding: "2px 12px",
    whiteSpace: "nowrap",
  },
  deviceListItem_selected: {
    backgroundColor: theme.palette.primary.almostInvisible,
    borderLeft: `2px solid ${theme.palette.primary.main}`,
  },
  listItemPrimary: {
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
});

const getDeviceName = (deviceId, testDevices) => {
  const device = testDevices.find((item) => item.id === deviceId);
  if (device) {
    return `${
      device.hasOwnProperty("nick_name") && device.nick_name !== ""
        ? device.nick_name
        : device.model
    } - ${device.id}`;
  }
  return "";
};

let intervalHandler;
const ADD_DEVICE_LABEL = "+ Add New Test Device";

function LaunchTestingDialogContent({ classes, handleDone }) {
  const auth = useAuth();
  const [state, dispatch] = useTracked();
  const {
    config: {
      conditions,
      testing: { devices = [], selectedVariant = "A" },
      meta: { isExperiment, platform },
      experiment: { variants = {} } = {},
    },
    test_devices = [],
  } = state;

  const [step, setStep] = useState(0);
  const [fetching, setFetching] = useState(false);
  const [variant, setVariant] = useState(selectedVariant);

  const testDevices = useMemo(() => test_devices, [test_devices]);

  const variantOptions = useMemo(() => {
    return Object.keys(variants)
      .filter((name) => name !== "CG" && name !== "controlGroup")
      .map((name) => ({
        label: (
          <RowDiv center styles={{ gap: 8, marginTop: 6, marginBottom: 6 }}>
            <div
              style={{
                background: "#00BDE1",
                padding: "4px 12px",
                color: "#FFFFFF",
                borderRadius: 2,
                fontSize: 16,
              }}
            >
              {name.toUpperCase()}
            </div>
            <Typography color="textPrimary" variant="h6">
              {variants[name].name}
            </Typography>
          </RowDiv>
        ),
        value: name,
      }));
  }, [variants]);

  const fetchTestDevices = () => {
    setFetching(true);
    getTestDevicesAPI(auth, auth.appId, null, isExperienceDashboard())
      .then((response) => {
        const reversed = response.reverse();
        dispatch({
          type: SET_DEFAULT_TEST_DEVICES,
          devices: reversed,
        });
        setFetching(false);
      })
      .catch(() => {
        setFetching(false);
      });
  };

  useEffect(() => {
    if (step === 1) {
      intervalHandler = setInterval(() => {
        fetchTestDevices();
      }, 5000);
    } else {
      clearInterval(intervalHandler);
      intervalHandler = null;
    }
    return () => {
      if (intervalHandler) {
        clearInterval(intervalHandler);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const clickedAddNew = (selectedDevices) => {
    return selectedDevices
      .map((eachDevice, index) => {
        return eachDevice.value === ADD_DEVICE_LABEL ? true : false;
      })
      .reduce((initialTruth, truth) => initialTruth || truth, false);
  };

  return (
    <ColumnDiv
      styles={{ margin: 30, marginTop: 0, marginBottom: 20, minHeight: 450 }}
    >
      {step === 0 && (
        <>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <RowDiv>
                <Typography
                  variant="h6"
                  style={{ fontWeight: 900, fontSize: 25 }}
                >
                  Test {!isExperiment ? "Campaign" : "Variant"}
                </Typography>
                {isExperiment && (
                  <MultiSelect
                    single
                    clearable={false}
                    isSearchable={false}
                    options={variantOptions}
                    value={variant}
                    handleChange={(value) => {
                      setVariant(value);
                      dispatch({
                        type: SET_SELECTED_TEST_VARIANT,
                        value,
                      });
                    }}
                    style={{
                      maxWidth: 250,
                      marginLeft: 8,
                    }}
                  />
                )}
                <Tooltip
                  backgroundColor="#002845"
                  color="#002845"
                  arrow
                  title="Select your test device and Validate your trigger conditions or add a new test device"
                >
                  <IconButton
                    style={{
                      padding: 1,
                      color: "#8DA2B0",
                      marginLeft: 8,
                      marginTop: 4,
                    }}
                  >
                    <HelpOutlineOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </RowDiv>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20, marginBottom: 15 }}>
              <RowDiv>
                {fetching ? (
                  <Typography
                    style={{ fontWeight: 500, fontSize: 14, color: "#FF7F33" }}
                    variant="h6"
                  >
                    Refreshing test devices ...
                  </Typography>
                ) : (
                  <Typography
                    style={{ fontWeight: 500, fontSize: 14, color: "#667A8A" }}
                    variant="h6"
                  >
                    Select your test devices
                  </Typography>
                )}
              </RowDiv>
              <Autocomplete
                multiple={true}
                minInputHeight="44px"
                noOptionsText="Select"
                open={false}
                disableClearable={false}
                options={testDevices.map((device) => ({
                  label: `${
                    device.hasOwnProperty("nick_name")
                      ? device.nick_name || device.model
                      : device.model
                  } - ${device.id}`,
                  value: device.id,
                }))}
                button={{
                  label: ADD_DEVICE_LABEL,
                  value: ADD_DEVICE_LABEL,
                  isButton: true,
                }}
                defaultValue={devices.map((deviceId, index) => {
                  return {
                    label: getDeviceName(deviceId, testDevices),
                    value: deviceId,
                  };
                })}
                handleOnChange={(selected) => {
                  if (clickedAddNew(selected)) {
                    setStep(1);
                  } else if (selected) {
                    dispatch({
                      type: UPDATE_TEST_DEVICES,
                      devices: selected.map((eachDevice) => eachDevice.value),
                    });
                  }
                }}
              />
            </Grid>
            <Typography
              style={{ fontWeight: 500, fontSize: 14, color: "#667A8A" }}
              variant="h6"
            >
              Testable conditions
            </Typography>
            <Grid
              item
              xs={12}
              style={{
                marginTop: 5,
                minHeight: 250,
                backgroundColor: "#FAFAFA",
              }}
            >
              <ColumnDiv
                styles={{
                  padding: 20,
                  border: "1px solid rgb(204, 212, 218)",
                  borderRadius: 3,
                  maxHeight: 200,
                  overflow: "scroll",
                  gap: 10,
                  minHeight: 250,
                }}
              >
                <Typography
                  variant="body1"
                  color="primary"
                  style={{
                    fontWeight: 800,
                    fontSize: 12,
                    lineHeight: "16px",
                    letterSpacing: "1px",
                  }}
                >
                  {conditions.length > 0 ? "TRIGGER:" : ""}
                </Typography>
                <TriggerSummary conditions={conditions} />
              </ColumnDiv>
            </Grid>
          </Grid>
          <RowDiv styles={{ justifyContent: "flex-end", marginTop: 40 }}>
            <Button
              variant="contained"
              color="primary"
              disabled={!devices?.length > 0}
              onClick={() => {
                dispatch({
                  type: SET_ENABLE_TESTING,
                  enabled: true,
                });
                setTimeout(() => {
                  if (handleDone) {
                    handleDone();
                  }
                }, 250);
              }}
            >
              Launch Testing
            </Button>
          </RowDiv>
        </>
      )}
      {step === 1 && (
        <>
          <IconButton
            className={classes.backButton}
            onClick={() => setStep(0)}
            style={{ width: 25 }}
          >
            <ChevronLeft />
          </IconButton>
          <DownloadApxorAppContent isTestingFlow={true} platform={platform} />
        </>
      )}
    </ColumnDiv>
  );
}

export default withStyles(styles)(LaunchTestingDialogContent);

