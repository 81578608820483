import React from "react";
import { Avatar, CardHeader, Typography } from "@material-ui/core";
import Box from "../../../../ReusableComponents/Box";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { useTrackedState } from "../store";

const iconGenerator = (config) => {
  const { icon_path, large_icon_path, large_icon_url } = config || {};
  if (icon_path || large_icon_path || large_icon_url) {
    return <Avatar src={large_icon_url} alt="Icon" />;
  } else {
    return null;
  }
};

const renderTitle = (text) => (
  <Typography style={{ fontSize: 12 }}>{text}</Typography>
);

const renderSubTitle = (text) => (
  <Typography variant="caption" style={{ fontSize: 9 }}>
    {text}
  </Typography>
);

const renderBody = (text) => (
  <Typography variant="caption" style={{ whiteSpace: "pre" }}>
    {text}
  </Typography>
);

const renderButtons = (buttons) => {
  return buttons.length > 0 ? (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {buttons.map((button) => (
        <Button size="small" key={button.name}>
          {button.name}
        </Button>
      ))}
    </div>
  ) : undefined;
};

export function Simple() {
  const state = useTrackedState();
  const { push_config } = state;
  const { payload } = push_config;
  const {
    notification_title,
    notification_body,
    buttons_config = [],
    icon_config,
  } = payload;

  return (
    <Box
      title={renderTitle(notification_title)}
      icon={iconGenerator(icon_config)}
      footer={renderButtons(buttons_config)}
    >
      {renderBody(notification_body)}
    </Box>
  );
}

export function BigText() {
  const state = useTrackedState();
  const { push_config } = state;
  const { payload } = push_config;
  const {
    notification_title = "Title",
    notification_body = "Body",
    buttons_config = [],
    icon_config,
    big_text,
  } = payload;
  const { long_text } = big_text;

  return (
    <Box
      title={
        <CardHeader
          title={renderTitle(notification_title)}
          subheader={renderSubTitle(notification_body)}
        />
      }
      icon={iconGenerator(icon_config)}
      footer={renderButtons(buttons_config)}
      collapsible
      defaultExpanded
    >
      {renderBody(long_text)}
    </Box>
  );
}

export function BigImage() {
  const state = useTrackedState();
  const { push_config } = state;
  const { payload } = push_config;
  const {
    buttons_config = [],
    icon_config,
    big_image,
    notification_body,
  } = payload;
  const { content_title = "Big Content Title", image_path, image_url } =
    big_image || {};

  return (
    <Box
      title={
        <CardHeader
          title={renderTitle(content_title)}
          subheader={renderSubTitle(notification_body)}
        />
      }
      icon={iconGenerator(icon_config)}
      collapsible
      footer={renderButtons(buttons_config)}
      defaultExpanded
    >
      <img
        src={image_url || image_path}
        alt={image_path}
        style={{ width: "100%", height: "100%" }}
      />
    </Box>
  );
}

const styles = (theme) => ({
  root: {},
  deviceText: {
    color: "#AAA",
  },
  deviceInfo: {
    position: "absolute",
    top: 620,
    textAlign: "center",
    maxWidth: 321,
    width: "100%",
  },
  controls: {
    position: "absolute",
    zIndex: 100,
    margin: "-60px 16px",
  },
});

function PushSimulator({ children }) {
  const MOBILE_IMAGE_URL = "/assets/img/mobile-mockup.png";
  const IMG_WIDTH = 321,
    IMG_HEIGHT = 640;
  return (
    <div
      style={{
        background: `url(${MOBILE_IMAGE_URL})`,
        width: IMG_WIDTH,
        height: IMG_HEIGHT,
        margin: "0 auto",
      }}
    >
      <div
        style={{ width: "100%", height: 72, padding: 15, textAlign: "right" }}
      >
        <Typography className={styles.deviceText} variant="caption">
          294 X 462
        </Typography>
      </div>
      <div
        style={{
          width: 294.2,
          height: 462,
          marginLeft: 12,
          position: "absolute",
          overflow: "hidden",
          background: "rgba(51,51,51, 0.3)",
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default withStyles(styles)(PushSimulator);
