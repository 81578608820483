import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import {
  SET_APP_ID,
  SET_AUTH_ERROR,
  SET_AUTH_LOADING,
  SET_USER,
} from "../Application/Auth/constants";
import {
  AUTH_PAGE_TYPE,
  INTEGRATION_ROLES,
} from "../Application/Auth/integration-experience/constants";
import { initial_state } from "../Application/Auth/reducer";
import { useDispatch, useTrackedState } from "../Application/Auth/store";
import { isExperienceDashboard } from "../config";
import Logger from "../utils/Logger";
import Cookie from "../utils/Storage/Cookie";
import { useDeepCompareEffect } from "../utils/use-deep-compare";

const {
  auth_error,
  auth_loading,
  user: { password, confirm_password, ...restUser },
  ...rest
} = initial_state;

const initialState = {
  ...rest,
  user: restUser,
};

const SESSION_KEY = "_apx_n2_";

const cookie = new Cookie(window, Logger);

/**
 * This function parses the cookie and returns the session object.
 * @private
 * @param {string} key The auth cookie's key or name.
 * @returns {Object} Returns the parsed session object.
 */
function extractAuthCookie(key = SESSION_KEY) {
  try {
    const sessionFromCookie =
      JSON.parse(atob(cookie.get(key) || "")) || initialState;
    return sessionFromCookie;
  } catch (err) {
    Logger.warn("Session cookie failed");
    return initialState;
  }
}

/**
 * This function removes the auth cookie.
 * @private
 * @param {string} key The auth cookie's key or name
 */
function removeAuthCookie(key = SESSION_KEY) {
  try {
    cookie.remove(key);
  } catch (err) {
    Logger.warn("Can't remove session from cookie");
  }
}

/**
 * This function sets the cookie with the session object.
 * @private
 * @param {string} key The auth cookie's key or name.
 * @param {Object} payload The session object.
 */
function updateAuthCookie(key, payload) {
  try {
    const session = btoa(JSON.stringify(payload) || "");
    cookie.set(key, session, 7, false);
  } catch (err) {
    Logger.warn("Can't persist session");
  }
}

/**
 * A custom hook to get the auth state.
 */
export function useAuth() {
  const state = useTrackedState();
  const { appId } = state;
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const queryParams = new URLSearchParams(search);

  const auth = extractAuthCookie(SESSION_KEY);

  useDeepCompareEffect(() => {
    if (
      isExperienceDashboard() &&
      cookie.get(SESSION_KEY) &&
      pathname !== "/login"
    ) {
      dispatch({
        type: SET_USER,
        payload: {
          ...state?.user,
          ...auth?.user,
        },
      });
    }
  }, [auth, dispatch, pathname, state]);

  /**
   * This function signs in the user.
   * @public @memberof {@link useAuth}
   * @param {Object} user The user object.
   */
  const login = useCallback(
    (user) => {
      const newUser = {
        ...user,
        email: user?.userId || user?.email || "",
        name: user?.name || "",
      };

      dispatch({
        type: SET_USER,
        payload: newUser,
      });

      updateAuthCookie(SESSION_KEY, {
        user: newUser,
      });
    },
    [dispatch],
  );

  /**
   * This function signs out the user.
   * @public @memberof {@link useAuth}
   */
  const logout = useCallback(() => {
    removeAuthCookie(SESSION_KEY);
    dispatch({
      type: SET_USER,
      payload: initial_state,
    });
    window.open("/login", "_self", true);
  }, [dispatch]);

  /**
   * This function sets the **appId** in the state.
   * @public @memberof {@link useAuth}
   * @param {string} appId The current app's ID.
   */
  const setAppId = useCallback(
    (appId) => {
      dispatch({
        type: SET_APP_ID,
        payload: appId,
      });
    },
    [dispatch],
  );

  /**
   * This function resets **auth_loading** and **auth_error** states.
   * @public @memberof {@link useAuth}
   * @param {Object} payload The payload to reset with, consisting of **loading** and **error**.
   */
  const resetIntermediateAuthStates = useCallback(
    ({ loading = false, error = "" }) => {
      dispatch({
        type: SET_AUTH_LOADING,
        payload: loading,
      });
      dispatch({
        type: SET_AUTH_ERROR,
        payload: error,
      });
    },
    [dispatch],
  );

  /**
   * This memoised value finds the current page type.
   * @public @memberof {@link useAuth}
   * @returns {string} Returns the current page type from the enum {@link AUTH_PAGE_TYPE}.
   */
  const pageType = useMemo(() => {
    if (isExperienceDashboard()) {
      return AUTH_PAGE_TYPE.EXPERIENCE_DASHBOARD;
    }
    if (pathname === "/sign_up") {
      if (
        queryParams.get("role") === INTEGRATION_ROLES.OWNER &&
        !!queryParams.get("name")
      ) {
        return AUTH_PAGE_TYPE.SET_PASSWORD;
      } else if (queryParams.get("role") === INTEGRATION_ROLES.DEV) {
        return AUTH_PAGE_TYPE.DEV_SIGN_UP;
      } else {
        return AUTH_PAGE_TYPE.SIGN_UP;
      }
    } else if (pathname === "/forgot-password") {
      return AUTH_PAGE_TYPE.FORGOT_PASSWORD;
    } else if (pathname === "/reset-password") {
      return AUTH_PAGE_TYPE.RESET_PASSWORD;
    } else {
      return AUTH_PAGE_TYPE.SIGN_IN;
    }
  }, [pathname, queryParams]);

  const app_id = appId;

  return {
    ...auth,
    appId,
    app_id,
    pageType,
    login,
    logout,
    setAppId,
    resetIntermediateAuthStates,
  };
}
