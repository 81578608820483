import React, { useState } from "react";
import Emoji from "./components/Emoji";
import RowDiv from "../../../../../ReusableComponents/RowDiv";
import { SURVEY_RATING_STYLE } from "./constants";
import sylized_0 from "./assets/sylized_0.svg";
import sylized_1 from "./assets/sylized_1.svg";
import sylized_2 from "./assets/sylized_2.svg";
import sylized_3 from "./assets/sylized_3.svg";
import sylized_4 from "./assets/sylized_4.svg";
import stylized_4_5 from "./assets/stylized_4_5.svg";
import sylized_5 from "./assets/sylized_5.svg";
import sylized_6 from "./assets/sylized_6.svg";
import sylized_7 from "./assets/sylized_7.svg";
import sylized_8 from "./assets/sylized_8.svg";
import sylized_9 from "./assets/sylized_9.svg";
import numbers_0 from "./assets/numbers_0.svg";
import numbers_1 from "./assets/numbers_1.svg";
import numbers_2 from "./assets/numbers_2.svg";
import numbers_3 from "./assets/numbers_3.svg";
import numbers_4 from "./assets/numbers_4.svg";
import numbers_5 from "./assets/numbers_5.svg";
import numbers_6 from "./assets/numbers_6.svg";
import numbers_7 from "./assets/numbers_7.svg";
import numbers_8 from "./assets/numbers_8.svg";
import numbers_9 from "./assets/numbers_9.svg";
import numbers_10 from "./assets/numbers_10.svg";
import numbers_5_1 from "./assets/numbers_5_1.svg";
import numbers_5_2 from "./assets/numbers_5_2.svg";
import numbers_5_3 from "./assets/numbers_5_3.svg";
import numbers_5_4 from "./assets/numbers_5_4.svg";
import numbers_5_5 from "./assets/numbers_5_5.svg";

export const EMOJI_STYLES = {
  CLASSIC_5: ["128545", "128577", "128529", "128516", "128525"],
  CLASSIC_10: [
    "128545",
    "128534",
    "128547",
    "128577",
    "128533",
    "128529",
    "128578",
    "128512",
    "128515",
    "128516",
    "128525",
  ],
  STYLIZED_5: [sylized_0, sylized_1, sylized_4, sylized_8, sylized_9],
  STYLIZED_10: [
    sylized_0,
    sylized_1,
    sylized_2,
    sylized_3,
    sylized_4,
    stylized_4_5,
    sylized_5,
    sylized_6,
    sylized_7,
    sylized_8,
    sylized_9,
  ],
  NUMBERS_5: [numbers_5_1, numbers_5_2, numbers_5_3, numbers_5_4, numbers_5_5],
  NUMBERS_10: [
    numbers_0,
    numbers_1,
    numbers_2,
    numbers_3,
    numbers_4,
    numbers_5,
    numbers_6,
    numbers_7,
    numbers_8,
    numbers_9,
    numbers_10,
  ],
};

export const GenerateEmojiRows = (
  style,
  icons,
  isCanvas = true,
  selectedIcon,
  setSelectedIcon
) => {
  const [iconSet, flag] = (() => {
    if (style === SURVEY_RATING_STYLE.CLASSIC)
      return [
        icons === 5 ? EMOJI_STYLES.CLASSIC_5 : EMOJI_STYLES.CLASSIC_10,
        true,
      ];
    else if (style === SURVEY_RATING_STYLE.STYLIZED)
      return [
        icons === 5 ? EMOJI_STYLES.STYLIZED_5 : EMOJI_STYLES.STYLIZED_10,
        false,
      ];
    else
      return [
        icons === 5 ? EMOJI_STYLES.NUMBERS_5 : EMOJI_STYLES.NUMBERS_10,
        false,
      ];
  })();

  const additionalStyles_top_row = {
    marginLeft: isCanvas ? "3px" : "2px",
    marginRight: isCanvas ? "3px" : "2px",
  };
  const additionalStyles_bottom_row = {
    marginLeft: isCanvas ? "5px" : "3px",
    marginRight: isCanvas ? "5px" : "3px",
  };
  if (icons === 5)
    return iconSet.map((each, index) => {
      const border =
        selectedIcon === index + 1
          ? {
              border: "1px solid #017DFD",
              boxSizing: "border-box",
            }
          : isCanvas || flag
          ? {}
          : { filter: "grayscale(100%)" };
      return (
        <Emoji
          code={each}
          isCanvas={isCanvas}
          index={index + 1}
          isunicodeEmoji={flag}
          styles={{ ...additionalStyles_top_row, ...border }}
          setSelectedIcon={setSelectedIcon}
        ></Emoji>
      );
    });
  else {
    return (
      <>
        <RowDiv>
          {iconSet.slice(0, 6).map((each, index) => {
            const border =
              selectedIcon === index
                ? {
                    border: "1px solid #017DFD",
                    boxSizing: "border-box",
                  }
                : isCanvas || flag
                ? {}
                : { filter: "grayscale(100%)" };
            return (
              <Emoji
                code={each}
                isCanvas={isCanvas}
                index={index}
                isunicodeEmoji={flag}
                styles={{ ...additionalStyles_top_row, ...border }}
                setSelectedIcon={setSelectedIcon}
              ></Emoji>
            );
          })}
        </RowDiv>
        <RowDiv>
          {iconSet.slice(6, iconSet.length).map((each, index) => {
            const border =
              selectedIcon === 6 + index
                ? {
                    border: "1px solid #017DFD",
                    boxSizing: "border-box",
                  }
                : isCanvas || flag
                ? {}
                : { filter: "grayscale(100%)" };
            return (
              <Emoji
                code={each}
                isCanvas={isCanvas}
                index={6 + index}
                isunicodeEmoji={flag}
                styles={{ ...additionalStyles_bottom_row, ...border }}
                setSelectedIcon={setSelectedIcon}
              ></Emoji>
            );
          })}
        </RowDiv>
      </>
    );
  }
};
