import React from "react";
import { Typography, IconButton } from "@material-ui/core";
import ColumnDiv from "../../../../../../../../ReusableComponents/ColumnDiv";
import RowDiv from "../../../../../../../../ReusableComponents/RowDiv";
import success_message_icon from "../../../assets/success_message.svg";

export default function SuccessMessageSimulator({ ui }) {
  const {
    success_message,
    success_message_text_color,
    success_message_icon_color,
  } = ui;

  return (
    <ColumnDiv styles={{ height: "100%" }}>
      <ColumnDiv styles={{ justifyContent: "center", height: "100%" }}>
        <RowDiv styles={{ justifyContent: "center" }}>
          <IconButton
            component="span"
            style={{
              background: success_message_icon_color,
              height: 50,
              width: 50,
              marginBottom: 10,
            }}
          >
            <img src={success_message_icon} alt="apxor"></img>
          </IconButton>
        </RowDiv>
        <RowDiv styles={{ justifyContent: "center" }}>
          <Typography
            style={{
              fontSize: 14,
              color: success_message_text_color,
              fontWeight: 700,
            }}
          >
            {success_message}
          </Typography>
        </RowDiv>
      </ColumnDiv>
    </ColumnDiv>
  );
}
