import { FormLabel, Grid, TextField } from "@material-ui/core";
import React from "react";
import CustomColorPicker from "../../../../../../../../../../ReusableComponents/CustomColorPicker";
import MultiSelect from "../../../../../../../../../../ReusableComponents/MultiSelect";
import { useCommonConfigStyles } from "../../../../../../../../walkthroughs/components/design/components/configuration/utils";
import {
  SET_DIALOG_BG_COLOR,
  SET_BUTTON_TYPE,
  SET_ICON_TYPE,
  SET_ICON,
  SET_TITLE_COLOR,
  SET_YES_TEXT,
  SET_YES_TEXT_COLOR,
  SET_YES_BG_COLOR,
  SET_NO_TEXT,
  SET_NO_TEXT_COLOR,
  SET_NO_BG_COLOR,
} from "../../../../../constants";

import { useTracked } from "../../../../../store";

const RequestForSurvey = () => {
  const [state, dispatch] = useTracked();

  const {
    ui: {
      dialog_config: {
        button_type = "circle",
        icon_name = null,
        icon_type = "none",
        yes_text = "Yes",
        dialog_background_color = "#FFFFFF",
        yes_button_bg_color,
        yes_button_text_color,
        no_text = "No",
        no_button_bg_color,
        no_button_text_color,
        title,
        title_color,
      } = {},
    },
  } = state;
  const classes = useCommonConfigStyles();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} className={classes.gridItem}>
        <FormLabel className={classes.formLabel}>
          Dialog Background Color
        </FormLabel>
        <CustomColorPicker
          projectHigher={true}
          color={dialog_background_color || "#FFFFFF"}
          handleColorChange={color => {
            dispatch({
              type: SET_DIALOG_BG_COLOR,
              value: color.hex,
            });
          }}
        />
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        <FormLabel className={classes.formLabel}>Button Type</FormLabel>
        <MultiSelect
          value={button_type}
          single
          clearable={false}
          options={[{ label: "Rectangle", value: "rectangle" }]}
          handleChange={value => {
            dispatch({
              type: SET_BUTTON_TYPE,
              value: value,
            });
          }}
        />
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        <FormLabel className={classes.formLabel}>Dialog Icon</FormLabel>
        <MultiSelect
          value={icon_type}
          single
          clearable={false}
          options={[
            { label: "Icon Question", value: "question" },
            { label: "Display From Assets", value: "other" },
            { label: "No Icon", value: "none" },
          ]}
          handleChange={value => {
            dispatch({
              type: SET_ICON_TYPE,
              value: value,
            });
          }}
        />
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        {icon_type === "other" && (
          <>
            <FormLabel className={classes.formLabel}>Icon Path</FormLabel>
            <TextField
              id="standard-basic"
              placeholder="Icon path from your assets folder"
              value={icon_name || ""}
              onChange={e => {
                dispatch({
                  type: SET_ICON,
                  value: e.target.value,
                });
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={6} className={classes.gridItem}>
        <FormLabel className={classes.formLabel}>Title Color</FormLabel>
        <CustomColorPicker
          projectHigher={true}
          color={title_color || "#000000"}
          handleColorChange={color => {
            dispatch({
              type: SET_TITLE_COLOR,
              value: color.hex,
            });
          }}
        />
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        <FormLabel className={classes.formLabel}>Yes Text</FormLabel>
        <TextField
          id="standard-basic"
          value={yes_text || ""}
          onChange={e => {
            dispatch({
              type: SET_YES_TEXT,
              value: e.target.value,
            });
          }}
        />
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        <FormLabel className={classes.formLabel}>Yes Text Color</FormLabel>
        <CustomColorPicker
          projectHigher={true}
          color={yes_button_text_color || "#000000"}
          handleColorChange={color => {
            dispatch({
              type: SET_YES_TEXT_COLOR,
              value: color.hex,
            });
          }}
        />
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        <FormLabel className={classes.formLabel}>
          Yes Background Color
        </FormLabel>
        <CustomColorPicker
          projectHigher={true}
          color={yes_button_bg_color || "#000000"}
          handleColorChange={color => {
            dispatch({
              type: SET_YES_BG_COLOR,
              value: color.hex,
            });
          }}
        />
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        <FormLabel className={classes.formLabel}>No Text</FormLabel>
        <TextField
          id="standard-basic"
          value={no_text || ""}
          onChange={e => {
            dispatch({
              type: SET_NO_TEXT,
              value: e.target.value,
            });
          }}
        />
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        <FormLabel className={classes.formLabel}>No Text Color</FormLabel>
        <CustomColorPicker
          projectHigher={true}
          color={no_button_text_color || "#000000"}
          handleColorChange={color => {
            dispatch({
              type: SET_NO_TEXT_COLOR,
              value: color.hex,
            });
          }}
        />
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        <FormLabel className={classes.formLabel}>No Background Color</FormLabel>
        <CustomColorPicker
          projectHigher={true}
          color={no_button_bg_color || "#000000"}
          handleColorChange={color => {
            dispatch({
              type: SET_NO_BG_COLOR,
              value: color.hex,
            });
          }}
        />
      </Grid>
    </Grid>
  );
};

export default RequestForSurvey;
