import React, { useState, useMemo } from "react";
import { Divider } from "@material-ui/core";
import "react-input-range/lib/css/index.css";
import { useDateFilters } from "../../common/utils";
import Filters from "../../common/components/Filters";
import PathComponent from "./components/PathComponent";

export default function Paths({ app }) {
  const [cohort, setCohort] = useState(null);
  const [version, setVersion] = useState(null);
  const [dateFilters, setDateFilters] = useDateFilters();

  const queryParams = useMemo(
    () => ({
      ...dateFilters,
      cohortId: cohort,
      versions: version,
    }),
    [dateFilters, cohort, version]
  );

  return (
    <>
      <Filters
        app={app}
        showVersion
        showCohort
        dateFilters={dateFilters}
        onCohortChange={(cohort) => setCohort(cohort)}
        onVersionChange={(version) => setVersion(version)}
        onDateFiltersChange={(since, till) =>
          setDateFilters({ since: since, till: till })
        }
      />
      <Divider />
      <section className="content">
        <PathComponent app={app} queryParams={queryParams} />
      </section>
    </>
  );
}
