import { makePatchRequest } from "../../../common/actions";

export function approveAppAPI(auth, appId) {
  const onSuccessCallback = (json) => {
    return json;
  };

  const onFailureCallback = (response) => {
    return {};
  };

  return makePatchRequest(
    `apps/${appId}/approve`,
    auth,
    appId,
    { approverId: auth.user.email },
    {},
    onSuccessCallback,
    onFailureCallback
  );
}
