import { Grid, InputAdornment, Tooltip, Typography } from "@material-ui/core";
import React from "react";
import ActionsTextField from "../../../../../../../ReusableComponents/ActionsTextField";
import CustomColorPicker, {
  COLOR_STYLE_ENUM,
} from "../../../../../../../ReusableComponents/CustomColorPicker";
import MultiSelect from "../../../../../../../ReusableComponents/MultiSelect";
import RowDiv from "../../../../../../../ReusableComponents/RowDiv";
import { IconImageComponent } from "../../../../../../common/utils";
import filled from "../../assets/filled.png";
import none from "../../assets/none.png";
import border_radius from "../../assets/border_radius.png";
import { APP_PLATFORMS } from "../../../../../../../../constants";

const LABEL_STYLE = {
  fontSize: 13,
  fontWeight: 400,
};

const BACKGROUND_OPTIONS = [
  {
    label: (
      <RowDiv center>
        {IconImageComponent(filled, "Filled", {
          width: 22,
          height: 22,
          marginRight: 9,
        })}
        <Typography style={LABEL_STYLE}>Filled</Typography>
      </RowDiv>
    ),
    value: "Filled",
  },
  {
    label: (
      <RowDiv center>
        {IconImageComponent(none, "None", {
          width: 22,
          height: 22,
          marginRight: 9,
        })}
        <Typography style={LABEL_STYLE}>None</Typography>
      </RowDiv>
    ),
    value: "None",
  },
];

function BackgroundToolbar({
  borderRadius = null,
  color,
  isButton = false,
  onColorChange,
  onBorderChange,
  platform,
}) {
  return (
    <Grid
      container
      spacing={1}
      style={{
        display: "flex",
        background: "#fff",
        boxShadow: "0px 4px 15px 2px rgba(0, 40, 69, 0.14)",
        borderRadius: 3,
        alignItems: "center",
        width: "auto",
      }}
    >
      {isButton && (
        <Grid item>
          <MultiSelect
            single
            clearable={false}
            isSearchable={false}
            options={BACKGROUND_OPTIONS}
            value={color === "#" ? "None" : "Filled"}
            handleChange={(value) => {
              if (value === "None") {
                onColorChange({ hex: "#" });
              } else {
                onColorChange({ hex: "#ffffff" });
              }
            }}
            style={{
              minWidth: 140,
              maxWidth: 140,
            }}
          />
        </Grid>
      )}
      {color !== "#" && (
        <Grid item>
          <CustomColorPicker
            color={color}
            showColorText={false}
            handleColorChange={(
              selectedColor,
              colorStyle = COLOR_STYLE_ENUM.SOLID
            ) => {
              if (onColorChange) {
                onColorChange(selectedColor, colorStyle);
              }
            }}
            styles={{ padding: 8 }}
            enableGradient={
              isButton && platform !== APP_PLATFORMS.web ? true : false
            }
          />
        </Grid>
      )}
      {platform !== APP_PLATFORMS.web && (
        <Grid item>
          <ActionsTextField
            key="border_radius"
            type="number"
            InputProps={{
              min: 0,
              max: 16,
              type: "number",
            }}
            value={borderRadius}
            onChange={(e) => {
              onBorderChange(Number(e.target.value), isButton);
            }}
            style={{
              marginBottom: 0,
              marginRight: 1,
            }}
            withPropertySelector={true}
            isOtherUsage={true}
            inputAdornments={{
              startAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Border Radius">
                    {IconImageComponent(border_radius, "Border Radius", {
                      width: 22,
                      height: 22,
                      marginLeft: 9,
                    })}
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default React.memo(BackgroundToolbar);