import generateShortID from "../../../../../../../../utils";
import { ACTION_CLASSES, NUDGE_LAYOUT_TYPES } from "../../constants";

const DEFAULT_NUDGE_CONFIG = {
  "name": "",
  "view_id": "",
  "activity": "",
  "bg_color": "#e40002",
  "animation": "none",
  "layout_type": "default",
  "has_ripple": false,
  "position": "right",
  "display_type": "passive_nudge",
  "delay": 0,
  "type": "IN_LINE",
};

export const getNudgeJSON = (type) => {
  const defaultJSON = JSON.parse(JSON.stringify(DEFAULT_NUDGE_CONFIG));

  //FIXME: DOES PLACING ```layout_type && _id``` CREATE AN ISSUE?! As Polarimetry did not have this type
  let json = {
    ...defaultJSON,
    action_class: ACTION_CLASSES.NUDGE,
    layout_type: type,
    _id: generateShortID()
  };

  switch (type) {
    case NUDGE_LAYOUT_TYPES.DEFAULT:
      return {
        ...json,
      };
    default:
      return json;
  }
}
