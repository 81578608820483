import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import ABSettingsSection from "./components/absettings";
import ConfigurationSection from "./components/configuration";
import GeneralSummary from "./components/general";
import SettingsSummary from "./components/settings";

const useStyles = makeStyles((theme) => ({
  gridItem: (props) => ({
    border: "1px solid #E4E7E9",
    borderRadius: "3px",
    marginBottom: 8,
    background: "#FFF",
  }),
}));

export default function SummaryPage({
  config,
  cohorts,
  designSummaryPage,
  handleSelect,
  appId = "",
  actionType = "",
  isGuidedToursTab,
}) {
  const classes = useStyles();

  const {
    at_specific_time,
    audience,
    conditions,
    goal_event,
    overall_cfg,
    meta,
    meta: { isExperiment = false, name, platform, tags = [] },
    time_limits,
    time_limits_in_day,
    validity,
    scheduling,
    frequency,
    track: { created_at, updated_at } = {},
  } = config;

  return (
    <Grid container spacing={1} style={{ padding: 8 }}>
      <Grid item xs={12} className={classes.gridItem}>
        <GeneralSummary
          handleSelect={handleSelect}
          name={name}
          platform={platform}
          tags={tags}
          createdAt={created_at}
          updatedAt={updated_at}
        />
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        {designSummaryPage &&
          React.cloneElement(designSummaryPage, {
            config,
            handleSelect,
            appId,
            actionType,
            isGuidedToursTab,
          })}
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        <ConfigurationSection
          audience={audience}
          cohorts={cohorts}
          conditions={conditions}
          goal_event={goal_event}
          handleSelect={handleSelect}
          overall_cfg={overall_cfg}
        />
      </Grid>
      {isExperiment && (
        <Grid item xs={12} className={classes.gridItem}>
          <ABSettingsSection config={config} handleSelect={handleSelect} />
        </Grid>
      )}
      <Grid item xs={12} className={classes.gridItem}>
        <SettingsSummary
          at_specific_time={at_specific_time}
          frequency={frequency}
          meta={meta}
          scheduling={scheduling}
          time_limits={time_limits}
          time_limits_in_day={time_limits_in_day}
          validity={validity}
          handleSelect={handleSelect}
          isExperiment={isExperiment}
          config={config}
        />
      </Grid>
    </Grid>
  );
}
