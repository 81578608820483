import React, { useMemo, useState } from "react";
import MultiSelect from "../../../../../ReusableComponents/MultiSelect";
import { DEFAULT_OPERATOR_OPTIONS } from "./constants";
import { getOptionsForType } from "./utils";

export default function OperatorSelector({
  selected,
  handleOnChange,
  type,
  options = [],
}) {
  const optionsArray = useMemo(
    () =>
      options.length > 0
        ? options
        : !type
        ? DEFAULT_OPERATOR_OPTIONS
        : getOptionsForType(type),
    [options, type]
  );

  const [selectedOption, setSelectedOption] = useState(
    optionsArray.findIndex((item) => item.value === selected) === -1
      ? optionsArray[0].value
      : selected
  );

  return (
    <MultiSelect
      single
      clearable={false}
      isSearchable={false}
      menuIsOpen={true}
      style={{ maxWidth: 110, minWidth: 110 }}
      value={selectedOption}
      options={optionsArray}
      handleChange={(selectedValue) => {
        setSelectedOption(selectedValue);
        handleOnChange(selectedValue);
      }}
    />
  );
}
