import {
  makeDefaultQueryString,
  withQueryStrings,
  makeDonutData,
} from "../../utils";
import { callApi } from "../../api";
import { isExperienceDashboard } from "../../config";

const GET_APPS_API = "apps";
const EVENTS_API = "events";
const ATTRIBUTES_API = "attributes";
const APP_SCREEN_NAMES_API = "screens";
const APP_ACTIVITIES_API = "activities";
const EVENT_ATTRIBUTES_API = "events/attributes";
const SEGMENTS_API = "segments";
const USER_PROFILE_SEARCH_API = "users/search";
const ATTRIBUTE_DISTRIBUTION_API = "distribution";
const CLIENT_EVENTS_API_BASE = "client-events/";

const makeGetOrDeleteRequest = (
  methodType,
  apiUrl,
  auth,
  appId,
  queryParams = null,
  successCallback = null,
  errorCallback = null,
  enableCancellation = true
) => {
  const url =
    makeDefaultQueryString(apiUrl, auth, appId) + withQueryStrings(queryParams);
  const requestConfig = {
    method: methodType || "GET",
    auth: auth,
  };

  return callApi(
    url,
    requestConfig,
    successCallback,
    errorCallback,
    null,
    enableCancellation
  );
};

const makePostOrPatchRequest = (
  methodType,
  apiUrl,
  auth,
  appId,
  queryParams = null,
  postBody = {},
  successCallback = null,
  errorCallback = null,
  customAPIRoot = null,
  enableCancellation
) => {
  const url =
    makeDefaultQueryString(apiUrl, auth, appId) + withQueryStrings(queryParams);
  const requestConfig = {
    method: methodType || "POST",
    auth: auth,
    body: JSON.stringify(postBody || {}),
  };

  return callApi(
    url,
    requestConfig,
    successCallback,
    errorCallback,
    customAPIRoot,
    enableCancellation
  );
};

export const makeGetRequest = (
  apiUrl,
  auth,
  appId,
  queryParams = null,
  successCallback = null,
  errorCallback = null,
  enableCancellation = true
) => {
  return makeGetOrDeleteRequest(
    "GET",
    apiUrl,
    auth,
    appId,
    queryParams,
    successCallback,
    errorCallback,
    enableCancellation
  );
};

export const makeDeleteRequest = (
  apiUrl,
  auth,
  appId,
  queryParams = null,
  successCallback = null,
  errorCallback = null
) => {
  return makeGetOrDeleteRequest(
    "DELETE",
    apiUrl,
    auth,
    appId,
    queryParams,
    successCallback,
    errorCallback
  );
};

export const makePostRequest = (
  apiUrl,
  auth,
  appId,
  queryParams = null,
  postBody = {},
  successCallback = null,
  errorCallback = null,
  customAPIRoot = null,
  enableCancellation
) => {
  return makePostOrPatchRequest(
    "POST",
    apiUrl,
    auth,
    appId,
    queryParams,
    postBody,
    successCallback,
    errorCallback,
    customAPIRoot,
    enableCancellation
  );
};

export const makePatchRequest = (
  apiUrl,
  auth,
  appId,
  queryParams = null,
  postBody = {},
  successCallback = null,
  errorCallback = null,
  customAPIRoot = null
) => {
  return makePostOrPatchRequest(
    "PATCH",
    apiUrl,
    auth,
    appId,
    queryParams,
    postBody,
    successCallback,
    errorCallback,
    customAPIRoot
  );
};

export const makePutRequest = (
  apiUrl,
  auth,
  appId,
  queryParams = null,
  postBody = {},
  successCallback = null,
  errorCallback = null,
  customAPIRoot = null
) => {
  return makePostOrPatchRequest(
    "PUT",
    apiUrl,
    auth,
    appId,
    queryParams,
    postBody,
    successCallback,
    errorCallback,
    customAPIRoot
  );
};

export const getApps = (auth) => {
  const onSuccessCallback = (json) => {
    return json;
  };

  const onFailureCallback = (response) => {
    return [];
  };

  return makeGetRequest(
    isExperienceDashboard() ? `demo/${GET_APPS_API}` : GET_APPS_API,
    auth,
    null,
    null,
    onSuccessCallback,
    onFailureCallback
  );
};

export function getAppDataAPI(auth, appId) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return [];
  };
  return makeGetRequest(
    GET_APPS_API + "/" + appId,
    auth,
    appId,
    null,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getEventAttributesAPI(auth, appId, queryParams) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makeGetRequest(
    EVENT_ATTRIBUTES_API,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getEventsAPI(auth, appId, queryParams) {
  const onSuccessCallback = (json) => {
    return json.sort((a, b) => a.localeCompare(b)); //default sorting on strings
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makeGetRequest(
    EVENTS_API,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getAppScreenNamesAPI(auth, appId) {
  const onSuccessCallback = (json) => {
    return json;
  };

  const onFailureCallback = (response) => {
    return {};
  };

  return makeGetRequest(
    APP_SCREEN_NAMES_API,
    auth,
    appId,
    null,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getAppActivitiesAPI(auth, appId) {
  const onSuccessCallback = (json) => {
    return json;
  };

  const onFailureCallback = (response) => {
    return {};
  };

  return makeGetRequest(
    APP_ACTIVITIES_API,
    auth,
    appId,
    null,
    onSuccessCallback,
    onFailureCallback
  );
}

const attributeValuesAPI = (attribute) => `attributes/${attribute}`;

export function getAttributeValuesAPI(auth, appId, queryParams) {
  const onSuccessCallback = (json) => {
    return json.filter((o) => o && o.length > 0);
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makeGetRequest(
    attributeValuesAPI(queryParams.attribute),
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getAttributes(auth, appId) {
  const onSuccessCallback = (json) => {
    return json || {};
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makeGetRequest(
    ATTRIBUTES_API,
    auth,
    appId,
    null,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getSegmentsAPI(auth, appId, queryParams) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makeGetRequest(
    SEGMENTS_API,
    auth,
    appId,
    null,
    onSuccessCallback,
    onFailureCallback
  );
}

export function searchUserProfiles(auth, appId, queryParams, filters) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    USER_PROFILE_SEARCH_API,
    auth,
    appId,
    queryParams,
    filters,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getDistributions(
  apiUrl,
  auth,
  appId,
  queryParams,
  filters,
  isRaw
) {
  const onSuccessCallback = (json) => {
    return isRaw ? json : makeDonutData(json);
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    apiUrl,
    auth,
    appId,
    queryParams,
    filters,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getAttributeDistributionAPI(auth, appId, queryParams, filters) {
  return getDistributions(
    ATTRIBUTE_DISTRIBUTION_API,
    auth,
    appId,
    queryParams,
    filters
  );
}

export function getClientEventsAPI(auth, appId, platform) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return [];
  };
  return makeGetRequest(
    CLIENT_EVENTS_API_BASE + appId + "/" + EVENTS_API,
    auth,
    appId,
    { platform },
    onSuccessCallback,
    onFailureCallback
  );
}
/**
 *
 * @param {*} auth
 * @param {*} appId
 * @param {event_name, platform} queryParams
 */
export function getClientEventAttributesAPI(auth, appId, queryParams) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return [];
  };
  return makeGetRequest(
    CLIENT_EVENTS_API_BASE + appId + "/" + ATTRIBUTES_API,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}
