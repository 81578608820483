import produce from "immer";
import { APP_PLATFORMS } from "../../../../../../constants";
import generateShortID, { isDefined } from "../../../../../../utils";
import { ApxEventHandler } from "../../../../common/ApxEventHandler";
import { MESSAGE_ENUM } from "../../../../common/components/campaigncreator/constants";
import { constructHTML } from "./components/configuration/utils";
import { ORIENTATION_ENUM } from "./components/simulator/utils";
import {
  UPDATE_IN_NEW_STEP,
  DELETE_STEP_FROM_CAMPAIGN,
  ADD_NEXT_STEP_TO_CAMPAIGN,
  SET_NEXT_STEP_DATA,
  MOVE_STEP,
  SET_WEB_VIEW,
  SET_WEB_VIEW_TAG,
  SET_DELAY,
  SET_BACKGROUND_COLOR,
  SET_BORDER_RADIUS,
  SET_MESSAGE_POSITION,
  SET_DIM_BACKGROUND,
  SET_DIM_BACKGROUND_OPACITY,
  SET_DIM_BACKGROUND_COLOR,
  SET_BORDER_COLOR,
  SET_IMAGE_ONLY_REDIRECTION_CONFIG,
  SET_BORDER_WIDTH,
  SET_TOOLTIP_TEXT_TYPE,
  SET_WEB_URL,
  SET_BUTTONS,
  SET_DISMISS_OUTSIDE_TOUCH,
  SET_ANIMATION_ENABLED,
  SET_WEB_HTML_DATA,
  SET_PASSIVE_NUDGE_ANIMATION_TYPE,
  SET_CURRENT_STEP,
  SET_TEXT,
  SET_COLOR,
  SET_GRADIENT,
  SET_FONT_FAMILY,
  SET_FONT_SIZE,
  SET_FONT_STYLE,
  SET_BUTTON_FONT_STYLE,
  SET_BUTTON_TEXT,
  SET_BUTTON_BORDER_RADIUS,
  SET_BUTTON_COLOR,
  SET_BUTTON_FONT_FAMILY,
  SET_BUTTON_FONT_SIZE,
  SET_BUTTON_BG_COLOR,
  SET_BUTTON_ACTION_TYPE,
  SET_BUTTON_REDIRECT_ACTIVITY,
  SET_BUTTON_REDIRECT_TYPE,
  SET_BUTTON_REDIRECT_DEEPLINK,
  BTN_ACTION_ENUM,
  DEFAULT_INTENT_ACTION,
  SET_BUTTON_IMAGE,
  SET_COACHMARK_HAS_ARROW,
  SET_COACHMARK_HAS_RIPPLE,
  SET_OPACITY,
  SET_COACHMARK_TYPE,
  SET_MODULE_TYPE,
  MODULE_TYPES,
  SET_IMAGE,
  SET_UIS_CONFIG,
  SET_ONLY_IMAGE,
  SET_TOUCH_URL,
  SET_BG_IMAGE_SIZE,
  SET_DIMMED_BACKGROUND,
  SET_INAPP_DISMISS_OUTSIDE_TOUCH,
  SET_INAPP_HTML,
  SET_INAPP_HTML_URL,
  SET_BG_IMAGE,
  SET_MAXIMUM_HEIGHT,
  SET_ONBOARDING_PAGINATION_TYPE,
  SET_ONBOARDING_DISMISS_TYPE,
  SET_ONBOARDING_LEFT_PAD,
  SET_ONBOARDING_TOP_PAD,
  SET_ONBOARDING_BOTTOM_PAD,
  SET_ONBOARDING_RIGHT_PAD,
  SET_ONBOARDING_HORIZONTAL_MARGIN,
  SET_ONBOARDING_VERTICAL_MARGIN,
  SET_ONBOARDING_ANIM_DURATION,
  SET_ONBOARDING_ANIM_TYPE,
  SET_ONBOARDING_FADEOUT_OPACITY,
  SET_ONBOARDING_DOTS_ACTIVE_COLOR,
  SET_ONBOARDING_DOTS_INACTIVE_COLOR,
  SET_ONBOARDING_SKIP_ENABLED,
  SET_ONBOARDING_NEXT_PREV_ENABLED,
  SET_ONBOARDING_BTN_TEXT,
  SET_ONBOARDING_BTN_COLOR,
  SET_ONBOARDING_BTN_FONT_FAMILY,
  SET_ONBOARDING_BTN_FONT_SIZE,
  SET_ONBOARDING_BTN_FONT_STYLE,
  SET_ONBOARDING_LAST_STEP_TEXT,
  SKIP_BUTTON_STATE,
  NEXT_BUTTON_STATE,
  PREV_BUTTON_STATE,
  INITIAL_ONBOARDING_STATE,
  SET_ONBOARDING_STATE,
  UPDATE_ONBOARDING_STATE_IN_UIS,
  ACTION_CLASSES,
  TOOLTIP_LAYOUT_TYPES,
  SET_ONBOARDING_MODE,
  SET_DYNAMIC_TEXT_CONFIG,
  SET_PREVIEW_DATA,
  SET_BORDER_BOTTOM_RADIUS,
  SET_PLATFORM,
  SET_IMG,
  SET_TREEVIEW,
  SET_LAYOUT,
  SET_ORIENTATION,
  SET_SELECTED_HTML_VIEW,
  SET_CURRENT_IMAGE,
  SET_HTML_SCRIPT_CONFIG,
  SET_VIEWID_SELECTION_DIALOG_DATA,
  DEFAULT_HTML_SCRIPT_JSON,
  REMOVE_SNIPPET_FROM_HTML_CONFIG,
  SET_BUTTON_ACTION,
  SET_HTML_ACTION_CONFIG,
  ON_COPY_STEP,
  ENABLE_CUSTOM_FONT,
  SET_CUSTOM_FONT,
  CONVERT_INAPP_TO_HTML,
  TEMPLATE_BEEN_SET,
  SET_CAROUSEL_COUNT,
  SET_CAROUSEL_IMAGES,
  UNSET_CAROUSAL_IMAGE,
  ON_UIS_CHANGE,
  SET_VIDEO_LINK,
  SET_HEIGHT,
  SET_WIDTH,
  SET_VIDEO_LOADING_TYPE,
  SET_VIDEO_HAS_AUDIO,
  SET_VIDEO_BINDING,
  SET_VIDEO_ACTIVITY,
  SET_VIDEO_CLOSE,
  SET_VIDEO_AUTO_REPLAY,
  SET_VIDEO_CAN_MINIMIZE,
  SET_VIDEO_ISFULLSCREEN,
  SET_VIDEO_MUTE_AUDIO,
  SET_VIDEO_PROGRESS_BAR,
  SET_VIDEO_BUTTON_COLOR,
  SET_EXPERIMENT_STATE,
} from "./constants";
import {
  ACTION_BUTTON_CONFIG,
  BUTTON_CONFIG,
  TEXT_CONFIG,
} from "./templates/common";
import { getNextButtonConfig, getUI as getUIFromIndex } from "./utils";

export const initial_ui_state = {
  template_been_set: false,
  uis: [],
  app_id: "",
  nextStep: {},
  currentStep: -1,
  uiIndex: -1,
  moduleType: "",
  isOnboardingMode: false,
  onboardingState: INITIAL_ONBOARDING_STATE,
  platform: APP_PLATFORMS.android,
  sseData: {
    screen: {
      orientation: ORIENTATION_ENUM.PORTRAIT,
    },
  },
  img: [],
  treeView: [],
  eventSource: {},
  currentImage: null,
  selectedHTMLView: null,
  layout: null,
  orientation: ORIENTATION_ENUM.PORTRAIT,
};

const update_onboarding_state = ({
  uis,
  onboardingState,
  isOnboardingMode,
}) => {
  if (!isOnboardingMode) {
    return uis;
  }
  uis.forEach((eachUI, index) => {
    const nextButtonConfig =
      index + 1 === uis.length
        ? getNextButtonConfig(onboardingState)
        : onboardingState.next
        ? { next: { ...onboardingState.next } }
        : {};
    // Append the entire `Onboarding State`
    Object.keys(onboardingState).forEach((key) => {
      eachUI.ui[key] = onboardingState[key];
    });

    // Append `next` button config
    eachUI.ui.next = nextButtonConfig.next;

    // If `counter` is enabled, append coutner
    if (onboardingState.has_counter) {
      eachUI.ui.counter = onboardingState.counter;
      eachUI.ui.counter.text = String(index + 1) + "/" + String(uis.length);
    }

    // Set defaults based on the template type
    if (
      eachUI.ui.action_class &&
      eachUI.ui.action_class === ACTION_CLASSES.TOOLTIP &&
      eachUI.ui.layout_type !== TOOLTIP_LAYOUT_TYPES.HTML_OVERLAY
    ) {
      eachUI.ui.layout_type = "default";
      eachUI.ui.buttons_config = [];
      eachUI.ui.dim_background = true;
    } else {
      eachUI.ui.carousal = { d_type: onboardingState.carousal.d_type };
    }

    return eachUI;
  });
};

const setFontInUI = (ui, enabled, fontValue) => {
  const value = enabled ? fontValue : "";
  ui.cFnt = value;

  if (enabled) {
    if (ui.hasOwnProperty("title_config")) {
      ui.title_config.font_family = fontValue;
    }
    if (ui.hasOwnProperty("text_config")) {
      ui.text_config.font_family = fontValue;
    }
    if (ui.hasOwnProperty("buttons_config")) {
      const { buttons_config = [] } = ui;
      buttons_config.forEach((button) => {
        button.text_config.font_family = fontValue;
      });
    }
    if (ui.hasOwnProperty("action_buttons_config")) {
      const { action_buttons_config = [] } = ui;
      action_buttons_config.forEach((actionButton) => {
        if (actionButton.hasOwnProperty("text_config")) {
          actionButton.text_config.font_family = fontValue;
        }
        if (actionButton.hasOwnProperty("description_config")) {
          actionButton.description_config.font_family = fontValue;
        }
      });
    }
  }
  return ui;
};

export const reducer = produce((draft, action) => {
  if (action.hasOwnProperty("currentStep") && !isDefined(action.currentStep)) {
    return draft;
  }

  let currentStep =
    action.currentStep !== undefined && action.currentStep !== null
      ? action.currentStep
      : draft.currentStep;
  let keyName = action.keyName;
  let index;
  let config;
  let html_config;

  const getUI = (step, uis) => {
    return getUIFromIndex(step, uis, draft.isExperiment);
  };

  const getButtonConfig = (draft, currentStep, keyName) => {
    if (draft.moduleType === "") {
      return [null, -1];
    }

    const config =
      draft.moduleType === MODULE_TYPES.BUTTON1 ||
      draft.moduleType === MODULE_TYPES.BUTTON2 ||
      draft.moduleType === MODULE_TYPES.BUTTON_TEXT1 ||
      draft.moduleType === MODULE_TYPES.BUTTON_TEXT2
        ? getUI(currentStep, draft.uis).ui.buttons_config
        : getUI(currentStep, draft.uis).ui.action_buttons_config;
    const index = config.findIndex((x) => x.__id === keyName);
    return [config, index];
  };

  const getButtonConfigByModKey = (draft, currentStep, keyName, modKey) => {
    const config = getUI(currentStep, draft.uis).ui[modKey];
    const index = config.findIndex((x) => x.__id === keyName);
    return [config, index];
  };

  switch (action.type) {
    case TEMPLATE_BEEN_SET:
      draft.template_been_set = action.value;
      break;
    case CONVERT_INAPP_TO_HTML:
      getUI(currentStep, draft.uis).ui.html_config.data = constructHTML(
        getUI(currentStep, draft.uis).ui,
        draft.platform,
        true
      );
      getUI(currentStep, draft.uis).ui.layout_type = "custom";
      break;
    case UPDATE_IN_NEW_STEP:
      getUI(currentStep, draft.uis).ui.ui = action.value;
      break;
    case DELETE_STEP_FROM_CAMPAIGN:
      if (action.isExperiment) {
        draft.uis = draft.uis.filter((eachUI, index) => {
          return eachUI.index !== action.index;
        });
      } else
        draft.uis = draft.uis.filter((eachUI, index) => {
          return index !== action.index;
        });
      // if (draft.currentStep === action.index) {
      //   draft.currentStep = 0;
      // }

      //if (draft.uis.length <= draft.currentStep) draft.currentStep = 0;
      if (draft.uis.length < 1) draft.currentStep = -1;

      // Update the onboarding state in all UIs
      update_onboarding_state(draft);
      break;
    case SET_NEXT_STEP_DATA:
      draft.nextStep = action.value;
      draft.nextStep.index = draft.uiIndex;
      if (action.blobName && action.provider) {
        draft.nextStep.blobName = action.blobName;
        draft.nextStep.provider = action.provider;
      }
      break;
    case ADD_NEXT_STEP_TO_CAMPAIGN:
      if (action.isInApp) {
        draft.uis.push(draft.nextStep);
      } else {
        draft.uis.push({
          ...draft.nextStep,
          provider: action.provider,
          blobName: action.blobName,
          index: draft.nextStep.index,
          ui: {
            ...draft.nextStep.ui,
            ...action.value,
          },
        });
      }
      draft.currentStep = action.isExperiment
        ? draft.uiIndex
        : draft.uis.length - 1;
      draft.uiIndex += 1;
      draft.nextStep = {};

      // Update the onboarding state in all UIs
      update_onboarding_state(draft);
      break;
    case MOVE_STEP:
      if (action.to === action.from) return;
      const temp = getUI(action.from, draft.uis);
      let fromIndex = action.from;
      let toIndex = action.to;
      if (action.isExperiment) {
        fromIndex = draft.uis.findIndex((ui) => ui.index === action.from);
        toIndex = draft.uis.findIndex((ui) => ui.index === action.to);
      }
      draft.uis.splice(fromIndex, 1);
      draft.uis.splice(toIndex, 0, temp);
      break;
    case ON_COPY_STEP:
      let temp_new = getUI(currentStep, draft.uis);
      temp_new.ui._id = generateShortID();
      draft.uis.push({
        ...temp_new,
        index: draft.uiIndex,
      });
      draft.uiIndex += 1;
      // update_onboarding_state(draft);
      break;
    case SET_UIS_CONFIG:
      draft.uis = [...action.uis];
      draft.app_id = action.appId;
      draft.uiIndex =
        action.uis.length > 0 ? action.uis[action.uis.length - 1].index + 1 : 0;
      break;
    case SET_WEB_VIEW:
      getUI(currentStep, draft.uis).ui.is_wv = action.value;
      getUI(currentStep, draft.uis).ui.url = action.value
        ? "https://unpkg.com/apxor-rtm-ui"
        : undefined;
      break;
    case SET_WEB_VIEW_TAG:
      getUI(currentStep, draft.uis).ui.wv_tag = action.value;
      break;
    case SET_DELAY:
      getUI(currentStep, draft.uis).ui.delay = action.value;
      break;
    case SET_BACKGROUND_COLOR:
      getUI(currentStep, draft.uis).ui.bg_color = action.value;
      break;
    case SET_BORDER_RADIUS:
      if (!getUI(currentStep, draft.uis).ui.corners)
        getUI(currentStep, draft.uis).ui.corners = {
          enabled: true,
        };
      getUI(currentStep, draft.uis).ui.corners.width = action.value;
      break;
    case SET_MESSAGE_POSITION:
      getUI(currentStep, draft.uis).ui.position = action.value;
      break;
    case SET_DIM_BACKGROUND:
      const enabled = action.value;
      getUI(currentStep, draft.uis).ui.dim_background = enabled;
      if (enabled) {
        getUI(currentStep, draft.uis).ui.dim_background_color = "#000000";
        getUI(currentStep, draft.uis).ui.dim_background_opacity = 0.8;
      } else {
        delete getUI(currentStep, draft.uis).ui.dim_background_color
        delete getUI(currentStep, draft.uis).ui.dim_background_opacity
      }
      break;
    case SET_DIM_BACKGROUND_COLOR:
      getUI(currentStep, draft.uis).ui.dim_background_color = action.value;
      break;
    case SET_DIM_BACKGROUND_OPACITY:
      getUI(currentStep, draft.uis).ui.dim_background_opacity = action.value;
      break;
    case SET_BORDER_COLOR:
      getUI(currentStep, draft.uis).ui.border.color = action.value;
      break;
    case SET_BORDER_WIDTH:
      getUI(currentStep, draft.uis).ui.border.width = action.value;
      break;
    case SET_TOOLTIP_TEXT_TYPE:
      if (action.value === 0) {
        delete getUI(currentStep, draft.uis).ui["title_config"];
      } else if (action.value === 1) {
        getUI(currentStep, draft.uis).ui.title_config = {
          ...TEXT_CONFIG,
          size: 18,
          style: "bold",
          __id: generateShortID(),
        };
      }
      getUI(currentStep, draft.uis).ui.text_config = {
        ...TEXT_CONFIG,
        size: 16,
        __id: generateShortID(),
      };
      break;
    case SET_WEB_URL:
      getUI(currentStep, draft.uis).ui.web.url = action.value;
      break;
    case SET_BUTTONS:
      const { count, key, isActionButtons } = action;
      if (count === 0) delete getUI(currentStep, draft.uis).ui[key];
      else {
        let buttons = [];
        const existingButtons = getUI(currentStep, draft.uis).ui[key] ?? [];
        if (existingButtons.length >= count)
          buttons = existingButtons.slice(0, count);
        else {
          for (let i = 0; i < count; i++) {
            buttons.push({
              ...(isActionButtons ? ACTION_BUTTON_CONFIG : BUTTON_CONFIG),
              __id: generateShortID(),
              ...(isActionButtons ? ACTION_BUTTON_CONFIG : BUTTON_CONFIG),
            });
          }
        }
        getUI(currentStep, draft.uis).ui[key] = buttons;
      }
      break;
    case SET_IMAGE_ONLY_REDIRECTION_CONFIG:
      getUI(currentStep, draft.uis).ui[action.buttonKeyName] = action.data;
      break;
    case SET_DISMISS_OUTSIDE_TOUCH:
      getUI(currentStep, draft.uis).ui.dismiss_outside_touch = action.value;
      break;
    case SET_ANIMATION_ENABLED:
      getUI(currentStep, draft.uis).ui.animation.enabled = action.value;
      break;
    case SET_WEB_HTML_DATA:
      getUI(currentStep, draft.uis).ui.web.data = action.value;
      break;
    case SET_PASSIVE_NUDGE_ANIMATION_TYPE:
      getUI(currentStep, draft.uis).ui.nudge_config = {
        type: "dot",
        anim_type: action.value,
      };
      break;
    case SET_CURRENT_STEP:
      draft.currentStep = action.value;
      break;
    case SET_TEXT:
      getUI(currentStep, draft.uis).ui[keyName].text = action.value;
      break;
    case SET_COLOR:
      getUI(currentStep, draft.uis).ui[keyName].color = action.value;
      break;
    case SET_GRADIENT:
      getUI(currentStep, draft.uis).ui[keyName].gradient_config = {
        colors: action.value,
        type: "linear",
        angle: 180,
      };
      break;
    case SET_FONT_FAMILY:
      getUI(currentStep, draft.uis).ui[keyName].font_family = action.value;
      break;
    case SET_FONT_SIZE:
      getUI(currentStep, draft.uis).ui[keyName].size = action.value;
      break;
    case SET_FONT_STYLE:
      getUI(currentStep, draft.uis).ui[keyName].style = action.value;
      break;
    case SET_BUTTON_TEXT:
      [config, index] = getButtonConfig(draft, currentStep, keyName[0]);
      if (index > -1) {
        config[index][keyName[1]].text = action.value;
      }
      break;
    case SET_BUTTON_BORDER_RADIUS:
      [config, index] = getButtonConfig(draft, currentStep, keyName[0]);
      if (index > -1) {
        config[index].borders.radius = action.value;
      }
      break;
    case SET_BUTTON_COLOR:
      [config, index] = getButtonConfig(draft, currentStep, keyName[0]);
      if (index > -1) {
        config[index][keyName[1]].color = action.value;
      }
      break;
    case SET_BUTTON_FONT_FAMILY:
      [config, index] = getButtonConfig(draft, currentStep, keyName[0]);
      if (index > -1) {
        config[index][keyName[1]].font_family = action.value;
      }
      break;
    case SET_BUTTON_FONT_SIZE:
      [config, index] = getButtonConfig(draft, currentStep, keyName[0]);
      if (index > -1) {
        config[index][keyName[1]].size = action.value;
      }
      break;
    case SET_BUTTON_FONT_STYLE:
      [config, index] = getButtonConfig(draft, currentStep, keyName[0]);
      if (index > -1) {
        config[index][keyName[1]].style = action.value;
      }
      break;
    case SET_BUTTON_BG_COLOR:
      [config, index] = getButtonConfig(draft, currentStep, keyName);
      if (index > -1) {
        if (Array.isArray(action.value)) {
          delete config[index].color;

          config[index]["gradient_config"] = {
            colors: action.value.map((eachObj) => eachObj.color),
            type: "linear",
            angle: 180,
            palette: action.value,
          };
        } else {
          config[index]["gradient_config"] &&
            delete config[index]["gradient_config"];
          config[index].color = action.value;
        }
      }

      break;
    case SET_BUTTON_ACTION_TYPE:
      [config, index] = getButtonConfig(draft, currentStep, keyName);
      if (index > -1) {
        config[index].action_config.action = action.value;
        if (action.value !== BTN_ACTION_ENUM.REDIRECT) {
          config[index].action_config.activity = "";
          config[index].action_config.uri = "";
        }
      }
      break;
    case SET_BUTTON_REDIRECT_TYPE:
      [config, index] = getButtonConfig(draft, currentStep, keyName);
      if (index > -1) {
        config[index].action_config.is_deep_link = action.value;
      }
      break;
    case SET_BUTTON_REDIRECT_ACTIVITY:
      [config, index] = getButtonConfig(draft, currentStep, keyName);
      if (index > -1) {
        config[index].action_config.activity = action.value;
      }
      break;
    case SET_BUTTON_REDIRECT_DEEPLINK:
      [config, index] = getButtonConfig(draft, currentStep, keyName);
      if (index > -1) {
        config[index].action_config.deep_link = {
          uri: action.value,
          intent_action: DEFAULT_INTENT_ACTION,
        };
      }
      break;
    case SET_BUTTON_ACTION:
      [config, index] = getButtonConfigByModKey(
        draft,
        currentStep,
        keyName,
        action.buttonKeyName
      );
      if (index > -1) {
        config[index].action_config = action.data;
      }
      break;
    case SET_BUTTON_IMAGE:
      [config, index] = getButtonConfig(draft, currentStep, keyName);
      if (index > -1) {
        config[index].image = action.value;
      }
      break;
    case SET_COACHMARK_HAS_ARROW:
      getUI(currentStep, draft.uis).ui.coach_mark_details.has_arrow =
        action.value;
      break;
    case SET_COACHMARK_HAS_RIPPLE:
      getUI(currentStep, draft.uis).ui.coach_mark_details.has_ripple =
        action.value;
      break;
    case SET_COACHMARK_TYPE:
      getUI(currentStep, draft.uis).ui.coach_mark_details.type = action.value;
      if (action.value === "circle")
        getUI(currentStep, draft.uis).ui.coach_mark_details.has_arrow = false;
      break;
    case SET_OPACITY:
      getUI(currentStep, draft.uis).ui.opacity = action.value;
      break;
    case SET_MODULE_TYPE:
      draft.moduleType = action.value;
      break;
    case SET_IMAGE:
      getUI(currentStep, draft.uis).ui.image = action.value;
      break;
    case SET_ONLY_IMAGE:
      getUI(currentStep, draft.uis).ui.only_image = action.value;
      if (!action.value) {
        getUI(currentStep, draft.uis).ui.bg_image = "";
      }
      break;
    case SET_TOUCH_URL:
      getUI(currentStep, draft.uis).ui.touch_redirect_url = action.value;
      break;
    case SET_BG_IMAGE_SIZE:
      getUI(currentStep, draft.uis).ui.image_size = action.value;
      break;
    case SET_DIMMED_BACKGROUND:
      getUI(currentStep, draft.uis).ui.window_attributes.dimmed_background =
        action.value;
      break;
    case SET_INAPP_DISMISS_OUTSIDE_TOUCH:
      getUI(currentStep, draft.uis).ui.window_attributes.dismiss_outside_touch =
        action.value;
      break;
    case SET_INAPP_HTML:
      getUI(currentStep, draft.uis).ui.html_config.data = action.value;
      break;
    case SET_INAPP_HTML_URL:
      getUI(currentStep, draft.uis).ui.html_config.url = action.value;
      break;
    case SET_MAXIMUM_HEIGHT:
      getUI(currentStep, draft.uis).ui.html_config.height = action.value;
      break;
    case SET_BG_IMAGE:
      getUI(currentStep, draft.uis).ui.bg_image = action.value;
      break;
    case SET_ONBOARDING_MODE:
      draft.isOnboardingMode = action.value;
      break;
    case SET_VIDEO_LINK:
      getUI(currentStep, draft.uis).ui.v_url = action.value;
      break;
    case SET_VIDEO_HAS_AUDIO:
      getUI(currentStep, draft.uis).ui.add_audio = action.value;
      if (!action.value) {
        getUI(currentStep, draft.uis).ui.mute_audio = true;
      }
      break;
    case SET_HEIGHT:
      getUI(currentStep, draft.uis).ui.height = action.value;
      break;
    case SET_WIDTH:
      getUI(currentStep, draft.uis).ui.width = action.value;
      break;
    case SET_VIDEO_ACTIVITY:
      getUI(currentStep, draft.uis).ui.activity = action.value;
      break;
    case SET_VIDEO_CLOSE:
      getUI(currentStep, draft.uis).ui.add_close = action.value;
      break;
    case SET_VIDEO_AUTO_REPLAY:
      getUI(currentStep, draft.uis).ui.auto_replay = action.value;
      break;
    case SET_VIDEO_CAN_MINIMIZE:
      getUI(currentStep, draft.uis).ui.can_minimize = action.value;
      break;
    case SET_VIDEO_LOADING_TYPE:
      getUI(currentStep, draft.uis).ui.download = action.value;
      break;
    case SET_VIDEO_BINDING:
      getUI(currentStep, draft.uis).ui.binding = action.value;
      if (action.value === false)
        getUI(currentStep, draft.uis).ui.activity = "";
      break;
    case SET_VIDEO_PROGRESS_BAR:
      getUI(currentStep, draft.uis).ui.progress_bar = action.value;
      break;
    case SET_VIDEO_ISFULLSCREEN:
      getUI(currentStep, draft.uis).ui.is_full_scr = action.value;
      break;
    case SET_VIDEO_MUTE_AUDIO:
      getUI(currentStep, draft.uis).ui.mute_audio = action.value;
      break;
    case SET_VIDEO_BUTTON_COLOR:
      getUI(currentStep, draft.uis).ui.x_icon_color = action.value;
      getUI(currentStep, draft.uis).ui.sound_icon_color = action.value;
      getUI(currentStep, draft.uis).ui.mini_color = action.value;
      break;

    case SET_ONBOARDING_PAGINATION_TYPE:
      let obj = {
        dots: true,
        counter: false,
      };
      switch (action.value) {
        case "number_carousal":
          obj = {
            dots: true,
            counter: true,
          };
          break;
        case "numbers":
          obj = {
            dots: false,
            counter: true,
          };
          break;
        default:
          obj = {
            dots: true,
            counter: false,
          };
          break;
      }
      draft.onboardingState.carousal.dots = obj.dots;
      draft.onboardingState.has_counter = obj.counter;
      if (!obj.counter) {
        delete draft.onboardingState.counter;
      } else if (!draft.onboardingState.counter) {
        draft.onboardingState.counter = INITIAL_ONBOARDING_STATE.counter;
      }
      break;
    case SET_ONBOARDING_DISMISS_TYPE:
      draft.onboardingState.carousal.d_type = action.value;
      break;
    case SET_ONBOARDING_TOP_PAD:
      draft.onboardingState.carousal.d_pad.top = action.value;
      break;
    case SET_ONBOARDING_BOTTOM_PAD:
      draft.onboardingState.carousal.d_pad.bottom = action.value;
      break;
    case SET_ONBOARDING_LEFT_PAD:
      draft.onboardingState.carousal.d_pad.left = action.value;
      break;
    case SET_ONBOARDING_RIGHT_PAD:
      draft.onboardingState.carousal.d_pad.right = action.value;
      break;
    case SET_ONBOARDING_HORIZONTAL_MARGIN:
      draft.onboardingState.carousal.m_margin.hz = action.value;
      break;
    case SET_ONBOARDING_VERTICAL_MARGIN:
      draft.onboardingState.carousal.m_margin.vr = action.value;
      break;
    case SET_ONBOARDING_ANIM_TYPE:
      draft.onboardingState.anim_type = action.value;
      break;
    case SET_ONBOARDING_ANIM_DURATION:
      draft.onboardingState.duration = action.value;
      break;
    case SET_ONBOARDING_FADEOUT_OPACITY:
      draft.onboardingState.carousal.f_alpha = action.value;
      break;
    case SET_ONBOARDING_DOTS_ACTIVE_COLOR:
      draft.onboardingState.carousal.d_acolor = action.value;
      break;
    case SET_ONBOARDING_DOTS_INACTIVE_COLOR:
      draft.onboardingState.carousal.d_icolor = action.value;
      break;
    case SET_ONBOARDING_SKIP_ENABLED:
      draft.onboardingState.skipEnabled = action.value;
      if (action.value && !draft.onboardingState.skip) {
        draft.onboardingState.skip = {
          ...SKIP_BUTTON_STATE.skip,
        };
      } else {
        delete draft.onboardingState.skip;
      }
      break;
    case SET_ONBOARDING_NEXT_PREV_ENABLED:
      draft.onboardingState.nextPrevEnabled = action.value;
      if (action.value) {
        if (!draft.onboardingState.next) {
          draft.onboardingState.next = {
            ...NEXT_BUTTON_STATE.next,
          };
        }
        if (!draft.onboardingState.prev) {
          draft.onboardingState.prev = {
            ...PREV_BUTTON_STATE.prev,
          };
        }
      } else {
        delete draft.onboardingState.next;
        delete draft.onboardingState.prev;
      }
      break;
    case SET_ONBOARDING_LAST_STEP_TEXT:
      draft.onboardingState.lastStepText = action.value;
      break;
    case SET_ONBOARDING_BTN_TEXT:
      draft.onboardingState[action.keyName].text = action.value;
      break;
    case SET_ONBOARDING_BTN_COLOR:
      draft.onboardingState[action.keyName].color = action.value;
      break;
    case SET_ONBOARDING_BTN_FONT_FAMILY:
      draft.onboardingState[action.keyName].font_family = action.value;
      break;
    case SET_ONBOARDING_BTN_FONT_SIZE:
      draft.onboardingState[action.keyName].size = action.value;
      break;
    case SET_ONBOARDING_BTN_FONT_STYLE:
      draft.onboardingState[action.keyName].style = action.value;
      break;
    case SET_ONBOARDING_STATE:
      draft.onboardingState = action.value || INITIAL_ONBOARDING_STATE;
      draft.isOnboardingMode = true;
      break;
    case UPDATE_ONBOARDING_STATE_IN_UIS:
      update_onboarding_state(draft);
      break;
    case SET_DYNAMIC_TEXT_CONFIG:
      const dynamicType = action.d_type;
      const isDynamic =
        dynamicType === "u" || dynamicType === "v" || dynamicType === "s";
      config = getUI(currentStep, draft.uis).ui[keyName];
      if (!isDynamic) {
        delete config.d_type;
        delete config.url;
        delete config.mvm;
        delete config.script;
      } else {
        config.d_type = dynamicType;
        if (dynamicType === "v") {
          config.mvm = action.data;
          config.url = "";
        } else if (dynamicType === "u") {
          config.url = action.data;
        } else if (dynamicType === "s") {
          config.script = {
            cmds: action.data.commands,
            vmap: action.data.variableMap,
            src: action.data.originalScript,
            t: action.data.tab,
          };
        }
      }
      config.text = action.value;
      config.is_dyn = isDynamic;
      break;
    case SET_PREVIEW_DATA:
      Object.keys(action.value).map(
        (key) => (getUI(currentStep, draft.uis).ui[key] = action.value[key])
      );
      break;
    case SET_BORDER_BOTTOM_RADIUS:
      getUI(currentStep, draft.uis).ui.border_radius = action.value;
      break;
    case SET_PLATFORM:
      draft.platform = action.value;
      break;
    case SET_CURRENT_IMAGE:
      draft.currentImage = action.value;
      break;
    case SET_IMG:
      /**
       * This Mechanism of storing images by index will not work for A/B
       *
       * Needs to be re-written to store key value pairs based on uiIndex
       */
      draft.img[currentStep] = action.value;
      break;
    case SET_LAYOUT:
      draft.layout = action.value;
      break;
    case SET_TREEVIEW:
      draft.treeView = action.value;
      break;
    case SET_ORIENTATION:
      draft.orientation = action.value;
      break;
    case SET_SELECTED_HTML_VIEW:
      draft.selectedHTMLView = action.value;
      break;
    case SET_HTML_SCRIPT_CONFIG:
      const { scriptName, tab, cmds, script, vmap } = action;
      html_config = getUI(currentStep, draft.uis).ui.html_config;
      html_config.has_scripts = true;
      if (!html_config.script) {
        html_config.script = DEFAULT_HTML_SCRIPT_JSON;
      }
      html_config.script.keys.push(scriptName);
      html_config.script[scriptName] = cmds;
      html_config.script.srcs = {
        ...html_config.script.srcs,
        [scriptName]: {
          t: tab,
          src: script,
        },
      };
      html_config.script.vmap = {
        ...html_config.script.vmap,
        ...vmap,
      };
      break;
    case REMOVE_SNIPPET_FROM_HTML_CONFIG:
      html_config = getUI(currentStep, draft.uis).ui.html_config;

      // Remove `scriptName` from `keys` array
      html_config.script.keys = html_config.script.keys.filter(
        (key) => key !== action.scriptName
      );

      // Remove `scriptName` key from `srcs` object
      html_config.script.srcs = Object.keys(html_config.script.srcs).reduce(
        (obj, key) => {
          if (key !== action.scriptName) {
            obj[key] = html_config.script.srcs[key];
          }
          return obj;
        },
        {}
      );

      // Remove `scriptName` key from `html_config.script` object
      html_config.script = Object.keys(html_config.script).reduce(
        (obj, key) => {
          if (key !== action.scriptName) {
            obj[key] = html_config.script[key];
          }
          return obj;
        },
        {}
      );

      if (html_config.script.keys.length < 1) {
        html_config.has_scripts = false;
        delete html_config.script;
      }

      break;
    case SET_HTML_ACTION_CONFIG:
      getUI(currentStep, draft.uis).ui.action_config = action.value;
      break;
    case SET_VIEWID_SELECTION_DIALOG_DATA:
      getUI(currentStep, draft.uis).ui = {
        ...getUI(currentStep, draft.uis).ui,
        ...action.options.value,
      };
      getUI(currentStep, draft.uis).provider = action.options.provider;
      getUI(currentStep, draft.uis).blobName = action.options.blobName;
      break;
    case ENABLE_CUSTOM_FONT:
      getUI(currentStep, draft.uis).ui.enableCfnt = action.value;
      getUI(currentStep, draft.uis).ui = setFontInUI(
        getUI(currentStep, draft.uis).ui,
        action.value
      );
      break;
    case SET_CUSTOM_FONT:
      getUI(currentStep, draft.uis).ui = setFontInUI(
        getUI(currentStep, draft.uis).ui,
        true,
        action.value
      );
      break;
    case SET_CAROUSEL_COUNT:
      if (getUI(currentStep, draft.uis).ui.carousel)
        getUI(currentStep, draft.uis).ui.carousel.count = action.value;
      else
        getUI(currentStep, draft.uis).ui.carousel = {
          count: action.value,
        };
      break;
    case SET_CAROUSEL_IMAGES:
      if (!!getUI(currentStep, draft.uis).ui.carousel)
        getUI(currentStep, draft.uis).ui.carousel.images
          ? (getUI(currentStep, draft.uis).ui.carousel.images[
              action.index - 1
            ] = action.value)
          : (getUI(currentStep, draft.uis).ui.carousel.images = [action.value]);
      else
        getUI(currentStep, draft.uis).ui.carousel = {
          count: 1,
          images: [action.value],
        };
      break;
    case UNSET_CAROUSAL_IMAGE:
      if (
        !!getUI(currentStep, draft.uis).ui.carousel &&
        !!getUI(currentStep, draft.uis).ui.carousel.images
      )
        getUI(currentStep, draft.uis).ui.carousel.images.pop();
      break;
    case SET_EXPERIMENT_STATE:
      draft.isExperiment = action.value;
      break;
    default:
      return draft;
  }
  //FIXME: Find a better fix for generating HTML after every inapp ui state change.
  /**
   * This code is the actual HTML generator for INAPP type config, for every config
   * property change in an Inapp step, we generate HTML and place it in html_config.data
   * of that particular step.
   *
   * getUI(currentStep, draft.uis) check is added to handle campaign deletion case
   */
  if (
    currentStep >= 0 &&
    getUI(currentStep, draft.uis) &&
    getUI(currentStep, draft.uis).type === MESSAGE_ENUM.IN_APP
  ) {
    getUI(currentStep, draft.uis).ui.html_config.data = constructHTML(
      getUI(currentStep, draft.uis).ui,
      draft.platform
    );
  }

  /**
   * Reason for 175 is because, this is inside immer callback function and it takes some time to reflect
   * in the REF that we maintain in DesignPageWithProvider component.
   *
   * So, in order to maintain that balance between state update, re-renders and auto save, we have kept this number.
   *
   * Don't jump to conclusions by looking at this :^)
   */

  // FIXME: This is kept as a random number. If we still face data update issues, have a look at this
  setTimeout(() => {
    ApxEventHandler.dispatchEvent({
      name: ON_UIS_CHANGE,
    });
  }, 175);
});
