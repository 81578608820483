import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { isExperienceDashboard } from "../../../../../../../../../../../config";
import ExpansionModuleConfig from "../../../../../../../../../../ReusableComponents/ExpansionModuleConfig";
import { APP_PLATFORMS } from "../../../../../../constants";
import { useTracked } from "../../../../../../store";
import ByAppVersion from "./components/ByAppVersion";
import BySessionAttributes from "./components/BySessionAttributes";
import ByUserAttributes from "./components/ByUserAttributes";
import ByUserTimeline from "./components/ByUserTimeline";

const useStyles = makeStyles((theme) => ({
  expansionSymmaryContent: (props) => ({
    marginLeft: "15px !important",
  }),
}));

export default function AdvancedFilters({ appId }) {
  const classes = useStyles();

  const [state] = useTracked();
  const {
    config: {
      overall_cfg: { toggleRetDay, toggleSession },
      audience: {
        hasVer = false,
        attributes: { hasSes = false, hasUser = false },
      },
      meta = {},
    },
  } = state;

  return (
    <ExpansionModuleConfig
      title="Advanced Filters"
      defaultExpanded={
        hasUser ||
        hasSes ||
        hasVer ||
        toggleRetDay ||
        toggleSession ||
        isExperienceDashboard()
      }
      iconStyles={{ color: "#002845" }}
      titleTypoStyles={{
        textTransform: "capitalize",
        fontWeight: 600,
        fontSize: 13,
        lineheight: "18px",
        letterSpacing: "0.01em",
      }}
      summaryStyles={{
        display: "flex",
        flexDirection: "row-reverse",
        borderBottom: "none",
        padding: 0,
      }}
      summaryContentClass={classes.expansionSymmaryContent}
    >
      <Grid container spacing={1}>
        {meta?.platform !== APP_PLATFORMS.ios && (
          <Grid item xs={12}>
            <ByUserTimeline />
          </Grid>
        )}
        <Grid item xs={12}>
          <ByUserAttributes appId={appId} />
        </Grid>
        <Grid item xs={12}>
          <BySessionAttributes appId={appId} />
        </Grid>
        <Grid item xs={12}>
          <ByAppVersion />
        </Grid>
      </Grid>
    </ExpansionModuleConfig>
  );
}



