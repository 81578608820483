import React from "react";
import Typography from "@material-ui/core/Typography";

export default function StatusIdentifier({ statusType, palette }) {
  return (
    <div
      style={{
        display: "inline-flex",
        borderRadius: 2,
        padding: 4,
        backgroundColor: "transparent",
        background: "transparent",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        minWidth: 100,
      }}
    >
      <div
        style={{
          width: 5,
          height: 5,
          backgroundColor: palette[statusType]?.color ?? "#d4d4d4",
          marginRight: 6,
          borderRadius: "50%",
        }}
      ></div>
      <Typography
        variant={"subtitle1"}
        style={{
          fontSize: 11,
          fontWeight: 700,
          color: palette[statusType]?.color,
          marginBottom: 1,
        }}
      >
        {palette[statusType]?.label}
      </Typography>
    </div>
  );
}
