import React from "react";
import { withStyles } from "@material-ui/core";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/ext-language_tools";
import "./apxor-rules";

const styles = () => ({
  dialogPaper: {
    minHeight: "calc(100% - 96px)",
    maxHeight: "calc(100% - 96px)",
  },
  search: {
    marginTop: 10,
    width: "100%",
  },
  results: {
    width: "100%",
    position: "relative",
    overflow: "auto",
    maxHeight: 300,
  },
});

function SnippetDialogContent({ isReadOnly, value, onChange, onKeyDown }) {
  return (
    <div
      style={{
        border: "1px solid rgba(0,40,69,0.25)",
        borderRadius: 4,
        padding: 5,
        marginBottom: 20,
      }}
      onKeyDown={onKeyDown}
    >
      <AceEditor
        value={value}
        mode="apxor"
        width="100%"
        theme="github"
        onChange={onChange}
        name="DYNAMIC_TEXT_SNIPPET"
        showPrintMargin={isReadOnly ? false : true}
        showGutter={isReadOnly ? false : true}
        highlightActiveLine={isReadOnly ? false : true}
        wrapEnabled={true}
        setOptions={{
          enableBasicAutocompletion: isReadOnly ? false : true,
          enableLiveAutocompletion: isReadOnly ? false : true,
          showLineNumbers: isReadOnly ? false : true,
          tabSize: 2,
        }}
        editorProps={{ $blockScrolling: true }}
        height={"220px"}
      />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <code>Ctrl + C to compile</code>
      </div>
    </div>
  );
}

export default withStyles(styles)(SnippetDialogContent);
