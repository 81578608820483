import React from "react";
import { Typography, Button } from "@material-ui/core";
import RowDiv from "../../../../../../../../../../ReusableComponents/RowDiv";
import CloseIcon from "@material-ui/icons/Close";

export default function ConvertToCustomHTMLDialogContent({
  handleCancel,
  handleDone,
}) {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          cursor: "pointer",
          top: 20,
          right: 20,
        }}
        onClick={handleCancel}
      >
        <CloseIcon />
      </div>

      <Typography
        variant="h4"
        style={{
          fontWeight: "bold",
          fontFamily: "Manrope",
          fontStyle: "normal",
          fontSize: "25px",
          lineHeight: "34px",
          color: "#002845",
        }}
      >
        Customize design
      </Typography>
      <Typography
        style={{
          fontFamily: "Manrope",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: 14,
          marginTop: 20,
        }}
      >
        This design will be converted to HTML, where you can customize it
        furthermore.
      </Typography>
      <div
        style={{
          marginTop: 15,
          background: "#EDF0F2",
          border: "1px solid #CCD4DA",
          boxSizing: "border-box",
          borderRadius: 2,
          height: 30,
        }}
      >
        <Typography
          style={{
            fontFamily: "Manrope",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: 12,
            letterSpacing: "0.01em",
            color: "#6D808F",
            alignItems: "center",
            margin: 5,
            marginLeft: 7,
          }}
        >
          Note: You cannot revert back to this design page state
        </Typography>
      </div>
      <RowDiv
        center
        styles={{
          justifyContent: "flex-end",
          width: "100%",
          marginTop: 50,
        }}
      >
        <Button
          style={{ marginRight: "2em", color: "black" }}
          onClick={handleCancel}
        >
          Cancel
        </Button>

        <Button
          variant={"contained"}
          style={{
            color: "#FFFFFF",
            backgroundColor: "#F12D2D",
          }}
          onClick={() => {
            handleDone();
          }}
        >
          Convert to HTML
        </Button>
      </RowDiv>
    </div>
  );
}
