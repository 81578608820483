import Apxor from "apxor"
import CE from "apxor-qe"
import ApxorRTM from "apxor-rtm"
import React, { useMemo } from "react"
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"
import Package from "../../package.json"
import { Provider as IntegrationProvider } from "../Application/IntegrationExperience/store"
import {
  DEMO_ECOMMERCE_APP_ID,
  getSiteId,
  isExperienceDashboard,
} from "../config"
import ForgotPasswordPage from "./Auth/integration-experience/pages/ForgotPasswordPage"
import LogoutPage from "./Auth/integration-experience/pages/LogoutPage"
import ResetPasswordPage from "./Auth/integration-experience/pages/ResetPasswordPage"
import SignInPage from "./Auth/integration-experience/pages/SignInPage"
import SignUpPage from "./Auth/integration-experience/pages/SignUpPage"
import { Provider as AuthProvider } from "./Auth/store"
import AuthorizedRoute from "./AuthorizedRoute"
import Error401 from "./Error/401"
import Error404 from "./Error/404"
import DesktopOnlyPage from "./Error/DesktopOnlyPage"
import InvitationRevokedPage from "./IntegrationExperience/pages/InvitationRevokedPage"

function success(data) {
  console.log("SDK Initialized: " + data.client_id)
}
function error() {
  console.log("SDK is not initialized..........")
}

Apxor.init(
  getSiteId(),
  {
    debug: true,
    plugins: ["ApxorRTM"],
    deps: [ApxorRTM, CE],
    version: Package.version,
  },
  success,
  error,
)

Apxor.setSessionProperties({ dashboard_version: Package.version })

export default function Root() {
  const isMobileDevice = useMemo(() => /Mobi/i.test(navigator.userAgent), [])

  return isMobileDevice ? (
    <DesktopOnlyPage />
  ) : (
    <AuthProvider>
      <IntegrationProvider>
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path="/invitation-revoked"
              component={InvitationRevokedPage}
            />
            <Route exact path="/">
              {isExperienceDashboard() ? (
                <Redirect to={`/apps/${DEMO_ECOMMERCE_APP_ID}/dashboard`} />
              ) : (
                <Redirect to="/apps" />
              )}
            </Route>
            <AuthorizedRoute path="/apps" />
            <Route exact path="/login" component={SignInPage} />
            <Route exact path="/sign_up" component={SignUpPage} />
            <Route
              exact
              path="/forgot-password"
              component={ForgotPasswordPage}
            />
            <Route exact path="/reset-password" component={ResetPasswordPage} />
            <AuthorizedRoute path="/profile" />
            <Route exact path="/logout" component={LogoutPage} />
            <Route path="/unauthorised" component={Error401} />
            <Route path="*" component={Error404} />
          </Switch>
        </BrowserRouter>
      </IntegrationProvider>
    </AuthProvider>
  )
}
