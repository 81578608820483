import { Button, Grid, Typography } from "@material-ui/core"
import Add from "@material-ui/icons/Add"
import LockIcon from "@material-ui/icons/Lock"
import Apxor from "apxor"
import React, { useState } from "react"
import { sortableContainer, sortableElement } from "react-sortable-hoc"
import { isExperienceDashboard } from "../../../../../../../../config"
import { APXOR_SDK_CATEGORY_CONSTANTS } from "../../../../../../../../constants/Endpoints"
import { useDeepCompareEffectNoCheck } from "../../../../../../../../utils/use-deep-compare"
import BootstrapTooltip from "../../../../../../../ReusableComponents/BootstrapTooltip"
import CustomMaterialUIDialog from "../../../../../../../ReusableComponents/CustomMaterialUIDialog"
import RowDiv from "../../../../../../../ReusableComponents/RowDiv"
import { sendEvent } from "../../../../../../common/ApxEventHandler"
import { SET_UI } from "../../../../../../common/components/campaigncreator/constants"
import { useTracked as parentTracked } from "../../../../../../common/components/campaigncreator/store"
import { EXPERIENCE_CONFIG } from "../../../../../../common/components/campaignsholder/constants"
import { IconImageComponent } from "../../../../../../common/utils"
import create_campaign from "../../assets/create_campaign.png"
import themingIcon from "../../assets/theming_icon.png"
import {
  ADD_NEXT_QUESTION_TO_SURVEY,
  ANSWER_MODES,
  MOVE_QUESTION,
  ON_EDIT_SURVEY_STEP,
  REMOVE_QUESTION,
  SET_CURRENT_STEP,
  getLogo,
} from "../../constants"
import { useTracked } from "../../store"
import SurveyPreviewDialog from "../designPreviewSection/components/SurveyPreviewDialog"
import SurveyGlobalConfig from "../surveysconfigsection/components/SurveyGlobalConfig"
import ActionTypeSelector from "./components/ActionTypeSelector"
import SurveysCard from "./components/SurveysCard"

const disableValidator = (questions, isResponseBased) => {
  let flag = false
  questions.every((eachQuestion) => {
    if (
      eachQuestion.answer_mode === ANSWER_MODES.rating &&
      eachQuestion.advanced_options &&
      isResponseBased
    ) {
      flag = true
      return false
    }
    return true
  })
  return flag
}

const SortableCard = sortableElement((props) => (
  <SurveysCard {...props} key={props.keyId} index={props.indexId} />
))

const SortableContainer = sortableContainer(({ children }) => (
  <div className="cards" style={{ display: "flex" }}>
    {children}
  </div>
))

// function appendAdditionalCards(questions, enable_dialog, show_success_message) {
//   let newQuestions = questions;
//   console.log(newQuestions);
//   if (enable_dialog) {
//     questions
//       .map(each => each.answer_mode !== ANSWER_MODES.request_survey)
//       .reduce((initial, current) => initial && current, true) &&
//       newQuestions.unshift(getDialogData().data);
//   }

//   if (show_success_message) {
//     questions
//       .map(each => each.answer_mode !== ANSWER_MODES.success_message)
//       .reduce((initial, current) => initial && current, true) &&
//       newQuestions.push(getSuccessMessageData().data);
//   }

//   return newQuestions;
// }

export default function SurveyFlowBuilder() {
  const [showAddNewQuestionDialog, setShowAddNewQuestionDialog] = useState(
    false,
  )
  const [showGlobalConfigDialog, setShowGlobalConfigDialog] = useState(false)
  const [showPreviewDialog, setShowPreviewDialog] = useState(false)
  const [updateParentState, setUpdateParentState] = useState(false)

  const [parentState, parentDispatch] = parentTracked()

  const {
    config: {
      meta: { experience_type = "" },
    },
  } = parentState

  const [state, dispatch] = useTracked()
  const {
    ui: {
      questions = [],
      is_response_based_survey,
      show_success_message,
      enable_dialog,
    },
    currentStep,
    ui,
  } = state

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (
      !(
        (enable_dialog && (oldIndex === 0 || newIndex === 0)) ||
        (show_success_message &&
          (oldIndex === questions.length - 1 ||
            newIndex === questions.length - 1))
      )
    ) {
      dispatch({
        type: SET_CURRENT_STEP,
        value: newIndex,
      })
      dispatch({
        type: MOVE_QUESTION,
        from: oldIndex,
        to: newIndex,
      })
      sendEvent(ON_EDIT_SURVEY_STEP)
    }
  }

  useDeepCompareEffectNoCheck(() => {
    parentDispatch({
      type: SET_UI,
      ui: ui,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateParentState, ui, currentStep])

  const [displayTooltip, setDisplayTooltip] = useState(false)
  const [hovered, setHovered] = useState(false)

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: 6,
          border: "1px solid #E4E7E9",
          borderRadius: 3,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: 80,
            backgroundColor: "white",
            borderTopLeftRadius: 3,
            borderBottomLeftRadius: 3,
            borderRight: "1px solid #E4E7E9",
          }}
        >
          <BootstrapTooltip
            open={
              (disableValidator(questions) && hovered) ||
              (isExperienceDashboard() &&
                [
                  EXPERIENCE_CONFIG.FEATURE_DISCOVERY,
                  EXPERIENCE_CONFIG.CART_DELETION_SURVEY,
                ].some((exp_type) => exp_type === experience_type) &&
                hovered)
            }
            placement="right"
            arrow
            title={
              isExperienceDashboard() &&
              [
                EXPERIENCE_CONFIG.FEATURE_DISCOVERY,
                EXPERIENCE_CONFIG.CART_DELETION_SURVEY,
              ].some((exp_type) => exp_type === experience_type)
                ? "Adding another step is disabled for this Cart Item Deletion Survey"
                : disableValidator(questions)
                ? "NPS with Advanced options when Response based is enabled should be the last step"
                : ""
            }
          >
            <div
              style={{ height: 80 }}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
              <Button
                color="primary"
                variant="contained"
                style={{
                  padding: 0,
                  margin: "2px 11px 11px 3px",
                  borderRadius: 2,
                  width: 58,
                  alignItems: "center",
                  top: 5,
                  left: 4,
                  height: 68,
                }}
                onClick={() => setShowAddNewQuestionDialog(true)}
                disabled={
                  disableValidator(questions, is_response_based_survey) ||
                  (isExperienceDashboard() &&
                    [
                      EXPERIENCE_CONFIG.FEATURE_DISCOVERY,
                      EXPERIENCE_CONFIG.CART_DELETION_SURVEY,
                    ].some((exp_type) => exp_type === experience_type))
                }
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Add
                    color="secondary"
                    style={{ height: 36, width: "auto" }}
                  />
                </div>
              </Button>
            </div>
          </BootstrapTooltip>
        </div>
        <Grid
          container
          style={{
            backgroundColor: "#FFFFFF",
            overflow: "hidden",
            borderRadius: 3,
            border: "1px rgba(0, 46, 69, 0.57)",
          }}
          spacing={0}
        >
          <Grid item xs={11}>
            <div
              style={{
                display: "flex",
                overflowX: "auto",
                overflowY: "hidden",
                height: "100%",
              }}
            >
              {Array.isArray(questions) && (
                <SortableContainer onSortEnd={onSortEnd} axis="x" distance={1}>
                  {questions.map((item, index) => (
                    <SortableCard
                      key={"key_card_" + index}
                      keyId={"key_card_" + index}
                      data={item}
                      actions={{
                        DELETE_STEP_FROM_CAMPAIGN: REMOVE_QUESTION,
                        MOVE_STEP_LEFT: MOVE_QUESTION,
                        MOVE_STEP_RIGHT: MOVE_QUESTION,
                        ON_EDIT_STEP: "ON_EDIT_STEP",
                        ON_DELETE_STEP: "ON_DELETE_STEP",
                        ON_COPY_STEP: "ON_COPY_STEP",
                        SET_CURRENT_STEP,
                      }}
                      index={index}
                      indexId={index}
                      isLast={index === questions.length - 1}
                      totalSteps={questions.length}
                      editable={true}
                      getLogo={() => getLogo(item.answer_mode)}
                      selected={currentStep === index}
                      dispatch={dispatch}
                      currentStep={currentStep}
                      parentDispatch={parentDispatch}
                      stepName="QUESTION"
                      disableCopy={
                        item.answer_mode === ANSWER_MODES.request_survey ||
                        item.answer_mode === ANSWER_MODES.success_message ||
                        (isExperienceDashboard() &&
                          [
                            EXPERIENCE_CONFIG.FEATURE_DISCOVERY,
                            EXPERIENCE_CONFIG.CART_DELETION_SURVEY,
                          ].some((exp_type) => exp_type === experience_type))
                      }
                    />
                  ))}
                </SortableContainer>
              )}
            </div>
          </Grid>
          <Grid
            item
            xs={1}
            style={{
              position: "relative",
              padding: 0,
              borderLeft: "1px solid #E4E7E9",
            }}
          >
            {isExperienceDashboard() && (
              <BootstrapTooltip
                open={isExperienceDashboard() && displayTooltip}
                title={
                  <>
                    <b>Theming Feature</b> is disabled for surveys in the demo
                    app
                  </>
                }
                placement="top"
              >
                <LockIcon
                  fontSize="small"
                  color="primary"
                  style={{
                    position: "absolute",
                    zIndex: "10",
                    right: "4px",
                    top: "4px",
                  }}
                />
              </BootstrapTooltip>
            )}
            <Button
              onMouseEnter={() => setDisplayTooltip(true)}
              onMouseLeave={() => setDisplayTooltip(false)}
              style={{
                opacity: isExperienceDashboard() ? 0.5 : 1,
                backgroundColor: "white",
                borderRadius: 3,
                height: "100%",
                width: "100%",
              }}
              onClick={() => setShowGlobalConfigDialog(true)}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  style={{ width: "26px", height: "21px" }}
                  src={themingIcon}
                  alt="Apxor"
                />
                <Typography
                  color="textPrimary"
                  style={{
                    fontWeight: 600,
                    textTransform: "capitalize",
                    size: 12,
                    marginTop: 5,
                  }}
                >
                  Theming
                </Typography>
              </div>
            </Button>
          </Grid>
        </Grid>

        <CustomMaterialUIDialog
          dialogContent={
            <ActionTypeSelector
              experience_type={experience_type}
              handleClose={() => setShowAddNewQuestionDialog(false)}
              handleDone={(action) => {
                setShowAddNewQuestionDialog(false)
                dispatch({
                  type: ADD_NEXT_QUESTION_TO_SURVEY,
                })
              }}
            />
          }
          maxWidth={"md"}
          openFlag={showAddNewQuestionDialog}
          onClose={() => setShowAddNewQuestionDialog(false)}
          onDialogClose={() => setShowAddNewQuestionDialog(false)}
        />

        <CustomMaterialUIDialog
          dialogActions={
            <Button
              onClick={() => {
                setShowGlobalConfigDialog(false)
                setUpdateParentState(!updateParentState)
              }}
              color="primary"
            >
              Done
            </Button>
          }
          dialogActionStyles={{
            borderRadius: 3,
            boxShadow: "0px 0px 10px 1px rgba(1, 1, 1, 0.6)",
          }}
          dialogProps={{
            disableBackdropClick: true,
            disableEscapeKeyDown: true,
          }}
          maxWidth="md"
          dialogContent={<SurveyGlobalConfig />}
          dialogContentStyles={{ margin: 0 }}
          title={"Survey Global Config"}
          openFlag={showGlobalConfigDialog}
          onClose={() => {
            setShowGlobalConfigDialog(false)
            setUpdateParentState(!updateParentState)
          }}
          onDialogClose={() => {
            setShowGlobalConfigDialog(false)
            setUpdateParentState(!updateParentState)
          }}
        />
        <CustomMaterialUIDialog
          dialogActions={
            <Button
              onClick={() => {
                setShowPreviewDialog(false)
              }}
              color="primary"
            >
              Done
            </Button>
          }
          dialogActionStyles={{
            borderRadius: 3,
            boxShadow: "0px 0px 10px 1px rgba(1, 1, 1, 0.6)",
          }}
          dialogProps={{
            disableBackdropClick: true,
            disableEscapeKeyDown: true,
          }}
          maxWidth="sm"
          dialogContent={<SurveyPreviewDialog />}
          dialogContentStyles={{ padding: 0, margin: 0 }}
          title={"Survey Preview"}
          openFlag={showPreviewDialog}
          onClose={() => {
            setShowPreviewDialog(false)
          }}
          onDialogClose={() => setShowPreviewDialog(false)}
        />
      </div>
      {
        questions.length === 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: 8,
              background: "#FFF",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #E4E7E9",
              borderRadius: 3,
              minHeight: 595,
            }}
          >
            <Typography
              color="textPrimary"
              style={{ marginBottom: 24, fontWeight: 500 }}
              variant="h4"
            >
              Start designing your survey
            </Typography>
            <Button
              id="add-survey-button"
              onClick={() => {
                Apxor.logEvent(
                  "SurveysTemplateSelectorLaunched",
                  { "experience_type": experience_type },
                  APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS,
                )
                setShowAddNewQuestionDialog(true)
              }}
              style={{ minWidth: 150, marginBottom: 50 }}
            >
              <RowDiv>
                <Button
                  color="primary"
                  variant="contained"
                  style={{
                    borderRadius: 2,
                    minWidth: 27,
                    width: 27,
                    alignItems: "center",
                    height: 30,
                  }}
                >
                  <Add
                    color="secondary"
                    style={{
                      height: 20,
                      width: "auto",
                    }}
                  />
                </Button>
                <Typography
                  color="primary"
                  style={{
                    marginLeft: 10,
                    fontSize: 20,
                    fontWeight: 500,
                  }}
                >
                  Add Template
                </Typography>
              </RowDiv>
            </Button>
            {IconImageComponent(create_campaign, "create_campaign", {
              height: "unset !important",
              width: "unset !important",
            })}
          </div>
        )
      }
    </>
  )
}
