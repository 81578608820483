import React, { useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { useTracked } from "../../../store";
import CustomMaterialUIDialog from "../../../../../../../../ReusableComponents/CustomMaterialUIDialog";
import ModuleDivider from "../../../../../../../../ReusableComponents/ModuleDivider";
import { useCommonConfigStyles } from "../../../../../../walkthroughs/components/design/components/configuration/utils";
import ToggleSwitch from "../../../../../../../../ReusableComponents/ToggleSwitch";
import { TOGGLE_SWITCH_STYLES } from "../../../../../../../common/components/campaigncreator/constants";
import MultiSelect from "../../../../../../../../ReusableComponents/MultiSelect";
import {
  ENABLE_DIM_BACKGROUND,
  SET_SURVEY_ORIENTATION,
  SURVEY_ORIENTATION,
  SET_DELAY,
  SET_RESPONSE_BASED_SURVEY,
} from "../../../constants";
import { capitalizeEachWord } from "../../../../../../../../../utils";
import ColumnDiv from "../../../../../../../../ReusableComponents/ColumnDiv";

const styles = theme => ({
  formLabel: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: "700 !important",
    marginBottom: 5,
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
  },
});

function GeneralConfiguration() {
  const [state, dispatch] = useTracked();
  const {
    ui: {
      delay = 1000,
      enable_dialog = false,
      is_response_based_survey,
      enable_dim_bg,
      layout_type = "",
      questions = [],
    },
    currentStep,
  } = state;

  const { answer_mode } = questions[currentStep];

  const [showOrientationPopup, setShowOrientationPopup] = useState(false);
  const [orientationBuffer, setOrientationBuffer] = useState("");
  const classes = useCommonConfigStyles();

  return (
    <>
      <Grid item xs={12} className={classes.gridItem}>
        <Typography className={classes.typographySubtitle2} variant="subtitle2">
          ORIENTATION
        </Typography>
        <MultiSelect
          style={{ maxWidth: 145, minWidth: 145 }}
          single
          clearable={false}
          isSearchable={false}
          options={Object.keys(SURVEY_ORIENTATION).map(actionType => ({
            value: SURVEY_ORIENTATION[actionType],
            label: capitalizeEachWord(SURVEY_ORIENTATION[actionType]),
          }))}
          value={layout_type.toUpperCase()}
          handleChange={value => {
            setOrientationBuffer(value);
            setShowOrientationPopup(true);
          }}
        />
      </Grid>
      <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
      <Grid item xs={12} className={classes.gridItem}>
        <Typography className={classes.typographySubtitle2} variant="subtitle2">
          DELAY
        </Typography>
        <TextField
          value={delay / 1000}
          style={{ maxWidth: 72 }}
          inputProps={{ min: 0 }}
          type={"number"}
          onChange={e => {
            const value = Number(e.target.value);
            dispatch({
              type: SET_DELAY,
              value: value * 1000,
            });
          }}
        />
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        {/* <Grid item xs={6} className={classes.gridItem}>
          <FormControlLabel
            label="Response Based Survey"
            control={
              <Checkbox
                color="primary"
                checked={is_response_based_survey || false}
                onChange={(e) => {
                  dispatch({
                    type: SET_RESPONSE_BASED_SURVEY,
                    value: e.target.checked,
                  });
                }}
                disableRipple
              />
            }
          />
        </Grid> */}
      </Grid>
      <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
      <Grid item xs={12} className={classes.gridItem}>
        <Typography className={classes.typographySubtitle2} variant="subtitle2">
          DIM BACKGROUND
        </Typography>
        <ToggleSwitch
          className={classes.formControlLabel}
          checked={enable_dim_bg}
          handleChange={(checked, e) => {
            dispatch({
              type: ENABLE_DIM_BACKGROUND,
              value: checked,
            });
          }}
          style={TOGGLE_SWITCH_STYLES(enable_dialog)}
        />
      </Grid>
      <CustomMaterialUIDialog
        dialogProps={{
          disableBackdropClick: true,
          disableEscapeKeyDown: true,
        }}
        dialogActions={
          <>
            <Button
              variant="text"
              onClick={() => {
                setOrientationBuffer("");
                setShowOrientationPopup(false);
              }}
              style={{ color: "#002845" }}
            >
              Cancel
            </Button>
            <Button
              variant="text"
              onClick={() => {
                dispatch({
                  type: SET_SURVEY_ORIENTATION,
                  currentStep: currentStep,
                  value: orientationBuffer,
                });
                setOrientationBuffer("");
                setShowOrientationPopup(false);
              }}
              style={{ color: "#FFFFFF", background: "#F12D2D" }}
            >
              Change orientation
            </Button>
          </>
        }
        dialogActionStyles={{
          borderRadius: 3,
        }}
        dialogContentStyles={{ overflowX: "hidden", padding: "8px 8px" }}
        openFlag={showOrientationPopup}
        onClose={() => setShowOrientationPopup(false)}
        title={"Change Orientation?"}
        titleVariant={"h5"}
        dialogContent={
          <ColumnDiv styles={{ padding: 15 }}>
            <Typography color="textPrimary" variant="subtitle1">
              This will change the orientation of all the templates.
            </Typography>

            <Typography
              style={{
                color: "#6D808F",
                padding: 10,
                background: "#EDF0F2",
                marginTop: 10,
              }}
              variant="subtitle2"
            >
              Note: Orientation of "Request for survey" & "Success message" will
              remain the same
            </Typography>
          </ColumnDiv>
        }
        maxWidth="sm"
        onDialogClose={() => setShowOrientationPopup(false)}
      />
    </>
  );
}

export default withStyles(styles)(GeneralConfiguration);