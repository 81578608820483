import produce from "immer";
import {
  ACTION_AFTER_RATING,
  ADD_NEW_RULE,
  ANSWER_MODES,
  DEFAULT_TEXT_CONFIG,
  EMPTY_CHOICE,
  EMPTY_QUESTION,
  ENABLE_DIM_BACKGROUND,
  RATING_ACTION_LABELS,
  REMOVE_RULE,
  RESET_REACTIONS,
  SET_ACTION_AFTER_RATING,
  SET_APP_STORE_LINK,
  SET_FOLLOWUP_QUESTION,
  SET_MAX_RATING,
  SET_MIN_RATING,
  SET_MODULE_TYPE,
  SET_PLAY_STORE_LINK,
  SET_RATING_REDIRECTION_QUESTION,
  SET_RULE_REACTIONS,
  SET_SURVEY_ORIENTATION,
  SET_SURVEY_RATING_BUTTONS,
  SET_SURVEY_RATING_STYLE,
  TOGGLE_RATING_REDIRECTION,
  ON_COPY_STEP,
  SET_DELAY,
  SET_SUCCESS_MESSAGE,
  SHOW_SUCCESS_MESSAGE,
  ENABLE_DIALOG,
  SET_BUTTON_TYPE,
  SET_TITLE_TEXT,
  SET_TITLE_COLOR,
  SET_DESCRIPTION_TEXT,
  SET_DESCRIPTION_COLOR,
  SET_RESPONSE_BASED_SURVEY,
  SET_UI_CONFIG,
  SET_QUESTION_TITLE,
  SET_IS_REQUIRED,
  SET_SUBMIT_TEXT,
  SET_RANDOMIZE_CHOICES,
  ADD_NEW_QUESTION,
  REMOVE_QUESTION,
  ADD_NEW_CHOICE,
  REMOVE_CHOICE,
  SET_NEXT_QUESTION_ID,
  SET_CHOICE_TEXT,
  SET_TXCFG_TEXT_COLOR,
  SET_TXCFG_BACKGROUND_COLOR,
  SET_TXCFG_MAX_CHARS,
  SET_TXCFG_PLACEHOLDER,
  SET_TXCFG_PLACEHOLDER_COLOR,
  SET_TXCFG_EXAMPLE_TEXT,
  SET_TXCFG_EXAMPLE_TEXT_COLOR,
  MOVE_QUESTION,
  IS_SKIP_ENABLED,
  IS_CANCEL_ENABLED,
  IS_PREVIOUS_ENABLED,
  IS_PROGRESSBAR_ENABLED,
  SET_QUESTION_TEXT_COLOR,
  SET_QUESTION_BG_COLOR,
  SET_OPTION_TEXT_ACTIVE_COLOR,
  SET_OPTION_TEXT_INACTIVE_COLOR,
  SET_OPTION_BG_ACTIVE_COLOR,
  SET_OPTION_BG_INACTIVE_COLOR,
  SET_SUCCESS_MESSAGE_BG_COLOR,
  SET_SUCCESS_MESSAGE_TEXT_COLOR,
  SET_SUCCESS_MESSAGE_ICON_COLOR,
  SET_SKIP_TEXT_COLOR,
  SET_CANCEL_BUTTON_COLOR,
  SET_PREVIOUS_BUTTON_COLOR,
  SET_PROGRESSBAR_COLOR,
  SET_SUBMIT_TEXT_COLOR,
  SET_SUBMIT_ACTIVE_COLOR,
  SET_SUBMIT_INACTIVE_COLOR,
  SET_YES_TEXT,
  SET_YES_TEXT_COLOR,
  SET_YES_BG_COLOR,
  SET_NO_TEXT,
  SET_NO_TEXT_COLOR,
  SET_NO_BG_COLOR,
  SET_ICON,
  SET_ICON_TYPE,
  SET_DIALOG_BG_COLOR,
  SET_NEXT_QUESTION_JSON,
  ADD_NEXT_QUESTION_TO_SURVEY,
  SET_CURRENT_STEP,
  getQAId,
} from "./constants";

export const initial_state = {
  ui: {
    delay: 1000,
    dialog_config: null,
    enable_dialog: false,
    enable_dim_bg: true,
    is_response_based_survey: false,
    questions: [],
    show_success_message: false,
    success_message: "Thank you for your feedback",
    question_text_color: "#3C3C3C",
    submit_active_color: "#017DFD",
    submit_inactive_color: "#99CBFE",
    option_text_color_active: "#002845",
    option_text_color_inactive: "#002845",
    radio_color_active: "#017DFD",
    radio_color_inactive: "#E5E5E5",
    option_bg_color_active: "#017DFD",
    option_bg_color_inactive: "#E5E5E5",
    question_background_color: "#FFFFFF",
    show_progress_bar: true,
    success_message_bg_color: "#FFFFFF",
    success_message_text_color: "#002845",
    success_message_icon_color: "#109B15",
    is_skip_enabled: true,
    is_cancel_enabled: true,
    is_previous_enabled: true,
    skip_text_color: "#017DFD",
    cancel_button_color: "#797b7b",
    previous_button_color: "#797b7b",
    progress_bar_color: "#017DFD",
    submit_text_color: "#FFFFFF",
    enable_full_screen: true,
    layout_type: "",
  },
  currentStep: -1,
  moduleType: "",
  next_question: {},
};

const INITIAL_DIALOG_CONFIG = {
  button_type: "rectangle",
  yes_text: "Yes",
  no_text: "No, thanks",
  icon_type: "question",
  icon_name: "survey_icon", //image name in asset folder in the app can be given here or just can give question/ customer_love
  title: "Did you like the feature? Please give us a feedback. ",
  description: "",
  dialog_background_color: "#FFFFFF",
  title_color: "#002845",
  description_color: "#909090",
  yes_button_bg_color: "#017DFD",
  no_button_bg_color: "#FFFFFF",
  yes_button_text_color: "#FFFFFF",
  no_button_text_color: "#017DFD",
  dialog_orientation: "",
};

const getQuestionIndex = (draft, id) => {
  return draft.ui.questions.findIndex(question => question.id === id);
};

const getChoiceIndex = (draft, questionId, choiceId) => {
  const index = draft.ui.questions.findIndex(
    question => question.id === questionId
  );
  if (index >= 0) {
    let attrIndex = draft.ui.questions[index].choices.findIndex(
      choice => choice.id === choiceId
    );
    return { questionIndex: index, choiceIndex: attrIndex };
  } else {
    return { questionIndex: -1, choiceIndex: -1 };
  }
};

export const reducer = produce((draft, action) => {
  let qIndex;
  let updateChoice;
  let objIndex;
  switch (action.type) {
    case SET_MODULE_TYPE:
      draft.moduleType = action.value;
      break;
    case SET_SURVEY_ORIENTATION:
      draft.ui.layout_type = action.value;
      break;
    case SET_DELAY:
      draft.ui.delay = action.value;
      break;
    case SHOW_SUCCESS_MESSAGE:
      draft.ui.show_success_message = action.value;
      break;
    case SET_SUCCESS_MESSAGE:
      draft.ui.success_message = action.value;
      break;
    case ENABLE_DIALOG:
      draft.ui.enable_dialog = action.value;
      if (action.value) {
        draft.ui.dialog_config = {
          ...INITIAL_DIALOG_CONFIG,
          ...draft.ui.dialog_config,
          dialog_orientation: action.orientation,
        };
      } else {
        draft.ui.dialog_config = null;
      }
      break;
    case ENABLE_DIM_BACKGROUND:
      draft.ui.enable_dim_bg = action.value;
      break;
    case SET_BUTTON_TYPE:
      draft.ui.dialog_config.button_type = action.value;
      break;
    case SET_TITLE_TEXT:
      draft.ui.dialog_config.title = action.value;
      break;
    case SET_TITLE_COLOR:
      draft.ui.dialog_config.title_color = action.value;
      break;
    case SET_DESCRIPTION_TEXT:
      draft.ui.dialog_config.description = action.value;
      break;
    case SET_DESCRIPTION_COLOR:
      draft.ui.dialog_config.description_color = action.value;
      break;
    case SET_YES_TEXT:
      draft.ui.dialog_config.yes_text = action.value;
      break;
    case SET_YES_TEXT_COLOR:
      draft.ui.dialog_config.yes_button_text_color = action.value;
      break;
    case SET_YES_BG_COLOR:
      draft.ui.dialog_config.yes_button_bg_color = action.value;
      break;
    case SET_NO_TEXT:
      draft.ui.dialog_config.no_text = action.value;
      break;
    case SET_NO_TEXT_COLOR:
      draft.ui.dialog_config.no_button_text_color = action.value;
      break;
    case SET_NO_BG_COLOR:
      draft.ui.dialog_config.no_button_bg_color = action.value;
      break;
    case SET_RESPONSE_BASED_SURVEY:
      draft.ui.is_response_based_survey = action.value;
      if (action.value) {
        draft.ui.questions = draft.ui.questions.map(question => {
          if (
            question.answer_mode !== ANSWER_MODES.single_choice &&
            question.answer_mode !== ANSWER_MODES.rating
          ) {
            return {
              ...question,
              answer_mode: ANSWER_MODES.single_choice,
              choices: [],
            };
          }
          return question;
        });
      }
      break;
    case SET_UI_CONFIG:
      draft.ui = { ...action.ui };
      break;
    case ADD_NEW_CHOICE:
      const questionId = action.id;
      const isOther = action.isOther || false;
      const index = getQuestionIndex(draft, questionId);
      if (index >= 0) {
        if (action.isOther) draft.ui.questions[index].otherEnabled = isOther;
        if (!isOther && draft.ui.questions[index].otherEnabled) {
          draft.ui.questions[index].choices.splice(
            draft.ui.questions[index].choices.length - 1,
            0,
            EMPTY_CHOICE(isOther)
          );
        } else {
          draft.ui.questions[index].choices.push(EMPTY_CHOICE(isOther));
        }
      }
      break;
    case ADD_NEW_QUESTION:
      draft.ui.questions.push(EMPTY_QUESTION());
      break;
    case REMOVE_CHOICE:
      const removeChoice = getChoiceIndex(draft, action.qid, action.choiceId);
      if (removeChoice.questionIndex >= 0 && removeChoice.choiceIndex >= 0) {
        if (
          draft.ui.questions[removeChoice.questionIndex].choices[
            removeChoice.choiceIndex
          ] &&
          draft.ui.questions[removeChoice.questionIndex].choices[
            removeChoice.choiceIndex
          ].is_other
        )
          draft.ui.questions[removeChoice.questionIndex].otherEnabled = false;
        draft.ui.questions[removeChoice.questionIndex].choices.splice(
          removeChoice.choiceIndex,
          1
        );
      }
      break;
    case REMOVE_QUESTION:
      draft.ui.questions = draft.ui.questions.filter(
        (_, index) => index !== action.index
      );

      if (draft.currentStep === action.index) {
        draft.currentStep = 0;
      }

      if (draft.ui.questions.length <= draft.currentStep) draft.currentStep = 0;
      if (draft.ui.questions.length < 1) draft.currentStep = -1;

      /**
       * Need to reset orientation state so that Template selector will allow selection of all three orientations
       * when we delete existing questions
       */
      if (draft.ui.questions.length < 3) {
        switch (draft.ui.questions.length) {
          case 2:
            if (draft.ui.enable_dialog && draft.ui.show_success_message)
              draft.ui.layout_type = "";
            break;
          case 1:
            if (draft.ui.enable_dialog || draft.ui.show_success_message)
              draft.ui.layout_type = "";
            break;
          default:
            draft.ui.layout_type = "";
            break;
        }
      }
      break;
    case ON_COPY_STEP:
      let temp_new = { ...draft.ui.questions[action.index], id: getQAId() };
      if (draft.ui.success_message) {
        draft.ui.questions.splice(draft.ui.questions.length - 1, 0, temp_new);
      } else draft.ui.questions.push({ ...temp_new });

      break;
    case SET_QUESTION_TITLE:
      qIndex = getQuestionIndex(draft, action.id);
      if (qIndex >= 0) {
        draft.ui.questions[qIndex].title = action.value;
      }
      break;
    case SET_IS_REQUIRED:
      qIndex = getQuestionIndex(draft, action.id);
      if (qIndex >= 0) {
        draft.ui.questions[qIndex].is_required = action.value;
      }
      break;
    case SET_SUBMIT_TEXT:
      qIndex = getQuestionIndex(draft, action.id);
      if (qIndex >= 0) {
        draft.ui.questions[qIndex].submit_text = action.value;
      }
      break;
    case SET_RANDOMIZE_CHOICES:
      qIndex = getQuestionIndex(draft, action.id);
      if (qIndex >= 0) {
        draft.ui.questions[qIndex].randomize_choices = action.value;
      }
      break;
    case SET_NEXT_QUESTION_ID:
      updateChoice = getChoiceIndex(draft, action.qid, action.choiceId);
      if (updateChoice.questionIndex >= 0 && updateChoice.choiceIndex >= 0) {
        draft.ui.questions[updateChoice.questionIndex].choices[
          updateChoice.choiceIndex
        ].next_question_id = action.value;
      }
      break;
    case SET_CHOICE_TEXT:
      updateChoice = getChoiceIndex(draft, action.qid, action.choiceId);
      if (updateChoice.questionIndex >= 0 && updateChoice.choiceIndex >= 0) {
        draft.ui.questions[updateChoice.questionIndex].choices[
          updateChoice.choiceIndex
        ].value = action.value;
      }
      break;
    case SET_TXCFG_BACKGROUND_COLOR:
      if (action.questionIndex) {
        qIndex = action.id;
        draft.ui.questions[qIndex][
          action.questionIndex
        ].text_config.background_color = action.value;
      } else {
        qIndex = getQuestionIndex(draft, action.id);
        if (qIndex >= 0)
          draft.ui.questions[qIndex].text_config.background_color =
            action.value;
      }
      break;
    case SET_TXCFG_EXAMPLE_TEXT:
      qIndex = getQuestionIndex(draft, action.id);
      if (qIndex >= 0) {
        draft.ui.questions[qIndex].text_config.example = action.value;
      }
      break;
    case SET_TXCFG_EXAMPLE_TEXT_COLOR:
      qIndex = getQuestionIndex(draft, action.id);
      if (qIndex >= 0) {
        draft.ui.questions[qIndex].text_config.example_text_color =
          action.value;
      }
      break;
    case SET_TXCFG_MAX_CHARS:
      if (action.questionIndex) {
        qIndex = action.id;
        draft.ui.questions[qIndex][
          action.questionIndex
        ].text_config.max_characters = action.value;
      } else {
        qIndex = getQuestionIndex(draft, action.id);
        if (qIndex >= 0)
          draft.ui.questions[qIndex].text_config.max_characters = action.value;
      }
      break;
    case SET_TXCFG_PLACEHOLDER:
      if (action.questionIndex) {
        qIndex = action.id;
        draft.ui.questions[qIndex][
          action.questionIndex
        ].text_config.placeholder = action.value;
      } else {
        qIndex = getQuestionIndex(draft, action.id);
        if (qIndex >= 0) {
          draft.ui.questions[qIndex].text_config.placeholder = action.value;
        }
      }
      break;
    case SET_TXCFG_PLACEHOLDER_COLOR:
      if (action.questionIndex) {
        qIndex = action.id;
        draft.ui.questions[qIndex][
          action.questionIndex
        ].text_config.placeholder_text_color = action.value;
      } else {
        qIndex = getQuestionIndex(draft, action.id);
        if (qIndex >= 0)
          draft.ui.questions[qIndex].text_config.placeholder_text_color =
            action.value;
      }
      break;
    case SET_TXCFG_TEXT_COLOR:
      if (action.questionIndex) {
        qIndex = action.id;
        draft.ui.questions[qIndex][
          action.questionIndex
        ].text_config.text_color = action.value;
      } else {
        qIndex = getQuestionIndex(draft, action.id);
        if (qIndex >= 0)
          draft.ui.questions[qIndex].text_config.text_color = action.value;
      }
      break;
    case MOVE_QUESTION:
      const from = action.from;
      const to = action.to;
      const {
        ui: { questions },
      } = draft;

      const temp = questions[from];
      questions[from] = questions[to];
      questions[to] = temp;
      draft.ui.questions = questions;
      break;
    case IS_SKIP_ENABLED:
      draft.ui.is_skip_enabled = action.value;
      break;
    case IS_CANCEL_ENABLED:
      draft.ui.is_cancel_enabled = action.value;
      break;
    case IS_PREVIOUS_ENABLED:
      draft.ui.is_previous_enabled = action.value;
      break;
    case IS_PROGRESSBAR_ENABLED:
      draft.ui.show_progress_bar = action.value;
      break;
    case SET_QUESTION_TEXT_COLOR:
      draft.ui.question_text_color = action.value;
      break;
    case SET_QUESTION_BG_COLOR:
      draft.ui.question_background_color = action.value;
      break;
    case SET_OPTION_TEXT_ACTIVE_COLOR:
      draft.ui.option_text_color_active = action.value;
      draft.ui.radio_color_active = action.value;
      break;
    case SET_OPTION_TEXT_INACTIVE_COLOR:
      draft.ui.option_text_color_inactive = action.value;
      draft.ui.radio_color_inactive = action.value;
      break;
    case SET_OPTION_BG_ACTIVE_COLOR:
      draft.ui.option_bg_color_active = action.value;
      break;
    case SET_OPTION_BG_INACTIVE_COLOR:
      draft.ui.option_bg_color_inactive = action.value;
      break;
    case SET_SUCCESS_MESSAGE_BG_COLOR:
      draft.ui.success_message_bg_color = action.value;
      break;
    case SET_SUCCESS_MESSAGE_TEXT_COLOR:
      draft.ui.success_message_text_color = action.value;
      break;
    case SET_SUCCESS_MESSAGE_ICON_COLOR:
      draft.ui.success_message_icon_color = action.value;
      break;
    case SET_SKIP_TEXT_COLOR:
      draft.ui.skip_text_color = action.value;
      break;
    case SET_CANCEL_BUTTON_COLOR:
      draft.ui.cancel_button_color = action.value;
      break;
    case SET_PREVIOUS_BUTTON_COLOR:
      draft.ui.previous_button_color = action.value;
      break;
    case SET_PROGRESSBAR_COLOR:
      draft.ui.progress_bar_color = action.value;
      break;
    case SET_SUBMIT_TEXT_COLOR:
      draft.ui.submit_text_color = action.value;
      break;
    case SET_SUBMIT_ACTIVE_COLOR:
      draft.ui.submit_active_color = action.value;
      break;
    case SET_SUBMIT_INACTIVE_COLOR:
      draft.ui.submit_inactive_color = action.value;
      break;
    case SET_ICON:
      draft.ui.dialog_config.icon_name = action.value;
      break;
    case SET_ICON_TYPE:
      draft.ui.dialog_config.icon_type = action.value;
      let icon_name = "";
      switch (action.value) {
        case "question":
          icon_name = "survey_icon";
          break;
        case "customer_love":
          icon_name = "customer_love";
          break;
        default:
          break;
      }
      draft.ui.dialog_config.icon_name = icon_name;
      break;
    case SET_DIALOG_BG_COLOR:
      draft.ui.dialog_config.dialog_background_color = action.value;
      break;
    case SET_NEXT_QUESTION_JSON:
      draft.next_question = action.value;
      break;
    case ADD_NEXT_QUESTION_TO_SURVEY:
      if (Object.keys(draft.next_question).length > 0) {
        if (draft.next_question?.answer_mode === ANSWER_MODES.request_survey)
          draft.ui.questions.unshift(draft.next_question);
        else if (
          draft.next_question?.answer_mode === ANSWER_MODES.success_message ||
          !draft.ui.show_success_message
        )
          draft.ui.questions.push(draft.next_question);
        else {
          draft.ui.questions.splice(
            draft.ui.questions.length - 1,
            0,
            draft.next_question
          );
        }
      }
      draft.currentStep = draft.ui.questions.length - 1;
      break;
    case SET_CURRENT_STEP:
      draft.currentStep = action.value;
      break;
    case SET_SURVEY_RATING_STYLE:
      draft.ui.questions[action.currentStep].rating_style = action.value;
      break;
    case SET_SURVEY_RATING_BUTTONS:
      draft.ui.questions[action.currentStep].rating_scale = action.count;
      break;
    case TOGGLE_RATING_REDIRECTION:
      draft.ui.questions[action.currentStep].advanced_options = action.value;
      draft.ui.questions[action.currentStep].rules = [];
      break;
    case SET_ACTION_AFTER_RATING:
      draft.ui.questions[action.currentStep][action.qid].type = action.value;
      if (action.value === RATING_ACTION_LABELS.QUESTION) {
        draft.ui.questions[action.currentStep][
          action.qid
        ].rating_redirection_question = "short_answer";
        draft.ui.questions[action.currentStep][
          action.qid
        ].text_config = DEFAULT_TEXT_CONFIG;
      } else {
        draft.ui.questions[action.currentStep][action.qid].intent_action =
          "android.intent.action.VIEW";
        delete draft.ui.questions[action.currentStep][action.qid]
          .rating_redirection_question;
      }
      break;
    case SET_RATING_REDIRECTION_QUESTION:
      draft.ui.questions[action.currentStep][
        action.qid
      ].rating_redirection_question = action.value;
      if (action.value === "short_answer") {
        draft.ui.questions[action.currentStep][
          action.qid
        ].text_config = DEFAULT_TEXT_CONFIG;
      }
      break;
    case SET_PLAY_STORE_LINK:
      draft.ui.questions[action.currentStep][action.qid].uri = action.value;
      break;
    case SET_APP_STORE_LINK:
      draft.ui.questions[action.currentStep][action.qid].app_store_link =
        action.value;
      break;
    case ADD_NEW_RULE:
      if (!draft.ui.questions[action.currentStep].rules)
        draft.ui.questions[action.currentStep].rules = [];
      draft.ui.questions[action.currentStep].rules.push({
        qid: action.value,
      });
      draft.ui.questions[action.currentStep][action.value] = {
        type: RATING_ACTION_LABELS.QUESTION,
        rating_redirection_question: "short_answer",
        text_config: DEFAULT_TEXT_CONFIG,
      };
      break;
    case REMOVE_RULE:
      draft.ui.questions[action.currentStep].rules = draft.ui.questions[
        action.currentStep
      ].rules.filter(each => each.qid !== action.value);
      delete draft.ui.questions[action.currentStep][action.value];
      break;
    case RESET_REACTIONS:
      //Whenever the rating scale changes, reset the selected reactions so that 10 scale wont be applied to 5
      if (!draft.ui.questions[action.currentStep].rules)
        draft.ui.questions[action.currentStep].rules = [];
      delete draft.ui.questions[action.currentStep].rules;
      draft.ui.questions[action.currentStep].rules = [];
      break;
    case SET_MAX_RATING:
      objIndex = draft.ui.questions[action.currentStep].rules.findIndex(
        each => each.qid === action.qid
      );
      draft.ui.questions[action.currentStep].rules[objIndex].max = action.value;
      break;
    case SET_MIN_RATING:
      objIndex = draft.ui.questions[action.currentStep].rules.findIndex(
        each => each.qid === action.qid
      );
      draft.ui.questions[action.currentStep].rules[objIndex].min = action.value;
      break;
    case SET_FOLLOWUP_QUESTION:
      draft.ui.questions[action.currentStep][action.qid].question =
        action.value;
      break;
    default:
      return draft;
  }
});


