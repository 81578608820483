import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { APP_PLATFORMS } from "../../../../../../../../../constants";
import { capitalizeEachWord } from "../../../../../../../../../utils";
import ActionsTextField from "../../../../../../../../ReusableComponents/ActionsTextField";
import MultiSelect from "../../../../../../../../ReusableComponents/MultiSelect";
import { executeWithDebounce } from "../../../../../../../common/components/campaigncreator/constants";
import { BTN_ACTION_ENUM, REDIRECT_ACTIONS } from "../../../constants";
import KeyValuePairs from "./KeyValuePairs";

function filterButtonActions(actions, platform) {
  if (platform === APP_PLATFORMS.web) {
    return actions.filter(
      (eachAction) => BTN_ACTION_ENUM[eachAction] !== BTN_ACTION_ENUM.CANCEL
    );
  } else return actions;
}

export default function ButtonAction({
  appScreenNames,
  button,
  isSingular = false,
  classes,
  index,
  onActionChange,
  platform,
  isImageOnlyRedirection = false,
}) {
  const {
    action_config: {
      action,
      is_deep_link = false,
      activity = "",
      deep_link = { uri: "" },
      url = "",
      is_external = false,
      internal_redirection: keyValuePairs = [],
      is_ir = false,
    },
    text_config: { text = "Button" },
  } = button;

  const [actionType, setActionType] = useState(
    action || BTN_ACTION_ENUM.DISMISS
  );

  const [isDeepLink, setIsDeepLink] = useState(is_deep_link);
  const [activityValue, setActivityValue] = useState(activity);
  const [uriValue, setUrivalue] = useState(is_deep_link ? deep_link.uri : "");
  const [urlValue, setUrlValue] = useState(url);
  const [external, setExternal] = useState(
    platform === APP_PLATFORMS.web ? true : is_external
  );

  const [isInternalRedirection, setIsInternalRedirection] = useState(is_ir);

  useEffect(() => {
    if (platform === APP_PLATFORMS.web) {
      onActionChange({
        action: action,
        url: urlValue,
        is_external: external,
        is_ir: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, urlValue, external]); //Adding this dependency as toggling the number of buttons adds back the default dismiss state.

  useEffect(() => {
    if (
      !(
        isDeepLink === is_deep_link &&
        activityValue === activity &&
        actionType === action
      )
    ) {
      setIsDeepLink(is_deep_link);
      setActionType(action);
      setActivityValue(activity);
      setUrivalue(is_deep_link ? deep_link.uri : "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, is_deep_link, activity, deep_link?.uri]);

  function onInternalRedirectionKeysChange(data) {
    onActionChange({
      action,
      activity,
      is_deep_link: isDeepLink,
      is_ir: isInternalRedirection,
      deep_link: isDeepLink
        ? {
            uri: uriValue,
            intent_action: "android.intent.action.VIEW",
          }
        : undefined,
      internal_redirection: data,
    });
  }

  function onKeyChange(value, index) {
    onInternalRedirectionKeysChange(
      keyValuePairs.map((pair, i) => {
        if (i !== index) return pair;
        pair.name = value;
        return pair;
      })
    );
  }

  function onValueChange(value, index) {
    onInternalRedirectionKeysChange(
      keyValuePairs.map((pair, i) => {
        if (i !== index) return pair;
        pair.value = value;
        return pair;
      })
    );
  }

  function onNewKey() {
    const pairs = keyValuePairs;
    pairs.push({ name: "", value: "" });
    onInternalRedirectionKeysChange(pairs);
  }

  function onKeyDelete(index) {
    onInternalRedirectionKeysChange(
      keyValuePairs.filter((_, i) => i !== index)
    );
  }

  return (
    <>
      <Grid
        item
        xs={12}
        className={classes.gridItem}
        style={{ marginTop: 0, marginBottom: 0 }}
      >
        <Typography className={classes.typographyCaption} variant="subtitle2">
          {isImageOnlyRedirection || isSingular
            ? isSingular
              ? "Button Action"
              : `Image action (on tap)`
            : `Button ${index + 1} action (${text})`}
        </Typography>
        <MultiSelect
          single
          clearable={false}
          isSearchable={false}
          options={filterButtonActions(
            Object.keys(BTN_ACTION_ENUM),
            platform
          ).map((actionType) => ({
            value: BTN_ACTION_ENUM[actionType],
            label: capitalizeEachWord(BTN_ACTION_ENUM[actionType]),
          }))}
          value={actionType}
          handleChange={value => {
            const isRedirect = value === BTN_ACTION_ENUM.REDIRECT;
            const actValue = isRedirect ? activityValue : "";
            const uriVal = isRedirect && isDeepLink ? uriValue : "";
            setActionType(value);
            setActivityValue(isRedirect ? activityValue : "");
            setUrivalue(isRedirect && isDeepLink ? uriValue : "");
            const additionalProps =
              platform === APP_PLATFORMS.web
                ? { url: urlValue, is_external: external }
                : {};
            if (onActionChange) {
              onActionChange({
                action: value,
                activity: actValue,
                is_deep_link: isDeepLink,
                is_ir: isInternalRedirection,
                deep_link: isDeepLink
                  ? {
                      uri: uriVal,
                      intent_action: "android.intent.action.VIEW",
                    }
                  : undefined,
                ...additionalProps,
              });
            }
          }}
          style={{ maxWidth: 125, minWidth: 125 }}
        />
      </Grid>
      {platform !== APP_PLATFORMS.web &&
        actionType === BTN_ACTION_ENUM.REDIRECT && (
          <>
            <Grid
              item
              xs={12}
              className={classes.gridItem}
              style={{ gap: 8, marginTop: 0, marginBottom: 0 }}
            >
              <MultiSelect
                single
                clearable={false}
                isSearchable={false}
                options={Object.keys(REDIRECT_ACTIONS).map(actionType => ({
                  value: REDIRECT_ACTIONS[actionType],
                  label: capitalizeEachWord(REDIRECT_ACTIONS[actionType]),
                }))}
                value={
                  isInternalRedirection
                    ? REDIRECT_ACTIONS.KEY_VALUE_PAIRS
                    : isDeepLink
                    ? REDIRECT_ACTIONS.DEEP_LINK
                    : REDIRECT_ACTIONS.ACTIVITY
                }
                handleChange={value => {
                  const isInternalRedirection =
                    value === REDIRECT_ACTIONS.KEY_VALUE_PAIRS;
                  const isDeepLink = value === REDIRECT_ACTIONS.DEEP_LINK;
                  setIsInternalRedirection(isInternalRedirection);
                  setIsDeepLink(isDeepLink);
                  if (onActionChange) {
                    onActionChange({
                      action,
                      activity,
                      is_deep_link: isDeepLink,
                      is_ir: isInternalRedirection,
                      deep_link: isDeepLink
                        ? {
                            uri: uriValue,
                            intent_action: "android.intent.action.VIEW",
                          }
                        : undefined,
                      internal_redirection: isInternalRedirection
                        ? keyValuePairs
                        : [],
                    });
                  }
                }}
                style={{
                  maxWidth: isInternalRedirection ? "unset" : 134,
                  minWidth: 134,
                }}
              />
              {isDeepLink && (
                <ActionsTextField
                  placeholder="yourapp://your_scheme"
                  value={uriValue}
                  key="DeepLink"
                  dataKey="uri"
                  multiline={false}
                  onChange={event => {
                    const value = event.target.value;
                    setUrivalue(value);
                    if (onActionChange) {
                      executeWithDebounce(700)(onActionChange, {
                        action,
                        activity,
                        is_deep_link: isDeepLink,
                        is_ir: isInternalRedirection,
                        deep_link: {
                          uri: value,
                          intent_action: "android.intent.action.VIEW",
                        },
                      });
                    }
                  }}
                  style={{
                    marginBottom: 0,
                    width: "100%",
                  }}
                />
              )}
              {!isDeepLink && !isInternalRedirection && (
                <MultiSelect
                  options={appScreenNames.map(screenName => ({
                    label: screenName,
                    value: screenName,
                  }))}
                  single
                  value={activityValue}
                  handleChange={activityValue => {
                    setActivityValue(activityValue);
                    if (onActionChange) {
                      onActionChange({
                        action,
                        activity: activityValue,
                        is_deep_link: isDeepLink,
                        is_ir: isInternalRedirection,
                        deep_link: isDeepLink
                          ? {
                              uri: uriValue,
                              intent_action: "android.intent.action.VIEW",
                            }
                          : undefined,
                      });
                    }
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {isInternalRedirection && (
                <KeyValuePairs
                  pairs={keyValuePairs}
                  onKeyChange={onKeyChange}
                  onValueChange={onValueChange}
                  onNewKey={onNewKey}
                  onKeyDelete={onKeyDelete}
                />
              )}
            </Grid>
          </>
        )}

      {platform === APP_PLATFORMS.web &&
        actionType === BTN_ACTION_ENUM.REDIRECT && (
          <>
            <Grid
              item
              xs={12}
              className={classes.gridItem}
              style={{
                gap: 8,
                marginTop: 0,
                marginBottom: 0,
                marginLeft: 10,
              }}
            >
              <Typography
                className={classes.typographyCaption}
                variant="subtitle2"
              >
                URL
              </Typography>
              <ActionsTextField
                placeholder="https://yourdomain.com/path?queryParam=SomeValue"
                value={urlValue}
                key="URL"
                dataKey="URL"
                multiline={false}
                onChange={event => {
                  setUrlValue(event.target.value);
                }}
                style={{
                  marginBottom: 0,
                  width: "100%",
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              className={classes.gridItem}
              style={{
                gap: 8,
                marginTop: 0,
                marginBottom: 0,
                marginLeft: 20,
              }}
            >
              <FormControlLabel
                className={classes.formControlLabel}
                label="Is External URI"
                control={
                  <Checkbox
                    color="primary"
                    checked={external}
                    disabled={platform === APP_PLATFORMS.web}
                    onChange={(e) => {
                      setExternal(e.target.checked);
                    }}
                    style={{ padding: 0, paddingRight: 8 }}
                    disableRipple
                  />
                }
              />
            </Grid>
          </>
        )}
    </>
  );

}

