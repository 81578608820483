import {
  makeGetRequest,
  makePostRequest,
  makeDeleteRequest,
} from "../../../common/actions";

const FUNNELS_API = "funnels";
const FUNNELS_GROUP_API = "funnels/analysis";
const SAVE_FUNNEL_API = "funnels/save";
const FUNNELS_OPPORTUNITY_API = "funnels/groupby/opportunities";
const TIME_SERIES_FUNNEL_API = "funnels/time-series";
const COHORT_FUNNEL_API = "cohorts/funnels";
const FUNNELS_GROUPED_BY_API = "funnels/groupby";
export const FUNNELS_DOWNLOAD_API = "funnels/analysis/download";

export function getFunnelsAPI(auth, appId, queryParams) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return [];
  };
  return makeGetRequest(
    FUNNELS_API,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getFunnelOpportunityAPI(auth, appId, queryParams, filters) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return [];
  };
  return makePostRequest(
    FUNNELS_OPPORTUNITY_API,
    auth,
    appId,
    queryParams,
    filters,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getFunnelAPI(auth, appId, queryParams, filters) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return [];
  };
  return makePostRequest(
    FUNNELS_GROUP_API,
    auth,
    appId,
    queryParams,
    filters,
    onSuccessCallback,
    onFailureCallback
  );
}

export function saveFunnelAPI(auth, appId, queryParams, filters) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    SAVE_FUNNEL_API,
    auth,
    appId,
    queryParams,
    filters,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getFunnelTimeseriesAPI(auth, appId, queryParams, funnelId) {
  const onSuccessCallback = (json) => {
    return json;
  };

  const onFailureCallback = (res) => {
    return {};
  };
  return makeGetRequest(
    TIME_SERIES_FUNNEL_API,
    auth,
    appId,
    { funnelId, ...queryParams },
    onSuccessCallback,
    onFailureCallback
  );
}

export function createCohortFromFunnelAPI(auth, appId, queryParams, filters) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return [];
  };
  return makePostRequest(
    COHORT_FUNNEL_API,
    auth,
    appId,
    queryParams,
    filters,
    onSuccessCallback,
    onFailureCallback
  );
}

export function deleteFunnelAPI(auth, appId, funnelId) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makeDeleteRequest(
    FUNNELS_API,
    auth,
    appId,
    { funnelId },
    onSuccessCallback,
    onFailureCallback
  );
}

export function getFunnelGroupedByAPI(auth, appId, queryParams, filters) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return [];
  };
  return makePostRequest(
    FUNNELS_GROUPED_BY_API,
    auth,
    appId,
    queryParams,
    filters,
    onSuccessCallback,
    onFailureCallback
  );

}