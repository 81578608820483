import React, { useState, useMemo } from "react";
import Filters from "../../common/components/Filters";
import { Divider } from "@material-ui/core";
import { useDateFilters } from "../../common/utils";
import { Funnel } from "./components";
import { Provider, useTrackedState } from "./store";

function FunnelsWithProvider({ app }) {
  const state = useTrackedState();

  const [dateFilters, setDateFilters] = useDateFilters();
  const [version, setVersion] = useState(null);
  const [cohortId, setCohortId] = useState(null);

  const queryParams = useMemo(
    () => ({ ...dateFilters, versions: version, cohort: cohortId }),
    [dateFilters, version, cohortId]
  );

  const { app_id: appId } = app;

  return (
    <>
      <Filters
        app={app}
        dateFilters={dateFilters}
        showCohort
        showVersion
        onVersionChange={(version) =>
          setVersion(version === "" ? null : version)
        }
        onCohortChange={(cohortId) =>
          setCohortId(cohortId === "" ? null : cohortId)
        }
        onDateFiltersChange={(start, end) =>
          setDateFilters({
            since: start,
            till: end,
          })
        }
        refresh={state.refreshCohorts}
      />
      <Divider />
      <section className="content" style={{ marginBottom: 240 }}>
        <Funnel appId={appId} queryParams={queryParams} />
      </section>
    </>
  );
}

/**
 * We are connecting the Funnel Component with a state here
 *
 * It's because, Sibling components depend on each other.
 * Maintaining the commonly needed prop at parent, passing down the props along with the callbacks is pretty messed-up pattern
 *
 * `react-tracked` library comes to rescue us from it (Redux can be used too, but `react-tracked` is more useful than that)
 *
 * It provides two useful hooks, one is state hook and update hook. Use whatever suits the situation
 *
 * Update the `prop` in global state, use `useEffect` to listen to the changes of that `prop`. See, it's very easy
 *
 */
export default function Funnels({ app }) {
  return (
    <Provider>
      <FunnelsWithProvider app={app} />
    </Provider>
  );
}
