export const SET_VARIANT = "SET_VARIANT";
export const SET_REFRESH = "SET_REFRESH";
export const SET_INITIAL_DATA = "SET_INITIAL_DATA";
export const SET_APP_EVENTS = "SET_APP_EVENTS";
export const SET_FILTERS_DATA = "SET_FILTERS_DATA";

export const TIME_SLOTS = [
  {
    label: "1 Min",
    value: 60,
  },
  {
    label: "5 Mins",
    value: 5 * 60,
  },
  {
    label: "15 Mins",
    value: 15 * 60,
  },
  {
    label: "30 Mins",
    value: 30 * 60,
  },
  {
    label: "1 Hour",
    value: 60 * 60,
  },
  {
    label: "1 Day",
    value: 24 * 60 * 60,
  },
  {
    label: "3 Days",
    value: 3 * 24 * 60 * 60,
  },
  {
    label: "1 Week",
    value: 7 * 24 * 60 * 60,
  },
  {
    label: "2 Weeks",
    value: 14 * 24 * 60 * 60,
  },
  {
    label: "3 Weeks",
    value: 21 * 24 * 60 * 60,
  },
  {
    label: "30 Days",
    value: 30 * 24 * 60 * 60,
  },
  {
    label: "60 Days",
    value: 60 * 24 * 60 * 60,
  },
  {
    label: "90 Days",
    value: 90 * 24 * 60 * 60,
  },
];
