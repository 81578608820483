import React, { useState, useMemo, useEffect } from "react";
import { Grid, Tooltip, Fab, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import PeopleGroupIcon from "@material-ui/icons/Group";
import Switch from "../../../../../../ReusableComponents/Switch";
import { USER_GROUPS } from "../../../../../../../constants";
import MultiSelect from "../../../../../../ReusableComponents/MultiSelect";
import { Link } from "react-router-dom";
import { formatNumber } from "../../../../../../../utils";
import { useAuth } from "../../../../../../../contexts/AuthContext";
import { getSegmentIdCount } from "../../../actions";

export default function Toolbar({
  appId,
  segments,
  segmentId = "",
  queryParams,
  filters,
  onGroupChange,
  onSegmentChange,
}) {
  const auth = useAuth();

  const [group, setGroup] = useState("all_users");
  const [count, setCount] = useState(0);

  const isSegmentSelected = useMemo(() => segmentId && segmentId.length > 0, [
    segmentId,
  ]);

  useEffect(() => {
    if (segmentId !== "") {
      getSegmentIdCount(
        auth,
        appId,
        segmentId,
        { ...queryParams, group: group },
        filters
      )
        .then((response) => {
          setCount(response);
        })
        .catch((err) => {
          setCount(0);
        });
    }
  }, [auth, appId, queryParams, group, segmentId, filters]);

  return (
    <Grid container justify="flex-end" style={{ marginBottom: 10 }} spacing={8}>
      <Grid item xs={12} sm>
        {isSegmentSelected && (
          <Switch
            data={USER_GROUPS}
            value={group}
            handleChange={(group) => {
              setGroup(group);
              if (onGroupChange) {
                onGroupChange(group);
              }
            }}
            containerStyles={{ margin: "0 auto" }}
          />
        )}
      </Grid>
      <Grid item xs={9} sm={3} md={3}>
        <MultiSelect
          single
          value={segmentId}
          options={segments}
          handleChange={(value) => {
            if (onSegmentChange) {
              onSegmentChange(value);
            }
          }}
          placeholder={"Select a segment"}
        />
      </Grid>
      <Grid item xs={3} sm={1} md={1}>
        {isSegmentSelected && (
          <Tooltip
            id="tooltip-users"
            title={`Users in this Segment (${count})`}
            placement="top"
          >
            <div style={{ display: "flex", marginTop: 8 }}>
              <PeopleGroupIcon color="primary" />
              <Typography style={{ marginLeft: 3 }}>
                {formatNumber(count)}
              </Typography>
            </div>
          </Tooltip>
        )}
      </Grid>
      <Grid item xs={6} sm={1} md={1}>
        {segments.length === 0 && (
          <Link to={`/apps/${appId}/segments/new`}>
            <Tooltip
              id="tooltip-fab-add"
              title="Create New Segment"
              placement="bottom"
              open={true}
            >
              <Fab size="small" color="primary" aria-label="Create New Segment">
                <AddIcon />
              </Fab>
            </Tooltip>
          </Link>
        )}
        {segments.length !== 0 && (
          <Link to={`/apps/${appId}/segments/new`}>
            <Tooltip
              id="tooltip-fab-add"
              title="Create New Segment"
              placement="bottom"
            >
              <Fab size="small" color="primary" aria-label="Create New Segment">
                <AddIcon />
              </Fab>
            </Tooltip>
          </Link>
        )}
      </Grid>
    </Grid>
  );
}
