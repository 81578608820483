import React, { useState } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import ActionsTextField from "../../../../../ReusableComponents/ActionsTextField";
import RowDiv from "../../../../../ReusableComponents/RowDiv";

export default function CreateEditVariantDialog({
  handleDone,
  experiment = false,
  variants = [],
}) {
  const [variantName, setVariantName] = useState("");
  const variantCode = String.fromCharCode(
    Object.keys(variants).filter((key) => key !== "CG").length + 65
  );

  return (
    <div
      style={{
        maxHeight: "411px",
        maxWidth: "421px",
        marginTop: "0.5em",
        marginBottom: "0.5em",
        marginLeft: "0.5em",
        marginRight: "0.5em",
      }}
    >
      <Typography
        variant="h4"
        style={{
          fontWeight: 700,
          fontFamily: "Manrope",
          fontStyle: "normal",
          fontSize: "25px",
          lineHeight: "34px",
          marginBottom: "1em",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {experiment === false
          ? "Add your first Variant"
          : "Add another Variant"}
      </Typography>
      <div
        style={{
          background: "#00BDE1",
          height: 62,
          width: 68,
          borderRadius: 5,
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "center",
          marginBottom: 10,
        }}
      >
        <Typography
          style={{
            fontSize: "40px",
            fontWeight: "700",
            fontStyle: "normal",
            fontFamily: "Manrope",
            color: "#FFFFFF",
            display: "flex",
            justifyContent: "center",
            padding: "unset",
          }}
        >
          {variantCode.toUpperCase()}{" "}
        </Typography>
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography
            style={{
              marginLeft: 50,
            }}
          >
            Variant name
          </Typography>
          <ActionsTextField
            style={{
              width: "321px",
              marginLeft: 50,
              marginRight: 50,
              display: "flex",
              justifyContent: "center",
            }}
            value={variantName}
            onChange={(e) => setVariantName(e.target.value)}
          />
        </Grid>

        <RowDiv
          center
          styles={{
            width: "140px",
            marginBottom: 34,
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Button
            fullWidth
            variant={"contained"}
            color={"primary"}
            disabled={variantName === "" || variants.length === 6}
            onClick={() => handleDone(variantName)}
          >
            Add
          </Button>
        </RowDiv>
      </Grid>
    </div>
  );
}
