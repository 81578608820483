import { produce } from "immer";
import { isDefined } from "../../../../utils";

export const SET_GROUP = "SET_GROUP";
export const SET_DAYS = "SET_DAYS";
export const SET_DATA = "SET_DATA";
export const SELECT_EVENT = "SELECT_EVENT";
export const SELECTED_EVENTS = "SELECTED_EVENTS";

export const initialState = {
  group: "all_users",
  startDay: null,
  endDay: null,
  eventList: [],
};

export const reducer = produce((draft, action) => {
  let startDay;
  let endDay;
  switch (action.type) {
    case SET_GROUP:
      draft.group = action.group;
      break;
    case SET_DAYS:
      startDay = action.startDay;
      endDay = action.endDay;
      draft.startDay =
        isDefined(startDay, false) && startDay >= 0 ? startDay : null;
      draft.endDay = isDefined(endDay, false) && endDay >= 0 ? endDay : null;
      break;
    case SET_DATA:
      if (action.group) {
        draft.group = action.group;
      }
      startDay = action.startDay;
      endDay = action.endDay;
      draft.startDay =
        isDefined(startDay, false) && startDay >= 0 ? startDay : null;
      draft.endDay = isDefined(endDay, false) && endDay >= 0 ? endDay : null;
      break;
    case SELECT_EVENT:
      if (!draft.eventList.includes(action.event)) {
        draft.eventList.push(action.event);
      }
      break;
    case SELECTED_EVENTS:
      // Add missing events to eventList
      action.events.forEach((event) => {
        if (!draft.eventList.includes(event)) {
          draft.eventList.push(event);
        }
      });
      // Delete events that are removed in eventList
      draft.eventList.forEach((event) => {
        if (!action.events.includes(event)) {
          const index = draft.eventList.findIndex((e) => e === event);
          if (index >= 0) {
            delete draft.eventList[index];
          }
        }
      });
      break;
    default:
      return draft;
  }
});
