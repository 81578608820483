import React, { useState, useEffect } from "react";
import { DropTarget } from "react-dnd";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Dimension from "./Dimension";
import { DIMENSION_TYPE, ItemTypes } from "../constants";
import List from "@material-ui/core/List";
import MultiSelect from "../../../../ReusableComponents/MultiSelect";
import withDragDropContext from "../../../../ReusableComponents/withDragDropContext";

const { attribute, event } = DIMENSION_TYPE;

const collect = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    currentDimension: monitor.getItem(),
  };
};

const DIMENSION_WIDTH = 240;

const styleForDimension = (index) => {
  return {
    width: DIMENSION_WIDTH,
    maxWidth: 240,
    margin: 5,
    marginLeft: index * 20,
    //background //: randomColorWithIndex(1)
  };
};

const dimensionTarget = {
  drop(props, monitor) {},
};

const styles = (theme) => ({
  paper: {
    padding: 5,
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  details: {
    display: "flex",
    flexWrap: "wrap",
    paddingTop: 10,
  },
  root: {
    margin: "5px 2px",
    background: theme.palette.primary.main,
    "&:hover": {
      background: `${theme.palette.primary.main} !important`,
    },
    "&:focus": {
      background: theme.palette.secondary.main,
    },
    color: theme.palette.secondary.main,
    "& span": {
      color: theme.palette.secondary.main,
    },
  },
  colorDefault: {
    /*color: theme.palette.secondary.dark,
        background: theme.palette.primary.main*/
  },
  listIcon: {
    color: theme.palette.secondary.main,
  },
});

function DimensionSelector({
  dimensions: dimensionList,
  handleChange,
  attributes,
  classes,
  connectDropTarget,
}) {
  const [dimensions, setDimensions] = useState(dimensionList);

  useEffect(() => {
    setDimensions(dimensionList);
  }, [dimensionList]);

  const findDimension = (id) => {
    const index = dimensions.findIndex((o) => o.id === id);
    return {
      dimension: dimensions[index],
      index,
    };
  };

  const moveDimension = (id, atIndex) => {
    const { dimension, index } = findDimension(id);
    const x = [...dimensions.slice(0, index), ...dimensions.slice(index + 1)];
    const updated = [...x.slice(0, atIndex), dimension, ...x.slice(atIndex)];
    setDimensions(updated);
    handleChange(updated);
  };

  const handleAddDimension = (dimension) => {
    if (dimension) {
      const newDimension = {
        id: dimension,
        name: dimension,
        dimension_type: dimension === "apx_event_name" ? event : attribute,
      };
      const updated = [...dimensions, newDimension];
      setDimensions(updated);
      handleChange(updated);
    }
  };

  const handleRemoveDimension = (index) => {
    const updated = [
      ...dimensions.slice(0, index),
      ...dimensions.slice(index + 1),
    ];
    setDimensions(updated);
    handleChange(updated);
  };

  const remainingAttributes = attributes
    .filter((a) => !dimensions.some((o) => o.name === a.value))
    .concat({ label: "Date", value: "apx_date" })
    .concat({ label: "apx_user_id", value: "apx_user_id" });

  return connectDropTarget(
    <div style={{ width: "100%", minHeight: 50 }}>
      <Grid container>
        <Grid item xs>
          <List>
            {dimensions.map((dim, index) => (
              <div key={dim.id} style={styleForDimension(index)}>
                <Dimension
                  key={dim.id}
                  dimension={dim}
                  id={dim.id || dim.name}
                  classes={classes}
                  findDimension={findDimension}
                  moveDimension={moveDimension}
                  handleRemove={() => handleRemoveDimension(index)}
                />
              </div>
            ))}
          </List>
          {dimensions.length < 10 && (
            <MultiSelect
              single
              value={null}
              handleChange={handleAddDimension}
              options={remainingAttributes}
              style={{
                margin: 5,
                marginLeft: dimensions.length * 20,
                maxWidth: DIMENSION_WIDTH,
              }}
              placeholder="Select Dimension"
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(
  withDragDropContext(
    DropTarget(ItemTypes.DIMENSION, dimensionTarget, collect)(DimensionSelector)
  )
);
