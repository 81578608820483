/**
 * Created by Rakesh Peela
 * Date: 05-Dec-2019
 * Time: 3:43 PM
 */

import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { Popper, withStyles } from "@material-ui/core";
import styled from "styled-components";
import PropTypes from "prop-types";
import { FONT_FAMILY_REGULAR } from "../../utils/index";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { GradientPicker } from "react-linear-gradient-picker";

const styles = (theme) => ({
  root: {
    border: `1px solid #CCD4DA`,
    borderRadius: 2,
    cursor: "pointer",
    padding: 6,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    background: "white",
  },
});

const StyledColorSwatch = styled.div`
  margin-left: 10px;
  border-radius: 1px;
  display: inline-block;
  cursor: pointer;
`;

const StyledColor = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background: ${(props) => {
    return COLOR_STYLE_ENUM.GRADIENT === props.colorStyle &&
      Array.isArray(props.color)
      ? `linear-gradient(${props.color.join(",")})`
      : props.color;
  }};
`;

const StyledCover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

const defaultPresetColors = [
  "#EF5350",
  "#EC407A",
  "#AB47BC",
  "#7E57C2",
  "#5C6BC0",
  "#42A5F5",
  "#29B6F6",
  "#26C6DA",
  "#26A69A",
  "#66BB6A",
  "#9CCC65",
  "#D4E157",
];

export const COLOR_STYLE_ENUM = {
  SOLID: "Solid",
  GRADIENT: "Gradient",
};

const getPalette = (color) => {
  if (Array.isArray(color)) {
    return color;
  } else {
    return [
      { offset: "0.000", color: "#000000", id: 1 },
      { offset: "1.000", color: "#ffffff", id: 2 },
    ];
  }
};


const WrappedSketchPicker = ({
  onSelect,
  ...rest
}) => {
  const rgbToRgba = (rgb, a = 1) =>
    rgb.replace("rgb(", "rgba(").replace(")", `, ${a})`);

  return (
    <SketchPicker
      {...rest}
      color={rgbToRgba(rest.color, rest.opacity)}
      // onChangeComplete={(color) => {
      //   handleColorChange(palette, colorStyle);
      // }}
      onChange={(c) => {
        //executeWithDebounce(500)(handleColorChange, { palette , colorStyle});
        //handleColorChange(palette, colorStyle);
        onSelect(c.hex, c.rgb.a);
      }}
    />
  );
};


function CustomColorPicker({
  classes,
  color,
  presetColors,
  handleColorChange,
  showColorText = true,
  styles = {},
  projectHigher = false,
  showAnglePicker = false,
  angle = 180,
  setAngle = () => null,
  maxStops = 4,
  paletteHeight,
  enableGradient = false,
}) {
  const [state, setState] = useState({
    anchorEl: null,
  });

  const [colorStyle, setcolorStyle] = React.useState(
    color && Array.isArray(color)
      ? COLOR_STYLE_ENUM.GRADIENT
      : COLOR_STYLE_ENUM.SOLID
  );
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [palette, setPalette] = useState(getPalette(color));

  const handleChange = (event) => {
    setcolorStyle(event.target.value);
  };

  useEffect(() => {
    if (colorStyle === COLOR_STYLE_ENUM.GRADIENT) {
      handleColorChange(palette, colorStyle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [palette]);


  return (
    <>
      <div
        className={classes.root}
        style={{ ...styles }}
        onClick={(e) => {
          setDisplayColorPicker(true);
          setState({
            anchorEl: e.currentTarget,
          });
        }}
      >
        <StyledColorSwatch
          style={{
            marginLeft: showColorText ? 1 : 0,
            boxShadow:
              "#ffffff".toLowerCase() === "#ffffff"
                ? "0 0 0 2px rgba(0, 0, 0, 0.13)"
                : "unset",
          }}
        >
          <StyledColor
            color={
              COLOR_STYLE_ENUM.SOLID === colorStyle
                ? color
                : palette.map((eachObject) => eachObject.color)
            }
            colorStyle={colorStyle}
          />
        </StyledColorSwatch>
        {showColorText && (
          <div
            style={{
              overflow: "hidden",
              fontWeight: 600,
              marginLeft: 12,
              fontFamily: FONT_FAMILY_REGULAR,
            }}
          >
            {(color || "#ffffff").substr(1).toUpperCase()}
          </div>
        )}
      </div>
      {displayColorPicker ? (
        <>
          <StyledCover
            onClick={() => {
              setDisplayColorPicker(false);
              setState({
                anchorEl: null,
              });
            }}
          />
          <Popper
            open={displayColorPicker}
            anchorEl={state.anchorEl}
            style={{
              zIndex: projectHigher ? 9999 : 99,
              background: "#FFFFFF",
              marginTop: 5,
            }}
            disablePortal
          >
            {enableGradient && (
              <div>
                <FormControl fullWidth>
                  <InputLabel>Color Style</InputLabel>
                  <Select
                    value={colorStyle}
                    onChange={handleChange}
                    label="Color Style"
                  >
                    <MenuItem value={COLOR_STYLE_ENUM.SOLID}>Single</MenuItem>
                    <MenuItem value={COLOR_STYLE_ENUM.GRADIENT}>
                      Gradient
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            )}

            {COLOR_STYLE_ENUM.SOLID === colorStyle && (
              <SketchPicker
                presetColors={presetColors || defaultPresetColors}
                color={color}
                onChangeComplete={(color) => {
                  handleColorChange(color);
                }}
              />
            )}
            {COLOR_STYLE_ENUM.GRADIENT === colorStyle && (
              <GradientPicker
                anchorEl={state.anchorEl}
                open={displayColorPicker}
                setOpen={setDisplayColorPicker}
                angle={angle}
                setAngle={setAngle}
                showAnglePicker={showAnglePicker}
                maxStops={maxStops}
                paletteHeight={paletteHeight}
                palette={palette}
                onPaletteChange={setPalette}
              >
                <WrappedSketchPicker
                  handleColorChange={handleColorChange}
                  colorStyle={colorStyle}
                  palette={palette}
                />
              </GradientPicker>
            )}
          </Popper>
        </>
      ) : null}
    </>
  );
}

CustomColorPicker.propTypes = {
  handleColorChange: PropTypes.func,
  color: PropTypes.string,
};

export default withStyles(styles)(CustomColorPicker);
