import React from "react";
import {
  Button,
  IconButton,
  LinearProgress,
  Paper,
  withStyles,
} from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Clear";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";
import { randomColorWithIndex } from "../../../../../../../../../utils";
import {
  EMPTY_CHOICE,
  EMPTY_QUESTION,
  selectedColor,
} from "../../../constants";
import { useTrackedState } from "../../../store";
import QuestionSimulator from "./QuestionSimulator";

const HEADER_ACTION_STYLES = {
  display: "flex",
  justifyContent: "space-between",
  margin: "8px auto",
};

const SUBMIT_BUTTON_STYLES = {
  margin: "16px auto 8px auto",
  display: "block",
  color: selectedColor(true),
};

function LinearProgressBar({ classes, progress, progress_bar_color }) {
  return (
    <LinearProgress
      value={progress}
      variant="determinate"
      classes={{
        barColorPrimary: classes.barColorPrimary,
        colorPrimary: classes.colorPrimary,
        root: classes.root,
      }}
    />
  );
}

const withStylesProps = styles => Component => props => {
  const Comp = withStyles(theme => styles({ ...props, ...theme }))(Component);
  return <Comp {...props} />;
};

const progressBarStyles = props => {
  return {
    colorPrimary: {
      backgroundColor: "#FFF",
    },
    barColorPrimary: {
      backgroundColor: props.progress_bar_color || "#b6bce2",
    },
    root: {
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
  };
};

const ColoredLinearProgressBar = withStylesProps(progressBarStyles)(
  LinearProgressBar
);

const DEFAULT_QUESTION = {
  ...EMPTY_QUESTION(),
  choices: [EMPTY_CHOICE(), EMPTY_CHOICE(), EMPTY_CHOICE(), EMPTY_CHOICE()],
};

export default function GlobalConfigSimulator() {
  const state = useTrackedState();

  const {
    ui: {
      is_response_based_survey = false,
      submit_active_color,
      submit_inactive_color,
      skip_text_color,
      cancel_button_color,
      previous_button_color,
      progress_bar_color,
      submit_text_color,
      is_skip_enabled = false,
      is_cancel_enabled = false,
      is_previous_enabled = false,
      show_progress_bar = false,
    },
    ui,
  } = state;

  return (
    <div
      style={{
        border: "5px solid #F0F2F4",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div style={{ position: "fixed" }}>
        <Paper elevation={0} style={{ marginTop: 100 }}>
          {show_progress_bar && (
            <ColoredLinearProgressBar
              progress={50}
              progress_bar_color={progress_bar_color}
            />
          )}
          <div style={{ padding: 8 }}>
            <div style={HEADER_ACTION_STYLES}>
              {is_cancel_enabled && (
                <IconButton style={{ color: cancel_button_color || undefined }}>
                  <RemoveIcon />
                </IconButton>
              )}
              {is_previous_enabled && (
                <IconButton
                  style={{ color: previous_button_color || undefined }}
                >
                  <KeyboardBackspace />
                </IconButton>
              )}
              {is_skip_enabled && (
                <Button style={{ color: skip_text_color || undefined }}>
                  <span style={{ textTransform: "none" }}>Skip</span>
                </Button>
              )}
            </div>
            <QuestionSimulator
              ui={ui}
              is_response_based_survey={is_response_based_survey}
              {...DEFAULT_QUESTION}
              handleAnswer={() => null}
              selectedId={DEFAULT_QUESTION.choices[2].id}
            />
            <Button
              variant="contained"
              style={{
                ...SUBMIT_BUTTON_STYLES,
                color: submit_text_color || undefined,
                background: submit_active_color || randomColorWithIndex(0),
              }}
            >
              Active Submit Button
            </Button>
            <Button
              variant="contained"
              style={{
                ...SUBMIT_BUTTON_STYLES,
                color: submit_text_color || undefined,
                background: submit_inactive_color || undefined,
              }}
            >
              Inactive Submit Button
            </Button>
          </div>
        </Paper>
      </div>
    </div>
  );
}
