import React, { useMemo, useState } from "react";
import BootstrapTooltip from "../../../../../../../../ReusableComponents/BootstrapTooltip";
import RowDiv from "../../../../../../../../ReusableComponents/RowDiv";
import EditIcon from "@material-ui/icons/Edit";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { IconButton } from "@material-ui/core";
import { generateQRCodeURL, openURLInNewTab } from "../../../utils";
import CustomMaterialUIDialog from "../../../../../../../../ReusableComponents/CustomMaterialUIDialog";
import ViewIDPathSelection from "../../common/ViewIDPathSelection";
import { useDispatch } from "../../../store";
import { SET_VIEWID_SELECTION_DIALOG_DATA } from "../../../constants";
import { CAMPAIGN_TYPE_ENUM } from "../../../../../../../common/components/campaigncreator/constants";
import WebLaunchURL from "../../common/WebLaunchURL";
import { useAuth } from "../../../../../../../../../contexts/AuthContext";
import ColumnDiv from "../../../../../../../../ReusableComponents/ColumnDiv";
import { useTrackedState } from "../../../../../../../common/components/campaigncreator/store";

export default function SimulatorActionsForWeb({ ui, appId }) {
  const {
    ui: { url = "", id = "", path = "" },
    type,
  } = ui;

  const campaignCreatorState = useTrackedState();
  const {
    config: {
      testing: { selected = "" },
    },
  } = campaignCreatorState;

  const auth = useAuth();

  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showOpenDialog, setShowOpenDialog] = useState(false);
  const [dialogUrl, setDialogUrl] = useState(url);

  const dispatch = useDispatch();

  const newVolatileID = useMemo(() => {
    return Math.random().toString(36).substring(2, 15);
  }, []);

  return (
    <RowDiv
      styles={{
        justifyContent: "flex-end",
        gap: 10,
        marginTop: 8,
        marginRight: 8,
      }}
    >
      <BootstrapTooltip title="Launch URL" placement="left">
        <IconButton
          onClick={() => {
            setShowOpenDialog(true);
          }}
        >
          <OpenInNewIcon color="primary" />
        </IconButton>
      </BootstrapTooltip>
      {type !== CAMPAIGN_TYPE_ENUM.IN_APP && (
        <BootstrapTooltip title="Edit" placement="left">
          <IconButton
            onClick={() => {
              setShowEditDialog(true);
            }}
          >
            <EditIcon color="primary" />
          </IconButton>
        </BootstrapTooltip>
      )}
      <CustomMaterialUIDialog
        dialogContent={
          <ViewIDPathSelection
            handleClose={() => setShowEditDialog(false)}
            handleProceed={(data) => {
              const options = {};
              if (data) {
                options.value = {
                  search_type: data.searchType,
                  is_wv: data.isForWebView,
                  wv_tag: data.webViewTag,
                  id: data.viewId,
                  path: data.path,
                  view_id: data.searchType === "" ? data.viewId : data.path,
                  activity: data.activity,
                  url: data.url,
                };
                options.provider = data.provider ?? "";
                options.blobName = data.blob_name ?? "";
              }
              dispatch({
                type: SET_VIEWID_SELECTION_DIALOG_DATA,
                options,
              });
              setShowEditDialog(false);
            }}
            props={{
              view_id: id,
              pathh: path,
              url,
              from_edit: true,
            }}
          />
        }
        maxWidth="sm"
        isFullWidth={false}
        paperStyles={{
          minWidth: "650px",
        }}
        openFlag={showEditDialog}
        onClose={() => setShowEditDialog(false)}
        onDialogClose={() => setShowEditDialog(false)}
      />
      <CustomMaterialUIDialog
        dialogContent={
          <ColumnDiv
            center
            styles={{
              margin: "10px 20px",
              marginBottom: 30,
            }}
          >
            <WebLaunchURL
              url={dialogUrl}
              setURL={setDialogUrl}
              launchCallback={() => {
                openURLInNewTab(
                  dialogUrl,
                  auth.appId,
                  selected === "" ? newVolatileID : selected,
                );
                setShowOpenDialog(false);
              }}
              generateQRCodeCallback={() => {
                const urlWithParams = generateQRCodeURL(
                  url,
                  appId,
                  selected === "" ? newVolatileID : selected,
                );
                return urlWithParams;
              }}
              title="Launch URL"
            />
          </ColumnDiv>
        }
        maxWidth="sm"
        openFlag={showOpenDialog}
        onClose={() => setShowOpenDialog(false)}
        onDialogClose={() => setShowOpenDialog(false)}
      />
    </RowDiv>
  );
}
