import { produce } from "immer";
export const UPDATE_PAGE_COUNTER = "UPDATE_PAGE_COUNTER";
export const SET_CAMPAIGNS = "SET_CAMPAIGNS";
export const PAGINATION_ACTION = "PAGINATION_ACTION";
export const SET_CAMPAIGN_KIND = "SET_CAMPAIGN_KIND";
export const SET_SELECTED_TAB = "SET_SELECTED_TAB";
export const SET_BEFORE_AFTER = "SET_BEFORE_AFTER";

export const initialState = {
         pagination: {
           after: null,
           before: null,
           limit: 10,
           pageCounter: 1,
           totalResults: null,
         },
         campaigns: [],
         campaignKind: "regular",
         selectedTab: 0,
       };

export const reducer = produce((draft, action) => {
  let { payload } = action;
  switch (action.type) {
    case UPDATE_PAGE_COUNTER:
      draft.pagination.pageCounter = payload.pageCounter;
      if (payload.pageCounter === 0) draft.pagination.before = null;
      else if (
        payload.pageCounter ===
        Math.ceil(draft.pagination.totalResults / draft.pagination.limit)
      )
        draft.pagination.after = null;
      break;
    case PAGINATION_ACTION:
      draft.pagination[payload.key] = payload.value;
      break;
    case SET_CAMPAIGNS:
      draft.campaigns = action.value;
      draft.pagination.totalResults = action.total;
      break;
    case SET_BEFORE_AFTER:
      draft.pagination.after = action.after;
      draft.pagination.before = action.before;
      break;
    case SET_CAMPAIGN_KIND:
      draft.campaignKind = action.value;
      break;
    case SET_SELECTED_TAB:
      draft.selectedTab = action.value;
      draft.pagination.after = null;
      draft.pagination.before = null;
      draft.pagination.pageCounter = 1;
      break;
    default:
      return draft;
  }
});
