import React from "react";
import { Grid, IconButton, withStyles } from "@material-ui/core";
import { useTracked } from "../../../../store";
import ActionsTextField from "../../../../../../../../../ReusableComponents/ActionsTextField";
import ColumnDiv from "../../../../../../../../../ReusableComponents/ColumnDiv";
import RowDiv from "../../../../../../../../../ReusableComponents/RowDiv";
import { SET_SUCCESS_MESSAGE } from "../../../../constants";
import success_message_icon from "../../../../assets/success_message.svg";

const styles = theme => ({
  gridItem: {},
});

/**
 * Contains all the config params related to Text Based Question
 */
function SuccessMessageSimulator({ classes, id }) {
  const [state, dispatch] = useTracked();

  const {
    ui: {
      show_success_message,
      success_message,
      success_message_bg_color,
      success_message_text_color,
      success_message_icon_color,
    },
  } = state;
  return (
    <ColumnDiv styles={{ height: 300, width: 250 }}>
      <ColumnDiv styles={{ justifyContent: "center", height: "100%" }}>
        <RowDiv styles={{ justifyContent: "center" }}>
          <IconButton
            component="span"
            style={{
              background: success_message_icon_color,
              height: 50,
              width: 50,
              marginBottom: 10,
            }}
          >
            <img src={success_message_icon} alt="apxor"></img>
          </IconButton>
        </RowDiv>
        <RowDiv styles={{ justifyContent: "center" }}>
          <ActionsTextField
            fullWidth
            required
            type="text"
            value={success_message}
            onChange={e => {
              dispatch({
                type: SET_SUCCESS_MESSAGE,
                id,
                value: e.target.value,
              });
            }}
            textFieldStyle={{
              minWidth: 200,
              color: success_message_text_color,
            }}
          />
        </RowDiv>
      </ColumnDiv>
    </ColumnDiv>
  );
}

export default withStyles(styles)(SuccessMessageSimulator);
