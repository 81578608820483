import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  withStyles,
} from "@material-ui/core";
import avg_session_length_per_user from "../images/avg_session_length_per_user.svg";
import avg_sessions_per_user from "../images/avg_sessions_per_user.svg";
import daily_active_users from "../images/daily_active_users.svg";
import day_0_uninstalls from "../images/day_0_uninstalls.svg";
import day_1_retention from "../images/day_1_retention.svg";
import percentage_change from "../images/percentage-change.svg";
import { formatNumber } from "../../../../../utils";
import moment from "moment";

function createData(
  img,
  field,
  today,
  yesterday,
  last_week,
  last_month,
  daily_users,
  optional_field
) {
  return {
    img,
    field,
    today,
    yesterday,
    last_week,
    last_month,
    optional_field,
  };
}

const findPercentage = (previous, today) => {
  if (previous === 0 && today !== 0) return "--";
  if (today === 0 && previous === 0) return "--";
  if (previous === 0) return "--";
  if (isNaN(previous) || isNaN(today)) {
    return "--";
  }
  if (previous !== 0) {
    const numb = ((today - previous) / previous) * 100;
    return numb.toFixed(2);
  }
};

const useStyles = makeStyles((theme) => ({
  img: {
    position: "relative",
    display: "inline",
  },
  field: {
    fontFamily: "Manrope",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "0.8vw",
    lineHeight: "0.5vw",
    textTransform: "capitalize",
    display: "inline",
    position: "relative",
    left: 10,
    top: -10,
  },
  optional_field: {
    display: "inline",
    position: "relative",
    left: 14,
    top: -10,
    fontWeight: "600",
    fontSize: "0.8vw",
    lineHeight: "0.5vw",
    color: "#8C9EAB",
  },
  today: {
    fontFamily: "Manrope",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "1.107vw",
    lineHeight: "23px",
    letterSpacing: "-1px",
    textTransform: "uppercase",
  },
  yesterday: {
    fontFamily: "Manrope",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "0.911vw",
    lineHeight: "19px",
    textTransform: "uppercase",
  },
  heading: {
    fontFamily: "Manrope",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "0.65vw",
    lineHeight: "15px",
    letterSpacing: "0.01em",
    opacity: "0.45",
    padding: "1vw",
  },
  tableRow: {
    "&:last-child th, &:last-child td": {
      borderBottom: 0,
    },
  },
}));

const StyledTableCell = withStyles({
  root: {
    borderBottom: "1px solid #EAECEE",
  },
})(TableCell);

export default function UserStatistics({
  d0_uninstalls,
  d1_retention,
  daily_users,
  sessions,
  avg_session_length,
}) {
  const classes = useStyles();
  const rows = [
    createData(
      daily_active_users,
      "Daily Active Users",
      formatNumber(daily_users.today),
      findPercentage(daily_users.yesterday, daily_users.today),
      findPercentage(daily_users.last_week, daily_users.today),
      findPercentage(daily_users.last_month, daily_users.today),
      daily_users
    ),
    createData(
      day_1_retention,
      "Day 1 Retention",
      isNaN(d1_retention.today) ? "0%" : `${d1_retention.today.toFixed(2)}%`,
      findPercentage(d1_retention.yesterday, d1_retention.today),
      findPercentage(d1_retention.last_week, d1_retention.today),
      findPercentage(d1_retention.last_month, d1_retention.today)
    ),
    createData(
      day_0_uninstalls,
      "Day 0 Uninstalls",
      isNaN(d0_uninstalls.today) ? "0%" : `${d0_uninstalls.today.toFixed(2)}%`,
      findPercentage(d0_uninstalls.yesterday, d0_uninstalls.today),
      findPercentage(d0_uninstalls.last_week, d0_uninstalls.today),
      findPercentage(d0_uninstalls.last_month, d0_uninstalls.today)
    ),

    createData(
      avg_sessions_per_user,
      "Average Sessions Per User",
      isNaN(sessions.today / daily_users.today)
        ? "0%"
        : (sessions.today / daily_users.today).toFixed(2),
      findPercentage(
        sessions.yesterday / daily_users.yesterday,
        sessions.today / daily_users.today
      ),
      findPercentage(
        sessions.last_week / daily_users.last_week,
        sessions.today / daily_users.today
      ),
      findPercentage(
        sessions.last_month / daily_users.last_month,
        sessions.today / daily_users.today
      )
    ),
    createData(
      avg_session_length_per_user,
      "Avg. Session Length/User",
      avg_session_length.today,
      findPercentage(avg_session_length.yesterday, avg_session_length.today),
      findPercentage(avg_session_length.last_week, avg_session_length.today),
      findPercentage(avg_session_length.last_month, avg_session_length.today),
      daily_users,
      "(sec)"
    ),
  ];

  const fontColor = (field, value) => {
    if (field === "Day 0 Uninstalls") {
      if (value >= 0) return "#DA1919";
      else return "#27A308";
    } else return value >= 0 ? "#27A308" : "#DA1919";
  };

  return (
    <TableContainer
      style={{
        height: 455,
        background: "#ffffff",
        border: "1px solid #EAECEE",
        borderRadius: "4px",
      }}
    >
      <Table sx={{ height: 366 }}>
        <TableHead style={{ height: 20 }}>
          <TableRow style={{ height: 10 }}>
            <StyledTableCell style={{ borderBottom: "none" }}></StyledTableCell>
            <StyledTableCell style={{ borderBottom: "none" }}></StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell
              className={classes.heading}
              style={{ padding: "0.5vw" }}
              align="center"
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>Today's</span>
                <img
                  color="secondary"
                  src={percentage_change}
                  height="13px"
                  width="13px"
                  style={{
                    marginInline: "2px",
                  }}
                  alt=""
                />
                <span>over</span>
              </Box>
            </StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell className={classes.heading}></StyledTableCell>
            <StyledTableCell className={classes.heading} align="center">
              Today
            </StyledTableCell>
            <StyledTableCell className={classes.heading} align="center">
              Yesterday
            </StyledTableCell>
            <StyledTableCell className={classes.heading} align="center">
              Last {moment().subtract(1, "weeks").format("ddd")}
            </StyledTableCell>
            <StyledTableCell className={classes.heading} align="center">
              {moment().subtract(1, "months").add(1, "days").format("MMM Do")}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.field} className={classes.tableRow}>
              <StyledTableCell component="th" scope="row">
                <img src={row.img} alt="img" className={classes.img} />
                <Typography className={classes.field}>{row.field}</Typography>
                <Typography className={classes.optional_field}>
                  {row.optional_field}
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography className={classes.today}>{row.today}</Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography
                  className={classes.yesterday}
                  style={{
                    color: fontColor(row.field, row.yesterday),
                  }}
                >
                  {row.yesterday}%
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography
                  className={classes.yesterday}
                  style={{
                    color: fontColor(row.field, row.last_week),
                  }}
                >
                  {row.last_week}%
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography
                  className={classes.yesterday}
                  style={{
                    color: fontColor(row.field, row.last_month),
                  }}
                >
                  {row.last_month}%
                </Typography>
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
