import { Typography } from "@material-ui/core";
import React from "react";
import { useTrackedState } from "../../../store";
import { TextHolder } from "../utils";

export default function OnboardingGlobalConfigSimulator() {
  const state = useTrackedState();
  const {
    onboardingState: {
      counter,
      nextPrevEnabled = false,
      next,
      prev,
      skipEnabled,
      skip,
      has_counter,
      carousal,
    },
  } = state;

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div style={{ maxWidth: 86, margin: 8 }}>
          <Typography
            variant={"h5"}
            style={{ color: "#fff", marginBottom: 10 }}
          >
            Pagination
          </Typography>
          <div
            style={{
              border: "2px dashed #abc",
              borderRadius: 3,
              padding: 2,
              textAlign: "center",
            }}
          >
            {has_counter && (
              <TextHolder
                config={{ ...counter, text: "1/X" }}
                bgColor={counter.bg_color || "#23282C"}
                isPure={true}
                style={{ margin: 8 }}
              />
            )}
            {carousal && carousal.dots && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  margin: 8,
                }}
              >
                <div
                  style={{
                    backgroundColor: carousal.d_acolor,
                    borderRadius: "25px",
                    width: 10,
                    height: 10,
                    marginRight: 4,
                  }}
                ></div>
                <div
                  style={{
                    backgroundColor: carousal.d_icolor,
                    borderRadius: "25px",
                    width: 10,
                    height: 10,
                    marginRight: 4,
                  }}
                ></div>
                <div
                  style={{
                    backgroundColor: carousal.d_icolor,
                    borderRadius: "25px",
                    width: 10,
                    height: 10,
                    marginRight: 4,
                  }}
                ></div>
              </div>
            )}
          </div>
        </div>
        {nextPrevEnabled && (
          <div style={{ maxWidth: 180, margin: 8 }}>
            <Typography
              variant={"h5"}
              style={{ color: "#fff", marginBottom: 10 }}
            >
              Next &amp; Prev Buttons
            </Typography>
            <div
              style={{
                border: "2px dashed #abc",
                borderRadius: 3,
                padding: 8,
                justifyItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyItems: "center",
                  justifyContent: "center",
                }}
              >
                <TextHolder
                  config={{ ...next, text: next.text || "Next" }}
                  bgColor={next.bg_color || "#23282C"}
                  isPure={true}
                  style={{ margin: 8 }}
                />
                <TextHolder
                  config={{ ...prev, text: prev.text || "Prev" }}
                  bgColor={prev.bg_color || "#23282C"}
                  isPure={true}
                  style={{ margin: 8 }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {skipEnabled && (
        <div style={{ maxWidth: 180 }}>
          <Typography
            variant={"h5"}
            style={{ color: "#fff", marginBottom: 10 }}
          >
            Skip
          </Typography>
          <div
            style={{
              border: "2px dashed #abc",
              borderRadius: 3,
              padding: 8,
              justifyItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyItems: "center",
                justifyContent: "center",
              }}
            >
              <TextHolder
                config={{ ...skip, text: skip.text || "Skip" }}
                bgColor={skip.bg_color || "#23282C"}
                isPure={true}
                style={{ margin: 8 }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
