import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import NoSsr from "@material-ui/core/NoSsr";
import MUISelect from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { useState, useMemo, useEffect } from "react";
import Select, { components as ReactSelectComponents } from "react-select";
import CreatableSelect from "react-select/creatable";
import { PALETTE } from "../ApplicationFrame/styling/CustomCSS";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: 36,
  },
  formControl: {
    //margin: theme.spacing(1),
    minWidth: 60,
    maxWidth: 300,
    minHeight: 36,
  },
  input: {
    display: "flex",
    padding: 0,
    // paddingLeft: 6,
    // minHeight: 40,
    height: "auto !important",
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
    overflow: "hidden",
    width: "calc(100% - 8px)",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  placeholder: {
    position: "absolute",
    left: 8,
    overflow: "hidden",
  },
  menuItemRoot: {
    overflow: "unset !important",
    color: theme.palette.text.primary,
    "&&&&:hover": {
      backgroundColor: theme.palette.primary.almostInvisible,
      borderLeft: `2px solid ${theme.palette.primary.main}`,
    },
    "&$selected": {
      backgroundColor: theme.palette.primary.almostInvisible,
      borderLeft: `2px solid ${theme.palette.primary.main}`,
    },
  },
  menuRoot: {
    width: "unset !important",
    minWidth: "100% !important",
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
          style: { ...(props.selectProps.inputStyles || {}) },
        },
      }}
      {...props.selectProps.textFieldProps}
      style={{ ...(props.selectProps.inputStyles || {}) }}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        overflow: "unset !important",
      }}
      {...props.innerProps}
      classes={{
        root: props.classes.menuItemRoot,
      }}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      variant="body1"
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      title={props.children}
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
      style={{ ...(props.selectProps.singleValueStyles || {}) }}
    >
      {props.children}
    </Typography>
  );
}

function CustomMenu(props) {
  return (
    <ReactSelectComponents.Menu
      {...props}
      className={props.selectProps.classes.menuRoot}
    >
      {props.children}
    </ReactSelectComponents.Menu>
  );
}

const components = {
  Control,
  NoOptionsMessage,
  Option: withStyles(styles, { withTheme: true })(Option),
  Placeholder,
  SingleValue,
  IndicatorSeparator: () => null,
  Menu: CustomMenu,
};

const ITEM_HEIGHT = 32;

const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

const getLabelForValue = (options = [], value, isGrouped = false) => {
  if (!isGrouped) {
    for (let i = 0; i < options.length; i++) {
      if (options[i].value === value && options[i].label) {
        return options[i].label;
      }
    }
  } else {
    for (let i = 0; i < options.length; i++) {
      const group = options[i];
      if (group.options) {
        for (let j = 0; j < group.options.length; j++) {
          const option = group.options[j];
          if (option.value === value && option.label) {
            return option.label;
          }
        }
      }
    }
  }
  return value;
};

function IntegrationReactSelect({ maxDisplayItems = 30, ...props }) {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [allOptions, setAllOptions] = useState(props.options || []);
  const [options, setOptions] = useState(
    (props.options || []).slice(0, maxDisplayItems)
  );

  const {
    classes,
    fullWidth = true,
    id = "react-select-chip" + Math.random(),
    value = [],
    handleChange,
    placeholder = "Select",
    name = "react-select-chip",
    onInputChange = () => null,
    single = false,
    disabled = false,
    style = { minWidth: "28px" },
    clearable,
    error = false,
    margin,
    label,
    withCheckbox = false,
    valueRenderer = (value) => value.join(", "),
    isCreatable = false,
    isRangeSelection = false,
    handleApply = () => null,
    isGrouped = false,
    isSearchable = true,
    formatGroupLabel = () => null,
    menuIsOpen,
    dropDownIconColor,
    defaultValue,
  } = props;

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const selectStyles = useMemo(
    () => ({
      input: (base) => ({
        ...base,
        color: props.theme.palette.text.primary,
        "& input": {
          font: "inherit",
        },
      }),
    }),
    [props.theme],
  );

  const valueSize = useMemo(() => (Array.isArray(value) ? value.length : 1), [
    value,
  ]);

  useEffect(() => {
    setAllOptions(props.options);
    if (!isGrouped) {
      setOptions(props.options.slice(0, maxDisplayItems));
    } else {
      const options = props.options;
      const updated = [];
      for (let i = 0; i < options.length; i++) {
        updated.push({
          ...options[i],
          options: options[i].options.slice(0, maxDisplayItems),
        });
      }
      setOptions(updated);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.options, maxDisplayItems]);

  if (withCheckbox && !isGrouped) {
    return (
      <FormControl
        style={{ ...style, maxWidth: fullWidth ? "100%" : undefined }}
        fullWidth={fullWidth}
        disabled={disabled}
        className={classes.formControl}
        margin={margin}
      >
        {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
        <MUISelect
          multiple
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          input={<Input id={id} placeholder={placeholder} />}
          renderValue={valueRenderer}
          MenuProps={MenuProps}
          open={open}
          onClose={(e) => {
            handleClose();
            handleApply(value);
          }}
          onOpen={handleOpen}
          displayEmpty
          defaultValue={defaultValue || []}
        >
          {options.map((option, index) => {
            const label = option.hasOwnProperty("label")
              ? option.label
              : option;
            const optValue = option.hasOwnProperty("value")
              ? option.value
              : option;
            const valueIndex = value.indexOf(optValue);
            const selected = valueIndex > -1;
            const disabled =
              isRangeSelection &&
              valueSize > 1 &&
              valueIndex > 0 &&
              valueIndex < valueSize - 1;
            return (
              <MenuItem
                disabled={disabled}
                selected={selected}
                key={label + "-" + optValue}
                value={optValue}
              >
                <Checkbox
                  color="primary"
                  disabled={disabled}
                  checked={selected}
                />
                <ListItemText title={label} primary={label} />
              </MenuItem>
            );
          })}
        </MUISelect>
      </FormControl>
    );
  }
  const selectValue = Array.isArray(value)
    ? value.map((v) => ({
        label: getLabelForValue(allOptions, v, isGrouped),
        value: v,
      }))
    : value !== null && value !== ""
    ? { label: getLabelForValue(allOptions, value, isGrouped), value: value }
    : value;

  const selectableProps = {
    classes: classes,
    style: selectStyles,
    styles: {
      dropdownIndicator: (provided, state) => ({
        ...provided,
        color: dropDownIconColor ? dropDownIconColor : PALETTE.text.secondary,
        transition: "all .2s ease",
        transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
      }),
    },
    inputStyles: props.inputStyles || {},
    singleValueStyles: props.singleValueStyles || {},
    textFieldProps: {
      label: label,
      fullWidth: fullWidth,
      InputLabelProps: {
        shrink: true,
      },
      error: error,
      margin: margin,
      disabled: disabled,
      name: name,
    },
    options: !isGrouped
      ? options.map(({ label, value, lingual }) => {
          return {
            label: lingual ? label + ` (${lingual})` : label,
            value: value,
          };
        })
      : options,
    components: components,
    value: selectValue,
    onChange: (obj) => {
      const actualValue = single
        ? obj
          ? obj.value || ""
          : ""
        : Array.isArray(obj)
        ? obj.map((o) => o.value)
        : [];
      handleChange(actualValue, single ? obj?.label : null);
    },
    placeholder: !label ? placeholder : null,
    isMulti: !single,
    isDisabled: disabled,
    isSearchable: isSearchable,
    menuIsOpen: menuIsOpen,
    menuPlacement: "auto",
    onInputChange: (inputValue) => {
      setInputValue(inputValue);
      if (
        !isGrouped &&
        Object.keys(options).length !== Object.keys(allOptions).length
      ) {
        setOptions(
          (
            allOptions.filter(
              (item) =>
                item.value.toLowerCase().includes(inputValue.toLowerCase()) ||
                item.label.toLowerCase().includes(inputValue.toLowerCase())
            ) || []
          ).slice(0, maxDisplayItems)
        );
      } else if (isGrouped) {
        const updated = [];
        for (let i = 0; i < allOptions.length; i++) {
          updated.push({
            ...allOptions[i],
            options: (
              allOptions[i].options.filter(
                (item) =>
                  item.value.toLowerCase().includes(inputValue.toLowerCase()) ||
                  item.label.toLowerCase().includes(inputValue.toLowerCase())
              ) || []
            ).slice(0, maxDisplayItems),
          });
        }
        setOptions(updated);
      }

      // FIXME: Not sure about this approach
      if (!isGrouped) {
        onInputChange(inputValue);
      }
    },
    createOptionPosition: "first",
    isClearable: clearable,
    inputValue: inputValue,
    formatGroupLabel: formatGroupLabel,
  };

  return (
    <div
      className={classes.root}
      style={style}
      onClick={(e) => e.stopPropagation()}
    >
      <NoSsr>
        {!isCreatable ? (
          <Select {...selectableProps} />
        ) : (
          <CreatableSelect {...selectableProps} />
        )}
      </NoSsr>
    </div>
  );
}

IntegrationReactSelect.propTypes = {
  classes: PropTypes.object,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  fullWidth: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  single: PropTypes.bool,
  isCreatable: PropTypes.bool,
  onInputChange: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  clearable: PropTypes.bool,
  error: PropTypes.bool,
  withCheckbox: PropTypes.bool,
  margin: PropTypes.string,
  valueRenderer: PropTypes.func,
  isRangeSelection: PropTypes.bool,
  handleApply: PropTypes.func, //Only for withCheckbox
};

export default withStyles(styles, { withTheme: true })(IntegrationReactSelect);
