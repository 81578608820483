import React, { useEffect, useMemo, useState } from "react";
import debounce from "lodash/debounce";
import {
  Button,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
  DATA_TYPES,
  DEFAULT_COMBINE_OPERATOR_OPTIONS,
  DEFAULT_DID_DID_NOT_OPTIONS,
  WEB_DID_DID_NOT_OPTIONS,
} from "../../../../constants";
import {
  getEventType,
  getOperatorString,
  removeLastChar,
} from "../../../../utils";
import EventSelector from "./EventSelector";
import OperatorSelector from "../../../../OperatorSelector";
import PropertyRow from "./PropertyRow";
import ColumnDiv from "../../../../../../../../../ReusableComponents/ColumnDiv";
import RowDiv from "../../../../../../../../../ReusableComponents/RowDiv";
import TruncatedText from "../../../../../../../../../ReusableComponents/TruncatedText";
import { useAuth } from "../../../../../../../../../../contexts/AuthContext";
import { useDateFilters } from "../../../../../../../utils";
import {
  getClientEventAttributesAPI,
  getEventAttributesAPI,
} from "../../../../../../../../../common/actions";
import ActionsTextField from "../../../../../../../../../ReusableComponents/ActionsTextField";
import {
  APP_PLATFORMS,
  DISPLAY_TEXT_ENUM,
  SET_SNACKBAR_ERROR_MESSAGE,
  TRIGGER_CONFIG,
} from "../../../../../constants";
import MultiSelect from "../../../../../../../../../ReusableComponents/MultiSelect";
import { PALETTE } from "../../../../../../../../../ApplicationFrame/styling/CustomCSS";
import CustomPopper from "../../../../../../../../../ReusableComponents/CustomPopper";
import { useCommonConfigStyles } from "../../../../../utils";
import { TYPOGRAPHY_CAPTION } from "../../../../../../../../../common/constants";
import { isExperienceDashboard } from "../../../../../../../../../../config";

const executeWithDebounce = debounce((fn, ...args) => {
  fn(...args);
}, 700);

const useStyles = makeStyles((theme) => ({
  input: (props) => ({
    maxHeight: 34,
  }),
  autoCompleteInputRoot: (props) => ({
    background: theme.palette.text.primary,
    borderRadius: 3,
    color: theme.palette.secondary.main,
    popoutIndicator: {
      "& span": {
        "& svg": {
          color: theme.palette.secondary.main,
        },
      },
    },
  }),
  autoCompletePopupIndicator: (props) => ({
    "& span": {
      "& svg": {
        color: theme.palette.secondary.main,
      },
    },
  }),
  closeIcon: (props) => ({
    color: "#A1ADB6",
    padding: 2,
    "&:hover": {
      background: "none",
    },
  }),
  gridItem: (props) => ({
    display: "flex",
  }),
  paperClass: (props) => ({
    overflow: "unset",
  }),
}));

const convertAttributeObjectToArray = (additional_info) => {
  return Object.keys(additional_info).map((attr, index) => {
    let values = additional_info[attr];
    if (typeof values !== "object") {
      values = {
        op: "EQ",
        t: "s",
        tl: DATA_TYPES.STRING,
        val: values,
      };
    }
    return {
      index,
      name: attr,
      ...values,
    };
  });
};

const convertAttributeArrayToObject = (additionalInfo) => {
  if (!Array.isArray(additionalInfo)) {
    return {};
  }

  return additionalInfo.reduce((acc, item) => {
    acc[item.name] = { op: item.op, val: item.val, t: item.t, tl: item.tl };
    return acc;
  }, {});
};

const getDefaultConditionConfig = (
  type,
  eventType,
  name,
  count_config,
  operator,
  count,
) => {
  return {
    type,
    activity: eventType === "ACTIVITY" || eventType === "SCREEN" ? name : "",
    context_rule_type: eventType === "SCREEN" ? "ACTIVITY" : eventType,
    event_type: getEventType(eventType, true),
    count_config: {
      ...count_config,
      operator: operator,
      count: count,
    },
  };
};

export const getDisplayText = (eventName) => {
  return DISPLAY_TEXT_ENUM[eventName] || eventName;
};

function EventRow({
  appId,
  condition,
  index: eventIndex,
  combineOperator,
  dispatch,
  sequenceEnabled,
  updateOperator,
  platform,
  features,
  handleOnChange,
  handleOnDelete,
  appEvents,
  clientEvents,
  appScreenNames,
  customScreenNames,
  onlyOrOperator = false,
}) {
  const auth = useAuth();
  const [dateFilters] = useDateFilters(true);

  const {
    details: {
      name: propName,
      additional_info,
      additional_info: { nkpi = [], et = "ae", time: propTime = 1000 },
    },
    context_rule_type = "APP",
    type: propType = "did",
    count_config,
    count_config: { operator: propOperator, count: propCount },
    trigger,
    trigger: {
      details: { name: triggerName, additional_info: triggerAdditionalInfo },
      details: triggerDetails,
    },
  } = condition;

  const [nameAnchorEl, setNameAnchorEl] = useState(null);
  const [operatorAnchorEl, setOperatorAnchorEl] = useState(null);
  const [typeAnchorEl, setTypeAnchorEl] = useState(null);

  const [name, setName] = useState(propType === "did" ? propName : triggerName);
  const [type, setType] = useState(propType);
  const [operator, setOperator] = useState(propOperator);
  const [count, setCount] = useState(propCount);
  const [eventType, setEventType] = useState(context_rule_type);

  const [disableAddButton, setDisableAddButton] = useState(false);

  const [time, setTime] = useState(propTime);
  const [nokpiAnchorEl, setNoKPIAnchorEl] = useState(null);

  const [attributes, setAttributes] = useState([]);
  const [allAttributes, setAllAttributes] = useState([]);
  const [additionalInfo, setAdditionalInfo] = useState(
    convertAttributeObjectToArray(
      type === "did" ? additional_info : triggerAdditionalInfo,
    ),
  );
  const classes = useStyles({ isDidnotProperty: type !== "did" });
  const typographyClasses = useCommonConfigStyles();

  const getEventAttributes = (name) => {
    getEventAttributesAPI(auth, appId, {
      ...dateFilters,
      of: "users",
      event: name,
    }).then((response) => {
      const filteredValues = response.filter(
        (name) =>
          ["apx_lt_count", "apx_lt_count_slot"].includes(name) ||
          name.indexOf("apx_") === -1,
      );
      setAttributes(filteredValues);
      setAllAttributes(filteredValues);
    });
  };

  const getClientEventAttributes = (name) => {
    getClientEventAttributesAPI(auth, appId, {
      ...dateFilters,
      event_name: name,
      platform,
    }).then((response) => {
      setAttributes(response);
      setAllAttributes(response);
    });
  };

  const eventOptions = useMemo(
    () => [
      {
        name: "App Events",
        options: appEvents
          .map((item) => ({ label: item, value: item }))
          .filter((item) => {
            return isExperienceDashboard()
              ? !item.value.startsWith("apx_")
              : true;
          }),
      },
      {
        name: "Client Events",
        options: clientEvents
          .map((item) => ({ label: item, value: item }))
          .filter((item) => {
            return isExperienceDashboard()
              ? !item.value.startsWith("apx_")
              : true;
          }),
      },
      {
        name: "Screen Names",
        options: appScreenNames
          .map((item) => ({ label: item, value: item }))
          .filter((item) => {
            return isExperienceDashboard()
              ? !item.value.startsWith("apx_")
              : true;
          }),
      },
      {
        name: "Custom Screen Names",
        options: customScreenNames
          .map((item) => ({
            label: item,
            value: item,
          }))
          .filter((item) => {
            return isExperienceDashboard()
              ? !item.value.startsWith("apx_")
              : true;
          }),
      },
    ],
    [appEvents, clientEvents, appScreenNames, customScreenNames],
  );

  const didNotDoEventOptions = useMemo(
    () => [
      {
        name: "App Events",
        options: appEvents
          .map((item) => ({
            label: item,
            value: item,
            et: "ae",
          }))
          .filter((item) => {
            return isExperienceDashboard()
              ? !item.value.startsWith("apx_")
              : true;
          }),
      },
      {
        name: "Client Events",
        options: clientEvents
          .map((item) => ({
            label: item,
            value: item,
            et: "ce",
          }))
          .filter((item) => {
            return isExperienceDashboard()
              ? !item.value.startsWith("apx_")
              : true;
          }),
      },
    ],
    [appEvents, clientEvents],
  );

  const handleDidnot = (
    noKPIEvents,
    et,
    time,
    newTrigger = trigger,
    newTriggerAdditionalInfo = additionalInfo,
  ) => {
    const event_type = getEventType(eventType, true);
    handleOnChange({
      ...condition,
      ...getDefaultConditionConfig(
        type,
        eventType,
        name,
        count_config,
        operator,
        count,
      ),
      trigger: {
        ...newTrigger,
        event_type: event_type,
        activity:
          event_type === "activity_event" || event_type === "screen_event "
            ? name
            : "",
        details: {
          ...triggerDetails,
          name: name,
          additional_info: convertAttributeArrayToObject(
            newTriggerAdditionalInfo,
          ),
        },
      },
      event_type: "activity_time",
      details: {
        name: "",
        additional_info: {
          nkpi: noKPIEvents,
          et: et,
          time: time,
        },
      },
    });
  };

  useEffect(() => {
    if (name !== "" && eventType !== "") {
      if (eventType !== "CLIENT") {
        getEventAttributes(name);
      } else {
        getClientEventAttributes(name);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      name !== propName ||
      type !== propType ||
      operator !== propOperator ||
      count !== propCount ||
      eventType !== context_rule_type
    ) {
      let additionalInfo = additional_info;
      if (name !== propName) {
        additionalInfo = {};
      }
      if (type === "did") {
        handleOnChange({
          ...condition,
          ...getDefaultConditionConfig(
            type,
            eventType,
            name,
            count_config,
            operator,
            count,
          ),
          details: {
            name: name,
            additional_info: additionalInfo,
          },
        });
      } else {
        let noKPIEvents = nkpi;
        let eventType = et;
        let newTime = time;
        let newTrigger = trigger;
        let newTriggerAdditionalInfo = additionalInfo;
        if (type !== propType) {
          noKPIEvents = [];
          eventType = "";
          newTime = 0;
          newTrigger = TRIGGER_CONFIG.trigger;
          newTriggerAdditionalInfo = [];
        }
        handleDidnot(
          noKPIEvents,
          eventType,
          newTime,
          newTrigger,
          newTriggerAdditionalInfo,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    name,
    type,
    count,
    propName,
    propType,
    operator,
    propOperator,
    propCount,
  ]);

  useEffect(() => {
    const newInfo = convertAttributeObjectToArray(
      type === "did" ? additional_info : triggerAdditionalInfo,
    );
    setAdditionalInfo(newInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additional_info, triggerAdditionalInfo]);

  useEffect(() => {
    if (eventType !== "CLIENT") {
      Array.isArray(allAttributes) &&
        setAttributes(
          allAttributes.filter(
            (attr) => Object.keys(additional_info).indexOf(attr) === -1,
          ),
        );
    } else if (eventType === "CLIENT") {
      const attributeKeys = Object.keys(additional_info);
      const updated = Object.keys(allAttributes).reduce((acc, item) => {
        if (attributeKeys.indexOf(item) === -1) {
          acc[item] = allAttributes[item];
        }
        return acc;
      }, {});
      setAttributes(updated);
    }
    if (type === "did") {
      handleOnChange({
        ...condition,
        details: {
          name: name,
          additional_info:
            eventType === "SCREEN"
              ? { screen: name }
              : convertAttributeArrayToObject(additionalInfo),
        },
      });
    } else {
      handleDidnot(nkpi, et, time);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalInfo]);

  const closeButton = (
    <Grid item xs={1} style={{ display: "flex", justifyContent: "center" }}>
      <IconButton
        onClick={() => handleOnDelete()}
        className={classes.closeIcon}
      >
        <CloseIcon />
      </IconButton>
    </Grid>
  );

  const propertiesSection = (
    <>
      <ColumnDiv
        styles={{
          borderLeft: "1px solid #C5CDD2",
          marginTop: -4,
          marginLeft: 45,
        }}
      >
        {additionalInfo.length > 0 &&
          additionalInfo.map((item) => (
            <PropertyRow
              key={item.name}
              index={item.index}
              appId={appId}
              eventType={eventType}
              name={item.name}
              platform={platform}
              features={features}
              property={item}
              eventName={name}
              isDidnotProperty={type !== "did"}
              handleOnChange={(data) => {
                const { attribute, op, tl, t, val } = data;
                const newInfo = [...additionalInfo];
                setDisableAddButton(
                  attribute === "" || val === "" || t === "" || tl === "",
                );
                newInfo[item.index] = {
                  index: item.index,
                  name: attribute,
                  op,
                  tl,
                  t,
                  val,
                };
                setAdditionalInfo([...newInfo]);
              }}
              handleOnDelete={() => {
                const newInfo = additionalInfo.filter(
                  (info) => item.index !== info.index,
                );
                setAdditionalInfo([...newInfo]);
                setDisableAddButton(false);
              }}
              attributes={attributes}
              allAttributes={allAttributes}
            />
          ))}
      </ColumnDiv>
      <ColumnDiv styles={{ marginLeft: 30, maxWidth: 130 }}>
        <Button
          disabled={disableAddButton || name === ""}
          variant="text"
          style={{ ...TYPOGRAPHY_CAPTION, color: "primary" }}
          color="primary"
          onClick={() => {
            const cannotAdd =
              (eventType === "CLIENT" && Object.keys(attributes).length < 1) ||
              attributes.length < 1;
            dispatch({
              type: SET_SNACKBAR_ERROR_MESSAGE,
              value: cannotAdd ? "No event properties left to add" : null,
            });

            if (cannotAdd) {
              return;
            }
            const newInfo = [
              ...additionalInfo,
              {
                index: additionalInfo.length,
                name: "",
                op: "EQ",
                tl: DATA_TYPES.STRING,
                t: "s",
                val: "",
              },
            ];
            setAdditionalInfo(newInfo);
          }}
        >
          + Add Property
        </Button>
      </ColumnDiv>
    </>
  );

  const eventSelectorPopover = (
    <CustomPopper anchorEl={nameAnchorEl} setAnchorEl={setNameAnchorEl}>
      <EventSelector
        placeholder="Select or Search Event"
        type={getEventType(eventType)}
        value={name}
        options={
          type === "did"
            ? !isExperienceDashboard()
              ? eventOptions
              : eventOptions.map((category) => {
                  if (category.name === "App Events") {
                    return {
                      name: "App Events",
                      options: category.options
                        .filter((evt) =>
                          Object.keys(DISPLAY_TEXT_ENUM).some(
                            (key) => key === evt.value,
                          ),
                        )
                        .map((evt) => ({
                          label: getDisplayText(evt.value),
                          value: evt.value,
                        })),
                    };
                  } else {
                    return category;
                  }
                })
            : eventOptions.filter(
                (item) =>
                  item.name === "Screen Names" ||
                  item.name === "Custom Screen Names",
              )
        }
        handleOnChange={(value, type) => {
          let typeDecoded = "APP";
          if (type === "Client Events") {
            typeDecoded = "CLIENT";
          } else if (type === "Screen Names") {
            typeDecoded = "ACTIVITY";
          } else if (type === "Custom Screen Names") {
            typeDecoded = "SCREEN";
          }
          setEventType(typeDecoded);
          setName(value || "");
          if (eventType !== type || value !== name) {
            setAttributes([]);
            setAdditionalInfo([]);
            setDisableAddButton(false);
          }
          if (value && value !== "") {
            if (typeDecoded === "CLIENT") {
              getClientEventAttributes(value);
            } else if (typeDecoded === "APP") {
              getEventAttributes(value);
            }
          }
        }}
      />
    </CustomPopper>
  );

  return (
    <ColumnDiv>
      {eventIndex > 0 && (
        <RowDiv styles={{ margin: "10px 0px", marginLeft: -15 }}>
          <MultiSelect
            options={
              onlyOrOperator
                ? [DEFAULT_COMBINE_OPERATOR_OPTIONS[0]]
                : DEFAULT_COMBINE_OPERATOR_OPTIONS
            }
            value={
              sequenceEnabled
                ? DEFAULT_COMBINE_OPERATOR_OPTIONS[2].value
                : combineOperator
            }
            handleChange={(value) => {
              updateOperator(
                value === "AND_THEN" ? "AND" : value,
                value === "AND_THEN",
              );
            }}
            single
            isSearchable={false}
            clearable={false}
            style={{ minWidth: 140, maxWidth: 140 }}
            dropDownIconColor={PALETTE.secondary.main}
            singleValueStyles={{
              ...TYPOGRAPHY_CAPTION,
              color: PALETTE.secondary.main,
            }}
            inputStyles={{
              background: PALETTE.text.primary,
              borderRadius: 3,
            }}
          />
        </RowDiv>
      )}
      <ColumnDiv>
        <RowDiv center>
          <div style={{ width: 30, borderBottom: "1px solid #C5CDD2" }}></div>
          <Grid container spacing={1}>
            <Grid item xs={type !== "did" ? 7 : 11}>
              <Grid
                container
                spacing={1}
                style={{ background: "#F0F2F3", alignItems: "center" }}
              >
                <Grid item style={{ marginLeft: 10 }}>
                  <Typography className={typographyClasses.typographyCaption}>
                    {eventIndex !== 0 ? "Users who" : "When user"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  className={classes.gridItem}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <TruncatedText
                    value={type}
                    onClick={(e) => e && setTypeAnchorEl(e.currentTarget)}
                  />
                  <CustomPopper
                    anchorEl={typeAnchorEl}
                    setAnchorEl={setTypeAnchorEl}
                    paperClass={classes.paperClass}
                  >
                    <MultiSelect
                      value={type}
                      handleChange={(value) => {
                        setType(value);
                      }}
                      options={
                        platform === APP_PLATFORMS.web
                          ? WEB_DID_DID_NOT_OPTIONS
                          : DEFAULT_DID_DID_NOT_OPTIONS
                      }
                      single
                      clearable={false}
                      isSearchable={false}
                      menuIsOpen={true}
                      style={{ minWidth: 104, maxWidth: 104 }}
                    />
                  </CustomPopper>
                </Grid>
                {type === "did" && (
                  <>
                    <Grid
                      item
                      className={classes.gridItem}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <TruncatedText
                        dontTruncate={isExperienceDashboard()}
                        tooltipTitle={name}
                        placeholder={"Event Name"}
                        value={
                          isExperienceDashboard()
                            ? getDisplayText(name)
                            : name === ""
                            ? name
                            : removeLastChar(getEventType(eventType)) +
                              ": " +
                              name
                        }
                        onClick={(e) => e && setNameAnchorEl(e.currentTarget)}
                      />
                      {eventSelectorPopover}
                    </Grid>
                    {name !== "" && (
                      <>
                        <Grid item className={classes.gridItem}>
                          <Typography
                            className={typographyClasses.typographyCaption}
                          >
                            for
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          className={classes.gridItem}
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <TruncatedText
                            value={getOperatorString(operator)}
                            onClick={(e) =>
                              e && setOperatorAnchorEl(e.currentTarget)
                            }
                          />
                          <CustomPopper
                            anchorEl={operatorAnchorEl}
                            setAnchorEl={setOperatorAnchorEl}
                            paperClass={classes.paperClass}
                          >
                            <OperatorSelector
                              selected={operator}
                              handleOnChange={(value) => setOperator(value)}
                            />
                          </CustomPopper>
                        </Grid>
                        <Grid item className={classes.gridItem}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <TextField
                              type="number"
                              required
                              placeholder="2"
                              value={count}
                              style={{
                                maxWidth: 60,
                                marginBottom: 0,
                                marginRight: 6,
                              }}
                              onChange={(e) => {
                                const value = Number(e.target.value);
                                setCount(value);
                              }}
                              margin="normal"
                              InputProps={{
                                className: classes.input,
                              }}
                            />
                            <Typography
                              className={typographyClasses.typographyCaption}
                              style={{
                                display: "inline-block",
                              }}
                            >
                              {" "}
                              time (s)
                            </Typography>
                          </div>
                        </Grid>
                      </>
                    )}
                  </>
                )}
                {type !== "did" && (
                  <>
                    <Grid item className={classes.gridItem}>
                      <Typography
                        className={typographyClasses.typographyCaption}
                      >
                        perform
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      className={classes.gridItem}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <TruncatedText
                        dontTruncate={isExperienceDashboard()}
                        tooltipTitle={nkpi.join(", ")}
                        placeholder={"Events"}
                        value={
                          !isExperienceDashboard()
                            ? nkpi.join(", ")
                            : nkpi.map((val) => getDisplayText(val)).join(", ")
                        }
                        onClick={(e) => e && setNoKPIAnchorEl(e.currentTarget)}
                      />
                      <CustomPopper
                        anchorEl={nokpiAnchorEl}
                        setAnchorEl={setNoKPIAnchorEl}
                      >
                        <EventSelector
                          placeholder="Select or Search Event"
                          multiple={true}
                          disableClearable={false}
                          disableOtherGroups={true}
                          type={et === "ae" ? "App Events" : "Client Events"}
                          value={nkpi.map((item) => ({
                            label: item,
                            value: item,
                            et,
                            type: et === "ae" ? "App Events" : "Client Events",
                          }))}
                          options={
                            !isExperienceDashboard()
                              ? didNotDoEventOptions
                              : didNotDoEventOptions.map((category) => {
                                  if (category.name === "App Events") {
                                    return {
                                      name: "App Events",
                                      options: category.options
                                        .filter((evt) =>
                                          Object.keys(DISPLAY_TEXT_ENUM).some(
                                            (key) => key === evt.value,
                                          ),
                                        )
                                        .map((evt) => ({
                                          label: getDisplayText(evt.value),
                                          value: evt.value,
                                        })),
                                    };
                                  } else {
                                    return category;
                                  }
                                })
                          }
                          handleOnChange={(value) => {
                            const noKPIEvents = value.map((item) => item.value);
                            const eventType =
                              value.length > 0 ? value[0].et : "ae";
                            handleDidnot(noKPIEvents, eventType, time);
                          }}
                        />
                      </CustomPopper>
                    </Grid>
                    <Grid item className={classes.gridItem}>
                      <Typography
                        className={typographyClasses.typographyCaption}
                        style={{ lineHeight: "unset" }}
                      >
                        within
                      </Typography>
                    </Grid>
                    <Grid item className={classes.gridItem}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <ActionsTextField
                          value={time / 1000}
                          style={{
                            maxWidth: 72,
                            marginRight: 5,
                            marginBottom: 0,
                          }}
                          InputProps={{ min: 1, className: classes.input }}
                          onChange={(e) => {
                            const value = Number(e.target.value) * 1000;
                            setTime(value);
                            executeWithDebounce(handleDidnot, nkpi, et, value);
                          }}
                          type="number"
                        />
                        <Typography
                          className={typographyClasses.typographyCaption}
                          style={{ display: "inline-block" }}
                        >
                          {" "}
                          second (s)
                        </Typography>
                      </div>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            {type !== "did" && nkpi.length > 0 && (
              <Grid item xs={5} style={{ display: "flex" }}>
                <Grid
                  item
                  xs={11}
                  style={{
                    background: "#F0F2F3",
                    alignItems: "center",
                    borderLeft: "8px solid #fff",
                    display: "flex",
                  }}
                >
                  <Typography
                    className={typographyClasses.typographyCaption}
                    style={{ margin: "0px 10px" }}
                  >
                    after
                  </Typography>
                  <ColumnDiv styles={{ width: "100%", overflowY: "scroll" }}>
                    <TruncatedText
                      placeholder={"Events"}
                      value={
                        name === ""
                          ? name
                          : getEventType(eventType) + ": " + name
                      }
                      onClick={(e) => e && setNameAnchorEl(e.currentTarget)}
                      styles={{ width: "95%", maxWidth: "unset" }}
                    />
                    {eventSelectorPopover}
                  </ColumnDiv>
                </Grid>
                {closeButton}
              </Grid>
            )}
            {(type === "did" || (type !== "did" && nkpi.length < 1)) &&
              closeButton}
          </Grid>
        </RowDiv>
        {type === "did" &&
          eventType !== "ACTIVITY" &&
          eventType !== "SCREEN" &&
          propertiesSection}
        {type !== "did" &&
          eventType !== "ACTIVITY" &&
          eventType !== "SCREEN" &&
          nkpi.length > 0 && (
            <Grid container spacing={1} style={{ marginTop: 0 }}>
              <Grid item xs={7}></Grid>
              <Grid item xs={5}>
                {propertiesSection}
              </Grid>
            </Grid>
          )}
      </ColumnDiv>
    </ColumnDiv>
  );
}

export default React.memo(EventRow);
