import {
  Button,
  Grid,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { isExperienceDashboard } from "../../../../../config";
import { useDeepCompareEffect } from "../../../../../utils/use-deep-compare";
import { apxTheme } from "../../../../ApplicationFrame/styling/CustomCSS";
import MultiSelect from "../../../../ReusableComponents/MultiSelect";
import { CAMPAIGNS_HOLDER_TABS } from "../../utils";
import GeneralSummaryComponent from "../campaigncreator/components/GeneralSummaryComponent";
import { getRouteForActionType } from "../campaigncreator/utils";
import CampaignsListing from "./components/CampaignsListing";
import { LISTING_OPTIONS } from "./constants";
import { SET_CAMPAIGN_KIND, SET_SELECTED_TAB } from "./reducer";
import { Provider, useTracked } from "./store";

const styles = (theme) => ({
  content: {
    padding: "25px 10%",
    height: "auto",
  },
  tabsRoot: {
    background: "#fff",
    flex: "1 0 auto",
  },
  tabsIndicator: {
    height: 3,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
  },
  tabRoot: {
    textTransform: "initial",
    minWidth: 72,
    fontSize: 16,
    marginRight: theme.spacing(4),
    "&:focus": {
      // color: '#40a9ff',
    },
  },
  typography: {
    fontWeight: 600,
  },
});

// const sortCampaigns = (response) => {
//   const messagesList = response.messages.reverse();
//   const updatedMessagesList = messagesList.map((messageItem, index) => {
//     const overall_cfg = messageItem.overall_cfg;
//     if (
//       overall_cfg &&
//       Array.isArray(overall_cfg.events) &&
//       overall_cfg.events.length > 0
//     ) {
//       overall_cfg["events"] = overall_cfg.events.filter(
//         (item) => item.hasOwnProperty("name") && item.hasOwnProperty("op")
//       );
//     }

//     let track = messageItem.track;
//     if (!track || Object.keys(track).length < 1) {
//       // FIXME: This is not the right approach. Not sure what to do :-(
//       const dateTimeStr = moment()
//         .add(-(index + 1), "minute")
//         .toISOString();
//       track = {
//         created_at: dateTimeStr,
//         updated_at: dateTimeStr,
//       };
//     }

//     if (track && track.created_at && !track.updated_at) {
//       track.updated_at = track.created_at;
//     }

//     return {
//       ...messageItem,
//       overall_cfg,
//       track,
//       ui: messageItem.ui ? messageItem.ui : {},
//       uis: messageItem.uis ? messageItem.uis : [],
//     };
//   });

//   if (response.hasOwnProperty("messages") && response.messages.length > 0) {
//     return {
//       campaigns: updatedMessagesList.sort((a, b) => {
//         if (moment(a.track.updated_at) < moment(b.track.updated_at)) {
//           return 1;
//         }
//         return -1;
//       }),
//       metrics: response.metrics,
//     };
//   }
//   return {
//     campaigns: [],
//     metrics: {},
//   };
// };

function CampaignsHolder({
  app,
  actionType,
  classes,
  configType = [],
  designSummaryComponent,
  generalSummaryComponent = <GeneralSummaryComponent />,
  title = "Campaigns",
  isExpermentPage,
}) {
  const { location, push } = useHistory();
  const { app_id: appId } = app;
  const [state, dispatch] = useTracked();
  const { selectedTab, campaignKind } = state;

  const [count, setRefreshCount] = useState(0);
  const [query, setQuery] = useState("");
  const [tabIndex, setTabIndex] = useState(
    location?.state?.postPublish && isExperienceDashboard() ? 1 : selectedTab,
  );

  useDeepCompareEffect(() => {
    if (isExpermentPage) {
      dispatch({
        type: SET_CAMPAIGN_KIND,
        value: "ab",
      });
    }
    if (location?.state?.postPublish && isExperienceDashboard()) {
      setTabIndex(1);
      dispatch({
        type: SET_SELECTED_TAB,
        value: 1,
      });
    }
  }, [dispatch, isExpermentPage, location]);

  return (
    <section className="content">
      <Grid container spacing={1}>
        <Grid item xs={4}>
          {title === "Surveys" ||
          (title === "Campaigns" && isExperienceDashboard()) ? (
            <Typography
              variant="h4"
              color="primary"
              classes={{ root: classes.typography }}
              style={{ color: apxTheme.palette.text.primary }}
            >
              {title}
            </Typography>
          ) : (
            <MultiSelect
              single
              clearable={false}
              isSearchable={false}
              options={LISTING_OPTIONS}
              value={campaignKind}
              variant="outlined"
              handleChange={(value) => {
                dispatch({
                  type: SET_CAMPAIGN_KIND,
                  value: value,
                });
              }}
              sx={{
                maxWidth: 250,
                fontSize: 30,
                fontWeight: 500,
              }}
            />
          )}
        </Grid>
        <Grid
          item
          xs={8}
          md
          style={{
            justifyContent: "flex-end",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <TextField
            placeholder={`Search ${title}`}
            value={query}
            InputProps={{
              startAdornment: (
                <InputAdornment position="end" style={{ padding: "20px 0" }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setQuery(e.target.value)}
            style={{ marginRight: 8 }}
          />
          <Button
            variant="contained"
            size="small"
            color="primary"
            aria-label="create-new"
            style={{ height: 42, fontSize: 16, fontWeight: 800 }}
            onClick={() => {
              push(`/apps/${appId}/${getRouteForActionType(actionType)}/new`);
            }}
          >
            <AddIcon style={{ marginRight: 6 }} />
            Create {title === "Surveys" ? "Survey" : "Campaign"}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Tabs
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
            value={tabIndex}
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, value) => {
              setTabIndex(value);
              dispatch({
                type: SET_SELECTED_TAB,
                value: value,
              });
            }}
          >
            {Object.keys(CAMPAIGNS_HOLDER_TABS)
              .filter((each) => each !== "AB_CAMPAIGN_PUBLISHED")
              .map((eachSection) => (
                <Tab
                  disableRipple
                  key={eachSection}
                  classes={{ root: classes.tabRoot }}
                  label={CAMPAIGNS_HOLDER_TABS[eachSection]}
                />
              ))}
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <CampaignsListing
            title={title}
            app={app}
            configType={configType}
            actionType={actionType}
            designSummaryComponent={designSummaryComponent}
            generalSummaryComponent={generalSummaryComponent}
            query={query}
            refreshCampaigns={() => setRefreshCount(count + 1)}
          />
        </Grid>
      </Grid>
    </section>
  );
}

function CampaignsHolderWithProvider({
  app,
  actionType,
  classes,
  configType = [],
  designSummaryComponent,
  generalSummaryComponent = <GeneralSummaryComponent />,
  title = "Campaigns",
  isExpermentPage,
}) {
  return (
    <Provider>
      <CampaignsHolder
        app={app}
        actionType={actionType}
        classes={classes}
        configType={configType}
        designSummaryComponent={designSummaryComponent}
        generalSummaryComponent={generalSummaryComponent}
        title={title}
        isExpermentPage={isExpermentPage}
      />
    </Provider>
  );
}

export default withStyles(styles)(CampaignsHolderWithProvider);
