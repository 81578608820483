import axios from "axios";
import { DEMO_ECOMMERCE_APP_ID } from "../../../config";
import {
  makeGetRequest,
  makeDeleteRequest,
  makePatchRequest,
  makePostRequest,
} from "../../common/actions";

//const ART_CONFIGS_META = "art-configs/meta";
const ART_CONFIGS_META = "art-configs/paginated/meta";
const DEMO_ART_CONFIGS = "demo/art-configs/templates";
const ART_CONFIG = "art-config";
const GET_COHORTS_API = "cohorts";
const VERSIONS_API = "versions";
const TEST_DEVICES_API = "test-devices";
const EMAIL_TESTING_LINK_API = "test-devices/mail";
const DEMO_TEST_DEVICES_API = "demo/test-devices";
const ATTRIBUTE_DISTRIBUTION_API = "distribution";
const VARIANT_PUBLISH_API = "art-configs/variant/publish/manual";
const SCHEDULED_CANCEL_API = "art-configs/schedule/cancel";

export const getAppVersions = (auth, appId) => {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makeGetRequest(
    VERSIONS_API,
    auth,
    appId,
    null,
    onSuccessCallback,
    onFailureCallback
  );
};

export const getCohorts = (auth, appId) => {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makeGetRequest(
    GET_COHORTS_API,
    auth,
    appId,
    null,
    onSuccessCallback,
    onFailureCallback
  );
};

export function getTestDevicesAPI(
  auth,
  appId,
  queryParams,
  isExperienceDashboard = false
) {
  const onSuccessCallback = (json) => {
    return Array.isArray(json) ? json : [];
  };
  const onFailureCallback = (response) => {
    return [];
  };
  return makeGetRequest(
    isExperienceDashboard ? DEMO_TEST_DEVICES_API : TEST_DEVICES_API,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}

export function removeTestDeviceAPI(auth, appId, deviceId, queryParams) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return false;
  };
  return makeDeleteRequest(
    `${TEST_DEVICES_API}/${deviceId}`,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}

export function updateTestDeviceAPI(
  auth,
  appId,
  deviceId,
  queryParams,
  postBody
) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return false;
  };
  return makePatchRequest(
    `${TEST_DEVICES_API}/${deviceId}`,
    auth,
    appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback
  );
}

export function updateDemoTestDeviceAPI(auth, queryParams, postBody) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return false;
  };
  return makePostRequest(
    "demo/test-device",
    auth,
    DEMO_ECOMMERCE_APP_ID,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getTopEventsAPI(auth, appId, queryParams, filters) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return [];
  };
  return makePostRequest(
    ATTRIBUTE_DISTRIBUTION_API,
    auth,
    appId,
    queryParams,
    filters,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getCampaignsAPI(
  auth,
  appId,
  queryParams,
  enableCancellation = true
) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };

  return makeGetRequest(
    ART_CONFIGS_META,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback,
    enableCancellation
  );
}

export function getDemoCampaignsAPI(
  auth,
  appId,
  queryParams,
  enableCancellation = true
) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };

  return makeGetRequest(
    DEMO_ART_CONFIGS,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback,
    enableCancellation
  );
}

export function getCampaignAPI(auth, appId, campaignId, queryParams) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };

  return makeGetRequest(
    ART_CONFIG + "/" + campaignId,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}

export function addTestDeviceAPI(auth, appId, deviceId, queryParams, postBody) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return false;
  };
  return makePostRequest(
    TEST_DEVICES_API,
    auth,
    appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback
  );
}

const GET_SIGNED_URL_API = "campaign-image-url";

export function getSignedURLToPost(
  auth,
  appId,
  queryParams,
  enableCancellation = false
) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    GET_SIGNED_URL_API,
    auth,
    appId,
    queryParams,
    {},
    onSuccessCallback,
    onFailureCallback,
    null,
    enableCancellation
  );
}

export function getSignedURLToGetImage(
  auth,
  appId,
  queryParams,
  enableCancellation = false
) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makeGetRequest(
    GET_SIGNED_URL_API,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback,
    enableCancellation
  );
}

export function uploadImageUsingSignedURL(
  signedURL,
  file,
  onSuccessCallback,
  onFailureCallback
) {
  const formData = new FormData();
  formData.append("image", file);

  axios({
    method: "post",
    url: signedURL,
    data: formData,
  })
    .then(function (response) {
      if (onSuccessCallback) {
        onSuccessCallback(response);
      }
    })
    .catch(function (response) {
      if (onFailureCallback) {
        onFailureCallback(response);
      }
    });
}

export function manualVariantPublish(auth, queryParams, postBody) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };

  return makePatchRequest(
    VARIANT_PUBLISH_API,
    auth,
    auth.appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback,
    "v1/sandbox/"
  );
}

export function convertToManualPublishAPI(auth, queryParams) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };

  return makeDeleteRequest(
    VARIANT_PUBLISH_API,
    auth,
    auth.appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}

export function cancelScheduledPublishAPI(auth, queryParams, postBody = {}) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };

  return makePatchRequest(
    SCHEDULED_CANCEL_API,
    auth,
    auth.appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback
  );
}

export function triggerTestEmails(
  auth,
  queryParams,
  postBody = { email_ids: [] }
) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    EMAIL_TESTING_LINK_API,
    auth,
    auth.appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback
  );
}
