import React from "react";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import { hexToRGBA } from "../../../../../../../../../../../utils";
import { MODULE_TYPES, TOOLTIP_LAYOUT_TYPES } from "../../../../../constants";
import { getLayoutBasedOnPosition, HoverDiv, StyledDiv } from "../../../utils";

export default function TooltipContainer({
  children,
  ui: {
    corners: { enabled: cornersEnabled, width = 8 },
    border = {},
    position,
    dim_background = false,
    dim_background_opacity = 0.6,
    dim_background_color = "#5c5c5c",
    bg_color,
    layout_type,
  },
  moduleType,
  onModuleSelection,
}) {
  return (
    <div
      id="inline-container"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 48,
        borderRadius: 8,
        background: dim_background
          ? hexToRGBA(dim_background_color, dim_background_opacity)
          : "transparent",
        ...getLayoutBasedOnPosition(position),
        gap: 14,
      }}
    >
      <HoverDiv
        style={{
          border:
            moduleType === MODULE_TYPES.BACKGROUND ? "1px dashed #2979ff" : "",
        }}
        onClick={(e) => {
          onModuleSelection(e, MODULE_TYPES.BACKGROUND);
        }}
      >
        <StyledDiv
          bgColor={bg_color}
          cornerRadius={cornersEnabled ? width : 8}
          position={position}
          border={
            layout_type === TOOLTIP_LAYOUT_TYPES.INLINE_WITH_DOT
              ? border
              : undefined
          }
          style={{ margin: "unset", border: "unset" }}
        >
          {children}
        </StyledDiv>
      </HoverDiv>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            background: "#ebebed",
            width: 48,
            height: 48,
            borderRadius: 4,
            zIndex: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ThumbUpIcon style={{ color: "#757575" }} />
        </div>
      </div>
    </div>
  );
}

