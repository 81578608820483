import React, { useState, useEffect } from "react";
import Box from "../../../../ReusableComponents/Box";
import Switch from "../../../../ReusableComponents/Switch";
import Loading from "../../../../ReusableComponents/Loading";
import {
  Dialog as MaterialDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Menu,
  MenuItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
  Snackbar as MaterialSnackbar,
} from "@material-ui/core";
import HighCharts from "highcharts";
import patternFill from "highcharts/modules/pattern-fill";
import HighChartsReact from "highcharts-react-official";
import { goToByScroll, roundOffNumber } from "../../../../../utils";
import { createCohortFromFunnelAPI } from "../actions";
import { REFRESH_COHORTS, SET_GROUP } from "../reducer";
import { useTracked } from "../store";
import {
  FunnelBehaviorEnum,
  FUNNEL_GRAPH_ID,
  transformToHighCharts,
  getStepIndex,
} from "../constants";

export default function FunnelGraph({
  auth,
  appId,
  queryParams,
  funnels = [],
  filters,
  conversionRate: conversion_rate,
}) {
  const [state, setState] = useState({
    showGraphContextMenu: false,
    graphContextEl: null,
    funnelBehaviorType: FunnelBehaviorEnum.DROPPED,
    showCohortCreationModal: false,
    cohortName: "",
    funnelSelectedStep: null,
  });

  const [funnelState, dispatch] = useTracked();

  const [showFunnelDialog, setShowFunnelDialog] = useState(false);
  const [createCohortPending, setCreateCohortPending] = useState(false);
  const [showFunnelSnackbar, setShowFunnelSnackbar] = useState(false);
  const [snackBarMessage, setSnackbarMessage] = useState("");

  // Scroll the page to this component on ComponentWillMount
  useEffect(() => {
    goToByScroll(FUNNEL_GRAPH_ID);
  }, []);

  const showContextMenu = (element, behavior, stepIndex) => {
    if (stepIndex !== 0) {
      setState({
        ...state,
        graphContextEl: element,
        funnelBehaviorType: behavior,
        funnelSelectedStep: stepIndex,
      });
    }
  };

  // TODO: Not sure if we can memoize this function. Look for the possibility
  const dismissModal = () => {
    setState({
      ...state,
      showCohortCreationModal: false,
      cohortName: "",
    });
    toggleDialog();
  };

  // FIXME: Use `useCallback` to memoize this function
  const toggleDialog = () => {
    setShowFunnelDialog(!showFunnelDialog);
  };

  const toggleFunnelSnackbar = (status, message = "") => {
    setShowFunnelSnackbar(status);
    setSnackbarMessage(status ? message : "");
  };

  const createCohortFromFunnel = (funnelConfig) => {
    setCreateCohortPending(true);
    createCohortFromFunnelAPI(
      auth,
      appId,
      { ...queryParams, ...funnelConfig },
      {
        ...filters,
        group_by: funnelState.groupBy || [],
      }
    ).then((response) => {
      if (response) {
        dispatch({
          type: REFRESH_COHORTS,
        });
        toggleDialog();
        toggleFunnelSnackbar(
          true,
          "Cohort Created Successfully (You can find it in Cohort Dropdown)"
        );
      } else {
        toggleFunnelSnackbar(true, "Cohort Creation Failed");
      }
      setCreateCohortPending(false);
    });
  };

  const chartOptions = transformToHighCharts(funnels, showContextMenu);
  const {
    graphContextEl,
    funnelBehaviorType,
    cohortName,
    funnelSelectedStep,
  } = state;

  return (
    <div id={FUNNEL_GRAPH_ID}>
      <Box
        title="Funnel"
        rowControls={true}
        controls={
          <div style={{ display: "flex" }}>
            <Typography component="span" variant="subtitle2">
              <Typography variant="caption">
                Overall Conversion Rate{" "}
              </Typography>
              {roundOffNumber(conversion_rate)}
            </Typography>
          </div>
        }
      >
        <HighChartsReact
          highcharts={patternFill(HighCharts)}
          options={chartOptions}
        />
        <Menu
          id="graph-context-menu"
          anchorEl={graphContextEl}
          open={Boolean(graphContextEl)}
          onClose={() =>
            setState({
              ...state,
              graphContextEl: null,
              cohortName: "",
            })
          }
        >
          <MenuItem
            onClick={() => {
              setState({
                ...state,
                graphContextEl: null,
                showCohortCreationModal: true,
                cohortName: "",
              });
              toggleDialog();
            }}
          >
            Create Cohort
          </MenuItem>
        </Menu>

        {showFunnelDialog && (
          <MaterialDialog
            disableEscapeKeyDown={false}
            disableBackdropClick={false}
            open={showFunnelDialog}
            scroll="body"
            onClose={() => {
              setState({ ...state, showCohortCreationDialog: false });
              toggleDialog();
            }}
          >
            <DialogTitle>Create Cohort from Funnel</DialogTitle>
            <DialogContent>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="funnel behavior type"
                  name="funnel_behavior_type"
                  value={funnelBehaviorType}
                  onChange={event => {
                    setState({
                      ...state,
                      funnelBehaviorType: event.target.value,
                    });
                  }}
                >
                  <FormControlLabel
                    value={FunnelBehaviorEnum.DID}
                    control={<Radio color={"primary"} />}
                    label={FunnelBehaviorEnum.DID}
                  />
                  <FormControlLabel
                    value={FunnelBehaviorEnum.DROPPED}
                    control={<Radio color={"primary"} />}
                    label={FunnelBehaviorEnum.DROPPED}
                  />
                </RadioGroup>
              </FormControl>
              <TextField
                label={"Cohort Name"}
                fullWidth={true}
                value={cohortName}
                onChange={e => {
                  setState({ ...state, cohortName: e.target.value });
                }}
              />
            </DialogContent>
            <DialogActions style={{ justifyContent: "space-between" }}>
              <Button onClick={dismissModal}>Cancel</Button>
              <Button
                variant={"outlined"}
                disabled={createCohortPending}
                onClick={() => {
                  const funnelConfig = {
                    name: cohortName,
                    level: getStepIndex(funnelBehaviorType, funnelSelectedStep),
                  };
                  createCohortFromFunnel(funnelConfig);
                }}
              >
                {createCohortPending && (
                  <Loading size={12} style={{ marginRight: 12 }} />
                )}{" "}
                Create Cohort
              </Button>
            </DialogActions>
          </MaterialDialog>
        )}

        {showFunnelSnackbar && (
          <MaterialSnackbar
            open={showFunnelSnackbar}
            autoHideDuration={3000}
            onClose={() => toggleFunnelSnackbar(false)}
            message={
              snackBarMessage !== ""
                ? snackBarMessage
                : "Cohort Creation Success"
            }
          />
        )}
      </Box>
    </div>
  );
}
