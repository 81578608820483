import { Button, Grid, IconButton, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";
import ActionsTextField from "../../../../../../../../ReusableComponents/ActionsTextField";
import ColumnDiv from "../../../../../../../../ReusableComponents/ColumnDiv";
import { useCommonConfigStyles } from "../../../../../../../common/components/campaigncreator/utils";

export default function KeyValuePairs({
  pairs,
  onKeyChange,
  onValueChange,
  onNewKey,
  onKeyDelete,
}) {
  const classes = useCommonConfigStyles();

  return (
    <ColumnDiv styles={{ gap: 8 }}>
      {pairs.map(({ name, value }, index) => (
        <Grid container spacing={1} key={index} alignItems="center">
          <Grid item style={{ marginTop: 16 }}>
            <Typography className={classes.typographyCaption}>
              {index + 1}.
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Typography className={classes.typographyCaption}>Key</Typography>
            <ActionsTextField
              value={name}
              key="Key"
              multiline={false}
              onChange={(event) => onKeyChange(event.target.value, index)}
              style={{
                marginBottom: 0,
                width: "100%",
              }}
            />
          </Grid>
          <Grid
            item
            xs={5}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Typography className={classes.typographyCaption}>Value</Typography>
            <ActionsTextField
              value={value}
              key="Value"
              multiline={false}
              onChange={(event) => onValueChange(event.target.value, index)}
              style={{
                marginBottom: 0,
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={1} style={{ marginTop: 16 }}>
            <IconButton
              style={{
                display: "block",
                margin: "0 auto",
                color: "#A1ADB6",
                padding: 0,
              }}
              onClick={() => onKeyDelete(index)}
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Button
        variant="text"
        color="primary"
        onClick={onNewKey}
        style={{ display: "inline", width: "fit-content" }}
      >
        + Add new key
      </Button>
    </ColumnDiv>
  );
}
