import React, { useMemo } from "react";
import styled from "styled-components";
import tinyColor2 from "tinycolor2";
import { sendEvent } from "../../../../../../common/ApxEventHandler";
import { SET_MODULE_TYPE } from "../../constants";

export const HoverDiv = styled.div`
  background: unset;
  &:hover {
    border: 1px solid #2979ff;
    box-shadow: ${(props) =>
      props.isFooter
        ? "0 -3px 3px rgba(18, 66, 74, 0.25)"
        : "0 3px 3px rgba(18, 66, 74, 0.25)"};
    cursor: pointer;
  }
`;

export const StyledHolder = styled.div`
  user-select: none;
  padding: 0px;
  transition: all 0.1s ease-in-out;
  ${(props) =>
    props.selected
      ? "border: 1px dashed #2979ff;"
      : "border: 1px solid transparent;"}
  &:hover {
    box-shadow: ${(props) =>
      props.defaultBgColor ? "none" : "0 3px 3px rgba(18, 66, 74, 0.25)"};
    cursor: pointer;
    background: ${(props) =>
      props.bgColor
        ? tinyColor2(props.bgColor).complement().toHexString()
        : props.defaultBgColor || "#ffffff"};
    border: ${(props) => (props.isPure ? "none" : "1px solid #2979ff")};
  }
  width: ${(props) => (props.isPure ? "100%" : "unset")};
`;

export const translateToFontConfig = (text_config) => {
  const { color, font_family, size, style } = text_config;
  return {
    color,
    fontFamily: font_family,
    fontSize: `${size}px`,
    fontStyle: style !== "bold" ? style : "normal",
    fontWeight: style === "bold" ? style : "normal",
    margin: 0,
    padding: 0,
    lineHeight: `${Number(size) + 2}px`,
    textAlign: "initial",
  };
};

export function QuestionHolder({
  text = "Text",
  bgColor,
  handleModuleSelection,
  selected,
  style = {},
}) {
  return (
    <StyledHolder
      bgColor={bgColor || "#FFFFFF"}
      selected={selected}
      onClick={handleModuleSelection}
    >
      <div
        style={{
          minWidth: 50,
          minHeight: 20,
          ...style,
        }}
      >
        {text}
      </div>
    </StyledHolder>
  );
}

export const onModuleSelection = (dispatch, moduleName, prevModule) => {
  dispatch({
    type: SET_MODULE_TYPE,
    value: moduleName === prevModule ? "" : moduleName,
  });
  sendEvent(moduleName, { isExpanded: !(moduleName === prevModule) });
};
