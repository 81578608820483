import { Button } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import { APP_PLATFORMS } from "../../../../../../../../../constants";
import generateShortID from "../../../../../../../../../utils";
import RowDiv from "../../../../../../../../ReusableComponents/RowDiv";
import { useTrackedState } from "../../../../../../../common/components/campaigncreator/store";
import {
  REMOVE_SNIPPET_FROM_HTML_CONFIG,
  SET_HTML_SCRIPT_CONFIG,
} from "../../../constants";
import { useTracked } from "../../../store";
import { getUI } from "../../../utils";
import DynamicText2Dialog from "./DynamicText";

export default function ScriptToolbar({
  currentStep,
  onAddNewSnippet,
  onRemoveSnippet,
}) {
  const parentState = useTrackedState();
  const [state, dispatch] = useTracked();

  const {
    config: {
      meta: { isExperiment, platform },
    },
  } = parentState;

  const ui = getUI(currentStep, state.uis, isExperiment).ui || {};

  const { html_config } = ui;

  const {
    script: { keys = [], vmap = {}, srcs = {} } = {
      keys: [],
      vmap: {},
      srcs: {},
    },
  } = html_config;

  const [selectedKey, setSelectedKey] = useState(null);

  return (
    <RowDiv center styles={{ gap: 8 }}>
      {keys.length > 0 && (
        <RowDiv
          center
          styles={{
            border: "1px solid rgba(0, 40, 69, 0.45)",
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
            marginBottom: 1,
            maxWidth: 400,
            flex: 1,
            flexWrap: "wrap",
            gap: 5,
            padding: 4,
          }}
        >
          {keys.map(key => (
            <Button
              key={key}
              variant="contained"
              color="primary"
              style={{
                cursor: "pointer",
                backgroundColor: "#121111b3",
              }}
              onClick={() => setSelectedKey(key)}
            >
              {key}
              <CloseOutlined
                color="secondary"
                style={{
                  cursor: "pointer",
                  marginLeft: 4,
                }}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  dispatch({
                    type: REMOVE_SNIPPET_FROM_HTML_CONFIG,
                    scriptName: key,
                  });
                  if (onRemoveSnippet) {
                    onRemoveSnippet(key);
                  }
                }}
              />
            </Button>
          ))}
        </RowDiv>
      )}
      {platform !== APP_PLATFORMS.web && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setSelectedKey("apx_new_007_snippet")}
        >
          Add Dynamic Text
        </Button>
      )}
      {selectedKey && (
        <DynamicText2Dialog
          hideRemove={true}
          openFlag={selectedKey !== null}
          onSuccess={(tab, _, cmds, script, vmap) => {
            let scriptName = selectedKey;
            if (selectedKey === "apx_new_007_snippet") {
              scriptName = generateShortID("-", 0, "Script-");
            }
            dispatch({
              type: SET_HTML_SCRIPT_CONFIG,
              scriptName,
              tab,
              cmds,
              script,
              vmap,
            });
            if (selectedKey === "apx_new_007_snippet") {
              onAddNewSnippet(scriptName);
            }
            setSelectedKey(null);
          }}
          onFail={() => setSelectedKey(null)}
          dynamicType="s"
          tab={srcs[selectedKey]?.t ?? 0}
          originalScript={srcs[selectedKey]?.src ?? ""}
          variableMap={vmap}
        />
      )}
    </RowDiv>
  );

}