import React, { useState, useEffect, useMemo } from "react";
import Box from "../../../../../../ReusableComponents/Box";
import MultiSelect from "../../../../../../ReusableComponents/MultiSelect";
import { useAuth } from "../../../../../../../contexts/AuthContext";
import Placeholder from "../../../../../../ReusableComponents/Placeholder";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  ReferenceLine,
  BarChart,
  Bar,
  Brush,
  Tooltip,
} from "recharts";
import {
  randomColorWithIndex,
  roundOffNumber,
  onlyAlphaNumericChars,
  getGroupNameFromList,
} from "../../../../../../../utils";
import { segmentAttributeDistributionAPI } from "../../../actions";
import Loading from "../../../../../../ReusableComponents/Loading";
import { PRIMARY } from "../../../../../../ApplicationFrame/styling/CustomCSS";

const _USERS_KEY = "_users";
const _HACK_IDENTIFIER_FOR_INDEX = "APX#";

function listToObject(list = []) {
  if (Array.isArray(list)) {
    return list.reduce((a, b) => {
      a[b.key] = b.segmentaion_percent; //FIXME: spelling saamyyyy @Kiran _/|\_
      a[b.key + _USERS_KEY] = b.value;
      return a;
    }, {});
  } else {
    return {};
  }
}

function toBarGraphModel(obj = {}, segmentList, attribute) {
  //FIXME:need proper name
  const segmentIds = Object.keys(obj);
  if (attribute && segmentIds.length > 0) {
    //FIXME: Pure hack for more values having segment, so that won't screwed up for not having data in attribute distribution
    const sortedSegmentIds = segmentIds.sort((a, b) =>
      obj[a].length > obj[b].length ? -1 : obj[a].length < obj[b].length ? 1 : 0
    );
    const [segmentId, ...otherSegmentIds] = sortedSegmentIds;
    const segmentName = getGroupNameFromList(segmentList, segmentId);
    const baseList = obj[segmentId].map((o) => ({
      [segmentName]: o.segmentaion_percent,
      [segmentName + _USERS_KEY]: o.value,
      [attribute]: o.key,
    }));
    const attributeValuesMap = { ...obj };
    for (let segmentId in attributeValuesMap) {
      if (attributeValuesMap.hasOwnProperty(segmentId)) {
        const segmentName = getGroupNameFromList(segmentList, segmentId);
        attributeValuesMap[segmentName] = listToObject(
          attributeValuesMap[segmentId]
        );
      }
    }
    const otherSegments = otherSegmentIds.map((segmentId) =>
      getGroupNameFromList(segmentList, segmentId)
    );
    return baseList.map((o) => {
      otherSegments.forEach((segment) => {
        o[segment] = attributeValuesMap[segment][o[attribute]] || 0;
        o[segment + _USERS_KEY] =
          attributeValuesMap[segment][o[attribute] + _USERS_KEY] || 0;
      });
      return o;
    });
  } else {
    return [];
  }
}

function CustomToolTip({ active, payload, label }) {
  if (active && Array.isArray(payload))
    return (
      <div
        className="recharts-default-tooltip"
        style={{
          margin: "0px",
          padding: "10px",
          backgroundColor: "rgb(255, 255, 255)",
          border: "1px solid rgb(204, 204, 204)",
          whiteSpace: "nowrap",
        }}
      >
        <p className="recharts-tooltip-label" style={{ margin: "0px" }}>
          {label}
        </p>
        <ul
          className="recharts-tooltip-item-list"
          style={{ padding: "0px", margin: "0px" }}
        >
          {payload.map((item, index) => {
            const color = randomColorWithIndex(
              Number(item.color.match(/APX#\d+/)[0].slice(4))
            );
            return (
              <li
                className="recharts-tooltip-item"
                key={`li${index}`}
                style={{
                  display: "block",
                  paddingTop: "4px",
                  paddingBottom: "4px",
                  color: color,
                }}
              >
                <span className="recharts-tooltip-item-name">{item.name}</span>
                <span className="recharts-tooltip-item-separator"> : </span>
                <span className="recharts-tooltip-item-value">
                  {roundOffNumber(item.value)} (
                  {item.payload[`${item.name + _USERS_KEY}`]})
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    );
  else return <span />;
}

export default function SegmentGroupByAttribute({
  appId,
  queryParams,
  filters,
  segments,
  selectedSegments,
  selectedSegmentNames,
  attributes = [],
}) {
  const auth = useAuth();

  const [selectedAttribute, setSelectedAttribute] = useState(
    attributes.length > 0 ? attributes[0] : ""
  );
  const [data, setData] = useState({});
  const [pending, setPending] = useState(false);

  const barGraphData = useMemo(
    () => toBarGraphModel(data, segments, selectedAttribute) || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  useEffect(() => {
    setSelectedAttribute(attributes.length > 0 ? attributes[0] : "");
  }, [attributes]);

  useEffect(() => {
    if (selectedAttribute === "") {
      return;
    }
    let promises = [];
    setPending(true);
    selectedSegments.forEach((segmentId) => {
      const promise = segmentAttributeDistributionAPI(
        auth,
        appId,
        segmentId,
        { ...queryParams, attribute: selectedAttribute },
        filters
      );
      promises.push(promise);
    });

    Promise.all(promises).then((values) => {
      let obj = {};
      selectedSegments.forEach((segmentId, index) => {
        obj[segmentId] = values[index];
      });
      setData(obj);
      setPending(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, appId, queryParams, filters, selectedAttribute, selectedSegments]);

  return (
    <Box
      withPadding
      title="Group By Property"
      controls={
        <MultiSelect
          placeholder="Select Property"
          options={attributes.map((a) => ({ label: a, value: a }))}
          handleChange={(attribute) => {
            setSelectedAttribute(attribute);
          }}
          value={selectedAttribute}
          single
          style={{ maxWidth: 200 }}
          clearable={false}
        />
      }
    >
      {pending && (
        <section className="content">
          <Loading />
        </section>
      )}
      {!pending &&
        (!Array.isArray(barGraphData) || barGraphData.length === 0) && (
          <Placeholder />
        )}
      {!pending && Array.isArray(barGraphData) && (
        <ResponsiveContainer height={300}>
          <BarChart
            data={barGraphData}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <XAxis dataKey={selectedAttribute} />
            <YAxis
              width={90}
              label={{
                value: "Users",
                position: "insideLeft",
                angle: -90,
                dy: 40,
              }}
            />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip content={<CustomToolTip />} />
            <ReferenceLine y={0} stroke="#000" />
            {barGraphData.length > 10 && (
              <Brush
                dataKey={selectedAttribute}
                height={30}
                stroke={PRIMARY.disabled}
                endIndex={10}
              />
            )}
            <defs>
              {selectedSegmentNames.map((segmentName, index) => (
                <linearGradient
                  key={"gradient-" + segmentName}
                  id={`${onlyAlphaNumericChars(
                    segmentName
                  )}${_HACK_IDENTIFIER_FOR_INDEX}${index}`}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  {" "}
                  {/*&withIndex$ :FIXME: a dodgy hack for getting color for tooltip*/}
                  <stop
                    offset="5%"
                    stopColor={randomColorWithIndex(index)}
                    stopOpacity={0.8}
                  />
                  <stop
                    offset="95%"
                    stopColor={randomColorWithIndex(index)}
                    stopOpacity={0}
                  />
                </linearGradient>
              ))}
            </defs>
            {selectedSegmentNames.map((segmentName, index) => (
              <Bar
                key={"bar-" + segmentName}
                type="monotone"
                dataKey={segmentName}
                fillOpacity={1}
                fill={`url(#${onlyAlphaNumericChars(
                  segmentName
                )}${_HACK_IDENTIFIER_FOR_INDEX}${index})`}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      )}
    </Box>
  );
}
