/**
 * Created by kodanda_rama on 1/27/17.
 */
import React from "react";
import PropTypes from "prop-types";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";

const useSwitchStyles = makeStyles((theme) => ({
  root: (props) => ({
    width: 39,
    height: 20,
    padding: 0,
    display: "flex",
    alignItems: "center",
  }),
  switchBase: (props) => ({
    padding: 2,
    top: "unset !important",
    left: 2,
    color: props.style.switchBaseColor
      ? props.style.switchBaseColor
      : theme.palette.text.disabled,
    "&$checked": {
      transform: "translateX(18.5px)",
      left: 1,
      color: props.style.checkedColor
        ? props.style.checkedColor
        : theme.palette.secondary.main,
      "& + $track": {
        opacity: 1,
        backgroundColor: props.style.trackBgColor
          ? props.style.trackBgColor
          : theme.palette.primary.main,
        borderColor: props.style.trackBorderColor
          ? props.style.trackBorderColor
          : theme.palette.primary.main,
      },
    },
  }),
  thumb: (props) => ({
    width: 14,
    height: 14,
    boxShadow: "none",
    color: theme.palette.secondary.main,
  }),
  track: (props) => ({
    backgroundColor: props.style.trackInActiveBgColor
      ? props.style.trackInActiveBgColor
      : theme.palette.text.disabled,
    borderRadius: 10,
    opacity: 1,
  }),
  checked: (props) => ({}),
}));

function ToggleSwitch({
  label,
  disabled,
  styles,
  handleChange,
  checked = false,
  value,
  ...others
}) {
  const classes = useSwitchStyles(others.style ? others : { style: {} });

  return (
    <FormControlLabel
      style={styles}
      className={others?.className}
      control={
        <Switch
          classes={classes}
          checked={checked}
          onChange={(e, checked) => {
            handleChange(checked, e);
          }}
          aria-label={label}
          disabled={disabled}
          value={value}
          style={{
            cursor: disabled ? "not-allowed" : "pointer",
          }}
        />
      }
      label={label}
    />
  );
}

ToggleSwitch.propTypes = {
  label: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  checked: PropTypes.bool,
  styles: PropTypes.object,
};

export default ToggleSwitch;
