import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

const defaultText = "No Data Available...!";

export default function Placeholder({
  height = 100,
  withIcon = false,
  icon,
  rootStyles = {},
  text,
  typographyStyles,
  children,
  isStretchable = false,
}) {
  if (withIcon) {
    return (
      <div style={{ textAlign: "center", ...rootStyles }}>
        {icon}
        <Typography variant="caption" style={{ ...typographyStyles }}>
          {text}
        </Typography>
        {children}
      </div>
    );
  }
  return (
    <div style={{ width: "100%", height: height, textAlign: "center" }}>
      {isStretchable ? (
        <svg
          width="100%"
          height={height + "px"}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <text y="50%" textLength="100%" lengthAdjust="spacingAndGlyphs">
              {this.props.text}
            </text>
          </g>
        </svg>
      ) : (
        <Typography variant="caption">{text || defaultText}</Typography>
      )}
    </div>
  );
}

Placeholder.propTypes = {
  isStretchable: PropTypes.bool,
  text: PropTypes.string,
  height: PropTypes.any,
  withIcon: PropTypes.bool,
};
