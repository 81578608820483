import produce from "immer";
import {
  SET_APP_ERROR,
  SET_APP_LOADING,
  SET_BUNDLE_ID,
  SET_FCM_KEY,
} from "./constants";

export const initial_state = {
  bundle_id: "",
  fcm_key: "",
  app_loading: false,
  app_error: null,
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case SET_BUNDLE_ID:
      draft.bundle_id = action.payload;
      break;
    case SET_FCM_KEY:
      draft.fcm_key = action.payload;
      break;
    case SET_APP_LOADING:
      draft.app_loading = action.payload;
      break;
    case SET_APP_ERROR:
      draft.app_error = action.payload;
      break;
    default:
      break;
  }
});
