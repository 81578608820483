import React, { useMemo, useState, useEffect, useRef } from "react";
import { Grid, Typography } from "@material-ui/core";
import PeopleGroupIcon from "@material-ui/icons/Group";
import Box from "../../../../../../ReusableComponents/Box";
import MultiSelect from "../../../../../../ReusableComponents/MultiSelect";
import Placeholder from "../../../../../../ReusableComponents/Placeholder";
import MultiUtilityChart from "../../../../../../ReusableComponents/MultiUtilityChart";
import {
  getGroupNameFromList,
  minMaxAverageOf,
} from "../../../../../../../utils";
import { getSegmentTimeSeriesAPI } from "../../../actions";
import { useAuth } from "../../../../../../../contexts/AuthContext";
import SegmentGroupByAttribute from "./SegmentGroupByAttribute";
import Loading from "../../../../../../ReusableComponents/Loading";

function mergeTimeSeries(all, segment, segmentName) {
  if (!Array.isArray(all) || !Array.isArray(segment)) return [];
  if (all.length === 0 || all.length !== segment.length)
    return segment.map((o) => ({ name: o.key, [segmentName]: o.value }));
  return all.map((item, index) => {
    item[segmentName] = segment[index].value;
    return item;
  });
}

export default function SegmentTimeSeries({
  appId,
  attributes = [],
  queryParams,
  filters,
  segmentId,
  segments,
}) {
  const auth = useAuth();

  const prevSegmentId = useRef(segmentId);

  const segmentName = useMemo(() => getGroupNameFromList(segments, segmentId), [
    segmentId,
    segments,
  ]);

  const [selectedSegments, setSelectedSegments] = useState([segmentId]);
  const [selectedSegmentNames, setSelectedSegmentNames] = useState([
    segmentName,
  ]);
  const [timeSeries, setTimeSeries] = useState([]);
  const [pending, setPending] = useState(false);

  const getTimeSeries = (segmentId) => {
    setPending(true);
    getSegmentTimeSeriesAPI(auth, appId, segmentId, queryParams, filters)
      .then((response) => {
        const updated = mergeTimeSeries(
          timeSeries,
          response,
          getGroupNameFromList(segments, segmentId)
        );
        setTimeSeries([...updated]);
        setPending(false);
      })
      .catch((err) => {
        setPending(false);
      });
  };

  useEffect(() => {
    if (prevSegmentId.current !== segmentId) {
      const updated = [
        segmentId,
        ...selectedSegments.filter((id) => id !== prevSegmentId.current),
      ];
      setSelectedSegments(updated);
      setTimeSeries([]);

      let names = [];

      // Fetch all TimeSeries values
      updated.forEach((id) => {
        getTimeSeries(id);
        names.push(getGroupNameFromList(segments, id));
      });

      setSelectedSegmentNames(names);
    }
    prevSegmentId.current = segmentId;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentId]);

  useEffect(() => {
    selectedSegments.forEach((segmentId) => {
      getTimeSeries(segmentId);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, appId, queryParams, filters]);

  const handleOnSegmentsSelect = (selected) => {
    let names = [];
    if (selected.length > 0) {
      selected.forEach((item) => {
        names.push(getGroupNameFromList(segments, item));
        if (!selectedSegments.includes(item)) {
          getTimeSeries(item);
        }
      });
    }
    setSelectedSegments(selected);
    setSelectedSegmentNames(names);
  };

  return (
    <Box
      withPadding
      title={
        <Grid container spacing={8}>
          <Grid item xs={12} md={6}>
            <div style={{ display: "flex", marginTop: 15 }}>
              <PeopleGroupIcon />
              <Typography variant="subtitle1" style={{ marginLeft: 5 }}>
                {" "}
                Segment Comparison
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <MultiSelect
              placeholder="Select Segments to compare"
              options={segments.map((o) => ({
                label: o.name,
                value: o._id,
              }))}
              handleChange={(data) => {
                handleOnSegmentsSelect(data);
              }}
              value={selectedSegments}
            />
          </Grid>
          {/*<Grid item xs={12} md={1}>*/}
          {/*    <AddToDashboard {...this.props} componentId={SEGMENTS_TREND} getComponentParams={() => {*/}
          {/*        return {*/}
          {/*            extraFilters: {user: [], session: [], event: []},*/}
          {/*            queryParams: {group, segmentId: [...Object.keys(selected_segments)]}*/}
          {/*        };*/}
          {/*    }}>*/}
          {/*        <div style={{display: 'flex', flexWrap: 'wrap'}}>*/}
          {/*            {*/}
          {/*                segmentNames.map(segment => <Chip style={{margin: 3}} key={segment} label={segment} />)*/}
          {/*            }*/}
          {/*        </div>*/}
          {/*    </AddToDashboard>*/}
          {/*</Grid>*/}
        </Grid>
      }
      footer={
        <SegmentGroupByAttribute
          appId={appId}
          queryParams={queryParams}
          filters={filters}
          segments={segments}
          selectedSegments={selectedSegments}
          selectedSegmentNames={selectedSegmentNames}
          attributes={attributes || []}
        />
      }
    >
      {pending && (
        <section className="content">
          <Loading />
        </section>
      )}
      {!pending && (!Array.isArray(timeSeries) || timeSeries.length === 0) && (
        <Placeholder />
      )}
      {!pending && Array.isArray(timeSeries) && (
        <MultiUtilityChart
          withHeader
          dataKey="name"
          yLabel="Users"
          data={timeSeries}
          lineDataKeys={selectedSegmentNames}
          stats={minMaxAverageOf(timeSeries, segmentName, "name")}
        />
      )}
    </Box>
  );
}
