import React, { useState, useMemo } from "react";
import ToggleSwitch from "../../ReusableComponents/ToggleSwitch";
import Snackbar from "../../ReusableComponents/Snackbar";

import {
  styles,
  appComponentStyle,
  PLATFORM_IMAGES,
  getPlatformLogoColor,
} from "../styling/CustomCSS";
import { isUserRestricted } from "../../../utils";

import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineOutlined from "@material-ui/icons/AddCircleOutlineOutlined";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import { Tooltip } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { withStyles } from "@material-ui/core/styles";
import { useAuth } from "../../../contexts/AuthContext";
import { setMonitoringStatusAPI } from "../actions";
import CustomMaterialUIDialog from "../../ReusableComponents/CustomMaterialUIDialog";
import { DEMO_ECOMMERCE_APP_ID, isExperienceDashboard } from "../../../config";
import { apxTheme } from "../../ApplicationFrame/styling/CustomCSS";

const AddApp = ({ isAppsListEmpty, fetchApps, classes }) => {
  const [showApprovalPending, setShowApprovalPending] = useState(false);

  return (
    <>
      {!isExperienceDashboard() && (
        <>
          {isAppsListEmpty ? (
            <div style={{ textAlign: "center", marginTop: 50 }}>
              <Link component={IconButton} to="/apps/create">
                <AddCircleOutlineOutlined
                  color="primary"
                  className={classes.addIcon}
                />
              </Link>
              <Typography variant="caption" style={{ fontSize: 32 }}>
                No Apps Found
              </Typography>
              <Typography variant="body2">
                Add new by pressing over "+"
              </Typography>
            </div>
          ) : (
            <Link
              component={Button}
              to="/apps/create"
              color="primary"
              variant="contained"
              style={{
                color: "white",
                textDecoration: "none",
              }}
            >
              Add New
            </Link>
          )}
        </>
      )}
      <CustomMaterialUIDialog
        maxWidth="xs"
        openFlag={showApprovalPending}
        dialogContent={
          <>
            <p>
              Thank you, your App has been submitted for Approval, please
              reachout to Apxor Support.
            </p>
          </>
        }
        dialogActions={
          <div>
            <Button
              onClick={() => {
                setShowApprovalPending(false);
                fetchApps();
              }}
              variant="outlined"
              color="primary"
            >
              Ok
            </Button>
          </div>
        }
        noTitleBg={true}
      />
    </>
  );
};

const SearchBar = ({ query, onChange }) => {
  return (
    <TextField
      placeholder="Search"
      value={query}
      margin="normal"
      onChange={(e) => onChange(e.target.value)}
      fullWidth
    />
  );
};

const MonitoringStatus = ({ monitoring, appId, approved }) => {
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [showDialog, setDialog] = useState(false);
  const [enabled, setEnabled] = useState(monitoring);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const confirmFlag = enabled ? "DISABLE" : "ENABLE";

  const handleMonitoringClick = () => {
    setDialog(true);
  };

  const handleMonitoringState = () => {
    setPending(true);
    setMonitoringStatusAPI(auth, appId, !enabled)
      .then((response) => {
        setPending(false);
        if (response) {
          setEnabled(!enabled);
          handleDialogClose();
        } else {
          setSnackbarMessage("Failed change monitoring status");
        }
      })
      .catch((e) => {
        setPending(false);
        setSnackbarMessage("Failed change monitoring status");
      });
  };

  const handleDialogClose = () => {
    setDialog(false);
  };

  return (
    <span title={"Monitoring"}>
      <ToggleSwitch
        styles={{ margin: 0 }}
        checked={!approved ? false : enabled}
        disabled={!approved}
        handleChange={handleMonitoringClick}
      />
      <CustomMaterialUIDialog
        maxWidth="xs"
        openFlag={showDialog}
        onDialogClose={handleDialogClose}
        dialogContent={
          <>
            <p>
              Are you sure want to <strong>{confirmFlag}</strong> SDK
              monitoring?
            </p>
            {pending && <CircularProgress size={30} />}
          </>
        }
        dialogActions={
          <div>
            <Button onClick={() => handleDialogClose()}>Cancel</Button>
            <Button onClick={() => handleMonitoringState()} color="primary">
              Ok
            </Button>
          </div>
        }
        noTitleBg={true}
      />
      {snackbarMessage !== "" && (
        <Snackbar onClose={() => setSnackbarMessage("")}>
          {snackbarMessage}
        </Snackbar>
      )}
    </span>
  );
};

const colorList = [
  "#311B92",
  "#304FFE",
  "#0091EA",
  "#1B5E20",
  "#D50000",
  "#C51162",
  "#82B1FF",
  "#AA00FF",
];

const ContributorsList = (customers = [], classes, email) => {
  const index = 0;
  return customers.slice(0, 2).map((customer, indeappComponentStylex) => {
    const { customer_id } = customer;
    return (
      <Tooltip
        title={customer_id + (customer_id === email ? " (You)" : "")}
        key={index + "_" + customer_id}
      >
        <Avatar
          className={classes.adminAvatar}
          style={{ backgroundColor: colorList[index], marginLeft: -6 }}
        >
          {customer_id.slice(0, 1).toUpperCase()}
        </Avatar>
      </Tooltip>
    );
  });
};

const AppComponent = (props) => {
  const { classes } = props;

  const {
    app: {
      app_id,
      basic_info: {
        app_name,
        bundle_id,
        icon_url,
        customers = [],
        platform,
        customer_id,
        approved = true,
      },
      monitoring,
    },
    auth: {
      user: { email },
    },
  } = props;
  const DREAM11 = "d831634e11cf6c424b817e22761710e2";
  return (
    <Card className={approved ? classes.root : classes.root_pending}>
      <CardContent className={classes.cardContent}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: 12,
            alignItems: "center",
          }}
        >
          <Avatar
            className={classes.logoAvatar}
            alt={app_name}
            src={icon_url}
            imgProps={{
              onError: (e) => {
                e.target.src = window.apxorAppInfo.favicon;
              },
            }}
          />
          <div>
            <Typography
              className={classes.appTitle}
              title={app_name}
              variant="body2"
              noWrap
            >
              {app_name}
            </Typography>
            <Typography
              className={classes.appDesc}
              title={bundle_id}
              variant="caption"
            >
              {bundle_id}
            </Typography>
          </div>
        </div>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item md={3}>
            <div style={{ paddingRight: 0 }}>
              <Typography
                style={{
                  marginBottom: 6,
                  fontWeight: 300,
                }}
              >
                Platform
              </Typography>
              {PLATFORM_IMAGES(platform, getPlatformLogoColor(platform), 24)}
            </div>
          </Grid>
          <Grid item md={9}>
            <div
              style={{
                display: "flex",
                padding: 14,
                borderRadius: 8,
                background: approved && "#F0F2F4",
              }}
            >
              <Grid container spacing={0} direction={"row"}>
                <Grid item xs={4}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingRight: 4,
                      borderRight:
                        approved && "1px solid rgba(206, 213, 255, 0.8)",
                    }}
                  >
                    <Typography
                      style={{
                        marginBottom: 6,
                        fontWeight: 300,
                      }}
                    >
                      {" "}
                      Owner{" "}
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <Tooltip
                        title={
                          customer_id + (customer_id === email ? " (You)" : "")
                        }
                      >
                        <Avatar
                          className={classes.adminAvatar}
                          style={{
                            backgroundColor: colorList[customer_id.length % 3],
                          }}
                        >
                          {customer_id.slice(0, 1).toUpperCase()}
                        </Avatar>
                      </Tooltip>
                      {!approved && (
                        <Typography
                          style={{
                            marginLeft: 8,
                          }}
                        >
                          {customer_id}
                        </Typography>
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={8}>
                  <div style={{ paddingLeft: 4 }}>
                    {customers && customers.length > 0 && (
                      <Typography
                        style={{
                          marginBottom: 6,
                          fontWeight: 300,
                        }}
                      >
                        Collaborators
                      </Typography>
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginLeft: 6,
                      }}
                    >
                      {ContributorsList(customers, classes, email)}
                      {customers.length > 3 && (
                        <Typography
                          variant={"subtitle2"}
                          style={{
                            marginLeft: 4,
                          }}
                        >
                          {" "}
                          + {customers.length - 3}
                        </Typography>
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </CardContent>
      <div className={classes.cardActions}>
        {!isUserRestricted(customers, email) && !isExperienceDashboard() ? (
          <MonitoringStatus
            monitoring={monitoring}
            appId={app_id}
            approved={approved}
          />
        ) : (
          <div />
        )}
        {approved && email === customer_id && (
          <Tooltip title="Add Collaborators">
            <Link
              component={Button}
              to={`apps/${app_id}/settings/iam`}
              color={"primary"}
              variant={"outlined"}
              size={"small"}
              style={{ marginRight: 2 }}
              endIcon={<PersonAddIcon />}
            >
              Add
            </Link>
          </Tooltip>
        )}
        {/* FIXME: using this for changing the Landing dashboard for Dream11. (Client events only)
         */}
        {approved && app_id === DREAM11 && (
          <Link
            component={Button}
            color={"primary"}
            variant={"outlined"}
            size={"small"}
            endIcon={<ArrowForwardIcon />}
            to={`/apps/${app_id}/walkthroughs`}
            style={{
              color: apxTheme.palette.primary.main,
              textDecoration: "none",
            }}
          >
            Analyse
          </Link>
        )}
        {/* Fixme using this for changing the Landing dashboard for Dream11.  The below condition translates to if(appId == dream11)*/}
        {approved && app_id !== "d831634e11cf6c424b817e22761710e2" && (
          <Link
            component={Button}
            color={"primary"}
            variant={"outlined"}
            size={"small"}
            to={`/apps/${app_id}/dashboard`}
            endIcon={<ArrowForwardIcon />}
            style={{
              color: apxTheme.palette.primary.main,
              textDecoration: "none",
            }}
          >
            Analyse
          </Link>
        )}

        {!approved && (
          <div
            style={{
              fontSize: 12,
              fontWeight: 800,
              border: "1px solid #ffbe42",
              borderRadius: 6,
              background: "#F6F0CF",
              padding: "4px 8px",
              color: "#545454",
            }}
          >
            Approval Pending
          </div>
        )}
      </div>
    </Card>
  );
};

// FIXME: remove this StyledAppComponent
const StyledAppComponent = React.memo(
  withStyles(appComponentStyle)(AppComponent)
);
StyledAppComponent.whyDidYouRender = true;
export { StyledAppComponent };

const filterApps = (apps, query) => {
  return apps.filter((o) => o.toLowerCase().includes(query.toLowerCase()));
};

const getFilteredApps = (appsList) => {
  return isExperienceDashboard()
    ? appsList.filter((eachApp) => eachApp.app_id === DEMO_ECOMMERCE_APP_ID)
    : appsList;
};

const AppsComponent = ({ apps, classes, fetchApps, ...otherProps }) => {
  const auth = useAuth();
  const [query, setQuery] = useState("");

  /**
   * Memoizing app names instead of app objects for faster filtering
   */
  const appNames = useMemo(() => {
    const names = [];
    apps.forEach((app) => {
      names.push(app.basic_info.app_name);
    });
    return names;
  }, [apps]);

  return (
    <>
      {apps.length > 0 && (
        <>
          <Grid container justifyContent="space-between">
            <Grid item xs={6} md={4}>
              {apps.length > 2 && (
                <SearchBar query={query} onChange={(val) => setQuery(val)} />
              )}
            </Grid>
            <Grid
              item
              xs={3}
              md={2}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <AddApp
                classes={classes}
                fetchApps={fetchApps}
                isAppsListEmpty={false}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} justify="center">
            {getFilteredApps(apps).map(
              (app, index) =>
                filterApps(appNames, query).indexOf(app.basic_info.app_name) !==
                  -1 && (
                  <Grid
                    key={index + app.app_id}
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={4}
                  >
                    {/* We should not pass classes props to AppComponent as
                     *   none of the classes defined for AppsComponent are used by it.
                     *   This effects the performance in dev (not sure if it's in prod)
                     */}
                    <StyledAppComponent {...otherProps} app={app} auth={auth} />
                  </Grid>
                )
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default withStyles(styles)(AppsComponent);
