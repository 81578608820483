import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const DIM = 8;

const styles = (
  color,
  withoutBefore = false,
  withoutAfter = false,
  reverse = false
) => {
  const ARROW_BEFORE = {
    display: "inline-block",
    content: '""',
    position: "absolute",
    border: `${DIM}px solid ${color}`,
    top: 0,
    left: -12,
    borderWidth: "21.5px 8px",
    borderLeftColor: "transparent",
  };

  const ARROW_AFTER = {
    display: "inline-block",
    content: '""',
    position: "absolute",
    top: "0",
    right: -DIM * 2,
    border: "22px solid transparent",
    borderWidth: "21.5px 8px",
    borderLeftColor: color,
  };

  const REVERSE_ARROW_AFTER = {
    display: "inline-block",
    content: '""',
    position: "absolute",
    top: "0",
    right: -12,
    border: `22px solid ${color}`,
    borderWidth: "22px 8px",
    borderRightColor: "transparent",
  };

  const REVERSE_ARROW_BEFORE = {
    display: "inline-block",
    content: '""',
    position: "absolute",
    border: `${DIM}px solid transparent`,
    top: 0,
    left: -16,
    borderWidth: "22px 8px",
    borderRightColor: color,
  };

  const LABEL = {
    display: "inline",
    marginTop: 5,
    marginBottom: 5,
    padding: `.3em .5em .3em .4em`,
    fontSize: 24,
    fontWeight: 600,
    lineHeight: 1,
    color: "#fff",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "baseline",
    borderRadius: 0,
    backgroundColor: color,
    position: "relative",
    marginRight: DIM,
    marginLeft: DIM,
    "&:before": !withoutBefore ? ARROW_BEFORE : {},
    "&:after": !withoutAfter ? ARROW_AFTER : {},
  };
  if (reverse) {
    return {
      label: {
        ...LABEL,
        "&:before": !withoutBefore ? REVERSE_ARROW_BEFORE : {},
        "&:after": !withoutAfter ? REVERSE_ARROW_AFTER : {},
      },
    };
  }
  return {
    label: LABEL,
  };
};

export default function ArrowedLabel({
  color,
  withoutBefore,
  withoutAfter,
  reverse,
  ...rest
}) {
  const Comp = withStyles(styles(color, withoutBefore, withoutAfter, reverse))(
    ({ classes, label }) => (
      <Typography variant={"h4"} className={classes.label}>
        {label}
      </Typography>
    )
  );
  return <Comp {...rest} />;
}
