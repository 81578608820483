import React, { useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useTracked } from "../../../../store";
import ActionsTextField from "../../../../../../../../ReusableComponents/ActionsTextField";
import {
  SET_FREQ_COUNT,
  SET_FREQ_DAY_LIMIT,
  SET_FREQ_LIMITS_ENABLED,
  SET_FREQ_SESSION_LIMIT,
  SET_FREQ_VALIDITY,
  SET_ROLL_RECURR,
  SET_ROLL_STEP,
  SET_TOGGLE_RECURR,
  TOGGLE_SWITCH_STYLES,
} from "../../../../constants";
import MultiSelect from "../../../../../../../../ReusableComponents/MultiSelect";
import RowDiv from "../../../../../../../../ReusableComponents/RowDiv";
import ToggleSwitch from "../../../../../../../../ReusableComponents/ToggleSwitch";
import BootstrapTooltip from "../../../../../../../../ReusableComponents/BootstrapTooltip";
import LockIcon from "@material-ui/icons/Lock";
import { isExperienceDashboard } from "../../../../../../../../../config";

const useStyles = makeStyles((theme) => ({
  formControlLabel: (props) => ({
    marginLeft: 8,
  }),
  gridItem: (props) => ({
    display: "flex",
    alignItems: "center",
  }),
  uiPicker: (props) => ({
    marginLeft: 10,
  }),
}));

const FREQUENCY_ENUM = [
  { label: "overall", value: "OVERALL" },
  { label: "per session", value: "SESSION" },
];

const RECUR_FREQUENCY_ENUM = [
  { label: "hour", value: "HOUR" },
  { label: "day", value: "DAY" },
  { label: "week", value: "WEEK" },
];

export default function RecurAndLimits() {
  const classes = useStyles();

  const [state, dispatch] = useTracked();
  const [displayTooltip, setDisplayTooltip] = useState(false);

  const {
    config: {
      frequency,
      scheduling: schedulingConfig,
      meta: { platform },
    },
  } = state;

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      {isExperienceDashboard() && (
        <BootstrapTooltip
          open={isExperienceDashboard() && displayTooltip}
          title={
            <>
              <b>Refreshing Target Audience</b> and{" "}
              <b>Customized Campaign Limits</b> features are disabled in the
              demo app
            </>
          }
          placement="top"
        >
          <LockIcon
            color="primary"
            style={{
              zIndex: "10",
              position: "absolute",
              top: "4px",
              right: "4px",
            }}
          />
        </BootstrapTooltip>
      )}
      <Grid
        container
        spacing={2}
        style={{
          opacity: isExperienceDashboard() ? 0.5 : 1,
        }}
        onMouseEnter={() => setDisplayTooltip(true)}
        onMouseLeave={() => setDisplayTooltip(false)}
      >
        <Grid item xs={12} className={classes.gridItem}>
          <Typography variant="subtitle1" style={{ fontWeight: 800 }}>
            Refresh Users in Segment
          </Typography>
          <ToggleSwitch
            classeName={classes.formControlLabel}
            styles={{ marginLeft: 8 }}
            checked={
              schedulingConfig?.isRecurEnabled || isExperienceDashboard()
            }
            handleChange={(checked, e) => {
              dispatch({
                type: SET_TOGGLE_RECURR,
                enabled: checked,
              });
            }}
            style={TOGGLE_SWITCH_STYLES()}
          />
          {(schedulingConfig?.isRecurEnabled || isExperienceDashboard()) && (
            <>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 600,
                }}
              >
                every
              </Typography>
              <ActionsTextField
                value={schedulingConfig.step}
                style={{
                  maxWidth: 72,
                  marginBottom: 0,
                  margin: "0 6px",
                }}
                InputProps={{ min: 1 }}
                onChange={(e) => {
                  dispatch({
                    type: SET_ROLL_STEP,
                    value: Number(e.target.value),
                  });
                }}
                type={"number"}
              />
              <MultiSelect
                options={RECUR_FREQUENCY_ENUM}
                single
                clerable={false}
                isSearchable={false}
                value={schedulingConfig.recur}
                style={{
                  margin: "0 6px",
                  maxWidth: 250,
                }}
                handleChange={(value) => {
                  dispatch({
                    type: SET_ROLL_RECURR,
                    value,
                  });
                }}
              />
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 600,
                }}
              >
                {" "}
                during the campaign period
              </Typography>
            </>
          )}
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          <Typography variant="subtitle1" style={{ fontWeight: 800 }}>
            Limit to displaying{" "}
          </Typography>
          <ActionsTextField
            value={frequency.count}
            style={{
              maxWidth: 72,
              marginBottom: 0,
              marginLeft: 4,
            }}
            InputProps={{ min: 0 }}
            onChange={(e) => {
              dispatch({
                type: SET_FREQ_COUNT,
                count: Number(e.target.value),
              });
            }}
            type="number"
          />
          <Typography
            variant="subtitle1"
            style={{
              marginLeft: 4,
              fontWeight: 600,
            }}
          >
            time(s)
          </Typography>
          <MultiSelect
            single
            clerable={false}
            isSearchable={false}
            options={FREQUENCY_ENUM}
            value={frequency.validity}
            handleChange={(value) => {
              dispatch({
                type: SET_FREQ_VALIDITY,
                validity: value,
              });
            }}
            style={{
              marginLeft: 4,
              maxWidth: 200,
            }}
          />
          <Typography
            variant="subtitle1"
            style={{
              marginLeft: 4,
              fontWeight: 600,
            }}
          >
            per user in campaign duration
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div style={{ marginTop: 6 }}>
            <FormControlLabel
              label={
                <Typography
                  variant="subtitle1"
                  style={{
                    fontWeight: 800,
                  }}
                >
                  Day and Session Limits
                </Typography>
              }
              control={
                <Checkbox
                  color="primary"
                  checked={frequency.DSLimits || false}
                  onChange={(e) => {
                    dispatch({
                      type: SET_FREQ_LIMITS_ENABLED,
                      enabled: e.target.checked,
                    });
                    dispatch({
                      type: SET_FREQ_SESSION_LIMIT,
                      value: 1,
                    });
                    dispatch({
                      type: SET_FREQ_DAY_LIMIT,
                      value: 1,
                    });
                  }}
                  disableRipple
                />
              }
            />
            <RowDiv>
              {frequency.DSLimits && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    paddingLeft: 36,
                    paddingBottom: 6,
                  }}
                >
                  <ActionsTextField
                    placeholder="10"
                    value={frequency.ses_lmt}
                    style={{
                      maxWidth: 72,
                      marginBottom: 0,
                      margin: "0 6px",
                    }}
                    InputProps={{
                      min: 1,
                      max: 10,
                    }}
                    onChange={(e) => {
                      dispatch({
                        type: SET_FREQ_SESSION_LIMIT,
                        value: Number(e.target.value),
                      });
                    }}
                    type="number"
                  />
                  <Typography
                    variant="subtitle1"
                    style={{
                      fontWeight: 600,
                      marginLeft: 4,
                    }}
                  >
                    time(s) per session
                  </Typography>
                </div>
              )}

              {frequency.DSLimits && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    paddingLeft: 36,
                    paddingBottom: 6,
                  }}
                >
                  <ActionsTextField
                    placeholder="10"
                    value={frequency.day_lmt}
                    style={{
                      maxWidth: 72,
                      marginBottom: 0,
                      margin: "0 6px",
                    }}
                    InputProps={{
                      min: 1,
                      max: 10,
                    }}
                    onChange={(e) => {
                      dispatch({
                        type: SET_FREQ_DAY_LIMIT,
                        value: Number(e.target.value),
                      });
                    }}
                    type="number"
                  />
                  <Typography
                    variant="subtitle1"
                    style={{
                      fontWeight: 600,
                      marginLeft: 4,
                    }}
                  >
                    time(s) per day
                  </Typography>
                </div>
              )}
            </RowDiv>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
