import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import MultiSelect from "../../../../../ReusableComponents/MultiSelect";
import CustomMaterialUIDialog from "../../../../../ReusableComponents/CustomMaterialUIDialog";

export default function TransferAdmin({ customers, transferAdmin }) {
  const [newOwner, setNewOwner] = useState("");
  const [continueAsUser, setContinueAsUser] = useState(true);
  const [transferTriggered, setTransferTriggered] = useState(false);

  const handleTransferAppOwnership = () => {
    transferAdmin(newOwner, continueAsUser);
    setNewOwner(" ");
    setTransferTriggered(false);
    setContinueAsUser(true); //emptying dashboard object
  };

  return (
    <Grid container justify="center">
      <Grid item xs={12} sm={8} md={6}>
        <Typography variant={"body2"}>
          Transfer application ownership to
        </Typography>
        <MultiSelect
          options={customers.map((o) => ({
            value: o.customer_id,
            label: o.customer_id,
          }))}
          value={newOwner}
          single={true}
          handleChange={(newOwner) => setNewOwner(newOwner)}
          placeholder="Select from Existing Customers"
          clearable={false}
          style={{ padding: 16 }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={continueAsUser}
              onChange={() => setContinueAsUser(!continueAsUser)}
              value="continueAsUser"
              color={"primary"}
            />
          }
          label="and continue as Regular User"
        />
        <br />
        <Button
          style={{ marginLeft: "auto" }}
          variant="contained"
          color="primary"
          onClick={() => setTransferTriggered(!transferTriggered)}
        >
          Proceed
        </Button>
        <CustomMaterialUIDialog
          dialogActions={
            <div>
              <Button onClick={() => setTransferTriggered(false)}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setTransferTriggered(false);
                  handleTransferAppOwnership();
                }}
                color="primary"
              >
                Confirm
              </Button>
            </div>
          }
          dialogContent={
            <Typography>
              Are you sure want to{" "}
              <strong>
                {" "}
                {continueAsUser ? "" : "leave the Application and"} transfer
                ownership
              </strong>{" "}
              to <strong>{newOwner}</strong> ?
            </Typography>
          }
          maxWidth={"sm"}
          onDialogClose={() => setTransferTriggered(false)}
          openFlag={transferTriggered}
          noTitleBg={true}
          title="Transfer App Ownership Confirmation"
        />
      </Grid>
    </Grid>
  );
}
