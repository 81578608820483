import React, { useState, useEffect } from "react";
import Filters from "../../common/components/Filters";
import { getTestDevicesAPI } from "../../common/actions";
import { getAttributeValuesAPI } from "../../../common/actions";
import { useAuth } from "../../../../contexts/AuthContext";
import {
  Divider,
  Paper,
  Grid,
  Tooltip,
  IconButton,
  Button,
} from "@material-ui/core";
import Switch from "../../../ReusableComponents/Switch";
import MultiSelect from "../../../ReusableComponents/MultiSelect";
import {
  USER_GROUPS,
  Operators,
  USER_GROUP_ENUM,
  APXOR_SDK_CATEGORY_CONSTANTS,
} from "../../../../constants";
import CustomMaterialUIDialog from "../../../ReusableComponents/CustomMaterialUIDialog";
import Placeholder from "../../../ReusableComponents/Placeholder";
import AttributeBuilder from "../../../ReusableComponents/AttributeBuilder";
import FilterIcon from "@material-ui/icons/FilterList";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import UsersList from "../../../ReusableComponents/UsersList";
import {
  getAttributes,
  getSegmentsAPI,
  searchUserProfiles,
} from "../../../common/actions";
import { withQueryStrings } from "../../../../utils";
import { useDateFilters } from "../../common/utils";
import Loading from "../../../ReusableComponents/Loading";
import Apxor from "apxor";

export default function UserExplorer({ app }) {
  const auth = useAuth();

  // Will hanve values on component will/did mount
  const [segments, setSegments] = useState([]);
  const [sessionAttributes, setSessionAttributes] = useState([]);
  const [userAttributes, setUserAttributes] = useState([]);
  const [testDevices, setTestDevices] = useState([]);

  // API post body
  const [user, setUser] = useState([]);
  const [session, setSession] = useState([]);

  // Query Params
  const [group, setGroup] = useState("all_users");
  const [segmentId, setSegmentId] = useState("");
  const [selectedAttribute, setSelectedAttribute] = useState("");
  const [query, setQuery] = useState("");
  const [version, setVersion] = useState("");
  const [dateFilters, setDateFilters] = useDateFilters();

  // Local state
  const [attrValues, setAttrValues] = useState([]);
  const [users, setUsers] = useState([]);
  const [downloadClicked, setDownloadClicked] = useState(false);
  const [exhausted, setExhausted] = useState(false);
  const [filterClicked, setFilterClicked] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [queryChanged, setQueryChanged] = useState(false);

  const { app_id: appId } = app;

  // FIXME: Might change
  const enableDownloadUsersForPush =
    segmentId &&
    !selectedAttribute &&
    user.length === 0 &&
    session.length === 0;

  // FIXME: download enabling for eazy diner only
  const availableForDownload = [
    "2f41d301-d99d-4bb9-b83f-b4127f40d7b8",
    "70efe874-e351-4fb1-9b50-c2579ce05155",
    "b571ecdc82247c3e8eca898ee3a93cdd",
    "1bf10a55-6c63-4bd3-89e1-3dc98a3fa32b",
    "d0a0caad5476d7144c8426b48fe3d03c",
    "e67e3d74-58aa-4464-9f7c-df00fd63b567",
  ].includes(app.app_id);

  useEffect(() => {
    getTestDevicesAPI(auth, appId).then((response) => {
      setTestDevices(response);
    });
    getAttributes(auth, appId).then((response) => {
      setUserAttributes(response.user);
      setSessionAttributes(response.session);
    });
    getSegmentsAPI(auth, appId, { ...dateFilters }).then((response) => {
      setSegments(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setQueryChanged(true);
  }, [
    query,
    version,
    dateFilters,
    group,
    user,
    session,
    segmentId,
    selectedAttribute,
  ]);

  const fetchAttributeValues = (value) => {
    if (value && value !== "") {
      getAttributeValuesAPI(auth, appId, {
        ...dateFilters,
        attribute: value,
        of: "users",
        version: version === "" ? null : version,
        q: query,
      }).then((response) => {
        setAttrValues(response);
      });
    }
  };

  const performSearch = (limit = 10, fromLoadMore = false) => {
    setFetching(!fromLoadMore);
    setLoadingMore(fromLoadMore);
    searchUserProfiles(
      auth,
      appId,
      {
        ...dateFilters,
        field: selectedAttribute === "" ? null : selectedAttribute,
        limit: limit,
        q: query === "" ? null : query,
        segmentId: segmentId === "" ? null : segmentId,
        group: group,
        version: version === "" ? null : version,
      },
      { user: user, session: session }
    )
      .then((response) => {
        setUsers(response);
        setFetching(false);
        setLoadingMore(false);
        setQueryChanged(false);
      })
      .catch((e) => {
        setFetching(false);
        setLoadingMore(false);
      });
  };

  const downloadUsersForPush = () => {
    window.open(
      window.API_BASE +
        window.API_ROOT +
        "segments/download?" +
        withQueryStrings({
          appId,
          customerId: auth.user.email,
          version,
          ...dateFilters,
          segmentId,
          group,
        })
    );
  };

  return (
    <>
      <Filters
        app={app}
        showVersion
        dateFilters={dateFilters}
        onVersionChange={(version) => setVersion(version)}
        onDateFiltersChange={(since, till) =>
          setDateFilters({ since: since, till: till })
        }
      />
      <Divider />
      <section className="content">
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} md={8}>
            <Paper elevation={1} style={{ padding: 20 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Switch
                    data={USER_GROUPS.filter(
                      (item) => item.value !== USER_GROUP_ENUM.UNINSTALL_USERS
                    )}
                    value={group}
                    handleChange={(value) => setGroup(value)}
                    containerStyles={{
                      margin: "0 auto",
                    }}
                  />
                </Grid>
                <br />
                <Grid item xs={12}>
                  <MultiSelect
                    options={segments.map((o) => ({
                      label: o.name,
                      value: o._id,
                    }))}
                    value={segmentId}
                    handleChange={(segment) => {
                      setSegmentId(segment);
                      setDownloadClicked(false);
                    }}
                    placeholder={"Select Segment"}
                    single
                    clearable
                  />
                </Grid>
                <br />
                <Grid item xs={12} md={3}>
                  <MultiSelect
                    clearable
                    options={userAttributes.map((o) => ({
                      label: o,
                      value: o,
                    }))}
                    value={selectedAttribute}
                    handleChange={(value) => {
                      setSelectedAttribute(value);
                      if (!value || value === "") {
                        setQuery("");
                      }
                      fetchAttributeValues(value);
                    }}
                    placeholder={"Property"}
                    single
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <MultiSelect
                    clearable
                    options={attrValues.map((e) => ({ label: e, value: e }))}
                    value={query}
                    disabled={
                      !(selectedAttribute && selectedAttribute.length > 0)
                    }
                    handleChange={(value) => {
                      setQuery(value);
                    }}
                    placeholder={"Search..."}
                    onInputChange={(q) => {
                      if (q && q.length > 0) {
                        setQuery(q);
                      }
                    }}
                    single
                  />
                </Grid>
                <Grid item xs={1} md={1}>
                  <Tooltip
                    id="tooltip-apply-more"
                    title="Apply More Filters"
                    placement="top"
                  >
                    <IconButton
                      color="primary"
                      aria-label="Delete"
                      onClick={() => setFilterClicked(true)}
                    >
                      <FilterIcon />
                    </IconButton>
                  </Tooltip>
                  <CustomMaterialUIDialog
                    dialogActions={
                      <div>
                        <Button
                          onClick={() => {
                            setFilterClicked(false);
                            setUser([]);
                            setSession([]);
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={() => {
                            setFilterClicked(false);
                          }}
                          color="primary"
                        >
                          Apply
                        </Button>
                      </div>
                    }
                    dialogContent={
                      <Grid container justify="center">
                        <Grid item xs>
                          <AttributeBuilder
                            key={"User Properties"}
                            appId={app.app_id}
                            title={"User Properties"}
                            dateFilters={dateFilters}
                            operators={Operators}
                            attributes={userAttributes}
                            handleOnChange={(attrs) => setUser(attrs)}
                          />
                          <AttributeBuilder
                            key={"Session Properties"}
                            appId={app.app_id}
                            title={"Session Properties"}
                            dateFilters={dateFilters}
                            operators={Operators}
                            attributes={sessionAttributes}
                            handleOnChange={(attrs) => setSession(attrs)}
                          />
                        </Grid>
                      </Grid>
                    }
                    dialogProps={{
                      maxWidth: "md",
                      fullWidth: true,
                    }}
                    noTitleBg={true}
                    onDialogClose={() => {
                      setFilterClicked(false);
                      setUser([]);
                      setSession([]);
                    }}
                    openFlag={filterClicked}
                    title={"Apply Filters"}
                  />
                </Grid>
                <br />
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    disabled={fetching || !queryChanged}
                    onClick={() => {
                      performSearch();
                      setExhausted(false);
                      Apxor.logEvent(
                        "GetUsersSelected",
                        {},
                        APXOR_SDK_CATEGORY_CONSTANTS.USER_SEGMENTS
                      );
                    }}
                    color="primary"
                    style={{ margin: 10, float: "right" }}
                  >
                    Get Users
                  </Button>
                </Grid>
                <br />
                <Grid item xs={12}>
                  {!fetching && (
                    <UsersList
                      data={users}
                      appId={app.app_id}
                      testDevices={testDevices}
                    />
                  )}
                  {!fetching && users.length === 10 && (
                    <Button
                      disabled={exhausted}
                      size="small"
                      style={{ margin: "10px auto", display: "block" }}
                      color="primary"
                      onClick={() => {
                        performSearch(50, true);
                        setExhausted(true);
                      }}
                    >
                      Load more...
                    </Button>
                  )}
                  {users.length > 0 &&
                    enableDownloadUsersForPush &&
                    availableForDownload && (
                      <Tooltip
                        id="users-download-for-push"
                        title="Download users to send Push notifications"
                        placement="top"
                      >
                        <Button
                          variant="contained"
                          disabled={downloadClicked}
                          onClick={() => {
                            setDownloadClicked(true);
                            setTimeout(() => {
                              setDownloadClicked(false);
                            }, 45000);
                            downloadUsersForPush();
                          }}
                          color="primary"
                          style={{ margin: 10, float: "left" }}
                        >
                          {downloadClicked && "Download starting..."}
                          {!downloadClicked && "Download"}
                        </Button>
                      </Tooltip>
                    )}
                  {!fetching && users.length === 0 && (
                    <Placeholder
                      text="No users found. Try changing filters"
                      withIcon
                      icon={
                        <PeopleOutlineIcon
                          style={{ width: 100, height: 100, opacity: 0.2 }}
                        />
                      }
                      rootStyles={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    />
                  )}
                  {(fetching || loadingMore) && <Loading size={36} />}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </section>
    </>
  );

}