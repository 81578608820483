import {
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { ChevronLeft, RefreshOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import { isExperienceDashboard } from "../../../../../../../../config";
import { useAuth } from "../../../../../../../../contexts/AuthContext";
import ColumnDiv from "../../../../../../../ReusableComponents/ColumnDiv";
import Loading from "../../../../../../../ReusableComponents/Loading";
import RowDiv from "../../../../../../../ReusableComponents/RowDiv";
import { getTestDevicesAPI } from "../../../../../../common/actions";
import { SET_DEFAULT_TEST_DEVICES } from "../../../../../../common/components/campaigncreator/constants";
import { useTracked } from "../../../../../../common/components/campaigncreator/store";
import DownloadApxorAppContent from "../../../../../../common/components/DownloadApxorAppContent";
import { IconImageComponent } from "../../../../../../common/utils";
import mobile from "../../assets/mobile.svg";

export default function DownloadAppDialog({ handleClose, handleDone }) {
  const auth = useAuth();
  const [state, dispatch] = useTracked();
  const { test_devices = [] } = state;

  const [step, setStep] = useState(0);
  const [fetching, setFetching] = useState(false);

  const fetchTestDevices = () => {
    setFetching(true);
    getTestDevicesAPI(auth, auth.appId, null, isExperienceDashboard())
      .then((response) => {
        const reversed = response.reverse();
        dispatch({
          type: SET_DEFAULT_TEST_DEVICES,
          devices: reversed,
        });
        setFetching(false);
      })
      .catch(() => {
        setFetching(false);
      });
  };

  return (
    <ColumnDiv styles={{ padding: 50, paddingRight: 25, paddingBottom: 10 }}>
      {step === 0 && (
        <Grid container spacing={4}>
          <Grid item xs={8}>
            <DownloadApxorAppContent />
          </Grid>
          <Grid
            item
            xs={4}
            style={{ display: "flex", justifyContent: "center" }}
          >
            {IconImageComponent(mobile, "Mobile", {
              width: "unset",
              height: "unset",
            })}
          </Grid>
        </Grid>
      )}
      {step === 1 && (
        <Grid container spacing={1}>
          <Grid item xs={12} style={{ display: "flex" }}>
            <RowDiv styles={{ marginLeft: -10 }}>
              <IconButton
                color="default"
                style={{ padding: 0 }}
                onClick={() => setStep(step - 1)}
              >
                <ChevronLeft color="primary" />
              </IconButton>
              <Typography variant="subtitle1" style={{ fontWeight: 800 }}>
                Once you add your test device in Apxor app click{" "}
                <span style={{ fontWeight: 900 }}>Refresh Button</span>
              </Typography>
            </RowDiv>
          </Grid>
          <Grid item xs={12}>
            <ColumnDiv
              styles={{ border: "1px solid #CCD4DA", borderRadius: 3 }}
            >
              <RowDiv
                center
                styles={{
                  justifyContent: "space-between",
                  borderBottom: "1px solid #CCD4DA",
                  padding: "5px 10px",
                }}
              >
                <Typography variant="body1" style={{ fontWeight: 800 }}>
                  TEST DEVICES
                </Typography>
                <Button
                  vatiant="outline"
                  disabled={fetching}
                  onClick={() => fetchTestDevices()}
                  style={{
                    fontSize: 12,
                    textDecoration: "underline",
                    textTransform: "none",
                  }}
                >
                  {!fetching && <RefreshOutlined color="action" />}
                  {fetching && <Loading size={18} />}
                  {!fetching ? "Refresh" : "Refreshing.."}
                </Button>
              </RowDiv>
              <List style={{ maxHeight: 380, overflow: "scroll" }}>
                {test_devices.map((device, index) => (
                  <ListItem key={index}>
                    <ListItemText>
                      {device.hasOwnProperty("nick_name")
                        ? device.nick_name
                        : device.model}{" "}
                      - {device.id}
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </ColumnDiv>
          </Grid>
        </Grid>
      )}
      <RowDiv
        styles={{ justifyContent: "flex-end", margin: "16px 0px 8px 0px" }}
      >
        <Button
          variant="text"
          color="primary"
          onClick={() => handleClose()}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        {step === 0 && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setStep(step + 1)}
          >
            Next
          </Button>
        )}
        {step === 1 && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleDone()}
          >
            Done
          </Button>
        )}
      </RowDiv>
    </ColumnDiv>
  );
}

