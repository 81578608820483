import React, { useEffect, useState, useMemo } from "react";
import { useHistory, Link } from "react-router-dom";
import BrokenImageIcon from "@material-ui/icons/BrokenImage";
import Placeholder from "../../../../../ReusableComponents/Placeholder";
import Loading from "../../../../../ReusableComponents/Loading";
import { Button, Grid } from "@material-ui/core";
import { useAuth } from "../../../../../../contexts/AuthContext";
import moment from "moment";

import { getCampaignAPI } from "../../../../common/actions";
import AnalysisHeader from "./components/variantanalysis/AnalysisHeader";
import CampaignStepsAnalysis from "./components/variantanalysis/CampaignStepsAnalysis";
import WinnerAndNumbersSection from "./components/variantanalysis/WinnerAndNumbersSection";
import { Provider, useTracked } from "./store";
import { SET_INITIAL_DATA } from "./constants";
import GoalEventAnalysis from "./components/variantanalysis/GoalEventAnalysis";
import CampaignMetricsCard from "../../../dashboard/components/CampaignMetricsCard";
import Graphs from "../../../dashboard/components/Graphs";
import { getCampaignMetricsAPI } from "./actions";
import { CAMPAIGNS_HOLDER_TABS } from "../../../../common/utils";

export const RESULTS_LAYOUT = {
  MULTI: "multi_steps",
  SINGLE: "single_step",
  DUAL: "two_steps",
};

// FIXME: Refactor this. Make sure each box is a simple component
function WalkthroughsAnalysisWithProvider({
  app,
  message,
  actionType,
  designSummaryComponent,
}) {
  const auth = useAuth();
  const history = useHistory();
  const { app_id: appId } = app;

  const [state, dispatch] = useTracked();
  const { funnelTime = 300, goalEvents } = state;

  const [goalEvent, setGoalEvent] = useState(
    message ? message && message.goal_event : null
  );

  const [fetchingConfig, setFetchingConfig] = useState(true);

  const [config, setConfig] = useState(message);
  const [showTestResults, setShowTestResults] = useState(false); //winnerPublished? false: true

  const [variant, setSelectedVariant] = useState("A"); //WinnerPublished? WinnerVariant: A;
  const [resultsLayout, setResultsLayout] = useState(RESULTS_LAYOUT.MULTI);

  const dummyMessageObject = useMemo(() => {
    return {
      validity: { start_date: null, end_date: null },
      statusType: null,
    };
  }, []);

  const {
    validity: { start_date, end_date },
    state: campaignState,
    meta: { name: configName, isExperiment = false } = {
      name: "Campaign name",
      isExperiment: false,
    },
    isWinnerVariant = false,
    experiment: {
      isWinnerPublished = false,
      publishSettings: { published_variant },
    } = {
      isWinnerPublished: false,
      publishSettings: {
        published_variant: "A",
      },
    },
  } = config ? config : dummyMessageObject;

  const isTestPublished = useMemo(() => {
    return (
      campaignState === CAMPAIGNS_HOLDER_TABS.ACTIVE ||
      campaignState === CAMPAIGNS_HOLDER_TABS.COMPLETED
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignState]);

  const dateFilters = () => {
    return {
      since:
        moment(start_date).utc().startOf("day").toISOString().slice(0, 23) +
        "Z",
      till:
        moment(end_date).utc().endOf("day").toISOString().slice(0, 23) + "Z",
    };
  };

  useEffect(() => {
    let campaignId;
    if (message) {
      campaignId = message._id;
    } else {
      const pathName = history.location.pathname;
      if (pathName.endsWith("/analysis")) {
        const path = pathName.substring(pathName.indexOf("walkthroughs/") + 13);
        campaignId = path.substring(0, path.indexOf("/analysis"));
      }
    }

    setFetchingConfig(true);
    getCampaignAPI(auth, auth.appId, campaignId, {
      configType: "walkthrough",
    }).then((response) => {
      setConfig(response);
      setGoalEvent(response.goal_event);
      dispatch({
        type: SET_INITIAL_DATA,
        goalEvents: [],
        startDate: response?.validity?.start_date,
        endDate: response?.validity?.end_date,
        defaultGoalEvent: response?.goal_event ?? null,
      });
      setFetchingConfig(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowTestResults(isExperiment && !isWinnerVariant);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExperiment, isWinnerVariant]);

  useEffect(() => {
    setSelectedVariant(published_variant);
  }, [published_variant]);

  if (!fetchingConfig && config) {
    const createdAt = config.hasOwnProperty("track")
      ? config.track.hasOwnProperty("created_at")
        ? config.track.created_at
        : moment().format("MMM Do YY")
      : moment().format("MMM Do YY");

    return (
      <div>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div style={{ marginLeft: 30, marginRight: 30 }}>
              <AnalysisHeader
                campaignConfig={config}
                designSummaryComponent={designSummaryComponent}
                winnerPublished={isWinnerPublished}
                setShowTestResults={setShowTestResults}
                isWinnerPublished={isWinnerPublished}
                isExperiment={isExperiment}
                actionType={actionType}
                isWinnerVariant={isWinnerVariant}
              />
            </div>
            {(!isExperiment || !showTestResults) && (
              <Grid item xs={12} style={{ marginTop: 30 }}>
                <CampaignMetricsCard
                  campaignType="walkthrough"
                  appId={appId}
                  isDashboards={false}
                  getCampaignMetricsAPI={getCampaignMetricsAPI}
                  apiMeta={{
                    postBody: {
                      name: config.goal_event ?? "InlineTargetViewClicked",
                      attributes: [],
                    },

                    params: {
                      messageId: config._id,
                      time: funnelTime,
                    },
                  }}
                  isExperiment={isExperiment}
                />
              </Grid>
            )}
          </Grid>
          {isExperiment && showTestResults && (
            <Grid item xs={12}>
              <WinnerAndNumbersSection
                campaignConfig={config}
                winnerVariant={variant}
              />
            </Grid>
          )}
          <Grid item xs={resultsLayout === RESULTS_LAYOUT.MULTI ? 12 : 6}>
            <CampaignStepsAnalysis
              campaignConfig={config}
              variant={variant}
              setSelectedVariant={setSelectedVariant}
              showTestResults={showTestResults}
              winnerVariant={variant}
              isExperiment={isExperiment}
              setResultsLayout={setResultsLayout}
              resultsLayout={resultsLayout}
            />
          </Grid>
          <Grid item xs={resultsLayout === RESULTS_LAYOUT.MULTI ? 12 : 6}>
            <GoalEventAnalysis
              campaignConfig={config}
              variant={variant}
              setSelectedVariant={setSelectedVariant}
              showTestResults={showTestResults}
              winnerVariant={variant}
              isExperiment={isExperiment}
            />
          </Grid>
          {(!showTestResults || !isExperiment) && (
            <Grid item xs={12}>
              <Graphs
                appId={appId}
                isDashboard={false}
                dateFilters={dateFilters}
                campaignId={config._id}
                isExperiment={isExperiment}
                winner_variant_suggested={variant}
              />
            </Grid>
          )}
        </Grid>
      </div>
    );
  } else if (fetchingConfig) {
    return <Loading size={24} />;
  } else {
    return (
      <section className="content">
        <Placeholder
          withIcon
          icon={
            <BrokenImageIcon
              style={{ opacity: 0.5, width: 120, height: 120 }}
            />
          }
        >
          <div style={{ fontSize: 20 }}>
            Try Coming Through Walkthroughs Listing Page.
          </div>
          <Link to={`/apps/${appId}/walkthroughs`}>
            <div>
              <Button
                style={{
                  marginTop: 12,
                  marginBottom: 20,
                }}
                variant={"outlined"}
                color={"primary"}
              >
                Take Me there
              </Button>
            </div>
          </Link>
        </Placeholder>
      </section>
    );
  }
}

export default function WalkthroughsAnalysis({
  app,
  message,
  actionType,
  designSummaryComponent,
}) {
  return (
    <Provider>
      <WalkthroughsAnalysisWithProvider
        app={app}
        message={message}
        actionType={actionType}
        designSummaryComponent={designSummaryComponent}
      />
    </Provider>
  );

}