import { Grid } from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  subscribeToEvent,
  unSubscribeAllConfigurationEvents,
} from "../../../../../walkthroughs/components/design/components/configuration/EventSubscriber";
import { HIDE_CONFIGURATION_TOOLBARS, SET_MODULE_TYPE } from "../../constants";
import { useTracked } from "../../store";

import ConfigToolbars from "./components/ConfigToolbars";
import SimulatorHolder from "./components/SimulatorHolder";

export default function Simulator({ platform }) {
  const [state, dispatch] = useTracked();

  const { currentStep, ui, moduleType = "" } = state;
  const currentQuestion = ui.questions[currentStep];
  const { answer_mode = "" } = currentQuestion;

  const simulatorRef = useRef(null);
  const [target, setTarget] = useState(null);

  const closeModal = useCallback(
    ({ target }) => {
      if (
        simulatorRef &&
        simulatorRef.current &&
        simulatorRef.current.contains(target)
      ) {
        try {
          setTarget(null);
          dispatch({
            type: SET_MODULE_TYPE,
            value: "",
          });
        } catch (e) {}
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [target]
  );

  useEffect(() => {
    const collapseCallback = () => {
      setTarget(null);
      dispatch({
        type: SET_MODULE_TYPE,
        value: "",
      });
    };
    const onNewStepAddSubscriber = subscribeToEvent(
      HIDE_CONFIGURATION_TOOLBARS,
      collapseCallback
    );
    return () => {
      unSubscribeAllConfigurationEvents([onNewStepAddSubscriber]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (target) {
      setTimeout(() => {
        document.addEventListener("click", closeModal, false);
      }, 100);
    } else {
      document.removeEventListener("click", closeModal, false);
    }

    return () => {
      document.removeEventListener("click", closeModal, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target]);

  return (
    <Grid
      id="survey-template-editor"
      container
      justify="center"
      alignContent="center"
      alignItems="center"
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        marginTop: 10,
        marginBottom: 10,
        position: "relative",
        minHeight: 300,
        height: 420,
        maxWidth: 420,
        margin: "20%",
      }}
      ref={simulatorRef}
    >
      {/* <ConfigToolbars
        currentStep={currentStep}
        moduleType={moduleType}
        answer_mode={answer_mode}
        platform={platform}
        target={target}
        currentQuestion={currentQuestion}
      /> */}

      <SimulatorHolder
        moduleType={moduleType}
        setTarget={setTarget}
        currentQuestion={currentQuestion}
      />
    </Grid>
  );
}

