import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Input,
  Link,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useAuth } from "../../../../contexts/AuthContext";
import useMutateRouterState from "../../../../utils/use-mutate-router-state";
import { apxTheme } from "../../../ApplicationFrame/styling/CustomCSS";
import Loading from "../../../ReusableComponents/Loading";
import { SET_AUTH_ERROR, SET_EMAIL_ID, SET_PASSWORD } from "../../constants";
import { useDispatch, useTrackedState } from "../../store";
import PasswordInput from "../components/PasswordInput";
import useIntegration from "../hooks/use-integration";
import AuthLayout from "../layouts/AuthLayout";

export default function SignInPage() {
  const { isSubmitDisabled } = useAuth();
  const { loginUser, makeUserStatusInvited } = useIntegration();
  const state = useTrackedState();
  const dispatch = useDispatch();
  const {
    push,
    location: { state: routerState },
  } = useHistory();
  const { clearStateFields } = useMutateRouterState();

  const {
    user: { email, password },
    auth_error,
    auth_loading,
  } = state;

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (await loginUser(email, password)) {
      if (routerState?.setInvitedToTrue) {
        await makeUserStatusInvited(routerState?.token);
        clearStateFields(["setInvitedToTrue", "token"]);
      }
      push("/");
    }
  };

  return (
    <AuthLayout title="Sign In">
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "start",
          marginRight: "24px",
          flex: "1",
        }}
      >
        <label
          htmlFor="email"
          style={{
            marginTop: "16px",
            fontSize: "13px",
            fontWeight: "500",
            color: "#667A8A",
          }}
        >
          Email ID
        </label>
        <Input
          name="email"
          size="medium"
          id="email"
          type="email"
          value={email}
          onChange={(e) => {
            dispatch({
              type: SET_EMAIL_ID,
              payload: e.target.value,
            });
            if (auth_error?.length > 0) {
              dispatch({
                type: SET_AUTH_ERROR,
                payload: "",
              });
            }
          }}
          style={{
            width: "60%",
            marginTop: "4px",
            border: "1px solid #CCD4DA",
            borderRadius: "2px",
            fontWeight: "600",
            fontSize: "14px",
            padding: "4.5px",
          }}
        />
        <label
          htmlFor="password"
          style={{
            marginTop: "16px",
            fontSize: "13px",
            fontWeight: "500",
            color: "#667A8A",
          }}
        >
          Password
        </label>
        <PasswordInput
          name="password"
          id="password"
          value={password}
          onChange={(e) => {
            dispatch({
              type: SET_PASSWORD,
              payload: e.target.value,
            });
            if (auth_error?.length > 0) {
              dispatch({
                type: SET_AUTH_ERROR,
                payload: "",
              });
            }
          }}
          style={{
            width: "60%",
            marginTop: "4px",
            border: "1px solid #CCD4DA",
            borderRadius: "2px",
            fontWeight: "600",
            fontSize: "14px",
            padding: "4.5px",
            marginBottom: "10px",
          }}
        />
        <Box
          style={{
            width: "60%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "45px",
          }}
        >
          <FormControlLabel
            value="end"
            control={<Checkbox color="primary" />}
            label={
              <Typography
                style={{
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                Remember Me
              </Typography>
            }
            labelPlacement="end"
            style={{
              fontSize: "13px",
              fontWeight: "500",
            }}
          />
          <Link
            component={RouterLink}
            to="/forgot-password"
            style={{
              color: apxTheme.palette.primary.main,
              fontSize: "13px",
              fontWeight: "500",
            }}
          >
            Forgot Password?
          </Link>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            marginBottom: "12px",
            width: auth_error ? "100%" : "60%",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            disabled={isSubmitDisabled || auth_error !== ""}
            style={{
              width: auth_error ? "30%" : "50%",
              height: 48,
            }}
          >
            {auth_loading ? <Loading size={28} color="#FFFFFF" /> : "Sign In"}
          </Button>
          <span
            style={{
              marginLeft: "12px",
              fontSize: "16px",
              fontWeight: "500",
              color: "#F12D2D",
            }}
          >
            {auth_error !== "" && auth_error}
          </span>
        </Box>
        <Typography
          style={{
            fontSize: "13px",
            fontWeight: "600",
            color: "#4D697D",
          }}
        >
          Don&apos;t have an account?{" "}
          <Link
            component={RouterLink}
            to="/sign_up"
            style={{
              color: apxTheme.palette.primary.main,
            }}
          >
            Sign Up
          </Link>
        </Typography>
      </form>
    </AuthLayout>
  );
}
