import { produce } from "immer";

export const FETCH_SEGMENTS = "FETCH_SEGMENTS";
export const FETCH_COHORTS = "FETCH_COHORTS";

export const initialState = {
  fetchSegments: 0,
  fetchCohorts: 0,
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case FETCH_SEGMENTS:
      draft.fetchSegments++;
      break;
    case FETCH_COHORTS:
      draft.fetchCohorts++;
      break;
    default:
      return draft;
  }
});
