import React, { useState, useMemo } from "react";
import Box from "../../../../ReusableComponents/Box";
import { APX_FEATURES } from "../../../../../constants";
import EventIcon from "@material-ui/icons/Event";
import Checkbox from "../../../../ReusableComponents/Checkbox";
import InfoHelper from "../../../../ReusableComponents/InfoHelper";
import { Grid } from "@material-ui/core";
import MultiUtilityChart from "../../../../ReusableComponents/MultiUtilityChart";
import { useAuth } from "../../../../../contexts/AuthContext";
import { useDeepCompareEffect } from "../../../../../utils/use-deep-compare";
import { getTimeSpentAPI } from "../actions";
import { addedWithAverageKey } from "../../../../../utils";

function replaceKeys(data = [], oldKey, newKey) {
  return data.map((item) => ({
    [newKey]: item[oldKey],
    ...item,
  }));
}

const available_metrics = ["average", "P25", "P50", "P75", "P90"];

export default function TimeSpents({ appId, features, queryParams, filters }) {
  const auth = useAuth();

  // Time Spents Local State
  const [fetching, setFetching] = useState(false);
  const [fetchingUsers, setFetchingUsers] = useState(false);
  const [normalize, setNormalize] = useState(false);
  const [timeSpentSessions, setTimeSpentSessions] = useState([]);
  const [timeSpentUsers, setTimeSpentUsers] = useState([]);
  const [normalizeTimeSpentSessions, setNormalizedTimeSpentSessions] = useState(
    []
  );
  const [normalizeTimeSpentUsers, setNormalizedTimeSpentUsers] = useState([]);

  // Memoizing always helps
  const normalizedTimespentEnabled = useMemo(() => {
    return features.includes(APX_FEATURES.APX_NORMALIZED_TIME_SPENT);
  }, [features]);

  // Memoizing always helps
  const showNormalizedTimespent = useMemo(
    () => normalize && normalizedTimespentEnabled,
    [normalize, normalizedTimespentEnabled]
  );

  useDeepCompareEffect(() => {
    setFetching(true);
    // FIXME: Use `Promise.all` to prevent displaying `No Data` while
    //        one of the request is still in progress.
    getTimeSpentAPI(
      auth,
      appId,
      { ...queryParams, of: "sessions" },
      filters,
      normalize
    )
      .then((response) => {
        setFetching(false);
        const modifiedData = replaceKeys(
          response,
          normalize ? "average" : "value",
          "seconds"
        );
        if (normalize) {
          setNormalizedTimeSpentSessions(modifiedData);
        } else {
          setTimeSpentSessions(addedWithAverageKey(modifiedData, "seconds"));
        }
      })
      .catch((e) => {
        setFetching(false);
        if (normalize) {
          setNormalizedTimeSpentSessions([]);
        } else {
          setTimeSpentSessions([]);
        }
      });

    setFetchingUsers(true);
    getTimeSpentAPI(
      auth,
      appId,
      { ...queryParams, of: "users" },
      filters,
      normalize
    )
      .then((response) => {
        setFetchingUsers(false);
        const modifiedData = replaceKeys(
          response,
          normalize ? "average" : "value",
          "seconds"
        );
        if (normalize) {
          setNormalizedTimeSpentUsers(modifiedData);
        } else {
          setTimeSpentUsers(addedWithAverageKey(modifiedData, "seconds"));
        }
      })
      .catch((e) => {
        setFetchingUsers(false);
        if (normalize) {
          setNormalizedTimeSpentUsers([]);
        } else {
          setTimeSpentUsers([]);
        }
      });
  }, [auth, appId, queryParams, filters, normalize]);

  return (
    <Box
      title="360"
      icon={<EventIcon />}
      withPadding
      footer={
        <div>
          {normalizedTimespentEnabled && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                label={"Time Spent Percentile Distribution"}
                checked={normalize}
                style={{ marginBottom: 12 }}
                handleChange={(normalize) => {
                  setNormalize(normalize);
                }}
              />
              <InfoHelper>
                Removed outliers capped with 99 percentile standard
              </InfoHelper>
            </div>
          )}
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <MultiUtilityChart
                height={250}
                valueLabel="Time Spent Per Session (in Seconds)"
                dataKey="key"
                fetching={fetching}
                lineDataKeys={
                  showNormalizedTimespent ? [...available_metrics] : ["seconds"]
                }
                data={
                  showNormalizedTimespent
                    ? normalizeTimeSpentSessions
                    : timeSpentSessions
                }
                withoutAggregations
                title="Time Spent Per Session (in Seconds)"
                withHeader={true}
                color="#519674"
                dot={false}
                showAverage={!showNormalizedTimespent}
                syncId="anyId-sessionLength"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <MultiUtilityChart
                height={250}
                valueLabel="Time Spent Per User (in Seconds)"
                dataKey="key"
                fetching={fetchingUsers}
                lineDataKeys={
                  showNormalizedTimespent ? [...available_metrics] : ["seconds"]
                }
                data={
                  showNormalizedTimespent
                    ? normalizeTimeSpentUsers
                    : timeSpentUsers
                }
                withoutAggregations
                title="Time Spent Per User (in Seconds)"
                withHeader={true}
                color="#519674"
                dot={false}
                showAverage={!showNormalizedTimespent}
                syncId="anyId-sessionLength"
              />
            </Grid>
          </Grid>
        </div>
      }
    ></Box>
  );
}
