import React, { useCallback, useMemo, useState } from "react";
import {
  Settings as SettingsIcon,
  Clear as RemoveIcon,
} from "@material-ui/icons";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Button,
} from "@material-ui/core";
import {
  Route,
  Switch,
  useRouteMatch,
  useHistory,
  useLocation,
} from "react-router-dom";
import NotificationCreatorWithProvider from "./components/NotificationCreator";
import NotificationList from "./components/NotificationList";
import Error404 from "../../../Error/404";
import { APP_PLATFORMS } from "../../../../constants";
import AndroidKeysForm from "./components/push_config_keys/AndroidKeysForm";
import IOSKeysForm from "./components/push_config_keys/IOSKeysForm";
import usePushConfigKeys from "./components/push_config_keys/use-push-config-keys";
import OmniKeysForm from "./components/push_config_keys/OmniKeysForm";

export default function Push({ app }) {
  const platform = app?.basic_info?.platform;
  const app_id = app?.app_id;

  const { path } = useRouteMatch();
  const location = useLocation();
  const { push } = useHistory();
  const state = useMemo(
    () => (location && location.state ? location.state : null),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]
  );

  const { is_save_disabled } = usePushConfigKeys({
    app_id,
    platform,
  });

  const [show_keys_dialog, set_show_keys_dialog] = useState(false);

  const closeDialog = useCallback(() => {
    set_show_keys_dialog(false);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }, []);

  const dialogContent = () => {
    switch (platform) {
      case APP_PLATFORMS.ios:
        return <IOSKeysForm app_id={app?.app_id} closeDialog={closeDialog} />;
      case APP_PLATFORMS.android:
        return (
          <AndroidKeysForm app_id={app?.app_id} closeDialog={closeDialog} />
        );
      case APP_PLATFORMS.omni:
        return <OmniKeysForm app_id={app?.app_id} />;
      default:
        break;
    }
  };

  return (
    <div>
      <section className="content">
        <Switch>
          <Route exact path={path}>
            <Grid container alignItems="center">
              <Grid
                item
                xs={9}
                style={{ margin: "20px 0 20px auto", display: "block" }}
              >
                <Button
                  style={{
                    fontWeight: "bold",
                  }}
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={() => {
                    set_show_keys_dialog(true);
                  }}
                  startIcon={<SettingsIcon />}
                >
                  Push Config
                </Button>
                <Dialog
                  scroll="body"
                  open={show_keys_dialog}
                  onClose={closeDialog}
                  fullWidth={true}
                  maxWidth={platform === APP_PLATFORMS.android ? "sm" : "md"}
                >
                  <Box
                    style={{
                      position: "relative",
                      padding: "20px",
                    }}
                  >
                    <IconButton
                      color="inherit"
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                      }}
                      onClick={closeDialog}
                    >
                      <RemoveIcon />
                    </IconButton>
                    <DialogTitle
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Enter your Keys
                    </DialogTitle>
                    <Divider variant="middle" />
                    <DialogContent>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          paddingBlock: "3rem",
                        }}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          spacing={3}
                        >
                          {dialogContent()}
                        </Grid>
                      </Box>
                    </DialogContent>
                  </Box>
                </Dialog>
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  onClick={() => push(`/apps/${app.app_id}/push/new`)}
                  color="primary"
                  style={{ margin: "20px 0 20px auto", display: "block" }}
                >
                  Create New Notification
                </Button>
              </Grid>
            </Grid>
            <NotificationList app={app} isPublishDisabled={is_save_disabled} />
          </Route>
          <Route exact path={`${path}/new`}>
            <NotificationCreatorWithProvider app={app} />
          </Route>
          <Route path={`${path}/:pushId/(edit|duplicate)`}>
            <NotificationCreatorWithProvider
              app={app}
              config={state ? state.config : null}
            />
          </Route>
          <Route path="*" component={Error404} />
        </Switch>
      </section>
    </div>
  );
}

