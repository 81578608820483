import React, { useState } from "react";
import Box from "../../../../ReusableComponents/Box";
import { DAYS_ENUM_FOR_USAGE } from "../constants";
import MultiUtilityChart from "../../../../ReusableComponents/MultiUtilityChart";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import Switch from "../../../../ReusableComponents/Switch";
import TextField from "@material-ui/core/TextField";
import { useTrackedState } from "../store";

const SWITCH_DATA = [
  {
    name: "Habit Trend",
    value: "percent",
    help:
      "% Number of retained users using the selected feature. Feature usage across the user life cycle.",
  },
  {
    name: "Habit Strength",
    value: "avg_events",
    help:
      "Number of times a selected feature is used in user life cycle. How strongly the given feature has become a habit",
  },
];

const formatRetentionUsage = (data = [], keyToSpill = "percent", days = []) => {
  function isDefined(value, strict = true) {
    if (!strict && (value === 0 || value === "")) return true; //FIXME: handling 0 values
    return value && value !== null && typeof value !== "undefined";
  }
  const sortArrayByKey = (arr = [], key, reverse = false) => {
    const f = reverse ? -1 : 1;
    if (isDefined(key)) {
      return arr.sort((a, b) =>
        a[key] > b[key] ? f : a[key] < b[key] ? -f : 0
      );
    } else {
      return arr;
    }
  };

  if (Array.isArray(data)) {
    const event_total = "event_total";
    const groupedByEvent = data.reduce((acc, obj) => {
      if (acc.hasOwnProperty(obj.event_name)) {
        acc[obj.event_name].push(obj);
      } else {
        acc[obj.event_name] = [obj];
      }
      return acc;
    }, {});
    const spillDays = (eventData = []) => {
      const obj = eventData.reduce((acc, o) => {
        acc["Day " + o.day] = o[keyToSpill];
        acc["avg_Day " + o.day] = o.user_count;
        acc[event_total] = (acc[event_total] || 0) + o.event_count;
        return acc;
      }, {});
      days.forEach((day) => {
        //filling empty days
        if (!obj.hasOwnProperty("Day " + day)) {
          obj["Day " + day] = 0;
          obj["avg_Day " + day] = 0;
        }
      });
      return {
        ...obj,
        ...(eventData[0] && eventData[0]),
      };
    };
    return sortArrayByKey(
      Object.keys(groupedByEvent).map((event) =>
        spillDays(groupedByEvent[event])
      ),
      event_total,
      true
    );
  } else {
    return [];
  }
};

export const RetentionUsage = () => {
  const state = useTrackedState();
  const { retention_usage } = state;

  const [current, setCurrent] = useState(SWITCH_DATA[0].value);
  const [query, setQuery] = useState("");

  const handleSwitch = (current) => {
    setCurrent(current);
  };

  const filteredData = formatRetentionUsage(
    retention_usage,
    current,
    DAYS_ENUM_FOR_USAGE
  ).filter((o) => o.event_name.toLowerCase().includes(query));

  return (
    <Box
      title="Habits"
      icon={<InsertChartIcon />}
      withPadding
      controls={
        <div
          style={{
            minWidth: 360,
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <TextField
            style={{ marginRight: 8 }}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="event_name"
            label="Search Events"
          />
          <Switch
            containerStyles={{ marginTop: 10 }}
            data={SWITCH_DATA}
            handleChange={(value, event) => handleSwitch(value)}
            value={current}
          />
        </div>
      }
    >
      <MultiUtilityChart
        barDataKeys={DAYS_ENUM_FOR_USAGE.map((d) => "Day " + d)}
        data={filteredData}
        dataKey="event_name"
        xLabelFormatter={(e) => e}
        showBrush
        maxBrushLength={5}
        showAverage
      />
    </Box>
  );
};
