import { produce } from "immer";
import {
  ADD_EVENT,
  REMOVE_EVENT,
  UPDATE_TIME_SERIES,
  RESET_TIME_SERIES,
} from "./constants";
import { mergeTimeSeries } from "../../../../../../utils";

export const initialState = {
  selectedEvents: {},
  countTimeSeries: [],
  usersTimeSeries: [],
  sessionsTimeSeries: [],
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case ADD_EVENT:
      draft.selectedEvents[action.event] = action.data;
      break;
    case REMOVE_EVENT:
      delete draft.selectedEvents[action.event];
      break;
    case UPDATE_TIME_SERIES:
      const key = action.key;
      if (
        action.count &&
        Array.isArray(action.count) &&
        action.count.length > 0
      ) {
        draft.countTimeSeries = mergeTimeSeries(
          draft.countTimeSeries,
          action.count,
          key
        );
      }
      if (action.user && Array.isArray(action.user) && action.user.length > 0) {
        draft.usersTimeSeries = mergeTimeSeries(
          draft.usersTimeSeries,
          action.user,
          key
        );
      }
      if (
        action.session &&
        Array.isArray(action.session) &&
        action.session.length > 0
      ) {
        draft.sessionsTimeSeries = mergeTimeSeries(
          draft.sessionsTimeSeries,
          action.session,
          key
        );
      }
      break;
    case RESET_TIME_SERIES:
      draft.countTimeSeries = [];
      draft.usersTimeSeries = [];
      draft.sessionsTimeSeries = [];
      break;
    default:
      return draft;
  }
});
