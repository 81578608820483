import React, { useState } from "react";
import {
  Button,
  Typography,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import moment from "moment";
import PresetDateRangePicker from "../../../../../../ReusableComponents/DateRangePicker";
import Loading from "../../../../../../ReusableComponents/Loading";
import WarningIcon from "@material-ui/icons/Warning";
import { createCohortFromSegment } from "../../../actions";
import { useAuth } from "../../../../../../../contexts/AuthContext";
import { FETCH_COHORTS } from "../reducer";
import { useDispatch } from "../store";

export const CohortCreator = ({
  app: {
    app_id: appId,
    settings: { timezone = "UTC" },
  },
  currentCohort,
  _id,
}) => {
  const [state, setState] = useState({
    cohortSince: moment().utc().subtract(8, "days"),
    cohortTill: moment().utc().subtract(1, "day"),
  });

  const auth = useAuth();
  const dispatch = useDispatch();
  const [createCohortPending, setCreateCohortPending] = useState(false);
  const [cohortFailedMessage, setCohortFailedMessage] = useState(null);

  return (
    <>
      <div>
        <Typography variant={"h6"}>Create Cohort for the date range</Typography>
        <div style={{ marginTop: 8, marginBottom: 12 }}>
          <PresetDateRangePicker
            handleChange={(start, end) => {
              setState({
                ...state,
                cohortSince: moment(start).utc(),
                cohortTill: moment(end).utc().endOf("d"),
              });
            }}
            inUTC
            small
            startDate={moment(state.cohortSince).utc()}
            endDate={moment(state.cohortTill).utc().endOf("d")}
            showDefaultInputIcon
            inputIconPosition="before"
            disableAfter
          />
          <Typography>{timezone}</Typography>
        </div>
        <div style={{ marginBottom: 12, marginTop: 12 }}>
          <Typography
            style={{
              display: "inline-flex",
              color: "#463815",
              backgroundColor: "#ecc86c",
              borderRadius: 4,
              padding: 4,
            }}
          >
            <WarningIcon style={{ marginRight: 4 }} /> Creating Cohorts can take
            a while.
          </Typography>
        </div>
        <div style={{ position: "relative" }}>
          <Button
            size="small"
            variant="contained"
            color={!createCohortPending ? "primary" : ""}
            disabled={currentCohort}
            onClick={() => {
              setCohortFailedMessage(null);
              setCreateCohortPending(true);
              createCohortFromSegment(
                auth,
                appId,
                _id,
                state.cohortSince.utc().startOf("d").toISOString(),
                state.cohortTill.utc().endOf("d").toISOString()
              )
                .then((response) => {
                  setCreateCohortPending(false);
                  if (response) {
                    dispatch({
                      type: FETCH_COHORTS,
                    });
                  }
                })
                .catch((err) => {
                  setCreateCohortPending(false);
                  setCohortFailedMessage("Failed to create cohort. Try again");
                });
            }}
          >
            {createCohortPending && <Loading size={16} />}
            {!createCohortPending && "Go"}
          </Button>
          {currentCohort && (
            <CircularProgress
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: -12,
                marginLeft: -12,
              }}
              size={24}
            />
          )}
        </div>
      </div>
      {!createCohortPending && cohortFailedMessage && (
        <Snackbar>
          <strong>{cohortFailedMessage}</strong>
        </Snackbar>
      )}
    </>
  );
};
