import React, { useEffect, useState, useRef } from "react";
import { Grid, Button } from "@material-ui/core";
import { useTracked } from "../store";
import { DefaultPayload } from "./DefaultPayload";
import { ConditionalPayload } from "./ConditionalPayload";
import { CustomKeysConfig } from "./CustomKeysConfig";
import Switch from "../../../../ReusableComponents/Switch";
import AddIcon from "@material-ui/icons/Add";
import ToggleSwitch from "../../../../ReusableComponents/ToggleSwitch";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import { useDateFilters } from "../../../common/utils";
import { useAuth } from "../../../../../contexts/AuthContext";
import { sendTestNotificationAPI } from "../actions";
import PushTestingDialogContent from "./PushTestingDialogContent";
import CustomMaterialUIDialog from "../../../../ReusableComponents/CustomMaterialUIDialog";
import Snackbar from "../../../../ReusableComponents/Snackbar";
import {
  SET_IS_SILENT,
  SET_IS_VALID_PUSH,
  SET_NOTIFICATION_TYPE,
  NOTIFICATION_ENUM,
  ADD_TO_CUSTOM_KEYS,
  TYPE_ENUM,
} from "../constants";
import ColumnDiv from "../../../../ReusableComponents/ColumnDiv";
import PushSimulator, { Simple, BigImage, BigText } from "./PushSimulator";
import { APP_PLATFORMS } from "../../../../../constants";

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function keyToTitle(str = "") {
  return str
    .split("_")
    .map((w) => toTitleCase(w))
    .join(" ");
}

export function DesignSelector({ app, errors, formik }) {
  const [state, dispatch] = useTracked();
  const auth = useAuth();
  const [showLaunchTestingDialog, setShowLaunchTestingDialog] = useState(false);
  const { push_config } = state;
  const { payload, meta = {} } = push_config;
  const { notification_type, is_silent, custom_keys } = payload;
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [dateFilters] = useDateFilters();
  const stateRef = useRef(state.push_config);
  stateRef.current = state.push_config;

  useEffect(() => {
    dispatch({
      type: SET_IS_VALID_PUSH,
      value: Object.keys(errors).length === 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, errors]);

  const notifTypesList = Object.keys(NOTIFICATION_ENUM).filter(notif_type =>
    meta?.platform === APP_PLATFORMS.ios
      ? notif_type === NOTIFICATION_ENUM.simple
      : true
  );

  return (
    <Grid container>
      <Grid item xs={8}>
        <Grid container>
          <Grid item xs={2}>
            {meta?.platform !== APP_PLATFORMS.ios && (
              <ToggleSwitch
                value={is_silent}
                checked={is_silent}
                handleChange={(is_silent) =>
                  dispatch({ type: SET_IS_SILENT, value: is_silent })
                }
                label="Silent"
              />
            )}
          </Grid>
          {!is_silent && (
            <>
              <Grid item xs={10}>
                <Switch
                  handleChange={(notification_type) =>
                    dispatch({
                      type: SET_NOTIFICATION_TYPE,
                      value: notification_type,
                    })
                  }
                  value={notification_type}
                  data={notifTypesList.map(o => ({
                    name: keyToTitle(o),
                    value: o,
                  }))}
                />
              </Grid>
              <Grid item xs={6}>
                <DefaultPayload errors={errors} app={app} formik={formik} />
              </Grid>
              <Grid item xs={6} md={6}>
                <ConditionalPayload errors={errors} />
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <ColumnDiv>
              {custom_keys.map((keyPair, index) => (
                <CustomKeysConfig
                  key={"config" + index}
                  index={index}
                  dataKey={keyPair.key}
                  value={keyPair.value}
                  type={keyPair.type}
                />
              ))}
              {meta?.platform !== APP_PLATFORMS.ios && (
                <Button
                  color="primary"
                  style={{ marginTop: "18px", justifyContent: "flex-start" }}
                  size="small"
                  onClick={e =>
                    dispatch({
                      type: ADD_TO_CUSTOM_KEYS,
                      value: { key: "", value: "", type: TYPE_ENUM.string },
                    })
                  }
                >
                  <AddIcon /> Add Custom Keys
                </Button>
              )}
              <Button
                color="primary"
                size="small"
                style={{ marginTop: "5px", justifyContent: "flex-start" }}
                onClick={(e) => {
                  formik.setValues(stateRef.current).then(() => {
                    formik
                      .submitForm()
                      .then(() => {
                        formik.setSubmitting(false);
                        return stateRef.current.is_valid === true
                          ? setShowLaunchTestingDialog(true)
                          : null;
                      })
                      .catch(() => {
                        return setShowLaunchTestingDialog(false);
                      });
                  });
                }}
              >
                <NotificationsNoneIcon /> Send a test push notification
              </Button>
              <CustomMaterialUIDialog
                dialogProps={{
                  disableBackdropClick: true,
                  disableEscapeKeyDown: true,
                }}
                dialogContent={
                  <PushTestingDialogContent
                    handleDone={() => {
                      sendTestNotificationAPI(
                        auth,
                        app.app_id,
                        dateFilters,
                        push_config
                      )
                        .then((response) => {
                          if (response) {
                            setShowLaunchTestingDialog(false);
                            setSnackbarMessage(
                              "Test Notification Sent Succesfully"
                            );
                          }
                        })
                        .catch(() => {
                          setShowLaunchTestingDialog(false);
                        });
                    }}
                  />
                }
                dialogContentStyles={{
                  overflowX: "hidden",
                  padding: "8px 8px",
                }}
                maxWidth={"md"}
                openFlag={showLaunchTestingDialog}
                onClose={() => setShowLaunchTestingDialog(false)}
                onDia
                logClose={() => setShowLaunchTestingDialog(false)}
              />
            </ColumnDiv>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        {!is_silent && (
          <PushSimulator>
            {notification_type === NOTIFICATION_ENUM.simple && <Simple />}
            {meta?.platform !== APP_PLATFORMS.ios &&
              notification_type === NOTIFICATION_ENUM.big_text && <BigText />}
            {meta?.platform !== APP_PLATFORMS.ios &&
              notification_type === NOTIFICATION_ENUM.big_image && <BigImage />}
          </PushSimulator>
        )}
      </Grid>
      {snackbarMessage !== "" && (
        <Snackbar onClose={() => setSnackbarMessage("")}>
          {snackbarMessage}
        </Snackbar>
      )}
    </Grid>
  );
}
