import MomentUtils from "@date-io/moment";
import {
  Grid,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Apxor from "apxor";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { isExperienceDashboard } from "../../../../../../../../../../config";
import {
  APX_FEATURES,
  LOGICAL_OPERATORS,
} from "../../../../../../../../../../constants";
import { APXOR_SDK_CATEGORY_CONSTANTS } from "../../../../../../../../../../constants/Endpoints";
import { useAuth } from "../../../../../../../../../../contexts/AuthContext";
import { getPropertiesOfSegment } from "../../../../../../../../../../utils";
import ActionsTextField from "../../../../../../../../../ReusableComponents/ActionsTextField";
import Autocomplete from "../../../../../../../../../ReusableComponents/Autocomplete";
import MultiSelect from "../../../../../../../../../ReusableComponents/MultiSelect";
import RowDiv from "../../../../../../../../../ReusableComponents/RowDiv";
import UploadCohortDialog from "../../../../../../../../../ReusableComponents/UploadCohortDialog";
import { getCohorts } from "../../../../../../../../components/cohorts/actions";
import { SegmentConfig } from "../../../../../../SegmentConfig";
import {
  AUDIENCE_TYPES,
  CLIENT_EVENTS_FOR_APP,
  SET_AUDIENCE_TYPE,
  SET_COHORTS,
  SET_COHORT_ID,
  SET_DIDNOT_DO_EVENTS,
  SET_ROLL_INTERVAL,
  SET_SEGMENT,
  SET_TOGGLE_ROLL,
  getAudienceIndex
} from "../../../../../constants";
import { useTracked } from "../../../../../store";
import { useCommonConfigStyles } from "../../../../../utils";
import OptionSelect from "../../../../OptionSelect";

const useStyles = makeStyles((theme) => ({
  segmentPreview: {
    border: `1px solid ${theme.palette.text.disabled}`,
    borderRadius: 3,
    padding: 16,
    background: "#F0F2F3",
  },
  uiPicker: {
    marginLeft: 10,
    marginTop: 18,
    height: 60,
  },
}));

const getDidNotDoEvents = (appEvents, app_id, client_events, features) => {
  let events = [
    {
      name: "App Events",
      options: appEvents.map((name) => ({
        label: name,
        value: name,
      })),
    },
  ];
  if (features.includes(APX_FEATURES.APX_CLIENT_EVENTS)) {
    let clientEvents = [...client_events];
    if (Object.keys(CLIENT_EVENTS_FOR_APP).includes(app_id))
      clientEvents = clientEvents.concat(
        Object.keys(CLIENT_EVENTS_FOR_APP[app_id])
      );

    events.push({
      name: "Client Events",
      options: clientEvents.map((name) => ({
        label: name,
        value: name,
      })),
    });

    return [events, true];
  } else {
    return [events[0].options, false];
  }
};

export default function TargetAudience({ appId: app_id, features }) {
  const auth = useAuth();
  const classes = useCommonConfigStyles();
  const previewClasses = useStyles();

  const [openDialog, setOpenDialog] = useState(false);
  const [fetchCohorts, setFetchCohorts] = useState(0);

  const [state, dispatch] = useTracked();
  const {
    app_events: appEvents,
    config: {
      audience: { audience_type, cohort_id, segment_id, start_time, end_time },
      overall_cfg,
      scheduling: { rollSegment, rollInterval },
      meta: { platform, experience_type = "" },
    },
    segments = [],
    cohorts = [],
    client_events = [],
  } = state;

  const selectedEvents = useMemo(
    () => overall_cfg.events.map((x) => x.name) || [],
    [overall_cfg]
  );
  const didNotDoEvents = useMemo(() => {
    return getDidNotDoEvents(appEvents, app_id, client_events, features);
  }, [appEvents, app_id, client_events, features]);

  useEffect(() => {
    // Upon component mount, we mustn't fetch the cohorts
    if (fetchCohorts === 0) {
      return;
    }
    getCohorts(auth, app_id).then((response) => {
      dispatch({
        type: SET_COHORTS,
        cohorts: response,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCohorts]);

  return (
    <>
      <Grid item xs={12}>
        <OptionSelect
          style={{ paddingTop: 8, paddingBottom: 12 }}
          noneSelectable={false}
          direction={"horizontal"}
          numPerRow={2}
          value={getAudienceIndex(audience_type)}
          radioOptions={{
            hasCheckboxOrRadio: true,
            radioFirst: true,
          }}
          handler={(selectedIndex) => {
            Apxor.logEvent(
              "TargetAudienceSelected",
              {
                "audience_type": AUDIENCE_TYPES[selectedIndex].key,
                "experience_type": experience_type || "",
              },
              APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS,
            );
            /**All Users only for experience demo dashboard */
            dispatch({
              type: SET_AUDIENCE_TYPE,
              audience_type: isExperienceDashboard()
                ? AUDIENCE_TYPES[0].key
                : AUDIENCE_TYPES[selectedIndex].key,
            });
          }}
          options={AUDIENCE_TYPES}
          cardStyles={{
            paddingLeft: 6,
            minWidth: 128,
            borderRadius: 3,
            boxShadow: "none",
          }}
          fieldTitleStyles={{ marginBottom: 0, marginLeft: 6 }}
        />
      </Grid>
      {audience_type === AUDIENCE_TYPES[1].key && (
        <Grid container justify={"space-between"}>
          <Grid item xs style={{ marginTop: 8, marginBottom: 8 }}>
            <Typography className={classes.typographyCaption}>
              Select Segment
            </Typography>
            <MultiSelect
              options={segments.map((segment) => ({
                label: segment.name,
                value: segment._id,
              }))}
              placeholder={"Select Segment"}
              single
              style={{ maxWidth: 440, margin: "5px 0" }}
              value={segment_id}
              handleChange={(segmentId) => {
                dispatch({
                  type: SET_SEGMENT,
                  segment_id: segmentId,
                });
              }}
            />
            {segment_id && (
              <div style={{ marginTop: 12, marginBottom: 0 }}>
                <div className={previewClasses.segmentPreview}>
                  <SegmentConfig
                    user={getPropertiesOfSegment(segments, segment_id, "user")}
                    session={getPropertiesOfSegment(
                      segments,
                      segment_id,
                      "session",
                    )}
                    event={getPropertiesOfSegment(
                      segments,
                      segment_id,
                      "event",
                    )}
                  />
                </div>
                <RowDiv>
                  <ToggleButtonGroup
                    value={rollSegment ? "Last" : "Between"}
                    exclusive
                    onChange={(e, value) => {
                      if (value === "Between") {
                        dispatch({
                          type: SET_TOGGLE_ROLL,
                          enabled: false,
                        });
                      } else if (value === "Last") {
                        dispatch({
                          type: SET_TOGGLE_ROLL,
                          enabled: true,
                        });
                      }
                    }}
                    style={{
                      marginTop: 16,
                      marginRight: 5,
                      maxHeight: 44,
                    }}
                  >
                    <ToggleButton
                      value="Between"
                      aria-label="between"
                      style={{ padding: 8 }}
                    >
                      <span style={{ padding: "0px 8px" }}>
                        <strong>Between</strong>
                      </span>
                    </ToggleButton>
                    <ToggleButton
                      value="Last"
                      aria-label="last"
                      style={{ padding: 8 }}
                    >
                      <span style={{ padding: "0px 8px" }}>
                        <strong>Last</strong>
                      </span>
                    </ToggleButton>
                  </ToggleButtonGroup>

                  {segment_id && !rollSegment && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 16,
                          maxHeight: 44,
                          minWidth: 240,
                        }}
                      >
                        <MuiPickersUtilsProvider
                          utils={MomentUtils}
                          moment={moment}
                        >
                          <DatePicker
                            className={previewClasses.uiPicker}
                            value={moment(start_time).utc()}
                            ampm={false}
                            format="DD, MMM, yyyy"
                            onChange={(start_time) => {
                              dispatch({
                                type: SET_SEGMENT,
                                start_time:
                                  moment(start_time)
                                    .utc()
                                    .startOf("day")
                                    .toISOString()
                                    .slice(0, 23) + "Z",
                              });
                            }}
                            style={{ maxWidth: 150 }}
                            TextFieldComponent={TextField}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  style={{ padding: 5 }}
                                >
                                  <CalendarTodayIcon color="primary" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        <Typography style={{ marginRight: 8, marginLeft: 8 }}>
                          and
                        </Typography>
                        <MuiPickersUtilsProvider
                          utils={MomentUtils}
                          moment={moment}
                        >
                          <DatePicker
                            className={previewClasses.uiPicker}
                            value={moment(end_time).utc()}
                            ampm={false}
                            format="DD, MMM, yyyy"
                            onChange={(end_time) => {
                              dispatch({
                                type: SET_SEGMENT,
                                end_time:
                                  moment(end_time)
                                    .utc()
                                    .endOf("day")
                                    .toISOString()
                                    .slice(0, 23) + "Z",
                              });
                            }}
                            style={{ maxWidth: 150 }}
                            TextFieldComponent={TextField}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  style={{ padding: 5 }}
                                >
                                  <CalendarTodayIcon color="primary" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </>
                  )}
                  {segment_id && rollSegment && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 16,
                        }}
                      >
                        <Typography>in the previous</Typography>
                        <ActionsTextField
                          value={rollInterval}
                          style={{
                            maxWidth: 72,
                            marginBottom: 0,
                            margin: "0 6px",
                          }}
                          InputProps={{ min: 1 }}
                          onChange={(e) => {
                            dispatch({
                              type: SET_ROLL_INTERVAL,
                              value: Number(e.target.value),
                            });
                          }}
                          type={"number"}
                        />
                        <Typography>
                          {rollInterval > 1 ? "days" : "day"}
                        </Typography>
                      </div>
                    </>
                  )}
                </RowDiv>
              </div>
            )}
          </Grid>
        </Grid>
      )}

      {audience_type === AUDIENCE_TYPES[2].key && (
        <Grid item xs={12}>
          <Typography className={classes.typographyCaption}>
            Select or upload new cohort
          </Typography>
          <Autocomplete
            open={false}
            disableClearable={false}
            options={cohorts.map((cohort) => ({
              label: cohort.name,
              value: cohort.cohort_id,
            }))}
            button={{
              label: "+ Add New Cohort",
              value: "+ Add New Cohort",
              isButton: true,
            }}
            defaultValue={{
              label:
                cohort_id === ""
                  ? ""
                  : cohorts.filter(
                      (cohort) => cohort.cohort_id === cohort_id,
                    )[0].name,
              value: cohort_id,
            }}
            handleOnChange={(selected) => {
              let value = "";
              if (selected) {
                value = selected.value;
              }
              if (selected?.isButton) {
                setOpenDialog(true);
                return;
              }
              dispatch({
                type: SET_COHORT_ID,
                cohort_id: value,
              });
            }}
          />
        </Grid>
      )}
      <Grid item xs={12} style={{ marginTop: 10 }}>
        <Typography className={classes.typographyCaption}>
          Users who didn't perform below events in their lifetime
        </Typography>
        <Autocomplete
          disabled={isExperienceDashboard()}
          open={false}
          options={didNotDoEvents[0]}
          multiple={true}
          grouped={didNotDoEvents[1]}
          defaultValue={selectedEvents.map((name) => ({
            label: name,
            value: name,
          }))}
          handleOnChange={(events) => {
            let updatedEventsList = [];
            if (Array.isArray(events)) {
              events.forEach((event) => {
                updatedEventsList.push({
                  name: event.value,
                  op: LOGICAL_OPERATORS.LT,
                  value: 1,
                });
              });
            }
            dispatch({
              type: SET_DIDNOT_DO_EVENTS,
              events: updatedEventsList,
            });
          }}
        />
      </Grid>
      {openDialog && getAudienceIndex(audience_type) === 2 && (
        <UploadCohortDialog
          appId={app_id}
          onClose={() => {
            setFetchCohorts(fetchCohorts + 1);
            setOpenDialog(false);
          }}
        />
      )}
    </>
  );
}

