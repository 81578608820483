import { Box, Button, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import EditIcon from "@material-ui/icons/Edit";
import SettingsIcon from "@material-ui/icons/Settings";
import SyncIcon from "@material-ui/icons/Sync";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
import Apxor from "apxor";
import axios from "axios";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { isExperienceDashboard } from "../../../../../../../config";
import { APXOR_SDK_CATEGORY_CONSTANTS } from "../../../../../../../constants/Endpoints";
import { useAuth } from "../../../../../../../contexts/AuthContext";
import { withQueryStrings } from "../../../../../../../utils";
import BootstrapTooltip from "../../../../../../ReusableComponents/BootstrapTooltip";
import ColumnDiv from "../../../../../../ReusableComponents/ColumnDiv";
import CustomMaterialUIDialog from "../../../../../../ReusableComponents/CustomMaterialUIDialog";
import RowDiv from "../../../../../../ReusableComponents/RowDiv";
import {
  getSignedURLToGetImage,
  getSignedURLToPost,
  uploadImageUsingSignedURL,
} from "../../../../../common/actions";
import {
  APP_PLATFORMS,
  CAMPAIGN_TYPE_ENUM,
} from "../../../../../common/components/campaigncreator/constants";
import { EXPERIENCE_CONFIG } from "../../../../../common/components/campaignsholder/constants";
import { base64ToBlob } from "../../../../../common/utils";
import { ReactComponent as MinimizeIcon } from "../assets/minimize_icon.svg";
import videoInAppGIF from "../assets/video-inapp.gif";
import {
  INAPP_LAYOUT_TYPES,
  SET_CURRENT_IMAGE,
  SET_IMG,
  SET_VIEWID_SELECTION_DIALOG_DATA,
} from "../constants";
import { publishDesignForPreviewAPI } from "./actions";
import ViewIDPathSelection from "./common/ViewIDPathSelection";
import { ORIENTATION_ENUM } from "./simulator/utils";

const SSE_API = window.SSE_ROOT + "layout?";

export default function DesignPreviewSection({
  campaignId,
  currentStep,
  dispatch,
  image: propImage = null,
  orientation = ORIENTATION_ENUM.PORTRAIT,
  previewMode = false,
  selectedDevice = "",
  ui,
  isSummary = false,
  showEdit = false,
  handleSelect = null,
  experience_type = "",
  app,
}) {
  const auth = useAuth();

  const [fetchingImageFromURL, setFetchingImageFromURL] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [image, setImage] = useState(propImage || null);
  const [blobName, setBlobName] = useState(ui?.blobName);

  const refreshEventSourceRef = useRef(null);

  const width = useMemo(
    () => (orientation === ORIENTATION_ENUM.PORTRAIT ? 270 : 540),
    [orientation],
  );
  const height = useMemo(
    () => (orientation === ORIENTATION_ENUM.PORTRAIT ? 540 : 270),
    [orientation],
  );

  // Try to fetch the image from GCS upon mount
  useEffect(() => {
    fetchImageFromGCS();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // An effect that only do the cleanup work
  useEffect(() => {
    return () => {
      const eventSource = refreshEventSourceRef.current;

      if (eventSource !== null) {
        eventSource.close();
      }
    };
  }, []);

  // Set image whenever propImage changes
  useEffect(() => {
    setFetchingImageFromURL(false);
    if (image !== propImage) setImage(propImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propImage]);

  // This effect fetches the image from GCS whenever
  // propImage is empty and blobName is not equals to ui.blobName

  // As this component meant for all steps, blobNames will keep
  // changing whenever we visit or re-visit the steps.

  // Every time a step is visited for the first time, we fetch the
  // image from GCS
  useEffect(() => {
    if (ui?.ui?.layout_type !== INAPP_LAYOUT_TYPES.VIDEOS) {
      setFetchingImageFromURL(false);
      if (
        !propImage &&
        ui &&
        ui.blobName &&
        (blobName !== ui?.blobName || !image)
      ) {
        fetchImageFromGCS();
      }
      setBlobName(ui?.blobName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ui?.blobName, currentStep, image]);

  const fetchImageFromGCS = () => {
    if (!ui || !ui?.blobName || !ui?.provider) {
      return;
    }

    setBlobName(ui.blobName);
    setFetchingImageFromURL(true);
    getSignedURLToGetImage(auth, auth.appId, {
      campaignId,
      provider: ui.provider,
      blobName: ui.blobName,
    }).then((response) => {
      const { signed_url } = response;
      axios
        .get(signed_url, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          const data =
            "data:image/png;base64," +
            Buffer.from(response.data, "binary").toString("base64");
          setImage(data);
          if (dispatch) {
            dispatch({
              type: SET_IMG,
              value: data,
              currentStep: ui?.index ?? currentStep,
            });
          }
          setFetchingImageFromURL(false);
        });
    });
  };

  const [isGIFfullScreen, setIsGIFfullScreen] = useState(false);
  const [isGIFclicked, setIsGIFclicked] = useState(false);
  const [isPreviewHovered, setIsPreviewHovered] = useState(false);

  return (
    <ColumnDiv
      center
      onMouseEnter={() => {
        setIsPreviewHovered(true);
      }}
      onMouseLeave={() => {
        setIsPreviewHovered(false);
      }}
      styles={{
        position: "relative",
        backgroundColor: "#F0F2F4",
        borderRadius: 3,
        border: "1px solid #DCE0E3",
        marginLeft: isSummary ? "unset" : 16,
        marginRight: isSummary ? "unset" : 5,
        height: "100%",
        padding: isSummary ? "24px 62px" : "10px 10px 27px",
      }}
    >
      {showEdit && handleSelect && isPreviewHovered && (
        <IconButton
          color="inherit"
          style={{
            backgroundColor: "#fff",
            padding: "8px",
            border: "1px solid #DCE0E3",
            position: "absolute",
            top: "5px",
            right: "5px",
          }}
          onClick={() => {
            handleSelect(0);
          }}
        >
          <CreateOutlinedIcon color="action" />
        </IconButton>
      )}
      {ui?.ui?.layout_type === INAPP_LAYOUT_TYPES.VIDEOS ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "auto",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              margin: "auto",
              backgroundColor: "#000",
              width,
              height,
              position: "relative",
            }}
          >
            {isGIFfullScreen ? (
              <>
                <Box
                  style={{
                    position: "absolute",
                    top: "10px",
                    insetInline: "0px",
                  }}
                >
                  <Box
                    style={{
                      paddingInline: "10px",
                      height: "8%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <IconButton>
                      <CloseIcon
                        style={{
                          color: "#fff",
                          fontSize: "20px",
                        }}
                      />
                    </IconButton>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          setIsGIFfullScreen(false);
                          setIsGIFclicked(false);
                        }}
                      >
                        <MinimizeIcon width="20px" height="20px" color="#fff" />
                      </IconButton>
                      <IconButton>
                        <VolumeOffIcon
                          style={{
                            color: "#fff",
                            fontSize: "20px",
                          }}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
                <img src={videoInAppGIF} alt="" width={width} height={height} />
              </>
            ) : (
              <Box
                onClick={() => {
                  setIsGIFclicked((prevClicked) => !prevClicked);
                }}
              >
                <img
                  src={videoInAppGIF}
                  style={{
                    width: "40%",
                    height: "40%",
                    position: "absolute",
                    bottom: "30px",
                    right: "10px",
                    filter: isGIFclicked ? "brightness(0.6)" : "brightness(1)",
                  }}
                  alt=""
                  width={width}
                  height={height}
                />
                {isGIFclicked && (
                  <Box
                    style={{
                      width: "40%",
                      height: "40%",
                      position: "absolute",
                      bottom: "30px",
                      right: "10px",
                    }}
                  >
                    <Box
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        position: "absolute",
                        top: "4px",
                        paddingInline: "4px",
                      }}
                    >
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <SettingsIcon
                          style={{
                            color: "#fff",
                            fontSize: "15px",
                          }}
                        />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <CloseIcon
                          style={{
                            color: "#fff",
                            fontSize: "15px",
                          }}
                        />
                      </IconButton>
                    </Box>
                    <IconButton
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsGIFfullScreen(true);
                      }}
                    >
                      <ZoomOutMapIcon
                        style={{
                          color: "#fff",
                          fontSize: "17px",
                        }}
                      />
                    </IconButton>
                  </Box>
                )}
              </Box>
            )}
          </div>
          <Typography
            style={{
              margin: "15px auto 0px",
              fontSize: "12px",
              maxWidth: width + 25,
            }}
            variant="body2"
            align="center"
          >
            This is a sample video for demonstration purposes. To check your
            video, please test on your device.
          </Typography>
        </div>
      ) : (
        <>
          <div
            style={{
              margin: isSummary ? "unset" : "auto auto 10px",
              backgroundColor: "#ffffff",
              border: "1.14433px solid #E4E7E9",
              width,
              height,
            }}
          >
            {!(
              isExperienceDashboard() &&
              (experience_type === EXPERIENCE_CONFIG.FEATURE_DISCOVERY ||
                experience_type === EXPERIENCE_CONFIG.CART_DELETION_SURVEY)
            ) &&
              ui &&
              ui.blobName &&
              ui.blobName !== "" &&
              (fetchingImageFromURL || !image) && (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography varitn="body1" style={{ fontWeight: 800 }}>
                    Fetching...
                  </Typography>
                </div>
              )}
            {(ui &&
              ui.blobName &&
              ui.blobName !== "" &&
              !fetchingImageFromURL &&
              image) ||
            (isExperienceDashboard() &&
              (experience_type === EXPERIENCE_CONFIG.FEATURE_DISCOVERY ||
                experience_type === EXPERIENCE_CONFIG.CART_DELETION_SURVEY)) ? (
              <img
                id="apx_design_preview"
                style={{
                  width: width,
                  height: height,
                }}
                src={
                  isExperienceDashboard() &&
                  (experience_type === EXPERIENCE_CONFIG.FEATURE_DISCOVERY ||
                    experience_type === EXPERIENCE_CONFIG.CART_DELETION_SURVEY)
                    ? "https://storage.googleapis.com/apx_frontend-assets/Design_Placeholder.jpg"
                    : image
                }
                alt="apx_screen_preview"
              />
            ) : (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography varitn="body1" style={{ fontWeight: 800 }}>
                  Preview will be shown here
                </Typography>
              </div>
            )}
          </div>
          {!previewMode && (
            <RowDiv center>
              {ui && ui.type === "IN_LINE" && (
                <BootstrapTooltip
                  title="Change the Test Device, Screen, View ID or Path"
                  placement="top"
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{
                      margin: "auto",
                      marginTop: 8,
                      borderRadius: 20,
                    }}
                    disabled={
                      (isExperienceDashboard() &&
                        (experience_type ===
                          EXPERIENCE_CONFIG.FEATURE_DISCOVERY ||
                          experience_type ===
                            EXPERIENCE_CONFIG.CART_DELETION_SURVEY)) ||
                      (null ? true : false)
                    }
                    onClick={() => setShowEditDialog(true)}
                  >
                    <EditIcon color="action" style={{ marginRight: 8 }} />
                    <Typography
                      variant="subtitle1"
                      color="textPrimary"
                      style={{ fontWeight: 800 }}
                    >
                      Edit
                    </Typography>
                  </Button>
                </BootstrapTooltip>
              )}
              <BootstrapTooltip
                title="Fetch the screenshot from device after preview"
                placement="top"
              >
                <Button
                  disabled={
                    (isExperienceDashboard() &&
                      (experience_type ===
                        EXPERIENCE_CONFIG.FEATURE_DISCOVERY ||
                        experience_type ===
                          EXPERIENCE_CONFIG.CART_DELETION_SURVEY)) ||
                    (null ? true : false)
                  }
                  id="preview-ui-refresh"
                  variant="contained"
                  color="secondary"
                  style={{
                    margin: "auto",
                    marginTop: 8,
                    borderRadius: 20,
                  }}
                  onClick={() => {
                    Apxor.logEvent(
                      "RefreshClicked",
                      { "experience_type": experience_type },
                      APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS,
                    );
                    // Close if an event source is already created
                    if (refreshEventSourceRef.current !== null) {
                      refreshEventSourceRef.current.close();
                    }

                    const eventSource = new EventSource(
                      SSE_API +
                        withQueryStrings({
                          appId: auth.appId,
                          deviceId: selectedDevice,
                        }),
                    );
                    refreshEventSourceRef.current = eventSource;
                    eventSource.onmessage = (event) => {
                      try {
                        const data = JSON.parse(event.data);
                        if (
                          data.device_info &&
                          data.device_info.id === selectedDevice
                        ) {
                          // Set the image to the state
                          // and store the latest image in global state.
                          // This avoids re-fetching image from GCS every
                          // time new preview screenshot gets received
                          setImage(
                            "data:image/png;base64," + data.screen.image,
                          );
                          if (dispatch) {
                            dispatch({
                              type: SET_IMG,
                              value:
                                "data:image/png;base64," + data.screen.image,
                            });
                          }
                          getSignedURLToPost(auth, auth.appId, {
                            campaignId: campaignId,
                            appId: auth.appId,
                          }).then((response) => {
                            const {
                              signed_url,
                              provider,
                              blob_name,
                            } = response;
                            setBlobName(blob_name);
                            dispatch({
                              type: SET_VIEWID_SELECTION_DIALOG_DATA,
                              options: {
                                value: {},
                                provider,
                                blobName: blob_name,
                              },
                            });
                            const blob = base64ToBlob(
                              data.screen.image,
                              "image/png",
                            );
                            const file = new File([blob], "image.png");
                            uploadImageUsingSignedURL(signed_url, file);
                            Apxor.logEvent(
                              "RefreshScreenshotFetched",
                              {
                                "experience_type": experience_type,
                              },
                              APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS,
                            );
                          });
                        }
                      } catch (e) {
                      } finally {
                        eventSource.close();
                      }
                    };
                    publishDesignForPreviewAPI(
                      auth,
                      auth.appId,
                      { deviceId: selectedDevice },
                      {
                        type: ui.type || CAMPAIGN_TYPE_ENUM.IN_LINE,
                        cmd: "capt",
                      },
                    );
                  }}
                >
                  <SyncIcon color="action" style={{ marginRight: 8 }} />
                  <Typography
                    variant="subtitle1"
                    color="textPrimary"
                    style={{ fontWeight: 800 }}
                  >
                    Refresh
                  </Typography>
                </Button>
              </BootstrapTooltip>
            </RowDiv>
          )}
          <CustomMaterialUIDialog
            isFullWidth={false}
            dialogContent={
              <ViewIDPathSelection
                bundle_id={app?.basic_info?.bundle_id ?? ""}
                props={{
                  search_type: ui?.ui?.search_type,
                  is_wv: ui?.ui?.is_wv,
                  wv_tag: ui?.ui?.wv_tag,
                  pathh: ui?.ui?.path,
                  view_id: ui?.ui?.view_id,
                  activity: ui?.ui?.activity,
                  url: ui?.ui?.url,
                }}
                handleClose={() => setShowEditDialog(false)}
                handleProceed={(data) => {
                  const options = {};
                  if (data) {
                    options.value = {
                      search_type: data.searchType,
                      is_wv: data.isForWebView,
                      wv_tag: data.webviewTag,
                      id: data.viewId,
                      path: data.path,
                      view_id: data.searchType === "" ? data.viewId : data.path,
                      activity: data.activity,
                    };
                    if (data.isForWebView)
                      options.value["url"] = "https://unpkg.com/apxor-rtm-ui";
                    options.provider = data.provider;
                    options.blobName = data.blob_name;

                    // To avoid re-fetching image from GCS, we update whatever is received
                    // from the ViewIDPathSelection component
                    if (data.screenshot) {
                      data.screenshot().then((dataUrl) => {
                        setImage(dataUrl);
                        if (dispatch) {
                          dispatch({
                            type: SET_IMG,
                            value: dataUrl,
                          });
                          dispatch({
                            type: SET_CURRENT_IMAGE,
                            value: null,
                          });
                        }
                      });
                    }
                  }
                  dispatch({
                    type: SET_VIEWID_SELECTION_DIALOG_DATA,
                    options,
                  });
                  setShowEditDialog(false);
                }}
              />
            }
            maxWidth={
              app?.basic_info?.platform === APP_PLATFORMS.web ? "sm" : "md"
            }
            openFlag={showEditDialog}
            onClose={() => setShowEditDialog(false)}
            onDialogClose={() => setShowEditDialog(false)}
          />
        </>
      )}
    </ColumnDiv>
  );
}
