import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@material-ui/core";
import FlowBuilder from "./components/flowBuilder";
import { useTracked } from "../../../../common/components/campaigncreator/store";
import { Provider, useTracked as localTracked } from "./store";
import ConfigurationSection from "./components/configuration";
import Simulator from "./components/simulator";
import { SET_UIS } from "../../../../common/components/campaigncreator/constants";
import {
  INITIAL_ONBOARDING_STATE,
  SET_ONBOARDING_STATE,
  SET_UIS_CONFIG,
  TOOLTIP_LAYOUT_TYPES,
  SET_PLATFORM,
  SET_CURRENT_STEP,
  ON_UIS_CHANGE,
  SET_EXPERIMENT_STATE,
} from "./constants";
import {
  APP_PLATFORMS,
  WALKTHROUGH_TYPE_ENUM,
} from "../../../../../../constants";
import DesignPreviewSection from "./components/DesignPreviewSection";
import VariantBuilder from "./components/variantbuilder";
import SimulatorActionsForWeb from "./components/simulator/components/SimulatorActionsForWeb";
import { ApxEventHandler } from "../../../../common/ApxEventHandler";
import { getUI } from "./utils";
import { useHistory } from "react-router-dom";

const transformsUIsIfNeeded = (uisArray) => {
  let uis = [];
  uisArray.forEach((ui) => {
    if (!ui.ui || !ui.type) {
      uis.push({
        name: ui.name,
        type: ui.type,
        ui: {
          ...ui,
        },
      });
    } else {
      uis.push(ui);
    }
  });
  return uis;
};

/**
 * We reconstruct the onboarding state from the UIs.
 */
const getOnboardingState = (uis) => {
  if (uis.length < 1) {
    return INITIAL_ONBOARDING_STATE;
  }

  let index = uis.findIndex(
    (ui) => ui.ui.layout_type !== TOOLTIP_LAYOUT_TYPES.HTML_OVERLAY,
  );
  if (index === -1) {
    index = 0;
  }
  const ui = getUI(index, uis).ui;
  const onboardingState = {
    anim_type: ui.anim_type || INITIAL_ONBOARDING_STATE.anim_type,
    carousal: ui.carousal || INITIAL_ONBOARDING_STATE.carousal,
    counter: ui.counter || INITIAL_ONBOARDING_STATE.counter,
    duration: ui.duration || INITIAL_ONBOARDING_STATE.duration,
    has_counter: ui.has_counter || false,
    lastStepText: ui.lastStepText || INITIAL_ONBOARDING_STATE.lastStepText,
    next: {
      ...(ui.next || INITIAL_ONBOARDING_STATE.next),
      text:
        ui.nextPrevEnabled && index - 1 >= 0
          ? uis[index - 1].ui.next.text
          : "Next",
    },
    nextPrevEnabled: ui.nextPrevEnabled || false,
    prev: ui.prev || INITIAL_ONBOARDING_STATE.prev,
    skip: ui.skip || INITIAL_ONBOARDING_STATE.skip,
    skipEnabled: ui.skipEnabled || false,
    rx: ui.rx || INITIAL_ONBOARDING_STATE.rx,
    ry: ui.ry || INITIAL_ONBOARDING_STATE.ry,
  };

  if (!onboardingState.has_counter) {
    delete onboardingState.counter;
  }

  if (!onboardingState.nextPrevEnabled) {
    delete onboardingState.next;
    delete onboardingState.prev;
  }

  if (!onboardingState.skipEnabled) {
    delete onboardingState.skip;
  }
  return onboardingState;
};

const DesignPageWithProvider = ({ app, config }) => {
  const [parentState, parentDispatch] = useTracked();
  const {
    meta: { config_type = "walkthrough", platform, isExperiment },
    testing: { selected = "" },
    uis,
    experiment,
  } = config;

  const [localState, localDispatch] = localTracked();
  const { uis: uis_array, currentStep, orientation } = localState;
  const localStateRef = useRef();
  localStateRef.current = localState.uis;

  const {
    location: { state: routerState = {} },
  } = useHistory();

  /**
   * We don't need to memoize this, because this is something that
   * won't change after this component loads/mounts.
   */
  const isOnboardingMode =
    config_type === WALKTHROUGH_TYPE_ENUM.ONBOARDING_WALKTHROUGH;

  // Update the entire UIs object in Global state which resides in
  // CampaignCreator whenever there is a change in local store
  // Note: This entire effect is commented because of #1092 issue
  // useDeepCompareEffectNoCheck(() => {
  //   parentDispatch({
  //     type: SET_UIS,
  //     uis: uis_array,
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   currentStep,
  //   uis_array[currentStep]?.ui,
  //   uis_array[currentStep]?.provider,
  //   uis_array[currentStep]?.blobName,
  //   uis_array.length,
  //   parentDispatch,
  // ]);

  // If there is already an `uis` array in global store,
  // update it in our local store
  useEffect(() => {
    localDispatch({ type: SET_PLATFORM, value: platform });
    localDispatch({
      type: SET_UIS_CONFIG,
      uis: transformsUIsIfNeeded(uis),
      appId: app.app_id,
    });
    /**
     * Putting this dispatch here to handle a case where the campaign is edited, then no new steps are added
     * Where upon the parent state is being flushed and the local state is not being added to parent state thereby clearing off
     * the uis
     *
     * Refer bug https://rm.apxor.com/issues/729
     */
    parentDispatch({
      type: SET_UIS,
      uis: uis,
    });
    if (isOnboardingMode) {
      localDispatch({
        type: SET_ONBOARDING_STATE,
        value: getOnboardingState(transformsUIsIfNeeded(uis)),
      });
    }

    // Setting experiment flag in local state to reuse in reducer rather than passing it around always.
    localDispatch({
      type: SET_EXPERIMENT_STATE,
      value: isExperiment,
    });
    // If UIs length is at least 1, set the current step to 0
    if (uis.length > 0) {
      if (isExperiment) {
        if (experiment && experiment.currentVariantStep > -1) {
          const indexToSet =
            experiment.variants[
              String.fromCharCode(experiment.currentVariantStep + 65)
            ].indices.length > 0
              ? experiment.variants[
                  String.fromCharCode(experiment.currentVariantStep + 65)
                ].indices[0]
              : -1;
          localDispatch({
            type: SET_CURRENT_STEP,
            value: indexToSet,
          });
        } else
          localDispatch({
            type: SET_CURRENT_STEP,
            value: -1,
          });
      } else {
        localDispatch({
          type: SET_CURRENT_STEP,
          value: 0,
        });
      }
    }

    /**
     * This callback will be triggered whenever any action dispatches to the local reducer
     */
    const parentDispatcher = ApxEventHandler.subscribe(ON_UIS_CHANGE, () => {
      parentDispatch({
        type: SET_UIS,
        uis: localStateRef.current,
      });
    });

    return () => {
      parentDispatcher.unsubscribe();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // If UIs length is at least 1, set the current step to 0
    if (uis.length > 0) {
      localDispatch({
        type: SET_CURRENT_STEP,
        value: routerState?.step_id ?? 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localDispatch, routerState?.step_id]);

  /**
   * Left side simulator
   * Right side config section
   *
   * As we are persisting the UI config in the local store, we don't
   * have to pass any props
   */

  const [showCustomSimulator, setShowCustomSimulator] = useState(true);
  return (
    <div>
      {isExperiment && <VariantBuilder />}
      <FlowBuilder platform={platform} app={app} />
      {localState.uis.length > 0 && localState.currentStep >= 0 && (
        <Grid
          container
          spacing={0}
          style={{
            display: "flex",
            width:
              platform === APP_PLATFORMS.web ? "100%" : "calc(100% + 16px)",
            margin: platform === APP_PLATFORMS.web ? 0 : -16,
            borderRadius: 5,
            marginTop: platform === APP_PLATFORMS.web ? 0 : 6,
          }}
        >
          {((getUI(currentStep, uis_array, isExperiment) &&
            getUI(currentStep, uis_array, isExperiment)?.ui.layout_type !==
              "custom") ||
            !showCustomSimulator) &&
            platform !== APP_PLATFORMS.web && (
              <Grid item xs={3}>
                <DesignPreviewSection
                  campaignId={parentState?.config?._id}
                  currentStep={currentStep}
                  dispatch={localDispatch}
                  image={null}
                  orientation={orientation}
                  selectedDevice={selected}
                  ui={getUI(currentStep, uis_array, isExperiment)}
                  isExperiment={isExperiment}
                  experience_type={
                    parentState?.config?.meta?.experience_type ?? ""
                  }
                />
              </Grid>
            )}
          {((getUI(currentStep, uis_array, isExperiment) &&
            getUI(currentStep, uis_array, isExperiment)?.ui.layout_type !==
              "custom") ||
            showCustomSimulator) && (
            <Grid
              id="campaign-design-canvas"
              item
              xs={
                platform === APP_PLATFORMS.web
                  ? 6
                  : getUI(currentStep, uis_array, isExperiment) &&
                    getUI(currentStep, uis_array, isExperiment)?.ui
                      .layout_type !== "custom"
                  ? 5
                  : showCustomSimulator
                  ? 3
                  : 0
              }
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#f0f2f4",
                border: "1px solid #DCE0E3",
                borderRadius: 3,
              }}
            >
              {platform === APP_PLATFORMS.web && (
                <SimulatorActionsForWeb
                  appId={app.app_id}
                  ui={getUI(currentStep, uis_array, isExperiment)}
                  isExperiment={isExperiment}
                />
              )}
              <Simulator
                platform={platform}
                showCustomSimulator={showCustomSimulator}
                isExperiment={isExperiment}
              />
            </Grid>
          )}
          <Grid
            item
            xs={
              platform === APP_PLATFORMS.web
                ? 6
                : getUI(currentStep, uis_array, isExperiment) &&
                  getUI(currentStep, uis_array, isExperiment)?.ui
                    .layout_type !== "custom"
                ? 4
                : showCustomSimulator
                ? 9
                : 9
            }
            style={{ padding: "0px 0px 0px 4px" }}
          >
            <ConfigurationSection
              showCustomSimulator={showCustomSimulator}
              setShowCustomSimulator={setShowCustomSimulator}
              isExperiment={isExperiment}
              experience_type={parentState?.config?.meta?.experience_type ?? ""}
            />
          </Grid>
        </Grid>
      )}
      {/* {localState.uis.length > 0 && localState.currentStep < 0 && (
        <Grid
          container
          justify={"center"}
          alignItems={"stretch"}
          style={{
            height: 120,
            marginBottom: 48,
          }}
        >
          <Grid
            item
            style={{
              background: "#FFF",
              padding: 36,
              width: "100%",
              textAlign: "center",
            }}
          >
            <EditIcon fontSize={"large"} color={"action"} />
            <Typography
              style={{
                marginTop: 12,
                fontSize: "medium",
              }}
            >
              Select a step to continue Editing
            </Typography>
          </Grid>
        </Grid>
      )} */}
    </div>
  )
};

const WalkthroughsDesignPage = ({ app, config }) => {
  return (
    <Provider>
      <DesignPageWithProvider app={app} config={config} />
    </Provider>
  );
};

export default WalkthroughsDesignPage;
