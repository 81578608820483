import React from "react";
import { Button, TextField } from "@material-ui/core";
import RadioButtonChecked from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

import { PALETTE, selectedColor } from "../../constants";
import { shuffledArray } from "../../../../../../../../utils";

const CHOICE_BUTTON_STYLES = selected => ({
  justifyContent: "flex-start",
  textTransform: "none",
});

export const singleChoice = (
  { id, value, is_other },
  active_color,
  inactive_color,
  bg_active_color,
  bg_inactive_color,
  selected = false
) => (
  <>
    <Button
      variant="outlined"
      fullWidth
      style={{
        ...CHOICE_BUTTON_STYLES(selected),
        border: selected ? `1px solid ${bg_active_color}` : `1px solid ${bg_inactive_color}`,
        borderRadius: 5,
      }}
    >
      {selected ? (
        <RadioButtonChecked
          htmlColor={active_color || "#FFFFFF"}
          style={{
            color: active_color || "#FFFFFF",
            width: 20,
            height: 20,
          }}
        />
      ) : (
        <RadioButtonUnchecked
          htmlColor={"#8B8B8B" || bg_inactive_color || undefined}
          style={{
            color: "#8B8B8B" || bg_inactive_color || undefined,
            width: 20,
            height: 20,
          }}
        />
      )}{" "}
      &nbsp;{" "}
      <strong
        style={{
          color: selected
            ? active_color || selectedColor(selected)
            : inactive_color || selectedColor(selected),
        }}
      >
        {value}
      </strong>
    </Button>
    {is_other && selected && (
      <TextField fullWidth type="text" margin="dense" autoFocus />
    )}
  </>
);

export const multiChoice = (
  { id, value, is_other },
  active_color,
  inactive_color,
  bg_active_color,
  bg_inactive_color,
  selected = false
) => (
  <>
    <Button
      variant="outlined"
      fullWidth
      style={{
        ...CHOICE_BUTTON_STYLES(selected),
        border: selected
          ? `1px solid ${bg_active_color}`
          : `1px solid ${bg_inactive_color}`,
        borderRadius: 5,
      }}
    >
      {selected ? (
        <CheckBoxIcon
          htmlColor={active_color || selectedColor(selected, undefined)}
          style={{ color: active_color || "#FFFFFF" }}
        />
      ) : (
        <CheckBoxOutlineBlankIcon
          htmlColor={inactive_color || selectedColor(selected, undefined)}
        />
      )}{" "}
      &nbsp;{" "}
      <strong
        style={{
          color: selected
            ? active_color || selectedColor(selected)
            : inactive_color || selectedColor(selected),
        }}
      >
        {value}
      </strong>
    </Button>
    {is_other && selected && (
      <TextField fullWidth type="text" margin="dense" autoFocus />
    )}
  </>
);

export function finalChoices(choices = [], randomize_choices = false) {
  if (randomize_choices) {
    const withOtherChoice = choices.filter(o => o.is_other);
    const withoutOtherChoice = choices.filter(o => !o.is_other);
    return [...shuffledArray(withoutOtherChoice), ...withOtherChoice];
  }
  return choices;
}
