import React from "react";
import { Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useTracked } from "../store";
import AddIcon from "@material-ui/icons/Add";
import {
  SET_ICON_PATH,
  ADD_TO_BUTTON_CONFIG,
  SET_BIG_IMAGE_CONTENT_TITLE,
  NOTIFICATION_ENUM,
  IMAGE_SOURCE,
  SET_BIG_IMAGE_SOURCE,
  SET_BIG_IMAGE_URL,
  SET_BIG_IMAGE_PATH,
  SET_ICON_CONFIG_TYPE,
  ICON_CONFIGURATION,
} from "../constants";
import ActionsTextField from "../../../../ReusableComponents/ActionsTextField";
import MultiSelect from "../../../../ReusableComponents/MultiSelect";
import { ButtonConfig } from "./ButtonConfig";
import { IconConfig } from "./IconConfig";
import { APP_PLATFORMS } from "../../../../../constants";

export function ConditionalPayload({ errors }) {
  const [state, dispatch] = useTracked();
  const { push_config } = state;
  const { payload, meta = {} } = push_config;
  const {
    notification_type,
    buttons_config,
    big_image = {},
    icon_config,
  } = payload;
  const { icon_config_type, icon_path } = icon_config;
  const { image_path, image_url, content_title, image_source } = big_image;

  return (
    <Grid container spacing={16} style={{ marginLeft: 12 }}>
      <Grid item xs={12}>
        {notification_type === NOTIFICATION_ENUM.big_image && (
          <>
            <Typography>Big Content Title</Typography>
            <ActionsTextField
              type="text"
              value={content_title || ""}
              onChange={e =>
                dispatch({
                  type: SET_BIG_IMAGE_CONTENT_TITLE,
                  value: e.target.value,
                })
              }
              fullWidth
              margin="normal"
              validationErrors={errors?.payload?.big_image?.content_title}
            />
          </>
        )}
        {notification_type === NOTIFICATION_ENUM.big_image && (
          <>
            <Typography>Select Big Image Source</Typography>
            <MultiSelect
              options={IMAGE_SOURCE.map(o => ({
                label: o.label,
                value: o.key,
              }))}
              style={{ marginBottom: 10 }}
              value={image_source}
              handleChange={big_image_source =>
                dispatch({
                  type: SET_BIG_IMAGE_SOURCE,
                  value: big_image_source,
                })
              }
              single
              fullWidth
            />
          </>
        )}
        {notification_type === NOTIFICATION_ENUM.big_image &&
          image_source === "path" && (
            <ActionsTextField
              type="text"
              placeholder="../drawable/.."
              value={image_path || ""}
              onChange={e =>
                dispatch({ type: SET_BIG_IMAGE_PATH, value: e.target.value })
              }
              fullWidth
              margin="normal"
            />
          )}
        {notification_type === NOTIFICATION_ENUM.big_image &&
          image_source === "URL" && (
            <ActionsTextField
              type="text"
              placeholder="https://cdn.com/image.webm"
              value={image_url || ""}
              onChange={e =>
                dispatch({ type: SET_BIG_IMAGE_URL, value: e.target.value })
              }
              fullWidth
              margin="normal"
            />
          )}
        {meta?.platform !== APP_PLATFORMS.ios && (
          <>
            <Typography>Icon Path</Typography>
            <ActionsTextField
              type="text"
              value={icon_path || ""}
              onChange={e =>
                dispatch({ type: SET_ICON_PATH, value: e.target.value })
              }
              fullWidth
              margin="normal"
            />
            <Typography>Select Icon Configuration</Typography>
            <MultiSelect
              options={ICON_CONFIGURATION.map(o => ({
                label: o.label,
                value: o.key,
              }))}
              value={icon_config_type || "None"}
              handleChange={icon_type =>
                dispatch({ type: SET_ICON_CONFIG_TYPE, value: icon_type })
              }
              single
              fullWidth
            />
            <IconConfig />
            {buttons_config.map((button, index) => (
              <ButtonConfig
                key={index}
                index={index}
                name={button.name}
                activity_name={button.activity_name}
              />
            ))}
            {buttons_config.length < 3 && (
              <Button
                color="primary"
                size="small"
                onClick={e =>
                  dispatch({
                    type: ADD_TO_BUTTON_CONFIG,
                    value: { name: "", activity_name: "" },
                  })
                }
              >
                <AddIcon /> Add Button
              </Button>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
}
