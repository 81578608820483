import React, { useMemo } from "react";
import { Grid, makeStyles, TextField } from "@material-ui/core";
import Autocomplete from "../../../../../ReusableComponents/Autocomplete";
import { DATA_TYPES } from "./constants";

const DEFAULT_BOOLEAN_VALUES = [
  { label: "True", value: "true" },
  { label: "False", value: "false" },
];

const useTextFieldStyles = makeStyles((theme) => ({
  input: (props) => ({
    maxHeight: 30,
  }),
}));

export default function PropertyValueSelector({
  attributeValues,
  eventType,
  handleOnChange,
  isDidnotProperty,
  name: attribute,
  type: t,
  label: tl,
  operator,
  value,
  multiple = false,
  onInputChange = null,
}) {
  const classes = useTextFieldStyles({ isDidnotProperty });
  const considerDataType = useMemo(() => eventType === "CLIENT", [eventType]);
  return (
    <>
      {(considerDataType || operator === "R") &&
        attribute.length > 0 &&
        t &&
        tl === DATA_TYPES.STRING && (
          <Grid item style={{ marginBottom: -4 }}>
            <TextField
              required
              placeholder="String"
              value={value || ""}
              style={{
                maxWidth: isDidnotProperty ? 50 : "unset",
                marginTop: -4,
              }}
              onChange={(e) => {
                const { value } = e.target;
                handleOnChange(value);
              }}
              InputProps={{
                className: classes.input,
              }}
            />
          </Grid>
        )}
      {!considerDataType &&
        attribute.length > 0 &&
        t &&
        tl !== "Boolean" &&
        operator !== "R" && (
          <Autocomplete
            onInputChange={onInputChange}
            multiple={multiple}
            defaultValue={
              multiple ? value : { label: value + "", value: value + "" }
            }
            handleOnChange={(data) =>
              multiple ? handleOnChange(data) : handleOnChange(data.value)
            }
            options={attributeValues.map((item) => ({
              label: item,
              value: item,
            }))}
            styles={{ marginBottom: -4 }}
          />
        )}
      {attribute.length > 0 && t && tl === "Boolean" && (
        <Autocomplete
          defaultValue={{
            label: value === "true" ? "True" : "False",
            value: value,
          }}
          handleOnChange={(data) => handleOnChange(data.value)}
          options={DEFAULT_BOOLEAN_VALUES}
          styles={{ marginBottom: -4 }}
        />
      )}
      {considerDataType &&
        attribute.length > 0 &&
        t &&
        (tl === DATA_TYPES.DECIMAL || tl === DATA_TYPES.INTEGER) && (
          <Grid item style={{ marginBottom: -4 }}>
            <TextField
              value={value || 0}
              style={{
                maxWidth: isDidnotProperty ? 50 : "unset",
                marginTop: -4,
              }}
              onChange={(e) => {
                const value = Number(e.target.value);
                handleOnChange(value);
              }}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                className: classes.input,
              }}
            />
          </Grid>
        )}
    </>
  );
}
