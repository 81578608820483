import React, { useState } from "react";
import { CircularProgress } from "@material-ui/core";
import EventIcon from "@material-ui/icons/Event";
import {
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  CartesianGrid,
  Brush,
  ReferenceLine,
  Bar,
  BarChart,
  Cell,
} from "recharts";
import { randomColorWithIndex } from "../../../../../utils";
import { useAuth } from "../../../../../contexts/AuthContext";
import Box from "../../../../ReusableComponents/Box";
import { useDeepCompareEffect } from "../../../../../utils/use-deep-compare";
import { getTopEventsAPI } from "../../../common/actions";
import { useDispatch } from "../store";
import { SELECT_EVENT } from "../reducer";
import { isDefined } from "../../../../../api";
import { PRIMARY } from "../../../../ApplicationFrame/styling/CustomCSS";

export default function TopEvents({ appId, queryParams, filters }) {
  const auth = useAuth();
  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);

  const dispatch = useDispatch();

  useDeepCompareEffect(() => {
    setFetching(true);
    getTopEventsAPI(
      auth,
      appId,
      { ...queryParams, of: "event", attribute: "apx_event_name" },
      filters
    ).then((response) => {
      setData(response);
      setFetching(false);
    });
  }, [appId, auth, queryParams, filters]);

  return (
    <Box
      title="Top Events"
      icon={<EventIcon />}
      withPadding
      collapsible
      defaultExpanded={false}
    >
      {!fetching && (
        <ResponsiveContainer height={300}>
          <BarChart
            data={data}
            margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
          >
            <XAxis dataKey={"key"} type={"category"} />

            <YAxis
              width={90}
              type={"number"}
              dataKey={"value"}
              label={{
                value: "Event count",
                position: "insideLeft",
                angle: -90,
                dy: 40,
              }}
            />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <ReferenceLine y={0} stroke="#000" />
            <defs>
              <linearGradient id="top-events" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor={randomColorWithIndex(0)}
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor={randomColorWithIndex(0)}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            {data && data.length > 10 && (
              <Brush
                dataKey="key"
                height={30}
                stroke={PRIMARY.disabled}
                endIndex={10}
              />
            )}
            <Bar
              dataKey="value"
              fillOpacity={1}
              onClick={(e) => {
                if (e && isDefined(e.key) && e.key.trim() !== "") {
                  dispatch({
                    type: SELECT_EVENT,
                    event: e.key,
                  });
                }
              }}
            >
              {data.map((entry, index) => (
                <Cell
                  cursor="pointer"
                  fill="url(#top-events)"
                  key={`cell-${entry.key + index}`}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
      {fetching && (
        <section
          className="content"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </section>
      )}
    </Box>
  );
}
