import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import LabelList from "recharts/lib/component/LabelList";
import { RESULTS_LAYOUT } from "../..";
import { useAuth } from "../../../../../../../../contexts/AuthContext";
import {
  formatNumber,
  randomColorWithIndex,
  roundOffNumber,
} from "../../../../../../../../utils";
import ColumnDiv from "../../../../../../../ReusableComponents/ColumnDiv";
import CustomMaterialUIDialog from "../../../../../../../ReusableComponents/CustomMaterialUIDialog";
import Loading from "../../../../../../../ReusableComponents/Loading";
import MultiSelect from "../../../../../../../ReusableComponents/MultiSelect";
import MultiUtilityChart from "../../../../../../../ReusableComponents/MultiUtilityChart";
import RowDiv from "../../../../../../../ReusableComponents/RowDiv";
import Snackbar from "../../../../../../../ReusableComponents/Snackbar";
import { useCommonConfigStyles } from "../../../../../../common/components/campaigncreator/utils";
import DesignPreviewSection from "../../../design/components/DesignPreviewSection";
import {
  getFunnelAnalysisAPI,
  getFunnelAnalysisTimeSeriesAPI,
  getVariantFunnelAnalysisAPI,
  getVariantFunnelTimeSeriesAPI,
} from "../../actions";
import { SET_VARIANT } from "../../constants";
import { useTracked } from "../../store";
import MultiUtilityChartAnalysisTooltip from "./MultiUtilityChartAnalysisTooltip";

const renderCustomizedLabel = (props) => {
  const { x, y, value } = props;
  const [percentage, count] = value ? value.split(" ") : [0, 0];
  return (
    <g>
      <text
        x={x + 40}
        y={y - 5}
        fill="#002845"
        textAnchor="middle"
        style={{
          fontSize: 13,
          fontWeight: 600,
        }}
      >
        <tspan>{roundOffNumber(Number(percentage), 1)}% | </tspan>
        <tspan>{formatNumber(Number(count))}</tspan>
      </text>
    </g>
  );
};

const getStepCards = (funnelData) => {
  return (
    <ColumnDiv>
      <Grid container direction={"column"} spacing={2}>
        {funnelData.map((eachStep, index) => {
          return (
            <Grid
              item
              xs={12}
              style={{ height: funnelData.length > 1 ? 130 : 248 }}
            >
              <Card
                style={{
                  background: index === 0 ? "#002845" : "#5CA678",
                  textAlign: "center",
                  height: "100%",
                }}
              >
                <CardContent>
                  <Typography
                    variant="h4"
                    style={{
                      color: "#FFFFFF",
                      fontSize: 40,
                      fontWeight: 700,
                    }}
                  >
                    {formatNumber(eachStep.count)}
                  </Typography>
                  <Typography
                    variant="body1"
                    noWrap
                    style={{
                      color: "#FFFFFF",
                      fontSize: 16,
                      fontWeight: 400,
                    }}
                  >
                    {"Step " + (index + 1) + " Views"}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </ColumnDiv>
  );
};

export default function CampaignStepsAnalysis({
  campaignConfig,
  showTestResults,
  winnerVariant,
  isExperiment,
  resultsLayout,
  setResultsLayout,
}) {
  const auth = useAuth();
  const classes = useCommonConfigStyles();

  const [loadFunnel, setLoadFunnel] = useState(false);
  const [funnelAnalysisMetrics, setFunnelAnalysisMetrics] = useState({});
  const [funnelAnalysisError, setFunnelAnalysisError] = useState(false);

  const [loadFunnelTimeSeries, setLoadFunnelTimeSeries] = useState(false);
  const [funnelAnalysisTimeSeries, setFunnelAnalysisTimeSeries] = useState([]);
  const [funnelTimeSeriesError, setFunnelTimeSeriesError] = useState(false);

  const [showViewStepsDialog, setShowViewStepsDialog] = useState(false);

  const [state, dispatch] = useTracked();
  const { variant = winnerVariant || "A", funnelTime = 300, refresh } = state;

  const { experiment: { variants = {} } = { variants: {} } } = campaignConfig;

  const { funnels: funnelData = [] } = funnelAnalysisMetrics;

  useEffect(() => {
    setLoadFunnel(true);
    setFunnelAnalysisError(false);

    const queryParams = {
      messageId: campaignConfig._id,
      time: funnelTime,
    };
    if (campaignConfig && !isExperiment) {
      getFunnelAnalysisAPI(auth, queryParams)
        .then((response) => {
          setFunnelAnalysisMetrics(response);
          setLoadFunnel(false);
          if (response.funnels) {
            switch (response.funnels.length) {
              case 1:
                setResultsLayout(RESULTS_LAYOUT.SINGLE);
                break;
              case 2:
                setResultsLayout(RESULTS_LAYOUT.DUAL);
                break;
              default:
                setResultsLayout(RESULTS_LAYOUT.MULTI);
                break;
            }
          }
        })
        .catch(() => {
          setFunnelAnalysisError(true);
          setLoadFunnel(false);
        });
      setLoadFunnelTimeSeries(true);
      setFunnelTimeSeriesError(false);
      getFunnelAnalysisTimeSeriesAPI(auth, queryParams)
        .then((response) => {
          setFunnelAnalysisTimeSeries(
            response.map((item) => {
              const { value, conversion, ...data } = item;
              Object.keys(data).forEach((key) => {
                if (
                  typeof data[key] === "object" &&
                  data[key].percent !== undefined &&
                  data[key].percent !== null &&
                  data[key].count !== undefined &&
                  data[key].count !== null
                ) {
                  data[key + "_percent"] = data[key].percent;
                  data[key] = data[key].count;
                }
              });
              return data;
            }),
          );
          setLoadFunnelTimeSeries(false);
        })
        .catch(() => {
          setFunnelTimeSeriesError(true);
          setLoadFunnelTimeSeries(false);
        });
    } else if (campaignConfig) {
      const additionalQueryParams = {
        ...queryParams,
        variantCode: variant || winnerVariant,
      };
      getVariantFunnelAnalysisAPI(auth, additionalQueryParams)
        .then((response) => {
          setFunnelAnalysisMetrics({
            ...response,
            funnels: response.funnels.map((funnel) => ({
              ...funnel,
              label: `${funnel.percent} ${funnel.count}`,
            })),
          });
          setLoadFunnel(false);
        })
        .catch(() => {
          setFunnelAnalysisError(true);
          setLoadFunnel(false);
        });
      setLoadFunnelTimeSeries(true);
      setFunnelTimeSeriesError(false);
      getVariantFunnelTimeSeriesAPI(auth, additionalQueryParams)
        .then((response) => {
          setFunnelAnalysisTimeSeries(
            response.map((item) => {
              const { value, conversion, ...data } = item;
              Object.keys(data).forEach((key) => {
                if (
                  typeof data[key] === "object" &&
                  data[key].percent !== undefined &&
                  data[key].percent !== null &&
                  data[key].count !== undefined &&
                  data[key].count !== null
                ) {
                  data[key + "_percent"] = data[key].percent;
                  data[key] = data[key].count;
                }
              });
              return data;
            }),
          );
          setLoadFunnelTimeSeries(false);
        })
        .catch(() => {
          setFunnelTimeSeriesError(true);
          setLoadFunnelTimeSeries(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variant, refresh]);

  const variantOptions = useMemo(() => {
    return Object.keys(variants)
      .filter((name) => name !== "CG" && name !== "controlGroup")
      .map((name) => ({
        label: (
          <div
            style={{
              background: "#00BDE1",
              padding: "4px 12px",
              color: "#FFFFFF",
              borderRadius: 2,
              fontSize: 16,
              minWidth: 41,
              marginTop: 4,
              marginBottom: 4,
            }}
          >
            {name.toUpperCase()}
          </div>
        ),
        value: name,
      }));
  }, [variants]);

  const VariantDropdown = (
    <MultiSelect
      single
      clearable={false}
      isSearchable={false}
      options={variantOptions}
      value={variant}
      handleChange={(value) => {
        dispatch({
          type: SET_VARIANT,
          value,
        });
      }}
      style={{
        maxWidth: 250,
        marginLeft: 8,
        display: "inline-flex",
        justifyContent: "flex-end",
      }}
    />
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Card
          style={{ minHeight: 340, height: 400, padding: 16, marginTop: 5 }}
        >
          <RowDiv
            center
            styles={{
              justifyContent: "space-between",
            }}
          >
            <RowDiv center styles={{ gap: 5 }}>
              <Typography
                className={classes.typographyHeading}
                style={{
                  fontSize: 20,
                  fontWeight: 600,
                  letterSpacing: "0.01em",
                }}
              >
                Users Per Each Step
              </Typography>
              <Button
                variant="text"
                color="primary"
                style={{
                  fontSize: 12,
                  fontWeight: 600,
                  letterSpacing: "0.01em",
                }}
                onClick={() => setShowViewStepsDialog(true)}
              >
                View steps
              </Button>
            </RowDiv>
            {isExperiment && VariantDropdown}
          </RowDiv>
          <CardContent>
            {loadFunnel && (
              <section className="content">
                <Loading size={24} />
              </section>
            )}
            {!loadFunnel && (
              <ResponsiveContainer height={300}>
                {loadFunnel || funnelData.length < 1 ? (
                  <div>
                    <Loading />
                  </div>
                ) : resultsLayout === RESULTS_LAYOUT.MULTI ? (
                  <BarChart
                    data={funnelData || []}
                    margin={{
                      top: 40,
                      right: 10,
                      left: 30,
                      bottom: 5,
                    }}
                    color="#519674"
                  >
                    <pattern
                      id="pattern-stripe"
                      width="8"
                      height="8"
                      patternUnits="userSpaceOnUse"
                      patternTransform="rotate(45)"
                    >
                      <rect
                        width="4"
                        height="8"
                        transform="translate(0,0)"
                        fill="white"
                      />
                    </pattern>
                    <mask id="mask-stripe">
                      <rect
                        x="0"
                        y="0"
                        width="100%"
                        height="100%"
                        opacity="0.5"
                        fill="url(#pattern-stripe)"
                      />
                    </mask>
                    <Tooltip />
                    <XAxis dataKey="name" />
                    <YAxis
                      width={90}
                      label={{
                        // value: "Users",
                        position: "insideLeft",
                        angle: -90,
                        dy: 40,
                      }}
                    />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Bar type="monotone" dataKey="count" stackId="a">
                      {funnelData.map((_, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={randomColorWithIndex(index)}
                        />
                      ))}
                      <LabelList
                        content={renderCustomizedLabel}
                        dataKey="label"
                        position="top"
                        formatter={roundOffNumber}
                      />
                    </Bar>
                    <Bar
                      type="monotone"
                      dataKey="dropped"
                      stackId="a"
                      isAnimationActive={false}
                      shape={<></>}
                    />
                  </BarChart>
                ) : (
                  getStepCards(funnelData)
                )}
              </ResponsiveContainer>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} style={{ overflow: "inherit" }}>
        <Card
          style={{ minHeight: 340, height: 400, padding: 16, marginTop: 5 }}
        >
          <RowDiv
            center
            styles={{
              justifyContent: "space-between",
            }}
          >
            <RowDiv center styles={{ gap: 5 }}>
              <Typography
                className={classes.typographyHeading}
                style={{
                  fontSize: 20,
                  fontWeight: 600,
                  letterSpacing: "0.01em",
                }}
              >
                Users Per Day
              </Typography>
              <Button
                variant="text"
                color="primary"
                style={{
                  fontSize: 12,
                  fontWeight: 600,
                  letterSpacing: "0.01em",
                }}
                onClick={() => setShowViewStepsDialog(true)}
              >
                View steps
              </Button>
            </RowDiv>
            {isExperiment && VariantDropdown}
          </RowDiv>
          <CardContent>
            <ResponsiveContainer width={"100%"} height={300}>
              {loadFunnelTimeSeries ? (
                <div>
                  <Loading />
                </div>
              ) : (
                <MultiUtilityChart
                  style={{ marginTop: 6 }}
                  boxStyles={{ height: "100%" }}
                  data={funnelAnalysisTimeSeries.map((item) => {
                    return {
                      key: item["date"],
                      ...item,
                    };
                  })}
                  isAnimationActive={false}
                  dataKey="key"
                  lineDataKeys={funnelAnalysisTimeSeries
                    .reduce((acc, item) => {
                      const keys = Object.keys(item);
                      keys.forEach((key) => {
                        if (key.endsWith("_percent")) {
                          return;
                        }
                        if (acc.indexOf(key) === -1) {
                          acc.push(key);
                        }
                      });
                      return acc;
                    }, [])
                    .filter((name) => name !== "date")}
                  customTooltip={<MultiUtilityChartAnalysisTooltip />}
                  withoutAggregations
                />
              )}
            </ResponsiveContainer>
          </CardContent>
        </Card>
      </Grid>

      {(funnelAnalysisError || funnelTimeSeriesError) && (
        <Snackbar autoHideDuration={5000}>
          Error! Data couldn't be Fetched! Please try again later.
        </Snackbar>
      )}
      <CustomMaterialUIDialog
        title="Campaign Steps"
        dialogContent={
          <RowDiv center styles={{ gap: 10, overflow: "scroll" }}>
            {isExperiment &&
              Object.keys(variants).length > 0 &&
              variants[variant].indices.map((uiIndex, index) => (
                <DesignPreviewSection
                  key={index}
                  campaignId={campaignConfig._id}
                  currentStep={index}
                  previewMode={true}
                  ui={campaignConfig.uis[uiIndex]}
                  isSummary={true}
                />
              ))}
            {!isExperiment && (
              <RowDiv center styles={{ gap: 10, overflow: "scroll" }}>
                {campaignConfig.uis.map((_, index) => (
                  <DesignPreviewSection
                    key={index}
                    campaignId={campaignConfig._id}
                    currentStep={index}
                    previewMode={true}
                    ui={campaignConfig.uis[index]}
                    isSummary={true}
                  />
                ))}
              </RowDiv>
            )}
          </RowDiv>
        }
        dialogContentStyles={{
          overflowX: "hidden",
          padding: "8px 8px",
        }}
        maxWidth="md"
        openFlag={showViewStepsDialog}
        handleClose={() => setShowViewStepsDialog(false)}
        onClose={() => setShowViewStepsDialog(false)}
        onDialogClose={() => setShowViewStepsDialog(false)}
      />
    </Grid>
  );
}
