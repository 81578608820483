import React from "react";
import { hexToRGBA } from "../../../../../../../../../utils";
import { StyledDiv } from "../../../../../../walkthroughs/components/design/components/simulator/utils";
import { HoverDiv, StyledHolder } from "../utils";

export default function SurveyContainer({
  children,
  moduleType,
  onModuleSelection,
}) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 48,
        borderRadius: 8,
        gap: 14,
        background: "#FFFFFF",
        flexDirection: "row",
      }}
    >
      {children}
    </div>
  );
}
