import React, { useMemo, useEffect, useState } from "react";
import {
  ACTION_CLASSES,
  ON_DELETE_STEP,
  ON_EDIT_STEP,
  SET_MODULE_TYPE,
} from "../../constants";
import withStyles from "@material-ui/core/es/styles/withStyles";
import { Tooltip, Switch } from "@material-ui/core";
import { useDispatch, useTrackedState } from "../../store";
import {
  subscribeToEvent,
  unSubscribeAllConfigurationEvents,
} from "./EventSubscriber";
import Snackbar from "../../../../../../../ReusableComponents/Snackbar";
import CustomMaterialUIDialog from "../../../../../../../ReusableComponents/CustomMaterialUIDialog";
import Dialog from "../../../../../../../ReusableComponents/Dialog";
import generateShortID from "../../../../../../../../utils";
import NewTooltipConfig from "./components/NewTooltipConfig";
import { Typography } from "@material-ui/core";
import ModuleDivider from "../../../../../../../ReusableComponents/ModuleDivider";
import NewCoachmarkConfig from "./components/NewCoachmarkConfig";
import NewNudgeConfig from "./components/NewNudgeConfig";
import NewInAppConfiguration from "./components/NewInAppConfiguration";
import PreviewButtonHolder from "../simulator/components/PreviewButtonHolder";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ImportCode from "../../assets/import_code.svg";
import SelectTemplate from "../../assets/upload_template.svg";
import ActionTypeSelector from "../flowBuilder/components/ActionTypeSelector";
import ConfirmationDialog from "./components/ConfirmImport";
import RowDiv from "../../../../../../../ReusableComponents/RowDiv";
import { getUI } from "../../utils";
import { isExperienceDashboard } from "../../../../../../../../config";
import BootstrapTooltip from "../../../../../../../ReusableComponents/BootstrapTooltip";
import LockIcon from "@material-ui/icons/Lock";
import { EXPERIENCE_CONFIG } from "../../../../../../common/components/campaignsholder/constants";

const LightTooltip = withStyles((theme) => ({
  arrow: {
    color: "#002845",
  },
  tooltip: {
    backgroundColor: "#002845",
    color: "#FFFFFF",
    boxShadow: theme.shadows[1],
    fontSize: 14,
    height: 40,
    padding: 10,
  },
}))(Tooltip);

const TextStyle = (formats) => ({
  display: "inline",
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: 14,
  letterSpacing: "0.01em",
  color: formats === "github" ? "black" : "white",
  padding: 10,
  cursor: "pointer",
});

export default function ConfigurationSection({
  showCustomSimulator,
  setShowCustomSimulator,
  isExperiment,
  experience_type = "",
}) {
  const state = useTrackedState();
  const dispatch = useDispatch();

  const { currentStep, uis } = state;

  const ui = useMemo(
    () =>
      getUI(currentStep, uis, isExperiment) &&
      getUI(currentStep, uis, isExperiment).ui
        ? getUI(currentStep, uis, isExperiment).ui
        : {},
    [currentStep, uis, isExperiment],
  );
  const isValidUI = useMemo(() => ui && Object.keys(ui).length >= 2, [ui]);
  const { action_class = "tooltip", layout_type } = ui;

  useEffect(() => {
    const collapseCallback = () => {
      dispatch({
        type: SET_MODULE_TYPE,
        value: "",
      });
    };
    const onEditStepSubscriber = subscribeToEvent(
      ON_EDIT_STEP,
      collapseCallback,
    );
    const onDeleteStepSubscriber = subscribeToEvent(
      ON_DELETE_STEP,
      collapseCallback,
    );
    return () => {
      unSubscribeAllConfigurationEvents([
        onEditStepSubscriber,
        onDeleteStepSubscriber,
      ]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const heading = layout_type === "custom" ? "CUSTOM HTML" : "EDIT DESIGN";
  const showText = showCustomSimulator ? "Show Preview" : "Show Simulator";
  const [showActionTypeSelector, setShowActionTypeSelector] = useState(false);
  const [showImportDialog, setShowImportDialog] = useState(false);

  const [formats, setFormats] = useState("github");
  const [templateSelected, setTemplateSelected] = useState(false);
  const [codeEmpty, setCodeEmpty] = useState(false);
  const [displayTooltip, setDisplayTooltip] = useState(false);

  const TextStyles = useMemo(() => TextStyle(formats), [formats]);

  function handleFormat(event, newFormats) {
    setFormats(newFormats);
  }

  return (
    <div
      onMouseEnter={() => setDisplayTooltip(true)}
      onMouseLeave={() => setDisplayTooltip(false)}
      style={{
        border: "1px solid #E4E7E9",
        borderRadius: 3,
        padding: 16,
        paddingRight: 8,
        height: "100%",
        background: "#FFF",
        position: "relative",
      }}
    >
      {isExperienceDashboard() &&
        (experience_type === EXPERIENCE_CONFIG.FEATURE_DISCOVERY ||
          experience_type === EXPERIENCE_CONFIG.CART_DELETION_SURVEY) && (
          <BootstrapTooltip
            open={isExperienceDashboard() && displayTooltip}
            title={
              <>
                <b>Design Configuration</b> feature is disabled for this feature
                discovery use case campaign
              </>
            }
            placement="bottom-end"
          >
            <LockIcon
              color="primary"
              fontSize="small"
              style={{
                position: "absolute",
                top: 19,
                right: 16,
                zIndex: "10",
              }}
            />
          </BootstrapTooltip>
        )}
      <div
        style={
          isExperienceDashboard() &&
          (experience_type === EXPERIENCE_CONFIG.FEATURE_DISCOVERY ||
            experience_type === EXPERIENCE_CONFIG.CART_DELETION_SURVEY)
            ? { opacity: 0.5, pointerEvents: "none" }
            : {}
        }
      >
        {layout_type === "custom" && codeEmpty && (
          <div
            style={{
              display: "inline",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 99999,
            }}
          >
            <img
              src={SelectTemplate}
              alt="select template"
              style={{
                cursor: "pointer",
                position: "relative",
                top: 3.5,
              }}
              onClick={() => setShowActionTypeSelector(true)}
            />
            <Typography
              style={TextStyles}
              onClick={() => setShowActionTypeSelector(true)}
            >
              Get code from Template
            </Typography>
            {/* <img
            src={ImportCode}
            alt="import code"
            style={{
              cursor: "pointer",
              position: "relative",
              top: 3.5,
            }}
            onClick={() => setShowImportDialog(true)}
          />
          <Typography
            style={TextStyles}
            onClick={() => setShowImportDialog(true)}
          >
            Import Code
          </Typography> */}
          </div>
        )}
        <Typography
          variant="subtitle2"
          style={{
            fontWeight: 800,
            paddingTop: 4,
            paddingBottom: 8,
            letterSpacing: "0.11em",
          }}
        >
          {heading}
        </Typography>
        {layout_type === "custom" && (
          <RowDiv center styles={{ justifyContent: "space-between" }}>
            <ToggleButtonGroup
              exclusive
              value={formats}
              onChange={handleFormat}
              aria-label="text formatting"
              style={{ height: "30px" }}
            >
              <ToggleButton value="github" aria-label="light">
                Light Theme
              </ToggleButton>
              <ToggleButton value="monokai" aria-label="dark">
                Dark Theme
              </ToggleButton>
            </ToggleButtonGroup>
            <RowDiv center styles={{ minHeight: 60 }}>
              {!showCustomSimulator && (
                <PreviewButtonHolder style={{ marginRight: 10 }} />
              )}

              <Typography
                style={{
                  ...TextStyles,
                  padding: 0,
                  color: "black",
                }}
              >
                {showText}
              </Typography>
              <Switch
                color="primary"
                label="Show Simulator"
                checked={showCustomSimulator}
                onChange={() => setShowCustomSimulator(!showCustomSimulator)}
              />
              {false && !codeEmpty && (
                <LightTooltip
                  title="Get code from template"
                  arrow
                  placement="top"
                >
                  <img
                    src={SelectTemplate}
                    alt="Select Template"
                    style={{
                      position: "relative",
                      left: showCustomSimulator ? "50%" : "97.5%",
                      bottom: showCustomSimulator ? -15 : 45,
                      cursor: "pointer",
                    }}
                    onClick={() => setShowActionTypeSelector(true)}
                  />
                </LightTooltip>
              )}
              {false && !codeEmpty && (
                <LightTooltip title="Import code" arrow placement="top">
                  <img
                    src={ImportCode}
                    alt="Import Code"
                    style={{
                      position: "relative",
                      left: showCustomSimulator ? "106%" : "104%",
                      bottom: showCustomSimulator ? -15 : 45,
                      cursor: "pointer",
                    }}
                    onClick={() => setShowImportDialog(true)}
                  />
                </LightTooltip>
              )}
            </RowDiv>
          </RowDiv>
        )}
        {showImportDialog && (
          <Dialog
            status={showImportDialog}
            handleClose={() => setShowImportDialog(false)}
            confirmLabel=""
            dialogProps={{
              disableBackdropClick: true,
              disableEscapeKeyDown: true,
            }}
            children={
              <ConfirmationDialog
                handleCancel={() => {
                  setShowImportDialog(false);
                }}
                handleDone={() => setShowImportDialog(false)}
                dispatch={dispatch}
                codeEmpty={codeEmpty}
              />
            }
            noActions={true}
            paperProps={{
              style: { borderRadius: 2 },
            }}
          ></Dialog>
        )}
        <CustomMaterialUIDialog
          dialogContent={
            <ActionTypeSelector
              onlyInapps={true}
              handleDone={() => setShowActionTypeSelector(false)}
              currentStep={currentStep}
              setTemplateSelected={setTemplateSelected}
              experience_type={experience_type}
            />
          }
          maxWidth="md"
          openFlag={showActionTypeSelector}
          onClose={() => setShowActionTypeSelector(false)}
          onDialogClose={() => setShowActionTypeSelector(false)}
        />
        <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
        {isValidUI && action_class === ACTION_CLASSES.TOOLTIP && (
          <NewTooltipConfig currentStep={currentStep} />
        )}
        {isValidUI && action_class === ACTION_CLASSES.NUDGE && (
          <NewNudgeConfig currentStep={currentStep} />
        )}
        {isValidUI && action_class === ACTION_CLASSES.COACHMARK && (
          <NewCoachmarkConfig currentStep={currentStep} />
        )}
        {isValidUI && action_class === ACTION_CLASSES.INAPP && (
          <NewInAppConfiguration
            currentStep={currentStep}
            theme={formats}
            setCodeEmpty={setCodeEmpty}
            templateSelected={templateSelected}
          />
        )}
        {!isValidUI && (
          <Snackbar key={generateShortID()}>
            Incompatible config detected. Try to create new campaign
          </Snackbar>
        )}
      </div>
    </div>
  );
}

