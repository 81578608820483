import React from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import { useTracked } from "../../../../../store";
import {
  ANSWER_MODES,
  DEFAULT_TEXT_CONFIG,
  SET_IS_REQUIRED,
  SET_RANDOMIZE_CHOICES,
  SET_SUBMIT_TEXT,
} from "../../../../../constants";
import TextBasedQuestion from "./TextBasedQuestion";
import ToggleSwitch from "../../../../../../../../../../ReusableComponents/ToggleSwitch";
import { TOGGLE_SWITCH_STYLES } from "../../../../../../../../../common/components/campaigncreator/constants";
import { useCommonConfigStyles } from "../../../../../../../../walkthroughs/components/design/components/configuration/utils";
import ModuleDivider from "../../../../../../../../../../ReusableComponents/ModuleDivider";
import RatingBasedQuestion from "./RatingBasedQuestion";
import SuccessMessage from "./SuccessMessage";
import RequestForSurvey from "./RequestForSurvey";

/**
 * Holds a single question.
 *
 * List of possible actions:
 * 1. Delete this question
 * 2. Move this question up or down
 * 3. Choose the Answer Mode from list of available Answer Modes
 *
 * Based on the selected Answer Mode, the rendering of the question
 * contents or choices vary.
 *
 * Single Choice:
 *    Takes the list of choices and display each choice item as a vertical
 *    list. We can delete a particular choice item using Delete icon
 *
 *    If the Survey is response based survey, each choice contains a list
 *    to choose the next question to be displayed when this gets selected.
 *
 * Multi Choice:
 *    Just like Single Choice. No additional things and response based
 *    survey doesn't applicable for this Answer Mode
 *
 * Rating:
 *    No config params for this as of now
 *
 * Pla in Text:
 *    Contains config params like text color, background color, etc
 */
function Question({
  answer_mode = "single_choice",
  choices = [],
  id,
  is_required,
  submit_text,
  randomize_choices,
  text_config = DEFAULT_TEXT_CONFIG,
  title,
}) {
  /**
   * When you use `react-tracked` lib, you don't need to pass props
   *
   * Always read the latest state from the store and post the changes
   * back to the store using `dispatch`
   *
   * There's a lot of confusion whether we need to maintain the component
   * specific state or not. AFAI see, I don't face any issues or any need
   */
  const [state, dispatch] = useTracked();
  const classes = useCommonConfigStyles();

  const {
    ui: { is_response_based_survey },
  } = state;

  const showExtraOptions =
    answer_mode !== ANSWER_MODES.rating &&
    answer_mode !== ANSWER_MODES.text &&
    answer_mode !== ANSWER_MODES.success_message &&
    answer_mode !== ANSWER_MODES.request_survey;

  return (
    <>
      {answer_mode !== ANSWER_MODES.success_message &&
        answer_mode !== ANSWER_MODES.request_survey && (
          <>
            <Grid item xs={12} className={classes.gridItem}>
              <Typography
                className={classes.typographySubtitle2}
                variant="subtitle2"
              >
                SUBMIT TEXT
              </Typography>
              <TextField
                value={submit_text}
                onChange={e => {
                  const value = e.target.value;
                  dispatch({
                    type: SET_SUBMIT_TEXT,
                    id: id,
                    value: value.trim() ? value : "Next >",
                  });
                }}
              />
            </Grid>
            <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />

            <Grid item xs={12} className={classes.gridItem}>
              <Typography
                className={classes.typographySubtitle2}
                variant="subtitle2"
              >
                MANDATORY QUESTION
              </Typography>
              <ToggleSwitch
                className={classes.formControlLabel}
                checked={is_required}
                handleChange={(checked, e) => {
                  dispatch({
                    type: SET_IS_REQUIRED,
                    id: id,
                    value: checked,
                  });
                }}
                style={TOGGLE_SWITCH_STYLES(is_required)}
              />
            </Grid>
          </>
        )}
      {/**
       * Only be visible for Single and Multi Choice questions.
       * For other types, this doesn't make any sense
       */}
      {showExtraOptions && (
        <>
          <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
          <Grid item xs={12} className={classes.gridItem}>
            <Typography
              className={classes.typographySubtitle2}
              variant="subtitle2"
            >
              RANDOMIZE CHOICES
            </Typography>
            <ToggleSwitch
              className={classes.formControlLabel}
              checked={randomize_choices}
              handleChange={(checked, e) => {
                dispatch({
                  type: SET_RANDOMIZE_CHOICES,
                  id: id,
                  value: checked,
                });
              }}
              style={TOGGLE_SWITCH_STYLES(is_required)}
            />
          </Grid>
        </>
      )}
      {/**
       * A separate component for the Text Based Question as it contains
       * lot of config parameters
       */}
      {answer_mode === ANSWER_MODES.text && (
        <>
          <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
          <TextBasedQuestion id={id} text_config={text_config} />
        </>
      )}
      {/**
       * Seperate component for Rating Section
       */}
      {answer_mode === ANSWER_MODES.rating && (
        <RatingBasedQuestion
          id={id}
          text_config={text_config}
        ></RatingBasedQuestion>
      )}
      {answer_mode === ANSWER_MODES.success_message && <SuccessMessage />}
      {answer_mode === ANSWER_MODES.request_survey && <RequestForSurvey />}
    </>
  );
}

export default Question;
