import React, { useState, useEffect } from "react";
import FilterIcon from "@material-ui/icons/FilterList";
import Box from "../../../../ReusableComponents/Box";
import MultiSelect from "../../../../ReusableComponents/MultiSelect";
import MultiUtilityChart from "../../../../ReusableComponents/MultiUtilityChart";
import { getFunnelTimeseriesAPI } from "../actions";
import { getGroupNameFromList } from "../../../../../utils";

function mergeTimeSeries(all, funnel, funnelName) {
  if (!Array.isArray(all) || !Array.isArray(funnel)) return [];
  if (all.length === 0 || all.length !== funnel.length)
    return funnel.map((o) => ({ name: o.key, [funnelName]: o.value }));
  return all.map((item, index) => {
    item[funnelName] = funnel[index].value;
    return item;
  });
}

export default function FunnelComparision({
  auth,
  appId,
  filters,
  funnelId,
  funnels = [],
  queryParams,
}) {
  const [timeSeries, setTimeSeries] = useState([]);
  const [selectedFunnels, setSelectedFunnels] = useState(
    funnelId ? [funnelId] : []
  );
  const [chartLabels, setChartLabels] = useState(
    selectedFunnels.map((o) => getGroupNameFromList(funnels, o)) || []
  );

  // This effect will run whenever the `selectedFunnels` gets modified
  useEffect(() => {
    setChartLabels(
      selectedFunnels.map((o) => getGroupNameFromList(funnels, o)) || []
    );
  }, [funnels, selectedFunnels]);

  // Fetches the timeseries of the selected funnels
  // whenever the mentioned dependencies gets changed
  useEffect(() => {
    // FIXME: This is bit annoying way of making things happen
    //        While leads to same request is being called multiple times
    selectedFunnels.forEach((funnelId) => {
      getFunnelTimeSeries(funnelId);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, appId, queryParams, filters, selectedFunnels]);

  const getFunnelTimeSeries = (funnelId) => {
    const funnelName =
      funnels.find((funnel) => funnel.id === funnelId).name || "";
    getFunnelTimeseriesAPI(auth, appId, queryParams, funnelId).then(
      (response) => {
        setTimeSeries([
          ...mergeTimeSeries(
            timeSeries,
            response.map((o) => {
              return { key: o.date, value: o.value };
            }),
            funnelName
          ),
        ]);
      }
    );
  };

  const handleSelectSavedFunnel = (selected) => {
    if (selected.length > 0) {
      selected.forEach((value) => {
        if (!selectedFunnels.includes(value)) {
          getFunnelTimeSeries(value);
        }
      });
    }
    setSelectedFunnels(selected);
  };

  return (
    <Box
      controls={
        <MultiSelect
          clearable
          handleChange={(selected) => {
            handleSelectSavedFunnel(selected);
          }}
          options={funnels.map((o) => {
            return { label: o.name, value: o._id };
          })}
          placeholder="Select Saved Funnel"
          style={{ maxWidth: 640 }}
          value={selectedFunnels}
        />
      }
      collapsible
      defaultExpanded={selectedFunnels.length > 0}
      icon={<FilterIcon />}
      title={"Conversion Over Time"}
      withPadding
    >
      {Array.isArray(chartLabels) && Array.isArray(timeSeries) && (
        <MultiUtilityChart
          data={timeSeries}
          dataKey={"name"}
          lineDataKeys={chartLabels}
          withHeader
          withoutAggregations
          yLabel={"Conversion %"}
        />
      )}
    </Box>
  );
}
