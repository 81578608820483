import React, { useState } from "react";
import { DEFAULT_TEXT_CONFIG } from "../../../constants";
import { Typography, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ActionsTextField from "../../../../../../../../ReusableComponents/ActionsTextField";

export default function TextSimulator({
  handleAnswer,
  text_config: {
    background_color,
    example,
    example_text_color,
    max_characters,
    placeholder,
    placeholder_text_color,
    text_color,
  } = DEFAULT_TEXT_CONFIG,
}) {
  const StyledTextField = withStyles(theme => ({
    root: {
      "& .MuiInputBase-root": {
        color: text_color ? text_color : "000",
        backgroundColor: background_color ? background_color : "#FFF",
      },
    },
  }))(ActionsTextField);

  const [text, setText] = useState("");

  return (
    <div>
      <StyledTextField
        multiline
        rowsMax={4}
        value={text}
        fullWidth
        onChange={e => {
          if (e.target.value.length <= max_characters) {
            setText(e.target.value);
            handleAnswer(e.target.value.length > 0);
          }
        }}
        margin="none"
        placeholder={placeholder}
        variant="outlined"
        textFieldStyle={{ minHeight: 100 }}
      />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Typography style={{ fontSize: 12 }}>
          {text.length}/{max_characters}
        </Typography>
      </div>
    </div>
  );
}
