import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

class Error401 extends Component {
  static propTypes = {
    children: PropTypes.object,
    location: PropTypes.object,
  };

  render() {
    return (
      <section className="content">
        <Grid container style={{ marginTop: "150px" }} spacing={0}>
          <Grid
            item
            xs={5}
            style={{ display: "flex", flexDirection: "row-reverse" }}
          >
            <Typography
              variant="h1"
              color="primary"
              style={{ paddingRight: 10 }}
            >
              {" "}
              401
            </Typography>
          </Grid>
          <Grid item xs>
            <div>
              <Typography variant="h5">
                <i className="fa fa-warning text-yellow"></i> Oops!
                Unauthorised.
              </Typography>
              <Typography variant="h6">
                You are not allowed to visit this page.
              </Typography>
              <Typography variant="subtitle1">
                Don't worry. You can{" "}
                <Link to="/">return to your dashboard</Link>
              </Typography>
            </div>
          </Grid>
        </Grid>
      </section>
    );
  }
}

export default Error401;
