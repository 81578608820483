import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Button, Grid, Typography } from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import { Link } from "react-router-dom";
import { CohortDetails } from "./CohortDetails";
import { CohortCreator } from "./CohortCreator";
import { SegmentConfig } from "../../../../../common/components/SegmentConfig";

function Segment({ _id, app, currentCohort, condition, event, session, user }) {
  return (
    <Card style={{ width: "100%" }}>
      <CardContent>
        <Grid container>
          <Grid item xs={6} style={{ paddingRight: 24 }}>
            <Typography variant="h6" style={{ fontWeight: 600 }}>
              Segment Config
            </Typography>
            <div
              style={{
                backgroundColor: "#eee",
                borderRadius: 6,
                padding: 12,
                marginTop: 4,
              }}
            >
              <SegmentConfig
                condition={condition}
                event={event}
                session={session}
                user={user}
              />
            </div>
          </Grid>
          {currentCohort && (
            <Grid xs={6} item>
              <CohortDetails app={app} currentCohort={currentCohort} />
            </Grid>
          )}
          {!currentCohort && (
            <Grid xs={6} item align={"center"}>
              <CohortCreator
                _id={_id}
                app={app}
                currentCohort={currentCohort}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
      <CardActions>
        <Link
          to={{
            pathname: `/apps/${app.app_id}/segments/${_id}`,
            state: {
              segmentId: _id,
            },
          }}
        >
          <Button size="small" variant="contained" color="primary">
            Analyse
          </Button>
        </Link>
        <Link
          to={{
            pathname: `/apps/${app.app_id}/segments/new`,
            state: {
              name: "",
              user: user,
              session: session,
              event: event,
              condition: condition,
            },
          }}
          style={{ marginLeft: 12 }}
        >
          <Button size="small" variant={"outlined"} color="primary">
            Create new segment from this
          </Button>
        </Link>
      </CardActions>
    </Card>
  );
}

export default React.memo(Segment);
