/**
 * Created by Rakesh Peela
 * Date: 07-Mar-2020
 * Time: 12:28 AM
 *
 * Ported and Refactored by Yasasvee Atreya
 * June 2020
 */

import React, { useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  Typography,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { default as MaterialDialog } from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import Loading from "./Loading";
import { uploadCohortAPI } from "../Dashboard/components/cohorts/actions";
import { useAuth } from "../../contexts/AuthContext";
import { StyledDropzone } from "../Dashboard/components/cohorts/styling/StyledDropzone";
import RowDiv from "./RowDiv";

const useStyles = makeStyles((theme) => ({
  closeButton: (props) => ({
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "#A1ADB6",
    padding: 0,
  }),
}));

const UploadCohortDialog = ({ appId, onClose }) => {
  const auth = useAuth();
  const classes = useStyles();

  const [state, setState] = useState({
    cohortName: null,
    selectedFile: null,
    uploadInProgress: false,
  });

  const [openDialog, setOpenDialog] = useState(true);
  const [pending, setPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  return (
    <MaterialDialog
      open={openDialog}
      disableEscapeKeyDown={pending}
      disableBackdropClick={pending}
      maxWidth={"md"}
      scroll="body"
      fullWidth
      onClose={() => {
        if (onClose) {
          onClose();
        }
      }}
    >
      <DialogTitle disableTypography>
        <Typography
          variant="h6"
          style={{
            fontWeight: 800,
            fontSize: 14,
            lineHeight: "18px",
            letterSpacing: "0.01em",
          }}
        >
          Upload new cohort
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ overflow: "hidden" }}>
        <>
          <Grid container spacing={8}>
            <Grid item xs={6} sm={6}>
              <TextField
                placeholder="Cohort Name"
                fullWidth
                value={state.cohortName}
                onChange={e =>
                  setState({ ...state, cohortName: e.target.value })
                }
                style={{ marginRight: 8 }}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              {!state.selectedFile && (
                <div>
                  <StyledDropzone
                    text="Click to select CSV file"
                    single
                    acceptType={[
                      ".csv",
                      "text/csv",
                      "application/vnd.ms-excel",
                      "application/csv",
                      "text/x-csv",
                      "application/x-csv",
                      "text/comma-separated-values",
                      "text/x-comma-separated-values",
                    ]}
                    onChange={file => {
                      if (file && file.name) {
                        setState({
                          ...state,
                          selectedFile: file || null,
                          cohortName: file.name,
                        });
                      }
                    }}
                  />
                  <Typography variant={"subtitle2"}>
                    Max file size: 50MB
                  </Typography>
                </div>
              )}
              {state.selectedFile && (
                <RowDiv
                  center
                  styles={{
                    background: "#d4d4d4",
                    border: "1px solid",
                    borderRadius: 3,
                    justifyContent: "space-between",
                    padding: 6,
                  }}
                >
                  <Typography variant="body1" style={{ fontWeight: 600 }}>
                    {state.selectedFile.name}
                  </Typography>
                  <IconButton
                    color="default"
                    onClick={() =>
                      setState({ ...state, selectedFile: null, cohortName: "" })
                    }
                    style={{ position: "unset", padding: 0 }}
                  >
                    <CloseIcon />
                  </IconButton>
                </RowDiv>
              )}
            </Grid>
          </Grid>
          <div style={{ textAlign: "center", marginTop: 16 }}>
            {errorMessage === null && (
              <Button
                variant="contained"
                color="primary"
                disabled={
                  pending ||
                  state.cohortName === null ||
                  state.cohortName === null ||
                  state.selectedFile === null ||
                  state.uploadInProgress
                }
                onClick={() => {
                  setState({ ...state, uploadInProgress: true });
                  setPending(true);
                  uploadCohortAPI(auth, appId, {
                    cohortName: state.cohortName,
                    cohortDescription: state.selectedFile.name,
                    cohortFile: state.selectedFile,
                  })
                    .then(response => {
                      if (response && response.status) {
                        setOpenDialog(false);
                        if (onClose) {
                          onClose();
                        }
                      }
                      setPending(false);
                      setErrorMessage(null);
                    })
                    .catch(err => {
                      setPending(false);
                      setErrorMessage(
                        "Cohort Upload failed. Please try again after sometime."
                      );
                    });
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {pending && <Loading size={10} />}
                  <Typography color="secondary">Upload</Typography>
                </div>
              </Button>
            )}

            {errorMessage !== null && (
              <Typography style={{ height: 36 }} color={"error"}>
                {errorMessage}
              </Typography>
            )}
          </div>
        </>
      </DialogContent>
    </MaterialDialog>
  );
};

export default UploadCohortDialog;
