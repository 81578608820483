import React, { useEffect, useState } from "react";
import FieldSection from "./FieldSection";
import { Typography, Grid, ButtonBase } from "@material-ui/core";
import FieldDescription from "./FieldDescription";
import {
  APP_PLATFORMS,
  SET_META_PLATFORM,
  SET_META_DESC,
  SET_META_NAME,
  SET_META_CONFIG_TYPE,
} from "../constants";
import ClassicCard from "../../../../../ReusableComponents/ClassicCard";
import { PLATFORM_IMAGES } from "../../campaignsholder/utils";
import ActionsTextField from "../../../../../ReusableComponents/ActionsTextField";
import { WALKTHROUGH_TYPE_ENUM } from "../../../../../../constants";
import { useTracked } from "../store";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

export default function GeneralStep({
  app: {
    basic_info: { platform: appPlatform },
  },
  actionType,
}) {
  const [state, dispatch] = useTracked();

  const {
    config: {
      meta: { name, description, platform, config_type },
    },
  } = state;

  const [value, setValue] = useState(
    config_type || WALKTHROUGH_TYPE_ENUM.WALKTHROUGH
  );

  const handleChange = (event) => {
    setValue(event.target.value);
    dispatch({
      type: SET_META_CONFIG_TYPE,
      config_type: event.target.value,
    });
  };

  useEffect(() => {
    if (platform !== appPlatform && appPlatform !== APP_PLATFORMS.omni) {
      dispatch({
        type: SET_META_PLATFORM,
        platform: appPlatform,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({
      type: SET_META_CONFIG_TYPE,
      config_type: config_type,
    });
    setValue(config_type || WALKTHROUGH_TYPE_ENUM.WALKTHROUGH);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config_type]);

  return (
    <FieldSection>
      <Grid container>
        <Grid item xs={6} md={6}>
          <FieldDescription
            title={"Platform"}
            description={"Select the target Platform for this Campaign"}
          />
          <Grid container spacing={2} style={{ marginTop: 8 }}>
            {Object.keys(APP_PLATFORMS)
              .filter((platform) => {
                return [APP_PLATFORMS.ios, APP_PLATFORMS.android].includes(
                  platform
                );
              })
              .map((plat) => {
                const fontColor = plat === platform ? "#4177F6" : "#6f6f6f";
                return (
                  <Grid item key={"select-card-" + plat}>
                    <ButtonBase
                      style={{
                        borderRadius: 10,
                      }}
                      disableRipple
                      disableTouchRipple
                      disabled={appPlatform !== APP_PLATFORMS.omni}
                      onClick={() => {
                        dispatch({
                          type: SET_META_PLATFORM,
                          platform: plat,
                        });
                      }}
                    >
                      <ClassicCard
                        style={{ minWidth: 64 }}
                        selected={plat === platform}
                        disabled={appPlatform !== APP_PLATFORMS.omni}
                      >
                        {/*<i style={{fontSize: 32, color: fontColor}}*/}
                        {/*   className={PLATFORM_IMAGES[plat]}/>*/}
                        {PLATFORM_IMAGES(plat, fontColor)}
                      </ClassicCard>
                    </ButtonBase>
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
        <Grid item xs={6} md={6}>
          <section style={{ maxWidth: 480 }}>
            <FieldDescription title={"Campaign Name"} required />
            <ActionsTextField
              placeholder={"Enter Campaign Name"}
              value={name}
              required
              onChange={(e) => {
                dispatch({
                  type: SET_META_NAME,
                  name: e.target.value,
                });
              }}
            />
            <FieldDescription title={"Campaign Description"} />
            <ActionsTextField
              placeholder={
                "Add more details like Purpose of this Campaign, etc."
              }
              value={description}
              onChange={(e) => {
                dispatch({
                  type: SET_META_DESC,
                  desc: e.target.value,
                });
              }}
            />
            {actionType !== "surveys" && (
              <FormControl component="fieldset">
                <FormLabel component="legend">Walkthrough Type</FormLabel>
                <RadioGroup
                  aria-label="type"
                  name="walkthroughType"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    style={{
                      padding: "10px",
                      border: `1px solid ${
                        value === WALKTHROUGH_TYPE_ENUM.WALKTHROUGH
                          ? "#FF7F33"
                          : "rgba(0, 40, 69, 0.2)"
                      }`,
                      borderRadius: "3px",
                      margin: "10px",
                    }}
                    value={WALKTHROUGH_TYPE_ENUM.WALKTHROUGH}
                    control={<Radio color="primary" />}
                    label={
                      <React.Fragment>
                        <Grid container>
                          <Grid item md={4}>
                            <img
                              alt={""}
                              src={"/assets/img/walkthrough_type.png"}
                            />
                          </Grid>
                          <Grid item md={8}>
                            <Typography
                              style={{
                                lineHeight: "28px",
                                color: "secondary",
                                fontSize: "20px",
                                fontWeight: "700",
                                fontStyle: "normal",
                                fontFamily: "Manrope",
                                marginTop: "10px",
                                marginBottom: "5px",
                              }}
                            >
                              Walkthroughs
                            </Typography>
                            <Typography
                              variant={"subtitle2"}
                              style={{
                                lineHeight: "16px",
                                color: "#4D697D",
                                fontSize: "16px",
                                fontWeight: "500",
                                fontFamily: "Manrope",
                                fontStyle: "normal",
                              }}
                            >
                              These are created for inner screens of your
                              application
                            </Typography>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    }
                  />
                  <FormControlLabel
                    style={{
                      padding: "10px",
                      border: `1px solid ${
                        value === WALKTHROUGH_TYPE_ENUM.ONBOARDING_WALKTHROUGH
                          ? "#FF7F33"
                          : "rgba(0, 40, 69, 0.2)"
                      }`,
                      borderRadius: "3px",
                      margin: "10px",
                    }}
                    value={WALKTHROUGH_TYPE_ENUM.ONBOARDING_WALKTHROUGH}
                    control={<Radio color="primary" />}
                    label={
                      <React.Fragment>
                        <Grid container>
                          <Grid item md={4}>
                            <img
                              alt={""}
                              src={
                                "/assets/img/onboarding_walkthrough_type.png"
                              }
                            />
                          </Grid>
                          <Grid item md={8}>
                            <Typography
                              style={{
                                lineHeight: "28px",
                                color: "secondary",
                                fontSize: "20px",
                                fontWeight: "700",
                                fontStyle: "normal",
                                fontFamily: "Manrope",
                                marginTop: "10px",
                                marginBottom: "5px",
                              }}
                            >
                              Onboarding Walkthroughs
                            </Typography>
                            <Typography
                              variant={"subtitle2"}
                              style={{
                                lineHeight: "16px",
                                color: "#4D697D",
                                fontSize: "16px",
                                fontWeight: "500",
                                fontFamily: "Manrope",
                                fontStyle: "normal",
                              }}
                            >
                              These are created for the onboarding screens of
                              your application
                            </Typography>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    }
                  />
                </RadioGroup>
              </FormControl>
            )}
          </section>
        </Grid>
      </Grid>
    </FieldSection>
  );
}
