import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Loading from "../../../../../ReusableComponents/Loading";
import MultiSelect from "../../../../../ReusableComponents/MultiSelect";
import { APP_PLATFORMS } from "../../../../../../constants";
import {
  getPlatformLogoColor,
  PLATFORM_IMAGES,
} from "../../../../../App/styling/CustomCSS";
import ModuleConfigSection from "../../../../../ReusableComponents/ModuleConfigSection";
import {
  ATTRIBUTE_TYPES,
  CLIENT_EVENT,
  EVENT_SOURCES,
  MODAL_TYPES,
} from "../constants";
import { SourceChip } from "../index";

function Edit_EventModal({
  handleAdd,
  handleClose,
  handleEdit,
  appVersions,
  edit_client_events_pending,
  add = false,
  event: eventProp,
}) {
  const [changed] = useState(false);

  const [event, setEvent] = useState(
    add
      ? {
          event_type: CLIENT_EVENT,
          event_name: undefined,
          attr_name: undefined,
          attr_type: ATTRIBUTE_TYPES.STRING,
          app_version: undefined,
          platform: APP_PLATFORMS.android,
          source: EVENT_SOURCES.manual,
        }
      : eventProp
  );

  const disabled = edit_client_events_pending;

  const [hasRequiredDataToAdd, setRequiredDataToggle] = useState(false);

  useEffect(() => {
    if (
      event.event_name !== undefined &&
      event.attr_name !== undefined &&
      event.attr_type !== undefined &&
      event.app_version !== undefined
    ) {
      if (
        event.event_name.trim() !== "" &&
        event.attr_name.trim() !== "" &&
        event.attr_type.trim() !== ""
      ) {
        setRequiredDataToggle(true);
      } else {
        setRequiredDataToggle(false);
      }
    } else {
      setRequiredDataToggle(false);
    }
  }, [event.event_name, event.attr_name, event.attr_type, event.app_version]);

  return (
    <>
      <DialogContent style={{ padding: 24 }}>
        <Grid container spacing={16}>
          {/*App Version*/}
          <Grid item xs={12} md={6} style={{ marginBottom: 10 }}>
            <ModuleConfigSection title={"App Version"}>
              <MultiSelect
                style={{ width: 200 }}
                options={appVersions.map((version) => ({
                  label: version,
                  value: version,
                }))}
                placeholder={"App Version"}
                single
                clearable
                disabled={disabled}
                value={event.app_version}
                handleChange={(app_version) => {
                  setEvent({
                    ...event,
                    app_version,
                  });
                }}
              />
            </ModuleConfigSection>
          </Grid>

          {/*Source*/}
          {!add && (
            <Grid item xs={12} md={3}>
              <ModuleConfigSection title={"Source"}>
                <SourceChip
                  color={
                    event.source === EVENT_SOURCES.sdk ? "slategray" : "#4198ca"
                  }
                >
                  {event.source}
                </SourceChip>
              </ModuleConfigSection>
            </Grid>
          )}
          <Grid item xs={12} md={3}>
            <ModuleConfigSection title={"Platform"}>
              {!add &&
                PLATFORM_IMAGES(
                  event.platform,
                  getPlatformLogoColor(event.platform),
                  32
                )}
              {add && (
                <MultiSelect
                  defaultValue={event.platform}
                  value={event.platform}
                  single
                  isSearchable={false}
                  disabled={disabled}
                  options={[APP_PLATFORMS.android, APP_PLATFORMS.ios].map(
                    (platform) => ({
                      value: platform,
                      label: platform,
                    })
                  )}
                  itemName={"add_platform"}
                  handleChange={(value) => {
                    setEvent({
                      ...event,
                      platform: value,
                    });
                  }}
                />
              )}
            </ModuleConfigSection>
          </Grid>

          {/*Event Name*/}
          <Grid item xs={12} md={12} style={{ marginBottom: 10 }}>
            <ModuleConfigSection title={"Event Name"}>
              <TextField
                placeholder={`Event Name`}
                fullWidth
                value={event.event_name}
                disabled={disabled}
                onChange={(e) => {
                  setEvent({
                    ...event,
                    event_name: e.currentTarget.value,
                  });
                }}
              />
            </ModuleConfigSection>
          </Grid>

          {/*Attribute Type*/}
          <Grid item xs={12} md={4}>
            <ModuleConfigSection title={"Attribute Type"}>
              <MultiSelect
                style={{ position: "fixed", minWidth: 150 }}
                defaultValue={event.attr_type}
                value={event.attr_type}
                disabled={disabled}
                options={Object.values(ATTRIBUTE_TYPES).map((type) => ({
                  value: type,
                  label: type,
                }))}
                itemName={"ce_source"}
                isSearchable={false}
                single
                handleChange={(value) => {
                  setEvent({
                    ...event,
                    attr_type: value,
                  });
                }}
              />
            </ModuleConfigSection>
          </Grid>

          <Grid item xs={12} md={8}>
            {/*Attribute Name*/}
            <ModuleConfigSection title={"Attribute Name"}>
              <TextField
                placeholder={`Attribute Name`}
                fullWidth
                value={event.attr_name}
                disabled={disabled}
                onChange={(e) => {
                  setEvent({
                    ...event,
                    attr_name: e.currentTarget.value,
                  });
                }}
              />
            </ModuleConfigSection>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant={"text"} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          disabled={
            disabled || add
              ? !hasRequiredDataToAdd
              : !changed && !hasRequiredDataToAdd
          }
          variant={"contained"}
          color={"primary"}
          onClick={() => {
            if (add) {
              handleAdd(event);
            } else {
              handleEdit(event);
            }
          }}
        >
          {edit_client_events_pending && <Loading size={12} />}{" "}
          {add ? "Add" : "Update"}
        </Button>
      </DialogActions>
    </>
  );
}

function Delete_EventModal({
  handleClose,
  handleDelete,
  delete_client_event_pending,
  event,
}) {
  const disabled = delete_client_event_pending;
  return (
    <>
      <DialogContent style={{ padding: 24 }}>
        <Typography>
          Are you sure you want to delete <b> {event.event_name} </b> with
          attribute <b>{event.attr_name}</b> of type <em>{event.attr_type}</em>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button disabled={disabled} variant={"text"} onClick={handleClose}>
          Close
        </Button>
        <Button
          disabled={disabled}
          variant={"contained"}
          color={"primary"}
          onClick={() => handleDelete(event._id.$oid)}
        >
          {delete_client_event_pending && <Loading size={12} />} Delete
        </Button>
      </DialogActions>
    </>
  );
}

function Default_EventModal({ handleClose }) {
  return (
    <>
      <DialogActions>
        <Button
          variant={"text"}
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>
        <Button variant={"contained"} color={"primary"}>
          Add
        </Button>
      </DialogActions>
    </>
  );
}

function getDialogTitle(dialogType) {
  switch (dialogType) {
    case MODAL_TYPES.CONVERT_EVENT_TYPE:
      return "Convert Event Type";
    case MODAL_TYPES.DELETE_EVENT_TYPE:
      return "Delete Event";
    case MODAL_TYPES.CREATE_NEW:
      return "Add New Client Event";
    case MODAL_TYPES.EDIT_EVENT_TYPE:
      return "Edit Event Info";
    default:
      return "";
  }
}

function Modal(props) {
  const { open, dialog_type, dialogProps, handleClose } = props;
  let Component;
  switch (dialog_type) {
    case MODAL_TYPES.EDIT_EVENT_TYPE:
    case MODAL_TYPES.CREATE_NEW:
      Component = Edit_EventModal;
      break;
    case MODAL_TYPES.DELETE_EVENT_TYPE:
      Component = Delete_EventModal;
      break;
    default:
      Component = Default_EventModal;
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick={true}
      fullWidth={true}
      scroll="body"
      maxWidth={"sm"}
      {...dialogProps}
    >
      <DialogTitle
        color={"white"}
        style={{ background: "#002845", color: "white" }}
        disableTypography
      >
        {getDialogTitle(dialog_type)}
      </DialogTitle>
      <Component {...props} add={dialog_type === MODAL_TYPES.CREATE_NEW} />
    </Dialog>
  );
}

Modal.propTypes = {
  dialog_type: PropTypes.string,
  open: PropTypes.bool,
};

export default Modal;
