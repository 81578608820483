/**
 * Created by kodanda_rama on 2/24/17.
 */

import React from "react";
import {
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Line,
  ReferenceLine,
  Bar,
  Brush,
} from "recharts";
import moment from "moment";
import {
  capitalizeEachWord,
  removeCharsWith,
  randomColorWithIndex,
} from "../../utils";
import Box from "./Box";

const defaultXLabelFormatter = (key) => moment(key).format("MMM DD");

class CustomToolTip extends React.Component {
  render() {
    const {
      active,
      units,
      payload,
      label,
      isFloat,
      valueLabel,
      xLabelFormatter,
      isXKeyDate = true,
      showAverage = false,
    } = this.props;
    if (active)
      return (
        <div
          className="recharts-default-tooltip"
          style={{
            margin: "0px",
            padding: "10px",
            backgroundColor: "rgb(255, 255, 255)",
            border: "1px solid rgb(204, 204, 204)",
            whiteSpace: "nowrap",
          }}
        >
          <p className="recharts-tooltip-label" style={{ margin: "0px" }}>
            {isXKeyDate
              ? xLabelFormatter
                ? xLabelFormatter(label)
                : defaultXLabelFormatter(label)
              : label}
          </p>
          <ul
            className="recharts-tooltip-item-list"
            style={{ padding: "0px", margin: "0px" }}
          >
            {payload.map((item, index) => {
              return (
                <li
                  className="recharts-tooltip-item"
                  key={`li${index}`}
                  style={{
                    display: "block",
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    color: item.color,
                  }}
                >
                  <span className="recharts-tooltip-item-name">
                    {item.name ||
                      valueLabel ||
                      capitalizeEachWord(removeCharsWith(item.name, "_", " "))}
                  </span>
                  <span className="recharts-tooltip-item-separator"> : </span>
                  <span className="recharts-tooltip-item-value">
                    {isFloat
                      ? item.value.toFixed(2)
                      : item.value +
                        " " +
                        (showAverage
                          ? `(${item.payload["avg_" + item.name]})`
                          : "")}
                  </span>
                  <span className="recharts-tooltip-item-unit">
                    {" "}
                    {units}{" "}
                    {item.payload[`${item.name}-users`] && (
                      <string>
                        ({item.payload[`${item.name}-users`]} Users)
                      </string>
                    )}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      );
    else return <span />;
  }
}

class ReChartComposedChart extends React.Component {
  render() {
    const {
      data,
      height,
      dataKey,
      barDataKeys,
      lineDataKeys,
      areaDataKeys,
      xLabelFormatter,
      dot = true,
      syncId = null,
      showBrush = false,
      connectNulls = false,
      xLabel = undefined,
      yLabel = undefined,
    } = this.props;
    return (
      <ResponsiveContainer height={height || 300}>
        <ComposedChart
          data={data}
          margin={{ top: 5, right: 10, bottom: 25, left: 10 }}
          syncId={syncId}
        >
          <XAxis
            dataKey={dataKey || "date"}
            tickFormatter={xLabelFormatter || defaultXLabelFormatter}
            label={
              xLabel
                ? { value: xLabel, position: "insideBottomRight", dy: -8 }
                : undefined
            }
            height={45}
          />
          <YAxis
            label={
              yLabel
                ? { value: yLabel, position: "insideLeft", angle: -90, dy: -10 }
                : undefined
            }
          />
          <Tooltip content={<CustomToolTip {...this.props} />} />
          <CartesianGrid stroke="#f5f5f5" />
          {areaDataKeys &&
            areaDataKeys.map((areaKey, index) => (
              <Area
                type="monotone"
                key={`area${index}`}
                dataKey={areaKey}
                fill={randomColorWithIndex(index)}
                stroke={randomColorWithIndex(index)}
              />
            ))}
          {barDataKeys &&
            barDataKeys.map((barKey, index) => (
              <Bar
                dataKey={barKey}
                key={`bar${index}`}
                barSize={20}
                fill={randomColorWithIndex(index)}
              />
            ))}
          {lineDataKeys &&
            lineDataKeys.map((lineKey, index) => (
              <Line
                connectNulls={connectNulls}
                type="monotone"
                key={`line${index}`}
                dataKey={lineKey}
                stroke={randomColorWithIndex(index)}
                dot={dot}
                style={{ strokeWidth: 2 }}
              />
            ))}
          {showBrush && <ReferenceLine y={0} stroke="#000" />}
          {showBrush && data && data.length > 10 && (
            <Brush
              dataKey="key"
              height={30}
              stroke="#429ef4"
              tickFormatter={xLabelFormatter}
              endIndex={10}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
}

/**
 * @deprecated
 */
export default class APXComposedChart extends React.Component {
  render() {
    const { withHeader = false, title, data = [] } = this.props;
    if (Array.isArray(data) && data.length > 0) {
      if (withHeader) {
        return (
          <Box title={title}>
            <ReChartComposedChart {...this.props} />
          </Box>
        );
      } else {
        return <ReChartComposedChart {...this.props} />;
      }
    } else {
      return <span />;
    }
  }
}
