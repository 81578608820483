import { Grid, Typography } from "@material-ui/core";
import React from "react";
import ActionsTextField from "../../../../../../../../ReusableComponents/ActionsTextField";
import RowDiv from "../../../../../../../../ReusableComponents/RowDiv";
import { SET_DELAY } from "../../../constants";
import { useDispatch } from "../../../store";
import { useCommonConfigStyles } from "../utils";

export default function DelayConfig({ delay }) {
  const classes = useCommonConfigStyles();

  const dispatch = useDispatch();

  return (
    <>
      <Grid item xs={12} className={classes.gridItem}>
        <Typography className={classes.typographySubtitle2} variant="h6">
          DELAY
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        <RowDiv
          center
          styles={{ justifyContent: "space-between", width: "100%" }}
        >
          <Typography className={classes.typographyCaption} variant="subtitle2">
            Delay showing campaign by
          </Typography>
          <RowDiv center>
            <ActionsTextField
              key="campaign_delay"
              type="number"
              InputProps={{
                min: 0,
                max: 20,
                type: "number",
              }}
              value={delay / 1000}
              onChange={(event) => {
                dispatch({
                  type: SET_DELAY,
                  value: Number(event.target.value) * 1000 || 0,
                });
              }}
              style={{
                marginBottom: 0,
                marginRight: 8,
              }}
            />
            <Typography
              className={classes.typographyCaption}
              variant="subtitle2"
            >
              sec
            </Typography>
          </RowDiv>
        </RowDiv>
      </Grid>
    </>
  );
}
