import { Button, Grid, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftSharpIcon from "@material-ui/icons/ChevronLeftSharp";
import ChevronRightSharpIcon from "@material-ui/icons/ChevronRightSharp";
import Apxor from "apxor";
import React, { useMemo, useState } from "react";
import { APXOR_SDK_CATEGORY_CONSTANTS } from "../../../../../../../../../constants";
import BootstrapTooltip from "../../../../../../../../ReusableComponents/BootstrapTooltip";
import { useTrackedState } from "../../../../../../../common/components/campaigncreator/store";
import {
  ACTION_CLASSES,
  SET_INAPP_HTML,
  SET_NEXT_STEP_DATA,
  TEMPLATE_BEEN_SET,
} from "../../../constants";
import { useDispatch, useTracked } from "../../../store";
import { ACTION_TEMPLATES } from "../../../templates";
import { constructHTML } from "../../configuration/utils";

function CarouselCard({
  title,
  image,
  isCustom,
  dispatch,
  currentIndex,
  defaultActionOptions,
  action,
  handleDone,
  setStep,
  onlyInapps = false,
  platform,
  currentStep,
  experience_type,
}) {
  const [state] = useTracked();
  const parentState = useTrackedState();
  const {
    config: {
      meta: { isExperiment },
      experiment: { currentVariantStep = 0, variants } = {},
    },
  } = parentState;
  const shouldButtonBeDisabled = (function () {
    if (state?.uis.length !== 0) {
      if (isExperiment) {
        const currentVariantKey = Object.keys(variants).filter(
          (key) => key !== "CG",
        )[currentVariantStep];
        const variantSpecificUIS = state?.uis.filter((eachUI) =>
          variants[currentVariantKey].indices.includes(eachUI.index),
        );
        if (variantSpecificUIS.length > 0 && title === "Videos") {
          return true;
        } else {
          return false;
        }
      } else {
        return title === "Videos";
      }
    }
    return false;
  })();

  const onCLickFunction = () => {
    Apxor.logEvent(
      "DesignSelected",
      {
        "template": defaultActionOptions[currentIndex]?.data.action_class,
        "experience_type": experience_type,
      },
      APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS,
    );
    if (!onlyInapps) {
      dispatch({
        type: SET_NEXT_STEP_DATA,
        value: {
          ui: defaultActionOptions[currentIndex]?.data,
          type: defaultActionOptions[currentIndex]?.data.type,
        },
      });
      if (defaultActionOptions[currentIndex]?.data.type === "IN_APP") {
        handleDone(null, true);
      } else {
        setStep(1);
      }
    } else if (onlyInapps) {
      dispatch({
        type: SET_INAPP_HTML,
        currentStep: currentStep,
        value: constructHTML(
          defaultActionOptions[currentIndex]?.data,
          platform,
        ),
      });
      dispatch({
        type: TEMPLATE_BEEN_SET,
        value: true,
      });
      handleDone();
    }
  };

  return (
    <Grid
      container
      style={{
        textAlign: "center",
      }}
    >
      <Grid item md={12}>
        <Typography
          style={{
            height: "22px",
            fontSize: "16px",
            fontWeight: "700",
            fontStyle: "normal",
            fontFamily: "Manrope",
            lineHeight: "22px",
            letterSpacing: "1%",
            marginBottom: "20px",
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item md={12}>
        {isCustom ? (
          <img
            style={{ width: "420px", height: "380px" }}
            src={image}
            alt="Apxor"
          />
        ) : (
          <img
            style={{ width: "190px", height: "380px" }}
            src={image}
            alt="Apxor"
          />
        )}
      </Grid>
      <Grid item md={12}>
        <Button
          variant="contained"
          style={{
            background: "rgba(125, 145, 159, 0.14)",
            height: 24,
            width: 46,
            borderRadius: 100,
            marginTop: 12,
          }}
        >
          <Typography
            style={{
              fontWeight: 700,
              fontSize: "12px",
              color: "#677A87",
              fontFamily: "Manrope",
            }}
          >
            {String(currentIndex + 1) +
              "/" +
              String(defaultActionOptions.length)}
          </Typography>
        </Button>
      </Grid>
      <Grid item md={12}>
        <BootstrapTooltip
          open={shouldButtonBeDisabled}
          placement="top"
          arrow
          title="Walkthrough can't be created with Video In-Apps"
        >
          <Button
            disabled={shouldButtonBeDisabled}
            color="primary"
            variant="contained"
            style={{
              height: 44,
              width: 188,
              borderRadius: 3,
              marginTop: 30,
            }}
            onClick={onCLickFunction}
          >
            <Typography
              color="secondary"
              style={{ fontWeight: 700, fontSize: "16px" }}
            >
              Select
            </Typography>
          </Button>
        </BootstrapTooltip>
      </Grid>
    </Grid>
  );
}

const Carousel = ({
  action,
  isOnboardingMode,
  setShowTemplateDialog,
  handleDone,
  setStep,
  onlyInapps = false,
  platform,
  currentStep,
  setTemplateSelected,
  experience_type,
}) => {
  const dispatch = useDispatch();

  const [currentIndex, setCurrentIndex] = useState(0);
  const defaultActionOptionsParent = useMemo(() => {
    return ACTION_TEMPLATES(isOnboardingMode, platform);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnboardingMode, platform]);

  const defaultActionOptions = onlyInapps
    ? defaultActionOptionsParent[action].slice(0, 4)
    : defaultActionOptionsParent[action];

  return (
    <>
      <Grid
        container
        style={{
          justifyContent: "center",
          alignItems: "Center",
        }}
      >
        <Grid
          item
          md={3}
          style={{ display: "flex", justifyContent: "center", marginTop: 60 }}
        >
          {defaultActionOptions.length > 1 && currentIndex !== 0 && (
            <IconButton onClick={() => setCurrentIndex(currentIndex - 1)}>
              <ChevronLeftSharpIcon color="action" />
            </IconButton>
          )}
        </Grid>
        <Grid item md={6} style={{ width: 190, height: 380 }}>
          <CarouselCard
            isCustom={
              action === ACTION_CLASSES.INAPP &&
              currentIndex === defaultActionOptionsParent[action].length - 1
            }
            title={defaultActionOptions[currentIndex]?.display_text}
            image={defaultActionOptions[currentIndex]?.image}
            dispatch={dispatch}
            currentIndex={currentIndex}
            defaultActionOptions={defaultActionOptions}
            action={action}
            handleDone={handleDone}
            setStep={setStep}
            currentStep={currentStep}
            setTemplateSelected={setTemplateSelected}
            onlyInapps={onlyInapps}
            experience_type={experience_type}
          />
        </Grid>
        <Grid
          item
          md={3}
          style={{ display: "flex", justifyContent: "center", marginTop: 60 }}
        >
          {defaultActionOptions.length > 1 &&
            currentIndex + 1 !== defaultActionOptions.length && (
              <IconButton onClick={() => setCurrentIndex(currentIndex + 1)}>
                <ChevronRightSharpIcon color="action" />
              </IconButton>
            )}
        </Grid>
      </Grid>
    </>
  );
};

export default Carousel;
