import { useReducer } from "react";
import { createContainer } from "react-tracked";
import { initial_state, reducer } from "./reducer";

const useValue = () => useReducer(reducer, initial_state);

export const {
  Provider,
  useTracked,
  useTrackedState,
  useUpdate: useDispatch,
} = createContainer(useValue);
