import IconButton from "@material-ui/core/ButtonBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function DownloadButton({ url, data, method, children, appId }) {
  const [state, setState] = useState({
    downloadInitiateSuccess: false,
    downloadInProgress: false,
    hasEncounteredError: false,
  });

  return (
    <>
      {!state.downloadInProgress && (
        <Tooltip
          id="tooltip-fab-download"
          title="Download as CSV"
          placement="bottom"
        >
          <IconButton
            style={{ margin: "0 10px" }}
            color="inherit"
            onClick={() => {
              setState({
                ...state,
                downloadInProgress: true,
              });

              axios
                .request({
                  url,
                  method,
                  data,
                  withCredentials: true,
                })
                .then((response) => {
                  if (response.data.hasOwnProperty("id")) {
                    setState({
                      ...state,
                      downloadInProgress: false,
                      downloadInitiateSuccess: true,
                    });
                  }
                })
                .catch((error) => {
                  console.error(error);
                  if (error) {
                    setState({
                      ...state,
                      hasEncounteredError: true,
                      downloadInProgress: false,
                    });
                  }
                });
            }}
          >
            {children}
          </IconButton>
        </Tooltip>
      )}
      {state.downloadInProgress && (
        <CircularProgress size={24} color="primary" />
      )}
      {state.downloadInitiateSuccess && (
        <Snackbar
          open={state.downloadInitiateSuccess}
          autoHideDuration={4000}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          message={
            <p>
              Download Initiated Successfully. Please check
              <Link
                to={`/apps/${appId}/settings/downloads`}
                style={{ color: "#ff7f33" }}
              >
                {" "}
                Downloads{" "}
              </Link>{" "}
              Page
            </p>
          }
          onClose={() => {
            setState({
              ...state,
              downloadInitiateSuccess: false,
            });
          }}
        />
      )}

      {state.hasEncounteredError && (
        <Snackbar
          open={state.hasEncounteredError}
          autoHideDuration={4000}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          message={
            <p>
              Unknown error encountered while downloading CSV. Please try again
              after sometime.
            </p>
          }
          onClose={() => {
            setState({
              ...state,
              hasEncounteredError: false,
            });
          }}
        />
      )}
    </>
  );
}
