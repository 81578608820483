import { FileCopyOutlined } from "@material-ui/icons";
import React from "react";
import { apxTheme } from "../../../ApplicationFrame/styling/CustomCSS";

export default function CopyContent({ copyText, styles = {} }) {
  return (
    <FileCopyOutlined
      style={{
        position: "absolute",
        top: ".8em",
        right: ".2em",
        cursor: "pointer",
        color: apxTheme.palette.text.hint,
        ...styles,
      }}
      onClick={() => navigator.clipboard.writeText(copyText)}
    />
  );
}
