/**
 * Created by Araja Jyothi Babu on 24-Oct-16.
 */
import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import SegmentsHolder from "./components/segmentlisting";
import NewSegment from "./components/NewSegment";
import AnalyseSegment from "./components/analysesegment";

export default function Segments({ app }) {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <section className="content">
          <SegmentsHolder app={app} />
        </section>
      </Route>
      <Route exact path={`${path}/new`}>
        <NewSegment app={app} />
      </Route>
      <Route exact path={`${path}/:segmentId`}>
        <section className="content">
          <AnalyseSegment app={app} />
        </section>
      </Route>
    </Switch>
  );
}
