import React, { useCallback, useEffect, useMemo } from "react";
import { useTracked } from "../store";
import {
  SET_IS_VALID_PUSH,
  SET_NAME,
  SET_DESCRIPTION,
  SET_APP_PLATFORM,
} from "../constants";
import { ButtonBase, Grid, Radio, Typography } from "@material-ui/core";
import ActionsTextField from "../../../../ReusableComponents/ActionsTextField";
import ClassicCard from "../../../../ReusableComponents/ClassicCard";
import RowDiv from "../../../../ReusableComponents/RowDiv";
import { withStyles } from "@material-ui/styles";

import android from "../../../common/components/assets/android.png";
import ios from "../../../common/components/assets/ios.png";
import web from "../../../common/components/assets/web.png";

const APP_PLATFORMS = {
  ios: "ios",
  android: "android",
  web: "web",
};

const APP_PLATFORM_NAMES = {
  ios: "IOS",
  android: "Android",
  web: "Web",
};

export const APP_PLATFORM_IMAGES = {
  ios: (
    <img style={{ height: 29, width: 24, marginBottom: -4 }} src={ios} alt="" />
  ),
  android: (
    <img
      style={{ height: 30, width: 28, marginBottom: -4 }}
      src={android}
      alt=""
    />
  ),
  web: (
    <img style={{ height: 36, width: 36, marginBottom: -4 }} src={web} alt="" />
  ),
};

export function MetaSelector({ app, errors }) {
  const [state, dispatch] = useTracked();
  const {
    push_config: { name, description, meta = {} },
  } = state;

  const metaPlatform = meta?.platform;

  const appPlatform = app?.basic_info?.platform;

  const selectedPlatform = useMemo(() => {
    if (appPlatform === APP_PLATFORMS.ios) return APP_PLATFORMS.ios;
    else if (appPlatform === APP_PLATFORMS.web) return APP_PLATFORMS.web;
    return metaPlatform;
  }, [appPlatform, metaPlatform]);

  const isPlatformDisabled = useCallback(
    platformKey => {
      switch (app?.basic_info?.platform) {
        case APP_PLATFORMS.ios:
          return platformKey !== APP_PLATFORMS.ios;
        case APP_PLATFORMS.android:
          return platformKey !== APP_PLATFORMS.android;
        case "omni":
          return (
            platformKey !== APP_PLATFORMS.ios &&
            platformKey !== APP_PLATFORMS.android
          );
        default:
          break;
      }
    },
    [app]
  );

  useEffect(() => {
    dispatch({
      type: SET_IS_VALID_PUSH,
      value: Object.keys(errors).length === 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, dispatch]);

  const ORadio = withStyles({
    root: {
      "&$checked": {
        color: "#FF7F33",
      },
    },
    checked: {},
  })(props => <Radio color="default" {...props} />);

  useEffect(() => {
    dispatch({
      type: SET_APP_PLATFORM,
      value: selectedPlatform,
    });
  }, [dispatch, selectedPlatform]);

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={12} md={4}>
        <Typography>Name*</Typography>
        <ActionsTextField
          type="text"
          value={name}
          onChange={e => dispatch({ type: SET_NAME, value: e.target.value })}
          margin="normal"
          fullWidth
          validationErrors={errors?.name}
        />
        <Typography>Description</Typography>
        <ActionsTextField
          type="text"
          value={description}
          onChange={e => {
            dispatch({ type: SET_DESCRIPTION, value: e.target.value });
          }}
          margin="normal"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography>Platform*</Typography>
        {Object.keys(APP_PLATFORMS).map(platformKey => {
          return (
            <div
              item
              key={"select-card-" + platformKey}
              style={{
                display: "inline-block",
                position: "relative",
                marginTop: "5px",
                marginRight: 15,
                alignItems: "left",
              }}
            >
              <ButtonBase
                style={{
                  borderRadius: 2,
                  width: "170px",
                  height: "68px",
                }}
                disabled={isPlatformDisabled(platformKey)}
                disableRipple
                disableTouchRipple
                onClick={() => {
                  dispatch({
                    type: SET_APP_PLATFORM,
                    value: APP_PLATFORMS[platformKey],
                  });
                }}
              >
                <ClassicCard
                  style={{ minWidth: 64, boxShadow: "none" }}
                  selected={selectedPlatform === platformKey}
                  disabled={isPlatformDisabled(platformKey)}
                >
                  <RowDiv>
                    <ORadio
                      checked={selectedPlatform === platformKey}
                      style={{ marginBottom: -8 }}
                    />
                    {APP_PLATFORM_IMAGES[platformKey]}
                    <Typography
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        fontWeight: 600,
                        fontFamily: "Manrope",
                        fontStyle: "normal",
                        fontSize: "16px",
                        lineHeight: "22px",
                        color: "#002845",
                        marginLeft: 12,
                      }}
                    >
                      {APP_PLATFORM_NAMES[platformKey]}
                    </Typography>
                  </RowDiv>
                </ClassicCard>
              </ButtonBase>
            </div>
          );
        })}
        {errors?.meta?.platform && (
          <Typography
            variant={"body2"}
            style={{ padding: "2px 4px", color: "red" }}
          >
            {errors?.meta?.platform}{" "}
          </Typography>
        )}
      </Grid>
    </Grid>
  );

}
