import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import debounce from "lodash/debounce";
import ActionsTextField from "../../../../../../../ReusableComponents/ActionsTextField";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

const executeWithDebounce = debounce((fn, ...args) => {
  fn(...args);
}, 700);

export default function ImageURLToolbar({
  url: propURL,
  onURLchange,
  isBgImage = false,
  bgImageSize = "Cover",
  onBgImageSizeChange = null,
  moduleType: propModuleType,
}) {
  const [moduleType, setModuleType] = useState(propModuleType);
  const [url, setUrl] = useState(propURL);
  const [imageSize, setImageSize] = useState(bgImageSize);

  useEffect(() => {
    if (moduleType !== propModuleType) {
      setModuleType(moduleType);
      setUrl(propURL);
      setImageSize(bgImageSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propModuleType]);

  return (
    <Grid
      container
      spacing={1}
      style={{
        background: "#fff",
        boxShadow: "0px 4px 15px 2px rgba(0, 40, 69, 0.14)",
        borderRadius: 3,
        alignItems: "center",
        padding: 2,
      }}
    >
      {!isBgImage && (
        <>
          <Grid item>
            <Typography variant="body1" style={{ fontWeight: 800 }}>
              URL
            </Typography>
          </Grid>
          <Grid item>
            <ActionsTextField
              placeholder={"https://image_url"}
              value={url}
              multiline={false}
              style={{ width: "100%", marginBottom: 0 }}
              onChange={(e) => {
                const value = e.target.value;
                setUrl(value);
                executeWithDebounce(onURLchange, {
                  url: value,
                });
              }}
            />
          </Grid>
        </>
      )}
      {isBgImage && (
        <>
          <Grid item>
            <Typography variant="body1" style={{ fontWeight: 800 }}>
              URL
            </Typography>
            <ActionsTextField
              placeholder={"https://image_url"}
              value={url}
              multiline={false}
              style={{ width: "100%", marginBottom: 0 }}
              onChange={(e) => {
                const value = e.target.value;
                setUrl(value);
                executeWithDebounce(onURLchange, {
                  url: value,
                });
              }}
            />
          </Grid>
          <Grid item>
            <Typography variant="body1" style={{ fontWeight: 800 }}>
              Image Style
            </Typography>
            <ToggleButtonGroup
              value={imageSize}
              exclusive
              onChange={(_, value) => {
                setImageSize(value || "Cover");

                if (onBgImageSizeChange) {
                  onBgImageSizeChange(value || "Cover");
                }
              }}
            >
              <ToggleButton
                value="Cover"
                aria-label="Cover"
                style={{ padding: 6 }}
              >
                Cover
              </ToggleButton>
              <ToggleButton
                value="Contain"
                aria-label="Contain"
                style={{ padding: 6 }}
              >
                Contain
              </ToggleButton>
              <ToggleButton
                value="Stretch"
                aria-label="Stretch"
                style={{ padding: 6 }}
              >
                Stretch
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </>
      )}
    </Grid>
  );
}
