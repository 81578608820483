import {
  IconButton,
  Tooltip,
  Typography,
  withStyles,
  Card,
} from "@material-ui/core";
import React, { useState } from "react";
import Loading from "../../../../../../../../ReusableComponents/Loading";
import dots from "../../../assets/dots.png";
import delete_icon from "../../../assets/delete_icon.png";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { sendEvent } from "../../../../../../../common/ApxEventHandler";

const styles = (theme) => ({
  buttonBaseStyle: {
    cursor: "pointer",
    borderRight: "1px solid #E4E7E9",
    display: "inline-flex",
    outline: "none",
    padding: 0,
    position: "relative",
    alignItems: "center",
    userSelect: "none",
    verticalAlign: "middle",
    justifyContent: "center",
    textDecoration: "none",
    backgroundColor: "transparent",
    textAlign: "initial",
    borderRadius: 0,
  },
  iconButtonRoot: {
    height: 30,
    width: 30,
    padding: 1,
    color: theme.palette.primary.main,
  },
  root: {
    zIndex: 9999,
  },
  contentAlign: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  classicCard: {
    top: 3,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    height: 80,
    width: "auto",
    borderRadius: 0,
    minWidth: 164,
    boxShadow: "none",
  },
  dots: {
    height: "19.5px",
    width: "auto",
    paddingLeft: "10px",
    top: "30px",
    alignContent: "center",
  },
  cardName: {
    fontFamily: "Manrope",
    fontSize: 14,
    fontWeight: 600,
    whiteSpace: "nowrap",
    overflow: "hidden",
    lineHeight: "19px",
    marginRight: 32,
  },
  deleteIcon: {
    height: 24,
    width: 24,
    position: "absolute",
    right: 4,
    top: 4,
  },
  deleteThumbnail: {
    height: "16px",
    width: "auto",
  },
  clipCopy: {
    height: 24,
    width: 24,
    bottom: 4,
    right: 5,
    position: "absolute",
    padding: 0,
  },
  copyThumbnail: {
    color: "#8497A5",
    height: "18px",
    width: "auto",
  },
});

const getName = (name) => {
  switch (name) {
    case "tooltip_with_text":
      return "Basic Text";
    case "image_text_tooltip":
      return "Basic Text with Image";
    case "tooltip_short_menu":
      return "Short Menu";
    case "custom_html":
      return "Custom";
    case "inapp_footer":
      return "Footer";
    case "inapp_header":
      return "Header";
    case "inapp_custom_html":
      return "Custom";
    case "inapp_fullscreen_modal":
      return "Full Screen Cover";
    case "inapp_modal_popup":
      return "Modal Popup";
    case "inapp_carousel":
      return "Carousel";
    case "inapp_floating":
      return "Floating Inapp";
    case "inapp_videos":
      return "Video InApp";
    case "nudge":
      return "Nudge";
    case "coachmark":
      return "Coachmark";
    default:
      return "step";
  }
};

const CampaignCard = ({
  classes,
  dispatch,
  editable = false,
  getLogo,
  index,
  actions,
  currentStep,
  parentDispatch,
  isExperiment = false,
  currentVariantKey = null,
  uiIndex = null,
  variants,
  disableCopy = false,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [onHover, setOnHover] = useState(false);
  const { image = "", name = "" } = getLogo();
  const {
    DELETE_STEP_FROM_VARIANT,
    DELETE_STEP_FROM_CAMPAIGN,
    ON_EDIT_STEP,
    ON_DELETE_STEP,
    SET_CURRENT_STEP,
    ON_COPY_STEP,
    ON_COPY_STEP_VARIANT,
  } = actions;

  return (
    <div id={`ClassicCard-${index}`} className={classes.root}>
      <div
        className={classes.contentAlign}
        style={{
          borderBottom: index === currentStep ? "2px solid black" : 0,
        }}
      >
        <div
          className={classes.buttonBaseStyle}
          onMouseEnter={() => setOnHover(true)}
          onMouseLeave={() => setOnHover(false)}
        >
          <Card
            raised={false}
            className={classes.classicCard}
            onClick={() => {
              dispatch({
                type: SET_CURRENT_STEP,
                value: index,
              });
              sendEvent(ON_EDIT_STEP);
            }}
          >
            <img src={dots} alt="dots" className={classes.dots} />
            {image}
            <Typography
              color="textPrimary"
              variant="h6"
              className={classes.cardName}
            >
              {getName(name)}
            </Typography>
          </Card>
          {editable && onHover && (
            <Tooltip title="Delete Step" className={classes.deleteIcon}>
              <IconButton
                classes={{
                  root: classes.iconButtonRoot,
                }}
                onClick={() => {
                  /**FIXME: Leaving this to debug an intermittent issue */
                  console.log("Deleting UI");
                  setIsDeleting(true);
                  dispatch({
                    type: DELETE_STEP_FROM_CAMPAIGN,
                    index,
                    isExperiment: isExperiment || false,
                  });
                  isExperiment &&
                    currentVariantKey &&
                    parentDispatch({
                      type: DELETE_STEP_FROM_VARIANT,
                      currentVariantKey: currentVariantKey,
                      index,
                    });
                  let stepToSet = -1;
                  if (isExperiment && currentVariantKey) {
                    stepToSet =
                      variants[currentVariantKey].indices.indexOf(index) > 0
                        ? variants[currentVariantKey].indices[
                            variants[currentVariantKey].indices.indexOf(index) -
                              1
                          ]
                        : -1;
                  } else {
                    stepToSet = 0;
                  }
                  dispatch({
                    type: SET_CURRENT_STEP,
                    value: stepToSet,
                  });
                  sendEvent(ON_DELETE_STEP);
                  setIsDeleting(false);
                }}
              >
                {isDeleting ? (
                  <Loading size={20} />
                ) : (
                  <img
                    src={delete_icon}
                    alt="delete"
                    className={classes.deleteThumbnail}
                  />
                )}
              </IconButton>
            </Tooltip>
          )}
          {editable && !disableCopy && onHover && (
            <Tooltip title="ClipCopy" className={classes.clipCopy}>
              <IconButton
                onClick={() => {
                  dispatch({
                    type: ON_COPY_STEP,
                    currentStep: index,
                  });
                  isExperiment &&
                    currentVariantKey &&
                    uiIndex &&
                    parentDispatch({
                      type: ON_COPY_STEP_VARIANT,
                      currentVariantKey: currentVariantKey,
                      index: uiIndex,
                    });
                }}
              >
                <FileCopyOutlinedIcon
                  fontSize="small"
                  color="action"
                  className={classes.copyThumbnail}
                />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(CampaignCard);
