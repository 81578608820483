import { produce } from "immer";
import { OVERVIEW_KEY } from "./constants";

export const REFRESH_COHORTS = "REFRESH_COHORTS";
export const SET_GROUP_BY = "SET_GROUP_BY";
export const SET_GROUP = "SET_GROUP";
export const RESET_GROUPBY = "RESET_GROUPBY";

export const initialState = {
  refreshCohorts: 0,
  groupBy: [],
  selectedGroup: OVERVIEW_KEY,
  selectedGroupByProperty: "",
};

let refreshIndex = 1;

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case REFRESH_COHORTS:
      draft.refreshCohorts = refreshIndex++;
      break;
    case SET_GROUP_BY:
      draft.groupBy = action.groupBy;
      draft.selectedGroupByProperty = action.property;
      break;
    case RESET_GROUPBY:
      draft.selectedGroupByProperty = "";
      break;
    case SET_GROUP:
      draft.selectedGroup = action.group;
      draft.groupBy = [];
      draft.selectedGroupByProperty = "";
      break;
    default:
      return draft;
  }
});
