import { IconButton, Input } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React, { useState } from "react";

export default function PasswordInput({ onChange, value, ...props }) {
  const [typePassword, setTypePassword] = useState(true);

  return (
    <Input
      size="medium"
      type={typePassword ? "password" : "text"}
      value={value}
      onChange={onChange}
      endAdornment={
        <IconButton
          aria-label="Toggle password visibility"
          size="small"
          style={{
            color: "#002845",
            opacity: 0.3,
          }}
          onClick={() => setTypePassword(!typePassword)}
        >
          {typePassword ? (
            <VisibilityOff
              style={{
                fontSize: "1.3rem",
              }}
            />
          ) : (
            <Visibility
              style={{
                fontSize: "1.3rem",
              }}
            />
          )}
        </IconButton>
      }
      style={props.style}
      {...props}
    />
  );
}
