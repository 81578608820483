const INITIAL_GET_CAMPAIGNS_STATES = {
  messages_pending: false,
  messages_failed: false,
};

const INITIAL_DELETE_CAMPAIGN_STATE = {
  campaign_delete_pending: false,
  campaign_delete_failed: false,
};

const INITIAL_SAVE_CAMPAIGN_STATE = {
  campaign_save_pending: false,
  campaign_save_failed: false,
};

const INITIAL_PAUSE_CAMPAIGN_STATE = {
  campaign_pause_pending: false,
  campaign_pause_failed: false,
};

const INITIAL_RESUME_CAMPAIGN_STATE = {
  campaign_resume_pending: false,
  campaign_resume_failed: false,
};

const INITIAL_PUBLISH_CAMPAIGN_STATE = {
  campaign_publish_pending: false,
  campaign_publish_failed: false,
};

export const INITIAL_CAMPAIGN_PROGRESS_STATE = {
  ...INITIAL_GET_CAMPAIGNS_STATES,
  ...INITIAL_DELETE_CAMPAIGN_STATE,
  ...INITIAL_SAVE_CAMPAIGN_STATE,
  ...INITIAL_PAUSE_CAMPAIGN_STATE,
  ...INITIAL_PUBLISH_CAMPAIGN_STATE,
  ...INITIAL_RESUME_CAMPAIGN_STATE,
  resetMessageState: false,
  statusMessage: "",
  actingID: null,
};

export const LISTING_OPTIONS = [
  {
    label: "Campaigns",
    value: "regular",
  },
  {
    label: "A/B Testing",
    value: "ab",
  },
];

export const DEMO_TEMPLATE_CONFIG_TYPES = {
  WALKTHROUGHS: "walkthrough_templates",
  SURVEYS: "survey_templates",
};

export const walkthroughTourTypes = [
  {
    name: "Search Feature Discovery",
    actionType: "walkthrough",
    content:
      "This tooltip nudges users to discover the search feature in the app when one does not add an item to the cart in 5 seconds after they come to the home screen.",
  },
  {
    name: "Guided Campaign",
    actionType: "walkthrough",
    content:
      "We will guide you through the creation of a campaign to solve your own use case in our very own demo app.",
  },
];

export const surveyTourTypes = [
  {
    name: "Cart Item Deletion - User Feedback Survey",
    actionType: "survey",
    content:
      "This survey is launched when users delete an item from the cart to understand the reason for the abandonment and asks to rate how likely they will comeback and purchase an item.",
  },
  {
    name: "Guided Survey",
    actionType: "survey",
    content:
      "We will guide you through the creation of a survey to understand your users better, in our very own demo app.",
  },
];

export const EXPERIENCE_CONFIG = {
  FEATURE_DISCOVERY: "feature_discovery",
  GUIDED_CAMPAIGN: "guided_campaign",
  CART_DELETION_SURVEY: "cart_deletion_survey",
  GUIDED_SURVEY: "guided_survey",
};

export const GUIDED_CAMPAIGN_EVENTS = [
  {
    title: "When user did App event: HomePageLaunched",
    config: {
      event_type: "app_event",
      context_rule_type: "APP",
      type: "did",
      sequence: -1,
      time_bounds: {
        lower: 0,
        upper: 6000000,
      },
      count_config: {
        count: 1,
        operator: "GTE",
      },
      combine_operator: "OR",
      activity: "",
      details: {
        name: "HomePageLaunched",
        additional_info: {},
      },
      trigger: {
        event_type: "app_start",
        activity: "",
        details: {
          name: "",
          additional_info: {},
        },
        time_bounds: {
          lower: 0,
          upper: 60000000,
        },
      },
    },
    displayText:
      "Kill the `Apxor Demo` app, re-open it and be on the home screen.",
  },
  {
    title: "When user did App event: AddClicked",
    description: "Where the source is HomePage",
    displayText:
      "Kill the `Apxor Demo` app, re-open it and add an item to cart when on home screen.",
    config: {
      event_type: "app_event",
      context_rule_type: "APP",
      type: "did",
      sequence: -1,
      time_bounds: {
        lower: 0,
        upper: 6000000,
      },
      count_config: {
        count: 1,
        operator: "GTE",
      },
      combine_operator: "OR",
      activity: "",
      details: {
        name: "AddClicked",
        additional_info: {
          source: {
            op: "EQ",
            val: "HomePage",
            t: "s",
            tl: "String",
          },
        },
      },
      trigger: {
        event_type: "app_start",
        activity: "",
        details: {
          name: "",
          additional_info: {},
        },
        time_bounds: {
          lower: 0,
          upper: 60000000,
        },
      },
    },
  },
  {
    title: "When user did App event: HomeMenuClicked",
    displayText:
      "Now open the `Apxor Demo` app and click on the hamburger menu icon.",
    config: {
      event_type: "app_event",
      context_rule_type: "APP",
      type: "did",
      sequence: -1,
      time_bounds: {
        lower: 0,
        upper: 6000000,
      },
      count_config: {
        count: 1,
        operator: "GTE",
      },
      combine_operator: "OR",
      activity: "",
      details: {
        name: "HomeMenuClicked",
        additional_info: {},
      },
      trigger: {
        event_type: "app_start",
        activity: "",
        details: {
          name: "",
          additional_info: {},
        },
        time_bounds: {
          lower: 0,
          upper: 60000000,
        },
      },
    },
  },
  {
    title: "When user did App event: ShowingSearchResults",
    displayText:
      "Now open the `Apxor Demo` app, search for something, and wait for the search results to show up.",
    config: {
      event_type: "app_event",
      context_rule_type: "APP",
      type: "did",
      sequence: -1,
      time_bounds: {
        lower: 0,
        upper: 6000000,
      },
      count_config: {
        count: 1,
        operator: "GTE",
      },
      combine_operator: "OR",
      activity: "",
      details: {
        name: "ShowingSearchResults",
        additional_info: {},
      },
      trigger: {
        event_type: "app_start",
        activity: "",
        details: {
          name: "",
          additional_info: {},
        },
        time_bounds: {
          lower: 0,
          upper: 60000000,
        },
      },
    },
  },
  {
    title: "When user did App event: MyCartPageLaunched",
    displayText: "Now open the `Apxor Demo` app and be on the cart screen.",
    config: {
      event_type: "app_event",
      context_rule_type: "APP",
      type: "did",
      sequence: -1,
      time_bounds: {
        lower: 0,
        upper: 6000000,
      },
      count_config: {
        count: 1,
        operator: "GTE",
      },
      combine_operator: "OR",
      activity: "",
      details: {
        name: "MyCartPageLaunched",
        additional_info: {},
      },
      trigger: {
        event_type: "app_start",
        activity: "",
        details: {
          name: "",
          additional_info: {},
        },
        time_bounds: {
          lower: 0,
          upper: 60000000,
        },
      },
    },
  },
  {
    title: "When user did App event: SearchIconClicked",
    displayText: "Now open the `Apxor Demo` app and click on the search icon.",
    config: {
      event_type: "app_event",
      context_rule_type: "APP",
      type: "did",
      sequence: -1,
      time_bounds: {
        lower: 0,
        upper: 6000000,
      },
      count_config: {
        count: 1,
        operator: "GTE",
      },
      combine_operator: "OR",
      activity: "",
      details: {
        name: "SearchIconClicked",
        additional_info: {},
      },
      trigger: {
        event_type: "app_start",
        activity: "",
        details: {
          name: "",
          additional_info: {},
        },
        time_bounds: {
          lower: 0,
          upper: 60000000,
        },
      },
    },
  },
];

export const GUIDED_SURVEY_EVENTS = [
  {
    title: "When user did App event: HomePageLaunched",
    config: {
      event_type: "app_event",
      context_rule_type: "APP",
      type: "did",
      sequence: -1,
      time_bounds: {
        lower: 0,
        upper: 6000000,
      },
      count_config: {
        count: 1,
        operator: "GTE",
      },
      combine_operator: "OR",
      activity: "",
      details: {
        name: "HomePageLaunched",
        additional_info: {},
      },
      trigger: {
        event_type: "app_start",
        activity: "",
        details: {
          name: "",
          additional_info: {},
        },
        time_bounds: {
          lower: 0,
          upper: 60000000,
        },
      },
    },
    displayText: "Now open the `Apxor Demo` app and be on the home screen.",
  },
  {
    title: "When user did App event: SearchResultsPageLaunched",
    config: {
      event_type: "app_event",
      context_rule_type: "APP",
      type: "did",
      sequence: -1,
      time_bounds: {
        lower: 0,
        upper: 6000000,
      },
      count_config: {
        count: 1,
        operator: "GTE",
      },
      combine_operator: "OR",
      activity: "",
      details: {
        name: "SearchResultsPageLaunched",
        additional_info: {},
      },
      trigger: {
        event_type: "app_start",
        activity: "",
        details: {
          name: "",
          additional_info: {},
        },
        time_bounds: {
          lower: 0,
          upper: 60000000,
        },
      },
    },
    displayText:
      "Now open the `Apxor Demo` app and be on the search results screen.",
  },
  {
    title: "When user did App event: MyCartPageLaunched",
    config: {
      event_type: "app_event",
      context_rule_type: "APP",
      type: "did",
      sequence: -1,
      time_bounds: {
        lower: 0,
        upper: 6000000,
      },
      count_config: {
        count: 1,
        operator: "GTE",
      },
      combine_operator: "OR",
      activity: "",
      details: {
        name: "MyCartPageLaunched",
        additional_info: {},
      },
      trigger: {
        event_type: "app_start",
        activity: "",
        details: {
          name: "",
          additional_info: {},
        },
        time_bounds: {
          lower: 0,
          upper: 60000000,
        },
      },
    },
    displayText: "Now open the `Apxor Demo` app and be on the cart screen.",
  },
  {
    title: "When user did App event: ItemDeleted",
    config: {
      event_type: "app_event",
      context_rule_type: "APP",
      type: "did",
      sequence: -1,
      time_bounds: {
        lower: 0,
        upper: 6000000,
      },
      count_config: {
        count: 1,
        operator: "GTE",
      },
      combine_operator: "OR",
      activity: "",
      details: {
        name: "ItemDeleted",
        additional_info: {},
      },
      trigger: {
        event_type: "app_start",
        activity: "",
        details: {
          name: "",
          additional_info: {},
        },
        time_bounds: {
          lower: 0,
          upper: 60000000,
        },
      },
    },
    displayText: "Now open the `Apxor Demo` app and delete an item from cart.",
  },
  {
    title: "When user did App event: SearchDismissClicked",
    config: {
      event_type: "app_event",
      context_rule_type: "APP",
      type: "did",
      sequence: -1,
      time_bounds: {
        lower: 0,
        upper: 6000000,
      },
      count_config: {
        count: 1,
        operator: "GTE",
      },
      combine_operator: "OR",
      activity: "",
      details: {
        name: "SearchDismissClicked",
        additional_info: {},
      },
      trigger: {
        event_type: "app_start",
        activity: "",
        details: {
          name: "",
          additional_info: {},
        },
        time_bounds: {
          lower: 0,
          upper: 60000000,
        },
      },
    },
    displayText:
      "Now open the `Apxor Demo` app and click on the search dismiss icon.",
  },
  {
    title: "When user did App event: OrderDetailPageLaunched",
    config: {
      event_type: "app_event",
      context_rule_type: "APP",
      type: "did",
      sequence: -1,
      time_bounds: {
        lower: 0,
        upper: 6000000,
      },
      count_config: {
        count: 1,
        operator: "GTE",
      },
      combine_operator: "OR",
      activity: "",
      details: {
        name: "OrderDetailPageLaunched",
        additional_info: {},
      },
      trigger: {
        event_type: "app_start",
        activity: "",
        details: {
          name: "",
          additional_info: {},
        },
        time_bounds: {
          lower: 0,
          upper: 60000000,
        },
      },
    },
    displayText:
      "Now open the `Apxor Demo` app and be on the order details screen.",
  },
];
