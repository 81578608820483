import { Typography, withStyles } from "@material-ui/core";
import React, { Fragment, useEffect } from "react";
import Loading from "../../../../../../../ReusableComponents/Loading";
import { ORIENTATION_ENUM } from "../simulator/utils";
import { useTracked } from "../../store";
import {
  SET_CURRENT_IMAGE,
  SET_EVENT_SOURCE,
  SET_LAYOUT,
  SET_ORIENTATION,
  SET_SELECTED_HTML_VIEW,
  SET_TREEVIEW,
} from "../../constants";
import { withQueryStrings } from "../../../../../../../../utils";

const SSE_API = window.SSE_ROOT + "layout?";

const getScaleFactors = (device_config, preview_config, orientation) => {
  const { image_width, image_height } = preview_config;
  const { device_width, device_height } = device_config;

  let w_scaleFactor = device_width / image_width,
    h_scaleFactor = device_height / image_height;

  return {
    w_scaleFactor:
      orientation === ORIENTATION_ENUM.PORTRAIT ? w_scaleFactor : h_scaleFactor,
    h_scaleFactor:
      orientation === ORIENTATION_ENUM.PORTRAIT ? h_scaleFactor : w_scaleFactor,
  };
};

const resizeBounds = (bounds, ratio) => {
  const { top, bottom, left, right } = bounds;
  const { w_scaleFactor, h_scaleFactor } = ratio;
  let newBounds = {
    top: Math.round(top / h_scaleFactor),
    bottom: Math.round(bottom / h_scaleFactor),
    left: Math.round(left / w_scaleFactor),
    right: Math.round(right / w_scaleFactor),
  };

  return {
    bounds: {
      ...newBounds,
      width: newBounds.right - newBounds.left,
      height: newBounds.bottom - newBounds.top,
    },
  };
};

const layoutModifier = (layout, ratio) => {
  return layout.map(({ bounds, views, ...others }) => ({
    ...others,
    ...resizeBounds(bounds, ratio),
    views: layoutModifier(views, ratio),
  }));
};

const styles = (theme) => ({
  layoutView: {
    border: "1px inset transparent",
    borderRadius: 4,
    "&:hover": {
      border: "1px inset red",
      backgroundColor: "#ff00031c",
    },
    cursor: "pointer",
  },
});

const WaitingForDevice = (props) => {
  const { withLoading, message } = props;
  return (
    <div>
      {withLoading && <Loading size={24} />}
      <Typography
        variant="subtitle2"
        style={{
          marginTop: 16,
          fontWeight: 400,
        }}
      >
        {message}
      </Typography>
    </div>
  );
};

const getTreeViewForLayout = (views, level = 0) =>
  views.map((view, index) => {
    let html_id = !view.path
      ? `${view.id}-${view.view}-${level}-${index}`
      : view.path;
    let name = view.id !== "" ? view.id : "View";
    let node = {
      name,
      html_id,
      sdk_id: view.id,
      sdk_path_id: view.path,
      view: view.view,
    };

    if (view.views && view.views.length === 0) {
      return node;
    } else {
      return {
        ...node,
        children: getTreeViewForLayout(view.views, index),
        toggled: true,
      };
    }
  });

const computeStyles = (bounds, level, selected) => {
  const { width, height, left, top } = bounds;
  const selectedStyles = {
    border: "1px dashed #FA0000",
    boxShadow: "0px 0px 5px 0px #545454",
    borderRadius: 0,
  };
  const positionStyle = {
    cursor: "pointer",
    zIndex: level,
    position: "absolute",
    width,
    height,
    left,
    top,
  };
  return { ...positionStyle, ...(selected ? selectedStyles : {}) };
};

const SSEDevicePreview = ({
  appId,
  imageConfig: { width, height },
  handleViewSelect,
  handleSSEDataReceive,
  classes,
  selectedDevice,
}) => {
  const [state, dispatch] = useTracked();
  const { layout, selectedHTMLView, currentImage } = state;
  const waitingState = selectedDevice && selectedDevice !== "";

  //willRecieveProps
  useEffect(() => {
    dispatch({
      type: SET_CURRENT_IMAGE,
      value: null,
    });
    dispatch({
      type: SET_LAYOUT,
      value: null,
    });
    dispatch({
      type: SET_SELECTED_HTML_VIEW,
      value: null,
    });

    // In order to make sure that the `return` function below
    // executes properly whenever this effect executes, we
    // must store the return value in this variable.
    let eventSourceInstance = null;
    //Object inside DOM
    if (window.hasOwnProperty("EventSource")) {
      eventSourceInstance = initEventSource(selectedDevice);
    } else {
      console.error("EventSource API not available");
    }

    // This effect runs whenever this effect re-runs
    // as well as upon unmount of this component
    return () => {
      if (eventSourceInstance?.close && eventSourceInstance?.url !== "") {
        eventSourceInstance.close();
        console.log("%cClosed the connection", "color: darkred");
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDevice]);

  const initEventSource = (selectedDevice) => {
    const eventSourceInstance = new EventSource(
      SSE_API + withQueryStrings({ appId, deviceId: selectedDevice })
    );
    console.log("%cCreated the connection", "color: green");
    dispatch({
      type: SET_EVENT_SOURCE,
      value: eventSourceInstance,
    });
    eventSourceInstance.onmessage = (event) => {
      dispatch({
        type: SET_SELECTED_HTML_VIEW,
        value: null,
      });
      try {
        const data = JSON.parse(event.data);
        if (data.hasOwnProperty("device_info")) {
          if (data.device_info.id === selectedDevice) {
            const {
              layout,
              device_info: { width: device_width, height: device_height },
              screen: { orientation = ORIENTATION_ENUM.PORTRAIT },
            } = data;
            const ratio = getScaleFactors(
              { device_width, device_height },
              {
                image_width: width,
                image_height: height,
              },
              orientation
            );
            dispatch({
              type: SET_CURRENT_IMAGE,
              value: "data:image/png;base64," + data.screen.image,
            });
            dispatch({
              type: SET_TREEVIEW,
              value: getTreeViewForLayout(layout),
            });
            dispatch({
              type: SET_LAYOUT,
              value: layoutModifier(layout, ratio),
            });
            dispatch({
              type: SET_ORIENTATION,
              value: orientation,
            });
            handleSSEDataReceive(data);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    return eventSourceInstance;
  };

  const EventSourceError = (
    <Typography
      variant={"subtitle2"}
      color={"error"}
      style={{
        fontWeight: 600,
        backgroundColor: "#f0516566",
        padding: "4px 12px",
        borderRadius: 4,
      }}
    >
      Your browser doesn't support this feature.
      <br />
      Contact <a href={"mailto:support@apxor.com"}>support@apxor.com</a> for
      help.
    </Typography>
  );

  let layoutGenerator = (views, level, parentBounds) =>
    views.map((view, index) => {
      let path = "";
      if (view.hasOwnProperty("path")) {
        path = view.path;
      }
      const HTML_ElementId =
        view.id + "-" + view.view + "-" + level + "-" + index;
      const HTML_ElementStyles = computeStyles(
        {
          ...view.bounds,
          top: view.bounds.top - 1,
          bottom: view.bounds.bottom - 1,
          left: view.bounds.left - 1,
          right: view.bounds.right - 1,
        },
        level,
        selectedHTMLView === view.id ||
          selectedHTMLView === path ||
          selectedHTMLView === HTML_ElementId
      );

      return (
        <Fragment key={HTML_ElementId}>
          <div
            id={HTML_ElementId}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              const isAlreadySelected = HTML_ElementId === selectedHTMLView;
              dispatch({
                type: SET_SELECTED_HTML_VIEW,
                value: isAlreadySelected ? null : HTML_ElementId,
              });
              if (isAlreadySelected) {
                handleViewSelect("", "");
              } else if (view.hasOwnProperty("id") && view.id !== "") {
                handleViewSelect(view.id, path);
              } else if (view.hasOwnProperty("path") && view.path !== "") {
                handleViewSelect(undefined, view.path);
              }
            }}
            className={classes.layoutView}
            style={{ ...HTML_ElementStyles }}
          ></div>
          {view.views.length > 0 &&
            layoutGenerator(view.views, level + index + 1, view.bounds)}
        </Fragment>
      );
    });

  return (
    <div
      style={{
        margin: "auto",
        backgroundColor: "#ffffff",
        border: "1.14433px solid #E4E7E9",
        width,
        height,
      }}
    >
      <div
        id="apx_screen_preview"
        style={{ position: "relative", overflow: "hidden" }}
      >
        {currentImage && (
          <img
            style={{
              width: width,
              height: height,
            }}
            src={currentImage}
            alt="apx_screen_preview"
          />
        )}
        {!currentImage && (
          <div
            style={{
              borderRadius: 16,
              textAlign: "center",
              maxWidth: "80%",
              height: height,
              margin: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {window.hasOwnProperty("EventSource") ? (
              <WaitingForDevice
                withLoading={waitingState}
                message={
                  !waitingState
                    ? "Please select a Test Device"
                    : "Waiting for Device"
                }
              />
            ) : (
              EventSourceError
            )}
          </div>
        )}
        {layout !== null &&
          layoutGenerator(layout, 1, {
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
          })}
      </div>
    </div>
  );
};

export default withStyles(styles)(SSEDevicePreview);
