import React from "react";
import { Grid, Tooltip, IconButton } from "@material-ui/core";
import {
  ADD_TO_CUSTOM_KEYS,
  TYPE_ENUM,
  DELETE_CUSTOM_KEYS,
} from "../constants";

import MultiSelect from "../../../../ReusableComponents/MultiSelect";
import ActionsTextField from "../../../../ReusableComponents/ActionsTextField";

import RemoveIcon from "@material-ui/icons/HighlightOff";
import { useDispatch } from "../store";

export function CustomKeysConfig({ dataKey, index, value, type }) {
  const dispatch = useDispatch();
  return (
    <Grid container>
      <Grid item xs={3}>
        <ActionsTextField
          type="text"
          placeholder="Key"
          value={dataKey}
          style={{ paddingTop: 8, paddingRight: 6, marginBottom: 2 }}
          textFieldStyle={{ padding: 4 }}
          onChange={(e) => {
            dispatch({
              type: ADD_TO_CUSTOM_KEYS,
              value: {
                key: e.target.value,
                value: value,
                type: type,
                index: index,
              },
            });
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <MultiSelect
          options={Object.keys(TYPE_ENUM).map((each) => ({
            label: each,
            value: each,
          }))}
          value={type}
          handleChange={(changedType) => {
            dispatch({
              type: ADD_TO_CUSTOM_KEYS,
              value: {
                key: dataKey,
                value: value,
                type: changedType,
                index: index,
              },
            });
          }}
          single
          fullWidth
          style={{ paddingTop: 8, paddingRight: 6, marginBottom: 2 }}
        />
      </Grid>
      <Grid item xs={4}>
        <ActionsTextField
          type="text"
          placeholder="Value"
          style={{ paddingTop: 8, paddingRight: 6, marginBottom: 2 }}
          textFieldStyle={{ padding: 4 }}
          value={value}
          onChange={(e) => {
            dispatch({
              type: ADD_TO_CUSTOM_KEYS,
              value: {
                key: dataKey,
                value: e.target.value,
                type: type,
                index: index,
              },
            });
          }}
        />
      </Grid>
      {
        <Grid item xs={1}>
          <Tooltip
            id={`button-delete-${dataKey}`}
            title="Remove this Key Value Pair"
            placement="bottom"
          >
            <IconButton
              style={{ marginTop: 12 }}
              color="primary"
              aria-label="Edit"
              onClick={() =>
                dispatch({
                  type: DELETE_CUSTOM_KEYS,
                  value: index,
                })
              }
            >
              <RemoveIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      }
    </Grid>
  );
}
