import React from "react";
import { useTracked } from "../store";
import { ADD_TO_BUTTON_CONFIG, DELETE_BUTTON_CONFIG } from "../constants";
import { Grid, Typography } from "@material-ui/core";
import ActionsTextField from "../../../../ReusableComponents/ActionsTextField";
import { IconButton, Tooltip } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/HighlightOff";
import MultiSelect from "../../../../ReusableComponents/MultiSelect";

export function ButtonConfig({ index, name, activity_name }) {
  const [state, dispatch] = useTracked();
  const { app_screens } = state;

  return (
    <Grid container spacing={16}>
      <Grid item xs={11}>
        <Typography>Button Label</Typography>
        <ActionsTextField
          type="text"
          required
          placeholder="Label"
          value={name}
          style={{ marginBottom: 12 }}
          onChange={(e) =>
            dispatch({
              type: ADD_TO_BUTTON_CONFIG,
              value: {
                name: e.target.value,
                activity_name: activity_name,
                index: index,
              },
            })
          }
          fullWidth
          margin="normal"
        />
        <MultiSelect
          options={app_screens.map((o) => ({ label: o, value: o }))}
          value={activity_name}
          placeholder="Select Activity"
          style={{ marginBottom: 5 }}
          handleChange={(selected_activity) =>
            dispatch({
              type: ADD_TO_BUTTON_CONFIG,
              value: {
                name: name,
                activity_name: selected_activity,
                index: index,
              },
            })
          }
          single
          fullWidth
        />
      </Grid>
      {
        <Grid item xs={1}>
          <Tooltip
            id={`button-delete-${name}`}
            title="Remove this button"
            placement="bottom"
          >
            <IconButton
              style={{ marginTop: 40 }}
              color="primary"
              aria-label="Edit"
              onClick={() =>
                dispatch({
                  type: DELETE_BUTTON_CONFIG,
                  value: index,
                })
              }
            >
              <RemoveIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      }
    </Grid>
  );
}
