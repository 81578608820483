import React from "react";
import PropTypes from "prop-types";
import AceEditor from "react-ace";

import "brace/mode/html";
import "brace/theme/github";
import "brace/theme/monokai";

export default function HTMLEditor({
  value = "",
  handleChange = () => null,
  config = {},
  height = "500px",
  editorRef = null,
  onCursorPositionChange = null,
  theme = "github",
  setCodeEmpty = () => null,
}) {
  if (value === "") {
    setCodeEmpty(true);
  }
  if (value !== "") {
    setCodeEmpty(false);
  }
  return (
    <AceEditor
      value={value}
      width="100%"
      height={height}
      ref={editorRef}
      mode="html"
      theme={theme} // theme select options
      onChange={handleChange}
      onCursorChange={(change) => {
        const { row, column } = change.cursor;
        if (onCursorPositionChange) {
          onCursorPositionChange(row, column);
        }
      }}
      name="ace-editor"
      enableBasicAutocompletion={true}
      enableLiveAutocompletion={true}
      editorProps={{
        $blockScrolling: true,
        ...config,
      }}
      fontSize={14}
      wrapEnabled={true}
    />
  );
}

HTMLEditor.propTypes = {
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  config: PropTypes.object,
};
