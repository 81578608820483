import { TextField } from "@material-ui/core";
import React from "react";
import { APP_PLATFORMS } from "../../../../../../constants";
import MultiSelect from "../../../../../ReusableComponents/MultiSelect";
import { EVENT_SOURCES } from "../constants";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ModuleConfigSection from "../../../../../ReusableComponents/ModuleConfigSection";

const StyledModuleConfigSection = (props) => {
  return (
    <ModuleConfigSection
      {...props}
      style={{ ...props.style, marginRight: 4, marginLeft: 4 }}
    />
  );
};

export default function Filters({
  appVersions,
  disabled,
  handleFilterChange,
  eventType,
  platform,
  source,
  eventName,
  appVersion,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
        zIndex: "1",
      }}
    >
      <StyledModuleConfigSection title={"Event Type"}>
        <MultiSelect
          disable={disabled}
          style={{ width: 180 }}
          value={eventType}
          options={[{ value: "client_event", label: "Client Event" }]}
          single
          isSearchable={false}
          placeholder={"Event Type"}
          handleChange={(selected) => {
            handleFilterChange("event_type", selected);
          }}
        />
      </StyledModuleConfigSection>
      <StyledModuleConfigSection title={"Platform"}>
        <MultiSelect
          disable={disabled}
          style={{ width: 150 }}
          options={[
            { value: null, label: "All" },
            { value: APP_PLATFORMS.android, label: "Android" },
            { value: APP_PLATFORMS.ios, label: "iOS" },
          ]}
          placeholder={"Platform"}
          defaultValue={platform}
          value={platform}
          single
          isSearchable={false}
          handleChange={(selected) => {
            handleFilterChange("platform", selected);
          }}
        />
      </StyledModuleConfigSection>
      <StyledModuleConfigSection title={"Source"}>
        <MultiSelect
          disable={disabled}
          style={{ width: 150 }}
          options={[
            { value: null, label: "All" },
            { value: EVENT_SOURCES.sdk, label: "SDK" },
            { value: EVENT_SOURCES.manual, label: "Manual" },
          ]}
          placeholder={"Source"}
          single
          isSearchable={false}
          value={source}
          handleChange={(selected) => {
            handleFilterChange("source", selected);
          }}
        />
      </StyledModuleConfigSection>
      <StyledModuleConfigSection title={"Event Name"} style={{ width: 200 }}>
        <TextField
          placeholder={`Search event`}
          value={eventName}
          InputProps={{
            startAdornment: (
              <InputAdornment position="end" style={{ padding: "10px 0" }}>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            handleFilterChange("event_name", e.target.value);
          }}
        />
      </StyledModuleConfigSection>
      <StyledModuleConfigSection title={"App Version"}>
        <MultiSelect
          style={{ width: 200 }}
          options={appVersions.map((version) => ({
            label: version,
            value: version,
          }))}
          placeholder={"App Version"}
          single
          clearable
          value={appVersion}
          handleChange={(selected) => {
            handleFilterChange(
              "app_version",
              selected !== "" ? selected : null
            );
          }}
        />
      </StyledModuleConfigSection>
    </div>
  );
}
