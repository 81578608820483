import {
  Box,
  Grid,
  IconButton,
  MuiThemeProvider,
  Typography,
} from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import React from "react";
import { useHistory } from "react-router-dom";
import {
  DEMO_ECOMMERCE_APP_ID,
  isExperienceDashboard,
} from "../../../../config";
import { useAuth } from "../../../../contexts/AuthContext";
import { useDeepCompareEffect } from "../../../../utils/use-deep-compare";
import { apxTheme } from "../../../ApplicationFrame/styling/CustomCSS";
import lockAndKeyImg from "../../assets/lock-and-key.svg";
import DemoSignInPage from "../../sales-sandbox/pages/DemoSignInPage";
import { useTrackedState } from "../../store";
import { AUTH_PAGE_TYPE } from "../constants";
import useIntegration from "../hooks/use-integration";

export default function AuthLayout({
  title,
  children,
  icon = null,
  withBackButton = false,
}) {
  const { user, pageType } = useAuth();
  const {
    user: { test_devices },
  } = useTrackedState();

  const { checkIfInvitationIsRevoked, checkIfUserExists } = useIntegration();
  const {
    push,
    goBack,
    location: { search },
  } = useHistory();
  const queryParams = new URLSearchParams(search);

  const token = queryParams.get("token") ?? "";
  const appId = queryParams.get("appId") ?? "";
  const email = queryParams.get("email") ?? "";

  useDeepCompareEffect(() => {
    if (!isExperienceDashboard() && user && user.email && user.email !== "") {
      push("/apps");
    } else if (
      isExperienceDashboard() &&
      user &&
      user.email &&
      user.email !== "" &&
      test_devices?.length >= 1
    ) {
      push(`/apps/${DEMO_ECOMMERCE_APP_ID}/dashboard`);
    }
    (async () => {
      if (!isExperienceDashboard() && token) {
        if (!(await checkIfInvitationIsRevoked(appId, email))) {
          if (await checkIfUserExists(token)) {
            push("/login", {
              setInvitedToTrue: true,
              token,
            });
          }
        } else {
          push("/invitation-revoked");
        }
      }
    })();
  }, [
    appId,
    checkIfInvitationIsRevoked,
    checkIfUserExists,
    email,
    push,
    test_devices,
    token,
    user,
  ]);

  return (
    <MuiThemeProvider theme={apxTheme}>
      {pageType === AUTH_PAGE_TYPE.EXPERIENCE_DASHBOARD ? (
        <DemoSignInPage />
      ) : (
        <div
          style={{
            margin: "-8px",
          }}
        >
          <Grid
            container
            style={{
              minHeight: "100vh",
              color: "#002845",
            }}
          >
            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#002845",
                padding: "3.5rem",
              }}
            >
              <Box
                style={{
                  backgroundColor: "rgba(3, 155, 229, 0.2)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingInline: "5rem",
                }}
              >
                {pageType === AUTH_PAGE_TYPE.SET_PASSWORD ? (
                  <img src={lockAndKeyImg} alt="Lock and Key" />
                ) : (
                  <img
                    src="https://storage.googleapis.com/apx_frontend-assets/sign-up-img.svg"
                    alt="Sign Up"
                    style={{
                      scale: 1.5,
                    }}
                  />
                )}
              </Box>
            </Grid>
            <Grid
              item
              xs={8}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
                padding: "150px",
              }}
            >
              <img src="/assets/img/ApxorLogo.svg" alt="Apxor App" />
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "start",
                  marginTop: "55px",
                  marginBottom: "20px",
                }}
              >
                {icon && (
                  <img
                    src={icon}
                    alt=""
                    style={{
                      display: "block",
                      marginBottom: "24px",
                    }}
                  />
                )}
                <Typography
                  style={{
                    color: "#002845",
                    fontSize: "24px",
                    fontWeight: "700",
                  }}
                >
                  {withBackButton && (
                    <IconButton
                      aria-label="Go Back"
                      size="small"
                      style={{
                        marginLeft: "-10px",
                        color: "#002845",
                      }}
                      onClick={() => goBack()}
                    >
                      <ChevronLeft
                        style={{
                          fontSize: "2rem",
                        }}
                      />
                    </IconButton>
                  )}
                  {title}
                </Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "end",
                  width: "100%",
                }}
              >
                {children}
              </Box>
            </Grid>
          </Grid>
        </div>
      )}
    </MuiThemeProvider>
  );
}
