/**
 * Created by Rakesh Peela
 * Date: 31-July-2019
 * Time: 6:22 PM
 */

import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {
  PasswordEditSection,
  PendingAppsApproval,
  ProfileSettings,
} from "./components";
import { getUserRoleAPI, updatePasswordAPI } from "./actions";
import { APXOR_ROLES } from "../../constants";
import { useAuth } from "../../contexts/AuthContext";
import ApplicationFrame from "../ApplicationFrame";

const styles = {
  bigAvatar: {
    margin: "auto",
    width: 120,
    height: 120,
    marginBottom: 16,
  },
};

function Profile() {
  const auth = useAuth();

  const [role, setRole] = useState(APXOR_ROLES.REGULAR_USER);

  useEffect(() => {
    getUserRoleAPI(auth).then((response) => {
      if (response && response.ROLE) {
        setRole(response.ROLE);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ApplicationFrame isNavbarEnabled={false} isAppsPage={true} />
      <section style={{ width: "100%", marginTop: 80 }} className="content">
        <Grid container justify={"center"}>
          <Grid item xs={12} sm={10} md={8}>
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <ProfileSettings
                  auth={auth}
                  updateUserProfileInfo={() => null}
                />
              </Grid>
              <Grid item xs={6}>
                <PasswordEditSection
                  auth={auth}
                  updatePassword={() => updatePasswordAPI()}
                />
              </Grid>
            </Grid>
            <hr style={{ marginTop: 16, marginBottom: 16 }} />
            <Grid container spacing={8}>
              {role === APXOR_ROLES.APX_ADMIN && (
                <Grid item>
                  <PendingAppsApproval auth={auth} userId={auth.user.email} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </section>
    </>
  );
}

export default withStyles(styles)(Profile);
