/**
 * Created by Rakesh Peela
 * Date: 06-Dec-2019
 * Time: 5:14 PM
 */

import React from "react";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";

function ModuleConfigSection({
  children,
  title,
  style = {},
  typographyStyleProps,
  typographyClassName,
  rootClassName,
}) {
  return (
    <div style={{ ...style }} className={rootClassName}>
      <Typography
        gutterBottom
        style={{ fontWeight: 400, ...typographyStyleProps }}
        variant={"subtitle1"}
        className={typographyClassName}
      >
        {title}
      </Typography>
      {children}
    </div>
  );
}

ModuleConfigSection.propTypes = {
  title: PropTypes.string.isRequired,
  typographyProps: PropTypes.object,
};

export default ModuleConfigSection;
