import React, { useState } from "react";
import { finalChoices, multiChoice } from "../constants";

export default function MultiChoiceSimulator({
  choices,
  handleAnswer,
  randomize_choices,
  ui,
}) {
  const [selected, setSelected] = useState([]);

  const {
    option_text_color_active,
    option_text_color_inactive,
    option_bg_color_active,
    option_bg_color_inactive,
  } = ui;

  return (
    <div>
      {finalChoices(choices, randomize_choices).map((choice) => (
        <div
          key={choice.id}
          onClick={(e) => {
            let updated = selected;
            if (selected.includes(choice.id)) {
              const selectedIndex = selected.indexOf(choice.id);
              updated = [
                ...selected.slice(0, selectedIndex),
                ...selected.slice(selectedIndex + 1),
              ];
            } else {
              updated = [...selected, choice.id];
            }
            setSelected(updated);
            handleAnswer(updated.length > 0);
          }}
          style={{
            margin: "8px 0",
          }}
        >
          {multiChoice(
            choice,
            option_text_color_active,
            option_text_color_inactive,
            option_bg_color_active,
            option_bg_color_inactive,
            selected.includes(choice.id)
          )}
        </div>
      ))}
    </div>
  );
}
