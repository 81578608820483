import { Grid } from "@material-ui/core"
import React from "react"
import useDebounced from "../../../../../utils/use-debounced"
import ActionsTextField from "../../../../ReusableComponents/ActionsTextField"
import {
  SET_AUTH_ERROR,
  SET_EMAIL_ID,
  SET_PHONE_COUNTRYCODE,
  SET_PHONE_ERROR,
  SET_PHONE_NUMBER
} from "../../../constants"
import { useDispatch, useTrackedState } from "../../../store"
import {
  demoDisposableEmailAPI,
  demoValidateEmailAPI
} from "../../actions"
import CountryCodeSelect from "./CountryCodeSelector"

export default function ExperienceDemoEmailForm() {
  const {
    user: { email, phone, country_code },
    auth_error,
  } = useTrackedState()
  const dispatch = useDispatch()
  // const [countryCode, setCountryCode] = useState("+911")

  const handleEmailChange = useDebounced(async (event) => {
    dispatch({
      type: SET_EMAIL_ID,
      payload: event.target.value,
    })
    const isDisposableEmail = await demoDisposableEmailAPI(event.target.value)
    const isValidEmail = await demoValidateEmailAPI(event.target.value)
    if (!isValidEmail || isDisposableEmail) {
      dispatch({
        type: SET_AUTH_ERROR,
        payload: "Please enter a valid work email",
      })
    }
  }, 700)

  const handlePhoneChange = (event) => {
    dispatch({
      type: SET_PHONE_NUMBER,
      payload: event.target.value,
    })
    if (phone !== "") {
      dispatch({
        type: SET_PHONE_ERROR,
        payload: "Please enter a valid phone number",
      })
    }
  }

  const handleCountryCodeChange = (event) => {
    dispatch({
      type: SET_PHONE_COUNTRYCODE,
      payload: event.target.value,
    })
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <label
            htmlFor="email"
            style={{
              color: "rgba(102, 122, 138, 1)",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            Work Email
          </label>
          <ActionsTextField
            multiline={false}
            id="email"
            type="email"
            name="email"
            value={email}
            onChange={(e) => {
              if (auth_error.length > 0) {
                dispatch({
                  type: SET_AUTH_ERROR,
                  payload: "",
                })
              }
              handleEmailChange(e)
            }}
            focused
            margin="normal"
            fullWidth
            validationErrors={auth_error}
            InputProps={{
              autoComplete: "email",
              style: {
                fontSize: "15px",
                fontWeight: "600",
                padding: "8px 6px",
                borderRadius: "2px",
                borderColor: auth_error ? "rgba(229, 59, 59, 1)" : "#CCD4DA",
              },
            }}
            style={{
              marginTop: "6px",
              marginBottom: "6px",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <label
                htmlFor="countryCode"
                style={{
                  color: "rgba(102, 122, 138, 1)",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Country Code
              </label>
              <CountryCodeSelect
                value={country_code}
                onChange={(e) => {
                  handleCountryCodeChange(e)
                }}
              />
              
            </Grid>
            <Grid item xs={6}>
              <label
                htmlFor="phone"
                style={{
                  color: "rgba(102, 122, 138, 1)",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Phone Number
              </label>
              <ActionsTextField
                multiline={false}
                id="phone"
                type="tel"
                name="phone"
                value={phone}
                onChange={(e) => {
                  handlePhoneChange(e)
                }}
                margin="normal"
                fullWidth
                InputProps={{
                  autoComplete: "tel",
                  style: {
                    fontSize: "15px",
                    fontWeight: "600",
                    padding: "8px 6px",
                    borderRadius: "2px",
                    borderColor: "#CCD4DA",
                  },
                }}
                style={{
                  marginTop: "6px",
                  marginBottom: "0px",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

