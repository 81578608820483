import { useMemo } from "react";
import { checkDeps, IS_DEV, useDeepCompareMemoize } from "./utils";

/**
 * The `useDeepCompareMemo` hook is a drop-in replacement for `useMemo` that will only trigger if the **array** or **object** dependencies of that factory have changed.
 * @param {() => any} factory The factory function to compute.
 * @param {import("react").DependencyList} deps The dependencies of the factory function.
 */
export default function useDeepCompareMemo(factory, deps) {
  if (IS_DEV) {
    checkDeps(deps, "useDeepCompareMemo");
  }

  return useMemo(factory, useDeepCompareMemoize(deps));
}

/**
 * The `useDeepCompareMemo` hook is a drop-in replacement for `useMemo` that will only trigger if the **array** or **object** dependencies of that factory have changed, but does not check the dependencies for validity, i.e., null or polymorphic values.
 * @param {() => any} factory The factory function to compute.
 * @param {import("react").DependencyList} deps The dependencies of the factory function.
 */
export function useDeepCompareMemoNoCheck(factory, deps) {
  return useMemo(factory, useDeepCompareMemoize(deps));
}
