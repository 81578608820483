import { Grid, Typography, withStyles } from "@material-ui/core";
import React, { useState } from "react";
import ColumnDiv from "../../../../../../../../ReusableComponents/ColumnDiv";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Carousel from "./Carousel";
import {
  ANSWER_MODES,
} from "../../../constants";

const CustomTab = withStyles(theme => ({
  root: {
    color: "#002845",
    borderRadius: theme.shape.borderRadius,
  },
  wrapper: {
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    alignItems: "baseline",
  },
  selected: {
    color: "white",
    backgroundColor: "#002845",
  },
  textColorInherit: {
    "&$selected": {
      color: "white",
    },
  },
  backButton: {
    color: theme.palette.text.primary,
    justifyContent: "flex-start",
    "&:hover": {
      background: "none",
    },
  },
}))(Tab);

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function setProps(index) {
  return {
    id: `vertical-tab-${index}`,
  };
}

const CustomTabs = withStyles(theme => ({
  indicator: {
    backgroundColor: "transparent",
  },
}))(Tabs);

const ActionTypeSelector = ({
  classes,
  handleDone,
  setTemplateSelected,
  experience_type,
}) => {
  const [action, setAction] = useState(ANSWER_MODES.single_choice);
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const carouselHandler = (
    <Carousel
      experience_type={experience_type}
      action={action}
      handleDone={handleDone}
      setTemplateSelected={setTemplateSelected}
    ></Carousel>
  );

  return (
    <ColumnDiv>
      <ColumnDiv>
        <div style={{ minHeight: 600 }}>
          <Grid container>
            <Grid item xs={3}>
              <Typography
                style={{
                  lineHeight: "34px",
                  letterSpacing: "0.02em",
                  color: "#002845",
                  fontSize: "25px",
                  fontWeight: "700",
                  fontStyle: "normal",
                  fontFamily: "Manrope",
                  textAlign: "left",
                  marginTop: "20px",
                  marginBottom: "15px",
                }}
              >
                Select Template
              </Typography>{" "}
              <CustomTabs
                orientation="vertical"
                value={value}
                onChange={handleChange}
                initialSelectedIndex={value}
              >
                <CustomTab
                  label="Request for Survey"
                  {...setProps(0)}
                  onClick={() => setAction(ANSWER_MODES.request_survey)}
                />
                <CustomTab
                  lableStyle={{ float: "left" }}
                  label="Single Choice"
                  {...setProps(1)}
                  onClick={() => setAction(ANSWER_MODES.single_choice)}
                />
                <CustomTab
                  lableStyle={{ float: "left" }}
                  label="Multi Choice"
                  {...setProps(2)}
                  onClick={() => setAction(ANSWER_MODES.multi_choice)}
                />
                <CustomTab
                  lableStyle={{ float: "left" }}
                  label="Short Answer"
                  {...setProps(3)}
                  onClick={() => setAction(ANSWER_MODES.text)}
                />
                <CustomTab
                  lableStyle={{ float: "left" }}
                  label="Rating"
                  {...setProps(4)}
                  onClick={() => setAction(ANSWER_MODES.rating)}
                />
                <CustomTab
                  lableStyle={{ float: "left" }}
                  label="Success Message"
                  {...setProps(5)}
                  onClick={() => setAction(ANSWER_MODES.success_message)}
                />
              </CustomTabs>
            </Grid>
            <Grid item xs={9}>
              {Array.from({ length: 6 }, (_, i) => i).map((eachIndex) => {
                return (
                  <TabPanel value={value} index={eachIndex}>
                    {carouselHandler}
                  </TabPanel>
                );
              })}
            </Grid>
          </Grid>
        </div>{" "}
      </ColumnDiv>
    </ColumnDiv>
  );
};

export default ActionTypeSelector;

