import {
  Checkbox,
  FormControlLabel,
  Grid,
  Input,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core"
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab"
import React, { useMemo } from "react"
import { APP_PLATFORMS } from "../../../../../../../../../constants"
import ActionsTextField from "../../../../../../../../ReusableComponents/ActionsTextField"
import CustomColorPicker from "../../../../../../../../ReusableComponents/CustomColorPicker"
import ModuleDivider from "../../../../../../../../ReusableComponents/ModuleDivider"
import MultiSelect from "../../../../../../../../ReusableComponents/MultiSelect"
import ToggleSwitch from "../../../../../../../../ReusableComponents/ToggleSwitch"
import { sendEvent } from "../../../../../../../common/ApxEventHandler"
import { TOGGLE_SWITCH_STYLES } from "../../../../../../../common/components/campaigncreator/constants"
import { useTrackedState } from "../../../../../../../common/components/campaigncreator/store"
import {
  HIDE_CONFIGURATION_TOOLBARS,
  MESSAGE_POSITION_ENUM,
  MESSAGE_TOOLTIP_TEXT_TYPE_ENUM,
  SET_ANIMATION_ENABLED,
  SET_BUTTONS,
  SET_BUTTON_ACTION,
  SET_DIM_BACKGROUND,
  SET_DIM_BACKGROUND_COLOR,
  SET_DIM_BACKGROUND_OPACITY,
  SET_DISMISS_OUTSIDE_TOUCH,
  SET_MESSAGE_POSITION,
  SET_TOOLTIP_TEXT_TYPE,
  TOOLTIP_LAYOUT_TYPES,
} from "../../../constants"
import { useTracked } from "../../../store"
import { getUI } from "../../../utils"
import { useCommonConfigStyles } from "../utils"
import ButtonAction from "./ButtonAction"
import CustomFontField from "./CustomFontField"
import DelayConfig from "./DelayConfig"

const getTooltipTextType = (ui) => {
  if (ui.hasOwnProperty("title_config") && ui.hasOwnProperty("text_config")) {
    return 1
  }
  return 0
}

export default function NewTooltipConfig({ currentStep }) {
  const classes = useCommonConfigStyles()

  const [state, dispatch] = useTracked()
  const parentState = useTrackedState()

  const {
    config: {
      meta: { platform, isExperiment },
    },
    app_screens: appScreenNames,
  } = parentState

  const ui = getUI(currentStep, state.uis, isExperiment).ui || {}
  const {
    animation = {},
    delay,
    dim_background,
    dim_background_color,
    dim_background_opacity,
    dismiss_outside_touch,
    layout_type,
    position,
    enableCfnt,
    cFnt,
  } = ui

  const isActionButtonsLayout = useMemo(
    () => layout_type === TOOLTIP_LAYOUT_TYPES.ACTION_BUTTONS,
    [layout_type],
  )

  const buttonKeyName = useMemo(
    () => (isActionButtonsLayout ? "action_buttons_config" : "buttons_config"),
    [isActionButtonsLayout],
  )

  return (
    <Grid container spacing={1} className={classes.gridContainer}>
      {!isActionButtonsLayout && (
        <>
          <Grid
            item
            xs={12}
            className={classes.gridItem}
            style={{ marginTop: 8 }}
          >
            <Typography
              className={classes.typographySubtitle2}
              variant="subtitle2"
            >
              TEXT TYPE
            </Typography>
            <Select
              input={<Input />}
              displayEmpty
              style={{ maxWidth: 220, minWidth: 220 }}
              options={MESSAGE_TOOLTIP_TEXT_TYPE_ENUM}
              value={getTooltipTextType(ui)}
              onChange={(e) => {
                const value = e.target.value
                dispatch({
                  type: SET_TOOLTIP_TEXT_TYPE,
                  currentStep,
                  value: Number(value),
                })
                sendEvent(HIDE_CONFIGURATION_TOOLBARS)
              }}
              defaultValue={MESSAGE_TOOLTIP_TEXT_TYPE_ENUM[0]}
            >
              {Object.values(MESSAGE_TOOLTIP_TEXT_TYPE_ENUM).map(
                ({ label, value }) => {
                  return (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  )
                },
              )}
            </Select>
          </Grid>
          <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
        </>
      )}
      <Grid item xs={12} className={classes.gridItem}>
        <Typography className={classes.typographySubtitle2} variant="subtitle2">
          BUTTONS
        </Typography>
        <ToggleSwitch
          className={classes.formControlLabel}
          disabled={buttonKeyName === "action_buttons_config"}
          checked={ui[buttonKeyName] ? ui[buttonKeyName].length > 0 : false}
          handleChange={(checked, e) => {
            let buttonsCount = 1
            if (!checked) {
              buttonsCount = 0
            }
            dispatch({
              type: SET_BUTTONS,
              currentStep,
              count: buttonsCount,
              key: buttonKeyName,
              isActionButtons: isActionButtonsLayout,
            })
            if (checked && dim_background && platform !== APP_PLATFORMS.web) {
              dispatch({
                type: SET_DIM_BACKGROUND,
                currentStep: currentStep,
                value: false,
              })
            }
            sendEvent(HIDE_CONFIGURATION_TOOLBARS)
          }}
          style={TOGGLE_SWITCH_STYLES(
            buttonKeyName === "action_buttons_config",
          )}
        />
      </Grid>

      {ui[buttonKeyName] && ui[buttonKeyName].length > 0 && (
        <>
          <Grid
            item
            xs={12}
            className={classes.gridItem}
            style={{ marginBottom: 0 }}
          >
            <Typography
              className={classes.typographyCaption}
              variant="subtitle2"
            >
              Number of buttons
            </Typography>
            <ToggleButtonGroup
              value={ui[buttonKeyName].length + ""}
              exclusive
              onChange={(e, value) => {
                let buttonsCount = isActionButtonsLayout ? 1 : 0
                if (value === "1") {
                  buttonsCount = 1
                } else if (value === "2") {
                  buttonsCount = 2
                } else if (value === "3") {
                  buttonsCount = 3
                }
                dispatch({
                  type: SET_BUTTONS,
                  currentStep,
                  count: buttonsCount,
                  key: buttonKeyName,
                  isActionButtons: isActionButtonsLayout,
                })
                sendEvent(HIDE_CONFIGURATION_TOOLBARS)
              }}
            >
              <ToggleButton value="1" aria-label="1" style={{ padding: 6 }}>
                <span style={{ padding: "0px 8px" }}>1</span>
              </ToggleButton>
              <ToggleButton value="2" aria-label="2" style={{ padding: 6 }}>
                <span style={{ padding: "0px 8px" }}>2</span>
              </ToggleButton>
              {isActionButtonsLayout && (
                <ToggleButton value="3" aria-label="3" style={{ padding: 6 }}>
                  <span style={{ padding: "0px 8px" }}>3</span>
                </ToggleButton>
              )}
            </ToggleButtonGroup>
          </Grid>
          {ui[buttonKeyName].map((button, index) => (
            <ButtonAction
              platform={platform}
              key={index}
              appScreenNames={appScreenNames}
              button={button}
              classes={classes}
              index={index}
              onActionChange={(data) => {
                dispatch({
                  type: SET_BUTTON_ACTION,
                  currentStep,
                  keyName: button.__id,
                  buttonKeyName,
                  data,
                })
              }}
            />
          ))}
        </>
      )}
      {!(
        platform === APP_PLATFORMS.android &&
        [
          TOOLTIP_LAYOUT_TYPES.IMAGE_TEXT_BUTTONS,
          TOOLTIP_LAYOUT_TYPES.ACTION_BUTTONS,
        ].some((templateType) => templateType === layout_type)
      ) && (
        <>
          <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
          <Grid item xs={12} className={classes.gridItem}>
            <Typography
              className={classes.typographySubtitle2}
              variant="subtitle2"
            >
              DIM BACKGROUND
            </Typography>
            <ToggleSwitch
              className={classes.formControlLabel}
              checked={dim_background}
              handleChange={(checked) => {
                dispatch({
                  type: SET_DIM_BACKGROUND,
                  currentStep: currentStep,
                  value: checked,
                })
                if (
                  animation.enabled &&
                  checked &&
                  platform === APP_PLATFORMS.android
                ) {
                  dispatch({
                    type: SET_ANIMATION_ENABLED,
                    currentStep,
                    value: false,
                  })
                }
                if (checked && platform !== APP_PLATFORMS.web) {
                  dispatch({
                    type: SET_BUTTONS,
                    currentStep,
                    count: 0,
                    key: buttonKeyName,
                    isActionButtons: isActionButtonsLayout,
                  })
                }
              }}
              style={TOGGLE_SWITCH_STYLES()}
            />
          </Grid>
          {dim_background && (
            <>
              <Grid item xs={12} className={classes.gridItem}>
                <Typography
                  className={classes.typographyCaption}
                  variant="subtitle2"
                >
                  Color
                </Typography>
                <CustomColorPicker
                  color={dim_background_color}
                  handleColorChange={(color) => {
                    dispatch({
                      type: SET_DIM_BACKGROUND_COLOR,
                      currentStep: currentStep,
                      value: color.hex,
                    })
                  }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridItem}>
                <Typography
                  className={classes.typographyCaption}
                  variant="subtitle2"
                >
                  Opacity %
                </Typography>
                <ActionsTextField
                  key="opacity_dim_background"
                  type="number"
                  InputProps={{
                    min: 0,
                    max: 100,
                    type: "number",
                  }}
                  value={dim_background_opacity * 100}
                  onChange={(event) => {
                    dispatch({
                      type: SET_DIM_BACKGROUND_OPACITY,
                      currentStep: currentStep,
                      value: Number(event.target.value) / 100 || 0,
                    })
                  }}
                  style={{
                    marginBottom: 0,
                  }}
                />
              </Grid>
            </>
          )}
        </>
      )}
      <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
      <Grid item xs={12} className={classes.gridItem}>
        <Typography className={classes.typographySubtitle2} variant="subtitle2">
          POSITION
        </Typography>
        <MultiSelect
          style={{ maxWidth: 145, minWidth: 145 }}
          single
          clearable={false}
          isSearchable={false}
          options={MESSAGE_POSITION_ENUM.filter(
            (x) => x.label !== "Center",
          ).map((x) => ({
            label: x.label.toUpperCase(),
            value: x.value.toUpperCase(),
          }))}
          value={position.toUpperCase()}
          handleChange={(value) => {
            dispatch({
              type: SET_MESSAGE_POSITION,
              currentStep: currentStep,
              value: value.toUpperCase(),
            })
          }}
        />
      </Grid>
      <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
      <Grid item xs={12} className={classes.gridItem}>
        <Typography className={classes.typographySubtitle2} variant="subtitle2">
          ANIMATION
        </Typography>
        <FormControlLabel
          className={classes.formControlLabel}
          label="Enable Animation"
          control={
            <Checkbox
              color="primary"
              checked={animation.enabled}
              onChange={(e) => {
                dispatch({
                  type: SET_ANIMATION_ENABLED,
                  currentStep,
                  value: e.target.checked,
                })
                if (
                  e.target.checked &&
                  dim_background &&
                  platform === APP_PLATFORMS.android
                ) {
                  dispatch({
                    type: SET_DIM_BACKGROUND,
                    currentStep: currentStep,
                    value: false,
                  })
                }
              }}
              style={{ padding: 0, paddingRight: 8 }}
              disableRipple
            />
          }
        />
      </Grid>
      <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
      <Grid item xs={12} className={classes.gridItem}>
        <Typography className={classes.typographySubtitle2} variant="subtitle2">
          DISMISS
        </Typography>
        <FormControlLabel
          className={classes.formControlLabel}
          label="Dismiss on Touch Outside"
          control={
            <Checkbox
              color="primary"
              checked={dismiss_outside_touch}
              onChange={(e) => {
                dispatch({
                  type: SET_DISMISS_OUTSIDE_TOUCH,
                  currentStep,
                  value: e.target.checked,
                })
              }}
              style={{ padding: 0, paddingRight: 8 }}
              disableRipple
            />
          }
        />
      </Grid>
      {platform !== APP_PLATFORMS.web && (
        <>
          <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
          <DelayConfig delay={delay} />
        </>
      )}
      <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
      {platform !== APP_PLATFORMS.ios && (
        <CustomFontField isCFnt={enableCfnt} customFont={cFnt} />
      )}
    </Grid>
  )
}
