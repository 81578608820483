import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../../contexts/AuthContext";
import {
  getFunnelsAPI,
  getFunnelAPI,
  saveFunnelAPI,
  deleteFunnelAPI,
} from "../actions";
import FunnelHeader from "./FunnelHeader";
import FunnelGraph from "./FunnelGraph";
import FunnelComparision from "./FunnelComparison";
import FunnelGroupBy from "./FunnelGroupBy";
import { getEventsAPI } from "../../../../common/actions";
import { useTracked } from "../store";
import { RESET_GROUPBY } from "../reducer";

export default function Funnel({ appId, queryParams }) {
  const auth = useAuth();

  const [funnels, setFunnels] = useState([]);
  const [funnelLoaded, setFunnelLoaded] = useState(false);
  const [funnelId, setFunnelId] = useState("");
  const [getFunnelClicked, setGetFunnelClicked] = useState(false);
  const [filters, setFilters] = useState({
    events: [],
    user: [],
    session: [],
    time: 300,
    groupBy: [],
  });
  const [fetchFunnels, setFetchFunnels] = useState(0);

  const [funnelSteps, setFunnelSteps] = useState([]);
  const [groupByData, setGroupByData] = useState([
    { attributes: ["Overall"], funnel: [] },
  ]);
  const [stepWiseTimes, setStepWiseTimes] = useState([]);
  const [conversionRate, setConversionRate] = useState(0);
  const [eventList, setEventList] = useState([]);
  const [pending, setPending] = useState(false);
  const [state, dispatch] = useTracked();
  const [groupByLoading, setGroupByLoading] = useState(false);
  // ComponentWillMount, Run only once
  useEffect(() => {
    getEventsAPI(auth, appId, queryParams).then(response => {
      setEventList(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Runs whenever we want the re-fetch the funnels list from server
  useEffect(() => {
    // FIXME: Make sure we display loading indicators while making network calls
    getFunnelsAPI(auth, appId, queryParams).then(response => {
      setFunnels(response || []);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchFunnels]);

  // Runs whenever one of the dependencies modified
  useEffect(() => {
    // setFunnelLoaded(false);
    if (getFunnelClicked && filters.events.length >= 2) {
      setPending(true);
      setGroupByLoading(true);
      getFunnelAPI(auth, appId, queryParams, {
        ...filters,
        time: filters.time * 1000,
      }).then(response => {
        setFunnelSteps(response.funnels);
        setGroupByData([{ attributes: ["Overall"], funnel: response.funnels }]);
        setConversionRate(response.conversion_rate);
        setStepWiseTimes(response.stepwise_times);
        setFunnelLoaded(true);
        setGetFunnelClicked(false);
        setPending(false);
        setGroupByLoading(false);
        dispatch({ type: RESET_GROUPBY });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, appId, queryParams, filters, getFunnelClicked, funnelId]);

  useEffect(() => {
    if (!getFunnelClicked && state.groupBy && filters.events.length >= 2) {
      setGroupByLoading(true);
      getFunnelAPI(auth, appId, queryParams, {
        ...filters,
        time: filters.time * 1000,
        groupBy: state.groupBy,
      }).then(response => {
        setFunnelSteps(response.funnels);
        setConversionRate(response.conversion_rate);
        setGroupByData(
          response.group_by.length < 1
            ? [{ attributes: ["Overall"], funnel: response.funnels }]
            : response.group_by
        );
        setStepWiseTimes(response.stepwise_times);
        setGroupByLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.groupBy]);

  // Creates a new funnel
  const saveNewFunnel = funnelConfig => {
    saveFunnelAPI(auth, appId, queryParams, funnelConfig).then(response => {
      if (response) {
        // This will make the funnels list to re-fetch from server
        setFetchFunnels(fetchFunnels + 1);
      }
    });
  };

  // Deletes an existing funnel
  const deleteFunnel = deleteFunnelId => {
    deleteFunnelAPI(auth, appId, deleteFunnelId).then(response => {
      if (response) {
        // As the selected funnel can only be deleted,
        // We need to reset the filters
        setFilters({
          events: [],
          session: [],
          user: [],
          time: 300,
        });

        // This will make the funnels list to re-fetch from server
        setFetchFunnels(fetchFunnels + 1);
      }
    });
  };

  return (
    <>
      <FunnelHeader
        appId={appId}
        funnels={funnels}
        filters={filters}
        pending={pending}
        onFiltersChange={data => {
          setFilters({
            ...filters,
            ...data,
          });
          if (data.name && data.name !== filters.name) {
            setFunnelSteps([]);
          }
          setFunnelLoaded(filters.events.length >= 2);
        }}
        getFunnel={id => {
          setFunnelId(id);
          setGetFunnelClicked(true);
        }}
        saveNewFunnel={saveNewFunnel}
        deleteFunnel={deleteFunnel}
        eventList={eventList}
      />
      {funnelLoaded && funnelSteps.length > 0 && (
        <FunnelGraph
          auth={auth}
          appId={appId}
          filters={filters}
          funnels={funnelSteps}
          queryParams={queryParams}
          conversionRate={conversionRate}
        />
      )}
      {funnelLoaded && funnelSteps.length > 0 && (
        <FunnelComparision
          auth={auth}
          appId={appId}
          funnelId={funnelId}
          filters={filters}
          funnels={funnels}
          queryParams={queryParams}
        />
      )}
      {funnelLoaded && funnelSteps.length > 0 && (
        <FunnelGroupBy
          auth={auth}
          appId={appId}
          funnels={funnelSteps}
          groupByData={groupByData}
          stepWiseTimes={stepWiseTimes}
          filters={filters}
          queryParams={queryParams}
          groupByLoading={groupByLoading}
        />
      )}
    </>
  );


}