import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  withStyles,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.text.full,
  },
  rootWithoutBg: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
  },
  typographyRoot: {
    color: theme.palette.secondary.main,
  },
  typographyRootWithoutBg: {
    color: theme.palette.text.full,
    fontWeight: 900,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "#A1ADB6",
    padding: 0,
  },
  closeButtonDark: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.secondary.main,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children,
    classes,
    noTitleBg = false,
    onClose,
    titleVariant = "h5",
    dialogTitleStyles = {},
    ...other
  } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={noTitleBg ? classes.rootWithoutBg : classes.root}
      {...other}
      classes={{
        root: noTitleBg ? classes.rootWithoutBg : classes.root,
      }}
      style={dialogTitleStyles}
    >
      <Typography
        variant={titleVariant}
        classes={{
          root: noTitleBg
            ? classes.typographyRootWithoutBg
            : classes.typographyRoot,
        }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const CustomMaterialUIDialog = ({
  dialogActions,
  dialogActionStyles = {},
  dialogContent,
  dialogContentStyles = {},
  dialogTitleStyles = {},
  dialogProps = {},
  hideCloseIcon = false,
  isFullWidth = true,
  maxWidth = "md",
  noTitleBg = false,
  openFlag = false,
  onClose,
  onDialogClose,
  title,
  titleVariant = "h5",
  classes,
  paperStyles = {},
  backdropProps = {},
}) => {
  if (!dialogContent) {
    return <div></div>;
  }

  const {
    disableBackdropClick = false,
    disableEscapeKeyDown = false,
    ...others
  } = dialogProps;

  return (
    <Dialog
      scroll="body"
      maxWidth={maxWidth}
      fullWidth={isFullWidth}
      open={openFlag}
      disableEscapeKeyDown={disableEscapeKeyDown}
      {...others}
      onClose={(_event, reason) => {
        if (reason === "backdropClick" && disableBackdropClick) {
          return;
        }
        onDialogClose();
      }}
      PaperProps={{
        elevation: 0,
        style: {
          ...paperStyles,
        },
      }}
      BackdropProps={backdropProps}
    >
      {title && (
        <DialogTitle
          noTitleBg={noTitleBg}
          onClose={onClose ? () => onClose() : null}
          titleVariant={titleVariant}
          dialogTitleStyles={dialogTitleStyles}
        >
          {title}
        </DialogTitle>
      )}
      {!title && onClose && !hideCloseIcon && (
        <MuiDialogTitle style={dialogTitleStyles}>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
      )}
      <DialogContent
        style={dialogContentStyles ? { ...dialogContentStyles } : {}}
      >
        {dialogContent}
      </DialogContent>
      {dialogActions && (
        <DialogActions style={{ ...dialogActionStyles }}>
          {dialogActions}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default withStyles(styles)(CustomMaterialUIDialog);
