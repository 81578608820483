import deepEqual from "deep-equal";
import { useRef } from "react";

export const IS_DEV = process.env.NODE_ENV !== "production";

/**
 * This is a helper function to check if the value is a primitive.
 * @private
 * @param {any} val The value to check if it is a primitive.
 * @returns {boolean} Whether the value is a primitive or not.
 */
export function isPrimitive(val) {
  return val == null || /^[sbn]/.test(typeof val);
}

/**
 * This is a helper function to check if the dependencies has changed.
 * @private
 * @param {Array} deps The dependencies to check if they are equal
 * @param {string} name The name of the hook
 */
export function checkDeps(deps, name) {
  const reactHookName = `${name.replace(/DeepCompare/, "")}`;

  if (!deps || deps.length === 0) {
    throw new Error(
      `${name} should not be used with no dependencies. Use ${reactHookName} instead.`,
    );
  }

  if (deps.every(isPrimitive)) {
    throw new Error(
      `${name} should not be used with dependencies that are all primitive values. Use ${reactHookName} instead.`,
    );
  }
}

/**
 * This is a custom hook that uses deep-equal to compare the previous and current values of a dependency array.
 * @private
 * @param {Array} value The dependency list to compare
 * @returns {Array} The previous dependency list
 */
export function useDeepCompareMemoize(value) {
  const ref = useRef([]);

  if (!deepEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}
