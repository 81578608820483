import React from "react";

export default function ColumnDiv({
  customRef,
  children,
  styles = {},
  onClick = () => null,
  ...props
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        ...styles,
      }}
      ref={customRef}
      onClick={onClick}
      {...props}
    >
      {children}
    </div>
  );
}
