import React from "react";
import { useTracked } from "../../../../store";
import Question from "./components/Question";

/**
 * Displays list of Questions and a tiny button to add new question
 *
 * Note: These config sections don't know that the Design page has a parent.
 * Whenever there is a change in these config params, they will post
 * these to the local store through `dispatch`. They don't need to
 * know whether the Design page is talking to somebody or not
 * (unnecessary headache right? :D).
 */
export default function QuestionsConfig() {
  /**
   * When you use `react-tracked` lib, you don't need to pass props
   *
   * Always read the latest state from the store and post the changes
   * back to the store using `dispatch`
   *
   * There's a lot of confusion whether we need to maintain the component
   * specific state or not. AFAI see, I don't face any issues or any need
   */
  const [state] = useTracked();

  const {
    currentStep,
    ui: { questions = [] },
  } = state;

  if (currentStep < 0) {
    return <></>;
  }

  return (
    <Question
      {...questions[currentStep]}
      index={currentStep}
      totalQuestions={questions.length}
    />
  );
}
