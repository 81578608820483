import React, { useState, useEffect, useMemo } from "react";
import TransferWithinAStation from "@material-ui/icons/TransferWithinAStation";
import { Grid, Button, TextField } from "@material-ui/core";
import Snackbar from "../../../../ReusableComponents/Snackbar";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import Box from "../../../../ReusableComponents/Box";
import MultiSelect from "../../../../ReusableComponents/MultiSelect";
import Checkbox from "../../../../ReusableComponents/Checkbox";
import PathGraph from "../../../../ReusableComponents/PathGraph";
import Loading from "../../../../ReusableComponents/Loading";
import FiltersHolder from "./FiltersHolder";
import { Operators } from "../../../../../constants";
import AttributeBuilder from "../../../../ReusableComponents/AttributeBuilder";
import {
  getEventsAPI,
  getAppScreenNamesAPI,
  getEventAttributesAPI,
  getAttributes,
} from "../../../../common/actions";
import { getEventPathAPI, getScreenPathAPI } from "../actions";
import { useAuth } from "../../../../../contexts/AuthContext";
import ExpansionModuleConfig from "../../../../ReusableComponents/ExpansionModuleConfig";

const PATH_ENUM = {
  EVENT: "Event",
  SCREEN: "Screen",
};

const currentPathType = PATH_ENUM.EVENT;

const DEFAULT_PATH_RESPONSE = { sample: 100, data: [] };

export default function PathComponent({ app, queryParams }) {
  const auth = useAuth();

  const [events, setEvents] = useState([]);
  const [screenNames, setScreenNames] = useState([]);
  const [userAttributes, setUserAttributes] = useState([]);
  const [sessionAttributes, setSessionAttributes] = useState([]);

  const [start, setStart] = useState("");
  const [startAttributes, setStartAttributes] = useState([]);
  const [selectedStartProps, setSelectedStartProps] = useState([]);

  const [end, setEnd] = useState("");
  const [endAttributes, setEndAttributes] = useState([]);
  const [selectedEndProps, setSelectedEndProps] = useState([]);

  const [selectedUserProps, setSelectedUserProps] = useState([]);
  const [selectedSessionProps, setSelectedSessionProps] = useState([]);

  const [limit, setLimit] = useState(10);
  const [size, setSize] = useState(5);
  const [days, setDays] = useState([]);
  const [range, setRange] = useState({ min: 0, max: 1 });
  const [submitted, setSubmitted] = useState(false);
  const [enabledDays, setEnabledDays] = useState(false);
  const [pending, setPending] = useState(false);
  const [failed, setFailed] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);

  const [path, setPath] = useState(DEFAULT_PATH_RESPONSE);

  const appId = useMemo(() => app.app_id, [app]);

  const dateFilters = useMemo(
    () => ({ since: queryParams.since, till: queryParams.till }),
    [queryParams.since, queryParams.till]
  );

  // Fetch Attributes, Events and Screen Names upon ComponentWillMount
  useEffect(() => {
    getAttributes(auth, appId).then((attrs) => {
      setUserAttributes(attrs.user);
      setSessionAttributes(attrs.session);
    });

    getEventsAPI(auth, appId, queryParams).then((events) => {
      setEvents(events);
    });

    getAppScreenNamesAPI(auth, appId).then((screens) => {
      setScreenNames(screens);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Enable the SUBMIT buttom whenever the input parameters gets changed
  useEffect(() => {
    setSubmitted(false);
  }, [
    limit,
    size,
    days,
    range,
    start,
    end,
    selectedStartProps,
    selectedEndProps,
    selectedSessionProps,
    selectedUserProps,
    queryParams,
    auth,
    appId,
  ]);

  // Whenever range gets changed, need to set the days accordingly
  useEffect(() => {
    const days = [...new Array(range.max - range.min + 1).keys()].map(
      (o, i) => range.min + i
    );
    setDays(days);
  }, [range]);

  // Fetch event attributes when the start event gets changed
  // As the event gets changed, we need to reset the selected props as well
  useEffect(() => {
    if (start && start.length > 0) {
      getEventAttributesAPI(auth, appId, { ...queryParams, event: start }).then(
        (response) => {
          setStartAttributes(response);
          setSelectedStartProps([]);
        }
      );
    }
  }, [auth, appId, queryParams, start]);

  // Fetch event attributes when the end event gets changed
  // As the event gets changed, we need to reset the selected props as well
  useEffect(() => {
    if (end && end.length > 0) {
      getEventAttributesAPI(auth, appId, { ...queryParams, event: end }).then(
        (response) => {
          setEndAttributes(response);
          setSelectedEndProps([]);
        }
      );
    }
  }, [auth, appId, queryParams, end]);

  // Get the PATH whenever the SUBMIT button is clicked
  useEffect(() => {
    if (submitClicked) {
      setPending(true);
      const api =
        currentPathType === PATH_ENUM.EVENT
          ? getEventPathAPI
          : getScreenPathAPI;
      api(
        auth,
        appId,
        {
          ...queryParams,
          startEvent: start === "" ? null : start,
          endEvent: end === "" ? null : end,
          size: size,
          limit: limit,
          day: days.length > 0 ? days : null,
        },
        {
          startProperties: selectedStartProps,
          endProperties: selectedEndProps,
          userProperties: selectedUserProps,
          sessionProperties: selectedSessionProps,
        }
      )
        .then((response) => {
          setPath(response);
          setPending(false);
          setFailed(false);
          setSubmitClicked(false);
        })
        .catch((e) => {
          setPending(false);
          setFailed(true);
          setSubmitClicked(false);
        });
    }
  }, [
    appId,
    auth,
    days,
    end,
    limit,
    queryParams,
    selectedEndProps,
    selectedSessionProps,
    selectedStartProps,
    selectedUserProps,
    size,
    start,
    submitClicked,
  ]);

  return (
    <Box
      title="User Behavior"
      icon={<TransferWithinAStation />}
      withPadding
      controls={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            minWidth: 480,
          }}
        >
          <TextField
            id="users"
            label={"Minimum Users"}
            placeholder={"10"}
            value={limit}
            onChange={(e) => {
              const number = Number(e.target.value);
              if (number > 0) {
                setLimit(number);
              }
            }}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ maxWidth: 120 }}
          />
          <TextField
            id="number"
            label={currentPathType + "s"}
            value={size}
            onChange={(e) => {
              const number = Number(e.target.value);
              if (number > 0 && number <= 10) {
                setSize(number);
              }
            }}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ maxWidth: 70 }}
          />
        </div>
      }
    >
      <ExpansionModuleConfig
        title={"Filter By Property"}
        defaultExpanded={false}
      >
        <Grid container spacing={0} justify="center">
          <Grid item xs={12} md={12}>
            <AttributeBuilder
              key={"User Properties"}
              title={"User Properties"}
              appId={appId}
              dateFilters={dateFilters}
              operators={Operators}
              attributes={userAttributes}
              handleOnChange={(attrs) => setSelectedUserProps(attrs)}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <AttributeBuilder
              key={"Session Properties"}
              title={"Session Properties"}
              appId={appId}
              dateFilters={dateFilters}
              operators={Operators}
              attributes={sessionAttributes}
              handleOnChange={(attrs) => setSelectedSessionProps(attrs)}
            />
          </Grid>
        </Grid>
      </ExpansionModuleConfig>
      <br />
      <Grid container spacing={8} justify="center">
        <Grid item xs={12} md={3}>
          <MultiSelect
            clearable
            options={(currentPathType === PATH_ENUM.EVENT
              ? events
              : screenNames
            ).map((o) => ({ label: o, value: o }))}
            placeholder={"From " + currentPathType}
            value={start}
            handleChange={(start) => {
              setStart(start);
              if (start === "" && end === "") {
                setPath(DEFAULT_PATH_RESPONSE);
              }
            }}
            single
          />
          {currentPathType === PATH_ENUM.EVENT && start && start.length > 0 && (
            <FiltersHolder event={start} data={selectedStartProps}>
              <AttributeBuilder
                key={start + " Properties"}
                appId={appId}
                title={start + " Properties"}
                dateFilters={dateFilters}
                initialValues={selectedStartProps}
                operators={Operators}
                attributes={startAttributes}
                withBox={false}
                handleOnChange={(attrs) => {
                  setSelectedStartProps(attrs);
                }}
              />
            </FiltersHolder>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={4} md={3}>
              <Checkbox
                label="Day Filter"
                checked={enabledDays}
                handleChange={(enabledDays) => {
                  const days = [
                    ...new Array(range.max - range.min + 1).keys(),
                  ].map((o, i) => range.min + i);
                  setEnabledDays(enabledDays);
                  setDays(enabledDays ? days : []);
                }}
              />
            </Grid>
            <Grid item xs={8} md={6}>
              {enabledDays && (
                <div style={{ padding: 16 }}>
                  <InputRange
                    maxValue={90}
                    minValue={0}
                    value={range}
                    onChange={(range) => setRange(range)}
                    onChangeComplete={(range) => null}
                  />
                </div>
              )}
            </Grid>
          </Grid>
          <Button
            style={{ margin: "16px auto", display: "block" }}
            variant="contained"
            disabled={
              submitted ||
              !((start && start.length > 0) || (end && end.length > 0))
            }
            onClick={(e) => {
              setSubmitted(true);
              setSubmitClicked(true);
            }}
            color="primary"
          >
            {pending ? <Loading /> : "Submit"}
          </Button>
          {failed && (
            <Snackbar>
              <strong>Unable to fetch path. Path does not exist!</strong>
            </Snackbar>
          )}
          {submitted && !pending && path.data.length === 0 && !failed && (
            <Snackbar>
              <strong>No path data found. Try changing query.</strong>
            </Snackbar>
          )}
        </Grid>
        <Grid item xs={12} md={3}>
          <MultiSelect
            clearable
            options={(currentPathType === PATH_ENUM.EVENT
              ? events
              : screenNames
            ).map((o) => ({ label: o, value: o }))}
            placeholder={"To " + currentPathType}
            value={end}
            handleChange={(end) => {
              setEnd(end);
              if (start === "" && end === "") {
                setPath(DEFAULT_PATH_RESPONSE);
              }
            }}
            single
          />
          {currentPathType === PATH_ENUM.EVENT && end && end.length > 0 && (
            <FiltersHolder event={end} data={selectedEndProps}>
              <AttributeBuilder
                key={end + " Properties"}
                appId={appId}
                title={end + " Properties"}
                dateFilters={dateFilters}
                initialValues={selectedEndProps}
                operators={Operators}
                attributes={endAttributes}
                handleOnChange={(attrs) => {
                  setSelectedEndProps(attrs);
                }}
              />
            </FiltersHolder>
          )}
        </Grid>
      </Grid>
      {(start !== "" || end !== "") && (
        <PathGraph
          start={start}
          end={end}
          size={size}
          sample={path.sample}
          data={path.data}
        />
      )}
    </Box>
  );
}
