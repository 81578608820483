import { Button, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import moment from "moment";
import ColumnDiv from "../../../../../../../ReusableComponents/ColumnDiv";
import DateRangePickerWrapper from "../../../../../../../ReusableComponents/DateRangePicker";
import ModuleConfigSection from "../../../../../../../ReusableComponents/ModuleConfigSection";
import MultiSelect from "../../../../../../../ReusableComponents/MultiSelect";
import { SET_APP_EVENTS, SET_FILTERS_DATA, TIME_SLOTS } from "../../constants";
import { useTracked } from "../../store";
import EventBuilder from "../../../../../../../ReusableComponents/EventBuilder";
import { useCommonDashboardStyles } from "../../../../../../../common/constants";
import { getEventsAPI } from "../../../../../../../common/actions";
import { ContainsOperators } from "../../../../../../../../constants";
import { useAuth } from "../../../../../../../../contexts/AuthContext";
import ActionsTextField from "../../../../../../../ReusableComponents/ActionsTextField";

export default function FilterDialog({ handleCancel, handleDone }) {
  const auth = useAuth();
  const typographyClasses = useCommonDashboardStyles();

  const [state, dispatch] = useTracked();
  const { goalEvents, appEvents = [], defaultGoalEvent } = state;

  const [dates, setDates] = useState({
    startDate: state.startDate,
    endDate: state.endDate,
  });
  const [funnelTime, setFunnelTime] = useState(state.funnelTime);
  const [goalEventList, setGoalEventList] = useState(goalEvents);

  useEffect(() => {
    if (appEvents.length < 1) {
      getEventsAPI(auth, auth.appId, {}).then((res) =>
        dispatch({
          type: SET_APP_EVENTS,
          value: res,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ColumnDiv styles={{ gap: 20 }}>
      <Typography
        className={typographyClasses.typographyHeading}
        style={{ fontSize: 25, lineHeight: "34px", letterSpacing: "0.01em" }}
      >
        Filter Analysis
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <ModuleConfigSection
            title="Duration"
            typographyClassName={typographyClasses.typographyCaption500}
            typographyStyleProps={{ marginBottom: 0 }}
          >
            <DateRangePickerWrapper
              startDate={moment(dates.startDate)}
              endDate={moment(dates.endDate).utc().startOf("day")}
              showDefaultInputIcon
              small
              handleChange={(start, end) => {
                const startDate =
                  moment(start).utc().toISOString().slice(0, 23) + "Z";
                const endDate =
                  moment(end).utc().endOf("day").toISOString().slice(0, 23) +
                  "Z";
                setDates({
                  startDate,
                  endDate,
                });
              }}
              disableAfter
            />
          </ModuleConfigSection>
        </Grid>
        <Grid item xs={6}>
          <ModuleConfigSection
            title="People who viewed goal event in"
            typographyClassName={typographyClasses.typographyCaption500}
          >
            <MultiSelect
              value={funnelTime}
              options={TIME_SLOTS}
              handleChange={(time) => {
                setFunnelTime(time);
              }}
              single
            />
          </ModuleConfigSection>
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{
              marginBottom: 16,
              fontSize: 15,
              fontWeight: 700,
              letterSpacing: "0.01em",
            }}
            className={typographyClasses.typographyHeading}
          >
            Filter by goal event
          </Typography>
          <Grid item xs={12}>
            <Typography>
              {!!defaultGoalEvent
                ? "Default Goal Event"
                : "Placeholder Goal event"}
            </Typography>
            <ActionsTextField
              disabled={true}
              value={defaultGoalEvent || "InlineTargetViewClicked"}
              onChange={() => null}
            />
          </Grid>

          <Grid container spacing={1}>
            <EventBuilder
              appId={auth.appId}
              events={appEvents}
              selectedEvents={goalEventList}
              operators={ContainsOperators}
              onQueryChange={(events) => setGoalEventList(events)}
              addButtonNode="Add another goal event"
              hideCondition={true}
              showNumbers={true}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          // className={classes.gridItem}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button variant="text" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch({
                type: SET_FILTERS_DATA,
                events: goalEventList,
                startDate: dates.startDate,
                endDate: dates.endDate,
                funnelTime,
              });
              handleDone();
            }}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </ColumnDiv>
  );
}

