import { Button, Grid, IconButton, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ArrowBackOutlined, FilterList, Refresh } from "@material-ui/icons";
import RowDiv from "../../../../../../../ReusableComponents/RowDiv";
import BootstrapTooltip from "../../../../../../../ReusableComponents/BootstrapTooltip";
import StatusIdentifier from "../../../../../../../ReusableComponents/StatusIdentifier";
import {
  CAMPAIGNS_HOLDER_TABS,
  getCampaignStatus,
  STATUS_PALETTE,
} from "../../../../../../common/utils";
import moment from "moment";
import { useCommonConfigStyles } from "../../../../../../common/components/campaigncreator/utils";
import SummaryPage from "../../../../../../common/components/campaigncreator/components/summary";
import { getCohorts } from "../../../../../../common/actions";
import { useAuth } from "../../../../../../../../contexts/AuthContext";
import CustomMaterialUIDialog from "../../../../../../../ReusableComponents/CustomMaterialUIDialog";
import { useDispatch } from "../../store";
import { SET_REFRESH } from "../../constants";
import ABVariantManualPublish from "../../../../../../common/components/ABVariantManualPublish";
import FilterDialog from "./FilterDialog";
import Divider from "@material-ui/core/Divider";

export default function AnalysisHeader({
  campaignConfig,
  designSummaryComponent,
  isWinnerPublished,
  setShowTestResults,
  showTestResults,
  isExperiment,
  isWinnerVariant,
}) {
  const auth = useAuth();
  const classes = useCommonConfigStyles();
  const history = useHistory();

  const dispatch = useDispatch();

  const [cohorts, setCohorts] = useState([]);
  const [showSummaryDialog, setShowSummaryDialog] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [showPublishDialog, setShowPublishDialog] = useState(false);

  const {
    isTestPublished = true,
    meta: { name: configName, category = "normal" },
    track: { created_at },
    validity: { start_date, end_date },
    experiment: {
      publishSettings: {
        time_settings: {
          start_date: winner_start_date,
          end_date: winner_end_date,
        } = {},
      } = {},
    } = { publishSettings: {} },
    state = CAMPAIGNS_HOLDER_TABS.DRAFTS,
  } = campaignConfig;

  useEffect(() => {
    getCohorts(auth, auth.appId).then((response) => {
      setCohorts(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid item xs>
        <RowDiv center>
          <ArrowBackOutlined
            size="small"
            color="action"
            style={{ cursor: "pointer", marginRight: 10 }}
            onClick={() => {
              history.push(`/apps/${auth.appId}/walkthroughs`, {
                isExperiment: isExperiment,
              });
            }}
          />
          <BootstrapTooltip title={configName} placement="bottom">
            <Typography
              className={classes.typographyHeading}
              style={{
                fontWeight: 500,
                fontSize: 24,
                lineHeight: "33px",
                maxWidth: 400,
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                letterSpacing: "0.01em",
              }}
            >
              {configName}
            </Typography>
          </BootstrapTooltip>
          <div style={{ marginLeft: 12 }}>
            <StatusIdentifier
              statusType={getCampaignStatus(campaignConfig)}
              palette={STATUS_PALETTE}
            />
          </div>
        </RowDiv>
        <RowDiv center>
          <Typography
            className={classes.typographyCaption}
            style={{ letterSpacing: "0.01em" }}
          >
            <span style={{ opacity: 0.6 }}>Duration :</span>{" "}
            {moment(start_date).format("DD MMM YYYY")} -{" "}
            {moment(end_date).format("DD MMM YYYY")}
          </Typography>
          <Divider
            style={{ marginLeft: 10, marginRight: 10 }}
            orientation="vertical"
            flexItem={true}
          />

          <Button
            variant="text"
            color="primary"
            onClick={() => setShowSummaryDialog(true)}
          >
            Show Summary
          </Button>
          {((isExperiment && isWinnerPublished) || isWinnerVariant) && (
            <Divider
              style={{ marginLeft: 10, marginRight: 10 }}
              orientation="vertical"
              flexItem={true}
            />
          )}

          {((isExperiment && isWinnerPublished) || isWinnerVariant) && (
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                let campaignId = isExperiment
                  ? `${campaignConfig?._id}.winner`
                  : campaignConfig?._id.replace(".winner", "");

                history.push(
                  `/apps/${auth?.appId}/walkthroughs/${campaignId}/analysis`,
                );
                history.go(0);
              }}
            >
              {isExperiment ? "Campaign Results" : "A/B Results"}
            </Button>
          )}
        </RowDiv>
      </Grid>
      <Grid
        item
        xs
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 5,
        }}
      >
        <IconButton
          style={{
            background: "#fff",
            borderRadius: 3,
            border: "1px solid #E4E7E9",
            padding: 5,
          }}
          onClick={() => {
            dispatch({
              type: SET_REFRESH,
            });
          }}
        >
          <Refresh color="primary" />
        </IconButton>
        <Button
          variant="outlined"
          style={{
            maxHeight: 40,
            background: "#fff",
            borderRadius: 3,
            border: "1px solid #E4E7E9",
          }}
          onClick={() => setShowFilterDialog(true)}
        >
          <RowDiv center styles={{ gap: 5 }}>
            <FilterList color="primary" />
            <Typography
              className={classes.typographyCaption}
              style={{ letterSpacing: "0.01em" }}
            >
              Filter
            </Typography>
          </RowDiv>
        </Button>
        {isExperiment &&
          !isWinnerPublished &&
          (state === CAMPAIGNS_HOLDER_TABS.ACTIVE ||
            state === CAMPAIGNS_HOLDER_TABS.COMPLETED) && (
            <Button
              variant="contained"
              color="primary"
              style={{
                maxHeight: 40,
                borderRadius: 3,
                padding: "8px 10px",
              }}
              onClick={() => setShowPublishDialog(true)}
              disabled={!isTestPublished}
            >
              <Typography
                className={classes.typographyCaption}
                style={{
                  letterSpacing: "0.01em",
                  color: "#fff",
                }}
              >
                Publish as Campaign
              </Typography>
            </Button>
          )}
      </Grid>
      <CustomMaterialUIDialog
        title="Campaign Summary"
        dialogContent={
          <SummaryPage
            config={campaignConfig}
            cohorts={cohorts}
            designSummaryPage={designSummaryComponent}
          />
        }
        dialogContentStyles={{
          overflowX: "hidden",
          padding: "8px 8px",
        }}
        maxWidth="lg"
        openFlag={showSummaryDialog}
        handleClose={() => setShowSummaryDialog(false)}
        onClose={() => setShowSummaryDialog(false)}
        onDialogClose={() => setShowSummaryDialog(false)}
      />
      <CustomMaterialUIDialog
        dialogContent={
          <FilterDialog
            handleCancel={() => setShowFilterDialog(false)}
            handleDone={() => {
              dispatch({
                type: SET_REFRESH,
              });
              setShowFilterDialog(false);
            }}
          />
        }
        dialogContentStyles={{
          overflow: "visible",
          marginTop: -20,
          padding: "18px 36px",
          paddingBottom: 36,
        }}
        maxWidth="md"
        openFlag={showFilterDialog}
        handleClose={() => setShowFilterDialog(false)}
        onClose={() => setShowFilterDialog(false)}
        onDialogClose={() => setShowFilterDialog(false)}
        paperStyles={{ overflow: "visible" }}
      />
      <CustomMaterialUIDialog
        dialogProps={{
          disableBackdropClick: true,
          disableEscapeKeyDown: true,
        }}
        dialogContent={
          <ABVariantManualPublish
            campaignId={campaignConfig._id}
            campaignConfig={campaignConfig}
            handleDone={() => {
              setShowPublishDialog(false);
              history.push(`/apps/${auth.appId}/walkthroughs`, {
                isExperiment: isExperiment,
              });
            }}
            handleCancel={() => setShowPublishDialog(false)}
          />
        }
        dialogContentStyles={{
          overflowX: "hidden",
          padding: "8px 8px",
          paddingTop: 4,
        }}
        maxWidth="xs"
        openFlag={showPublishDialog}
        handleClose={() => setShowPublishDialog(false)}
        onClose={() => setShowPublishDialog(false)}
        onDialogClose={() => setShowPublishDialog(false)}
      />
    </Grid>
  );
}
