import React from "react";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

export const NPSTextResponses = ({ data, buttonText }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        {buttonText ? (
          buttonText
        ) : (
          <>
            &nbsp;<strong>{buttonText}</strong> &nbsp;
          </>
        )}
        {data.length > 0 ? (
          <div style={{ margin: 16 }}>
            <List dense style={{ maxHeight: 320, overflowY: "auto" }}>
              {data.map(eachResponse => (
                <ListItem button disableGutters>
                  <ListItemText primary={eachResponse} />
                </ListItem>
              ))}
            </List>
          </div>
        ) : (
          <div style={{ margin: 16 }}>
            &nbsp;<strong>{`No Valid Responses to display`}</strong> &nbsp;
          </div>
        )}
      </Grid>
    </Grid>
  );
};
