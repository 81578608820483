import React, { useEffect } from "react";
import { MuiThemeProvider, withStyles } from "@material-ui/core/styles";

import { Link } from "react-router-dom";

import classNames from "classnames";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import { darkTheme } from "../../../../styling";
import Typography from "@material-ui/core/Typography";
import SideBar from "./components/SideBar";
import { notOnDashboard } from "../../../../utils/index";
import { styles } from "./styling/CustomCSS";

const pixelometryVersion = process.env.REACT_APP_VERSION;

const Navigation = (props) => {
  const { appId, location, classes, updateModes } = props;
  const showSidebar = false;

  useEffect((props) => {
    if (notOnDashboard(location)) {
      props.handleDrawerClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MuiThemeProvider theme={darkTheme}>
      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(
            classes.drawerPaper,
            !showSidebar && classes.drawerPaperClose,
          ),
        }}
        open={showSidebar}
      >
        <div className={classes.drawerInner}>
          <div className={classes.drawerHeader}>
            <Link to={`/apps`}>
              <img
                alt={window.apxorAppInfo.whiteLogo}
                src="/assets/img/sidebar_logo.png"
                style={{
                  maxHeight: "40px",
                }}
              />
            </Link>
          </div>
          <Divider />
          <SideBar
            appId={appId}
            updateModes={updateModes}
            {...props}
            isSidebarOpen={false}
            session={{ user: props.user }}
            handleNavigationClick={() => {
              if (props.open) {
                props.handleDrawerClose()
              }
            }}
          />
        </div>
        <Typography
          style={{
            position: "fixed",
            bottom: 0,
            padding: 5,
            marginLeft: 20,
          }}
          variant="caption"
        >
          <strong>{pixelometryVersion}</strong>
        </Typography>
      </Drawer>
    </MuiThemeProvider>
  )
};

export default withStyles(styles, { withTheme: true })(Navigation);
