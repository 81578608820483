import { Box, Button, Fab, Menu, Typography } from "@material-ui/core"
import { MuiThemeProvider, withStyles } from "@material-ui/core/styles"
import DescriptionIcon from "@material-ui/icons/DescriptionOutlined"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import Apxor from "apxor"
import classNames from "classnames"
import moment from "moment"
import React, { useEffect, useState } from "react"
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom"
import {
  DEMO_ECOMMERCE_APP_ID,
  isExperienceDashboard,
  isSuper,
} from "../../config"
import {
  APXOR_SDK_CATEGORY_CONSTANTS,
  FEATURE_KEY_ENUM,
  ON_APP_STATE_CHANGE,
} from "../../constants"
import { DASHBOARD_ENDPOINTS } from "../../constants/Endpoints"
import { useAuth } from "../../contexts/AuthContext"
import { isAccessable } from "../../utils"
import { useDeepCompareMemo } from "../../utils/use-deep-compare"
import { ApxEventHandler } from "../Dashboard/common/ApxEventHandler"
import {
  AppApproval,
  CustomReports,
  Dashboard,
  EventAnalysis,
  EventsManagement,
  Funnels,
  IAM,
  Paths,
  Preprocess,
  Push,
  Retention,
  Segments,
  Surveys,
  TestDevices,
  Uninstalls,
  UserData,
  UserExplorer,
  Walkthroughs,
} from "../Dashboard/components"
import Cohorts from "../Dashboard/components/cohorts"
import GeneralSettings from "../Dashboard/components/settings/general"
import SDKSettings from "../Dashboard/components/settings/sdk"
import User from "../Dashboard/components/userexplorer/userAnalysis"
import Error404 from "../Error/404"
import ColumnDiv from "../ReusableComponents/ColumnDiv"
import Loading from "../ReusableComponents/Loading"
import Snackbar from "../ReusableComponents/Snackbar"
import { getAppDataAPI } from "../common/actions"
import { AppHeader } from "./components"
import ContactUsDialog from "./components/ContactUsDialog"
import Navigation from "./components/Navigation"
import NavigationNew from "./components/NavigationNew"
import { Provider } from "./store"
import { apxTheme, globalStyles } from "./styling/CustomCSS"

const FEATURE_ROUTE_MAPPER = (app, path) => [
  {
    name: FEATURE_KEY_ENUM.COHORTS,
    path: `${path}/${DASHBOARD_ENDPOINTS.COHORTS}`,
    enabled: isAccessable(app, FEATURE_KEY_ENUM.COHORTS),
    component: <Cohorts app={app} />,
  },
  {
    name: FEATURE_KEY_ENUM.DASHBOARD,
    path: `${path}/${DASHBOARD_ENDPOINTS.DASHBOARD}`,
    enabled: isAccessable(app, FEATURE_KEY_ENUM.DASHBOARD),
    component: <Dashboard app={app} />,
  },
  {
    name: FEATURE_KEY_ENUM.WALKTHROUGHS,
    path: `${path}/${DASHBOARD_ENDPOINTS.WALKTHROUGHS}`,
    enabled: isAccessable(app, FEATURE_KEY_ENUM.WALKTHROUGHS),
    component: <Walkthroughs app={app} />,
  },
  {
    name: FEATURE_KEY_ENUM.SURVEYS,
    path: `${path}/${DASHBOARD_ENDPOINTS.SURVEYS}`,
    enabled: isAccessable(app, FEATURE_KEY_ENUM.SURVEYS),
    component: <Surveys app={app} />,
  },
  {
    name: FEATURE_KEY_ENUM.PUSH,
    path: `${path}/${DASHBOARD_ENDPOINTS.PUSH}`,
    enabled: isAccessable(app, FEATURE_KEY_ENUM.PUSH),
    component: <Push app={app} />,
  },
  {
    name: FEATURE_KEY_ENUM.EVENT_ANALYSIS,
    path: `${path}/${DASHBOARD_ENDPOINTS.EVENT_ANALYSIS}`,
    enabled: isAccessable(app, FEATURE_KEY_ENUM.EVENT_ANALYSIS),
    component: <EventAnalysis app={app} />,
  },
  {
    name: FEATURE_KEY_ENUM.RETENTION,
    path: `${path}/${DASHBOARD_ENDPOINTS.RETENTION}`,
    enabled: isAccessable(app, FEATURE_KEY_ENUM.RETENTION),
    component: <Retention app={app} />,
  },
  {
    name: FEATURE_KEY_ENUM.PATH,
    path: `${path}/${DASHBOARD_ENDPOINTS.PATH}`,
    enabled: isAccessable(app, FEATURE_KEY_ENUM.PATH),
    component: <Paths app={app} />,
  },
  {
    name: FEATURE_KEY_ENUM.SEGMENTS,
    path: `${path}/${DASHBOARD_ENDPOINTS.SEGMENTS}`,
    enabled: isAccessable(app, FEATURE_KEY_ENUM.SEGMENTS),
    component: <Segments app={app} />,
  },
  {
    name: FEATURE_KEY_ENUM.UNINSTALLS,
    path: `${path}/${DASHBOARD_ENDPOINTS.UNINSTALLS}`,
    enabled: isAccessable(app, FEATURE_KEY_ENUM.UNINSTALLS),
    component: <Uninstalls app={app} />,
  },
  {
    name: FEATURE_KEY_ENUM.USER_EXPLORER,
    path: `${path}/${DASHBOARD_ENDPOINTS.USER_EXPLORER}`,
    enabled: isAccessable(app, FEATURE_KEY_ENUM.USER_EXPLORER),
    component: <UserExplorer app={app} />,
  },
  {
    name: FEATURE_KEY_ENUM.USERS,
    path: `${path}/${DASHBOARD_ENDPOINTS.USERS}`,
    enabled: isAccessable(app, FEATURE_KEY_ENUM.USER_EXPLORER),
    component: <User app={app} />,
  },
  {
    name: FEATURE_KEY_ENUM.FUNNELS,
    path: `${path}/${DASHBOARD_ENDPOINTS.FUNNELS}`,
    enabled: isAccessable(app, FEATURE_KEY_ENUM.FUNNELS),
    component: <Funnels app={app} />,
  },
  {
    name: FEATURE_KEY_ENUM.CUSTOM_REPORTS,
    path: `${path}/${DASHBOARD_ENDPOINTS.CUSTOM_REPORTS}`,
    enabled: isAccessable(app, FEATURE_KEY_ENUM.CUSTOM_REPORTS),
    component: <CustomReports app={app} />,
  },
  {
    name: FEATURE_KEY_ENUM.PREPROCESS,
    path: `${path}/${DASHBOARD_ENDPOINTS.PREPROCESS}`,
    enabled: isAccessable(app, FEATURE_KEY_ENUM.PREPROCESS),
    component: <Preprocess app={app} />,
  },
  {
    name: FEATURE_KEY_ENUM.DASHBOARD,
    path: `${path}/${DASHBOARD_ENDPOINTS.DASHBOARD}`,
    enabled: isAccessable(app, FEATURE_KEY_ENUM.DASHBOARD),
    component: <Dashboard app={app} />,
  },
  {
    name: FEATURE_KEY_ENUM.DASHBOARD,
    path: `${path}/${DASHBOARD_ENDPOINTS.DASHBOARD}`,
    enabled: isAccessable(app, FEATURE_KEY_ENUM.DASHBOARD),
    component: <Dashboard app={app} />,
  },
  {
    name: FEATURE_KEY_ENUM.DASHBOARD,
    path: `${path}/${DASHBOARD_ENDPOINTS.DASHBOARD}`,
    enabled: isAccessable(app, FEATURE_KEY_ENUM.DASHBOARD),
    component: <Dashboard app={app} />,
  },
  {
    name: FEATURE_KEY_ENUM.DASHBOARD,
    path: `${path}/${DASHBOARD_ENDPOINTS.DASHBOARD}`,
    enabled: isAccessable(app, FEATURE_KEY_ENUM.DASHBOARD),
    component: <Dashboard app={app} />,
  },
]

const ApplicationFrame = (props) => {
  const {
    classes,
    children,
    isNavbarEnabled = true,
    isAppsPage = false,
  } = props

  const auth = useAuth()
  const [open, setOpen] = useState(false)
  const [collapsed, setCollapsed] = useState(true)
  const { path } = useRouteMatch()

  const {
    user: { email },
  } = auth

  const state = props?.location?.state
  const [app, setApp] = useState(state?.app)

  const isAdmin = useDeepCompareMemo(() => {
    return app && (email === app.basic_info.customer_id || isSuper(email))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, app])

  const [isFailed, setFailed] = useState(false)
  const [pendingAppAPI, setPendingAppAPI] = useState(false)

  useEffect(() => {
    const fetchApps = () => {
      setFailed(false)
      setPendingAppAPI(true)
      // FIXME: Once the #636 is fixed, re-visit this and verify
      getAppDataAPI(auth, props.match.params.appId)
        .then((response) => {
          // Check if the app in params exists
          setApp(response)
          auth.setAppId(response.app_id)
          setPendingAppAPI(false)
        })
        .catch(() => {
          setFailed(true)
          setPendingAppAPI(false)
        })
    }
    if (!isAppsPage && (!state || !state.app)) {
      fetchApps()
    } else if (state?.app) {
      auth.setAppId(state.app.app_id)
    }
    const onAppStateChangeSubscriber = ApxEventHandler.subscribe(
      ON_APP_STATE_CHANGE,
      fetchApps,
    )

    return () => {
      try {
        onAppStateChangeSubscriber.unsubscribe()
      } catch (e) {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (app) {
      Apxor.setUserProperties({
        timeZone: app?.settings?.timezone || "UTC",
        userId: email,
      })
    }
  }, [app, email])

  const handleDrawerOpen = () => {
    setOpen(true)
    setCollapsed(false)
  }

  const handleDrawerClose = () => {
    setOpen(false)
    setCollapsed(true)
  }

  const demoPeriodDaysLeft =
    isExperienceDashboard() && auth?.user?.demo_period_end_date
      ? Math.ceil(
          moment(auth?.user?.demo_period_end_date).diff(
            moment(new Date().toUTCString()),
            "days",
            true,
          ),
        )
      : 0

  const isAuthenticated = useDeepCompareMemo(
    () => auth?.user?.email && auth?.user?.email !== "",
    [auth],
  )

  const { appId } = useParams()
  const { pathname } = useLocation()
  const path_arr = pathname.split("/")
  const nudge_id = ["edit", "analysis"].some((x) => path_arr.at(-1) === x)
    ? path_arr.at(-2)
    : ""

  const [anchorEl, setAnchorEl] = useState(null)
  const [contactUsDialogOpen, setContactUsDialogOpen] = useState(false)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [contactUsMessage, setContactUsMessage] = useState("")

  function HelpMenu() {
    return (
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            boxShadow: "none",
          },
        }}
        MenuListProps={{
          style: {
            padding: "0px",
            backgroundColor: "#002845",
          },
        }}
        style={{
          boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.15)",
          borderRadius: "4px",
          display: "relative",
        }}
      >
        <Box>
          <ColumnDiv
            styles={{
              alignItems: "flex-start",
              gap: "14px",
              padding: "16px",
              backgroundColor: "#002845",
              maxWidth: "220px",
            }}
          >
            <Button
              startIcon={
                <DescriptionIcon
                  color="secondary"
                  style={{
                    marginLeft: "-1px",
                    marginRight: "-1px",
                  }}
                />
              }
              variant="text"
              size="small"
              component="a"
              target="_blank"
              href="https://guides.apxor.com/"
              rel="noopener noreferrer"
              onClick={handleClose}
            >
              <Typography
                style={{
                  color: "#fff",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                Documentation
              </Typography>
            </Button>
            <Button
              startIcon={<MailOutlineIcon color="secondary" />}
              variant="text"
              size="small"
              onClick={() => {
                setContactUsDialogOpen(true)
                Apxor.logEvent(
                  "ContactUsClicked",
                  {
                    "nudge_id": nudge_id,
                    "app_id" : appId,
                  },
                  APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS,
                )
                handleClose()
              }}
            >
              <Typography
                style={{
                  color: "#fff",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                Contact Us
              </Typography>
            </Button>
          </ColumnDiv>
        </Box>
      </Menu>
    )
  }

  const [drawerOpen, setDrawerOpen] = useState(false)

  const handleMouseEnter = () => {
    setDrawerOpen(true)
  }

  const handleMouseLeave = () => {
    setDrawerOpen(false)
  }

  return (
    <MuiThemeProvider theme={apxTheme}>
      <Provider>
        <div className={classes.root}>
          <div className={classes.appFrame}>
            {isAuthenticated && (
              <>
                {isExperienceDashboard() && (
                  <AppHeader
                    {...props}
                    appState={app}
                    open={open}
                    pendingAppAPI={pendingAppAPI}
                    handleDrawerOpen={handleDrawerOpen}
                    handleDrawerClose={handleDrawerClose}
                    setContactUsDialogOpen={setContactUsDialogOpen}
                  />
                )}
                {isNavbarEnabled && app && (
                  <>
                    {isExperienceDashboard() ? (
                      <Navigation
                        {...props}
                        appState={app}
                        open={open}
                        handleDrawerOpen={handleDrawerOpen}
                        handleDrawerClose={handleDrawerClose}
                        user={auth?.user}
                        loggedIn={isAuthenticated}
                      />
                    ) : (
                      <NavigationNew
                        {...props}
                        appState={app}
                        open={drawerOpen}
                        user={auth?.user}
                        handleMouseEnter={handleMouseEnter}
                        handleMouseLeave={handleMouseLeave}
                      />
                    )}
                  </>
                )}
                {!pendingAppAPI && app && (isNavbarEnabled || open) && (
                  <main
                    className={classNames(
                      classes.content,
                      !collapsed && classes.pinnedContent,
                    )}
                  >
                    <Switch>
                      {isExperienceDashboard() && demoPeriodDaysLeft === 0 && (
                        <Redirect
                          to={`/apps/${DEMO_ECOMMERCE_APP_ID}/dashboard`}
                        />
                      )}
                      <Route
                        path={`${path}/${DASHBOARD_ENDPOINTS.SETTINGS_TEST_DEVICES}`}
                      >
                        <TestDevices app={app} />
                      </Route>
                      <Route
                        exact
                        path={`${path}/${DASHBOARD_ENDPOINTS.SETTINGS_GENERAL}`}
                      >
                        {isAdmin && <GeneralSettings app={app} />}
                        {!isAdmin && <Redirect to="/unauthorised" />}
                      </Route>
                      <Route
                        exact
                        path={`${path}/${DASHBOARD_ENDPOINTS.SETTINGS_SDK}`}
                      >
                        {isExperienceDashboard() && (
                          <Redirect
                            to={`/apps/${DEMO_ECOMMERCE_APP_ID}/dashboard`}
                          />
                        )}
                        {isAdmin && <SDKSettings app={app} />}
                        {!isAdmin && <Redirect to="/unauthorised" />}
                      </Route>
                      <Route
                        exact
                        path={`${path}/${DASHBOARD_ENDPOINTS.SETTINGS_IAM}`}
                      >
                        {isExperienceDashboard() && (
                          <Redirect
                            to={`/apps/${DEMO_ECOMMERCE_APP_ID}/dashboard`}
                          />
                        )}
                        {isAdmin && <IAM app={app} />}
                        {!isAdmin && <Redirect to="/unauthorised" />}
                      </Route>
                      <Route
                        path={`${path}/${DASHBOARD_ENDPOINTS.SETTINGS_EM}`}
                      >
                        {isExperienceDashboard() && (
                          <Redirect
                            to={`/apps/${DEMO_ECOMMERCE_APP_ID}/dashboard`}
                          />
                        )}
                        <EventsManagement app={app} />
                      </Route>
                      <Route
                        path={`${path}/${DASHBOARD_ENDPOINTS.SETTINGS_DATA}`}
                      >
                        {isExperienceDashboard() && (
                          <Redirect
                            to={`/apps/${DEMO_ECOMMERCE_APP_ID}/dashboard`}
                          />
                        )}
                        <UserData app={app} />
                      </Route>

                      {FEATURE_ROUTE_MAPPER(app, path)
                        .filter(
                          (eachFeatureObject) => eachFeatureObject.enabled,
                        )
                        .map((eachFeatureObject) => (
                          <Route
                            path={eachFeatureObject.path}
                            key={eachFeatureObject.path}
                          >
                            {eachFeatureObject.component}
                          </Route>
                        ))}
                      <Route
                        path="*"
                        component={Error404}
                      />
                    </Switch>
                    <HelpMenu />
                    <Fab
                      aria-label="help"
                      size="large"
                      onClick={handleClick}
                      style={{
                        backgroundColor: "#002845",
                        color: "#fff",
                        position: "fixed",
                        bottom: "20px",
                        right: "20px",
                      }}
                    >
                      <Typography
                        color="secondary"
                        style={{
                          fontSize: "30px",
                        }}
                      >
                        ?
                      </Typography>
                    </Fab>
                    <ContactUsDialog
                      open={contactUsDialogOpen}
                      onClose={() => setContactUsDialogOpen(false)}
                      setContactUsMessage={setContactUsMessage}
                    />
                    {contactUsMessage !== "" && (
                      <Snackbar
                        onClose={() => {
                          setContactUsMessage("")
                        }}
                      >
                        <strong>{contactUsMessage}</strong>
                      </Snackbar>
                    )}
                  </main>
                )}
                {app && !isNavbarEnabled && (
                  <Switch>
                    <Route
                      exact
                      path={`${path}/approve`}
                    >
                      {isAdmin && <AppApproval app={app} />}
                      {!isAdmin && <Redirect to="/unauthorised" />}
                    </Route>
                    <Route
                      path="*"
                      component={Error404}
                    />
                  </Switch>
                )}
                {!isAppsPage && !isFailed && pendingAppAPI && (
                  <div
                    style={{
                      position: "fixed",
                      top: "50%",
                      left: "50%",
                    }}
                  >
                    <Loading size={48} />
                  </div>
                )}
                {!isAppsPage && isFailed && !pendingAppAPI && (
                  <Redirect to="/unauthorised" />
                )}
              </>
            )}
            {children}
          </div>
        </div>
      </Provider>
    </MuiThemeProvider>
  )
}

export default withStyles(globalStyles, { withTheme: true })(ApplicationFrame)
