import { Box, Button, Grid, Tab, Tabs, Typography } from "@material-ui/core";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import ClockIcon from "@material-ui/icons/ScheduleOutlined";
import React, { lazy, Suspense, useCallback, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import androidLogo from "../../../../Application/Dashboard/common/components/assets/android.png";
import iosLogo from "../../../../Application/Dashboard/common/components/assets/ios.png";
import webLogo from "../../../../Application/Dashboard/common/components/assets/web.png";
import { useAuth } from "../../../../contexts/AuthContext";
import { useDeepCompareEffect } from "../../../../utils/use-deep-compare";
import { getAppDataAPI } from "../../../common/actions";
import Loading from "../../../ReusableComponents/Loading";
import { SET_BUNDLE_ID, SET_FCM_KEY } from "../../constants";
import IntegrationLayout from "../../IntegrationLayout";
import { useDispatch, useTrackedState } from "../../store";
import CopyContent from "../components/CopyContent";

const IntegrateByMySelfSection = lazy(() =>
  import("../by-myself/IntegrateByMySelfSection"),
);
const InviteDevsSection = lazy(() =>
  import("../invite-devs/InviteDevsSection"),
);

const TAB_HEADERS = ["Integrate by MySelf", "Invite Developers"];

export default function IntegratePage() {
  const auth = useAuth();
  const { app_loading } = useTrackedState();
  const dispatch = useDispatch();
  const { auth_loading } = auth;
  const { push } = useHistory();
  const { appId } = useParams();

  const [developers, setDevelopers] = useState([]);
  const [appData, setAppData] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [progress, setProgress] = useState(0);
  const [openInviteDevelopersDialog, setOpenInviteDevelopersDialog] = useState(
    false,
  );

  useDeepCompareEffect(() => {
    (async () => {
      const appData = await getAppDataAPI(auth, appId);
      setAppData(appData);
      dispatch({
        type: SET_BUNDLE_ID,
        payload: appData?.basic_info?.bundle_id ?? "",
      });
      dispatch({
        type: SET_FCM_KEY,
        payload: appData?.basic_info?.fcm_key ?? "",
      });
      setDevelopers(appData?.basic_info?.customers ?? []);
    })();
  }, [appId, dispatch, auth]);

  const handleIntegrationComplete = useCallback(async () => {
    push(`/apps/${appId}/test-connection`);
  }, [appId, push]);

  return (
    <IntegrationLayout>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "30px",
          }}
        >
          <Typography
            style={{
              fontWeight: "700",
              fontSize: "24px",
            }}
          >
            Integrate
          </Typography>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={
                appData?.basic_info?.platform === "android"
                  ? androidLogo
                  : appData?.basic_info?.platform === "ios"
                  ? iosLogo
                  : webLogo
              }
              height="18px"
              style={{
                marginRight: "6px",
              }}
              alt={`${appData?.basic_info?.platform} icon`}
            />
            <Typography
              style={{
                fontWeight: "500",
                fontSize: "13px",
                marginRight: "7px",
              }}
            >
              {appData?.basic_info?.app_name}
            </Typography>
            <Typography
              style={{
                fontWeight: "600",
                fontSize: "13px",
                opacity: "0.5",
                marginRight: "4px",
              }}
            >
              App ID
            </Typography>
            <CopyContent
              copyText={appId}
              styles={{
                position: "unset",
                fontSize: "20px",
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            borderBottom: "1px solid rgba(0, 40, 69, 0.15)",
          }}
        >
          <Tabs
            value={selectedTab}
            textColor="primary"
            indicatorColor="primary"
            onChange={(_, value) => {
              setSelectedTab(value);
            }}
          >
            {TAB_HEADERS.map((label, index) => (
              <Tab
                disableRipple
                key={`${label}-${index}`}
                label={label}
                style={{
                  textTransform: "none",
                  paddingBottom: "10px",
                  paddingLeft: "0px",
                  marginLeft: "-5px",
                  marginRight: "15px",
                }}
              />
            ))}
          </Tabs>
          {selectedTab === 1 && developers?.length > 0 && (
            <Button
              size="large"
              variant="contained"
              color="primary"
              startIcon={<PersonAddOutlinedIcon />}
              onClick={() => setOpenInviteDevelopersDialog(true)}
              style={{
                fontWeight: "600",
                fontSize: "14px",
              }}
              disabled={auth_loading}
            >
              Invite
            </Button>
          )}
        </Grid>
        <Grid item xs={12}>
          <Suspense
            fallback={
              <Box
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Loading size={25} />
              </Box>
            }
          >
            {selectedTab === 0 && (
              <IntegrateByMySelfSection
                progress={progress}
                setProgress={setProgress}
              />
            )}
            {selectedTab === 1 && (
              <InviteDevsSection
                developers={developers}
                setDevelopers={setDevelopers}
                owner={appData?.basic_info?.customer_id}
                openInviteDevelopersDialog={openInviteDevelopersDialog}
                setOpenInviteDevelopersDialog={setOpenInviteDevelopersDialog}
              />
            )}
          </Suspense>
        </Grid>
      </Grid>
      <Box
        style={{
          marginTop: "65px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",
        }}
      >
        <Button
          disabled={selectedTab === 0 ? progress !== 7 : false}
          color="primary"
          size="large"
          variant="contained"
          style={{
            textDecoration: "none",
            color: "white",
          }}
          onClick={handleIntegrationComplete}
        >
          {app_loading ? (
            <Loading size={28} color="#FFFFFF" />
          ) : (
            "Integration Complete"
          )}
        </Button>
        {selectedTab === 1 && (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <ClockIcon
              color="primary"
              style={{
                fontSize: "16px",
                marginRight: "4px",
              }}
            />
            <Typography
              style={{
                fontSize: "12px",
                fontWeight: "400",
                opacity: 0.6,
              }}
            >
              Waiting for integration to complete...
            </Typography>
          </Box>
        )}
      </Box>
    </IntegrationLayout>
  );
}
