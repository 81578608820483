import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import React from "react";
import MultiSelect from "../../../../../ReusableComponents/MultiSelect";
import { useTracked } from "../store";

const RowsLimitOptions = [5, 10, 25, 50];

function pageRowLimits(pageIndex, limit, count) {
  let from = count === 0 ? 0 : pageIndex * limit + 1;
  let to =
    count !== -1
      ? Math.min(count, (pageIndex + 1) * limit)
      : (pageIndex + 1) * limit;

  return `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`;
}

export default function PaginationController({
  handleRowsPerPageChange,
  fetchResults,
  disabled,
}) {
  const [state, dispatch] = useTracked();

  const {
    pagination: { after, before, limit, pageCounter, totalResults },
  } = state;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "8px 12px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginRight: 8,
        }}
      >
        <Typography style={{ margin: "0px 8px" }}>Results per page</Typography>
        <MultiSelect
          disabled={disabled || pageCounter > 1}
          style={{ width: 100 }}
          isSearchable={false}
          single
          value={limit.toString()}
          options={RowsLimitOptions.map((item) => ({
            label: item.toString(),
            value: item.toString(),
          }))}
          itemName={"RowsLimit"}
          handleChange={(value) => {
            handleRowsPerPageChange("limit", parseInt(value));
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: 8,
        }}
      >
        <IconButton
          onClick={() => {
            fetchResults(null, before, -1);
          }}
          disabled={disabled || !before}
          aria-label="previous page"
        >
          <KeyboardArrowLeft fontSize={"small"} />
        </IconButton>
        <Typography style={{ margin: "0px 8px" }}>
          {pageRowLimits(pageCounter - 1, limit, totalResults)}
        </Typography>
        <IconButton
          onClick={() => {
            fetchResults(after, null, +1);
          }}
          disabled={
            disabled || (pageCounter - 1) * limit + limit >= totalResults
          }
          aria-label="next page"
        >
          <KeyboardArrowRight fontSize={"small"} />
        </IconButton>
      </div>
    </div>
  );

}