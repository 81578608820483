import {
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import {
  DEMO_ECOMMERCE_APP_ID,
  isExperienceDashboard,
} from "../../../../../../config"
import { useAuth } from "../../../../../../contexts/AuthContext"
import useMutateRouterState from "../../../../../../utils/use-mutate-router-state"
import Loading from "../../../../../ReusableComponents/Loading"
import {
  getCampaignsAPI,
  getCohorts,
  getDemoCampaignsAPI,
} from "../../../actions"
import { CAMPAIGNS_HOLDER_TABS, getCampaignStatus } from "../../../utils"
import { EmptyStateImage } from "../assets/EmptyStateImage"
import { DEMO_TEMPLATE_CONFIG_TYPES } from "../constants"
import {
  PAGINATION_ACTION,
  SET_BEFORE_AFTER,
  SET_CAMPAIGNS,
  UPDATE_PAGE_COUNTER,
} from "../reducer"
import { useTracked } from "../store"
import { getNameForActionType } from "../utils"
import CampaignCard from "./CampaignCard"
import PaginationController from "./PaginationController"
import CampaignPublishedDialog from "./sales-sandbox/CampaignPublishedDialog"

import Apxor from "apxor"
const useStyles = makeStyles((theme) => ({
  tabTitle: (props) => ({
    display: "flex",
    justifyContent: "left",
    fontWeight: 600,
    size: 14,
    lineHeight: "19.12px",
    opacity: "80%",
  }),
  tabTitleSecondary: (props) => ({
    display: "flex",
    justifyContent: "center",
    fontWeight: 600,
    size: 14,
    lineHeight: "19.12px",
    opacity: "80%",
  }),
}))

function descendingComparator(a, b, orderBy) {
  function resolveKeyPath(path, dataObj) {
    return path.split(".").reduce((prevObj, currObj) => {
      return prevObj ? prevObj[currObj] : null
    }, dataObj)
  }
  const key = (function () {
    switch (orderBy) {
      case "name":
        return "meta.name"
      case "platform":
        return "meta.platform"
      case "start":
        return "validity.start_date"
      case "end":
        return "validity.end_date"
      case "status":
        return
      case "reached":
      default:
        return "validity.start_date"
    }
  })()

  const right = resolveKeyPath(key, a)
  const left = resolveKeyPath(key, b)

  /**
   * The commented logic sorts asciibetically
   */
  // if (left < right) {
  //   return -1;
  // }
  // if (left > right) {
  //   return 1;
  // }
  // return 0;
  return left.localeCompare(right)
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const handleSort = (column, order, setOrderBy, setOrder) => {
  setOrder(order === "asc" ? "desc" : "asc")
  setOrderBy(column)
}

const getSelectedTabName = (selectedTab) => {
  return CAMPAIGNS_HOLDER_TABS[Object.keys(CAMPAIGNS_HOLDER_TABS)[selectedTab]]
}

export default function CampaignsListing({
  app,
  actionType,
  configType,
  designSummaryComponent,
  generalSummaryComponent,
  query,
  refreshCampaigns = null,
  title,
}) {
  const { app_id: appId } = app

  const auth = useAuth()
  const classes = useStyles()
  const [state, dispatch] = useTracked()
  const { state: browserState } = useLocation()
  const { clearStateFields } = useMutateRouterState()
  const {
    campaigns,
    selectedTab,
    campaignKind,
    pagination: { pageCounter, limit, after, before, totalResults },
  } = state

  const [filteredCampaigns, setFilteredCampaigns] = useState(campaigns)
  const [cohorts, setCohorts] = useState([])
  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("name")
  const [pending, setPending] = useState(false)
  const [count, setRefreshCount] = useState(0)
  const [showCampaignPublishedDialog, setShowCampaignPublishedDialog] =
    useState(browserState?.postPublish ?? false)

  const closeCampaignPublishedDialog = useCallback(() => {
    setShowCampaignPublishedDialog(false)
    if (browserState?.postPublish) {
      clearStateFields(["postPublish", "campaignId"])
    }
    Apxor.logEvent("PublishPopUpClosed")
  }, [browserState, clearStateFields])

  const categoryWord =
    getSelectedTabName(selectedTab) === CAMPAIGNS_HOLDER_TABS.DRAFTS
      ? "Draft"
      : getSelectedTabName(selectedTab)

  const isGuidedToursTab = useMemo(() => {
    return (
      isExperienceDashboard() &&
      getSelectedTabName(selectedTab).toUpperCase() ===
        CAMPAIGNS_HOLDER_TABS.GUIDED_TOUR
    )
  }, [selectedTab])

  function fetchCampaigns(
    afterId = null,
    beforeId = null,
    updatedPageCounter = null,
  ) {
    const currentPageCounter = updatedPageCounter ?? pageCounter
    let additionalParams = {}
    if (afterId) additionalParams = { ...additionalParams, after: afterId }
    else if (beforeId)
      additionalParams = { ...additionalParams, before: beforeId }
    if (query !== "") {
      additionalParams = { ...additionalParams, name: query }
    }
    setPending(true)
    if (isGuidedToursTab) {
      getDemoCampaignsAPI(auth, DEMO_ECOMMERCE_APP_ID, {
        configType:
          actionType === "walkthroughs"
            ? DEMO_TEMPLATE_CONFIG_TYPES.WALKTHROUGHS
            : DEMO_TEMPLATE_CONFIG_TYPES.SURVEYS,
      })
        .then((response) => {
          setPending(false)
          dispatch({
            type: SET_CAMPAIGNS,
            value: response?.data ?? [],
          })
        })
        .catch((err) => {
          setPending(false)
          dispatch({
            type: SET_CAMPAIGNS,
            value: [],
          })
        })
    } else {
      getCampaignsAPI(auth, appId, {
        configType,
        kind: campaignKind,
        state: getSelectedTabName(selectedTab).toUpperCase(),
        limit: limit,
        ...additionalParams,
      })
        .then((response) => {
          setPending(false)
          dispatch({
            type: SET_CAMPAIGNS,
            value: response.messages || [],
            total: response.total,
          })
          if (response.messages) {
            const messages = response.messages

            if (currentPageCounter <= 1) {
              dispatch({
                type: SET_BEFORE_AFTER,
                after:
                  messages[messages.length - 1]?.pagination_id ||
                  messages[messages.length - 1]?._id,
                before: null,
              })
            } else if (currentPageCounter === Math.ceil(totalResults / limit)) {
              dispatch({
                type: SET_BEFORE_AFTER,
                before: messages[0]?.pagination_id || messages[0]?._id,
                after: null,
              })
            } else {
              dispatch({
                type: SET_BEFORE_AFTER,
                before: messages[0]?.pagination_id || messages[0]?._id,
                after:
                  messages[messages.length - 1]?.pagination_id ||
                  messages[messages.length - 1]?._id,
              })
            }
          }
        })
        .catch((err) => {
          setPending(false)
          dispatch({
            type: SET_CAMPAIGNS,
            value: [],
          })
          dispatch({
            type: SET_BEFORE_AFTER,
            before: null,
            after: null,
          })
        })
    }
  }

  useEffect(() => {
    getCohorts(auth, appId).then((response) => {
      setCohorts(response)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch({
      type: SET_BEFORE_AFTER,
      before: null,
      after: null,
    })
    fetchCampaigns(null, null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, selectedTab, campaignKind, limit, query])

  // useEffect(() => {
  //   setFilteredCampaigns(
  //     stableSort(filteredCampaigns, getComparator(order, orderBy))
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [order, orderBy]);

  useEffect(() => {
    setFilteredCampaigns(campaigns)
  }, [campaigns])

  return (
    <div>
      {filteredCampaigns.length === 0 && !pending && (
        <div
          style={{
            padding: 36,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: 200,
              height: 200,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "#3e50b52e",
              borderRadius: "100%",
            }}
          >
            <EmptyStateImage />
          </div>
          <Typography
            color="textSecondary"
            variant="h6"
            style={{ marginTop: 32 }}
          >
            {`You have no ${categoryWord} ${getNameForActionType(actionType)}`}
          </Typography>
        </div>
      )}
      {filteredCampaigns.length > 0 && (
        <Card
          style={{
            marginTop: 25,
            marginBottom: 8,
            border: "1px solid #e4eaec",
          }}
          className={classes.root}
          elevation={0}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <div className={classes.tableWrapper}>
            <Table>
              <thead style={{ border: "unset" }}>
                <TableRow style={{ border: "unset" }}>
                  <TableCell
                    colSpan={8}
                    style={{
                      border: "unset",
                      color: "#002845",
                      fontWeight: 600,
                      fontSize: 14,
                      opacity: 0.45,
                    }}
                  >
                    <Grid
                      container
                      justify={"space-evenly"}
                      alignItems={"center"}
                    >
                      <Grid
                        item
                        xs={1}
                        style={{
                          textAlign: "left",
                          paddingRight: 25,
                        }}
                      >
                        S.No.
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{
                          textAlign: "left",
                          overflowWrap: "anywhere",
                        }}
                      >
                        Campaign Name
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{ textAlign: "center" }}
                      >
                        Platform
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{ textAlign: "left" }}
                      >
                        Start
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{ textAlign: "left" }}
                      >
                        End
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        Status
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Actions
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </thead>
              <TableBody>
                {filteredCampaigns.length > 0 &&
                  filteredCampaigns.map((campaignConfig, index) => (
                    <CampaignCard
                      key={`key-${index}-${campaignConfig._id}`}
                      id={index + 1}
                      appId={appId}
                      actionType={actionType}
                      campaignConfig={campaignConfig}
                      cohorts={cohorts}
                      designSummaryComponent={designSummaryComponent}
                      refreshCampaigns={() => setRefreshCount(count + 1)}
                      statusType={getCampaignStatus(campaignConfig)}
                      pageCounter={pageCounter}
                      limit={limit}
                      isGuidedToursTab={isGuidedToursTab}
                    />
                  ))}
              </TableBody>
            </Table>
          </div>
          {!isGuidedToursTab && (
            <PaginationController
              campaigns={campaigns}
              disabled={pending}
              handleRowsPerPageChange={(key, value) => {
                dispatch({
                  type: PAGINATION_ACTION,
                  payload: { key, value },
                })
              }}
              fetchResults={(afterId, beforeId, direction) => {
                const updatedPageCounter =
                  direction > 0 ? pageCounter + 1 : pageCounter - 1
                dispatch({
                  type: UPDATE_PAGE_COUNTER,
                  payload: {
                    pageCounter: updatedPageCounter,
                  },
                })

                fetchCampaigns(afterId, beforeId, updatedPageCounter)
              }}
            />
          )}
        </Card>
      )}
      {pending && (
        <div style={{ position: "fixed", top: "50%", left: "50%" }}>
          <Loading size={36} />
        </div>
      )}
      {showCampaignPublishedDialog && (
        <CampaignPublishedDialog
          auth={auth}
          open={showCampaignPublishedDialog}
          onClose={closeCampaignPublishedDialog}
          actionType={actionType}
          campaignId={browserState?.campaignId}
        />
      )}
    </div>
  )
}
