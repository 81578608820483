import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { Cell } from "fixed-data-table-2";
import styled from "styled-components";
import { COUNT_TYPE_ENUM } from "../../../../../constants";
import { toTitleCase } from "../../../../../utils";
import SortableDataTable from "../../../../ReusableComponents/SortableDataTable";
import { useRouteMatch } from "react-router-dom";
import { useAuth } from "../../../../../contexts/AuthContext";
import Loading from "../../../../ReusableComponents/Loading";

const UNKNOWN_KEY = "UnKnown";

function headerLabelFormatter(header) {
  if (header === COUNT_TYPE_ENUM.impressions) return "Total Events";
  return toTitleCase(header);
}

const StyledAnchorTag = styled.a`
  text-decoration: underline !important;
  color: #3c8dbc;
  cursor: pointer;
`;

function ExplorerTable({
  metrics,
  aggregations,
  data,
  handleValueClick,
  isValueSelectable,
  boxProps,
  params,
}) {
  const { url } = useRouteMatch();
  const urlWithAppId = url.replace("/apps/", "");

  const auth = useAuth();

  return (
    <div style={{ width: "100%" }}>
      <SortableDataTable
        downloadParams={{
          appId: urlWithAppId.substring(0, urlWithAppId.indexOf("/")),
          auth: auth,
          ...params,
        }}
        boxProps={boxProps}
        data={data}
        tableType={"explorer"}
        headerLabels={[
          aggregations.name,
          ...metrics.map((m) => {
            if (["users", "sessions"].includes(m))
              return headerLabelFormatter(m);
            return `${headerLabelFormatter(m)} (${aggregations[m] || "NA"})`;
          }),
        ]}
        keys={["name", ...metrics]}
        TextCell={({ rowIndex, data, col, ...props }) => {
          const value = data[rowIndex][col];
          return (
            <Cell {...props}>
              {col === "name" ? (
                isValueSelectable && value !== UNKNOWN_KEY ? (
                  <StyledAnchorTag
                    onClick={(e) => {
                      e.preventDefault();
                      handleValueClick(value);
                    }}
                  >
                    {value}
                  </StyledAnchorTag>
                ) : (
                  value
                )
              ) : (
                value
              )}
            </Cell>
          );
        }}
        downloadReportTitle={`Explorer-${new Date().toDateString()}`}
      />
    </div>
  );
}

export default function Explorer({
  dimensions,
  setDimensions,
  metrics,
  filters,
  queryParams,
  explorer: { aggregations = [], data = [] },
  fetching,
}) {
  const [selectedDimensions, setSelectedDimensions] = useState(
    dimensions.slice(0, 1)
  );

  console.log(dimensions);
  console.log(selectedDimensions);

  useEffect(() => {
    if (dimensions.length > 0) {
      setSelectedDimensions(dimensions.slice(0, 1));
    }
  }, [dimensions]);

  const handleDimensionWithValue = (value) => {
    const length = selectedDimensions.length;
    if (dimensions.length > length) {
      const dimension = { ...selectedDimensions[length - 1] };
      dimension.value = value;
      const newSelectedDimensions = [
        ...selectedDimensions.slice(0, -1),
        dimension,
        ...dimensions.slice(length, length + 1),
      ];
      setSelectedDimensions(newSelectedDimensions);
      setDimensions(newSelectedDimensions);
    }
  };

  const handleDimensionWithDimensionIndex = (index, isDimension = false) => {
    let newSelectedDimensions = [...selectedDimensions.slice(0, index + 1)];
    if (isDimension) {
      newSelectedDimensions[index].value = undefined;
    } else {
      newSelectedDimensions = [...newSelectedDimensions, dimensions[index + 1]];
    }
    setSelectedDimensions(newSelectedDimensions);
    setDimensions(newSelectedDimensions);
  };

  const getBreadCrumbs = (selectedDimensions) => (
    <Typography>
      {selectedDimensions.map((dim, index) => {
        if (index === selectedDimensions.length - 1) {
          return <span key={dim.name}>{dim.name}</span>;
        } else {
          return (
            <span key={dim.name}>
              <StyledAnchorTag
                onClick={(e) => handleDimensionWithDimensionIndex(index, true)}
              >
                {dim.name}
              </StyledAnchorTag>
              &nbsp;&gt;&nbsp;
              {dim.value && <span>({dim.value})</span>}
              {dim.value && <span>&nbsp;&gt;&nbsp;</span>}
            </span>
          );
        }
      })}
    </Typography>
  );

  const isValueSelectable = selectedDimensions.length < dimensions.length;

  if (fetching) {
    return (
      <section className="content">
        <Loading />
      </section>
    );
  } else {
    return (
      <div style={{ width: "100%" }}>
        {selectedDimensions.length > 0 && (
          <ExplorerTable
            metrics={metrics}
            params={{
              dimensions: selectedDimensions,
              filters: filters,
              queryParams: queryParams,
            }}
            aggregations={aggregations}
            data={data}
            isValueSelectable={isValueSelectable}
            boxProps={{
              title: getBreadCrumbs(selectedDimensions),
            }}
            handleValueClick={handleDimensionWithValue}
          />
        )}
      </div>
    );
  }
}
