import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MultiUtilityChart from "../../../../../../ReusableComponents/MultiUtilityChart";
import Box from "../../../../../../ReusableComponents/Box";
import RadioButtonChecked from "@material-ui/icons/RadioButtonChecked";
import CheckBox from "@material-ui/icons/CheckBox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { ANSWER_MODES } from "../../design/constants";
import { OtherOptionResults } from "./OtherOptionResults";

export const ChoiceQuestion = ({
  id,
  title,
  responses = [],
  number,
  answer_mode,
  choices = [],
  others,
}) => {
  const otherOptionChoiceIndex = choices.findIndex(o => o.is_other);
  const options = responses.map(item => item.value);
  const modifiedResponses = responses.map((x, index) => ({
    ...x,
    value: `Option-${index + 1}`,
  }));

  return (
    <Box
      icon={
        answer_mode === ANSWER_MODES.single_choice ? (
          <RadioButtonChecked />
        ) : (
          <CheckBox />
        )
      }
      title={title}
      footer={
        otherOptionChoiceIndex > -1 && (
          <OtherOptionResults
            {...choices[otherOptionChoiceIndex]}
            questionId={id}
          />
        )
      }
    >
      <Grid container>
        <Grid item xs={9} spacing={1}>
          <MultiUtilityChart
            xType="number"
            yType="category"
            xLabelFormatter={l => l}
            layout="vertical"
            withoutAggregations
            height={modifiedResponses.length >= 10 ? 600 : 300}
            dataKey="count"
            yLabel={"Choice"}
            xLabel="Responses"
            data={modifiedResponses}
            barDataKeys={["count"]}
            fullWidth
            yDataKey="value"
          />
        </Grid>
        <Grid item xs={3}>
          <Typography variant={"h6"}>Legend</Typography>
          <List
            style={{
              maxHeight: 300,
              overflowY: "scroll",
              borderRadius: 4,
              border: "1px solid #bbc9d5",
              padding: 0,
            }}
          >
            {options.map((option, index) => (
              <ListItem>
                <ListItemText
                  primary={option}
                  secondary={`Option ${index + 1}`}
                  style={{ display: "flex", flexDirection: "column-reverse" }}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Box>
  );
};
