/* eslint-disable no-useless-escape */
import moment from "moment";
import shortid from "shortid";
import {
  APP_ID_FOR_DEMO,
  DEMO_APP_ID,
  IOS_DEVICE_MAP,
  isSuper,
} from "../config";
import {
  API_QUERY_PARAMS,
  DASHBOARD_ENDPOINTS,
  NON_DASHBOARD_ENDPOINTS,
} from "../constants/Endpoints";
import logger from "./Logger";
import {
  USER_GROUP_FILTERS_CONSTANTS,
  OTHERS_KEY,
  FEATURE_KEY_ENUM,
  ANALYTICS,
  ACTIONS,
} from "../constants";
import {
  yellow,
  green,
  orange,
  purple,
  red,
  blue,
  brown,
  teal,
  grey,
  amber,
  cyan,
  deepOrange,
  deepPurple,
  indigo,
  pink,
  lime,
  blueGrey,
  lightGreen,
} from "@material-ui/core/colors";
import $ from "jquery";

/**
 *
 * @param customers
 * @param user
 * @returns {boolean}
 */
export const isUserRestricted = (customers = [], user) => {
  if (isSuper(user)) {
    return false;
  }
  for (let i = 0; i < customers.length; i++) {
    const { customer_id, limited_access = false } = customers[i];
    if (customer_id === user && limited_access) {
      //FIXME: Need better evaluation for restriction, may Realm will do
      return true;
    }
  }
  return false;
};

/**
 * checks whether a value is defined
 * @param value {*}
 * @param strict {Boolean}
 * @returns {Boolean}
 */
export function isDefined(value, strict = true) {
  if (!strict && (value === 0 || value === "")) return true; //FIXME: handling 0 values
  return value !== null && typeof value !== "undefined";
}

/**
 *
 * @param url
 * @param appId
 * @returns {*}
 */
const MUTATION_URL_REGEX = /messages|art-configs|notifications|additional-customers/;

export const changeForDemoApp = (url = "", appId) => {
  if (appId === DEMO_APP_ID && !MUTATION_URL_REGEX.test(url)) {
    return APP_ID_FOR_DEMO;
  } else {
    return appId;
  }
};

/**
 * returns URL with appId and userId as QueryStrings
 * @param url
 * @param auth
 * @param appId
 * @returns {string}
 */
export function makeDefaultQueryString(url, auth, appId) {
  let withQP = `${url}?${API_QUERY_PARAMS.customerId}=${auth.user.email}`;
  if (isDefined(appId)) {
    withQP += auth
      ? `&${API_QUERY_PARAMS.appId}=${changeForDemoApp(url, appId)}`
      : `${API_QUERY_PARAMS.appId}=${changeForDemoApp(url, appId)}`;
  }
  return withQP;
}

/**
 *
 * @param variable
 * @returns {boolean}
 */
function isArray(variable) {
  return isDefined(variable) && Array.isArray(variable);
}

/**
 *
 * @param path {string}
 * @param exclusionContent {Array}
 * @returns {boolean|Boolean}
 */
export function isDateFilterApplicable(path, exclusionContent) {
  return (
    isArray(exclusionContent) &&
    isDefined(path) &&
    exclusionContent.every((endPoint) => !Boolean(path.match(endPoint)))
  );
}

/**
 *
 * @param path
 * @param inclusionContent
 * @returns {boolean|Boolean}
 */
export function isDateFilterDisabled(path, inclusionContent) {
  return (
    isArray(inclusionContent) &&
    isDefined(path) &&
    inclusionContent.some((endPoint) => Boolean(path.match(endPoint)))
  );
}

/**
 *
 * @param arrA
 * @param arrB
 * @returns {*}
 */
export const areArraysEqual = (arrA, arrB) => {
  if (Array.isArray(arrA) && Array.isArray(arrB)) {
    return (
      arrA.length === arrB.length &&
      arrA.every((o) => arrB.includes(o)) &&
      arrB.every((o) => arrA.includes(o))
    );
  }
  return false;
};

/**
 * Calculating starting of day with IST offset added
 * @param moment
 * @param inUTC
 * @returns {Date}
 */
export const normalizedDate = (moment, inUTC = true, isStart = true) => {
  if (inUTC) {
    return isStart
      ? moment.utc().startOf("day").toISOString()
      : moment.utc().endOf("day").toISOString();
  }
  const timeZoneOffset = new Date().getTimezoneOffset();
  return moment.startOf("day").add(-timeZoneOffset, "m").toDate().toISOString();
};

export const FONT_FAMILY_REGULAR =
  '"Manrope", "Karla Regular", "Roboto", "Helvetica", "Arial", sans-serif';
export const FONT_FAMILY_BOLD =
  '"Manrope", "Karla Bold", "Roboto", "Helvetica", "Arial", sans-serif';

export const notOnDashboard = (location) => {
  for (let i = 0; i < NON_DASHBOARD_ENDPOINTS.length; i++) {
    if (location.pathname === `/${DASHBOARD_ENDPOINTS.CREATE}`) return true; //FIXME: need proper check
    if (location.pathname === `/${DASHBOARD_ENDPOINTS.APPS}`) return true; //FIXME: need proper check
    if (location.pathname === `/${DASHBOARD_ENDPOINTS.PROFILE}`) return true;
    if (location.pathname === `/${DASHBOARD_ENDPOINTS.INT_EXP}`) return true;
    if (location.pathname === `/${DASHBOARD_ENDPOINTS.RESET_PASSWORD}`)
      return true; //FIXME: need proper check
    if (location.pathname.indexOf(NON_DASHBOARD_ENDPOINTS[i]) > -1) {
      return true;
    }
  }
  return false;
};

function normalizeAttributeKeys(key) {
  //server query params
  if (USER_GROUP_FILTERS_CONSTANTS.hasOwnProperty(key)) {
    switch (key) {
      case USER_GROUP_FILTERS_CONSTANTS.app_versions:
        return "versions";
      case USER_GROUP_FILTERS_CONSTANTS.os_versions:
        return "os_version";
      case USER_GROUP_FILTERS_CONSTANTS.acquisition_sources:
        return "acquisition";
      case USER_GROUP_FILTERS_CONSTANTS.location:
        return "country";
      default:
        return key;
    }
  } else {
    return key;
  }
}

/**
 * make query Strings for API
 * @param queryObject
 * @returns {string}
 */
export function withQueryStrings(queryObject = {}) {
  let queryStrings = "";
  if (isDefined(queryObject, false)) {
    for (let query in queryObject) {
      if (queryObject.hasOwnProperty(query)) {
        logger.debug(query, queryObject[query]);
        if (isArray(queryObject[query])) {
          queryStrings += queryObject[query]
            .map((item) => `&${normalizeAttributeKeys(query)}=${item}`)
            .join("");
          logger.debug(query, queryObject[query], queryStrings);
        } else if (
          isString(queryObject[query]) ||
          isNumber(queryObject[query])
        ) {
          queryStrings += `&${query}=${queryObject[query]}`;
        } else {
          //FIXME: Not sure of this
          //logger.debug("Undefined Key", query, "for Object", queryObject);
          queryStrings += withQueryStrings(queryObject[query]);
        }
      }
    }
  } else {
    //logger.debug("Undefined Keys", queryObject);
  }
  return queryStrings;
}

function isString(variable) {
  return isDefined(variable) && typeof variable === "string";
}

export function isNumber(variable) {
  return isDefined(variable, false) && !isNaN(variable);
}

export function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

export const roundOffNumber = (number, toDigits = 2, defaultNumber = 0) => {
  return isNaN(number)
    ? defaultNumber
    : isFloat(number)
    ? Number(number.toFixed(toDigits))
    : number;
};

/**
 *
 * @param timestamp
 * @param format
 * @param defaultValue
 * @returns {*}
 */
export function formatTime(
  timestamp,
  format = "MMM Do YYYY, h:mm:ss a",
  defaultValue = "NA"
) {
  if (!isDefined(timestamp)) return defaultValue;
  return moment(timestamp).format(format);
}

export function formatNumber(anyNumber, fixed = 2) {
  if (isNaN(anyNumber)) return anyNumber;
  const number = roundOffNumber(anyNumber); //rounding number to 2 decimals
  return Math.abs(Number(number)) >= 1.0e9
    ? (Math.abs(Number(number)) / 1.0e9).toFixed(fixed) + " B"
    : // Six Zeroes for Millions
    Math.abs(Number(number)) >= 1.0e6
    ? (Math.abs(Number(number)) / 1.0e6).toFixed(fixed) + " M"
    : // Three Zeroes for Thousands
    Math.abs(Number(number)) >= 1.0e3
    ? (Math.abs(Number(number)) / 1.0e3).toFixed(fixed) + " K"
    : Math.abs(Number(number));
}

const REMAINING_COLORS = () => {
  const colors = [
    lime,
    deepOrange,
    lightGreen,
    deepPurple,
    cyan,
    pink,
    indigo,
    blueGrey,
  ];
  const shades = [200, 500, 700, 900];
  return shades.map((shade) => colors.map((color) => color[shade])).flat(1);
};

const COLORS = [
  blue[900],
  blue[500],
  red[500],
  green[500],
  orange[500],
  yellow[900],
  purple[500],
  teal[500],
  amber[500],
  brown[500],
  grey[500],
  ...REMAINING_COLORS(),
];

export function randomColorWithIndex(index, colors = COLORS) {
  return colors[index % colors.length];
}

export function dataWithColoredSegments(data) {
  return data.map((item, index) => {
    item.fill = randomColorWithIndex(index);
    return item;
  });
}

export function makeDonutData(data) {
  if (isArray(data)) {
    const remaining = data.slice(9);
    const topData = [...data.slice(0, 9)];
    if (data.length > 9) {
      topData.push({
        key: OTHERS_KEY,
        value: remaining.reduce((a, b) => a + b.value, 0),
      });
    }
    return {
      mainData: dataWithColoredSegments(topData), //top 10
      remainData: remaining,
    };
  } else {
    return {
      mainData: [],
      remainData: [],
    };
  }
}

export const hexToRGBA = (hex, a = 0.5, defaultHex = hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16
      )}, ${a})`
    : defaultHex;
};

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function capitalizeEachWord(text, onlyFirstWord = false) {
  if (!isDefined(text)) return "";
  if (onlyFirstWord) return toTitleCase(text);
  return text
    .split(" ")
    .map((word) => toTitleCase(word))
    .join(" ");
}

export function removeCharsWith(
  text,
  originalChar,
  newChar,
  defaultValue = ""
) {
  if (!isDefined(text)) return defaultValue;
  return text.split(originalChar).join(newChar);
}

export function onlyAlphaNumericChars(text = "") {
  return text.replace(/[\W]+/g, "");
}

export const sortArrayByKey = (arr = [], key, reverse = false) => {
  const f = reverse ? -1 : 1;
  if (isDefined(key)) {
    return arr.sort((a, b) => (a[key] > b[key] ? f : a[key] < b[key] ? -f : 0));
  } else {
    return arr;
  }
};

export function addedWithAverageKey(data, valueKey = null) {
  if (!Array.isArray(data)) return [];
  const average = data.reduce((a, b) => a + b.value, 0) / data.length;
  return data.map((item) => {
    let obj = { ...item };
    obj["avg_" + valueKey] = average.toFixed(2); //for calculating value
    if (valueKey) {
      obj[valueKey] = item.value;
      delete obj.value;
    }
    return obj;
  });
}

export const toTimeSpent = (number, toDigits = 2) => {
  function inTime(n, text) {
    return roundOffNumber(number / n, toDigits) + ` ${text} `;
  }
  switch (true) {
    case number / 86400 > 1:
      return inTime(86400, "Days");
    case number / 3600 > 1:
      return inTime(3600, "Hours");
    case number / 60 > 1:
      return inTime(60, "Minutes");
    default:
      return inTime(1, "Seconds");
  }
};

export const mergeTimeSeries = (all, event, valueKey) => {
  if (!Array.isArray(all) || !Array.isArray(event)) return [];
  event = addedWithAverageKey([...event], valueKey);
  if (all.length === 0 || all.length !== event.length) return event;
  return all.map((item, index) => {
    for (let key in event[index]) {
      if (event[index].hasOwnProperty(key) && key !== "key") {
        item[key] = event[index][key];
      }
    }
    return item;
  });
};

export function getGroupFromList(groups, groupId) {
  if (groups === null || typeof groups === "undefined" || groups.length === 0)
    return null;
  for (let i = 0; i < groups.length; i++) {
    if (groups[i]._id === groupId) {
      return groups[i];
    }
  }
  // logger.error("No group found from given Groups for groupId:", groupId);
  return null;
}

export function goToByScroll(id) {
  const domEl = $("#" + id);
  if (domEl.offset()) {
    $("html, body").animate({ scrollTop: domEl.offset().top - 60 }, "slow");
  }
}

export function getPercent(of = 0, from = 1, rounded = 2, defaultValue = "NA") {
  if (isNaN(of) || isNaN(from) || from === 0) return defaultValue;
  return roundOffNumber((of / from) * 100, rounded);
}

export function getGroupNameFromList(groups, groupId) {
  if (groups === null || typeof groups === "undefined" || groups.length === 0)
    return null;
  for (let i = 0; i < groups.length; i++) {
    if (groups[i]._id === groupId) {
      return groups[i].group_name || groups[i].name;
    }
  }
  return "No Name";
}

export function minMaxAverageOf(arr = [], key, defaultKey = "key") {
  if (Array.isArray(key)) {
    return key.reduce((a, b) => {
      a[b] = minMaxAverageOf(arr, b, defaultKey);
      return a;
    }, {});
  } else {
    const obj = {};
    obj.avg = arr.reduce((a, b) => a + b[key], 0) / arr.length;
    obj.max = arr.reduce((a, b) => Math.max(a, b[key]), 0);
    obj.min = arr.reduce((a, b) => Math.min(a, b[key]), Infinity);
    const minIndex = arr.findIndex((o) => o[key] === obj.min);
    const maxIndex = arr.findIndex((o) => o[key] === obj.max);
    if (isNumber(minIndex) && minIndex > -1) {
      obj.minKey = arr[minIndex][defaultKey];
    }
    if (isNumber(maxIndex) && maxIndex > -1) {
      obj.maxKey = arr[maxIndex][defaultKey];
    }
    return obj;
  }
}

export const isValidEmail = (text = "") => /\S+@\S+\.\S+/.test(text);

export function copyToClipboard(text = "") {
  const input = document.createElement("input");
  document.body.appendChild(input);
  input.value = text;
  input.select();
  document.execCommand("copy");
  input.remove();
}

export const shuffledArray = (inputArr = []) => {
  if (Array.isArray(inputArr)) {
    const shuffled = inputArr.slice();
    for (let i = shuffled.length - 1; i > 0; i--) {
      const rand = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[rand]] = [shuffled[rand], shuffled[i]]; //swapping
    }
    return shuffled;
  }
  return inputArr;
};

shortid.characters(
  "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_"
);

export default function generateShortID(
  separator = "-",
  repeat = 1,
  prefix = "",
  suffix = ""
) {
  let shortID_repeated = shortid.generate().replace(/[-_]/, "x");
  for (let val = 0; val < repeat; val++) {
    shortID_repeated +=
      separator + shortid.generate().toString().replace(/[-_]/, "x");
  }
  return prefix + shortID_repeated + suffix;
}

export const replaceMacrosWithDefault = (text = "") => {
  if (isString(text)) {
    // FIXME: Have an eye on this, '?' indicates match as less as possible
    const updated = text.replace(/\[.+?\((.*?)\)\]/g, "$1");
    return updated.replace(
      /apx_evaluate\(Script[\-\w\W]{10}\)/g,
      "DynamicText"
    );
  } else {
    return text;
  }
};

export const getPropertiesOfSegment = (segments, segment_id, category) => {
  let pickedSegmentsList = segments.filter((o) => o._id === segment_id);
  return pickedSegmentsList.length > 0 ? pickedSegmentsList[0][category] : [];
};

/**
 * downloads JSON object as JSON file
 * @param JSONData
 * @param fileName
 */
export function downloadJSONAsJSONFile(JSONData, fileName) {
  downloadAsFile(JSON.stringify(JSONData, null, 2), fileName, "json");
}

export const downloadAsFile = (data, fileName, extension) => {
  let uri = "data:text/" + extension + ";charset=utf-8," + escape(data); //encodeURIComponent(data);
  let link = document.createElement("a");
  link.href = uri;
  link.style = "visibility:hidden";
  link.download = fileName + "." + extension;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * return time from now
 * @param time
 * @returns {*}
 */
export function displayTimeFromNow(time) {
  //TODO: use this function to all
  if (!time || time === "NA") return "NA";
  return moment(time).fromNow();
}

/**
 * checks whether a property exists and if the value is defined
 * @param object {Object}
 * @param property {String}
 * @returns {Boolean}
 */
export function hasAndIsDefined(object, property) {
  return object.hasOwnProperty(property) && isDefined(object[property]);
}

/**
 *
 * @param device
 * @returns {*}
 */
export function readableDeviceName(device) {
  return IOS_DEVICE_MAP.hasOwnProperty(device)
    ? IOS_DEVICE_MAP[device]
    : device;
}

/**
 *
 * @param defaultSortIndexes
 * @param sortedDataList
 * @param columnKey
 * @param sortDir
 * @param SortTypes
 * @returns {*}
 */
export function sortSessionList(sortedDataList, columnKey, sortDir, SortTypes) {
  return sortedDataList.sort((elem1, elem2) => {
    let valueA = elem1[columnKey];
    let valueB = elem2[columnKey];
    let sortVal = 0;
    if (valueA > valueB) {
      sortVal = 1;
    }
    if (valueA < valueB) {
      sortVal = -1;
    }
    if (sortVal !== 0 && sortDir === SortTypes.ASC) {
      sortVal = sortVal * -1;
    }
    return sortVal;
  });
}

/**
 *
 * @param time
 * @param timestamp
 * @param defaultValue
 * @return as format of hh:mm AM
 */
export function formatWithAddedTime(time, timestamp, defaultValue = "NA") {
  if (!isDefined(time, false) || !isDefined(timestamp)) return defaultValue;
  return moment(timestamp).add(time, "seconds").format("LT");
}

export function isAccessable(appState, featureKey) {
  if (!appState || appState?.features.length <= 0) return false;
  const appFeatures = appState?.features || [];
  switch (featureKey) {
    case FEATURE_KEY_ENUM.ANALYTICS:
      return ANALYTICS.map((eachFeature) =>
        appFeatures.indexOf(eachFeature) > -1 ? true : false
      ).reduce((initialTruth, eachTruth) => {
        return initialTruth || eachTruth;
      }, false);

    case FEATURE_KEY_ENUM.ACTIONS:
      return ACTIONS.map((eachFeature) =>
        appFeatures.indexOf(eachFeature) > -1 ? true : false
      ).reduce((initialTruth, eachTruth) => {
        return initialTruth || eachTruth;
      }, false);
    default:
      return appFeatures.indexOf(featureKey) > -1 ? true : false;
  }
}

export function getDefaultPage(appState) {
  if (!appState || appState?.features.length <= 0) return false;
  const appFeatures = appState?.features || [];
  if (appFeatures.indexOf(FEATURE_KEY_ENUM.EVENT_ANALYSIS) > -1)
    return "event-analysis";
  else if (appFeatures.indexOf(FEATURE_KEY_ENUM.SEGMENTS)) return "segments";
}

