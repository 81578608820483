import React, { useState, useEffect } from "react";
import Box from "../../../../ReusableComponents/Box";
import Snackbar from "../../../../ReusableComponents/Snackbar";
import Switch from "../../../../ReusableComponents/Switch";
import {
  DATA_DISPLAY_FORMAT_ENUM,
  SET_DATA_DISPLAY_FORMAT,
} from "../constants";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import { useTracked } from "../store";
import { RetentionTableBody } from "./RetentionTableBody";
import Placeholder from "../../../../ReusableComponents/Placeholder";
import Loading from "../../../../ReusableComponents/Loading";

const DEFAULTS = {
  DEFAULT_SWITCH_DATA: [
    {
      name: "Days",
      value: "Day",
    },
    {
      name: "Weeks",
      value: "Week",
    },
    {
      name: "Months",
      value: "Month",
    },
  ],
  DEFAULT_TITLE: "Retention analysis",
  DEFAULT_DATE_DISPLAY_FORMAT: "MMM DD YYYY",
  DEFAULT_SWITCH_VALUE: "Days",
  DEFAULT_DATA_DISPLAY_FORMAT: "percent",
  DEFAULT_VALUE: "7 Days",
};

export const RetentionGraph = ({
  appId,
  title,
  dateFilters,
  pending,
  failed,
}) => {
  const [state, dispatch] = useTracked();
  const [dataNotAvailable, setDataNotAvailable] = useState(false);
  const {
    retentionData,
    retention_header,
    dateDisplayFormat,
    enableTooltip,
    dataDisplayFormat,
    switchValue,
  } = state;
  const isDataAvailable =
    retentionData && Object.keys(retentionData).length > 0; //FIXME: Need proper check than this.

  useEffect(() => {
    dispatch({
      type: SET_DATA_DISPLAY_FORMAT,
      value: DATA_DISPLAY_FORMAT_ENUM.PERCENT,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retentionData]);

  const getDataDisplayFormat = (value) => {
    dispatch({
      type: SET_DATA_DISPLAY_FORMAT,
      value: value,
    });
  };

  const handleToggleClick = (format) => {
    dispatch({
      type: SET_DATA_DISPLAY_FORMAT,
      value: format,
    });
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <Box
          title={title || DEFAULTS.DEFAULT_TITLE}
          icon={<PeopleOutlineIcon />}
          withPadding
          controls={
            <Switch
              handleChange={handleToggleClick}
              data={[
                { name: "#", value: DATA_DISPLAY_FORMAT_ENUM.VALUE },
                { name: "%", value: DATA_DISPLAY_FORMAT_ENUM.PERCENT },
              ]}
              value={dataDisplayFormat}
              containerStyles={{ margin: 0, minWidth: 120 }}
              groupStyles={{ minWidth: 10 }}
            />
          }
        >
          {!pending && !failed && isDataAvailable && (
            <RetentionTableBody
              dataDisplayFormat={
                dataDisplayFormat || DEFAULTS.DEFAULT_DATA_DISPLAY_FORMAT
              }
              switchValue={switchValue || DEFAULTS.DEFAULT_SWITCH_VALUE}
              inputDateFormat={null}
              header={retention_header}
              dateDisplayFormat={
                dateDisplayFormat || DEFAULTS.DEFAULT_DATE_DISPLAY_FORMAT
              }
              enableTooltip={enableTooltip || false}
              getDataDisplayFormat={getDataDisplayFormat}
              dateFilters={dateFilters}
            />
          )}
          {failed && <Placeholder />}
          {pending && <Loading />}
          <Snackbar
            open={dataNotAvailable}
            message="Data not available..!"
            autoHideDuration={4000}
            onRequestClose={() => setDataNotAvailable(false)}
          />
        </Box>
      </div>
    </div>
  );
};
