/**
 * Created by jyothi on 17/12/16.
 */
import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";

function ResponsiveDialog({
  title,
  status,
  handleClose = () => null,
  handleConfirm = () => null,
  noActions = false,
  confirmLabel = "Ok",
  fullScreen,
  children,
  allowCancelLabel = false,
  dialogProps = {},
}) {
  return (
    <div>
      <Dialog
        scroll="body"
        fullScreen={fullScreen}
        open={status}
        onClose={() => handleClose(false)}
        {...dialogProps}
      >
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>{children}</DialogContent>
        {!noActions && (
          <DialogActions>
            {allowCancelLabel && (
              <Button onClick={() => handleClose(false)} color="primary">
                {allowCancelLabel}
              </Button>
            )}
            {confirmLabel && (
              <Button
                onClick={() => handleConfirm(true)}
                color="primary"
                autoFocus
              >
                {confirmLabel}
              </Button>
            )}
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}

ResponsiveDialog.propTypes = {
  title: PropTypes.string,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  status: PropTypes.bool.isRequired,
  confirmLabel: PropTypes.string,
  fullScreen: PropTypes.bool,
  noActions: PropTypes.bool,
  allowCancelLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  dialogProps: PropTypes.object,
};

export default withMobileDialog()(ResponsiveDialog);
