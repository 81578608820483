import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography/Typography";
import TextField from "@material-ui/core/TextField/TextField";
import MultiSelect from "../../../../ReusableComponents/MultiSelect";

const INCLUDE_OPTIONS = [
  { label: "Include", value: "F" },
  { label: "Exclude", value: "T" },
];

const REGEX_OPTIONS = [
  { label: "Exact", value: "F" },
  { label: "Regex", value: "T" },
];

function Filter({
  index,
  attributes,
  without = false,
  property = "",
  query = "",
  regex = false,
  handleUpdate,
  handleRemove,
}) {
  const [state, setState] = useState({
    without: without,
    property: property,
    query: query,
    regex: regex,
  });

  const updateState = (key) => (value) => {
    const updatedState = {
      ...state,
      [key]: value,
    };
    setState(updatedState);
    handleUpdate(index, updatedState);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={2} md={1}>
          <MultiSelect
            options={INCLUDE_OPTIONS}
            value={state.without ? "T" : "F"}
            handleChange={(flag) => updateState("without")(flag === "T")}
            single
          />
        </Grid>
        <Grid item xs={3}>
          <MultiSelect
            options={attributes}
            single
            handleChange={updateState("property")}
            value={state.property}
          />
        </Grid>
        <Grid item xs={2} md={1}>
          <MultiSelect
            options={REGEX_OPTIONS}
            value={state.regex ? "T" : "F"}
            handleChange={(flag) => updateState("regex")(flag === "T")}
            single
          />
        </Grid>
        <Grid item xs={4} md={3}>
          <TextField
            value={state.query}
            onChange={(e) => updateState("query")(e.target.value)}
            placeholder={"Property Value"}
            fullWidth
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={() => handleRemove(index)}>
            <RemoveIcon color="error" />
          </IconButton>
        </Grid>
      </Grid>
      <Typography variant="caption">AND</Typography>
    </>
  );
}

export default function Filters({ attributes, filters, setFilters }) {
  const handleChange = (index, obj) => {
    const updatedFilters = [
      ...filters.slice(0, index),
      obj,
      ...filters.slice(index + 1),
    ];
    setFilters(updatedFilters);
  };

  const handleAddFilter = () => {
    const updatedFilters = [
      ...filters,
      { without: false, property: "", query: "", regex: false },
    ];
    setFilters(updatedFilters);
  };

  const handleRemoveFilter = (index) => () => {
    const updatedFilters = [
      ...filters.slice(0, index),
      ...filters.slice(index + 1),
    ];
    setFilters(updatedFilters);
  };

  return (
    <div style={{ margin: "16px 0" }}>
      {filters.map((filter, index) => (
        <Filter
          key={filter.property + index}
          index={index}
          attributes={attributes}
          {...filter}
          handleUpdate={handleChange}
          handleRemove={handleRemoveFilter(index)}
        />
      ))}
      <Button variant="text" onClick={handleAddFilter}>
        <AddIcon />
        &nbsp;Add Filter
      </Button>
    </div>
  );
}
