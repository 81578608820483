import React, { Fragment, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { getPercent, randomColorWithIndex } from "../../../../../../../utils";
import MultiUtilityChart from "../../../../../../ReusableComponents/MultiUtilityChart";
import Box from "../../../../../../ReusableComponents/Box";
import StarRate from "@material-ui/icons/StarRate";
import { useTrackedState } from "../store";
import { POST_BODY } from "../constants";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { getEventAttributeDistributionAPI } from "../../../../eventanalysis/actions";
import { useAuth } from "../../../../../../../contexts/AuthContext";
import { RATING_ACTION_LABELS } from "../../design/constants";
import { NPSTextResponses } from "./NPSTextResponses";

const getQuestion = (questions, qid) => {
  for (let question of questions) {
    if (question.id === qid) return question;
  }
  return null;
};

const getData = (response, question) => {
  if (question && question.rating_scale === 10) {
    return response.map(eachResponse => {
      return {
        id: eachResponse.id - 1,
        value: eachResponse.value - 1,
        count: eachResponse.count,
      };
    });
  } else return response;
};

const groupAndTransformResponseData = (data, question) => {
  let hash = {};
  if (question.rules) {
    for (let rule of question.rules) {
      const rangeToAdd = [...Array(rule.max - rule.min + 1).keys()].map(
        x => x + rule.min
      );
      if (question[rule.qid].type === RATING_ACTION_LABELS.QUESTION) {
        for (let eachKey of rangeToAdd)
          hash[eachKey] = {
            type: RATING_ACTION_LABELS.QUESTION,
            responses: [],
            ruleId: rule.qid,
          };
      } else {
        for (let eachKey of rangeToAdd)
          hash[eachKey] = {
            type: RATING_ACTION_LABELS.REDIRECTION,
            count: 0,
            ruleId: rule.qid,
          };
      }
    }
    for (let response of data) {
      const key = JSON.parse("[" + response.key + "]").flat();
      if (hash[key[0]])
        hash[key[0]].type === RATING_ACTION_LABELS.QUESTION
          ? key[1] && hash[key[0]].responses.push(key[1])
          : hash[key[0]].count++;
    }
    return hash;
  } else return {};
};

export const NPSQuestion = ({
  responses,
  title,
  answer_mode,
  id,
  dateFilters,
}) => {
  const auth = useAuth();
  const state = useTrackedState();
  const [question, setQuestion] = useState({});
  const [legend, setLegend] = useState([]);
  const [showOther, setShowOther] = useState(false);
  const [NPSRedirectionResponses, setNPSRedirectionResponses] = useState({});
  const [otherTextData, setOtherTextData] = useState([]);
  const {
    config: { ui: { questions } = { questions: [] } },
  } = state;

  const { total, promoters, passive, detractors } = calculateNPSSlabs(
    responses
  );

  useEffect(() => {
    setQuestion(getQuestion(questions, id));
  }, [id, questions]);

  useEffect(() => {
    if (question && question.advanced_options && dateFilters) {
      const queryParams = {
        ...dateFilters,
        group: "all_users",
        of: "users",
        attribute: "answers",
        event: "apx_survey_" + question.title,
      };
      const postBody = POST_BODY;
      getEventAttributeDistributionAPI(
        auth,
        auth.appId,
        queryParams,
        postBody,
        true
      ).then(response => {
        setNPSRedirectionResponses(
          groupAndTransformResponseData(response, question)
        );
      });
      if (question.rules) {
        setLegend(
          question.rules.map(eachRule => {
            return {
              min: eachRule.min,
              max: eachRule.max,
              type:
                question[eachRule.qid].type === RATING_ACTION_LABELS.QUESTION
                  ? RATING_ACTION_LABELS.QUESTION
                  : RATING_ACTION_LABELS.REDIRECTION,
              text:
                question[eachRule.qid].type === RATING_ACTION_LABELS.QUESTION
                  ? "Text Question"
                  : "Redirection",
              secondaryText:
                eachRule.min === eachRule.max
                  ? `Option ${
                      question.rating_scale === 10
                        ? eachRule.min - 1
                        : eachRule.min
                    }`
                  : `Option ${
                      question.rating_scale === 10
                        ? eachRule.min - 1
                        : eachRule.min
                    } to ${
                      question.rating_scale === 10
                        ? eachRule.max - 1
                        : eachRule.max
                    }`,
            };
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question, dateFilters]);

  return (
    <Box
      title={title}
      icon={<StarRate />}
      footer={
        showOther && (
          <NPSTextResponses
            data={otherTextData.filter(x => x)}
            buttonText={"Text Responses"}
            questionId={id}
          />
        )
      }
    >
      <Grid container spacing={16}>
        <Grid item xs={12} style={{ margin: 8 }}>
          <NPSSlab
            value={promoters}
            color={randomColorWithIndex(0)}
            title="Promoters"
            total={total}
          />
          <NPSSlab
            value={passive}
            color={randomColorWithIndex(3)}
            title="Passive"
            total={total}
          />
          <NPSSlab
            value={detractors}
            color={randomColorWithIndex(6)}
            title="Detractors"
            total={total}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={question && question.advanced_options ? 9 : 12}
          spacing={1}
        >
          <MultiUtilityChart
            xType="number"
            yType="category"
            xLabelFormatter={l => l}
            layout="vertical"
            withoutAggregations
            height={responses.length >= 10 ? 600 : 300}
            dataKey="count"
            yLabel={"Choice"}
            xLabel="Responses"
            data={getData(responses, question)}
            barDataKeys={["count"]}
            fullWidth
            yDataKey="value"
          />
        </Grid>
        {question && question.advanced_options && (
          <Grid item xs={3}>
            <Typography variant={"h6"}>Advanced Ratings</Typography>
            <List
              style={{
                maxHeight: 300,
                overflowY: "scroll",
                borderRadius: 4,
                border: "1px solid #bbc9d5",
                padding: 0,
                cursor: "pointer",
              }}
            >
              {legend.map(option => (
                <ListItem
                  onClick={() => {
                    if (option.type === RATING_ACTION_LABELS.QUESTION) {
                      setShowOther(true);
                      setOtherTextData(
                        Object.keys(NPSRedirectionResponses)
                          .map(eachKey => {
                            let rating = parseInt(eachKey);
                            if (rating >= option.min && rating <= option.max)
                              return NPSRedirectionResponses[eachKey].responses;
                          })
                          .flat()
                      );
                    } else {
                      setShowOther(false);
                    }
                  }}
                >
                  <ListItemText
                    primary={option.text}
                    secondary={option.secondaryText}
                    style={{
                      display: "flex",
                      flexDirection: "column-reverse",
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

const calculateNPSSlabs = (data = []) => {
  const total = data.reduce((a, b) => a + b.count, 0);
  const strengthOfValues = (values = []) =>
    data.reduce((a, b) => a + (values.includes(b.value) ? b.count : 0), 0);
  return {
    total,
    promoters: strengthOfValues([4, 5]),
    passive: strengthOfValues([3]),
    detractors: strengthOfValues([1, 2]),
  };
};

const NPSSlab = ({ total, value, color, title }) => (
  <div
    style={{
      display: "inline-block",
      width: `${getPercent(value, total)}%`,
      backgroundImage: `linear-gradient(45deg, ${color} 50%, transparent 25%, transparent 50%, ${color} 50%, ${color} 75%, transparent 75%, #fff)`,
      backgroundSize: "2px 2px",
      padding: value > 0 ? 8 : 0,
    }}
  >
    {value > 0 && (
      <Fragment>
        <Typography variant="subtitle1" style={{ color: "#FFF" }}>
          {value}
        </Typography>
        <Typography variant="subtitle1" style={{ color: "#FFF" }}>
          {title}
        </Typography>
      </Fragment>
    )}
  </div>
);
