import { makeDeleteRequest, makePostRequest } from "../../../../common/actions";

const ADDITIONAL_CUSTOMERS_API = "additional-customers";
const TRANSFER_APP_API = "apps/transfer/";

export function addCustomerAPI(auth, appId, queryParams, customerBody) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return false;
  };
  return makePostRequest(
    ADDITIONAL_CUSTOMERS_API,
    auth,
    appId,
    { ...queryParams },
    customerBody,
    onSuccessCallback,
    onFailureCallback
  );
}

export function removeCustomerAPI(auth, appId, queryParams, customerId) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return false;
  };
  return makeDeleteRequest(
    ADDITIONAL_CUSTOMERS_API,
    auth,
    appId,
    { ...queryParams, customerId },
    onSuccessCallback,
    onFailureCallback
  );
}

export function transferAppOwnershipAPI(auth, appId, customerBody) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return response || {};
  };
  return makePostRequest(
    TRANSFER_APP_API + appId,
    auth,
    appId,
    null,
    customerBody,
    onSuccessCallback,
    onFailureCallback
  );
}
