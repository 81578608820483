import { APP_PLATFORMS } from "../../../../../constants";

import React from "react";
import { AndroidLogo, AppleLogo, WebLogo } from "./assets/Logos";
import { capitalizeEachWord } from "../../../../../utils";

export const PLATFORM_IMAGES = (platform, color = "#212121", width = 32) => {
  switch (platform) {
    case APP_PLATFORMS.android:
      return <AndroidLogo color={color} width={width} />;
    case APP_PLATFORMS.ios:
      return <AppleLogo color={color} width={width} />;
    case APP_PLATFORMS.web:
      return <WebLogo color={color} width={width} />;
    case APP_PLATFORMS.omni:
      return <img alt={"OMNI_APP"} src={"assets/img/omni.png"} width={width} />;
    default:
      return "";
  }
};

export const ART_CONFIG_TYPES = {
  SURVEY: "survey",
  RTA: "rta",
  WALKTHROUGH: "walkthrough",
  ONBOARDING: "onboarding",
};

export const getNameForActionType = (actionType) => {
  return Object.values(ART_CONFIG_TYPES).some((type) => type === actionType)
    ? capitalizeEachWord(
        Object.values(ART_CONFIG_TYPES).find((each) => each === actionType) +
          "s",
      )
    : "Campaigns";
};
