import React from "react";
import styled from "styled-components";
import { Fab, Grid, withStyles } from "@material-ui/core";

const styles = () => ({
  sheetC: {
    border: 0,
    display: "inline-flex",
    outline: "none",
    padding: 0,
    position: "relative",
    alignItems: "center",
    userSelect: "none",
    verticalAlign: "middle",
    justifyContent: "center",
    textDecoration: "none",
    backgroundColor: "transparent",
    textAlign: "initial",
    minWidth: 120,
    minHeight: 120,
    margin: 12,
  },
});

function getAbsolutePosition(pos) {
  switch (pos) {
    case "top_center":
      return ` left: 50%; top: 0%; `;
    case "left":
      return ` left: 0%; top: 0%; `;
    case "center":
      return ` left: 50%; top: 50%; `;
    case "right":
    default:
      return ` left: 100%; top: 0%; `;
  }
}

function getNotificationDotStyle({ bg_color, position, animation }) {
  return `
      position: relative;
      @keyframes cycle {
          0% {
              transform: translateY(4px);
          }
          5% {
              transform: translateY(0px);
          }
          10% {
              transform: translateY(-4px);
          }
          15% {
              transform: translateY(0px);
          }
          20% {
              transform: translateY(4px);
          }
          25% {
              transform: translateY(0px);
          }
      }
      &::before {
          content: '';
          display: inline-block;
          position: absolute;
          background: ${bg_color || "red"};
          ${getAbsolutePosition(position || "right")}
          margin: -4px;
          height: 8px;
          width: 8px;
          border-radius: 8px;
          border: none;
          ${
            animation !== "none"
              ? "animation: " + animation + " 1.5s ease-in-out 0s infinite;"
              : ""
          }
      }
  `;
}

const StyledFAB = styled(Fab)`
  background-color: #fff !important;
  width: 80px !important;
  height: 80px !important;
  ${props => getNotificationDotStyle(props)}
`;

const NudgeSimulator = ({
  ui: {
    position,
    bg_color,
    nudge_config: { anim_type: animation = "none" } = { anim_type: "none" },
  },
}) => {
  return (
    <div style={{ margin: 32 }}>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item>
          <StyledFAB
            bg_color={bg_color}
            position={position}
            animation={animation}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(NudgeSimulator);
