import { Box, Divider, Typography } from "@material-ui/core";
import React from "react";
import AndroidKeysForm from "./AndroidKeysForm";
import IOSKeysForm from "./IOSKeysForm";

export default function OmniKeysForm({ app_id }) {
  return (
    <Box
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-evenly",
        alignItems: "start",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Typography
          variant="h6"
          style={{
            fontWeight: "bold",
            marginLeft: "6px",
            marginBottom: "2rem",
          }}
        >
          Android Key(s)
        </Typography>
        <AndroidKeysForm app_id={app_id} />
      </Box>
      <Divider variant="middle" orientation="vertical" flexItem />
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Typography
          variant="h6"
          style={{
            fontWeight: "bold",
            marginLeft: "6px",
            marginBottom: "2rem",
          }}
        >
          iOS Keys
        </Typography>
        <IOSKeysForm app_id={app_id} />
      </Box>
    </Box>
  );
}
