import React from 'react';
import { CircularProgress } from '@material-ui/core';

export default function Loading({ color = "primary", size = 50 }) {
  return (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress color={color} size={size} />
    </div>
  );
}
