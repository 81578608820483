import { produce } from "immer";

export const ACTION_ADD_ATTRIBUTE = "ADD_ATTRIBUTE";
export const ACTION_SET_ATTRIBUTE_NAME = "SET_ATTRIBUTE_NAME";
export const ACTION_SET_ATTRIBUTE_VALUE = "SET_ATTRIBUTE_VALUE";
export const ACTION_SET_ATTRIBUTE_OPERATOR = "SET_ATTRIBUTE_OPERATOR";
export const ACTION_SET_QUERY_PARAMS = "SET_QUERY_PARAMS";
export const ACTION_REMOVE_ATTRIBUTE = "ACTION_REMOVE_ATTRIBUTE";
export const ACTION_RESET = "ACTION_RESET";
export const ACTION_SET_INITIAL_VALUES = "ACTION_SET_INITIAL_VALUES";

// Default State maintained by Event Builder
export const initialState = {
  attributes: [],
};

// Default Attribute Format
const initialAttributeState = {
  name: "",
  value: [],
  operator: "EQ",
};

let nextAttrId = 1000;

const getAttribute = (draft, attributeId) => {
  let attrIndex = draft.attributes.findIndex((attr) => attr.id === attributeId);
  return attrIndex;
};

export const reducer = produce((draft, action) => {
  let index;
  switch (action.type) {
    case ACTION_SET_INITIAL_VALUES:
      draft.attributes = action.attributes.map((attr) => ({
        ...attr,
        id: nextAttrId++,
      }));
      break;
    case ACTION_ADD_ATTRIBUTE:
      draft.attributes.push({
        id: nextAttrId++,
        ...initialAttributeState,
      });
      break;
    case ACTION_SET_ATTRIBUTE_NAME:
      index = getAttribute(draft, action.attributeId);
      if (index >= 0) {
        draft.attributes[index].name = action.name;
        draft.attributes[index].value = [];
      }
      break;
    case ACTION_SET_ATTRIBUTE_OPERATOR:
      index = getAttribute(draft, action.attributeId);
      if (index >= 0) {
        draft.attributes[index].operator = action.operator;
      }
      break;
    case ACTION_SET_ATTRIBUTE_VALUE:
      index = getAttribute(draft, action.attributeId);
      if (index >= 0) {
        draft.attributes[index].value = action.value;
      }
      break;
    case ACTION_REMOVE_ATTRIBUTE:
      index = getAttribute(draft, action.attributeId);
      if (index >= 0) {
        draft.attributes.splice(index, 1);
      }
      break;
    case ACTION_RESET:
      return initialState;
    default:
      return draft;
  }
});
