export const RETENTION_ENUM = {
  FTU: "FTU",
  ALL: "ALL",
  RETURNING: "RETURNING",
};

export const RETENTION_WINDOWS = [
  {
    name: "Daily",
    value: 1,
  },
  {
    name: "Weekly",
    value: 7,
  },
  {
    name: "Monthly",
    value: 30,
  },
  {
    name: "Custom",
    value: "custom",
  },
];

export const RETENTION_SWITCH_DATA = [
  { name: "New Installs", value: RETENTION_ENUM.FTU },
  { name: "All Users", value: RETENTION_ENUM.ALL },
  { name: "Returning Users", value: RETENTION_ENUM.RETURNING },
];

export const DATA_DISPLAY_FORMAT_ENUM = {
  PERCENT: "percent",
  VALUE: "value",
};

export const DAYS_ENUM_FOR_USAGE = [0, 1, 7, 15, 30, 60, 90];

export const SET_FIRST_EVENT = "SET_FIRST_EVENT";
export const SET_NEXT_EVENT = "SET_NEXT_EVENT";
export const SET_EVENT_LIST = "SET_EVENT_LIST";
export const SET_DAY_WINDOW = "SET_DAY_WINDOW";
export const SET_DAY_WINDOW_TEXT = "SET_DAY_WINDOW_TEXT";
export const SET_DATA_DISPLAY_FORMAT = "SET_DATA_DISPLAY_FORMAT";
export const SET_SWITCH_VALUE = "SET_SWITCH_VALUE";
export const SET_RETENTION_DATA = "SET_RETENTION_DATA";
export const SET_RETENTION_HEADER = "SET_RETENTION_HEADER";
export const SET_RETENTION_USAGE = "SET_RETENTION_USAGE";
export const SET_RETENTION_WINDOW = "SET_RETENTION_WINDOW";
