import React from "react";
import {
  capitalizeEachWord,
  formatNumber,
  randomColorWithIndex,
  removeCharsWith,
  sortArrayByKey,
} from "../../../../../../../../utils";
import { Grid, makeStyles, Typography } from "@material-ui/core";

const TYPOGRAPHY_NORMAL = {
  fontSize: 12,
  fontWeight: 400,
  wordWrap: "break-word",
  wordBreak: "break-word",
  letterSpacing: "0.01em",
  lineHeight: "17px",
};

const useStyles = makeStyles((theme) => ({
  typography: (props) => TYPOGRAPHY_NORMAL,
  typographyBold: (props) => ({
    ...TYPOGRAPHY_NORMAL,
    fontWeight: 700,
    backgroundColor: "#0028451A",
    borderRadius: 1,
    paddingLeft: 2,
  }),
}));

export default function GoalEventConversionTooltip({
  payload,
  label,
  valueLabel,
  xLabelFormatter,
  isXKeyDate = true,
  colors,
  isExperiment = false,
}) {
  const styles = useStyles();
  return isExperiment ? (
    <div
      className="recharts-default-tooltip"
      style={{
        margin: "0px",
        padding: "10px",
        border: "1px solid rgb(204, 204, 204)",
        whiteSpace: "nowrap",
        background: "rgb(255,255,255) !important",
        boxShadow: "0px 4px 24px rgba(0, 40, 69, 0.17)",
        opacity: 1,
      }}
    >
      <p
        className="recharts-tooltip-label"
        style={{ margin: "0px", marginBottom: 10 }}
      >
        {label}
      </p>
      <Grid container spacing={1} alignItems="center" style={{ maxWidth: 450 }}>
        {Array.isArray(payload) &&
          sortArrayByKey(
            payload.map((o, i) => ({
              ...o,
              color: randomColorWithIndex(i, colors),
            })),
            "value",
            true
          ).map((item, index) => {
            return (
              <Grid item xs={12} key={index}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={1}>
                    <div
                      style={{
                        width: 7,
                        height: 7,
                        borderRadius: "50%",
                        backgroundColor: item.color,
                      }}
                    />
                  </Grid>
                  <Grid item xs={5} style={{ maxWidth: 150 }}>
                    <Typography
                      className={styles.typography}
                      style={{
                        whiteSpace: "break-spaces",
                      }}
                    >
                      {item.name.replace("Var-", "") ||
                        valueLabel ||
                        capitalizeEachWord(
                          removeCharsWith(item.name, "_", " ")
                        )}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      className={styles.typographyBold}
                      style={{
                        padding: "4px 8px",
                        wordWrap: "break-all",
                        wordBreak: "break-all",
                      }}
                    >
                      {item.value.toLocaleString().split(",").length >= 3
                        ? formatNumber(item.value)
                        : item.value.toLocaleString()}
                      %
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      className={styles.typographyBold}
                      style={{
                        padding: "4px 8px",
                        wordWrap: "break-all",
                        wordBreak: "break-all",
                      }}
                    >
                      {item.payload[item.name + "_percentage"]}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
      </Grid>
    </div>
  ) : (
    <div
      className="recharts-default-tooltip"
      style={{
        margin: "0px",
        padding: "10px",
        border: "1px solid rgb(204, 204, 204)",
        whiteSpace: "nowrap",
        background: "rgb(255,255,255) !important",
        boxShadow: "0px 4px 24px rgba(0, 40, 69, 0.17)",
        opacity: 1,
      }}
    >
      <p
        className="recharts-tooltip-label"
        style={{ margin: "0px", marginBottom: 10 }}
      >
        {label}
      </p>
      <Grid container spacing={1} alignItems="center" style={{ maxWidth: 450 }}>
        {Array.isArray(payload) &&
          sortArrayByKey(
            payload.map((o, i) => ({
              ...o,
              color: randomColorWithIndex(i, colors),
            })),
            "value",
            true
          ).map((item, index) => {
            return (
              <Grid item xs={12} key={index}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={1}>
                    <div
                      style={{
                        width: 7,
                        height: 7,
                        borderRadius: "50%",
                        backgroundColor: item.color,
                      }}
                    />
                  </Grid>
                  <Grid item xs={5} style={{ maxWidth: 150 }}>
                    <Typography
                      className={styles.typography}
                      style={{
                        whiteSpace: "break-spaces",
                      }}
                    >
                      {item.payload.name.replace("Var-", "") ||
                        valueLabel ||
                        capitalizeEachWord(
                          removeCharsWith(item.name, "_", " ")
                        )}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      className={styles.typographyBold}
                      style={{
                        padding: "4px 8px",
                        wordWrap: "break-all",
                        wordBreak: "break-all",
                      }}
                    >
                      {item.payload.percentage.toLocaleString().split(",")
                        .length >= 3
                        ? formatNumber(item.value)
                        : item.payload.percentage.toLocaleString()}
                      %
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      className={styles.typographyBold}
                      style={{
                        padding: "4px 8px",
                        wordWrap: "break-all",
                        wordBreak: "break-all",
                      }}
                    >
                      {item.payload.conversion_count}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
}