import { ApxEventHandler } from "../../../../../../common/ApxEventHandler";
import { MODULE_TYPES } from "../../constants";

/**
 * Returns a list of event Subscribers exhaustive to the MODULE_TYPES list
 * [actionButton1Subscriber, actionButton1Subscriber, titleSubscriber ... ]
 * @param {*} callback
 */
export const subscribeToAllConfigurationEvents = (callback) => {
  const subscribedList = Object.keys(MODULE_TYPES).map((key) => {
    return ApxEventHandler.subscribe(MODULE_TYPES[key], (data) => {
      callback(MODULE_TYPES[key], data);
    });
  });
  return subscribedList;
};

export const unSubscribeAllConfigurationEvents = (subscribers) => {
  subscribers.map((eachSubscriber) => eachSubscriber.unsubscribe());
};

export const subscribeToEvent = (eventName, callback) => {
  return ApxEventHandler.subscribe(eventName, () => callback());
};
