import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import SnackBar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme) => ({
  close: {
    padding: theme.spacing(1 / 2),
  },
});

function Snackbar({
  classes,
  vertical = "bottom",
  horizontal = "center",
  children,
  autoHideDuration = 4000,
  action,
  open = true,
  onClose = () => null,
}) {
  const [openNow, setOpenNow] = useState(open);

  const _id = "message-id" + Math.random();

  const handleRequestClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNow(false);
    onClose();
  };

  return (
    <SnackBar
      anchorOrigin={{ vertical, horizontal }}
      open={openNow}
      autoHideDuration={autoHideDuration}
      onClose={handleRequestClose}
      ContentProps={{
        "aria-describedby": _id,
      }}
      message={<span id={_id}>{children}</span>}
      action={[
        action,
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={handleRequestClose}
        >
          <CloseIcon />
        </IconButton>,
      ]}
    />
  );
}

Snackbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Snackbar);
