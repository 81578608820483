import React, { useState, useEffect } from "react";
import Box from "../../../../ReusableComponents/Box";
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Bar,
  Cell,
} from "recharts";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { randomColorWithIndex } from "../../../../../utils";
import Loading from "../../../../ReusableComponents/Loading";
import { useAuth } from "../../../../../contexts/AuthContext";
import { getUninstallDistributionAPI } from "../actions";

const getLabel = (start, end) => `Day ${start} - Day ${end}`;

function getStartEnd(range, key) {
  const { min, max } = range;
  switch (key) {
    case 1:
      return { start: 0, end: min - 1, key };
    case 2:
      return { start: min, end: max - 1, key };
    default:
      return { start: max, end: 90, key };
  }
}

const makeUninstallDistribution = (value, range) => {
  return {
    Uninstalls: value,
    key: getLabel(range.start, range.end),
  };
};

export default function UninstallDistributions({
  appId,
  queryParams,
  filters,
}) {
  const auth = useAuth();

  const [value, setValue] = useState({ min: 7, max: 15 });
  const [valueAfterInputRangeChange, setValueAfterInputRangeChange] = useState({
    min: 7,
    max: 15,
  });

  const [fetching, setFetching] = useState(false);

  const [distributions, setDistributions] = useState([]);

  useEffect(() => {
    const range1 = getStartEnd(valueAfterInputRangeChange, 1);
    const range2 = getStartEnd(valueAfterInputRangeChange, 2);
    const range3 = getStartEnd(valueAfterInputRangeChange, 3);

    setFetching(true);
    const count1 = getUninstallDistributionAPI(
      auth,
      appId,
      { ...queryParams, ...range1 },
      filters
    );
    const count2 = getUninstallDistributionAPI(
      auth,
      appId,
      { ...queryParams, ...range2 },
      filters
    );
    const count3 = getUninstallDistributionAPI(
      auth,
      appId,
      { ...queryParams, ...range3 },
      filters
    );
    Promise.all([count1, count2, count3]).then((values) => {
      setFetching(false);
      setDistributions([
        makeUninstallDistribution(values[0], range1),
        makeUninstallDistribution(values[1], range2),
        makeUninstallDistribution(values[2], range3),
      ]);
    });
  }, [auth, appId, queryParams, filters, valueAfterInputRangeChange]);

  return (
    <Box title="Uninstalls by Journey" icon={<InsertChartIcon />} withPadding>
      {!fetching && (
        <>
          <ResponsiveContainer height={238}>
            <BarChart
              data={distributions}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis dataKey={"key"} type={"category"} />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Bar dataKey="Uninstalls">
                {distributions.map((entry, index) => (
                  <Cell
                    fill={randomColorWithIndex(0)}
                    fillOpacity={0.78}
                    key={`cell-${index}`}
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
          <br />
          <InputRange
            maxValue={90}
            minValue={1}
            value={value}
            onChange={(value) => setValue(value)}
            onChangeComplete={(value) => {
              setValueAfterInputRangeChange(value);
            }}
          />
          <br />
        </>
      )}
      {fetching && (
        <section className="content">
          <Loading size={36} />
        </section>
      )}
    </Box>
  );
}
