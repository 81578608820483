import { Box } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { DEMO_ECOMMERCE_APP_ID } from "../../../../config"
import { useDeepCompareEffect } from "../../../../utils/use-deep-compare"
import { useTrackedState } from "../../store"
import ExperienceDemoAuthDialog from "../components/ExperienceDemoAuthDialog"

export default function DemoSignInPage() {
  const { push } = useHistory()
  const { user } = useTrackedState()

  const [showDemoAuthDialog, setShowDemoAuthDialog] = useState(true)
  const [count, setCount] = useState(0)

  useEffect(() => {
    if (count === 0) {
      window.gtag("event", "conversion", {
        send_to: "AW-327181277/k7SqCJrLidAYEN3HgZwB",
      })
      setCount((count) => count + 1)
    }
  }, [count])

  useDeepCompareEffect(() => {
    if (
      user &&
      user?.email &&
      user?.email !== "" &&
      user?.access_code &&
      user?.access_code !== "" &&
      user?.test_devices?.length >= 1 &&
      !showDemoAuthDialog
    ) {
      push(`/apps/${DEMO_ECOMMERCE_APP_ID}/dashboard`)
    }
  }, [push, showDemoAuthDialog, user])

  const onClose = () => {
    setShowDemoAuthDialog(false)
  }

  return (
    <Box
      style={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url("/assets/img/demo-bg-img.png")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        marginInline: "-8px",
        marginBlock: "-10px",
      }}
    >
      <ExperienceDemoAuthDialog open={showDemoAuthDialog} onClose={onClose} />
    </Box>
  )
}
