import { makeGetRequest, makePostRequest } from "../../../../../common/actions";
import {
  CONDTIONAL_OPERATORS,
  LOGICAL_OPERATORS,
  USER_GROUP_ENUM,
} from "../../../../../../constants";
const STEP_ENUM = {
  inline_shown: "IN_LINE",
  inapp_shown: "IN_APP",
};

const EVENT_DISTRIBUTION_API = "art-configs/analysis/distribution";
const MESSAGE_ANALYSIS_API = "art-configs/analysis";
const VARIANT_ANALYSIS_API = "art-configs/variant/analysis";
const MESSAGE_ANALYSIS_TIME_SERIES_API = "art-configs/analyze/time-series";
const VARIANT_ANALYSIS_TIME_SERIES_API =
  "art-configs/variant/analysis/time-series";
const VARIANT_SUMMARY_API = "art-configs/variant/summary";
const VARIANT_GOAL_CONVERSIONS_TIME_SERIES_API =
  "art-configs/variants/conversions/time-series";
const VARIANT_GOAL_CONVERSIONS_API = "art-configs/variants/conversions";
const GOAL_CONVERSIONS_TIME_SERIES_API = "art-configs/conversions/time-series";
const GOAL_CONVERSIONS_API = "art-configs/conversions";
const CAMPAIGN_METRICS = "art-configs/count-card";
const WINNER_CAMPAIGN_METRICS = "art-configs/variants/count-card";

export function getStepsMetricsAPI(
  auth,
  appId,
  validity,
  stepsMap,
  stepType,
  of
) {
  const group = USER_GROUP_ENUM.ALL_USERS;
  const DEFAULT_countConfig = {
    count: {
      value: 1,
      operator: LOGICAL_OPERATORS.GTE,
    },
  };
  const attribute = "id";
  const postBody = {
    user: [],
    session: [],
    event: [
      {
        name: stepType,
        ...DEFAULT_countConfig,
        attributes: [
          {
            name: "id",
            value: stepsMap[STEP_ENUM[stepType]] || [],
            operator: LOGICAL_OPERATORS.EQ,
          },
        ],
      },
    ],
    condition: CONDTIONAL_OPERATORS.AND,
  };
  const queryParams = {
    since: validity.since,
    till: validity.till,
    event: stepType,
    attribute,
    of,
    group,
  };
  const onSuccessCallback = (json) => {
    json = json.map((item) => {
      return {
        key: item.key,
        users: item.value,
        stepType: STEP_ENUM[stepType],
      };
    });
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    EVENT_DISTRIBUTION_API,
    auth,
    appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getStepsTimeSeriesMetricsAPI(
  auth,
  appId,
  validity,
  stepsMap,
  stepType,
  of
) {
  const group = USER_GROUP_ENUM.ALL_USERS;
  const DEFAULT_countConfig = {
    count: {
      value: 1,
      operator: LOGICAL_OPERATORS.GTE,
    },
  };
  const attribute = "id";
  const postBody = {
    user: [],
    session: [],
    event: [
      {
        name: stepType,
        ...DEFAULT_countConfig,
        attributes: [
          {
            name: "id",
            value: stepsMap[STEP_ENUM[stepType]] || [],
            operator: LOGICAL_OPERATORS.EQ,
          },
        ],
      },
    ],
    condition: CONDTIONAL_OPERATORS.AND,
  };
  const queryParams = {
    since: validity.since,
    till: validity.till,
    event: stepType,
    attribute: ["apx_date", attribute],
    of,
    group,
  };
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    EVENT_DISTRIBUTION_API,
    auth,
    appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getFunnelAnalysisAPI(auth, queryParams) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makeGetRequest(
    MESSAGE_ANALYSIS_API,
    auth,
    auth.appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getVariantFunnelAnalysisAPI(auth, queryParams) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makeGetRequest(
    VARIANT_ANALYSIS_API,
    auth,
    auth.appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getFunnelAnalysisTimeSeriesAPI(auth, queryParams) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makeGetRequest(
    MESSAGE_ANALYSIS_TIME_SERIES_API,
    auth,
    auth.appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getVariantFunnelTimeSeriesAPI(auth, queryParams) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makeGetRequest(
    VARIANT_ANALYSIS_TIME_SERIES_API,
    auth,
    auth.appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getGoalConversionTimeSeriesAPI(
  isExperiment,
  auth,
  queryParams,
  postBody
) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    isExperiment
      ? VARIANT_GOAL_CONVERSIONS_TIME_SERIES_API
      : GOAL_CONVERSIONS_TIME_SERIES_API,
    auth,
    auth.appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback,
    "v1/sandbox/"
  );
}

export function getGoalConversionAPI(
  isExperiment,
  auth,
  queryParams,
  postBody
) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return [];
  };
  return makePostRequest(
    isExperiment ? VARIANT_GOAL_CONVERSIONS_API : GOAL_CONVERSIONS_API,
    auth,
    auth.appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback,
    "v1/sandbox/"
  );
}

export function getCampaignMetricsAPI(
  isExperiment,
  auth,
  queryParams,
  postBody,
  enableCancellation = true
) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return [];
  };
  return makePostRequest(
    isExperiment ? WINNER_CAMPAIGN_METRICS : CAMPAIGN_METRICS,
    auth,
    auth.appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback,
    null,
    enableCancellation
  );
}

export function getVariantSummaryAPI(auth, queryParams, postBody) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    VARIANT_SUMMARY_API,
    auth,
    auth.appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback,
    "v1/sandbox/"
  );
}

