import {
  makePostRequest,
  makeGetRequest,
  makeDeleteRequest,
} from "../../../common/actions";

const CUSTOM_REPORTS_API = "custom-reports";

const EXPLORER_API = "custom-reports/explorer";
const FLAT_TABLE_API = "custom-reports/flat-table";

export function getExplorerAPI(auth, appId, queryParams, filters) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    EXPLORER_API,
    auth,
    appId,
    queryParams,
    filters,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getFlatTableAPI(auth, appId, queryParams, filters) {
  const onSuccessCallback = (json) => {
    const { aggregations, data = [] } = json || {};
    return {
      aggregations,
      data: data.map(({ dimensions, ...o }) => ({ ...dimensions, ...o })),
    };
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    FLAT_TABLE_API,
    auth,
    appId,
    queryParams,
    filters,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getFlatTableForIdAPI(auth, appId, queryParams) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makeGetRequest(
    FLAT_TABLE_API,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}

export function saveCustomReportAPI(auth, appId, queryParams, filters) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    CUSTOM_REPORTS_API,
    auth,
    appId,
    queryParams,
    filters,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getCustomReportsAPI(auth, appId, queryParams) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makeGetRequest(
    CUSTOM_REPORTS_API,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getCustomReportAPI(auth, appId, reportId, queryParams) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makeGetRequest(
    CUSTOM_REPORTS_API + "/" + reportId,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}

export function deleteCustomReportAPI(auth, appId, reportId, queryParams) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makeDeleteRequest(
    CUSTOM_REPORTS_API + "/" + reportId,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}
