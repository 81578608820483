import React from "react";
import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import RowDiv from "../../../../../../../../../ReusableComponents/RowDiv";
import ColumnDiv from "../../../../../../../../../ReusableComponents/ColumnDiv";
import ModuleDivider from "../../../../../../../../../ReusableComponents/ModuleDivider";
import { useSummaryStyles } from "../../../constants";

export default function CampaignScheduleSummary({
  at_specific_time,
  meta: { platform },
  scheduling: schedulingConfig,
  time_limits,
  time_limits_in_day,
  validity: { start_date, end_date },
}) {
  const headerClasses = useSummaryStyles();

  return (
    <Grid container spacing={1} style={{ marginLeft: -8 }}>
      <Grid item xs={12}>
        <RowDiv center applyMargin>
          <Typography className={headerClasses.heading}>Start Date</Typography>
          <Typography className={headerClasses.body}>
            {moment(start_date).format("DD MMM, YYYY, HH:mm")}
          </Typography>
        </RowDiv>
      </Grid>
      <Grid item xs={12}>
        <RowDiv center applyMargin>
          <Typography className={headerClasses.heading}>End Date</Typography>
          <Typography className={headerClasses.body}>
            {moment(end_date).format("DD MMM, YYYY, HH:mm")}
          </Typography>
        </RowDiv>
      </Grid>
      {(time_limits_in_day || at_specific_time) && (
        <>
          <Grid item xs={12}>
            <RowDiv center applyMargin>
              <Typography className={headerClasses.heading}>
                Start Time
              </Typography>
              <Typography className={headerClasses.body}>
                {moment(time_limits.start_time, "HH:mm")
                  .utc(true)
                  .format("HH:mm") + " UTC"}
              </Typography>
            </RowDiv>
          </Grid>
          <Grid item xs={12}>
            <RowDiv center applyMargin>
              <Typography className={headerClasses.heading}>
                End Time
              </Typography>
              <Typography className={headerClasses.body}>
                {moment(time_limits.end_time, "HH:mm")
                  .utc(true)
                  .format("HH:mm") + " UTC"}
              </Typography>
            </RowDiv>
          </Grid>
        </>
      )}
      {schedulingConfig?.isRecurEnabled && (
        <Grid item xs={12}>
          <ModuleDivider styles={{ marginBottom: 10, marginTop: 10 }} />
          <ColumnDiv>
            <Typography className={headerClasses.orangeHeading}>
              RECUR CAMPAIGN
            </Typography>
            <RowDiv center applyMargin>
              <Typography className={headerClasses.heading}>
                Refresh Users in Segment
              </Typography>
              <Typography className={headerClasses.body}>
                Every {schedulingConfig.step} {schedulingConfig.recur}(s)
              </Typography>
            </RowDiv>
          </ColumnDiv>
        </Grid>
      )}
    </Grid>
  );
}
