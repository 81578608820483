import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import ColumnDiv from "../../../../../../../../../../ReusableComponents/ColumnDiv";
import { useDispatch } from "../../../../../../store";
import Property from "./components/Property";
import {
  ADD_NEW_PROPERTY_IN_AUDIENCE,
  REMOVE_AUDIENCE_ATTRIBUTE,
  SET_AUDIENCE_ATTRIBUTE,
  SET_SNACKBAR_ERROR_MESSAGE,
} from "../../../../../../constants";

export default function PropertiesBuilder({
  appId,
  platform,
  properties,
  attributes,
  isUser = true,
}) {
  const dispatch = useDispatch();

  const [filteredAttributes, setFilteredAttributes] = useState(attributes);

  useEffect(() => {
    dispatch({
      type: SET_SNACKBAR_ERROR_MESSAGE,
      value:
        attributes.length < 1
          ? `No ${isUser ? "User" : "Session"} properties left to add`
          : null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilteredAttributes(
      attributes.filter(
        (attr) => properties.findIndex((prop) => prop.name === attr) === -1
      )
    );
  }, [attributes, properties]);

  return (
    <ColumnDiv styles={{ marginLeft: 15, borderLeft: "1px solid #C5CDD2" }}>
      <ColumnDiv>
        {properties.map((prop, index) => (
          <Property
            appId={appId}
            key={index}
            platform={platform}
            index={index}
            attributes={filteredAttributes}
            property={prop}
            handleOnChange={(data) => {
              dispatch({
                type: SET_AUDIENCE_ATTRIBUTE,
                index: index,
                attr_key: isUser ? "user" : "session",
                data,
              });
            }}
            handleOnDelete={() => {
              dispatch({
                type: REMOVE_AUDIENCE_ATTRIBUTE,
                attr_key: isUser ? "user" : "session",
                index,
              });
            }}
          />
        ))}
      </ColumnDiv>
      <ColumnDiv
        styles={{ marginLeft: properties.length > 0 ? 30 : 0, maxWidth: 130 }}
      >
        <Button
          disabled={
            filteredAttributes.length < 1 ||
            (properties.length > 0 &&
              properties.findIndex(
                (item) => item.name === "" || item?.value?.length < 1
              ) !== -1)
          }
          variant="text"
          color="primary"
          onClick={() => {
            dispatch({
              type: SET_SNACKBAR_ERROR_MESSAGE,
              value:
                filteredAttributes.length < 1
                  ? `No ${isUser ? "User" : "Session"} properties left to add`
                  : null,
            });
            if (filteredAttributes.length < 1) {
              return;
            }
            dispatch({
              type: ADD_NEW_PROPERTY_IN_AUDIENCE,
              attr_key: isUser ? "user" : "session",
            });
          }}
        >
          + Add Property
        </Button>
      </ColumnDiv>
    </ColumnDiv>
  );
}
