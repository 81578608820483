import { Box, Button, Grid, Typography } from "@material-ui/core";
import { PlayCircleFilled } from "@material-ui/icons";
import Apxor from "apxor";
import React, { useEffect, useMemo, useState } from "react";
import { DEMO_ECOMMERCE_APP_ID } from "../../../../../../config";
import { APXOR_SDK_CATEGORY_CONSTANTS } from "../../../../../../constants/Endpoints";
import { getDemoCampaignsAPI } from "../../../../common/actions";
import useCampaign from "../../../../common/components/campaignsholder/components/use-campaign";
import {
  DEMO_TEMPLATE_CONFIG_TYPES,
  surveyTourTypes,
  walkthroughTourTypes,
} from "../../../../common/components/campaignsholder/constants";

function SingleTourCard({ actionType, campaignConfig, tourType, index }) {
  const { handleDuplicate } = useCampaign(
    campaignConfig,
    () => {},
    actionType,
    DEMO_ECOMMERCE_APP_ID
  );

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
      }}
    >
      <Box
        style={{
          padding: "14px 20px",
          border: "1px solid #E4E7E9",
          borderRadius: "3px",
          maxWidth: "100%",
          marginBottom: index === 0 ? "8px" : "0px",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              fontWeight: "600",
              fontSize: "14px",
            }}
          >
            {tourType.name}
          </Typography>
          <Button
            variant="text"
            startIcon={<PlayCircleFilled />}
            style={{
              color: "rgba(255, 127, 51, 1)",
              fontSize: "13px",
              fontWeight: "700",
            }}
            onClick={() => {
              Apxor.logEvent(
                "TryNudgeClicked",
                {
                  "name": campaignConfig.meta.name || "NA",
                  "actionType": actionType,
                },
                APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS
              );
              handleDuplicate(campaignConfig);
            }}
          >
            Try Now
          </Button>
        </Box>
        <Typography
          style={{
            opacity: "0.6",
            color: "rgba(0, 40, 69, 1)",
            fontSize: "12px",
            fontWeight: "500",
          }}
        >
          {tourType.content}
        </Typography>
      </Box>
    </Box>
  );
}

export default function TourTypeSelectionSection({ actionType, auth }) {
  const [demoWalkthroughs, setDemoWalkthroughs] = useState([]);
  const [demoSurveys, setDemoSurveys] = useState([]);

  useEffect(() => {
    (async () => {
      if (actionType === "walkthroughs") {
        try {
          const res = await getDemoCampaignsAPI(
            auth,
            DEMO_ECOMMERCE_APP_ID,
            {
              configType: DEMO_TEMPLATE_CONFIG_TYPES.WALKTHROUGHS,
            },
            false
          );
          setDemoWalkthroughs(res?.data ?? []);
        } catch (error) {
          setDemoWalkthroughs([]);
        }
      } else {
        try {
          const res = await getDemoCampaignsAPI(
            auth,
            DEMO_ECOMMERCE_APP_ID,
            {
              configType: DEMO_TEMPLATE_CONFIG_TYPES.SURVEYS,
            },
            false
          );
          setDemoSurveys(res?.data ?? []);
        } catch (error) {
          setDemoSurveys([]);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionType]);

  const tourTypesByActionType = useMemo(
    () =>
      actionType === "walkthroughs" ? walkthroughTourTypes : surveyTourTypes,
    [actionType]
  );

  const actions = useMemo(
    () => (actionType === "walkthroughs" ? demoWalkthroughs : demoSurveys),
    [actionType, demoWalkthroughs, demoSurveys]
  );

  return (
    <Grid
      container
      spacing={0}
      style={{
        padding: "24px 46px",
        background: "#ffffff",
        border: "1px solid #EAECEE",
        borderRadius: "4px",
        marginBottom: "17px",
      }}
    >
      <Grid item xs={4}>
        {actionType === "walkthroughs" ? (
          <img
            src="/assets/img/basic_tooltip.png"
            alt="Campaign"
            height="276px"
          />
        ) : (
          <img
            src="/assets/img/survey-demo-img.png"
            alt="Survey"
            height="276px"
          />
        )}
      </Grid>
      <Grid item xs={8}>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "start",
          }}
        >
          <Typography
            style={{
              fontWeight: "700",
              fontSize: "17px",
              marginBottom: "22px",
            }}
          >
            Practically understand how{" "}
            {actionType === "walkthroughs" ? "campaigns" : "surveys"} work:
          </Typography>
          {tourTypesByActionType?.map((tourType, index) => {
            return (
              <SingleTourCard
                actionType={actionType}
                key={index}
                tourType={tourType}
                index={index}
                campaignConfig={actions.find(
                  (walkthrough) => walkthrough.meta.name === tourType.name
                )}
              />
            );
          })}
        </Box>
      </Grid>
    </Grid>
  );
}

