import React from "react";
import { Grid, TextField, InputAdornment, Tooltip } from "@material-ui/core";
import MultiSelect from "../../../../../../../../../../ReusableComponents/MultiSelect";
import { useTrackedState } from "../../../../../store";
import delete_icon from "../../../../../assets/delete_icon.png";
import RowDiv from "../../../../../../../../../../ReusableComponents/RowDiv";
import {
  SET_CHOICE_TEXT,
  SET_NEXT_QUESTION_ID,
} from "../../../../../constants";

/**
 * Displays Choice item and it's contents
 */

const getStartAdonrment = isMultiChoice => {
  return (
    <InputAdornment position="end">
      <Tooltip title="Delete Option">
        <img
          src={delete_icon}
          alt="Apxor"
          style={{ height: 20, marginRight: 5, cursor: "pointer" }}
        ></img>
      </Tooltip>
    </InputAdornment>
  );
};

export default function Choice({
  id,
  questionId,
  value,
  next_question_id = -1,
  responseBasedSurvey = false,
  isMultiChoice = false,
  /**
   * We are passing down these callbacks because
   * we need to update the right question when we modify
   * this particular item
   */
  handleUpdate,
  handleDelete,
}) {
  /**
   * Getting the entire local store to display a MultiSelect
   * when `responseBasedSurvey` flag is enabled
   */
  const state = useTrackedState();

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={responseBasedSurvey && !isMultiChoice ? 4 : 11}>
        <RowDiv>
          <TextField
            fullWidth
            style={{ height: 44, width: 286 }}
            required
            type="text"
            margin="dense"
            placeholder="Option x"
            value={value}
            onChange={e => {
              handleUpdate(SET_CHOICE_TEXT, e.target.value);
            }}
            InputProps={{
              // startAdornment: getStartAdonrment(isMultiChoice),
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Delete Option">
                    <img
                      src={delete_icon}
                      alt="Apxor"
                      onClick={() => handleDelete(id)}
                      style={{ height: 20, marginRight: 5, cursor: "pointer" }}
                    ></img>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </RowDiv>
      </Grid>
      {responseBasedSurvey && !isMultiChoice && (
        <Grid item xs={7} style={{ marginTop: 9 }}>
          <MultiSelect
            label="Next Question"
            options={[{ label: "End Survey", value: -1 }].concat(
              // Filter out the current question and construct the options
              state.ui.questions
                .filter(question => question.id !== questionId)
                .map(question => ({
                  label: question.title,
                  value: question.id,
                }))
            )}
            single
            clearable={false}
            value={next_question_id}
            itemName={"style"}
            handleChange={value => {
              handleUpdate(SET_NEXT_QUESTION_ID, value);
            }}
          />
        </Grid>
      )}
    </Grid>
  );
}
