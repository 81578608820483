export const UPDATE_IN_NEW_STEP = "UPDATE_IN_NEW_STEP";
export const COPY_STEP = "COPY_STEP";
export const DELETE_STEP_FROM_CAMPAIGN = "DELETE_STEP_FROM_CAMPAIGN";
export const ADD_NEXT_STEP_TO_CAMPAIGN = "ADD_NEXT_STEP_TO_CAMPAIGN";
export const SET_NEXT_STEP_DATA = "SET_NEXT_STEP_DATA";
export const MOVE_STEP = "MOVE_STEP";
export const SET_WEB_VIEW = "SET_WEB_VIEW";
export const SET_WEB_VIEW_TAG = "SET_WEB_VIEW_TAG";
export const SET_DELAY = "SET_DELAY";
export const SET_BACKGROUND_COLOR = "SET_BACKGROUND_COLOR";
export const SET_BORDER_COLOR = "SET_BORDER_COLOR";
export const SET_BORDER_RADIUS = "SET_BORDER_RADIUS";
export const SET_BORDER_WIDTH = "SET_BORDER_WIDTH";
export const SET_DIM_BACKGROUND = "SET_DIM_BACKGROUND";
export const SET_DIM_BACKGROUND_COLOR = "SET_DIM_BACKGROUND_COLOR";
export const SET_DIM_BACKGROUND_OPACITY = "SET_DIM_BACKGROUND_OPACITY";
export const SET_MESSAGE_POSITION = "SET_MESSAGE_POSITION";
export const SET_TOOLTIP_TEXT_TYPE = "SET_TOOLTIP_TEXT_TYPE";
export const SET_WEB_URL = "SET_WEB_URL";
export const SET_DISMISS_OUTSIDE_TOUCH = "SET_DISMISS_OUTSIDE_TOUCH";
export const SET_BUTTONS = "SET_BUTTONS";
export const SET_ANIMATION_ENABLED = "SET_ANIMATION_ENABLED";
export const SET_WEB_HTML_DATA = "SET_WEB_HTML_DATA";
export const SET_PASSIVE_NUDGE_ANIMATION_TYPE =
  "SET_PASSIVE_NUDGE_ANIMATION_TYPE";
export const SET_CURRENT_STEP = "SET_CURRENT_STEP";
export const SET_BUTTON_ACTION = "SET_BUTTON_ACTION";
export const SET_IMAGE_ONLY_REDIRECTION_CONFIG =
  "SET_IMAGE_ONLY_REDIRECTION_CONFIG";
export const SET_BUTTON_TEXT = "SET_BUTTON_TEXT";
export const SET_BUTTON_BORDER_RADIUS = "SET_BUTTON_BORDER_RADIUS";
export const SET_BUTTON_COLOR = "SET_BUTTON_COLOR";
export const SET_BUTTON_BG_COLOR = "SET_BUTTON_BG_COLOR";
export const SET_BUTTON_FONT_FAMILY = "SET_BUTTON_FONT_FAMILY";
export const SET_BUTTON_FONT_SIZE = "SET_BUTTON_FONT_SIZE";
export const SET_BUTTON_REDIRECT_ACTIVITY = "SET_BUTTON_REDIRECT_ACTIVITY";
export const SET_BUTTON_REDIRECT_DEEPLINK = "SET_BUTTON_REDIRECT_DEEPLINK";
export const SET_BUTTON_REDIRECT_TYPE = "SET_BUTTON_REDIRECT_TYPE";
export const SET_BUTTON_ACTION_TYPE = "SET_BUTTON_ACTION_TYPE";
export const SET_BUTTON_FONT_STYLE = "SET_BUTTON_FONT_STYLE";
export const SET_TEXT = "SET_TEXT";
export const SET_COLOR = "SET_COLOR";
export const SET_GRADIENT = "SET_GRADIENT";
export const SET_FONT_FAMILY = "SET_FONT_FAMILY";
export const SET_FONT_SIZE = "SET_FONT_SIZE";
export const SET_FONT_STYLE = "SET_FONT_STYLE";
export const SET_BUTTON_IMAGE = "SET_BUTTON_IMAGE";
export const SET_COACHMARK_TYPE = "SET_COACHMARK_TYPE";
export const SET_COACHMARK_HAS_ARROW = "SET_COACHMARK_HAS_ARROW";
export const SET_COACHMARK_HAS_RIPPLE = "SET_COACHMARK_HAS_RIPPLE";
export const SET_OPACITY = "SET_OPACITY";
export const SET_MODULE_TYPE = "SET_MODULE_TYPE";
export const SET_IMAGE = "SET_IMAGE";
export const SET_UIS_CONFIG = "SET_UIS_CONFIG";
export const SET_ONLY_IMAGE = "SET_ONLY_IMAGE";
export const SET_TOUCH_URL = "SET_TOUCH_URL";
export const SET_BG_IMAGE_SIZE = "SET_BG_IMAGE_SIZE";
export const SET_DIMMED_BACKGROUND = "SET_DIMMED_BACKGROUND";
export const SET_INAPP_DISMISS_OUTSIDE_TOUCH =
  "SET_INAPP_DISMISS_OUTSIDE_TOUCH";
export const SET_INAPP_HTML = "SET_INAPP_HTML";
export const SET_INAPP_HTML_URL = "SET_INAPP_HTML_URL";
export const SET_MAXIMUM_HEIGHT = "SET_MAXIMUM_HEIGHT";
export const SET_BG_IMAGE = "SET_BG_IMAGE";
export const SET_PREVIEW_DATA = "SET_PREVIEW_DATA";

export const SET_VIDEO_LINK = "SET_VIDEO_LINK";
export const SET_HEIGHT = "SET_HEIGHT";
export const SET_WIDTH = "SET_WIDTH";
export const SET_VIDEO_LOADING_TYPE = "SET_VIDEO_LOADING_TYPE";
export const SET_VIDEO_HAS_AUDIO = "SET_VIDEO_HAS_AUDIO";
export const SET_VIDEO_BINDING = "SET_VIDEO_BINDING";
export const SET_VIDEO_PROGRESS_BAR = "SET_VIDEO_PROGRESS_BAR";
export const SET_VIDEO_ACTIVITY = "SET_VIDEO_ACTIVITY";
export const SET_VIDEO_CLOSE = "SET_VIDEO_CLOSE";
export const SET_VIDEO_AUTO_REPLAY = "SET_VIDEO_AUTO_REPLAY";
export const SET_VIDEO_CAN_MINIMIZE = "SET_VIDEO_CAN_MINIMIZE";
export const SET_VIDEO_ISFULLSCREEN = "SET_VIDEO_ISFULLSCREEN";
export const SET_VIDEO_MUTE_AUDIO = "SET_VIDEO_MUTE_AUDIO";
export const SET_VIDEO_BUTTON_COLOR = "SET_VIDEO_BUTTON_COLOR";

export const SET_ONBOARDING_ANIM_TYPE = "SET_ONBOARDING_ANIM_TYPE";
export const SET_ONBOARDING_ANIM_DURATION = "SET_ONBOARDING_ANIM_DURATION";
export const SET_ONBOARDING_FADEOUT_OPACITY = "SET_ONBOARDING_FADEOUT_OPACITY";
export const SET_ONBOARDING_DISMISS_TYPE = "SET_ONBOARDING_DISMISS_TYPE";
export const SET_ONBOARDING_PAGINATION_TYPE = "SET_ONBOARDING_PAGINATION_TYPE";
export const SET_ONBOARDING_LEFT_PAD = "SET_ONBOARDING_LEFT_PAD";
export const SET_ONBOARDING_RIGHT_PAD = "SET_ONBOARDING_RIGHT_PAD";
export const SET_ONBOARDING_TOP_PAD = "SET_ONBOARDING_TOP_PAD";
export const SET_ONBOARDING_BOTTOM_PAD = "SET_ONBOARDING_BOTTOM_PAD";
export const SET_ONBOARDING_VERTICAL_MARGIN = "SET_ONBOARDING_VERTICAL_MARGIN";
export const SET_ONBOARDING_HORIZONTAL_MARGIN =
  "SET_ONBOARDING_HORIZONTAL_MARGIN";
export const SET_ONBOARDING_DOTS_ACTIVE_COLOR =
  "SET_ONBOARDING_DOTS_ACTIVE_COLOR";
export const SET_ONBOARDING_DOTS_INACTIVE_COLOR =
  "SET_ONBOARDING_DOTS_INACTIVE_COLOR";
export const SET_ONBOARDING_DOTS_OPACITY = "SET_ONBOARDING_DOTS_OPACITY";
export const SET_ONBOARDING_MESSAGE_POSITION =
  "SET_ONBOARDING_MESSAGE_POSITION";
export const SET_ONBOARDING_IMAGE_POSITION = "SET_ONBOARDING_IMAGE_POSITION";
export const SET_ONBOARDING_LAST_STEP_TEXT = "SET_ONBOARDING_LAST_STEP_TEXT";
export const SET_ONBOARDING_BTN_TEXT = "SET_ONBOARDING_BTN_TEXT";
export const SET_ONBOARDING_BTN_FONT_SIZE = "SET_ONBOARDING_BTN_FONT_SIZE";
export const SET_ONBOARDING_BTN_FONT_FAMILY = "SET_ONBOARDING_BTN_FONT_FAMILY";
export const SET_ONBOARDING_BTN_FONT_STYLE = "SET_ONBOARDING_BTN_FONT_STYLE";
export const SET_ONBOARDING_BTN_COLOR = "SET_ONBOARDING_BTN_COLOR";
export const SET_ONBOARDING_SKIP_ENABLED = "SET_ONBOARDING_SKIP_ENABLED";
export const SET_ONBOARDING_NEXT_PREV_ENABLED =
  "SET_ONBOARDING_NEXT_PREV_ENABLED";
export const SET_CORNER_RADUIS_X = "SET_CORNER_RADUIS_X";
export const SET_CORNER_RADUIS_Y = "SET_CORNER_RADUIS_Y";
export const SET_FADEOUT_OPACITY = "SET_FADEOUT_OPACITY";
export const SET_ONBOARDING_STATE = "SET_ONBOARDING_STATE";
export const SET_ONBOARDING_MODE = "SET_ONBOARDING_MODE";
export const UPDATE_ONBOARDING_STATE_IN_UIS = "UPDATE_ONBOARDING_STATE_IN_UIS";
export const SET_DYNAMIC_TEXT_CONFIG = "SET_DYNAMIC_TEXT_CONFIG";
export const TEMPLATE_BEEN_SET = "TEMPLATE_BEEN_SET";
export const HIDE_CONFIGURATION_TOOLBARS = "HIDE_CONFIGURATION_TOOLBARS";
export const ON_COPY_STEP = "ON_COPY_STEP";
export const ON_EDIT_STEP = "ON_EDIT_STEP";
export const ON_DELETE_STEP = "ON_DELETE_STEP";
export const SET_BORDER_BOTTOM_RADIUS = "SET_BORDER_BOTTOM_RADIUS";
export const SET_PLATFORM = "SET_PLATFORM";
export const CONVERT_INAPP_TO_HTML = "CONVERT_INAPP_TO_HTML";
export const SET_IMG = "SET_IMG";
export const SET_CURRENT_IMAGE = "SET_CURRENT_IMAGE";
export const SET_LAYOUT = "SET_LAYOUT";
export const SET_SELECTED_HTML_VIEW = "SET_SELECTED_HTML_VIEW";
export const SET_EVENT_SOURCE = "SET_EVENT_SOURCE";
export const SET_TREEVIEW = "SET_TREEVIEW";
export const SET_ORIENTATION = "SET_ORIENTATION";
export const SET_ACTIVITY = "SET_ACTIVITY";
export const SET_SSE_DATA = "SET_SSE_DATA";
export const SET_VIEWID_SELECTION_DIALOG_DATA =
  "SET_VIEWID_SELECTION_DIALOG_DATA";

export const SET_HTML_SCRIPT_CONFIG = "SET_HTML_SCRIPT_CONFIG";
export const REMOVE_SNIPPET_FROM_HTML_CONFIG =
  "REMOVE_SNIPPET_FROM_HTML_CONFIG";

export const SET_HTML_ACTION_CONFIG = "SET_HTML_ACTION_CONFIG";

export const SHOW_CUSTOM_FONT_TOOLTIP = "SHOW_CUSTOM_FONT_TOOLTIP";
export const ENABLE_CUSTOM_FONT = "ENABLE_CUSTOM_FONT";
export const SET_CUSTOM_FONT = "SET_CUSTOM_FONT";

export const SET_CAROUSEL_IMAGES = "SET_CAROUSEL_IMAGES";
export const SET_CAROUSEL_COUNT = "SET_CAROUSEL_COUNT";
export const UNSET_CAROUSAL_IMAGE = "UNSET_CAROUSAL_IMAGE";

export const ON_UIS_CHANGE = "ON_UIS_CHANGE";

export const SET_EXPERIMENT_STATE = "SET_EXPERIMENT_STATE";

export const ACTION_CLASSES = {
  TOOLTIP: "tooltip",
  NUDGE: "nudge",
  COACHMARK: "coachmark",
  INAPP: "inapp",
};

export const TOOLTIP_LAYOUT_TYPES = {
  ACTION_BUTTONS: "action_buttons",
  DEFAULT: "default",
  IMAGE_TEXT_BUTTONS: "image_text_buttons",
  INLINE_WITH_DOT: "inline_with_dot",
  TOOLTIP_WITH_COACHMARK: "default",
  TEXT_WITH_FIXED_BUTTONS: "text_with_fixed_buttons",
  HTML_OVERLAY: "overlay",
};

export const INAPP_LAYOUT_TYPES = {
  HEADER: "header",
  FOOTER: "footer",
  POPOUT_MODAL: "popout_modal",
  CLASSIC_MODAL: "classic_modal",
  FULL_SCREEN_MODAL: "full_screen_modal",
  CAROUSEL: "carousel",
  FLOATING: "floating",
  CUSTOM_HTML: "custom",
  VIDEOS: "videos",
};

export const NUDGE_LAYOUT_TYPES = {
  DEFAULT: "default",
};

export const COACHMARK_LAYOUT_TYPES = {
  DEFAULT: "default",
};

export const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export const ONBOARDING_PAGINATION_ENUM = [
  { label: "Numbers with Carousel indicators", value: "number_carousal" },
  { label: "Numbers", value: "numbers" },
  { label: "Carousel", value: "carousal" },
];

export const ONBOARDING_DISMISS_ENUM = [
  { label: "swipe", value: "swipe" },
  { label: "tap", value: "tap" },
];

export const ONBOARDING_ANIMATION_ENUM = [
  { label: "Transition", value: "tran" },
  { label: "Fade Out", value: "fade_out" },
];

export const BORDER_RADIUS_ENUM = [2, 4, 8, 16].map((x) => ({
  label: x,
  value: x + "",
}));

export const COACHMARK_SHAPE_TYPES = [
  { label: "circle", value: "circle" },
  { label: "rectangle", value: "rectangle" },
];

export const MAX_HEIGHT_ENUM = range(20, 100, 1).map((x) => ({
  label: x,
  value: x + "",
}));

export const BORDER_WIDTH_ENUM = BORDER_RADIUS_ENUM;

export const BUTTONS_COUNT_ENUM = [0, 1, 2, 3].map((x) => ({
  label: x > 0 ? x : "None",
  value: x + "",
}));

export const MESSAGE_POSITION_ENUM = [
  "Top",
  "Right",
  "Bottom",
  "Left",
  "Center",
].map((x) => ({ label: x, value: x }));

export const MESSAGE_TOOLTIP_TEXT_TYPE_ENUM = [
  { label: "Description Text Only", value: 0 },
  { label: "Title & Description", value: 1 },
];

export const NUDGE_POSITION_ENUM = [
  { value: "center", label: "Center" },
  { value: "top_center", label: "Top Center" },
  { value: "left", label: "Left" },
  { value: "right", label: "Right" },
];

export const NUDGE_ANIMATION_ENUM = ["none", "cycle"].map((x) => ({
  label: x,
  value: x.toLowerCase(),
}));

export const DEFAULT_INTENT_ACTION = "android.intent.action.VIEW";
export const DEFAULT_IMAGE_SIZE_TYPES = ["Cover", "Contain", "Stretch"];

export const BTN_ACTION_ENUM = {
  DISMISS: "dismiss",
  REDIRECT: "redirect",
  CANCEL: "cancel",
};

export const ASPECT_RATIO_ENUM = {
  "16:9 (portrait)": {
    width: 1080,
    height: 1920,
  },
  "16:9 (landscape)": {
    width: 1920,
    height: 1080,
  },
  Custom: {
    width: 1280,
    height: 720,
  },
};

export const REDIRECT_ACTIONS = {
  ACTIVITY: "activity",
  DEEP_LINK: "deep link",
  KEY_VALUE_PAIRS: "Key Value Pairs",
};

export const MODULE_TYPES = {
  TITLE: "TITLE",
  DESCRIPTION: "DESCRIPTION",
  IMAGE: "IMAGE",
  IMAGE_URL: "IMAGE_URL",
  BACKGROUND: "BACKGROUND",
  BG_IMAGE_URL: "BG_IMAGE_URL",
  BUTTON: "BUTTON_",
  BUTTON_TEXT: "BUTTON_TEXT",
  BUTTON1: "BUTTON_1",
  BUTTON2: "BUTTON_2",
  BUTTON_TEXT1: "BUTTON_TEXT1",
  BUTTON_TEXT2: "BUTTON_TEXT2",
  ACTION_BUTTON: "ACTION_BUTTON_",
  ACTION_BUTTON1: "ACTION_BUTTON_1",
  ACTION_BUTTON2: "ACTION_BUTTON_2",
  ACTION_BUTTON3: "ACTION_BUTTON_3",
  ACTION_BUTTON_1_IMAGE: "ACTION_BUTTON_1_IMAGE",
  ACTION_BUTTON_1_TEXT: "ACTION_BUTTON_1_TEXT",
  ACTION_BUTTON_1_DESC: "ACTION_BUTTON_1_DESC",
  ACTION_BUTTON_2_IMAGE: "ACTION_BUTTON_2_IMAGE",
  ACTION_BUTTON_2_TEXT: "ACTION_BUTTON_2_TEXT",
  ACTION_BUTTON_2_DESC: "ACTION_BUTTON_2_DESC",
  ACTION_BUTTON_3_IMAGE: "ACTION_BUTTON_3_IMAGE",
  ACTION_BUTTON_3_TEXT: "ACTION_BUTTON_3_TEXT",
  ACTION_BUTTON_3_DESC: "ACTION_BUTTON_3_DESC",
  VIDEO_BUTTON: "VIDEO_BUTTON",
  // and the list goes on
};

export const INITIAL_ONBOARDING_STATE = {
  anim_type: "tran",
  duration: 300,
  rx: 7.2,
  ry: 7.2,
  carousal: {
    d_type: "swipe",
    m_temp: "left_i",
    d_pad: {
      bottom: 2,
      left: -2,
      right: -2,
      top: 2,
    },
    m_margin: {
      hz: 0,
      vr: -10,
    },
    f_alpha: 0.85,
    dots: true,
    d_acolor: "#00AB99",
    d_icolor: "#FFFFFF",
    d_opacity: 0.4,
    m_pos: "center",
    i_pos: "center",
    skip_top: -10,
  },
  has_counter: true,
  counter: {
    color: "#FFFFFF",
    font_family: "Roboto",
    text: "",
    size: 22,
    style: "bold",
    bottom_dist: 15,
    left_dist: 15,
  },
  lastStepText: "Got it",
  nextPrevEnabled: false,
  skipEnabled: false,
};

export const SKIP_BUTTON_STATE = {
  skip: {
    color: "#FFFFFF",
    font_family: "Roboto",
    text: "Skip",
    size: 22,
    style: "bold",
    bottom_dist: 15,
    left_dist: 15,
  },
};

export const NEXT_BUTTON_STATE = {
  next: {
    color: "#FFFFFF",
    font_family: "Roboto",
    text: "Next",
    size: 22,
    style: "bold",
    bottom_dist: 15,
    left_dist: 15,
  },
};

export const PREV_BUTTON_STATE = {
  prev: {
    color: "#FFFFFF",
    font_family: "Roboto",
    text: "Prev",
    size: 22,
    style: "bold",
    bottom_dist: 15,
    left_dist: 15,
  },
};

export const DEFAULT_HTML_SCRIPT_JSON = {
  keys: [],
  vmap: {},
  srcs: {},
};

export const DEFAULT_HTML_IMAGE_URL =
  "https://images.unsplash.com/photo-1577578306649-09e937512e28?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=50";


