import React, { useEffect, useState, useMemo } from "react";
import { OVERVIEW_KEY } from "../constants";
import { makeSortableTableData, comparator } from "../utils";
import SortableDataTable from "../../../../ReusableComponents/SortableDataTable";
import { Grid, Button, Typography, Snackbar } from "@material-ui/core";
import {
  getAttributes,
  getEventAttributesAPI,
} from "../../../../common/actions";
import Box from "../../../../ReusableComponents/Box";
import { getFunnelOpportunityAPI } from "../actions";
import { useTracked } from "../store";
import { SET_GROUP, SET_GROUP_BY } from "../reducer";
import MultiSelect from "../../../../ReusableComponents/MultiSelect";
import Loading from "../../../../ReusableComponents/Loading";
import { areArraysEqual, withQueryStrings } from "../../../../../utils";
import Switch from "../../../../ReusableComponents/Switch";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import { API_BASE, API_ROOT_V4 } from "../../../../../config";
import Axios from "axios";

function FunnelsGroupedByTable({
  funnels,
  group_by,
  groupByData,
  stepWiseTimes,
}) {
  const { keys = [], data = [] } = makeSortableTableData(funnels, groupByData);
  let headerLabels = funnels.map((o) => o.name);
  if (stepWiseTimes.length + 1 === headerLabels.length) {
    headerLabels = headerLabels.map((eachlabel, index) =>
      stepWiseTimes[index]
        ? eachlabel +
          ` (Mean time taken till next step - ${(
            parseFloat(stepWiseTimes[index]).toFixed(2) / 1000
          ).toFixed(2)}s) `
        : eachlabel
    );
  }
  const comparators = headerLabels.reduce((a, b) => {
    a[b] = comparator;
    return a;
  }, {});
  return (
    <Grid container spacing={8}>
      <Grid item xs>
        <SortableDataTable
          data={data}
          headerLabels={[group_by, ...headerLabels]}
          keys={keys}
          withoutBox
          withoutSerialNumbers
          comparators={comparators}
        />
      </Grid>
    </Grid>
  );
}

export default function FunnelGroupBy({
  auth,
  appId,
  funnels,
  filters,
  queryParams,
  groupByData,
  stepWiseTimes,
  groupByLoading,
}) {
  const [state, dispatch] = useTracked();

  const [attributes, setAttributes] = useState({
    user: [],
    session: [],
    event: [],
  });
  const [eventAttributes, setEventAttribtues] = useState({});
  const [downloading, setDownloading] = useState(false);

  const [opportunityAttributes, setOpportunityAttributes] = useState([]);

  // Whenever the group gets changed, this memo will be re-evaluated
  const properties = useMemo(
    () =>
      state.selectedGroup === OVERVIEW_KEY
        ? [...attributes.user, ...attributes.session, "apx_date"]
        : eventAttributes[state.selectedGroup] || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [attributes, eventAttributes, state.selectedGroup]
  );

  const [opportunity, setOpportunity] = useState({
    keys: [],
    data: [],
  });
  const [opportunityFailed, setOpportunityFailed] = useState(false);
  const [opportunityPending, setOpportunityPending] = useState(false);

  const headerLabels = useMemo(() => funnels.map((o) => o.name), [funnels]);
  const comparators = headerLabels.reduce((a, b) => {
    a[b] = comparator;
    return a;
  }, {});

  // Fetch all attributes upon component did mount
  useEffect(() => {
    getAttributes(auth, appId).then((response) => {
      setAttributes(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Runs whenever the group is changed in `FunnelGraph` component
  useEffect(() => {
    if (state.selectedGroup !== OVERVIEW_KEY) {
      if (!eventAttributes[state.selectedGroup]) {
        getEventAttributesAPI(auth, appId, {
          ...queryParams,
          event: state.selectedGroup,
        }).then((response) => {
          setEventAttribtues({
            ...eventAttributes,
            [state.selectedGroup]: response,
          });
        });
      }
    } else {
      dispatch({
        type: SET_GROUP_BY,
        groupBy: [],
      });
    }

    // Whenever the `selectedGroup` changes, we need to clear the Opportunities related state
    setOpportunity({ key: [], data: [] });
    setOpportunityAttributes([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedGroup]);

  const { keys = [], data = [] } = useMemo(
    () => makeSortableTableData(funnels, opportunity),
    [funnels, opportunity]
  );

  const getFunnelOpportunity = () => {
    setOpportunityPending(true);
    getFunnelOpportunityAPI(
      auth,
      appId,
      {
        ...queryParams,
        ...opportunityAttributes.map((o) => ({ attribute: o })),
      },
      { ...filters, group_by: [] }
    )
      .then((response) => {
        setOpportunityPending(false);
        setOpportunityFailed(false);
        setOpportunity(response);
      })
      .catch((error) => {
        setOpportunityFailed(true);
      });
  };

  return (
    <>
      <Box
        title={state.selectedGroup}
        rowControls={true}
        controls={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Switch
              data={[OVERVIEW_KEY, ...funnels.map((o) => o.name)]}
              handleChange={(value) => {
                dispatch({
                  type: SET_GROUP,
                  group: value,
                });
              }}
              value={state.selectedGroup}
            />
            <MultiSelect
              clearable
              single={true}
              handleChange={(selectedAttribute) => {
                let additionalProp = {};
                if (state.selectedGroup && state.selectedGroup !== OVERVIEW_KEY)
                  additionalProp.event = state.selectedGroup;
                dispatch({
                  type: SET_GROUP_BY,
                  groupBy: [
                    {
                      ...additionalProp,
                      attribute: selectedAttribute,
                    },
                  ],
                  property: selectedAttribute,
                });
              }}
              options={properties.map((o) => {
                return { label: o, value: o };
              })}
              placeholder="Select Property to groupBy"
              style={{ maxWidth: 450, zIndex: 9999 }}
              value={state.selectedGroupByProperty}
            />
            <Button
              variant="contained"
              onClick={() => {
                setDownloading(true);
                Axios.request({
                  url:
                    `${
                      API_BASE + API_ROOT_V4
                    }funnels/analysis/download?customerId=${
                      auth.user.email
                    }&appId=${appId}` +
                    withQueryStrings({
                      ...queryParams,
                    }),
                  method: "POST",
                  responseType: "blob",
                  withCredentials: true,
                  data: {
                    ...filters,
                    time: filters.time * 1000,
                    groupBy: state.groupBy,
                  },
                })
                  .then((response) => {
                    let blob = new Blob([response.data], {
                      type: response.headers["content-type"],
                    });
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = "funnel.csv";
                    link.click();
                    link.remove();
                    setDownloading(false);
                  })
                  .catch((error) => {
                    if (error) {
                      setDownloading(false);
                    }
                  });
              }}
              disabled={areArraysEqual(opportunityAttributes, properties)}
              color="primary"
            >
              {downloading ? <Loading size={24} /> : <DownloadIcon />}
            </Button>
          </div>
        }
      >
        {groupByLoading && <Loading size={24} />}
        {!groupByLoading && (
          <FunnelsGroupedByTable
            funnels={funnels}
            group_by={state.selectedGroupByProperty}
            groupByData={groupByData}
            stepWiseTimes={stepWiseTimes}
          />
        )}
      </Box>
      <Box title="Quick View">
        <Grid container spacing={8}>
          <Grid item xs={12} md={10}>
            <MultiSelect
              options={properties.map((o) => ({
                label: o,
                value: o,
              }))}
              handleChange={(attributes) => {
                setOpportunityAttributes(attributes);
              }}
              value={opportunityAttributes}
              margin="normal"
              fullWidth
            />
          </Grid>
          <Grid item xs={4} md={2}>
            <Button
              variant="contained"
              onClick={() => {
                getFunnelOpportunity();
              }}
              disabled={areArraysEqual(opportunityAttributes, properties)}
              style={{ margin: 16 }}
              color="primary"
            >
              Apply
            </Button>
          </Grid>
          <Grid item xs={12}>
            {opportunityPending && <Loading size={100} />}
            {opportunityPending && (
              <Typography style={{ textAlign: "center" }} variant="caption">
                This may take a while.
              </Typography>
            )}
            {opportunityFailed && (
              <Snackbar open={opportunityFailed}>
                Opportunity failed loading.
              </Snackbar>
            )}
            {!opportunityFailed && !opportunityPending && data.length > 0 && (
              <SortableDataTable
                data={data}
                headerLabels={["Property (Value)", ...headerLabels]}
                keys={keys}
                withoutBox
                withoutSerialNumbers
                comparators={comparators}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );

}