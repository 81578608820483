import {
  Box,
  Button,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core"
import Apxor from "apxor"
import React, { memo, useCallback, useState } from "react"
import { useHistory } from "react-router-dom"
import { DEMO_ECOMMERCE_APP_ID } from "../../../../config"
import ExperienceDemoFinishStep from "../../../Dashboard/components/dashboard/components/sales-sandbox/ExperienceDemoFinishStep"
import CustomMaterialUIDialog from "../../../ReusableComponents/CustomMaterialUIDialog"
import { SET_ACCESS_CODE, SET_AUTH_ERROR } from "../../constants"
import { useDispatch, useTrackedState } from "../../store"
import { AUTH_FLOW_STATES, STEPPER_ACTION_TYPES } from "../constants"
import useSandBox from "../hooks/use-sandbox"
import ExperienceDemoEmailForm from "./forms/ExperienceDemoEmailForm"
import ExperienceDemoSignUpForm from "./forms/ExperienceDemoSignUpForm"

const steps = [
  {
    title: "Enter Email",
    actions: [
      {
        label: STEPPER_ACTION_TYPES.BACK_TO_APXOR_WEBSITE,
      },
      {
        label: STEPPER_ACTION_TYPES.CONTINUE,
      },
    ],
  },
  {
    title: "Sign In/Sign Up",
    actions: [
      {
        label: STEPPER_ACTION_TYPES.BACK_TO_APXOR_WEBSITE,
      },
      {
        label: STEPPER_ACTION_TYPES.VERIFY,
      },
    ],
  },
  {
    title: "App Installation",
    actions: [
      {
        label: STEPPER_ACTION_TYPES.PROCEED,
      },
    ],
  },
]

function ExperienceDemoAuthDialog({ open, onClose }) {
  const {
    authenticate,
    initialise,
    signInDisabled,
    signUpDisabled,
  } = useSandBox()
  const { push } = useHistory()
  const dispatch = useDispatch()
  const {
    user: { email, phone, otp, test_devices, country_code },
    auth_error,
    auth_loading,
  } = useTrackedState()

  const [activeStep, setActiveStep] = useState(0)

  const handleContinue = useCallback(async () => {
    const res = await initialise(email)

    if (res?.type === AUTH_FLOW_STATES.OTP) {
      setActiveStep((activeStep) => activeStep + 1)
    } else if (res?.type === AUTH_FLOW_STATES.USER) {
      push(`/apps/${DEMO_ECOMMERCE_APP_ID}/dashboard`, {
        from_login: true,
      })
      onClose()
    } else if (res?.type === AUTH_FLOW_STATES.FINAL) {
      setActiveStep((activeStep) => activeStep + 2)
      dispatch({
        type: SET_ACCESS_CODE,
        payload: res.data.access_code,
      })
    }
    Apxor.logEvent("Email_Entered", {
      "email": email,
    })
    Apxor.logEvent("Phone_Entered", {
      "phone": phone,
    })
  }, [dispatch, email, phone, initialise, onClose, push])

  const handleVerify = useCallback(async () => {
    const res = await authenticate()
    if (res) {
      window.lintrk("track", { conversion_id: 14455513 })

      window.gtag("event", "conversion", {
        send_to: "AW-327181277/9qyrCPr1-rsYEN3HgZwB",
      })

      Apxor.logEvent("Email_Verified", { 
        "email": email
       })
      Apxor.logEvent("User_Registered", {
        "email": email,
        "phone": `${phone} ${country_code}`,
      })
      setActiveStep((activeStep) => activeStep + 1)
    }
  }, [authenticate, email, phone, country_code])

  const handleProceed = useCallback(() => {
    push(`/apps/${DEMO_ECOMMERCE_APP_ID}/dashboard`, {
      from_login: true,
    })
    Apxor.logEvent("App_Verified", { 
      "email": email
     })
    Apxor.logEvent("Proceed_Clicked", { 
      "email": email
     })

    onClose()
  }, [onClose, push, email])

  const handleActionButton = useCallback(
    (from) => {
      // eslint-disable-next-line default-case
      switch (activeStep) {
        case 0: {
          if (email?.length === 0) {
            dispatch({
              type: SET_AUTH_ERROR,
              payload: "Please enter an email address",
            })
            return
          }
          break
        }
        case 1: {
          if (otp?.length === 0) {
            dispatch({
              type: SET_AUTH_ERROR,
              payload: "Please enter the OTP that was sent to your email",
            })
            return
          } else if (otp?.length !== 6) {
            dispatch({
              type: SET_AUTH_ERROR,
              payload: "Please enter a valid OTP",
            })
            return
          }
          break
        }
      }
      if (auth_error?.length === 0) {
        // eslint-disable-next-line default-case
        switch (from) {
          case STEPPER_ACTION_TYPES.CONTINUE:
            return handleContinue()
          case STEPPER_ACTION_TYPES.VERIFY:
            return handleVerify()
          case STEPPER_ACTION_TYPES.PROCEED:
            return handleProceed()
        }
      }
    },
    [
      email,
      activeStep,
      auth_error,
      otp,
      dispatch,
      handleContinue,
      handleVerify,
      handleProceed,
    ],
  )

  return (
    <CustomMaterialUIDialog
      openFlag={open}
      hideCloseIcon={true}
      onClose={() => {}}
      onDialogClose={() => {}}
      maxWidth={activeStep === 2 ? "md" : "sm"}
      dialogContent={
        <form noValidate>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={index}>
                <StepLabel>
                  <Typography
                    style={{
                      fontSize: "25px",
                      fontWeight: "700",
                    }}
                  >
                    {step.title}
                  </Typography>
                </StepLabel>
                <StepContent>
                  {index === 0 && <ExperienceDemoEmailForm />}
                  {index === 1 && (
                    <ExperienceDemoSignUpForm setActiveStep={setActiveStep} />
                  )}
                  {index === 2 && (
                    <ExperienceDemoFinishStep close_dialog={onClose} />
                  )}
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent:
                        activeStep === steps.length - 1 ? "start" : "end",
                      padding: "28px 0px",
                    }}
                  >
                    {step.actions[0].label ===
                      STEPPER_ACTION_TYPES.BACK_TO_APXOR_WEBSITE && (
                      <a
                        href="https://apxor.com"
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <Button
                          variant="text"
                          size="large"
                          style={{
                            fontWeight: "700",
                            fontSize: "16px",
                          }}
                        >
                          {STEPPER_ACTION_TYPES.BACK_TO_APXOR_WEBSITE}
                        </Button>
                      </a>
                    )}
                    {step.actions[0].label === STEPPER_ACTION_TYPES.PROCEED && (
                      <Button
                        type="submit"
                        size="large"
                        variant="contained"
                        color="primary"
                        style={{
                          color: "white",
                          fontWeight: "700",
                          fontSize: "16px",
                        }}
                        disabled={test_devices?.length === 0}
                        onClick={async () =>
                          await handleActionButton(STEPPER_ACTION_TYPES.PROCEED)
                        }
                      >
                        {auth_loading
                          ? "Loading..."
                          : STEPPER_ACTION_TYPES.PROCEED}
                      </Button>
                    )}
                    {step.actions[1] &&
                      step.actions[1].label ===
                        STEPPER_ACTION_TYPES.CONTINUE && (
                        <Button
                          type="submit"
                          size="large"
                          variant="contained"
                          color="primary"
                          style={{
                            marginLeft: "30px",
                            color: "white",
                            fontWeight: "700",
                            fontSize: "16px",
                          }}
                          disabled={signInDisabled || auth_loading}
                          onClick={async () =>
                            await handleActionButton(
                              STEPPER_ACTION_TYPES.CONTINUE,
                            )
                          }
                        >
                          {auth_loading
                            ? "Loading..."
                            : STEPPER_ACTION_TYPES.CONTINUE}
                        </Button>
                      )}
                    {step.actions[1] &&
                      step.actions[1].label === STEPPER_ACTION_TYPES.VERIFY && (
                        <Button
                          type="submit"
                          size="large"
                          variant="contained"
                          color="primary"
                          style={{
                            marginLeft: "30px",
                            color: "white",
                            fontWeight: "700",
                            fontSize: "16px",
                          }}
                          disabled={signUpDisabled || auth_loading}
                          onClick={async () =>
                            await handleActionButton(
                              STEPPER_ACTION_TYPES.VERIFY,
                            )
                          }
                        >
                          {auth_loading
                            ? "Loading..."
                            : STEPPER_ACTION_TYPES.VERIFY}
                        </Button>
                      )}
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </form>
      }
    />
  )
}

export default memo(ExperienceDemoAuthDialog)

