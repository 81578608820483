/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState } from "react";
import withStyles from "@material-ui/core/es/styles/withStyles";
import { Box, Grid, Tooltip } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VolumeOnIcon from "@material-ui/icons/VolumeUp";
import {
  ButtonsHolder,
  DEFAULT_IMAGE_DATA,
  HoverDiv,
  ImageHolder,
  TextHolder,
  VideoButtonHolder,
} from "../../utils";
import { replaceMacrosWithDefault } from "../../../../../../../../../../utils";
import {
  MODULE_TYPES,
  INAPP_LAYOUT_TYPES,
  CONVERT_INAPP_TO_HTML,
} from "../../../../constants";
import InAppContainer from "./components/InAppContainer";
import ConvertToCustomHTMLDialogContent from "./components/ConvertToCustomHTMLDialogContent";
import custom_html_switch from "../../.././../assets/custom_html.svg";
import { ReactComponent as MinimizeIcon } from "../../.././../assets/minimize_icon.svg";
import { useDispatch } from "../../../../store";
import CustomMaterialUIDialog from "../../../../../../../../../ReusableComponents/CustomMaterialUIDialog";
import video_inapp from "../../../../assets/video_inapp.svg";

const LightTooltip = withStyles((theme) => ({
  arrow: {
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const styles = (theme) => ({
  top: {
    "&:before": {
      content: '""',
      position: "absolute",
      left: "70px",
      top: 0,
      width: "50%",
      borderTop: "1px dashed #606060",
    },
  },
  bottom: {
    "&:before": {
      content: '""',
      position: "absolute",
      left: "70px",
      bottom: 0,
      width: "50%",
      borderBottom: "1px dashed #606060",
    },
  },
  left: {
    "&:before": {
      content: '""',
      position: "absolute",
      left: 0,
      top: "175px",
      height: "40%",
      borderLeft: "1px dashed #606060",
    },
  },
  right: {
    "&:before": {
      content: '""',
      position: "absolute",
      right: 0,
      top: "175px",
      height: "40%",
      borderRight: "1px dashed #606060",
    },
  },
  carouselNavigation: {
    position: "relative",
    right: 0,
    bottom: 0,
    left: 0,
    textAlign: "center",
  },
  carouseNavigationItem: {
    display: "inline-block",
  },
  carouselNavigationButton: {
    display: "inline-block",
    width: "0.75rem",
    height: "0.75rem",
    backgroundColor: "#000000",
    backgroundClip: "content-box",
    border: "0.15rem solid transparent",
    borderRadius: "50%",
    fontSize: 0,
  },
  carouselNavigationButtonActive: {
    display: "inline-block",
    width: "0.75rem",
    height: "0.75rem",
    backgroundColor: "#017DFD",
    backgroundClip: "content-box",
    border: "0.15rem solid transparent",
    borderRadius: "50%",
    fontSize: 0,
  },
});

const isCustomHTML = (layout_type) =>
  layout_type === INAPP_LAYOUT_TYPES.CUSTOM_HTML;

const isHeader = (layout_type) => layout_type === INAPP_LAYOUT_TYPES.HEADER;

const isFooter = (layout_type) => layout_type === INAPP_LAYOUT_TYPES.FOOTER;

const isPopoutModal = (layout_type) =>
  layout_type === INAPP_LAYOUT_TYPES.POPOUT_MODAL;

const isFullScreenModal = (layout_type) =>
  layout_type === INAPP_LAYOUT_TYPES.FULL_SCREEN_MODAL;
const isCarousel = (layout_type) => layout_type === INAPP_LAYOUT_TYPES.CAROUSEL;

const isFloating = (layout_type) => layout_type === INAPP_LAYOUT_TYPES.FLOATING;

const isVideo = (layout_type) => layout_type === INAPP_LAYOUT_TYPES.VIDEOS;

const VideoButtonsWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

const InAppSimulator = ({
  classes,
  moduleType,
  ui,
  onModuleSelection,
  type,
}) => {
  const {
    image,
    text_config,
    x_icon_color,
    mini_color,
    sound_icon_color,
    title_config,
    add_audio,
    mute_audio,
    buttons_config,
    layout_type,
    bg_color,
    is_full_scr,
    only_image,
    bg_image,
    border_radius = 8,
    image_size,
    window_attributes: { dimmed_background },
    html_config: { data },
    orientation,
    carousel: { images } = {},
  } = ui;

  const dispatch = useDispatch();

  let defaultSimulatorWidth = orientation === "Portrait" ? 270 : 540;
  let defaultSimulatorHeight = orientation === "Portrait" ? 540 : 270;
  const defaultVideoBtnFontSize = "18px";

  const [showConfirmation, setShowConfirmation] = useState(false);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {!isCustomHTML(layout_type) &&
        !isCarousel(layout_type) &&
        !isVideo(layout_type) && (
          <div
            style={{
              position: "absolute",
              display: "inline-block",
              top: 0,
              right: 12,
              cursor: "pointer",
            }}
            onClick={() => {
              setShowConfirmation(true);
            }}
          >
            <LightTooltip
              title="Customize this template"
              arrow
              placement="left"
            >
              <img
                src={custom_html_switch}
                alt="custom_html_switch"
                height="35"
              />
            </LightTooltip>
          </div>
        )}
      <CustomMaterialUIDialog
        dialogProps={{
          disableBackdropClick: true,
          disableEscapeKeyDown: true,
        }}
        hideCloseIcon={true}
        dialogContent={
          <ConvertToCustomHTMLDialogContent
            type={type}
            handleCancel={() => {
              setShowConfirmation(false);
            }}
            handleDone={() => {
              setShowConfirmation(false);
              dispatch({ type: CONVERT_INAPP_TO_HTML });
            }}
            ui={ui}
          />
        }
        dialogContentStyles={{ padding: 16 }}
        maxWidth="sm"
        openFlag={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onDialogClose={() => setShowConfirmation(false)}
      />
      <div style={{ marginBottom: 10, marginTop: 30, color: "#B3C0CA" }}>
        Top
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginRight: 10, color: "#B3C0CA" }}>Left</div>
        {/* Header and Footer logic goes here */}
        {(isHeader(layout_type) || isFooter(layout_type)) && (
          <InAppContainer
            orientation={orientation}
            dimmed_background={dimmed_background}
            isFooter={isFooter(layout_type)}
            children={
              <HoverDiv
                isFooter={isFooter(layout_type)}
                style={{
                  border:
                    moduleType === MODULE_TYPES.BACKGROUND
                      ? "1px dashed #2979ff"
                      : "",
                  backgroundColor: !only_image ? bg_color || "#FFFFFF" : "",
                  borderBottomRightRadius: isHeader(layout_type)
                    ? border_radius
                    : 0,
                  borderBottomLeftRadius: isHeader(layout_type)
                    ? border_radius
                    : 0,
                  borderTopRightRadius: isFooter(layout_type)
                    ? border_radius
                    : 0,
                  borderTopLeftRadius: isFooter(layout_type)
                    ? border_radius
                    : 0,
                }}
                onClick={(e) => {
                  onModuleSelection(e, MODULE_TYPES.BACKGROUND);
                }}
              >
                <div className={classes.top}></div>
                <div className={classes.bottom}></div>
                <div className={classes.left}></div>
                <div className={classes.right}></div>
                <div
                  id="InApp"
                  style={{
                    width: "100%",
                    backgroundSize:
                      image_size === "Stretch"
                        ? "100% 100%"
                        : image_size.toLowerCase(),
                    backgroundImage: "url(" + bg_image + ")",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  {/* Icon, Title and Description will be shown only if `only_image` flag is FALSE */}
                  {!only_image && (
                    <Grid
                      container
                      spacing={0}
                      alignItems={"stretch"}
                      justify={"center"}
                    >
                      {/* Icon on the left side */}
                      {image !== "NA" && (
                        <Grid
                          item
                          style={{ marginLeft: "10px", marginTop: "10px" }}
                        >
                          <ImageHolder
                            id="image"
                            handleModuleSelection={(e) => {
                              e.stopPropagation();
                              onModuleSelection(e, MODULE_TYPES.IMAGE_URL);
                            }}
                            selected={moduleType === MODULE_TYPES.IMAGE_URL}
                            config={image}
                            type={"URL"}
                          />
                        </Grid>
                      )}
                      <Grid
                        item
                        xs
                        sm
                        md
                        style={{
                          alignSelf: "center",
                          marginLeft: image === "NA" ? 10 : 0,
                          marginTop: image === "NA" ? 10 : 0,
                        }}
                      >
                        {/* Title */}
                        {title_config && (
                          <TextHolder
                            id="title_config"
                            handleModuleSelection={(e) => {
                              e.stopPropagation();
                              onModuleSelection(e, MODULE_TYPES.TITLE);
                            }}
                            selected={moduleType === MODULE_TYPES.TITLE}
                            bgColor={ui.bg_color}
                            config={title_config}
                            style={{
                              paddingTop: "5px",
                              paddingLeft: image !== "NA" ? 6 : 0,
                              maxWidth: 194,
                            }}
                          />
                        )}
                        {/* Description */}
                        {text_config && (
                          <TextHolder
                            id="text_config"
                            handleModuleSelection={(e) => {
                              e.stopPropagation();
                              onModuleSelection(e, MODULE_TYPES.DESCRIPTION);
                            }}
                            selected={moduleType === MODULE_TYPES.DESCRIPTION}
                            bgColor={ui.bg_color}
                            config={text_config}
                            style={{
                              maxWidth: 194,
                              paddingTop: "5px",
                              paddingLeft: image !== "NA" ? 6 : 0,
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  )}
                  {/* Buttons will be shown only if `only_image` flag is FALSE */}
                  {(layout_type === INAPP_LAYOUT_TYPES.HEADER ||
                    layout_type === INAPP_LAYOUT_TYPES.FOOTER) &&
                    !only_image &&
                    buttons_config &&
                    buttons_config.length > 0 && (
                      <div style={{ marginTop: "8px", marginBottom: "8px" }}>
                        <ButtonsHolder
                          id="buttons_holder"
                          style={{
                            textAlign: "center",
                            width: "100%",
                            padding: "6px",
                          }}
                          onModuleSelection={(e, name) =>
                            onModuleSelection(e, name)
                          }
                          bgColor={ui.bg_color}
                          config={buttons_config}
                          moduleType={moduleType}
                          isPure={true}
                          buttonHolderStyle={{ margin: "0px 4px" }}
                        />
                      </div>
                    )}
                </div>
              </HoverDiv>
            }
          />
        )}
        {isCustomHTML(layout_type) && (
          <InAppContainer
            orientation={orientation}
            dimmed_background={dimmed_background}
            isFooter={isFooter(layout_type)}
            children={
              <div>
                <div className={classes.top}></div>
                <div className={classes.bottom}></div>
                <div className={classes.left}></div>
                <div className={classes.right}></div>
                <div
                  style={{ width: "100%", height: "585px" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  dangerouslySetInnerHTML={{
                    __html: replaceMacrosWithDefault(data.replace("%23", "#")),
                  }}
                />
              </div>
            }
          />
        )}
        {/* Popout Modal logic goes here*/}
        {isPopoutModal(layout_type) && (
          <HoverDiv
            style={{
              border:
                moduleType === MODULE_TYPES.BACKGROUND
                  ? "1px dashed #2979ff"
                  : "",
            }}
            onClick={(e) => {
              onModuleSelection(e, MODULE_TYPES.BACKGROUND);
            }}
          >
            <div
              style={{
                overflow: "hidden",
                width: defaultSimulatorWidth,
                height: defaultSimulatorHeight,
                backgroundColor: dimmed_background
                  ? "rgb(51, 51, 51, 0.45)"
                  : "",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                position: "relative",
              }}
            >
              <div className={classes.top}></div>
              <div className={classes.bottom}></div>
              <div className={classes.left}></div>
              <div className={classes.right}></div>
              <div
                id="InApp"
                style={{
                  width: "85%",
                  maxHeight: "99%",
                  minHeight: "50%",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: !only_image ? bg_color || "#FFF" : "",
                  backgroundSize:
                    image_size === "Stretch"
                      ? "100% 100%"
                      : image_size.toLowerCase(),
                  backgroundImage: "url(" + bg_image + ")",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  borderRadius: border_radius,
                }}
              >
                <a
                  href={"apxor://xclose"}
                  style={{
                    margin: "-8px 12px",
                    right: orientation === "Portrait" ? 0 : 24,
                    fontSize: 16,
                    lineHeight: "12px",
                    position: "absolute",
                    width: 18,
                    height: 18,
                    textAlign: "center",
                    background: "#ffffff",
                    borderRadius: 36,
                    color: "#ffffff",
                    border: "2px solid white",
                    backgroundImage:
                      "url('https://res.cloudinary.com/apxor/image/upload/c_scale,h_64/v1586432138/close-icon_nirvzq.png')",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                />
                {!only_image && (
                  <Grid
                    container
                    spacing={0}
                    alignItems={"stretch"}
                    justify={"center"}
                    style={{ marginTop: 15, minHeight: "57%" }}
                  >
                    {(image || image === "") && (
                      <Grid item style={{ width: "90%" }}>
                        <ImageHolder
                          id="image"
                          handleModuleSelection={(e) => {
                            e.stopPropagation();
                            onModuleSelection(e, MODULE_TYPES.IMAGE_URL);
                          }}
                          selected={moduleType === MODULE_TYPES.IMAGE_URL}
                          config={image}
                          type={"URL"}
                          newUI={ui}
                          style={{ width: "unset", height: "100%" }}
                          isPure={false}
                          useImgTag={false}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
                {!only_image && (
                  <Grid
                    container
                    spacing={0}
                    alignItems={"stretch"}
                    justify={"center"}
                  >
                    <Grid
                      item
                      xs
                      sm
                      md
                      style={{ alignSelf: "center", marginTop: 5 }}
                    >
                      {/* Title */}
                      {title_config && (
                        <TextHolder
                          id="title_config"
                          handleModuleSelection={(e) => {
                            e.stopPropagation();
                            onModuleSelection(e, MODULE_TYPES.TITLE);
                          }}
                          selected={moduleType === MODULE_TYPES.TITLE}
                          bgColor={ui.bg_color}
                          config={title_config}
                          style={{
                            textAlign: "center",
                            marginLeft: 5,
                            marginRight: 5,
                          }}
                        />
                      )}
                      {/* Description */}
                      {text_config && (
                        <TextHolder
                          id="text_config"
                          handleModuleSelection={(e) => {
                            e.stopPropagation();
                            onModuleSelection(e, MODULE_TYPES.DESCRIPTION);
                          }}
                          selected={moduleType === MODULE_TYPES.DESCRIPTION}
                          bgColor={ui.bg_color}
                          config={text_config}
                          style={{
                            textAlign: "center",
                            marginLeft: 5,
                            marginRight: 5,
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                )}
                {!only_image && buttons_config && buttons_config.length > 0 && (
                  <Grid container spacing={0}>
                    <Grid item xs sm md>
                      <ButtonsHolder
                        id="buttons_holder"
                        style={{
                          textAlign: "center",
                          padding: "6px",
                          margin: 8,
                        }}
                        onModuleSelection={(e, name) =>
                          onModuleSelection(e, name)
                        }
                        moduleType={moduleType}
                        bgColor={ui.bg_color}
                        config={buttons_config}
                        isPure={true}
                      />
                    </Grid>
                  </Grid>
                )}
                {only_image && (
                  <Grid container spacing={0} style={{ height: "100%" }}>
                    <Grid item xs={12} style={{ height: "100%" }}>
                      <ImageHolder
                        id="bg_image"
                        handleModuleSelection={(e) => {
                          e.stopPropagation();
                          onModuleSelection(e, MODULE_TYPES.BG_IMAGE_URL);
                        }}
                        selected={moduleType === MODULE_TYPES.BG_IMAGE_URL}
                        config={bg_image === "" ? DEFAULT_IMAGE_DATA : bg_image}
                        type={
                          bg_image === "" || bg_image === DEFAULT_IMAGE_DATA
                            ? "FILE"
                            : "URL"
                        }
                        newUI={ui}
                        isPure={false}
                        useImgTag={
                          bg_image === "" || bg_image === DEFAULT_IMAGE_DATA
                        }
                        style={{ width: "100%", height: "100%" }}
                      />
                    </Grid>
                  </Grid>
                )}
              </div>
            </div>
          </HoverDiv>
        )}
        {/* Full Screen Modal */}
        {isFullScreenModal(layout_type) && (
          <HoverDiv
            style={{
              border:
                moduleType === MODULE_TYPES.BACKGROUND
                  ? "1px dashed #2979ff"
                  : "",
            }}
            onClick={(e) => {
              onModuleSelection(e, MODULE_TYPES.BACKGROUND);
            }}
          >
            <div
              style={{
                overflow: "hidden",
                width: defaultSimulatorWidth,
                height: defaultSimulatorHeight,
                backgroundColor: dimmed_background
                  ? "rgb(51, 51, 51, 0.4)"
                  : "",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                position: "relative",
                backgroundSize:
                  image_size === "Stretch"
                    ? "100% 100%"
                    : image_size.toLowerCase(),
                backgroundImage: "url(" + bg_image + ")",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className={classes.top}></div>
              <div className={classes.bottom}></div>
              <div className={classes.left}></div>
              <div className={classes.right}></div>
              <a
                style={{
                  top: "3%",
                  right: "2%",
                  fontSize: 16,
                  lineHeight: "12px",
                  position: "absolute",
                  width: 18,
                  height: 18,
                  textAlign: "center",
                  background: "#ffffff",
                  borderRadius: 36,
                  color: "#ffffff",
                  border: "2px solid white",
                  backgroundImage:
                    "url('https://res.cloudinary.com/apxor/image/upload/c_scale,h_64/v1586432138/close-icon_nirvzq.png')",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
                href={"apxor://xclose"}
              />

              {!only_image && (
                <Grid
                  container
                  spacing={0}
                  style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "100%",
                    textAlign: "center",
                    padding: 12,
                  }}
                >
                  <Grid item style={{ marginBottom: 6 }}>
                    {!only_image && (
                      <TextHolder
                        id="title_config"
                        handleModuleSelection={(e) => {
                          e.stopPropagation();
                          onModuleSelection(e, MODULE_TYPES.TITLE);
                        }}
                        selected={moduleType === MODULE_TYPES.TITLE}
                        bgColor={ui.bg_color}
                        config={title_config}
                        style={{
                          paddingTop: "5px",
                          display: "-webkit-box",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                          alignSelf: "center",
                          textAlign: "center",
                        }}
                      />
                    )}
                  </Grid>

                  {(image || image === "") && (
                    <Grid
                      item
                      style={{
                        maxHeight: "70%",
                        minHeight: "70%",
                        overflow: "hidden",
                        borderTopLeftRadius: border_radius,
                        borderTopRightRadius: border_radius,
                      }}
                    >
                      <ImageHolder
                        id="image"
                        handleModuleSelection={(e) => {
                          e.stopPropagation();
                          onModuleSelection(e, MODULE_TYPES.IMAGE_URL);
                        }}
                        selected={moduleType === MODULE_TYPES.IMAGE_URL}
                        config={image}
                        type={"URL"}
                        style={{ width: "100%", height: "100%", border: 0 }}
                        isPure={false}
                        newUI={ui}
                        useImgTag={false}
                      />
                    </Grid>
                  )}
                  {!only_image && (
                    <Grid
                      item
                      style={{
                        overflow: "hidden",
                        borderBottomLeftRadius: border_radius,
                        borderBottomRightRadius: border_radius,
                        marginTop: "-2px",
                      }}
                    >
                      {buttons_config && buttons_config.length > 0 && (
                        <ButtonsHolder
                          id="buttons_holder"
                          buttonHolderStyle={{ padding: 0, border: 0 }}
                          style={{
                            textAlign: "center",
                            padding: "6px",
                            borderRadius: 0,
                          }}
                          onModuleSelection={(e, name) =>
                            onModuleSelection(e, name)
                          }
                          moduleType={moduleType}
                          bgColor={ui.bg_color}
                          config={buttons_config}
                          isPure={true}
                          buttonStyledHolderStyle={{ border: "unset" }}
                          buttonMarginRight={1}
                        />
                      )}
                    </Grid>
                  )}
                </Grid>
              )}
              {only_image && (
                <Grid container spacing={0} style={{ height: "100%" }}>
                  <Grid item xs={12} style={{ height: "100%" }}>
                    <ImageHolder
                      id="bg_image"
                      handleModuleSelection={(e) => {
                        e.stopPropagation();
                        onModuleSelection(e, MODULE_TYPES.BG_IMAGE_URL);
                      }}
                      selected={moduleType === MODULE_TYPES.BG_IMAGE_URL}
                      config={bg_image === "" ? DEFAULT_IMAGE_DATA : bg_image}
                      type={
                        bg_image === "" || bg_image === DEFAULT_IMAGE_DATA
                          ? "FILE"
                          : "URL"
                      }
                      newUI={ui}
                      isPure={false}
                      useImgTag={
                        bg_image === "" || bg_image === DEFAULT_IMAGE_DATA
                      }
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Grid>
                </Grid>
              )}
            </div>
          </HoverDiv>
        )}
        {/* Carousel logic goes here */}
        {isCarousel(layout_type) && (
          <HoverDiv
            style={{
              border:
                moduleType === MODULE_TYPES.BACKGROUND
                  ? "1px dashed #2979ff"
                  : "",
            }}
          >
            <div
              style={{
                overflow: "hidden",
                width: defaultSimulatorWidth,
                height: defaultSimulatorHeight,
                backgroundColor: dimmed_background
                  ? "rgb(51, 51, 51, 0.45)"
                  : "",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                position: "relative",
              }}
            >
              <div className={classes.top}></div>
              <div className={classes.bottom}></div>
              <div className={classes.left}></div>
              <div className={classes.right}></div>
              <div
                id="InApp"
                style={{
                  width: "85%",
                  maxHeight: "99%",
                  minHeight: "50%",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "",
                  backgroundSize:
                    image_size === "Stretch"
                      ? "100% 100%"
                      : image_size.toLowerCase(),
                  backgroundImage: "url(" + bg_image + ")",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  borderRadius: border_radius,
                }}
              >
                <a
                  href={"apxor://xclose"}
                  style={{
                    margin: "-8px 12px",
                    right: orientation === "Portrait" ? 0 : 24,
                    fontSize: 16,
                    lineHeight: "12px",
                    position: "absolute",
                    width: 18,
                    height: 18,
                    textAlign: "center",
                    background: "#ffffff",
                    borderRadius: 36,
                    color: "#ffffff",
                    border: "2px solid white",
                    backgroundImage:
                      "url('https://res.cloudinary.com/apxor/image/upload/c_scale,h_64/v1586432138/close-icon_nirvzq.png')",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                />

                {
                  <Grid container spacing={0} style={{ height: "100%" }}>
                    <Grid item xs={12} style={{ height: "100%" }}>
                      <ImageHolder
                        isPure={true}
                        id="bg_image"
                        config={images ? images[0] : DEFAULT_IMAGE_DATA}
                        type={images ? "URL" : "FILE"}
                        newUI={ui}
                        style={{ width: "100%", height: "100%" }}
                      ></ImageHolder>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ marginTop: "-50px", marginLeft: "-20px" }}
                    >
                      <aside class={classes.carouselNavigation}>
                        <ol class={classes.carouseNavigationItem}>
                          <li class={classes.carouseNavigationItem}>
                            <a class={classes.carouselNavigationButtonActive}>
                              1
                            </a>
                          </li>
                          <li class={classes.carouseNavigationItem}>
                            <a class={classes.carouselNavigationButton}>2</a>
                          </li>
                          <li class={classes.carouseNavigationItem}>
                            <a class={classes.carouselNavigationButton}>3</a>
                          </li>
                        </ol>
                      </aside>
                    </Grid>
                  </Grid>
                }
              </div>
            </div>
          </HoverDiv>
        )}
        {/* Video Inapp logic goes here */}
        {isVideo(layout_type) && (
          <div
            style={{
              overflow: "hidden",
              width:
                orientation === "Portrait"
                  ? defaultSimulatorWidth
                  : defaultSimulatorHeight,
              height:
                orientation === "Portrait"
                  ? defaultSimulatorHeight
                  : defaultSimulatorWidth,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <div className={classes.top}></div>
            <div className={classes.bottom}></div>
            <div className={classes.left}></div>
            <div className={classes.right}></div>
            <Box
              id="InApp"
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#E1E4E7",
              }}
            >
              <VideoButtonsWrapper
                condition={!is_full_scr}
                wrapper={children => (
                  <HoverDiv
                    style={{
                      border:
                        moduleType === MODULE_TYPES.BACKGROUND
                          ? "1px dashed #2979ff"
                          : "",
                      height: "8%",
                    }}
                    onClick={e => {
                      onModuleSelection(e, MODULE_TYPES.BACKGROUND);
                    }}
                  >
                    {children}
                  </HoverDiv>
                )}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: is_full_scr ? "#E1E4E7" : bg_color,
                  }}
                >
                  <VideoButtonHolder
                    config={{
                      color: x_icon_color,
                    }}
                    moduleType={moduleType}
                    onModuleSelection={(e, name) => {
                      onModuleSelection(e, name);
                    }}
                  >
                    <CloseIcon
                      style={{
                        fontSize: defaultVideoBtnFontSize,
                      }}
                    />
                  </VideoButtonHolder>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <VideoButtonHolder
                      config={{
                        color: mini_color,
                      }}
                      moduleType={moduleType}
                      onModuleSelection={(e, name) => {
                        onModuleSelection(e, name);
                      }}
                    >
                      <MinimizeIcon
                        width={defaultVideoBtnFontSize}
                        height={defaultVideoBtnFontSize}
                        stroke={mini_color}
                      />
                    </VideoButtonHolder>
                    {add_audio && (
                      <VideoButtonHolder
                        config={{
                          color: sound_icon_color,
                        }}
                        moduleType={moduleType}
                        onModuleSelection={(e, name) => {
                          onModuleSelection(e, name);
                        }}
                      >
                        {mute_audio ? (
                          <VolumeOffIcon
                            style={{
                              fontSize: defaultVideoBtnFontSize,
                            }}
                          />
                        ) : (
                          <VolumeOnIcon
                            style={{
                              fontSize: defaultVideoBtnFontSize,
                            }}
                          />
                        )}
                      </VideoButtonHolder>
                    )}
                  </Box>
                </Box>
              </VideoButtonsWrapper>
              <Box
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={video_inapp} height="72px" width="72px" alt="" />
              </Box>
              {buttons_config && buttons_config.length > 0 && (
                <Grid container spacing={0}>
                  <Grid item xs sm md>
                    <Box
                      style={{
                        paddingInline: "10px",
                      }}
                    >
                      <ButtonsHolder
                        id="buttons_holder"
                        style={{
                          textAlign: "center",
                          padding: "10px",
                          marginBottom: "20px",
                        }}
                        onModuleSelection={(e, name) =>
                          onModuleSelection(e, name)
                        }
                        moduleType={moduleType}
                        bgColor={ui.bg_color}
                        config={buttons_config}
                        isPure={true}
                      />
                    </Box>
                  </Grid>
                </Grid>
              )}
              {!is_full_scr && (
                <HoverDiv
                  style={{
                    border:
                      moduleType === MODULE_TYPES.BACKGROUND
                        ? "1px dashed #2979ff"
                        : "",
                    paddingInline: defaultSimulatorWidth * 0.08,
                    paddingBlock: defaultSimulatorHeight * 0.08,
                    backgroundColor: bg_color,
                  }}
                  onClick={e => {
                    onModuleSelection(e, MODULE_TYPES.BACKGROUND);
                  }}
                />
              )}
            </Box>
          </div>
        )}
        {/* Floating Inapp logic goes here */}
        {isFloating(layout_type) && (
          <InAppContainer
            orientation={orientation}
            isFooter={true}
            children={
              <HoverDiv
                isFooter={isFooter(layout_type)}
                style={{
                  height: "50%",
                }}
                onClick={(e) => {
                  onModuleSelection(e, MODULE_TYPES.BACKGROUND);
                }}
              >
                <div className={classes.top}></div>
                <div className={classes.bottom}></div>
                <div className={classes.left}></div>
                <div className={classes.right}></div>

                <div
                  id="InApp"
                  style={{
                    width: "85%",
                    height: "90%",
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "",
                    backgroundSize:
                      image_size === "Stretch"
                        ? "100% 100%"
                        : image_size.toLowerCase(),
                    backgroundImage: "url(" + bg_image + ")",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    borderRadius: border_radius,
                    justifyContent: "end",
                  }}
                >
                  <a
                    href={"apxor://xclose"}
                    style={{
                      margin: "-8px 12px",
                      right: orientation === "Portrait" ? 0 : 24,
                      fontSize: 16,
                      lineHeight: "12px",
                      position: "absolute",
                      width: 18,
                      height: 18,
                      textAlign: "center",
                      background: "#ffffff",
                      borderRadius: 36,
                      color: "#ffffff",
                      border: "2px solid white",
                      backgroundImage:
                        "url('https://res.cloudinary.com/apxor/image/upload/c_scale,h_64/v1586432138/close-icon_nirvzq.png')",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  />

                  {
                    <Grid container spacing={0} style={{ height: "130px" }}>
                      <Grid item xs={12} style={{ height: "100%" }}>
                        <ImageHolder
                          isPure={true}
                          id="bg_image"
                          config={images ? images[0] : DEFAULT_IMAGE_DATA}
                          type={images ? "URL" : "FILE"}
                          newUI={ui}
                          style={{ width: "110px", height: "100%" }}
                        ></ImageHolder>
                      </Grid>
                    </Grid>
                  }
                </div>
              </HoverDiv>
            }
          />
        )}
        <div style={{ marginLeft: 10, color: "#B3C0CA" }}>Right</div>
      </div>
      <div style={{ marginTop: 10, color: "#B3C0CA" }}>Bottom</div>
    </div>
  );
};

export default withStyles(styles)(InAppSimulator);
