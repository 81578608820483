import React, { useState, useMemo } from "react";
import { CustomReportHolder } from "./components/CustomReportHolder";
import { Divider } from "@material-ui/core";
import Filters from "../../common/components/Filters";
import { useDateFilters } from "../../common/utils";

export default function CustomReports({ app }) {
  const [dateFilters, setDateFilters] = useDateFilters();
  const [version, setVersion] = useState(null);
  const [cohortId, setCohortId] = useState(null);

  const queryParams = useMemo(
    () => ({ ...dateFilters, versions: version, cohort: cohortId }),
    [dateFilters, version, cohortId]
  );

  return (
    <>
      <Filters
        app={app}
        dateFilters={dateFilters}
        showCohort
        showVersion
        onVersionChange={(version) =>
          setVersion(version === "" ? null : version)
        }
        onCohortChange={(cohortId) =>
          setCohortId(cohortId === "" ? null : cohortId)
        }
        onDateFiltersChange={(start, end) =>
          setDateFilters({
            since: start,
            till: end,
          })
        }
      />
      <Divider />
      <section className="content">
        <CustomReportHolder app={app} queryParams={queryParams} />
      </section>
    </>
  );
}
