import { makeDefaultQueryString } from "../../utils";
import { callApi } from "../../api";
import { APP_PLATFORMS } from "../../constants";

const ADD_APP_API = "apps";
const SET_MONITORING_STATUS_API_URL = "apps/monitor";

const PLAY_STORE_ICON_URL = "/assets/img/playStore.ico";
const APP_STORE_ICON_URL = "/assets/img/defaultAppImage.png";
const WEB_ICON_URL = "/assets/img/web.png";
const FAVICON_URL = "/assets/img/favicon.png";
const OMNI_ICON_URL = "/assets/img/omni.png";

const { android, ios, web, omni } = APP_PLATFORMS;

const ICONS = {
  [android]: PLAY_STORE_ICON_URL,
  [ios]: APP_STORE_ICON_URL,
  [web]: WEB_ICON_URL,
  [omni]: OMNI_ICON_URL,
};

function getIcon(platform) {
  return ICONS[platform] || FAVICON_URL;
}

const makeAddAppModel = (auth, app) => {
  return {
    app_name: app.app_name,
    icon_url: getIcon(app.platform),
    customer_id: auth.user.email,
    bundle_id: app.bundle_id ? app.bundle_id : "",
    platform: app.platform ? app.platform : "",
  };
};

export function addAppAPI(auth, app) {
  const url = makeDefaultQueryString(ADD_APP_API, auth);
  const config = {
    method: "POST",
    auth: auth,
    body: JSON.stringify(makeAddAppModel(auth, app)),
  };
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function setMonitoringStatusAPI(auth, appId, monitoringStatus) {
  const url = makeDefaultQueryString(
    SET_MONITORING_STATUS_API_URL,
    auth,
    appId
  );
  const config = {
    method: "PATCH",
    auth: auth,
    body: JSON.stringify({ monitoring: monitoringStatus }),
  };
  const onSuccessCallback = (json) => {
    return true;
  };
  const onFailureCallback = (response) => {
    return false;
  };
  return callApi(url, config, onSuccessCallback, onFailureCallback);
}
