import { Button, Grid, IconButton, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../../../../../../contexts/AuthContext";
import { getSignedURLToGetImage } from "../../../../../../../common/actions";
import { CAMPAIGN_TYPE_ENUM } from "../../../../../../../common/components/campaigncreator/constants";
import { INAPP_LAYOUT_TYPES, SET_NEXT_STEP_DATA } from "../../../constants";
import { useTracked } from "../../../store";
import { ACTION_TEMPLATE_MAP } from "../../../templates";
import { getUI } from "../../../utils";
import axios from "axios";
const LEFT_ICON = "/assets/img/left.png";
const RIGHT_ICON = "/assets/img/right.png";

function VariantCarouselCard({
  campaignId,
  currentIndex,
  noOfCards,
  onSelect,
  ui,
}) {
  const auth = useAuth();
  const {
    ui: { action_class, layout_type },
    ui: uiJSON,
  } = ui;

  const [template, setTemplate] = useState(
    ACTION_TEMPLATE_MAP[action_class][layout_type],
  );
  const [image, setImage] = useState(null);

  useEffect(() => {
    setTemplate(ACTION_TEMPLATE_MAP[action_class][layout_type]);
  }, [layout_type, action_class]);

  useEffect(() => {
    if (ui && ui.blobName) {
      fetchImageFromGCS();
    } else setImage(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ui?.blobName]);

  const fetchImageFromGCS = () => {
    if (!ui || !ui?.blobName || !ui?.provider) {
      return;
    }

    getSignedURLToGetImage(auth, auth.appId, {
      campaignId,
      provider: ui.provider,
      blobName: ui.blobName,
    }).then((response) => {
      const { signed_url } = response;
      axios
        .get(signed_url, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          const data =
            "data:image/png;base64," +
            Buffer.from(response.data, "binary").toString("base64");
          setImage(data);
        });
    });
  };

  return (
    <Grid
      container
      style={{
        textAlign: "center",
      }}
    >
      <Grid item md={12}>
        <Typography
          style={{
            height: "22px",
            fontSize: "16px",
            fontWeight: "700",
            fontStyle: "normal",
            fontFamily: "Manrope",
            lineHeight: "22px",
            letterSpacing: "1%",
            marginBottom: "20px",
          }}
        >
          {template.display_text}
        </Typography>
      </Grid>
      <Grid item md={12}>
        {layout_type === INAPP_LAYOUT_TYPES.CUSTOM_HTML ? (
          <img
            style={{ width: "420px", height: "380px" }}
            src={template.image}
            alt="Apxor"
          />
        ) : (
          <img
            style={{ width: "190px", height: "380px" }}
            src={image || template.image}
            alt="Apxor"
          />
        )}
      </Grid>
      <Grid item md={12}>
        <Button
          variant="contained"
          style={{
            background: "rgba(125, 145, 159, 0.14)",
            height: 24,
            width: 46,
            borderRadius: 100,
            marginTop: 12,
          }}
        >
          <Typography
            style={{
              fontWeight: 700,
              fontSize: "12px",
              color: "#677A87",
              fontFamily: "Manrope",
            }}
          >
            {String(currentIndex + 1) + "/" + String(noOfCards)}
          </Typography>
        </Button>
      </Grid>
      <Grid item md={12}>
        <Button
          color="primary"
          variant="contained"
          style={{
            height: 44,
            width: 188,
            borderRadius: 3,
            marginTop: 30,
          }}
          onClick={() =>
            onSelect(
              uiJSON,
              ui?.blobName
                ? { blobName: ui.blobName, provider: ui.provider }
                : {},
            )
          }
        >
          <Typography
            color="secondary"
            style={{ fontWeight: 700, fontSize: "16px" }}
          >
            Select
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
}

export default function VariantCarousel({
  campaignId,
  handleDone,
  uiIndices,
  setStep,
  variantUISelector,
}) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const [designState, dispatch] = useTracked();
  const { uis = [] } = designState;

  return (
    <Grid
      container
      style={{
        justifyContent: "center",
        alignItems: "Center",
      }}
    >
      <Grid
        item
        md={3}
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 60,
        }}
      >
        {currentIndex !== 0 && (
          <IconButton onClick={() => setCurrentIndex(currentIndex - 1)}>
            <img src={LEFT_ICON} alt=""></img>
          </IconButton>
        )}
      </Grid>
      <Grid item md={6} style={{ width: 190, height: 380 }}>
        {getUI(uiIndices[currentIndex], uis, true) && (
          <VariantCarouselCard
            campaignId={campaignId}
            currentIndex={currentIndex}
            noOfCards={uiIndices.length}
            onSelect={(data, blobData = {}) => {
              dispatch({
                type: SET_NEXT_STEP_DATA,
                value: {
                  ui: data,
                  type: data.type,
                },
                ...blobData,
              });
              if (data.type === CAMPAIGN_TYPE_ENUM.IN_APP) {
                handleDone(null, true);
              } else if (variantUISelector) {
                handleDone({ ...data, ...blobData }, false, true);
              } else {
                setStep(1);
              }
            }}
            ui={getUI(uiIndices[currentIndex], uis, true)}
          />
        )}
      </Grid>
      <Grid
        item
        md={3}
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 60,
        }}
      >
        {currentIndex + 1 !== uiIndices.length && (
          <IconButton onClick={() => setCurrentIndex(currentIndex + 1)}>
            <img src={RIGHT_ICON} alt=""></img>
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
}
