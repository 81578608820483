import { Grid } from "@material-ui/core";
import React from "react";
import { MODULE_TYPES, TOOLTIP_LAYOUT_TYPES } from "../../../../constants";
import { ButtonsHolder, ImageHolder, TextHolder } from "../../utils";
import ActionTooltipButtonsHolder from "./components/ActionButtonsTooltip";
import TooltipContainer from "./components/TooltipContainer";

export default function TooltipSimulator({
  moduleType,
  ui,
  onModuleSelection,
}) {
  const {
    action_buttons_config = [],
    buttons_config = [],
    image,
    layout_type,
    text_config,
    title_config,
  } = ui;

  return (
    <TooltipContainer
      ui={ui}
      moduleType={moduleType}
      onModuleSelection={onModuleSelection}
    >
      {layout_type !== TOOLTIP_LAYOUT_TYPES.ACTION_BUTTONS && (
        <Grid container alignItems={"stretch"} justify={"center"}>
          {image && (
            <Grid item>
              <ImageHolder
                handleModuleSelection={(e) => {
                  e.stopPropagation();
                  onModuleSelection(e, MODULE_TYPES.IMAGE);
                }}
                selected={moduleType === MODULE_TYPES.IMAGE}
                config={image}
              />
            </Grid>
          )}
          <Grid item xs sm md>
            {title_config && (
              <TextHolder
                handleModuleSelection={(e) => {
                  e.stopPropagation();
                  onModuleSelection(e, MODULE_TYPES.TITLE);
                }}
                selected={moduleType === MODULE_TYPES.TITLE}
                bgColor={ui.bg_color}
                config={title_config}
              />
            )}
            {text_config && (
              <TextHolder
                handleModuleSelection={(e) => {
                  e.stopPropagation();
                  onModuleSelection(e, MODULE_TYPES.DESCRIPTION);
                }}
                selected={moduleType === MODULE_TYPES.DESCRIPTION}
                bgColor={ui.bg_color}
                config={text_config}
              />
            )}
          </Grid>
        </Grid>
      )}

      {layout_type !== TOOLTIP_LAYOUT_TYPES.ACTION_BUTTONS &&
        buttons_config &&
        buttons_config.length > 0 && (
          <ButtonsHolder
            moduleType={moduleType}
            bgColor={ui.bg_color}
            config={buttons_config}
            onModuleSelection={onModuleSelection}
          />
        )}

      {layout_type === TOOLTIP_LAYOUT_TYPES.ACTION_BUTTONS && (
        <ActionTooltipButtonsHolder
          moduleType={moduleType}
          bgColor={ui.bg_color}
          config={action_buttons_config}
          onModuleSelection={onModuleSelection}
        />
      )}
    </TooltipContainer>
  );
}
