import { Box, Typography } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import React from "react";
import { PASSWORD_CHECKS } from "../constants";
import useIntegration from "../hooks/use-integration";

export default function PasswordStrengthChecks({ styles }) {
  const { isValidPassword } = useIntegration();

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        ...styles,
      }}
    >
      {PASSWORD_CHECKS.map((check, index) => (
        <Box
          key={index}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "12px",
          }}
        >
          <CheckCircle
            style={{
              fontSize: "16px",
              display: "inline-block",
              color: isValidPassword[index] ? "#469702" : "#002845",
              opacity: isValidPassword[index] ? "1" : "0.5",
              marginRight: "6px",
            }}
          />
          <Typography
            key={index}
            style={{
              display: "inline-block",
              fontSize: "13px",
              fontWeight: "600",
              color: isValidPassword[index] ? "#469702" : "#002845",
              opacity: isValidPassword[index] ? "1" : "0.5",
            }}
          >
            {check}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
