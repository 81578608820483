import React, { useState } from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import { Grid, Typography, withStyles } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Snackbar from "../../../../../../ReusableComponents/Snackbar";
import ToolTip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "../../../../../../ReusableComponents/Dialog";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Segment from "./Segment";
import { deleteSegment } from "../../../actions";
import { useAuth } from "../../../../../../../contexts/AuthContext";
import { FETCH_SEGMENTS } from "../reducer";
import { useDispatch } from "../store";

const styles = (theme) => ({
  root: {
    fontFamily: "Manrope",
    fontWeight: 600,
    fontSize: 14,
    width: "100%",
    minHeight: 72,
    borderRadius: 3,
    padding: "2px 16px 3px 16px",
    "&:hover": {
      background: "#FFF9F5",
      cursor: "pointer",
      color: `#FF7F33`,
    },
  },
  highlight: {
    "&:hover": {
      color: `#FF7F33`,
    },
  },
});

const SegmentHolder = ({
  _id,
  app,
  currentCohort = {},
  condition,
  event = [],
  name,
  session = [],
  user = [],
  setSegmentDeleted,
  index,
  classes,
}) => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const [deleteTriggered, setDeleteTriggered] = useState(false);
  const [deleteFailed, setDeleteFailed] = useState(false);
  const [expanded, setExpanded] = useState(false);

  return (
    <div style={{ width: "100%", margin: 4 }}>
      <ExpansionPanel
        className={classes.root}
        expanded={expanded}
        onChange={(e, expanded) => {
          setExpanded(expanded);
        }}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container spacing={1}>
            <Grid item xs={1}>
              <Typography
                variant="subtitle1"
                style={{
                  marginTop: "0.8em",
                  fontWeight: 600,
                  fontSize: 14,
                  fontFamily: "Manrope",
                }}
              >
                {index}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography
                variant="subtitle1"
                style={{
                  marginTop: "0.8em",
                  fontWeight: 600,
                  fontSize: 14,
                  fontFamily: "Manrope",
                }}
              >
                {name}
              </Typography>
            </Grid>
            <Grid item xs style={{ alignSelf: "center" }}>
              {currentCohort && !expanded && (
                <Chip
                  label="Cohort"
                  variant={"outlined"}
                  style={{ marginRight: 4, height: 28 }}
                />
              )}
            </Grid>
            <Grid item xs={1} onClick={(e) => e.stopPropagation()}>
              {deleteFailed && (
                <Snackbar open={deleteFailed}>
                  Deleting Segment Failed. Try Again..!
                </Snackbar>
              )}

              <ToolTip
                id="tooltip-fab"
                title="Delete this Segment"
                placement="bottom"
              >
                <IconButton
                  aria-label="Delete"
                  onClick={() => setDeleteTriggered(true)}
                >
                  <DeleteIcon color="error" />
                </IconButton>
              </ToolTip>
              <Dialog
                status={deleteTriggered}
                handleConfirm={() => {
                  // FIXME: Display a Snackbar whenever delete fails
                  setSegmentDeleted(false);
                  deleteSegment(auth, app.app_id, _id)
                    .then((response) => {
                      if (response) {
                        setDeleteTriggered(false);
                        setDeleteFailed(false);
                        setSegmentDeleted(true);
                        dispatch({
                          type: FETCH_SEGMENTS,
                        });
                      } else {
                        setDeleteFailed(true);
                        setDeleteTriggered(false);
                        setSegmentDeleted(false);
                      }
                    })
                    .catch((err) => {
                      setDeleteFailed(true);
                      setDeleteTriggered(false);
                      setSegmentDeleted(false);
                    });
                }}
                title="Delete Segment Confirmation"
                handleClose={() => {
                  setDeleteTriggered(false);
                }}
                confirmLabel="Confirm"
                allowCancelLabel="Cancel"
              >
                <Typography variant="subtitle1">
                  Are you sure want to delete the segment{" "}
                  <strong>{name}</strong> ?{" "}
                </Typography>
              </Dialog>
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        {expanded && (
          <ExpansionPanelDetails>
            <Segment
              _id={_id}
              app={app}
              currentCohort={currentCohort}
              condition={condition}
              event={event}
              session={session}
              user={user}
            />
          </ExpansionPanelDetails>
        )}
      </ExpansionPanel>
    </div>
  );
};

/**
 * Whenever the `cursor` state variable gets changed
 * in parent component, already rendered `SegmentHolder` component
 * might be re-rendered if we didn't wrap it with `React.memo`.
 *
 * So, it's important to remember to wrap it with `React.memo` when you
 * know that due to some change in parent might re-render this.
 */
export default withStyles(styles)(React.memo(SegmentHolder));
