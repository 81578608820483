import { Box, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { DEMO_ECOMMERCE_APP_ID } from "../../../../../../../config";
import { capitalizeEachWord } from "../../../../../../../utils";
import { apxTheme } from "../../../../../../ApplicationFrame/styling/CustomCSS";
import ColumnDiv from "../../../../../../ReusableComponents/ColumnDiv";
import CustomMaterialUIDialog from "../../../../../../ReusableComponents/CustomMaterialUIDialog";
import { getCampaignAPI } from "../../../../actions";
import { ReactComponent as SuccessImage } from "../../assets/success_image.svg";
import {
  EXPERIENCE_CONFIG,
  GUIDED_CAMPAIGN_EVENTS,
  GUIDED_SURVEY_EVENTS,
} from "../../constants";

function StepsToFollow({ experience_type, evt_name = "" }) {
  switch (experience_type) {
    case EXPERIENCE_CONFIG.FEATURE_DISCOVERY: {
      return (
        <ol
          style={{
            listStylePosition: "outside",
            paddingInlineStart: "15px",
          }}
        >
          <li>Kill the Apxor Demo App</li>
          <li>Open the Apxor Demo App</li>
          <li>Don't add any item to the cart for 5 seconds</li>
        </ol>
      );
    }
    case EXPERIENCE_CONFIG.CART_DELETION_SURVEY: {
      return (
        <ol
          style={{
            listStylePosition: "outside",
            paddingInlineStart: "15px",
          }}
        >
          <li>Kill the Apxor Demo App</li>
          <li>Open the Apxor Demo App</li>
          <li>Add any item to the cart</li>
          <li>Click on the cart icon</li>
          <li>Delete an item from the list</li>
        </ol>
      );
    }
    case EXPERIENCE_CONFIG.GUIDED_CAMPAIGN: {
      const textToDisplay =
        GUIDED_CAMPAIGN_EVENTS.find(
          (evt) => evt?.config?.details?.name === evt_name,
        )?.displayText ?? "";
      return <>{textToDisplay}</>;
    }
    case EXPERIENCE_CONFIG.GUIDED_SURVEY: {
      const textToDisplay =
        GUIDED_SURVEY_EVENTS.find(
          (evt) => evt?.config?.details?.name === evt_name,
        )?.displayText ?? "";
      return <>{textToDisplay}</>;
    }
    default:
      const textToDisplay = "";
      return <>{textToDisplay}</>;
  }
}

export default function CampaignPublishedDialog({
  auth,
  open,
  onClose,
  actionType,
  campaignId,
}) {
  const action_type = useMemo(
    () => (actionType === "walkthroughs" ? "campaign" : "survey"),
    [actionType],
  );

  const [campaignConfig, setCampaignConfig] = useState();

  useEffect(() => {
    getCampaignAPI(auth, DEMO_ECOMMERCE_APP_ID, campaignId, {
      configType: actionType === "walkthroughs" ? "walkthrough" : "survey",
    }).then((res) => {
      setCampaignConfig(res);
    });
  }, [actionType, action_type, auth, campaignId]);

  return (
    <CustomMaterialUIDialog
      openFlag={open}
      maxWidth="md"
      onClose={onClose}
      onDialogClose={onClose}
      dialogContent={
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{
            paddingInline: "70px",
          }}
        >
          <Grid item xs={5}>
            <SuccessImage
              style={{
                minHeight: "215px",
              }}
            />
          </Grid>
          <Grid item xs={7}>
            <ColumnDiv
              styles={{
                color: apxTheme.palette.text.primary,
              }}
            >
              <Typography
                style={{
                  fontWeight: "700",
                  fontSize: "25px",
                  marginBottom: "2px",
                }}
              >
                {capitalizeEachWord(action_type)} Published!
              </Typography>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: "14px",
                  marginBottom: "22px",
                }}
              >
                Congratulations, your {action_type} is now published!
              </Typography>
              <Typography
                style={{
                  fontWeight: "700",
                  fontSize: "14px",
                  marginBottom: "4px",
                }}
              >
                Schedule:
              </Typography>
              <Box
                style={{
                  background: "rgba(55, 151, 96, 0.1)",
                  border: "1px solid rgba(55, 151, 96, 0.4)",
                  borderRadius: "2px",
                  padding: "6px 10px",
                  marginBottom: "12px",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "600",
                    fontSize: "13px",
                    color: "rgba(55, 151, 96, 1)",
                    textAlign: "left",
                    maxWidth: "360px",
                  }}
                >
                  Active for 1 Day and will be shown 10 times at most
                </Typography>
              </Box>
              {campaignConfig && campaignConfig?.meta?.experience_type && (
                <>
                  <Typography
                    style={{
                      fontWeight: "700",
                      fontSize: "14px",
                      marginBottom: "4px",
                    }}
                  >
                    How to check your {action_type}?
                  </Typography>
                  <Box
                    style={{
                      background: "rgba(55, 151, 96, 0.1)",
                      border: "1px solid rgba(55, 151, 96, 0.4)",
                      borderRadius: "2px",
                      padding: "6px 10px",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: "600",
                        fontSize: "13px",
                        color: "rgba(55, 151, 96, 1)",
                        textAlign: "left",
                        maxWidth: "360px",
                      }}
                    >
                      <StepsToFollow
                        experience_type={campaignConfig?.meta?.experience_type}
                        evt_name={
                          campaignConfig?.conditions[0]?.details?.name ?? ""
                        }
                      />
                    </Typography>
                  </Box>
                </>
              )}
            </ColumnDiv>
          </Grid>
        </Grid>
      }
      dialogActions={
        <Box
          style={{
            height: "34px",
          }}
        ></Box>
      }
    />
  );
}
