import { makeGetRequest, makePostRequest } from "../../../common/actions";

const PREPROCESS_CONFIGS_API = "preprocess/retention/calculated";
const SAVE_PREPROCESS_API = "preprocess/retention";

export function savePreprocessAPI(auth, appId, queryParams, body) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    SAVE_PREPROCESS_API,
    auth,
    appId,
    queryParams,
    body,
    onSuccessCallback,
    onFailureCallback
  );
}

export function preprocessConfigsAPI(auth, appId, queryParams) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makeGetRequest(
    PREPROCESS_CONFIGS_API,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}
