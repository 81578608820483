import React from "react";
import { selectedColor } from "../../../constants";
import { Typography, Button } from "@material-ui/core";
import RowDiv from "../../../../../../../../ReusableComponents/RowDiv";
import dialog_icon from "../../../assets/dialog_icon.svg";
import { randomColorWithIndex } from "../../../../../../../../../utils";

export default function RequestForSurveySimulator({
  handleAnswer,
  ui,
  currentQuestionIndex,
  setCurrentQuestionIndex,
}) {
  const {
    dialog_config: {
      icon_name = null,
      yes_text = "Yes",
      dialog_background_color = "#FFFFFF",
      yes_button_bg_color,
      yes_button_text_color,
      no_text = "No",
      no_button_bg_color,
      no_button_text_color,
      title,
      title_color,
    },
    questions = [],
  } = ui;

  return (
    <div
      style={{
        textAlign: "center",
        // position: isOverAllSimulation ? "absolute" : "",
        bottom: 0,
        backgroundColor: dialog_background_color,
      }}
    >
      {icon_name && <img src={dialog_icon} alt="Apxor" />}
      <Typography
        variant="subtitle1"
        style={{
          color: title_color ? title_color : "",
          fontSize: 14,
          fontWeight: 500,
          padding: 5,
          marginTop: 35,
        }}
      >
        {title}
      </Typography>
      <RowDiv center styles={{ justifyContent: "center" }}>
        <Button
          variant="contained"
          style={{
            background: yes_button_bg_color
              ? yes_button_bg_color
              : selectedColor(false, randomColorWithIndex(0)),
            margin: "8px 0",
            width: 102,
            height: 34,
          }}
          onClick={(e) => {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            handleAnswer(true);
          }}
        >
          <strong
            style={{
              textTransform: "none",
              color: yes_button_text_color
                ? yes_button_text_color
                : selectedColor(true),
            }}
          >
            {yes_text}
          </strong>
        </Button>
      </RowDiv>
      <Button
        variant="text"
        style={{
          background: no_button_bg_color ? no_button_bg_color : "#FFF",
        }}
        onClick={(e) => {
          setCurrentQuestionIndex(questions.length - 1);
          handleAnswer(true);
        }}
      >
        <strong
          style={{
            textTransform: "none",
            color: no_button_text_color ? no_button_text_color : "",
          }}
        >
          {no_text}
        </strong>
      </Button>
    </div>
  );
}
