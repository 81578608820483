import React from "react";
import { useDispatch } from "../../../../../store";
import { Grid, withStyles } from "@material-ui/core";
import { SET_TXCFG_PLACEHOLDER } from "../../../../../constants";
import ActionsTextField from "../../../../../../../../../../ReusableComponents/ActionsTextField";

const styles = theme => ({
  formLabel: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: "700 !important",
    marginBottom: 5,
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
  },
});

/**
 * Contains all the config params related to Text Based Question
 */
function TextBasedQuestionSimulator({ classes, id, text_config }) {
  /**
   * Used to update the changes to the local store
   */
  const dispatch = useDispatch();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} className={classes.gridItem}>
        <ActionsTextField
          fullWidth
          required
          type="text"
          multiline
          rowsMax={4}
          value={text_config.placeholder}
          onChange={e => {
            dispatch({
              type: SET_TXCFG_PLACEHOLDER,
              id,
              value: e.target.value,
            });
          }}
          textFieldStyle={{ height: 100 }}
        />
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(TextBasedQuestionSimulator);
