import {
  Button,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { formatNumber } from "../../../../../../../../utils";
import BootstrapTooltip from "../../../../../../../ReusableComponents/BootstrapTooltip";
import ColumnDiv from "../../../../../../../ReusableComponents/ColumnDiv";
import Loading from "../../../../../../../ReusableComponents/Loading";
import RowDiv from "../../../../../../../ReusableComponents/RowDiv";
import { IconImageComponent } from "../../../../../../common/utils";
import { getVariantSummaryAPI } from "../../actions";
import winner from "../../assets/winner.png";
import { useTrackedState } from "../../store";
import { useAuth } from "../../../../../../../../contexts/AuthContext";
import { useCommonDashboardStyles } from "../../../../../../../common/constants";

const getTableHeaderCells = (variants) => {
  return variants.map((eachVariant) => {
    return <TableCell>{eachVariant.variant_code}</TableCell>;
  });
};

const getTableCells = (variants, calculations, key) => {
  return variants.map((eachVariant) => {
    let computedValue = Math.round(
      calculations[key][eachVariant.variant_code] * 100
    );
    return (
      <TableCell>
        {(computedValue === -100 ? "--" : computedValue) + "%"}
      </TableCell>
    );
  });
};

export default function WinnerAndNumbersSection({
  campaignConfig,
  winnerVariant,
}) {
  const auth = useAuth();
  const classes = useCommonDashboardStyles();

  const [variants, setVariants] = useState([]);
  const [calculations, setCalculations] = useState({});
  const [summaryLoading, setSummaryLoading] = useState(true);
  const [toggleMetrics, setToggleMetrics] = useState(true);

  const state = useTrackedState();
  const { funnelTime, refresh, goalEvents } = state;

  useEffect(() => {
    const postBody = {
      name: campaignConfig.goal_event,
      attributes: [],
    };
    const queryParams = {
      messageId: campaignConfig._id,
      time: funnelTime,
      variantCode: winnerVariant,
    };
    setSummaryLoading(true);
    getVariantSummaryAPI(
      auth,
      queryParams,
      campaignConfig.goal_event ? postBody : {},
    )
      .then((response) => {
        setVariants(response.variants);
        setCalculations(response.calculations ?? {});
        setSummaryLoading(false);
      })
      .catch(() => {
        setSummaryLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={6} style={{ maxHeight: 400 }}>
        <Card style={{ height: "100%" }}>
          {summaryLoading ? (
            <section className="content">
              <Loading size={24} />
            </section>
          ) : !toggleMetrics ? (
            <ColumnDiv>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow style={{ height: 50 }}>
                      <TableCell>
                        <Button
                          variant="text"
                          color="primary"
                          style={{
                            fontWeight: 600,
                            letterSpacing: "0.01em",
                          }}
                          onClick={() => setToggleMetrics(!toggleMetrics)}
                        >
                          {"< Go Back"}
                        </Button>
                      </TableCell>
                      {getTableHeaderCells(variants)}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography
                          className={classes.typographyCaption}
                          style={{
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            letterSpacing: "0.01em",
                          }}
                        >
                          Chance to be the winner
                        </Typography>
                      </TableCell>
                      {getTableCells(
                        variants,
                        calculations,
                        "probability_to_be_best",
                      )}
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography
                          className={classes.typographyCaption}
                          style={{
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            letterSpacing: "0.01em",
                          }}
                        >
                          Chance to beat control
                        </Typography>
                      </TableCell>
                      {getTableCells(
                        variants,
                        calculations,
                        "probability_to_beat_control",
                      )}
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography
                          className={classes.typographyCaption}
                          style={{
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            letterSpacing: "0.01em",
                          }}
                        >
                          Expected Loss
                        </Typography>
                      </TableCell>
                      {getTableCells(variants, calculations, "expected_loss")}
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography
                          className={classes.typographyCaption}
                          style={{
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            letterSpacing: "0.01em",
                          }}
                        >
                          Expected Improvement
                        </Typography>
                      </TableCell>
                      {getTableCells(variants, calculations, "improvements")}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </ColumnDiv>
          ) : (
            <ColumnDiv styles={{ padding: 16 }}>
              <RowDiv>
                <Typography
                  className={classes.typographyCaption}
                  style={{
                    fontSize: 20,
                    lineHeight: "27px",
                    letterSpacing: "0.01em",
                  }}
                >
                  Test Winner
                </Typography>
                <Button
                  variant="text"
                  color="primary"
                  style={{
                    fontSize: 12,
                    fontWeight: 600,
                    letterSpacing: "0.01em",
                  }}
                  onClick={() => setToggleMetrics(!toggleMetrics)}
                >
                  View Metrics
                </Button>
              </RowDiv>
              <ColumnDiv
                center
                styles={{
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  marginTop: 20,
                }}
              >
                {IconImageComponent(winner, "Winner", {
                  width: 150,
                  height: 100,
                  filter:
                    Object.keys(calculations).length > 0 && calculations.winner
                      ? "grayscale(0%)"
                      : "grayscale(100%)",
                })}
                {Object.keys(calculations).length > 0 && calculations.winner && (
                  <div
                    style={{
                      position: "absolute",
                      background: "#F0F2F4e6",
                      padding: "4px 12px",
                      backgroundColor: "#002845",
                      color: "#FFFFFF",
                      borderRadius: "50%",
                      fontSize: 30,
                      fontWeight: "bold",
                      marginRight: 8,
                      height: 65,
                      width: 65,
                      boxSizing: "border-box",
                      left: "50%",
                      transform: "translate(-46%)",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {calculations.winner}
                  </div>
                )}
              </ColumnDiv>
              <ColumnDiv
                center
                styles={{
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  marginTop: 20,
                }}
              >
                <Typography
                  className={classes.typographyCaption}
                  style={{
                    fontWeight: 700,
                    marginTop: 10,
                    letterSpacing: "0.01em",
                  }}
                >
                  {Object.keys(calculations).length > 0 && calculations.winner
                    ? calculations.reason
                    : `Test results are inconclusive`}
                </Typography>
                <Typography
                  className={classes.typographyCaption}
                  style={{
                    fontWeight: 500,
                    fontSize: 12,
                    marginTop: 10,
                    letterSpacing: "0.01em",
                  }}
                >
                  {Object.keys(calculations).length > 0 && calculations.winner
                    ? ``
                    : "Once the campaign meets certain threshold, the winner will be declared."}
                </Typography>
              </ColumnDiv>
            </ColumnDiv>
          )}
        </Card>
      </Grid>
      <Grid item xs={6} style={{ maxHeight: 400 }}>
        <Card style={{ height: "100%", overflow: "hidden" }}>
          {summaryLoading ? (
            <section className="content">
              <Loading size={24} />
            </section>
          ) : (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow style={{ height: 50 }}>
                      <TableCell>
                        <BootstrapTooltip title="Variant" placement="top">
                          <Typography
                            className={classes.typographyCaption}
                            style={{
                              fontSize: 12,
                              color: "#00284588",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              letterSpacing: "0.01em",
                            }}
                          >
                            Variant
                          </Typography>
                        </BootstrapTooltip>
                      </TableCell>
                      <TableCell>
                        <BootstrapTooltip title="Users" placement="top">
                          <Typography
                            className={classes.typographyCaption}
                            style={{
                              fontSize: 12,
                              color: "#00284588",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              letterSpacing: "0.01em",
                              textAlign: "center",
                            }}
                          >
                            Users
                          </Typography>
                        </BootstrapTooltip>
                      </TableCell>
                      <TableCell>
                        <BootstrapTooltip title="Conversions" placement="top">
                          <Typography
                            className={classes.typographyCaption}
                            style={{
                              fontSize: 12,
                              color: "#00284588",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              letterSpacing: "0.01em",
                              textAlign: "center",
                            }}
                          >
                            Conversions
                          </Typography>
                        </BootstrapTooltip>
                      </TableCell>
                      <TableCell>
                        <BootstrapTooltip
                          title="Conversion rate"
                          placement="top"
                        >
                          <Typography
                            className={classes.typographyCaption}
                            style={{
                              fontSize: 12,
                              color: "#00284588",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              letterSpacing: "0.01em",
                              textAlign: "center",
                            }}
                          >
                            Conversion rate
                          </Typography>
                        </BootstrapTooltip>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {(campaignConfig?.goal_event || goalEvents.length > 0) &&
                      variants.map((variant, index) => (
                        <TableRow>
                          <TableCell>
                            <RowDiv>
                              <div
                                style={{
                                  background: "#F0F2F4ab",
                                  padding: "5px 10px",
                                  color: "#002845",
                                  borderRadius: 2,
                                  fontSize: 17,
                                  marginRight: 8,
                                  border: "1px solid #DCE0E3",
                                  boxSizing: "border-box",
                                  fontWeight: 700,
                                }}
                              >
                                {variant.variant_code.toUpperCase()}
                              </div>
                              <BootstrapTooltip
                                title={variant.variant_name}
                                placement="top"
                              >
                                <Typography
                                  className={classes.typographyCaption}
                                  style={{
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    letterSpacing: "0.01em",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {variant.variant_name}
                                </Typography>
                              </BootstrapTooltip>
                            </RowDiv>
                          </TableCell>
                          <TableCell>
                            <Typography
                              className={classes.typographyCaption}
                              style={{
                                letterSpacing: "0.01em",
                                textAlign: "center",
                              }}
                            >
                              {variant.count.toLocaleString().split(",")
                                .length >= 3
                                ? formatNumber(variant.count)
                                : variant.count.toLocaleString()}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              className={classes.typographyCaption}
                              style={{
                                letterSpacing: "0.01em",
                                textAlign: "center",
                              }}
                            >
                              {variant.conversion_count
                                .toLocaleString()
                                .split(",").length >= 3
                                ? formatNumber(variant.conversion_count)
                                : variant.conversion_count.toLocaleString()}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              className={classes.typographyCaption}
                              style={{
                                letterSpacing: "0.01em",
                                textAlign: "center",
                              }}
                            >
                              {Math.round(variant.percentage)}%
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    {!campaignConfig?.goal_event && goalEvents.length <= 0 && (
                      <TableRow rowSpan="3">
                        <TableCell
                          colSpan="4"
                          style={{ textAlign: "center", border: "unset" }}
                        >
                          <Typography
                            style={{
                              fontWeight: "700",
                              fontSize: "14px",
                              fontFamily: "Manrope",
                              marginTop: 50,
                            }}
                          >
                            Select a goal event from 'filter' to view test
                            winner
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Card>
      </Grid>
    </Grid>
  );
}
