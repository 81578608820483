import {
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import React, { useRef, useState } from "react";
import { APP_PLATFORMS } from "../../../../constants";
import { useAuth } from "../../../../contexts/AuthContext";
import ColumnDiv from "../../../ReusableComponents/ColumnDiv";
import RowDiv from "../../../ReusableComponents/RowDiv";
import WebLaunchURL from "../../components/walkthroughs/components/design/components/common/WebLaunchURL";
import { openURLInNewTab } from "../../components/walkthroughs/components/design/utils";
import { triggerTestEmails } from "../actions";
import apxor_app from "./assets/apxor_app.png";
import { useCommonConfigStyles } from "./campaigncreator/utils";

export default function DownloadApxorAppContent({
  showBackButton,
  handleOnBackButtonClick,
  isTestingFlow = false,
  platform = "",
}) {
  const auth = useAuth();
  const typographyClasses = useCommonConfigStyles();

  const ref = useRef();
  const [url, setURL] = useState("");
  const [emailList, setEmailList] = useState([]);
  const [pending, setPending] = useState(false);
  const [successText, setSuccessText] = useState("");
  const [inputValue, setInputValue] = useState("");

  function handleChange(value) {
    if (emailList.indexOf(value) === -1) {
      setEmailList([...emailList, value.replace(/\n/g, "")]);
    }
  }

  const handleDelete = (item) => () => {
    setEmailList(emailList.filter((eachMail) => eachMail !== item));
  };

  const triggerEmails = () => {
    setPending(true);
    triggerTestEmails(auth, null, {
      email_ids: (emailList.indexOf(inputValue) === -1
        ? [...emailList, inputValue.replace(/\n/g, "")]
        : emailList
      ).filter((eachItem) => eachItem !== ""),
    })
      .then((response) => {
        setPending(false);
        setSuccessText(
          "Open the email link in your mobile to add your test device",
        );
        setInputValue("");
      })
      .catch(() => {
        setPending(false);
        setSuccessText("Please check your email ID");
      });
  };

  return (
    <>
      <ColumnDiv styles={{ gap: 16 }} customRef={ref}>
        {platform !== APP_PLATFORMS.web && (
          <Grid container spacing={0}>
            <Grid
              item
              xs={isTestingFlow ? 6 : 12}
              style={{
                padding: 20,
              }}
            >
              <RowDiv>
                {showBackButton && (
                  <IconButton
                    style={{ padding: 0, minHeight: 8 }}
                    onClick={() => {
                      setSuccessText("");
                      if (handleOnBackButtonClick) {
                        handleOnBackButtonClick();
                      }
                    }}
                  >
                    <ChevronLeft />
                  </IconButton>
                )}
                <Typography
                  style={{
                    fontSize: 24,
                    fontWeight: 700,
                    lineHeight: "34px",
                    color: "#002845",
                  }}
                >
                  Add your test device
                </Typography>
              </RowDiv>
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  lineHeight: "19px",
                  opacity: 0.7,
                  color: "#002845",
                  marginTop: 8,
                  marginBottom: 30,
                }}
              >
                You will be able to preview and test your campaigns on your
                device
              </Typography>
              <ColumnDiv styles={{ justifyContent: "center" }}>
                <RowDiv styles={{ marginTop: 10 }}>
                  <Typography
                    style={{
                      width: 36,
                      height: 18,
                      fontSize: 16,
                      fontWeight: 800,
                      color: "#002845",
                      opacity: "60%",
                      marginBottom: 2,
                    }}
                  >
                    Email's
                  </Typography>
                </RowDiv>
                <RowDiv>
                  <TextField
                    fullWidth
                    placeholder={"Enter Multiple email id's"}
                    multiline
                    minRows={2}
                    InputProps={{
                      startAdornment: emailList.map((item) => (
                        <Chip
                          key={item}
                          tabIndex={-1}
                          label={item}
                          onDelete={handleDelete(item)}
                          style={{ margin: 2 }}
                        />
                      )),
                      onKeyDown: (event) => {
                        if (event.key === "Enter") {
                          handleChange(event.target.value);
                          setInputValue("");
                          event.target.value = "";
                        }
                        if (
                          emailList.length &&
                          !inputValue.length &&
                          event.key === "Backspace"
                        ) {
                          setEmailList(
                            emailList.slice(0, emailList.length - 1),
                          );
                        }
                      },
                      onChange: (event) => {
                        setInputValue(event.target.value);
                      },
                    }}
                  />
                  {/* <Autocomplete
                    multiple
                    freeSolo
                    fullWidth
                    multiline
                    minRows={4}
                    options={[]}
                    getOptionLabel={(option) => option}
                    defaultValue={[]}
                    onChange={(event, values) => {}}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        multiline
                        minRows={4}
                        placeholder={placeholder}
                      />
                    )}
                  /> */}
                </RowDiv>

                <RowDiv styles={{ marginTop: 10 }}>
                  <Button
                    style={{ height: 38, width: 110 }}
                    size="large"
                    variant="contained"
                    color="primary"
                    disabled={false}
                    onClick={() => {
                      triggerEmails();
                    }}
                  >
                    {!pending && "Proceed"}
                    {pending && (
                      <CircularProgress color="secondary" size={18} />
                    )}
                  </Button>
                </RowDiv>
                {successText !== "" && (
                  <RowDiv>
                    <Typography
                      style={{
                        fontSize: 14,
                        fontWeight: 500,
                        lineHeight: "19px",
                        opacity: 0.7,
                        color: "#FF7F33",
                        marginTop: 8,
                      }}
                    >
                      {successText}
                    </Typography>
                  </RowDiv>
                )}
              </ColumnDiv>

              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 46,
                  marginBottom: 8,
                }}
              >
                {IconImageComponent(apxor_app_qr, "Icon", {
                  width: "120px",
                  height: "120px",
                })}
              </div> */}
              {/* <RowDiv>
                <ModuleDivider styles={{ width: "47%" }} />
                <Typography variant="caption" style={{ padding: "0px 8px" }}>
                  or
                </Typography>
                <ModuleDivider styles={{ width: "47%" }} />
              </RowDiv>
              <RowDiv
                center
                styles={{
                  justifyContent: "space-between",
                  border: "1px dashed #CCD4DA",
                  background: "#F9FBFD",
                  borderRadius: 3,
                  marginTop: 10,
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    maxWidth: "90%",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    paddingLeft: 10,
                  }}
                >
                  {APXOR_APP_DOWNLOAD_LINK}
                </Typography>
                <IconButton
                  disableRipple
                  style={{
                    borderRadius: 1,
                  }}
                  disabled={null ? true : false}
                  onClick={() => copyToClipboard(ref)}
                >
                  <img src={copy_link} alt="Apxor"></img>
                  <Typography
                    style={{
                      marginLeft: 8,
                      color: "#FF7F33",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Copy link
                  </Typography>
                </IconButton>
              </RowDiv> */}
            </Grid>
            {isTestingFlow && (
              <Grid
                item
                xs={6}
                style={{
                  padding: 20,
                }}
              >
                <div
                  style={{
                    background: `url(${apxor_app})`,
                    width: 214,
                    height: 437,
                    margin: "0 auto",
                  }}
                ></div>
              </Grid>
            )}
          </Grid>
        )}
      </ColumnDiv>
      {platform === APP_PLATFORMS.web && (
        <ColumnDiv styles={{ gap: 16, margin: "10px 20px" }}>
          <Typography className={typographyClasses.typographyHeading}>
            Add new Test Device
          </Typography>
          <WebLaunchURL
            url={url}
            setURL={setURL}
            launchCallback={() => {
              openURLInNewTab(url, auth.appId);
            }}
            title="Launch URL to add test device"
          />
        </ColumnDiv>
      )}
    </>
  );
}
