import {
  EM_GET_CLIENT_EVENTS,
  EM_GET_CLIENT_EVENTS_PENDING,
  EM_GET_CLIENT_EVENTS_FAILED,
  DELETE_CLIENT_EVENT,
  DELETE_CLIENT_EVENT_PENDING,
  DELETE_CLIENT_EVENT_FAILED,
  EDIT_CLIENT_EVENT,
  EDIT_CLIENT_EVENT_PENDING,
  EDIT_CLIENT_EVENT_FAILED,
  ADD_CLIENT_EVENT,
  ADD_CLIENT_EVENT_PENDING,
  ADD_CLIENT_EVENT_FAILED,
  PAGINATION_ACTION,
  UPDATE_PAGE_COUNTER,
  FILTERS_CHANGED,
  MODAL_STATE_CHANGE,
  SET_APP_VERSIONS,
} from "./constants";

import { CLIENT_EVENT } from "./constants";
import { produce } from "immer";

const GET_CLIENT_EVENTS_FLAG_STATE = {
  get_client_events_failed: false,
  get_client_events_pending: false,
};

const EDIT_CLIENT_EVENTS_FLAG_STATE = {
  edit_client_events_failed: false,
  edit_client_events_pending: false,
};
const ADD_CLIENT_EVENTS_FLAG_STATE = {
  add_client_events_failed: false,
  add_client_events_pending: false,
};

const DELETE_CLIENT_EVENTS_FLAG_STATE = {
  delete_client_events_failed: false,
  delete_client_events_pending: false,
};

export const initialState = {
         appVersions: [],
         pagination: {
           after: null,
           before: null,
           hasNext: false,
           hasPrev: false,
           limit: 10,
           pageCounter: 0,
           totalResults: 10,
         },
         filters: {
           event_type: CLIENT_EVENT,
           event_name: undefined,
           attr_name: undefined,
           attr_type: undefined,
           app_version: undefined,
           platform: undefined,
           source: undefined,
         },
         rows: [],
         dialog: {
           open: false,
           dialog_type: undefined,
         },
         ...GET_CLIENT_EVENTS_FLAG_STATE,
         ...ADD_CLIENT_EVENTS_FLAG_STATE,
         ...EDIT_CLIENT_EVENTS_FLAG_STATE,
         ...DELETE_CLIENT_EVENTS_FLAG_STATE,
       };

export const reducer = produce((draft, action) => {
  let { payload, meta } = action;
  switch (action.type) {
    //    Get
    case EM_GET_CLIENT_EVENTS:
      draft.rows = payload.rows;
      draft.get_client_events_pending = false;
      draft.get_client_events_failed = false;
      draft.pagination = {
        ...draft.pagination,
        after: payload.after,
        before: payload.before,
        hasNext: payload.has_next,
        hasPrev: payload.has_prev,
        totalResults: payload.total,
      };
      break;
    case EM_GET_CLIENT_EVENTS_PENDING:
      draft.get_client_events_pending = true;
      draft.get_client_events_failed = false;
      break;
    case EM_GET_CLIENT_EVENTS_FAILED:
      draft.get_client_events_pending = false;
      draft.get_client_events_failed = true;
      break;
    case PAGINATION_ACTION:
      draft.pagination[payload.key] = payload.value;
      draft.pagination.pageCounter = 0;
      break;
    case UPDATE_PAGE_COUNTER:
      draft.pagination.pageCounter = payload.pageCounter;
      break;
    case FILTERS_CHANGED:
      draft.pagination.pageCounter = 0;
      draft.filters[payload.key] = payload.value;
      break;
    case MODAL_STATE_CHANGE:
      draft.pagination.pageCounter = meta.reset_pagination
        ? 0
        : draft.pagination.pageCounter;
      draft.dialog.open = payload.open;
      draft.dialog.dialog_type = payload.type;
      break;
    //    Edit
    case EDIT_CLIENT_EVENT_FAILED:
      draft.edit_client_events_failed = true;
      draft.edit_client_events_pending = false;
      break;
    case EDIT_CLIENT_EVENT:
      draft.edit_client_events_failed = false;
      draft.edit_client_events_pending = false;
      break;
    case EDIT_CLIENT_EVENT_PENDING:
      draft.edit_client_events_failed = false;
      draft.edit_client_events_pending = true;
      break;
    //    Add
    case ADD_CLIENT_EVENT_FAILED:
      draft.add_client_events_failed = true;
      draft.add_client_events_pending = false;
      break;
    case ADD_CLIENT_EVENT:
      draft.add_client_events_failed = false;
      draft.add_client_events_pending = false;
      break;
    case ADD_CLIENT_EVENT_PENDING:
      draft.add_client_events_failed = false;
      draft.add_client_events_pending = true;
      break;

    //    Delete
    case DELETE_CLIENT_EVENT_FAILED:
      draft.delete_client_events_failed = true;
      draft.delete_client_events_pending = false;
      break;
    case DELETE_CLIENT_EVENT:
      draft.delete_client_events_failed = false;
      draft.delete_client_events_pending = false;
      break;
    case DELETE_CLIENT_EVENT_PENDING:
      draft.delete_client_events_failed = false;
      draft.delete_client_events_pending = true;
      break;

    case SET_APP_VERSIONS:
      draft.appVersions = payload;
      break;
    default:
      return draft;
  }
});
