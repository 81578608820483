import React from "react";
import { Provider } from "./store";
import EventMetricsWithProvider from "./components/EventMetricsWithProvider";

export default function EventMetrics({
  appId,
  queryParams,
  filters,
  userAttributes = [],
  sessionAttributes = [],
  eventList = [],
  totals = { users: 1, sessions: 1 },
}) {
  return (
    <Provider>
      <EventMetricsWithProvider
        appId={appId}
        queryParams={queryParams}
        filters={filters}
        userAttributes={userAttributes}
        eventList={eventList}
        sessionAttributes={sessionAttributes}
        totals={totals}
      />
    </Provider>
  );
}
