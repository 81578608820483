import generateShortID from "../../../../../../../../utils";
import { ACTION_CLASSES, INAPP_LAYOUT_TYPES } from "../../constants";
import {
  CONTAINER_CONFIG,
  CONSTANT_CONFIGS,
  BUTTON_CONFIG,
  TEXT_CONFIG,
  IMAGE_ONLY_REDIRECTION_CONFIG,
} from "../common";

const DEFAULT_INAPP_CONFIG = {
  name: "",
  bg_color: "#FFFFFF",
  bg_image: "",
  image_size: "cover",
  layout: "html_layout",
  delay: 1000,
  type: "IN_APP",
  orientation: "Portrait",
  image:
    "https://images.unsplash.com/photo-1577578306649-09e937512e28?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=50",
  border_radius: 2,
  only_image: false,
  touch_redirect_url: "",
  action_config: {
    action: "dismiss",
    activity: "",
    is_deep_link: false,
  },
  title_config: {
    ...TEXT_CONFIG,
    style: "bold",
    size: 18,
    text: "Title",
    __id: "uCwdrrqvb-7Dc5eQYjE",
  },
  text_config: {
    ...TEXT_CONFIG,
    text: "Description",
    __id: "xbOMVSFiO-WF5QGkh0D",
  },
  image_only_redirection_config: {
    ...IMAGE_ONLY_REDIRECTION_CONFIG,
    __id: "blnpy3XYx-Nr1JPARXG",
  },
  buttons_config: [
    {
      ...BUTTON_CONFIG,
      text_config: {
        ...TEXT_CONFIG,
        text: "Ok",
      },
      __id: "ctmpy1XYy-Nr4JPARXF",
    },
    {
      ...BUTTON_CONFIG,
      text_config: {
        ...TEXT_CONFIG,
        text: "Cancel",
      },
      __id: "VYvJfbeq1-7Dc5eQYjE",
    },
  ],
  window_attributes: {
    position: "CENTER",
    dimmed_background: true,
    dismiss_outside_touch: true,
    has_animation: true,
    animation_duration: 1000,
  },
  html_config: {
    width: 100,
    height: 100,
    data: "",
    url: "",
  },
};

const DEFAULT_VIDEOS_CONFIG = {
  activity: "",
  delay: 1000,
  is_via: true,
  width: 1080,
  height: 1920,
  add_close: true,
  x_icon_color: "#000000",
  add_audio: false,
  sound_icon_color: "#000000",
  can_minimize: true,
  mini_color: "#000000",
  is_full_scr: true,
  bg_color: "#000000",
  mute_audio: true,
  enable_PIP: true,
  download: true,
  auto_replay: true,
  video_size: 8,
  v_url: "",
  window_attributes: {
    position: "CENTER",
    dimmed_background: true,
    dismiss_outside_touch: true,
    has_animation: true,
    animation_duration: 1000,
  },
  html_config: {
    width: 100,
    height: 100,
    data: "",
    url: "",
  },
  type: "IN_APP",
};

const DEFAULT_CUSTOM_HTML_CONFIG = {
  name: "",
  layout: "html_layout",
  delay: 1000,
  type: "IN_APP",
  window_attributes: {
    position: "CENTER",
    dimmed_background: false,
    dismiss_outside_touch: false,
    has_animation: true,
    animation_duration: 1000,
  },
  html_config: {
    width: 100,
    height: 100,
    data: "",
    url: "",
  },
  ...CONTAINER_CONFIG,
  ...CONSTANT_CONFIGS,
};

export const getInAppJSON = (type) => {
  const defaultJSON = JSON.parse(JSON.stringify(DEFAULT_INAPP_CONFIG));

  let json = {
    ...defaultJSON,
    action_class: ACTION_CLASSES.INAPP,
    layout_type: type,
    _id: generateShortID(),
  };

  switch (type) {
    case INAPP_LAYOUT_TYPES.HEADER:
    case INAPP_LAYOUT_TYPES.FOOTER:
      return json;

    case INAPP_LAYOUT_TYPES.CUSTOM_HTML:
      return {
        ...DEFAULT_CUSTOM_HTML_CONFIG,
        action_class: ACTION_CLASSES.INAPP,
        layout_type: type,
        orientation: "Portrait",
      };
    case INAPP_LAYOUT_TYPES.POPOUT_MODAL:
      return {
        ...json,
        buttons_config: [
          {
            ...BUTTON_CONFIG,
            color: "#FA7404",
          },
        ],
      };
    case INAPP_LAYOUT_TYPES.FULL_SCREEN_MODAL:
      return {
        ...json,
        text_config: {},
        buttons_config: [
          {
            ...BUTTON_CONFIG,
            color: "#FA7404",
          },
        ],
      };
    case INAPP_LAYOUT_TYPES.CAROUSEL:
      return {
        ...json,
      };
    case INAPP_LAYOUT_TYPES.VIDEOS:
      return {
        ...DEFAULT_VIDEOS_CONFIG,
        action_class: ACTION_CLASSES.INAPP,
        layout_type: type,
      };
    case INAPP_LAYOUT_TYPES.FLOATING:
      return {
        ...json,
      };
    case INAPP_LAYOUT_TYPES.CLASSIC_MODAL:
    default:
      return json;
  }
};
