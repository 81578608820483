import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { getApps } from "../common/actions";

import { MuiThemeProvider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useDeepCompareEffect } from "../../utils/use-deep-compare";
import ApplicationFrame from "../ApplicationFrame";
import { apxTheme } from "../ApplicationFrame/styling/CustomCSS";
import Loading from "../ReusableComponents/Loading";
import AppsComponent from "./components/AppsComponent";

export default function Apps(props) {
  const [apps, setApps] = useState([]);
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [appsFetchFlag, setAppsFetchFlag] = useState(false);

  useDeepCompareEffect(() => {
    setPending(true);
    getApps(auth)
      .then((result) => {
        setPending(false);
        setApps(result || []);
      })
      .catch((_err) => setPending(false));
  }, [auth, appsFetchFlag]);

  return (
    <MuiThemeProvider theme={apxTheme}>
      <section style={{ width: "100%", marginTop: 50 }} className="content">
        <Grid container justifyContent="center">
          <ApplicationFrame
            {...props}
            isNavbarEnabled={false}
            isAppsPage={true}
          ></ApplicationFrame>
          <Grid
            item
            xs={12}
            sm={10}
            md={8}
            style={{
              position: pending ? "absolute" : "unset",
              top: pending ? "50%" : "unset",
            }}
          >
            {pending && <Loading size={48} />}
            {!pending && (
              <AppsComponent
                apps={apps}
                fetchApps={(flag) => setAppsFetchFlag(!flag)}
              />
            )}
          </Grid>
        </Grid>
      </section>
    </MuiThemeProvider>
  );
}
