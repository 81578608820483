import React, { useEffect, useMemo } from "react";
import { Provider, useDispatch, useTrackedState } from "./store";
import {
  ACTION_REMOVE_ATTRIBUTE,
  ACTION_SET_ATTRIBUTE_NAME,
  ACTION_SET_ATTRIBUTE_OPERATOR,
  ACTION_SET_ATTRIBUTE_VALUE,
  ACTION_ADD_ATTRIBUTE,
  ACTION_RESET,
  ACTION_SET_INITIAL_VALUES,
} from "./reducer";
import Box from "../Box";
import { AttributeRow } from "../AttributeRow";
import WebAssetIcon from "@material-ui/icons/WebAsset";
import { Button } from "@material-ui/core";

export default function AttributeBuilder({
  appId,
  title,
  dateFilters,
  initialValues = [],
  attributes,
  operators,
  handleOnChange,
  buttonText = "Add Property",
  withBox = true,
  onlyAttributeName = false,
}) {
  return (
    <Provider>
      <AttributeBuilderWithProvider
        appId={appId}
        title={title}
        dateFilters={dateFilters}
        initialValues={initialValues}
        attributes={attributes}
        operators={operators}
        handleOnChange={handleOnChange}
        buttonText={buttonText}
        withBox={withBox}
        onlyAttributeName={onlyAttributeName}
      />
    </Provider>
  );
}

function AttributeBuilderWithProvider({
  appId,
  title,
  dateFilters,
  initialValues = [],
  attributes,
  operators,
  handleOnChange,
  disabled = false,
  buttonText = "Add Property",
  withBox = true,
  // By default, we call `handleOnChange` iff attribute, operator and value get selected
  // This flag controls the default behavior and can call `handleOnChange` whenever attribute get selected
  onlyAttributeName = false,
}) {
  const dispatch = useDispatch();
  const state = useTrackedState();

  const filters = useMemo(
    () => ({
      since: dateFilters.since,
      till: dateFilters.till,
      of: "users",
    }),
    [dateFilters.since, dateFilters.till]
  );

  useEffect(() => {
    if (initialValues.length > 0) {
      dispatch({
        type: ACTION_SET_INITIAL_VALUES,
        attributes: initialValues,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Whenever we receive new props, reset the state
  useEffect(() => {
    dispatch({
      type: ACTION_RESET,
    });
    if (initialValues.length > 0) {
      dispatch({
        type: ACTION_SET_INITIAL_VALUES,
        attributes: initialValues,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributes]);

  useEffect(() => {
    if (handleOnChange) {
      const { attributes } = state;
      let isValid = true;
      for (let i in attributes) {
        const attribute = attributes[i];
        if (onlyAttributeName && attribute.name === "") {
          isValid = false;
          break;
        } else if (
          !onlyAttributeName &&
          (attribute.name === "" || attribute.operator === "")
        ) {
          isValid = false;
          break;
        }
      }
      if (isValid) {
        handleOnChange(attributes);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const AttributeRows = (
    <>
      {state.attributes.map(({ id, name, value, operator }, index) => (
        <div key={name + index}>
          <AttributeRow
            appId={appId}
            filters={filters}
            id={id}
            name={name}
            value={value}
            operator={operator}
            operators={operators}
            options={attributes}
            dispatch={dispatch}
            actions={{
              ACTION_SET_ATTRIBUTE_NAME,
              ACTION_SET_ATTRIBUTE_OPERATOR,
              ACTION_SET_ATTRIBUTE_VALUE,
              ACTION_REMOVE_ATTRIBUTE,
            }}
            disabled={disabled}
          />
        </div>
      ))}
      {!disabled && (
        <Button
          color="primary"
          onClick={() => dispatch({ type: ACTION_ADD_ATTRIBUTE })}
          style={{ margin: "10px 0" }}
        >
          {buttonText}
          <WebAssetIcon />
        </Button>
      )}
    </>
  );

  if (withBox) {
    return (
      <Box
        title={title}
        collapsible
        withPadding
        defaultExpanded={state.attributes.length > 0}
      >
        {AttributeRows}
      </Box>
    );
  } else {
    return AttributeRows;
  }
}
