import React, { useState } from "react";
import {
  Button,
  Grid,
  Typography,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import ToolTip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import moment from "moment";
import PresetDateRangePicker from "../../../../../../ReusableComponents/DateRangePicker";
import Snackbar from "../../../../../../ReusableComponents/Snackbar";
import { updateCohort } from "../../../actions";
import { useAuth } from "../../../../../../../contexts/AuthContext";
import { FETCH_COHORTS } from "../reducer";
import { useDispatch } from "../store";

export const CohortDetails = ({
  app: {
    app_id: appId,
    settings: { timezone },
  },
  currentCohort,
}) => {
  const auth = useAuth();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    updatedCohortSince: null,
    updatedCohortTill: null,
    updateEnabled: false,
    snackbarMessage: null,
  });

  const [pending, setPending] = useState(false);
  const [editSuccess, setEditSuccess] = useState(false);

  return (
    <div>
      <Typography variant="h6" style={{ fontWeight: 600 }}>
        Cohort Details
      </Typography>
      <div style={{ marginTop: 12 }}>
        <Grid container spacing={1}>
          {!state.updateEnabled && (
            <Grid item xs={5}>
              <Typography>From:</Typography>
              <Typography style={{ fontWeight: 600 }}>
                {moment(currentCohort["since"]).utc().format("LL")}
              </Typography>
            </Grid>
          )}
          {!state.updateEnabled && (
            <Grid item xs={5}>
              <Typography>To:</Typography>
              <Typography style={{ fontWeight: 600 }}>
                {moment(currentCohort["till"]).utc().endOf("d").format("LL")}
              </Typography>
            </Grid>
          )}
          {state.updateEnabled && (
            <Grid item xs={6}>
              <PresetDateRangePicker
                handleChange={(start, end) => {
                  setState({
                    ...state,
                    updatedCohortSince: moment(start).utc(),
                    updatedCohortTill: moment(end).utc().endOf("d"),
                  });
                }}
                inUTC
                small
                startDate={moment(currentCohort["since"]).utc()}
                endDate={moment(currentCohort["till"]).utc().endOf("d")}
                showDefaultInputIcon
                inputIconPosition="before"
                disableAfter
              />
              <Typography>{timezone}</Typography>
            </Grid>
          )}
          {state.updateEnabled && (
            <Grid item xs={4}>
              <div style={{ position: "absolute", verticalAlign: "middle" }}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={pending}
                  onClick={() => {
                    // FIXME: Show a snackbar about the status
                    setEditSuccess(false);
                    setPending(true);
                    updateCohort(
                      auth,
                      appId,
                      currentCohort["cohort_id"],
                      moment(
                        state.updatedCohortSince
                          ? state.updatedCohortSince
                          : currentCohort["since"]
                      )
                        .utc()
                        .startOf("d")
                        .toISOString(),
                      moment(
                        state.updatedCohortTill
                          ? state.updatedCohortTill
                          : currentCohort["till"]
                      )
                        .utc()
                        .endOf("d")
                        .toISOString()
                    ).then((response) => {
                      if (response) {
                        setPending(false);
                        setEditSuccess(true);
                        setState({
                          ...state,
                          updateEnabled: false,
                        });
                        dispatch({
                          type: FETCH_COHORTS,
                        });
                      }
                    });
                  }}
                >
                  Go
                </Button>

                {pending && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: -12,
                      marginLeft: -12,
                    }}
                    size={24}
                  />
                )}
              </div>
            </Grid>
          )}
          {editSuccess && (
            <Snackbar open={editSuccess} autoHideDuration={2500}>
              Cohort Updated Successfully!
            </Snackbar>
          )}
          {!state.updateEnabled && (
            <Grid item xs={2}>
              <ToolTip title={"Edit"}>
                <IconButton
                  onClick={() => {
                    setState({
                      ...state,
                      updateEnabled: true,
                    });
                  }}
                >
                  <EditIcon color={"primary"} />
                </IconButton>
              </ToolTip>
            </Grid>
          )}
          {state.updateEnabled && (
            <Grid item xs={2}>
              <ToolTip title={"Cancel Edit"}>
                <IconButton
                  disabled={pending}
                  onClick={() => {
                    setState({
                      ...state,
                      updateEnabled: false,
                    });
                  }}
                >
                  <CancelIcon color={"error"} />
                </IconButton>
              </ToolTip>
            </Grid>
          )}
        </Grid>
        <Divider style={{ marginTop: 8, marginBottom: 8 }} />
        <Grid container spacing={8}>
          <Grid item xs={5}>
            <Typography style={{ fontSize: 12, color: "#666" }}>
              Created at:
            </Typography>
            <Typography
              style={{ fontSize: 12, color: "#666", fontWeight: 600 }}
            >
              {moment(currentCohort["created_time"]).format("LLL")}
            </Typography>
          </Grid>
          {currentCohort["modified_time"] && (
            <Grid item xs={5}>
              <Typography style={{ fontSize: 12, color: "#666" }}>
                Updated At:
              </Typography>
              <Typography
                style={{ fontSize: 12, color: "#666", fontWeight: 600 }}
              >
                {moment(currentCohort["modified_time"]).format("LL LTS")}
              </Typography>
            </Grid>
          )}
          {state.snackbarMessage && (
            <Snackbar
              onClose={() => {
                setState({
                  ...state,
                  snackbarMessage: null,
                });
              }}
              autoHideDuration={2500}
            >
              {state.snackbarMessage}
            </Snackbar>
          )}
        </Grid>
      </div>
    </div>
  );
};
