import React from "react";
import DonutChart from "./Donut";
import Loading from "./Loading";
import Placeholder from "./Placeholder";

/**
 * Responsible for displaying Donuts for the selected properties
 */
export default function DonutDistributions({
  data,
  selections,
  handleSelect,
  notSelectable = false,
  isPending = false,
  messageForNoData = "",
}) {
  return (
    <div
      style={{
        padding: 10,
        display: "flex",
        flexWrap: "wrap",
        textAlign: "center",
        alignContent: "space-between",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {!isPending &&
        Object.keys(data).map((item) => (
          <div
            key={"distributions" + item}
            style={{ minHeight: "180px", minWidth: "200px", maxWidth: "250px" }}
          >
            <DonutChart
              onClickHandler={
                notSelectable ? null : (values) => handleSelect(item, values)
              }
              data={data[item]}
              height={210}
              innerRadius={50}
              outerRadius={75}
              title={item}
              selected={selections[item] || []}
            />
          </div>
        ))}
      {!isPending && Object.keys(data).length === 0 && (
        <Placeholder height="auto" text={messageForNoData} />
      )}
      {isPending && (
        <section style={{ width: "100%" }} className="content">
          <Loading size={36} />
        </section>
      )}
    </div>
  );
}
