import React from "react";
import { useTracked } from "../../../store";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  TextField,
  withStyles,
  FormLabel,
} from "@material-ui/core";

import ExpansionModuleConfig from "../../../../../../../../ReusableComponents/ExpansionModuleConfig";
import ModuleConfigSection from "../../../../../../../../ReusableComponents/ModuleConfigSection";
import ModuleDivider from "../../../../../../../../ReusableComponents/ModuleDivider";
import CustomColorPicker from "../../../../../../../../ReusableComponents/CustomColorPicker";
import GlobalConfigSimulator from "../../designPreviewSection/components/GlobalConfigSimulator";
import {
  SHOW_SUCCESS_MESSAGE,
  SET_SUCCESS_MESSAGE,
  SET_SUBMIT_INACTIVE_COLOR,
  SET_SUBMIT_ACTIVE_COLOR,
  SET_SUBMIT_TEXT_COLOR,
  SET_PROGRESSBAR_COLOR,
  SET_PREVIOUS_BUTTON_COLOR,
  SET_CANCEL_BUTTON_COLOR,
  SET_SKIP_TEXT_COLOR,
  SET_SUCCESS_MESSAGE_TEXT_COLOR,
  SET_SUCCESS_MESSAGE_BG_COLOR,
  SET_OPTION_BG_INACTIVE_COLOR,
  SET_OPTION_BG_ACTIVE_COLOR,
  SET_OPTION_TEXT_INACTIVE_COLOR,
  SET_OPTION_TEXT_ACTIVE_COLOR,
  SET_QUESTION_BG_COLOR,
  SET_QUESTION_TEXT_COLOR,
  IS_PROGRESSBAR_ENABLED,
  IS_PREVIOUS_ENABLED,
  IS_CANCEL_ENABLED,
  IS_SKIP_ENABLED,
  SET_SUCCESS_MESSAGE_ICON_COLOR,
} from "../../../constants";

const styles = theme => ({
  formLabel: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: "700 !important",
    marginBottom: 5,
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
  },
  simulatorRoot: {
    display: "flex",
    backgroundColor: theme.palette.text.primary,
    borderRadius: 3,
  },
});

/**
 * There are nearly 23 config params in this General section. Hell Yeah!!
 *
 * Nothing much to describe. Oh wait!!
 *
 * Note: These config sections don't know that the Design page has a parent.
 * Whenever there is a change in these config params, they will post
 * these to the local store through `dispatch`. They don't need to
 * know whether the Design page is talking to somebody or not
 * (unnecessary headache right? :D).
 */
function SurveyGlobalDialog({ classes }) {
  /**
   * When you use `react-tracked` lib, you don't need to pass props
   *
   * Always read the latest state from the store and post the changes
   * back to the store using `dispatch`
   *
   * There's a lot of confusion whether we need to maintain the component
   * specific state or not. AFAI see, I don't face any issues or any need
   */
  const [state, dispatch] = useTracked();

  const {
    ui: {
      show_success_message = false,
      success_message = "",
      question_text_color,
      question_background_color,
      submit_active_color,
      submit_inactive_color,
      option_bg_color_active,
      option_bg_color_inactive,
      option_text_color_active,
      option_text_color_inactive,
      success_message_bg_color,
      success_message_text_color,
      success_message_icon_color,
      skip_text_color,
      cancel_button_color,
      previous_button_color,
      progress_bar_color,
      submit_text_color,
      is_skip_enabled = false,
      is_cancel_enabled = false,
      is_previous_enabled = false,
      show_progress_bar = false,
    },
  } = state;

  return (
    <Grid container spacing={0} style={{ minHeight: 720, maxHeight: 720 }}>
      <Grid item xs={6} className={classes.simulatorRoot}>
        <GlobalConfigSimulator />
      </Grid>
      <Grid item xs={6} style={{ padding: 10 }}>
        <ExpansionModuleConfig defaultExpanded title="Questions Configuration">
          <Grid container spacing={2}>
            <Grid item xs={6} className={classes.gridItem}>
              <FormLabel className={classes.formLabel}>Text Color</FormLabel>
              <CustomColorPicker
                projectHigher={true}
                color={question_text_color || "#000000"}
                handleColorChange={color => {
                  dispatch({
                    type: SET_QUESTION_TEXT_COLOR,
                    value: color.hex,
                  });
                }}
              />
            </Grid>
            <Grid item xs={6} className={classes.gridItem}>
              <FormLabel className={classes.formLabel}>
                Background Color
              </FormLabel>
              <CustomColorPicker
                projectHigher={true}
                color={question_background_color || "#000000"}
                handleColorChange={color => {
                  dispatch({
                    type: SET_QUESTION_BG_COLOR,
                    value: color.hex,
                  });
                }}
              />
            </Grid>
          </Grid>
        </ExpansionModuleConfig>
        <ExpansionModuleConfig defaultExpanded title="Options Configuration">
          <Grid container spacing={2}>
            <Grid item xs={6} className={classes.gridItem}>
              <FormLabel className={classes.formLabel}>
                Active Text Color
              </FormLabel>
              <CustomColorPicker
                projectHigher={true}
                color={option_text_color_active || "#000000"}
                handleColorChange={color => {
                  dispatch({
                    type: SET_OPTION_TEXT_ACTIVE_COLOR,
                    value: color.hex,
                  });
                }}
              />
            </Grid>
            <Grid item xs={6} className={classes.gridItem}>
              <FormLabel className={classes.formLabel}>
                Inactive Text Color
              </FormLabel>
              <CustomColorPicker
                projectHigher={true}
                color={option_text_color_inactive || "#000000"}
                handleColorChange={color => {
                  dispatch({
                    type: SET_OPTION_TEXT_INACTIVE_COLOR,
                    value: color.hex,
                  });
                }}
              />
            </Grid>
            <Grid item xs={6} className={classes.gridItem}>
              <FormLabel className={classes.formLabel}>
                Active Background Color
              </FormLabel>
              <CustomColorPicker
                projectHigher={true}
                color={option_bg_color_active || "#000000"}
                handleColorChange={color => {
                  dispatch({
                    type: SET_OPTION_BG_ACTIVE_COLOR,
                    value: color.hex,
                  });
                }}
              />
            </Grid>
            <Grid item xs={6} className={classes.gridItem}>
              <FormLabel className={classes.formLabel}>
                Inactive Background Color
              </FormLabel>
              <CustomColorPicker
                projectHigher={true}
                color={option_bg_color_inactive || "#000000"}
                handleColorChange={color => {
                  dispatch({
                    type: SET_OPTION_BG_INACTIVE_COLOR,
                    value: color.hex,
                  });
                }}
              />
            </Grid>
          </Grid>
        </ExpansionModuleConfig>
        <ExpansionModuleConfig
          defaultExpanded
          title="Progressbar Configuration"
        >
          <Grid container spacing={1}>
            <Grid item xs={6} className={classes.gridItem}>
              <FormControlLabel
                label="Show Progressbar"
                control={
                  <Checkbox
                    color="primary"
                    checked={show_progress_bar || false}
                    onChange={e => {
                      dispatch({
                        type: IS_PROGRESSBAR_ENABLED,
                        value: e.target.checked,
                      });
                    }}
                    disableRipple
                  />
                }
              />
            </Grid>
            <Grid item xs={6} className={classes.gridItem}>
              {show_progress_bar && (
                <>
                  <FormLabel className={classes.formLabel}>
                    Progressbar Color
                  </FormLabel>
                  <CustomColorPicker
                    projectHigher={true}
                    color={progress_bar_color || "#000000"}
                    handleColorChange={color => {
                      dispatch({
                        type: SET_PROGRESSBAR_COLOR,
                        value: color.hex,
                      });
                    }}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </ExpansionModuleConfig>
        <ExpansionModuleConfig
          defaultExpanded
          title="Submit &amp; Close Button Configuration"
        >
          <Grid container spacing={1}>
            <Grid item xs={6} className={classes.gridItem}>
              <FormControlLabel
                label="Close Button Enabled"
                control={
                  <Checkbox
                    color="primary"
                    checked={is_cancel_enabled || false}
                    onChange={e => {
                      dispatch({
                        type: IS_CANCEL_ENABLED,
                        value: e.target.checked,
                      });
                    }}
                    disableRipple
                  />
                }
              />
            </Grid>
            <Grid item xs={6} className={classes.gridItem}>
              {is_cancel_enabled && (
                <>
                  <FormLabel className={classes.formLabel}>
                    Close Button Color
                  </FormLabel>
                  <CustomColorPicker
                    projectHigher={true}
                    color={cancel_button_color || "#000000"}
                    handleColorChange={color => {
                      dispatch({
                        type: SET_CANCEL_BUTTON_COLOR,
                        value: color.hex,
                      });
                    }}
                  />
                </>
              )}
            </Grid>
            <ModuleConfigSection
              style={{ width: "100%" }}
              title="Submit Button"
              typographyClassName={classes.formLabel}
            >
              <ModuleDivider />
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <FormLabel className={classes.formLabel}>
                    Text Color
                  </FormLabel>
                  <CustomColorPicker
                    projectHigher={true}
                    color={submit_text_color || "#000000"}
                    handleColorChange={color => {
                      dispatch({
                        type: SET_SUBMIT_TEXT_COLOR,
                        value: color.hex,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormLabel className={classes.formLabel}>
                    Active Color
                  </FormLabel>
                  <CustomColorPicker
                    projectHigher={true}
                    color={submit_active_color || "#000000"}
                    handleColorChange={color => {
                      dispatch({
                        type: SET_SUBMIT_ACTIVE_COLOR,
                        value: color.hex,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormLabel className={classes.formLabel}>
                    Inactive Color
                  </FormLabel>
                  <CustomColorPicker
                    projectHigher={true}
                    color={submit_inactive_color || "#000000"}
                    handleColorChange={color => {
                      dispatch({
                        type: SET_SUBMIT_INACTIVE_COLOR,
                        value: color.hex,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </ModuleConfigSection>
          </Grid>
        </ExpansionModuleConfig>
        <ExpansionModuleConfig
          defaultExpanded
          title="Previous &amp; Skip Button Configuration"
        >
          <Grid container spacing={1}>
            <Grid item xs={6} className={classes.gridItem}>
              <FormControlLabel
                label="Skip Enabled"
                control={
                  <Checkbox
                    color="primary"
                    checked={is_skip_enabled || false}
                    onChange={e => {
                      dispatch({
                        type: IS_SKIP_ENABLED,
                        value: e.target.checked,
                      });
                    }}
                    disableRipple
                  />
                }
              />
            </Grid>
            <Grid item xs={6} className={classes.gridItem}>
              {is_skip_enabled && (
                <>
                  <FormLabel className={classes.formLabel}>
                    Skip Text Color
                  </FormLabel>
                  <CustomColorPicker
                    projectHigher={true}
                    color={skip_text_color || "#000000"}
                    handleColorChange={color => {
                      dispatch({
                        type: SET_SKIP_TEXT_COLOR,
                        value: color.hex,
                      });
                    }}
                  />
                </>
              )}
            </Grid>
            <Grid item xs={6} className={classes.gridItem}>
              <FormControlLabel
                label="Previous Enabled"
                control={
                  <Checkbox
                    color="primary"
                    checked={is_previous_enabled || false}
                    onChange={e => {
                      dispatch({
                        type: IS_PREVIOUS_ENABLED,
                        value: e.target.checked,
                      });
                    }}
                    disableRipple
                  />
                }
              />
            </Grid>
            <Grid item xs={6} className={classes.gridItem}>
              {is_previous_enabled && (
                <>
                  <FormLabel className={classes.formLabel}>
                    Previous Button Color
                  </FormLabel>
                  <CustomColorPicker
                    projectHigher={true}
                    color={previous_button_color || "#000000"}
                    handleColorChange={color => {
                      dispatch({
                        type: SET_PREVIOUS_BUTTON_COLOR,
                        value: color.hex,
                      });
                    }}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </ExpansionModuleConfig>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(SurveyGlobalDialog);
