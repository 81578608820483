import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import { PLATFORM_IMAGES } from "../../../../../../../../App/styling/CustomCSS";
import RowDiv from "../../../../../../../../ReusableComponents/RowDiv";
import TruncatedText from "../../../../../../../../ReusableComponents/TruncatedText";
import { useSummaryStyles } from "../../constants";
import moment from "moment";

export default function GeneralSummary({
  name,
  platform,
  tags = [],
  handleSelect,
  createdAt,
  updatedAt,
}) {
  const headerClasses = useSummaryStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} className={headerClasses.root}>
        <Typography variant="body1" className={headerClasses.summaryHeading}>
          Details
        </Typography>
        {handleSelect && (
          <Button
            variant="text"
            color="primary"
            onClick={() => handleSelect(-1)}
            className={headerClasses.editButton}
          >
            Edit
          </Button>
        )}
      </Grid>
      <Grid item xs={12} style={{ padding: "8px 26px", paddingLeft: 22 }}>
        <RowDiv
          center
          styles={{
            gap: "10px 50px",
            flexWrap: "wrap",
          }}
        >
          <RowDiv center applyMargin>
            <Typography className={headerClasses.heading}>Name</Typography>
            <Typography className={headerClasses.body}>{name}</Typography>
          </RowDiv>
          <RowDiv center applyMargin>
            <Typography className={headerClasses.heading}>Platform</Typography>
            <Typography className={headerClasses.body}>
              {PLATFORM_IMAGES(platform, "#212121", 24)}
            </Typography>
          </RowDiv>
          {tags.length > 0 && (
            <RowDiv
              center
              applyMargin
              styles={{ maxWidth: 600, overflow: "scroll" }}
            >
              <Typography className={headerClasses.heading}>Tags</Typography>
              <Typography
                className={headerClasses.body}
                style={{
                  paddingTop: "3px",
                }}
              >
                {tags?.map((tag) => (
                  <TruncatedText key={tag} value={tag} disabled />
                ))}
              </Typography>
            </RowDiv>
          )}
          <RowDiv center applyMargin>
            <Typography className={headerClasses.heading}>
              Last Modified At
            </Typography>
            <Typography className={headerClasses.body}>
              {moment(updatedAt).format("L (LT)")}
            </Typography>
          </RowDiv>
          <RowDiv center applyMargin>
            <Typography className={headerClasses.heading}>
              Created At
            </Typography>
            <Typography className={headerClasses.body}>
              {moment(createdAt).format("L (LT)")}
            </Typography>
          </RowDiv>
        </RowDiv>
      </Grid>
    </Grid>
  );
}
