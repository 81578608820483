import { Typography } from "@material-ui/core";
import React from "react";

export default function FieldDescription({
  title,
  description,
  required,
  titleProps,
  descriptionProps = {},
}) {
  return (
    <div>
      <Typography
        variant={"h6"}
        style={{
          fontWeight: 600,
          lineHeight: 1,
          marginBottom: description ? 4 : 8,
          verticalAlign: "middle",
          ...titleProps,
        }}
      >
        {title}{" "}
        {required && (
          <span
            style={{
              fontSize: 12,
              color: "#81838a",
              fontStyle: "italic",
            }}
          >
            {" "}
            (Required){" "}
          </span>
        )}
      </Typography>
      {description && (
        <Typography
          variant={"subtitle1"}
          style={{
            lineHeight: "1.2em",
            color: "#7d7f87",
            ...descriptionProps,
          }}
        >
          {description}
        </Typography>
      )}
    </div>
  );
}
