import React, { useMemo } from "react";
import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";
import CampaignCreator from "../../common/components/campaigncreator";
import CampaignsHolder from "../../common/components/campaignsholder";
import WalkthroughsAnalysis from "./components/analysis";
import WalkthroughsDesignPage from "./components/design";
import DesignSummaryComponent from "./components/design/components/DesignSummaryComponent";
import Error404 from "../../../Error/404";

const CONFIG_TYPE = ["walkthrough"];

export default function Walkthroughs({ app }) {
  const { path } = useRouteMatch();
  const location = useLocation();
  const state = useMemo(
    () => (location && location.state ? location.state : null),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]
  );
  return (
    <section className="content">
      <Switch>
        <Route exact path={path}>
          <CampaignsHolder
            title={"Campaigns"}
            app={app}
            actionType={"walkthroughs"}
            configType={CONFIG_TYPE}
            designSummaryComponent={<DesignSummaryComponent />}
            isExpermentPage={state?.isExperiment}
          />
        </Route>
        <Route exact path={`${path}/new`}>
          <CampaignCreator
            app={app}
            actionType={CONFIG_TYPE[0]}
            configType={CONFIG_TYPE[0]}
            designOrConfigureComponent={<WalkthroughsDesignPage app={app} />}
            designOrConfigureSummaryComponent={<DesignSummaryComponent />}
          />
        </Route>
        <Route exact path={`${path}/:campaignId/edit`}>
          <CampaignCreator
            app={app}
            actionType={CONFIG_TYPE[0]}
            configType={
              state && state.config && state.config.meta
                ? state.config.meta.config_type
                : "walkthrough"
            }
            designOrConfigureComponent={<WalkthroughsDesignPage app={app} />}
            designOrConfigureSummaryComponent={<DesignSummaryComponent />}
          />
        </Route>
        <Route exact path={`${path}/:campaignId/analysis`}>
          <WalkthroughsAnalysis
            app={app}
            actionType={CONFIG_TYPE[0]}
            message={state}
            designSummaryComponent={<DesignSummaryComponent />}
          />
        </Route>
        <Route path="*" component={Error404} />
      </Switch>
    </section>
  )
}
