import {
  Box,
  Button,
  ButtonBase,
  Input,
  Radio,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import ClassicCard from "../../../ReusableComponents/ClassicCard";
import android from "../../../Dashboard/common/components/assets/android.png";
import ios from "../../../Dashboard/common/components/assets/ios.png";
import web from "../../../Dashboard/common/components/assets/web.png";
import useIntegration from "../../../Auth/integration-experience/hooks/use-integration";
import IntegrationLayout from "../../IntegrationLayout";
import { useTrackedState } from "../../store";
import Loading from "../../../ReusableComponents/Loading";
import { useHistory } from "react-router-dom";
import { apxTheme } from "../../../ApplicationFrame/styling/CustomCSS";

const APP_PLATFORMS = {
  android: "Android",
  ios: "iOS",
  web: "Web",
};

const APP_PLATFORM_NAMES = {
  ios: "iOS",
  android: "Android",
  web: "Web",
};

const APP_PLATFORM_IMAGES = {
  ios: (
    <img alt="" style={{ height: 29, width: 24, marginBottom: -4 }} src={ios} />
  ),
  android: (
    <img
      alt=""
      style={{ height: 30, width: 28, marginBottom: -4 }}
      src={android}
    />
  ),
  web: (
    <img alt="" style={{ height: 36, width: 36, marginBottom: -4 }} src={web} />
  ),
};

const ORadio = withStyles((theme) => ({
  root: {
    "&$checked": {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
}))((props) => <Radio color="default" {...props} />);

export default function AddNewAppPage() {
  const { addNewApplication } = useIntegration();
  const { app_loading } = useTrackedState();
  const { push } = useHistory();

  const [app_name, set_app_name] = useState("");
  const [app_img_url, set_app_img_url] = useState("");
  const [app_platform, set_app_platform] = useState(
    Object.keys(APP_PLATFORMS)[0]
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    const appId = await addNewApplication(app_name, "", app_platform);
    push(`/apps/${appId}/integrate`);
  };

  return (
    <IntegrationLayout>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "700",
            marginBottom: "30px",
          }}
        >
          Add Application
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              marginBottom: "16px",
            }}
          >
            <Typography
              component={"label"}
              htmlFor="app_name"
              style={{
                fontWeight: "500",
                fontSize: "13px",
                color: "#667A8A",
                marginBottom: "4px",
              }}
            >
              Application Name
            </Typography>
            <Input
              id="app_name"
              name="app_name"
              type="text"
              value={app_name}
              onChange={(e) => set_app_name(e.target.value)}
              required
              autoFocus
              style={{
                fontSize: "14px",
                fontWeight: "600",
                width: "75%",
                padding: "5px",
                border: "1px solid #CCD4DA",
                borderRadius: "2px",
              }}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              marginBottom: "16px",
            }}
          >
            <Typography
              component={"label"}
              htmlFor="app_img_url"
              style={{
                fontWeight: "500",
                fontSize: "13px",
                color: "#667A8A",
                marginBottom: "4px",
              }}
            >
              App Icon URL
            </Typography>
            <Input
              id="app_img_url"
              name="app_img_url"
              type="text"
              value={app_img_url}
              onChange={(e) => set_app_img_url(e.target.value)}
              placeholder="https://cloudinary.com/v1/logo.png"
              style={{
                fontSize: "14px",
                fontWeight: "600",
                width: "75%",
                padding: "5px",
                border: "1px solid #CCD4DA",
                borderRadius: "2px",
              }}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              marginBottom: "45px",
            }}
          >
            <Typography
              component={"label"}
              htmlFor="platform"
              style={{
                fontWeight: "500",
                fontSize: "13px",
                color: "#667A8A",
                marginBottom: "4px",
              }}
            >
              Platform
            </Typography>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              {Object.keys(APP_PLATFORMS).map((platformKey) => (
                <Box key={"select-card-" + platformKey}>
                  <ButtonBase
                    style={{
                      minWidth: "140px",
                      minHeight: "54px",
                      marginRight: "8px",
                      border: "1px solid",
                      borderRadius: "2px",
                      borderColor:
                        app_platform === platformKey
                          ? apxTheme.palette.primary.main
                          : "#CCD4DA",
                    }}
                    disableRipple
                    disableTouchRipple
                    onClick={() => set_app_platform(platformKey)}
                  >
                    <ClassicCard
                      style={{
                        boxShadow: "none",
                        border: "none",
                      }}
                      selected={app_platform === platformKey}
                      disabled={false}
                    >
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                        }}
                      >
                        <ORadio
                          checked={app_platform === platformKey}
                          style={{
                            color:
                              app_platform === platformKey
                                ? apxTheme.palette.primary.main
                                : "#002845",
                            opacity: app_platform === platformKey ? 1 : 0.3,
                          }}
                        />
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                          }}
                        >
                          <div>{APP_PLATFORM_IMAGES[platformKey]}</div>
                          <Typography
                            style={{
                              fontWeight: 600,
                              fontSize: "16px",
                              marginLeft: 12,
                            }}
                          >
                            {APP_PLATFORM_NAMES[platformKey]}
                          </Typography>
                        </Box>
                      </Box>
                    </ClassicCard>
                  </ButtonBase>
                </Box>
              ))}
            </Box>
          </Box>
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            disabled={app_name === ""}
            style={{
              height: 48,
            }}
          >
            {app_loading ? <Loading size={28} color="#FFFFFF" /> : "Next"}
          </Button>
        </form>
      </Box>
    </IntegrationLayout>
  );
}
