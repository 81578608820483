import { makeDefaultQueryString, withQueryStrings } from "../../../../../utils";
import { callApi } from "../../../../../api";

const CLIENT_EVENTS = "client-events/";

/**
 *
 * @param appId  {string}
 * @param filters {{event_name: string, attr_name: string, attr_type: string, app_version: string, platform: string, source: string}}
 * @param after {string}
 * @param before {string}
 * @param limit {number}
 * @param auth {{user:{email: string}}}
 * @returns {Promise}
 */
export function getEventRowsAPI(
  appId,
  filters = {},
  after,
  before,
  limit = 10,
  auth
) {
  const url =
    makeDefaultQueryString(CLIENT_EVENTS + appId, auth) +
    withQueryStrings({
      ...filters,
      limit: limit,
      after: after,
      before: before,
    });
  const config = {
    method: "GET",
    auth: auth,
  };
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param appId {string}
 * @param appVersion {string}
 * @param platform {string}
 * @param event {{name: string, attributes: {string:string}}}
 * @param auth {{user:{email: string}}}
 * @returns {Promise}
 */
export function upsertClientEventAPI(
  appId,
  appVersion,
  platform,
  event = {},
  auth
) {
  const url = makeDefaultQueryString(
    CLIENT_EVENTS + appId + "/" + platform,
    auth
  );
  const config = {
    method: "PUT",
    auth: auth,
    body: JSON.stringify({
      app_version: appVersion,
      client_events: [
        {
          name: event.name,
          attributes: event.attributes,
        },
      ],
    }),
  };
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return false;
  };
  return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param appId {string}
 * @param id {string}
 * @param auth {{user:{email: string}}}
 * @returns {Promise}
 */
export function deleteClientEventAPI(appId, id, auth) {
  const url = makeDefaultQueryString(
    CLIENT_EVENTS + appId + "/" + id || "",
    auth,
    appId
  );
  const config = {
    method: "DELETE",
    auth: auth,
  };
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return false;
  };
  return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param appId {string}
 * @param event {{}}
 * @param auth {{user:{email: string}}}
 * @returns {Promise}
 */
export function editClientEventAPI(appId, event, auth) {
  const url = makeDefaultQueryString(
    CLIENT_EVENTS + appId + "/" + event._id.$oid || "",
    auth,
    appId
  );
  const config = {
    method: "PATCH",
    auth: auth,
    body: {
      ...event,
    },
  };
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return false;
  };
  return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param appId {string}
 * @param event {{}}
 * @param auth {{user:{email: string}}}
 * @returns {Promise}
 */
export function addClientEventAPI(appId, event, auth) {
  const url =
    makeDefaultQueryString(`${CLIENT_EVENTS}${appId}/csv`, auth) +
    withQueryStrings({
      platform: event.platform,
      appVersion: event.app_version,
    });
  const header = ["Event Name", "Attribute Name", "Attribute Type"].join(",");
  const values = [event.event_name, event.attr_name, event.attr_type].join(",");
  const config = {
    method: "PUT",
    auth: auth,
    body: [header, values].join("\n"),
    customHeaders: { "Content-Type": "text/csv", Accept: "text/csv" },
  };
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return false;
  };
  return callApi(url, config, onSuccessCallback, onFailureCallback);
}
