/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { useAuth } from "../../../../contexts/AuthContext";
import { getAppVersions, getCohorts } from "../actions";
import { Button, Grid, Typography } from "@material-ui/core";
import DateRangePickerWrapper from "../../../ReusableComponents/DateRangePicker";
import moment from "moment";
import MultiSelect from "../../../ReusableComponents/MultiSelect";
import { DASHBOARD_ENDPOINTS } from "../../../../constants/Endpoints";
import { useLocation } from "react-router-dom";

const styles = {
  paddingLeft: 25,
  fontWeight: 600,
  fontSize: 35,
  fontFamily: "Manrope",
  paddingRight: 30,
  paddingTop: 20,
};

function Filters({
  endPoint,
  app: {
    app_id: appId,
    settings: { timezone = "UTC" },
  },
  dateFilters,
  onCohortChange,
  onVersionChange,
  onDateFiltersChange,
  showVersion = false,
  showCohort = false,
  // FIXME
  // As we seperated this component to avoid passing down and calling up pattern
  // we have to somehow provide a mechanism to tell this component to re-fetch the data
  // So, added this flag to do that work for us.
  // Whoever uses this component and if this needs to be re-rendered, update this flag to make it work
  refresh = 0,
  startDateOffset = null,
}) {
  const [cohorts, setCohorts] = useState([]);
  const [versions, setVersions] = useState([]);
  const [selectedCohort, setSelectedCohort] = useState("");
  const [selectedVersions, setSelectedVersions] = useState([]);
  const [changed, setChanged] = useState(false);

  const auth = useAuth();
  const location = useLocation();
  const overrideTZ = [
    DASHBOARD_ENDPOINTS.UNINSTALLS,
    DASHBOARD_ENDPOINTS.RETENTION,
  ].some((item) => location.pathname.match(item));

  // Get Versions and Cohorts on will mount
  useEffect(() => {
    if (showVersion) {
      getAppVersions(auth, appId).then((response) => {
        setVersions(response);
      });
    }

    if (showCohort) {
      getCohorts(auth, appId).then((response) => {
        setCohorts(response);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <Grid
      container
      style={{
        marginBottom: 10,
        paddingLeft: 25,
        paddingRight: 30,
        paddingTop: 20,
      }}
      spacing={1}
      justify="space-between"
    >
      {endPoint && <div style={styles}>{endPoint}</div>}

      <Grid item xs={12} sm md={3} xl />

      <Grid item xs={12} sm md xl>
        <Grid container spacing={1}>
          <Grid item xs={6} md xl sm>
            {showCohort && (
              <MultiSelect
                single
                clearable
                value={selectedCohort}
                placeholder="Select a Cohort"
                handleChange={(value) => {
                  setSelectedCohort(value);
                  if (onCohortChange) {
                    onCohortChange(value);
                  }
                }}
                options={cohorts.map((o) => ({
                  label: o.name,
                  value: o.cohort_id,
                }))}
              />
            )}
          </Grid>
          <Grid item xs={6} md xl sm>
            {showVersion && (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <MultiSelect
                  clearable
                  value={selectedVersions}
                  placeholder="Select versions"
                  handleChange={(values) => {
                    setChanged(true);
                    if (onVersionChange) {
                      setSelectedVersions(values);
                    }
                  }}
                  options={versions.map((o) => ({ label: o, value: o }))}
                />
                {changed && (
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={!changed}
                    onClick={() => {
                      onVersionChange(selectedVersions);
                      setChanged(false);
                    }}
                    style={{ marginLeft: 8, maxHeight: 40 }}
                  >
                    Apply
                  </Button>
                )}
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={2} xl={2} style={{ textAlign: "right" }}>
        {/* DATE PICKER HERE */}
        <DateRangePickerWrapper
          startDate={moment(dateFilters?.since)}
          endDate={moment(dateFilters?.till).utc().startOf("day")}
          showDefaultInputIcon
          small
          handleChange={(start, end) => {
            const startDate =
              moment(start).utc().toISOString().slice(0, 23) + "Z";
            const endDate =
              moment(end).utc().endOf("day").toISOString().slice(0, 23) + "Z";
            if (onDateFiltersChange) {
              onDateFiltersChange(startDate, endDate);
            }
            localStorage.setItem(
              "filters",
              JSON.stringify({ since: startDate, till: endDate })
            );
          }}
          disableAfter
          startDateOffset={startDateOffset}
        />

        {!overrideTZ && (
          <Typography variant="caption">Timezone: {timezone}</Typography>
        )}
        {overrideTZ && (
          <Typography variant="caption">
            Timezone will be overridden by UTC
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

export default React.memo(Filters);
