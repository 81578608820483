import React, { useState, useEffect, useMemo } from "react";
import { SessionsTable } from "./components/SessionsTable";
import { useAuth } from "../../../../../contexts/AuthContext";
import SessionTimeline from "./components/sessionTimeline";
import {
  getUserProfileStaticBasicInfo,
  getUserProfileDynamicBasicInfo,
  getUserProfileSessionTimeLine,
  getUserProfileSessionInfo,
  getUserProfileCrashInfo,
  getUserProfileSessions,
} from "./actions";
import { Divider, Grid } from "@material-ui/core";
import Filters from "../../../common/components/Filters";
import { useDateFilters } from "../../../common/utils";
import { useTracked, Provider } from "../store";
import {
  USER_PROFILE_STATIC_BASIC_INFO,
  USER_PROFILE_DYNAMIC_BASIC_INFO,
  USER_PROFILE_SESSION_INFO,
  USER_PROFILE_CRASH_INFO,
  USER_PROFILE_SESSIONS_LIST,
  USER_PROFILE_SESSION_TIMELINE,
} from "../constants";
import { useLocation } from "react-router-dom";
import { UserProfile } from "./components/UserProfile";
import { UserInfo } from "./components/UserCustomInfo";

function getUserIdFromLocation(location) {
  return location.pathname.substring(location.pathname.lastIndexOf("/") + 1);
}

const User = ({ app }) => {
  const auth = useAuth();
  const location = useLocation();
  const userId = getUserIdFromLocation(location);
  const { app_id: appId } = app;
  const [timestamp, setTimeStamp] = useState(new Date()); //currently selected session start time
  const [duration, setDuration] = useState(0);
  const [state, dispatch] = useTracked();
  const {
    sessions,
    dynamic_basic_info,
    timeline,
    actual_timeline,
    pagination,
  } = state;
  const [version, setVersion] = useState(null);
  const [dateFilters, setDateFilters] = useDateFilters();

  const queryParams = useMemo(
    () => ({
      ...dateFilters,
      versions: version,
    }),
    [dateFilters, version]
  );

  useEffect(() => {
    getUserProfileStaticBasicInfo(auth, appId, userId, queryParams).then(
      (response) => {
        dispatch({ type: USER_PROFILE_STATIC_BASIC_INFO, value: response });
      }
    );
    getUserProfileDynamicBasicInfo(auth, appId, userId, queryParams).then(
      (response) => {
        dispatch({ type: USER_PROFILE_DYNAMIC_BASIC_INFO, value: response });
      }
    );
    getUserProfileSessionInfo(auth, appId, userId, queryParams).then(
      (response) => {
        dispatch({ type: USER_PROFILE_SESSION_INFO, value: response });
      }
    );
    getUserProfileCrashInfo(auth, appId, userId, queryParams).then(
      (response) => {
        dispatch({ type: USER_PROFILE_CRASH_INFO, value: response });
      }
    );
    getUserProfileSessions(auth, appId, userId, {
      ...queryParams,
      pagination: pagination,
    }).then((response) => {
      dispatch({
        type: USER_PROFILE_SESSIONS_LIST,
        sessions: sessions,
        value: response,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilters, version]);

  const onSessionClick = (sessionId, time, sessionData) => {
    getUserProfileSessionTimeLine(auth, app.app_id, {
      ...queryParams,
      sessionId,
      deviceId: userId,
      time,
    }).then((response) => {
      dispatch({ type: USER_PROFILE_SESSION_TIMELINE, value: response });
    });
    setTimeStamp(time);
    setDuration(sessionData.length);
  };

  return (
    <>
      <Filters
        app={app}
        showVersion
        dateFilters={dateFilters}
        onVersionChange={(version) => setVersion(version)}
        onDateFiltersChange={(since, till) =>
          setDateFilters({ since: since, till: till })
        }
        startDateOffset={(day) => day.subtract(6, "days")}
      />
      <Divider />
      <section className="content">
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <UserProfile app={app} appId={appId} userId={userId} />
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <UserInfo data={dynamic_basic_info} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <SessionsTable onSessionClick={onSessionClick} />
        {timeline.length > 0 && (
          <SessionTimeline
            timestamp={timestamp}
            pending={false}
            failed={false}
            timeline={timeline}
            actual_timeline={actual_timeline}
            duration={duration}
          />
        )}
      </section>
    </>
  );
};

const UserwithProvider = ({ app, userId, user }) => {
  return (
    <Provider>
      <User app={app} userId={userId} user={user} />
    </Provider>
  );
};

export default UserwithProvider;
