import React, { useEffect, useState } from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    display: "flex",
    borderRadius: 3,
    height: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  }),
  negativeButton: (props) => ({
    cursor: "pointer",
    padding: 5,
    border: `1px solid #CCD4DA`,
    borderRadius: "3px 0px 0px 3px",
    borderWidth: "1px 0px 1px 1px",
  }),
  positiveButton: (props) => ({
    cursor: "pointer",
    padding: 5,
    border: `1px solid #CCD4DA`,
    borderRadius: "0px 3px 3px 0px",
    borderWidth: "1px 1px 1px 0px",
  }),
  value: (props) => ({
    padding: 10,
    borderRadius: 0,
    border: `1px solid #CCD4DA`,
    color: `${theme.palette.text.primary} !important`,
  }),
}));

export default function NumberWithButtons({
  value,
  handleChange,
  maxValue = 100,
  minValue = 0,
}) {
  const classes = useStyles();

  const [val, setVal] = useState(value);

  useEffect(() => {
    setVal(value);
  }, [value]);

  return (
    <div className={classes.root}>
      <IconButton
        variant="text"
        disabled={val === minValue}
        disableRipple={true}
        className={classes.negativeButton}
        onClick={() => {
          setVal((prev) => prev - 1);
          handleChange(value - 1);
        }}
      >
        <RemoveIcon
          style={{ color: val === minValue ? "#6F787E55" : "#6F787E" }}
        />
      </IconButton>
      <IconButton className={classes.value} disabled>
        {val}
      </IconButton>
      <IconButton
        variant="text"
        disabled={val === maxValue}
        disableRipple={true}
        className={classes.positiveButton}
        onClick={() => {
          setVal((prev) => prev + 1);
          handleChange(value + 1);
        }}
      >
        <AddIcon
          style={{ color: val === maxValue ? "#6F787E55" : "#6F787E" }}
        />
      </IconButton>
    </div>
  );
}
