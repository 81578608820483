import React, { useEffect, useRef } from "react";
import { withQueryStrings } from "../../../../../../../../utils";

const SSE_API = window.SSE_ROOT + "layout?";

export default function SSEDesignPreviewForWeb({
  selected,
  appId,
  handleSSEDataReceive,
  setSelectedDevice,
}) {
  const selectedDevice = useRef(selected);

  useEffect(() => {
    // In order to make sure that the `return` function below
    // executes properly whenever this effect executes, we
    // must store the return value in this variable.
    let eventSourceInstance = null;
    //Object inside DOM
    if (window.hasOwnProperty("EventSource")) {
      eventSourceInstance = initEventSource();
    } else {
      console.error("EventSource API not available");
    }
    // This effect runs whenever this effect re-runs
    // as well as upon unmount of this component
    return () => {
      if (eventSourceInstance?.close && eventSourceInstance?.url !== "") {
        eventSourceInstance.close();
        console.log("%cClosed the connection", "color: darkred");
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initEventSource = () => {
    const eventSourceInstance = new EventSource(
      SSE_API +
        withQueryStrings({
          appId,
          deviceId: selectedDevice.current,
        }),
    );
    console.log("%cCreated the connection", "color: green");
    eventSourceInstance.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.hasOwnProperty("device_info")) {
          const {
            screen: { image: type, navigation: key1, orientation: key2 },
          } = data;
          switch (type) {
            case "view":
              if (
                selectedDevice.current !== "" &&
                selectedDevice.current === data.device_info.id
              ) {
                handleSSEDataReceive(key1, key2);
              } else {
                // Select the device first
              }
              break;
            case "select":
              setSelectedDevice(key1, key2);
              selectedDevice.current = key2;
              break;
            default:
              break;
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    return eventSourceInstance;
  };

  return <></>;
}
