/**
 * Created by Rakesh Peela
 * Date: 12-Aug-2019
 * Time: 6:22 PM
 */

import { Typography } from "@material-ui/core";
import React, { useEffect, useState, useMemo } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import LockIcon from "@material-ui/icons/Lock";
import Tooltip from "@material-ui/core/Tooltip";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import { Link, useHistory } from "react-router-dom";
import {
  fetchPendingAppApprovalsAPI,
  updatePasswordAPI,
  updateProfileDetailsAPI,
} from "./actions";
import Dialog from "../ReusableComponents/Dialog";
import Snackbar from "../ReusableComponents/Snackbar";

const checkProfileFormChanged = (defaultFormData, { email, name }) => {
  return !(
    email.trim() === defaultFormData.email.trim() &&
    name.trim() !== defaultFormData.name.trim()
  );
};

export function ProfileSettings({ auth }) {
  const [name, setName] = useState(auth.user.name);
  const [email] = useState(auth.user.email);

  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);

  const enableProfileUpdate = useMemo(
    () =>
      checkProfileFormChanged(
        { email: auth.user.email, name: auth.user.name },
        { email, name }
      ),
    [auth.user.email, auth.user.name, email, name]
  );

  return (
    <Card>
      <CardHeader
        title={"Profile Settings"}
        titleTypographyProps={{
          style: {
            fontSize: "1rem",
            fontWeight: "bolder",
            marginTop: 9,
            marginBottom: 9,
          },
        }}
        style={{ borderBottom: "1px solid #e1e1e1" }}
        action={<div />}
      />
      <form noValidate autoComplete={"off"}>
        <CardContent>
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <Grid container spacing={8}>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label={"Name"}
                    name={"name"}
                    fullWidth
                    margin={"normal"}
                    defaultValue={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label={"E-mail Address"}
                    fullWidth
                    margin={"normal"}
                    name={"email"}
                    disabled
                    defaultValue={email}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions style={{ borderTop: "1px solid #e1e1e1" }}>
          <Button
            variant="outlined"
            color={"primary"}
            style={{ margin: 12 }}
            onClick={() => {
              setFailed(false);
              setSuccess(false);
              updateProfileDetailsAPI(auth, {
                email: email,
                name: name,
              })
                .then((response) => {
                  if (response) {
                    setSuccess(true);
                  } else {
                    setFailed(true);
                  }
                })
                .catch((e) => {
                  setFailed(true);
                });
            }}
            disabled={enableProfileUpdate}
          >
            Save Changes
          </Button>
        </CardActions>
      </form>

      <Dialog
        status={success}
        title="Profile Updated"
        confirmLabel={"Ok"}
        handleConfirm={() => {
          setSuccess(false);
          setFailed(false);
        }}
      >
        <Typography>
          Your <strong>Profile</strong> has been successfully updated
        </Typography>
      </Dialog>

      {failed && <Snackbar>There was a problem updating your profile</Snackbar>}
    </Card>
  );
}

const DEFAULT_PASS_STATE = {
  showNewPassword: false,
  showOldPassword: false,
  newPassword: "",
  OldPassword: "",
};

export function PasswordEditSection({ auth }) {
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState(null);
  const [success, setSuccess] = useState(false);
  const [state, setState] = useState({
    ...DEFAULT_PASS_STATE,
    passChangeEnabled: false,
  });

  const handlePasswordInputChange = (inputType, value) => {
    setState({
      ...state,
      [inputType]: value,
    });
  };

  const handleClickShowNewPassword = () => {
    setState({
      ...state,
      showNewPassword: !state.showNewPassword,
    });
    setTimeout(
      () =>
        setState({
          ...state,
          showNewPassword: !state.showNewPassword,
        }),
      1000
    );
  };

  const handleClickShowOldPassword = () => {
    setState({
      ...state,
      showOldPassword: !state.showOldPassword,
    });
    setTimeout(
      () =>
        setState({
          ...state,
          showOldPassword: !state.showOldPassword,
        }),
      1000
    );
  };

  return (
    <Card>
      <CardHeader
        title={"Change Password"}
        titleTypographyProps={{
          style: { fontSize: "1rem", fontWeight: "bolder" },
        }}
        action={
          <Tooltip
            title={state.passChangeEnabled ? "Lock Section" : "Unlock Section"}
            aria-label={
              state.passChangeEnabled ? "Lock Section" : "Unlock Section"
            }
          >
            <IconButton
              onClick={() => {
                setState({
                  ...state,
                  passChangeEnabled: !state.passChangeEnabled,
                });
              }}
            >
              {state.passChangeEnabled ? (
                <LockOpenIcon color={"primary"} />
              ) : (
                <LockIcon color={"error"} />
              )}
            </IconButton>
          </Tooltip>
        }
        style={{ borderBottom: "1px solid #e1e1e1", fontSize: "1rem" }}
      />
      <form noValidate autoComplete={"off"}>
        <CardContent
          style={{ backgroundColor: state.passChangeEnabled ? "" : "#e1e1e1" }}
        >
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                label={"Old Password"}
                type={state.showOldPassword ? "text" : "password"}
                value={state.OldPassword}
                disabled={!state.passChangeEnabled}
                onChange={(e) =>
                  handlePasswordInputChange("OldPassword", e.target.value)
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {state.OldPassword !== "" && (
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={handleClickShowOldPassword}
                        >
                          {state.showOldPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
                fullWidth
                margin={"normal"}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                label={"New Password"}
                type={state.showNewPassword ? "text" : "password"}
                disabled={!state.passChangeEnabled}
                value={state.newPassword}
                onChange={(e) =>
                  handlePasswordInputChange("newPassword", e.target.value)
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {state.newPassword !== "" && (
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={handleClickShowNewPassword}
                        >
                          {state.showNewPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
                fullWidth
                margin={"normal"}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions style={{ borderTop: "1px solid #e1e1e1" }}>
          <Button
            variant="outlined"
            color={"primary"}
            style={{ margin: 12 }}
            disabled={
              state.newPassword === "" ||
              state.OldPassword === "" ||
              state.newPassword === state.OldPassword
            }
            onClick={() => {
              setErrorMessage(null);
              updatePasswordAPI(auth, {
                userId: auth.user.email,
                oldPassword: state.OldPassword,
                newPassword: state.newPassword,
              })
                .then((response) => {
                  if (response) {
                    setSuccess(true);
                    setState({
                      ...state,
                      ...DEFAULT_PASS_STATE,
                    });
                  }
                })
                .catch((e) => {
                  setErrorMessage(e.data?.message || e.message);
                });
            }}
          >
            Update Password
          </Button>
        </CardActions>
      </form>
      {errorMessage && <Snackbar>{errorMessage}</Snackbar>}
      <Dialog
        status={success}
        title="Logout Warning"
        handleClose={() => {
          history.push("/logout");
        }}
        handleConfirm={() => {
          history.push("/logout");
        }}
      >
        <Typography>
          Password change <strong>successful</strong>.
        </Typography>
        <Typography>Please login again.</Typography>
      </Dialog>
    </Card>
  );
}

export function PendingAppsApproval({ auth, userId }) {
  const [pendingApps, setPendingApps] = useState([]);

  useEffect(() => {
    fetchPendingAppApprovalsAPI(auth, userId).then((response) => {
      setPendingApps(response);
    });
  }, [auth, userId]);

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <Typography variant={"h5"} style={{ fontWeight: 600, margin: 16 }}>
          Pending App Approvals
        </Typography>
      </div>
      <Grid container spacing={0}>
        {pendingApps.map((app) => {
          const {
            basic_info: {
              customers,
              icon_url,
              approved,
              approved_by,
              ...otherInfoKeys
            },
            basic_info,
          } = app;
          return (
            <Grid item xs={6} key={app.app_id}>
              <Card key={app.app_id} style={{ margin: 16 }}>
                <CardContent>
                  <Grid container>
                    {Object.keys(otherInfoKeys).map((key, idx) => {
                      return (
                        <Grid item xs={6} key={idx} style={{ padding: 16 }}>
                          <div style={{ fontWeight: 600, paddingRight: 12 }}>
                            {key.replace("_", " ").toUpperCase()}
                          </div>
                          <div>{basic_info[key]}</div>
                        </Grid>
                      );
                    })}
                  </Grid>
                </CardContent>
                <CardActions style={{ borderTop: "1px solid #cecece" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                    }}
                  >
                    <Link to={`/apps/${app.app_id}/approve`}>
                      <Button variant={"outlined"} color={"primary"}>
                        Goto Approval Page >
                      </Button>
                    </Link>
                  </div>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
