import Apxor from "apxor"
import { useCallback, useMemo } from "react"
import {
  DEMO_ECOMMERCE_APP_ID,
  isExperienceDashboard,
} from "../../../../config"
import { useAuth } from "../../../../contexts/AuthContext"
import { isValidEmail } from "../../../../utils"
import { getTestDevicesAPI } from "../../../Dashboard/common/actions"
import { SET_ACCESS_CODE, SET_TEST_DEVICES } from "../../constants"
import { useDispatch, useTrackedState } from "../../store"
import {
  demoAuthenticateAPI,
  demoInitialiseAPI,
  demoResendOtpAPI,
} from "../actions"
import { AUTH_FLOW_STATES } from "../constants"

/**
 * A custom hook to use the sales sandbox functionality.
 */
export default function useSandBox() {
  const { login, resetIntermediateAuthStates } = useAuth()
  const {
    user: { email, phone, country_code, name, job_title, otp },
    auth_error,
  } = useTrackedState()
  const dispatch = useDispatch()

  /**
   * This function is used to fetch the user's test devices.
   * @public @memberof {@link useSandBox}
   * @param {Object=} auth The auth object containing the email of the user.
   * @returns {Promise} Returns a promise containing the test devices.
   */
  const fetchTestDevices = useCallback(
    async (auth = null) => {
      const devices = await getTestDevicesAPI(
        auth
          ? auth
          : {
              user: {
                email,
              },
            },
        DEMO_ECOMMERCE_APP_ID,
        {},
        isExperienceDashboard(),
      )
      dispatch({
        type: SET_TEST_DEVICES,
        payload: devices,
      })
      return devices
    },
    [dispatch, email],
  )

  /**
   * This function is used to send OTP to user's email or log them in directly.
   * @public @memberof {@link useSandBox}
   * @returns {Promise} Returns a promise that resolves to the any of the enum {@link AUTH_FLOW_STATES}.
   */
  const initialise = useCallback(async () => {
    try {
      resetIntermediateAuthStates({
        loading: true,
        error: "",
      })
      const response = await demoInitialiseAPI(email)
      if (response.message) {
        resetIntermediateAuthStates({
          loading: false,
          error: "",
        })
        return {
          data: response.message,
          type: AUTH_FLOW_STATES.OTP,
        }
      } else {
        login(response.user)
        resetIntermediateAuthStates({
          loading: false,
          error: "",
        })
        const devices = await fetchTestDevices()
        dispatch({
          type: SET_TEST_DEVICES,
          payload: devices,
        })
        if (devices?.length < 1) {
          return {
            data: response.user,
            type: AUTH_FLOW_STATES.FINAL,
          }
        } else {
          return {
            data: response.user,
            type: AUTH_FLOW_STATES.USER,
          }
        }
      }
    } catch (err) {
      resetIntermediateAuthStates({
        loading: false,
        error: err.message,
      })
    }
  }, [dispatch, email, fetchTestDevices, login, resetIntermediateAuthStates])

  /**
   * This function is used to authenticate the user with the given access code.
   * @public @memberof {@link useSandBox}
   * @returns {Promise} Returns a promise that resolves to the user's profile.
   */
  const authenticate = useCallback(async () => {
    resetIntermediateAuthStates({
      loading: true,
      error: "",
    })
    try {
      const response = await demoAuthenticateAPI(
        name,
        job_title,
        phone,
        country_code,
        email,
        otp,
      )
      login(response.user)
      Apxor.logEvent("Name_Entered", { 
        "name": name,
        "email": email
       })
      Apxor.logEvent("Job_Title_Entered", { 
        "job_title": job_title,
        "email": email
       })
      //demoSlackMessage(email, phone)
      resetIntermediateAuthStates({
        loading: false,
        error: "",
      })
      dispatch({
        type: SET_ACCESS_CODE,
        payload: response.user?.access_code,
      })
      return {
        data: response.user,
      }
    } catch (err) {
      resetIntermediateAuthStates({
        loading: false,
        error: err.message,
      })
    }
  }, [
    dispatch,
    email,
    phone,
    job_title,
    country_code,
    login,
    name,
    otp,
    resetIntermediateAuthStates,
  ])

  /**
   * This function is used to resend the OTP to the user's email.
   * @public @memberof {@link useSandBox}
   * @returns {Promise} Returns a promise.
   */
  const resendOTP = useCallback(async () => {
    await demoResendOtpAPI(email)
  }, [email])

  /**
   * This memoised value checks if the sign in operation is disabled.
   * @public @memberof {@link useSandBox}
   * @returns {Boolean} Returns **true** if the sign in operation is disabled, **false** otherwise.
   */
  const signInDisabled = useMemo(
    () => !isValidEmail(email) || !phone || auth_error.length > 0,
    [email, phone, auth_error.length],
  )

  /**
   * This memoised value checks if the sign up operation is disabled.
   * @public @memberof {@link useSandBox}
   * @returns {Boolean} Returns **true** if the sign up operation is disabled, **false** otherwise.
   */
  const signUpDisabled = useMemo(
    () => !isValidEmail(email) || otp?.length < 6 || auth_error.length > 0,
    [email, otp, auth_error.length],
  )

  return {
    fetchTestDevices,
    initialise,
    authenticate,
    resendOTP,
    signInDisabled,
    signUpDisabled,
  }
}

