import React from "react";
import ColumnDiv from "../../../../../../ReusableComponents/ColumnDiv";

export const imageEmoji = (code, isCanvas = false) => {
  return (
    <span role="img">
      <img
        src={code}
        style={{
          width: isCanvas ? "36px" : "26px",
        }}
        alt="Apxor"
      />
    </span>
  );
};

export const unicodeEmoji = (code, size) => {
  return (
    <span role="img" style={{ fontSize: size }}>
      {String.fromCodePoint(code)}
    </span>
  );
};

const Emoji = ({
  code = "128579",
  isCanvas = true,
  size = isCanvas ? 36 : 24,
  styles,
  index,
  isunicodeEmoji = true,
  setSelectedIcon,
}) => {
  if (isCanvas) {
    return isunicodeEmoji ? (
      <ColumnDiv
        styles={{
          alignItems: "center",
          width: 36,
          height: 51,
          textAlign: "center",
          justifyContent: "center",
          ...styles,
        }}
      >
        {unicodeEmoji(code, size)}
      </ColumnDiv>
    ) : (
      <ColumnDiv
        styles={{
          alignItems: "center",
          width: 36,
          height: 51,
          textAlign: "center",
          justifyContent: "center",
          ...styles,
        }}
      >
        {imageEmoji(code, isCanvas)}
      </ColumnDiv>
    );
  } else {
    return (
      <ColumnDiv
        styles={{
          alignItems: "center",
          width: 36,
          height: 51,
          textAlign: "center",
          cursor: "pointer",
          justifyContent: "center",
          ...styles,
        }}
        onClick={() => setSelectedIcon(index)}
      >
        {isunicodeEmoji ? unicodeEmoji(code, size) : imageEmoji(code, isCanvas)}
        <span role="img" style={{ fontSize: 14 }}>
          {index}
        </span>
      </ColumnDiv>
    );
  }
};
export default Emoji;
