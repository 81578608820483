import React, { useEffect, useMemo, useState } from "react";
import { Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import debounce from "lodash/debounce";
import { getOperatorString } from "../../../../utils";
import TruncatedText from "../../../../../../../../../ReusableComponents/TruncatedText";
import Autocomplete from "../../../../../../../../../ReusableComponents/Autocomplete";
import PropertyValueSelector from "../../../../PropertyValueSelector";
import DatatypeSelector from "../../../../DatatypeSelector";
import OperatorSelector from "../../../../OperatorSelector";
import RowDiv from "../../../../../../../../../ReusableComponents/RowDiv";
import CustomPopper from "../../../../../../../../../ReusableComponents/CustomPopper";
import { DEFAULT_DATATYPE_OPTIONS } from "../../../../constants";
import { getAttributeValuesAPI } from "../../../../../../../../../common/actions";
import { useAuth } from "../../../../../../../../../../contexts/AuthContext";
import { useDateFilters } from "../../../../../../../utils";
import { useCommonConfigStyles } from "../../../../../utils";
import { ATTRIBUTE_TYPES } from "../../../../../../../../components/settings/EventsManagement/constants";

const executeWithDebounce = debounce((fn, ...args) => {
  fn(...args);
}, 700);

const useStyles = makeStyles((theme) => ({
  closeIcon: (props) => ({
    color: "#A1ADB6",
    padding: 2,
    "&:hover": {
      background: "none",
    },
  }),
  paperClass: (props) => ({
    overflow: "unset",
  }),
}));

function PropertyRow({
  eventType,
  eventName,
  index,
  name: propName,
  property,
  handleOnChange,
  handleOnDelete,
  platform,
  attributes,
  allAttributes,
  isDidnotProperty = false,
}) {
  const classes = useStyles();
  const typographyClasses = useCommonConfigStyles();

  const auth = useAuth();
  const [dateFilters] = useDateFilters(true);

  const { t = "s", tl = "String", op = "EQ", val = property } = property;

  const [nameAnchorEl, setNameAnchorEl] = useState(null);
  const [operatorAnchorEl, setOperatorAnchorEl] = useState(null);
  const [typeAnchorEl, setTypeAnchorEl] = useState(null);
  const [valueAnchorEl, setValueAnchorEl] = useState(null);

  // It's wise to maintain attribute values in every property row
  const [attributeValues, setAttributeValues] = useState([]);
  const [dataTypes, setDataTypes] = useState(DEFAULT_DATATYPE_OPTIONS);

  const [state, setState] = useState({
    attribute: propName,
    op,
    tl,
    t,
    val,
  });

  const {
    attribute: name,
    op: operator,
    tl: label,
    t: type,
    val: value,
  } = state;

  // Upon component mount, fetch attribute values
  // iff attribute name is not empry and eventType is App Event
  useEffect(() => {
    if (name !== "" && eventType === "APP") {
      getAttributeValues(name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch attribute data types iff attributes are not empty
  // This is only meant for Client events
  useEffect(() => {
    if (allAttributes.length < 1) {
      return;
    }
    if (name !== "" && eventType === "CLIENT") {
      getAttributeDataTypes(name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAttributes]);

  // Execute handleOnChange whenever any of the state values modified
  useEffect(() => {
    executeWithDebounce(handleOnChange, {
      ...state,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.attribute, state.op, state.t, state.tl, state.val]);

  const getAttributeDataTypes = (attribute) => {
    let dTypes = [];
    if (allAttributes && allAttributes[attribute]) {
      const types = Object.values(allAttributes[attribute] || {});
      types.forEach((type) => {
        switch (type) {
          case ATTRIBUTE_TYPES.STRING:
            dTypes.push({ value: "s", label: "String" });
            break;
          case ATTRIBUTE_TYPES.BOOLEAN:
            dTypes.push({ value: "s", label: "Boolean" });
            break;
          case ATTRIBUTE_TYPES.INT:
            dTypes.push({ value: "l", label: "Integer" });
            break;
          case ATTRIBUTE_TYPES.DOUBLE:
            dTypes.push({ value: "f", label: "Decimal" });
            break;
          default:
            break;
        }
      });
    } else {
      dTypes.push({ value: t || "s", label: label || "String" });
    }
    dTypes = dTypes.reduce((acc, item) => {
      if (acc.findIndex((record) => record.label === item.label) === -1) {
        acc.push(item);
      }
      return acc;
    }, []);
    setDataTypes(dTypes.length > 0 ? dTypes : DEFAULT_DATATYPE_OPTIONS);
  };

  const getAttributeValues = (attrName, query = null) => {
    getAttributeValuesAPI(auth, auth.appId, {
      ...dateFilters,
      of: "users",
      event: eventName,
      attribute: attrName,
      q: query || "",
    }).then((response) => {
      setAttributeValues(response);
    });
  };

  const attributeNames = useMemo(
    () =>
      eventType === "CLIENT"
        ? Object.keys(attributes).length > 0
          ? Object.keys(attributes)
          : []
        : attributes,
    [eventType, attributes]
  );

  // Helper memo to get proper label based on the data types
  //
  // This will override default String label that gets set
  // when new property is added
  const updatedLabel = useMemo(() => {
    return dataTypes.length > 0 &&
      dataTypes.findIndex((item) => item.label === label) === -1
      ? dataTypes[0].label
      : label;
  }, [dataTypes, label]);

  const propertySelector = (
    <PropertyValueSelector
      attributeValues={attributeValues}
      attrValues={attributeValues}
      eventType={eventType}
      handleOnChange={(value) =>
        setState({
          ...state,
          val: value,
        })
      }
      isDidnotProperty={isDidnotProperty}
      label={updatedLabel}
      name={name}
      onInputChange={(query) => {
        setState({
          ...state,
          val: query,
        });
        getAttributeValues(name, query);
      }}
      operator={operator}
      platform={platform}
      type={type}
      value={value}
    />
  );

  return (
    <RowDiv center>
      <div style={{ width: 30, borderBottom: "1px solid #C5CDD2" }}></div>
      <Grid
        container
        spacing={1}
        style={{ marginBottom: 2, marginTop: index === 0 ? 5 : 3 }}
      >
        <Grid
          item
          xs={11}
          style={{
            overflow: "hidden",
            marginLeft: isDidnotProperty ? -8 : "unset",
          }}
        >
          <Grid
            container
            spacing={1}
            style={{
              background: "#F0F2F3",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid item style={{ marginLeft: 10 }}>
              <Typography
                className={typographyClasses.typographyCaption}
                style={{ alignSelf: "center" }}
              >
                {index === 0 ? "where" : "and where"}
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: -4 }}>
              <TruncatedText
                placeholder="Property Name"
                value={name}
                maxWidth={isDidnotProperty ? 50 : null}
                onClick={(e) => e && setNameAnchorEl(e.currentTarget)}
              />
              <CustomPopper
                anchorEl={nameAnchorEl}
                setAnchorEl={setNameAnchorEl}
              >
                <Autocomplete
                  styles={{ width: 300 }}
                  defaultValue={{ label: name, value: name }}
                  handleOnChange={(selected) => {
                    if (selected && eventType === "CLIENT") {
                      getAttributeDataTypes(selected.value);
                    }
                    setState({
                      attribute: selected?.value || "",
                      op: "EQ",
                      tl: "String",
                      t: "s",
                      val: "",
                    });
                    if (selected && eventType === "APP")
                      getAttributeValues(selected.value);
                  }}
                  options={attributeNames.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                />
              </CustomPopper>
            </Grid>
            {name !== "" && (
              <>
                <Grid item style={{ marginBottom: -4 }}>
                  <TruncatedText
                    maxWidth={isDidnotProperty ? 50 : null}
                    value={updatedLabel}
                    onClick={(e) => e && setTypeAnchorEl(e.currentTarget)}
                  />
                  <CustomPopper
                    anchorEl={typeAnchorEl}
                    setAnchorEl={setTypeAnchorEl}
                    paperClass={classes.paperClass}
                  >
                    <DatatypeSelector
                      type={type}
                      options={dataTypes}
                      handleOnChange={(value, label) => {
                        setState({
                          ...state,
                          t: value,
                          tl: label,
                        });
                      }}
                    />
                  </CustomPopper>
                </Grid>
                <Grid item style={{ marginBottom: -4 }}>
                  <TruncatedText
                    maxWidth={isDidnotProperty ? 50 : null}
                    value={getOperatorString(operator)}
                    onClick={(e) => e && setOperatorAnchorEl(e.currentTarget)}
                  />
                  <CustomPopper
                    anchorEl={operatorAnchorEl}
                    setAnchorEl={setOperatorAnchorEl}
                    paperClass={classes.paperClass}
                  >
                    <OperatorSelector
                      type={updatedLabel}
                      selected={operator}
                      handleOnChange={(value) =>
                        setState({
                          ...state,
                          op: value,
                        })
                      }
                    />
                  </CustomPopper>
                </Grid>
              </>
            )}
            {name.length > 0 &&
              type &&
              ((eventType !== "CLIENT" &&
                updatedLabel !== "Boolean" &&
                operator !== "R") ||
                updatedLabel === "Boolean") && (
                <Grid item style={{ marginBottom: -4 }}>
                  <TruncatedText
                    maxWidth={isDidnotProperty ? 50 : null}
                    placeholder="value"
                    value={value}
                    onClick={(e) => e && setValueAnchorEl(e.currentTarget)}
                  />
                  <CustomPopper
                    anchorEl={valueAnchorEl}
                    setAnchorEl={setValueAnchorEl}
                  >
                    {propertySelector}
                  </CustomPopper>
                </Grid>
              )}
            {name.length > 0 &&
              type &&
              !(
                (eventType !== "CLIENT" &&
                  updatedLabel !== "Boolean" &&
                  operator !== "R") ||
                updatedLabel === "Boolean"
              ) &&
              propertySelector}
          </Grid>
        </Grid>
        <Grid
          item
          xs={1}
          style={{ display: "flex", padding: 0, justifyContent: "center" }}
        >
          <IconButton
            onClick={() => handleOnDelete(name)}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    </RowDiv>
  );
}

export default React.memo(PropertyRow);
