import React from "react";
import { SURVEY_MODULE_TYPES } from "../../../../constants";
import Question from "../../../surveysconfigsection/components/questions/components/Question";
import { HoverDiv, QuestionHolder } from "../../utils";
import QuestionSimulator from "../QuestionSimulator";
import SurveyContainer from "../SurveyContainer";

const TextBasedSimulator = ({
  questions,
  currentStep,
  onModuleSelection,
  moduleType,
}) => {
  return (
    <SurveyContainer
      currentQuestion={questions[currentStep]}
      moduleType={moduleType}
      onModuleSelection={onModuleSelection}
    >
      <QuestionSimulator
        {...questions[currentStep]}
        index={currentStep}
        totalQuestions={questions.length}
        isCanvas={true}
      />
    </SurveyContainer>
  );
};

export default TextBasedSimulator;
