import { useEffect } from "react";
import { useAuth } from "../../../../contexts/AuthContext";
import { logoutAPI } from "../actions";

export default function LogoutPage() {
  const { logout } = useAuth();

  useEffect(() => {
    (async () => {
      try {
        await logoutAPI();
        logout();
      } catch (err) {
      } finally {
        logout();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
