import {
  Button,
  IconButton,
  LinearProgress,
  Paper,
  withStyles,
} from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Clear";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";
import React, { useEffect, useState } from "react";
import { randomColorWithIndex } from "../../../../../../../../../utils";
import RowDiv from "../../../../../../../../ReusableComponents/RowDiv";
import {
  ANSWER_MODES,
  selectedColor,
  SURVEY_ORIENTATION,
} from "../../../constants";
import { useTrackedState } from "../../../store";
import QuestionSimulator from "./QuestionSimulator";

const HEADER_ACTION_STYLES = {
  display: "flex",
  justifyContent: "space-between",
  margin: "8px auto",
};

const SUBMIT_BUTTON_STYLES = {
  display: "block",
  color: selectedColor(true),
};

function LinearProgressBar({ classes, progress, progress_bar_color }) {
  return (
    <LinearProgress
      value={progress}
      variant="determinate"
      classes={{
        colorPrimary: classes.colorPrimary,
        barColorPrimary: classes.barColorPrimary,
      }}
    />
  );
}

const withStylesProps = (styles) => (Component) => (props) => {
  const Comp = withStyles((theme) => styles({ ...props, ...theme }))(Component);
  return <Comp {...props} />;
};

const progressBarStyles = (props) => {
  return {
    colorPrimary: {
      backgroundColor: "#FFF",
    },
    barColorPrimary: {
      backgroundColor: props.progress_bar_color || "#b6bce2",
    },
  };
};

const ColoredLinearProgressBar = withStylesProps(progressBarStyles)(
  LinearProgressBar,
);

function getLayoutType(currentQuestion, dialog_orientation, layout_type) {
  let layoutToCompare = layout_type;
  if (currentQuestion) {
    if (currentQuestion.answer_mode === ANSWER_MODES.request_survey)
      layoutToCompare = dialog_orientation;
    else if (currentQuestion.answer_mode === ANSWER_MODES.success_message)
      layoutToCompare = SURVEY_ORIENTATION.FULL_SCREEN;
  }
  return layoutToCompare;
}

function getBackground(
  currentQuestion,
  question_background_color,
  dialog_background_color,
  success_message_bg_color,
) {
  if (currentQuestion) {
    if (currentQuestion.answer_mode === ANSWER_MODES.request_survey)
      return dialog_background_color;
    else if (currentQuestion.answer_mode === ANSWER_MODES.success_message)
      return success_message_bg_color;
  }
  return question_background_color;
}

function getAdditionalStyles(currentQuestion, layout_type) {
  if (
    currentQuestion &&
    (currentQuestion.answer_mode === ANSWER_MODES.success_message ||
      layout_type === SURVEY_ORIENTATION.FULL_SCREEN) &&
    currentQuestion.answer_mode !== ANSWER_MODES.request_survey
  )
    return { height: "100%" };
  return {};
}

/**
 * Simulates the Survey as per the configuration
 *
 * Displays the Dialog when dialog is enabled in config section
 *    If Yes is clicked -> display list of questions
 *    If No is clicked -> end the survey
 *
 * Skip, Previous, Close and progress bar will be displayed if
 * they are enabled in config
 *
 * Displays one question at a time and if it marked as required,
 * we have to choose an option or respond to that question then
 * Submit button will be enabled.
 *
 * Upon submit button click, we display next question. Once all
 * questions are answered and show success message is enabled,
 * we display the configured success message along with it's configuration
 *
 * If it is response based survey, next question will be displayed as per
 * the configuration
 */
export default function SimulatorHolder({ reset }) {
  const {
    ui: { questions, is_response_based_survey, layout_type },
    ui,
    currentStep,
  } = useTrackedState();
  const {
    is_skip_enabled,
    is_previous_enabled,
    is_cancel_enabled,
    show_progress_bar,
    skip_text_color,
    cancel_button_color,
    previous_button_color,
    progress_bar_color,
    submit_text_color,
    submit_active_color,
    submit_inactive_color,
    dialog_config,
    success_message_bg_color,
    question_background_color,
  } = ui;
  const { dialog_orientation, dialog_background_color } = dialog_config || {
    dialog_orientation: "",
    dialog_background_color: "#FFFFFF",
  };
  const [dialogDone, setDialogDone] = useState(false);
  const [dialogOutput, setDialogOutput] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(currentStep);
  const [nextQuestion, setNextQuestion] = useState(-1);
  const currentQuestion = questions[currentQuestionIndex];

  const [progress, setProgress] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [additionalStyles, setAdditionalStyles] = useState(
    (() => {
      let layoutToCompare = getLayoutType(
        currentQuestion,
        dialog_orientation,
        layout_type,
      );

      if (layoutToCompare === SURVEY_ORIENTATION.POPUP) {
        return {
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        };
      } else if (layoutToCompare === SURVEY_ORIENTATION.FOOTER) {
        return { justifyContent: "flex-end" };
      } else return {};
    })(),
  );
  const disableSubmitButton =
    currentQuestion &&
    currentQuestion.is_required &&
    !answeredQuestions.includes(currentQuestionIndex);

  const showQuestions = !showMessage && questions.length > 0;
  const lastQuestion =
    questions.length > 0 && currentQuestionIndex === questions.length - 1;

  let defaultSimulatorWidth = 270;
  let defaultSimulatorHeight = 540;
  /**
   * Whenever reset button clicked, reset the simulator state
   */
  useEffect(() => {
    if (reset) {
      setDialogDone(false);
      setDialogOutput(false);
      setShowMessage(false);
      setCurrentQuestionIndex(currentStep);
      setProgress(0);
      setAnsweredQuestions([]);
    }
  }, [currentStep, reset]);

  useEffect(() => {
    setAdditionalStyles(
      (() => {
        let layoutToCompare = getLayoutType(
          currentQuestion,
          dialog_orientation,
          layout_type,
        );

        if (layoutToCompare === SURVEY_ORIENTATION.POPUP) {
          return {
            justifyContent: "center",
            position: "relative",
            paddingLeft: "20px",
            paddingRight: "20px",
          };
        } else if (layoutToCompare === SURVEY_ORIENTATION.FOOTER) {
          return { justifyContent: "flex-end" };
        } else return { backgroundColor: "#FFFFFF" };
      })(),
    );
  }, [layout_type, currentQuestion, dialog_orientation]);

  useEffect(() => {
    setCurrentQuestionIndex(currentStep);
  }, [currentStep]);

  useEffect(() => {
    setProgress((currentQuestionIndex / questions.length) * 100);
  }, [currentQuestionIndex, questions]);

  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        width: defaultSimulatorWidth,
        height: defaultSimulatorHeight,
        backgroundColor: "#000000",
        display: "flex",
        flexDirection: "column",
        borderRadius: 3,
        border: "1px solid #DCE0E3",
        ...additionalStyles,
      }}
    >
      {showQuestions && (
        <>
          {show_progress_bar && (
            <ColoredLinearProgressBar
              progress={progress}
              progress_bar_color={progress_bar_color}
            />
          )}
          <Paper
            elevation={0}
            style={{
              padding: 8,
              color: "unset",
              background: getBackground(
                currentQuestion,
                question_background_color,
                dialog_background_color,
                success_message_bg_color,
              ),
              ...getAdditionalStyles(currentQuestion, layout_type),
            }}
          >
            <div style={HEADER_ACTION_STYLES}>
              {currentQuestionIndex > 0 && is_previous_enabled && (
                <IconButton
                  onClick={(e) =>
                    currentQuestionIndex > currentStep &&
                    setCurrentQuestionIndex(currentQuestionIndex - 1)
                  }
                  style={{ color: previous_button_color || undefined }}
                >
                  <KeyboardBackspace />
                </IconButton>
              )}
              {currentQuestionIndex === 0 && is_cancel_enabled && (
                <IconButton
                  onClick={(e) => {
                    setCurrentQuestionIndex(0);
                    setDialogDone(false);
                    setDialogOutput(false);
                  }}
                  style={{
                    marginLeft: "auto",
                    color: cancel_button_color || undefined,
                  }}
                >
                  <RemoveIcon />
                </IconButton>
              )}
            </div>
            {questions.length > 0 && questions[currentQuestionIndex] && (
              <QuestionSimulator
                currentQuestionIndex={currentQuestionIndex}
                setCurrentQuestionIndex={setCurrentQuestionIndex}
                key={questions[currentQuestionIndex].id}
                ui={ui}
                is_response_based_survey={ui.is_response_based_survey}
                {...questions[currentQuestionIndex]}
                handleAnswer={(answered, nextQuestionId) => {
                  if (
                    answered &&
                    !answeredQuestions.includes(currentQuestionIndex)
                  ) {
                    setAnsweredQuestions([
                      ...answeredQuestions,
                      currentQuestionIndex,
                    ]);
                  }
                  if (!answered) {
                    const index = answeredQuestions.indexOf(
                      currentQuestionIndex,
                    );
                    setAnsweredQuestions([
                      ...answeredQuestions.slice(0, index),
                      ...answeredQuestions.slice(index + 1),
                    ]);
                  }
                  // Set the next question ID if `is_response_based_survey` flag is enabled
                  if (
                    answered &&
                    is_response_based_survey &&
                    nextQuestionId !== undefined &&
                    nextQuestionId !== null
                  ) {
                    if (nextQuestionId === -1) {
                      setNextQuestion(nextQuestionId);
                      return;
                    }

                    const index = questions.findIndex(
                      (q) => q.id === nextQuestionId,
                    );
                    if (index > -1) {
                      setNextQuestion(index);
                    }
                  }
                }}
              />
            )}
            {currentQuestion &&
              currentQuestion.answer_mode !== ANSWER_MODES.success_message &&
              currentQuestion.answer_mode !== ANSWER_MODES.request_survey && (
                <RowDiv center styles={{ justifyContent: "end" }}>
                  {!lastQuestion &&
                    questions[currentQuestionIndex] &&
                    !questions[currentQuestionIndex].is_required &&
                    is_skip_enabled && (
                      <Button
                        variant="text"
                        onClick={(e) => {
                          if (currentQuestionIndex < questions.length - 1) {
                            setCurrentQuestionIndex(currentQuestionIndex + 1);
                          }
                        }}
                        style={{ color: skip_text_color || undefined }}
                      >
                        <span style={{ margin: "auto", textTransform: "none" }}>
                          Skip
                        </span>
                      </Button>
                    )}
                  <Button
                    variant="contained"
                    disabled={disableSubmitButton}
                    onClick={(e) => {
                      if (lastQuestion) {
                        setShowMessage(true);
                      } else {
                        if (!is_response_based_survey) {
                          if (currentQuestionIndex < questions.length - 1) {
                            setCurrentQuestionIndex(currentQuestionIndex + 1);
                          } else {
                            setShowMessage(true);
                          }
                        } else {
                          // If nextQuestion is -1, end the survey
                          if (nextQuestion === -1) {
                            setShowMessage(true);
                          } else {
                            // Set the current question index to nextQuestion
                            setCurrentQuestionIndex(nextQuestion);
                          }
                        }
                      }
                    }}
                    style={{
                      ...SUBMIT_BUTTON_STYLES,
                      color: submit_text_color || undefined,
                      background: disableSubmitButton
                        ? submit_inactive_color || undefined
                        : submit_active_color || randomColorWithIndex(0),
                    }}
                  >
                    {(questions &&
                      questions[currentQuestionIndex] &&
                      questions[currentQuestionIndex].submit_text) ||
                      "Next >"}
                  </Button>
                </RowDiv>
              )}
          </Paper>
        </>
      )}
    </div>
  );
}
