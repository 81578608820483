import React, { useEffect, useState, useRef, useCallback } from "react";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Stepper from "@material-ui/core/Stepper";
import {
  NOTIFICATION_CONFIGURATIONS,
  SET_PUSH_CONFIG_AS_INITIAL_STATE,
  SET_APP_SCREENS,
  SET_APP_SEGMENTS,
  SET_APP_VERSIONS,
  SET_APP_COHORTS,
  SET_TEST_DEVICES,
} from "../constants";
import StepButton from "@material-ui/core/StepButton";
import Step from "@material-ui/core/Step";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { useDateFilters } from "../../../common/utils";
import { useAuth } from "../../../../../contexts/AuthContext";
import {
  getSingleNotificationAPI,
  saveNotificationAPI,
  updateNotificationAPI,
} from "../actions";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { Provider, useTracked } from "../store";
import Snackbar from "../../../../ReusableComponents/Snackbar";
import Loading from "../../../../ReusableComponents/Loading";
import { AudienceSelector } from "./AudienceSelector";
import { MetaSelector } from "./MetaSelector";
import { ConfigSelector } from "./ConfigSelector";
import { DesignSelector } from "./DesignSelector";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { SCHEDULING_ENUM } from "../../../common/components/campaigncreator/constants";
import moment from "moment";

import {
  getAppScreenNamesAPI,
  getSegmentsAPI,
} from "../../../../common/actions";
import {
  getAppVersions,
  getTestDevicesAPI,
  getCohorts,
} from "../../../../Dashboard/common/actions";

const metaValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is Required"),
  meta: Yup.object().shape({
    platform: Yup.string().required("Platform is Required"),
  }),
});

const configurationValidationSchema = Yup.object().shape({
  payload: Yup.object().shape({
    ttl: Yup.string().test("is_greater", "TTL Cannot be in the past", function (
      value
    ) {
      const ttlDate = moment(Number(this.parent.ttl)).format();
      return moment(ttlDate).isSameOrAfter(moment().format());
    }),
  }),
});

const DesignValidationSchema = Yup.object().shape({
  payload: Yup.object().shape({
    notification_title: Yup.string().required("Notification Title is Required"),
    //   channel_id: Yup.string().when("platform", {
    //     is: APP_PLATFORMS.android,
    //     then: Yup.string().required("Channel is Required"),
    //   }),
    //   activity_name: Yup.string().when("redirection_type", {
    //     is: "activity",
    //     then: Yup.string().required("Activity is Required"),
    //   }),
    //   deep_link_uri: Yup.string().when("redirection_type", {
    //     is: "deep_link",
    //     then: Yup.string().required("DeepLink is Required"),
    //   }),
  }),
});

const audienceValidationSchema = Yup.object().shape({
  scheduling: Yup.object().shape({
    schedule_start_datetime: Yup.string().when("schedule", {
      is: SCHEDULING_ENUM.LATER,
      then: Yup.string().test(
        "is_greater",
        "Start Time should be greater than Present Time ",
        function (value) {
          const startDate = moment(this.parent.schedule_start_datetime).format(
            "YYYY-MM-DD HH:mm"
          );
          return moment(startDate).isSameOrAfter(
            moment().format("YYYY-MM-DD HH:mm"),
            "YYYY-MM-DD HH:mm"
          );
        }
      ),
    }),
    schedule_end_datetime: Yup.string().when("isRecurEnabled", {
      is: true,
      then: Yup.string().test(
        "is_greater",
        "End Time should be greater than Present Time And Start Time ",
        function (value) {
          const startDate = moment(this.parent.schedule_start_datetime).format(
            "YYYY-MM-DD HH:mm"
          );
          const endDate = moment(this.parent.schedule_end_datetime).format(
            "YYYY-MM-DD HH:mm"
          );
          return (
            moment(endDate).isSameOrAfter(
              moment().format("YYYY-MM-DD HH:mm"),
              "YYYY-MM-DD HH:mm"
            ) && moment(endDate).isSameOrAfter(startDate, "YYYY-MM-DD HH:mm")
          );
        }
      ),
    }),
  }),
});

function NotificationCreator({ app, config }) {
  const [state, dispatch] = useTracked();
  const history = useHistory();
  const auth = useAuth();
  const { url, params } = useRouteMatch();
  const [dateFilters] = useDateFilters();
  const [pending, setPending] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [validationSchema, setValidationSchema] = useState(
    metaValidationSchema
  );
  const [activeStep, setActiveStep] = useState(0);
  const { push_config: initialValues } = state;
  const stateRef = useRef(initialValues);
  stateRef.current = initialValues;

  const app_id = app?.app_id;

  useEffect(() => {
    getAppScreenNamesAPI(auth, app_id).then(screens => {
      dispatch({ type: SET_APP_SCREENS, value: screens });
    });
    getAppVersions(auth, app_id).then(response => {
      dispatch({ type: SET_APP_VERSIONS, value: response });
    });
    getSegmentsAPI(auth, app_id, dateFilters).then(response => {
      dispatch({ type: SET_APP_SEGMENTS, value: response });
    });
    getCohorts(auth, app_id).then(response => {
      dispatch({ type: SET_APP_COHORTS, value: response });
    });
    getTestDevicesAPI(auth, app_id, dateFilters).then(response => {
      dispatch({ type: SET_TEST_DEVICES, value: response });
    });
  }, [app_id, auth, dateFilters, dispatch]);

  const getNotificationFromServer = useCallback(() => {
    setPending(true);
    const notificationId = params.pushId;
    getSingleNotificationAPI(auth, app_id, notificationId, dateFilters)
      .then(response => {
        dispatch({
          type: SET_PUSH_CONFIG_AS_INITIAL_STATE,
          value: url.includes("/edit")
            ? response.push_config
            : {
                ...response.push_config,
                name: response.push_config.name + " (Copy)",
              },
        });
        setPending(false);
      })
      .catch(() => setPending(false));
  }, [app_id, auth, dateFilters, dispatch, params.pushId, url]);

  useEffect(() => {
    if (url.includes("/edit") || url.includes("/duplicate")) {
      config !== null
        ? dispatch({
            type: SET_PUSH_CONFIG_AS_INITIAL_STATE,
            value: config,
          })
        : getNotificationFromServer();
    }
  }, [config, dispatch, getNotificationFromServer, url]);

  useEffect(() => {
    if (activeStep === 2 && !stateRef.current.payload.is_silent)
      setValidationSchema(DesignValidationSchema);
    if (activeStep === 3) setValidationSchema(audienceValidationSchema);
    if (activeStep === 0) setValidationSchema(metaValidationSchema);
    if (activeStep === 1) setValidationSchema(configurationValidationSchema);
    if (activeStep === 2 && stateRef.current.payload.is_silent)
      setValidationSchema(Yup.object({}));
  }, [activeStep, stateRef.current.payload.is_silent]);

  const handleSubmit = (formik, from, toStep) => {
    const { submitForm, setSubmitting } = formik;
    formik.setValues(stateRef.current).then(() => {
      submitForm().then(() => {
        if (stateRef.current.is_valid === true) {
          // eslint-disable-next-line default-case
          switch (from) {
            case "next":
              return setActiveStep((prevState) => prevState + 1);
            case "save":
              return handleSave();
            case "jump":
              return setActiveStep(toStep);
          }
        }
        setSubmitting(false);
      });
    });
  };

  const handleSave = () => {
    (url.includes("/edit")
      ? updateNotificationAPI(
          auth,
          app_id,
          params.pushId,
          dateFilters,
          stateRef.current
        )
      : saveNotificationAPI(auth, app_id, dateFilters, stateRef.current)
    )
      .then((response) => {
        if (response) {
          history.push(`/apps/${app_id}/push`);
        }
      })
      .catch((err) =>
        setSnackbarMessage(
          url.includes("/edit") ? "Updation Failed " : "Save Failed"
        )
      );
  };

  return (
    <Grid container>
      <Grid item xs>
        <Formik
          initialValues={initialValues}
          onSubmit={() => null}
          enableReinitialize={false}
          validationSchema={validationSchema}
          validateOnChange
        >
          {(formik) => {
            const { errors } = formik;
            return (
              <Form noValidate>
                <ExpansionPanel>
                  {pending && <Loading size={100} />}
                  {!pending && (
                    <ExpansionPanelDetails>
                      <div style={{ width: "100%" }}>
                        <Stepper nonLinear activeStep={activeStep}>
                          {Object.values(NOTIFICATION_CONFIGURATIONS).map(
                            (label, index) => (
                              <Step key={label}>
                                <StepButton
                                  onClick={() => {
                                    handleSubmit(formik, "jump", index);
                                  }}
                                >
                                  {label}
                                </StepButton>
                              </Step>
                            )
                          )}
                        </Stepper>
                        <div style={{ padding: 30 }}>
                          {activeStep === 0 && (
                            <MetaSelector errors={errors} app={app} />
                          )}
                          {activeStep === 1 && (
                            <ConfigSelector errors={errors} />
                          )}
                          {activeStep === 2 && (
                            <DesignSelector
                              app={app}
                              errors={errors}
                              formik={formik}
                            />
                          )}
                          {activeStep === 3 && (
                            <AudienceSelector errors={errors} />
                          )}
                        </div>
                        <div style={{ display: "flex", marginTop: 20 }}>
                          <div>
                            <Link
                              to={{
                                pathname: `/apps/${app_id}/push`,
                              }}
                            >
                              <Button size="small">Discard</Button>
                            </Link>
                          </div>
                          <div style={{ marginLeft: "auto" }}>
                            <Button
                              size="small"
                              disabled={activeStep === 0}
                              onClick={() =>
                                setActiveStep((prevStep) => prevStep - 1)
                              }
                            >
                              Back
                            </Button>
                            <Button
                              size="small"
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                activeStep !== 3
                                  ? handleSubmit(formik, "next")
                                  : handleSubmit(formik, "save");
                              }}
                            >
                              {activeStep !== 3 ? "Next" : "Save"}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </ExpansionPanelDetails>
                  )}
                </ExpansionPanel>
              </Form>
            );
          }}
        </Formik>
      </Grid>
      {snackbarMessage !== "" && (
        <Snackbar onClose={() => setSnackbarMessage("")}>
          {snackbarMessage}
        </Snackbar>
      )}
    </Grid>
  );
}

export default function NotificationCreatorWithProvider({ app, config }) {
  return (
    <Provider>
      <NotificationCreator app={app} config={config}></NotificationCreator>
    </Provider>
  );
}
