import { DATA_TYPES, NUMBER_OPERATOR_OPTIONS } from "./constants";
import { DEFAULT_OPERATOR_OPTIONS } from "./constants";
import { EQUALITY_OPERATOR_OPTIONS } from "./constants";
import { STRING_OPERATOR_OPTIONS } from "./constants";

export const getEventType = (type, isConfig = false) => {
  switch (type) {
    case "ACTIVITY":
      return isConfig ? "activity_event" : "Screen Names";
    case "SCREEN":
      return isConfig ? "screen_event" : "Screen Names";
    case "CLIENT":
      return isConfig ? "client_event" : "Client Events";
    default:
      return isConfig ? "app_event" : "App Events";
  }
};

export const removeLastChar = (str) => {
  if (!str) {
    return "";
  }
  return str.substring(0, str.length - 1);
};

export const getDatatype = (type) => {
  switch (type) {
    case "l":
      return DATA_TYPES.INTEGER;
    case "f":
      return DATA_TYPES.DECIMAL;
    default:
      return DATA_TYPES.STRING;
  }
};

export const getOperatorString = (operator) => {
  switch (operator) {
    case "GT":
      return ">";
    case "GTE":
      return ">=";
    case "LT":
      return "<";
    case "LTE":
      return "<=";
    case "EQ":
      return "==";
    case "NEQ":
      return "!=";
    case "R":
      return "REGEX";
    default:
      return "";
  }
};

export const getOptionsForType = (type) => {
  switch (type) {
    case DATA_TYPES.STRING:
      return STRING_OPERATOR_OPTIONS;
    case DATA_TYPES.BOOLEAN:
      return EQUALITY_OPERATOR_OPTIONS;
    case DATA_TYPES.DECIMAL:
    case DATA_TYPES.INTEGER:
      return NUMBER_OPERATOR_OPTIONS;
    default:
      return DEFAULT_OPERATOR_OPTIONS;
  }

};