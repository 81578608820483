import React, { useState } from "react";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Radio,
  withStyles,
} from "@material-ui/core";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import ClassicCard from "../../../../../../../ReusableComponents/ClassicCard";
import ModuleConfigSection from "../../../../../../../ReusableComponents/ModuleConfigSection";
import { useTrackedState } from "../../../../../../common/components/campaigncreator/store";

const styles = (theme) => ({
  deviceListItem: {
    marginTop: 4,
    marginBottom: 4,
    borderRadius: 4,
    padding: 0,
  },
  deviceListItem_checkBox: {
    padding: 4,
  },
  deviceListItem_text: {
    padding: "2px 12px",
  },
  cardRoot: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    border: `0px solid ${theme.palette.border.disabled} !important`,
  },
});

function DevicePreviewWithoutSSE({ classes, onPreviewClick }) {
  const campaignState = useTrackedState();
  const { test_devices } = campaignState;

  const [selectedDevice, setSelectedDevice] = useState("");

  return (
    <div>
      <ClassicCard
        style={{ marginTop: 12 }}
        classes={{
          root: classes.cardRoot,
        }}
        selected={false}
      >
        <ModuleConfigSection
          title={"Select Your Test Device"}
          typographyStyleProps={{ fontWeight: 600 }}
        >
          <List
            style={{
              maxHeight: 180,
              overflowY: "scroll",
              overflowX: "hidden",
              padding: 0,
              paddingRight: 8,
            }}
          >
            {test_devices.map((testDevice, index) => {
              const isSelected = selectedDevice === testDevice.id;
              return (
                <ListItem
                  key={testDevice.id + "-" + index}
                  role={undefined}
                  dense
                  button
                  className={classes.deviceListItem}
                  style={{
                    background: isSelected ? "#ced5ff70" : "transparent",
                  }}
                  onClick={() => setSelectedDevice(testDevice.id)}
                >
                  <Radio
                    color={"primary"}
                    className={classes.deviceListItem_checkBox}
                    checked={isSelected}
                    tabIndex={-1}
                    disableRipple
                  />
                  <ListItemText
                    className={classes.deviceListItem_text}
                    primary={`${
                      testDevice.hasOwnProperty("nick_name")
                        ? testDevice.nick_name
                        : testDevice.model
                    }`}
                    secondary={
                      `${testDevice.id}` +
                      (testDevice.hasOwnProperty("nick_name")
                        ? " · " + testDevice.model
                        : "")
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        </ModuleConfigSection>
      </ClassicCard>
      <ClassicCard
        style={{ display: "flex", flexDirection: "row-reverse" }}
        classes={{
          root: classes.cardRoot,
        }}
        selected={false}
      >
        <Button
          disabled={selectedDevice === ""}
          variant={"contained"}
          color={"primary"}
          style={{ marginTop: 8 }}
          onClick={() => onPreviewClick()}
        >
          <OfflineBoltIcon style={{ marginRight: 8 }} /> Preview On Device
        </Button>
      </ClassicCard>
    </div>
  );
}

export default withStyles(styles)(DevicePreviewWithoutSSE);
