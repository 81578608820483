import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { formatNumber } from "../../../../../../../utils";
import Box from "../../../../../../ReusableComponents/Box";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import { OtherOptionResults } from "./OtherOptionResults";

export const TextAnswerResults = ({
  id,
  title,
  text_config: { id: answerId },
  responses = [],
}) => {
  return (
    <Box icon={<TextFieldsIcon />} title={title}>
      <Grid container spacing={16}>
        <Grid item xs={3} justify={"center"}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              padding: 36,
              borderRight: "1px solid #f0f0f0",
            }}
          >
            <Typography style={{ fontSize: 36, fontWeight: 800 }}>
              {formatNumber(responses[0].count)}
            </Typography>
            <Typography style={{ fontSize: 24 }}>Responses</Typography>
          </div>
        </Grid>
        <Grid item xs={9}>
          <OtherOptionResults
            questionId={id}
            id={answerId}
            buttonText={"Click to Load Responses"}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
