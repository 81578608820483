import React, { useState, useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useAuth } from "../../../../contexts/AuthContext";
import { getCohorts } from "./actions";
import get from "lodash/get";
import Snackbar from "../../../ReusableComponents/Snackbar";
import Loading from "../../../ReusableComponents/Loading";
import { Button, Grid } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CohortCard from "./components/CohortCard";
import Placeholder from "../../../ReusableComponents/Placeholder";
import UploadCohortDialog from "../../../ReusableComponents/UploadCohortDialog";

export default function Cohorts({ app }) {
  const { path } = useRouteMatch();
  const auth = useAuth();
  const { app_id: appId } = app;
  const [query, setQuery] = useState("");
  const [cursor, setCursor] = useState(20);
  const [openDialog, setOpenDialog] = useState(false);
  const [fetchCohorts, setFetchCohorts] = useState(false);
  const [cohorts, setCohorts] = useState([]);
  const [filteredExternalCohorts, setFilteredExternalCohorts] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [cohortsFailed, setCohortsFailed] = useState(false);

  /**
   * Fetch cohorts whenever the `fetchCohorts` state gets changed
   */
  useEffect(() => {
    setFetchCohorts(true);
    getCohorts(auth, appId)
      .then((response) => {
        setCohorts(response);
        const externalCohorts = response
          .filter(
            (eachCohort) =>
              get(eachCohort, "meta.created_from", null) === "ExternalFile"
          )
          .reverse();
        setFilteredExternalCohorts(externalCohorts);
        setFetchCohorts(false);
      })
      .catch((err) => {
        setErrorMessage("Failed to fetch segments. Try again after sometime");
        setCohortsFailed(true);
        setFetchCohorts(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialog]);

  /**
   * Filter the data based on the selected tab whenver the query is changed
   */
  useEffect(() => {
    setFilteredExternalCohorts(
      cohorts
        .filter((cohort) =>
          cohort.name.toLowerCase().includes(query.toLowerCase())
        )
        .reverse()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <section className="content">
          <>
            <Grid container justify="center" spacing={1}>
              <Grid item xs={12} md={8}>
                <Grid container justify="space-between" spacing={1}>
                  <Grid item xs={12} sm={3} style={{ marginBottom: 12 }}>
                    <Button
                      size={"small"}
                      variant={"contained"}
                      color={"primary"}
                      onClick={() => {
                        //openDialogue ~ close(false)
                        setOpenDialog(true);
                      }}
                    >
                      <CloudUploadIcon style={{ marginRight: 8 }} />
                      Upload
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={3} style={{ marginBottom: 12 }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <TextField
                        placeholder="Search Cohorts"
                        id="query-adornment"
                        fullWidth
                        value={query}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="end"
                              style={{ padding: "20px 0" }}
                            >
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => setQuery(e.target.value)}
                        style={{ marginRight: 8 }}
                      />
                    </div>
                  </Grid>
                </Grid>

                {filteredExternalCohorts.length === 0 && (
                  <Placeholder text={"No External Cohorts Available"} />
                )}

                {/**
                 * FIXME: Poor design as it does not contain more than
                 * 4 to 5 fileds. Can't we just show it in a simple table?
                 */}
                {filteredExternalCohorts.length > 0 &&
                  filteredExternalCohorts.map(
                    (cohort, index) =>
                      index < cursor && (
                        <CohortCard
                          index={index + 1}
                          key={cohort.cohort_id || "o=o"}
                          cohort={cohort}
                        />
                      )
                  )}

                {filteredExternalCohorts.length > 20 && (
                  <Button
                    disabled={cursor >= filteredExternalCohorts.length}
                    size="small"
                    style={{ margin: "10px auto", display: "block" }}
                    color="primary"
                    onClick={() => {
                      setCursor(cursor + 20);
                    }}
                  >
                    {cursor >= filteredExternalCohorts.length
                      ? "You are all caught up!!"
                      : "Load more..."}
                  </Button>
                )}

                {openDialog && (
                  <UploadCohortDialog
                    appId={appId}
                    onClose={() => setOpenDialog(false)}
                  />
                )}
              </Grid>
            </Grid>
            {fetchCohorts && <Loading size={24} />}
            {!fetchCohorts && cohortsFailed && errorMessage && (
              <Snackbar>
                <strong>{errorMessage}</strong>
              </Snackbar>
            )}
          </>
        </section>
      </Route>
    </Switch>
  );
}


