import { makePostRequest } from "../../../../../../common/actions";

const PREVIEW_API = "ui-config";

export function publishDesignForPreviewAPI(auth, appId, queryParams, postBody) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    PREVIEW_API,
    auth,
    appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback,
    window.SSE_ROOT
  );
}
