import {
  Grid,
  InputAdornment,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTracked } from "../../../store";
import ActionsTextField from "../../../../../../../../ReusableComponents/ActionsTextField";
import CustomColorPicker from "../../../../../../../../ReusableComponents/CustomColorPicker";
import ModuleConfigSection from "../../../../../../../../ReusableComponents/ModuleConfigSection";
import {
  SET_COLOR,
  SET_DYNAMIC_TEXT_CONFIG,
  SET_FONT_FAMILY,
  SET_FONT_SIZE,
  SET_FONT_STYLE,
  SET_TEXT,
} from "../../../constants";
import { useTrackedState } from "../../../../../../../common/components/campaigncreator/store";
import DynamicText2Dialog from "./DynamicText";
import ExpansionModuleConfig from "../../../../../../../../ReusableComponents/ExpansionModuleConfig";
import MultiSelect from "../../../../../../../../ReusableComponents/MultiSelect";
import { moduleConfigStyles } from "../utils";
import { APP_PLATFORMS } from "../../../../../../../../../constants";

const FONT_FAMILY_ENUM = ["casual", "cursive", "monospace", "sans-serif"];
const IOS_FONT_FAMILY_ENUM = [
  "Helvetica Neue",
  "Arial",
  "San Francisco",
  "Menlo",
];

const FONT_SIZES_ENUM = [12, 14, 16, 18, 20, 22, 24].map((f) => ({
  label: f,
  value: f,
}));

const FONT_STYLES_ENUM = ["normal", "bold", "italic"].map((f) => ({
  label: f,
  value: f,
}));

function TextConfig({
  keyName = "text_config",
  actions = {
    SET_TEXT: SET_TEXT,
    SET_COLOR: SET_COLOR,
    SET_FONT_FAMILY: SET_FONT_FAMILY,
    SET_FONT_SIZE: SET_FONT_SIZE,
    SET_FONT_STYLE: SET_FONT_STYLE,
  },
  classes,
  config: {
    color,
    font_family,
    size,
    style,
    text,
    is_dyn,
    d_type,
    script = null,
  },
  title = null,
  isButton = false,
}) {
  const [state, dispatch] = useTracked();
  const { platform, currentStep } = state;

  const parentState = useTrackedState();
  const { user_attributes = [], session_attributes = [] } = parentState;

  const [showDynamicTextConfig, setShowDynamicTextDialog] = useState(false);
  const getFonts = () => {
    if (APP_PLATFORMS.ios === platform)
      return [...FONT_FAMILY_ENUM, ...IOS_FONT_FAMILY_ENUM].map((f) => ({
        label: f,
        value: f,
      }));
    else return FONT_FAMILY_ENUM.map((f) => ({ label: f, value: f }));
  };
  const children = (
    <>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <ModuleConfigSection
            title="Font Style"
            typographyClassName={classes.moduleConfigRoot}
          >
            <MultiSelect
              single
              clearable={false}
              options={getFonts()}
              value={font_family}
              handleChange={(value) => {
                dispatch({
                  type: actions.SET_FONT_FAMILY,
                  currentStep,
                  keyName,
                  value: value,
                });
              }}
            />
          </ModuleConfigSection>
        </Grid>
        <Grid item xs={4}>
          <ModuleConfigSection
            title="Font Size"
            typographyClassName={classes.moduleConfigRoot}
          >
            <MultiSelect
              single
              clearable={false}
              options={FONT_SIZES_ENUM}
              value={size}
              handleChange={(value) => {
                dispatch({
                  type: actions.SET_FONT_SIZE,
                  currentStep,
                  keyName,
                  value: value,
                });
              }}
            />
          </ModuleConfigSection>
        </Grid>
        <Grid item xs={4}>
          <ModuleConfigSection
            title="Font Weight"
            typographyClassName={classes.moduleConfigRoot}
          >
            <MultiSelect
              single
              clearable={false}
              options={FONT_STYLES_ENUM}
              value={style}
              handleChange={(value) => {
                dispatch({
                  type: actions.SET_FONT_STYLE,
                  currentStep,
                  keyName,
                  value: value,
                });
              }}
            />
          </ModuleConfigSection>
        </Grid>
        <Grid item xs={4}>
          <ModuleConfigSection
            title="Color"
            typographyClassName={classes.moduleConfigRoot}
          >
            <CustomColorPicker
              color={color}
              handleColorChange={(selectedColor) => {
                dispatch({
                  type: actions.SET_COLOR,
                  currentStep,
                  keyName,
                  value: selectedColor.hex,
                });
              }}
            />
          </ModuleConfigSection>
        </Grid>
        <Grid item xs={8}>
          {keyName !== "counter" && (
            <ModuleConfigSection
              title="Text"
              typographyClassName={classes.moduleConfigRoot}
            >
              <ActionsTextField
                placeholder="Text"
                disabled={is_dyn && d_type === "s"}
                value={is_dyn && d_type === "s" ? "Script" : text}
                style={{ margin: 0 }}
                required
                multiline={false}
                withPropertySelector={!title && !isButton}
                inputAdornments={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Dynamic text">
                        <Typography
                          variant="h6"
                          onClick={() => setShowDynamicTextDialog(true)}
                          style={{
                            borderLeft: "1px solid #abc",
                            cursor: "pointer",
                            padding: 6,
                          }}
                        >
                          + dynamic text
                        </Typography>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                userProperties={user_attributes}
                sessionProperties={session_attributes}
                onChange={(event) => {
                  dispatch({
                    type: actions.SET_TEXT,
                    currentStep,
                    keyName,
                    value: event.target.value,
                  });
                }}
              />
            </ModuleConfigSection>
          )}
        </Grid>
      </Grid>
      {showDynamicTextConfig && (
        <DynamicText2Dialog
          openFlag={showDynamicTextConfig}
          onFail={() => setShowDynamicTextDialog(false)}
          onSuccess={(tab, type, commands, originalScript, variableMap) => {
            dispatch({
              type: SET_DYNAMIC_TEXT_CONFIG,
              currentStep,
              d_type: type,
              data: {
                tab,
                commands,
                originalScript,
                variableMap,
              },
              keyName,
              value: type === "s" ? "Script" : "Text",
            });
            setShowDynamicTextDialog(false);
          }}
          dynamicType={d_type ?? ""}
          originalScript={script?.src ?? ""}
          variableMap={script?.vmap ?? {}}
          tab={script?.t ?? 0}
        />
      )}
    </>
  );

  if (!title) {
    return <>{children}</>;
  }

  return (
    <ExpansionModuleConfig
      title={title}
      defaultExpanded={true}
      iconStyles={{ color: "#FF7F33" }}
      rootStyles={{ border: 0 }}
    >
      {children}
    </ExpansionModuleConfig>
  );
}

export default withStyles(moduleConfigStyles)(TextConfig);
