import { useTracked } from "../store";
import {
  AUDIENCE_TYPES,
  RECUR_FREQUENCY_ENUM,
  SCHEDULING_ENUM,
  SET_AUDIENCE_TYPE,
  SET_END_TIME,
  SET_ROLL_INTERVAL,
  SET_ROLL_RECURR,
  SET_ROLL_SEGMENT,
  SET_ROLL_STEP,
  SET_SCHEDULING_END,
  SET_SCHEDULING_START,
  SET_SCHEDULING_TYPE,
  SET_SELECTED_COHORT,
  SET_SELECTED_SEGMENT,
  SET_START_TIME,
  SET_TOGGLE_RECURR,
  NOTIFICATION_ENUM,
  SET_IS_VALID_PUSH,
} from "../constants";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import PushSimulator, { Simple, BigImage, BigText } from "./PushSimulator";
import OptionSelect from "../../../common/components/campaigncreator/components/OptionSelect";
import MultiSelect from "../../../../ReusableComponents/MultiSelect";
import DateRangePickerWrapper from "../../../../ReusableComponents/DateRangePicker";
import moment from "moment";
import ActionsTextField from "../../../../ReusableComponents/ActionsTextField";
import Switch from "../../../../ReusableComponents/Switch";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import FieldSection from "../../../common/components/campaigncreator/components/FieldSection";

import FieldDescription from "../../../common/components/campaigncreator/components/FieldDescription";
import React, { useEffect } from "react";

const getAudienceIndex = (audienceType) => {
  return AUDIENCE_TYPES.map((x) => x.key).indexOf(audienceType);
};

export function AudienceSelector({ errors }) {
  const [state, dispatch] = useTracked();
  const { push_config, app_segments, app_cohorts } = state;
  const { audience, scheduling, payload } = push_config;
  const {
    schedule,
    schedule_end_datetime,
    schedule_start_datetime,
    isRecurEnabled,
    rollSegment,
    step,
    rollInterval,
    recur,
  } = scheduling;
  const {
    audience_type,
    segment_id,
    cohort_id,
    start_time,
    end_time,
  } = audience;
  const { notification_type, is_silent } = payload;

  useEffect(() => {
    dispatch({
      type: SET_IS_VALID_PUSH,
      value: Object.keys(errors).length === 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <Grid container spacing={16}>
      <Grid item xs={8}>
        <Typography>Target Audience</Typography>
        <OptionSelect
          style={{ paddingTop: 12, paddingBottom: 12, marginBottom: 10 }}
          direction={"horizontal"}
          numPerRow={2}
          value={getAudienceIndex(audience_type)}
          radioOptions={{
            hasCheckboxOrRadio: true,
            radioFirst: true,
          }}
          handler={(selectedIndex) => {
            dispatch({
              type: SET_AUDIENCE_TYPE,
              value: AUDIENCE_TYPES[selectedIndex].key,
            });
          }}
          options={AUDIENCE_TYPES}
          cardStyles={{ paddingLeft: 6, minWidth: 128, borderRadius: 3 }}
          fieldTitleStyles={{ marginBottom: 0, marginLeft: 6 }}
        />
        {audience_type === AUDIENCE_TYPES[1].key && (
          <Grid container justify={"space-between"}>
            <Grid item xs style={{ marginTop: 8, marginBottom: 8 }}>
              <MultiSelect
                options={app_segments.map((segment) => ({
                  label: segment.name,
                  value: segment._id,
                }))}
                placeholder={"Select Segment"}
                label={"Select Segment"}
                single
                style={{ maxWidth: 440, margin: "5px 0" }}
                value={segment_id}
                handleChange={(segmentId) => {
                  dispatch({
                    type: SET_SELECTED_SEGMENT,
                    value: segmentId,
                  });
                }}
              />
              {!rollSegment && (
                <Typography component="span" type="subheading">
                  Who visited between &nbsp;
                  <div style={{ display: "inline-block" }}>
                    <DateRangePickerWrapper
                      single
                      small
                      disableAfter
                      anchorDirection="left"
                      date={moment(start_time)}
                      handleChange={(start_time) =>
                        dispatch({
                          type: SET_START_TIME,
                          value:
                            moment(start_time)
                              .utc()
                              .startOf("day")
                              .toISOString()
                              .slice(0, 23) + "Z",
                        })
                      }
                      disableFuture={false}
                    />
                  </div>
                  &nbsp;&nbsp; and &nbsp;&nbsp;
                  <div style={{ display: "inline-block" }}>
                    <DateRangePickerWrapper
                      single
                      small
                      disableAfter
                      date={moment(end_time).utc()}
                      handleChange={(end_time) =>
                        dispatch({
                          type: SET_END_TIME,
                          value:
                            moment(end_time)
                              .utc()
                              .endOf("day")
                              .toISOString()
                              .slice(0, 23) + "Z",
                        })
                      }
                      disableFuture={false}
                    />
                  </div>
                </Typography>
              )}
              {rollSegment && (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 16,
                    }}
                  >
                    <Typography color="textSecondary">
                      in the previous
                    </Typography>
                    <ActionsTextField
                      value={rollInterval}
                      style={{
                        maxWidth: 72,
                        marginBottom: 0,
                        margin: "0 6px",
                      }}
                      InputProps={{ min: 1 }}
                      onChange={(e) => {
                        dispatch({
                          type: SET_ROLL_INTERVAL,
                          value: Number(e.target.value),
                        });
                      }}
                      type={"number"}
                    />
                    <Typography color="textSecondary">
                      {rollInterval > 1 ? "days" : "day"}
                    </Typography>
                  </div>
                </>
              )}
            </Grid>
          </Grid>
        )}
        {audience_type === AUDIENCE_TYPES[2].key && (
          <Grid container justify={"space-between"}>
            <Grid item xs style={{ marginTop: 8, marginBottom: 8 }}>
              <MultiSelect
                options={app_cohorts.map((cohort) => ({
                  label: cohort.name,
                  value: cohort.cohort_id,
                }))}
                placeholder={"Select Cohort"}
                label={"Select Cohort"}
                single
                style={{ maxWidth: 440, margin: "5px 0" }}
                value={cohort_id}
                handleChange={(cohortId) => {
                  dispatch({
                    type: SET_SELECTED_COHORT,
                    value: cohortId,
                  });
                }}
              />
            </Grid>
          </Grid>
        )}
        <Typography>Campaign Duration</Typography>
        <Switch
          handleChange={(value) => {
            dispatch({ type: SET_SCHEDULING_TYPE, value: value });
          }}
          data={Object.values(SCHEDULING_ENUM)}
          value={schedule}
          containerStyles={{ minWidth: 120 }}
          groupStyles={{ minWidth: 10 }}
        />
        <Grid container spacing={8}>
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: "inline-block" }}>
              {schedule === SCHEDULING_ENUM.LATER && (
                <>
                  <Typography variant="h5">
                    Start Date{" "}
                    <span style={{ fontSize: 12 }}>(in Local Time)</span>
                  </Typography>
                  <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                    <DateTimePicker
                      value={schedule_start_datetime}
                      disablePast
                      ampm={false}
                      format="LLL"
                      onChange={(date) => {
                        const reqDate = moment(date);
                        dispatch({
                          type: SET_SCHEDULING_START,
                          date_time: reqDate.toISOString(),
                          date: reqDate.format("YYYY-MM-DD"),
                          time: reqDate.format("HH:mm"),
                        });
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  {errors?.scheduling?.schedule_start_datetime && (
                    <Typography
                      variant={"body2"}
                      style={{ padding: "2px 4px", color: "red" }}
                    >
                      {errors.scheduling.schedule_start_datetime}{" "}
                    </Typography>
                  )}
                </>
              )}
            </div>
            <div style={{ display: "inline-block", marginLeft: "10px" }}>
              {isRecurEnabled && (
                <>
                  <Typography variant="h5">
                    End Date{" "}
                    <span style={{ fontSize: 12 }}>(in Local Time)</span>
                  </Typography>
                  <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                    <DateTimePicker
                      value={schedule_end_datetime}
                      disablePast
                      ampm={false}
                      format="LLL"
                      onChange={(date) => {
                        dispatch({
                          type: SET_SCHEDULING_END,
                          date_time: moment(date).toISOString(),
                        });
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  {errors?.scheduling?.schedule_end_datetime && (
                    <Typography
                      variant={"body2"}
                      style={{ padding: "2px 4px", color: "red" }}
                    >
                      {errors.scheduling.schedule_end_datetime}{" "}
                    </Typography>
                  )}
                </>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={16}>
          <Grid item>
            <FieldSection styles={{ borderBottom: 0 }}>
              <FieldDescription title={"Campaign Recurring"} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <FormControlLabel
                  label={"Recur Campaign"}
                  control={
                    <Checkbox
                      color={"primary"}
                      checked={isRecurEnabled}
                      onChange={(e) => {
                        dispatch({
                          type: SET_TOGGLE_RECURR,
                          value: e.target.checked,
                        });
                      }}
                    />
                  }
                />
                {isRecurEnabled && (
                  <>
                    <Typography>every</Typography>
                    <ActionsTextField
                      value={step}
                      style={{
                        maxWidth: 72,
                        marginBottom: 0,
                        margin: "0 6px",
                      }}
                      InputProps={{ min: 0 }}
                      onChange={(e) => {
                        dispatch({
                          type: SET_ROLL_STEP,
                          value: Number(e.target.value),
                        });
                      }}
                      type={"number"}
                    />
                    <MultiSelect
                      options={RECUR_FREQUENCY_ENUM.map((each) => ({
                        label: each.label,
                        value: each.key,
                      }))}
                      placeholder={"Select Recur Type"}
                      value={recur}
                      style={{ margin: "5px 0" }}
                      single
                      handleChange={(event) => {
                        dispatch({
                          type: SET_ROLL_RECURR,
                          value: event,
                        });
                      }}
                    />
                    <Typography> during the campaign period</Typography>
                  </>
                )}
              </div>
              {segment_id !== "" &&
                audience_type === "SEGMENT" &&
                isRecurEnabled && (
                  <FormControlLabel
                    label={"Roll Segment"}
                    control={
                      <Checkbox
                        color={"primary"}
                        checked={rollSegment}
                        onChange={(e) => {
                          dispatch({
                            type: SET_ROLL_SEGMENT,
                            value: e.target.checked,
                          });
                        }}
                        disableRipple
                      />
                    }
                  />
                )}
            </FieldSection>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        {!is_silent && (
          <PushSimulator>
            {notification_type === NOTIFICATION_ENUM.simple && <Simple />}
            {notification_type === NOTIFICATION_ENUM.big_text && <BigText />}
            {notification_type === NOTIFICATION_ENUM.big_image && <BigImage />}
          </PushSimulator>
        )}
      </Grid>
    </Grid>
  );
}
