import { produce } from "immer";

export const ACTION_ADD_EVENT = "ADD_EVENT";
export const ACTION_REMOVE_EVENT = "REMOVE_EVENT";
export const ACTION_SET_EVENT_NAME = "SET_EVENT_NAME";
export const ACTION_SET_EVENT_COUNT_VALUE = "SET_EVENT_COUNT_VALUE";
export const ACTION_SET_EVENT_COUNT_OPERATOR = "SET_EVENT_COUNT_OPERATOR";
export const ACTION_ADD_EVENT_ATTRIBUTE = "ADD_EVENT_ATTRIBUTE";
export const ACTION_REMOVE_ATTRIBUTE = "REMOVE_EVENT_ATTRIBUTE";
export const ACTION_SET_ATTRIBUTE_NAME = "SET_ATTRIBUTE_NAME";
export const ACTION_SET_ATTRIBUTE_VALUE = "SET_ATTRIBUTE_VALUE";
export const ACTION_SET_ATTRIBUTE_OPERATOR = "SET_ATTRIBUTE_OPERATOR";
export const ACTION_SET_QUERY_PARAMS = "SET_QUERY_PARAMS";
export const ACTION_REMOVE_ATTRIBUTES = "ACTION_REMOVE_ATTRIBUTES";
export const SET_CONDITION = "SET_CONDITION";
export const ACTION_SET_INITIAL_EVENTS = "SET_INITIAL_EVENTS";

// Default State maintained by Event Builder
export const initialState = {
  condition: "AND",
  events: [],
  filters: {
    since: "",
    till: "",
    of: "users",
  },
  appId: "",
};

// Default Event Format
const initialEventState = {
  name: "",
  attributes: [],
  count: {
    value: 1,
    operator: "GTE",
  },
};

// Default Attribute Format
const initialAttributeState = {
  name: "",
  value: [],
  operator: "EQ",
};

let nextEventId = 1;
let nextAttrId = 1000;

const getEventIndex = (draft, id) => {
  return draft.events.findIndex((event) => event.id === id);
};

const getAttribute = (draft, eventId, attributeId) => {
  const index = draft.events.findIndex((event) => event.id === eventId);
  if (index >= 0) {
    let attrIndex = draft.events[index].attributes.findIndex(
      (attr) => attr.id === attributeId
    );
    return { eventIndex: index, attrIndex: attrIndex };
  } else {
    return { eventIndex: -1, attrIndex: -1 };
  }
};

export const reducer = produce((draft, action) => {
  let eventId;
  let index;
  let data;
  switch (action.type) {
    case ACTION_SET_INITIAL_EVENTS:
      draft.events = action.events;
      break;
    case ACTION_ADD_EVENT:
      const newEvent = {
        id: nextEventId++,
        ...initialEventState,
        count: action.countConfig,
      };
      if (action.index && action.index === -1) {
        draft.events.push(newEvent);
      } else {
        draft.events.splice(action.index, 0, newEvent);
      }
      break;
    case ACTION_REMOVE_EVENT:
      eventId = action.id;
      index = getEventIndex(draft, eventId);
      if (index >= 0) {
        draft.events.splice(index, 1);
      }
      break;
    case ACTION_SET_EVENT_NAME:
      eventId = action.id;
      index = getEventIndex(draft, eventId);
      if (index >= 0) {
        draft.events[index].name = action.name;
      }
      break;
    case ACTION_SET_EVENT_COUNT_VALUE:
      eventId = action.id;
      index = getEventIndex(draft, eventId);
      if (index >= 0) {
        draft.events[index].count.value = action.value;
      }
      break;
    case ACTION_SET_EVENT_COUNT_OPERATOR:
      eventId = action.id;
      index = getEventIndex(draft, eventId);
      if (index >= 0) {
        draft.events[index].count.operator = action.operator;
      }
      break;
    case ACTION_ADD_EVENT_ATTRIBUTE:
      eventId = action.id;
      index = getEventIndex(draft, eventId);
      if (index >= 0) {
        draft.events[index].attributes.push({
          id: nextAttrId++,
          ...initialAttributeState,
        });
      }
      break;
    case ACTION_REMOVE_ATTRIBUTE:
      data = getAttribute(draft, action.eventId, action.attributeId);
      if (data.eventIndex >= 0 && data.attrIndex >= 0) {
        draft.events[data.eventIndex].attributes.splice(data.attrIndex, 1);
      }
      break;
    case ACTION_SET_ATTRIBUTE_NAME:
      data = getAttribute(draft, action.eventId, action.attributeId);
      if (data.eventIndex >= 0 && data.attrIndex >= 0) {
        draft.events[data.eventIndex].attributes[data.attrIndex].name =
          action.name;
      }
      break;
    case ACTION_SET_ATTRIBUTE_OPERATOR:
      data = getAttribute(draft, action.eventId, action.attributeId);
      if (data.eventIndex >= 0 && data.attrIndex >= 0) {
        draft.events[data.eventIndex].attributes[data.attrIndex].operator =
          action.operator;
      }
      break;
    case ACTION_SET_ATTRIBUTE_VALUE:
      data = getAttribute(draft, action.eventId, action.attributeId);
      if (data.eventIndex >= 0 && data.attrIndex >= 0) {
        draft.events[data.eventIndex].attributes[data.attrIndex].value =
          action.value;
      }
      break;
    case ACTION_SET_QUERY_PARAMS:
      const { appId, since, till } = action;
      draft.appId = appId;
      draft.filters.since = since;
      draft.filters.till = till;
      break;
    case ACTION_REMOVE_ATTRIBUTES:
      eventId = action.id;
      index = getEventIndex(draft, eventId);
      if (index >= 0) {
        draft.events[index].attributes = [];
      }
      break;
    case SET_CONDITION:
      draft.condition = action.value;
      break;
    default:
      return draft;
  }
});
