import React, { Component, PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import MobileSimulator from "../../../../../ReusableComponents/MobileSimulator";
import { Grid, Typography, Tooltip, Button } from "@material-ui/core";
import EventIcon from "@material-ui/icons/Event";
import { roundOffNumber } from "../../../../../../utils";
import Box from "../../../../../ReusableComponents/Box";
import MobileFriendly from "@material-ui/icons/MobileFriendly";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import LinearProgress from "@material-ui/core/LinearProgress";

const styles = {
  event: {
    position: "absolute",
    background: "transparent",
    zIndex: 10,
  },
  ripple: {
    borderRadius: "50%",
    background: "#F1F1F1",
    border: `1px solid #F1F1F1`,
  },
};

const classedStyles = (theme) => ({
  "@keyframes event": {
    "100%": {
      marginLeft: -120,
      marginTop: -120,
      borderWidth: 120,
      opacity: 0,
    },
  },
  ripple: {
    width: 0,
    height: 0,
    position: "absolute",
    webkitAnimation: "event 1s infinite",
    animation: "event 1s infinite",
    webkitAnimationDelay: "1s",
    animationDelay: "1s",
    marginTop: 18, //Half og Button Height
  },
});

const renderValue = (value) => (
  <span style={{ float: "right" }}>{value || "NA"}</span>
);

class SessionSimulator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentScreen: {},
      currentEvent: {},
      currentIndex: 0,
      timeUp: false,
      complete: 0,
      buffer: 0,
    };
    this.times = [];
    this.positions = {};
    this.endTime = 0;
  }

  renderNextScreen = (index, data) => {
    this.setState({
      currentScreen: data,
      currentIndex: index,
    });
  };

  renderNextEvent = (index, data) => {
    this.setState({
      currentEvent: data,
      currentIndex: index,
    });
  };

  simulate = (data = []) => {
    if (data.length > 0) {
      const lastItem = data[data.length - 1];
      this.endTime = lastItem.time + lastItem.duration;
    } else {
      this.endTime = 0;
    }
    data.forEach((d, i) => {
      this.times[i] = setTimeout(() => {
        if (d.item_type === "AppEventItem") {
          if (!this.positions.hasOwnProperty(d.name)) {
            this.positions[d.name] = { top: (Math.random() * 400) % 462 };
          }
          this.renderNextEvent(i, data[i]);
        } else {
          this.renderNextScreen(i, data[i]);
        }
        var buffer = 0;
        if (this.endTime > 0 && i < data.length - 1) {
          const nextItem = data[i + 1];
          buffer = ((nextItem.time - data[i].time) / this.endTime) * 100;
        }
        this.setState({
          complete: (data[i].time / this.endTime) * 100,
          buffer,
        });
      }, d.time * 1000);
    });
    this.times.push(
      setTimeout(() => {
        this.setState({ timeUp: true, complete: 100, buffer: 0 });
      }, this.props.duration * 1000)
    );
  };

  getPosition = (eventName) => {
    return this.positions[eventName] || { top: 200 };
  };

  componentDidMount() {
    this.simulate(this.props.data);
  }

  componentWillUnmount() {
    if (Array.isArray(this.times)) {
      this.times.forEach((time) => {
        clearInterval(time);
      });
    }
  }

  render() {
    const {
      currentScreen = {},
      currentEvent = {},
      timeUp = false,
      complete,
      buffer,
    } = this.state;
    const { classes } = this.props;
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <LinearProgress
            color="secondary"
            variant="buffer"
            value={complete}
            valueBuffer={buffer}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MobileSimulator>
            {timeUp && <SessionEnds />}
            {!timeUp && currentEvent && (
              <Event
                {...currentEvent}
                classes={classes}
                position={this.getPosition(currentEvent.name)}
              />
            )}
            {!timeUp && currentScreen && (
              <Screen {...currentScreen} classes={classes} />
            )}
          </MobileSimulator>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            title={currentScreen.name || "Unknown"}
            icon={<MobileFriendly />}
            controls={
              <Typography variant="caption">
                {roundOffNumber(currentScreen.time)}
                <sup>th</sup> Sec
              </Typography>
            }
            elevation={0}
          >
            <Typography variant="caption">
              Spent {roundOffNumber(currentScreen.duration)} Secs
            </Typography>
            <br />
            {currentEvent && (
              <Box
                title={currentEvent.name || ""}
                withPadding
                icon={<EventIcon />}
                controls={
                  <Typography variant="caption">
                    {roundOffNumber(currentEvent.time)}
                    <sup>th</sup> Sec
                  </Typography>
                }
                elevation={0}
              >
                <List>
                  {Object.keys(currentEvent.additional_info || {}).map(
                    (d, i) => (
                      <ListItem>
                        <ListItemText
                          key={"info" + i}
                          primary={
                            <span>
                              <strong>{d}</strong>{" "}
                              {renderValue(currentEvent.additional_info[d])}
                            </span>
                          }
                        />
                      </ListItem>
                    )
                  )}
                </List>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    );
  }
}

class Screen extends PureComponent {
  render() {
    const { name, time } = this.props;
    return (
      <div style={styles.screen}>
        <Tooltip id={name + time} title={name} placement="top">
          <Typography variant="caption" noWrap style={{ marginTop: 440 }}>
            {name}
          </Typography>
        </Tooltip>
      </div>
    );
  }
}

class Event extends PureComponent {
  render() {
    const { classes, name, time, position } = this.props;
    return (
      <div style={{ ...styles.event, ...position }}>
        <div style={{ margin: "0 50%" }}>
          <span style={styles.ripple} className={classes.ripple} />
        </div>
        <Tooltip
          id={name + time}
          title={`${name} (${roundOffNumber(time)} Sec)`}
          placement="top"
        >
          <Button size="small" aria-label={name} variant="flat" color="primary">
            {name} <EventIcon />
          </Button>
        </Tooltip>
      </div>
    );
  }
}

class SessionEnds extends PureComponent {
  render() {
    return (
      <div style={{ marginTop: "40%" }}>
        <Typography
          style={{ fontSize: 48, textAlign: "center" }}
          variant="caption"
        >
          Session Ends
        </Typography>
      </div>
    );
  }
}

export default withStyles(classedStyles, { withTheme: true })(SessionSimulator);
