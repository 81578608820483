import {
  makePostRequest,
  makePutRequest,
  makeGetRequest,
} from "../../../../common/actions";

const ART_CONFIGS = "art-configs";
const ART_CONFIG = "art-config";
const TAGS = "tags";

export function saveCampaignAPI(auth, appId, queryParams, postBody) {
  const onSuccessCallback = (response) => {
    return response;
  };

  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    ART_CONFIGS,
    auth,
    appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback
  );
}

export function updateCampaignAPI(
  auth,
  appId,
  queryParams,
  postBody,
  campaignId
) {
  const onSuccessCallback = (response) => {
    return response;
  };

  const onFailureCallback = (response) => {
    return {};
  };
  return makePutRequest(
    `${ART_CONFIGS}/${campaignId}`,
    auth,
    appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback
  );
}

export function sendTestConfigToSDK(auth, appId, queryParams = {}, postBody) {
  const onSuccessCallback = (response) => {
    return response;
  };

  const onFailureCallback = (response) => {
    return {};
  };

  return makePostRequest(
    ART_CONFIG,
    auth,
    appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback,
    window.SSE_ROOT
  );
}

export function getTagsAPI(auth, appId, configType) {
  const onSuccessCallback = (json) => {
    return Array.isArray(json) ? json : [];
  };
  const onFailureCallback = (response) => {
    return [];
  };
  return makeGetRequest(
    TAGS,
    auth,
    appId,
    { configType },
    onSuccessCallback,
    onFailureCallback
  );
}
