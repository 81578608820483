import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import LabelList from "recharts/lib/component/LabelList";
import { useAuth } from "../../../../../../../../contexts/AuthContext";
import {
  formatNumber,
  randomColorWithIndex,
  roundOffNumber,
} from "../../../../../../../../utils";
import CustomMaterialUIDialog from "../../../../../../../ReusableComponents/CustomMaterialUIDialog";
import Loading from "../../../../../../../ReusableComponents/Loading";
import MultiSelect from "../../../../../../../ReusableComponents/MultiSelect";
import MultiUtilityChart from "../../../../../../../ReusableComponents/MultiUtilityChart";
import RowDiv from "../../../../../../../ReusableComponents/RowDiv";
import Snackbar from "../../../../../../../ReusableComponents/Snackbar";
import { useCommonConfigStyles } from "../../../../../../common/components/campaigncreator/utils";
import DesignPreviewSection from "../../../design/components/DesignPreviewSection";
import {
  getGoalConversionTimeSeriesAPI,
  getGoalConversionAPI,
} from "../../actions";
import { SET_VARIANT } from "../../constants";
import { useTracked } from "../../store";
import MultiUtilityChartAnalysisTooltip from "./MultiUtilityChartAnalysisTooltip";
import GoalEventConversionTooltip from "./GoalEventConversionTooltip";
import { ContinuousColorLegend } from "react-vis";
import { isEmptyChildren } from "formik";

export default function GoalEventAnalysis({
  campaignConfig,
  showTestResults,
  setSelectedVariant,
  winnerVariant,
  isExperiment,
}) {
  const auth = useAuth();
  const classes = useCommonConfigStyles();

  const [loadConversions, setLoadConversions] = useState(false);
  const [goalConversionMetrics, setGoalConversionMetrics] = useState([]);
  const [goalConversionError, setGoalConversionError] = useState(false);
  const [goalConversionBarData, setGoalCOnversionBarData] = useState([]);
  const [selectedVariants] = useState([]);

  const [
    loadGoalCOnversionTimeSeries,
    setLoadGoalCOnversionTimeSeries,
  ] = useState(false);
  const [goalConversionTimeSeries, setGoalConversionTimeSeries] = useState([]);
  const [
    goalConversionTimeSeriesError,
    setGoalConversionTimeSeriesError,
  ] = useState(false);

  const [showViewStepsDialog, setShowViewStepsDialog] = useState(false);

  const [state, dispatch] = useTracked();
  const {
    variant = winnerVariant || "A",
    funnelTime = 300,
    refresh,

    goalEvents,
  } = state;

  const { experiment: { variants = {} } = { variants: {} } } = campaignConfig;

  const getVariantNames = (data) => {
    return data.length > 0
      ? data[0].variants
          .map((each) => (each.variant_name ? each.variant_name : ""))
          .filter((item) => item)
      : [];
  };

  const getRemainingKeys = (variants) => {
    const data = variants.map((eachVariant) => {
      const percentageKey = eachVariant.variant_name + "_percentage";
      return {
        [eachVariant.variant_name]: eachVariant.percentage,
        [percentageKey]: eachVariant.conversion_count,
      };
    });
    return Object.assign({}, ...data);
  };

  const getGoalBarData = (data) => {
    return data.map((eachGoalData) => {
      return {
        name: eachGoalData?.goal_event?.name,
        ...eachGoalData.summary,
      };
    });
  };

  const getbarGraphs = () => {
    const variantsList =
      selectedVariants.length > 0
        ? selectedVariants
        : getVariantNames(goalConversionMetrics);

    return variantsList.map((each, index) => {
      return (
        <Bar dataKey={each} fill={randomColorWithIndex(index + 1)}>
          <LabelList dataKey={each + "_percentage"} position="insideTop" />
        </Bar>
      );
    });
  };

  const renderCustomizedLabel = (props) => {
    const { x, y, value } = props;
    return (
      <g>
        <text
          x={x + 40}
          y={y - 5}
          fill="#002845"
          textAnchor="middle"
          style={{
            fontSize: 13,
            fontWeight: 600,
          }}
        >
          <tspan>{roundOffNumber(Number(value), 1)} </tspan>
        </text>
      </g>
    );
  };

  const getLegendContent = ({ payload }) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        {payload.map((each, index) => {
          return (
            <RowDiv styles={{ float: "left" }}>
              <div
                style={{
                  height: 8,
                  width: 8,
                  borderRadius: 50,
                  backgroundColor: randomColorWithIndex(index + 1),
                  marginTop: 8,
                  marginLeft: 20,
                }}
              ></div>
              <Typography style={{ marginLeft: 5 }}>
                {each.dataKey.replace("Var-", "")}
              </Typography>
            </RowDiv>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    const data = goalConversionMetrics
      .filter(
        (eachGoalEventData) =>
          eachGoalEventData.goal_event &&
          eachGoalEventData.goal_event.name &&
          eachGoalEventData.variants
      )
      .map((eachGoalEventData) => {
        return {
          name: eachGoalEventData.goal_event.name,
          ...getRemainingKeys(eachGoalEventData.variants),
        };
      });
    setGoalCOnversionBarData(data);
  }, [goalConversionMetrics]);

  useEffect(() => {
    setLoadConversions(true);
    setGoalConversionError(false);
    const postBody = [
      {
        name: campaignConfig.goal_event ?? "InlineTargetViewClicked",
        attributes: [],
      },
      ...goalEvents.filter((eachEvent) => eachEvent.name),
    ];

    const params = {
      messageId: campaignConfig._id,
      time: funnelTime,
    };

    const queryParams = isExperiment
      ? { ...params, variantCode: variant || winnerVariant }
      : params;

    getGoalConversionAPI(isExperiment, auth, queryParams, postBody)
      .then((response) => {
        setGoalConversionMetrics(response);
        setLoadConversions(false);
      })
      .catch(() => {
        setGoalConversionError(true);
        setLoadConversions(false);
      });

    setLoadGoalCOnversionTimeSeries(true);
    setGoalConversionTimeSeriesError(false);
    getGoalConversionTimeSeriesAPI(isExperiment, auth, queryParams, postBody)
      .then((response) => {
        setGoalConversionTimeSeries(response);
        setLoadGoalCOnversionTimeSeries(false);
      })
      .catch(() => {
        setGoalConversionTimeSeriesError(true);
        setLoadGoalCOnversionTimeSeries(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variant, refresh]);

  const variantOptions = useMemo(() => {
    return Object.keys(variants).map((name) => ({
      label: (
        <div
          style={{
            background: "#00BDE1",
            padding: "4px 12px",
            color: "#FFFFFF",
            borderRadius: 2,
            fontSize: 16,
            minWidth: 41,
            marginTop: 4,
            marginBottom: 4,
          }}
        >
          {name.toUpperCase()}
        </div>
      ),
      value: name,
    }));
  }, [variants]);

  const VariantDropdown = (
    <MultiSelect
      single
      clearable={false}
      isSearchable={false}
      options={variantOptions}
      value={variant}
      handleChange={(value) => {
        dispatch({
          type: SET_VARIANT,
          value,
        });
      }}
      style={{
        maxWidth: 250,
        marginLeft: 8,
        display: "inline-flex",
        justifyContent: "flex-end",
      }}
    />
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Card
          style={{ minHeight: 340, height: 400, padding: 16, marginTop: 5 }}
        >
          <RowDiv
            center
            styles={{
              justifyContent: "space-between",
            }}
          >
            <RowDiv center styles={{ gap: 5 }}>
              <Typography
                className={classes.typographyHeading}
                style={{
                  fontSize: 20,
                  fontWeight: 600,
                  letterSpacing: "0.01em",
                }}
              >
                Goal Event - Conversion Rate
              </Typography>
            </RowDiv>
            {false && VariantDropdown}
            {/*TODO: Write a multiSelect here*/}
          </RowDiv>
          <CardContent>
            {loadConversions && (
              <section className="content">
                <Loading size={24} />
              </section>
            )}
            <ResponsiveContainer height={300}>
              {loadConversions || goalConversionBarData.length < 0 ? (
                loadConversions ? (
                  <div>
                    <Loading />
                  </div>
                ) : (
                  <Typography
                    style={{
                      fontWeight: "700",
                      fontSize: "14px",
                      fontFamily: "Manrope",
                      marginTop: 50,
                    }}
                  >
                    Select a goal event from 'filter' to view test winner
                  </Typography>
                )
              ) : isExperiment ? (
                <BarChart width={730} height={300} data={goalConversionBarData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis
                    width={90}
                    tickFormatter={(tick) => {
                      return `${tick}%`;
                    }}
                    label={{
                      // value: "Users",
                      position: "insideLeft",
                      angle: -90,
                      dy: 40,
                    }}
                  />
                  <Tooltip
                    content={
                      <GoalEventConversionTooltip isExperiment={isExperiment} />
                    }
                  />
                  <Legend
                    iconType="circle"
                    iconSize={10}
                    content={getLegendContent}
                  />
                  {getbarGraphs()}
                </BarChart>
              ) : (
                <BarChart
                  data={getGoalBarData(goalConversionMetrics)}
                  margin={{
                    top: 40,
                    right: 10,
                    left: 30,
                    bottom: 5,
                  }}
                  color="#519674"
                >
                  <pattern
                    id="pattern-stripe"
                    width="8"
                    height="8"
                    patternUnits="userSpaceOnUse"
                    patternTransform="rotate(45)"
                  >
                    <rect
                      width="4"
                      height="8"
                      transform="translate(0,0)"
                      fill="white"
                    />
                  </pattern>
                  <mask id="mask-stripe">
                    <rect
                      x="0"
                      y="0"
                      width="100%"
                      height="100%"
                      opacity="0.5"
                      fill="url(#pattern-stripe)"
                    />
                  </mask>
                  <Tooltip
                    content={
                      <GoalEventConversionTooltip isExperiment={isExperiment} />
                    }
                  />
                  <XAxis dataKey="name" />
                  <YAxis
                    width={90}
                    tickFormatter={(tick) => {
                      return `${tick}%`;
                    }}
                    label={{
                      // value: "Users",
                      position: "insideLeft",
                      angle: -90,
                      dy: 40,
                    }}
                  />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Bar type="monotone" dataKey="percentage" stackId="a">
                    {getGoalBarData(goalConversionMetrics).map((_, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={randomColorWithIndex(index)}
                      />
                    ))}
                    <LabelList
                      content={renderCustomizedLabel}
                      dataKey="conversion_count"
                      position="insideTop"
                      formatter={roundOffNumber}
                    />
                  </Bar>
                  <Bar
                    type="monotone"
                    dataKey="dropped"
                    stackId="a"
                    isAnimationActive={false}
                    shape={<></>}
                  />
                </BarChart>
              )}
            </ResponsiveContainer>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} style={{ overflow: "inherit" }}>
        <Card
          style={{ minHeight: 340, height: 400, padding: 16, marginTop: 5 }}
        >
          <RowDiv
            center
            styles={{
              justifyContent: "space-between",
            }}
          >
            <RowDiv center styles={{ gap: 5 }}>
              <Typography
                className={classes.typographyHeading}
                style={{
                  fontSize: 20,
                  fontWeight: 600,
                  letterSpacing: "0.01em",
                }}
              >
                Goal Event - Day wise analysis
              </Typography>
            </RowDiv>
            {isExperiment && VariantDropdown}
          </RowDiv>
          <CardContent>
            <ResponsiveContainer width={"100%"} height={300}>
              {loadGoalCOnversionTimeSeries ? (
                <div>
                  <Loading />
                </div>
              ) : (
                <MultiUtilityChart
                  style={{ marginTop: 6 }}
                  boxStyles={{ height: "100%" }}
                  data={goalConversionTimeSeries.map((item) => {
                    return {
                      key: item["date"],
                      ...item,
                    };
                  })}
                  isAnimationActive={false}
                  dataKey="key"
                  lineDataKeys={goalConversionTimeSeries
                    .reduce((acc, item) => {
                      const keys = Object.keys(item);
                      keys.forEach((key) => {
                        if (key.endsWith("_percent")) {
                          return;
                        }
                        if (acc.indexOf(key) === -1) {
                          acc.push(key);
                        }
                      });
                      return acc;
                    }, [])
                    .filter((name) => name !== "date")}
                  customTooltip={
                    <MultiUtilityChartAnalysisTooltip isGoalEvent={true} />
                  }
                  withoutAggregations
                />
              )}
            </ResponsiveContainer>
          </CardContent>
        </Card>
      </Grid>

      {(goalConversionError || goalConversionTimeSeriesError) && (
        <Snackbar autoHideDuration={5000}>
          Error! Data couldn't be Fetched! Please try again later.
        </Snackbar>
      )}
      <CustomMaterialUIDialog
        title="Campaign Steps"
        dialogContent={
          <RowDiv center styles={{ gap: 10, overflow: "scroll" }}>
            {Object.keys(variants).length > 0 &&
              variants[variant] &&
              variants[variant].indices.map((uiIndex, index) => (
                <DesignPreviewSection
                  key={index}
                  campaignId={campaignConfig._id}
                  currentStep={index}
                  previewMode={true}
                  ui={campaignConfig.uis[uiIndex]}
                  isSummary={true}
                />
              ))}
          </RowDiv>
        }
        dialogContentStyles={{
          overflowX: "hidden",
          padding: "8px 8px",
        }}
        maxWidth="md"
        openFlag={showViewStepsDialog}
        handleClose={() => setShowViewStepsDialog(false)}
        onClose={() => setShowViewStepsDialog(false)}
        onDialogClose={() => setShowViewStepsDialog(false)}
      />
    </Grid>
  );



}