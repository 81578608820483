import {
  Button,
  FormControlLabel,
  Input,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { capitalizeEachWord } from "../../../../../../../../utils";
import ColumnDiv from "../../../../../../../ReusableComponents/ColumnDiv";
import ModuleConfigSection from "../../../../../../../ReusableComponents/ModuleConfigSection";
import RowDiv from "../../../../../../../ReusableComponents/RowDiv";
import { useModuleConfigStyles } from "./utils";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { QRCodeSVG } from "qrcode.react";

const WEB_DEVICE_TYPES = Object.freeze({
  WEB: "web",
  MOBILE: "mobile",
});

export default function WebLaunchURL({
  defaultUrl = "",
  url,
  setURL,
  launchCallback: launchURLCallback,
  generateQRCodeCallback,
  title = "",
}) {
  const moduleConfigClasses = useModuleConfigStyles();

  useEffect(() => {
    if (url === "") {
      setURL(defaultUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultUrl, setURL]);

  const [deviceType, setDeviceType] = useState(WEB_DEVICE_TYPES.WEB);
  const [qrCodeURL, setQrCodeURL] = useState("");

  const generateQR = () => {
    const qrCodeURL = generateQRCodeCallback();
    setQrCodeURL(qrCodeURL);
  };

  return (
    <ColumnDiv styles={{ gap: "10px" }}>
      <RadioGroup
        name="device"
        value={deviceType}
        onChange={(e) => {
          setDeviceType(e.target.value);
        }}
      >
        <RowDiv center styles={{ gap: "30px", marginLeft: "-10px" }}>
          {Object.values(WEB_DEVICE_TYPES).map((deviceType) => (
            <FormControlLabel
              color="primary"
              value={deviceType}
              control={<Radio color="primary" />}
              label={
                <Typography
                  style={{
                    fontSize: "15px",
                    fontWeight: "600",
                  }}
                >
                  {capitalizeEachWord(deviceType)}
                </Typography>
              }
            />
          ))}
        </RowDiv>
      </RadioGroup>
      <RowDiv styles={{ gap: "8px", alignItems: "flex-end" }}>
        <ModuleConfigSection
          title={title}
          style={{
            marginTop: 0,
            alignItems: "center",
            gap: 2,
            minWidth: "270px",
          }}
          typographyClassName={moduleConfigClasses.moduleConfigRoot}
          typographyStyleProps={{ fontSize: 12 }}
        >
          <Input
            fullWidth
            style={{
              border: "1px solid #CCD4DA",
              borderRadius: "2px",
              fontSize: "15px",
              fontWeight: "600",
              padding: "2px 4px",
            }}
            autoFocus
            placeholder="https://your-url.com"
            value={url}
            onChange={(e) => setURL(e.target.value)}
          />
        </ModuleConfigSection>

        {((qrCodeURL === "" && deviceType === WEB_DEVICE_TYPES.MOBILE) ||
          deviceType === WEB_DEVICE_TYPES.WEB) && (
          <Button
            size="large"
            variant="contained"
            color="primary"
            disabled={!url || url === "" || (url.trim && url.trim === "")}
            onClick={() => {
              if (deviceType === WEB_DEVICE_TYPES.MOBILE) {
                generateQR();
              } else {
                launchURLCallback();
              }
            }}
            style={{
              padding: "10.5px 20px",
              borderRadius: "3px",
            }}
          >
            <Typography
              style={{
                color: "#fff",
                fontSize: "15px",
                fontWeight: "700",
              }}
            >
              {deviceType === WEB_DEVICE_TYPES.WEB
                ? "Launch URL"
                : "Generate QR"}
            </Typography>
          </Button>
        )}
      </RowDiv>
      {qrCodeURL !== "" && deviceType === WEB_DEVICE_TYPES.MOBILE && (
        <ColumnDiv styles={{ gap: "9px", maxWidth: "150px" }}>
          <QRCodeSVG value={qrCodeURL} height="150px" width="150px" />
          <Button
            variant="text"
            startIcon={<FileCopyOutlinedIcon />}
            color="default"
            onClick={() => {
              navigator.clipboard.writeText(qrCodeURL);
            }}
          >
            Copy Link
          </Button>
        </ColumnDiv>
      )}
    </ColumnDiv>
  );
}
