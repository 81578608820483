import React, { useMemo } from "react";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { formatNumber, isDefined } from "../../../../../../../utils";
import { purple, blue } from "@material-ui/core/colors";
import Group from "@material-ui/icons/Group";
import AccessTime from "@material-ui/icons/AccessTime";
import { withStyles } from "@material-ui/core/styles";
import { useEffect } from "react";
import { useState } from "react";
import {
  getEventCountAPI,
  getTimeSeriesAPI,
  getEventTimeSeriesAPI,
} from "../../../actions";
import { useAuth } from "../../../../../../../contexts/AuthContext";
import Box from "../../../../../../ReusableComponents/Box";
import MultiSelect from "../../../../../../ReusableComponents/MultiSelect";
import { Grid } from "@material-ui/core";
import MultiUtilityChart from "../../../../../../ReusableComponents/MultiUtilityChart";
import EventAttributeDistributions from "../../EventAttributeDistributions";
import EventIcon from "@material-ui/icons/Event";
import { ADD_EVENT, UPDATE_TIME_SERIES, RESET_TIME_SERIES } from "../constants";
import { useTracked } from "../store";
import { useDeepCompareEffect } from "../../../../../../../utils/use-deep-compare";
import { getEventsAPI } from "../../../../../../common/actions";
import { useDispatch } from "../../../store";
import { SELECTED_EVENTS } from "../../../reducer";

const getPercent = (total, value) => {
  if (isNaN(value) || isNaN(total) || total === 0) return "NA";
  return parseFloat((value * 100) / total).toFixed(2);
};

const metricRowStyles = (theme) => ({
  root: {
    WebkitJustifyContent: "space-around",
    justifyContent: "space-around",
    width: "100%",
    display: "flex",
    height: 50,
  },
  icon: {
    fill: blue,
  },
});

const EventMetricsRowInner = (props) => {
  const {
    name,
    event: count,
    sessions,
    users,
    stats = {},
    classes,
    children,
  } = props;
  if (children !== undefined) {
    return (
      <BottomNavigation showLabels className={classes.root}>
        {children}
      </BottomNavigation>
    );
  }
  const totalSessions = stats.sessions;
  const totalUsers = stats.users;
  return (
    <BottomNavigation showLabels className={classes.root}>
      <BottomNavigationAction
        label={
          <span title="Event Count">
            {count !== undefined && count !== null && formatNumber(count)}
            {(count === undefined || count === null) && "Loading"}
          </span>
        }
        style={{ minWidth: 250 }}
        icon={<span style={{ color: purple }}>{name}</span>}
      />
      <BottomNavigationAction
        label={
          <span>
            <span title="Users">
              {users !== undefined && users !== null && formatNumber(users)}
              {(users === undefined || users === null) && "Loading"}
            </span>{" "}
            <span title={`of ${totalUsers} total users`}>
              {users !== undefined &&
                users !== null &&
                `(${getPercent(totalUsers, users)} %)`}
            </span>
          </span>
        }
        icon={<Group className={classes.icon} />}
      />
      <BottomNavigationAction
        label={
          <span>
            <span title="Sessions">
              {sessions !== undefined &&
                sessions !== null &&
                formatNumber(sessions)}
              {(sessions === undefined || sessions === null) && "Loading"}
            </span>{" "}
            <span title={`of ${totalSessions} total sessions`}>
              {sessions !== undefined &&
                sessions !== null &&
                `(${getPercent(totalSessions, sessions)} %)`}
            </span>
          </span>
        }
        icon={<AccessTime className={classes.icon} />}
      />
    </BottomNavigation>
  );
};

const EventMetricsRow = withStyles(metricRowStyles)(EventMetricsRowInner);

const getTimeSeries = (
  auth,
  appId,
  type,
  queryParams,
  filters,
  eventName = null,
  props = null,
) => {
  const fn = eventName ? getEventTimeSeriesAPI : getTimeSeriesAPI;
  const updatedFilters = eventName
    ? {
        ...filters,
        event: [
          {
            name: eventName,
            count: { value: 1, operator: "GTE" },
            attributes: props || [],
          },
        ],
      }
    : filters;
  return fn(
    auth,
    appId,
    { ...queryParams, of: type, event: eventName },
    updatedFilters,
  );
};

export default function EventMetricsWithProvider({
  appId,
  queryParams,
  filters,
  userAttributes = [],
  eventList = [],
  sessionAttributes = [],
  totals = { users: 1, sessions: 1 },
}) {
  const auth = useAuth();
  const [events, setEvents] = useState([]);
  const [state, dispatch] = useTracked();
  // As the parent of EventChart is also connected to different react-tracked provider,
  // we can use that dispatch and it never collides with the current provider
  const parentDispatch = useDispatch();
  const { selectedEvents } = state;
  const [selectedEventKeys, setSelectedKeys] = useState([]);
  const [eventAttrMap, setEventAttrMap] = useState({});

  const graphDataKeys = useMemo(() => [...selectedEventKeys, "All"], [
    selectedEventKeys,
  ]);
  const [count, setCount] = useState(0);
  const [fetching, setFetching] = useState(false);

  // Fetch events upon component will mount
  useEffect(() => {
    getEventsAPI(auth, appId, queryParams).then((response) => {
      setEvents(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch time series whenever the queryParams or filters changes
  useDeepCompareEffect(() => {
    dispatch({
      type: RESET_TIME_SERIES,
    });
    setFetching(true);
    const usersPromise = getTimeSeries(auth, appId, "users", queryParams, filters);
    const sessionsPromise = getTimeSeries(auth, appId, "sessions", queryParams, filters);
    Promise.all([usersPromise, sessionsPromise])
      .then((values) => {
        setFetching(false);
        // Update in usersTimeSeries
        dispatch({
          type: UPDATE_TIME_SERIES,
          key: "All",
          user: values[0],
          session: values[1],
        });
        selectedEventKeys.forEach((event) => {
          getCountAndTimeSeriesFor(event, eventAttrMap[event] || []);
        });

        setCount(count + 1);
      })
      .catch((err) => {
        setFetching(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, filters]);

  /**
   * Whenever the eventList gets updated, we need to fetch the data for
   * the newly added event and add it to the list
   */
  useEffect(() => {
    if (eventList.length > 0) {
      let newEvents = [];
      eventList.forEach((event) => {
        if (isDefined(event) && !selectedEventKeys.includes(event)) {
          if (!newEvents.includes(event)) {
            newEvents.push(event);
          }
          getCountAndTimeSeriesFor(event);
        }
      });
      setSelectedKeys([...selectedEventKeys, ...newEvents]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventList]);

  /**
   * Whenever a new event is selected or removed an existing event in this component,
   * we have to notify it to the parent
   */
  useEffect(() => {
    parentDispatch({
      type: SELECTED_EVENTS,
      events: selectedEventKeys,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEventKeys]);



  const getEventCount = (event, type, props) => {
    const updatedFilters = props
      ? {
          ...filters,
          event: [
            {
              name: event,
              count: { value: 1, operator: "GTE" },
              attributes: props || [],
            },
          ],
        }
      : filters;
    return getEventCountAPI(
      auth,
      appId,
      { ...queryParams, of: type, event: event },
      updatedFilters
    );
  };

  const getCountAndTimeSeriesFor = (event, props) => {
    setFetching(true);
    const sessionsCount = getEventCount(event, "sessions", props);
    const usersCount = getEventCount(event, "users", props);
    const eventCount = getEventCount(event, "event", props);
    Promise.all([sessionsCount, usersCount, eventCount])
      .then((values) => {
        setFetching(false);
        dispatch({
          type: ADD_EVENT,
          event: event,
          data: {
            sessions: values[0],
            users: values[1],
            event: values[2],
          },
        });
      })
      .catch((err) => {
        setFetching(false);
      });
    const sessionsPromise = getTimeSeries(
      auth,
      appId,
      "sessions",
      queryParams,
      filters,
      event,
      props,
    );
    const usersPromise = getTimeSeries(
      auth,
      appId,
      "users",
      queryParams,
      filters,
      event,
      props,
    );
    const eventPromise = getTimeSeries(
      auth,
      appId,
      "event",
      queryParams,
      filters,
      event,
      props,
    );
    setFetching(true);
    Promise.all([sessionsPromise, usersPromise, eventPromise])
      .then((values) => {
        setFetching(false);
        dispatch({
          type: UPDATE_TIME_SERIES,
          key: event,
          count: values[2],
          session: values[0],
          user: values[1],
        });
        setCount(count + 1);
      })
      .catch((err) => {
        setFetching(false);
      });
  };

  const handleEventSelection = (values) => {
    if (values.length > 0) {
      values.forEach((event) => {
        if (!selectedEventKeys.includes(event)) {
          getCountAndTimeSeriesFor(event);
        }
      });
    }
    setSelectedKeys(values);
  };

  const handleEventAttributeFilter = (event, props) => {
    setEventAttrMap({
      ...eventAttrMap,
      [event]: props,
    });
    getCountAndTimeSeriesFor(event, props);
  };

  return (
    <Box
      title="Events"
      icon={<EventIcon />}
      withPadding
      controls={
        <MultiSelect
          placeholder="Select Events"
          value={selectedEventKeys}
          options={events.map((event) => ({ label: event, value: event }))}
          handleChange={handleEventSelection}
          style={{ maxWidth: "60%" }}
        />
      }
      footer={
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <MultiUtilityChart
              key={`Events-${count}`}
              height={250}
              valueLabel="Events"
              dataKey="key"
              data={[...state.countTimeSeries]}
              fetching={fetching}
              areaDataKeys={graphDataKeys}
              withoutAggregations
              title="Events"
              withHeader={true}
              color="#519674"
              dot={false}
              showAverage={true}
              syncId="anyId"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <MultiUtilityChart
              height={250}
              valueLabel="Users"
              dataKey="key"
              data={[...state.usersTimeSeries]}
              fetching={fetching}
              areaDataKeys={graphDataKeys}
              withoutAggregations
              title="Users"
              withHeader={true}
              color="#519674"
              dot={false}
              showAverage={true}
              syncId="anyId"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <MultiUtilityChart
              key={`Sessions-${count}`}
              height={250}
              valueLabel="Sessions"
              dataKey="key"
              data={[...state.sessionsTimeSeries]}
              fetching={fetching}
              areaDataKeys={graphDataKeys}
              withoutAggregations
              title="Sessions"
              withHeader={true}
              color="#519674"
              dot={false}
              showAverage={true}
              syncId="anyId"
            />
          </Grid>
        </Grid>
      }
    >
      {selectedEventKeys.map((event) => {
        return (
          <Box
            key={event}
            title={
              <EventMetricsRow
                name={event}
                {...selectedEvents[event]}
                stats={totals}
              />
            }
            collapsible
          >
            <EventAttributeDistributions
              appId={appId}
              userAttributes={userAttributes}
              sessionAttributes={sessionAttributes}
              queryParams={queryParams}
              filters={filters}
              eventName={event}
              title="Selected Properties"
              messageForNoData="No Event Properties Selected"
              onFiltersChange={(props) =>
                handleEventAttributeFilter(event, props)
              }
            />
          </Box>
        );
      })}
    </Box>
  );
}
