import { FormLabel, Grid } from "@material-ui/core";
import React from "react";
import CustomColorPicker from "../../../../../../../../../../ReusableComponents/CustomColorPicker";
import { useCommonConfigStyles } from "../../../../../../../../walkthroughs/components/design/components/configuration/utils";
import {
  SET_SUCCESS_MESSAGE_BG_COLOR,
  SHOW_SUCCESS_MESSAGE,
  SET_SUCCESS_MESSAGE_TEXT_COLOR,
  SET_SUCCESS_MESSAGE_ICON_COLOR,
} from "../../../../../constants";

import { useTracked } from "../../../../../store";

const SuccessMessage = () => {
  const [state, dispatch] = useTracked();

  const {
    ui: {
      show_success_message = false,
      success_message_bg_color,
      success_message_text_color,
      success_message_icon_color,
    },
  } = state;
  const classes = useCommonConfigStyles();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} className={classes.gridItem}>
        <FormLabel className={classes.formLabel}>Text Color</FormLabel>
        <CustomColorPicker
          projectHigher={true}
          color={success_message_text_color || "#000000"}
          handleColorChange={color => {
            dispatch({
              type: SET_SUCCESS_MESSAGE_TEXT_COLOR,
              value: color.hex,
            });
          }}
        />
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        <FormLabel className={classes.formLabel}>Icon Color</FormLabel>
        <CustomColorPicker
          projectHigher={true}
          color={success_message_icon_color || "#000000"}
          handleColorChange={color => {
            dispatch({
              type: SET_SUCCESS_MESSAGE_ICON_COLOR,
              value: color.hex,
            });
          }}
        />
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        <FormLabel className={classes.formLabel}>Background Color</FormLabel>
        <CustomColorPicker
          projectHigher={true}
          color={success_message_bg_color || "#000000"}
          handleColorChange={color => {
            dispatch({
              type: SET_SUCCESS_MESSAGE_BG_COLOR,
              value: color.hex,
            });
          }}
        />
      </Grid>
    </Grid>
  );
};

export default SuccessMessage;
