import {
  Button,
  IconButton,
  Menu,
  Table,
  TableBody,
  TableCell,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { useState, useEffect } from "react";
import { Provider, useTracked } from "./store";
import styled from "styled-components";
import {
  getPlatformLogoColor,
  PLATFORM_IMAGES,
} from "../../../../../Application/App/styling/CustomCSS";
import ClassicCard from "../../../../ReusableComponents/ClassicCard";
import { useAuth } from "../../../../../contexts/AuthContext";
import { getAppVersions } from "../../../common/actions";
import {
  EM_GET_CLIENT_EVENTS,
  MODAL_STATE_CHANGE,
  EM_GET_CLIENT_EVENTS_PENDING,
  UPDATE_PAGE_COUNTER,
  SET_APP_VERSIONS,
  PAGINATION_ACTION,
  FILTERS_CHANGED,
  EVENT_SOURCES,
  MODAL_TYPES,
} from "./constants";
import Modal from "./components/Modal";
import PaginationController from "./components/PaginationController";
import {
  getEventRowsAPI,
  addClientEventAPI,
  deleteClientEventAPI,
  editClientEventAPI,
} from "./api";
import EventMenu from "./components/EventMenu";
import Filters from "./components/Filters";

const TableHeaderCell = styled(TableCell)`
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
`;

const useStyles = makeStyles((theme) => ({
  content: {
    paddingTop: 36,
    maxWidth: "85%",
    margin: "auto",
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  thead: {
    color: "#ffffff",
  },
  table: {
    minWidth: 500,
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      background: "#f7f7f7",
    },
    "&:hover": {
      background: "#FFF9F5",
      cursor: "pointer",
      color: `#FF7F33`,
    },
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

export const SourceChip = styled.div`
  padding: 4px 6px;
  background: ${(props) => props.color || "#a3aaae"};
  color: white;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 4px;
  display: inline-block;
  font-size: 12px;
`;

function transformEventRows(response) {
  if (response) {
    const { rows, total, has_prev, has_next } = response;
    return {
      after: rows.length > 0 ? rows[rows.length - 1]["_id"]["$oid"] : "",
      before: rows.length > 0 ? rows[0]["_id"]["$oid"] : "",
      rows: rows,
      has_next,
      has_prev,
      total,
    };
  } else {
    return {
      rows: [],
      after: "",
      before: "",
      has_next: false,
      has_prev: false,
      total: 0,
    };
  }
}

function EventsManagement({ app }) {
  const [state, dispatch] = useTracked();
  const auth = useAuth();
  const appId = app.app_id;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRowId, setCurrentRowId] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);

  const {
    filters,
    pagination: { after, before, limit, pageCounter },
    pagination,
    rows = [],
    get_client_events_pending,
    dialog,
    appVersions,
  } = state;

  const updateEventRows = (appId, filters, after, before, limit) => {
    dispatch({ type: EM_GET_CLIENT_EVENTS_PENDING });
    getEventRowsAPI(appId, filters, after, before, limit, auth)
      .then(transformEventRows)
      .then((payload) => {
        dispatch({ type: EM_GET_CLIENT_EVENTS, payload: payload, meta: {} });
      });
  };

  useEffect(() => {
    updateEventRows(appId, filters, after, before, limit);
    getAppVersions(auth, appId).then((payload) =>
      dispatch({ type: SET_APP_VERSIONS, payload: payload, meta: {} })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateEventRows(appId, filters, null, null, limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, limit]);

  const handleDialogState = (open, type, reset_pagination = false) => {
    dispatch({
      type: MODAL_STATE_CHANGE,
      payload: {
        open,
        type,
      },
      meta: {
        reset_pagination,
      },
    });
  };

  const handleAdd = (event) => {
    addClientEventAPI(appId, event, auth).then((res) => {
      if (res) {
        handleDialogState(false, null, true);
        updateEventRows(appId, filters, null, null, limit);
      }
    });
  };

  const handleDelete = (eventId) => {
    deleteClientEventAPI(appId, eventId, auth).then((res) => {
      if (res) {
        handleDialogState(false, null, true);
        updateEventRows(appId, filters, null, null, limit);
      }
    });
  };

  const handleEdit = (event) => {
    editClientEventAPI(appId, event, auth).then((res) => {
      if (res) {
        handleDialogState(false, null, true);
        updateEventRows(appId, filters, null, null, limit);
      }
    });
  };

  let numCols = 7;
  return (
    <section className={classes.content}>
      <Grid container justify={"center"}>
        <ClassicCard
          style={{
            padding: 0,
            borderRadius: 6,
            boxShadow:
              "0px 1px 5px 0px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.1), 0px 3px 1px -2px rgba(0,0,0,0.1)",
          }}
        >
          <div className={classes.tableWrapper}>
            <Table className={classes.table}>
              <thead>
                <TableRow style={{ height: 128 }}>
                  <TableCell colSpan={numCols}>
                    <Grid
                      container
                      style={{ width: "100%" }}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-end"
                    >
                      <Filters
                        appVersions={appVersions}
                        eventType={filters.event_type}
                        eventName={filters.event_name}
                        source={filters.source}
                        platform={filters.platform}
                        appVersion={filters.app_version}
                        disabled={get_client_events_pending}
                        handleFilterChange={(key, value) => {
                          dispatch({
                            type: FILTERS_CHANGED,
                            payload: {
                              key,
                              value,
                            },
                            meta: { reset_pagination: false },
                          });
                        }}
                      />
                      <Button
                        variant={"outlined"}
                        color={"primary"}
                        style={{ marginLeft: "auto" }}
                        onClick={() => {
                          dispatch({
                            type: MODAL_STATE_CHANGE,
                            payload: {
                              open: true,
                              type: MODAL_TYPES.CREATE_NEW,
                            },
                            meta: {
                              reset_pagination: false,
                            },
                          });
                        }}
                      >
                        <AddIcon style={{ marginRight: 4 }} />
                        Add New
                      </Button>
                    </Grid>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableHeaderCell>Event Name</TableHeaderCell>
                  <TableHeaderCell>Attribute Name</TableHeaderCell>
                  <TableHeaderCell>Attribute Type</TableHeaderCell>
                  <TableHeaderCell align={"center"}>Source</TableHeaderCell>
                  <TableHeaderCell align={"center"}>Platform</TableHeaderCell>
                  <TableHeaderCell align={"center"}>
                    App Version
                  </TableHeaderCell>
                  <TableHeaderCell> </TableHeaderCell>
                </TableRow>
              </thead>
              <TableBody>
                {rows.length > 0 &&
                  rows.map((row) => {
                    const rowId = row._id.$oid;
                    const canEdit = row.source !== EVENT_SOURCES.sdk;
                    // const canConvert = filters.event_type === CLIENT_EVENT;
                    const canConvert = false;
                    const canDelete = row.source !== EVENT_SOURCES.sdk;
                    const hasActions = [canEdit, canConvert, canDelete].some(
                      (x) => x
                    );
                    return (
                      <TableRow className={classes.tableRow} key={rowId}>
                        <TableCell> {row.event_name} </TableCell>
                        <TableCell> {row.attr_name} </TableCell>
                        <TableCell> {row.attr_type} </TableCell>
                        <TableCell align={"center"}>
                          <SourceChip
                            color={
                              row.source === EVENT_SOURCES.sdk
                                ? "slategray"
                                : "#002845"
                            }
                          >
                            {row.source}
                          </SourceChip>
                        </TableCell>
                        <TableCell align={"center"}>
                          {PLATFORM_IMAGES(
                            row.platform,
                            getPlatformLogoColor(row.platform),
                            24
                          )}
                        </TableCell>
                        <TableCell align={"center"}>
                          {" "}
                          {row.app_version}{" "}
                        </TableCell>
                        <TableCell style={{ width: 36, textAlign: "center" }}>
                          {hasActions && (
                            <IconButton
                              onClick={(e) => {
                                setAnchorEl(e.currentTarget);
                                setCurrentRowId(rowId);
                                setCurrentRow(row);
                              }}
                            >
                              <MoreVertIcon fontSize={"small"} />
                            </IconButton>
                          )}

                          {currentRowId === rowId && (
                            <Menu
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={() => {
                                setAnchorEl(null);
                                setCurrentRowId(rowId);
                                setCurrentRow(row);
                              }}
                              PaperProps={{
                                style: {
                                  boxShadow:
                                    "0px 1px 5px 0px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.1), 0px 3px 1px -2px rgba(0,0,0,0.1)",
                                },
                              }}
                            >
                              <EventMenu
                                canEdit={canEdit}
                                canConvert={canConvert}
                                canDelete={canDelete}
                                handleDialog={handleDialogState}
                                onClose={() => setAnchorEl(null)}
                              />
                            </Menu>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
          <PaginationController
            disabled={get_client_events_pending}
            limit={limit}
            handleRowsPerPageChange={(key, value) => {
              dispatch({
                type: PAGINATION_ACTION,
                payload: { key, value },
                meta: {},
              });
            }}
            pagination={pagination}
            updatePageCounter={(direction) => {
              dispatch({
                type: UPDATE_PAGE_COUNTER,
                payload: {
                  pageCounter:
                    direction > 0 ? pageCounter + 1 : pageCounter - 1,
                },
                meta: {},
              });
            }}
            fetchResults={(after, before) =>
              updateEventRows(appId, filters, after, before, limit)
            }
          />
        </ClassicCard>
      </Grid>

      {dialog.open && (
        <Modal
          event={{ ...currentRow }}
          open={dialog.open}
          dialog_type={dialog.dialog_type}
          handleAdd={handleAdd}
          handleClose={() => handleDialogState(false, null)}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          appVersions={appVersions}
          statusMap={{
            edit_client_events_pending: state.edit_client_events_pending,
            delete_client_events_pending: state.delete_client_events_pending,
          }}
        />
      )}
    </section>
  );
}

export default function EventsManagementWithProvider({ app }) {
  return (
    <Provider>
      <EventsManagement app={app}></EventsManagement>
    </Provider>
  );
}
