import { Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { useAuth } from "../../../../../../../../../../../../contexts/AuthContext";
import { getAttributeValuesAPI } from "../../../../../../../../../../../common/actions";
import Autocomplete from "../../../../../../../../../../../ReusableComponents/Autocomplete";
import TruncatedText from "../../../../../../../../../../../ReusableComponents/TruncatedText";
import { useDateFilters } from "../../../../../../../../../utils";
import {
  DEFAULT_DATA_TYPES,
  executeWithDebounce,
} from "../../../../../../../constants";
import DatatypeSelector from "../../../../../../DatatypeSelector";
import OperatorSelector from "../../../../../../OperatorSelector";
import PropertyValueSelector from "../../../../../../PropertyValueSelector";
import { getOperatorString, getOptionsForType } from "../../../../../../utils";
import RowDiv from "../../../../../../../../../../../ReusableComponents/RowDiv";
import CustomPopper from "../../../../../../../../../../../ReusableComponents/CustomPopper";
import { DATA_TYPES } from "../../../../../../constants";

const useStyles = makeStyles((theme) => ({
  closeIcon: (props) => ({
    color: "#A1ADB6",
    padding: 2,
    "&:hover": {
      background: "none",
    },
  }),
  paperClass: (props) => ({
    overflow: "unset",
  }),
}));

export default function Property({
  appId,
  platform,
  property,
  index,
  handleOnChange,
  handleOnDelete,
  attributes,
}) {
  const auth = useAuth();
  const dateFilters = useDateFilters(true);
  const classes = useStyles();

  const {
    name: propName,
    operator: propOperator = "EQ",
    type: propType = "s",
    tl: propLabel = DATA_TYPES.STRING,
    value: propValue = [],
  } = property;

  const [nameAnchorEl, setNameAnchorEl] = useState(null);
  const [operatorAnchorEl, setOperatorAnchorEl] = useState(null);
  const [typeAnchorEl, setTypeAnchorEl] = useState(null);
  const [valueAnchorEl, setValueAnchorEl] = useState(null);

  const [values, setValues] = useState([]);
  const [filteredValues, setFilteredValues] = useState([]);

  const [state, setState] = useState({
    name: propName,
    op: propOperator,
    t: propType,
    tl: propLabel,
    val: propValue,
  });

  const { name, op, tl, t, val } = state;

  useEffect(() => {
    name && getAttributeValues(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Adding this effect owing to this bug
   * https://gtl.apxor.com/apxor/issue-tracker/-/issues/184
   *
   * Despite deleting an attribute, eventhough `property` is updated, the state is being left stale,
   * Using this effect to force update state on `property` change.
   */
  useEffect(() => {
    setState({
      name: propName,
      op: propOperator,
      t: propType,
      tl: propLabel,
      val: propValue,
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property])

  const getAttributeValues = (value, search = "") => {
    getAttributeValuesAPI(auth, appId, {
      ...dateFilters,
      of: "users",
      attribute: value,
      q: search,
    }).then((response) => {
      const filteredValues = response.filter(
        (name) =>
          ["apx_lt_count", "apx_lt_count_slot"].includes(name) ||
          name.indexOf("apx_") === -1
      );
      setValues(filteredValues);
      setFilteredValues(filteredValues);
    });
  };

  useEffect(() => {
    executeWithDebounce(700)(handleOnChange, {
      name: state.name,
      operator: state.op,
      type: state.t,
      tl: state.tl,
      value: state.val,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.name, state.op, state.t, state.tl, state.val]);

  return (
    <RowDiv center>
      <div style={{ width: 30, borderBottom: "1px solid #C5CDD2" }}></div>
      <Grid
        container
        spacing={1}
        style={{ marginBottom: 10, marginTop: index === 0 ? 10 : 3 }}
      >
        <Grid item xs={11}>
          <Grid
            container
            spacing={1}
            style={{
              background: "#F0F2F3",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid item style={{ marginLeft: 10 }}>
              <Typography
                variant="subtitle2"
                style={{ fontWeight: 600, alignSelf: "center" }}
              >
                {index === 0 ? "" : "and"}
              </Typography>
            </Grid>
            <Grid item>
              <TruncatedText
                placeholder="select property"
                value={name}
                onClick={(e) => e && setNameAnchorEl(e.currentTarget)}
              />
              <CustomPopper
                anchorEl={nameAnchorEl}
                setAnchorEl={setNameAnchorEl}
              >
                <Autocomplete
                  styles={{ width: 300 }}
                  defaultValue={{ label: name, value: name }}
                  handleOnChange={(selected) => {
                    setState({
                      name: selected?.value || "",
                      op: propOperator,
                      t: propType,
                      tl: propLabel,
                      val: propValue,
                    });
                    if (selected) getAttributeValues(selected.value);
                  }}
                  options={attributes.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                />
              </CustomPopper>
            </Grid>
            <Grid item>
              <TruncatedText
                placeholder="select data type"
                value={tl}
                onClick={(e) => e && setTypeAnchorEl(e.currentTarget)}
              />
              <CustomPopper
                anchorEl={typeAnchorEl}
                setAnchorEl={setTypeAnchorEl}
                paperClass={classes.paperClass}
              >
                <DatatypeSelector
                  type={t}
                  label={tl}
                  options={DEFAULT_DATA_TYPES.filter(
                    (item) => item.label !== "Boolean"
                  )}
                  handleOnChange={(value, label) => {
                    setState({
                      ...state,
                      t: value,
                      tl: label,
                    });
                  }}
                />
              </CustomPopper>
            </Grid>
            {tl && (
              <Grid item>
                <TruncatedText
                  value={getOperatorString(op)}
                  onClick={(e) => e && setOperatorAnchorEl(e.currentTarget)}
                />
                <CustomPopper
                  anchorEl={operatorAnchorEl}
                  setAnchorEl={setOperatorAnchorEl}
                  paperClass={classes.paperClass}
                >
                  <OperatorSelector
                    type={tl}
                    selected={op}
                    options={getOptionsForType(tl).filter(
                      (item) => item.value !== "R"
                    )}
                    handleOnChange={(value) =>
                      setState({
                        ...state,
                        op: value,
                      })
                    }
                  />
                </CustomPopper>
              </Grid>
            )}
            {name.length > 0 &&
              t &&
              ((tl !== "Boolean" && op !== "R") || tl === "Boolean") && (
                <Grid item>
                  <TruncatedText
                    placeholder="value"
                    value={val.join(", ")}
                    onClick={(e) => e && setValueAnchorEl(e.currentTarget)}
                  />
                  <CustomPopper
                    anchorEl={valueAnchorEl}
                    setAnchorEl={setValueAnchorEl}
                  >
                    <PropertyValueSelector
                      multiple={true}
                      attributeValues={filteredValues}
                      handleOnChange={(value) => {
                        setState({
                          ...state,
                          val: value.map((item) => item.value),
                        });
                        setFilteredValues(
                          values.filter(
                            (item) =>
                              value.findIndex((i) => i.value === item) === -1
                          )
                        );
                      }}
                      label={tl}
                      name={name}
                      onInputChange={(query) => getAttributeValues(name, query)}
                      operator={op}
                      platform={platform}
                      type={t}
                      value={val.map((item) => ({ label: item, value: item }))}
                    />
                  </CustomPopper>
                </Grid>
              )}
          </Grid>
        </Grid>
        <Grid
          item
          xs={1}
          style={{ display: "flex", padding: 0, justifyContent: "center" }}
        >
          <IconButton
            onClick={() => handleOnDelete(index)}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    </RowDiv>
  );
}
