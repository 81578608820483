import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import Loading from "../../../../../../ReusableComponents/Loading";
import { API_BASE, API_ROOT, API_ROOT_V4 } from "../../../../../../../config";
import { withQueryStrings } from "../../../../../../../utils";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link, useRouteMatch } from "react-router-dom";
import { useAuth } from "../../../../../../../contexts/AuthContext";
import { surveyResultForOtherOptionAPI } from "../actions";
import { POST_BODY, SET_OTHER_RESPONSES } from "../constants";
import { useTracked } from "../store";

export const OtherOptionResults = ({
  questionId,
  id,
  value,
  buttonText,
  filters,
}) => {
  const auth = useAuth();
  const {
    user: { email },
  } = auth;

  const [state, dispatch] = useTracked();
  const { others = {} } = state;

  const [data, setData] = useState(others[questionId + "-" + id]);
  const [fetchingData, setFetchingData] = useState(false);
  const [downloadInProgress, setDownloadInProgress] = useState(false);

  const {
    params: { appId, campaignId: surveyId },
  } = useRouteMatch();

  return (
    <Grid container>
      <Grid item xs={12}>
        {!data && (
          <Button
            variant="outlined"
            onClick={(e) => {
              setFetchingData(true);
              surveyResultForOtherOptionAPI(
                auth,
                appId,
                surveyId,
                { questionId, answerId: id, readable: true },
                POST_BODY
              ).then((response) => {
                setData(response);
                dispatch({
                  type: SET_OTHER_RESPONSES,
                  key: `${questionId}-${id}`,
                  value: response,
                });
              });
            }}
          >
            {fetchingData && <Loading size={18} style={{ marginRight: 12 }} />}
            {buttonText ? (
              buttonText
            ) : (
              <>
                &nbsp;<strong>{value}</strong> &nbsp; Responses
              </>
            )}
          </Button>
        )}

        {data && (
          <div style={{ margin: 16 }}>
            <Typography variant="body2">
              {data.choice && data.choice !== "" ? data.choice : "Responses"}
            </Typography>
            <List dense style={{ maxHeight: 320, overflowY: "auto" }}>
              {data.responses.map(({ user_id, response }, index) => (
                <Link
                  title="Click to go to User"
                  key={user_id + response + index}
                  target="_blank"
                  to={`/apps/${appId}/user/${user_id}`}
                >
                  <ListItem button disableGutters>
                    <ListItemText primary={response} />
                  </ListItem>
                </Link>
              ))}
            </List>
          </div>
        )}

        {data && (
          <Tooltip
            id="tooltip-fab-download"
            title="Download Responses as CSV"
            placement="bottom"
          >
            <Button
              size={"small"}
              disabled={downloadInProgress}
              style={{ margin: "0 10px" }}
              variant={"contained"}
              color="primary"
              onClick={() => {
                setDownloadInProgress(true);
                const isv4App = [].includes(appId);
                axios
                  .request({
                    url:
                      `${
                        isv4App ? API_BASE + API_ROOT_V4 : API_BASE + API_ROOT
                      }surveys/${surveyId}/results/others/download?customerId=${email}&appId=${appId}` +
                      withQueryStrings({
                        ...filters,
                        questionId,
                        answerId: id,
                      }),
                    method: "POST",
                    responseType: "blob",
                    withCredentials: true,
                    data: { user: [], session: [], event: [] },
                  })
                  .then((response) => {
                    let blob = new Blob([response.data], {
                      type: response.headers["content-type"],
                    });
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = "other_responses.csv";
                    link.click();
                    link.remove();
                    setDownloadInProgress(false);
                  })
                  .catch((error) => {
                    if (error) {
                      setDownloadInProgress(false);
                    }
                  });
              }}
            >
              {!downloadInProgress && (
                <DownloadIcon style={{ color: "#ffffff" }} />
              )}
              {downloadInProgress && (
                <CircularProgress size={24} style={{ color: "white" }} />
              )}
            </Button>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );
};
