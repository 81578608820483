import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core"
import Snackbar from "@material-ui/core/Snackbar"
import {
  FileCopyOutlined,
  HighlightOff,
  PauseCircleOutline,
  PlayCircleOutline,
} from "@material-ui/icons"
import BarChartIcon from "@material-ui/icons/BarChart"
import CloseIcon from "@material-ui/icons/Close"
import MoreHorizIcon from "@material-ui/icons/MoreHoriz"
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled"
import SendIcon from "@material-ui/icons/Send"
import Apxor from "apxor"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { isExperienceDashboard } from "../../../../../../config"
import {
  APP_PLATFORMS,
  APXOR_SDK_CATEGORY_CONSTANTS,
} from "../../../../../../constants"
import { useAuth } from "../../../../../../contexts/AuthContext"
import BootstrapTooltip from "../../../../../ReusableComponents/BootstrapTooltip"
import CustomMaterialUIDialog from "../../../../../ReusableComponents/CustomMaterialUIDialog"
import Loading from "../../../../../ReusableComponents/Loading"
import RowDiv from "../../../../../ReusableComponents/RowDiv"
import StatusIdentifier from "../../../../../ReusableComponents/StatusIdentifier"
import TruncatedText from "../../../../../ReusableComponents/TruncatedText"
import { useCommonDashboardStyles } from "../../../../../common/constants"
import { cancelScheduledPublishAPI } from "../../../actions"
import { CAMPAIGNS_HOLDER_TABS, STATUS_PALETTE } from "../../../utils"
import ABVariantManualPublish from "../../ABVariantManualPublish"
import BarChartIconActiveIcon from "../../assets/BarChartIconActiveIcon.png"
import android from "../../assets/android.png"
import ios from "../../assets/ios.png"
import web from "../../assets/web.png"
import SummaryPage from "../../campaigncreator/components/summary"
import {
  checkIfValidToPublishOrTest,
  getRouteForActionType,
} from "../../campaigncreator/utils"
import useCampaign from "./use-campaign"

function DialogBox({
  dialogProps,
  extraInfo,
  message,
  onDialogClose,
  negativeHandler,
  negativeWord,
  positiveHandler,
  positiveWord,
  showDialog,
  title = null,
  withActions = true,
}) {
  return (
    <div>
      <CustomMaterialUIDialog
        hideCloseIcon={true}
        title={title}
        maxWidth={"sm"}
        openFlag={showDialog}
        onDialogClose={onDialogClose}
        onClose={negativeHandler}
        dialogActions={
          withActions ? (
            <>
              <div>
                {extraInfo && (
                  <Typography style={{ paddingLeft: 12 }}>
                    Campaign Status:{" "}
                    <StatusIdentifier
                      statusType={extraInfo}
                      palette={STATUS_PALETTE}
                    />
                  </Typography>
                )}
              </div>
              <div>
                {negativeHandler && (
                  <Button onClick={negativeHandler}>
                    {negativeWord ? negativeWord : "Cancel"}
                  </Button>
                )}
                {positiveHandler && (
                  <Button onClick={positiveHandler} color="primary">
                    {positiveWord ? positiveWord : "Ok"}
                  </Button>
                )}
              </div>
            </>
          ) : null
        }
        dialogActionStyles={{
          display: "flex",
          justifyContent: "space-between",
        }}
        dialogContent={message}
        dialogProps={dialogProps}
      />
    </div>
  )
}

DialogBox.propTypes = {
  message: PropTypes.object,
  showDialog: PropTypes.bool,
  positiveHandler: PropTypes.func,
  negativeHandler: PropTypes.func,
}

const styles = (theme) => ({
  root: {
    fontWeight: 600,
    fontSize: 14,
    width: "100%",
    minHeight: 72,
    borderRadius: 3,
    padding: "8px 16px 4px 16px",
    display: "flex",
    alignItems: "center",
    borderLeft: "1px solid #e4eaec",
    borderRight: "1px solid #e4eaec",
    "&:hover": {
      background: "#FFF9F5",
      cursor: "pointer",
      color: `#FF7F33`,
    },
  },
  highlight: {
    "&:hover": {
      color: `#FF7F33`,
    },
  },
  odd: {
    backgroundColor: "rgba(0, 40, 69, 0.01)",
  },
  statusIdentifierColor: {
    color: `${theme.palette.text.primary} !important`,
    fontWeight: "bold",
  },
  statusIdentifierBgColor: {
    backgroundColor: `${theme.palette.text.primary}  !important`,
  },
  buttonLabel: {
    gap: "6px",
  },
})

export const INITIAL_STATE = {
  alertMessage: null,
  showDialog: false,
  positiveFunction: null,
  positiveWord: "Ok",
  negativeFunction: null,
  negativeWord: "Cancel",
}

const TRUNCATED_TEXT_STYLES = (value) => {
  return {
    backgroundColor: value === "A/B" ? "#00BDE1" : "rgba(125, 145, 159, 0.14)",
    color: value === "A/B" ? "#FFFFFF" : "rgba(103, 122, 135, 1)",
    fontSize: 12,
    fontWeight: 600,
    borderRadius: 100,
    paddingLeft: 10,
    paddingRight: 10,
    border: "#FFFFFF",
  }
}

const INNER_TRUNCATED_TEXT_STYLES = {
  ...TRUNCATED_TEXT_STYLES,
  backgroundColor: "rgba(79, 101, 117, 1)",
  color: "rgba(255, 255, 255, 1)",
}

function ActionIconHolder({ children }) {
  return <div style={{ margin: "0 2px" }}>{children}</div>
}

function CampaignCard({
  id,
  appId,
  actionType,
  campaignConfig,
  classes,
  cohorts = [],
  designSummaryComponent,
  refreshCampaigns = null,
  statusType,
  pageCounter,
  limit,
  isGuidedToursTab,
}) {
  const auth = useAuth()
  const typographyClasses = useCommonDashboardStyles()
  const { push, go } = useHistory()

  const {
    completeConfig,
    progressState,
    setProgressState,
    showABManualPubishDialog,
    setShowABManualPubishDialog,
    publishPending,
    showPublishConfirmDialog,
    setShowPublishConfirmDialog,
    validationRef,
    validationMessage,
    setValidationMessage,
    anchorEl,
    setAnchorEl,
    pending,
    open,
    state,
    setState,
    handleDuplicate,
    deleteCampaign,
    toggleCampaign,
    publishCampaign,
    handleShowCampaignInfo,
    handleSnackbarClosed,
    fetchConfig,
  } = useCampaign(campaignConfig, refreshCampaigns, actionType, appId)

  const {
    alertMessage,
    showDialog,
    positiveFunction,
    positiveWord,
    negativeFunction,
    negativeWord,
    showCampaignInfo,
  } = state

  const {
    meta: { name = "", platform: metaPlatform = "android", tags = [] },
    enabled,
  } = campaignConfig

  const startDate = campaignConfig.validity.start_date
  const endDate = campaignConfig.validity.end_date
  const canPause =
    statusType === CAMPAIGNS_HOLDER_TABS.ACTIVE ||
    (statusType === CAMPAIGNS_HOLDER_TABS.DRAFTS && enabled)
  const canEdit = !campaignConfig?.published

  const canAnalyse = useMemo(
    () =>
      statusType !== CAMPAIGNS_HOLDER_TABS.DRAFTS &&
      statusType !== CAMPAIGNS_HOLDER_TABS.SCHEDULED,
    [statusType],
  )

  // Need to combile this duplicate code, move publish flow outside as a util.
  const stateRef = useRef()
  stateRef.current = completeConfig

  useEffect(() => {
    if (Object.keys(completeConfig).length !== 0) {
      checkIfValidToPublishOrTest(stateRef, validationRef, setValidationMessage)
    }
  }, [completeConfig, setValidationMessage, validationRef])

  const [
    showResumeConfirmationDialog,
    setShowResumeConfirmationDialog,
  ] = useState(false)

  const handleToggleAction = useCallback(() => {
    toggleCampaign(campaignConfig, !campaignConfig.enabled)
    setShowResumeConfirmationDialog(false)
  }, [campaignConfig, toggleCampaign])

  const closeResumeConfirmationDialog = useCallback(() => {
    setShowResumeConfirmationDialog(false)
  }, [])

  const action_type = useMemo(
    () => (actionType === "walkthroughs" ? "campaign" : "survey"),
    [actionType],
  )

  return (
    <TableRow>
      <TableCell colSpan={8} style={{ padding: "unset", border: "unset" }}>
        <Card
          className={`${classes.root} ${
            id % 2 === 0 ? classes.even : classes.odd
          }`}
          style={{
            borderInline: "none",
          }}
          elevation={0}
        >
          <Grid container justifyContent="space-evenly" alignItems={"center"}>
            <Grid
              item
              xs={1}
              style={{ textAlign: "left", paddingRight: 25 }}
              onClick={handleShowCampaignInfo}
            >
              <Typography
                style={{ fontWeight: 600, fontSize: 14 }}
                variant={"h6"}
                gutterBottom
              >
                {id + (pageCounter - 1) * limit}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              style={{ textAlign: "left", overflowWrap: "anywhere" }}
              onClick={handleShowCampaignInfo}
            >
              <Typography
                className={classes.highlight}
                style={{ fontWeight: 700, fontSize: 15 }}
                variant={"h6"}
                gutterBottom
              >
                {name}
              </Typography>
              <RowDiv center applyMargin>
                {tags.length >= 1 && (
                  <>
                    <TruncatedText
                      value={tags[0]}
                      styles={TRUNCATED_TEXT_STYLES(tags[0])}
                      tooltipPlacement="bottom-start"
                      disabled
                    />
                    {tags[1] && (
                      <TruncatedText
                        value={tags[1]}
                        styles={TRUNCATED_TEXT_STYLES(tags[1])}
                        tooltipPlacement="bottom-start"
                        disabled
                      />
                    )}
                    {tags.length > 2 && (
                      <TruncatedText
                        value="..."
                        styles={{
                          ...TRUNCATED_TEXT_STYLES(),
                          paddingTop: 0,
                        }}
                        disabled
                        tooltipPlacement="bottom-start"
                        tooltipTitle={
                          <RowDiv center applyMargin>
                            {tags.map((tag, index) => {
                              if (index === 0) {
                                return (
                                  <React.Fragment key={tag}></React.Fragment>
                                )
                              }
                              return (
                                <TruncatedText
                                  key={tag}
                                  value={tag}
                                  styles={INNER_TRUNCATED_TEXT_STYLES}
                                  maxWidth={500}
                                  disabled
                                />
                              )
                            })}
                          </RowDiv>
                        }
                      />
                    )}
                  </>
                )}
              </RowDiv>
            </Grid>
            <Grid
              item
              xs={1}
              style={{ textAlign: "center" }}
              onClick={handleShowCampaignInfo}
            >
              {metaPlatform === APP_PLATFORMS.ios ? (
                <img src={ios} alt="iOS Logo"></img>
              ) : metaPlatform === APP_PLATFORMS.web ? (
                <img
                  style={{ height: 26, width: 26 }}
                  src={web}
                  alt="Web Logo"
                ></img>
              ) : (
                <img src={android} alt="Android Logo"></img>
              )}
            </Grid>
            <Grid
              item
              xs={1}
              style={{ textAlign: "left" }}
              onClick={handleShowCampaignInfo}
            >
              {isGuidedToursTab ? (
                <>-</>
              ) : (
                <Typography
                  variant={"h6"}
                  style={{ fontWeight: 600, fontSize: 14 }}
                  gutterBottom
                  title={`${moment(startDate).format("LLL")}`}
                >
                  {`${moment(startDate).format("DD/MM/YYYY")}`}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={1}
              style={{ textAlign: "left" }}
              onClick={handleShowCampaignInfo}
            >
              {isGuidedToursTab ? (
                <>-</>
              ) : (
                <Typography
                  variant={"h6"}
                  style={{ fontWeight: 600, fontSize: 14 }}
                  gutterBottom
                  title={`${moment(endDate).format("LLL")}`}
                >
                  {`${moment(endDate).format("DD/MM/YYYY")}`}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={1}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <StatusIdentifier
                disabled={!enabled}
                statusType={statusType}
                palette={STATUS_PALETTE}
                classes={{
                  color: classes.statusIdentifierColor,
                  bgColor: classes.statusIdentifierBgColor,
                }}
              />
              {statusType === CAMPAIGNS_HOLDER_TABS.COMPLETED &&
                campaignConfig?.meta?.isExperiment &&
                campaignConfig?.experiment?.publishSettings
                  ?.winner_variant_calculated &&
                !campaignConfig?.experiment?.isWinnerPublished && (
                  <IconButton
                    color="primary"
                    aria-label="Manual Publish"
                    onClick={() => setShowABManualPubishDialog(true)}
                    style={{ padding: 0 }}
                  >
                    <SendIcon
                      style={{
                        transform: "rotate(-45deg)",
                        color: "#FFF",
                        background: "#FF7F33",
                        borderRadius: "50%",
                        padding: 4,
                      }}
                    />
                  </IconButton>
                )}
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {isGuidedToursTab ? (
                <Button
                  color="primary"
                  variant="text"
                  startIcon={<PlayCircleFilledIcon />}
                  style={{
                    fontSize: "13px",
                    fontWeight: "700",
                  }}
                  onClick={() => {
                    Apxor.logEvent(
                      "TryNudgeClicked",
                      {
                        "name": campaignConfig.meta.name || "NA",
                        "actionType": actionType,
                        "experience_type":
                          campaignConfig?.meta?.experience_type ?? "",
                      },
                      APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS,
                    )

                    handleDuplicate(campaignConfig)
                  }}
                >
                  Try Now
                </Button>
              ) : (
                <>
                  <ActionIconHolder>
                    {canAnalyse && (
                      <BootstrapTooltip
                        title="Campaign Analytics"
                        placement="top"
                      >
                        <div>
                          <IconButton
                            color="secondary"
                            aria-label="Analysis"
                            onClick={() => {
                              push({
                                pathname: `/apps/${
                                  auth?.appId
                                }/${getRouteForActionType(actionType)}/${
                                  campaignConfig?._id
                                }/analysis`,
                              })
                            }}
                            disabled={
                              statusType === CAMPAIGNS_HOLDER_TABS.SCHEDULED
                            }
                          >
                            <img
                              alt=""
                              style={{ height: 16, width: 14 }}
                              src={BarChartIconActiveIcon}
                            />
                          </IconButton>
                        </div>
                      </BootstrapTooltip>
                    )}
                  </ActionIconHolder>

                  <IconButton
                    color="primary"
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={(element) => setAnchorEl(element.currentTarget)}
                  >
                    <MoreHorizIcon style={{ color: "#8C9EAB" }} />
                  </IconButton>

                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => setAnchorEl(null)}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    {canEdit && (
                      <Link
                        to={{
                          pathname: `/apps/${appId}/${getRouteForActionType(
                            actionType,
                          )}/${campaignConfig._id}/edit`,
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            setAnchorEl(null)
                          }}
                        >
                          Edit
                        </MenuItem>
                      </Link>
                    )}
                    <MenuItem
                      onClick={() => handleDuplicate(campaignConfig, true)}
                    >
                      Duplicate
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        if (statusType === CAMPAIGNS_HOLDER_TABS.PAUSED) {
                          setShowResumeConfirmationDialog(true)
                        } else {
                          handleToggleAction()
                        }
                      }}
                    >
                      {canPause ? "Pause" : "Resume"}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setState({
                          ...state,
                          alertMessage: (
                            <Typography color="textPrimary" variant="h5">
                              Are you sure you want to delete "
                              <b>{campaignConfig.meta.name}</b>"?
                            </Typography>
                          ),
                          positiveFunction: () => {
                            setState({
                              ...state,
                              showDialog: false,
                            })
                            deleteCampaign(campaignConfig)
                          },
                          positiveWord: "Ok",
                          negativeFunction: () => {
                            setState({
                              ...state,
                              ...INITIAL_STATE,
                            })
                          },
                          negativeWord: "Cancel",
                          showDialog: true,
                        })
                        setAnchorEl(null)
                        Apxor.logEvent(
                          "EditCampaignClicked",
                          {
                            "name": campaignConfig.meta.name || "NA",
                            "actionType": actionType,
                            "experience_type":
                              campaignConfig?.meta?.experience_type ?? "",
                          },
                          APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS,
                        )
                      }}
                    >
                      Delete
                    </MenuItem>
                  </Menu>
                </>
              )}
            </Grid>
          </Grid>
          {progressState.statusMessage && (
            <Snackbar
              key={campaignConfig._id}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              open={
                progressState.statusMessage !== "" &&
                progressState.statusMessage !== null
              }
              autoHideDuration={3000}
              onClose={handleSnackbarClosed}
              ContentProps={{
                "aria-describedby": "message-id",
              }}
              message={
                <>
                  <span id="message-id">
                    {progressState.statusMessage || ""}
                  </span>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleSnackbarClosed}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </>
              }
            />
          )}
          {showDialog && (
            <DialogBox
              message={alertMessage}
              negativeHandler={negativeFunction}
              negativeWord={negativeWord}
              positiveHandler={positiveFunction}
              positiveWord={positiveWord}
              showDialog={showDialog}
              onDialogClose={() =>
                setState({
                  ...state,
                  showDialog: false,
                })
              }
            />
          )}
          <CustomMaterialUIDialog
            title={
              <RowDiv
                center
                styles={{
                  justifyContent: "space-between",
                  marginRight: 26,
                  marginLeft: 20,
                }}
              >
                <Typography
                  style={{
                    fontSize: 25,
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {completeConfig?.meta?.isExperiment
                    ? "A/B Test Summary"
                    : `${
                        actionType === "surveys" ? "Survey" : "Campaign"
                      } Summary`}
                  {completeConfig?.meta?.isExperiment &&
                    statusType !== CAMPAIGNS_HOLDER_TABS.COMPLETED &&
                    statusType !== CAMPAIGNS_HOLDER_TABS.DRAFTS && (
                      <StatusIdentifier
                        disabled={!enabled}
                        statusType={statusType}
                        palette={STATUS_PALETTE}
                        classes={{
                          color: classes.statusIdentifierColor,
                          bgColor: classes.statusIdentifierBgColor,
                        }}
                      />
                    )}
                </Typography>
                {completeConfig?.meta?.isExperiment && (
                  <RowDiv center styles={{ gap: 8 }}>
                    {statusType === CAMPAIGNS_HOLDER_TABS.ACTIVE && (
                      <>
                        {campaignConfig?.meta?.isExperiment &&
                          !campaignConfig?.experiment?.settings
                            ?.isManualExperiment && (
                            <Typography
                              className={typographyClasses.typographyCaption500}
                              style={{ fontWeight: 600 }}
                            >
                              Publish winner automatically on{" "}
                              {`${moment(
                                campaignConfig?.experiment?.publishSettings
                                  ?.time_settings?.start_date,
                              ).format("DD/MM/YYYY, HH:MM")}`}
                            </Typography>
                          )}
                        <Button
                          style={{
                            border: "1px solid #CED4D9",
                            borderRadius: 3,
                          }}
                          onClick={() => toggleCampaign(completeConfig, false)}
                          classes={{
                            label: classes.buttonLabel,
                          }}
                        >
                          <PauseCircleOutline color="primary" /> Pause
                        </Button>
                      </>
                    )}
                    {statusType === CAMPAIGNS_HOLDER_TABS.PAUSED && (
                      <Button
                        style={{
                          border: "1px solid #CED4D9",
                          borderRadius: 3,
                        }}
                        onClick={() => toggleCampaign(completeConfig, true)}
                        classes={{
                          label: classes.buttonLabel,
                        }}
                      >
                        <PlayCircleOutline color="primary" /> Resume
                      </Button>
                    )}
                    {
                      statusType !== CAMPAIGNS_HOLDER_TABS.COMPLETED &&
                        statusType !== CAMPAIGNS_HOLDER_TABS.DRAFTS && (
                          <Link
                            to={{
                              pathname: `/apps/${appId}/${getRouteForActionType(
                                actionType,
                              )}/${completeConfig._id}/analysis`,
                              state: { ...completeConfig, statusType },
                            }}
                          >
                            <IconButton
                              color="primary"
                              aria-label="Analysis"
                              style={{
                                border: "1px solid rgb(206, 212, 217)",
                                borderRadius: 3,
                                padding: 6,
                              }}
                              onClick={() => {
                                setState({
                                  ...state,
                                  showCampaignInfo: false,
                                })
                              }}
                            >
                              <BarChartIcon />
                            </IconButton>
                          </Link>
                        )
                    }
                    {
                      statusType !== CAMPAIGNS_HOLDER_TABS.COMPLETED &&
                        statusType !== CAMPAIGNS_HOLDER_TABS.DRAFTS && (
                          <IconButton
                            color="primary"
                            aria-label="Analysis"
                            style={{
                              border: "1px solid rgb(206, 212, 217)",
                              borderRadius: 3,
                              padding: 6,
                            }}
                            onClick={() => handleDuplicate(campaignConfig)}
                          >
                            <FileCopyOutlined color="primary" />
                          </IconButton>
                        )
                    }
                    {
                      statusType === CAMPAIGNS_HOLDER_TABS.COMPLETED && (
                        <RowDiv center styles={{ gap: 8 }}>
                          {completeConfig?.experiment?.isWinnerPublished && (
                            <Typography
                              className={typographyClasses.typographyCaption500}
                              style={{ fontWeight: 600 }}
                            >
                              {`Variant ${completeConfig?.experiment?.publishDetails?.published_variant} has been published`}
                            </Typography>
                          )}
                          <Link
                            to={{
                              pathname: `/apps/${appId}/${getRouteForActionType(
                                actionType,
                              )}/${completeConfig._id}/analysis`,
                              state: { ...completeConfig, statusType },
                            }}
                          >
                            <IconButton
                              color="primary"
                              aria-label="Analysis"
                              style={{
                                border: "1px solid rgb(206, 212, 217)",
                                borderRadius: 3,
                                padding: 6,
                              }}
                              classes={{
                                label: classes.buttonLabel,
                              }}
                              onClick={() => {
                                setState({
                                  ...state,
                                  showCampaignInfo: false,
                                })
                              }}
                            >
                              <BarChartIcon /> Analysis
                            </IconButton>
                          </Link>
                        </RowDiv>
                      )
                    }
                  </RowDiv>
                )}
                {
                  completeConfig?.state === CAMPAIGNS_HOLDER_TABS.SCHEDULED && (
                    <RowDiv center styles={{ gap: 8 }}>
                      {
                        <Typography
                          className={typographyClasses.typographyCaption500}
                          style={{ fontWeight: 600 }}
                        >
                          Campaign will be published on{" "}
                          {`${moment(startDate).format("DD/MM/YYYY, HH:MM")}`}
                        </Typography>
                      }
                      <Button
                        style={{
                          border: "1px solid #CED4D9",
                          borderRadius: 3,
                        }}
                        onClick={() =>
                          // convertToManualPublish(
                          //   completeConfig?.meta?.isExperiment,
                          //   completeConfig?.experiment?.settings
                          //     ?.isManualExperiment
                          // )
                          cancelScheduledPublishAPI(auth, {
                            configId: completeConfig._id,
                            configType: completeConfig?.meta?.config_type,
                          })
                            .then((response) => {
                              if (!response) {
                                // TODO: Show a Snackbar that campaign publish failed
                                return
                              }
                              push(
                                `/apps/${auth.appId}/${getRouteForActionType(
                                  actionType,
                                )}`,
                                {
                                  isExperiment: campaignConfig?.isExperiment,
                                },
                              )
                              go(0)
                              setShowPublishConfirmDialog(false)
                              setState({
                                ...state,
                                showCampaignInfo: false,
                              })
                            })
                            .catch((err) => {
                              //Show snackbar
                            })
                        }
                      >
                        <HighlightOff color="primary" />
                        Cancel
                      </Button>
                    </RowDiv>
                  )
                }
                {
                  completeConfig?.state === CAMPAIGNS_HOLDER_TABS.DRAFTS && (
                    <RowDiv center styles={{ gap: 8 }}>
                      {validationMessage !== "" && !isGuidedToursTab && (
                        <BootstrapTooltip
                          placement="bottom"
                          title={validationMessage}
                        >
                          <Button
                            variant="contained"
                            disableRipple
                            disableTouchRipple
                            className={classes.disableHover}
                          >
                            Publish as Campaign
                          </Button>
                        </BootstrapTooltip>
                      )}
                      {validationMessage === "" && !isGuidedToursTab && (
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={validationMessage !== ""}
                          style={{
                            maxHeight: 40,
                            borderRadius: 3,
                            padding: "8px 10px",
                          }}
                          onClick={() => {
                            setState({
                              ...state,
                              showCampaignInfo: false,
                            })
                            setShowPublishConfirmDialog(true)
                          }}
                        >
                          <Typography
                            className={classes.typographyCaption}
                            style={{ letterSpacing: "0.01em", color: "#fff" }}
                          >
                            {pending ? (
                              <Loading size={14} />
                            ) : (
                              "Publish as Campaign"
                            )}
                          </Typography>
                        </Button>
                      )}
                      {completeConfig?.isTestPublished && (
                        <Link
                          to={{
                            pathname: `/apps/${appId}/${getRouteForActionType(
                              actionType,
                            )}/${completeConfig._id}/analysis`,
                            state: { ...completeConfig, statusType },
                          }}
                        >
                          <IconButton
                            color="primary"
                            aria-label="Analysis"
                            style={{
                              border: "1px solid rgb(206, 212, 217)",
                              borderRadius: 3,
                              padding: 6,
                            }}
                            classes={{
                              label: classes.buttonLabel,
                            }}
                            onClick={() => {
                              setState({
                                ...state,
                                showCampaignInfo: false,
                              })
                            }}
                          >
                            <BarChartIcon /> A/B Results
                          </IconButton>
                        </Link>
                      )}
                    </RowDiv>
                  )
                }
              </RowDiv>
            }
            noTitleBg={true}
            dialogContent={
              <>
                <SummaryPage
                  config={completeConfig}
                  cohorts={cohorts}
                  appId={appId}
                  actionType={actionType}
                  designSummaryPage={designSummaryComponent}
                  isGuidedToursTab={isGuidedToursTab}
                />
                {(pending ||
                  progressState.campaign_pause_pending ||
                  progressState.campaign_resume_pending) && (
                  <div
                    style={{
                      position: "fixed",
                      top: "50%",
                      left: "50%",
                    }}
                  >
                    <Loading size={36} />
                  </div>
                )}
              </>
            }
            dialogContentStyles={{
              overflowX: "hidden",
            }}
            maxWidth="lg"
            openFlag={showCampaignInfo}
            handleClose={() =>
              setState({
                ...state,
                showCampaignInfo: false,
              })
            }
            onClose={() =>
              setState({
                ...state,
                showCampaignInfo: false,
              })
            }
            onDialogClose={() =>
              setState({
                ...state,
                showCampaignInfo: false,
              })
            }
          />
          <CustomMaterialUIDialog
            dialogProps={{
              disableBackdropClick: true,
              disableEscapeKeyDown: true,
            }}
            dialogContent={
              <ABVariantManualPublish
                campaignId={campaignConfig._id}
                campaignConfig={completeConfig}
                fromListing={true}
                handleDone={() => {
                  setShowABManualPubishDialog(false)
                  fetchConfig(false, () => {
                    refreshCampaigns()
                  })
                  setProgressState((prev) => ({
                    ...prev,
                    statusMessage: "Campaign published successfully",
                  }))
                }}
                handleCancel={() => setShowABManualPubishDialog(false)}
              />
            }
            dialogContentStyles={{
              overflowX: "hidden",
              padding: "8px 8px",
              paddingTop: 4,
            }}
            maxWidth="xs"
            openFlag={showABManualPubishDialog}
            handleClose={() => setShowABManualPubishDialog(false)}
            onClose={() => setShowABManualPubishDialog(false)}
            onDialogClose={() => setShowABManualPubishDialog(false)}
          />
          <CustomMaterialUIDialog
            dialogProps={{
              disableBackdropClick: true,
              disableEscapeKeyDown: true,
            }}
            title={
              <Typography
                style={{
                  fontSize: "26px",
                  fontWeight: "700",
                  marginLeft: "25px",
                }}
              >
                Confirm Publish
              </Typography>
            }
            noTitleBg={true}
            dialogContent={
              <Box
                style={{
                  padding: "20px",
                  paddingTop: "10px",
                }}
              >
                <Typography color="textPrimary" variant="h5">
                  Are you sure you want to publish the {action_type}{" "}
                  <b>{stateRef.current?.meta?.name}</b>?
                  <br />
                  {isExperienceDashboard() && (
                    <>
                      All active campaigns and surveys will be{" "}
                      <b
                        style={{
                          textTransform: "uppercase",
                        }}
                      >
                        paused
                      </b>
                      .
                    </>
                  )}
                </Typography>
              </Box>
            }
            dialogActions={
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingInline: "30px",
                  paddingBottom: "20px",
                }}
              >
                <Button
                  color="default"
                  size="large"
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                    marginRight: "20px",
                  }}
                  onClick={() => setShowPublishConfirmDialog(false)}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                  onClick={publishCampaign}
                >
                  {publishPending ? <Loading size={14} /> : "Publish"}
                </Button>
              </Box>
            }
            hideCloseIcon={true}
            maxWidth="sm"
            openFlag={showPublishConfirmDialog}
            onClose={() => setShowPublishConfirmDialog(false)}
            onDialogClose={() => setShowPublishConfirmDialog(false)}
          />
          {showResumeConfirmationDialog && !canPause && (
            <CustomMaterialUIDialog
              openFlag={showResumeConfirmationDialog && !canPause}
              onClose={closeResumeConfirmationDialog}
              onDialogClose={closeResumeConfirmationDialog}
              hideCloseIcon={true}
              maxWidth="sm"
              title={
                <Typography
                  style={{
                    fontSize: "26px",
                    fontWeight: "700",
                    marginLeft: "25px",
                  }}
                >
                  Confirm Resume
                </Typography>
              }
              noTitleBg={true}
              dialogContent={
                <Box
                  style={{
                    padding: "20px",
                    paddingTop: "10px",
                  }}
                >
                  <Typography color="textPrimary" variant="h5">
                    Are you sure you want to resume the {action_type}{" "}
                    <b>{stateRef.current?.meta?.name}</b>?
                    <br />
                    {isExperienceDashboard() && (
                      <>
                        All active campaigns and surveys will be{" "}
                        <b
                          style={{
                            textTransform: "uppercase",
                          }}
                        >
                          paused
                        </b>
                        .
                      </>
                    )}
                  </Typography>
                </Box>
              }
              dialogActions={
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingInline: "30px",
                    paddingBottom: "20px",
                  }}
                >
                  <Button
                    color="default"
                    size="large"
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      marginRight: "20px",
                    }}
                    onClick={closeResumeConfirmationDialog}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleToggleAction}
                    size="large"
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Resume
                  </Button>
                </Box>
              }
            />
          )}
          {pending && (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
              }}
            >
              <Loading size={36} />
            </div>
          )}
        </Card>
      </TableCell>
    </TableRow>
  )
}

export default withStyles(styles)(React.memo(CampaignCard))
