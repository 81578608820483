import { apxTheme } from "../../../../../ApplicationFrame/styling/CustomCSS";
import { IconImageComponent } from "../../../../common/utils";
import single_choice from "./assets/single_choice.png";
import multi_choice from "./assets/multi_choice.png";
import rating from "./assets/rating.png";
import text from "./assets/text.png";
import success_message from "./assets/success_message.png";
import request_survey from "./assets/request_survey.png";
import request_footer from "./assets/request_footer.png";
import request_popup from "./assets/request_popup.png";
import rating_full from "./assets/rating_full.png";
import rating_popup from "./assets/rating_popup.png";
import rating_footer from "./assets/rating_footer.png";
import success_full from "./assets/success_full.png";
import text_full from "./assets/text_full.png";
import text_popup from "./assets/text_popup.png";
import text_footer from "./assets/text_footer.png";
import multi_full from "./assets/multi_full.png";
import multi_popup from "./assets/multi_popup.png";
import multi_footer from "./assets/multi_footer.png";
import single_full from "./assets/single_full.png";
import single_popup from "./assets/single_popup.png";
import single_footer from "./assets/single_footer.png";

let qaId = 999;
let choiceId = 5001;
export const getQAId = () => Math.ceil(Math.random() * 100000) + qaId++;
const getChoiceId = () => Math.ceil(Math.random() * 100000) + choiceId++;
export const ON_COPY_STEP = "ON_COPY_STEP";
export const HIDE_CONFIGURATION_TOOLBARS = "HIDE_CONFIGURATION_TOOLBARS";
export const SET_MODULE_TYPE = "SET_MODULE_TYPE";
export const SET_SURVEY_ORIENTATION = "SET_SURVEY_ORIENTATION";
export const SET_SURVEY_RATING_STYLE = "SET_SURVEY_RATING_STYLE";
export const SET_SURVEY_RATING_BUTTONS = "SET_SURVEY_RATING_BUTTONS";
export const ENABLE_DIM_BACKGROUND = "ENABLE_DIM_BACKGROUND";
export const ON_EDIT_SURVEY_STEP = "ON_EDIT_SURVEY_STEP";
export const TOGGLE_RATING_REDIRECTION = "TOGGLE_RATING_REDIRECTION";
export const SET_ACTION_AFTER_RATING = "SET_ACTION_AFTER_RATING";
export const SET_RATING_REDIRECTION_QUESTION =
  "SET_RATING_REDIRECTION_QUESTION";
export const SET_PLAY_STORE_LINK = "SET_PLAY_STORE_LINK";
export const SET_APP_STORE_LINK = "SET_APP_STORE_LINK";
export const ADD_NEW_RULE = "ADD_NEW_RULE";
export const REMOVE_RULE = "REMOVE_RULE";
export const RESET_REACTIONS = "RESET_REACTIONS";
export const SET_RULE_REACTIONS = "SET_RULE_REACTIONS";
export const SET_MIN_RATING = "SET_MIN_RATING";
export const SET_MAX_RATING = "SET_MAX_RATING";
export const SET_FOLLOWUP_QUESTION = "SET_FOLLOWUP_QUESTION";
export const SET_DELAY = "SET_DELAY";
export const SET_SUCCESS_MESSAGE = "SET_SUCCESS_MESSAGE";
export const SHOW_SUCCESS_MESSAGE = "SHOW_SUCCESS_MESSAGE";
export const ENABLE_DIALOG = "ENABLE_DIALOG";
export const SET_BUTTON_TYPE = "SET_BUTTON_TYPE";
export const SET_TITLE_TEXT = "SET_TITLE_TEXT";
export const SET_TITLE_COLOR = "SET_TITLE_COLOR";
export const SET_DESCRIPTION_TEXT = "SET_DESCRIPTION_TEXT";
export const SET_DESCRIPTION_COLOR = "SET_DESCRIPTION_COLOR";
export const SET_RESPONSE_BASED_SURVEY = "SET_RESPONSE_BASED_SURVEY";
export const SET_UI_CONFIG = "SET_UI_CONFIG";
export const SET_QUESTION_TITLE = "SET_QUESTION_TITLE";
export const SET_IS_REQUIRED = "SET_IS_REQUIRED";
export const SET_SUBMIT_TEXT = "SET_SUBMIT_TEXT";
export const SET_RANDOMIZE_CHOICES = "SET_RANDOMIZE_CHOICES";
export const ADD_NEW_QUESTION = "ADD_NEW_QUESTION";
export const REMOVE_QUESTION = "REMOVE_QUESTION";
export const ADD_NEW_CHOICE = "ADD_NEW_CHOICE";
export const REMOVE_CHOICE = "REMOVE_CHOICE";
export const SET_NEXT_QUESTION_ID = "SET_NEXT_QUESTION_ID";
export const SET_CHOICE_TEXT = "SET_CHOICE_TEXT";
export const SET_TXCFG_TEXT_COLOR = "SET_TXCFG_TEXT_COLOR";
export const SET_TXCFG_BACKGROUND_COLOR = "SET_TXCFG_BACKGROUND_COLOR";
export const SET_TXCFG_MAX_CHARS = "SET_TXCFG_MAX_CHARS";
export const SET_TXCFG_PLACEHOLDER = "SET_TXCFG_PLACEHOLDER";
export const SET_TXCFG_PLACEHOLDER_COLOR = "SET_TXCFG_PLACEHOLDER_COLOR";
export const SET_TXCFG_EXAMPLE_TEXT = "SET_TXCFG_EXAMPLE_TEXT";
export const SET_TXCFG_EXAMPLE_TEXT_COLOR = "SET_TXCFG_EXAMPLE_TEXT_COLOR";
export const MOVE_QUESTION = "MOVE_QUESTION";
export const IS_SKIP_ENABLED = "IS_SKIP_ENABLED";
export const IS_CANCEL_ENABLED = "IS_CANCEL_ENABLED";
export const IS_PREVIOUS_ENABLED = "IS_PREVIOUS_ENABLED";
export const IS_PROGRESSBAR_ENABLED = "IS_PROGRESSBAR_ENABLED";
export const SET_QUESTION_TEXT_COLOR = "SET_QUESTION_TEXT_COLOR";
export const SET_QUESTION_BG_COLOR = "SET_QUESTION_BG_COLOR";
export const SET_OPTION_TEXT_ACTIVE_COLOR = "SET_OPTION_TEXT_ACTIVE_COLOR";
export const SET_OPTION_TEXT_INACTIVE_COLOR = "SET_OPTION_TEXT_INACTIVE_COLOR";
export const SET_OPTION_BG_ACTIVE_COLOR = "SET_OPTION_BG_ACTIVE_COLOR";
export const SET_OPTION_BG_INACTIVE_COLOR = "SET_OPTION_BG_INACTIVE_COLOR";
export const SET_SUCCESS_MESSAGE_BG_COLOR = "SET_SUCCESS_MESSAGE_BG_COLOR";
export const SET_SUCCESS_MESSAGE_TEXT_COLOR = "SET_SUCCESS_MESSAGE_TEXT_COLOR";
export const SET_SUCCESS_MESSAGE_ICON_COLOR = "SET_SUCCESS_MESSAGE_ICON_COLOR";
export const SET_SKIP_TEXT_COLOR = "SET_SKIP_TEXT_COLOR";
export const SET_CANCEL_BUTTON_COLOR = "SET_CANCEL_BUTTON_COLOR";
export const SET_PREVIOUS_BUTTON_COLOR = "SET_PREVIOUS_BUTTON_COLOR";
export const SET_PROGRESSBAR_COLOR = "SET_PROGRESSBAR_COLOR";
export const SET_SUBMIT_TEXT_COLOR = "SET_SUBMIT_TEXT_COLOR";
export const SET_SUBMIT_ACTIVE_COLOR = "SET_SUBMIT_ACTIVE_COLOR";
export const SET_SUBMIT_INACTIVE_COLOR = "SET_SUBMIT_INACTIVE_COLOR";
export const SET_YES_TEXT = "SET_YES_TEXT";
export const SET_YES_TEXT_COLOR = "SET_YES_TEXT_COLOR";
export const SET_YES_BG_COLOR = "SET_YES_BG_COLOR";
export const SET_NO_TEXT = "SET_NO_TEXT";
export const SET_NO_TEXT_COLOR = "SET_NO_TEXT_COLOR";
export const SET_NO_BG_COLOR = "SET_NO_BG_COLOR";
export const SET_ICON = "SET_ICON";
export const SET_ICON_TYPE = "SET_ICON_TYPE";
export const SET_DIALOG_BG_COLOR = "SET_DIALOG_BG_COLOR";
export const SET_NEXT_QUESTION_JSON = "SET_NEXT_QUESTION_JSON";
export const ADD_NEXT_QUESTION_TO_SURVEY = "ADD_NEXT_QUESTION_TO_SURVEY";
export const SET_CURRENT_STEP = "SET_CURRENT_STEP";

export const ANSWER_MODES = {
  single_choice: "single_choice",
  multi_choice: "multi_choice",
  rating: "rating",
  text: "text",
  success_message: "success_message",
  request_survey: "request_survey",
};

export const RESPONSE_BASED_ANSWER_MODES = [
  {
    name: "Single Choice",
    value: "single_choice",
  },
  {
    name: "Rating",
    value: "rating",
  },
];

export const ANSWER_MODE_MAPPING = {
  single_choice: "Single Choice",
  multi_choice: "Multi Choice",
  rating: "Rating",
  text: "Text Based",
};

export const ANSWER_MODE_OPTIONS = [
  {
    name: "Single Choice",
    value: "single_choice",
  },
  {
    name: "Multiple Choice",
    value: "multi_choice",
  },
  {
    name: "Rating",
    value: "rating",
  },
  {
    name: "Plain Text",
    value: "text",
  },
];
export const SURVEY_ORIENTATION = {
  FULL_SCREEN: "full_scr",
  POPUP: "center",
  FOOTER: "bottom",
};
export const SURVEY_RATING_STYLE = {
  CLASSIC: "classic",
  STYLIZED: "stylized",
  NUMBER: "numbers",
};

export const RATING_ACTION_LABELS = {
  REDIRECTION: "redirection",
  QUESTION: "question",
};

export const ACTION_AFTER_RATING = [
  {
    label: "Redirect to Play Store",
    value: RATING_ACTION_LABELS.REDIRECTION,
  },
  {
    label: "Provide Further Question",
    value: RATING_ACTION_LABELS.QUESTION,
  },
];

export const MODULE_TYPES = {
  TEXT: "TEXT",
  BUTTON: "BUTTON",
  BACKGROUND: "BACKGROUND",
  RATING: "RATING",
  BUTTON_BACKGROUND: "BUTTON_BACKGROUND",
};

export const EMPTY_CHOICE = (is_other = false, value = "") => ({
  id: getChoiceId(),
  value: is_other ? "Other" : value,
  is_other,
  next_question_id: -1,
});

export const EMPTY_QUESTION = () => ({
  id: getQAId(),
  answer_mode: ANSWER_MODES.single_choice,
  title: "",
  is_required: false,
  randomize_choices: false,
  submit_text: "Next >",
  choices: [
    EMPTY_CHOICE(false, "Option 1"),
    EMPTY_CHOICE(false, "Option 2"),
    EMPTY_CHOICE(false, "Option 3"),
    EMPTY_CHOICE(false, "Option 4"),
  ],
});

export const DEFAULT_TEXT_CONFIG = {
  id: getChoiceId(),
  background_color: "#f1f0f0",
  example: "eg : UI improvements (Example)",
  example_text_color: "#000000",
  max_characters: 150,
  placeholder: "Placeholder",
  placeholder_text_color: "#000000",
  text_color: "#000000",
};

export const PALETTE = apxTheme.palette;

/**
 * For success message and dialog adding `id` and `title` to satisfy server contract only, irrelevant in the frontend.
 */
export function getSuccessMessageData(styles) {
  return {
    type: ANSWER_MODES.success_message,
    data: {
      id: getQAId(),
      title: "",
      answer_mode: ANSWER_MODES.success_message,
    },
    image: {
      [SURVEY_ORIENTATION.FULL_SCREEN]: success_full,
    },
    display_text: "Success Message",
    logo: () => IconImageComponent(success_message, "success_message", styles),
  };
}

export function getDialogData(styles) {
  return {
    type: ANSWER_MODES.request_survey,
    data: {
      id: getQAId(),
      title: "",
      answer_mode: ANSWER_MODES.request_survey,
    },
    image: {
      [SURVEY_ORIENTATION.FOOTER]: request_footer,
      [SURVEY_ORIENTATION.POPUP]: request_popup,
    },
    display_text: "Request for Survey",
    logo: () => IconImageComponent(request_survey, "request_survey"),
  };
}

export const selectedColor = (
  selected,
  defaultColor = apxTheme.palette.primary.main
) => (selected ? "#FFFFFF" : defaultColor);

export const QUESTION_TYPES = () => {
  const question = EMPTY_QUESTION();
  const styles = {
    width: "auto",
    height: 40,
    marginRight: 10,
    marginLeft: 18,
  };
  return {
    [ANSWER_MODES.single_choice]: {
      type: ANSWER_MODES.single_choice,
      data: {
        ...question,
      },
      display_text: "Single Choice",
      image: {
        [SURVEY_ORIENTATION.FOOTER]: single_footer,
        [SURVEY_ORIENTATION.POPUP]: single_popup,
        [SURVEY_ORIENTATION.FULL_SCREEN]: single_full,
      },
      logo: () => IconImageComponent(single_choice, "single_choice", styles),
    },
    [ANSWER_MODES.multi_choice]: {
      type: ANSWER_MODES.multi_choice,
      data: {
        ...question,
        answer_mode: ANSWER_MODES.multi_choice,
      },
      image: {
        [SURVEY_ORIENTATION.FOOTER]: multi_footer,
        [SURVEY_ORIENTATION.POPUP]: multi_popup,
        [SURVEY_ORIENTATION.FULL_SCREEN]: multi_full,
      },
      display_text: "Multi Choice",
      logo: () => IconImageComponent(multi_choice, "multi_choice", styles),
    },
    [ANSWER_MODES.rating]: {
      type: ANSWER_MODES.rating,
      data: {
        ...question,
        answer_mode: ANSWER_MODES.rating,
        choices: [],
        rating_style: "classic",
        rating_scale: 5,
        advanced_options: false,
      },
      image: {
        [SURVEY_ORIENTATION.FOOTER]: rating_footer,
        [SURVEY_ORIENTATION.POPUP]: rating_popup,
        [SURVEY_ORIENTATION.FULL_SCREEN]: rating_full,
      },
      display_text: "Rating",
      logo: () => IconImageComponent(rating, "rating", styles),
    },
    [ANSWER_MODES.success_message]: getSuccessMessageData(styles),
    [ANSWER_MODES.text]: {
      type: ANSWER_MODES.text,
      data: {
        ...question,
        answer_mode: ANSWER_MODES.text,
        choices: [],
        text_config: DEFAULT_TEXT_CONFIG,
      },
      image: {
        [SURVEY_ORIENTATION.FOOTER]: text_footer,
        [SURVEY_ORIENTATION.POPUP]: text_popup,
        [SURVEY_ORIENTATION.FULL_SCREEN]: text_full,
      },
      display_text: "Text Based",
      logo: () => IconImageComponent(text, "text", styles),
    },
    [ANSWER_MODES.request_survey]: getDialogData(styles),
  };
};

export const getLogo = answer_mode => {
  const styles = {
    width: "auto",
    height: 40,
    marginRight: 10,
    marginLeft: 18,
  };

  switch (answer_mode) {
    case ANSWER_MODES.text:
      return {
        image: IconImageComponent(text, "text", styles),
        name: "text",
      };
    case ANSWER_MODES.rating:
      return {
        image: IconImageComponent(rating, "rating", styles),
        name: "rating",
      };
    case ANSWER_MODES.multi_choice:
      return {
        image: IconImageComponent(multi_choice, "multi_choice", styles),
        name: "multi_choice",
      };
    case ANSWER_MODES.success_message:
      return {
        image: IconImageComponent(success_message, "success_message", styles),
        name: "success_message",
      };
    case ANSWER_MODES.request_survey:
      return {
        image: IconImageComponent(request_survey, "request_survey", styles),
        name: "request_survey",
      };
    default:
      return {
        image: IconImageComponent(single_choice, "single_choice", styles),
        name: "single_choice",
      };
  }
};

export const SURVEY_MODULE_TYPES = {
  QUESTION: "QUESTION",
  RADIO_OPTION: "RADIO_OPTION",
  CHECKBOX_OPTION: "CHECKBOX_OPTION ",
  IMAGE_URL: "IMAGE_URL",
  BACKGROUND: "BACKGROUND",
  BUTTON: "BUTTON_",
  BUTTON_TEXT: "BUTTON_TEXT",
  BUTTON1: "BUTTON_1",
  BUTTON2: "BUTTON_2",
  BUTTON_TEXT1: "BUTTON_TEXT1",
  BUTTON_TEXT2: "BUTTON_TEXT2",
};
