import {
  Button,
  Portal,
  Snackbar,
  Tab,
  Tabs,
  withStyles,
} from "@material-ui/core";
import React, { useState, useReducer, useEffect } from "react";
import ActionsTextField from "../../../../../../../../../ReusableComponents/ActionsTextField";
import Parser from "../../../../../../../../../../utils/ApxorDSL/parser";
import ViewIDPathSelection from "../../../common/ViewIDPathSelection";
import SnippetDialogContent from "./components/SnippetDialogContent";
import { Row } from "./components/VariableRow";
import { reducer } from "./reducer";
import { useTrackedState } from "../../../../store";
import RowDiv from "../../../../../../../../../ReusableComponents/RowDiv";
import { Alert } from "@material-ui/lab";
import ColumnDiv from "../../../../../../../../../ReusableComponents/ColumnDiv";

const IDENTIFIER_REGEX = /\{[a-zA-Z0-9_]+\}/g;

const styles = (theme) => ({
  content: {
    padding: "25px 10%",
    height: "auto",
  },
  tabsRoot: {
    background: "#fff",
    flex: "1 0 auto",
  },
  tabsIndicator: {
    height: 3,
    backgroundColor: "#FF7F33",
  },
  tabRoot: {
    textTransform: "initial",
    minWidth: 72,
    fontSize: 14,
    color: "rgba(0,40,69, 60%)",
    fontWeight: "500 !important",
    marginRight: theme.spacing(4),
    "&:focus": {
      // color: '#40a9ff',
    },
  },
  selected: {
    color: "#002845",
  },
  textColorPrimary: {
    color: "#002845",
  },
});

const DIALOG_CONTENT_TABS = {
  TEXT: "Text",
  SCRIPT: "Script",
};

function DynamicTextDialogContent({
  classes,
  selectedTab: propSelectedTab,
  originalScript,
  variableMap,
  onSuccessfulParse,
  onDone: onSave,
  onCancel,
  hideRemove = false,
}) {
  const [caretPosition, setCaretPosition] = useState(0);
  const [script, setScript] = useState(originalScript);
  const [selectedTab, setSelectedTab] = useState(propSelectedTab);
  const [showViewSelectionPopup, setShowViewSelectionPopup] = useState(false);
  const [viewSelectionId, setViewSelectionId] = useState(null);
  const [variableState, dispatch] = useReducer(reducer, {
    variables: Object.keys(variableMap).reduce((acc, variableName) => {
      acc.push({ name: variableName, ...variableMap[variableName] });
      return acc;
    }, []),
    currentVariable: {},
  });
  const [scriptError, setScriptError] = useState(null);
  const [compiling, setCompiling] = useState(false);
  const [compileSuccess, setCompileSuccess] = useState(null);
  const [error, setError] = useState(null);

  const designPageState = useTrackedState();
  const { isOnboardingMode } = designPageState;

  useEffect(() => {
    window.apx_variables = Object.keys(variableMap ?? {});
    return () => {
      delete window.apx_variables;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parseAndNotify = (
    tab,
    variables,
    data,
    originalScript,
    callback = null
  ) => {
    setCompiling(true);
    const parser = new Parser();

    setTimeout(() => {
      try {
        const commands = parser.parse(data, variables, !isOnboardingMode);
        const vmap = variableState.variables.reduce((acc, item) => {
          const { name, ...others } = item;
          const variableName = name;
          acc[variableName] = {
            ...others,
          };
          return acc;
        }, {});
        onSuccessfulParse(tab, "s", commands, originalScript, vmap);

        if (callback) {
          callback(tab, "s", commands, originalScript, vmap);
        }
        setCompiling(false);
        setCompileSuccess(true);
        setTimeout(() => {
          setCompileSuccess(false);
        }, 1000);
      } catch (e) {
        let message = e.toString().replace("Error: ", "");
        if (message.startsWith("API calls")) {
          message = message.replace("API calls", "[Onboarding Mode] API calls");
        }
        setScriptError(message.replace("  ", " "));
        setCompiling(false);
        setCompileSuccess(false);
      }
    }, 1000);
  };

  const validateScriptTab = (callback = null) => {
    setScriptError(null);
    const variables = variableState.variables.reduce((acc, item) => {
      acc.push(item.name);
      return acc;
    }, []);
    if (
      variableState.currentVariable &&
      variableState.currentVariable.name &&
      variableState.currentVariable.name !== ""
    ) {
      variables.push(variableState.currentVariable.name);
    }
    parseAndNotify(1, variables, script, script, callback);
  };

  const handleTextTab = () => {
    const variables = [];
    let tempScript = script;
    let reResult = IDENTIFIER_REGEX.exec(tempScript);
    while (reResult !== null) {
      variables.push(reResult[0].substring(1, reResult[0].length - 1));
      tempScript = tempScript.replace(reResult[0], "{}");
      reResult = IDENTIFIER_REGEX.exec(tempScript);
    }
    const constructedScript =
      'format("' + tempScript + '", ' + variables.join(", ") + ")";
    parseAndNotify(
      0,
      variables,
      constructedScript,
      script,
      (tab, type, cmds, script, vmap) => {
        if (onSave) {
          onSave(tab, type, cmds, script, vmap);
        }
      }
    );
  };

  const handleSave = () => {
    if (selectedTab === 1) {
      validateScriptTab((tab, type, cmds, script, vmap) => {
        setTimeout(() => {
          if (onSave) {
            onSave(tab, type, cmds, script, vmap);
          }
        }, 1000);
      });
    } else {
      handleTextTab();
    }
  };

  return (
    <>
      <div style={{ marginTop: 16 }}>
        {!showViewSelectionPopup && (
          <>
            {variableState.variables.map((eachVariable, index) => (
              <React.Fragment key={`${eachVariable.name} ${index}`}>
                <Row
                  id={eachVariable.id}
                  classes={classes}
                  {...eachVariable}
                  selectedTab={selectedTab}
                  dispatch={dispatch}
                  variables={variableState.variables}
                  handleViewSelection={(id) => {
                    setViewSelectionId(id);
                    setShowViewSelectionPopup(true);
                  }}
                  deleteVariable={(variableName) =>
                    setScript(script.replaceAll("{" + variableName + "}", ""))
                  }
                />
              </React.Fragment>
            ))}
            <Row
              classes={classes}
              id="current"
              {...variableState.currentVariable}
              dispatch={dispatch}
              handleViewSelection={(_) => {
                setViewSelectionId("current");
                setShowViewSelectionPopup(true);
              }}
              isLast={true}
              selectedTab={selectedTab}
              variables={variableState.variables}
              showError={(message) => setError(message)}
              insertVariable={(variableName) => {
                const updatedData =
                  script.slice(0, caretPosition) +
                  "{" +
                  variableName +
                  "}" +
                  script.slice(caretPosition);
                setScript(updatedData);
                setCaretPosition(updatedData.length);
              }}
            />
            <Tabs
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator,
              }}
              value={selectedTab}
              onChange={(e, value) => {
                setSelectedTab(value);
                setScript("");
              }}
            >
              {Object.keys(DIALOG_CONTENT_TABS).map((tab, index) => (
                <Tab
                  disableRipple
                  key={tab}
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.selected,
                    textColorPrimary: classes.textColorPrimary,
                  }}
                  label={DIALOG_CONTENT_TABS[tab]}
                />
              ))}
            </Tabs>
            {selectedTab === 0 && (
              <ActionsTextField
                value={script}
                InputProps={{ min: 1, max: 25 }}
                onChange={(e) => setScript(e.target.value)}
                onSelect={(position) => setCaretPosition(position)}
                type={"text"}
                variant="outlined"
                multiline={false}
                textFieldStyle={{ width: "100%", height: "210px" }}
              />
            )}
            {selectedTab === 1 && (
              <ColumnDiv>
                <SnippetDialogContent
                  value={script || ""}
                  onChange={(data) => setScript(data)}
                  onKeyDown={(e) => {
                    if (selectedTab === 0 || !script || script.length < 1) {
                      return;
                    }
                    const charCode = String.fromCharCode(e.which).toLowerCase();
                    if (e.ctrlKey && charCode === "c") {
                      validateScriptTab();
                    }
                  }}
                />
                <RowDiv center styles={{ marginBottom: 10, width: "100%" }}>
                  {scriptError && (
                    <Alert severity="error" style={{ width: "100%" }}>
                      {scriptError}
                    </Alert>
                  )}
                  {compiling && (
                    <Alert severity="info" style={{ width: "100%" }}>
                      Compiling your code...
                    </Alert>
                  )}
                  {compileSuccess && (
                    <Alert severity="success" style={{ width: "100%" }}>
                      Valid Code, 0 errors
                    </Alert>
                  )}
                </RowDiv>
              </ColumnDiv>
            )}
          </>
        )}
        {showViewSelectionPopup && (
          <ViewIDPathSelection
            allowWebViewCheck={false}
            handleClose={() => setShowViewSelectionPopup(false)}
            handleProceed={(data) => {
              setShowViewSelectionPopup(false);
              if (viewSelectionId) {
                dispatch({
                  type: "SET_PROPERTY",
                  id: viewSelectionId,
                  key: "st",
                  value: data.searchType ?? "",
                });
                dispatch({
                  type: "SET_PROPERTY",
                  id: viewSelectionId,
                  key: "n",
                  value: data.searchType === "" ? data.viewId : data.path,
                });
              }
            }}
            saveImages={false}
          />
        )}
      </div>
      {!showViewSelectionPopup && (
        <RowDiv
          styles={{
            justifyContent: hideRemove ? "flex-end" : "space-between",
            marginBottom: 14,
          }}
        >
          {!hideRemove && (
            <Button
              onClick={() => onSave && onSave(selectedTab, "", "", "", null)}
              style={{ background: "#CCD4DA" }}
            >
              Remove Dynamic Text & Close
            </Button>
          )}
          <RowDiv
            styles={{ justifyContent: "flex-end", alignItems: "flex-end" }}
          >
            <Button onClick={() => onCancel && onCancel()} color="primary">
              Cancel
            </Button>
            <Button
              disabled={script.length < 1 || compiling}
              onClick={() => handleSave()}
              color="primary"
              style={{
                backgroundColor: !(script.length < 1 || compiling)
                  ? "#FF7F33"
                  : "#ffbc95",
                color: "#FFFFFF",
              }}
            >
              {compiling ? "Compiling" : "Save"}
            </Button>
          </RowDiv>
        </RowDiv>
      )}
      {error && (
        <Portal>
          <Snackbar
            autoHideDuration={5000}
            open={true}
            onClose={(_, reason) => {
              if (reason !== "clickaway") {
                setError(null);
              }
            }}
            message={error}
          />
        </Portal>
      )}
    </>
  );
}

export default withStyles(styles)(DynamicTextDialogContent);
