/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 **
 * Created by Rakesh Peela
 * Date: 08-Sep-2019
 * Time: 10:37 AM
 */

import { formatTime } from "../../../../utils";
import {
  makePostRequest,
  makeGetRequest,
  makeDeleteRequest,
} from "../../../common/actions";

const CORRELATIONS_TIMESERIES_API = "time-series";
const CORRELATIONS_RETENTION_API = "total-user-retention";
const CORRELATIONS_UNINSTALL_API = "total-uninstall-timeseries";
const CORRELATIONS_LIST_API = "segments";
const SAVE_SEGMENT_API = "segmentation";
const SEGMENT_COUNT_API = "segmentation/count";

const GET_COHORTS_API = "cohorts";
const CREATE_COHORTS_API = "cohorts/create";
const UPDATE_COHORT_API = "cohorts/update";

function getSegmentURL(segmentId, property) {
  return "segmentation/" + segmentId + "/" + property;
}

function makeRetentionAPI(segmentId) {
  return "segmentation/" + segmentId + "/retention";
}

function getCountAPI(segmentId) {
  return "segmentation/" + segmentId + "/count";
}

function getUninstallAPI(segmentId) {
  return "segmentation/" + segmentId + "/uninstall";
}

function getDeleteSegmentAPI(segmentId) {
  return "segments/" + segmentId;
}

const COUNT_API = "count";
const TIME_SERIES_API = "time-series";
const DISTRIBUTION_API = "distribution";

const beautifyRetentionBarModel = (d) => {
  for (let key in d) {
    if (d.hasOwnProperty(key)) {
      if (key !== "date") {
        d[key.replace("day", "Day ")] = d[key];
        delete d[key];
      } else {
        d[key] = formatTime(d[key], "MMM Do");
      }
    }
  }
  return d;
};

/**
 *
 * @param appId
 * @param auth
 * @param segmentId
 * @param queryParams
 * @param body
 * @returns {Promise}
 */
export function getSegmentTimeSeriesAPI(
  auth,
  appId,
  segmentId,
  queryParams,
  body
) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return [];
  };
  return makePostRequest(
    getSegmentURL(segmentId, CORRELATIONS_TIMESERIES_API),
    auth,
    appId,
    queryParams,
    body,
    onSuccessCallback,
    onFailureCallback
  );
}

/**
 *
 * @param appId
 * @param auth
 * @param segmentId
 * @param queryParams
 * @param body
 * @returns {Promise}
 */
export function getSegmentRetentionAPI(
  auth,
  appId,
  segmentId,
  queryParams,
  body
) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    makeRetentionAPI(segmentId),
    auth,
    appId,
    queryParams,
    body,
    onSuccessCallback,
    onFailureCallback
  );
}

const genericSuccessCallback = (json) => {
  return json
    .sort((a, b) => a.date.localeCompare(b.date))
    .map(beautifyRetentionBarModel);
};
/**
 *
 * @param appId
 * @param auth
 * @param queryParams
 * @param body
 * @returns {Promise}
 */
export function getCorrelationsRetentionAPI(appId, auth, queryParams, body) {
  const onSuccessCallback = genericSuccessCallback;
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    CORRELATIONS_RETENTION_API,
    auth,
    appId,
    queryParams,
    body,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getCorrelationsUninstallAPI(appId, auth, queryParams, body) {
  const onSuccessCallback = genericSuccessCallback;
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    CORRELATIONS_UNINSTALL_API,
    auth,
    appId,
    queryParams,
    body,
    onSuccessCallback,
    onFailureCallback
  );
}

/**
 *
 * @param appId
 * @param auth
 * @param segmentId
 * @param queryParams
 * @param body
 * @returns {Promise}
 */
export function getCorrelationsSegmentUninstallAPI(
  appId,
  auth,
  segmentId,
  queryParams,
  body
) {
  const onSuccessCallback = genericSuccessCallback;
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    getUninstallAPI(segmentId),
    auth,
    appId,
    queryParams,
    body,
    onSuccessCallback,
    onFailureCallback
  );
}

/**
 *
 * @param appId
 * @param auth
 * @param queryParams
 * @returns {Promise}
 */
export function getSegmentsList(auth, appId, queryParams) {
  const onSuccessCallback = (json) => {
    return Array.isArray(json) ? json.reverse() : [];
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makeGetRequest(
    CORRELATIONS_LIST_API,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getSegmentIdCount(auth, appId, segmentId, queryParams, body) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    getCountAPI(segmentId),
    auth,
    appId,
    queryParams,
    body,
    onSuccessCallback,
    onFailureCallback
  );
}

export function deleteSegment(auth, appId, segmentId) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makeDeleteRequest(
    getDeleteSegmentAPI(segmentId),
    auth,
    appId,
    null,
    onSuccessCallback,
    onFailureCallback
  );
}

/**
 * New APIs
 */

export function segmentCount(appId, auth, segmentId, queryParams, body) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    getSegmentURL(segmentId, COUNT_API),
    auth,
    appId,
    queryParams,
    body,
    onSuccessCallback,
    onFailureCallback
  );
}

export function segmentTimeSeries(appId, auth, segmentId, queryParams, body) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    getSegmentURL(segmentId, TIME_SERIES_API),
    auth,
    appId,
    queryParams,
    body,
    onSuccessCallback,
    onFailureCallback
  );
}

export function createCohortFromSegment(
  auth,
  appId,
  segmentId,
  cohortSince,
  cohortTill
) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    CREATE_COHORTS_API,
    auth,
    appId,
    {
      segmentId,
      since: cohortSince,
      till: cohortTill,
    },
    {},
    onSuccessCallback,
    onFailureCallback
  );
}

export function updateCohort(auth, appId, cohortId, since, till) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    UPDATE_COHORT_API,
    auth,
    appId,
    { cohortId, since, till },
    {},
    onSuccessCallback,
    onFailureCallback
  );
}

export function getCohorts(auth, appId) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makeGetRequest(
    GET_COHORTS_API,
    auth,
    appId,
    null,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getSegmentCountAPI(auth, appId, queryParams, body) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    SEGMENT_COUNT_API,
    auth,
    appId,
    queryParams,
    body,
    onSuccessCallback,
    onFailureCallback
  );
}

export function saveSegmentAPI(auth, appId, queryParams, body) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    SAVE_SEGMENT_API,
    auth,
    appId,
    queryParams,
    body,
    onSuccessCallback,
    onFailureCallback
  );
}

export function segmentAttributeDistributionAPI(
  auth,
  appId,
  segmentId,
  queryParams,
  body
) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    getSegmentURL(segmentId, DISTRIBUTION_API),
    auth,
    appId,
    queryParams,
    body,
    onSuccessCallback,
    onFailureCallback
  );
}
