import React, { useState } from "react";
import DynamicTextDialogContent from "../components/dynamicTextv2";
import CustomMaterialUIDialog from "../../../../../../../../ReusableComponents/CustomMaterialUIDialog";

export default function DynamicText2Dialog({
  openFlag,
  onSuccess,
  onFail,
  dynamicType,
  tab,
  originalScript: propScript,
  variableMap: propVariableMap,
  hideRemove = false,
}) {
  const [type, setType] = useState(dynamicType ?? "");
  const [selectedTab, setSelectedTab] = useState(tab);
  const [originalScript, setOriginalScript] = useState(propScript ?? "");
  const [commands, setCommands] = useState("");
  const [variableMap, setVariableMap] = useState(propVariableMap ?? null);

  const handleDone = (
    tab = selectedTab,
    dType = type,
    cmds = commands,
    script = originalScript,
    vmap = variableMap
  ) => {
    if (onSuccess) {
      onSuccess(tab, dType, cmds, script, vmap);
    }
  };

  return (
    <CustomMaterialUIDialog
      dialogProps={{
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
      }}
      dialogContent={
        <DynamicTextDialogContent
          hideRemove={hideRemove}
          originalScript={originalScript}
          variableMap={variableMap}
          selectedTab={selectedTab}
          onSuccessfulParse={(
            selectedTab,
            type,
            commands,
            originalScript,
            variableMap
          ) => {
            setType(type);
            setSelectedTab(selectedTab);
            setCommands(commands);
            setOriginalScript(originalScript);
            setVariableMap(variableMap);
          }}
          onDone={handleDone}
          onCancel={() => onFail && onFail()}
        />
      }
      dialogActionStyles={{
        borderRadius: 3,
        boxShadow: "0px 0px 10px 1px rgba(1, 1, 1, 0.6)",
      }}
      maxWidth="md"
      openFlag={openFlag}
      onClose={onFail}
      title="Dynamic text"
      titleVariant="h4"
      noTitleBg={true}
      onDialogClose={() => onFail && onFail()}
    />
  );
}
