import { makePatchRequest } from "../common/actions";

export function saveBundleIdAPI(auth, appId, bundleId) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return [];
  };
  return makePatchRequest(
    `/apps/${appId}/bundle-id`,
    auth,
    appId,
    { bundleId },
    null,
    onSuccessCallback,
    onFailureCallback,
  );
}
