import React from "react";
import ReactCohortGraph from "react-cohort-graph";
import moment from "moment";
import { RetentionTrend } from "./RetentionTrend";
import { useTrackedState } from "../store";

const formattedValue = (value) => {
  if (value.split(" ")[1]) return value.split(" ")[1].toLowerCase();
  else return `${value.toLowerCase()}s`;
};

const isCellCompleted = (date, index, dayWindow) => {
  return moment().diff(moment(date).add(dayWindow * index, "days")) < 0
    ? " *"
    : "";
};

const getLabelText = (dataIndex, windowSize, timeframeWindow) => {
  if (windowSize > 1) {
    const upperLimit = dataIndex * windowSize + windowSize - 1;
    // return (dataIndex * windowSize) + "-" + (timeframeWindow < upperLimit ? timeframeWindow : upperLimit);
    return dataIndex * windowSize + "-" + upperLimit;
  }
  return dataIndex;
};

const formatDate = (date, dateDisplayFormat, inputDateFormat = null) => {
  if (!Date.parse(date)) return date;
  return inputDateFormat
    ? moment(date, inputDateFormat).format(dateDisplayFormat)
    : moment(date).format(dateDisplayFormat);
};

const formatter = (
  cellData,
  dayWindow,
  dateDisplayFormat,
  timeframeWindow,
  retention_header
) => {
  const {
    isHeader,
    isLabel,
    isCell,
    index,
    label,
    valueType,
    isDate,
    valueFor,
    isHeaderValue,
    value,
    isTotal,
    isFixed,
  } = cellData;

  if (isHeader && !isHeaderValue) {
    if (isHeader && isFixed) {
      return "Users";
    }
    if (dayWindow > 1) {
      const [first, index] = label.split(" ");
      if (index) {
        const numIndex = Number(index);
        return (
          `${first} ` + getLabelText(numIndex - 1, dayWindow, timeframeWindow)
        );
      }
      return first;
    }
    const [first, index] = label.split(" ");
    return first + " " + (Number(index) - 1);
  }

  if (isDate) {
    return formatDate(valueFor, dateDisplayFormat);
  }

  if (isHeader && isHeaderValue) {
    if (isFixed) {
      return cellData["value"];
    }
    if (index === retention_header.length) {
      return "NA";
    }
    const getLeast = (a, b) => {
      return a < b ? a : b;
    };
    let starFixedValue = 0;
    if (retention_header[index - 1] || retention_header.length < index - 1) {
      starFixedValue = getLeast(
        cellData["value"],
        retention_header[index - 1]["value"]
      );
      const starFixedFinalResult =
        index === retention_header.length && starFixedValue === 0
          ? "NA"
          : starFixedValue + (starFixedValue < cellData["value"] ? "+" : "");
      if (valueType === "percent") {
        return (
          retention_header[index - 1]["percent"] +
          ` % (${starFixedFinalResult})`
        );
      } else {
        return starFixedFinalResult;
      }
    }
    return "NA";
  }

  if (isCell) {
    return (
      cellData[valueType] +
      (valueType === "percent" ? " %" : "") +
      isCellCompleted(valueFor, index, dayWindow)
    );
  }

  if (cellData["index"] === 0 && !isLabel) {
    return cellData["value"] + isCellCompleted(valueFor, index, dayWindow);
  }

  if (isTotal) {
    return value;
  }
};

export const RetentionTableBody = ({ dateFilters }) => {
  const [state] = useTrackedState();

  const {
    dataDisplayFormat = "percent",
    retention_header,
    switchValue,
    windowSize,
    dateDisplayFormat,
    overallRetention,
    retentionData,
  } = state;
  let dataType = formattedValue(switchValue);
  const timeframeWindow = moment(dateFilters.till).diff(
    moment(dateFilters.since),
    "days"
  );

  if (!retention_header || Object.keys(retention_header).length <= 0)
    return <></>;

  return (
    <>
      <ReactCohortGraph
        data={retentionData}
        valueType={dataDisplayFormat}
        dataType={dataType}
        cellFormatter={(cellData) => {
          return formatter(
            cellData,
            windowSize || 1,
            dateDisplayFormat,
            timeframeWindow,
            retention_header
          );
        }}
        headerCellStyles={{
          borderColor: "#FFFFFF",
        }}
        bodyCellStyles={{
          padding: "15px 30px",
          borderColor: "#FFFFFF",
          textTransform: "uppercase",
        }}
        shadeColor="#8E8FBF"
        tableStyles={{
          borderLeft: "none",
        }}
        tableHeadingStyles={{
          border: "none",
        }}
        showHeaderValues
      >
        <RetentionTrend
          overallRetention={overallRetention}
          windowSize={windowSize}
          timeframeWindow={timeframeWindow}
        />
      </ReactCohortGraph>
    </>
  );
};
