import React, { useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ColumnDiv from "../../../../../../../../../../../ReusableComponents/ColumnDiv";
import CustomPopper from "../../../../../../../../../../../ReusableComponents/CustomPopper";
import RowDiv from "../../../../../../../../../../../ReusableComponents/RowDiv";
import TruncatedText from "../../../../../../../../../../../ReusableComponents/TruncatedText";
import {
  APP_PLATFORMS,
  SET_TOGGLE_APP_VERSION_FILTER,
  SET_VERSION_OPERATOR,
  SET_VERSION_VALUE,
} from "../../../../../../../constants";
import { NUMBER_OPERATOR_OPTIONS } from "../../../../../../constants";
import OperatorSelector from "../../../../../../OperatorSelector";
import { getOperatorString } from "../../../../../../utils";
import { useTracked } from "../../../../../../../store";
import { useCommonConfigStyles } from "../../../../../../../utils";
import { isExperienceDashboard } from "../../../../../../../../../../../../config";

const useStyles = makeStyles((theme) => ({
  closeIcon: (props) => ({
    color: "#A1ADB6",
    padding: 2,
    "&:hover": {
      background: "none",
    },
  }),
  expansionSymmaryContent: (props) => ({
    marginLeft: "15px !important",
  }),
  input: (props) => ({
    maxHeight: 34,
  }),
}));

export default function ByAppVersion() {
  const classes = useStyles();
  const commonClasses = useCommonConfigStyles();

  const [state, dispatch] = useTracked();
  const {
    config: {
      audience: { hasVer = false, ver = {} },
      meta: { platform },
    },
  } = state;

  const [versionValue, setVersionValue] = useState(
    hasVer && ver.val ? ver.val : ""
  );
  const [operatorAnchorEl, setOperatorAnchorEl] = useState(null);

  return (
    <ColumnDiv>
      <FormControlLabel
        disabled={isExperienceDashboard()}
        label={
          <Typography className={commonClasses.typographyCaption}>
            By App Version
          </Typography>
        }
        control={
          <Checkbox
            color="primary"
            checked={hasVer}
            onChange={(e) =>
              dispatch({
                type: SET_TOGGLE_APP_VERSION_FILTER,
                enabled: e.target.checked,
                value: versionValue,
              })
            }
          />
        }
      />
      {hasVer && (
        <RowDiv styles={{ marginLeft: 40 }}>
          <RowDiv styles={{ borderRadius: 3, width: "100%" }}>
            <div
              style={{
                width: 30,
                height: 25,
                borderBottom: "1px solid #C5CDD2",
                borderLeft: "1px solid #C5CDD2",
              }}
            ></div>
            <Grid container spacing={1}>
              <Grid item xs={11}>
                <Grid
                  container
                  spacing={1}
                  style={{
                    background: "#F0F2F3",
                    alignItems: "center",
                  }}
                >
                  <Grid item style={{ marginLeft: 10 }}>
                    <Typography
                      variant="subtitle2"
                      style={{
                        fontWeight: 600,
                        alignSelf: "center",
                      }}
                    >
                      App Version
                    </Typography>
                  </Grid>
                  <Grid item style={{ marginBottom: -4 }}>
                    <TruncatedText
                      value={getOperatorString(ver.op)}
                      onClick={(e) => e && setOperatorAnchorEl(e.currentTarget)}
                    />
                    <CustomPopper
                      anchorEl={operatorAnchorEl}
                      setAnchorEl={setOperatorAnchorEl}
                      paperClass={classes.paperClass}
                    >
                      <OperatorSelector
                        selected={ver.op}
                        options={NUMBER_OPERATOR_OPTIONS}
                        handleOnChange={(value) =>
                          dispatch({
                            type: SET_VERSION_OPERATOR,
                            value,
                          })
                        }
                      />
                    </CustomPopper>
                  </Grid>
                  <Grid item style={{ marginBottom: -4 }}>
                    {platform === APP_PLATFORMS.android && (
                      <TextField
                        type="number"
                        required
                        placeholder="Enter version code"
                        value={versionValue || ""}
                        style={{
                          marginTop: -4,
                        }}
                        multiline={false}
                        InputProps={{
                          min: 1,
                          className: classes.input,
                        }}
                        inputProps={{
                          min: 1,
                          className: classes.input,
                        }}
                        onChange={(e) => {
                          const { value } = e.target;
                          setVersionValue(Number(value));
                          dispatch({
                            type: SET_VERSION_VALUE,
                            value: Number(value),
                          });
                        }}
                      />
                    )}
                    {platform === APP_PLATFORMS.ios && (
                      <TextField
                        required
                        placeholder="Version string like 1.2.3"
                        value={versionValue || ""}
                        style={{
                          marginTop: -4,
                        }}
                        onChange={(e) => {
                          const { value } = e.target;
                          setVersionValue(value);
                          dispatch({
                            type: SET_VERSION_VALUE,
                            value: value,
                          });
                        }}
                        InputProps={{
                          className: classes.input,
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={1}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <IconButton
                  onClick={() =>
                    dispatch({
                      type: SET_TOGGLE_APP_VERSION_FILTER,
                      enabled: false,
                    })
                  }
                  className={classes.closeIcon}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </RowDiv>
        </RowDiv>
      )}
    </ColumnDiv>
  );
}


