import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import { MODAL_TYPES } from "../constants";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: 4,
  },
}));

export default function EventMenu({
  canEdit = true,
  canDelete = true,
  canConvert = true,
  handleDialog,
  onClose,
}) {
  const classes = useStyles();
  return (
    <MenuList>
      {canEdit && (
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            handleDialog(true, MODAL_TYPES.EDIT_EVENT_TYPE);
            onClose();
          }}
        >
          <ListItemIcon className={classes.icon}>
            <EditIcon />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.primary }}
            inset
            primary="Edit"
          />
        </MenuItem>
      )}
      {canConvert && (
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            handleDialog(true, MODAL_TYPES.CONVERT_EVENT_TYPE);
            onClose();
          }}
        >
          <ListItemIcon className={classes.icon}>
            <SwapHorizIcon />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.primary }}
            inset
            primary="Convert"
          />
        </MenuItem>
      )}
      {canDelete && (
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            handleDialog(true, MODAL_TYPES.DELETE_EVENT_TYPE);
            onClose();
          }}
        >
          <ListItemIcon className={classes.icon}>
            <DeleteIcon color={"error"} />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.primary }}
            inset
            primary="Delete"
          />
        </MenuItem>
      )}
    </MenuList>
  );
}
