import { makeStyles } from "@material-ui/core";

export const useSummaryStyles = makeStyles((theme) => ({
  root: (props) => ({
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #E4E7E9",
    padding: "20px 26px !important",
    alignItems: "center",
  }),
  summaryHeading: (props) => ({
    fontWeight: 800,
    fontSize: 14,
    letterSpacing: "0.11em",
    lineHeight: "19px",
  }),
  heading: (props) => ({
    fontWeight: 800,
    fontSize: 13,
    letterSpacing: "0.01em",
    lineHeight: "18px",
  }),
  body: (props) => ({
    fontWeight: 600,
    fontSize: 13,
    letterSpacing: "0.01em",
    lineHeight: "18px",
  }),
  orangeHeading: (props) => ({
    fontWeight: 800,
    fontSize: 12,
    letterSpacing: "0.01em",
    lineHeight: "16px",
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    marginBottom: 14,
  }),
  editButton: (props) => ({
    textDecoration: "underline",
    fontWeight: 600,
    fontSize: 13,
    padding: 0,
    marginRight: -16,
    textTransform: "capitalize",
    "&:hover": {
      background: "transparent",
      textDecoration: "underline",
    },
  }),
  subHeading: (props) => ({
    fontWeight: 800,
    fontSize: 12,
    letterSpacing: "0.01em",
    lineHeight: "16px",
    color: "#9DAAB4",
    marginBottom: 14,
  }),
}));
