import React, { useEffect, useState } from "react";
import QuestionsConfig from "./components/questions";
import GeneralConfiguration from "./components/GeneralConfiguration";
import { useTrackedState } from "../../store";
import { useDispatch } from "../../../../../../common/components/campaigncreator/store";
import { SET_UI } from "../../../../../../common/components/campaigncreator/constants";
import { useDeepCompareEffectNoCheck } from "../../../../../../../../utils/use-deep-compare";
import ModuleDivider from "../../../../../../../ReusableComponents/ModuleDivider";
import {
  subscribeToEvent,
  unSubscribeAllConfigurationEvents,
} from "../../../../../walkthroughs/components/design/components/configuration/EventSubscriber";

import {
  ANSWER_MODES,
  ON_EDIT_SURVEY_STEP,
  SET_MODULE_TYPE,
} from "../../constants";
import { Grid, Typography } from "@material-ui/core";
import { useCommonConfigStyles } from "../../../../../walkthroughs/components/design/components/configuration/utils";

/**
 * Tiny wrapper to make things clear
 *
 * There are 3 major sub-config sections in Surveys
 * 1. GeneralConfig
 *    - The name tells what it contains. Config params which are
 *    - applicable for the entire survey
 * 2. DialogConfig
 *    - Before we launch the questions, customers can show a Dialog
 *    - to end-users. If a end-user selects YES, list of questions
 *    - will be shown. Otherwise, the Survey will be dismissed
 * 3. QuestionsConfig
 *    - Customers can create any number of questions they like
 *    - There is no max limit for the no.of questions.
 */
export default function SurveysConfigSection() {
  const state = useTrackedState();
  const { ui, currentStep } = state;
  const parentDispatch = useDispatch();
  const classes = useCommonConfigStyles();
  const [currentQuestion, setCurrentQuestion] = useState();

  useDeepCompareEffectNoCheck(() => {
    parentDispatch({
      type: SET_UI,
      ui: ui,
    });
    ui &&
      ui.questions &&
      ui.questions[currentStep] &&
      setCurrentQuestion(ui.questions[currentStep]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ui, currentStep, ui.questions[currentStep || 0]]);

  useEffect(() => {
    const collapseCallback = () => {
      parentDispatch({
        type: SET_MODULE_TYPE,
        value: "",
      });
    };
    const onEditStepSubscriber = subscribeToEvent(
      ON_EDIT_SURVEY_STEP,
      collapseCallback
    );

    return () => {
      unSubscribeAllConfigurationEvents([onEditStepSubscriber]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        border: "1px solid #E4E7E9",
        borderRadius: 3,
        padding: 16,
        paddingRight: 8,
        height: "100%",
        background: "#FFF",
        position: "relative",
      }}
    >
      <Typography
        variant="subtitle2"
        style={{
          fontWeight: 800,
          paddingTop: 4,
          paddingBottom: 8,
          letterSpacing: "0.11em",
        }}
      >
        EDIT DESIGN
      </Typography>
      <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
      <Grid container spacing={1} className={classes.gridContainer}>
        {currentQuestion &&
          currentQuestion.answer_mode !== ANSWER_MODES.success_message &&
          currentQuestion.answer_mode !== ANSWER_MODES.request_survey && (
            <>
              <GeneralConfiguration />

              <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
            </>
          )}
        <QuestionsConfig />
      </Grid>
    </div>
  );
}
