import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import React from "react";
import { isExperienceDashboard } from "../../../../../../../../../../../../config";
import ColumnDiv from "../../../../../../../../../../../ReusableComponents/ColumnDiv";
import { SET_TOGGLE_USER_ATTRIBUTES } from "../../../../../../../constants";
import { useTracked } from "../../../../../../../store";
import { useCommonConfigStyles } from "../../../../../../../utils";
import PropertiesBuilder from "../../propertiesbuilder";

export default function ByUserAttributes({ appId: app_id }) {
  const classes = useCommonConfigStyles();

  const [state, dispatch] = useTracked();
  const {
    config: {
      audience: {
        attributes: { user = [], hasUser = false },
      },
      meta: { platform },
    },
    user_attributes = [],
  } = state;

  return (
    <ColumnDiv>
      <FormControlLabel
        disabled={isExperienceDashboard()}
        label={
          <Typography className={classes.typographyCaption}>
            By User Properties
          </Typography>
        }
        control={
          <Checkbox
            color="primary"
            checked={hasUser || user.length > 0}
            onChange={(e) =>
              dispatch({
                type: SET_TOGGLE_USER_ATTRIBUTES,
                enabled: e.target.checked,
              })
            }
          />
        }
      />
      {(hasUser || user.length > 0) && (
        <PropertiesBuilder
          appId={app_id}
          platform={platform}
          properties={user}
          attributes={user_attributes}
        />
      )}
    </ColumnDiv>
  );
}


