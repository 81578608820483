import { callApi } from "../../../../api";
import { APP_PLATFORMS } from "../../../../constants";
import { makeDefaultQueryString } from "../../../../utils";
import {
  makePostRequest,
  makeGetRequest,
  makeDeleteRequest,
  makePatchRequest,
  makePutRequest,
} from "../../../common/actions";

const NOTIFICATIONS_API = "notifications";
const NOTIFICATION_SETTINGS_API = "notifications/settings";
const IOS_NOTIFICATIONS_SETTINGS_API = "notifications/ios-settings";

export function getNotificationsAPI(auth, appId, queryParams) {
  const onSuccessCallback = json => {
    return json;
  };
  const onFailureCallback = response => {
    return [];
  };
  return makeGetRequest(
    NOTIFICATIONS_API,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}

export function saveNotificationAPI(auth, appId, queryParams, filters) {
  const onSuccessCallback = json => {
    return json;
  };
  const onFailureCallback = response => {
    return {};
  };
  return makePostRequest(
    NOTIFICATIONS_API,
    auth,
    appId,
    queryParams,
    filters,
    onSuccessCallback,
    onFailureCallback
  );
}

export function deleteNotifiactionAPI(auth, appId, notificationId) {
  const onSuccessCallback = json => {
    return json;
  };
  const onFailureCallback = response => {
    return {};
  };
  return makeDeleteRequest(
    `${NOTIFICATIONS_API}/${notificationId}`,
    auth,
    appId,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getSingleNotificationAPI(
  auth,
  appId,
  notificationId,
  queryParams
) {
  const onSuccessCallback = json => {
    return json;
  };
  const onFailureCallback = response => {
    return {};
  };
  return makeGetRequest(
    NOTIFICATIONS_API + "/" + notificationId,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}

export function publishNotificationAPI(
  auth,
  appId,
  notificationId,
  queryParams
) {
  const onSuccessCallback = json => {
    return json;
  };
  const onFailureCallback = response => {
    return {};
  };
  return makePatchRequest(
    NOTIFICATIONS_API + "/" + notificationId + "/publish",
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}

export function pauseNotificationAPI(auth, appId, notificationId, queryParams) {
  const onSuccessCallback = json => {
    return json;
  };
  const onFailureCallback = response => {
    return {};
  };
  return makePatchRequest(
    NOTIFICATIONS_API + "/" + notificationId + "/pause",
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}

export function updateNotificationAPI(
  auth,
  appId,
  notificationId,
  queryParams,
  filters
) {
  const onSuccessCallback = json => {
    return json;
  };
  const onFailureCallback = response => {
    return {};
  };
  return makePutRequest(
    NOTIFICATIONS_API + "/" + notificationId,
    auth,
    appId,
    queryParams,
    filters,
    onSuccessCallback,
    onFailureCallback
  );
}

export function resumeNotificationAPI(
  auth,
  appId,
  notificationId,
  queryParams,
  filters
) {
  const onSuccessCallback = json => {
    return json;
  };
  const onFailureCallback = response => {
    return {};
  };
  return makePatchRequest(
    NOTIFICATIONS_API + "/" + notificationId + "/resume",
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}

export function sendTestNotificationAPI(auth, appId, queryParams, filters) {
  const onSuccessCallback = json => {
    return json;
  };
  const onFailureCallback = response => {
    return {};
  };
  return makePatchRequest(
    NOTIFICATIONS_API + "/test",
    auth,
    appId,
    queryParams,
    filters,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getPushSettings(
  auth,
  appId,
  queryParams,
  platform = APP_PLATFORMS.android
) {
  const onSuccessCallback = json => {
    return json;
  };
  const onFailureCallback = json => {
    return "";
  };
  const apiURL =
    platform === APP_PLATFORMS.android
      ? NOTIFICATION_SETTINGS_API
      : IOS_NOTIFICATIONS_SETTINGS_API;
  return makeGetRequest(
    apiURL,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}

export function saveIosPushSettings(auth, appId, iosKeys, auth_file) {
  const url = makeDefaultQueryString(
    IOS_NOTIFICATIONS_SETTINGS_API,
    auth,
    appId
  );
  const formData = new FormData();
  formData.append("keys", JSON.stringify(iosKeys));
  formData.append("file", auth_file, auth_file?.name);
  const config = {
    method: "POST",
    auth: auth,
    body: formData,
    headers: { "Content-Type": "multipart/form-data" },
  };
  const onSuccessCallback = json => {
    return json;
  };
  const onFailureCallback = response => {
    return response;
  };
  return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function saveAndroidPushSettings(auth, appId, queryParams, postBody) {
  const onSuccessCallback = json => {
    return json;
  };
  const onFailureCallback = json => {
    return json.message;
  };
  return makePostRequest(
    NOTIFICATION_SETTINGS_API,
    auth,
    appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback
  );
}

export function deletePushSettings(
  auth,
  appId,
  queryParams,
  platform = APP_PLATFORMS.android
) {
  const onSuccessCallback = json => {
    return json;
  };
  const onFailureCallback = json => {
    return "";
  };
  const apiURL =
    platform === APP_PLATFORMS.android
      ? NOTIFICATION_SETTINGS_API
      : IOS_NOTIFICATIONS_SETTINGS_API;
  return makeDeleteRequest(
    apiURL,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}
