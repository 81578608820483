import { callApi } from "../../../api";

const LOGIN_API = "authenticate/customer";
const LOGOUT_API = "logout";
const FORGOT_PASSWORD_API = "forgot-password/";
const RESET_PASSWORD_API = "reset-password/customer";
const INTEGRATION_API = "integration";
const INVITEES_API = "invitees";
const INVITEE_API = "invitee";

/**
 * @typedef {Object} LoginResponse
 * @property {Object} user - JSON Object
 * @property {string} user.email - The email of the user
 * @property {string} user.name - The name of the user
 */

/**
 * Sends a login request to the server.
 * @param {string} email The **email** of the user.
 * @param {string} password The **password** of the user.
 * @returns {Promise<LoginResponse>} Returns a promise that resolves to {@link LoginResponse}.
 */
export function loginAPI(email, password) {
  const config = {
    method: "POST",
    body: JSON.stringify({
      userId: email,
      password,
    }),
  };

  const onSuccessCallback = (json) => {
    return {
      token: "1234-5678-9101-1213",
      user: json,
    };
  };
  const onFailureCallback = (response) => {
    return response;
  };

  return callApi(LOGIN_API, config, onSuccessCallback, onFailureCallback);
}

/**
 * Sends a logout request to the server.
 * @returns {Promise<boolean>} Returns a promise that resolves to **true** if the logout was successful, **false** otherwise.
 */
export function logoutAPI() {
  const config = {
    method: "POST",
    body: "{}",
  };

  const onSuccessCallback = () => {
    return true;
  };
  const onFailureCallback = () => {
    return false;
  };

  return callApi(LOGOUT_API, config, onSuccessCallback, onFailureCallback);
}

/**
 * Sends a forgot password request to the server.
 * @param {string} email The **email** of the user.
 * @param {string} domain The **domain** of the dashboard from which the forgot password request is made.
 * @returns {Promise<boolean|string>} Returns a promise.
 */
export function forgotPasswordAPI(email, domain) {
  const url = `${FORGOT_PASSWORD_API}${email}?domain=${domain}`;
  const config = {
    method: "GET",
  };
  const onSuccessCallback = (email) => {
    return email?.length > 0;
  };
  const onFailureCallback = (error) => {
    return error?.data?.message;
  };
  return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 * Sends a reset password request to the server.
 * @param {Object} auth The **auth** object.
 * @param {string} email The **email** of the user.
 * @param {string} password The NEW **password** of the user.
 * @param {string} token The **token** received via the magic link from email.
 * @returns {Promise} Returns a promise.
 */
export function resetPasswordAPI(auth, email, password, token) {
  const config = {
    method: "POST",
    auth,
    body: JSON.stringify({
      userId: email,
      token,
      password,
    }),
  };
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return response;
  };
  return callApi(
    RESET_PASSWORD_API,
    config,
    onSuccessCallback,
    onFailureCallback
  );
}

/**
 * Sends an email with a magic link to the user to set a password.
 * @param {Object} body
 * @param {string} body.name The **name** of the user.
 * @param {string} body.email_id The **email** of the user.
 * @param {string} body.job_title The **job_title** of the user.
 * @param {string} body.company_name The **company_name** of the user.
 * @param {string} body.phone_number The **phone_number** of the user.
 * @returns {Promise} Returns a promise.
 */
export function integrationAuthStartAPI(body) {
  const config = {
    method: "POST",
    body: JSON.stringify(body),
  };
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return response;
  };
  return callApi(
    `${INTEGRATION_API}/signup/start`,
    config,
    onSuccessCallback,
    onFailureCallback
  );
}

/**
 * Sets the given password to the associated new user account.
 * @param {Object} body
 * @param {string} body.name The **name** of the user.
 * @param {string} body.password The **password** of the user.
 * @param {string} body.token The **token** received via the magic link from email.
 * @returns {Promise} Returns a promise.
 */
export function integrationAuthFinishAPI(body) {
  const config = {
    method: "POST",
    body: JSON.stringify(body),
  };
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return response;
  };
  return callApi(
    `${INTEGRATION_API}/signup/finish`,
    config,
    onSuccessCallback,
    onFailureCallback
  );
}

/**
 * Checks if the given user is already registered.
 * @param {string} token The **token** received via the magic link from email.
 * @returns {Promise} Returns a promise.
 */
export function checkUserExistsAPI(token) {
  const config = {
    method: "GET",
  };
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return response;
  };
  return callApi(
    `${INTEGRATION_API}/user/exists?token=${token}`,
    config,
    onSuccessCallback,
    onFailureCallback
  );
}

export function inviteUserAPI(appId, orgId, email_ids) {
  const config = {
    method: "POST",
    body: JSON.stringify({
      email_ids,
    }),
  };
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return response;
  };
  return callApi(
    `${INVITEES_API}?appId=${appId}&orgId=${orgId}`,
    config,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getAllInviteesAPI(appId) {
  const config = {
    method: "GET",
  };
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return response;
  };
  return callApi(
    `${INVITEES_API}?appId=${appId}`,
    config,
    onSuccessCallback,
    onFailureCallback
  );
}

export function revokeInvitationAPI(appId, inviteeId) {
  const config = {
    method: "DELETE",
  };
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return response;
  };
  return callApi(
    `${INVITEE_API}?appId=${appId}&inviteeId=${inviteeId}`,
    config,
    onSuccessCallback,
    onFailureCallback
  );
}

/**
 * @param {string} token The **token** received via the magic link from email.
 * @param {boolean} status The invitation status to be set to.
 * @returns {Promise} Returns a promise.
 */
export function setInviteeStatusAPI(token, status) {
  const config = {
    method: "PATCH",
  };
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return response;
  };
  return callApi(
    `${INVITEE_API}/status?token=${token}&status=${status}`,
    config,
    onSuccessCallback,
    onFailureCallback
  );
}

/**
 * Checks if the invitation sent to a user is revoked.
 * @param {string} appId The **appId** of the app to which the user was invited.
 * @param {string} inviteeId The **email** of the user.
 * @returns {Promise} Returns a promise.
 */
export function checkInvitationRevokedAPI(appId, inviteeId) {
  const config = {
    method: "GET",
  };
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return response;
  };
  return callApi(
    `${INVITEE_API}/revoked?appId=${appId}&inviteeId=${inviteeId}`,
    config,
    onSuccessCallback,
    onFailureCallback
  );
}
