import {
  IconButton,
  Tooltip,
  Typography,
  withStyles,
  Card,
} from "@material-ui/core";
import React, { useState } from "react";
import Loading from "../../../../../../../../ReusableComponents/Loading";
import dots from "../../../../../../walkthroughs/components/design/assets/dots.png";
import delete_icon from "../../../../../../walkthroughs/components/design/assets/delete_icon.png";

import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { sendEvent } from "../../../../../../../common/ApxEventHandler";
import {
  ANSWER_MODES,
  ENABLE_DIALOG,
  SHOW_SUCCESS_MESSAGE,
} from "../../../constants";

const styles = theme => ({
  buttonBaseStyle: {
    cursor: "pointer",
    borderRight: "1px solid #E4E7E9",
    display: "inline-flex",
    outline: "none",
    padding: 0,
    position: "relative",
    alignItems: "center",
    userSelect: "none",
    verticalAlign: "middle",
    justifyContent: "center",
    textDecoration: "none",
    backgroundColor: "transparent",
    textAlign: "initial",
    borderRadius: 0,
  },
  iconButtonRoot: {
    padding: 1,
    color: theme.palette.primary.main,
  },
  root: {
    zIndex: 9999,
  },
  contentAlign: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  classicCard: {
    top: 3,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    height: 80,
    width: "auto",
    borderRadius: 0,
    minWidth: 164,
    boxShadow: "none",
  },
  dots: {
    height: "19.5px",
    width: "auto",
    paddingLeft: "10px",
    top: "30px",
    alignContent: "center",
  },
  cardName: {
    fontFamily: "Manrope",
    fontSize: 14,
    fontWeight: 600,
    whiteSpace: "nowrap",
    overflow: "hidden",
    lineHeight: "19px",
    marginRight: 32,
  },
  deleteIcon: {
    position: "absolute",
    right: 4,
    top: 4,
  },
  deleteThumbnail: {
    height: "15px",
    width: "auto",
  },
  clipCopy: {
    bottom: 4,
    right: 5,
    position: "absolute",
    padding: 0,
  },
  copyThumbnail: {
    color: "#8497A5",
    height: "16px",
    width: "auto",
  },
});

const getName = name => {
  switch (name) {
    case "single_choice":
      return "Single Choice";
    case "text":
      return "Short Answer";
    case "rating":
      return "Rating";
    case "success_message":
      return "Success Message";
    case "multi_choice":
      return "Multi Select";
    case "request_survey":
      return "Request for Survey";
    default:
      return "Question";
  }
};

const SurveysCard = ({
  data,
  classes,
  dispatch,
  editable = false,
  disableCopy = false,
  getLogo,
  index,
  actions,
  currentStep,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [onHover, setOnHover] = useState(false);
  const { image = "", name = "" } = getLogo();
  const {
    DELETE_STEP_FROM_CAMPAIGN,
    ON_EDIT_STEP,
    ON_DELETE_STEP,
    SET_CURRENT_STEP,
    ON_COPY_STEP,
  } = actions;
  return (
    <div id={`ClassicCard-${index}`} className={classes.root}>
      <div
        className={classes.contentAlign}
        style={{
          borderBottom: index === currentStep ? "2px solid black" : 0,
        }}
      >
        <div
          className={classes.buttonBaseStyle}
          onMouseEnter={() => setOnHover(true)}
          onMouseLeave={() => setOnHover(false)}
        >
          <Card
            raised={false}
            className={classes.classicCard}
            onClick={() => {
              dispatch({
                type: SET_CURRENT_STEP,
                value: index,
              });
              sendEvent(ON_EDIT_STEP);
            }}
          >
            {data.answer_mode !== ANSWER_MODES.request_survey &&
              data.answer_mode !== ANSWER_MODES.success_message && (
                <img src={dots} alt="dots" className={classes.dots} />
              )}
            {image}
            <Typography
              color="textPrimary"
              variant="h6"
              className={classes.cardName}
            >
              {getName(name)}
            </Typography>
          </Card>
          {editable && onHover && (
            <Tooltip title="Delete Step" className={classes.deleteIcon}>
              <IconButton
                classes={{
                  root: classes.iconButtonRoot,
                }}
                onClick={() => {
                  setIsDeleting(true);
                  if (data.answer_mode === ANSWER_MODES.request_survey) {
                    dispatch({
                      type: ENABLE_DIALOG,
                      value: false,
                      orientation: "",
                    });
                  } else if (
                    data.answer_mode === ANSWER_MODES.success_message
                  ) {
                    dispatch({
                      type: SHOW_SUCCESS_MESSAGE,
                      value: false,
                    });
                  }
                  dispatch({
                    type: DELETE_STEP_FROM_CAMPAIGN,
                    index,
                  });
                  dispatch({
                    type: SET_CURRENT_STEP,
                    value: currentStep - 1,
                  });
                  sendEvent(ON_DELETE_STEP);
                  setIsDeleting(false);
                }}
              >
                {isDeleting ? (
                  <Loading size={20} />
                ) : (
                  <img
                    src={delete_icon}
                    alt="delete"
                    className={classes.deleteThumbnail}
                  />
                )}
              </IconButton>
            </Tooltip>
          )}
          {editable && onHover && !disableCopy && (
            <Tooltip title="ClipCopy" className={classes.clipCopy}>
              <IconButton
                onClick={() => {
                  dispatch({
                    type: ON_COPY_STEP,
                    index: index,
                  });
                }}
              >
                <FileCopyOutlinedIcon
                  fontSize="small"
                  color="action"
                  className={classes.copyThumbnail}
                />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(SurveysCard);
