import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import { ANSWER_MODES } from "../../design/constants";
import { Stats } from "./Stats";
import { NPSQuestion } from "./NPSQuestions";
import { TextAnswerResults } from "./TextAnswerResults";
import { ChoiceQuestion } from "./ChoiceQuestion";
import Loading from "../../../../../../ReusableComponents/Loading";

export const ResultsHolder = ({
  survey = {},
  results: { data = [] } = {},
  results,
  result_distributions: { user, session } = {},
  pending,
  dateFilters,
}) => {
  return (
    <Fragment>
      {pending && <Loading size={36} />}
      {!pending && survey && (
        <>
          <Stats survey={survey} results={results} />
          <Typography variant="h4" style={{ marginBottom: 16 }}>
            Responses
          </Typography>
          {data.map(item =>
            item.answer_mode === ANSWER_MODES.rating ? (
              <NPSQuestion key={item.id} dateFilters={dateFilters} {...item} />
            ) : item.answer_mode === ANSWER_MODES.text ? (
              <TextAnswerResults key={item.id} {...item} />
            ) : (
              <ChoiceQuestion key={item.id} {...item} />
            )
          )}
        </>
      )}
    </Fragment>
  );
};
