import {
  Avatar,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MuiThemeProvider,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import {
  AssessmentOutlined,
  ExitToApp,
  ExpandLess,
  ExpandMore,
  PersonOutline,
  SearchOutlined,
  SettingsOutlined,
  TimelineOutlined,
  TouchAppOutlined,
} from "@material-ui/icons";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { isSuper } from "../../../../config";
import { FEATURE_KEY_ENUM } from "../../../../constants";
import { darkTheme } from "../../../../styling";
import { capitalizeEachWord, isAccessable } from "../../../../utils";
import { useDeepCompareMemo } from "../../../../utils/use-deep-compare";
import ColumnDiv from "../../../ReusableComponents/ColumnDiv";
import RowDiv from "../../../ReusableComponents/RowDiv";
import { apxTheme as theme } from "../../styling/CustomCSS";

const pixelometryVersion = process.env.REACT_APP_VERSION;

const drawerWidth = 242;

const DASHBOARD_ENDPOINTS = {
  ALERTS: "alerts",
  APPS: "apps",
  APPROVAL: "approve",
  AUDIENCE: "audience",
  CONTEXTS: "contexts",
  COHORTS: "cohorts",
  CREATE: "create",
  CUSTOM_GROUPING: "custom-grouping",
  CUSTOM_REPORTS: "custom-reports",
  DASHBOARD: "dashboard",
  DASHBOARDS: "dashboards",
  DOWNLOADS: "downloads",
  DROP_OFF: "drop-off",
  ENGAGEMENT_REPORT: "reports/key-metrics",
  EVENT_ANALYSIS: "event-analysis",
  FUNNELS: "funnels",
  HEALTH: "health",
  INCIDENTS: "incidents",
  INTEGRATION: "integration",
  ISSUE_DETAILS: "issues",
  LOGIN: "login",
  LOGOUT: "logout",
  MESSAGES: "messages",
  WALKTHROUGHS: "walkthroughs",
  OVERVIEW: "overview",
  PATH: "path",
  PREPROCESS: "preprocess",
  PROFILE: "profile",
  PUSH: "push",
  REPORTS: "reports",
  RESET_PASSWORD: "reset-password",
  RETENTION: "retention",
  SEGMENTS: "segments",
  SETTINGS: "settings",
  SETTINGS_DATA: "settings/downloads",
  SETTINGS_GENERAL: "settings/general",
  SETTINGS_IAM: "settings/iam",
  SETTINGS_EM: "settings/events",
  SETTINGS_SDK: "settings/sdk",
  SETTINGS_SESSIONS: "settings/sessions",
  SETTINGS_TEST_DEVICES: "settings/test-devices",
  SETTINGS_USERS: "settings/users",
  SIGN_UP: "sign_up",
  SURVEYS: "surveys",
  UNAUTHORISED: "unauthorised",
  UNINSTALL_REPORT: "reports/day0-analysis",
  UNINSTALLS: "uninstalls",
  USER: "user",
  USER_EXPLORER: "user-explorer",
  USERS: "users",
};

const styles = () => ({
  appDropdown: { height: 92 },
  profileDropdown: { height: 100 },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0 16px",
    ...theme.mixins.toolbar,
  },
  appIcon: {
    width: 40,
  },
  profileIcon: {
    width: 32,
    borderRadius: 32,
  },
  sideLogo: {
    justifyContent: "flex-start",
    width: "65%",
  },
  sideLogoDrawerClosed: {
    height: 40,
  },
  drawerPaper: {
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)",
    position: "fixed",
    zIndex: 10000,
    height: "100vh",
    width: drawerWidth,
    transition: "all 0.1s ease-in-out",
    overflowY: "auto",
    "&:hover": {
      overflowY: "auto",
    },
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  drawerPaperOpen: {
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)",
    minWidth: 242,
    transition: "all 0.1s ease-in-out",
  },
  drawerPaperClosed: {
    maxWidth: "90px !important",
    overflowX: "hidden",
  },
  nested: {
    paddingLeft: "52px !important",
  },
  activeNode: {
    borderLeft: `6px solid ${theme.palette.primary.main}`,
    padding: "8px 12px",
    height: 48,
  },
  node: {
    borderLeft: "6px solid transparent",
    padding: "8px 12px",
    height: 48,
    transition: "all 0.1s ease-in-out",
  },
  drawerClosedNode: {
    paddingLeft: "27px !important",
  },
  nodeLeft: {
    alignItems: "center",
  },
  listItemIcon: {
    minWidth: "auto",
    paddingRight: 12,
  },
  listItemIconActive: {
    minWidth: "auto",
    paddingRight: 12,
    color: theme.palette.primary.main,
  },
  listItemText: {
    fontSize: 14,
    fontWeight: "500",
    color: theme.palette.secondary.main,
  },
  listItemTextActive: {
    fontSize: 14,
    fontWeight: "500",
    color: theme.palette.primary.main,
  },

  activeDot: {
    width: 8,
    height: 8,
    borderRadius: 8,
    backgroundColor: theme.palette.success.main,
  },
  inactiveDot: {
    width: 8,
    height: 8,
    borderRadius: 8,
    backgroundColor: theme.palette.error.main,
  },
  sidebarVerticalContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
});

const navigationLink = (appId, endPoint) => {
  return { pathname: `/apps/${appId}/${endPoint}` };
};

const ActiveDot = ({ active, classes }) => (
  <div
    className={classNames([active ? classes.activeDot : classes.inactiveDot])}
  />
);

const ProfileLabel = ({ children }) => (
  <Typography
    style={{
      padding: "3px 8px",
      background: "#176FAC",
      borderRadius: 2,
      color: "white",
      fontSize: 11,
      width: "fit-content",
    }}
  >
    {children}
  </Typography>
);

const ParentNode = ({
  appState,
  nodeOpen,
  handleOpen,
  classes,
  children,
  name: parentName,
  id: parentId,
  icon: parentIcon,
  drawerOpen,
}) => {
  const handleClick = () => {
    handleOpen(parentId === nodeOpen ? null : parentId);
  };
  const { pathname } = useLocation();
  const pathItem = pathname.split("/").at(-1);
  const isCurrentActive = checkIfParentIsActive(parentId, pathItem);

  if (!children) {
    return (
      <ListItem
        disableGutters
        button
        className={classNames({
          [classes.activeNode]: isCurrentActive,
          [classes.node]: !isCurrentActive,
          [classes.drawerClosedNode]: !drawerOpen,
        })}
      >
        <ListItemIcon
          className={classNames({
            [classes.listItemIcon]: true,
            [classes.listItemIconActive]: isCurrentActive,
          })}
        >
          {parentIcon}
        </ListItemIcon>
        {drawerOpen && (
          <ListItemText
            className={classes.listItemText}
            primary={parentName}
            primaryTypographyProps={{
              className: classes.listItemText,
            }}
          />
        )}
      </ListItem>
    );
  }

  const childNodes = children.map(({ name, id, path, feature_key }) => {
    const isChildActive = pathname.endsWith(id);

    return (
      <>
        {parentId === "settings" ? (
          <NavLink to={navigationLink(appState?.app_id, path)}>
            <List component="div" disablePadding key={id}>
              <ListItem disableGutters button className={classes.nested}>
                <ListItemText
                  className={classNames({
                    [classes.listItemTextActive]: isChildActive,
                    [classes.listItemText]: !isChildActive,
                  })}
                  primary={name}
                  primaryTypographyProps={{
                    className:
                      classes[
                        isChildActive ? "listItemTextActive" : "listItemText"
                      ],
                  }}
                />
              </ListItem>
            </List>
          </NavLink>
        ) : (
          <>
            {isAccessable(appState, feature_key) && (
              <NavLink to={navigationLink(appState?.app_id, path)}>
                <List component="div" disablePadding key={id}>
                  <ListItem disableGutters button className={classes.nested}>
                    <ListItemText
                      className={classNames({
                        [classes.listItemTextActive]: isChildActive,
                        [classes.listItemText]: !isChildActive,
                      })}
                      primary={name}
                      primaryTypographyProps={{
                        className:
                          classes[
                            isChildActive
                              ? "listItemTextActive"
                              : "listItemText"
                          ],
                      }}
                    />
                  </ListItem>
                </List>
              </NavLink>
            )}
          </>
        )}
      </>
    );
  });

  const isOpen = nodeOpen === parentId;

  return (
    <>
      <ListItem
        disableGutters
        button
        onClick={handleClick}
        className={classNames({
          [classes.activeNode]: isCurrentActive,
          [classes.node]: !isCurrentActive,
          [classes.drawerClosedNode]: !drawerOpen,
        })}
      >
        <ListItemIcon
          className={classNames({
            [classes.listItemIcon]: true,
            [classes.listItemIconActive]: isCurrentActive,
          })}
        >
          {parentIcon}
        </ListItemIcon>
        {drawerOpen && (
          <>
            <ListItemText
              className={classes.listItemText}
              primaryTypographyProps={{
                className: classes.listItemText,
              }}
            >
              {parentName}
            </ListItemText>
            {isOpen ? <ExpandLess /> : <ExpandMore />}
          </>
        )}
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        {childNodes}
      </Collapse>
    </>
  );
};

const ALL_USERS_SETTINGS_OPTIONS = [
  {
    name: "Test Devices",
    id: "test-devices",
    path: `${DASHBOARD_ENDPOINTS.SETTINGS_TEST_DEVICES}`,
    icon: null,
    children: null,
    feature_key: FEATURE_KEY_ENUM.SETTINGS_TEST_DEVICES,
  },
  {
    name: "Downloads",
    id: "downloads",
    path: `${DASHBOARD_ENDPOINTS.SETTINGS_DATA}`,
    icon: null,
    children: null,
    feature_key: FEATURE_KEY_ENUM.SETTINGS_DATA,
  },
  {
    name: "Events Management",
    id: "events",
    path: `${DASHBOARD_ENDPOINTS.SETTINGS_EM}`,
    icon: null,
    children: null,
    feature_key: FEATURE_KEY_ENUM.SETT,
  },
];
const ADMIN_USERS_SETTINGS_OPTIONS = [
  ...ALL_USERS_SETTINGS_OPTIONS,
  {
    name: "General",
    id: "general",
    path: `${DASHBOARD_ENDPOINTS.SETTINGS_GENERAL}`,
    icon: null,
    children: null,
    feature_key: FEATURE_KEY_ENUM.SETTINGS_GENERAL,
  },
  {
    name: "SDK",
    id: "sdk",
    path: `${DASHBOARD_ENDPOINTS.SETTINGS_SDK}`,
    icon: null,
    children: null,
    feature_key: FEATURE_KEY_ENUM.SETTINGS_SDK,
  },
  {
    name: "IAM",
    id: "iam",
    path: `${DASHBOARD_ENDPOINTS.SETTINGS_IAM}`,
    icon: null,
    children: null,
    feature_key: FEATURE_KEY_ENUM.SETTINGS_IAM,
  },
];

const navigationItems = [
  {
    name: "Nudges",
    id: "nudges",
    path: `${DASHBOARD_ENDPOINTS.DASHBOARD}`,
    icon: <TouchAppOutlined />,
    children: [
      {
        name: "Overview",
        id: "dashboard",
        path: `${DASHBOARD_ENDPOINTS.DASHBOARD}`,
        icon: null,
        children: null,
        feature_key: FEATURE_KEY_ENUM.DASHBOARD,
      },
      {
        name: "Campaigns",
        id: "walkthroughs",
        path: `${DASHBOARD_ENDPOINTS.WALKTHROUGHS}`,
        icon: null,
        children: null,
        feature_key: FEATURE_KEY_ENUM.WALKTHROUGHS,
      },
      {
        name: "Surveys",
        id: "surveys",
        path: `${DASHBOARD_ENDPOINTS.SURVEYS}`,
        icon: null,
        children: null,
        feature_key: FEATURE_KEY_ENUM.SURVEYS,
      },
      {
        name: "Push",
        id: "push",
        path: `${DASHBOARD_ENDPOINTS.PUSH}`,
        icon: null,
        children: null,
        feature_key: FEATURE_KEY_ENUM.PUSH,
      },
    ],
  },
  {
    name: "Analytics",
    id: "analytics",
    path: `${DASHBOARD_ENDPOINTS.FUNNELS}`,
    icon: <TimelineOutlined />,
    children: [
      {
        name: "Templates Retention",
        id: "retention",
        path: `${DASHBOARD_ENDPOINTS.RETENTION}`,
        icon: null,
        children: null,
        feature_key: FEATURE_KEY_ENUM.RETENTION,
      },
      {
        name: "Uninstalls",
        id: "uninstalls",
        path: `${DASHBOARD_ENDPOINTS.UNINSTALLS}`,
        icon: null,
        children: null,
        feature_key: FEATURE_KEY_ENUM.UNINSTALLS,
      },
      {
        name: "Funnels",
        id: "funnels",
        path: `${DASHBOARD_ENDPOINTS.FUNNELS}`,
        icon: null,
        children: null,
        feature_key: FEATURE_KEY_ENUM.FUNNELS,
      },
      {
        name: "Paths",
        id: "path",
        path: `${DASHBOARD_ENDPOINTS.PATH}`,
        icon: null,
        children: null,
        feature_key: FEATURE_KEY_ENUM.PATH,
      },
    ],
  },
  {
    name: "Reporting",
    id: "reporting",
    path: `${DASHBOARD_ENDPOINTS.REPORTING}`,
    icon: <AssessmentOutlined />,
    children: [
      {
        name: "Custom Reports",
        id: "custom-reports",
        path: `${DASHBOARD_ENDPOINTS.CUSTOM_REPORTS}`,
        icon: null,
        children: null,
        feature_key: FEATURE_KEY_ENUM.CUSTOM_REPORTS,
      },
    ],
  },
  {
    name: "Explorer",
    id: "explorer",
    path: `${DASHBOARD_ENDPOINTS.EVENT_ANALYSIS}`,
    icon: <SearchOutlined />,
    children: [
      {
        name: "Event Explorer",
        id: "event-analysis",
        path: `${DASHBOARD_ENDPOINTS.EVENT_ANALYSIS}`,
        icon: null,
        children: null,
        feature_key: FEATURE_KEY_ENUM.EVENT_ANALYSIS,
      },
      {
        name: "User Explorer",
        id: "user-explorer",
        path: `${DASHBOARD_ENDPOINTS.USER_EXPLORER}`,
        icon: null,
        children: null,
        feature_key: FEATURE_KEY_ENUM.USER_EXPLORER,
      },
    ],
  },
  {
    name: "Target Audience",
    id: "target_audience",
    path: `${DASHBOARD_ENDPOINTS.TARGET_AUDIENCE}`,
    icon: <PersonOutline />,
    children: [
      {
        name: "Cohorts",
        id: "cohorts",
        path: `${DASHBOARD_ENDPOINTS.COHORTS}`,
        icon: null,
        children: null,
        feature_key: FEATURE_KEY_ENUM.COHORTS,
      },
      {
        name: "Segments",
        id: "segments",
        path: `${DASHBOARD_ENDPOINTS.SEGMENTS}`,
        icon: null,
        children: null,
        feature_key: FEATURE_KEY_ENUM.SEGMENTS,
      },
    ],
  },
  {
    name: "Settings",
    id: "settings",
    path: `${DASHBOARD_ENDPOINTS.SETTINGS}`,
    icon: <SettingsOutlined />,
    children: ALL_USERS_SETTINGS_OPTIONS,
  },
];

const checkIfParentIsActive = (parentId, childId) => {
  const parent = navigationItems.find((item) =>
    item.children?.find((child) => child.id === childId),
  );
  return parent?.id === parentId;
};

const profileItems = [
  {
    name: "My Profile",
    id: "profile",
    path: `${DASHBOARD_ENDPOINTS.PROFILE}`,
    icon: null,
    children: null,
  },
  {
    name: "Logout",
    id: "logout",
    path: `${DASHBOARD_ENDPOINTS.LOGOUT}`,
    icon: <ExitToApp />,
    children: null,
  },
];

const NavigationNew = ({
  open: drawerOpen,
  appState,
  user,
  handleMouseEnter,
  handleMouseLeave,
  classes,
}) => {
  const { pathname } = useLocation();

  const [nodeOpen, setNodeOpen] = useState(null);
  const [isOpenProfileDropdown, setOpenProfileDropdown] = useState(false);
  const [appActive, setAppActive] = useState(appState?.monitoring);
  useEffect(() => {
    if (!drawerOpen) {
      setNodeOpen(null);
      setOpenProfileDropdown(false);
    }
  }, [drawerOpen]);
  const handleSetNodeOpen = (nodeId) => setNodeOpen(nodeId);
  const handleOpenProfileDropdown = () =>
    setOpenProfileDropdown((prev) => !prev);

  const AppActiveStatus = () => {
    return (
      <RowDiv styles={{ gap: 4, alignItems: "center" }}>
        <ActiveDot active={appActive} classes={classes} />
        <Typography variant="subtitle2">
          {appActive ? "Active" : "Inactive"}
        </Typography>
      </RowDiv>
    );
  };

  const isAdmin = useDeepCompareMemo(
    () =>
      user?.email === appState?.basic_info?.customer_id || isSuper(user.email),
    [appState, user],
  );

  const conditionalNavItems = useMemo(
    () =>
      !isAdmin
        ? navigationItems
        : [
            ...navigationItems.slice(0, -1),
            {
              ...navigationItems.at(-1),
              children: ADMIN_USERS_SETTINGS_OPTIONS,
            },
          ],
    [isAdmin],
  );

  return (
    <MuiThemeProvider theme={darkTheme}>
      <Drawer
        variant="permanent"
        open={drawerOpen}
        className={classNames(classes.drawerPaper, {
          [classes.drawerPaperOpen]: drawerOpen,
          [classes.drawerPaperClosed]: !drawerOpen,
        })}
        classes={{
          paper: classNames({
            [classes.drawerPaperOpen]: drawerOpen,
            [classes.drawerPaperClosed]: !drawerOpen,
          }),
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className={classes.sidebarVerticalContainer}>
          <div>
            <div
              className={classNames(classes.drawerHeader, {
                [classes.drawerClosedNode]: !drawerOpen,
              })}
            >
              <NavLink to={`/apps`}>
                <img
                  alt={window.apxorAppInfo.whiteLogo}
                  src={
                    drawerOpen
                      ? "/assets/img/logo-white.png"
                      : "/assets/img/sidebar_logo.png"
                  }
                  className={classNames({
                    [classes.sideLogo]: drawerOpen,
                    [classes.sideLogoDrawerClosed]: !drawerOpen,
                  })}
                />
              </NavLink>
            </div>
            <ListItem
              className={classNames(classes.appDropdown, {
                [classes.drawerClosedNode]: !drawerOpen,
              })}
            >
              <ListItemIcon>
                <img
                  alt={window.apxorAppInfo.whiteLogo}
                  src={appState?.basic_info?.icon_url}
                  className={classes.appIcon}
                />
              </ListItemIcon>
              {drawerOpen && (
                <ListItemText
                  primary={appState?.basic_info?.app_name}
                  secondary={<AppActiveStatus />}
                  style={{
                    fontWeight: "500",
                  }}
                />
              )}
            </ListItem>

            <List component="nav">
              {conditionalNavItems?.map((parentNode) => {
                return (
                  <ParentNode
                    appState={appState}
                    {...parentNode}
                    nodeOpen={nodeOpen}
                    handleOpen={handleSetNodeOpen}
                    classes={classes}
                    drawerOpen={drawerOpen}
                  />
                );
              })}
            </List>
          </div>
          <div>
            <ListItem
              button
              onClick={handleOpenProfileDropdown}
              className={classNames(classes.profileDropdown, {
                [classes.drawerClosedNode]: !drawerOpen,
              })}
            >
              <ListItemIcon>
                <Avatar
                  style={{
                    color: theme.palette.secondary.main,
                    border: "2px solid",
                    borderColor: theme.palette.primary.main,
                    backgroundColor: "transparent",
                  }}
                >
                  {capitalizeEachWord(user?.[user?.name ? "name" : "email"])
                    ?.split(" ")
                    .map((name) => name[0])
                    .join("")}
                </Avatar>
              </ListItemIcon>
              {drawerOpen && (
                <>
                  <ListItemText
                    primary={
                      <Typography
                        style={{
                          maxWidth: 120,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {user?.name ?? user?.email}
                      </Typography>
                    }
                    secondary={
                      <ColumnDiv styles={{ gap: 4, maxWidth: 120 }}>
                        <Tooltip title={user?.email} placement="top" arrow>
                          <Typography
                            variant="subtitle2"
                            color="textSecondary"
                            style={{
                              maxWidth: 120,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {user?.email}
                          </Typography>
                        </Tooltip>
                        {user?.job_title && (
                          <ProfileLabel>{user?.job_title}</ProfileLabel>
                        )}
                      </ColumnDiv>
                    }
                  />
                  {isOpenProfileDropdown ? <ExpandLess /> : <ExpandMore />}
                </>
              )}
            </ListItem>

            <Collapse in={isOpenProfileDropdown} timeout="auto" unmountOnExit>
              {profileItems.map(({ name, id, icon }) => {
                const isCurrentActive = id === pathname.split("/").at(-1);

                return (
                  <List component="div" disablePadding key={id}>
                    <ListItem
                      disableGutters
                      button
                      className={classes.nested}
                      component={NavLink}
                      to={`/${id}`}
                    >
                      <ListItemText
                        className={classNames({
                          [classes.listItemTextActive]: isCurrentActive,
                          [classes.listItemText]: !isCurrentActive,
                        })}
                        primary={name}
                        primaryTypographyProps={{
                          className:
                            classes[
                              isCurrentActive
                                ? "listItemTextActive"
                                : "listItemText"
                            ],
                        }}
                      />
                      {icon && <ListItemIcon>{icon}</ListItemIcon>}
                    </ListItem>
                  </List>
                );
              })}
            </Collapse>

            <Divider />

            <Typography
              style={{
                textAlign: "center",
                padding: 4,
                fontSize: 12,
              }}
              variant="subtitle2"
              color="textSecondary"
            >
              {drawerOpen && "App Version: "}
              <b>{pixelometryVersion}</b>
            </Typography>
          </div>
        </div>
      </Drawer>
    </MuiThemeProvider>
  );
};

export default withStyles(styles, { withTheme: true })(NavigationNew);
