import { Box, Button, Input } from "@material-ui/core";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../../contexts/AuthContext";
import Loading from "../../../ReusableComponents/Loading";
import { SET_CONFIRM_PASSWORD, SET_PASSWORD } from "../../constants";
import { useDispatch, useTrackedState } from "../../store";
import PasswordInput from "../components/PasswordInput";
import PasswordStrengthChecks from "../components/PasswordStrengthChecks";
import useIntegration from "../hooks/use-integration";
import AuthLayout from "../layouts/AuthLayout";

export default function ResetPasswordPage() {
  const {
    user: { password, confirm_password },
    auth_error,
    auth_loading,
  } = useTrackedState();
  const dispatch = useDispatch();
  const { resetIntermediateAuthStates } = useAuth();
  const { isSubmitDisabled, resetPassword } = useIntegration();
  const { push } = useHistory();

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      if (await resetPassword(password)) {
        resetIntermediateAuthStates({
          loading: false,
          error: "",
        });
        push("/login");
      }
    },
    [password, push, resetIntermediateAuthStates, resetPassword]
  );

  return (
    <AuthLayout title="Set New Password">
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "start",
          marginRight: "24px",
          flex: "1",
        }}
      >
        <label
          htmlFor="password"
          style={{
            marginTop: "16px",
            fontSize: "13px",
            fontWeight: "500",
            color: "#667A8A",
          }}
        >
          Password
        </label>
        <Input
          name="password"
          size="medium"
          id="password"
          type="password"
          value={password}
          onChange={(e) => {
            dispatch({
              type: SET_PASSWORD,
              payload: e.target.value,
            });
          }}
          style={{
            width: "100%",
            marginTop: "4px",
            border: "1px solid #CCD4DA",
            borderRadius: "2px",
            fontWeight: "600",
            fontSize: "14px",
            padding: "4.5px",
          }}
        />
        <label
          htmlFor="confirm_password"
          style={{
            marginTop: "16px",
            fontSize: "13px",
            fontWeight: "500",
            color: "#667A8A",
          }}
        >
          Confirm Password
        </label>
        <PasswordInput
          name="confirm_password"
          id="confirm_password"
          value={confirm_password}
          onChange={(e) => {
            dispatch({
              type: SET_CONFIRM_PASSWORD,
              payload: e.target.value,
            });
          }}
          style={{
            width: "100%",
            marginTop: "4px",
            border: "1px solid #CCD4DA",
            borderRadius: "2px",
            marginBottom: "45px",
            fontWeight: "600",
            fontSize: "14px",
            padding: "4.5px",
          }}
        />
        <Box
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            width: auth_error ? "100%" : "60%",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            disabled={isSubmitDisabled}
            style={{
              width: auth_error ? "30%" : "50%",
              height: 48,
            }}
          >
            {auth_loading ? <Loading size={28} color="#FFFFFF" /> : "Next"}
          </Button>
          <span
            style={{
              marginLeft: "12px",
              fontSize: "16px",
              fontWeight: "500",
              color: "#F12D2D",
            }}
          >
            {auth_error !== "" && auth_error}
          </span>
        </Box>
      </form>
      <PasswordStrengthChecks
        styles={{
          paddingBottom: "5.2rem",
        }}
      />
    </AuthLayout>
  );
}
