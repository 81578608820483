import React from "react";
import ComposedChart from "../../../../ReusableComponents/ComposedChart";
import { useTrackedState } from "../store";

const getLabelText = (dataIndex, windowSize, timeframeWindow) => {
  if (windowSize > 1) {
    const upperLimit = dataIndex * windowSize + windowSize - 1;
    // return (dataIndex * windowSize) + "-" + (timeframeWindow < upperLimit ? timeframeWindow : upperLimit);
    return dataIndex * windowSize + "-" + upperLimit;
  }
  return dataIndex;
};

export const RetentionTrend = () => {
  const state = useTrackedState();
  const {
    overallRetention = [],
    windowSize,
    timeframeWindow,
    retention_header,
  } = state;
  let data = [],
    labelText = "Day ";
  const lineDataKeys = ["Retention"];
  try {
    if (
      Array.isArray(overallRetention) &&
      overallRetention.length === retention_header.length
    ) {
      lineDataKeys.push("Overall Retention");
    }
    data = retention_header
      .slice(1, retention_header.length - 1)
      .map((item, i) => ({
        key: labelText + getLabelText(i, windowSize, timeframeWindow),
        Retention: item["percent"],
        "Overall Retention": overallRetention[i],
      }));
  } catch (e) {
    console.error(e);
    data = [];
  }
  return (
    <ComposedChart
      height={350}
      valueLabel="Events"
      dataKey="key"
      data={data}
      lineDataKeys={lineDataKeys}
      title="Retention"
      withHeader={false}
      color="#519674"
      dot={true}
      showAverage={false}
      xLabelFormatter={(key) => key}
    />
  );
};
