/**
 * Created by jyothi on 25/11/17.
 */
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    //margin: apxTheme.spacing.unit,
    width: "100%",
    /*minWidth: 120,
        maxWidth: 300,*/
  },
  root: {
    height: "auto", //36
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const labelRenderer = (label, labelFormatter) => {
  return typeof labelFormatter === "function" ? labelFormatter(label) : label;
};

function FormSelect({
  classes,
  options = [],
  label,
  value = "",
  helper,
  allowEmpty = false,
  emptyLabel = "All",
  multiple = false,
  containerStyles = {},
  disabled = false,
  idKey = "key",
  nameKey = "label",
  margin = "none",
  fullWidth = false,
  labelFormatter,
  handleUpdate,
}) {
  return (
    <div
      className={classes.container}
      style={containerStyles}
      onClick={(e) => e.stopPropagation()}
    >
      <FormControl
        fullWidth={fullWidth}
        className={classes.formControl}
        margin={margin}
      >
        {label && <InputLabel htmlFor="name-multiple">{label}</InputLabel>}
        <Select
          className={classes.root}
          disabled={disabled}
          multiple={multiple}
          value={value}
          onChange={(event) => {
            handleUpdate(event.target.value); // updates the value to parent
          }}
          input={<Input id={"name-multiple" + Math.random()} />}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 200,
              },
            },
          }}
        >
          {allowEmpty && (
            <MenuItem
              key={"empty-key"}
              value={""}
              style={{
                fontWeight: 500,
              }}
            >
              {emptyLabel}
            </MenuItem>
          )}
          {options.map((o) => (
            <MenuItem
              key={o[idKey] || o}
              value={o[idKey] || o}
              style={{ fontWeight: 500, ...(o.style || {}) }}
              title={o[nameKey] || o}
            >
              {labelRenderer(o[nameKey] || o, labelFormatter)}
            </MenuItem>
          ))}
        </Select>
        {helper && <FormHelperText>{helper}</FormHelperText>}
      </FormControl>
    </div>
  );
}

FormSelect.propTypes = {
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  helper: PropTypes.string,
  value: PropTypes.any,
  handleUpdate: PropTypes.func,
  containerStyles: PropTypes.object,
  emptyLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelFormatter: PropTypes.func,
  idKey: PropTypes.string,
  nameKey: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default withStyles(styles)(FormSelect);
