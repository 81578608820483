import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useTrackedState } from "../../../../../../../../../common/components/campaigncreator/store";
import {
  Button,
  Grid,
  Typography,
  InputAdornment,
  Tooltip,
  FormControlLabel,
  Checkbox,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ActionsTextField from "../../../../../../../../../../ReusableComponents/ActionsTextField";
import MultiSelect from "../../../../../../../../../../ReusableComponents/MultiSelect";
import ModuleConfigSection from "../../../../../../../../../../ReusableComponents/ModuleConfigSection";
import ExpansionModuleConfig from "../../../../../../../../../../ReusableComponents/ExpansionModuleConfig";
import { useDateFilters } from "../../../../../../../../../common/utils";
import { useAuth } from "../../../../../../../../../../../contexts/AuthContext";
import { getEventAttributesAPI } from "../../../../../../../../../../common/actions";
import { APP_PLATFORMS } from "../../../../../../../../../common/components/campaigncreator/constants";

const IDENTIFIER_REGEX = /^[a-zA-Z][a-zA-Z0-9_]*$/g;

export const PERSONALISATION_TYPE_ENUM = [
  { label: "User Property", value: "u" },
  { label: "Session Property", value: "s" },
  { label: "Application(View ID)", value: "v" },
  { label: "Response Parameter", value: "ar" },
  { label: "Event Property", value: "ev" },
];

export const IOS_PERSONALISATION_TYPE_ENUM = [
  { label: "User Property", value: "u" },
  { label: "Session Property", value: "s" },
  { label: "Application(View ID)", value: "v" },
];

const isEmpty = (value) => {
  return (
    value === undefined ||
    value === null ||
    value === "" ||
    (value.trim && value.trim() === "")
  );
};

const useStyles = makeStyles((theme) => ({
  closeIcon: (props) => ({
    color: "#A1ADB6",
    padding: 6,
    border: `1px solid ${theme.palette.text.secondary}`,
    borderRadius: 3,
    "&:hover": {
      background: "none",
    },
  }),
  expansionSymmaryContent: (props) => ({
    marginLeft: "15px !important",
  }),
  adornment: (props) => ({
    borderLeft: "1px solid #abc",
    cursor: "pointer",
    padding: 8,
    marginRight: -2,
    background: theme.palette.text.primary,
    color: theme.palette.secondary.main,
  }),
}));

export const Row = ({
  classes,
  id,
  isLast = false,
  dispatch,
  name,
  t,
  def,
  selectedTab,
  ...others
}) => {
  const state = useTrackedState();
  const {
    user_attributes = [],
    session_attributes = [],
    app_events = [],
    config: { meta: { platform } = {} },
  } = state;

  const styles = useStyles();

  const [variableName, setVariableName] = useState(name ?? "");
  const [variableType, setVariableType] = useState(
    t || PERSONALISATION_TYPE_ENUM[0].value
  );
  const [property, setProperty] = useState(others.n ?? others.p ?? "");
  const [defaultValue, setDefaultValue] = useState(def ?? "");
  const [propertyName, setPropertyName] = useState(others?.prop ?? "");

  // View type state constants
  const [readFromKeyedTag, setReadFromKeyedTag] = useState(
    others.rfkt ?? false
  );
  const [startIndex, setStartIndex] = useState(others.si ?? -1);
  const [endIndex, setEndIndex] = useState(others.ei ?? -1);
  const [eventProperties, setEventProperties] = useState([]);

  useEffect(() => {
    setVariableName(name);
    setVariableType(t);
    setProperty(others.n ?? others.p);
    setPropertyName(others?.prop);
    setDefaultValue(def);
    setReadFromKeyedTag(others.rfkt ?? false);
    setStartIndex(others.si ?? -1);
    setEndIndex(others.ei ?? -1);
  }, [property, propertyName, name, t, def, others]);

  const [dateFilters] = useDateFilters(true);
  const auth = useAuth();

  const getEventAttributes = useCallback(
    async name => {
      const res = await getEventAttributesAPI(auth, auth?.appId, {
        ...dateFilters,
        of: "users",
        event: name,
      });

      const data = res.filter(
        name =>
          ["apx_lt_count", "apx_lt_count_slot"].includes(name) ||
          name.indexOf("apx_") === -1
      );
      setEventProperties(data);
    },
    [auth, dateFilters]
  );

  useEffect(() => {
    if (variableType === "ev" && others?.n) getEventAttributes(others?.n);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEventAttributes, variableType]);

  const property_types = useMemo(
    () =>
      platform === APP_PLATFORMS.ios
        ? IOS_PERSONALISATION_TYPE_ENUM
        : PERSONALISATION_TYPE_ENUM,
    [platform]
  );

  return (
    <Grid container spacing={1}>
      <Grid item md={variableType && variableType === "ev" ? 5 : 6}>
        <Grid container spacing={1}>
          <Grid item md={6}>
            <ModuleConfigSection
              title="Variable name"
              typographyClassName={classes.moduleConfigRoot}
            >
              <ActionsTextField
                value={name}
                disabled={id !== "current"}
                required={true}
                onChange={(e) => {
                  dispatch({
                    type: "SET_PROPERTY",
                    id: id,
                    key: "name",
                    value: e.target.value,
                  });
                }}
                multiline={false}
                style={{ margin: 0 }}
              />
            </ModuleConfigSection>
          </Grid>
          <Grid item md={6}>
            <ModuleConfigSection
              title="Property Type"
              typographyClassName={classes.moduleConfigRoot}
            >
              <MultiSelect
                single
                clearable={false}
                options={property_types}
                value={t}
                handleChange={(value) => {
                  dispatch({
                    type: "SET_PROPERTY",
                    id: id,
                    key: "t",
                    value: value,
                  });
                }}
              />
            </ModuleConfigSection>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={variableType && variableType === "ev" ? 6 : 5}>
        <Grid container spacing={1}>
          {variableType && (variableType === "u" || variableType === "s") && (
            <>
              <Grid item md={6}>
                <ModuleConfigSection
                  title={
                    variableType === "u" ? "User Property" : "Session Property"
                  }
                  typographyClassName={classes.moduleConfigRoot}
                >
                  <MultiSelect
                    single
                    clearable={false}
                    options={(variableType === "u"
                      ? user_attributes
                      : session_attributes
                    )
                      .filter((x) => x.indexOf("apx_") === -1) // Filter out all apx_ related props
                      .filter(
                        // Filter out all selected props
                        (attrName) =>
                          others.variables.findIndex(
                            (item) => item.n === attrName,
                          ) === -1,
                      )
                      .map((x) => ({
                        label: x,
                        value: x,
                      }))}
                    value={others.n}
                    handleChange={(value) => {
                      dispatch({
                        type: "SET_PROPERTY",
                        id: id,
                        key: "n",
                        value: value,
                      });
                    }}
                  />
                </ModuleConfigSection>
              </Grid>
              <Grid item md={6}>
                <ModuleConfigSection
                  title="Default Value"
                  typographyClassName={classes.moduleConfigRoot}
                >
                  <ActionsTextField
                    value={def}
                    required={true}
                    onChange={(e) => {
                      dispatch({
                        type: "SET_PROPERTY",
                        id: id,
                        key: "def",
                        value: e.target.value,
                      });
                    }}
                    multiline={false}
                    style={{ margin: 0 }}
                  />
                </ModuleConfigSection>
              </Grid>
            </>
          )}
          {variableType && variableType === "v" && (
            <>
              <Grid item md={6}>
                <ModuleConfigSection
                  title="Application(View ID)"
                  typographyClassName={classes.moduleConfigRoot}
                >
                  <ActionsTextField
                    value={others.n}
                    style={{ margin: 0, overflow: "hidden" }}
                    required
                    multiline={false}
                    withPropertySelector={true}
                    isOtherUsage={true}
                    inputAdornments={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Preview Selector">
                            <Typography
                              variant="h6"
                              onClick={() => {
                                if (others.handleViewSelection) {
                                  others.handleViewSelection(id);
                                }
                              }}
                              className={styles.adornment}
                            >
                              Select
                            </Typography>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      dispatch({
                        type: "SET_PROPERTY",
                        id: id,
                        key: "n",
                        value: e.target.value,
                      });
                    }}
                  />
                </ModuleConfigSection>
              </Grid>
              <Grid item md={6}>
                <ModuleConfigSection
                  title="Default value"
                  typographyClassName={classes.moduleConfigRoot}
                >
                  <ActionsTextField
                    value={defaultValue}
                    required={true}
                    onChange={(e) =>
                      dispatch({
                        type: "SET_PROPERTY",
                        id: id,
                        key: "def",
                        value: e.target.value,
                      })
                    }
                    multiline={false}
                    style={{ margin: 0 }}
                  />
                </ModuleConfigSection>
              </Grid>
            </>
          )}
          {variableType && variableType === "ar" && (
            <>
              <Grid item md={6}>
                <ModuleConfigSection
                  title="Path"
                  typographyClassName={classes.moduleConfigRoot}
                >
                  <ActionsTextField
                    value={others.n ?? others.p}
                    required={true}
                    onChange={(e) => {
                      dispatch({
                        type: "SET_PROPERTY",
                        id: id,
                        key: "p",
                        value: e.target.value,
                      });
                    }}
                    multiline={false}
                    style={{ margin: 0 }}
                  />
                </ModuleConfigSection>
              </Grid>
              <Grid item md={6}>
                <ModuleConfigSection
                  title="Value"
                  typographyClassName={classes.moduleConfigRoot}
                >
                  <ActionsTextField
                    value={def}
                    required={true}
                    onChange={(e) => {
                      dispatch({
                        type: "SET_PROPERTY",
                        id: id,
                        key: "def",
                        value: e.target.value,
                      });
                    }}
                    multiline={false}
                    style={{ margin: 0 }}
                  />
                </ModuleConfigSection>
              </Grid>
            </>
          )}
          {variableType && variableType === "ev" && (
            <>
              <Grid item md={4}>
                <ModuleConfigSection
                  title="Event Name"
                  typographyClassName={classes.moduleConfigRoot}
                >
                  <MultiSelect
                    single
                    clearable={false}
                    options={app_events.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    value={others?.n}
                    handleChange={(value) => {
                      dispatch({
                        type: "SET_PROPERTY",
                        id,
                        key: "n",
                        value,
                      });
                      dispatch({
                        type: "SET_PROPERTY",
                        id,
                        key: "prop",
                        value: "",
                      });
                      getEventAttributes(value);
                    }}
                  />
                </ModuleConfigSection>
              </Grid>
              <Grid item md={4}>
                <ModuleConfigSection
                  title="Event Property"
                  typographyClassName={classes.moduleConfigRoot}
                >
                  <MultiSelect
                    single
                    clearable={false}
                    options={eventProperties?.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    value={others?.prop}
                    handleChange={(value) => {
                      dispatch({
                        type: "SET_PROPERTY",
                        id,
                        key: "prop",
                        value,
                      });
                    }}
                  />
                </ModuleConfigSection>
              </Grid>
              <Grid item md={4}>
                <ModuleConfigSection
                  title="Default Value"
                  typographyClassName={classes.moduleConfigRoot}
                >
                  <ActionsTextField
                    value={def}
                    required={true}
                    onChange={(e) => {
                      dispatch({
                        type: "SET_PROPERTY",
                        id: id,
                        key: "def",
                        value: e.target.value,
                      });
                    }}
                    multiline={false}
                    style={{ margin: 0 }}
                  />
                </ModuleConfigSection>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        md={1}
        justifyContent="flex-end"
        style={{
          display: isLast ? "unset" : "flex",
          justifyContent: isLast ? "unset" : "center",
          alignItems: isLast ? "unset" : "flex-end",
        }}
      >
        {isLast ? (
          <Button
            color="primary"
            variant="contained"
            disabled={
              isEmpty(variableName) ||
              isEmpty(variableType) ||
              isEmpty(property)
            }
            style={{
              borderRadius: 3,
              marginTop: 28,
            }}
            onClick={() => {
              const index = others.variables.findIndex(
                (variable) => variable.name === variableName,
              );
              if (index !== -1) {
                others.showError(
                  "Variable with name '" + variableName + "' already exists",
                );
                return;
              }

              if (variableName.match(IDENTIFIER_REGEX) === null) {
                others.showError(
                  "Variable names must start with an alphabet and valid variable name can contain alpha-numerics and underscore(_) only",
                );
                return;
              }

              var otherProps = {};
              switch (variableType) {
                case "u":
                  otherProps.t = "u";
                  otherProps.n = property;
                  break;
                case "s":
                  otherProps.t = "s";
                  otherProps.n = property;
                  break;
                case "ev":
                  otherProps.t = "ev";
                  otherProps.n = property;
                  otherProps.prop = propertyName;
                  break;
                case "ar":
                  otherProps.t = "ar";
                  otherProps.p = property;
                  break;
                default:
                  otherProps.t = "v";
                  otherProps.st = "";
                  otherProps.n = property;
                  otherProps.rfkt = false;
                  otherProps.si = -1;
                  otherProps.ei = -1;
                  break;
              }
              dispatch({
                type: "ADD_VARIABLE",
                id: id,
                ...otherProps,
              });
              window.apx_variables.push(variableName);
              if (selectedTab === 0 && others.insertVariable) {
                others.insertVariable(variableName);
              }
            }}
          >
            Add
          </Button>
        ) : (
          <IconButton
            onClick={() => {
              dispatch({
                type: "DELETE_VARIABLE",
                id: id,
              });
              window.apx_variables = window.apx_variables.filter(
                (name) => name !== variableName,
              );
              if (selectedTab === 0 && others.deleteVariable) {
                others.deleteVariable(variableName);
              }
            }}
            className={styles.closeIcon}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Grid>
      {variableType && variableType === "v" && (
        <Grid item xs={12}>
          <ExpansionModuleConfig
            title="Advanced Filters"
            defaultExpanded={false}
            titleTypoStyles={{
              textTransform: "capitalize",
              fontWeight: 500,
              fontSize: 13,
            }}
            summaryStyles={{
              display: "flex",
              flexDirection: "row-reverse",
              borderBottom: "none",
              padding: 0,
            }}
            summaryContentClass={styles.expansionSymmaryContent}
            detailsStyles={{ padding: 2 }}
          >
            <Grid container spacing={1}>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={readFromKeyedTag}
                      onChange={(e) =>
                        dispatch({
                          type: "SET_PROPERTY",
                          id: id,
                          key: "rfkt",
                          value: e.target.checked,
                        })
                      }
                    />
                  }
                  label="Get Text From Keyed Tag"
                />
              </Grid>
              <Grid item style={{ display: "flex" }} alignItems="center">
                <Typography>Get text between</Typography>
                <ActionsTextField
                  value={startIndex}
                  style={{
                    maxWidth: 72,
                    marginBottom: 0,
                    margin: "0 6px",
                  }}
                  InputProps={{ min: 0 }}
                  placeholder="from"
                  type="number"
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    setStartIndex(value);
                    if (value > endIndex) {
                      setEndIndex(value);
                      dispatch({
                        type: "SET_PROPERTY",
                        id: id,
                        key: "ei",
                        value: value,
                      });
                    }
                    dispatch({
                      type: "SET_PROPERTY",
                      id: id,
                      key: "si",
                      value: value,
                    });
                  }}
                  textFieldStyle={{ height: 8 }}
                />
                <Typography> and </Typography>
                <ActionsTextField
                  value={endIndex}
                  style={{
                    maxWidth: 72,
                    marginBottom: 0,
                    margin: "0 6px",
                  }}
                  InputProps={{ min: startIndex === -1 ? 0 : startIndex }}
                  placeholder="to"
                  type="number"
                  onChange={(e) =>
                    dispatch({
                      type: "SET_PROPERTY",
                      id: id,
                      key: "ei",
                      value: Number(e.target.value),
                    })
                  }
                  textFieldStyle={{ height: 8 }}
                />
                <Typography>of text</Typography>
              </Grid>
            </Grid>
          </ExpansionModuleConfig>
        </Grid>
      )}
    </Grid>
  );
};
