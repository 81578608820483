import React, { useMemo } from "react";
import { Popper } from "@material-ui/core";

export default function MaterialPopper(props) {
  const { placement = "bottom" } = props;

  const position = useMemo(() => {
    if (placement === "top") {
      return { marginBottom: 10 };
    }
    return { marginTop: 5 };
  }, [placement]);

  return (
    <Popper
      open={props.target !== null}
      anchorEl={props.target}
      style={{
        zIndex: 10,
        ...(props.style || {}),
        ...position,
      }}
      onClick={(e) => e.stopPropagation()}
      placement={placement}
    >
      {props.children}
    </Popper>
  );
}
