import React, { useState, useEffect } from "react";
import Box from "../Box";
import { Grid, Button, Typography, TextField } from "@material-ui/core";
import FilterIcon from "@material-ui/icons/FilterList";
import MultiSelect from "../MultiSelect";
import FormSelect from "../FormSelect";
import Attribute from "./Attribute";
import { useTrackedState, useDispatch } from "./store";
import {
  ACTION_ADD_EVENT_ATTRIBUTE,
  ACTION_SET_EVENT_NAME,
  ACTION_REMOVE_ATTRIBUTES,
  ACTION_SET_EVENT_COUNT_OPERATOR,
  ACTION_SET_EVENT_COUNT_VALUE,
} from "./reducer";
import { memo } from "react-tracked";
import { getEventAttributesAPI } from "../../common/actions";
import { useAuth } from "../../../contexts/AuthContext";
import ColumnDiv from "../ColumnDiv";
import { useCommonDashboardStyles } from "../../common/constants";
import RowDiv from "../RowDiv";

const Event = memo(
  ({
    id: eventId,
    name,
    title,
    attributes,
    count,
    events: eventList,
    disabled,
    clearable,
    operators,
    withBox,
    showWhereCount = false,
  }) => {
    const auth = useAuth();

    const classes = useCommonDashboardStyles();

    // Global State
    const state = useTrackedState();
    const dispatch = useDispatch();
    const { filters, appId } = state;

    // Local State
    const [eventAttributes, setEventAttributes] = useState([]);
    const [eventName, setEventName] = useState(name);

    const setName = (name) => {
      setEventName(name);
      dispatch({
        type: ACTION_SET_EVENT_NAME,
        id: eventId,
        name: name,
      });
      if (name === "") {
        dispatch({
          type: ACTION_REMOVE_ATTRIBUTES,
          id: eventId,
        });
      }
    };

    const fetchAttributes = () => {
      getEventAttributesAPI(auth, appId, {
        ...filters,
        event: eventName,
      }).then((values) => {
        setEventAttributes(values);
      });
    };

    // Run this effect whenever an event is selected
    useEffect(() => {
      // Fetch if and only if name has some non-empty value
      if (eventName !== "") {
        fetchAttributes();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth, appId, filters]);

    // Add Attribute for Event
    const addAttribute = (eventId) => {
      dispatch({
        type: ACTION_ADD_EVENT_ATTRIBUTE,
        id: eventId,
      });
    };

    const grid = (
      <>
        <Grid container spacing={1} key={eventId}>
          <Grid item xs={12}>
            <RowDiv center styles={{ gap: 10 }}>
              <ColumnDiv>
                <Typography className={classes.typographyCaption500}>
                  Select Event
                </Typography>
                <MultiSelect
                  value={eventName}
                  options={eventList.map((o) => ({ label: o, value: o }))}
                  handleChange={(name) => {
                    setName(name);
                    fetchAttributes();
                  }}
                  single
                  disabled={disabled}
                  clearable={clearable || true}
                  style={{ minWidth: 180 }}
                />
              </ColumnDiv>
              {showWhereCount && (
                <RowDiv center styles={{ gap: 10, marginTop: 14 }}>
                  <Typography className={classes.typographyCaption500}>
                    where count{" "}
                  </Typography>
                  <FormSelect
                    value={count.operator}
                    options={operators.filter(
                      (operator) =>
                        operator.label !== "!=" &&
                        operator.label !== "∋" &&
                        operator.label !== "∌"
                    )}
                    placeholder="="
                    handleUpdate={(operator) => {
                      dispatch({
                        type: ACTION_SET_EVENT_COUNT_OPERATOR,
                        id: eventId,
                        operator: operator,
                      });
                    }}
                    single
                    disabled={disabled}
                    idKey="value"
                    nameKey="label"
                    margin="none"
                  />
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <TextField
                      type="number"
                      required
                      placeholder="2"
                      value={count.value}
                      style={{ maxWidth: 60 }}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        dispatch({
                          type: ACTION_SET_EVENT_COUNT_VALUE,
                          id: eventId,
                          value: value,
                        });
                      }}
                      disabled={disabled}
                    />
                    <Typography
                      style={{
                        display: "inline-block",
                        lineHeight: "3.5em",
                        marginLeft: 6,
                      }}
                      className={classes.typographyCaption500}
                    >
                      {" "}
                      time (s)
                    </Typography>
                  </div>
                </RowDiv>
              )}
            </RowDiv>
          </Grid>
          <Grid item xs={12} style={{ marginLeft: 40 }}>
            {attributes.map(({ id, name, operator, value }) => (
              <React.Fragment key={id}>
                <Attribute
                  disabled={disabled}
                  eventId={eventId}
                  id={id}
                  name={name}
                  operator={operator}
                  value={value}
                  operators={operators}
                  eventName={eventName}
                  options={eventAttributes}
                />
              </React.Fragment>
            ))}
            {
              <Button
                color="primary"
                onClick={() => {
                  addAttribute(eventId);
                }}
                style={{ margin: "10px 0" }}
              >
                Add Filter
                <FilterIcon />
              </Button>
            }
          </Grid>
        </Grid>
      </>
    );

    if (withBox) {
      return (
        <Box key={eventId} title={title || name} collapsible defaultExpanded>
          {grid}
        </Box>
      );
    } else {
      return grid;
    }
  }
);

Event.whyDidYouRender = true;

export default Event;
