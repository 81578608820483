import React from "react"
import { render } from "react-dom"
import Root from "./Application"

/**
 * For Loader
 * @type {HTMLElement | null}
 */
const loader = document.getElementById("loader")
document.body.style.overflowY = "overlay"
loader.remove()
render(<Root />, document.getElementById("root"))
