import { produce } from "immer";
import { GRAPH_DEFAULTS } from "../../../../constants";
import {
  DATA_DISPLAY_FORMAT_ENUM,
  RETENTION_ENUM,
  SET_FIRST_EVENT,
  SET_NEXT_EVENT,
  SET_EVENT_LIST,
  SET_DAY_WINDOW,
  SET_DAY_WINDOW_TEXT,
  SET_RETENTION_WINDOW,
  SET_DATA_DISPLAY_FORMAT,
  SET_SWITCH_VALUE,
  SET_RETENTION_DATA,
  SET_RETENTION_HEADER,
  SET_RETENTION_USAGE,
} from "./constants";

export const initialState = {
  retentionData: {},
  retention_data_ftu: {},
  retention_data_return: {},
  event_attribute_values: [],
  event_attributes: {},
  selectedValues: [],
  retention_type: RETENTION_ENUM.FTU,
  retention_usage: [],
  retention_usage_count: 0,
  retention_usage_type: 1,
  retention_day_window: 1,
  retention_header: [],
  eventList: [],
  switchValue: GRAPH_DEFAULTS.switchValue,
  retentionWindow: 1,
  dayWindow: 1,
  retention: {
    events: [],
    query: {
      first_event: null,
      next_event: null,
    },
  },
  dayWindowText: 15,
  groupWindows: false,
  dataDisplayFormat: DATA_DISPLAY_FORMAT_ENUM.PERCENT,
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case SET_FIRST_EVENT:
      draft.retention.query.first_event =
        action.value.length > 0 ? action.value[0] : null;
      break;
    case SET_NEXT_EVENT:
      draft.retention.query.next_event =
        action.value.length > 0 ? action.value[0] : null;
      break;
    case SET_EVENT_LIST:
      draft.eventList = action.value;
      break;
    case SET_DAY_WINDOW:
      draft.dayWindow = action.value;
      break;
    case SET_DAY_WINDOW_TEXT:
      draft.dayWindowText = action.value;
      break;
    case SET_RETENTION_WINDOW:
      draft.retentionWindow = action.value;
      break;
    case SET_DATA_DISPLAY_FORMAT:
      draft.dataDisplayFormat = action.value;
      break;
    case SET_SWITCH_VALUE:
      draft.switchValue = action.value;
      break;
    case SET_RETENTION_DATA:
      draft.retentionData = action.value;
      break;
    case SET_RETENTION_HEADER:
      draft.retention_header = action.value;
      break;
    case SET_RETENTION_USAGE:
      draft.retention_usage = action.value;
      break;
    default:
      return draft;
  }
});
