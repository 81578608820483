import { roundOffNumber } from "../../../../utils";

export const makeSortableTableData = (funnels = [], groupByData = []) => {
  let keys = [],
    data = [];
  if (funnels.length > 0 && groupByData.length > 0) {
    keys = [
      "attribute",
      ...groupByData[0].funnel.map((o, index) => o.name + String(index)),
    ];
    data = groupByData.map(item => {
      const obj = { attribute: item.attributes[0] };
      item.funnel.forEach((el, index) => {
        obj[el.name + String(index)] = `${el.count} (${roundOffNumber(
          el.percent
        )} %)`;
      });
      return obj;
    });
  }
  return { keys, data };
};

export const comparator = (a, b, isASC = false) => {
  const valueA = Number(a.replace(/\(.*\)/, "").trim());
  const valueB = Number(b.replace(/\(.*\)/, "").trim());
  let sortVal = 0;
  if (valueA > valueB) {
    sortVal = 1;
  }
  if (valueA < valueB) {
    sortVal = -1;
  }
  if (sortVal !== 0 && isASC) {
    sortVal = sortVal * -1;
  }
  return sortVal;
};

export const getPercent = (str) => {
  const matcher = str.match(/\(.+ %/);
  if (matcher) {
    return Number(matcher[0].slice(1, -1).trim());
  } else return 0;
};

export const percentComparator = (a, b, isASC = false) => {
  const valueA = getPercent(a);
  const valueB = getPercent(b);
  let sortVal = 0;
  if (valueA > valueB) {
    sortVal = 1;
  }
  if (valueA < valueB) {
    sortVal = -1;
  }
  if (sortVal !== 0 && isASC) {
    sortVal = sortVal * -1;
  }
  return sortVal;
};
