import { Button, Typography } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import Apxor from "apxor";
import React, { useMemo, useState } from "react";
import { isExperienceDashboard } from "../../../../../../../../../config";
import { APXOR_SDK_CATEGORY_CONSTANTS } from "../../../../../../../../../constants/Endpoints";
import generateShortID from "../../../../../../../../../utils";
import BootstrapTooltip from "../../../../../../../../ReusableComponents/BootstrapTooltip";
import ColumnDiv from "../../../../../../../../ReusableComponents/ColumnDiv";
import {
  EXPERIENCE_CONFIG,
  GUIDED_CAMPAIGN_EVENTS,
  GUIDED_SURVEY_EVENTS,
} from "../../../../../campaignsholder/constants";
import {
  CONDITIONS_ENUM,
  CONTEXT_EVENT_TYPES_ENUM,
  EVENT_CONDTION_TYPES,
  SET_ADD_CONDITION,
  SET_DEMO_UPSERT_CONDITION,
  SET_OPERATOR_AND_SEQUENCE_ENABLED,
  SET_REMOVE_CONDITION,
  SET_SNACKBAR_ERROR_MESSAGE,
  SET_UPDATE_CONDITION,
  TRIGGER_CONFIG,
} from "../../../../constants";
import { useTracked } from "../../../../store";
import { useCommonConfigStyles } from "../../../../utils";
import OptionSelect from "../../../OptionSelect";
import EventRow from "./components/EventRow";

export const getDefaultCombineOperator = (conditions) => {
  if (
    conditions &&
    conditions.length > 0 &&
    !conditions[0].hasOwnProperty("dummy_event_type")
  ) {
    return conditions[0].combine_operator
  }
  return CONDITIONS_ENUM.OR
}

export default function NewContextBuilder({ app }) {
  const classes = useCommonConfigStyles()

  const [state, dispatch] = useTracked()

  const {
    config: {
      meta: { platform, experience_type = "" },
      conditions = [],
      sequence_enabled = false,
    },
    app_events = [],
    app_screens = [],
    app_screen_names: customScreenNames = [],
    client_events = [],
  } = state

  const [combineOperator, setCombineOperator] = useState(
    getDefaultCombineOperator(conditions),
  )

  const [demo_app_event, set_demo_app_event] = useState(null)

  /**
   * Commenting this as CUstomers have explicitly asked to set trigger on same app event combinations
   * with different properties.
   * https://gtl.apxor.com/apxor/issue-tracker/-/issues/491
   */
  const appEvents = app_events
  //   .filter(
  //   (name) =>
  //     conditions.findIndex((condition) => condition.details.name === name) ===
  //     -1
  // );
  const clientEvents = client_events
  //   .filter(
  //   name =>
  //     conditions.findIndex(condition => condition.details.name === name) === -1
  // );
  const appScreenNames = app_screens

  //   .filter(
  //   name =>
  //     conditions.findIndex(condition => condition.details.name === name) === -1
  // );

  const tourEvents = useMemo(
    () =>
      experience_type === EXPERIENCE_CONFIG.GUIDED_CAMPAIGN
        ? GUIDED_CAMPAIGN_EVENTS
        : GUIDED_SURVEY_EVENTS,
    [experience_type],
  )

  /**
   * FIXME: Can be refactored by exporting the next 3 functions after moving outside the component, or a custom hook maybe?
   */
  const addCondition = (event_type, conditions_type) => {
    const condition = {
      _id: generateShortID(),
      event_type: CONTEXT_EVENT_TYPES_ENUM[event_type],
      context_rule_type: event_type,
      type: "did",
      sequence: sequence_enabled ? conditions.length + 1 : -1,
      time_bounds: {
        lower: 0,
        upper: 6000000,
      },
      count_config: {
        count: 1,
        operator: "GTE",
      },
      combine_operator: combineOperator,
      activity: "",
      details: {
        additional_info: {},
        name: "",
      },
      ...TRIGGER_CONFIG,
    }
    dispatch({
      type: SET_ADD_CONDITION,
      condition,
      conditions_type,
    })
  }

  const demoUpsertCondition = (idx) => {
    set_demo_app_event(idx)
    const condition = {
      _id: generateShortID(),
      ...tourEvents[idx].config,
    }

    dispatch({
      type: SET_DEMO_UPSERT_CONDITION,
      condition,
    })
  }

  const removeCondition = (index, conditions_type) => {
    dispatch({
      type: SET_REMOVE_CONDITION,
      index,
      conditions_type,
    })
  }

  const updateOperator = (
    conditions_type = EVENT_CONDTION_TYPES.DEFAULT_CS,
  ) => (operator, sequence_enabled = false) => {
    setCombineOperator(operator)
    dispatch({
      type: SET_OPERATOR_AND_SEQUENCE_ENABLED,
      operator,
      enabled: sequence_enabled,
      conditions_type,
    })
  }

  const [displayTooltip, setDisplayTooltip] = useState(false)

  return isExperienceDashboard() &&
    [EXPERIENCE_CONFIG.GUIDED_CAMPAIGN, EXPERIENCE_CONFIG.GUIDED_SURVEY].some(
      (exp_type) => exp_type === experience_type,
    ) ? (
    <OptionSelect
      id="template_trigger_events"
      noneSelectable={true}
      direction="horizontal"
      numPerRow={2}
      value={tourEvents.map((_, idx) => idx).indexOf(demo_app_event)}
      radioOptions={{
        hasCheckboxOrRadio: true,
        radioFirst: true,
      }}
      handler={(selectedIndex) => {
        demoUpsertCondition(selectedIndex)
        Apxor.logEvent(
          "TriggerSelected",
          {
            "experience_type": experience_type ?? "",
          },
          APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS,
        )
      }}
      style={{
        paddingTop: 8,
        paddingBottom: 12,
      }}
      buttonContainerStyles={{
        minWidth: "max-content",
        flex: "1 0 auto",
      }}
      options={tourEvents}
      cardStyles={{
        boxShadow: "none",
        minWidth: "500px",
        paddingLeft: "15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        borderRadius: 3,
        background: "#F6F8FA",
        minHeight: "70px",
      }}
      fieldTitleStyles={{
        marginBottom: 0,
        marginLeft: 6,
      }}
      fieldDescriptionStyles={{
        marginTop: 4,
        marginLeft: 8,
      }}
    />
  ) : (
    <div id="campaign-trigger" style={{ position: "relative" }}>
      {isExperienceDashboard() && (
        <BootstrapTooltip
          open={isExperienceDashboard() && displayTooltip}
          title={
            <>
              <b>Editing Trigger</b> is disabled for this{" "}
              {experience_type === EXPERIENCE_CONFIG.CART_DELETION_SURVEY
                ? "survey"
                : "campaign"}{" "}
              in the demo app
            </>
          }
          placement="bottom-start"
        >
          <LockIcon
            color="primary"
            style={{
              zIndex: "10",
              position: "absolute",
              top: "4px",
              right: "4px",
            }}
          />
        </BootstrapTooltip>
      )}
      <div
        onMouseEnter={() => setDisplayTooltip(true)}
        onMouseLeave={() => setDisplayTooltip(false)}
        style={
          isExperienceDashboard() &&
          [
            EXPERIENCE_CONFIG.FEATURE_DISCOVERY,
            EXPERIENCE_CONFIG.CART_DELETION_SURVEY,
          ].some((exp_type) => exp_type === experience_type)
            ? {
                opacity: 0.5,
              }
            : {}
        }
      >
        {conditions.length > 0 && (
          <Typography className={classes.typographyHeading}>Events:</Typography>
        )}
        <ColumnDiv
          styles={{
            borderLeft: "1px solid #C5CDD2",
            marginLeft: conditions.length > 0 ? 15 : 0,
            paddingTop: conditions.length > 0 ? 20 : 0,
            paddingBottom: conditions.length > 0 ? 10 : 0,
          }}
        >
          {conditions.length > 0 &&
            conditions.map((condition, index) => (
              <EventRow
                key={condition._id}
                appId={app.app_id}
                condition={condition}
                index={index}
                dispatch={dispatch}
                combineOperator={combineOperator}
                sequenceEnabled={sequence_enabled}
                updateOperator={updateOperator()}
                platform={platform}
                features={app.features || []}
                handleOnChange={(data) => {
                  dispatch({
                    type: SET_UPDATE_CONDITION,
                    index,
                    condition: data,
                    conditions_type: EVENT_CONDTION_TYPES.DEFAULT_CS,
                  })
                }}
                handleOnDelete={() =>
                  removeCondition(index, EVENT_CONDTION_TYPES.DEFAULT_CS)
                }
                appEvents={appEvents}
                appScreenNames={appScreenNames}
                customScreenNames={customScreenNames}
                clientEvents={clientEvents}
              />
            ))}
        </ColumnDiv>
        <Button
          id="add-event-button"
          variant="contained"
          color="primary"
          disabled={
            isExperienceDashboard() &&
            [
              EXPERIENCE_CONFIG.FEATURE_DISCOVERY,
              EXPERIENCE_CONFIG.CART_DELETION_SURVEY,
            ].some((exp_type) => exp_type === experience_type)
          }
          onClick={() => {
            const cannotAdd =
              appEvents.length < 1 &&
              clientEvents.length < 1 &&
              appScreenNames.length < 1
            dispatch({
              type: SET_SNACKBAR_ERROR_MESSAGE,
              value: cannotAdd ? "No events left to add" : null,
            })
            if (cannotAdd) {
              return
            }
            addCondition("APP", EVENT_CONDTION_TYPES.DEFAULT_CS)
          }}
        >
          {conditions.length > 0 ? "+ Add another event" : "Add Event"}
        </Button>
      </div>
    </div>
  )
}
