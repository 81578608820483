import { Grid, Typography } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import LockIcon from "@material-ui/icons/Lock";
import React, { useState } from "react";
import { isExperienceDashboard } from "../../../../../../../../../config";
import ActionsTextField from "../../../../../../../../ReusableComponents/ActionsTextField";
import BootstrapTooltip from "../../../../../../../../ReusableComponents/BootstrapTooltip";
import RowDiv from "../../../../../../../../ReusableComponents/RowDiv";
import { SET_CAMPAIGN_PUBLISH_CG } from "../../../../constants"
import { useCommonConfigStyles } from "../../../../utils"
import AdvancedFilters from "./components/advancedfilters"
import TargetAudience from "./components/TargetAudience"

export default function TargetAudienceSection({
  app_id,
  features,
  CG,
  parentDispatch,
  isExperiment,
}) {
  const classes = useCommonConfigStyles()
  const [displayTooltip, setDisplayTooltip] = useState(false)

  return (
    <Grid
      container
      spacing={1}
      style={{
        position: "relative",
      }}
    >
      {isExperienceDashboard() && (
        <BootstrapTooltip
          open={isExperienceDashboard() && displayTooltip}
          title={
            <>
              <b>Audience Configuration</b> feature is disabled in the demo app
            </>
          }
          placement="bottom-start"
        >
          <LockIcon
            color="primary"
            style={{
              zIndex: "10",
              position: "absolute",
              top: "4px",
              right: "4px",
            }}
          />
        </BootstrapTooltip>
      )}
      <div
        style={isExperienceDashboard() ? { opacity: 0.5 } : {}}
        onMouseEnter={() => setDisplayTooltip(true)}
        onMouseLeave={() => setDisplayTooltip(false)}
      >
        <TargetAudience appId={app_id} features={features} />
        <Grid item xs={12} style={{ mardingTop: 10 }}>
          <AdvancedFilters appId={app_id} />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={4}>
              <RowDiv
                center
                styles={{
                  gap: "3px",
                  marginTop: 10,
                }}
              >
                <Typography
                  className={classes.typographyCaption}
                  style={{
                    letterSpacing: "0.01em",
                  }}
                >
                  Campaign Control Group
                </Typography>
                <BootstrapTooltip
                  title="These users will not receive the campaign"
                  placement="top"
                >
                  <HelpOutlineIcon
                    style={{
                      color: "#002845",
                      fontSize: "15px",
                    }}
                  />
                </BootstrapTooltip>
              </RowDiv>
            </Grid>
            <Grid item xs={8}>
              <ActionsTextField
                value={CG ?? 3}
                style={{
                  maxWidth: 72,
                  marginBottom: 0,
                  margin: "0 6px",
                }}
                InputProps={{ min: 1 }}
                onChange={(e) =>
                  parentDispatch({
                    type: SET_CAMPAIGN_PUBLISH_CG,
                    value: Number(e.target.value),
                    isExperiment: isExperiment,
                  })
                }
                type="number"
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Grid>
  )
}
