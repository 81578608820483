import { APP_PLATFORMS, AUDIENCE_TYPES } from "./constants";
import moment from "moment";
import { useCommonDashboardStyles } from "../../../../common/constants";
import React from "react";
import { ANSWER_MODES } from "../../../components/surveys/components/design/constants";
import { INAPP_LAYOUT_TYPES } from "../../../components/walkthroughs/components/design/constants";

export const SECTIONS_ENUM = {
  ALL: "All",
  ACTIVE: "Active",
  PAUSED: "Paused",
  COMPLETED: "Completed",
  DRAFTS: "Drafts",
};

export const getPlatformBasedTimelimitKey = messages2 => {
  const {
    new_message: {
      meta: { platform },
    },
  } = messages2;
  if (platform === APP_PLATFORMS.ios) {
    return "time_limits_in_day";
  } else {
    return "at_specific_time";
  }
};

export const getRouteForActionType = actionType => {
  switch (actionType) {
    case "walkthroughs":
    case "walkthrough":
      return "walkthroughs";
    case "surveys":
    case "survey":
      return "surveys";
    default:
      return "404";
  }
};

/**
 * Adding all the validations here
 */
export const checkIfValidToPublishOrTest = (
  stateRef,
  validationRef,
  setValidationMessage
) => {
  const {
    audience: {
      audience_type,
      cohort_id,
      segment_id,
      attributes: { user = [], session = [], hasUser = false, hasSes = false },
      hasVer,
      ver = {},
    },
    meta: { name, config_type, platform, isExperiment },
    goal_event = "",
    goal_met_check = false,
    conditions = [],
    pre_cs = [],
    terminate_info: { conditions: delete_conditions = [] } = {},
    validity: { start_date, end_date },
    ui = {},
    uis = [],
  } = stateRef.current;

  let currentMessage = "";

  // Date validations
  if (moment(end_date).isSameOrBefore(moment(start_date))) {
    currentMessage = "End Date cannot be less than Start Date";
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage;
      setValidationMessage(currentMessage);
    }
    return false;
  }

  //Not sure for what window of start time we need to be liberal with checks
  if (
    moment(end_date).isBefore(moment.now()) ||
    moment(start_date).isBefore(moment().startOf("day"))
  ) {
    currentMessage = "End Date or Start date cannot be past date time";
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage;
      setValidationMessage(currentMessage);
    }
    return false;
  }

  // Campaign Name validation
  if (name === "") {
    currentMessage = "Campaign must have a name";
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage;
      setValidationMessage(currentMessage);
    }
    return false;
  }

  // Design Validation
  if (config_type === "survey") {
    if (ui.questions?.length < 1) {
      currentMessage = "At least one question must be added";

      if (validationRef.current !== currentMessage) {
        validationRef.current = currentMessage;
        setValidationMessage(currentMessage);
      }
      return false;
    }
  }

  // Design Validations for NPS
  if (config_type === "survey" && ui.questions?.length > 0) {
    let [redFlag, redIndex] = [false, null];

    ui.questions.every((eachQuestion, index) => {
      if (
        eachQuestion.answer_mode &&
        eachQuestion.answer_mode === ANSWER_MODES.rating &&
        eachQuestion.advanced_options
      ) {
        [redFlag, redIndex] = [true, index];
        return false;
      }
      return true;
    });
    if (redFlag) {
      if (
        ui.is_response_based_survey &&
        ((!ui.show_success_message && redIndex !== ui.questions.length - 1) ||
          (ui.show_success_message && redIndex !== ui.questions.length - 2))
      ) {
        currentMessage =
          "NPS with Advanced options should be the last question in Response based survey";
        validationRef.current = currentMessage;
        setValidationMessage(currentMessage);
      }
    }
  }

  if (config_type === "survey" && ui.questions?.length > 0) {
    ui.questions.map((eachQuestion, index) => {
      if (
        eachQuestion.answer_mode &&
        eachQuestion.answer_mode === ANSWER_MODES.rating &&
        eachQuestion.advanced_options
      ) {
        if (eachQuestion.rules && eachQuestion.rules.length > 0) {
          eachQuestion.rules.map((eachRule) => {
            if (!eachRule.min || !eachRule.max) {
              currentMessage =
                "Minimum and Maximum conditions for Advanced rules should not be empty";
              validationRef.current = currentMessage;
              setValidationMessage(currentMessage);
              return false;
            }
          });
        } else {
          currentMessage =
            "Rules should not be empty when advanced options are enabled";
          validationRef.current = currentMessage;
          setValidationMessage(currentMessage);
          return false;
        }
      }
    });
  }

  if (config_type === "walkthrough" || config_type === "onboarding") {
    if (uis.length < 1) {
      currentMessage = "At least one template must be added";
      if (validationRef.current !== currentMessage) {
        validationRef.current = currentMessage;
        setValidationMessage(currentMessage);
      }
      return false;
    }
    if (
      uis?.length > 1 &&
      uis?.some((ui) => ui?.ui?.layout_type === INAPP_LAYOUT_TYPES.VIDEOS)
    ) {
      if (isExperiment) {
        const variants = stateRef.current?.experiment?.variants;
        Object.keys(variants)
          .filter((key) => key !== "CG")
          .map((eachVariant) => {
            if (
              variants[eachVariant].indices &&
              variants[eachVariant].indices.length > 0
            ) {
              const variantSpecificUIS = uis.filter((eachUI) =>
                variants[eachVariant].indices.includes(eachUI.index),
              );
              let count = 0;
              variantSpecificUIS.map((eachVariantUI) => {
                if (
                  ui?.type === "IN_APP" &&
                  ui?.ui?.layout_type === INAPP_LAYOUT_TYPES.VIDEOS
                ) {
                  count += 1;
                }
                if (count > 1) {
                  currentMessage =
                    "Walkthrough can't be created with Video In-Apps";
                  if (validationRef.current !== currentMessage) {
                    validationRef.current = currentMessage;
                    setValidationMessage(currentMessage);
                  }
                  return false;
                }
              });
            }
          });
      } else {
        currentMessage = "Walkthrough can't be created with Video In-Apps";
        if (validationRef.current !== currentMessage) {
          validationRef.current = currentMessage;
          setValidationMessage(currentMessage);
        }
        return false;
      }

      uis.map((eachUI) => {
        if (eachUI?.ui?.layout_type === INAPP_LAYOUT_TYPES.VIDEOS) {
          if (eachUI?.ui?.v_url === "") {
            currentMessage = "A video URL is required for Video InApp";
            if (validationRef.current !== currentMessage) {
              validationRef.current = currentMessage;
              setValidationMessage(currentMessage);
            }
            return false;
          } else {
            if (
              !eachUI?.ui?.v_url?.startsWith("https://") ||
              !eachUI?.ui?.v_url?.endsWith(".mp4")
            ) {
              currentMessage =
                "Only mp4 videos with secure https streaming are supported for Video InApp";
              if (validationRef.current !== currentMessage) {
                validationRef.current = currentMessage;
                setValidationMessage(currentMessage);
              }
              return false;
            }
          }
        }

        if (eachUI?.ui?.binding && eachUI?.ui?.activity === "") {
          currentMessage =
            "When binding is enabled in Video InApp, an activity or a screen must be selected";
          if (validationRef.current !== currentMessage) {
            validationRef.current = currentMessage;
            setValidationMessage(currentMessage);
          }
          return false;
        }

        // Download-When Conditions Validation
        if (eachUI?.ui?.download && pre_cs?.length === 0) {
          currentMessage =
            "When the video loading type is DOWNLOAD, make sure to add at least one download-when condition";
          if (validationRef.current !== currentMessage) {
            validationRef.current = currentMessage;
            setValidationMessage(currentMessage);
          }
          return false;
        } else {
          let condition = pre_cs.find((condition) => {
            if (condition.type === "did") {
              return condition.details.name === "";
            }
            if (condition.type === "didn't") {
              return (
                condition.details.additional_info.nkpi.length < 1 ||
                condition.trigger.details.name === ""
              );
            }
            return null;
          });
          if (condition) {
            if (condition.type === "did") {
              currentMessage = "Event name must be selected in Download Events";
            } else if (condition.type === "didn't") {
              if (condition.details.additional_info.nkpi.length < 1) {
                currentMessage =
                  "At least one event must be selected in Who didn't download event";
              } else {
                currentMessage =
                  "After Event name must be selected for didn't event condition";
              }
            }
            if (validationRef.current !== currentMessage) {
              validationRef.current = currentMessage;
              setValidationMessage(currentMessage);
            }
            return false;
          }

          condition = pre_cs.find((condition) => {
            if (condition.type === "did") {
              return (
                Object.keys(condition.details.additional_info).findIndex(
                  (attributeName) =>
                    attributeName === "" ||
                    condition.details.additional_info[attributeName].val === "",
                ) !== -1
              );
            }
            if (condition.type === "didn't") {
              return (
                Object.keys(
                  condition.trigger.details.additional_info,
                ).findIndex(
                  (attributeName) =>
                    attributeName === "" ||
                    condition.trigger.details.additional_info[attributeName]
                      .val === "",
                ) !== -1
              );
            }
            return null;
          });
          if (condition) {
            if (condition.type === "did") {
              currentMessage =
                "Property name and value must be selected for event " +
                condition.details.name;
            } else if (condition.type === "didn't") {
              currentMessage =
                "Property name and value must be selected for after event " +
                condition.trigger.details.name;
            }
            if (validationRef.current !== currentMessage) {
              validationRef.current = currentMessage;
              setValidationMessage(currentMessage);
            }
            return false;
          }
        }

        // Delete-When Conditions Validation
        if (eachUI?.ui?.download && delete_conditions?.length === 0) {
          currentMessage =
            "When the video loading type is DOWNLOAD, make sure to add at least one delete-when condition";
          if (validationRef.current !== currentMessage) {
            validationRef.current = currentMessage;
            setValidationMessage(currentMessage);
          }
          return false;
        } else {
          let condition = delete_conditions.find((condition) => {
            if (condition.type === "did") {
              return condition.details.name === "";
            }
            if (condition.type === "didn't") {
              return (
                condition.details.additional_info.nkpi.length < 1 ||
                condition.trigger.details.name === ""
              );
            }
            return null;
          });
          if (condition) {
            if (goal_met_check && !goal_event) {
              currentMessage = (
                <>
                  Goal event must be selected if <b>Goal Met</b> is one of the{" "}
                  <b>delete conditions</b>
                </>
              );
            } else if (condition.type === "did") {
              currentMessage = "Event name must be selected in Delete Events";
            } else if (condition.type === "didn't") {
              if (condition.details.additional_info.nkpi.length < 1) {
                currentMessage =
                  "At least one event must be selected in Who didn't delete event";
              } else {
                currentMessage =
                  "After Event name must be selected for didn't event condition";
              }
            }
            if (validationRef.current !== currentMessage) {
              validationRef.current = currentMessage;
              setValidationMessage(currentMessage);
            }
            return false;
          }

          condition = delete_conditions.find((condition) => {
            if (condition.type === "did") {
              return (
                Object.keys(condition.details.additional_info).findIndex(
                  (attributeName) =>
                    attributeName === "" ||
                    condition.details.additional_info[attributeName].val === "",
                ) !== -1
              );
            }
            if (condition.type === "didn't") {
              return (
                Object.keys(
                  condition.trigger.details.additional_info,
                ).findIndex(
                  (attributeName) =>
                    attributeName === "" ||
                    condition.trigger.details.additional_info[attributeName]
                      .val === "",
                ) !== -1
              );
            }
            return null;
          });
          if (condition) {
            if (condition.type === "did") {
              currentMessage =
                "Property name and value must be selected for event " +
                condition.details.name;
            } else if (condition.type === "didn't") {
              currentMessage =
                "Property name and value must be selected for after event " +
                condition.trigger.details.name;
            }
            if (validationRef.current !== currentMessage) {
              validationRef.current = currentMessage;
              setValidationMessage(currentMessage);
            }
            return false;
          }
        }
      });
    }
  }

  // Target audience validation
  if (audience_type === AUDIENCE_TYPES[1].key && segment_id === "") {
    currentMessage = "Segment must be selected";
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage;
      setValidationMessage(currentMessage);
    }
    return false;
  }

  if (audience_type === AUDIENCE_TYPES[2].key && cohort_id === "") {
    currentMessage = "Cohort must be selected";
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage;
      setValidationMessage(currentMessage);
    }
    return false;
  }

  //A/B Distribution Validation
  if (config_type === "walkthrough" && isExperiment) {
    const variants = stateRef.current?.experiment?.variants;
    if (variants) {
      const distributions = Object.keys(variants).map((eachVariant) => {
        return variants[eachVariant].distribution;
      });
      const sum = distributions.reduce((a, b) => a + b, 0);
      if (sum !== 100) {
        currentMessage =
          "In Target Page, Variant Distributions are UnEven, make sure they amount to 100%";
        if (validationRef.current !== currentMessage) {
          validationRef.current = currentMessage;
          setValidationMessage(currentMessage);
        }
        return false;
      }
    }
  }

  if (config_type === "walkthrough" && isExperiment) {
    const manualExperiment =
      stateRef.current?.experiment?.settings?.isManualExperiment;
    if (!manualExperiment) {
      if (!goal_event) {
        currentMessage =
          "Goal Event has to be selected for AutoPublish campaigns";
        if (validationRef.current !== currentMessage) {
          validationRef.current = currentMessage;
          setValidationMessage(currentMessage);
        }
        return false;
      }
    }
  }

  if (
    config_type === "walkthrough" &&
    isExperiment &&
    !stateRef.current?.experiment?.settings?.isManualExperiment
  ) {
    const campaignStartDate =
      stateRef.current?.experiment?.publishSettings?.time_settings?.start_date;
    const campaignEndDate =
      stateRef.current?.experiment?.publishSettings?.time_settings?.end_date;
    const testEndDate = stateRef.current?.validity?.end_date;

    if (
      moment(campaignEndDate).isSameOrBefore(moment(campaignStartDate)) ||
      moment(campaignStartDate).isSameOrBefore(moment(testEndDate))
    ) {
      currentMessage =
        "Campaign Start Time cannot be less than Test End Time and Campaign End Time";
      if (validationRef.current !== currentMessage) {
        validationRef.current = currentMessage;
        setValidationMessage(currentMessage);
      }
      return false;
    }
  }

  if (config_type === "walkthrough" && isExperiment) {
    const variants = stateRef.current?.experiment?.variants;
    if (variants) {
      Object.keys(variants)
        .filter((key) => key !== "CG")
        .map((eachVariant) => {
          if (variants[eachVariant].indices.length <= 0) {
            currentMessage =
              "One or more variants are Empty, please add a step";
            if (validationRef.current !== currentMessage) {
              validationRef.current = currentMessage;
              setValidationMessage(currentMessage);
            }
            return false;
          }
        });
    }
  }

  if (hasUser && (!Array.isArray(user) || user.length < 1)) {
    currentMessage = "No Properties selected in By User Properties section";
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage;
      setValidationMessage(currentMessage);
    }
    return false;
  }

  if (
    hasUser &&
    user.findIndex((attr) => attr.name === "" || attr.value?.length < 1) !== -1
  ) {
    currentMessage =
      "By User Properties section should not contain empty property name or value";
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage;
      setValidationMessage(currentMessage);
    }
    return false;
  }

  if (hasSes && (!Array.isArray(session) || session.length < 1)) {
    currentMessage = "No Properties selected in By Session Properties section";
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage;
      setValidationMessage(currentMessage);
    }
    return false;
  }

  if (
    hasSes &&
    session.findIndex((attr) => attr.name === "" || attr.value?.length < 1) !==
      -1
  ) {
    currentMessage =
      "By Session Properties section should not contain empty property name or value";
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage;
      setValidationMessage(currentMessage);
    }
    return false;
  }

  if (
    hasVer &&
    ((platform === APP_PLATFORMS.android && !Number(ver.val)) ||
      (platform === APP_PLATFORMS.ios &&
        ver.val.trim &&
        ver.val.trim().length < 1))
  ) {
    currentMessage = "Expected valid version value in By App Version section";
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage;
      setValidationMessage(currentMessage);
    }
    return false;
  }

  // Context conditions validation
  if (conditions.length === 0) {
    currentMessage =
      "In Target page, Trigger must contain at least one condition";
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage;
      setValidationMessage(currentMessage);
    }
    return false;
  } else {
    let condition = conditions.find((condition) => {
      if (condition.type === "did") {
        return condition.details.name === "";
      }
      if (condition.type === "didn't") {
        return (
          condition.details.additional_info.nkpi.length < 1 ||
          condition.trigger.details.name === ""
        );
      }
      return null;
    });
    if (condition) {
      if (condition.type === "did") {
        currentMessage =
          "Event name must be selected in Trigger Events section of Target Page";
      } else if (condition.type === "didn't") {
        if (condition.details.additional_info.nkpi.length < 1) {
          currentMessage =
            "At least one event must be selected in Who didn't trigger event section of Target Page";
        } else {
          currentMessage =
            "After Event name must be selected for didn't event condition in Target Page";
        }
      }
      if (validationRef.current !== currentMessage) {
        validationRef.current = currentMessage;
        setValidationMessage(currentMessage);
      }
      return false;
    }

    condition = conditions.find((condition) => {
      if (condition.type === "did") {
        return (
          Object.keys(condition.details.additional_info).findIndex(
            (attributeName) =>
              attributeName === "" ||
              condition.details.additional_info[attributeName].val === "",
          ) !== -1
        );
      }
      if (condition.type === "didn't") {
        return (
          Object.keys(condition.trigger.details.additional_info).findIndex(
            (attributeName) =>
              attributeName === "" ||
              condition.trigger.details.additional_info[attributeName].val ===
                "",
          ) !== -1
        );
      }
      return null;
    });
    if (condition) {
      if (condition.type === "did") {
        currentMessage =
          "Property name and value must be selected for event " +
          condition.details.name;
      } else if (condition.type === "didn't") {
        currentMessage =
          "Property name and value must be selected for after event " +
          condition.trigger.details.name;
      }
      if (validationRef.current !== currentMessage) {
        validationRef.current = currentMessage;
        setValidationMessage(currentMessage);
      }
      return false;
    }
  }

  if (validationRef.current !== currentMessage) {
    setValidationMessage("");
    validationRef.current = "";
  }
  return true;
};

export const useCommonConfigStyles = useCommonDashboardStyles;

