/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import React from "react";
import { Typography } from "@material-ui/core";
import { LOGICAL_OPERATORS, MESSAGE_EVENTS } from "../../../../constants";
import { getGroupFromList } from "../../../../utils";

const { GT, GTE, EQ, NEQ, LT, LTE } = LOGICAL_OPERATORS;

const conditionalUtterance = (operator) => {
  switch (operator) {
    case GT:
      return " more than ";
    case GTE:
      return " at least ";
    case LT:
      return " less than ";
    case LTE:
      return " at most ";
    case EQ:
      return " exactly ";
    case NEQ:
      return " not ";
    default:
      return "";
  }
};

const renderProperty = (property, isMessageEvent, messages = []) => {
  const { name, value, operator } = property;
  let values = value.join(", ");
  if (isMessageEvent && name === "id") {
    //Only for message events
    const message = getGroupFromList(messages, values);
    if (message && message._id === values) {
      values = message.meta.name + " (" + values + ")";
    }
  }
  return (
    <Typography style={{ marginLeft: 60 }} key={name} component="h3">
      <strong>{name} </strong> {conditionalUtterance(operator)}{" "}
      <strong>{values}</strong>
    </Typography>
  );
};

export const renderEvent = (event = {}, showCondition, condition) => {
  if (event.hasOwnProperty("name")) {
    const {
      name,
      count: { value, operator } = { value: 1, operator: GTE },
      attributes = [],
    } = event;
    const validAttributes = validProperties(attributes); //having valid name
    return (
      <div style={{ marginLeft: 60 }} key={name}>
        {showCondition && (
          <Typography variant="caption" component="h2">
            {condition}
          </Typography>
        )}
        <Typography component="h3">
          <strong>{name} </strong>
          {value > 0 && (
            <span>
              {conditionalUtterance(operator)}
              {value === 1 ? (
                <strong> once </strong>
              ) : (
                <span>
                  <strong> {value} </strong> times
                </span>
              )}
            </span>
          )}
        </Typography>
        {validAttributes.length > 0 && (
          <div>
            <Typography
              variant="body2"
              component="h2"
              style={{
                lineHeight: "18px",
                fontSize: "14px",
                fontWeight: "800",
                fontStyle: "normal",
                fontFamily: "Manrope",
              }}
            >
              having{" "}
            </Typography>
            {validAttributes.map((attribute) =>
              renderProperty(
                attribute,
                MESSAGE_EVENTS.hasOwnProperty(name.toUpperCase())
              )
            )}
          </div>
        )}
      </div>
    );
  } else {
    return "Anything";
  }
};

const validProperties = (properties) =>
  properties.filter((o) => o && o.name && o.name.length > 0); //having valid name

export const SegmentConfig = ({
  condition,
  event = [],
  session = [],
  user = [],
}) => {
  const label = "Users";
  const positiveEvents = event.filter((o) => o.count.value > 0);
  const negativeEvents = event.filter((o) => o.count.value === 0);
  const validUserProperties = validProperties(user);
  const validSessionProperties = validProperties(session);

  if (user.length < 1 && event.length < 1 && session.length < 1) {
    return (
      <div style={{ width: "100%" }}>
        <Typography variant="body2">All Users Segment</Typography>
      </div>
    );
  }

  return (
    <div style={{ width: "100%" }}>
      <Typography
        variant="body2"
        style={{
          lineHeight: "18px",
          fontSize: "14px",
          fontWeight: "800",
          fontStyle: "normal",
          fontFamily: "Manrope",
        }}
      >
        {"Filter Audience By"}
      </Typography>
      <br></br>
      {(user.length > 0 || session.length > 0 || event.length > 0) && (
        <Typography
          variant="body2"
          style={{
            lineHeight: "18px",
            fontSize: "14px",
            fontWeight: "800",
            fontStyle: "normal",
            fontFamily: "Manrope",
          }}
        >
          {label}
        </Typography>
      )}
      {(validUserProperties.length > 0 ||
        validSessionProperties.length > 0) && (
        <Typography
          color="textSecondary"
          variant="body2"
          component="h2"
          style={{
            marginLeft: 40,
            lineHeight: "18px",
            fontSize: "14px",
            fontWeight: "800",
            fontStyle: "normal",
            fontFamily: "Manrope",
          }}
        >
          having
        </Typography>
      )}
      {validUserProperties.map((property) => renderProperty(property))}
      {validSessionProperties.map((property) => renderProperty(property))}
      {positiveEvents.length > 0 && (
        <Typography
          color="textSecondary"
          variant="body2"
          component="h2"
          style={{
            marginLeft: 40,
            lineHeight: "18px",
            fontSize: "14px",
            fontWeight: "800",
            fontStyle: "normal",
            fontFamily: "Manrope",
          }}
        >
          who did{" "}
        </Typography>
      )}
      {positiveEvents.map(
        (event, index) =>
          event.name && renderEvent(event, index !== 0, condition)
      )}
      {negativeEvents.length > 0 && (
        <Typography
          color="textSecondary"
          variant="body2"
          component="h2"
          style={{
            marginLeft: 40,
            lineHeight: "18px",
            fontSize: "14px",
            fontWeight: "800",
            fontStyle: "normal",
            fontFamily: "Manrope",
          }}
        >
          who never did{" "}
        </Typography>
      )}
      {negativeEvents.map(
        (event, index) =>
          event.name && renderEvent(event, index !== 0, condition)
      )}
    </div>
  );
};
