/**
 * Created by jyothi on 4/12/17.
 */
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    width: 56,
    height: 56,
    margin: 12,
  },
  card: {
    display: "flex",
    marginBottom: 12,
    padding: theme.spacing(3),
  },
  details: {
    display: "flex",
  },
  content: {
    flex: "auto",
    paddingBottom: "16px !important",
  },
});

function NumberBox(props) {
  const {
    classes,
    heading = "h2",
    subheading = "subtitle1",
    icon = undefined,
  } = props;

  return (
    <Card className={classes.card}>
      <div>{icon && React.cloneElement(icon, { className: classes.root })}</div>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography variant="h4">{heading}</Typography>
          <Typography
            variant="body1"
            noWrap
            style={{ textTransform: "uppercase" }}
          >
            {subheading}
          </Typography>
        </CardContent>
      </div>
    </Card>
  );
}

NumberBox.propTypes = {
  theme: PropTypes.object.isRequired,
  heading: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  subheading: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  icon: PropTypes.node,
};

export default withStyles(styles, { withTheme: true })(NumberBox);
