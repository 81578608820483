import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { PLATFORM_IMAGES } from "../../../../../App/styling/CustomCSS";
import ExpansionModuleConfig from "../../../../../ReusableComponents/ExpansionModuleConfig";
import FieldDescription from "./FieldDescription";

export default function GeneralSummaryComponent({ meta }) {
  return (
    <ExpansionModuleConfig title="Information" defaultExpanded>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <FieldDescription title="Platform" />
          {PLATFORM_IMAGES(meta.platform)}
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={7}>
          <FieldDescription title="Name" />
          <Typography>{meta.name}</Typography>
          <br />
          <FieldDescription title="Description" />
          <Typography>{meta.description}</Typography>
        </Grid>
      </Grid>
    </ExpansionModuleConfig>
  );
}
