import { Button } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { toTitleCase } from "../../../../../../../../../../utils";
import { MODULE_TYPES } from "../../../../constants";
import { POSITION_ENUM } from "../../../../templates/common";
import {
  ButtonsHolder,
  BUTTONS_POSITION,
  HoverDiv,
  TextHolder,
} from "../../utils";

const RippleDiv = styled.div`
  position: absolute;
  animation: load-progress 1s infinite;
  background: white;
  animation-delay: 1s;
  z-index: 2;
  border-radius: ${(props) => (props.shape === "circle" ? "50%" : "0")};
  @keyframes load-progress {
    0% {
      opacity: 1;
      width: 0px;
      height: 0px;
    }
    100% {
      opacity: 0;
      width: ${(props) => (props.shape === "circle" ? "360px" : "270px")};
      height: ${(props) => (props.shape === "circle" ? "360px" : "270px")};
    }
  }
`;

const getRotationByPosition = (position) => {
  switch (position) {
    case POSITION_ENUM.BOTTOM:
      return 0;
    case POSITION_ENUM.RIGHT:
      return -90;
    case POSITION_ENUM.TOP:
      return 180;
    case POSITION_ENUM.LEFT:
      return 90;
    default:
      return 0;
  }
};

const getFlexDirection = (position) => {
  switch (position) {
    case POSITION_ENUM.BOTTOM:
      return "column-reverse";
    case POSITION_ENUM.TOP:
      return "column";
    case POSITION_ENUM.LEFT:
      return "row";
    case POSITION_ENUM.RIGHT:
      return "row-reverse";
    default:
      return "row";
  }
};

const getSizeByShape = (shape) => {
  switch (shape) {
    case "circle":
      return {
        borderRadius: "50%",
        height: 360,
        width: 360,
      };
    default:
      return {
        height: 540,
        width: 270,
      };
  }
};

export default function CoachmarkSimulator({
  moduleType,
  onModuleSelection,
  ui: {
    position,
    bg_color,
    opacity,
    coach_mark_details: { type: coachmark_shape, has_ripple, has_arrow },
    buttons_config = [],
    button_position = BUTTONS_POSITION.RIGHT,
    text_config: textConfig,
  },
}) {
  return (
    <div
      style={{
        height: 540,
        width: 1000,
        borderRadius: 16,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      <HoverDiv
        style={{
          border:
            moduleType === MODULE_TYPES.BACKGROUND ? "1px dashed #2979ff" : "",
          ...getSizeByShape(coachmark_shape),
        }}
        onClick={(e) => {
          onModuleSelection(e, MODULE_TYPES.BACKGROUND);
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: getFlexDirection(position),
            backgroundColor: bg_color,
            opacity: opacity,
            ["margin" + toTitleCase(position)]: -52,
            zIndex: 1,
            ...getSizeByShape(coachmark_shape),
          }}
        >
          <div
            style={{
              zIndex: 5,
            }}
          >
            <TextHolder
              selected={module === MODULE_TYPES.DESCRIPTION}
              bgColor={bg_color}
              config={textConfig}
              handleModuleSelection={(e) => {
                e.stopPropagation();
                onModuleSelection(e, MODULE_TYPES.DESCRIPTION);
              }}
            >
              Some Text Goes Here
            </TextHolder>
            {buttons_config.length > 0 && (
              <ButtonsHolder
                bgColor={bg_color}
                buttonPosition={button_position}
                config={buttons_config}
                moduleType={moduleType}
                onModuleSelection={onModuleSelection}
              />
            )}
          </div>
          {has_arrow && (
            <div
              style={{
                transform: `rotate(${getRotationByPosition(position)}deg)`,
                zIndex: 5,
              }}
            >
              <ArrowUpwardIcon style={{ color: "#ffffff" }} />
            </div>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              zIndex: 5,
            }}
          >
            <Button
              variant={"outlined"}
              style={{
                margin: 12,
                background: "#ffffff",
              }}
            >
              Gecko
            </Button>
          </div>

          {has_ripple && <RippleDiv color={bg_color} shape={coachmark_shape} />}
        </div>
      </HoverDiv>
    </div>
  );
}
