import React from "react";
import { Grid, Typography } from "@material-ui/core";
import RecurAndLimits from "./RecurAndLimits";
import recur_limits from "../../../../assets/recur_limits.png";
import {
  IconImageComponent,
  useTypographyStyles,
} from "../../../../../../utils";

export default function RecurAndLimitsSection() {
  const typographyClasses = useTypographyStyles();

  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={3}
        style={{
          display: "flex",
          flexDirection: "column",
          borderRight: "1px solid #E4E7E9",
          alignItems: "center",
          justifyContent: "center",
          padding: 16,
        }}
      >
        <Typography
          className={typographyClasses.h5_800}
          style={{ marginBottom: 10 }}
        >
          LIMITS
        </Typography>
        {IconImageComponent(recur_limits, "Limits", {
          width: 157,
          height: 157,
        })}
      </Grid>
      <Grid item xs={9} style={{ padding: 24 }}>
        <RecurAndLimits />
      </Grid>
    </Grid>
  );
}
