import { Box, Typography } from "@material-ui/core";
import React from "react";
import CustomMaterialUiDialog from "../../ReusableComponents/CustomMaterialUIDialog";

export default function AppUnderReviewDialog({ open }) {
  return (
    <CustomMaterialUiDialog
      openFlag={open}
      onClose={() => {}}
      onDialogClose={() => {}}
      noTitleBg
      hideCloseIcon
      maxWidth="380px"
      isFullWidth={false}
      dialogContent={
        <Box
          style={{
            padding: "26px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography
            style={{
              fontSize: "20px",
              fontWeight: "700",
            }}
          >
            Your application is under review.
          </Typography>
          <Typography
            style={{
              fontSize: "13px",
              fontWeight: "500",
              marginTop: "14px",
              maxWidth: "280px",
            }}
          >
            Our team in verifying your application. It usually takes 1-2 hours
            to complete. In case it is not complete please +91 - 7569853100
          </Typography>
        </Box>
      }
    />
  );
}
