import {
  ACTION_CLASSES,
  TOOLTIP_LAYOUT_TYPES,
  INAPP_LAYOUT_TYPES,
  NEXT_BUTTON_STATE,
} from "./constants";
import tooltip_short_menu from "./assets/tooltip_short_menu.png";
import tooltip_with_text from "./assets/tooltip_with_text.png";
import coachmark from "./assets/coachmark.png";
import inapp_custom_html from "./assets/inapp_custom_html.png";
import inapp_footer from "./assets/inapp_footer.png";
import inapp_header from "./assets/inapp_header.png";
import tooltip_with_image from "./assets/tooltip_with_image.png";
import inapp_modal_popup from "./assets/inapp_modal_popup.png";
import inapp_fullscreen_modal from "./assets/inapp_fullscreen_modal.png";
import inapp_videos from "./assets/inapp_videos.svg";
import carousel from "./assets/carousel.svg";
import nudge from "./assets/nudge.png";
import tooltip_with_coachmark from "./assets/tooltip_with_coachmark.png";
import { encode, IconImageComponent } from "../../../../common/utils";

export const getActionClassInfo = (
  action_class = ACTION_CLASSES.TOOLTIP,
  layout_type,
  isOnboardingMode = false,
) => {
  const styles = { width: "auto", height: 40, marginRight: 10, marginLeft: 18 };
  if (action_class === ACTION_CLASSES.TOOLTIP) {
    switch (layout_type) {
      case TOOLTIP_LAYOUT_TYPES.DEFAULT:
        return {
          image: IconImageComponent(
            isOnboardingMode ? tooltip_with_coachmark : tooltip_with_text,
            "tooltip_with_text",
            styles,
          ),
          name: "tooltip_with_text",
        };
      case TOOLTIP_LAYOUT_TYPES.IMAGE_TEXT_BUTTONS:
        return {
          image: IconImageComponent(
            tooltip_with_image,
            "image_text_tooltip",
            styles,
          ),
          name: "image_text_tooltip",
        };
      case TOOLTIP_LAYOUT_TYPES.ACTION_BUTTONS:
        return {
          image: IconImageComponent(
            tooltip_short_menu,
            "tooltip_short_menu",
            styles,
          ),
          name: "tooltip_short_menu",
        };
      case TOOLTIP_LAYOUT_TYPES.HTML_OVERLAY:
        return {
          image: IconImageComponent(inapp_modal_popup, "custom_html", styles),
          name: "custom_html",
        };
      default:
        return {
          image: IconImageComponent(
            tooltip_with_text,
            "tooltip_with_text",
            styles,
          ),
          name: "tooltip_with_text",
        };
    }
  }

  if (action_class === ACTION_CLASSES.INAPP) {
    switch (layout_type) {
      case INAPP_LAYOUT_TYPES.FOOTER:
        return {
          image: IconImageComponent(inapp_footer, "inapp_footer", styles),
          name: "inapp_footer",
        };
      case INAPP_LAYOUT_TYPES.HEADER:
        return {
          image: IconImageComponent(inapp_header, "inapp_header", styles),
          name: "inapp_header",
        };
      case INAPP_LAYOUT_TYPES.CUSTOM_HTML:
        return {
          image: IconImageComponent(
            inapp_custom_html,
            "inapp_custom_html",
            styles,
          ),
          name: "inapp_custom_html",
        };
      case INAPP_LAYOUT_TYPES.FULL_SCREEN_MODAL:
        return {
          image: IconImageComponent(
            inapp_fullscreen_modal,
            "inapp_fullscreen_modal",
            styles,
          ),
          name: "inapp_fullscreen_modal",
        };
      case INAPP_LAYOUT_TYPES.POPOUT_MODAL:
        return {
          image: IconImageComponent(
            inapp_modal_popup,
            "inapp_modal_popup",
            styles,
          ),
          name: "inapp_modal_popup",
        };
      case INAPP_LAYOUT_TYPES.CAROUSEL:
        return {
          image: IconImageComponent(carousel, "carousel", styles),
          name: "inapp_carousel",
        };
      case INAPP_LAYOUT_TYPES.FLOATING:
        return {
          image: IconImageComponent(
            inapp_modal_popup,
            "inapp_modal_popup",
            styles,
          ),
          name: "inapp_floating",
        };
      case INAPP_LAYOUT_TYPES.VIDEOS:
        return {
          image: IconImageComponent(inapp_videos, "inapp_videos", styles),
          name: "inapp_videos",
        };
      default:
        return { image: "", name: "" };
    }
  }

  if (action_class === ACTION_CLASSES.NUDGE) {
    return {
      image: IconImageComponent(nudge, action_class, styles),
      name: action_class,
    };
  }

  if (action_class === ACTION_CLASSES.COACHMARK) {
    return {
      image: IconImageComponent(coachmark, action_class, styles),
      name: action_class,
    };
  }
};

export const getNextButtonConfig = (config) => {
  if (config.next)
    return {
      next: {
        ...config.next,
        text: config.lastStepText,
      },
    };
  else
    return {
      next: {
        ...NEXT_BUTTON_STATE.next,
        text: config.lastStepText,
      },
    };
};

const URL_PARAMS = ["_a", "_p", "_x", "_o", "_r"];

export const openURLInNewTab = (url, app_id, volatile_id = "") => {
  if (!url || !url.trim || url.trim() === "") {
    return;
  }

  try {
    const urlObj = new URL(url);
    urlObj.searchParams.append(
      URL_PARAMS[Math.round(Math.random() * 4)],
      encode(
        app_id,
        JSON.stringify({
          app_id,
          expiry: Date.now() + 5 * 60 * 1000,
          volatile_id,
        }),
      ),
    );
    window.open(urlObj.toString(), "_blank");
  } catch (e) {}
};

export const generateQRCodeURL = (url, app_id, volatile_id = "") => {
  if (!url || !url.trim || url.trim() === "") {
    return;
  }

  try {
    const urlObj = new URL(url);
    urlObj.searchParams.append(
      URL_PARAMS[Math.round(Math.random() * 4)],
      encode(
        app_id,
        JSON.stringify({
          app_id,
          expiry: Date.now() + 5 * 60 * 1000,
          volatile_id,
        }),
      ),
    );
    return urlObj.toString();
  } catch (e) {}
};

export const getUI = (index, uis, isExperiment = false) => {
  if (isExperiment)
    return uis.filter((eachUI) => eachUI.index === index)[0] || uis[index];
  else return uis[index];

};
