import { useReducer } from "react";
import { createContainer } from "react-tracked";
import { reducer, initialState } from "./reducer";

const useValue = () => useReducer(reducer, initialState);

export const {
  Provider,
  useTracked,
  useTrackedState,
  useUpdate: useDispatch,
} = createContainer(useValue);
