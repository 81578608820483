import { APP_PLATFORMS } from "../../../../../../../constants";
import {
  INAPP_LAYOUT_TYPES,
  COACHMARK_LAYOUT_TYPES,
  NUDGE_LAYOUT_TYPES,
  TOOLTIP_LAYOUT_TYPES,
  ACTION_CLASSES,
} from "../constants";
import { getInAppJSON } from "./inapp";
import { getNudgeJSON } from "./nudge";
import { getTooltipJSON } from "./tooltip";
import { getCoachmarkJSON } from "./coachmark";

const BASIC_TOOLTIP = "/assets/img/basic_tooltip.png";
const BASIC_TEXT_IMAGE_TOOLTIP = "/assets/img/basic_text_image.png";
const SHORT_MENU_TOOLTIP = "/assets/img/short_menu.png";
const INFORMATIVE_SPOTLIGHT_COACHMARK = "/assets/img/informative_spotlight.png";
const NUDGE = "/assets/img/nudge.png";
const HEADER_INAPP = "/assets/img/header.png";
const FOOTER_INAPP = "/assets/img/footer.png";
const MODAL_INAPP = "/assets/img/simple_modal.png";
const FULL_MODAL_INAPP = "/assets/img/fullscreen_cover.png";
const CAROUSEL = "/assets/img/carousel.svg";
//const FLOATING = "/assets/img/floating.svg";
const CUSTOM_HTML = "/assets/img/custom_html.png";
const VIDEOS = "/assets/img/inapp_video.png";

export const ACTION_TEMPLATES = (isOnboardingMode, platform) => {
  if (isOnboardingMode) {
    return {
      [ACTION_CLASSES.TOOLTIP]: [
        {
          type: TOOLTIP_LAYOUT_TYPES.IMAGE_TEXT_BUTTONS,
          available: true,
          display_text: "Text with Image",
          image: BASIC_TEXT_IMAGE_TOOLTIP,
          data: {
            ...getTooltipJSON(TOOLTIP_LAYOUT_TYPES.IMAGE_TEXT_BUTTONS),
          },
          requisites: [
            {
              platform: APP_PLATFORMS.android,
              minSDK: 258,
            },
            {
              platform: APP_PLATFORMS.ios,
              minSDK: 258,
            },
          ],
        },
        {
          type: TOOLTIP_LAYOUT_TYPES.HTML_OVERLAY,
          available: true,
          display_text: "HTML Overlay",
          image: CUSTOM_HTML,
          data: {
            ...getTooltipJSON(TOOLTIP_LAYOUT_TYPES.HTML_OVERLAY),
          },
          requisites: [
            {
              platform: APP_PLATFORMS.android,
              minSDK: 258,
            },
            {
              platform: APP_PLATFORMS.ios,
              minSDK: 258,
            },
          ],
        },
      ],
    };
  }

  if (platform === APP_PLATFORMS.web) {
    return {
      [ACTION_CLASSES.TOOLTIP]: [
        {
          type: TOOLTIP_LAYOUT_TYPES.DEFAULT,
          available: true,
          display_text: "Simple Text",
          image: BASIC_TOOLTIP,
          data: {
            ...getTooltipJSON(TOOLTIP_LAYOUT_TYPES.DEFAULT),
          },
          requisites: [
            {
              platform: APP_PLATFORMS.android,
              minSDK: 258,
            },
            {
              platform: APP_PLATFORMS.ios,
              minSDK: 258,
            },
          ],
        },
      ],
      [ACTION_CLASSES.COACHMARK]: [
        {
          type: COACHMARK_LAYOUT_TYPES.DEFAULT,
          available: true,
          display_text: "Simple Text (Coachmark Template)",
          image: INFORMATIVE_SPOTLIGHT_COACHMARK,
          data: {
            ...getCoachmarkJSON(COACHMARK_LAYOUT_TYPES.DEFAULT),
          },
          requisites: [
            {
              platform: APP_PLATFORMS.android,
              minSDK: 258,
            },
            {
              platform: APP_PLATFORMS.ios,
              minSDK: 258,
            },
          ],
        },
      ],
      [ACTION_CLASSES.INAPP]: [
        {
          type: INAPP_LAYOUT_TYPES.CUSTOM_HTML,
          available: true,
          display_text: "Custom HTML",
          image: CUSTOM_HTML,
          data: {
            ...getInAppJSON(INAPP_LAYOUT_TYPES.CUSTOM_HTML),
          },
          requisites: [
            {
              platform: APP_PLATFORMS.android,
              minSDK: 258,
            },
            {
              platform: APP_PLATFORMS.ios,
              minSDK: 258,
            },
          ],
        },
      ],
    };
  }
  if (platform === APP_PLATFORMS.ios) {
    return {
      [ACTION_CLASSES.TOOLTIP]: [
        {
          type: TOOLTIP_LAYOUT_TYPES.DEFAULT,
          available: true,
          display_text: "Simple Text",
          image: BASIC_TOOLTIP,
          data: {
            ...getTooltipJSON(TOOLTIP_LAYOUT_TYPES.DEFAULT),
          },
          requisites: [
            {
              platform: APP_PLATFORMS.android,
              minSDK: 258,
            },
            {
              platform: APP_PLATFORMS.ios,
              minSDK: 258,
            },
          ],
        },
        {
          type: TOOLTIP_LAYOUT_TYPES.IMAGE_TEXT_BUTTONS,
          available: true,
          display_text: "Text with Image",
          image: BASIC_TEXT_IMAGE_TOOLTIP,
          data: {
            ...getTooltipJSON(TOOLTIP_LAYOUT_TYPES.IMAGE_TEXT_BUTTONS),
          },
          requisites: [
            {
              platform: APP_PLATFORMS.android,
              minSDK: 258,
            },
            {
              platform: APP_PLATFORMS.ios,
              minSDK: 258,
            },
          ],
        },
      ],
      [ACTION_CLASSES.COACHMARK]: [
        {
          type: COACHMARK_LAYOUT_TYPES.DEFAULT,
          available: true,
          display_text: "Simple Text (Coachmark Template)",
          image: INFORMATIVE_SPOTLIGHT_COACHMARK,
          data: {
            ...getCoachmarkJSON(COACHMARK_LAYOUT_TYPES.DEFAULT),
          },
          requisites: [
            {
              platform: APP_PLATFORMS.android,
              minSDK: 258,
            },
            {
              platform: APP_PLATFORMS.ios,
              minSDK: 258,
            },
          ],
        },
      ],
      [ACTION_CLASSES.INAPP]: [
        {
          type: INAPP_LAYOUT_TYPES.HEADER,
          available: true,
          display_text: "Header",
          image: HEADER_INAPP,
          data: {
            ...getInAppJSON(INAPP_LAYOUT_TYPES.HEADER),
          },
          requisites: [
            {
              platform: APP_PLATFORMS.android,
              minSDK: 258,
            },
            {
              platform: APP_PLATFORMS.ios,
              minSDK: 258,
            },
          ],
        },
        {
          type: INAPP_LAYOUT_TYPES.FOOTER,
          available: true,
          display_text: "Footer",
          image: FOOTER_INAPP,
          data: {
            ...getInAppJSON(INAPP_LAYOUT_TYPES.FOOTER),
          },
          requisites: [
            {
              platform: APP_PLATFORMS.android,
              minSDK: 258,
            },
            {
              platform: APP_PLATFORMS.ios,
              minSDK: 258,
            },
          ],
        },
        {
          type: INAPP_LAYOUT_TYPES.POPOUT_MODAL,
          available: true,
          display_text: "Modal Popup",
          image: MODAL_INAPP,
          data: {
            ...getInAppJSON(INAPP_LAYOUT_TYPES.POPOUT_MODAL),
          },
          requisites: [
            {
              platform: APP_PLATFORMS.android,
              minSDK: 258,
            },
            {
              platform: APP_PLATFORMS.ios,
              minSDK: 258,
            },
          ],
        },
        {
          type: INAPP_LAYOUT_TYPES.FULL_SCREEN_MODAL,
          available: true,
          display_text: "Full Screen Cover",
          image: FULL_MODAL_INAPP,
          data: {
            ...getInAppJSON(INAPP_LAYOUT_TYPES.FULL_SCREEN_MODAL),
          },
          requisites: [
            {
              platform: APP_PLATFORMS.android,
              minSDK: 258,
            },
            {
              platform: APP_PLATFORMS.ios,
              minSDK: 258,
            },
          ],
        },
        {
          type: INAPP_LAYOUT_TYPES.CUSTOM_HTML,
          available: true,
          display_text: "Custom HTML",
          image: CUSTOM_HTML,
          data: {
            ...getInAppJSON(INAPP_LAYOUT_TYPES.CUSTOM_HTML),
          },
          requisites: [
            {
              platform: APP_PLATFORMS.android,
              minSDK: 258,
            },
            {
              platform: APP_PLATFORMS.ios,
              minSDK: 258,
            },
          ],
        },
      ],
      [ACTION_CLASSES.NUDGE]: [
        {
          type: NUDGE_LAYOUT_TYPES.DEFAULT,
          available: true,
          display_text: "Basic Notification",
          image: NUDGE,
          data: {
            ...getNudgeJSON(NUDGE_LAYOUT_TYPES.DEFAULT),
          },
          requisites: [
            {
              platform: APP_PLATFORMS.android,
              minSDK: 258,
            },
            {
              platform: APP_PLATFORMS.ios,
              minSDK: 258,
            },
          ],
        },
      ],
    };
  }
  return {
    [ACTION_CLASSES.TOOLTIP]: [
      {
        type: TOOLTIP_LAYOUT_TYPES.DEFAULT,
        available: true,
        display_text: "Simple Text",
        image: BASIC_TOOLTIP,
        data: {
          ...getTooltipJSON(TOOLTIP_LAYOUT_TYPES.DEFAULT),
        },
        requisites: [
          {
            platform: APP_PLATFORMS.android,
            minSDK: 258,
          },
          {
            platform: APP_PLATFORMS.ios,
            minSDK: 258,
          },
        ],
      },
      {
        type: TOOLTIP_LAYOUT_TYPES.ACTION_BUTTONS,
        available: true,
        display_text: "Short Menu",
        image: SHORT_MENU_TOOLTIP,
        data: {
          ...getTooltipJSON(TOOLTIP_LAYOUT_TYPES.ACTION_BUTTONS),
        },
        requisites: [
          {
            platform: APP_PLATFORMS.android,
            minSDK: 258,
          },
        ],
      },
      {
        type: TOOLTIP_LAYOUT_TYPES.IMAGE_TEXT_BUTTONS,
        available: true,
        display_text: "Text with Image",
        image: BASIC_TEXT_IMAGE_TOOLTIP,
        data: {
          ...getTooltipJSON(TOOLTIP_LAYOUT_TYPES.IMAGE_TEXT_BUTTONS),
        },
        requisites: [
          {
            platform: APP_PLATFORMS.android,
            minSDK: 258,
          },
          {
            platform: APP_PLATFORMS.ios,
            minSDK: 258,
          },
        ],
      },
    ],
    [ACTION_CLASSES.COACHMARK]: [
      {
        type: COACHMARK_LAYOUT_TYPES.DEFAULT,
        available: true,
        display_text: "Simple Text (Coachmark Template)",
        image: INFORMATIVE_SPOTLIGHT_COACHMARK,
        data: {
          ...getCoachmarkJSON(COACHMARK_LAYOUT_TYPES.DEFAULT),
        },
        requisites: [
          {
            platform: APP_PLATFORMS.android,
            minSDK: 258,
          },
          {
            platform: APP_PLATFORMS.ios,
            minSDK: 258,
          },
        ],
      },
    ],
    [ACTION_CLASSES.INAPP]: [
      {
        type: INAPP_LAYOUT_TYPES.HEADER,
        available: true,
        display_text: "Top Banner",
        image: HEADER_INAPP,
        data: {
          ...getInAppJSON(INAPP_LAYOUT_TYPES.HEADER),
        },
        requisites: [
          {
            platform: APP_PLATFORMS.android,
            minSDK: 258,
          },
          {
            platform: APP_PLATFORMS.ios,
            minSDK: 258,
          },
        ],
      },
      {
        type: INAPP_LAYOUT_TYPES.FOOTER,
        available: true,
        display_text: "Footer",
        image: FOOTER_INAPP,
        data: {
          ...getInAppJSON(INAPP_LAYOUT_TYPES.FOOTER),
        },
        requisites: [
          {
            platform: APP_PLATFORMS.android,
            minSDK: 258,
          },
          {
            platform: APP_PLATFORMS.ios,
            minSDK: 258,
          },
        ],
      },
      {
        type: INAPP_LAYOUT_TYPES.POPOUT_MODAL,
        available: true,
        display_text: "Center Modal",
        image: MODAL_INAPP,
        data: {
          ...getInAppJSON(INAPP_LAYOUT_TYPES.POPOUT_MODAL),
        },
        requisites: [
          {
            platform: APP_PLATFORMS.android,
            minSDK: 258,
          },
          {
            platform: APP_PLATFORMS.ios,
            minSDK: 258,
          },
        ],
      },
      {
        type: INAPP_LAYOUT_TYPES.FULL_SCREEN_MODAL,
        available: true,
        display_text: "Full Screen Cover",
        image: FULL_MODAL_INAPP,
        data: {
          ...getInAppJSON(INAPP_LAYOUT_TYPES.FULL_SCREEN_MODAL),
        },
        requisites: [
          {
            platform: APP_PLATFORMS.android,
            minSDK: 258,
          },
          {
            platform: APP_PLATFORMS.ios,
            minSDK: 258,
          },
        ],
      },
      {
        type: INAPP_LAYOUT_TYPES.CAROUSEL,
        available: true,
        display_text: "Carousel",
        image: CAROUSEL,
        data: {
          ...getInAppJSON(INAPP_LAYOUT_TYPES.CAROUSEL),
        },
        requisites: [
          {
            platform: APP_PLATFORMS.android,
            minSDK: 258,
          },
          {
            platform: APP_PLATFORMS.ios,
            minSDK: 258,
          },
        ],
      },
      {
        type: INAPP_LAYOUT_TYPES.VIDEOS,
        available: true,
        display_text: "Videos",
        image: VIDEOS,
        data: {
          ...getInAppJSON(INAPP_LAYOUT_TYPES.VIDEOS),
        },
        requisites: [
          {
            platform: APP_PLATFORMS.android,
            minSDK: 258,
          },
          {
            platform: APP_PLATFORMS.ios,
            minSDK: 258,
          },
        ],
      },
      {
        type: INAPP_LAYOUT_TYPES.CUSTOM_HTML,
        available: true,
        display_text: "Custom HTML",
        image: CUSTOM_HTML,
        data: {
          ...getInAppJSON(INAPP_LAYOUT_TYPES.CUSTOM_HTML),
        },
        requisites: [
          {
            platform: APP_PLATFORMS.android,
            minSDK: 258,
          },
          {
            platform: APP_PLATFORMS.ios,
            minSDK: 258,
          },
        ],
      },
    ],
    [ACTION_CLASSES.NUDGE]: [
      {
        type: NUDGE_LAYOUT_TYPES.DEFAULT,
        available: true,
        display_text: "Dot Type",
        image: NUDGE,
        data: {
          ...getNudgeJSON(NUDGE_LAYOUT_TYPES.DEFAULT),
        },
        requisites: [
          {
            platform: APP_PLATFORMS.android,
            minSDK: 258,
          },
          {
            platform: APP_PLATFORMS.ios,
            minSDK: 258,
          },
        ],
      },
    ],
  };
};

export const ACTION_TEMPLATE_MAP = {
  [ACTION_CLASSES.TOOLTIP]: {
    [TOOLTIP_LAYOUT_TYPES.DEFAULT]: {
      display_text: "Simple Text",
      image: BASIC_TOOLTIP,
    },
    [TOOLTIP_LAYOUT_TYPES.ACTION_BUTTONS]: {
      display_text: "Short Menu",
      image: SHORT_MENU_TOOLTIP,
    },
    [TOOLTIP_LAYOUT_TYPES.IMAGE_TEXT_BUTTONS]: {
      display_text: "Text with Image",
      image: BASIC_TEXT_IMAGE_TOOLTIP,
    },
  },
  [ACTION_CLASSES.COACHMARK]: {
    [COACHMARK_LAYOUT_TYPES.DEFAULT]: {
      display_text: "Simple Text (Coachmark Template)",
      image: INFORMATIVE_SPOTLIGHT_COACHMARK,
    },
  },
  [ACTION_CLASSES.INAPP]: {
    [INAPP_LAYOUT_TYPES.HEADER]: {
      display_text: "Header",
      image: HEADER_INAPP,
    },
    [INAPP_LAYOUT_TYPES.FOOTER]: {
      display_text: "Footer",
      image: FOOTER_INAPP,
    },
    [INAPP_LAYOUT_TYPES.POPOUT_MODAL]: {
      display_text: "Modal Popup",
      image: MODAL_INAPP,
    },
    [INAPP_LAYOUT_TYPES.FULL_SCREEN_MODAL]: {
      display_text: "Fullscreen Modal",
      image: FULL_MODAL_INAPP,
    },
    [INAPP_LAYOUT_TYPES.CAROUSEL]: {
      display_text: "Carousel",
      image: CAROUSEL,
    },
    [INAPP_LAYOUT_TYPES.VIDEOS]: {
      display_text: "Video",
      image: VIDEOS,
    },
    [INAPP_LAYOUT_TYPES.CUSTOM_HTML]: {
      display_text: "Custom HTML",
      image: CUSTOM_HTML,
    },
  },
  [ACTION_CLASSES.NUDGE]: {
    [NUDGE_LAYOUT_TYPES.DEFAULT]: {
      display_text: "Basic Notification",
      image: NUDGE,
    },
  },
};
