import { Divider, Grid } from "@material-ui/core";
import React from "react";
import { MODULE_TYPES } from "../../../../../constants";
import { ImageHolder, StyledHolder, TextHolder } from "../../../utils";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

function ActionTooltipButton({
  index: idx,
  buttonConfig: { color, text_config, description_config, image },
  moduleType,
  onModuleSelection,
}) {
  const index = idx + 1;

  return (
    <div
      style={{
        padding: "2px 4px",
        margin: "4px 0",
        background: color,
        color: text_config.color || "#cccccc",
        fontFamily: text_config.font_family || "sans-serif",
      }}
      onClick={(e) => {
        e.stopPropagation();
        onModuleSelection(e, MODULE_TYPES.ACTION_BUTTON + index);
      }}
    >
      <Grid
        container
        spacing={1}
        justify={"center"}
        alignItems={"center"}
        style={{
          minWidth: "250px",
          minHeight: "75px",
        }}
      >
        <Grid item>
          <ImageHolder
            config={image}
            style={{
              backgroundColor: "#E1E4E7",
              borderRadius: "0px",
              width: "50px",
              height: "50px",
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            selected={
              moduleType === MODULE_TYPES.ACTION_BUTTON + index + "_IMAGE"
            }
            handleModuleSelection={(e) => {
              e.stopPropagation();
              onModuleSelection(
                e,
                MODULE_TYPES.ACTION_BUTTON + index + "_IMAGE"
              );
            }}
          />
        </Grid>
        <Grid item xs sm md>
          <TextHolder
            bgColor={"#ffffff"}
            config={text_config}
            selected={
              moduleType === MODULE_TYPES.ACTION_BUTTON + index + "_TEXT"
            }
            handleModuleSelection={(e) => {
              e.stopPropagation();
              onModuleSelection(
                e,
                MODULE_TYPES.ACTION_BUTTON + index + "_TEXT"
              );
            }}
          />
          <TextHolder
            bgColor={"#ffffff"}
            config={description_config}
            selected={
              moduleType === MODULE_TYPES.ACTION_BUTTON + index + "_DESC"
            }
            handleModuleSelection={(e) => {
              e.stopPropagation();
              onModuleSelection(
                e,
                MODULE_TYPES.ACTION_BUTTON + index + "_DESC"
              );
            }}
          />
        </Grid>
        <Grid
          item
          style={{
            justifySelf: "flex-end",
          }}
        >
          <ArrowForwardIosIcon
            fontSize="small"
            style={{
              color: "#99A9B5",
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default function ActionTooltipButtonsHolder({
  bgColor,
  moduleType,
  config,
  onModuleSelection,
}) {
  return (
    <div>
      {config &&
        Array.isArray(config) &&
        config.map((eachButton, index) => (
          <StyledHolder
            key={`action_buttons_${index}`}
            bgColor={bgColor}
            selected={moduleType === (MODULE_TYPES.ACTION_BUTTON + (index + 1))}
          >
            <ActionTooltipButton
              index={index}
              buttonConfig={eachButton}
              key={MODULE_TYPES.ACTION_BUTTON + index}
              moduleType={moduleType}
              onModuleSelection={onModuleSelection}
            />
            {config.length !== index + 1 && <Divider />}
          </StyledHolder>
        ))}
    </div>
  );
}
