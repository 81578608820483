import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const styles = (theme) => ({
  checked: {
    color: theme.palette.primary.main + "!important",
  },
  formControl: {
    display: "flex",
    margin: theme.spacing(3),
  },
  group: {
    display: "flex",
    margin: `${theme.spacing(1 / 10)}px 0`,
    flexDirection: "row",
  },
});

function CustomCheckbox({
  classes,
  label,
  handleChange,
  value,
  checked,
  disabled = false,
}) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={(e, checked) => handleChange(checked)}
          value={value}
          classes={{
            checked: classes.checked,
          }}
          disabled={disabled}
        />
      }
      label={label}
    />
  );
}

CustomCheckbox.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.any,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
};

export default withStyles(styles, { withTheme: true })(CustomCheckbox);
