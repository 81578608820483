import React from "react";
import { useTracked } from "./store";
import {
  ACTION_SET_ATTRIBUTE_NAME,
  ACTION_SET_ATTRIBUTE_OPERATOR,
  ACTION_SET_ATTRIBUTE_VALUE,
  ACTION_REMOVE_ATTRIBUTE,
} from "./reducer";
import { AttributeRow } from "../AttributeRow";

const Attribute = ({
  eventId = null,
  eventName = null,
  id,
  name,
  operator,
  value,
  operators,
  options,
  disabled,
}) => {
  // Global State
  const [state, dispatch] = useTracked();
  const filters = state.filters;
  const appId = state.appId;

  return (
    <AttributeRow
      dispatch={dispatch}
      appId={appId}
      filters={filters}
      eventId={eventId}
      id={id}
      disabled={disabled}
      name={name}
      operator={operator}
      value={value}
      operators={operators}
      eventName={eventName}
      options={options}
      actions={{
        ACTION_SET_ATTRIBUTE_NAME,
        ACTION_SET_ATTRIBUTE_OPERATOR,
        ACTION_SET_ATTRIBUTE_VALUE,
        ACTION_REMOVE_ATTRIBUTE,
      }}
    />
  );
};

Attribute.whyDidYouRender = true;

export default React.memo(Attribute);
