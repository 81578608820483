import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import ColumnDiv from "../../../../../../../../ReusableComponents/ColumnDiv";
import TriggerSummary from "./components/TriggerSummary";
import TargetAudienceSummary from "./components/TargetAudienceSummary";
import { useSummaryStyles } from "../../constants";

export default function ConfigurationSection({
  audience,
  cohorts,
  conditions,
  goal_event,
  handleSelect,
  overall_cfg,
}) {
  const headerClasses = useSummaryStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} className={headerClasses.root}>
        <Typography className={headerClasses.summaryHeading}>
          Context
        </Typography>
        {handleSelect && (
          <Button
            variant="text"
            color="primary"
            onClick={() => handleSelect(1)}
            className={headerClasses.editButton}
          >
            Edit
          </Button>
        )}
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid
            item
            xs={6}
            style={{ borderRight: "1px solid #E4E7E9", padding: "20px 26px" }}
          >
            <ColumnDiv>
              <Typography className={headerClasses.orangeHeading}>
                TARGET AUDIENCE:
              </Typography>
              <TargetAudienceSummary
                audience={audience}
                cohorts={cohorts}
                goal_event={goal_event}
                overall_cfg={overall_cfg}
              />
            </ColumnDiv>
          </Grid>
          <Grid item xs={6} style={{ padding: "20px 26px" }}>
            <ColumnDiv>
              <Typography className={headerClasses.orangeHeading}>
                TRIGGER:
              </Typography>
              <TriggerSummary conditions={conditions} />
            </ColumnDiv>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
