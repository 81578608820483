import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import ActionsTextField from "../../../../../../../../ReusableComponents/ActionsTextField";
import BootstrapTooltip from "../../../../../../../../ReusableComponents/BootstrapTooltip";
import ToggleSwitch from "../../../../../../../../ReusableComponents/ToggleSwitch";
import { ApxEventHandler } from "../../../../../../../common/ApxEventHandler";
import { TOGGLE_SWITCH_STYLES } from "../../../../../../../common/components/campaigncreator/constants";
import {
  ENABLE_CUSTOM_FONT,
  SET_CUSTOM_FONT,
  SHOW_CUSTOM_FONT_TOOLTIP,
} from "../../../constants";
import { useDispatch } from "../../../store";
import { unSubscribeAllConfigurationEvents } from "../EventSubscriber";
import { useCommonConfigStyles } from "../utils";

export default function CustomFontField({ isCFnt = false, customFont = "" }) {
  const classes = useCommonConfigStyles();
  const dispatch = useDispatch();

  const [enableCustomFont, setEnableCustomFont] = useState(isCFnt);
  const [customFontValue, setCustomFontValue] = useState(customFont);
  const [showTooltip, setShowTooltip] = useState(false);

  const fontFieldRef = useRef();

  useEffect(() => {
    const eventHandler = ApxEventHandler.subscribe(
      SHOW_CUSTOM_FONT_TOOLTIP,
      (data) => {
        const { checked = false } = data;
        if (checked) {
          setShowTooltip(checked);
          setEnableCustomFont(checked);
          dispatch({
            type: ENABLE_CUSTOM_FONT,
            value: checked,
          });
          if (fontFieldRef.current) {
            fontFieldRef.current.scrollIntoView({ behaviour: "smooth" });
          }
          setTimeout(() => {
            setShowTooltip(false);
          }, 1500);
          if (customFontValue !== "" && customFontValue.endsWith(".ttf")) {
            dispatch({
              type: SET_CUSTOM_FONT,
              value: customFontValue,
            });
          }
        }
      }
    );

    return () => {
      unSubscribeAllConfigurationEvents([eventHandler]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (enableCustomFont !== isCFnt) {
      setEnableCustomFont(isCFnt);
    }
    if (customFont !== customFontValue) {
      setCustomFontValue(customFontValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCFnt, customFont]);

  return (
    <>
      <Grid item xs={12} className={classes.gridItem}>
        <Typography className={classes.typographySubtitle2} variant="h6">
          Custom Font
        </Typography>
        <ToggleSwitch
          disabled={true}
          className={classes.formControlLabel}
          checked={enableCustomFont}
          handleChange={(checked, _) => {
            setEnableCustomFont(checked);
            dispatch({
              type: ENABLE_CUSTOM_FONT,
              value: checked,
            });
          }}
          style={TOGGLE_SWITCH_STYLES(true)}
        />
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.gridItem}
        style={{ marginBottom: 0 }}
      >
        <BootstrapTooltip
          title="Enter custom font details here"
          placement="left"
          open={showTooltip}
        >
          <div ref={fontFieldRef} style={{ width: "100%" }}>
            <ActionsTextField
              value={customFontValue}
              disabled={!enableCustomFont}
              placeholder="Enter path for .ttf file"
              onChange={(e) => {
                const value = e.target.value;
                setCustomFontValue(value);
                setShowTooltip(false);
                if (value.endsWith(".ttf")) {
                  dispatch({
                    type: SET_CUSTOM_FONT,
                    value,
                  });
                }
              }}
              multiline={false}
              style={{ margin: 0, width: "100%" }}
            />
          </div>
        </BootstrapTooltip>
      </Grid>
    </>
  );
}
