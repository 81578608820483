import React from "react";
import { Card, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const styles = (theme) => {
  return {
    root: {
      width: "100%",
      border: `1px solid ${theme.palette.border.disabled}`,
      padding: 16,
      backgroundColor: theme.palette.secondary.main,
    },
    rootSelected: {
      width: "100%",
      border: `1px solid ${theme.palette.border.primary}`,
      boxShadow: "0px 3px 20px rgba(181, 202, 224, 0.8)",
      padding: 16,
      backgroundColor: theme.palette.secondary.main,
    },
    rootDisabled: {
      width: "100%",
      padding: 16,
      backgroundColor: theme.palette.background.disabled,
      border: "1px solid transparent",
      // margin: "8px 0",
    },
    rootPlaceholderType: {
      border: `2px dashed ${theme.palette.border.primary}`,
      background: "transparent",
      // margin: "8px 0",
      width: "100%",
    },
  };
};

const getClass = (type, classes) => {
  switch (type) {
    case "selected":
      return classes["rootSelected"];
    case "disabled":
      return classes["rootDisabled"];
    case "placeholderType":
      return classes["rootPlaceholderType"];
    default:
      return classes["root"];
  }
};

function ClassicCard({
  classes,
  selected,
  disabled,
  style,
  placeholderType,
  children,
}) {
  return (
    <Card
      style={{ transition: "all 0.1s ease-in-out", ...style }}
      className={getClass(
        selected
          ? "selected"
          : disabled
          ? "disabled"
          : placeholderType
          ? "placeholderType"
          : "root",
        classes
      )}
      elevation={0}
    >
      {children}
    </Card>
  );
}

ClassicCard.propTypes = {
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default withStyles(styles)(ClassicCard);
