import { Button, Grid, Typography } from "@material-ui/core"
import Add from "@material-ui/icons/Add"
import PlayCircleFilledRoundedIcon from "@material-ui/icons/PlayCircleFilledRounded"
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined"
import React, { useState } from "react"
import {
  APP_PLATFORMS,
  WALKTHROUGH_TYPE_ENUM,
} from "../../../../../../../../constants"
import { useAuth } from "../../../../../../../../contexts/AuthContext"
import CustomMaterialUIDialog from "../../../../../../../ReusableComponents/CustomMaterialUIDialog"
import {
  ADD_STEP_TO_VARIANT,
  CAMPAIGN_TYPE_ENUM,
  DELETE_STEP_FROM_VARIANT,
  ON_COPY_STEP_VARIANT,
} from "../../../../../../common/components/campaigncreator/constants"
import { useTracked as useParentTracked } from "../../../../../../common/components/campaigncreator/store"
import create_campaign from "../../assets/create_campaign.svg"
import {
  ADD_NEXT_STEP_TO_CAMPAIGN,
  DELETE_STEP_FROM_CAMPAIGN,
  HIDE_CONFIGURATION_TOOLBARS,
  INAPP_LAYOUT_TYPES,
  MOVE_STEP,
  ON_COPY_STEP,
  ON_DELETE_STEP,
  ON_EDIT_STEP,
  SET_CURRENT_STEP,
  UPDATE_ONBOARDING_STATE_IN_UIS,
} from "../../constants"
import { useTracked } from "../../store"
import { getActionClassInfo } from "../../utils"
import { publishDesignForPreviewAPI } from "../actions"
import OnboardingConfiguration from "../configuration/components/OnboardingConfiguration"
import CampaignCard from "../flowBuilder/components/CampaignCard"
import ActionTypeSelector from "./components/ActionTypeSelector"

import Apxor from "apxor"
import { sortableContainer, sortableElement } from "react-sortable-hoc"
import { isExperienceDashboard } from "../../../../../../../../config"
import { APXOR_SDK_CATEGORY_CONSTANTS } from "../../../../../../../../constants/Endpoints"
import BootstrapTooltip from "../../../../../../../ReusableComponents/BootstrapTooltip"
import RowDiv from "../../../../../../../ReusableComponents/RowDiv"
import { sendEvent } from "../../../../../../common/ApxEventHandler"
import { EXPERIENCE_CONFIG } from "../../../../../../common/components/campaignsholder/constants"
import { IconImageComponent } from "../../../../../../common/utils"
import DevicePreviewWithoutSSE from "../common/DevicePreviewWithoutSSE"

const SortableCard = sortableElement((props) => (
  <CampaignCard {...props} key={props.keyId} index={props.indexId} />
))

const SortableContainer = sortableContainer(({ children }) => (
  <div className="cards" style={{ display: "flex" }}>
    {children}
  </div>
))

export default function FlowBuilder({ platform, app }) {
  const auth = useAuth()

  const [state, dispatch] = useTracked()

  const [showTemplateDialog, setShowTemplateDialog] = useState(false)
  const [showGlobalConfigDialog, setShowGlobalConfigDialog] = useState(false)
  const [showOnboardingPreview, setShowOnboardingPreviewDialog] = useState(
    false,
  )

  const [parentState, parentDispatch] = useParentTracked()
  const {
    config: {
      meta: { config_type = "walkthrough", isExperiment, experience_type = "" },
      experiment: { currentVariantStep = 0, variants } = {},
    },
  } = parentState
  const isOnboardingMode =
    config_type === WALKTHROUGH_TYPE_ENUM.ONBOARDING_WALKTHROUGH

  const { app_id, currentStep = -1, uis, uiIndex } = state
  const onSortEnd = ({ oldIndex, newIndex }) => {
    dispatch({
      type: SET_CURRENT_STEP,
      value: newIndex,
    })
    dispatch({
      type: MOVE_STEP,
      from: oldIndex,
      to: newIndex,
      isExperiment,
    })
    sendEvent(ON_EDIT_STEP)
  }

  const getCurrentvariantUIS = (uis) => {
    const currentVariantKey = Object.keys(variants).filter(
      (key) => key !== "CG",
    )[currentVariantStep]
    const variantSpecificUIS = uis.filter((eachUI) =>
      variants[currentVariantKey].indices.includes(eachUI.index),
    )
    return variantSpecificUIS
  }

  const getCurrentVariantKey = () => {
    return isExperiment
      ? Object.keys(variants).filter((key) => key !== "CG")[currentVariantStep]
      : null
  }
  const [hovered, setHovered] = useState(false)

  const shouldButtonBeDisabled = (function () {
    if (uis?.length !== 0) {
      if (isExperiment) {
        const currentVariantKey = Object.keys(variants).filter(
          (key) => key !== "CG",
        )[currentVariantStep]
        const variantSpecificUIS = uis.filter((eachUI) =>
          variants[currentVariantKey].indices.includes(eachUI.index),
        )
        return variantSpecificUIS.some(
          (ui) =>
            ui?.type === "IN_APP" &&
            ui?.ui?.layout_type === INAPP_LAYOUT_TYPES.VIDEOS,
        )
      } else {
        return uis.some(
          (ui) =>
            ui?.type === "IN_APP" &&
            ui?.ui?.layout_type === INAPP_LAYOUT_TYPES.VIDEOS,
        )
      }
    }
    return false
  })()

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: 6,
          border: "1px solid #E4E7E9",
          borderRadius: 3,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: 80,
            backgroundColor: "white",
            borderTopLeftRadius: 3,
            borderBottomLeftRadius: 3,
            borderRight: "1px solid #E4E7E9",
          }}
        >
          <BootstrapTooltip
            open={
              (shouldButtonBeDisabled && hovered) ||
              (isExperienceDashboard() &&
                [
                  EXPERIENCE_CONFIG.FEATURE_DISCOVERY,
                  EXPERIENCE_CONFIG.CART_DELETION_SURVEY,
                ].some((exp_type) => exp_type === experience_type) &&
                hovered)
            }
            placement="right"
            arrow
            title={
              isExperienceDashboard() &&
              [
                EXPERIENCE_CONFIG.FEATURE_DISCOVERY,
                EXPERIENCE_CONFIG.CART_DELETION_SURVEY,
              ].some((exp_type) => exp_type === experience_type)
                ? "Adding another step is disabled for this Feature Discovery Campaign"
                : "Walkthrough can't be created with Video In-Apps"
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: 80,
              }}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
              <Button
                color="primary"
                variant="contained"
                style={{
                  padding: 0,
                  margin: "2px 11px 11px 3px",
                  borderRadius: 2,
                  width: 58,
                  alignItems: "center",
                  top: 5,
                  left: 4,
                  height: 100,
                  pointerEvents:
                    shouldButtonBeDisabled ||
                    (isExperienceDashboard() &&
                      [
                        EXPERIENCE_CONFIG.FEATURE_DISCOVERY,
                        EXPERIENCE_CONFIG.CART_DELETION_SURVEY,
                      ].some((exp_type) => exp_type === experience_type))
                      ? "none"
                      : "auto",
                }}
                disabled={
                  shouldButtonBeDisabled ||
                  (isExperienceDashboard() &&
                    [
                      EXPERIENCE_CONFIG.FEATURE_DISCOVERY,
                      EXPERIENCE_CONFIG.CART_DELETION_SURVEY,
                    ].some((exp_type) => exp_type === experience_type))
                }
                onClick={() => setShowTemplateDialog(true)}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: isOnboardingMode ? "row" : "column",
                    alignItems: "center",
                  }}
                >
                  <Add
                    color="secondary"
                    style={{
                      height: 36,
                      width: "auto",
                    }}
                  />
                </div>
              </Button>
            </div>
          </BootstrapTooltip>
          {isOnboardingMode && (
            <Button
              onClick={() => setShowGlobalConfigDialog(true)}
              size="small"
              style={{
                width: 157,
                minHeight: 49,
                marginBottom: 16,
                borderRadius: 2,
                border: "1.5px solid rgba(0, 40, 69, 0.35)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: isOnboardingMode ? "row" : "column",
                  alignItems: "center",
                }}
              >
                <SettingsOutlinedIcon color="action" />
                <Typography
                  color="textPrimary"
                  variant="subtitle1"
                  style={{
                    fontWeight: 900,
                  }}
                >
                  Global Config
                </Typography>
              </div>
            </Button>
          )}
        </div>
        <Grid
          container
          style={{
            backgroundColor: "#FFFFFF",
            overflow: "hidden",
            borderRadius: 3,
            border: "1px rgba(0, 46, 69, 0.57)",
          }}
          spacing={0}
        >
          <Grid item xs={isOnboardingMode ? 11 : 12}>
            <div
              style={{
                display: "flex",
                overflowX: "auto",
                overflowY: "hidden",
                height: "100%",
              }}
            >
              {(isExperiment
                ? Array.isArray(uis) && Array.isArray(getCurrentvariantUIS(uis))
                : Array.isArray(uis)) && (
                <SortableContainer
                  onSortEnd={isExperiment ? null : onSortEnd}
                  axis="x"
                  distance={1}
                >
                  {(isExperiment ? getCurrentvariantUIS(uis) : uis).map(
                    (item, index) => (
                      <SortableCard
                        key={"key_card_" + index}
                        keyId={"key_card_" + index}
                        actions={{
                          DELETE_STEP_FROM_VARIANT,
                          DELETE_STEP_FROM_CAMPAIGN,
                          MOVE_STEP,
                          ON_EDIT_STEP,
                          ON_DELETE_STEP,
                          SET_CURRENT_STEP,
                          ON_COPY_STEP,
                          ON_COPY_STEP_VARIANT,
                        }}
                        index={isExperiment ? item.index : index}
                        indexId={isExperiment ? item.index : index}
                        isLast={index === uis.length - 1}
                        totalSteps={
                          isExperiment
                            ? getCurrentvariantUIS(uis).length
                            : uis.length
                        }
                        editable={true}
                        getLogo={() => {
                          return getActionClassInfo(
                            item.ui.action_class,
                            item.ui.layout_type,
                          )
                        }}
                        selected={
                          currentStep === (isExperiment ? item.index : index)
                        }
                        dispatch={dispatch}
                        currentStep={currentStep}
                        parentDispatch={parentDispatch}
                        isExperiment={isExperiment}
                        currentVariantKey={getCurrentVariantKey()}
                        uiIndex={uiIndex}
                        variants={variants}
                        disableCopy={[
                          EXPERIENCE_CONFIG.FEATURE_DISCOVERY,
                          EXPERIENCE_CONFIG.CART_DELETION_SURVEY,
                        ].some((exp_type) => exp_type === experience_type)}
                      />
                    ),
                  )}
                </SortableContainer>
              )}
            </div>
          </Grid>

          {isOnboardingMode && (
            <Grid
              item
              xs={1}
              style={{
                padding: 0,
              }}
            >
              <Button
                disabled={uis.length < 2}
                style={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: 3,
                  height: "100%",
                  width: "100%",
                }}
                onClick={() => setShowOnboardingPreviewDialog(true)}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <PlayCircleFilledRoundedIcon
                    style={{
                      fontSize: "2.1875rem",
                      color: "rgba(0,40,69,1)",
                    }}
                  />
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    style={{
                      fontWeight: 900,
                      textTransform: "capitalize",
                    }}
                  >
                    Preview all
                  </Typography>
                </div>
              </Button>
            </Grid>
          )}
        </Grid>

        <CustomMaterialUIDialog
          scrollType="body"
          dialogContent={
            <ActionTypeSelector
              app={app}
              experience_type={experience_type}
              isOnboardingMode={isOnboardingMode}
              platform={platform}
              handleClose={() => setShowTemplateDialog(false)}
              handleDone={(data, isInApp, isVariantCarousel = false) => {
                setShowTemplateDialog(false)
                const options = {}
                if (data) {
                  let previewData = {}
                  if (isVariantCarousel) {
                    previewData.search_type = data.search_type
                    previewData.is_wv = data.is_wv
                    previewData.wv_tag = data.wv_tag
                    previewData.id = data.id
                    previewData.path = data.path
                    previewData.view_id =
                      data.search_type === "" ? data.id : data.path
                    previewData.activity = data.activity
                  } else {
                    previewData.search_type = data.searchType
                    previewData.is_wv = data.isForWebView
                    previewData.wv_tag = data.webviewTag
                    previewData.id = data.viewId
                    previewData.path = data.path
                    previewData.view_id =
                      data.searchType === "" ? data.viewId : data.path
                    previewData.activity = data.activity
                  }
                  options.value = {
                    search_type: previewData.search_type,
                    is_wv: previewData.is_wv,
                    wv_tag: previewData.wv_tag,
                    id: previewData.id,
                    path: previewData.path,
                    view_id: previewData.view_id,
                    activity: previewData.activity,
                  }
                  if (data.isForWebView)
                    options.value["url"] = "https://unpkg.com/apxor-rtm-ui"

                  if (platform === APP_PLATFORMS.web) {
                    options.value = {
                      ...options.value,
                      url: data.url ?? "",
                    }
                  }
                  options.provider = data.provider
                  options.blobName = data.blob_name || data.blobName
                }
                isExperiment &&
                  parentDispatch({
                    type: ADD_STEP_TO_VARIANT,
                    index: uiIndex,
                    step: Object.keys(variants).filter((key) => key !== "CG")[
                      currentVariantStep
                    ],
                  })
                dispatch({
                  type: ADD_NEXT_STEP_TO_CAMPAIGN,
                  isExperiment: isExperiment,
                  isInApp,
                  ...options,
                })
                sendEvent(HIDE_CONFIGURATION_TOOLBARS)
              }}
            />
          }
          maxWidth="md"
          openFlag={showTemplateDialog}
          onClose={() => setShowTemplateDialog(false)}
          onDialogClose={() => setShowTemplateDialog(false)}
        />

        <CustomMaterialUIDialog
          dialogActions={
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                setShowGlobalConfigDialog(false)
                dispatch({
                  type: UPDATE_ONBOARDING_STATE_IN_UIS,
                })
              }}
            >
              Done
            </Button>
          }
          dialogActionStyles={{
            borderRadius: 3,
            boxShadow: "0px 0px 10px 1px rgba(1, 1, 1, 0.6)",
          }}
          dialogProps={{
            disableBackdropClick: true,
            disableEscapeKeyDown: true,
          }}
          dialogContent={<OnboardingConfiguration />}
          dialogContentStyles={{
            padding: 0,
            margin: 0,
          }}
          title={"Onboarding Global Config"}
          openFlag={showGlobalConfigDialog}
          onClose={() => {
            setShowGlobalConfigDialog(false)
            dispatch({
              type: UPDATE_ONBOARDING_STATE_IN_UIS,
            })
          }}
          onDialogClose={() => setShowGlobalConfigDialog(false)}
        />

        <CustomMaterialUIDialog
          dialogProps={{
            disableBackdropClick: true,
            disableEscapeKeyDown: true,
          }}
          dialogContent={
            <DevicePreviewWithoutSSE
              onPreviewClick={() => {
                publishDesignForPreviewAPI(auth, app_id, {
                  type: CAMPAIGN_TYPE_ENUM.ONBOARDING,
                  ui: {},
                  uis: uis.map((item) => {
                    return item.ui
                  }),
                })
              }}
            />
          }
          disableBackdropClick={false}
          disableEscapeKeyDown={false}
          maxWidth={"sm"}
          isFullWidth={false}
          dialogContentStyles={{
            padding: 0,
            margin: 0,
          }}
          title={"Onboarding Device Preview"}
          openFlag={showOnboardingPreview}
          onClose={() => {
            setShowOnboardingPreviewDialog(false)
          }}
          onDialogClose={() => setShowOnboardingPreviewDialog(false)}
        />
      </div>
      {uis.length === 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 8,
            textAlign: "center",
            width: "100%",
            background: "#FFF",
            alignItems: "center",
            justifyContent: "center",
            minHeight: 595,
            border: "1px solid #E4E7E9",
            borderRadius: 3,
          }}
        >
          <Typography
            color="textPrimary"
            style={{
              marginBottom: 24,
              fontWeight: 500,
            }}
            variant="h4"
          >
            Start designing your campaign
          </Typography>
          <Button
            id="add-campaign-button"
            disabled={
              shouldButtonBeDisabled ||
              (isExperienceDashboard() &&
                [
                  EXPERIENCE_CONFIG.FEATURE_DISCOVERY,
                  EXPERIENCE_CONFIG.CART_DELETION_SURVEY,
                ].some((exp_type) => exp_type === experience_type))
            }
            onClick={() => {
              Apxor.logEvent(
                "CampaignsTemplateSelectorLaunched",
                {
                  "experience_type": experience_type,
                },
                APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS,
              )
              setShowTemplateDialog(true)
            }}
            style={{
              minWidth: 150,
              marginBottom: 50,
            }}
          >
            <RowDiv>
              <Button
                color="primary"
                variant="contained"
                style={{
                  borderRadius: 2,
                  minWidth: 27,
                  width: 27,
                  alignItems: "center",
                  height: 30,
                }}
              >
                <Add
                  color="secondary"
                  style={{
                    height: 20,
                    width: "auto",
                  }}
                />
              </Button>
              <Typography
                color="primary"
                style={{
                  marginLeft: 10,
                  fontSize: 20,
                  fontWeight: 500,
                }}
              >
                Add Template
              </Typography>
            </RowDiv>
          </Button>

          {IconImageComponent(create_campaign, "create_campaign", {
            height: "unset !important",
            width: "unset !important",
          })}
        </div>
      )}
    </>
  )
}
