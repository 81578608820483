import { produce } from "immer";
import moment from "moment";
import {
  SET_APP_EVENTS,
  SET_FILTERS_DATA,
  SET_INITIAL_DATA,
  SET_REFRESH,
  SET_VARIANT,
} from "./constants";

export const initial_state = {
  variant: "A",
  refresh: false,
  funnelTime: 300,
  startDate: moment().toISOString(),
  endDate: moment().add(7, "day").toISOString(),
  goalEvents: [],
  appEvents: [],
  defaultGoalEvent: null,
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case SET_INITIAL_DATA:
      draft.goalEvents = action.goalEvents;
      draft.startDate = action.startDate;
      draft.endDate = action.endDate;
      draft.defaultGoalEvent = action.defaultGoalEvent;
      break;
    case SET_VARIANT:
      draft.variant = action.value;
      break;
    case SET_REFRESH:
      draft.refresh = !draft.refresh;
      break;
    case SET_APP_EVENTS:
      draft.appEvents = action.value;
      break;
    case SET_FILTERS_DATA:
      draft.funnelTime = action.funnelTime;
      draft.startDate = action.startDate;
      draft.endDate = action.endDate;
      draft.goalEvents = action.events;
      break;
    default:
      break;
  }

  return draft;
});

