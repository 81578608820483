import { FormLabel } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/ButtonBase";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AssignmentIcon from "@material-ui/icons/Assignment";
import BuildIcon from "@material-ui/icons/Build";
import SaveIcon from "@material-ui/icons/Save";
import Apxor from "apxor";
import React, { useEffect, useState } from "react";
import {
  APXOR_SDK_CATEGORY_CONSTANTS,
  COUNT_TYPE_ENUM,
} from "../../../../../constants";
import { useAuth } from "../../../../../contexts/AuthContext";
import Box from "../../../../ReusableComponents/Box";
import CheckboxGroup from "../../../../ReusableComponents/CheckboxGroup";
import CustomMaterialUIDialog from "../../../../ReusableComponents/CustomMaterialUIDialog";
import MultiSelect from "../../../../ReusableComponents/MultiSelect";
import { getAttributes } from "../../../../common/actions";
import {
  deleteCustomReportAPI,
  getCustomReportsAPI,
  getExplorerAPI,
  getFlatTableAPI,
  saveCustomReportAPI,
} from "../actions";
import DimensionSelector from "./DimensionSelector";
import Explorer from "./Explorer";
import Filters from "./Filters";
import FlatTable from "./FlatTable";

const { users, sessions, impressions } = COUNT_TYPE_ENUM;
/**
 * Supported Metrics
 * @type {{value: string, label: *}[]}
 */
const METRICS = [
  //FIXME: Might change
  {
    label: "Total Events",
    value: impressions,
  },
  {
    label: "Users",
    value: users,
  },
  {
    label: "Sessions",
    value: sessions,
  },
];

const EXPLORER = "explorer";
const FLAT_TABLE = "flat-table";

const DEFAULT_TITLE = "New Report";

const getCustomReportResponsesLimit = (app) => {
  const { features = [] } = app;
  let key = "APX_CR_LIMIT_";
  let limit = 1000;
  features.forEach((feature) => {
    if (feature.indexOf(key) !== -1) {
      limit = feature.slice(key.length);
    }
  });
  return Number(limit);
};

export function CustomReportHolder({ app, queryParams }) {
  const auth = useAuth();

  const [metrics, setMetrics] = useState([]);
  const [dimensions, setDimensions] = useState([]);
  const [filters, setFilters] = useState([]);
  const [reports, setReports] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [selectedDimensions, setSelectedDimensions] = useState([]);

  const [goClicked, setGoClicked] = useState(false);
  const [updateDisabled, setUpdateDisabled] = useState(true);
  const [deleteTriggered, setDeleteTriggered] = useState(false);
  const [saveTriggered, setSaveTriggered] = useState(false);
  const [reportName, setReportName] = useState("");
  const [boxTitle, setBoxTitle] = useState(DEFAULT_TITLE);
  const [activeTab, setActiveTab] = useState(EXPLORER);
  const [selectedReport, setSelectedReport] = useState("");
  // Whenever this gets changed, we fetch the data again
  const [fetchReports, setFetchReports] = useState(0);
  const [explorerData, setExplorerData] = useState({
    aggregations: [],
    data: [],
  });
  const [flatTableData, setFlatTableData] = useState({
    aggregations: [],
    data: [],
  });
  const [fetchingInProgress, setFetchingInProgress] = useState(false);
  const { app_id: appId } = app;

  const responseLimit = { limit: getCustomReportResponsesLimit(app) };
  // Fetch attribute list upon component mount
  useEffect(() => {
    getAttributes(auth, appId).then((response) => {
      const { user = [], session = [], event = [] } = response;
      setAttributes(
        [...new Set([...user, ...session, ...event])]
          .map((o) => ({ label: o, value: o }))
          .concat({ label: "Event", value: "apx_event_name" })
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch Custom Reports every time the `fetchReports` gets modified
  useEffect(() => {
    getCustomReportsAPI(auth, appId, queryParams).then((reports) => {
      setReports(reports);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchReports]);

  useEffect(() => {
    // This may not be the best way to differentiate when to fire API
    // Think of another logic which works just like in old dashboard.
    if (
      metrics.length > 0 &&
      (dimensions.length > 0 || selectedDimensions.length > 0)
    ) {
      // It is 100% possible that this gets executed when any of the
      // dependencies gets changed. So, to prevent executing same thing
      // multiple times, we need this `if`.
      if (!fetchingInProgress) {
        setFetchingInProgress(true);
        setUpdateDisabled(true);
        const dim = activeTab === EXPLORER ? selectedDimensions : dimensions;
        const api = activeTab === EXPLORER ? getExplorerAPI : getFlatTableAPI;
        const postBody = {
          filters,
          metrics,
          dimensions: dim.length > 0 ? dim : dimensions.slice(0, 1),
        };
        api(auth, appId, { ...queryParams, ...responseLimit }, postBody)
          .then((response) => {
            if (activeTab === EXPLORER) {
              setExplorerData(response);
            } else {
              setFlatTableData(response);
            }
            setGoClicked(false);
            setUpdateDisabled(true);
            setFetchingInProgress(false);
          })
          .catch((err) => {
            // FIXME: Show a Snackbar about what went wrong
            setGoClicked(false);
            setFetchingInProgress(false);
            setUpdateDisabled(false);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auth,
    appId,
    queryParams,
    selectedReport,
    selectedDimensions,
    activeTab,
    goClicked,
  ]);

  // FIXME: Use `useCallback` to preve unnecessarly creating functions
  //        upon every re-render
  const readyToGo = () => metrics.length > 0 && dimensions.length > 0;
  const isSavedReport = () =>
    selectedReport !== "" &&
    reports.findIndex((report) => report._id === selectedReport) !== -1;

  const saveCustomReport = (name) => {
    saveCustomReportAPI(auth, appId, queryParams, {
      name: name,
      dimensions,
      filters,
      metrics,
    }).then((response) => {
      if (response) {
        setFetchReports(fetchReports + 1);
      }
    });
  };

  const resetState = () => {
    setBoxTitle(DEFAULT_TITLE);
    setMetrics([]);
    setDimensions([]);
    setSelectedDimensions([]);
    setFilters([]);
    setExplorerData({});
    setFlatTableData({});
    setSelectedReport("");
  };

  return (
    <Box
      title={boxTitle}
      icon={boxTitle === DEFAULT_TITLE ? <BuildIcon /> : <AssignmentIcon />}
      defaultExpanded
      headerWithBorder
      controls={
        <MultiSelect
          options={reports.map((o) => ({ label: o.name, value: o._id }))}
          value={selectedReport}
          handleChange={(reportId) => {
            setSelectedReport(reportId);
            if (reportId === "") {
              resetState();
              return;
            }
            const report = reports.find((report) => report._id === reportId);
            if (report) {
              setBoxTitle(report.name);
              const dim = report.dimensions;
              setDimensions(dim.map((d) => ({ ...d, id: d.name })) || []);
              setMetrics(report.metrics || []);
              setFilters(report.filters || []);
            }
          }}
          placeholder="Select Saved Report"
          single
          clearable
          style={{ maxWidth: 460 }}
        />
      }
      footer={
        readyToGo() &&
        updateDisabled && (
          <Grid container>
            <Grid item xs>
              <Typography variant={"subtitle1"}>
                Showing top <b>{getCustomReportResponsesLimit(app)}</b>{" "}
                responses.
              </Typography>
              <AppBar position="static" color="default">
                <Tabs
                  value={activeTab}
                  onChange={(e, activeTab) => {
                    setActiveTab(activeTab);
                    if (activeTab !== EXPLORER) {
                      setSelectedDimensions([]);
                    }
                    setGoClicked(true);
                  }}
                  variant="fullWidth"
                  centered
                  textColor="secondary"
                  indicatorColor="secondary"
                >
                  <Tab
                    fullWidth
                    textColor="primary"
                    disabled={goClicked}
                    value={EXPLORER}
                    label="Explorer"
                  />
                  <Tab
                    fullWidth
                    textColor="primary"
                    disabled={goClicked}
                    value={FLAT_TABLE}
                    label="Flat Table"
                  />
                </Tabs>
              </AppBar>
              {activeTab === EXPLORER && (
                <Explorer
                  dimensions={dimensions}
                  metrics={metrics}
                  filters={filters}
                  queryParams={queryParams}
                  setDimensions={(values) => {
                    setSelectedDimensions(values);
                    setGoClicked(true);
                  }}
                  explorer={explorerData}
                  fetching={fetchingInProgress}
                />
              )}
              {activeTab === FLAT_TABLE && (
                <FlatTable
                  dimensions={dimensions}
                  metrics={metrics}
                  filters={filters}
                  queryParams={queryParams}
                  flatTable={flatTableData}
                  fetching={fetchingInProgress}
                />
              )}
            </Grid>
          </Grid>
        )
      }
    >
      <div style={{ width: "100%", padding: 10 }}>
        <Typography variant="subtitle1">Metrics</Typography>
        <CheckboxGroup
          options={METRICS}
          value={metrics}
          handleChange={(metrics) => {
            setMetrics(metrics);
          }}
          labelRenderer={(o) => o.label}
        />
        <Divider />
        <Typography style={{ marginTop: 10 }} variant="subtitle1">
          Dimensions
        </Typography>
        <DimensionSelector
          attributes={attributes}
          dimensions={dimensions}
          handleChange={(dimensions) => {
            setDimensions(dimensions);
            setUpdateDisabled(false);
          }}
        />
        <Divider style={{ marginTop: 10 }} />
        <Typography style={{ marginTop: 10 }} variant="subtitle1">
          Filters
        </Typography>
        <Filters
          attributes={attributes}
          filters={filters}
          /**
           * FIXME: Simplify this logic. When do we decide that
           *        we need to pass state update callback as a prop?
           */
          setFilters={setFilters}
        />
        <Grid container justify="flex-end">
          {readyToGo() && (
            <Grid
              item
              xs={2}
              md={1}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Tooltip
                id="tooltip-fab-save"
                title="Save this as new report"
                placement="bottom"
              >
                <IconButton
                  disableRipple
                  color="inherit"
                  onClick={() => {
                    setSaveTriggered(true);
                  }}
                >
                  <SaveIcon color="primary" />
                </IconButton>
              </Tooltip>
              <CustomMaterialUIDialog
                dialogActions={
                  <>
                    <Button
                      onClick={() => setSaveTriggered(false)}
                      color="action"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        if (reportName && reportName.trim().length > 0) {
                          saveCustomReport(reportName);
                        }
                        setSaveTriggered(false);
                      }}
                      color="primary"
                    >
                      Save
                    </Button>
                  </>
                }
                dialogContent={
                  <>
                    <FormLabel>Report Name</FormLabel>
                    <TextField
                      value={reportName}
                      fullWidth
                      onChange={(e) => {
                        setReportName(e.target.value);
                      }}
                      placeholder="Report Name"
                      type="text"
                      required
                    />
                  </>
                }
                maxWidth={"xs"}
                title={"Saving Report"}
                titleVariant={"h4"}
                noTitleBg={true}
                openFlag={saveTriggered}
                onDialogClose={() => setSaveTriggered(false)}
              />
            </Grid>
          )}
          <Grid item xs={3} md={1} style={{ display: "flex" }}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              disabled={
                !(metrics.length > 0 && dimensions.length > 0) && updateDisabled
              }
              onClick={(e) => {
                setGoClicked(true);
                setUpdateDisabled(false);
                Apxor.logEvent(
                  "ViewReportClicked",
                  { "metrics": metrics || [], "dimensions": dimensions || [] },
                  APXOR_SDK_CATEGORY_CONSTANTS.CUSTOM_REPORTS
                );
              }}
            >
              Update
            </Button>
          </Grid>
          {isSavedReport() && (
            <Grid item xs={2} md={1} style={{ display: "flex" }}>
              <div style={{ display: "flex" }}>
                <Tooltip
                  id="tooltip-fab-delete"
                  title="Delete this report"
                  placement="bottom"
                >
                  <Button
                    variant="contained"
                    color="action"
                    onClick={() => setDeleteTriggered(true)}
                    style={{ textTransform: "capitalize" }}
                  >
                    Delete
                  </Button>
                </Tooltip>
                <CustomMaterialUIDialog
                  dialogActions={
                    <>
                      <Button
                        onClick={() => setDeleteTriggered(false)}
                        color="action"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          deleteCustomReportAPI(
                            auth,
                            appId,
                            selectedReport,
                            queryParams
                          ).then((response) => {
                            // FIXME: Not sure of this logic
                            if (response === "Custom Report removed") {
                              setFetchReports(fetchReports + 1);
                            }
                          });
                          setDeleteTriggered(false);
                          resetState();
                        }}
                        color="primary"
                      >
                        Delete
                      </Button>
                    </>
                  }
                  dialogContent={
                    <p>
                      Are you sure want to <strong>delete</strong> report{" "}
                      <strong>{reportName}</strong>
                    </p>
                  }
                  maxWidth={"xs"}
                  title={"Delete Confirmation"}
                  titleVariant={"h4"}
                  noTitleBg={true}
                  openFlag={deleteTriggered}
                  onDialogClose={() => setDeleteTriggered(false)}
                />
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    </Box>
  );

}