import { Button, Typography, makeStyles } from "@material-ui/core";
import { ExpandMoreSharp } from "@material-ui/icons";
import PlayCircleFilledRoundedIcon from "@material-ui/icons/PlayCircleFilledRounded";
import Apxor from "apxor";
import React, { useEffect, useMemo, useState } from "react";
import {
  DEMO_ECOMMERCE_APP_ID,
  isExperienceDashboard,
} from "../../../../../../../../../config";
import { APP_PLATFORMS } from "../../../../../../../../../constants";
import { APXOR_SDK_CATEGORY_CONSTANTS } from "../../../../../../../../../constants/Endpoints";
import { useAuth } from "../../../../../../../../../contexts/AuthContext";
import { SET_TEST_DEVICES } from "../../../../../../../../Auth/constants";
import {
  useDispatch as useDemoDispatch,
  useTrackedState as useDemoState,
} from "../../../../../../../../Auth/store";
import Autocomplete from "../../../../../../../../ReusableComponents/Autocomplete";
import BootstrapTooltip from "../../../../../../../../ReusableComponents/BootstrapTooltip";
import CustomMaterialUIDialog from "../../../../../../../../ReusableComponents/CustomMaterialUIDialog";
import CustomPopper from "../../../../../../../../ReusableComponents/CustomPopper";
import RowDiv from "../../../../../../../../ReusableComponents/RowDiv";
import { getTestDevicesAPI } from "../../../../../../../common/actions";
import {
  CAMPAIGN_TYPE_ENUM,
  SET_SELECTED_TEST_DEVICE,
} from "../../../../../../../common/components/campaigncreator/constants";
import {
  useDispatch,
  useTrackedState,
} from "../../../../../../../common/components/campaigncreator/store";
import { EXPERIENCE_CONFIG } from "../../../../../../../common/components/campaignsholder/constants";
import ExperienceDemoFinishStep from "../../../../../../dashboard/components/sales-sandbox/ExperienceDemoFinishStep";
import { useTracked } from "../../../store";
import { getUI } from "../../../utils";
import { publishDesignForPreviewAPI } from "../../actions";
import DownloadAppDialog from "../../common/DownloadAppDialog";

const useStyles = makeStyles((theme) => ({
  previewButton: (props) => ({
    marginTop: 8,
    borderRadius: 20,
    position: "relative",
    left: -27,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    "&:hover": {
      background: theme.palette.secondary.main,
    },
  }),
}));

export default function PreviewButtonHolder() {
  const auth = useAuth();

  const classes = useStyles();

  const parentDispatch = useDispatch();
  const parentState = useTrackedState();
  const {
    test_devices = [],
    config: {
      testing: { selected = "", selectedLabel = "" },
      meta: { isExperiment, platform, experience_type = "" },
    },
  } = parentState;

  const [state] = useTracked();
  const { currentStep, uis = [] } = state;
  const ui = useMemo(
    () =>
      getUI(currentStep, uis, isExperiment) &&
      getUI(currentStep, uis, isExperiment).ui
        ? getUI(currentStep, uis, isExperiment).ui
        : {},
    [currentStep, uis, isExperiment],
  );

  const [testDevicesAnchorEl, setTestDevicesAnchorEl] = useState(null);
  const [showDownloadDialog, setShowDownloadDialog] = useState(false);

  const {
    user: { test_devices: demo_test_devices },
  } = useDemoState();
  const demoDispatch = useDemoDispatch();

  useEffect(() => {
    if (isExperienceDashboard()) {
      getTestDevicesAPI(
        auth,
        DEMO_ECOMMERCE_APP_ID,
        {},
        isExperienceDashboard(),
      ).then((devices) => {
        demoDispatch({
          type: SET_TEST_DEVICES,
          payload: devices,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demoDispatch]);

  const testDevices = useMemo(
    () => (isExperienceDashboard() ? demo_test_devices : test_devices),
    [demo_test_devices, test_devices],
  );
  const selectedTestDevice = useMemo(() => selected, [selected]);
  const selectedTestDeviceLabel = useMemo(() => selectedLabel, [selectedLabel]);

  return (
    <>
      <RowDiv
        center
        styles={{
          marginBottom: 16,
        }}
        id="preview-device-selector"
      >
        <Button
          variant="contained"
          color="secondary"
          style={{
            marginTop: 8,
            borderRadius: 20,
            paddingRight: 26,
            background: "rgba(0, 40, 69, 0.1)",
          }}
          disabled={null ? true : false}
          onClick={(e) => setTestDevicesAnchorEl(e.target)}
        >
          <BootstrapTooltip
            title={selectedTestDeviceLabel || "No device selected"}
            placement="top"
          >
            <Typography
              variant="subtitle1"
              color="textPrimary"
              style={{
                maxWidth: 120,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                fontWeight: 600,
                fontSize: 13,
              }}
            >
              {selectedTestDeviceLabel || "No device selected"}
            </Typography>
          </BootstrapTooltip>
          <ExpandMoreSharp
            style={{
              color: "#8497A5",
            }}
          />
        </Button>
        <CustomPopper
          anchorEl={testDevicesAnchorEl}
          setAnchorEl={setTestDevicesAnchorEl}
        >
          {platform !== APP_PLATFORMS.web ? (
            <Autocomplete
              button={{
                label: "+ Register New Device",
                value: "+ Register New Device",
                isButton: true,
              }}
              defaultValue={{
                label: selectedTestDeviceLabel,
                value: selectedTestDevice,
              }}
              handleOnChange={(selectedValue) => {
                if (selectedValue.isButton) {
                  // Show Download Dialog
                  setShowDownloadDialog(true);
                  setTestDevicesAnchorEl(null);
                  return;
                }

                publishDesignForPreviewAPI(
                  auth,
                  auth.appId,
                  { deviceId: selectedValue.value },
                  {
                    type: CAMPAIGN_TYPE_ENUM.IN_LINE,
                    cmd: "show",
                  }
                );

                parentDispatch({
                  type: SET_SELECTED_TEST_DEVICE,
                  value: selectedValue,
                });
                setTestDevicesAnchorEl(null);
              }}
              options={testDevices.map((device) => ({
                label: `${
                  device?.hasOwnProperty("nick_name")
                    ? device?.nick_name || device?.model
                    : device?.model
                } - ${device?.id}`,
                value: device?.id,
              }))}
              maxDisplayItems={5}
              floatButton={false}
              styles={{
                width: 300,
              }}
            />
          ) : (
            <Autocomplete
              defaultValue={{
                label: selectedTestDeviceLabel,
                value: selectedTestDevice,
              }}
              handleOnChange={(selectedValue) => {
                if (selectedValue.isButton) {
                  // Show Download Dialog
                  setShowDownloadDialog(true);
                  setTestDevicesAnchorEl(null);
                  return;
                }

                publishDesignForPreviewAPI(
                  auth,
                  auth.appId,
                  { deviceId: selectedValue.value },
                  {
                    type: CAMPAIGN_TYPE_ENUM.IN_LINE,
                    cmd: "show",
                  }
                );
                parentDispatch({
                  type: SET_SELECTED_TEST_DEVICE,
                  value: selectedValue,
                });
                setTestDevicesAnchorEl(null);
              }}
              options={testDevices.map((device) => ({
                label: `${
                  device.hasOwnProperty("nick_name")
                    ? device.nick_name || device.model
                    : device.model
                } - ${device.id}`,
                value: device.id,
              }))}
              maxDisplayItems={5}
              floatButton={false}
              styles={{
                width: 300,
              }}
            />
          )}
        </CustomPopper>
        <Button
          variant="contained"
          color="secondary"
          className={classes.previewButton}
          style={{
            marginRight:
              getUI(currentStep, uis, isExperiment).type === "IN_LINE"
                ? -28
                : -16,
          }}
          onClick={() => {
            if (
              !(
                isExperienceDashboard() &&
                (experience_type === EXPERIENCE_CONFIG.FEATURE_DISCOVERY ||
                  experience_type === EXPERIENCE_CONFIG.CART_DELETION_SURVEY)
              )
            ) {
              Apxor.logEvent(
                "PreviewClicked",
                {
                  "device_id": selectedTestDevice?.id
                    ? selectedTestDevice?.id
                    : selectedTestDevice,
                  "experience_type": experience_type,
                },
                APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS,
              );

              publishDesignForPreviewAPI(
                auth,
                auth.appId,
                {
                  deviceId: selectedTestDevice?.id
                    ? selectedTestDevice?.id
                    : selectedTestDevice,
                },
                {
                  type:
                    getUI(currentStep, uis, isExperiment).type ||
                    CAMPAIGN_TYPE_ENUM.IN_LINE,
                  ui,
                },
              );
            }
          }}
        >
          <PlayCircleFilledRoundedIcon
            color="primary"
            style={{
              marginRight: 4,
            }}
          />
          <Typography
            variant="subtitle1"
            color="textPrimary"
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              fontWeight: 600,
              fontSize: 13,
            }}
          >
            Preview On Device
          </Typography>
        </Button>
      </RowDiv>
      <CustomMaterialUIDialog
        dialogContent={
          isExperienceDashboard() ? (
            <ExperienceDemoFinishStep
              close_dialog={() => {
                setShowDownloadDialog(false);
              }}
              just_display_access_code={true}
            />
          ) : (
            <DownloadAppDialog
              handleDone={() => setShowDownloadDialog(false)}
              handleClose={() => setShowDownloadDialog(false)}
            />
          )
        }
        maxWidth={isExperienceDashboard() ? "md" : "xl"}
        isFullWidth={false}
        dialogContentStyles={{
          padding: isExperienceDashboard() ? "50px" : 0,
          paddingTop: "0px",
          margin: 0,
        }}
        dialogTitleStyles={{
          ...(isExperienceDashboard() && { padding: "26px" }),
        }}
        title={!isExperienceDashboard() && "Download Apxor App"}
        openFlag={showDownloadDialog}
        onClose={() => {
          setShowDownloadDialog(false);
        }}
        onDialogClose={() => setShowDownloadDialog(false)}
      />
    </>
  );
}
