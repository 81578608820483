import { Grid, Typography } from "@material-ui/core";
import React from "react";
import {
  IconImageComponent,
  useTypographyStyles,
} from "../../../../../../utils";
import Scheduling from "./Scheduling";
import schedule_icon from "../../../../assets/schedule_icon.png";

export default function SchedulingSection() {
  const typographyClasses = useTypographyStyles();

  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={3}
        style={{
          display: "flex",
          flexDirection: "column",
          borderRight: "1px solid #E4E7E9",
          alignItems: "center",
          justifyContent: "center",
          padding: 16,
        }}
      >
        <Typography
          className={typographyClasses.h5_800}
          style={{ marginBottom: 10 }}
        >
          SCHEDULE
        </Typography>
        {IconImageComponent(schedule_icon, "Schedule", {
          width: 157,
          height: 157,
        })}
      </Grid>
      <Grid item xs={9} style={{ padding: 24 }}>
        <Scheduling />
      </Grid>
    </Grid>
  );
}
