import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import campaign2 from "../images/campaign2.svg";
import campaign3 from "../images/campaign3.svg";
import campaign1 from "../images/campaign1.svg";
import surveys1 from "../images/surveys1.svg";
import surveys2 from "../images/surveys2.svg";
import surveys3 from "../images/surveys3.svg";
import { getCampaignCountAPI } from "../actions";
import { useAuth } from "../../../../../contexts/AuthContext";
import { formatNumber } from "../../../../../utils";
import { isDemoApplicaton, isExperienceDashboard } from "../../../../../config";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 100,
  },
  number: {
    fontFamily: "Manrope",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "22px",
    lineHeight: "30px",
    letterSpacing: "-0.8125px",
    textTransform: "uppercase",
    color: "#002845",
  },
  text: {
    fontFamily: "Manrope",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "19px",
    color: "#002845",
    marginLeft: "10px",
  },
}));

const StyledFlexContainer = withStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginInline: "20px",
    "& > *": {
      marginInline: theme.spacing(2),
    },
  },
}))(Box);

const walkthroughIcons = [campaign1, campaign2, campaign3];
const walkthroughTexts = ["Campaigns", "People Reached", "Goal Reached"];
const surveysIcons = [surveys1, surveys2, surveys3];
const surveysTexts = ["Surveys", "People Reached", "People Responded"];

const getCampaignNumbers = (numbers) => {
  return [numbers?.campaign_count, numbers?.user_count, numbers?.percentage];
};

//FIXME: Move to Reusable components after merging with dashboards and A/B
export default function CampaignMetricsCard({
  campaignType,
  appId,
  active,
  callback,
  isDashboards = true,
  getCampaignMetricsAPI = null,
  apiMeta = { postBody: [], params: {} },
  isExperiment,
}) {
  const classes = useStyles();
  const auth = useAuth();

  const [campaignNumbers, setCampaignNumbers] = useState({});
  const [icons, texts, numbers] =
    campaignType === "walkthrough"
      ? [
          walkthroughIcons,
          walkthroughTexts,
          getCampaignNumbers(campaignNumbers),
        ]
      : [surveysIcons, surveysTexts, getCampaignNumbers(campaignNumbers)];

  //we need count of number of active walkthroughs too

  useEffect(() => {
    if (isDashboards) {
      if (isExperienceDashboard() && isDemoApplicaton(appId)) {
        if (campaignType === "walkthrough") {
          return setCampaignNumbers({
            campaign_count: 116,
            user_count: 443120,
          });
        } else
          return setCampaignNumbers({
            campaign_count: 56,
            user_count: 83210,
          });
      } else {
        getCampaignCountAPI(auth, appId, campaignType, false).then(
          (response) => {
            setCampaignNumbers(response);
            if (callback) callback(response);
          }
        );
      }
    } else {
      getCampaignMetricsAPI(
        isExperiment,
        auth,
        apiMeta.params,
        apiMeta.postBody,
        false
      ).then((response) => {
        setCampaignNumbers({
          campaign_count: response?.count,
          user_count: response?.conversion_count,
          percentage: response?.percentage,
        });
      });
    }
  }, []);

  return (
    <Grid
      container
      spacing={0}
      justifyContent="flex-start"
      alignItems="center"
      style={{
        height: 100,
        backgroundColor: "#ffffff",
        border: "1px solid #EAECEE",
        borderRadius: "4px",
      }}
    >
      <Grid item md={4}>
        <StyledFlexContainer>
          <img src={icons[0]} alt="icon" />
          <Box
            style={{
              display: "flex",
              alignItems: "baseline",
            }}
          >
            <Typography className={classes.number}>
              {formatNumber(numbers[0])}
            </Typography>
            <Typography className={classes.text}>
              {isDashboards ? texts[0] : "Users"}
            </Typography>
          </Box>
        </StyledFlexContainer>
      </Grid>
      <Grid item md={4}>
        <StyledFlexContainer>
          <img src={icons[1]} alt="icon" />
          <Box
            style={{
              display: "flex",
              alignItems: "baseline",
            }}
          >
            <Typography className={classes.number}>
              {formatNumber(numbers[1])}
            </Typography>
            <Typography className={classes.text}>
              {isDashboards ? texts[1] : "Conversions"}
            </Typography>
          </Box>
        </StyledFlexContainer>
      </Grid>
      {!isDashboards && (
        <Grid item md={4}>
          <StyledFlexContainer>
            <img src={icons[2]} alt="icon" />
            <Box
              style={{
                display: "flex",
                alignItems: "baseline",
              }}
            >
              <Typography className={classes.number}>
                {formatNumber(numbers[2])}%
              </Typography>
              <Typography className={classes.text}>
                {isDashboards ? texts[1] : "Conversion Rate"}
              </Typography>
            </Box>
          </StyledFlexContainer>
        </Grid>
      )}
    </Grid>
  );
}
