import { Divider } from "@material-ui/core";
import PieChartIcon from "@material-ui/icons/PieChart";
import React, { useMemo, useState } from "react";
import { useAuth } from "../../../../contexts/AuthContext";
import { useDeepCompareEffect } from "../../../../utils/use-deep-compare";
import { getAttributes } from "../../../common/actions";
import UserSessionDistributions from "../../../ReusableComponents/UserSessionDistributions";
import Filters from "../../common/components/Filters";
import { useDateFilters } from "../../common/utils";
import { EventMetrics, TimeSpents, TopEvents, UserCounts } from "./components";
import { Provider, useTrackedState } from "./store";

function EventAnalysisWithProvider({ app }) {
  const auth = useAuth();

  const state = useTrackedState();

  const [userAttributes, setUserAttributes] = useState([]);
  const [sessionAttributes, setSessionAttributes] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalSessions, setTotalSessions] = useState(0);

  const [dateFilters, setDateFilters] = useDateFilters();
  const [cohort, setCohort] = useState(null);
  const [version, setVersion] = useState(null);

  const queryParams = useMemo(
    () => ({
      ...dateFilters,
      versions: version,
      cohort: cohort,
      group: state.group,
      startDay: state.startDay,
      endDay: state.endDay,
    }),
    [cohort, dateFilters, state.startDay, state.endDay, state.group, version],
  );

  useDeepCompareEffect(() => {
    getAttributes(auth, app.app_id).then((attributes) => {
      setUserAttributes(attributes.user);
      setSessionAttributes(attributes.session);
    });
  }, [auth, app.app_id]);

  // Event Analysis State
  const [filters, setFilters] = useState({
    event: [],
    session: [],
    user: [],
  });

  return (
    <>
      <Filters
        app={app}
        dateFilters={dateFilters}
        showVersion
        showCohort
        onVersionChange={(version) =>
          setVersion(version === "" ? null : version)
        }
        onCohortChange={(cohortId) =>
          setCohort(cohortId === "" ? null : cohortId)
        }
        onDateFiltersChange={(start, end) =>
          setDateFilters({
            since: start,
            till: end,
          })
        }
      />
      <Divider style={{ marginBottom: 10 }} />
      <section className="content">
        <UserCounts
          appId={app.app_id}
          queryParams={queryParams}
          filters={filters}
          onUsersFetch={(value) => setTotalUsers(value)}
          onSessionsFetch={(value) => setTotalSessions(value)}
        />
        <UserSessionDistributions
          appId={app.app_id}
          title={"Distributions by property"}
          icon={<PieChartIcon />}
          queryParams={queryParams}
          filters={filters}
          userAttributes={userAttributes}
          sessionAttributes={sessionAttributes}
          onFiltersChange={(user = [], session = []) => {
            setFilters({
              ...filters,
              user: user,
              session: session,
            });
          }}
        />
        <TopEvents
          appId={app.app_id}
          queryParams={queryParams}
          filters={filters}
        />
        <EventMetrics
          appId={app.app_id}
          totals={{ users: totalUsers, sessions: totalSessions }}
          queryParams={queryParams}
          filters={filters}
          eventList={state.eventList || []}
          userAttributes={userAttributes}
          sessionAttributes={sessionAttributes}
        />
        <TimeSpents
          appId={app.app_id}
          features={app.features}
          queryParams={queryParams}
          filters={filters}
        />
      </section>
    </>
  );
}

export default function EventAnalysis({ app }) {
  return (
    <Provider>
      <EventAnalysisWithProvider app={app} />
    </Provider>
  );
}
