import { Grid, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import ActionsTextField from "../../../../../../../../../ReusableComponents/ActionsTextField";
import RowDiv from "../../../../../../../../../ReusableComponents/RowDiv";
import { useCommonConfigStyles } from "../../../../../utils";

export default function VariantDistributions({
  variants,
  onPercentageChange,
  isSummary = false,
}) {
  const classes = useCommonConfigStyles();

  const variantCodes = useMemo(() => Object.keys(variants).sort(), [variants]);
  return (
    <>
      <RowDiv center styles={{ marginBottom: 20, marginTop: 8 }}>
        <Typography className={classes.typographyCaption}>
          Variant allocation to selected % target group
        </Typography>
      </RowDiv>
      <Grid container spacing={1} style={{ gap: isSummary ? 10 : "unset" }}>
        {variantCodes
          .filter((name) => name !== "controlGroup" && name !== "CG")
          .map((variant, index) => (
            <Grid item xs={12} key={index}>
              <Grid container>
                <Grid item xs={4}>
                  <Typography className={classes.typographyCaption}>
                    {variant} - {variants[variant].name}
                    {isSummary ? ":" : ""}
                  </Typography>
                </Grid>
                <Grid item>
                  {!isSummary && (
                    <RowDiv>
                      <ActionsTextField
                        value={variants[variant].distribution ?? 10}
                        style={{
                          maxWidth: 72,
                          marginBottom: 0,
                          margin: "0 6px",
                        }}
                        InputProps={{
                          min: 1,
                        }}
                        onChange={(e) =>
                          onPercentageChange(variant, Number(e.target.value))
                        }
                        type="number"
                      />
                      <Typography
                        className={classes.typographyCaption500}
                        style={{ margin: "auto" }}
                      >
                        %
                      </Typography>
                    </RowDiv>
                  )}
                  {isSummary && (
                    <Typography className={classes.typographyCaption}>
                      {variants[variant].distribution ?? 10}%
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ))}
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={4}>
              <Typography className={classes.typographyCaption}>
                Control Group
                {isSummary ? ":" : ""}
              </Typography>
            </Grid>
            <Grid item>
              {!isSummary && (
                <RowDiv>
                  <ActionsTextField
                    value={variants?.CG?.distribution ?? 10}
                    style={{
                      maxWidth: 72,
                      marginBottom: 0,
                      margin: "0 6px",
                    }}
                    InputProps={{ min: 1 }}
                    onChange={(e) =>
                      onPercentageChange("CG", Number(e.target.value))
                    }
                    type="number"
                  />
                  <Typography
                    className={classes.typographyCaption500}
                    style={{ margin: "auto" }}
                  >
                    %
                  </Typography>
                </RowDiv>
              )}
              {isSummary && (
                <Typography className={classes.typographyCaption}>
                  {variants?.CG?.distribution ?? 10}%
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );

}