let nextVariableId = 1234;
let index = -1;

export function reducer(state, action) {
  index = -1;
  let variables = state.variables;
  const { type, id, ...props } = action;
  switch (type) {
    case "ADD_VARIABLE":
      return {
        variables: [
          ...state.variables,
          { ...state.currentVariable, id: ++nextVariableId },
        ],
        currentVariable: {},
      };
    case "SET_PROPERTY":
      if (action.id === "current") {
        let currentVariable = {
          ...state.currentVariable,
          [props.key]: props.value,
        };
        if (props.key === "t") {
          currentVariable = {
            ...currentVariable,
            t: props.value,
            [currentVariable.t === "ar" ? "p" : "n"]: "",
            def: "",
          };
        }
        return {
          ...state,
          variables: [...state.variables],
          currentVariable,
        };
      } else {
        index = state.variables.findIndex(
          (eachVariable) => eachVariable.id === action.id
        );
        if (index >= 0) {
          if (props.key === "t") {
            variables[index] = {
              ...variables[index],
              t: props.value,
              [variables[index].t === "ar" ? "p" : "n"]: "",
              def: "",
            };
          } else {
            variables[index][props.key] = props.value;
          }
        }
        return {
          ...state,
          variables: [...variables],
        };
      }
    case "DELETE_VARIABLE":
      variables = variables.filter((variable) => variable.id !== id);
      return { ...state, variables: [...variables] };
    default:
      return { ...state, variables: [...state.variables] };
  }
}
