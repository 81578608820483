import debounce from "lodash/debounce";
import { DATA_TYPES } from "./components/constants";

export const UPDATE_TEST_DEVICES = "UPDATE_TEST_DEVICES";
export const SET_GOAL_MET_CHECK = "SET_GOAL_MET_CHECK";
export const REMOVE_TEST_DEVICE = "REMOVE_TEST_DEVICE";
export const ENABLE_CONDITIONS = "ENABLE_CONDITIONS";
export const SET_ADD_CONDITION = "SET_ADD_CONDITION";
export const SET_DEMO_UPSERT_CONDITION = "SET_DEMO_UPSERT_CONDITION";
export const SET_MOVE_CONDITION = "SET_MOVE_CONDITION";
export const SET_REMOVE_CONDITION = "SET_REMOVE_CONDITION";
export const SET_UPDATE_CONDITION = "SET_UPDATE_CONDITION";
export const SET_APP_SCREENS = "SET_APP_SCREENS";
export const SET_APP_SCREEN_NAMES = "SET_APP_SCREEN_NAMES";
export const SET_ATTRIBUTES = "SET_ATTRIBUTES";
export const SET_AUDIENCE_TYPE = "SET_AUDIENCE_TYPE";
export const SET_AUDIENCE_USER_ATTR = "SET_AUDIENCE_USER_ATTR";
export const SET_AUTO_DISMISS_DURATION = "SET_AUTO_DISMISS_DURATION";
export const SET_AUTO_DISMISS_ENABLED = "SET_AUTO_DISMISS_ENABLED";
export const SET_COHORT_ID = "SET_COHORT_ID";
export const SET_CONFIG = "SET_CONFIG";
export const SET_CONTEXT_TYPE = "SET_CONTEXT_TYPE";
export const SET_DIDNOT_DO_EVENTS = "SET_DIDNOT_DO_EVENTS";
export const SET_EVENTS = "SET_EVENTS";
export const SET_FREQ_COUNT = "SET_FREQ_COUNT";
export const SET_FREQ_DAY_LIMIT = "SET_FREQ_DAY_LIMIT";
export const SET_FREQ_LIMITS_ENABLED = "SET_FREQ_LIMITS_ENABLED";
export const SET_FREQ_SESSION_LIMIT = "SET_FREQ_SESSION_LIMIT";
export const SET_FREQ_VALIDITY = "SET_FREQ_VALIDITY";
export const SET_GOAL_EVENT = "SET_GOAL_EVENT";
export const SET_META_CONFIG_TYPE = "SET_META_CONFIG_TYPE";
export const SET_META_TYPE = "SET_META_TYPE";
export const SET_META_NAME = "SET_META_NAME";
export const SET_META_DESC = "SET_META_DESC";
export const SET_META_PLATFORM = "SET_META_PLATFORM";
export const SET_META_USECASE = "SET_META_USECASE";
export const SET_META_TAGS = "SET_META_TAGS";
export const SET_NTH_EVENT = "SET_NTH_EVENT";
export const SET_OPERATOR_AND_SEQUENCE_ENABLED =
  "SET_OPERATOR_AND_SEQUENCE_ENABLED";
export const SET_PRIORITY = "SET_PRIORITY";
export const SET_RETAINED_DAY_FROM = "SET_RETAINED_DAY_FROM";
export const SET_RETAINED_DAY_TO = "SET_RETAINED_DAY_TO";
export const SET_SESSION_LIMIT_FROM = "SET_SESSION_LIMIT_FROM";
export const SET_SESSION_LIMIT_TO = "SET_SESSION_LIMIT_TO";
export const SET_SEGMENT = "SET_SEGMENT";
export const SET_ROLL_INTERVAL = "SET_ROLL_INTERVAL";
export const SET_ROLL_RECURR = "SET_ROLL_RECURR";
export const SET_ROLL_STEP = "SET_ROLL_STEP";
export const SET_TEST_DEVICES = "SET_TEST_DEVICES";
export const SET_ENABLE_TESTING = "SET_ENABLE_TESTING";
export const SET_TIME_LIMITS_END = "SET_TIME_LIMITS_END";
export const SET_TIME_LIMITS_START = "SET_TIME_LIMITS_START";
export const SET_TIME_SPENT = "SET_TIME_SPENT";
export const SET_TOGGLE_RECURR = "SET_TOGGLE_RECURR";
export const SET_TOGGLE_RETAINED_DAY = "SET_TOGGLE_RETAINED_DAY";
export const SET_TOGGLE_ROLL = "SET_TOGGLE_ROLL";
export const SET_TOGGLE_SESSION_LIMITS = "SET_TOGGLE_SESSION_LIMITS";
export const SET_TOGGLE_TIME_LIMITS_ENABLED = "SET_TOGGLE_TIME_LIMITS_ENABLED";
export const SET_UI = "SET_UI";
export const SET_UIS = "SET_UIS";
export const SET_VALIDITY_END = "SET_VALIDITY_END";
export const SET_VALIDITY_START = "SET_VALIDITY_START";
export const SET_COHORTS = "SET_COHORTS";
export const SET_SEGMENTS = "SET_SEGMENTS";
export const SET_DEFAULT_TEST_DEVICES = "SET_DEFAULT_TEST_DEVICES";
export const SET_CLIENT_EVENTS = "SET_CLIENT_EVENTS";
export const SET_SCHEDULING_INSTANCE = "SET_SCHEDULING_INSTANCE";
export const SET_SELECTED_TEST_DEVICE = "SET_SELECTED_TEST_DEVICE";
export const SET_SCHEDULING_TIME = "SET_SCHEDULING_TIME";

export const ADD_NEW_PROPERTY_IN_AUDIENCE = "ADD_NEW_PROPERTY_IN_AUDIENCE";
export const SET_AUDIENCE_ATTRIBUTE = "SET_AUDIENCE_ATTRIBUTE";
export const REMOVE_AUDIENCE_ATTRIBUTE = "REMOVE_AUDIENCE_ATTRIBUTE";
export const SET_TOGGLE_USER_ATTRIBUTES = "SET_TOGGLE_USER_ATTRIBUTES";
export const SET_TOGGLE_SESSION_ATTRIBUTES = "SET_TOGGLE_SESSION_ATTRIBUTES";
export const SET_TOGGLE_APP_VERSION_FILTER = "SET_TOGGLE_APP_VERSION_FILTER";
export const SET_VERSION_OPERATOR = "SET_VERSION_OPERATOR";
export const SET_VERSION_VALUE = "SET_VERSION_VALUE";
export const SET_EXPERIMENT = "SET_EXPERIMENT";

export const SET_SNACKBAR_ERROR_MESSAGE = "SET_SNACKBAR_ERROR_MESSAGE";

export const SET_VARIANT_PERCENTAGE = "SET_VARIANT_PERCENTAGE";
export const SET_TARGET_PERCENTAGE = "SET_TARGET_PERCENTAGE";
export const SET_AUTO_PUBLISH_WINNER = "SET_AUTO_PUBLISH_WINNER";
export const ADD_STEP_TO_VARIANT = "ADD_STEP_TO_VARIANT";
export const ADD_VARIANT = "ADD_VARIANT";
export const SET_CURRENT_VARIANT_STEP = "SET_CURRENT_VARIANT_STEP";
export const DELETE_VARIANT = "DELETE_VARIANT";
export const COPY_VARIANT_STEP = "ON_COPY_STEP";

export const SET_SELECTED_TEST_VARIANT = "SET_SELECTED_TEST_VARIANT";
export const DELETE_STEP_FROM_VARIANT = "DELETE_STEP_FROM_VARIANT";
export const ON_COPY_STEP_VARIANT = "ON_COPY_STEP_VARIANT";

export const SET_TEST_START_DATE = "SET_TEST_START_DATE";
export const SET_TEST_END_DATE = "SET_TEST_END_DATE";
export const SET_TEST_SHOW_AT_SPECIFIC_TIME = "SET_TEST_SHOW_AT_SPECIFIC_TIME";
export const SET_TEST_TIME_LIMITS_START = "SET_TEST_TIME_LIMITS_START";
export const SET_TEST_TIME_LIMITS_END = "SET_TEST_TIME_LIMITS_END";

export const SET_PUBLISH_START_DATE = "SET_PUBLISH_START_DATE";
export const SET_PUBLISH_END_DATE = "SET_PUBLISH_END_DATE";
export const SET_PUBLISH_SHOW_AT_SPECIFIC_TIME =
  "SET_PUBLISH_SHOW_AT_SPECIFIC_TIME";
export const SET_PUBLISH_TIME_LIMITS_START = "SET_PUBLISH_TIME_LIMITS_START";
export const SET_PUBLISH_TIME_LIMITS_END = "SET_PUBLISH_TIME_LIMITS_END";
export const SET_CAMPAIGN_PUBLISH_CG = "SET_CAMPAIGN_PUBLISH_CG";

//export const GENERAL = "GENERAL";
export const DESIGN = "DESIGN";
export const CONTEXT = "CONTEXT";
export const STRATEGY = "STRATEGY";
export const SUMMARY = "SUMMARY";
export const TARGET = "TARGET";
export const SCHEDULE = "SCHEDULE";
export const REVIEW = "REVIEW";

export const DEFAULT_STEP_ORDER = {
  // GENERAL: 0,
  DESIGN: 0,
  TARGET: 1,
  SCHEDULE: 2,
  REVIEW: 3,
};

export const MESSAGE_CONFIGURATIONS = {
  //GENERAL: "General",
  DESIGN: "Design",
  TARGET: "Target",
  SCHEDULE: "Schedule",
  REVIEW: "Review",
};

export const APP_PLATFORMS = {
  android: "android",
  ios: "ios",
  web: "web",
  omni: "omni",
};

export const CONDTIONAL_OPERATORS = {
  AND: "AND",
  OR: "OR",
};

export const EVENT_CONDTION_TYPES = {
  DEFAULT_CS: {
    cs: "conditions",
    se: "sequence_enabled",
  },
  DOWNLOAD_CS: {
    cs: "pre_cs",
    se: "pre_cs_seq_enabled",
  },
  DELETE_CS: {
    cs: "terminate_info.conditions",
    se: "terminate_info.sequence_enabled",
  },
};

export const TRIGGER_CONFIG = {
  trigger: {
    // DEFAULT FOR ALL (app_start)
    event_type: "app_start",
    activity: "",
    details: {
      name: "",
      additional_info: {},
    },
    time_bounds: {
      lower: 0,
      upper: 60000000,
    },
  },
};

export const TIME_SPENT_TRIGGER = {
  trigger: {
    ...TRIGGER_CONFIG.trigger,
    event_type: "activity_event",
  },
};

export const NTH_EVENT_DEFAULT_CONFIG = {
  event_type: "app_event",
  dummy_event_type: "nth",
  time_bounds: {
    lower: 0,
    upper: 6000000,
  },
  count_config: {
    count: 1,
    operator: "GTE",
  },
  combine_operator: CONDTIONAL_OPERATORS.OR,
  activity: "",
  details: {
    additional_info: {},
    name: "",
  },
  ...TRIGGER_CONFIG,
};

export const NO_KPI_DEFAULT_CONFIG = {
  event_type: "idle_time",
  time_bounds: {
    lower: 0,
    upper: 6000000,
  },
  count_config: {
    count: 1,
    operator: "GTE",
  },
  combine_operator: CONDTIONAL_OPERATORS.OR,
  activity: "",
  details: {
    additional_info: {
      time: 5000,
      nkpi: [],
    },
    name: "",
  },
  ...TIME_SPENT_TRIGGER,
};

export const AUDIENCE_TYPES = [
  {
    title: "All Users",
    key: "ALL",
    // description: "All your users",
    disabled: false,
  },
  {
    title: "Segment",
    key: "SEGMENT",
    // description: "Select user segments",
    disabled: false,
  },
  {
    title: "Cohort",
    key: "COHORT",
    // description: "Select cohort of users",
    disabled: false,
  },
];

export const getAudienceIndex = (audienceType) => {
  return AUDIENCE_TYPES.map((x) => x.key).indexOf(audienceType);
};

export const TIME_TYPES = [
  {
    title: "Activity Time",
    key: "activity_time",
    disabled: false,
  },
  {
    title: "Idle Time",
    key: "idle_time",
    disabled: false,
  },
];

export const CLIENT_EVENTS_FOR_APP = {
  d831634e11cf6c424b817e22761710e2: {
    "Bottom Nav Selection": {
      selection: "string",
    },
    CommentAdded: {
      apx_lt_count: "number",
    },
    CommentListLoaded: {
      apx_lt_count: "number",
      appVersion: "string",
    },
    "Create Team Loaded": {
      apx_lt_count: "number",
      appVersion: "string",
      source: "string",
    },
    "Match Center Loaded": {
      appVersion: "string",
    },
    matchcentredloaded: {
      appVersion: "string",
    },
    CommentLongPressTapped: {
      appVersion: "string",
    },
    FeedCampaignButtonClicked: {
      sportName: "string",
    },
    FeedLoadedClient: {},
    "Round Selected": {
      appVersion: "string",
      source: "string",
      roundStatus: "string",
    },
    ContestPageInitiated: {
      appVersion: "string",
    },
    ContestShown: {
      appVersion: "string",
    },
    ScreenLoaded: {
      appVersion: "string",
      TabSelected: "string",
      ScreenName: "string",
      sportName: "string",
    },
    PlayerStatsOpen: {
      appVersion: "string",
      onboardingFlag: "boolean",
      sportName: "string",
      roundId: "number",
      contestId: "string",
      pipRoundId: "number",
      source: "string",
      ActiveScreenName: "string",
    },
    "League Card Clicked": {
      appVersion: "string",
      sportName: "string",
      contestType: "string",
      category: "string",
      isConfirmed: "boolean",
      contestCategory: "string",
      ActiveScreenName: "string",
    },
    "Create Team Next Tapped": {
      appVersion: "string",
      onboardingFlag: "boolean",
      sportName: "string",
      tourId: "number",
      pipRoundId: "number",
      ActiveScreenName: "string",
      abTestParameter: "string",
      roundId: "number",
    },
  },
  "20ffd8ea-2617-45bf-be5a-04be9e0e4657": {
    ButtonClicked: {},
    SymbolButtonClicked: {
      rank: "string",
      kind: "string",
    },
  },
  "2e301190ed3376549cd12658f9c22844": {
    BottomNavigationClicked: {
      TabItemName: "string",
    },
    DiscoverThanksClicked: {},
    RequestOTPClicked: {
      AccountType: "string",
      Statue: "string",
      FailureReason: "string",
    },
    MobileRechargeScreenActions: {
      SearchAmountQuery: "string",
      ActionType: "string",
      SeePacksType: "string",
      Source: "string",
    },
    MoreMenuItemClicked: {
      ItemName: "string",
    },
    MoreSubMenuItemClicked: {
      MenuItemName: "string",
      SubMenuItemName: "string",
    },
    ProfileEditClicked: {},
    ProfileSaved: {
      UserName: "string",
      UserGender: "string",
      UserAge: "number",
      UserEmail: "string",
      Location: "string",
      isProfilePhotoUploaded: "boolean",
    },
    HomeScreenLaunched: {
      DTHValidityDays: "number",
      MobilePrepaidValidityDays: "number",
      DataCardValidityDays: "number",
      MobilePostpaidValidityDays: "number",
    },
    ScreenLoaded: {
      ScreenName: "string",
    },
    HomeComponentClicked: {
      ComponentType: "string",
    },
    Home: {
      isWalletPresent: "boolean",
    },
    ReminderCard: {
      CardType: "string",
      CardIndex: "number",
      NumberOfCards: "number",
      ExpiryValidity: "string",
      ExpiryMessage: "string",
    },
  },
  b571ecdc82247c3e8eca898ee3a93cdd: {
    "OTP Validated Successfully.": {},
    "Existing User : InstaCard_Availability": {
      InstaCard_Availability: "boolean",
    },
    Dashboard: {
      "Saathi update status": "boolean",
      "Details update time": "string",
    },
    "OTP Validation": {
      Error: "string",
    },
    ContactUs: {
      OnClick: "string",
    },
    "Emi Calculator": {
      "Event end time": "string",
      "Event start time": "string",
      Error: "string",
      InterestRate: "float",
      Amount: "integer",
      Tenure: "integer",
      TotalInterest: "float",
    },
    Location: {
      Location: "string",
    },
    INSTA_LOAN: {
      OnClick: "string",
    },
    Notifications: {
      OnClick: "string",
    },
    "Nearest Dealer": {
      "Event end time": "string",
      "Event start time": "string",
      Error: "string",
      OnClick: "string",
      Dealers: "integer",
    },
    "Two Wheeler Loan": {
      OnClick: "string",
    },
    "Refer friend details": {
      "Event end time": "string",
      "Event start time": "string",
    },
  },
};

export const CAMPAIGN_TYPE_ENUM = {
  IN_APP: "IN_APP",
  IN_LINE: "IN_LINE",
  WALKTHROUGH: "WALKTHROUGH",
  ONBOARDING: "ONBOARDING",
};

export const DEFAULT_DATA_TYPES = [
  {
    label: DATA_TYPES.STRING,
    value: "s",
  },
  {
    label: DATA_TYPES.BOOLEAN,
    value: "s",
  },
  {
    label: DATA_TYPES.DECIMAL,
    value: "f",
  },
  {
    label: DATA_TYPES.INTEGER,
    value: "l",
  },
];

export const CONTEXT_EVENT_TYPES_ENUM = {
  ACTIVITY: "activity_event",
  APP: "app_event",
  MESSAGE: "app_event",
  CLIENT: "client_event",
};

export const CONDITIONS_ENUM = {
  AND: "AND",
  OR: "OR",
};

export const MESSAGE_ENUM = {
  IN_APP: "IN_APP",
  IN_LINE: "IN_LINE",
  WALKTHROUGH: "WALKTHROUGH",
};

export const SCHEDULING_ENUM = {
  NOW: "NOW",
  LATER: "LATER",
};

export const TOGGLE_SWITCH_STYLES = (disabled = false) => ({
  trackBgColor: disabled ? "#4697028a" : "#469702",
  trackBorderColor: disabled ? "#4697028a" : "#469702",
  trackInActiveBgColor: "#677A87",
});

export const executeWithDebounce = (delay = 700) =>
  debounce((fn, ...args) => {
    fn(...args);
  }, delay);

export const DISPLAY_TEXT_ENUM = {
  AddClicked: "click on Add button of an item",
  BuyNowClicked: "click on BuyNow",
  HomeMenuClicked: "click on Menu in the Hamburger",
  HomePageLaunched: "land on HomePage",
  ItemDeleted: "delete an item from the cart",
  ItemSelected: "click on an item",
  LoginClicked: "click on login",
  LoginPageLaunched: "land on LoginPage",
  LogOutClicked: "click on logout",
  MyCartClicked: "click on the CartIcon",
  MyCartPageLaunched: "land on CartPage",
  OrderDetailPageLaunched: "land on OrderDetailsPage",
  OrderHistoryClicked: "click on OrderHistory",
  OTPEntered: "enter the OTP",
  PlaceOrderClicked: "click on PlaceOrder",
  ProfileClicked: "click on Profile",
  QuantityDecreased: "decrease quantity of an item in the cart",
  QuantityIncreased: "increase quantity of an item in the cart",
  SearchClicked: "click on search icon",
  SearchDismissClicked: "click on X in the search bar",
  SearchIconClicked: "click on the search icon",
  SearchResultsPageLaunched: "land on the search results page",
  ShopByCategoryClicked: "click on ShopByCategory menu item",
  ShopByCategorySelected: "select a category to shop",
  OkClicked: "confirm the order placed",
};
