import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import red from "@material-ui/core/colors/red";
import DownIcon from "@material-ui/icons/KeyboardArrowDown";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";
import RowDiv from "./RowDiv";

const styles = theme => ({
  card: {
    width: "100%",
    marginBottom: 12,
  },
  root: {
    width: "100%",
    padding: 8,
    "&:last-child": {
      paddingBottom: 8,
    },
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  flexGrow: {
    flex: "1 1 auto",
  },
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  container: {
    overflow: "initial !important",
  },
  actions: {
    height: "auto",
  },
  cursor: {
    cursor: "pointer",
  },
});

function Box({
  classes,
  children,
  controls,
  title,
  collapsible = false,
  icon = null,
  footer,
  headerWithBorder = false,
  withPadding = false,
  unmountOnExit = true,
  raised = false,
  elevation = 1,
  defaultExpanded = false,
  onCollapse = null,
  rowControls = false,
  styles = {},
}) {
  const [expanded, setExpanded] = useState(defaultExpanded);

  const rootStyles = () => {
    if (withPadding) {
      if (collapsible && !expanded) {
        return { ...styles };
      } else {
        return { padding: 5, ...styles };
      }
    }
    return { ...styles };
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
    if (onCollapse) {
      onCollapse(!expanded);
    }
  };

  return (
    <Card
      className={classes.card}
      style={rootStyles()}
      raised={raised}
      elevation={elevation}
    >
      {rowControls && (
        <RowDiv
          styles={{
            padding: 8,
            marginTop: 10,
            justifyContent: "space-between",
          }}
        >
          {controls}
        </RowDiv>
      )}
      <CardActions
        disableactionspacing="true"
        classes={{
          root: typeof title !== "string" && classes.actions,
        }}
        style={collapsible ? { cursor: "pointer" } : {}}
        onClick={handleExpandClick}
      >
        {icon && React.cloneElement(icon, { color: "primary" })}
        {typeof title === "string" && (
          <Typography variant="h5" noWrap>
            {title}
          </Typography>
        )}
        {typeof title !== "string" && title}
        <div className={classes.flexGrow} />
        {!rowControls && controls}
        {collapsible && (
          <IconButton
            color="primary"
            className={classnames(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="Expand"
          >
            <DownIcon />
          </IconButton>
        )}
      </CardActions>
      {headerWithBorder && <Divider />}
      {collapsible && (
        <Collapse
          in={expanded}
          className={classes.container}
          unmountOnExit={unmountOnExit}
        >
          <CardContent className={classes.root}>{children}</CardContent>
        </Collapse>
      )}
      {!collapsible && (
        <CardContent className={classes.root}>{children}</CardContent>
      )}
      {footer && <Divider />}
      {footer && <CardContent className={classes.root}>{footer}</CardContent>}
    </Card>
  );
}

Box.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
  ]),
  collapsible: PropTypes.bool,
  controls: PropTypes.node,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  icon: PropTypes.node,
  footer: PropTypes.node,
  headerWithBorder: PropTypes.bool,
  withPadding: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
  collapseNow: PropTypes.bool, //FIXME: Not sure with this implementation, giving handle to parent NOT A GOOD IDEA
  onCollapse: PropTypes.func,
};

export default withStyles(styles)(Box);
