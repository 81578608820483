import React from "react";
import { toTitleCase } from "../../../../../utils";
import SortableDataTable from "../../../../ReusableComponents/SortableDataTable";
import { useRouteMatch } from "react-router-dom";
import { useAuth } from "../../../../../contexts/AuthContext";
import Loading from "../../../../ReusableComponents/Loading";

export default function FlatTable({
  dimensions,
  metrics,
  flatTable: { aggregations = [], data = [] },
  filters,
  queryParams,
  fetching,
}) {
  const { url } = useRouteMatch();
  const urlWithAppId = url.replace("/apps/", "");

  const auth = useAuth();

  const headerLabels = [
    ...dimensions.map((o) => o.name),
    ...metrics.map((m) => {
      if (["users", "sessions"].includes(m)) return toTitleCase(m);
      return `${toTitleCase(m)} (${aggregations[m] || "NA"})`;
    }),
  ];

  const keys = [...dimensions.map((o) => o.name), ...metrics];

  if (fetching) {
    return (
      <section className="content">
        <Loading />
      </section>
    );
  } else {
    return (
      <div style={{ width: "100%" }}>
        <SortableDataTable
          downloadParams={{
            appId: urlWithAppId.substring(0, urlWithAppId.indexOf("/")),
            auth: auth,
            queryParams: queryParams,
            dimensions: dimensions,
            filters: filters,
            metrics: metrics,
          }}
          boxProps={{
            title: "Flat Table",
          }}
          data={data}
          headerLabels={headerLabels}
          keys={keys}
          tableType={"flat-table"}
          downloadReportTitle={`FlatTable-${new Date().toDateString()}`}
        />
      </div>
    );
  }
}
