import React, { useState } from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Chip from "@material-ui/core/Chip";
import moment from "moment";

import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const styles = (theme) => ({
  root: {
    fontFamily: "Manrope",
    fontWeight: 600,
    fontSize: 14,
    width: "100%",
    minHeight: 72,
    borderRadius: 3,
    padding: "2px 16px 3px 16px",
    "&:hover": {
      background: "#FFF9F5",
      cursor: "pointer",
      color: `#FF7F33`,
    },
  },
  highlight: {
    "&:hover": {
      color: `#FF7F33`,
    },
  },
});

function CohortCard({ cohort: { name, created_time, meta }, classes, index }) {
  const [expanded, setExpanded] = useState(false);
  const { description, created_from } = meta || "";
  return (
    <div style={{ width: "100%", margin: 4 }}>
      <ExpansionPanel
        className={classes.root}
        expanded={expanded}
        onChange={(e) => setExpanded(!expanded)}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container spacing={8}>
            <Grid item xs={1}>
              <Typography
                variant="subtitle1"
                style={{
                  marginTop: "0.8em",
                  fontWeight: 600,
                  fontSize: 14,
                  fontFamily: "Manrope",
                }}
              >
                {index}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography
                variant="subtitle1"
                style={{
                  marginTop: "0.8em",
                  fontWeight: 600,
                  fontSize: 14,
                  fontFamily: "Manrope",
                }}
              >
                {name}
              </Typography>
            </Grid>
            <Grid item xs style={{ alignSelf: "center" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <AttachFileIcon style={{ fontSize: 16 }} color={"disabled"} />
                <Chip
                  label="Cohort"
                  variant={"outlined"}
                  style={{ marginRight: 4, height: 28 }}
                />
              </div>
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        {expanded && (
          <ExpansionPanelDetails>
            <Grid container spacing={8} alignItems={"center"}>
              <Grid item xs={6}>
                <div
                  style={{
                    background: "rgb(238,238,238)",
                    borderRadius: 6,
                    padding: 12,
                    marginTop: 4,
                  }}
                >
                  <Typography style={{ color: "#666" }}>
                    {`Created From: ${created_from}`}
                  </Typography>
                  <Typography style={{ color: "#666", fontWeight: 600 }}>
                    {`File: ${description}`}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={6}>
                <Typography style={{ color: "#666" }}>Created at:</Typography>
                <Typography style={{ color: "#666", fontWeight: 600 }}>
                  {moment(created_time).format("LLL")}
                </Typography>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        )}
      </ExpansionPanel>
    </div>
  );
}

/**
 * Whenever the `cursor` state variable gets changed
 * in parent component, already rendered `SegmentHolder` component
 * might be re-rendered if we didn't wrap it with `React.memo`.
 *
 * So, it's important to remember to wrap it with `React.memo` when you
 * know that due to some change in parent might re-render this.
 */
export default withStyles(styles)(React.memo(CohortCard));
