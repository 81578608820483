import React, { useEffect, useState } from "react";
import { Grid, Tooltip, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MultiSelect from "./MultiSelect";
import debounce from "lodash/debounce";
import { getAttributeValuesAPI } from "../common/actions";
import { useAuth } from "../../contexts/AuthContext";
import { useCommonDashboardStyles } from "../common/constants";
import ColumnDiv from "./ColumnDiv";

const executeWithDebounce = debounce((fn, ...args) => {
  fn(...args);
}, 700);

export function AttributeRow({
  appId,
  filters,
  // Denotes a unique id for which this `AttributeRow` belongs to
  eventId,
  eventName,
  id,
  name,
  value,
  operator,
  operators,
  options,
  // react-tracked `dispatch` function with which we will post changes
  // to the store that might be maintained by the parent component
  dispatch,
  actions,
  disabled,
}) {
  const auth = useAuth();

  const classes = useCommonDashboardStyles();

  // Local State
  const [attrValues, setAttrValues] = useState([]);
  const [query, setQuery] = useState("");

  const {
    ACTION_SET_ATTRIBUTE_NAME,
    ACTION_SET_ATTRIBUTE_OPERATOR,
    ACTION_SET_ATTRIBUTE_VALUE,
    ACTION_REMOVE_ATTRIBUTE,
  } = actions;

  useEffect(() => {
    if (name !== "") {
      getAttributeValuesAPI(auth, appId, {
        ...filters,
        event: eventName,
        q: query,
        attribute: name,
      }).then((response) => {
        // FIXME: This triggeres multiple API calls, Find a way to minimize unnecessary API calls
        setAttrValues(response);
        dispatch({
          type: ACTION_SET_ATTRIBUTE_VALUE,
          eventId,
          attributeId: id,
          value: value,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, appId, name, filters, query]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={3}>
        <ColumnDiv>
          <Typography className={classes.typographyCaption500}>
            Select Property
          </Typography>
          <MultiSelect
            value={name}
            options={options.map((o) => ({ label: o, value: o }))}
            placeholder="Select Property"
            handleChange={(name) => {
              dispatch({
                type: ACTION_SET_ATTRIBUTE_NAME,
                eventId,
                attributeId: id,
                name: name,
              });
            }}
            single
            disabled={disabled}
          />
        </ColumnDiv>
      </Grid>
      {name && name !== "" && (
        <>
          <Grid item xs={12} md={2}>
            <ColumnDiv>
              <Typography className={classes.typographyCaption500}>
                Condition
              </Typography>
              <MultiSelect
                value={operator}
                options={operators}
                placeholder="Condition"
                handleChange={(operator) => {
                  dispatch({
                    type: ACTION_SET_ATTRIBUTE_OPERATOR,
                    eventId,
                    attributeId: id,
                    operator: operator,
                  });
                }}
                single
                disabled={disabled}
              />
            </ColumnDiv>
          </Grid>
          <Grid item xs={12} md={6}>
            <ColumnDiv>
              <Typography className={classes.typographyCaption500}>
                Value(s)
              </Typography>
              <MultiSelect
                placeholder="Search..."
                options={[
                  ...value.map((o) => ({ label: o, value: o })),
                  ...attrValues.map((item) => ({ label: item, value: item })),
                ]}
                handleChange={(value) => {
                  dispatch({
                    type: ACTION_SET_ATTRIBUTE_VALUE,
                    eventId,
                    attributeId: id,
                    value: value,
                  });
                }}
                onInputChange={(q, callback) => {
                  executeWithDebounce(setQuery, q);
                }}
                isAsync
                isCreatable={false}
                value={value}
                disabled={disabled}
              />
            </ColumnDiv>
          </Grid>
        </>
      )}
      <Grid item xs={12} md={1}>
        <Tooltip
          id={"attribute-filter-remove-" + id}
          title="Remove Filter"
          placement="top"
        >
          <IconButton
            style={{ marginTop: 8, color: "#A1ADB6" }}
            aria-label="Delete"
            onClick={() => {
              dispatch({
                type: ACTION_REMOVE_ATTRIBUTE,
                eventId: eventId,
                attributeId: id,
              });
            }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
}
