import React, { useState } from "react";
import {
  Grid,
  TextField,
  Typography,
  Button,
  FormLabel,
} from "@material-ui/core";
import { isValidEmail } from "../../../../../../utils";
import CustomMaterialUIDialog from "../../../../../ReusableComponents/CustomMaterialUIDialog";

export default function AddCustomer({ addCustomer, setIsLoading }) {
  const [email, setEmail] = useState("");
  const [addTriggered, setAddTriggered] = useState(false);

  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12} sm={8} md={6}>
          <FormLabel>User Email *</FormLabel>
          <TextField
            type="text"
            value={email}
            fullWidth
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            required
          />
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          style={{ alignSelf: "flex-end", marginLeft: 10 }}
        >
          <Button
            disabled={!isValidEmail(email)}
            variant="contained"
            color="primary"
            onClick={() => {
              setAddTriggered(true);
            }}
          >
            Add User
          </Button>
        </Grid>
      </Grid>
      <CustomMaterialUIDialog
        dialogActions={
          <div>
            <Button onClick={() => setAddTriggered(false)}>Cancel</Button>
            <Button
              onClick={() => {
                setIsLoading(true);
                setEmail("");
                setAddTriggered(false);
                addCustomer(email);
              }}
              color="primary"
            >
              Confirm
            </Button>
          </div>
        }
        dialogContent={
          <Typography>
            Are you sure want to give <strong>Full Access</strong> to{" "}
            <strong>{email}</strong> ?
          </Typography>
        }
        maxWidth={"sm"}
        onDialogClose={() => setAddTriggered(false)}
        openFlag={addTriggered}
        noTitleBg={true}
        title="Add User Confirmation"
      />
    </>
  );
}
