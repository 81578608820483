import React, { useState } from "react";
import { Button, Grid } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import { useDispatch } from "../../store";
import {
  ADD_VARIANT,
  DELETE_VARIANT,
  SET_CURRENT_VARIANT_STEP,
} from "../../../../../../common/components/campaigncreator/constants";
import { useTracked as useParentTracked } from "../../../../../../common/components/campaigncreator/store";
import CustomMaterialUIDialog from "../../../../../../../ReusableComponents/CustomMaterialUIDialog";
import { sortableContainer } from "react-sortable-hoc";
import CreateEditVariantDialog from "../../../../../../common/components/campaigncreator/components/CreateEditVariantDialog";
import VariantCard from "./components/VariantCard";
import BootstrapTooltip from "../../../../../../../ReusableComponents/BootstrapTooltip";
import {
  ON_EDIT_STEP,
  SET_CURRENT_STEP,
  DELETE_STEP_FROM_CAMPAIGN,
} from "../../constants";

const SortableContainer = sortableContainer(({ children }) => (
  <div className="cards" style={{ display: "flex" }}>
    {children}
  </div>
));

const VariantBuilder = ({ platform }) => {
  const dispatch = useDispatch();
  const [showVariantDialog, setShowVariantDialog] = useState(false);

  const [parentState, parentDispatch] = useParentTracked();
  const {
    config: { experiment: { currentVariantStep, variants } = {} },
  } = parentState;

  const handleVariantDialogDone = (variantName) => {
    parentDispatch({
      type: ADD_VARIANT,
      value: variantName,
    });
    setShowVariantDialog(false);
  };

  const getVariantNames = (variantObjects) => {
    return Object.keys(variantObjects)
      .filter((key) => key !== "CG")
      .map((eachKey) => variantObjects[eachKey].name);
  };

  const getVariantObject = (variantName, variantObjects) => {
    // Object.keys(variantObjects).filter((key) => key !== "CG").forEach((key) => {
    //   if (variantObjects[key].name === variantName)
    //     console.log(variantObjects[key]);
    // }
    for (const variantCode in variantObjects) {
      if (
        variantCode !== "CG" &&
        variantObjects[variantCode].name === variantName
      )
        return variantObjects[variantCode];
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: 6,
          border: "1px solid #E4E7E9",
          borderRadius: 3,
          height: 50,
          backgroundColor: "#F0F2F4",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#F0F2F4",
          }}
        >
          {Object.keys(variants).length >= 6 && (
            <BootstrapTooltip
              placement="bottom"
              title={"You can only have a maximum of 6 Variants including CG"}
            >
              <Button
                variant="contained"
                color={"gray"}
                disableRipple
                disableTouchRipple
                style={{
                  margin: "2px 11px 0px 3px",
                  borderRadius: 2,
                  height: 32,
                  alignItems: "center",
                  top: 5,
                  left: 4,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Add color="secondary" />
                </div>
              </Button>
            </BootstrapTooltip>
          )}
          {Object.keys(variants).length < 6 && (
            <Button
              variant="contained"
              style={{
                backgroundColor: "#002845",
                margin: "2px 11px 0px 3px",
                borderRadius: 2,
                height: 32,
                alignItems: "center",
                top: 5,
                left: 4,
              }}
              onClick={() => setShowVariantDialog(true)}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Add color="secondary" />
              </div>
            </Button>
          )}
        </div>
        <Grid
          container
          style={{
            backgroundColor: "#F0F2F4",
            overflow: "hidden",
            borderRadius: 3,
          }}
          spacing={0}
        >
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                overflowX: "auto",
                overflowY: "hidden",
                height: "100%",
              }}
            >
              {Array.isArray(Object.keys(variants)) && (
                <SortableContainer onSortEnd={() => null} axis="x" distance={1}>
                  {getVariantNames(variants).map((item, index) => (
                    <VariantCard
                      key={"key_card_" + index}
                      keyId={"key_card_" + index}
                      actions={{
                        DELETE_VARIANT,
                        SET_CURRENT_VARIANT_STEP,
                        ON_EDIT_STEP,
                        SET_CURRENT_STEP,
                        DELETE_STEP_FROM_CAMPAIGN,
                      }}
                      index={index}
                      indexId={index}
                      isLast={index === Object.keys(variants).length - 1}
                      totalSteps={Object.keys(variants).length}
                      editable={true}
                      selected={currentVariantStep === index}
                      dispatch={dispatch}
                      currentStep={currentVariantStep}
                      variantName={item}
                      variant={getVariantObject(item, variants)}
                    />
                  ))}
                </SortableContainer>
              )}
            </div>
          </Grid>
        </Grid>
      </div>

      <CustomMaterialUIDialog
        isFullWidth={false}
        dialogProps={{
          disableBackdropClick: true,
          disableEscapeKeyDown: true,
        }}
        dialogContent={
          <CreateEditVariantDialog
            handleDone={handleVariantDialogDone}
            experiment={true}
            variants={Object.keys(variants).filter((key) => key !== "CG")}
          />
        }
        dialogContentStyles={{
          overflowX: "hidden",
          padding: "8px 8px",
        }}
        maxWidth={"md"}
        openFlag={showVariantDialog}
        onClose={() => setShowVariantDialog(false)}
        onDialogClose={() => setShowVariantDialog(false)}
      />
    </>
  );
};

export default VariantBuilder;
