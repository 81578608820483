import React, { useState, useEffect, useRef } from "react";
import { withStyles, IconButton, Snackbar } from "@material-ui/core";
import {
  MESSAGE_CONFIGURATIONS,
  SET_CONFIG,
  SET_EVENTS,
  DEFAULT_STEP_ORDER,
  DESIGN,
  SET_APP_SCREENS,
  SET_ATTRIBUTES,
  SET_SEGMENTS,
  SET_COHORTS,
  SET_DEFAULT_TEST_DEVICES,
  SET_META_CONFIG_TYPE,
  TARGET,
  SCHEDULE,
  REVIEW,
  SET_SNACKBAR_ERROR_MESSAGE,
  CLIENT_EVENTS_FOR_APP,
  SET_CLIENT_EVENTS,
  SET_APP_SCREEN_NAMES,
} from "./constants";
import Context from "./components/context";
import { Provider, useTracked } from "./store";
import { useAuth } from "../../../../../contexts/AuthContext";
import {
  getAppActivitiesAPI,
  getAppScreenNamesAPI,
  getAttributes,
  getClientEventsAPI,
  getEventsAPI,
  getSegmentsAPI,
} from "../../../../common/actions";
import { useDateFilters } from "../../utils";
import Loading from "../../../../ReusableComponents/Loading";
import CloseIcon from "@material-ui/icons/Close";
import { useRouteMatch, useHistory } from "react-router-dom";
import { getCampaignAPI, getCohorts, getTestDevicesAPI } from "../../actions";
import GeneralStep from "./components/GeneralStep";
import GeneralSummaryComponent from "./components/GeneralSummaryComponent";
import { getRouteForActionType } from "./utils";
import ScheduleTab from "./components/settings";
import SummaryPage from "./components/summary";
import { APX_FEATURES } from "../../../../../constants";
import HeaderToolbar from "./components/HeaderToolbar";
import useMutateRouterState from "../../../../../utils/use-mutate-router-state";
import { isExperienceDashboard } from "../../../../../config";

const DEFAULT_COMPONENT = <div>Default</div>;
const DEFAULT_GENERAL_SUMMARY_COMPONENT = <GeneralSummaryComponent />;
const DEFAULT_GENERAL_COMPONENT = <GeneralStep />;

const CLIENT_EVENTS = {
  BACK_BUTTON_PRESSED: "apx_hard_back_button_pressed",
};

const hasCustomClientEvents = (features) => {
  return features.includes(APX_FEATURES.APX_CLIENT_EVENTS);
};

const styles = (theme) => ({
  content: {
    height: "auto",
  },
  root: {
    width: "100%",
    minHeight: 360,
    borderRadius: 10,
    border: "1px solid #e4eaec",
    marginBottom: 6,
    boxShadow:
      "rgba(60, 64, 67, 0.1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.10) 0px 1px 1px 0px",
  },
  cardContent: {
    padding: "16px 32px",
  },
  cardActions: {
    padding: 16,
    paddingTop: 0,
  },
  menuPaper: {
    border: "1px solid #e4eaec",
  },
  cardHeaderActionsSection: {
    minWidth: 120,
  },
});

const getDefaultRequiredData = (auth, dateFilters, dispatch) => {
  getEventsAPI(auth, auth.appId, dateFilters).then((response) => {
    dispatch({
      type: SET_EVENTS,
      events: response,
    });
  });
  getAppScreenNamesAPI(auth, auth.appId).then((response) => {
    dispatch({
      type: SET_APP_SCREEN_NAMES,
      screens: response,
    });
  });
  getAppActivitiesAPI(auth, auth.appId).then((response) => {
    dispatch({
      type: SET_APP_SCREENS,
      screens: response,
    });
  });
  getTestDevicesAPI(auth, auth.appId, null, isExperienceDashboard()).then(
    (response) => {
      const reversed = response.reverse();
      dispatch({
        type: SET_DEFAULT_TEST_DEVICES,
        devices: reversed,
      });
    },
  );
  getAttributes(auth, auth.appId).then((response) => {
    dispatch({
      type: SET_ATTRIBUTES,
      user: response.user.filter((name) => name.indexOf("apx_") === -1),
      session: response.session.filter((name) => name.indexOf("apx_") === -1),
    });
  });
  getCohorts(auth, auth.appId).then((response) => {
    dispatch({
      type: SET_COHORTS,
      cohorts: response,
    });
  });
  getSegmentsAPI(auth, auth.appId, dateFilters).then((response) => {
    dispatch({
      type: SET_SEGMENTS,
      segments: response,
    });
  });
};

function CampaignCreatorWithProvider({
  app,
  actionType,
  classes,
  // config = null,
  configType,
  stepOrder = DEFAULT_STEP_ORDER,
  designOrConfigureComponent = DEFAULT_COMPONENT,
  designOrConfigureSummaryComponent = DEFAULT_COMPONENT,
  generalComponent = DEFAULT_GENERAL_COMPONENT,
  generalSummaryComponent = DEFAULT_GENERAL_SUMMARY_COMPONENT,
  stepperConfig = MESSAGE_CONFIGURATIONS,
}) {
  const auth = useAuth();
  const history = useHistory();
  const { url, params } = useRouteMatch();
  const [dateFilters] = useDateFilters(true);

  const [state, dispatch] = useTracked();
  const { cohorts = [], snackbarMessage = null } = state;

  const stateRef = useRef();
  stateRef.current = state.config;

  const initial_active_step =
    history?.location?.state?.initial_active_step !== undefined
      ? history?.location?.state?.initial_active_step
      : url?.includes("/edit") && !history?.location?.state?.isDuplicated
      ? 3
      : 0;
  const initialShowDialog = !url?.includes("/edit")
    ? true
    : history?.location?.state?.isDuplicated;

  const { clearStateFields } = useMutateRouterState();

  const [activeStep, setActiveStep] = useState(initial_active_step);
  const [pending, setPending] = useState(false);
  const [updateConfigOuter, setUpdateConfigOuter] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(initialShowDialog);

  const handleSelect = (index) => {
    if (index === -1) {
      setShowEditDialog(true);
      return;
    }
    setShowEditDialog(false);
    setActiveStep(index);
  };

  useEffect(() => {
    getDefaultRequiredData(auth, dateFilters, dispatch);

    if (url.includes("/edit")) {
      const campaignId = params.campaignId;
      const appId = params.appId;
      setPending(true);
      getCampaignAPI(auth, appId, campaignId, { configType })
        .then((response) => {
          if (
            (response.published &&
              response.old_publish_ids &&
              response.old_publish_ids?.length >= 1) ||
            response?.meta?.config_type === "onboarding"
          ) {
            history.push(
              `/apps/${auth.appId}/${getRouteForActionType(actionType)}`,
            );
          }

          // Forcefully adding the `index` key in every UI for backward compatibility
          const updatedConfig = {
            ...response,
            uis: response.uis.map((ui, index) => ({
              ...ui,
              index: index,
            })),
          };

          stateRef.current = response.meta.isExperiment
            ? response
            : updatedConfig;
          setUpdateConfigOuter(true);
          dispatch({
            type: SET_CONFIG,
            config: response.meta.isExperiment ? response : updatedConfig,
          });
          setPending(false);
        })
        .catch(() => setPending(false));
    } else {
      dispatch({
        type: SET_META_CONFIG_TYPE,
        config_type: actionType,
      });
    }

    clearStateFields(["initial_active_step", "step_id"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getClientEventsAPI(
      auth,
      auth.appId,
      stateRef.current.meta?.platform || "android",
    ).then((response) => {
      let clientEvents = response;
      if (clientEvents.length === 0) {
        if (Object.keys(CLIENT_EVENTS_FOR_APP).includes(auth.appId)) {
          clientEvents = CLIENT_EVENTS_FOR_APP[auth.appId];
        }
      }
      if (!hasCustomClientEvents(app.features || [])) {
        clientEvents = Object.values(CLIENT_EVENTS);
      } else {
        clientEvents = [...Object.values(CLIENT_EVENTS), ...response];
      }
      dispatch({
        type: SET_CLIENT_EVENTS,
        values: clientEvents,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRef.current.meta?.platform]);

  return (
    <>
      {!pending && (
        <section className={classes.content}>
          <HeaderToolbar
            app={app}
            activeStep={activeStep}
            actionType={actionType}
            configType={configType}
            platform={app.basic_info.platform}
            setShowEditDialog={setShowEditDialog}
            showEditDialog={showEditDialog}
            stateRef={stateRef}
            stepperConfig={stepperConfig}
            setActiveStep={setActiveStep}
            updateConfigOuter={updateConfigOuter}
          />
          {activeStep === stepOrder[DESIGN] &&
            React.cloneElement(designOrConfigureComponent, {
              app: app,
              config: stateRef.current,
              actionType: actionType,
            })}
          {activeStep === stepOrder[TARGET] && (
            <Context app={app} platform={stateRef.current.meta?.platform} />
          )}
          {activeStep === stepOrder[SCHEDULE] && <ScheduleTab />}
          {activeStep === stepOrder[REVIEW] && (
            <SummaryPage
              designSummaryPage={designOrConfigureSummaryComponent}
              config={stateRef.current}
              cohorts={cohorts}
              handleSelect={handleSelect}
              appId={auth?.appId}
              actionType={actionType}
            />
          )}
          {snackbarMessage && (
            <Snackbar
              open={snackbarMessage !== null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              message={snackbarMessage}
              action={
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => {
                    dispatch({
                      type: SET_SNACKBAR_ERROR_MESSAGE,
                      value: null,
                    });
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              }
            />
          )}
        </section>
      )}
      {pending && (
        <section className={classes.content}>
          <Loading size={18} />
        </section>
      )}
    </>
  );
}

function CampaignCreator({
  app,
  actionType,
  classes,
  configType,
  stepOrder = DEFAULT_STEP_ORDER,
  designOrConfigureComponent = DEFAULT_COMPONENT,
  designOrConfigureSummaryComponent = DEFAULT_COMPONENT,
  generalComponent = DEFAULT_GENERAL_COMPONENT,
  generalSummaryComponent = DEFAULT_GENERAL_SUMMARY_COMPONENT,
  stepperConfig = MESSAGE_CONFIGURATIONS,
}) {
  return (
    <Provider>
      <CampaignCreatorWithProvider
        app={app}
        actionType={actionType}
        classes={classes}
        configType={configType}
        stepOrder={stepOrder}
        designOrConfigureComponent={designOrConfigureComponent}
        designOrConfigureSummaryComponent={designOrConfigureSummaryComponent}
        generalComponent={generalComponent}
        generalSummaryComponent={generalSummaryComponent}
        stepperConfig={stepperConfig}
      />
    </Provider>
  );
}

export default withStyles(styles)(CampaignCreator);
