import React, { useState } from "react";
import { finalChoices, singleChoice } from "../constants";

export default function SingleChoiceSimulator({
  choices,
  handleAnswer,
  is_response_based_survey,
  randomize_choices,
  ui,
  selectedId,
}) {
  const [selected, setSelected] = useState(selectedId);

  const {
    option_text_color_active,
    option_text_color_inactive,
    option_bg_color_active,
    option_bg_color_inactive,
  } = ui;

  return (
    <div>
      {finalChoices(choices, randomize_choices).map((choice) => (
        <div
          key={choice.id}
          onClick={(e) => {
            if (selected === choice.id) {
              setSelected(null);
              handleAnswer(false);
            } else {
              setSelected(choice.id);
              handleAnswer(
                true,
                is_response_based_survey ? choice.next_question_id : null
              );
            }
          }}
          style={{
            margin: "8px 0",
          }}
        >
          {singleChoice(
            choice,
            option_text_color_active,
            option_text_color_inactive,
            option_bg_color_active,
            option_bg_color_inactive,
            selected === choice.id
          )}
        </div>
      ))}
    </div>
  );
}
