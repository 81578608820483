import {
  IconButton,
  Tooltip,
  Typography,
  withStyles,
  Card,
} from "@material-ui/core";
import React, { useState } from "react";
import Loading from "../../../../../../../../ReusableComponents/Loading";
import delete_icon from "../../../assets/delete_icon.png";
import { sendEvent } from "../../../../../../../common/ApxEventHandler";
import { useTracked } from "../../../../../../../common/components/campaigncreator/store";

const styles = (theme) => ({
  buttonBaseStyle: {
    cursor: "pointer",
    display: "inline-flex",
    outline: "none",
    padding: 0,
    position: "relative",
    alignItems: "center",
    userSelect: "none",
    verticalAlign: "middle",
    justifyContent: "center",
    textDecoration: "none",
    backgroundColor: "transparent",
    textAlign: "initial",
    borderRadius: 0,
  },
  iconButtonRoot: {
    padding: 1,
    color: theme.palette.primary.main,
  },
  root: {
    zIndex: 9999,
  },
  contentAlign: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  classicCard: {
    top: 3,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    height: 50,
    width: "150px",
    borderRadius: 0,
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  dots: {
    height: "19.5px",
    width: "auto",
    paddingLeft: "10px",
    top: "30px",
    alignContent: "center",
  },
  cardName: {
    fontFamily: "Manrope",
    fontSize: 14,
    fontWeight: 600,
    whiteSpace: "nowrap",
    overflow: "hidden",
    lineHeight: "19px",
    marginLeft: 12,
  },
  deleteIcon: {
    position: "absolute",
    right: 4,
    top: 4,
  },
  deleteThumbnail: {
    height: "15px",
    width: "auto",
  },
  clipCopy: {
    bottom: 4,
    right: 5,
    position: "absolute",
    padding: 0,
  },
  copyThumbnail: {
    color: "#8497A5",
    height: "16px",
    width: "auto",
  },
});

const VariantCard = ({
  classes,
  dispatch,
  editable = false,
  index,
  actions,
  currentStep,
  variantName,
  variant,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [onHover, setOnHover] = useState(false);
  const variantCode = String.fromCharCode(index + 65);
  const [parentState, parentDispatch] = useTracked();
  const {
    config: { experiment: { variants } = {} },
  } = parentState;

  const {
    DELETE_VARIANT,
    SET_CURRENT_VARIANT_STEP,
    SET_CURRENT_STEP,
    ON_EDIT_STEP,
    DELETE_STEP_FROM_CAMPAIGN,
  } = actions;

  return (
    <div id="ClassicCard" className={classes.root}>
      <div className={classes.contentAlign}>
        <div
          className={classes.buttonBaseStyle}
          onMouseEnter={() => setOnHover(true)}
          onMouseLeave={() => setOnHover(false)}
        >
          <Card
            style={{
              marginRight: 10,
              borderBottom: index === currentStep ? "4px solid black" : 0,
            }}
            raised={false}
            className={classes.classicCard}
            onClick={() => {
              parentDispatch({
                type: SET_CURRENT_VARIANT_STEP,
                value: index,
              });
              dispatch({
                type: SET_CURRENT_STEP,
                value: variant.indices.length > 0 ? variant.indices[0] : -1,
              });
              sendEvent(ON_EDIT_STEP);
            }}
          >
            <div
              style={{
                fontSize: "18px",
                fontWeight: "700",
                fontStyle: "normal",
                fontFamily: "Manrope",
                background: index === currentStep ? "#00BDE1" : "#E5E5E5",
                padding: "8px 12px",
                border: index === currentStep ? "none" : "1px solid #DCE0E3",
                color: index === currentStep ? "#FFFFFF" : "#8497A5",
                borderRadius: 2,
              }}
            >
              {variantCode.toUpperCase()}
            </div>
            <Typography
              color="textPrimary"
              variant="h6"
              className={classes.cardName}
            >
              {variantName}
            </Typography>
          </Card>
          {editable && onHover && (
            <Tooltip title="Delete Step" className={classes.deleteIcon}>
              <IconButton
                classes={{
                  root: classes.iconButtonRoot,
                }}
                onClick={() => {
                  setIsDeleting(true);

                  const keyToDelete = Object.keys(variants).filter(
                    (key) => key !== "CG"
                  )[index];
                  const uiIndicesToDelete = [...variants[keyToDelete].indices];
                  if (uiIndicesToDelete.length > 0) {
                    uiIndicesToDelete.forEach((eachIndex) => {
                      dispatch({
                        type: DELETE_STEP_FROM_CAMPAIGN,
                        index: eachIndex,
                        isExperiment: true,
                      });
                    });
                  }

                  parentDispatch({
                    type: DELETE_VARIANT,
                    value: index,
                    uiIndicesToDelete: uiIndicesToDelete,
                    key: keyToDelete,
                  });

                  let stepToSet = -1;
                  if (index - 1 >= 0) {
                    stepToSet =
                      variants[String.fromCharCode(index - 1 + 65)].indices
                        .length > 0
                        ? variants[String.fromCharCode(index - 1 + 65)]
                            .indices[0]
                        : -1;
                  }
                  dispatch({
                    type: SET_CURRENT_STEP,
                    value: stepToSet,
                  });
                  setIsDeleting(false);
                }}
              >
                {isDeleting ? (
                  <Loading size={20} />
                ) : (
                  <img
                    src={delete_icon}
                    alt="delete"
                    className={classes.deleteThumbnail}
                  />
                )}
              </IconButton>
            </Tooltip>
          )}
          {/* {editable && onHover && (
            <Tooltip title="ClipCopy" className={classes.clipCopy}>
              <IconButton
                onClick={() => {
                  // parentDispatch({
                  //   type: ON_COPY_STEP,
                  //   currentStep: index,
                  // });
                }}
              >
                <FileCopyOutlinedIcon
                  fontSize="small"
                  color="action"
                  className={classes.copyThumbnail}
                />
              </IconButton>
            </Tooltip>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(VariantCard);
