import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  withStyles,
} from "@material-ui/core";
import { ChevronLeft, CloseOutlined } from "@material-ui/icons";
import React, { useEffect, useMemo, useState } from "react";
import { useAuth } from "../../../../../contexts/AuthContext";
import {
  ADD_TEST_DEVICE,
  REMOVE_TEST_DEVICE,
  SET_TEST_DEVICES,
} from "../constants";
import { useTracked } from "../store";
import { getTestDevicesAPI } from "../../../common/actions";
import ColumnDiv from "../../../../ReusableComponents/ColumnDiv";
import RowDiv from "../../../../ReusableComponents/RowDiv";
import DownloadApxorAppContent from "../../../common/components/DownloadApxorAppContent";

const styles = (theme) => ({
  deviceListItem: {
    marginTop: 4,
    marginBottom: 4,
    borderRadius: 4,
    padding: 0,
    background: "transparent",
  },
  gutters: {
    padding: "0px !important",
  },
  backButton: {
    padding: 0,
    maxHeight: 30,
    marginLeft: -10,
    color: "#002845",
    "&:hover": {
      background: "none",
    },
  },
  deviceListItem_checkBox: {
    padding: 4,
  },
  deviceListItem_text: {
    padding: "2px 12px",
    whiteSpace: "nowrap",
  },
  deviceListItem_selected: {
    backgroundColor: theme.palette.primary.almostInvisible,
    borderLeft: `2px solid ${theme.palette.primary.main}`,
  },
  listItemPrimary: {
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
});

const getDeviceName = (deviceId, testDevices) => {
  const device = testDevices.find((item) => item.id === deviceId);
  if (device) {
    return `${
      device.hasOwnProperty("nick_name") && device.nick_name !== ""
        ? device.nick_name
        : device.model
    } - ${device.id}`;
  }
  return "";
};

let intervalHandler;

function PushTestingDialogContent({ classes, handleDone }) {
  const auth = useAuth();
  const [state, dispatch] = useTracked();
  const {
    push_config: { test_devices: devices },
    test_devices,
  } = state;

  const [step, setStep] = useState(0);
  const [fetching, setFetching] = useState(false);

  const testDevices = useMemo(() => test_devices, [test_devices]);

  const fetchTestDevices = () => {
    setFetching(true);
    getTestDevicesAPI(auth, auth.appId)
      .then((response) => {
        const reversed = response.reverse();
        dispatch({
          type: SET_TEST_DEVICES,
          value: reversed,
        });
        setFetching(false);
      })
      .catch(() => {
        setFetching(false);
      });
  };

  useEffect(() => {
    if (step === 1) {
      intervalHandler = setInterval(() => {
        fetchTestDevices();
      }, 5000);
    } else {
      clearInterval(intervalHandler);
      intervalHandler = null;
    }
    return () => {
      if (intervalHandler) {
        clearInterval(intervalHandler);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  return (
    <ColumnDiv styles={{ margin: 30, marginTop: 0, marginBottom: 20 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid
            container
            spacing={0}
            style={{
              border: "1px solid #CCD4DA",
              borderRadius: 3,
            }}
          >
            <Grid
              item
              xs={7}
              style={{
                padding: 20,
              }}
            >
              {step === 0 && (
                <ColumnDiv>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 900, marginBottom: 15 }}
                  >
                    SELECTED TEST DEVICES
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 500, fontSize: 15, marginBottom: 4 }}
                  >
                    {devices.length > 0 ? "Selected Devices" : ""}
                  </Typography>
                  <ColumnDiv
                    styles={{ gap: 5, maxHeight: 340, overflow: "scroll" }}
                  >
                    {devices.map((device, index) => {
                      const deviceName = getDeviceName(device, testDevices);
                      if (deviceName === "") {
                        return <></>;
                      }
                      return (
                        <RowDiv
                          key={index}
                          center
                          styles={{
                            justifyContent: "space-between",
                            background: "#EDF0F2",
                            border: "1px solid #CCD4DA",
                            borderRadius: 2,
                          }}
                        >
                          <Typography
                            variant="body1"
                            style={{
                              marginLeft: 10,
                              fontWeight: 600,
                              fontSize: 15,
                            }}
                          >
                            {getDeviceName(device, testDevices)}
                          </Typography>
                          <IconButton
                            style={{ padding: 4 }}
                            onClick={() =>
                              dispatch({
                                type: REMOVE_TEST_DEVICE,
                                value: device,
                              })
                            }
                          >
                            <CloseOutlined style={{ color: "#6B8190" }} />
                          </IconButton>
                        </RowDiv>
                      );
                    })}
                  </ColumnDiv>
                </ColumnDiv>
              )}
              {step === 1 && (
                <>
                  <IconButton
                    className={classes.backButton}
                    onClick={() => setStep(0)}
                  >
                    <ChevronLeft /> Back
                  </IconButton>
                  <DownloadApxorAppContent />
                </>
              )}
            </Grid>
            <Grid item xs={5}>
              <ColumnDiv styles={{ borderLeft: "1px solid #CCD4DA" }}>
                <RowDiv
                  center
                  styles={{
                    justifyContent: "space-between",
                    borderBottom: "1px solid #CCD4DA",
                    padding: "0px 10px",
                  }}
                >
                  <Typography variant="body1" style={{ fontWeight: 800 }}>
                    ALL DEVICES{" "}
                    {fetching ? (
                      <span style={{ color: "#8494A0" }}>refreshing...</span>
                    ) : (
                      ""
                    )}
                  </Typography>
                  <Button
                    color="primary"
                    variant="text"
                    onClick={() => setStep(1)}
                    disabled={step === 1}
                    style={{ fontSize: 12, textTransform: "none" }}
                  >
                    + Add device
                  </Button>
                </RowDiv>
                <List
                  style={{
                    maxHeight: 414,
                    overflowY: "scroll",
                    overflowX: "hidden",
                    padding: 0,
                    paddingRight: 8,
                  }}
                >
                  {testDevices.map((testDevice, index) => {
                    const isSelected = devices.includes(testDevice.id);
                    return (
                      <ListItem
                        key={index + "-" + testDevice.id}
                        role={undefined}
                        dense
                        button
                        className={
                          isSelected
                            ? classes.deviceListItem_selected
                            : classes.deviceListItem
                        }
                        classes={{
                          gutters: classes.gutters,
                        }}
                        onClick={() => {
                          if (!isSelected) {
                            dispatch({
                              type: ADD_TEST_DEVICE,
                              value: testDevice.id,
                            });
                          } else {
                            dispatch({
                              type: REMOVE_TEST_DEVICE,
                              value: testDevice.id,
                            });
                          }
                        }}
                      >
                        <Checkbox
                          color={"primary"}
                          className={classes.deviceListItem_checkBox}
                          checked={isSelected}
                          tabIndex={-1}
                          disableRipple
                        />
                        <ListItemText
                          className={classes.deviceListItem_text}
                          classes={{ primary: classes.listItemPrimary }}
                          primary={`${
                            testDevice.nick_name && testDevice.nick_name !== ""
                              ? testDevice.nick_name + " - " + testDevice.id
                              : testDevice.model + " - " + testDevice.id
                          }`}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </ColumnDiv>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <RowDiv center styles={{ justifyContent: "center", marginTop: 16 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setTimeout(() => {
              if (handleDone) {
                handleDone();
              }
            }, 250);
          }}
        >
          Send Push
        </Button>
      </RowDiv>
    </ColumnDiv>
  );
}

export default withStyles(styles)(PushTestingDialogContent);
