import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const imageUploadStyles = (theme) => ({
  inputRoot: {
    color: "transparent",
    "&::before": {
      content: "'Upload Image'",
      color: theme.palette.text.primary,
      display: "inline-block",
      borderRadius: 3,
      padding: "10px 8px",
      outline: "none",
      whiteSpace: "nowrap",
      "-webkit-user-select": "none",
      cursor: "pointer",
      fontWeight: 700,
      fontSize: 12,
    },
    "&::-webkit-file-upload-button": {
      visibility: "hidden",
    },
  },
  icon: {
    background: theme.palette.secondary.main,
  },
});

function ImageUploaderWithStyles({ classes, handleImageChange }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 120,
        padding: "0px 0px 0px 6px",
      }}
      className={classes.icon}
    >
      <CloudUploadIcon color="action" />
      <input
        accept="image/jpeg, image/png"
        type={"file"}
        multiple={false}
        onChange={(e) => {
          handleImageChange(e.target.files);
        }}
        className={classes.inputRoot}
      />
    </div>
  );
}

const ImageUploader = withStyles(imageUploadStyles)(ImageUploaderWithStyles);

export default function Base64ImageUploadToolbar({ onImageUpload }) {
  return (
    <Grid
      container
      spacing={1}
      style={{
        background: "#fff",
        boxShadow: "0px 4px 15px 2px rgba(0, 40, 69, 0.14)",
        borderRadius: 3,
        alignItems: "center",
      }}
    >
      <Grid item>
        <ImageUploader
          handleImageChange={(imagesList) => {
            if (imagesList.length > 0) {
              let reader = new FileReader();
              reader.onload = (readerEvent) => {
                let imageObj = new Image();
                imageObj.onload = (imageEvent) => {
                  let canvas = document.createElement("canvas"),
                    max_size = 128,
                    width = imageObj.width,
                    height = imageObj.height;
                  if (width > height) {
                    if (width > max_size) {
                      height *= max_size / width;
                      width = max_size;
                    }
                  } else {
                    if (height > max_size) {
                      width *= max_size / height;
                      height = max_size;
                    }
                  }
                  canvas.width = width;
                  canvas.height = height;
                  canvas
                    .getContext("2d")
                    .drawImage(imageObj, 0, 0, width, height);
                  let dataUrl = canvas.toDataURL("image/png");
                  dataUrl = dataUrl.slice(22);
                  onImageUpload(dataUrl);
                };
                imageObj.src = readerEvent.target.result;
              };

              reader.readAsDataURL(imagesList[0]);

              reader.onerror = () => {
                console.error("error");
              };
            }
          }}
        />
      </Grid>
    </Grid>
  );
}
