//FIXME: Refactor this file and rename variables to immutable vars, fix compare operations
/* eslint-disable no-useless-escape */
window.ace.define(
  "ace/mode/apxor_highlight_rules",
  [
    "require",
    "exports",
    "module",
    "ace/lib/oop",
    "ace/mode/text_highlight_rules",
  ],
  function (acequire, exports, module) {
    var oop = acequire("../lib/oop");
    var TextHighlightRules = acequire("./text_highlight_rules")
      .TextHighlightRules;

    var apxorHighlightRules = function () {
      this.$rules = {
        start: [
          {
            token: "string",
            regex: '"',
            next: "string2",
          },
          {
            token: "constant.numeric", // float
            regex: "(?:0|[1-9][0-9]*)\\b",
          },
          {
            token: "constant.language.boolean",
            regex: "(?:true|false)\\b",
          },
          {
            token: "keyword",
            regex:
              "(?:format|toInt|if|else|contains|httpGet|replace|onSuccess|onError|true|false)\\b",
          },
          {
            token: "variable",
            regex: "[a-zA-Z\\$_\u00a1-\uffff][a-zA-Z\\d\\$_\u00a1-\uffff]*",
          },
          {
            token: "keyword.operator",
            regex: "\\|\\||&&|!=|==|<=|<|>=|>|\\+|-|\\*|\\/|\\%|\\!",
          },
          {
            token: "paren.lparen",
            regex: /[\[({]/,
            next: "start",
          },
          {
            token: "paren.rparen",
            regex: /[\])}]/,
          },
          {
            token: "text",
            regex: "\\s+",
          },
        ],
        string2: [
          {
            token: "string",
            regex: '[^"\\\\]+',
          },
          {
            token: "string",
            regex: '"',
            next: "start",
          },
          {
            token: "string",
            regex: "",
            next: "start",
          },
        ],
      };
    };

    oop.inherits(apxorHighlightRules, TextHighlightRules);

    exports.apxorHighlightRules = apxorHighlightRules;
  }
);

window.ace.define(
  "ace/mode/matching_brace_outdent",
  ["require", "exports", "module", "ace/range"],
  function (acequire, exports, module) {
    var Range = acequire("../range").Range;

    var MatchingBraceOutdent = function () {};

    (function () {
      this.checkOutdent = function (line, input) {
        if (!/^\s+$/.test(line)) return false;

        return /^\s*\}/.test(input);
      };

      this.autoOutdent = function (doc, row) {
        var line = doc.getLine(row);
        var match = line.match(/^(\s*\})/);

        if (!match) return 0;

        var column = match[1].length;
        var openBracePos = doc.findMatchingBracket({
          row: row,
          column: column,
        });

        if (!openBracePos || openBracePos.row == row) return 0;

        var indent = this.$getIndent(doc.getLine(openBracePos.row));
        doc.replace(new Range(row, 0, row, column - 1), indent);
      };

      this.$getIndent = function (line) {
        return line.match(/^\s*/)[0];
      };
    }.call(MatchingBraceOutdent.prototype));

    exports.MatchingBraceOutdent = MatchingBraceOutdent;
  }
);

window.ace.define(
  "ace/mode/folding/cstyle",
  [
    "require",
    "exports",
    "module",
    "ace/lib/oop",
    "ace/range",
    "ace/mode/folding/fold_mode",
  ],
  function (acequire, exports, module) {
    var oop = acequire("../../lib/oop");
    var Range = acequire("../../range").Range;
    var BaseFoldMode = acequire("./fold_mode").FoldMode;

    var FoldMode = (exports.FoldMode = function (commentRegex) {
      if (commentRegex) {
        this.foldingStartMarker = new RegExp(
          this.foldingStartMarker.source.replace(
            /\|[^|]*?$/,
            "|" + commentRegex.start
          )
        );
        this.foldingStopMarker = new RegExp(
          this.foldingStopMarker.source.replace(
            /\|[^|]*?$/,
            "|" + commentRegex.end
          )
        );
      }
    });
    oop.inherits(FoldMode, BaseFoldMode);

    (function () {
      this.foldingStartMarker = /([\{\[\(])[^\}\]\)]*$|^\s*(\/\*)/;
      this.foldingStopMarker = /^[^\[\{\(]*([\}\]\)])|^[\s\*]*(\*\/)/;
      this.singleLineBlockCommentRe = /^\s*(\/\*).*\*\/\s*$/;
      this.tripleStarBlockCommentRe = /^\s*(\/\*\*\*).*\*\/\s*$/;
      this.startRegionRe = /^\s*(\/\*|\/\/)#?region\b/;
      this._getFoldWidgetBase = this.getFoldWidget;
      this.getFoldWidget = function (session, foldStyle, row) {
        var line = session.getLine(row);

        if (this.singleLineBlockCommentRe.test(line)) {
          if (
            !this.startRegionRe.test(line) &&
            !this.tripleStarBlockCommentRe.test(line)
          )
            return "";
        }

        var fw = this._getFoldWidgetBase(session, foldStyle, row);

        if (!fw && this.startRegionRe.test(line)) return "start"; // lineCommentRegionStart

        return fw;
      };

      this.getFoldWidgetRange = function (
        session,
        foldStyle,
        row,
        forceMultiline
      ) {
        var line = session.getLine(row);

        if (this.startRegionRe.test(line))
          return this.getCommentRegionBlock(session, line, row);

        var match = line.match(this.foldingStartMarker);
        if (match) {
          var i = match.index;

          if (match[1])
            return this.openingBracketBlock(session, match[1], row, i);

          var range = session.getCommentFoldRange(row, i + match[0].length, 1);

          if (range && !range.isMultiLine()) {
            if (forceMultiline) {
              range = this.getSectionRange(session, row);
            } else if (foldStyle != "all") range = null;
          }

          return range;
        }

        if (foldStyle === "markbegin") return;

        var match = line.match(this.foldingStopMarker);
        if (match) {
          var i = match.index + match[0].length;

          if (match[1])
            return this.closingBracketBlock(session, match[1], row, i);

          return session.getCommentFoldRange(row, i, -1);
        }
      };

      this.getSectionRange = function (session, row) {
        var line = session.getLine(row);
        var startIndent = line.search(/\S/);
        var startRow = row;
        var startColumn = line.length;
        row = row + 1;
        var endRow = row;
        var maxRow = session.getLength();
        while (++row < maxRow) {
          line = session.getLine(row);
          var indent = line.search(/\S/);
          if (indent === -1) continue;
          if (startIndent > indent) break;
          var subRange = this.getFoldWidgetRange(session, "all", row);

          if (subRange) {
            if (subRange.start.row <= startRow) {
              break;
            } else if (subRange.isMultiLine()) {
              row = subRange.end.row;
            } else if (startIndent == indent) {
              break;
            }
          }
          endRow = row;
        }

        return new Range(
          startRow,
          startColumn,
          endRow,
          session.getLine(endRow).length
        );
      };
      this.getCommentRegionBlock = function (session, line, row) {
        var startColumn = line.search(/\s*$/);
        var maxRow = session.getLength();
        var startRow = row;

        var re = /^\s*(?:\/\*|\/\/|--)#?(end)?region\b/;
        var depth = 1;
        while (++row < maxRow) {
          line = session.getLine(row);
          var m = re.exec(line);
          if (!m) continue;
          if (m[1]) depth--;
          else depth++;

          if (!depth) break;
        }

        var endRow = row;
        if (endRow > startRow) {
          return new Range(startRow, startColumn, endRow, line.length);
        }
      };
    }.call(FoldMode.prototype));
  }
);

window.ace.define(
  "ace/mode/apxor",
  [
    "require",
    "exports",
    "module",
    "ace/lib/oop",
    "ace/mode/text",
    "ace/mode/apxor_highlight_rules",
    "ace/mode/matching_brace_outdent",
    "ace/mode/behaviour/cstyle",
    "ace/mode/folding/cstyle",
  ],
  function (acequire, exports, module) {
    var oop = acequire("../lib/oop");
    var TextMode = acequire("./text").Mode;
    var HighlightRules = acequire("./apxor_highlight_rules")
      .apxorHighlightRules;
    var MatchingBraceOutdent = acequire("./matching_brace_outdent")
      .MatchingBraceOutdent;
    var CstyleBehaviour = acequire("./behaviour/cstyle").CstyleBehaviour;
    var CStyleFoldMode = acequire("./folding/cstyle").FoldMode;

    var Mode = function () {
      this.HighlightRules = HighlightRules;
      this.$outdent = new MatchingBraceOutdent();
      this.$behaviour = new CstyleBehaviour();
      this.foldingRules = new CStyleFoldMode();
    };
    oop.inherits(Mode, TextMode);

    (function () {
      this.lineCommentStart = "--";

      this.getNextLineIndent = function (state, line, tab) {
        var indent = this.$getIndent(line);

        if (state == "start") {
          var match = line.match(/^.*[\{\(\[]\s*$/);
          if (match) {
            indent += tab;
          }
        }

        return indent;
      };

      this.checkOutdent = function (state, line, input) {
        return this.$outdent.checkOutdent(line, input);
      };

      this.autoOutdent = function (state, doc, row) {
        this.$outdent.autoOutdent(doc, row);
      };

      this.$id = "ace/mode/apxor";
    }.call(Mode.prototype));

    exports.Mode = Mode;
  }
);

var apxorCompleter = {
  getCompletions: function (editor, session, pos, prefix, callback) {
    var completions = [];
    var variables = window.apx_variables || [];
    [
      "format",
      "toInt",
      "if",
      "else",
      "contains",
      "httpGet",
      "onSuccess",
      "onError",
      "true",
      "false",
      "replace",
    ].forEach(function (w) {
      completions.push({
        value: w,
        meta: "Apxor DSL Keyword",
      });
    });
    variables.forEach(function (w) {
      completions.push({
        value: w,
        meta: "Variable",
      });
    });
    callback(null, completions);
  },
};

const langTools = window.ace.acequire("ace/ext/language_tools");
langTools.addCompleter(apxorCompleter);
