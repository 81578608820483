/**
 * Created by Rakesh Peela
 * Date: 07-Mar-2020
 * Time: 1:46 AM
 */
import { Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

export function StyledDropzone(props) {
  const { acceptType, onChange, text } = props;
  const {
    acceptedFiles = [],
    fileRejections = [],
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: acceptType || "text/csv",
    multiple: false,
    noDrag: true,
    maxSize: 52428800,
  });

  acceptedFiles.map(file => {
    onChange(file);
    return file;
  });

  const rejections = fileRejections.map(({ file, errors }) => (
    <Alert severity="error" style={{ marginTop: 6 }}>
      {errors
        .map(e => {
          if (e.message.indexOf("larger than") !== -1) {
            return "File is larger than 50 MB";
          }
          return e.message;
        })
        .join(", ")}
    </Alert>
  ));

  return (
    <div className="container">
      <Container {...getRootProps()}>
        <input {...getInputProps()} />
        <Typography variant="body1">{text}</Typography>
      </Container>
      {rejections}
    </div>
  );
}
