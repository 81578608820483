import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { RATING_ACTION_LABELS } from "../../../constants";
import { GenerateEmojiRows } from "../../../utils";
import { useTrackedState } from "../../../store";
import TextSimulator from "./TextSimulator";
import RowDiv from "../../../../../../../../ReusableComponents/RowDiv";

export default function RatingSimulator({
  currentQuestionIndex,
  handleAnswer,
  question_text_color,
}) {
  const state = useTrackedState();
  const {
    ui: { questions = [] },
  } = state;
  const {
    rating_style = "",
    rating_scale = 5,
    advanced_options,
    rules,
  } = questions[currentQuestionIndex];
  const [selectedIcon, setSelectedIcon] = useState(-1);
  const [currentRule, setCurrentRule] = useState(null);
  const [currentRuleConfig, setCurrentRuleConfig] = useState({});
  const [isTextRedirection, setIsTextRedirection] = useState(false);
  const [currentTextConfig, setCurrentTextConfig] = useState({});

  useEffect(() => {
    if (advanced_options) {
      const selectedValue =
        rating_scale === 10 ? selectedIcon + 1 : selectedIcon;
      rules.every(rule => {
        if (
          rule.min &&
          rule.max &&
          selectedValue >= rule.min &&
          selectedValue <= rule.max
        ) {
          setCurrentRule(rule);
          return false;
        } else {
          setCurrentRule(null);
          return true;
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advanced_options, selectedIcon, rules]);

  useEffect(() => {
    const qid = currentRule && currentRule.qid;
    const currentConfig = questions[currentQuestionIndex][qid];
    if (currentConfig && currentConfig.type === RATING_ACTION_LABELS.QUESTION) {
      setIsTextRedirection(true);
      setCurrentRuleConfig(currentConfig);
      setCurrentTextConfig(currentConfig.text_config);
    } else {
      setIsTextRedirection(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRule]);

  return (
    <Grid container justify="center">
      {GenerateEmojiRows(
        rating_style,
        rating_scale,
        false,
        selectedIcon,
        setSelectedIcon
      )}
      {selectedIcon > -1 && advanced_options && isTextRedirection && (
        <RowDiv>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                style={{
                  fontSize: 18,
                  fontWeight: 600,
                  color: question_text_color,
                  wordWrap: "break-word",
                  marginTop: 10,
                }}
              >
                {currentRuleConfig.question || ""}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextSimulator
                text_config={currentTextConfig}
                handleAnswer={handleAnswer}
              />
            </Grid>
          </Grid>
        </RowDiv>
      )}
    </Grid>
  );
}
