import React from "react";
import ActionsTextField from "../../../../ReusableComponents/ActionsTextField";
import { useTracked } from "../store";
import { SET_LARGE_ICON_PATH, SET_LARGE_ICON_URL } from "../constants";
import { Grid, Typography } from "@material-ui/core";

export function IconConfig() {
  const [state, dispatch] = useTracked();
  const { push_config } = state;
  const { payload } = push_config;
  const { icon_config } = payload;
  const { large_icon_path, large_icon_url, icon_config_type } = icon_config;
  return (
    <Grid container>
      <Grid item xs>
        {icon_config_type !== "None" && <br />}
        {icon_config_type === "large_icon_path" && (
          <>
            <Typography>LargeIcon Path</Typography>
            <ActionsTextField
              type="text"
              value={large_icon_path || ""}
              onChange={e =>
                dispatch({ type: SET_LARGE_ICON_PATH, value: e.target.value })
              }
              fullWidth
              margin="normal"
            />
          </>
        )}
        {icon_config_type === "large_icon_url" && (
          <>
            <Typography>LargeIcon Url</Typography>
            <ActionsTextField
              type="text"
              placeholder="LargeIcon Url"
              required
              value={large_icon_url || ""}
              onChange={e =>
                dispatch({ type: SET_LARGE_ICON_URL, value: e.target.value })
              }
              fullWidth
              margin="normal"
            />
          </>
        )}
        <br />
      </Grid>
    </Grid>
  );
}
