import { makePostRequest } from "../../../common/actions";

const EVENT_PATH_API = "graph/paths/events";
const SCREEN_PATH_API = "graph/paths/screens";

export function getEventPathAPI(auth, appId, queryParams, filters) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return [];
  };
  return makePostRequest(
    EVENT_PATH_API,
    auth,
    appId,
    queryParams,
    filters,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getScreenPathAPI(auth, appId, queryParams, filters) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return [];
  };
  return makePostRequest(
    SCREEN_PATH_API,
    auth,
    appId,
    queryParams,
    filters,
    onSuccessCallback,
    onFailureCallback
  );
}
