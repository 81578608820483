import React, { useEffect, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import CustomColorPicker from "../../../../../../../ReusableComponents/CustomColorPicker";
import MultiSelect from "../../../../../../../ReusableComponents/MultiSelect";
import NumberWithButtons from "../../../../../../../ReusableComponents/NumberWithButtons";
import {
  APP_PLATFORMS,
  executeWithDebounce,
} from "../../../../../../common/components/campaigncreator/constants";
import DynamicText2Dialog from "../configuration/components/DynamicText";
import ActionsTextField from "../../../../../../../ReusableComponents/ActionsTextField";
import { ACTION_CLASSES, SHOW_CUSTOM_FONT_TOOLTIP } from "../../constants";
import Autocomplete from "../../../../../../../ReusableComponents/Autocomplete";
import { sendEvent } from "../../../../../../common/ApxEventHandler";

// Do NOT remove this import! (yeah, ik it is an unused import 🥴)
import GradientColorPicker from "../../../../../../../ReusableComponents/GradientColorPicker";
import { isExperienceDashboard } from "../../../../../../../../config";
import BootstrapTooltip from "../../../../../../../ReusableComponents/BootstrapTooltip";
import Apxor from "apxor";
import { APXOR_SDK_CATEGORY_CONSTANTS } from "../../../../../../../../constants/Endpoints";
import LockIcon from "@material-ui/icons/Lock";
import RowDiv from "../../../../../../../ReusableComponents/RowDiv";

const FONT_FAMILY_ENUM = ["casual", "cursive", "monospace", "sans-serif"];
const IOS_FONT_FAMILY_ENUM = [
  "Helvetica Neue",
  "Arial",
  "San Francisco",
  "Menlo",
];

const getFonts = (platform) => {
  if (APP_PLATFORMS.ios === platform)
    return [...FONT_FAMILY_ENUM, ...IOS_FONT_FAMILY_ENUM].map((f) => ({
      label: f,
      value: f,
    }));
  else {
    const fonts = FONT_FAMILY_ENUM.map((f) => ({ label: f, value: f }));
    if (platform === APP_PLATFORMS.web) {
      return [...fonts, { label: "inherit", value: "inherit" }];
    } else {
      return fonts;
    }
  }
};

const getDefaultValue = (fontFamily) => {
  if (!fontFamily.endsWith(".ttf") && fontFamily !== "Custom Font") {
    return { label: fontFamily, value: fontFamily };
  } else {
    return { label: "Custom Font", value: "Custom Font", isButton: true };
  }
};

function TextConfigToolbar({
  action_class,
  platform,
  fontFamily: propFontFamily,
  fontSize: propFontSize,
  color: propColor,
  fontStyle: propFontStyle,
  dynamicType = "",
  script,
  showDynamicText = true,
  text: propText = "",
  onFontFamilyChange,
  onFontSizeChange,
  onColorChange,
  onFontStyleChange,
  onScriptDialogClose,
  onTextChange,
  moduleType: propModuleType = "",
}) {
  const [fontFamily, setFontFamily] = useState(propFontFamily || "");
  const [fontSize, setFontSize] = useState(propFontSize);
  const [color, setColor] = useState(propColor);
  const [fontStyle, setFontStyle] = useState(propFontStyle);
  const [text, setText] = useState(propText);
  const [moduleType, setModuleType] = useState(propModuleType);
  const [showDynamicTextDialog, setShowDynamicTextDialog] = useState(false);
  const [displayTooltip, setDisplayTooltip] = useState(false);
  useEffect(() => {
    if (moduleType !== propModuleType) {
      setFontFamily(propFontFamily || "");
      setFontSize(propFontSize);
      setFontStyle(propFontStyle);
      setColor(propColor);
      setText(propText);
      setModuleType(propModuleType);
    }
    Apxor.logEvent(
      "TextToolbarOpened",
      {},
      APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propModuleType]);

  let autoSelectProps = {};
  if (platform !== APP_PLATFORMS.web && platform !== APP_PLATFORMS.ios) {
    autoSelectProps = {
      button: {
        label: "Custom Font",
        value: "Custom Font",
        isButton: true,
      },
    };
  }

  return (
    <>
      <Grid
        container
        spacing={1}
        style={{
          background: "#fff",
          boxShadow: "0px 4px 15px 2px rgba(0, 40, 69, 0.14)",
          borderRadius: "3px",
          alignItems: "center",
          padding: "8px 12px",
          maxWidth: "600px",
        }}
      >
        <Grid item xs={12}>
          <ActionsTextField
            focused
            onFocus={(event) => {
              // /**
              //  * Event properties must be copied to use async, not only safari, edge as well
              //  * REF: https://stackoverflow.com/questions/54229359/why-does-select-not-work-on-safari-with-reactjs
              //  */
              //const target = event.target;
              // setTimeout(() => target.select(), 0);

              /** Very Dirty hack to satisfy sales sandbox requirement, remove if possible */
              const target = event.target;
              setTimeout(() => {
                target.selectionStart = 0;
                target.selectionEnd = 0;
              }, 0);
            }}
            style={{ marginBottom: 0 }}
            placeholder="Text"
            value={text}
            required
            multiline={false}
            onChange={(e) => {
              const value = e.target.value;
              setText(value);
              if (onTextChange) {
                executeWithDebounce(300)(onTextChange, value);
              }
            }}
          />
        </Grid>
        <Grid item>
          {action_class !== ACTION_CLASSES.TOOLTIP &&
            action_class !== ACTION_CLASSES.COACHMARK && (
              <MultiSelect
                single
                placeholder="Select"
                defaultValue={""}
                isCreatable={true}
                clearable={false}
                options={getFonts(platform)}
                value={fontFamily}
                handleChange={(value) => {
                  setFontFamily(value);
                  onFontFamilyChange(value);
                }}
                style={{ minWidth: 180, maxWidth: 220 }}
              />
            )}
          {(action_class === ACTION_CLASSES.TOOLTIP ||
            action_class === ACTION_CLASSES.COACHMARK) && (
            <Autocomplete
              open={false}
              {...autoSelectProps}
              defaultValue={getDefaultValue(fontFamily)}
              handleOnChange={(selectedValue) => {
                const { value } = selectedValue;
                setFontFamily(value);
                onFontFamilyChange(value);
                // Fire an event to show tooltip in config section
                sendEvent(SHOW_CUSTOM_FONT_TOOLTIP, {
                  checked: selectedValue && selectedValue.isButton === true,
                });
              }}
              options={getFonts(platform)}
              styles={{ width: 165 }}
            />
          )}
        </Grid>
        <Grid item>
          <NumberWithButtons
            value={fontSize}
            maxValue={32}
            minValue={8}
            handleChange={(value) => {
              setFontSize(value);
              onFontSizeChange(value);
            }}
          />
        </Grid>
        <Grid item>
          {/* <GradientColorPicker
            showAnglePicker={true}
            width={220}
            maxStops={4}
            paletteHeight={32}
          /> */}
          <CustomColorPicker
            color={color}
            styles={{ padding: 8 }}
            handleColorChange={(color) => {
              setColor(color.hex);
              onColorChange(color);
            }}
            showColorText={false}
          />
        </Grid>
        <Grid item>
          <ToggleButtonGroup
            value={fontStyle}
            exclusive
            onChange={(e, value) => {
              setFontStyle(value ?? "");
              onFontStyleChange(value ?? "");
            }}
          >
            <ToggleButton value="bold" aria-label="bold" style={{ padding: 6 }}>
              <FormatBoldIcon />
            </ToggleButton>
            <ToggleButton
              value="italic"
              aria-label="italic"
              style={{ padding: 6 }}
            >
              <FormatItalicIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        {showDynamicText && (
          <Grid
            item
            onMouseEnter={() => {
              setDisplayTooltip(true);
            }}
            onMouseLeave={() => setDisplayTooltip(false)}
          >
            <BootstrapTooltip
              open={isExperienceDashboard() && displayTooltip}
              title={
                <>
                  <b>Dynamic Text</b> feature is disabled in the demo app
                </>
              }
              placement="top"
            >
              <Button
                disabled={isExperienceDashboard()}
                variant="outlined"
                style={{ padding: "6px 15px" }}
                onClick={() => setShowDynamicTextDialog(true)}
              >
                <RowDiv center styles={{ gap: "3px" }}>
                  {isExperienceDashboard() && (
                    <LockIcon color="primary" fontSize="small" />
                  )}
                  <span>+ Dynamic Text</span>
                </RowDiv>
              </Button>
            </BootstrapTooltip>
          </Grid>
        )}
        {showDynamicTextDialog && (
          <DynamicText2Dialog
            openFlag={showDynamicTextDialog !== null}
            onSuccess={(tab, type, cmds, script, vmap) => {
              setShowDynamicTextDialog(false);
              onScriptDialogClose(tab, type, cmds, script, vmap);
            }}
            onFail={() => setShowDynamicTextDialog(false)}
            dynamicType={dynamicType ?? ""}
            tab={script?.t ?? 0}
            originalScript={script?.src ?? ""}
            variableMap={script?.vmap ?? {}}
          />
        )}
      </Grid>
    </>
  );
}

export default React.memo(TextConfigToolbar);
