import React from "react";
import { useDispatch, useTracked } from "../../../../../store";
import { FormLabel, Grid, TextField, withStyles } from "@material-ui/core";
import { GenerateEmojiRows } from "../../../../../utils";
import {
  ACTION_AFTER_RATING,
  EMOJI_STYLES,
  SET_APP_STORE_LINK,
} from "../../../../../constants";
import ActionsTextField from "../../../../../../../../../../ReusableComponents/ActionsTextField";
import { useCommonConfigStyles } from "../../../../../../../../walkthroughs/components/design/components/configuration/utils";

/**
 * Contains all the config params related to Rating Question
 */
function RatingBasedQuestionSimulator({ id, text_config }) {
  /**
   * Used to update the changes to the local store
   */
  const [state, dispatch] = useTracked();
  const {
    currentStep,
    ui: { questions = [] },
  } = state;
  const { rating_style = "", rating_scale = "" } = questions[currentStep];
  //Emojis are displayed here
  const classes = useCommonConfigStyles();
  return (
    <>
      <Grid container justify="center">
        {GenerateEmojiRows(rating_style, rating_scale)}
      </Grid>
    </>
  );
}

export default RatingBasedQuestionSimulator;
