import {
  Box,
  Grid,
  makeStyles,
  MuiThemeProvider,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
  withStyles,
} from "@material-ui/core";
import classNames from "classnames";
import React, { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { apxTheme } from "../ApplicationFrame/styling/CustomCSS";
import { ReactComponent as IntExpLaptop } from "../Auth/assets/integrate-stepper-laptop.svg";

const STEPS = [
  "Add Application",
  "Integrate",
  "Test Connection",
  "You are all set!",
];

const QontoConnector = withStyles({
  active: {
    "& $line": {
      borderColor: "#8C583B",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#8C583B",
    },
  },
  line: {
    borderColor: "#5A7286",
    borderWidth: 1.4,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles((theme) => ({
  root: {
    color: "#5A7286",
  },
  active: {
    color: theme.palette.primary.main,
  },
  normal: {
    width: 14,
    height: 14,
    borderRadius: "50%",
    backgroundColor: "currentColor",
    color: "",
  },
  completed: {
    width: 14,
    height: 14,
    borderRadius: "50%",
    backgroundColor: "currentColor",
    color: "#8C583B",
  },
}));

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={classNames(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <div className={classes.completed} />
      ) : (
        <div className={classes.normal} />
      )}
    </div>
  );
}

export default function IntegrationLayout({ children }) {
  const { pathname } = useLocation();
  const { appId } = useParams();

  const stepper_index = useMemo(() => {
    if (pathname === "/apps/create") {
      return 0;
    } else if (pathname === `/apps/${appId}/integrate`) {
      return 1;
    } else if (pathname === `/apps/${appId}/test-connection`) {
      return 2;
    } else if (pathname === `/apps/${appId}/dashboard`) {
      return 3;
    }
  }, [appId, pathname]);

  return (
    <MuiThemeProvider theme={apxTheme}>
      <Grid
        container
        style={{
          margin: "-8px",
          minHeight: "100vh",
        }}
      >
        <Grid
          item
          xs={4}
          style={{
            backgroundColor: "#002845",
            padding: "35px 60px",
          }}
        >
          <Box style={{ justifyContent: "center", alignItems: "start" }}>
            <img
              src="/assets/img/logo-white.png"
              alt="Apxor Logo"
              height="30px"
              style={{
                marginBottom: "60px",
              }}
            />
            <Stepper
              orientation="vertical"
              activeStep={stepper_index}
              style={{ background: "none", padding: 0 }}
              connector={<QontoConnector />}
            >
              {STEPS.map((label, index) => (
                <Step key={`${label} - ${index}`}>
                  <StepLabel StepIconComponent={QontoStepIcon}>
                    <Typography
                      style={{
                        lineHeight: "38px",
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "#FFFFFF",
                      }}
                    >
                      {label}
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <IntExpLaptop
              style={{
                marginTop: "60px",
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={8}
          style={{
            padding: "60px",
            backgroundColor: "#ffffff",
            minHeight: "100%",
          }}
        >
          {children}
        </Grid>
      </Grid>
    </MuiThemeProvider>
  );
}
