import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../../contexts/AuthContext";
import { isValidEmail } from "../../../../utils";
import {
  useDeepCompareCallback,
  useDeepCompareMemo,
} from "../../../../utils/use-deep-compare";
import { addAppAPI } from "../../../App/actions";
import { getAppDataAPI } from "../../../common/actions";
import { saveAndroidPushSettings } from "../../../Dashboard/components/push/actions";
import { saveBundleIdAPI } from "../../../IntegrationExperience/actions";
import {
  SET_APP_ERROR,
  SET_APP_LOADING,
} from "../../../IntegrationExperience/constants";
import { useDispatch as useAppDispatch } from "../../../IntegrationExperience/store";
import { useTrackedState as useAuthState } from "../../store";
import {
  checkInvitationRevokedAPI,
  checkUserExistsAPI,
  forgotPasswordAPI,
  getAllInviteesAPI,
  integrationAuthFinishAPI,
  integrationAuthStartAPI,
  inviteUserAPI,
  loginAPI,
  resetPasswordAPI,
  revokeInvitationAPI,
  setInviteeStatusAPI,
} from "../actions";
// eslint-disable-next-line no-unused-vars
import { AUTH_PAGE_TYPE, PASSWORD_CHECKS } from "../constants";

/**
 * A custom hook to use the integration experience functionality.
 */
export default function useIntegration() {
  const auth = useAuth();
  const { pageType, resetIntermediateAuthStates, login } = auth;
  const {
    user: { name, job_title, email, password, confirm_password, company_name },
  } = useAuthState();
  const appDispatch = useAppDispatch();
  const { search } = useLocation();

  /**
   * This function resets **app_loading** and **app_error** states.
   * @private @memberof {@link useIntegration}
   * @param {Object} payload
   * @param {boolean} payload.loading The loading state to reset with.
   * @param {string} payload.error The error state to reset with.
   */
  const resetIntermediateAppStates = useCallback(
    ({ loading = false, error = "" }) => {
      appDispatch({
        type: SET_APP_LOADING,
        payload: loading,
      });
      appDispatch({
        type: SET_APP_ERROR,
        payload: error,
      });
    },
    [appDispatch],
  );

  /**
   * This function is used to check if the user exists.
   * @public @memberof {@link useIntegration}
   * @param {string} token The auth token to check if the user exists.
   * @returns {Promise<boolean>} Returns a promise that resolves to **true** if the user exists, **false** otherwise.
   */
  const checkIfUserExists = async (token) => {
    const { data } = await checkUserExistsAPI(token);
    return !!data;
  };

  /**
   * This function is used to check if the invitation is revoked.
   * @public @memberof {@link useIntegration}
   * @param {string} appId The app ID to check if the user's invitation is revoked.
   * @param {string} email The email of the user to check whose invitation is revoked.
   * @returns {Promise<boolean>} Returns a promise that resolves to **true** if the invitation is revoked, **false** otherwise.
   */
  const checkIfInvitationIsRevoked = async (appId, email) => {
    const { data } = await checkInvitationRevokedAPI(appId, email);
    return !!data;
  };

  /**
   * This function is used to send an email to the user with a link to reset their password.
   * @public @memberof {@link useIntegration}
   * @returns {Promise<boolean>} Returns a promise that resolves to **true** if the email is sent successfully, **false** otherwise.
   */
  const sendPasswordResetEmail = async (email) => {
    try {
      resetIntermediateAuthStates({
        loading: true,
        error: "",
      });
      const sent = await forgotPasswordAPI(email, window.location.host);
      resetIntermediateAuthStates({
        loading: false,
        error: "",
      });
      return sent === true;
    } catch (err) {
      resetIntermediateAuthStates({
        loading: false,
        error: err,
      });
      return false;
    }
  };

  const saveFCMKeyAndBundleID = useDeepCompareCallback(
    async (appId, bundleId, fcmKey) => {
      try {
        resetIntermediateAppStates({
          loading: true,
          error: "",
        });
        const responses = await Promise.all([
          saveAndroidPushSettings(auth, appId, null, { fcmKey }),
          saveBundleIdAPI(auth, appId, bundleId),
        ]);
        if (responses.every((response) => response === true)) {
          resetIntermediateAppStates({
            loading: false,
            error: "",
          });
        }
        return true;
      } catch (err) {
        resetIntermediateAppStates({
          loading: false,
          error: err?.message ?? err?.data?.message,
        });
        return false;
      }
    },
    [auth],
  );

  /**
   * This function resets the password of a user.
   * @public @memberof {@link useIntegration}
   * @returns {Promise<boolean>} Returns a promise that resolves to **true** if the password is reset successfully, **false** otherwise.
   */
  const resetPassword = useCallback(
    async (newPassword) => {
      try {
        const queryParams = new URLSearchParams(search);
        resetIntermediateAuthStates({
          loading: true,
          error: "",
        });
        const res = await resetPasswordAPI(
          { host: null, token: null },
          queryParams.get("user"),
          newPassword,
          queryParams.get("token"),
        );
        return !!res;
      } catch (err) {
        resetIntermediateAuthStates({
          loading: true,
          error: err?.message ?? err?.data?.message,
        });
        return false;
      }
    },
    [resetIntermediateAuthStates, search],
  );

  /**
   * This function logs the user in.
   * @public @memberof {@link useIntegration}
   * @returns {Promise<boolean>} Returns a promise that resolves to **true** if the login was successful, **false** otherwise.
   */
  const loginUser = async (email, password) => {
    try {
      resetIntermediateAuthStates({
        loading: true,
        error: "",
      });
      const { user } = await loginAPI(email, password);
      login(user);
      resetIntermediateAuthStates({
        loading: false,
        error: "",
      });
      return true;
    } catch (err) {
      resetIntermediateAuthStates({
        loading: false,
        error: err?.data?.message,
      });
      return false;
    }
  };

  /**
   * This function sends an email magic link inorder to set a new password to the associated user account.
   * @public @memberof {@link useIntegration}
   * @returns {Promise<boolean>} Returns a promise that resolves to **true** if the magic link is sent successfully, **false** otherwise.
   */
  const sendSetPasswordEmail = async (userDetails) => {
    try {
      resetIntermediateAuthStates({
        loading: true,
        error: "",
      });
      const res = await integrationAuthStartAPI({
        ...userDetails,
        phone_number: "",
      });
      resetIntermediateAuthStates({
        loading: false,
        error: "",
      });
      return !!res?.data && res?.status === "success";
    } catch (err) {
      resetIntermediateAuthStates({
        loading: false,
        error: err?.data?.message ?? err,
      });
      return false;
    }
  };

  /**
   * This function sets a new password to the associated user account.
   * @public @memberof {@link useIntegration}
   * @param {string} name The name of the user.
   * @param {string} password The password of the user to be set.
   * @param {string} token The auth token to set the new password.
   * @returns {Promise<boolean>} Returns a promise that resolves to **true** if the password is set successfully, **false** otherwise.
   */
  const setPassword = async (name, password, token) => {
    try {
      resetIntermediateAuthStates({
        loading: true,
        error: "",
      });
      const res = await integrationAuthFinishAPI({
        name,
        password,
        token,
      });
      resetIntermediateAuthStates({
        loading: false,
        error: "",
      });
      return !!res;
    } catch (err) {
      resetIntermediateAuthStates({
        loading: false,
        error: err?.data?.message ?? err,
      });
      return false;
    }
  };

  /**
   * This function is used to send a magic link to invite a user to the app.
   * @public @memberof {@link useIntegration}
   * @returns {Promise<boolean>} Returns a promise that resolves to **true** if the magic link is sent successfully, **false** otherwise.
   */
  const sendInviteEmail = async (appId, email_ids) => {
    try {
      resetIntermediateAppStates({
        loading: true,
        error: "",
      });
      const res = await inviteUserAPI(appId, "", email_ids);
      resetIntermediateAppStates({
        loading: false,
        error: "",
      });
      return !!res;
    } catch (err) {
      resetIntermediateAppStates({
        loading: false,
        error: err?.data?.message ?? err,
      });
      return false;
    }
  };

  const getInvitees = async (appId) => {
    try {
      resetIntermediateAppStates({
        loading: true,
        error: "",
      });
      const res = await getAllInviteesAPI(appId);
      resetIntermediateAppStates({
        loading: false,
        error: "",
      });
      return res;
    } catch (err) {
      resetIntermediateAppStates({
        loading: false,
        error: err?.data?.message ?? err,
      });
    }
  };

  /**
   * This function is used to revoke an invitation sent to a user from the app.
   * @public @memberof {@link useIntegration}
   * @returns {Promise<boolean>} Returns a promise that resolves to **true** if the invitation is revoked successfully, **false** otherwise.
   */
  const revokeInvitation = async (appId, email) => {
    try {
      resetIntermediateAppStates({
        loading: true,
        error: "",
      });
      const res = await revokeInvitationAPI(appId, email);
      resetIntermediateAppStates({
        loading: false,
        error: "",
      });
      return !!res;
    } catch (err) {
      resetIntermediateAppStates({
        loading: false,
        error: err?.data?.message ?? err,
      });
      return false;
    }
  };

  /**
   * This function is used to create a new app in the organisation.
   * @public @memberof {@link useIntegration}
   * @returns {Promise} Returns a promise that resolves to the app's details.
   */
  const addNewApplication = useDeepCompareCallback(
    async (app_name, bundle_id, platform) => {
      try {
        resetIntermediateAppStates({
          loading: true,
          error: "",
        });
        const { app_id } = await addAppAPI(auth, {
          app_name,
          bundle_id,
          platform,
        });
        resetIntermediateAppStates({
          loading: false,
          error: "",
        });
        return app_id;
      } catch (err) {
        resetIntermediateAppStates({
          loading: false,
          error: err?.data?.message ?? err,
        });
      }
    },
    [auth, resetIntermediateAppStates],
  );

  const getApplicationData = useDeepCompareCallback(
    async (appId) => {
      try {
        resetIntermediateAppStates({
          loading: true,
          error: "",
        });
        const res = await getAppDataAPI(auth, appId);
        resetIntermediateAppStates({
          loading: false,
          error: "",
        });
        return res;
      } catch (err) {
        resetIntermediateAppStates({
          loading: false,
          error: err?.data?.message ?? err,
        });
      }
    },
    [auth, resetIntermediateAppStates],
  );

  /**
   * This function is used to set an invitee's invitation status to **accepted**.
   * @public @memberof {@link useIntegration}
   * @param {string} token - The token sent in the invitee's invitation magic link.
   * @returns {Promise<boolean>} Returns a promise that resolves to **true** if the invitation status is made truthy, **false** otherwise.
   */
  const makeUserStatusInvited = async (token) => {
    try {
      resetIntermediateAppStates({
        loading: true,
        error: "",
      });
      await setInviteeStatusAPI(token, true);
      resetIntermediateAppStates({
        loading: false,
        error: "",
      });
      return true;
    } catch (err) {
      resetIntermediateAppStates({
        loading: false,
        error: err?.data?.message ?? err,
      });
      return false;
    }
  };

  /**
   * This memoised value checks if the password is valid.
   * @public @memberof {@link useIntegration}
   * @returns {Array<boolean>} Returns an array of 4 boolean values which map to the checks in the enum {@link PASSWORD_CHECKS}
   */
  const isValidPassword = useMemo(() => {
    return [
      password?.length >= 8,
      password?.match(/[a-z]/) !== null,
      password?.match(/[A-Z]/) !== null,
      password?.match(/[0-9]/) !== null,
    ];
  }, [password]);

  /**
   * This memoised value checks if **password** and **confirm_password** are matching.
   * @private @memberof {@link useIntegration}
   * @returns {boolean} **true** if passwords match, **false** otherwise.
   */
  const arePasswordsMatching = useMemo(() => {
    return password === confirm_password;
  }, [password, confirm_password]);

  /**
   * This memoised value checks if the submit button is disabled.
   * @public @memberof {@link useIntegration}
   * @returns {boolean} **true** if it is disabled, **false** otherwise.
   */
  const isSubmitDisabled = useDeepCompareMemo(() => {
    switch (pageType) {
      case AUTH_PAGE_TYPE.SIGN_IN:
        return !email || !password || !isValidEmail(email);
      case AUTH_PAGE_TYPE.SET_PASSWORD:
        return (
          [password, confirm_password, isValidPassword].some(
            (param) => !param,
          ) || !arePasswordsMatching
        );
      case AUTH_PAGE_TYPE.DEV_SIGN_UP:
        return (
          [name, email, password, confirm_password, isValidPassword].some(
            (param) => !param,
          ) ||
          !arePasswordsMatching ||
          !isValidEmail(email)
        );
      case AUTH_PAGE_TYPE.SIGN_UP:
        return (
          [name, email, job_title, company_name].some((param) => !param) ||
          !isValidEmail(email)
        );
      case AUTH_PAGE_TYPE.FORGOT_PASSWORD:
        return !email || !isValidEmail(email);
      case AUTH_PAGE_TYPE.RESET_PASSWORD:
        return !password || !confirm_password || !arePasswordsMatching;
      default:
        return true;
    }
  }, [
    arePasswordsMatching,
    company_name,
    confirm_password,
    email,
    isValidPassword,
    job_title,
    name,
    pageType,
    password,
  ]);

  return {
    checkIfUserExists,
    checkIfInvitationIsRevoked,
    sendPasswordResetEmail,
    resetPassword,
    loginUser,
    sendSetPasswordEmail,
    setPassword,
    addNewApplication,
    saveFCMKeyAndBundleID,
    sendInviteEmail,
    getInvitees,
    revokeInvitation,
    makeUserStatusInvited,
    getApplicationData,
    isSubmitDisabled,
    isValidPassword,
  };
}
