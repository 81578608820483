import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import React, { useMemo } from "react";
import { APP_PLATFORMS } from "../../../../../../../../../constants";
import ActionsTextField from "../../../../../../../../ReusableComponents/ActionsTextField";
import ModuleDivider from "../../../../../../../../ReusableComponents/ModuleDivider";
import MultiSelect from "../../../../../../../../ReusableComponents/MultiSelect";
import ToggleSwitch from "../../../../../../../../ReusableComponents/ToggleSwitch";
import { sendEvent } from "../../../../../../../common/ApxEventHandler";
import { TOGGLE_SWITCH_STYLES } from "../../../../../../../common/components/campaigncreator/constants";
import { useTrackedState } from "../../../../../../../common/components/campaigncreator/store";
import {
  HIDE_CONFIGURATION_TOOLBARS,
  MESSAGE_POSITION_ENUM,
  SET_BUTTONS,
  SET_BUTTON_ACTION,
  SET_COACHMARK_HAS_ARROW,
  SET_COACHMARK_HAS_RIPPLE,
  SET_COACHMARK_TYPE,
  SET_DISMISS_OUTSIDE_TOUCH,
  SET_MESSAGE_POSITION,
  SET_OPACITY,
  TOOLTIP_LAYOUT_TYPES,
} from "../../../constants";
import { useTracked } from "../../../store";
import { getUI } from "../../../utils";
import { useCommonConfigStyles } from "../utils";
import ButtonAction from "./ButtonAction";
import CustomFontField from "./CustomFontField";
import DelayConfig from "./DelayConfig";

export default function NewCoachmarkConfig({ currentStep }) {
  const classes = useCommonConfigStyles();

  const parentState = useTrackedState();
  const {
    config: {
      meta: { platform, isExperiment },
    },
    app_screens: appScreenNames = [],
  } = parentState;

  const [state, dispatch] = useTracked();

  const ui = getUI(currentStep, state.uis, isExperiment).ui || {};

  const {
    coach_mark_details = {},
    dismiss_outside_touch,
    layout_type,
    opacity = 0.6,
    position,
    delay,
    enableCfnt,
    cFnt,
  } = ui;

  const isActionButtonsLayout = useMemo(
    () => layout_type === TOOLTIP_LAYOUT_TYPES.ACTION_BUTTONS,
    [layout_type]
  );

  const buttonKeyName = useMemo(
    () => (isActionButtonsLayout ? "action_buttons_config" : "buttons_config"),
    [isActionButtonsLayout]
  );

  return (
    <Grid container spacing={1} className={classes.gridContainer}>
      <Grid item xs={12} className={classes.gridItem} style={{ marginTop: 8 }}>
        <Typography className={classes.typographySubtitle2} variant="h6">
          COACHMARK TYPE
        </Typography>
        <ToggleButtonGroup
          value={coach_mark_details.type}
          exclusive
          onChange={(e, value) => {
            let val = value;
            if (!value) {
              if (coach_mark_details.type === "circle") {
                val = "rectangle";
              } else {
                val = "circle";
              }
            }
            dispatch({
              type: SET_COACHMARK_TYPE,
              currentStep,
              value: val,
            });
          }}
        >
          <ToggleButton
            value="circle"
            aria-label="circle"
            style={{ padding: 8 }}
          >
            <span style={{ padding: "0px 8px" }}>Circle</span>
          </ToggleButton>
          <ToggleButton
            value="rectangle"
            aria-label="rectangle"
            style={{ padding: 8 }}
          >
            <span style={{ padding: "0px 8px" }}>Rectangle</span>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      {coach_mark_details?.type !== "circle" && platform !== APP_PLATFORMS.web && (
        <>
          <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
          <Grid item xs={12} className={classes.gridItem}>
            <Typography className={classes.typographySubtitle2} variant="h6">
              ARROW
            </Typography>
            <ToggleSwitch
              className={classes.formControlLabel}
              checked={coach_mark_details.has_arrow}
              handleChange={(checked, e) => {
                dispatch({
                  type: SET_COACHMARK_HAS_ARROW,
                  currentStep,
                  value: checked,
                });
              }}
              style={TOGGLE_SWITCH_STYLES()}
            />
          </Grid>
        </>
      )}
      <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
      <Grid item xs={12} className={classes.gridItem}>
        <Typography className={classes.typographySubtitle2} variant="h6">
          BUTTONS
        </Typography>
        <ToggleSwitch
          className={classes.formControlLabel}
          checked={ui[buttonKeyName] && ui[buttonKeyName].length > 0}
          handleChange={(checked, e) => {
            let buttonsCount = 1;
            if (!checked) {
              buttonsCount = 0;
            }
            dispatch({
              type: SET_BUTTONS,
              currentStep,
              count: buttonsCount,
              key: buttonKeyName,
              isActionButtons: isActionButtonsLayout,
            });
            sendEvent(HIDE_CONFIGURATION_TOOLBARS);
          }}
          style={TOGGLE_SWITCH_STYLES()}
        />
      </Grid>
      {ui[buttonKeyName] && ui[buttonKeyName].length > 0 && (
        <>
          <Grid
            item
            xs={12}
            className={classes.gridItem}
            style={{ marginBottom: 0 }}
          >
            <Typography className={classes.typographySubtitle2} variant="h6">
              Number of buttons
            </Typography>
            <ToggleButtonGroup
              value={(ui[buttonKeyName] ? ui[buttonKeyName].length : 0) + ""}
              exclusive
              onChange={(e, value) => {
                let buttonsCount = 0;
                if (value === "1") {
                  buttonsCount = 1;
                } else if (value === "2") {
                  buttonsCount = 2;
                }
                dispatch({
                  type: SET_BUTTONS,
                  currentStep,
                  count: buttonsCount,
                  key: buttonKeyName,
                  isActionButtons: isActionButtonsLayout,
                });
                sendEvent(HIDE_CONFIGURATION_TOOLBARS);
              }}
            >
              <ToggleButton value="1" aria-label="1" style={{ padding: 8 }}>
                <span style={{ padding: "0px 8px" }}>1</span>
              </ToggleButton>
              <ToggleButton value="2" aria-label="2" style={{ padding: 8 }}>
                <span style={{ padding: "0px 8px" }}>2</span>
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          {ui[buttonKeyName].map((button, index) => (
            <ButtonAction
              platform={platform}
              key={index}
              appScreenNames={appScreenNames}
              button={button}
              classes={classes}
              index={index}
              onActionChange={(data) => {
                dispatch({
                  type: SET_BUTTON_ACTION,
                  currentStep,
                  keyName: button.__id,
                  buttonKeyName,
                  data,
                });
              }}
            />
          ))}
        </>
      )}
      <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
      <Grid item xs={12} className={classes.gridItem}>
        <Typography className={classes.typographySubtitle2} variant="h6">
          RIPPLE ANIMATION
        </Typography>
        <ToggleSwitch
          className={classes.formControlLabel}
          checked={coach_mark_details.has_ripple}
          handleChange={(checked, e) => {
            dispatch({
              type: SET_COACHMARK_HAS_RIPPLE,
              currentStep,
              value: checked,
            });
          }}
          style={TOGGLE_SWITCH_STYLES()}
        />
      </Grid>
      <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
      <Grid item xs={12} className={classes.gridItem}>
        <Typography className={classes.typographySubtitle2} variant="h6">
          POSITION
        </Typography>
        <MultiSelect
          style={{ maxWidth: 145, minWidth: 145 }}
          single
          clearable={false}
          isSearchable={false}
          options={MESSAGE_POSITION_ENUM.filter(
            (x) => x.label !== "Center"
          ).map((x) => ({
            label: x.label.toUpperCase(),
            value: x.value.toUpperCase(),
          }))}
          value={position.toUpperCase()}
          handleChange={(value) => {
            dispatch({
              type: SET_MESSAGE_POSITION,
              currentStep: currentStep,
              value: value.toUpperCase(),
            });
          }}
        />
      </Grid>
      <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
      <Grid item xs={12} className={classes.gridItem}>
        <Typography className={classes.typographySubtitle2} variant="h6">
          OPACITY
        </Typography>
        <ActionsTextField
          key="opacity"
          type="number"
          InputProps={{
            min: 10,
            max: 100,
            type: "number",
          }}
          value={Number(opacity * 100)}
          onChange={(event) => {
            dispatch({
              type: SET_OPACITY,
              value: Number(event.target.value) / 100.0,
            });
          }}
          style={{
            marginBottom: 0,
          }}
        />
      </Grid>
      <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
      <Grid item xs={12} className={classes.gridItem}>
        <Typography className={classes.typographySubtitle2} variant="h6">
          DISMISS
        </Typography>
        <FormControlLabel
          className={classes.formControlLabel}
          label="Dismiss on Touch Outside"
          control={
            <Checkbox
              color="primary"
              checked={dismiss_outside_touch}
              onChange={(e) => {
                dispatch({
                  type: SET_DISMISS_OUTSIDE_TOUCH,
                  currentStep,
                  value: e.target.checked,
                });
              }}
              style={{ padding: 0, paddingRight: 8 }}
              disableRipple
            />
          }
        />
      </Grid>
      {platform !== APP_PLATFORMS.web && (
        <>
          <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
          <DelayConfig delay={delay} />
        </>
      )}
      <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
      {platform !== APP_PLATFORMS.ios && (
        <CustomFontField isCFnt={enableCfnt} customFont={cFnt} />
      )}
    </Grid>
  );
}
