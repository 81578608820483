import { Box, Button, Grid } from "@material-ui/core"
import Apxor from "apxor"
import React, { useCallback } from "react"
import OtpInput from "react-otp-input"
import ActionsTextField from "../../../../ReusableComponents/ActionsTextField"
import {
  SET_AUTH_ERROR,
  SET_JOB_TITLE,
  SET_NAME,
  SET_OTP,
} from "../../../constants"
import { useDispatch, useTrackedState } from "../../../store"
import useSandBox from "../../hooks/use-sandbox"

export default function ExperienceDemoSignUpForm({ setActiveStep }) {
  const { resendOTP } = useSandBox()
  const {
    user: { name, job_title, phone, email, otp },
    auth_error,
  } = useTrackedState()
  const dispatch = useDispatch()

  const handleOtpChange = useCallback(
    (otpValue) => {
      if (auth_error.length > 0) {
        dispatch({
          type: SET_AUTH_ERROR,
          payload: "",
        })
      }
      dispatch({
        type: SET_OTP,
        payload: otpValue,
      })
    },
    [dispatch, auth_error.length],
  )

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <label
              htmlFor="fullName"
              style={{
                color: "rgba(102, 122, 138, 1)",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Full Name
            </label>
            <ActionsTextField
              multiline={false}
              id="fullName"
              type="text"
              name="fullName"
              value={name}
              onChange={(e) => {
                dispatch({
                  type: SET_NAME,
                  payload: e.target.value,
                })
              }}
              margin="normal"
              fullWidth
              InputProps={{
                style: {
                  fontSize: "15px",
                  fontWeight: "600",
                  padding: "8px 6px",
                  borderRadius: "2px",
                  borderColor: "#CCD4DA",
                },
              }}
              style={{
                marginTop: "6px",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <label
              htmlFor="jobTitle"
              style={{
                color: "rgba(102, 122, 138, 1)",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Job Title
            </label>
            <ActionsTextField
              multiline={false}
              id="jobTitle"
              type="text"
              name="jobTitle"
              value={job_title}
              onChange={(e) => {
                dispatch({
                  type: SET_JOB_TITLE,
                  payload: e.target.value,
                })
              }}
              margin="normal"
              fullWidth
              InputProps={{
                style: {
                  fontSize: "15px",
                  fontWeight: "600",
                  padding: "8px 6px",
                  borderRadius: "2px",
                  borderColor: "#CCD4DA",
                },
              }}
              style={{
                marginTop: "6px",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <label
          style={{
            color: "rgba(102, 122, 138, 1)",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          phone Number
        </label>
        <Box
          style={{
            position: "relative",
          }}
        >
          <ActionsTextField
            multiline={false}
            disabled={true}
            type="text"
            value={phone}
            margin="normal"
            fullWidth
            InputProps={{
              style: {
                fontSize: "15px",
                fontWeight: "600",
                padding: "8px 6px",
                borderRadius: "2px",
                borderColor: "#CCD4DA",
              },
            }}
            style={{
              marginTop: "6px",
            }}
          />
          <Button
            color="primary"
            variant="text"
            size="small"
            style={{
              position: "absolute",
              marginLeft: "7px",
              fontWeight: "700",
              fontSize: "14px",
              right: "16px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
            onClick={() => {
              Apxor.logEvent("Change_Phone_Clicked")
              setActiveStep((activeStep) => activeStep - 1)
            }}
          >
            Change
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <label
          style={{
            color: "rgba(102, 122, 138, 1)",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          Work Email
        </label>
        <Box
          style={{
            position: "relative",
          }}
        >
          <ActionsTextField
            multiline={false}
            disabled={true}
            type="text"
            value={email}
            margin="normal"
            fullWidth
            InputProps={{
              style: {
                fontSize: "15px",
                fontWeight: "600",
                padding: "8px 6px",
                borderRadius: "2px",
                borderColor: "#CCD4DA",
              },
            }}
            style={{
              marginTop: "6px",
            }}
          />
          <Button
            color="primary"
            variant="text"
            size="small"
            style={{
              position: "absolute",
              marginLeft: "7px",
              fontWeight: "700",
              fontSize: "14px",
              right: "16px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
            onClick={() => {
              Apxor.logEvent("Change_Email_Clicked")
              setActiveStep((activeStep) => activeStep - 1)
            }}
          >
            Change
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <label
            htmlFor="otp"
            style={{
              color: "rgba(102, 122, 138, 1)",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            Enter the 6 digit OTP sent to your email
          </label>
          <Button
            color="primary"
            variant="text"
            size="small"
            style={{
              marginLeft: "7px",
              fontWeight: "600",
              fontSize: "14px",
            }}
            onClick={resendOTP}
          >
            Resend
          </Button>
        </Box>
        <OtpInput
          id="otp"
          name="otp"
          value={otp}
          onChange={handleOtpChange}
          numInputs={6}
          focusStyle={{
            border: "1px solid rgba(0, 40, 69, 1)",
            outline: "none",
          }}
          hasErrored={!!auth_error}
          errorStyle={{
            border: "1px solid rgba(229, 59, 59, 1)",
          }}
          shouldAutoFocus
          separator={
            <span
              style={{
                width: "13px",
              }}
            ></span>
          }
          inputStyle={{
            fontFamily: "Roboto, Manrope, sans-serif",
            fontWeight: "700",
            fontSize: "38px",
            minHeight: "60px",
            minWidth: "60px",
            border: "1px solid #CCD4DA",
            borderRadius: "2px",
            paddingBottom: "6px",
          }}
          containerStyle={{
            marginTop: "6px",
          }}
        />
        {auth_error && (
          <span
            style={{
              color: "rgba(229, 59, 59, 1)",
              fontSize: "13px",
              fontWeight: "500",
              marginTop: "2px",
            }}
          >
            {auth_error}
          </span>
        )}
      </Grid>
    </Grid>
  )
}

