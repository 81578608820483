/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import { Typography, Button, TextField } from "@material-ui/core";
import RowDiv from "../../../../../../../../ReusableComponents/RowDiv";
import CloseIcon from "@material-ui/icons/Close";
import Warning from "../../../assets/Vectorwarning.svg";
import { SET_INAPP_HTML_URL } from "../../../constants";

export default function ConfirmationDialog({
  handleCancel,
  dispatch,
  codeEmpty,
}) {
  const [url, setUrl] = useState("");
  const [isValid, setIsValid] = useState(false);

  const isUrlValid = (userInput) => {
    var res = userInput.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    if (res == null) setIsValid(false);
    else return setIsValid(true);
  };

  const handleChange = (event) => {
    setUrl(event.target.value);
    isUrlValid(event.target.value);
  };
  const inputStyle = {
    position: "absolute",
    width: "460px",
    height: 48,
    background: "#FFFFFF",
    top: 100,
  };
  const warningStyle = {
    position: "absolute",
    top: 166,
    left: 85,
    fontFamily: "Manrope",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "19px",
    letterSpacing: "0.01em",
    color: "#002845",
  };
  return (
    <div style={{ width: 500, height: 250, padding: 20, marginLeft: 10 }}>
      <div
        style={{
          position: "absolute",
          cursor: "pointer",
          top: 20,
          right: 20,
        }}
        onClick={handleCancel}
      >
        {" "}
        <CloseIcon />
      </div>

      <Typography
        variant="h4"
        style={{
          fontWeight: "bold",
          fontFamily: "Manrope",
          fontStyle: "normal",
          fontSize: "25px",
          lineHeight: "34px",
          color: "#002845",
        }}
      >
        Import Code
      </Typography>
      <TextField
        id="outlined-basic"
        label="HTML URL"
        variant="outlined"
        style={inputStyle}
        value={url}
        onChange={handleChange}
      />
      {!codeEmpty && (
        <div>
          <img
            src={Warning}
            alt="warning"
            style={{ position: "absolute", top: 167 }}
          />
          <Typography style={warningStyle}>
            Note: The present code will be cleared
          </Typography>
        </div>
      )}
      {!isValid && (
        <div style={{ ...warningStyle, top: 80, left: 410, color: "red" }}>
          Not a valid URL
        </div>
      )}
      <RowDiv
        center
        styles={{
          justifyContent: "flex-end",
          width: "100%",
          marginRight: 0,
          marginTop: 130,
        }}
      >
        <Button
          style={{ marginRight: "2em", color: "black" }}
          onClick={handleCancel}
        >
          Cancel
        </Button>

        <Button
          variant={"contained"}
          style={{
            marginRight: "2em",
            color: "#FFFFFF",
            backgroundColor: !isValid ? " #F9D9C7" : "#FF7F33",
          }}
          disabled={!isValid}
          onClick={() => {
            dispatch({ type: SET_INAPP_HTML_URL, value: url });
            handleCancel();
          }}
        >
          Import
        </Button>
      </RowDiv>
    </div>
  );
}
