import React, { useState, useMemo } from "react";
import { Button, Typography } from "@material-ui/core";
import MobileSimulator from "../../../../../../../../ReusableComponents/MobileSimulator";
import SimulatorHolder from "./SimulatorHolder";
import { ORIENTATION_ENUM } from "../../../../../../walkthroughs/components/design/components/simulator/utils";
import ColumnDiv from "../../../../../../../../ReusableComponents/ColumnDiv";
import BootstrapTooltip from "../../../../../../../../ReusableComponents/BootstrapTooltip";
import RowDiv from "../../../../../../../../ReusableComponents/RowDiv";

export default function SurveyPreviewDialog({
  isCanvas,
  orientation = ORIENTATION_ENUM.PORTRAIT,
}) {
  const width = useMemo(
    () => (orientation === ORIENTATION_ENUM.PORTRAIT ? 270 : 540),
    [orientation]
  );
  const height = useMemo(
    () => (orientation === ORIENTATION_ENUM.PORTRAIT ? 540 : 270),
    [orientation]
  );

  const [reset, setReset] = useState(false);
  return (
    <ColumnDiv
      center
      styles={{
        backgroundColor: "#F0F2F4",
        borderRadius: 3,
        border: "1px solid #DCE0E3",
        marginLeft: !isCanvas ? "unset" : 16,
        marginRight: !isCanvas ? "unset" : 5,
        height: "100%",
        padding: !isCanvas ? "24px 62px" : "10px 10px 27px",
      }}
    >
      <div
        style={{
          margin: !isCanvas ? "unset" : "auto auto 50px",
          backgroundColor: "#ffffff",
          border: "1.14433px solid #E4E7E9",
          width,
          height,
        }}
      >
        <SimulatorHolder reset={reset} isCanvas={isCanvas} />
        <RowDiv center>
          <BootstrapTooltip
            title="Reset Simulator to current Question"
            placement="top"
          >
            <Button
              variant="contained"
              color="secondary"
              style={{
                margin: "auto",
                marginTop: 8,
                borderRadius: 20,
              }}
              disabled={null ? true : false}
              onClick={e => {
                setReset(true);
                setTimeout(() => {
                  setReset(false);
                }, 100);
              }}
            >
              <Typography
                variant="subtitle1"
                color="textPrimary"
                style={{ fontWeight: 800 }}
              >
                Reset Simulator
              </Typography>
            </Button>
          </BootstrapTooltip>
        </RowDiv>
      </div>
    </ColumnDiv>
  );
}
