import React from "react";
import { Grid, Typography } from "@material-ui/core";
import "react-tabs/style/react-tabs.css";
import "../../styles.css";
import NewContextBuilder from "./components/NewContextBuilder";
import { IconImageComponent } from "../../../../utils";
import trigger from "../../assets/trigger.png";
import ab from "../../assets/ab.png";
import goal from "../../assets/goal.png";
import target_audience from "../../assets/target_audience.png";
import TargetAudienceSection from "./components/targetaudience";
import ABSettingsSection from "./components/absettings";
import GoalEvent from "./components/GoalEvent";
import { TYPOGRAPHY_HEADING } from "../../../../../../common/constants";
import { useTracked } from "../../store";
import { isExperienceDashboard } from "../../../../../../../config";

export default function Context({ app }) {
  const [parentState, parentDispatch] = useTracked();
  const {
    config: {
      meta: { isExperiment, platform },
      experiment: { publishSettings: { CG = null } = {} } = {},
      cg_percentage = null,
    },
  } = parentState;

  return (
    <>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          style={{
            border: "1px solid #E4E7E9",
            borderRadius: 3,
            margin: 8,
            background: "#FFF",
          }}
        >
          <Grid container spacing={1}>
            <Grid
              item
              xs={3}
              style={{
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid #E4E7E9",
                alignItems: "center",
                padding: 20,
              }}
            >
              <Typography
                style={{
                  marginBottom: 20,
                  ...TYPOGRAPHY_HEADING,
                  letterSpacing: "0.11em",
                }}
              >
                TARGET AUDIENCE
              </Typography>
              {IconImageComponent(target_audience, "Target Audience", {
                width: 157,
                height: 157,
              })}
            </Grid>
            <Grid item xs={9} style={{ padding: "24px" }}>
              <TargetAudienceSection
                app_id={app.app_id}
                features={app.features}
                CG={isExperiment ? CG : cg_percentage}
                parentDispatch={parentDispatch}
                platform={platform}
                isExperiment={isExperiment}
              />
            </Grid>
          </Grid>
        </Grid>
        {isExperiment && (
          <Grid
            item
            xs={12}
            style={{
              border: "1px solid #E4E7E9",
              borderRadius: 3,
              margin: 8,
              marginTop: 0,
              background: "#FFF",
            }}
          >
            <Grid container spacing={1}>
              <Grid
                item
                xs={3}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  borderRight: "1px solid #E4E7E9",
                  alignItems: "center",
                  padding: 20,
                }}
              >
                <Typography
                  style={{
                    marginBottom: 20,
                    ...TYPOGRAPHY_HEADING,
                    letterSpacing: "0.11em",
                  }}
                >
                  A/B SETTINGS
                </Typography>
                {IconImageComponent(ab, "A/B", {
                  width: 157,
                  height: 157,
                })}
              </Grid>
              <Grid item xs={9} style={{ padding: "24px" }}>
                <ABSettingsSection />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          style={{
            border: "1px solid #E4E7E9",
            borderRadius: 3,
            margin: 8,
            marginTop: 0,
            background: "#FFF",
          }}
        >
          <Grid container spacing={1}>
            <Grid
              item
              xs={3}
              style={{
                display: "flex",
                flexDirection: "column",
                borderRight: "1px solid #E4E7E9",
                alignItems: "center",
                padding: 20,
              }}
            >
              <Typography
                style={{
                  marginBottom: 20,
                  ...TYPOGRAPHY_HEADING,
                  letterSpacing: "0.11em",
                }}
              >
                TRIGGER
              </Typography>
              {IconImageComponent(trigger, "Trigger", {
                width: 157,
                height: 157,
              })}
            </Grid>
            <Grid item xs={9} style={{ padding: "24px" }}>
              <NewContextBuilder app={app} />
            </Grid>
          </Grid>
        </Grid>

        {!!!isExperienceDashboard() && (
          <Grid
            item
            xs={12}
            style={{
              border: "1px solid #E4E7E9",
              borderRadius: 3,
              margin: 8,
              marginTop: 0,
              background: "#FFF",
            }}
          >
            <Grid container spacing={1}>
              <Grid
                item
                xs={3}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  borderRight: "1px solid #E4E7E9",
                  alignItems: "center",
                  padding: 20,
                }}
              >
                <Typography
                  style={{
                    marginBottom: 20,
                    ...TYPOGRAPHY_HEADING,
                    letterSpacing: "0.11em",
                  }}
                >
                  GOAL EVENT
                </Typography>
                {IconImageComponent(goal, "Goal", {
                  width: 157,
                  height: 157,
                })}
              </Grid>
              <Grid item xs={9} style={{ padding: "24px" }}>
                <GoalEvent />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );

}
