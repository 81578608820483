import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useAuth } from "../../../../../../../../../contexts/AuthContext";
import ActionsTextField from "../../../../../../../../ReusableComponents/ActionsTextField";
import ColumnDiv from "../../../../../../../../ReusableComponents/ColumnDiv";
import Loading from "../../../../../../../../ReusableComponents/Loading";
import RowDiv from "../../../../../../../../ReusableComponents/RowDiv";
import { updateCampaignAPI } from "../../../../actions";
import VariantDistributions from "../../../context/components/absettings/components/VariantDistributions";
import { useSummaryStyles } from "../../constants";

export default function ABSettingsSection({ config, handleSelect }) {
  const auth = useAuth();
  const headerClasses = useSummaryStyles();

  const {
    experiment: {
      variants: propVariants,
      settings: {
        targetGroup: propTargetGroup,
        isManualExperiment: propIsManualExperiment,
      },
    } = {
      variants: {},
      settings: { isManualExperiment: false, targetGroup: 10 },
    },
  } = config;

  const [isSummary, setIsSummary] = useState(true);
  const [variants, setVariants] = useState(propVariants);
  const [targetGroup, setTargetGroup] = useState(propTargetGroup);
  const [isManualExperiment, setIsManualExperiment] = useState(
    !propIsManualExperiment
  );

  const [savePending, setSavePending] = useState(false);

  const handleOnEditSaveClick = () => {
    if (window.location.pathname.endsWith("/edit")) {
      handleSelect(1);
      return;
    }

    if (!isSummary) {
      // Call Save by updating the entire config
      setSavePending(true);
      updateCampaignAPI(
        auth,
        auth.appId,
        { configType: config.meta.config_type },
        {
          ...config,
          experiment: {
            ...config.experiment,
            variants,
            settings: {
              ...config.settings,
              targetGroup,
              isManualExperiment: !isManualExperiment,
            },
          },
        },
        config._id
      )
        .then(() => {
          setSavePending(false);
          setIsSummary(true);
        })
        .catch(() => {
          setSavePending(false);
          setIsSummary(true);
        });
    }
    setIsSummary((prev) => !prev);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} className={headerClasses.root}>
        <Typography className={headerClasses.summaryHeading}>
          A/B SETTINGS
        </Typography>
        <Button
          variant="text"
          color="primary"
          onClick={handleOnEditSaveClick}
          className={headerClasses.editButton}
        >
          {savePending ? <Loading size={24} /> : isSummary ? "Edit" : "Save"}
        </Button>
      </Grid>
      <Grid item xs={12} style={{ margin: "8px 20px" }}>
        <ColumnDiv center styles={{ gap: 5 }}>
          <RowDiv center styles={{ gap: 10 }}>
            <Typography className={headerClasses.heading}>
              To what % of target group do you to send this test?
            </Typography>
            {isSummary && (
              <Typography className={headerClasses.heading}>
                {targetGroup}%
              </Typography>
            )}
            {!isSummary && (
              <ActionsTextField
                value={targetGroup}
                style={{
                  maxWidth: 72,
                  marginBottom: 0,
                  margin: "0 6px",
                }}
                InputProps={{ min: 1 }}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  setTargetGroup(value);
                }}
                type="number"
              />
            )}
          </RowDiv>
          <ColumnDiv center>
            <VariantDistributions
              variants={variants}
              isSummary={isSummary}
              onPercentageChange={(variant, value) => {
                setVariants((prev) => ({
                  ...prev,
                  [variant]: {
                    ...prev[variant],
                    distribution: value,
                  },
                }));
              }}
            />
          </ColumnDiv>
          <FormControlLabel
            label={
              <Typography className={headerClasses.heading}>
                Automatically pick and publish the winner variant
              </Typography>
            }
            control={
              <Checkbox
                color="primary"
                checked={isManualExperiment}
                onChange={(e) => {
                  const { checked } = e.target;
                  setIsManualExperiment(checked);
                }}
                disableRipple
                disabled={isSummary}
              />
            }
          />
        </ColumnDiv>
      </Grid>
    </Grid>
  );
}
