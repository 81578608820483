import React from "react";
import { useDispatch } from "../../../../../store";
import { Grid, TextField, Typography, withStyles } from "@material-ui/core";
import CustomColorPicker from "../../../../../../../../../../ReusableComponents/CustomColorPicker";
import {
  SET_TXCFG_PLACEHOLDER_COLOR,
  SET_TXCFG_PLACEHOLDER,
  SET_TXCFG_MAX_CHARS,
  SET_TXCFG_BACKGROUND_COLOR,
  SET_TXCFG_TEXT_COLOR,
} from "../../../../../constants";
import { useCommonConfigStyles } from "../../../../../../../../walkthroughs/components/design/components/configuration/utils";
import ModuleDivider from "../../../../../../../../../../ReusableComponents/ModuleDivider";

const styles = theme => ({
  formLabel: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: "700 !important",
    marginBottom: 5,
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
  },
});

/**
 * Contains all the config params related to Text Based Question
 */
function TextBasedQuestion({ id, text_config, questionIndex }) {
  /**
   * Used to update the changes to the local store
   */
  const dispatch = useDispatch();
  const classes = useCommonConfigStyles();
  return (
    <div style={{ padding: 8 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} className={classes.gridItem}>
          <Typography
            className={classes.typographySubtitle2}
            variant="subtitle2"
          >
            TEXT COLOR
          </Typography>
          <CustomColorPicker
            color={text_config.text_color || "#000000"}
            handleColorChange={color => {
              dispatch({
                type: SET_TXCFG_TEXT_COLOR,
                id,
                value: color.hex,
                questionIndex: questionIndex,
              });
              dispatch({
                type: SET_TXCFG_PLACEHOLDER_COLOR,
                id,
                value: color.hex,
                questionIndex: questionIndex,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          <Typography
            className={classes.typographySubtitle2}
            variant="subtitle2"
          >
            PLACEHOLDER COLOR
          </Typography>
          <CustomColorPicker
            color={text_config.placeholder_text_color || "#000000"}
            handleColorChange={color => {
              dispatch({
                type: SET_TXCFG_TEXT_COLOR,
                id,
                value: color.hex,
                questionIndex: questionIndex,
              });
              dispatch({
                type: SET_TXCFG_PLACEHOLDER_COLOR,
                id,
                value: color.hex,
                questionIndex: questionIndex,
              });
            }}
          />
        </Grid>
        <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
        <Grid item xs={12} className={classes.gridItem}>
          <Typography
            className={classes.typographySubtitle2}
            variant="subtitle2"
          >
            BACKGROUND COLOR
          </Typography>
          <CustomColorPicker
            color={text_config.background_color || "#000000"}
            handleColorChange={color => {
              dispatch({
                type: SET_TXCFG_BACKGROUND_COLOR,
                id,
                value: color.hex,
                questionIndex: questionIndex,
              });
            }}
          />
        </Grid>
        <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
        <Grid item xs={12} className={classes.gridItem}>
          <Typography
            className={classes.typographySubtitle2}
            variant="subtitle2"
          >
            MAX CHARACTERS
          </Typography>
          <TextField
            value={text_config.max_characters}
            inputProps={{ min: 0 }}
            type="number"
            onChange={e => {
              const value = Number(e.target.value);
              dispatch({
                type: SET_TXCFG_MAX_CHARS,
                id,
                value: value,
                questionIndex: questionIndex,
              });
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(TextBasedQuestion);
