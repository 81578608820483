import {
  Card,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import DoneIcon from "@material-ui/icons/Done";
import Snackbar from "../../../../ReusableComponents/Snackbar";
import {
  deleteNotifiactionAPI,
  pauseNotificationAPI,
  publishNotificationAPI,
  resumeNotificationAPI,
} from "../actions";
import { PUSH_STATUS } from "../constants";
import { useAuth } from "../../../../../contexts/AuthContext";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Link } from "react-router-dom";
import SendIcon from "@material-ui/icons/Send";

const styles = theme => ({
  root: {
    width: "100%",
    minHeight: 72,
    borderRadius: 10,
    border: "1px solid #e4eaec",
    padding: "8px 16px 4px 16px",
    marginBottom: 6,
    display: "flex",
    alignItems: "center",
    boxShadow:
      "rgba(60, 64, 67, 0.1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.10) 0px 1px 1px 0px",
  },
  statusIdentifierColor: {
    color: `${theme.palette.text.primary} !important`,
    fontWeight: "bold",
  },
  statusIdentifierBgColor: {
    backgroundColor: `${theme.palette.text.primary}  !important`,
  },
});

function NotificationItem({
  notification,
  setFetching,
  classes,
  isPublishDisabled,
}) {
  const auth = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const deleteNotification = deleteNotificationId => {
    deleteNotifiactionAPI(auth, auth.appId, deleteNotificationId)
      .then(response => {
        if (response) {
          setFetching(prevState => prevState + 1);
        }
      })
      .catch(err => setSnackbarMessage("Deletion Failed"));
  };

  const pauseNotification = pauseNotificationId => {
    pauseNotificationAPI(auth, auth.appId, pauseNotificationId)
      .then(response => {
        if (response) {
          setFetching(prevState => prevState + 1);
        }
      })
      .catch(err => setSnackbarMessage("Pause Notification Failed"));
  };

  const publishNotification = publishNotificationId => {
    publishNotificationAPI(auth, auth.appId, publishNotificationId)
      .then(response => {
        if (response) {
          setFetching(prevState => prevState + 1);
        }
      })
      .catch(err => setSnackbarMessage("Publish Notification Failed"));
  };

  const resumeNotification = resumeNotificationId => {
    resumeNotificationAPI(auth, auth.appId, resumeNotificationId)
      .then(response => {
        if (response) {
          setFetching(prevState => prevState + 1);
        }
      })
      .catch(err => setSnackbarMessage("Resume Notification Failed"));
  };

  return (
    <>
      <Card className={classes.root} elevation={0}>
        <Grid
          container
          justify={"flex-start"}
          alignItems={"center"}
          style={{
            marginLeft: "5px",
          }}
        >
          <Grid item xs={3}>
            <Typography style={{ marginTop: 15 }} variant="subtitle1">
              {notification.push_config.name}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography style={{ marginTop: 15 }} variant="caption">
              {notification.push_config.payload.notification_type}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              style={{ marginTop: 10 }}
              variant="caption"
              title="Sent"
            >
              {notification.results.sent || "NA"}
              <DoneIcon style={{ width: "0.6em" }} />
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              style={{ marginTop: 10, marginLeft: 10 }}
              variant="caption"
              title="Notification Status"
            >
              {notification.status}
            </Typography>
          </Grid>
          <Grid item xs={1} onClick={e => e.stopPropagation()}>
            {notification.status === PUSH_STATUS.Draft && (
              <Tooltip
                arrow
                placement="top"
                title={
                  isPublishDisabled
                    ? "Push Config Keys are missing. Can't publish"
                    : "Publish"
                }
              >
                <span>
                  <IconButton
                    color="primary"
                    disabled={isPublishDisabled}
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      publishNotification(notification._id);
                    }}
                  >
                    <SendIcon
                      fontSize={"small"}
                      style={{
                        transform: "rotate(-45deg)",
                      }}
                    />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            <IconButton
              color="primary"
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={element => setAnchorEl(element.currentTarget)}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              {notification.status !== PUSH_STATUS.Expired && (
                <Link
                  to={{
                    pathname: `/apps/${auth.app_id}/push/${notification._id}/edit`,
                    state: {
                      config: {
                        ...notification.push_config,
                        is_valid: true,
                      },
                    },
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                    }}
                  >
                    Edit
                  </MenuItem>
                </Link>
              )}
              <MenuItem
                onClick={() => {
                  deleteNotification(notification._id);
                  setAnchorEl(null);
                }}
              >
                Delete
              </MenuItem>
              <Link
                to={{
                  pathname: `/apps/${auth.app_id}/push/${notification._id}/duplicate`,
                  state: {
                    config: {
                      ...notification.push_config,
                      name: notification.push_config.name + " (Copy)",
                      is_valid: true,
                    },
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                  }}
                >
                  Duplicate
                </MenuItem>
              </Link>
              {notification.status === PUSH_STATUS.Scheduled && (
                <MenuItem
                  onClick={() => {
                    pauseNotification(notification._id);
                    setAnchorEl(null);
                  }}
                >
                  Pause
                </MenuItem>
              )}
              {notification.status === PUSH_STATUS.Paused && (
                <MenuItem
                  onClick={() => {
                    resumeNotification(notification._id);
                    setAnchorEl(null);
                  }}
                >
                  Resume
                </MenuItem>
              )}
            </Menu>
          </Grid>
        </Grid>
      </Card>
      {snackbarMessage !== "" && (
        <Snackbar onClose={() => setSnackbarMessage("")}>
          {snackbarMessage}
        </Snackbar>
      )}
    </>
  );
}

export default withStyles(styles)(React.memo(NotificationItem));
