import React, { useState } from "react";
import ToggleSwitch from "../../../../../ReusableComponents/ToggleSwitch";
import Dialog from "../../../../../ReusableComponents/Dialog";
import Box from "../../../../../ReusableComponents/Box";
import SettingsIcon from "@material-ui/icons/Settings";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import { updateUninstallSettings } from "../actions";
import { useAuth } from "../../../../../../contexts/AuthContext";

export const UninstallSettings = ({
  appId,
  uninstallSettings,
  setUninstallSettings,
}) => {
  const [tracking] = useState(uninstallSettings?.tracking);
  const [dialog, setDialog] = useState(false);
  const [changed, setChanged] = useState(false);
  const auth = useAuth();

  const areSettingsChanged = () => {
    if (uninstallSettings?.tracking !== tracking) {
      return true;
    }
    return false;
  };

  const handleToggle = (index, item) => {
    const versions = [...uninstallSettings.versions];
    versions[index].tracking = !item.tracking;
    setUninstallSettings({
      ...uninstallSettings,
      versions,
    });
    setChanged(areSettingsChanged());
  };

  const handleMasterTrackingChange = (e) => {
    setUninstallSettings({
      ...uninstallSettings,
      tracking: !uninstallSettings.tracking,
    });
    setChanged(areSettingsChanged());
  };

  const handleSubmit = (e) => {
    setDialog(true);
  };

  const handleConfirm = (confirmed) => {
    setDialog(false);
    if (confirmed) {
      updateUninstallSettings(auth, appId, {}, uninstallSettings);
    } else {
      setChanged(false);
      setUninstallSettings({
        ...uninstallSettings,
      });
      setChanged(false);
    }
  };

  return (
    <Box
      icon={<SettingsIcon />}
      title="Uninstall Tracking"
      controls={
        <ToggleSwitch
          handleChange={handleMasterTrackingChange}
          value={"All"}
          checked={uninstallSettings.tracking || false}
        />
      }
      footer={
        changed && (
          <div>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Save Settings
            </Button>

            <Dialog
              status={dialog}
              handleConfirm={handleConfirm}
              title="This will change settings of Uninstall Tracking in your App. Are you sure ?"
              handleClose={handleConfirm}
              confirmLabel="Apply"
              dialogProps={{
                maxWidth: "md",
                fullWidth: true,
              }}
            ></Dialog>
          </div>
        )
      }
    >
      {uninstallSettings?.versions && uninstallSettings?.versions.length > 0 && (
        <List>
          {uninstallSettings.versions.map((item, index) => {
            return (
              <ListItem key={item.version + index} divider>
                <ListItemText primary={<strong>{item.version}</strong>} />
                <ListItemSecondaryAction>
                  <ToggleSwitch
                    handleChange={(e) => handleToggle(index, item)}
                    value={item.version}
                    checked={
                      (item.tracking && uninstallSettings?.tracking) || false
                    }
                    disabled={!uninstallSettings.tracking}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      )}
    </Box>
  );
};
