import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import ColumnDiv from "../../../../../../../../../ReusableComponents/ColumnDiv";
import RowDiv from "../../../../../../../../../ReusableComponents/RowDiv";
import TruncatedText from "../../../../../../../../../ReusableComponents/TruncatedText";
import {
  getEventType,
  getOperatorString,
  removeLastChar,
} from "../../../../utils";
import RenderPropertyRows from "./RenderPropertyRows";
import { useSummaryStyles } from "../../../constants";
import { isExperienceDashboard } from "../../../../../../../../../../config";
import { getDisplayText } from "../../../../context/components/NewContextBuilder/components/EventRow";

const useStyles = makeStyles((theme) => ({
  combineOp: (props) => ({
    padding: 6,
    background: theme.palette.text.full,
    color: theme.palette.secondary.main,
    width: "max-content",
    borderRadius: 3,
    marginLeft: -15,
    fontWeight: 600,
    fontSize: 13,
    lineHeight: "18px",
    letterSpacing: "0.01em",
  }),
}));

const getNoKPIEvents = (condition) => {
  return condition.details.additional_info.nkpi;
};

const getTime = (condition) => {
  return condition.details.additional_info.time;
};

const RenderCondition = ({ dontTruncate, condition, index }) => {
  const classes = useStyles();
  const headerClasses = useSummaryStyles();

  const {
    combine_operator,
    context_rule_type,
    sequence,
    type = "did",
    count_config: { operator, count },
    details: { name = "", additional_info },
    trigger: {
      details: { name: triggerName, additional_info: triggerAdditionalInfo },
    },
  } = condition;

  if (type === "did" && (!name || name === "")) {
    return <></>;
  }

  return (
    <ColumnDiv>
      {index !== 0 && (
        <Typography variant="subtitle2" className={classes.combineOp}>
          {sequence > 0 ? "AND THEN" : combine_operator}
        </Typography>
      )}
      <RowDiv center>
        <div style={{ width: 30, borderBottom: "1px solid #C5CDD2" }}></div>
        <RowDiv center applyMargin>
          <Typography className={headerClasses.body}>User who</Typography>
          <TruncatedText disabled value={type} />
          <Typography className={headerClasses.body}>perform</Typography>
          {type === "did" && (
            <>
              <TruncatedText
                disabled
                dontTruncate={isExperienceDashboard() ? true : dontTruncate}
                tooltipTitle={
                  removeLastChar(getEventType(context_rule_type)) + ": " + name
                }
                value={
                  isExperienceDashboard()
                    ? getDisplayText(name)
                    : removeLastChar(getEventType(context_rule_type)) +
                      ": " +
                      name
                }
              />
              <TruncatedText disabled value={getOperatorString(operator)} />
              <TruncatedText disabled value={count} />
              <Typography className={headerClasses.body}>times</Typography>
            </>
          )}
          {type !== "did" && (
            <>
              <TruncatedText
                dontTruncate={isExperienceDashboard() ? true : dontTruncate}
                disabled
                placeholder="No Events"
                tooltipTitle={getNoKPIEvents(condition).join(", ")}
                value={
                  !isExperienceDashboard()
                    ? getNoKPIEvents(condition).join(", ")
                    : getNoKPIEvents(condition)
                        .map((val) => getDisplayText(val))
                        .join(", ")
                }
              />
              <Typography className={headerClasses.body}>within</Typography>
              <TruncatedText disabled value={getTime(condition) / 1000} />
              <Typography className={headerClasses.body}>second(s)</Typography>
            </>
          )}
        </RowDiv>
      </RowDiv>
      <RowDiv center styles={{ marginLeft: 40 }}>
        {type === "did" && Object.keys(additional_info).length > 0 && (
          <RenderPropertyRows info={additional_info} />
        )}
      </RowDiv>
      {type !== "did" && (
        <ColumnDiv styles={{ marginLeft: 40, borderLeft: "1px solid #C5CDD2" }}>
          <RowDiv center>
            <div style={{ width: 30, borderBottom: "1px solid #C5CDD2" }}></div>
            <RowDiv center applyMargin>
              <Typography className={headerClasses.body}>after</Typography>
              <TruncatedText disabled value={triggerName} />
            </RowDiv>
          </RowDiv>
          <RowDiv center styles={{ marginLeft: 40 }}>
            <RenderPropertyRows info={triggerAdditionalInfo} />
          </RowDiv>
        </ColumnDiv>
      )}
    </ColumnDiv>
  );
};

export default function TriggerSummary({ conditions, dontTruncate = false }) {
  const headerClasses = useSummaryStyles();

  return (
    <ColumnDiv
      styles={{
        marginBottom: 13,
      }}
    >
      {conditions.length < 1 && (
        <Typography className={headerClasses.body}>Not Configured</Typography>
      )}
      {conditions.length > 0 && (
        <Typography className={headerClasses.heading}>Events</Typography>
      )}
      <ColumnDiv
        styles={{
          borderLeft: "1px solid #C5CDD2",
          marginTop: 10,
          marginLeft: 15,
        }}
      >
        {conditions.length > 0 &&
          conditions.map((condition, index) => (
            <RenderCondition
              dontTruncate={dontTruncate}
              key={index}
              condition={condition}
              index={index}
            />
          ))}
      </ColumnDiv>
    </ColumnDiv>
  );
}
