import React, { useMemo } from "react";
import { APP_PLATFORMS } from "../../../../../../../../../constants";
import { COLOR_STYLE_ENUM } from "../../../../../../../../ReusableComponents/CustomColorPicker";
import {
  ACTION_CLASSES,
  INAPP_LAYOUT_TYPES,
  MODULE_TYPES,
  SET_BUTTON_BORDER_RADIUS,
  SET_DYNAMIC_TEXT_CONFIG,
  SET_HTML_ACTION_CONFIG,
  TOOLTIP_LAYOUT_TYPES,
} from "../../../constants";
import { useDispatch } from "../../../store";
import BackgroundToolbar from "../../common/BackgroundToolbar";
import Base64ImageUploadToolbar from "../../common/Base64ImageUploadToolbar";
import ImageURLToolbar from "../../common/ImageURLToolbar";
import TextConfigToolbar from "../../common/TextConfigToolbar";
import VideoInAppColorsToolbar from "../../common/VideoInAppColorsToolbar";
import { getDataForModuleType } from "../utils";
import MaterialPopper from "./MaterialPopper";

export default function ConfigToolbars({
  currentStep,
  moduleType,
  platform,
  target,
  ui,
}) {
  const dispatch = useDispatch();
  const { layout_type, action_class } = ui;
  const {
    config,
    keyName,
    actions,
    buttonConfig = null,
  } = getDataForModuleType(ui, moduleType);

  const showDynamicText = useMemo(() => {
    const showOnlyForTitleAndDesc =
      moduleType === MODULE_TYPES.TITLE ||
      moduleType === MODULE_TYPES.DESCRIPTION;

    if (platform === APP_PLATFORMS.web) {
      return false;
    } else {
          const showOnlyForTooltipAndCoachmark =
      action_class === ACTION_CLASSES.TOOLTIP ||
      action_class === ACTION_CLASSES.COACHMARK;
    return platform === APP_PLATFORMS.ios
      ? showOnlyForTooltipAndCoachmark && showOnlyForTitleAndDesc
      : showOnlyForTitleAndDesc;
    }
  }, [action_class, moduleType, platform]);

  return (
    <>
      {config &&
        (moduleType === MODULE_TYPES.TITLE ||
          moduleType === MODULE_TYPES.DESCRIPTION ||
          moduleType === MODULE_TYPES.BUTTON_TEXT1 ||
          moduleType === MODULE_TYPES.BUTTON_TEXT2 ||
          moduleType === MODULE_TYPES.ACTION_BUTTON_1_TEXT ||
          moduleType === MODULE_TYPES.ACTION_BUTTON_1_DESC ||
          moduleType === MODULE_TYPES.ACTION_BUTTON_2_TEXT ||
          moduleType === MODULE_TYPES.ACTION_BUTTON_2_DESC ||
          moduleType === MODULE_TYPES.ACTION_BUTTON_3_TEXT ||
          moduleType === MODULE_TYPES.ACTION_BUTTON_3_DESC) && (
          <MaterialPopper
            target={target}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 10,
            }}
            placement={
              moduleType !== MODULE_TYPES.BUTTON_TEXT1 &&
              moduleType !== MODULE_TYPES.BUTTON_TEXT2
                ? "top"
                : "bottom"
            }
          >
            <TextConfigToolbar
              platform={platform}
              text={config.text}
              fontFamily={config.font_family}
              fontSize={config.size}
              fontStyle={config.style}
              color={config.color}
              moduleType={moduleType}
              showDynamicText={showDynamicText}
              action_class={action_class}
              dynamicType={config?.d_type}
              script={config?.script}
              onFontFamilyChange={value =>
                dispatch({
                  type: actions.SET_FONT_FAMILY,
                  currentStep,
                  keyName,
                  value: value,
                })
              }
              onFontSizeChange={value =>
                dispatch({
                  type: actions.SET_FONT_SIZE,
                  currentStep,
                  keyName,
                  value: value,
                })
              }
              onFontStyleChange={value =>
                dispatch({
                  type: actions.SET_FONT_STYLE,
                  currentStep,
                  keyName,
                  value: value,
                })
              }
              onColorChange={selectedColor =>
                dispatch({
                  type: actions.SET_COLOR,
                  currentStep,
                  keyName,
                  value: selectedColor.hex,
                })
              }
              onScriptDialogClose={(
                tab,
                type,
                commands,
                originalScript,
                variableMap
              ) =>
                dispatch({
                  type: SET_DYNAMIC_TEXT_CONFIG,
                  currentStep,
                  d_type: type,
                  data: {
                    tab,
                    commands,
                    originalScript,
                    variableMap,
                  },
                  keyName,
                  value: type === "s" ? "Script" : "Text",
                })
              }
              onTextChange={value => {
                dispatch({
                  type: actions.SET_TEXT,
                  currentStep,
                  keyName,
                  value: value,
                });
              }}
            />
          </MaterialPopper>
        )}
      {buttonConfig &&
        (moduleType === MODULE_TYPES.BACKGROUND ||
          moduleType === MODULE_TYPES.BUTTON1 ||
          moduleType === MODULE_TYPES.BUTTON2 ||
          moduleType === MODULE_TYPES.ACTION_BUTTON1 ||
          moduleType === MODULE_TYPES.ACTION_BUTTON2 ||
          moduleType === MODULE_TYPES.ACTION_BUTTON3) && (
          <>
            {layout_type === INAPP_LAYOUT_TYPES.VIDEOS &&
            moduleType === MODULE_TYPES.BACKGROUND ? (
              <MaterialPopper target={target} placement="bottom">
                <VideoInAppColorsToolbar
                  color={
                    (buttonConfig.gradient_config &&
                      buttonConfig.gradient_config.palette) ||
                    buttonConfig.color
                  }
                  onColorChange={(color, colorStyle) => {
                    dispatch({
                      type: actions.SET_BG_COLOR,
                      currentStep,
                      keyName,
                      value:
                        colorStyle === COLOR_STYLE_ENUM.GRADIENT
                          ? color
                          : color.hex,
                    });
                  }}
                />
              </MaterialPopper>
            ) : (
              <MaterialPopper
                target={target}
                placement={
                  layout_type === INAPP_LAYOUT_TYPES.FOOTER ? "top" : "bottom"
                }
              >
                <BackgroundToolbar
                  color={
                    (buttonConfig.gradient_config &&
                      buttonConfig.gradient_config.palette) ||
                    buttonConfig.color
                  }
                  isButton={
                    layout_type !== TOOLTIP_LAYOUT_TYPES.ACTION_BUTTONS &&
                    moduleType !== MODULE_TYPES.BACKGROUND
                  }
                  onColorChange={(color, colorStyle) => {
                    dispatch({
                      type: actions.SET_BG_COLOR,
                      currentStep,
                      keyName: buttonConfig.__id,
                      value:
                        colorStyle === COLOR_STYLE_ENUM.GRADIENT
                          ? color
                          : color.hex,
                    });
                  }}
                  borderRadius={
                    (moduleType === MODULE_TYPES.BACKGROUND
                      ? buttonConfig?.border_radius
                      : buttonConfig?.borders?.radius) ??
                    buttonConfig?.border_radius
                  }
                  onBorderChange={(value, isButton) => {
                    if (isButton) {
                      dispatch({
                        type: SET_BUTTON_BORDER_RADIUS,
                        currentStep,
                        keyName,
                        value: value,
                      });
                    } else
                      dispatch({
                        type: actions.SET_BORDER_RADIUS,
                        currentStep,
                        value: value,
                      });
                  }}
                />
              </MaterialPopper>
            )}
          </>
        )}
      {(moduleType === MODULE_TYPES.IMAGE ||
        (buttonConfig &&
          (moduleType === MODULE_TYPES.ACTION_BUTTON_1_IMAGE ||
            moduleType === MODULE_TYPES.ACTION_BUTTON_2_IMAGE ||
            moduleType === MODULE_TYPES.ACTION_BUTTON_3_IMAGE))) && (
        <MaterialPopper target={target}>
          <Base64ImageUploadToolbar
            onImageUpload={data =>
              dispatch({
                type: actions.SET_IMAGE,
                keyName: buttonConfig?.__id,
                value: data,
              })
            }
          />
        </MaterialPopper>
      )}
      {(moduleType === MODULE_TYPES.IMAGE_URL ||
        moduleType === MODULE_TYPES.BG_IMAGE_URL) && (
        <MaterialPopper target={target}>
          <ImageURLToolbar
            moduleType={moduleType}
            url={
              moduleType === MODULE_TYPES.BG_IMAGE_URL
                ? config?.bg_image
                : config?.image
            }
            onURLchange={data =>
              dispatch({
                type:
                  moduleType === MODULE_TYPES.BG_IMAGE_URL
                    ? actions.SET_BG_IMAGE
                    : actions.SET_IMAGE,
                value: data.url,
              })
            }
            isBgImage={
              layout_type !== INAPP_LAYOUT_TYPES.HEADER &&
              layout_type !== INAPP_LAYOUT_TYPES.FOOTER
            }
            bgImageSize={config?.image_size || "Cover"}
            onBgImageSizeChange={size => {
              dispatch({
                type: actions.SET_BG_IMAGE_SIZE,
                currentStep,
                value: size,
              });
            }}
            actionConfig={config?.action_config}
            onActionChange={config => {
              dispatch({
                type: SET_HTML_ACTION_CONFIG,
                currentStep,
                value: config,
              });
            }}
          />
        </MaterialPopper>
      )}
      {moduleType === MODULE_TYPES.VIDEO_BUTTON && (
        <MaterialPopper target={target} placement="bottom">
          <VideoInAppColorsToolbar
            color={
              config?.x_icon_color ||
              config?.sound_icon_color ||
              config?.mini_color
            }
            onColorChange={color => {
              dispatch({
                type: actions.SET_VIDEO_BUTTON_COLOR,
                currentStep,
                keyName,
                value: color.hex,
              });
            }}
          />
        </MaterialPopper>
      )}
    </>
  );

}