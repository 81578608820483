import React, { useMemo } from "react";
import CampaignCreator from "../../common/components/campaigncreator";
import CampaignsHolder from "../../common/components/campaignsholder";
import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";
import DesignPage from "./components/design";
import SurveyResults from "./components/analysis";
import Error404 from "../../../Error/404";
import DesignSummary from "./components/design/components/DesignSummary";

const CONFIG_TYPE = ["survey"];

export default function Surveys({ app }) {
  const { path } = useRouteMatch();
  const location = useLocation();
  const state = useMemo(
    () => (location && location.state ? location.state : null),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <section className="content">
      <Switch>
        <Route exact path={path}>
          <CampaignsHolder
            title={"Surveys"}
            app={app}
            actionType={"survey"}
            configType={CONFIG_TYPE}
            designSummaryComponent={<DesignSummary />}
          />
        </Route>
        <Route exact path={`${path}/new`}>
          <CampaignCreator
            app={app}
            actionType={"surveys"}
            configType={CONFIG_TYPE}
            designOrConfigureComponent={<DesignPage />}
            designOrConfigureSummaryComponent={<DesignSummary />}
          />
        </Route>
        <Route exact path={`${path}/:campaignId/edit`}>
          <CampaignCreator
            app={app}
            actionType={"surveys"}
            configType={CONFIG_TYPE}
            designOrConfigureComponent={<DesignPage />}
            designOrConfigureSummaryComponent={<DesignSummary />}
          />
        </Route>
        <Route exact path={`${path}/:campaignId/analysis`}>
          <SurveyResults app={app} config={state ? state.config : null} />
        </Route>
        <Route path="*" component={Error404} />
      </Switch>
    </section>
  );
}
