import React, { useState } from "react";
import Box from "../../../../ReusableComponents/Box";
import { Grid } from "@material-ui/core";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import MultiUtilityChart from "../../../../ReusableComponents/MultiUtilityChart";
import { useAuth } from "../../../../../contexts/AuthContext";
import {
  getUninstallsAPI,
  getDay0UninstallsAPI,
  getTotalUserTrendsAPI,
  getDay0UserTrendsAPI,
} from "../actions";
import { useDeepCompareEffect } from "../../../../../utils/use-deep-compare";

function mergeTimeSeries(all, newTrend, trendName) {
  if (!Array.isArray(all) || !Array.isArray(newTrend)) return [];
  if (all.length === 0 || all.length !== newTrend.length)
    return newTrend.map((o) => ({ date: o.key, [trendName]: o.value }));
  return all.map((item, index) => {
    item[trendName] = newTrend[index].value;
    return item;
  });
}

export default function UninstallTrends({ appId, queryParams, filters }) {
  const auth = useAuth();

  const [trends, setTrends] = useState([]);
  const [pending, setPending] = useState(false);

  useDeepCompareEffect(() => {
    const dayUninstalls = getUninstallsAPI(auth, appId, queryParams, filters);
    const day0Uninstalls = getDay0UninstallsAPI(
      auth,
      appId,
      queryParams,
      filters
    );
    const userTrends = getTotalUserTrendsAPI(auth, appId, queryParams, filters);
    const day0UserTrends = getDay0UserTrendsAPI(
      auth,
      appId,
      queryParams,
      filters
    );
    setPending(true);
    Promise.all([dayUninstalls, day0Uninstalls, userTrends, day0UserTrends])
      .then((values) => {
        let updated = mergeTimeSeries(trends, values[0], "Uninstalls");
        updated = mergeTimeSeries(updated, values[1], "Day 0 Uninstalls");
        updated = mergeTimeSeries(updated, values[2], "Active Users");
        updated = mergeTimeSeries(updated, values[3], "New Installs");
        setTrends(updated);
        setPending(false);
      })
      .catch(() => {
        setPending(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, appId, queryParams, filters]);

  return (
    <Box title="Uninstall Trends" icon={<ShowChartIcon />} withPadding>
      <Grid container>
        <Grid item xs={12} md={6}>
          <MultiUtilityChart
            data={trends}
            areaDataKeys={["Active Users", "Uninstalls"]}
            syncId="uninstalls"
            fetching={pending}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MultiUtilityChart
            data={trends}
            areaDataKeys={["New Installs", "Day 0 Uninstalls"]}
            syncId="uninstalls"
            fetching={pending}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
