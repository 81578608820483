import { makePostRequest } from "../../../common/actions";

const UNINSTALLS_API = "day-uninstall-trends";
const USER_TRENDS_API = "user-trends";
const UNINSTALL_USER_DISTRIBUTION_API = "uninstall/count";

export function getUninstallDistributionAPI(auth, appId, queryParams, filters) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    UNINSTALL_USER_DISTRIBUTION_API,
    auth,
    appId,
    queryParams,
    filters,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getUninstallsAPI(auth, appId, queryParams, filters) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    UNINSTALLS_API,
    auth,
    appId,
    queryParams,
    filters,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getDay0UninstallsAPI(auth, appId, queryParams, filters) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    UNINSTALLS_API,
    auth,
    appId,
    { ...queryParams, day: 0 },
    filters,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getTotalUserTrendsAPI(auth, appId, queryParams, filters) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    USER_TRENDS_API,
    auth,
    appId,
    queryParams,
    filters,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getDay0UserTrendsAPI(auth, appId, queryParams, filters) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    USER_TRENDS_API,
    auth,
    appId,
    { ...queryParams, day: 0 },
    filters,
    onSuccessCallback,
    onFailureCallback
  );
}