import React from "react";
import SessionsList from "./sessionsList";
import Box from "../../../../../ReusableComponents/Box";
import { useTrackedState } from "../../store";

export const SessionsTable = ({ onSessionClick }) => {
  const state = useTrackedState();
  const { sessions } = state;
  const { session_list, meta_info } = sessions;
  return (
    <Box
      withPadding
      title={`${(session_list && session_list.length) || 0} of ${
        meta_info.total_sessions
      } Sessions`}
    >
      <SessionsList
        onSessionClick={onSessionClick}
        data={sessions}
      />
    </Box>
  );
};
