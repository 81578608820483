export const DEFAULT_DID_DID_NOT_OPTIONS = [
  {
    label: "did",
    value: "did",
  },
  {
    label: "didn't",
    value: "didn't",
  },
];

export const WEB_DID_DID_NOT_OPTIONS = [
  {
    label: "did",
    value: "did",
  },
];

export const EQUALITY_OPERATOR_OPTIONS = [
  {
    label: "==",
    value: "EQ",
  },
  {
    label: "!=",
    value: "NEQ",
  },
];

export const NUMBER_OPERATOR_OPTIONS = [
  {
    label: ">",
    value: "GT",
  },
  {
    label: ">=",
    value: "GTE",
  },
  {
    label: "<",
    value: "LT",
  },
  {
    label: "<=",
    value: "LTE",
  },
  ...EQUALITY_OPERATOR_OPTIONS,
];

export const DEFAULT_OPERATOR_OPTIONS = [...NUMBER_OPERATOR_OPTIONS];

export const STRING_OPERATOR_OPTIONS = [
  ...EQUALITY_OPERATOR_OPTIONS,
  { label: "RegEx", value: "R" },
];

export const DATA_TYPES = {
  STRING: "String",
  BOOLEAN: "Boolean",
  DECIMAL: "Decimal",
  INTEGER: "Integer",
}

export const DEFAULT_DATATYPE_OPTIONS = [
  {
    label: DATA_TYPES.STRING,
    value: "s",
  },
  // {
  //   label: "Boolean",
  //   value: "s",
  // },
  {
    label: DATA_TYPES.DECIMAL,
    value: "f",
  },
  {
    label: DATA_TYPES.INTEGER,
    value: "l",
  },
];

export const DEFAULT_COMBINE_OPERATOR_OPTIONS = [
  {
    label: "OR",
    value: "OR",
    enabled: false,
  },
  {
    label: "AND",
    value: "AND",
    enabled: false,
  },
  {
    label: "AND THEN",
    value: "AND_THEN",
    enabled: true,
  },

];