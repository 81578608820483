import {
  Button,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftSharpIcon from "@material-ui/icons/ChevronLeftSharp";
import ChevronRightSharpIcon from "@material-ui/icons/ChevronRightSharp";
import Apxor from "apxor";
import React, { useEffect, useMemo, useState } from "react";
import { APXOR_SDK_CATEGORY_CONSTANTS } from "../../../../../../../../../constants/Endpoints";
import RowDiv from "../../../../../../../../ReusableComponents/RowDiv";
import {
  ANSWER_MODES,
  ENABLE_DIALOG,
  QUESTION_TYPES,
  SET_NEXT_QUESTION_JSON,
  SET_SURVEY_ORIENTATION,
  SHOW_SUCCESS_MESSAGE,
  SURVEY_ORIENTATION,
} from "../../../constants";
import { useTracked } from "../../../store";

export const ORIENTATION_CONFIG = [
  {
    label: "Footer",
    value: SURVEY_ORIENTATION.FOOTER,
  },
  {
    label: "Popup",
    value: SURVEY_ORIENTATION.POPUP,
  },
  {
    label: "Full Screen",
    value: SURVEY_ORIENTATION.FULL_SCREEN,
  },
];

const useStyles = makeStyles((theme) => ({
  tabsIndicator: {
    height: 3,
    borderRadius: 0,
  },
  tabRoot: {
    textTransform: "initial",
    minWidth: 72,
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "20px",
    letterSpacing: "0.01em",
    marginRight: theme.spacing(4),
    "&:focus": {
      // color: '#40a9ff',
    },
  },
}));

const validateRules = (action, enable_dialog, show_success_message) => {
  if (action === ANSWER_MODES.request_survey) return enable_dialog;
  else if (action === ANSWER_MODES.success_message) return show_success_message;
  else return false;
};

function CarouselCard({
  image,
  dispatch,
  currentOption,
  currentIndex,
  currentOrientationConfig = [],
  action,
  handleDone,
  enable_dialog,
  show_success_message,
  requestOrientation,
  orientation,
  currentOrientation,
  experience_type,
}) {
  return (
    <Grid
      container
      style={{
        textAlign: "center",
      }}
    >
      <Grid item md={12}>
        <img
          style={{ width: "190px", height: "380px" }}
          src={image}
          alt="Apxor"
        />
      </Grid>

      <Grid item md={12}>
        <Button
          color="primary"
          variant="contained"
          disabled={validateRules(action, enable_dialog, show_success_message)}
          style={{
            height: 44,
            width: 188,
            borderRadius: 3,
            marginTop: 30,
          }}
          onClick={() => {
            if (action === ANSWER_MODES.request_survey) {
              dispatch({
                type: ENABLE_DIALOG,
                value: true,
                orientation: requestOrientation,
              });
            } else if (action === ANSWER_MODES.success_message) {
              dispatch({
                type: SHOW_SUCCESS_MESSAGE,
                value: true,
              });
            } else {
              dispatch({
                type: SET_SURVEY_ORIENTATION,
                value: orientation || currentOrientation,
              });
            }
            dispatch({
              type: SET_NEXT_QUESTION_JSON,
              value: currentOption.data,
              answerMode: currentOption.data.answer_mode,
            });
            Apxor.logEvent(
              "SurveyDesignSelected",
              { "template": action, "experience_type": experience_type },
              APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS,
            );
            handleDone();
          }}
        >
          <Typography
            color="secondary"
            style={{ fontWeight: 700, fontSize: "14px" }}
          >
            Select
          </Typography>
        </Button>
        {validateRules(action, enable_dialog, show_success_message) && (
          <Typography
            style={{
              fontWeight: 700,
              fontSize: "16px",
              color: "#677A87",
              opacity: 0.5,
              marginTOp: 10,
            }}
          >
            Already added this design
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

const getImage = (action) => {
  if (action === ANSWER_MODES.request_survey) return SURVEY_ORIENTATION.FOOTER;
  else if (action === ANSWER_MODES.success_message)
    return SURVEY_ORIENTATION.FULL_SCREEN;
  else return SURVEY_ORIENTATION.POPUP;
};

const getOptionFromIndex = (index) => {
  switch (index) {
    case 0:
      return SURVEY_ORIENTATION.FOOTER;
    case 1:
      return SURVEY_ORIENTATION.POPUP;
    default:
      return SURVEY_ORIENTATION.FULL_SCREEN;
  }
};

const getIndexFromOption = (index) => {
  switch (index) {
    case SURVEY_ORIENTATION.FOOTER:
      return 0;
    case SURVEY_ORIENTATION.POPUP:
      return 1;
    default:
      return 2;
  }
};

const getdefaultOrientation = (action, orientation, requestOrientation) => {
  if (action === ANSWER_MODES.request_survey) {
    return requestOrientation || ANSWER_MODES.FOOTER;
  } else return orientation || ANSWER_MODES.FOOTER;
};

const Carousel = ({
  action,
  handleDone,
  setTemplateSelected,
  experience_type,
}) => {
  const [state, dispatch] = useTracked();
  const {
    ui: {
      layout_type = "",
      show_success_message,
      enable_dialog,
      dialog_config,
    },
  } = state;
  const { dialog_orientation = "" } = dialog_config || {};
  const [orientation, setOrientation] = useState(layout_type);
  const [requestOrientation, setRequestOrientation] = useState(
    dialog_orientation,
  );

  const [currentOrientationConfig, setCurrentOrientationConfig] = useState(
    ORIENTATION_CONFIG,
  );
  const defaultQuestions = useMemo(() => QUESTION_TYPES(), []);
  const currentOption = defaultQuestions[action];

  const [currentIndex, setCurrentIndex] = useState(
    getIndexFromOption(
      getdefaultOrientation(action, orientation, requestOrientation),
    ),
  );

  const classes = useStyles();

  useEffect(() => {
    if (action === ANSWER_MODES.request_survey) {
      let configToCompare =
        requestOrientation || SURVEY_ORIENTATION.FULL_SCREEN;
      let comparatorFunction = (function () {
        return requestOrientation
          ? (x) => x.value === configToCompare
          : (x) => x.value !== configToCompare;
      })();
      setCurrentOrientationConfig(
        ORIENTATION_CONFIG.filter(comparatorFunction),
      );
      setCurrentIndex(
        requestOrientation ? getIndexFromOption(configToCompare) : 0,
      );
    } else if (action === ANSWER_MODES.success_message) {
      setCurrentOrientationConfig(
        ORIENTATION_CONFIG.filter(
          (eachConfig) => eachConfig.value === SURVEY_ORIENTATION.FULL_SCREEN,
        ),
      );
      setCurrentIndex(2);
    } else if (orientation) {
      setCurrentOrientationConfig(
        ORIENTATION_CONFIG.filter(
          (eachConfig) => eachConfig.value === orientation,
        ),
      );
      setCurrentIndex(orientation ? getIndexFromOption(orientation) : 0);
    }
  }, [action]);

  useEffect(() => {
    action === ANSWER_MODES.request_survey
      ? setRequestOrientation(getOptionFromIndex(currentIndex))
      : setOrientation(getOptionFromIndex(currentIndex));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <>
      <RowDiv center styles={{ justifyContent: "center" }}>
        <Tabs
          classes={{
            root: classes.tabsRoot,
            indicator: classes.tabsIndicator,
          }}
          value={currentIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, value) => {
            setCurrentIndex(
              action === ANSWER_MODES.success_message ? 2 : value,
            );
          }}
        >
          {currentOrientationConfig.map((config) => (
            <Tab
              key={config.value}
              label={config.label}
              classes={{ root: classes.tabRoot }}
              disableRipple
            />
          ))}
        </Tabs>
      </RowDiv>
      <Grid
        container
        style={{
          justifyContent: "center",
          alignItems: "Center",
          marginTop: 20,
        }}
      >
        <Grid
          item
          md={3}
          style={{ display: "flex", justifyContent: "center", marginTop: 60 }}
        >
          {currentOrientationConfig.length > 1 && currentIndex !== 0 && (
            <IconButton onClick={() => setCurrentIndex(currentIndex - 1)}>
              <ChevronLeftSharpIcon color="action" />
            </IconButton>
          )}
        </Grid>
        <Grid item md={6} style={{ width: 190, height: 380 }}>
          <CarouselCard
            title={currentOption.display_text}
            image={
              (action === ANSWER_MODES.request_survey
                ? currentOption.image[requestOrientation]
                : currentOption.image[orientation]) ||
              currentOption.image[getImage(action)]
            }
            currentOption={currentOption}
            dispatch={dispatch}
            action={action}
            handleDone={handleDone}
            setTemplateSelected={setTemplateSelected}
            currentIndex={currentIndex}
            currentOrientationConfig={currentOrientationConfig}
            show_success_message={show_success_message}
            enable_dialog={enable_dialog}
            requestOrientation={requestOrientation}
            orientation={orientation}
            currentOrientation={getOptionFromIndex(currentIndex)}
            experience_type={experience_type}
          />
        </Grid>
        <Grid
          item
          md={3}
          style={{ display: "flex", justifyContent: "center", marginTop: 60 }}
        >
          {currentOrientationConfig.length > 1 &&
            currentIndex + 1 !== currentOrientationConfig.length && (
              <IconButton onClick={() => setCurrentIndex(currentIndex + 1)}>
                <ChevronRightSharpIcon color="action" />
              </IconButton>
            )}
        </Grid>
      </Grid>
    </>
  );
};

export default Carousel;
