import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Grid, Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import NaturePeopleIcon from "@material-ui/icons/NaturePeople";
import ToolTip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import { useDateFilters } from "../../../../common/utils";
import Placeholder from "../../../../../ReusableComponents/Placeholder";
import { useAuth } from "../../../../../../contexts/AuthContext";
import SegmentHolder from "./components/SegmentHolder";
import { getSegmentsList, getCohorts } from "../../actions";
import { useTrackedState, Provider } from "./store";
import Snackbar from "../../../../../ReusableComponents/Snackbar";
import Loading from "../../../../../ReusableComponents/Loading";
import Apxor from "apxor";
import { APXOR_SDK_CATEGORY_CONSTANTS } from "../../../../../../constants";

function SegmentsHolderWithProvider({ app }) {
  const auth = useAuth();
  const dateFilters = useDateFilters();

  const trackedState = useTrackedState();

  const { app_id: appId } = app;

  const [segments, setSegments] = useState([]);
  const [filteredSegments, setFilteredSegments] = useState([]);
  const [query, setQuery] = useState("");
  const [cursor, setCursor] = useState(20);
  const [segmentsPending, setSegmentsPending] = useState(true);
  const [segmentsFailed, setSegmentsFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [segmentDeleted, setSegmentDeleted] = useState(false);
  const [cohorts, setCohorts] = useState([]);

  /**
   * Doing this to show the chohort label,
   * find a better way to deal with this via design
   */
  useEffect(() => {
    getCohorts(auth, appId).then((response) => {
      setCohorts(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackedState.fetchCohorts]);

  /**
   * Fetch segments whenever the `fetchSegments` state gets changed
   */
  useEffect(() => {
    setErrorMessage(null);
    setSegmentsPending(true);
    getSegmentsList(auth, appId, dateFilters)
      .then((response) => {
        setSegments(response);
        setFilteredSegments(response);
        setSegmentsPending(false);
        setSegmentsFailed(false);
      })
      .catch((err) => {
        setErrorMessage("Failed to fetch segments. Try again after sometime");
        setSegmentsFailed(true);
        setSegmentsPending(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackedState.fetchSegments, trackedState.fetchCohorts]);

  /**
   * Filter the data based on the selected tab whenver the query is changed
   */
  useEffect(() => {
    setFilteredSegments(
      segments.filter((segment) => segment.name.includes(query))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  function isCurrentCohort(_id, cohorts_list) {
    let currentCohort = null;
    cohorts_list.forEach((cohort, idx) => {
      if (cohort.segment_id === _id) currentCohort = cohorts_list[idx];
    });
    return currentCohort;
  }

  return (
    <>
      {!segmentsPending && !segmentsFailed && (
        <Grid container justify="center" spacing={1}>
          <Grid item xs={12} md={8}>
            <Grid container justify="space-between" spacing={1}>
              <Grid item xs={12} sm={3} style={{ marginBottom: 12 }}>
                <Link
                  to={`/apps/${appId}/segments/new`}
                  style={{ spacing: 10 }}
                >
                  <Button
                    size={"small"}
                    variant={"contained"}
                    color={"primary"}
                    onClick={() =>
                      Apxor.logEvent(
                        "CreateSegmentClicked",
                        {},
                        APXOR_SDK_CATEGORY_CONSTANTS.SEGMENTS
                      )
                    }
                  >
                    <ToolTip
                      id="tooltip-fab-add"
                      title="Create New Segment"
                      placement="bottom"
                    >
                      <Fab
                        size="small"
                        color="primary"
                        aria-label="Create New Segment"
                      >
                        <AddIcon />
                      </Fab>
                    </ToolTip>
                    Create
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} sm={3} style={{ marginBottom: 12 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <TextField
                    placeholder="Search Segments"
                    id="query-adornment"
                    fullWidth
                    value={query}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ padding: "20px 0" }}
                        >
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => setQuery(e.target.value)}
                    style={{ marginRight: 8 }}
                  />
                </div>
              </Grid>
            </Grid>

            {filteredSegments.map(
              (segment, index) =>
                index < cursor && (
                  <SegmentHolder
                    app={app}
                    appId={appId}
                    index={index + 1}
                    key={segment._id}
                    {...segment}
                    currentCohort={isCurrentCohort(segment._id, cohorts)}
                    setSegmentDeleted={setSegmentDeleted}
                  />
                )
            )}

            {filteredSegments.length > 20 && (
              <Button
                disabled={cursor >= filteredSegments.length}
                size="small"
                style={{ margin: "10px auto", display: "block" }}
                color="primary"
                onClick={() => {
                  setCursor(cursor + 20);
                }}
              >
                {cursor >= filteredSegments.length
                  ? "You are all caught up!!"
                  : "Load more..."}
              </Button>
            )}

            {!segmentsPending &&
              (!Array.isArray(segments) || segments.length === 0) && (
                <Placeholder
                  withIcon
                  icon={
                    <NaturePeopleIcon
                      style={{ opacity: 0.2, width: 150, height: 150 }}
                    />
                  }
                  text="To create your first segment, tap the plus on the top right of the dashboard."
                />
              )}
          </Grid>
        </Grid>
      )}
      {segmentsPending && (
        <div style={{ position: "fixed", top: "50%", left: "50%" }}>
          <Loading size={36} />
        </div>
      )}
      {segmentDeleted && (
        <Snackbar open={segmentDeleted} autoHideDuration={2500}>
          Segment Deleted Successfully!
        </Snackbar>
      )}

      {!segmentsPending && segmentsFailed && errorMessage && (
        <Snackbar>
          <strong>{errorMessage}</strong>
        </Snackbar>
      )}
    </>
  );
}

export default function SegmentsHolder({ app }) {
  return (
    <Provider>
      <SegmentsHolderWithProvider app={app} />
    </Provider>
  );
}


