import React, { useState, useEffect, useCallback, useReducer } from "react";
import moment from "moment";
import { useAuth } from "../../../../contexts/AuthContext";
import { Grid, Typography } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import CampaignMetricsCard from "./components/CampaignMetricsCard";
import UserStatistics from "./components/UsersStatistics";
import CreateCampaign from "./components/CreateCampaignHolder";
import CleanSlate from "./components/CleanSlate";
import Graphs from "./components/Graphs";
import { getCampaignsAPI } from "./actions";

import graphs from "./images/graphs.svg";
import { makeStyles } from "@material-ui/core/styles";
import {
  getEventCountAPI,
  countAPI,
  getTimeSpentAPI,
} from "../eventanalysis/actions";
import { getRouteForActionType } from "../../common/components/campaigncreator/utils";
import { isDemoApplicaton, isExperienceDashboard } from "../../../../config";
import TourTypeSelectionSection from "./components/sales-sandbox/TourTypeSelectionSection";
import { getCampaignsAPI as getCampaignsMetaAPI } from "../../common/actions";
import { CAMPAIGNS_HOLDER_TABS } from "../../common/utils";
import Apxor from "apxor";
import TourTypeSelectionDialog from "./components/sales-sandbox/TourTypeSelectionDialog";
import WelcomeBackDialog from "./components/sales-sandbox/WelcomeBackDialog";
import DemoPeriodOverDialog from "./components/sales-sandbox/DemoPeriodOverDialog";
import useMutateRouterState from "../../../../utils/use-mutate-router-state";

const useStyles = makeStyles((theme) => ({
  dashboard: {
    fontFamily: "Manrope",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "30px",
    lineHeight: "41px",
    color: "#002845",
    paddingBottom: 25,
  },
  name: {
    fontFamily: "Manrope",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "25px",
    letterSpacing: "0.01em",
    textTransform: "uppercase",
    color: "#002845",
    position: "relative",
    left: 10,
    paddingTop: 30,
    paddingBottom: 5,
  },
  viewall: {
    fontFamily: "Manrope",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "19px",
    color: "#FF7F33",
    position: "relative",
    left: 130,
    top: -28,
  },
}));

const CleanGraph = ({ tag }) => {
  return (
    <div style={{ paddingTop: 30, height: "27em" }}>
      <img src={graphs} alt="" style={{ width: "100%" }} />
      <div
        style={{
          width: "45%",
          backgroundColor: "#FFFFFF",
          position: "relative",
          top: -230,
          left: "28%",
          border: "1px solid #EAECEE",
          boxShadow: "0px 3px 20px rgba(140, 165, 192, 0.3)",
          borderRadius: "2px",
        }}
      >
        {" "}
        <Typography
          style={{
            fontFamily: "Manrope",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "19px",
            textAlign: "center",
            color: "#002845",
            padding: 30,
          }}
        >
          {tag}
        </Typography>
      </div>
    </div>
  );
};

export default function Dashboard({ app }) {
  const { app_id: appId } = app;
  const auth = useAuth();
  const [walkthroughNumber, setWalkthroughNumber] = useState({});
  const [surveys, setSurveys] = useState({});
  const [walkthroughs, setWalkthroughs] = useState({});
  const [errorWalkthrough, setErrorWalkthrough] = useState(false);
  const [errorSurveys, setErrorSurveys] = useState(false);

  //d0_uninstalls
  const [d0_today, setD0_today] = useState(0);
  const [d0_yester, setD0_yester] = useState(0);
  const [d0_lweek, setD0_lweek] = useState(0);
  const [d0_lmonth, setD0_lmonth] = useState(0);
  //d1_retention;
  const [d1_today, setD1_today] = useState(0);
  const [d1_yester, setD1_yester] = useState(0);
  const [d1_lweek, setD1_lweek] = useState(0);
  const [d1_lmonth, setD1_lmonth] = useState(0);
  //daily_users
  const [du_today, setDu_today] = useState(0);
  const [du_yester, setDu_yester] = useState(0);
  const [du_lweek, setDu_lweek] = useState(0);
  const [du_lmonth, setDu_lmonth] = useState(0);
  //sessions;
  const [session_today, setSession_today] = useState(0);
  const [session_yester, setSession_yester] = useState(0);
  const [session_lweek, setSession_lweek] = useState(0);
  const [session_lmonth, setSession_lmonth] = useState(0);
  //avg_session_length
  const [asl_today, setAsl_today] = useState(0);
  const [asl_yester, setAsl_yester] = useState(0);
  const [asl_lweek, setAsl_lweek] = useState(0);
  const [asl_lmonth, setAsl_lmonth] = useState(0);

  const dateFilters = (numberOfDays = -1, previous = 0) => {
    return {
      since:
        moment()
          .add(numberOfDays - previous, "day")
          .utc()
          .startOf("day")
          .toISOString()
          .slice(0, 23) + "Z",
      till:
        moment()
          .add(numberOfDays, "day")
          .utc()
          .endOf("day")
          .toISOString()
          .slice(0, 23) + "Z",
    };
  };
  // all the query params-----------------------------------------------------------------------------------------------------------------------------------
  const queryParams_d0Unin = (numberofDays = -1) => {
    return {
      ...dateFilters(numberofDays),
      group: "all_users",
      startDay: 0,
      endDay: 0,
      of: "users",
      event: "apx_uninstall",
    };
  };

  const queryParams2_daily_users = (numberOfDays = -1) => {
    return {
      ...dateFilters(numberOfDays),
      group: "all_users",
      of: "users",
    };
  };

  const queryParams3_sessions = (numberOfDays = 0) => {
    return {
      ...dateFilters(numberOfDays),
      group: "all_users",
      of: "sessions",
    };
  };

  const queryParams4_retention = (numberOfDays = -1, day = 0) => {
    return {
      ...dateFilters(numberOfDays),
      group: "all_users",
      startDay: day,
      endDay: day,
      of: "users",
    };
  };

  //----------------------------------------------------------------------------------------------------------------------------------------------------------
  // all the filter
  const filters1 = {
    event: [],
    session: [],
    user: [],
  };
  //----------------------------------------------------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    getCampaignsAPI(auth, appId, "walkthrough", false)
      .then((res) => {
        setWalkthroughs(res.messages);
      })
      .catch((err) => {
        setErrorWalkthrough(true);
      });
    getCampaignsAPI(auth, appId, "survey", false)
      .then((resp) => {
        setSurveys(resp.messages);
      })
      .catch((err) => {
        setErrorSurveys(true);
      });

    if (isExperienceDashboard()) {
      let publishedCampaign = false;
      let publishedSurvey = false;
      getCampaignsMetaAPI(
        auth,
        appId,
        {
          configType: "walkthrough",
          kind: "regular",
          state: CAMPAIGNS_HOLDER_TABS.ACTIVE,
          limit: 10,
        },
        false
      )
        .then((response) => {
          if (response.messages.length > 0) {
            publishedCampaign = true;
          } else {
            getCampaignsMetaAPI(
              auth,
              appId,
              {
                configType: "walkthrough",
                kind: "regular",
                state: CAMPAIGNS_HOLDER_TABS.COMPLETED,
                limit: 10,
              },
              false
            )
              .then((response) => {
                if (response.messages.length > 0) {
                  publishedCampaign = true;
                }
              })
              .catch((err) => {});
          }
        })
        .catch((err) => {});

      getCampaignsMetaAPI(
        auth,
        appId,
        {
          configType: "survey",
          kind: "regular",
          state: CAMPAIGNS_HOLDER_TABS.ACTIVE,
          limit: 10,
        },
        false
      )
        .then((response) => {
          if (response.messages.length > 0) {
            publishedSurvey = true;
          } else {
            getCampaignsMetaAPI(
              auth,
              appId,
              {
                configType: "survey",
                kind: "regular",
                state: CAMPAIGNS_HOLDER_TABS.COMPLETED,
                limit: 10,
              },
              false
            )
              .then((response) => {
                if (response.messages.length > 0) {
                  publishedSurvey = true;
                }
              })
              .catch((err) => {});
          }
        })
        .catch((err) => {});
      Apxor.setUserProperties({
        PublishedCampaign: publishedCampaign,
        PublishedSurvey: publishedSurvey,
      });
    }

    if (isExperienceDashboard() && isDemoApplicaton(appId)) {
      setD0_today(6.597);
      setD0_yester(6.315);
      setD0_lweek(6.046);
      setD0_lmonth(8.014);
      setD1_today(52.667);
      setD1_yester(52.663);
      setD1_lweek(52.662);
      setD1_lmonth(54.205);
      setDu_today(62599);
      setDu_yester(70702);
      setDu_lweek(58292);
      setDu_lmonth(94112);
      setSession_today(268684);
      setSession_yester(291933);
      setSession_lweek(264196);
      setSession_lmonth(409163);
      setAsl_today(1124);
      setAsl_yester(1089);
      setAsl_lweek(1245);
      setAsl_lmonth(935);
    } else {
      getEventCountAPI(
        auth,
        appId,
        queryParams_d0Unin(-2),
        filters1,
        false
      ).then((res1) => {
        countAPI(
          auth,
          appId,
          queryParams4_retention(-2, 0),
          filters1,
          false
        ).then((res2) => {
          setD0_today((res1 * 100) / res2);
        });
      });
      getEventCountAPI(
        auth,
        appId,
        queryParams_d0Unin(-3),
        filters1,
        false
      ).then((res1) => {
        countAPI(
          auth,
          appId,
          queryParams4_retention(-3, 0),
          filters1,
          false
        ).then((res2) => {
          setD0_yester((res1 * 100) / res2);
        });
      });
      getEventCountAPI(
        auth,
        appId,
        queryParams_d0Unin(-9),
        filters1,
        false
      ).then((res1) => {
        countAPI(
          auth,
          appId,
          queryParams4_retention(-9, 0),
          filters1,
          false
        ).then((res2) => {
          setD0_lweek((res1 * 100) / res2);
        });
      });
      getEventCountAPI(
        auth,
        appId,
        queryParams_d0Unin(-32),
        filters1,
        false
      ).then((res1) => {
        countAPI(
          auth,
          appId,
          queryParams4_retention(-32, 0),
          filters1,
          false
        ).then((res2) => {
          setD0_lmonth((res1 * 100) / res2);
        });
      });

      //---------------------------------------------------------------------------------------------------------------------------------------------------------
      countAPI(auth, appId, queryParams2_daily_users(-1), filters1, false).then(
        (res) => {
          setDu_today(res);
        }
      );
      countAPI(auth, appId, queryParams2_daily_users(-2), filters1, false).then(
        (res) => {
          setDu_yester(res);
        }
      );
      countAPI(auth, appId, queryParams2_daily_users(-8), filters1, false).then(
        (res) => {
          setDu_lweek(res);
        }
      );
      countAPI(
        auth,
        appId,
        queryParams2_daily_users(-31),
        filters1,
        false
      ).then((res) => {
        setDu_lmonth(res);
      });

      //---------------------------------------------------------------------------------------------------------------------------------------------------------

      countAPI(auth, appId, queryParams3_sessions(-1), filters1, false).then(
        (res) => {
          setSession_today(res);
        }
      );
      countAPI(auth, appId, queryParams3_sessions(-2), filters1, false).then(
        (res) => {
          setSession_yester(res);
        }
      );
      countAPI(auth, appId, queryParams3_sessions(-8), filters1, false).then(
        (res) => {
          setSession_lweek(res);
        }
      );
      countAPI(auth, appId, queryParams3_sessions(-31), filters1, false).then(
        (res) => {
          setSession_lmonth(res);
        }
      );

      //-------------------------------------------------------------------------------------------------------------------------------------------------------

      countAPI(
        auth,
        appId,
        queryParams4_retention(-1, 1),
        filters1,
        false
      ).then((res1) => {
        countAPI(
          auth,
          appId,
          queryParams4_retention(-2, 0),
          filters1,
          false
        ).then((res2) => {
          setD1_today((res1 * 100) / res2);
        });
      });
      countAPI(
        auth,
        appId,
        queryParams4_retention(-2, 1),
        filters1,
        false
      ).then((res1) => {
        countAPI(
          auth,
          appId,
          queryParams4_retention(-3, 0),
          filters1,
          false
        ).then((res2) => {
          setD1_yester((res1 * 100) / res2);
        });
      });
      countAPI(
        auth,
        appId,
        queryParams4_retention(-8, 1),
        filters1,
        false
      ).then((res1) => {
        countAPI(
          auth,
          appId,
          queryParams4_retention(-9, 0),
          filters1,
          false
        ).then((res2) => {
          setD1_lweek((res1 * 100) / res2);
        });
      });
      countAPI(
        auth,
        appId,
        queryParams4_retention(-31, 1),
        filters1,
        false
      ).then((res1) => {
        countAPI(
          auth,
          appId,
          queryParams4_retention(-32, 0),
          filters1,
          false
        ).then((res2) => {
          setD1_lmonth((res1 * 100) / res2);
        });
      });

      //-------------------------------------------------------------------------------------------------------------------------------------------------------
      getTimeSpentAPI(
        auth,
        appId,
        queryParams2_daily_users(-1),
        filters1,
        false,
        false
      ).then((res) => {
        const value = res.length > 0 ? res[res.length - 1].value : "--";
        setAsl_today(value);
      });
      getTimeSpentAPI(
        auth,
        appId,
        queryParams2_daily_users(-2),
        filters1,
        false,
        false
      ).then((res) => {
        const value = res.length > 0 ? res[res.length - 1].value : "--";
        setAsl_yester(value);
      });
      getTimeSpentAPI(
        auth,
        appId,
        queryParams2_daily_users(-8),
        filters1,
        false,
        false
      ).then((res) => {
        const value = res.length > 0 ? res[res.length - 1].value : "--";
        setAsl_lweek(value);
      });
      getTimeSpentAPI(
        auth,
        appId,
        queryParams2_daily_users(-31),
        filters1,
        false,
        false
      ).then((res) => {
        const value = res.length > 0 ? res[res.length - 1].value : "--";
        setAsl_lmonth(value);
      });
    }

    // -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  }, []);

  const classes = useStyles();

  //we need active surveys count.

  const tag =
    walkthroughNumber?.campaign_count === 0 ||
    walkthroughNumber?.user_count === 0
      ? "Launch campaigns to measure their impact on your key KPIs"
      : "no tag";

  const d0_uninstalls = {
    today: d0_today,
    yesterday: d0_yester,
    last_week: d0_lweek,
    last_month: d0_lmonth,
  };

  const d1_retention = {
    today: d1_today,
    yesterday: d1_yester,
    last_week: d1_lweek,
    last_month: d1_lmonth,
  };

  const daily_users = {
    today: du_today,
    yesterday: du_yester,
    last_week: du_lweek,
    last_month: du_lmonth,
  };

  const sessions = {
    today: session_today,
    yesterday: session_yester,
    last_week: session_lweek,
    last_month: session_lmonth,
  };

  const avg_session_length = {
    today: asl_today,
    yesterday: asl_yester,
    last_week: asl_lweek,
    last_month: asl_lmonth,
  };

  const INIT_DIALOG_ENUM = {
    WELCOME_USER: "WELLCOME_USER",
    TOUR_TYPE_SELECTOR: "TOUR_TYPE_SELECTOR",
    DEMO_PERIOD_OVER: "DEMO_PERIOD_OVER",
    CLOSE: "CLOSE",
  };

  const demoPeriodDaysLeft =
    isExperienceDashboard() && auth?.user?.demo_period_end_date
      ? Math.ceil(
          moment(auth?.user?.demo_period_end_date).diff(
            moment(new Date().toUTCString()),
            "days",
            true
          )
        )
      : 0;

  const history = useHistory();

  const initialState = {
    open:
      demoPeriodDaysLeft === 0
        ? INIT_DIALOG_ENUM.DEMO_PERIOD_OVER
        : demoPeriodDaysLeft <= 5 && history?.location?.state?.from_login
        ? INIT_DIALOG_ENUM.WELCOME_USER
        : INIT_DIALOG_ENUM.TOUR_TYPE_SELECTOR,
  };

  function tourTypeOrWelcomeReducer(state = initialState, { type }) {
    switch (type) {
      case INIT_DIALOG_ENUM.TOUR_TYPE_SELECTOR:
        return { ...state, open: INIT_DIALOG_ENUM.TOUR_TYPE_SELECTOR };
      case INIT_DIALOG_ENUM.WELCOME_USER:
        return { ...state, open: INIT_DIALOG_ENUM.WELCOME_USER };
      case INIT_DIALOG_ENUM.DEMO_PERIOD_OVER:
        return { ...state, open: INIT_DIALOG_ENUM.DEMO_PERIOD_OVER };
      case INIT_DIALOG_ENUM.CLOSE:
        return { ...state, open: false };
      default:
        return state;
    }
  }

  const [{ open }, dispatch] = useReducer(
    tourTypeOrWelcomeReducer,
    initialState
  );

  const { clearStateFields } = useMutateRouterState();

  const closeInitDialog = useCallback(() => {
    dispatch({ type: INIT_DIALOG_ENUM.CLOSE });
    clearStateFields(["from_login"]);
  }, [INIT_DIALOG_ENUM.CLOSE, clearStateFields]);

  return (
    <div
      style={{
        position: "relative",
        padding: 30,
      }}
    >
      <Typography className={classes.dashboard}>Overview</Typography>
      {isExperienceDashboard() && (
        <>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <TourTypeSelectionSection actionType="walkthroughs" auth={auth} />
            </Grid>
            <Grid item md={6}>
              <TourTypeSelectionSection actionType="surveys" auth={auth} />
            </Grid>
          </Grid>
        </>
      )}
      <Grid container spacing={2}>
        <Grid item md={6}>
          <UserStatistics
            d0_uninstalls={d0_uninstalls}
            d1_retention={d1_retention}
            daily_users={daily_users}
            sessions={sessions}
            avg_session_length={avg_session_length}
          />
        </Grid>
        <Grid item md={6}>
          {walkthroughNumber?.campaign_count === 0 ? (
            <CleanSlate appId={appId} />
          ) : (
            <CreateCampaign
              appId={appId}
              walkthroughs={walkthroughs}
              surveys={surveys}
              errorWalkthrough={errorWalkthrough}
              errorSurveys={errorSurveys}
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            width: 300,
            height: 68,
          }}
        >
          <Typography className={classes.name}>CAMPAIGNS</Typography>
          <Link
            to={{
              pathname: `/apps/${appId}/${getRouteForActionType(
                "walkthroughs",
              )}/`,
            }}
          >
            <Typography className={classes.viewall}>View All</Typography>
          </Link>
        </Grid>
        <Grid item md={12}>
          <CampaignMetricsCard
            campaignType="walkthrough"
            appId={appId}
            active={0}
            callback={setWalkthroughNumber}
          />
        </Grid>
        <Grid item md={12}>
          {walkthroughNumber?.campaign_count === 0 ||
          walkthroughNumber?.user_count === 0 ? (
            <CleanGraph tag={tag} />
          ) : (
            <Graphs appId={appId} dateFilters={dateFilters} />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            width: 300,
            height: 68,
          }}
        >
          <Typography className={classes.name}>SURVEYS</Typography>
          <Link
            to={{
              pathname: `/apps/${appId}/${getRouteForActionType("surveys")}/`,
            }}
          >
            {" "}
            <Typography className={classes.viewall} style={{ left: 105 }}>
              View All
            </Typography>
          </Link>
        </Grid>
        <Grid item md={12}>
          <CampaignMetricsCard campaignType="survey" appId={appId} active={0} />
        </Grid>
      </Grid>
      {isExperienceDashboard() && (
        <>
          <TourTypeSelectionDialog
            open={open === INIT_DIALOG_ENUM.TOUR_TYPE_SELECTOR}
            onClose={closeInitDialog}
            auth={auth}
          />
          <WelcomeBackDialog
            open={open === INIT_DIALOG_ENUM.WELCOME_USER}
            onClose={closeInitDialog}
            name={auth?.user?.name ?? ""}
            demoPeriodDaysLeft={demoPeriodDaysLeft}
          />
          <DemoPeriodOverDialog
            open={open === INIT_DIALOG_ENUM.DEMO_PERIOD_OVER}
          />
        </>
      )}
    </div>
  );
}
