/**
 * Created by kodanda_rama on 1/15/17.
 */
import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const IMG_WIDTH = 321,
  IMG_HEIGHT = 640;

const styles = (theme) => ({
  root: {},
  deviceText: {
    color: "#AAA",
  },
  deviceInfo: {
    position: "absolute",
    top: 620,
    textAlign: "center",
    maxWidth: 321,
    width: "100%",
  },
  controls: {
    position: "absolute",
    zIndex: 100,
    margin: "-60px 16px",
  },
});

export const MOBILE_IMAGE_URL = "/assets/img/mobile-mockup.png";

function MobileSimulator({
  classes,
  screenStyles = {},
  children,
  deviceInfo: { hardware_model, os_version, width, height } = {},
  deviceInfo = {},
  controls,
}) {
  const hasDeviceInfo = Object.keys(deviceInfo).length > 0;
  return (
    <div
      style={{
        background: `url(${MOBILE_IMAGE_URL})`,
        width: IMG_WIDTH,
        height: IMG_HEIGHT,
        margin: "0 auto",
      }}
    >
      <div
        style={{ width: "100%", height: 72, padding: 15, textAlign: "right" }}
      >
        <Typography className={classes.deviceText} variant="caption">
          294 X 462
        </Typography>
      </div>
      {controls && <div className={classes.controls}>{controls}</div>}
      <div
        style={{
          width: 294.2,
          height: 462,
          marginLeft: 12,
          position: "absolute",
          overflow: "hidden",
          background: "rgba(51,51,51, 0.3)",
          ...screenStyles,
        }}
      >
        {children}
      </div>
      {hasDeviceInfo && (
        <div className={classes.deviceInfo}>
          <Typography className={classes.deviceText} variant="caption" noWrap>
            <strong>{hardware_model}</strong>
          </Typography>
          <Typography className={classes.deviceText} variant="caption" noWrap>
            OS Version: <strong>{os_version}</strong>
          </Typography>
          <Typography className={classes.deviceText} variant="caption" noWrap>
            <strong>{width}</strong>x<strong>{height}</strong>
          </Typography>
        </div>
      )}
    </div>
  );
}

MobileSimulator.propTypes = {
  screenStyles: PropTypes.object,
  deviceInfo: PropTypes.object,
  controls: PropTypes.any,
};

export default withStyles(styles)(MobileSimulator);
