import React, { useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { getRouteForActionType } from "../../../common/components/campaigncreator/utils";
import StatusIdentifier from "../../../../ReusableComponents/StatusIdentifier";
import {
  CAMPAIGNS_HOLDER_TABS,
  getCampaignStatus,
  STATUS_PALETTE,
} from "../../../common/utils";

const useStyles = makeStyles((theme) => ({
  root1: {
    paddingLeft: 25,
    paddingRight: 30,
    paddingTop: 20,
    paddingBottom: 20,
    fontFamily: "Manrope",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "22px",
    lineHeight: "30px",
    color: "#002845",
  },
  root: {
    fontSize: 27,
    fontFamily: "Manrope",
    color: "black",
  },
  bar: {
    fontFamily: "Manrope",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "14px",
    letterSpacing: "0.3px",
    marginLeft: 0,
    textAlign: "left",
    color: "#002845",
    textTransform: "capitalize",
  },
  walkthroughName: {
    fontFamily: "Manrope",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#002845",
    paddingRight: 30,
  },
  typography: {
    fontFamily: "Manrope",
    fontSize: "26px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "36px",
    letterSpacing: "0.01em",
    color: "#002845",
    textAlign: "left",
    position: "relative",
    width: "295px",
    height: "72px",
    left: "30%",
    top: "4em",
  },
  dates: {
    fontFamily: "Manrope",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "11px",
    lineHeight: "15px",
    letterSpacing: "0.01em",
    color: "#002845",
    opacity: "0.45",
  },
  heading: {
    fontFamily: "Manrope",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "13px",
    paddingLeft: 25,
    paddingRight: 30,
    paddingTop: 20,
    paddingBottom: 20,
    lineHeight: "15px",
    letterSpacing: "0.01em",
    color: "#002845",
    opacity: "0.45",
  },
}));

const StyledTableCell = withStyles({
  root: {
    borderBottom: "1px solid #EAECEE",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
  },
})(TableCell);

export default function CreateCampaign({ appId, walkthroughs, surveys }) {
  const classes = useStyles();
  const [state, setState] = useState({
    selectedTabIndex: 0,
  });

  const handleChange = (_event, value) => {
    setState({
      selectedTabIndex: value,
    });
  };

  return (
    <Box
      style={{
        background: "#ffffff",
        border: "1px solid #EAECEE",
        borderBottom: "none",
        borderRadius: "4px",
      }}
    >
      <div className={classes.root1}>Latest Activity</div>
      <div className={classes.root}>
        <Grid xs={12} spacing={1}>
          <Grid xs={12}>
            <Tabs
              value={state.selectedTabIndex}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              style={{ borderBottom: "1px solid #EAECEE", borderWidth: "80%" }}
            >
              <Tab className={classes.bar} label="Campaigns" />
              <Tab className={classes.bar} label="Surveys" />
            </Tabs>
          </Grid>

          <TableContainer>
            <Table style={{ height: 336 }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell className={classes.heading}>
                    Name
                  </StyledTableCell>
                  <StyledTableCell className={classes.heading} align="center">
                    Status
                  </StyledTableCell>
                  <StyledTableCell
                    className={classes.heading}
                    align="center"
                  ></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.selectedTabIndex === 0 &&
                  walkthroughs?.length > 0 &&
                  walkthroughs.map((index, key) => (
                    <TableRow key={walkthroughs[key]}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        style={{
                          paddingLeft: 25,
                        }}
                      >
                        <Link
                          to={{
                            pathname: `/apps/${appId}/${getRouteForActionType(
                              "walkthroughs"
                            )}/${walkthroughs[key]._id}/analysis`,
                            state: walkthroughs[key],
                          }}
                        >
                          <Typography className={classes.walkthroughName}>
                            {walkthroughs[key].meta.name}
                          </Typography>
                        </Link>
                        <Typography
                          variant="subtitle1"
                          color="textSecondary"
                          className={classes.dates}
                        >
                          {moment(walkthroughs[key].validity.start_date).format(
                            "DD/MM/YYYY"
                          )}{" "}
                          -{" "}
                          {moment(walkthroughs[key].validity.end).format(
                            "DD/MM/YYYY"
                          )}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <StatusIdentifier
                          disabled={
                            !(walkthroughs[key] && walkthroughs[key].enabled)
                          }
                          statusType={
                            getCampaignStatus(walkthroughs[key]) ||
                            CAMPAIGNS_HOLDER_TABS.DRAFTS
                          }
                          palette={STATUS_PALETTE}
                          classes={{
                            color: classes.statusIdentifierColor,
                            bgColor: classes.statusIdentifierBgColor,
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Link
                          to={{
                            pathname: `/apps/${appId}/${getRouteForActionType(
                              "walkthroughs"
                            )}/${walkthroughs[key]._id}/analysis`,
                            state: walkthroughs[key],
                          }}
                        >
                          <ArrowForwardIosIcon style={{ fill: "#BEC9D0" }} />
                        </Link>
                      </StyledTableCell>
                    </TableRow>
                  ))}

                {state.selectedTabIndex === 1 &&
                  surveys?.length > 0 &&
                  surveys.map((index, key) => (
                    <TableRow key={surveys[key]} className={classes.lastRow}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        style={{
                          paddingLeft: 25,
                        }}
                      >
                        <Link
                          to={{
                            pathname: `/apps/${appId}/${getRouteForActionType(
                              "surveys"
                            )}/${surveys[key]._id}/analysis`,
                            state: surveys[key],
                          }}
                        >
                          <Typography className={classes.walkthroughName}>
                            {surveys[key].meta.name}
                          </Typography>
                        </Link>
                        <Typography
                          variant="subtitle1"
                          color="textSecondary"
                          className={classes.dates}
                        >
                          {moment(surveys[key].validity.start_date).format(
                            "DD/MM/YYYY"
                          )}{" "}
                          -{" "}
                          {moment(surveys[key].validity.end).format(
                            "DD/MM/YYYY"
                          )}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <StatusIdentifier
                          disabled={!(surveys[key] && surveys[key].enabled)}
                          statusType={
                            getCampaignStatus(surveys[key]) ||
                            CAMPAIGNS_HOLDER_TABS.DRAFTS
                          }
                          palette={STATUS_PALETTE}
                          classes={{
                            color: classes.statusIdentifierColor,
                            bgColor: classes.statusIdentifierBgColor,
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Link
                          to={{
                            pathname: `/apps/${appId}/${getRouteForActionType(
                              "surveys"
                            )}/${surveys[key]._id}/analysis`,
                            state: surveys[key],
                          }}
                        >
                          <ArrowForwardIosIcon style={{ fill: "#BEC9D0" }} />
                        </Link>
                      </StyledTableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </div>
    </Box>
  );
}
