import { Grid } from "@material-ui/core";
import React from "react";
import RecurAndLimitsSection from "./components/recurlimits";
import SchedulingSection from "./components/scheduling";

export default function ScheduleTab() {
  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={12}
        style={{
          border: "1px solid #E4E7E9",
          borderRadius: 3,
          margin: 8,
          background: "#FFF",
        }}
      >
        <SchedulingSection />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          border: "1px solid #E4E7E9",
          borderRadius: 3,
          margin: 8,
          marginTop: 0,
          background: "#FFF",
        }}
      >
        <RecurAndLimitsSection />
      </Grid>
    </Grid>
  );
}
