import { Chip, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    background: theme.palette.primary.main,
    height: 22,
    color: theme.palette.secondary.main,
    fontWeight: 700,
    margin: props.withGutter ? 8 : 0,
  }),
}));

export default function BetaChip(props) {
  const classes = useStyles(props);
  return <Chip label={"Beta"} className={classes.root} />;
}
