import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import ColumnDiv from "../../../../../../../../ReusableComponents/ColumnDiv";
import { useSummaryStyles } from "../../constants";
import CampaignLimitsSummary from "./components/CampaignLimitsSummary";
import CampaignScheduleSummary from "./components/CampaignScheduleSummary";
import ABCampaignScheduleSummary from "./components/ABCampaignScheduleSummary";

export default function SettingsSummary({
  at_specific_time,
  frequency,
  meta,
  scheduling,
  time_limits,
  time_limits_in_day,
  validity,
  handleSelect,
  isExperiment,
  config,
}) {
  const headerClasses = useSummaryStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} className={headerClasses.root}>
        <Typography variant="body1" className={headerClasses.summaryHeading}>
          Schedule
        </Typography>
        {handleSelect && (
          <Button
            variant="text"
            color="primary"
            onClick={() => handleSelect(2)}
            className={headerClasses.editButton}
          >
            Edit
          </Button>
        )}
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid
            item
            xs={6}
            style={{
              borderRight: "1px solid #E4E7E9",
              padding: "20px 26px",
            }}
          >
            <ColumnDiv>
              <Typography className={headerClasses.orangeHeading}>
                CAMPAIGN SCHEDULE:
              </Typography>
              {!isExperiment && (
                <CampaignScheduleSummary
                  at_specific_time={at_specific_time}
                  meta={meta}
                  scheduling={scheduling}
                  time_limits={time_limits}
                  time_limits_in_day={time_limits_in_day}
                  validity={validity}
                />
              )}
              {isExperiment && (
                <ABCampaignScheduleSummary
                  config={config}
                  at_specific_time={at_specific_time}
                  meta={meta}
                  scheduling={scheduling}
                  time_limits={time_limits}
                  time_limits_in_day={time_limits_in_day}
                  validity={validity}
                />
              )}
            </ColumnDiv>
          </Grid>
          <Grid item xs={6} style={{ padding: "20px 26px" }}>
            <ColumnDiv>
              <Typography className={headerClasses.orangeHeading}>
                CAMPAIGN LIMITS:
              </Typography>
              <CampaignLimitsSummary frequency={frequency} />
            </ColumnDiv>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
