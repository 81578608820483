import { randomColorWithIndex } from "../../../../utils";

export const FunnelBehaviorEnum = {
  DID: "did",
  DROPPED: "dropped",
};

export const FUNNEL_GRAPH_ID = "funnel-graph";

export const OVERVIEW_KEY = "Holding Constant";

export const transformToHighCharts = (data, setContextElement) => {
  const { categories, did, dropped, eventNames } = data.reduce(
    (result, item) => {
      result["categories"] = result["categories"].concat(item.name);
      result[FunnelBehaviorEnum.DID] = result[FunnelBehaviorEnum.DID].concat(
        item.count === 0 ? null : item.count
      );
      result[FunnelBehaviorEnum.DROPPED] = result[
        FunnelBehaviorEnum.DROPPED
      ].concat(
        item[FunnelBehaviorEnum.DROPPED] === 0
          ? null
          : item[FunnelBehaviorEnum.DROPPED]
      );
      result["eventNames"] = result["eventNames"].concat(item.name);
      return result;
    },
    { categories: [], dropped: [], did: [], eventNames: [] }
  );

  return {
    chart: {
      type: "column",
    },
    title: {
      text: eventNames.join(" > "),
    },
    xAxis: {
      categories,
    },
    yAxis: {
      title: "Users",
    },
    plotOptions: {
      column: {
        cursor: "pointer",
        stacking: "normal",
        dataLabels: { enabled: true },
      },
    },
    legend: {
      enabled: true,
    },
    series: [
      {
        y: 1,
        color: {
          pattern: {
            path: {
              d: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
              strokeWidth: 4,
            },
            width: 10,
            height: 10,
            opacity: 0.4,
            color: randomColorWithIndex(0),
          },
        },
        name: FunnelBehaviorEnum.DROPPED,
        type: "column",
        data: dropped,
        cursor: "pointer",
        events: {
          click: (event) => {
            const stepIndex = event.point.index;
            setContextElement(
              event.target,
              FunnelBehaviorEnum.DROPPED,
              stepIndex
            );
          },
        },
      },
      {
        name: FunnelBehaviorEnum.DID,
        data: did,
        type: "column",
        cursor: "pointer",
        color: randomColorWithIndex(0),
        events: {
          click: (event) => {
            const stepIndex = event.point.index;
            setContextElement(event.target, FunnelBehaviorEnum.DID, stepIndex);
          },
        },
      },
    ],
  };
};

export const getStepIndex = (behaviorType, level) => {
  return (behaviorType === FunnelBehaviorEnum.DROPPED ? -1 : 1) * (level + 1);
};

