import { makeStyles } from "@material-ui/core";
import React from "react";
import BootstrapTooltip from "./BootstrapTooltip";

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    background: props.disabled ? "#EDF0F2" : "#FFF",
    border: "1px solid #CCD4DA",
    borderRadius: 2,
    color:
      props.value === "" || props.value === null
        ? theme.palette.text.secondary
        : theme.palette.text.primary,
    fontSize: 13,
    fontWeight: 600,
    lineHeight: "18px",
    letterSpacing: "0.01em",
    padding: "6px 10px",
    maxWidth: props.maxWidth ? props.maxWidth : 150,
    display: "inline-block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&&&&:hover": {
      border: props.disabled ? "1px solid #CCD4DA" : "1px solid #606365",
      cursor: props.disabled ? "default" : "pointer",
    },
  }),
  withNoTruncation: (props) => ({
    background: props.disabled ? "#EDF0F2" : "#FFF",
    border: "1px solid #CCD4DA",
    borderRadius: 2,
    color:
      props.value === "" || props.value === null
        ? theme.palette.text.secondary
        : theme.palette.text.primary,
    fontSize: 13,
    fontWeight: 600,
    lineHeight: "18px",
    letterSpacing: "0.01em",
    padding: "6px 10px",
    display: "inline-block",
    "&&&&:hover": {
      border: props.disabled ? "1px solid #CCD4DA" : "1px solid #606365",
      cursor: props.disabled ? "default" : "pointer",
    },
  }),
}));

export default function TruncatedText(props) {
  const {
    placeholder = null,
    value,
    onClick = null,
    styles = {},
    hasTooltip = true,
    tooltipTitle = null,
    tooltipPlacement = null,
    dontTruncate = false,
  } = props;
  const classes = useStyles(props);

  const content = (
    <div
      className={dontTruncate ? classes.withNoTruncation : classes.root}
      onClick={(e) => {
        e.preventDefault();
        if (onClick) {
          onClick(e);
        }
      }}
      style={{ ...styles }}
    >
      {value === "" ? placeholder : value + ""}
    </div>
  );

  if (!hasTooltip) {
    return content;
  }

  return (
    <BootstrapTooltip
      title={tooltipTitle ? tooltipTitle : value === "" ? placeholder : value}
      placement={tooltipPlacement ? tooltipPlacement : "top"}
    >
      {content}
    </BootstrapTooltip>
  );
}
