import { makeStyles, Tooltip } from "@material-ui/core";
import React from "react";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.text.primary,
  },
  tooltip: {
    backgroundColor: theme.palette.text.full,
    color: theme.palette.secondary.main,
    fontSize: 14,
    padding: 10,
  },
}));

export default function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow={props.arrow ?? true} classes={classes} {...props} />;
}
