import { produce } from "immer";
import moment from "moment";
import {
  NOTIFICATION_ENUM,
  SET_NAME,
  SET_DESCRIPTION,
  SET_SELECTED_VERSIONS,
  SET_SELECTED_SEGMENT,
  SET_END_TIME,
  SET_START_TIME,
  SET_TTL,
  SET_PRIORITY,
  SET_NOTIFICATION_TYPE,
  SET_NOTIFICATION_TITLE,
  SET_NOTIFICATION_BODY,
  SET_ACTIVITY_NAME,
  SET_DEEP_LINK_URI,
  SET_CHANNEL_ID,
  ADD_TO_CUSTOM_KEYS,
  DELETE_CUSTOM_KEYS,
  SET_IS_SILENT,
  SET_BIG_IMAGE_PATH,
  SET_BIG_IMAGE_URL,
  SET_BIG_IMAGE_CONTENT_TITLE,
  SET_BIG_TEXT_LONG_TEXT,
  SET_ICON_PATH,
  SET_LARGE_ICON_PATH,
  SET_LARGE_ICON_URL,
  ADD_TO_BUTTON_CONFIG,
  DELETE_BUTTON_CONFIG,
  SET_PUSH_CONFIG_AS_INITIAL_STATE,
  SET_SCHEDULING_TYPE,
  SET_SCHEDULING_START,
  SET_SCHEDULING_END,
  SET_SELECTED_COHORT,
  SET_ROLL_INTERVAL,
  SET_TOGGLE_RECURR,
  SET_ROLL_SEGMENT,
  SET_ROLL_STEP,
  SET_ROLL_RECURR,
  SET_REDIRECTION_TYPE,
  SET_BIG_IMAGE_SOURCE,
  SET_ICON_CONFIG_TYPE,
  SET_IS_COLLAPSING_ENABLED,
  SET_COLLAPSING_KEY,
  SET_IS_VALID_PUSH,
  ADD_TEST_DEVICE,
  REMOVE_TEST_DEVICE,
  SET_APP_SCREENS,
  SET_APP_EVENTS,
  SET_APP_COHORTS,
  SET_APP_SEGMENTS,
  SET_APP_VERSIONS,
  SET_TEST_DEVICES,
  SET_AUDIENCE_TYPE,
  SET_APP_PLATFORM,
} from "./constants";
import { SCHEDULING_ENUM } from "../../common/components/campaigncreator/constants";
import { APP_PLATFORMS } from "../../../../constants";

const DEFAULT_SCHEDULING_STATE = {
  schedule: SCHEDULING_ENUM.NOW,
  schedule_date: moment().toISOString(),
  schedule_time: moment().format("HH:mm"),
  schedule_end_datetime: moment().toISOString(),
  schedule_start_datetime: moment().toISOString(),
  isRecurEnabled: false,
  rollSegment: false,
  rollInterval: 1,
  recur: "DAY",
  step: 1,
};

const DEFAULT_AUDIENCE_STATE = {
  audience_type: "ALL",
  attributes: { user: [], session: [] },
  limited: {},
  segment_id: "",
  cohort_id: "",
  end_time: moment().utc().endOf("day").toISOString().slice(0, 23) + "Z",
  start_time: moment().utc().startOf("day").toISOString().slice(0, 23) + "Z",
};

const DEFAULT_BIG_IMAGE_STATE = {
  image_path: "",
  image_url: "",
  image_source: "path",
  content_title: "",
};

const DEFAULT_BIG_TEXT_STATE = {
  long_text: "",
};


export const initialState = {
  app_versions: [],
  app_segments: [],
  app_screens: [],
  app_events: [],
  app_cohorts: [],
  test_devices: [],
  push_config: {
    name: "",
    description: "",
    meta: { platform: APP_PLATFORMS.android },
    audience: DEFAULT_AUDIENCE_STATE,
    scheduling: DEFAULT_SCHEDULING_STATE,
    payload: {
      notification_type: NOTIFICATION_ENUM.simple,
      notification_title: "",
      activity_name: "",
      deep_link_uri: "",
      redirection_type: "activity",
      custom_keys: [],
      buttons_config: [],
      notification_body: "",
      is_silent: false,
      priority: 0,
      is_collapsing_enabled: false,
      collapsing_key: "",
      channel_id: "",
      big_text: DEFAULT_BIG_TEXT_STATE,
      big_image: DEFAULT_BIG_IMAGE_STATE,
      icon_config: {
        icon_path: "",
        large_icon_path: "",
        large_icon_url: "",
        icon_config_type: "icon",
      },
      ttl: moment().add(1, "day").valueOf(),
    },
    is_valid: true,
    test_devices: [],
  },
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case SET_APP_SCREENS:
      draft.app_screens = action.value;
      break;
    case SET_APP_EVENTS:
      draft.app_events = action.value;
      break;
    case SET_APP_PLATFORM:
      draft.push_config.meta.platform = action.value;
      break;
    case SET_APP_SEGMENTS:
      draft.app_segments = action.value;
      break;
    case SET_APP_COHORTS:
      draft.app_cohorts = action.value;
      break;
    case SET_APP_VERSIONS:
      draft.app_versions = action.value;
      break;
    case SET_NAME:
      draft.push_config.name = action.value;
      break;
    case SET_DESCRIPTION:
      draft.push_config.description = action.value;
      break;
    case SET_SELECTED_VERSIONS:
      draft.push_config.versions = action.value;
      break;
    case SET_SELECTED_SEGMENT:
      draft.push_config.audience.segment_id = action.value;
      break;
    case SET_SELECTED_COHORT:
      draft.push_config.audience.cohort_id = action.value;
      break;
    case SET_AUDIENCE_TYPE:
      draft.push_config.audience.audience_type = action.value;
      break;
    case SET_START_TIME:
      draft.push_config.audience.start_time = action.value;
      break;
    case SET_END_TIME:
      draft.push_config.audience.end_time = action.value;
      break;
    case SET_SCHEDULING_TYPE:
      draft.push_config.scheduling.schedule = action.value;
      break;
    case SET_SCHEDULING_START:
      draft.push_config.scheduling.schedule_date = action.date;
      draft.push_config.scheduling.schedule_time = action.time;
      draft.push_config.scheduling.schedule_start_datetime = action.date_time;
      if (!draft.push_config.scheduling.isRecurEnabled)
        draft.push_config.scheduling.schedule_end_datetime = action.date_time;
      break;
    case SET_SCHEDULING_END:
      draft.push_config.scheduling.schedule_end_datetime = action.date_time;
      break;
    case SET_ROLL_INTERVAL:
      draft.push_config.scheduling.rollInterval = action.value;
      break;
    case SET_ROLL_STEP:
      draft.push_config.scheduling.step = action.value;
      break;
    case SET_TOGGLE_RECURR:
      draft.push_config.scheduling.isRecurEnabled = action.value;
      break;
    case SET_ROLL_SEGMENT:
      draft.push_config.scheduling.rollSegment = action.value;
      break;
    case SET_ROLL_RECURR:
      draft.push_config.scheduling.recur = action.value;
      break;
    case SET_TTL:
      draft.push_config.payload.ttl = action.value;
      break;
    case SET_PRIORITY:
      draft.push_config.payload.priority = action.value;
      break;
    case SET_NOTIFICATION_TYPE:
      draft.push_config.payload.notification_type = action.value;
      if (
        action.value === NOTIFICATION_ENUM.big_image &&
        !draft.push_config.payload.big_image
      )
        draft.push_config.payload.big_image = DEFAULT_BIG_IMAGE_STATE;
      else if (
        action.value === NOTIFICATION_ENUM.big_text &&
        !draft.push_config.payload.big_text
      )
        draft.push_config.payload.big_text = DEFAULT_BIG_TEXT_STATE;
      break;
    case SET_NOTIFICATION_TITLE:
      draft.push_config.payload.notification_title = action.value;
      break;
    case SET_NOTIFICATION_BODY:
      draft.push_config.payload.notification_body = action.value;
      break;
    case SET_ACTIVITY_NAME:
      draft.push_config.payload.activity_name = action.value;
      break;
    case SET_DEEP_LINK_URI:
      draft.push_config.payload.deep_link_uri = action.value;
      break;
    case SET_CHANNEL_ID:
      draft.push_config.payload.channel_id = action.value;
      break;
    case ADD_TO_CUSTOM_KEYS:
      const index = action.value.index;
      if (index !== undefined) {
        const updatedKeys = [
          ...draft.push_config.payload.custom_keys.slice(0, index),
          action.value,
          ...draft.push_config.payload.custom_keys.slice(index + 1),
        ];
        draft.push_config.payload.custom_keys = updatedKeys;
      } else {
        const length = draft.push_config.payload.custom_keys.length;
        action.value.index = length;
        draft.push_config.payload.custom_keys.push(action.value);
      }
      break;
    case ADD_TO_BUTTON_CONFIG:
      const buttonConfigIndex = action.value.index;
      if (buttonConfigIndex !== undefined) {
        const updatedKeys = [
          ...draft.push_config.payload.buttons_config.slice(
            0,
            buttonConfigIndex
          ),
          action.value,
          ...draft.push_config.payload.buttons_config.slice(
            buttonConfigIndex + 1
          ),
        ];
        draft.push_config.payload.buttons_config = updatedKeys;
      } else {
        const length = draft.push_config.payload.buttons_config.length;
        action.value.index = length;
        draft.push_config.payload.buttons_config.push(action.value);
      }
      break;
    case DELETE_CUSTOM_KEYS:
      draft.push_config.payload.custom_keys = draft.push_config.payload.custom_keys.filter(
        (val, index) => {
          return index !== action.value;
        }
      );
      break;
    case SET_IS_SILENT:
      draft.push_config.payload.is_silent = action.value;
      break;
    case SET_BIG_IMAGE_PATH:
      draft.push_config.payload.big_image.image_path = action.value;
      break;
    case SET_BIG_IMAGE_URL:
      draft.push_config.payload.big_image.image_url = action.value;
      break;
    case SET_BIG_IMAGE_CONTENT_TITLE:
      draft.push_config.payload.big_image.content_title = action.value;
      break;
    case SET_BIG_TEXT_LONG_TEXT:
      draft.push_config.payload.big_text.long_text = action.value;
      break;
    case SET_ICON_PATH:
      draft.push_config.payload.icon_config.icon_path = action.value;
      break;
    case SET_LARGE_ICON_PATH:
      draft.push_config.payload.icon_config.large_icon_path = action.value;
      break;
    case SET_LARGE_ICON_URL:
      draft.push_config.payload.icon_config.large_icon_url = action.value;
      break;
    case DELETE_BUTTON_CONFIG:
      draft.push_config.payload.buttons_config = draft.push_config.payload.buttons_config.filter(
        (val, index) => {
          return index !== action.value;
        }
      );
      break;
    case SET_REDIRECTION_TYPE:
      draft.push_config.payload.redirection_type = action.value;
      break;
    case SET_BIG_IMAGE_SOURCE:
      draft.push_config.payload.big_image.image_source = action.value;
      break;
    case SET_ICON_CONFIG_TYPE:
      draft.push_config.payload.icon_config.icon_config_type = action.value;
      break;
    case SET_IS_COLLAPSING_ENABLED:
      draft.push_config.payload.is_collapsing_enabled = action.value;
      break;
    case SET_COLLAPSING_KEY:
      draft.push_config.payload.collapsing_key = action.value;
      break;
    case SET_PUSH_CONFIG_AS_INITIAL_STATE:
      draft.push_config = action.value;
      break;
    case SET_IS_VALID_PUSH:
      draft.push_config.is_valid = action.value;
      break;
    case ADD_TEST_DEVICE:
      draft.push_config.test_devices.push(action.value);
      break;
    case REMOVE_TEST_DEVICE:
      const indexPos = draft.push_config.test_devices.indexOf(action.value);
      draft.push_config.test_devices.splice(indexPos, 1);
      break;
    case SET_TEST_DEVICES:
      draft.test_devices = action.value;
      break;
    default:
      return draft;
  }

});