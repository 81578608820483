/**
 * Created by Araja Jyothi Babu on 25-Oct-16.
 *
 * Ported by Uma/yasasvee June 2020
 */
import React, { useState, useEffect, useMemo } from "react";
import { useAuth } from "../../../../contexts/AuthContext";

import { getAttributes } from "../../../common/actions";
import { Operators } from "../../../../constants";
import Filters from "../../common/components/Filters";
import AttributeBuilder from "../../../ReusableComponents/AttributeBuilder";
import { useDateFilters } from "../../common/utils";
import { Button, Divider, Grid } from "@material-ui/core";
import { Provider } from "./store";
import { RetentionAnalysis } from "./components/RetentionAnalysis";
import { RetentionUsage } from "./components/RetentionUsage";
import { useTracked } from "./store";
import { getRetentionData, getRetentionUsage } from "./actions";
import {
  SET_RETENTION_DATA,
  SET_RETENTION_HEADER,
  SET_RETENTION_USAGE,
} from "./constants";
import { useDeepCompareEffect } from "../../../.././utils/use-deep-compare";

const RetentionWithProvider = ({ app }) => {
  const [state, dispatch] = useTracked();
  const {
    retention: { query },
    retention_type,
    dayWindow,
  } = state;
  const { app_id: appId } = app;

  const auth = useAuth();

  // Initial values used
  const [userAttributes, setUserAttributes] = useState([]);
  const [sessionAttributes, setSessionAttributes] = useState([]);

  // LocalState
  const [filtersApplied, setFiltersApplied] = useState(true);
  const [user, setUser] = useState([]);
  const [session, setSession] = useState([]);
  //Filters is request body
  const [filters, setFilters] = useState({
    user: [],
    session: [],
    event: [],
  });
  const [cohort, setCohort] = useState(null);
  const [version, setVersion] = useState(null);
  const [dateFilters, setDateFilters] = useDateFilters();

  const queryParams = useMemo(
    () => ({
      ...dateFilters,
      cohortId: cohort,
      versions: version,
    }),
    [dateFilters, cohort, version]
  );

  useEffect(() => {
    getAttributes(auth, appId).then((response) => {
      setUserAttributes(response.user);
      setSessionAttributes(response.session);
      setFiltersApplied(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDeepCompareEffect(() => {
    getRetentionData(
      auth,
      appId,
      queryParams,
      filters,
      retention_type,
      dayWindow,
      query
    ).then((response) => {
      dispatch({
        type: SET_RETENTION_DATA,
        value: {
          days: response.days,
          weeks: response.weeks,
          months: response.months,
        },
      });
      dispatch({
        type: SET_RETENTION_HEADER,
        value: response.header,
      });
    });
    getRetentionUsage(auth, appId, queryParams, filters).then((response) => {
      dispatch({
        type: SET_RETENTION_USAGE,
        value: response,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, filters, retention_type, dayWindow, query]);

  const onApplyButtonClicked = () => {
    setFilters({
      user: user,
      session: session,
      event: [],
    });
    setFiltersApplied(true);
  };

  return (
    <>
      <Filters
        app={app}
        showVersion
        showCohort
        dateFilters={dateFilters}
        onCohortChange={(cohort) => setCohort(cohort)}
        onVersionChange={(version) => setVersion(version)}
        onDateFiltersChange={(since, till) =>
          setDateFilters({ since: since, till: till })
        }
      />
      <Divider />
      <section className="content">
        <Grid container spacing={1}>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <AttributeBuilder
              key={"Session Properties"}
              appId={app.app_id}
              title={"Session Properties"}
              dateFilters={dateFilters}
              operators={Operators}
              attributes={sessionAttributes}
              handleOnChange={(attrs) => {
                setSession(attrs);
                setFiltersApplied(false);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <AttributeBuilder
              key={"User Properties"}
              appId={app.app_id}
              title={"User Properties"}
              dateFilters={dateFilters}
              operators={Operators}
              attributes={userAttributes}
              handleOnChange={(attrs) => {
                setUser(attrs);
                setFiltersApplied(false);
              }}
            />
          </Grid>
        </Grid>
        <Button
          disabled={filtersApplied}
          z
          variant="contained"
          size="small"
          color="primary"
          onClick={() => {
            setFiltersApplied(true);
            onApplyButtonClicked();
            getRetentionData(
              auth,
              appId,
              queryParams,
              filters,
              retention_type,
              dayWindow,
              query
            ).then((response) => {
              dispatch({
                type: SET_RETENTION_DATA,
                value: {
                  days: response.days,
                  weeks: response.weeks,
                  months: response.months,
                },
              });
              dispatch({
                type: SET_RETENTION_HEADER,
                value: response.header,
              });
            });
            getRetentionUsage(auth, appId, queryParams, filters).then(
              (response) => {
                dispatch({
                  type: SET_RETENTION_USAGE,
                  value: response,
                });
              }
            );
          }}
          style={{ display: "block", margin: "10px 0 10px auto" }}
        >
          Apply
        </Button>
        {/* <Loading /> */}
        <RetentionAnalysis
          appId={appId}
          queryParams={queryParams}
          dateFilters={dateFilters}
          filters={filters}
        />
        <RetentionUsage appId={appId} queryParams={queryParams} />
      </section>
    </>
  );
};

const Retention = ({ app }) => {
  return (
    <Provider>
      <RetentionWithProvider app={app} />
    </Provider>
  );
};

export default Retention;
