import React, { useState } from "react";
import { Button, Grid, Paper, TextField, Typography } from "@material-ui/core";
import MultiSelect from "../../../../../../../../../../ReusableComponents/MultiSelect";
import {
  ACTION_AFTER_RATING,
  RATING_ACTION_LABELS,
  REMOVE_RULE,
  RESET_REACTIONS,
  SET_ACTION_AFTER_RATING,
  SET_APP_STORE_LINK,
  SET_FOLLOWUP_QUESTION,
  SET_MAX_RATING,
  SET_MIN_RATING,
  SET_PLAY_STORE_LINK,
  SET_RATING_REDIRECTION_QUESTION,
  SET_RULE_REACTIONS,
  SET_SURVEY_RATING_BUTTONS,
  SET_SURVEY_RATING_STYLE,
  SURVEY_RATING_STYLE,
  TOGGLE_RATING_REDIRECTION,
  SET_TXCFG_PLACEHOLDER,
} from "../../../../../constants";
import { useTracked } from "../../../../../store";
import { useCommonConfigStyles } from "../../../../../../../../walkthroughs/components/design/components/configuration/utils";
import ActionsTextField from "../../../../../../../../../../ReusableComponents/ActionsTextField";

import { EMOJI_STYLES } from "../../../../../utils";
import { imageEmoji, unicodeEmoji } from "../../../../Emoji";
import TextBasedQuestion from "./TextBasedQuestion";
import RowDiv from "../../../../../../../../../../ReusableComponents/RowDiv";

const getCorrespondingRuleQuestion = (id, state) => {
  return state[id] ?? {};
};

const getEmoji = (code, isUnicode) => {
  if (isUnicode) return unicodeEmoji(code, 20);
  else return imageEmoji(code);
};

const getEmojiFromMapper = (index, emojiMap) => {
  const emojiObject = emojiMap.findIndex(
    eachEmoji => eachEmoji.value === index
  );
  return isNaN(emojiObject) || emojiObject < 0
    ? undefined
    : emojiMap[emojiObject].label;
};

const getEmojiList = (rating_scale, rating_style) => {
  if (rating_style === SURVEY_RATING_STYLE.NUMBER) {
    return rating_scale === 5
      ? EMOJI_STYLES.NUMBERS_5
      : EMOJI_STYLES.NUMBERS_10;
  } else if (rating_style === SURVEY_RATING_STYLE.STYLIZED) {
    return rating_scale === 5
      ? EMOJI_STYLES.STYLIZED_5
      : EMOJI_STYLES.STYLIZED_10;
  } else {
    return rating_scale === 5
      ? EMOJI_STYLES.CLASSIC_5
      : EMOJI_STYLES.CLASSIC_10;
  }
};

const appendCurrentRuleOptions = (existing, rule) => {
  return rule.max && rule.min
    ? [
        ...[...Array(rule.max - rule.min + 1).keys()].map(x => x + rule.min),
        ...existing,
      ].sort()
    : existing;
};

const RatingQuestionFollowupBuilder = ({
  questionIndex,
  optionsAvailable,
  ruleData,
  updateOptionsAvailable,
  disabled = false,
  rules,
}) => {
  const [state, dispatch] = useTracked();
  const {
    currentStep,
    ui: { questions = [] },
  } = state;

  const [errorMessage, setErrorMessage] = useState("");

  const { rating_redirection_question, rating_scale, rating_style } = questions[
    currentStep
  ];

  const additionalStyles = disabled
    ? { pointerEvents: "none", opacity: "0.4" }
    : {};

  const emojiIndexMapper = getEmojiList(rating_scale, rating_style).map(
    (each, index) => {
      return {
        value: index + 1,
        label: getEmoji(each, rating_style === SURVEY_RATING_STYLE.CLASSIC),
      };
    }
  );

  const reactionOptions = emojiIndexMapper.filter(
    each =>
      appendCurrentRuleOptions(optionsAvailable, ruleData).indexOf(
        each.value
      ) !== -1
  );

  const { type, ...otherData } = getCorrespondingRuleQuestion(
    questionIndex,
    questions[currentStep]
  );

  const validateRatingSelection = (selectedValue, key) => {
    let redFlag = false;
    const keyToCompare = ruleData[key];
    rules.every(eachRule => {
      const { min, max } = eachRule;
      if (
        eachRule.qid !== ruleData.qid &&
        ((keyToCompare <= min && selectedValue >= max) ||
          (keyToCompare >= max && selectedValue <= min))
      ) {
        redFlag = true;
        setErrorMessage(
          "Min - Max range should not overlap with already created rules"
        );
        return false;
      } else setErrorMessage("");
      return true;
    });
    return redFlag;
  };

  const classes = useCommonConfigStyles();
  return (
    <Paper
      elevation={0}
      style={{
        background: "#F1F3F4",
        width: "100%",
        padding: 8,
        borderTop: "10px solid #FFFFFF",
        ...additionalStyles,
      }}
    >
      <Grid
        item
        xs={12}
        className={classes.gridItem}
        style={{ justifyContent: "end" }}
      >
        <Button
          color={"primary"}
          onClick={() => {
            dispatch({
              type: REMOVE_RULE,
              currentStep: currentStep,
              value: questionIndex,
            });
            updateOptionsAvailable(ruleData.min, ruleData.max, "add");
          }}
        >
          <span style={{ textTransform: "none" }}>Remove</span>
        </Button>
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        <Typography className={classes.typographySubtitle2} variant="subtitle2">
          ACTION AFTER RATING
        </Typography>
        <MultiSelect
          style={{ maxWidth: 264, minWidth: 180 }}
          single
          clearable={false}
          isSearchable={false}
          options={ACTION_AFTER_RATING}
          value={type}
          handleChange={value => {
            dispatch({
              type: SET_ACTION_AFTER_RATING,
              currentStep: currentStep,
              qid: questionIndex,
              value: value,
            });
          }}
        />
      </Grid>

      {type === RATING_ACTION_LABELS.REDIRECTION && (
        <>
          <Grid item xs={12} className={classes.gridItem}>
            <Typography
              className={classes.typographySubtitle2}
              variant="subtitle2"
            >
              PLAY STORE LINK
            </Typography>
            <ActionsTextField
              value={otherData.uri ?? ""}
              placeholder="Enter the path"
              onChange={event => {
                dispatch({
                  type: SET_PLAY_STORE_LINK,
                  currentStep: currentStep,
                  qid: questionIndex,
                  value: event.target.value,
                });
              }}
              style={{
                maxWidth: 264,
                minWidth: 264,
                marginTop: 10,
                marginBottom: "unset",
              }}
            />
          </Grid>
          {/* <Grid item xs={12} className={classes.gridItem}>
            <Typography
              className={classes.typographySubtitle2}
              variant="subtitle2"
            >
              APP STORE LINK
            </Typography>
            <ActionsTextField
              value={app_store_link}
              placeholder="Enter the path"
              onChange={(event) => {
                dispatch({
                  type: SET_APP_STORE_LINK,
                  qid: questionIndex,
                  value: event.target.value,
                });
              }}
              style={{
                maxWidth: 264,
                minWidth: 264,
                marginTop: 10,
                marginBottom: "unset",
              }}
            />
          </Grid> */}
        </>
      )}

      {type === RATING_ACTION_LABELS.QUESTION && (
        // <Grid
        //   item
        //   xs={12}
        //   className={classes.gridItem}
        //   style={{ marginTop: 10 }}
        // >
        //   <Typography
        //     className={classes.typographySubtitle2}
        //     variant="subtitle2"
        //   >
        //     TYPE OF QUESTION
        //   </Typography>
        //   <ToggleButtonGroup
        //     value={rating_redirection_question}
        //     exclusive
        //     onChange={(e, value) => {
        //       let rating_question = "short_answer";
        //       if (value === "short_answer") {
        //         rating_question = "short_answer";
        //       } else if (value === "multi_select") {
        //         //rating_question = "multi_select";
        //       }
        //       dispatch({
        //         type: SET_RATING_REDIRECTION_QUESTION,
        //         currentStep: currentStep,
        //         qid: questionIndex,
        //         value: rating_question,
        //       });
        //     }}
        //   >
        //     <ToggleButton
        //       value="short_answer"
        //       aria-label="short_answer"
        //       style={{ padding: 8 }}
        //     >
        //       <span style={{ padding: "0px 8px" }}>Short Answer</span>
        //     </ToggleButton>
        //     <ToggleButton
        //       value="multi_select"
        //       aria-label="multi_select"
        //       style={{ padding: 8 }}
        //     >
        //       <span style={{ padding: "0px 8px" }}>Multi Select</span>
        //     </ToggleButton>
        //   </ToggleButtonGroup>
        // </Grid>
        <>
          <Grid
            item
            xs={12}
            className={classes.gridItem}
            style={{ marginTop: 10 }}
          >
            <Typography
              className={classes.typographySubtitle2}
              variant="subtitle2"
            >
              QUESTION
            </Typography>
            <TextField
              value={otherData.question || ""}
              onChange={e => {
                dispatch({
                  type: SET_FOLLOWUP_QUESTION,
                  value: e.target.value,
                  qid: questionIndex,
                  currentStep: currentStep,
                });
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            className={classes.gridItem}
            style={{ marginTop: 10 }}
          >
            <Typography
              className={classes.typographySubtitle2}
              variant="subtitle2"
            >
              PLACEHOLDER
            </Typography>
            <TextField
              value={otherData.text_config.placeholder || ""}
              onChange={e => {
                dispatch({
                  type: SET_TXCFG_PLACEHOLDER,
                  value: e.target.value,
                  id: currentStep,
                  questionIndex: questionIndex,
                });
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            className={classes.gridItem}
            style={{ marginTop: 10 }}
          >
            <TextBasedQuestion
              id={currentStep}
              questionIndex={questionIndex}
              text_config={otherData.text_config}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} style={{ marginTop: 10 }}>
        <Grid container style={{ alignItems: "center" }}>
          <Grid item xs={3}>
            <Typography className={classes.typographySubtitle2}>
              ASK IF USER SELECTS
            </Typography>
          </Grid>
          <Grid item xs={3} className={classes.gridItem}>
            <MultiSelect
              style={{
                maxWidth: 100,
                minWidth: 80,
                marginLeft: 20,
              }}
              single
              clearable={false}
              isSearchable={false}
              options={reactionOptions}
              value={
                (ruleData.min || ruleData.min === 0) &&
                getEmojiFromMapper(ruleData.min, emojiIndexMapper)
              }
              handleChange={value => {
                //The component handles 0 by sending back empty string, so this check
                let valueToUpdate = !value ? 0 : value;
                if (
                  (isNaN(ruleData.max) || valueToUpdate <= ruleData.max) &&
                  !validateRatingSelection(valueToUpdate, "max")
                ) {
                  dispatch({
                    type: SET_MIN_RATING,
                    currentStep: currentStep,
                    qid: questionIndex,
                    value: valueToUpdate,
                  });
                  updateOptionsAvailable(valueToUpdate, ruleData.max, "remove");
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <RowDiv styles={{ alignItems: "center" }}>
              <span style={{ fontSize: 18, fontWeight: 800, margin: 15 }}>
                {"≤"}
              </span>
              <Typography className={classes.typographySubtitle2}>
                RATING
              </Typography>
              <span style={{ fontSize: 18, fontWeight: 800, margin: 20 }}>
                {"≤"}
              </span>
            </RowDiv>
          </Grid>
          <Grid item xs={3} className={classes.gridItem}>
            <MultiSelect
              style={{ maxWidth: 100, minWidth: 80 }}
              single
              clearable={false}
              isSearchable={false}
              options={reactionOptions}
              value={
                (ruleData.max || ruleData.max === 0) &&
                getEmojiFromMapper(ruleData.max, emojiIndexMapper)
              }
              handleChange={value => {
                //The component handles 0 by sending back empty string, so this check
                let valueToUpdate = !value ? 0 : value;
                if (
                  (isNaN(ruleData.min) || valueToUpdate >= ruleData.min) &&
                  !validateRatingSelection(valueToUpdate, "min")
                ) {
                  dispatch({
                    type: SET_MAX_RATING,
                    currentStep: currentStep,
                    qid: questionIndex,
                    value: valueToUpdate,
                  });
                  updateOptionsAvailable(ruleData.min, valueToUpdate, "remove");
                }
              }}
            />
          </Grid>
        </Grid>
        <Typography
          className={classes.typographySubtitle2}
          style={{ color: "red" }}
          variant="subtitle2"
        >
          {errorMessage}
        </Typography>
      </Grid>
    </Paper>
  );
};

export default RatingQuestionFollowupBuilder;
