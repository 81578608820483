import React, { useState, useEffect, useRef } from "react";
import {
  IconButton,
  Grid,
  Button,
  TextField,
  Typography,
} from "@material-ui/core";
import FilterIcon from "@material-ui/icons/FilterList";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import DoneIcon from "@material-ui/icons/Done";
import Box from "../../../../ReusableComponents/Box";
import CustomMaterialUIDialog from "../../../../ReusableComponents/CustomMaterialUIDialog";
import MultiSelect from "../../../../ReusableComponents/MultiSelect";
import EventBuilder from "../../../../ReusableComponents/EventBuilder";
import {
  APXOR_SDK_CATEGORY_CONSTANTS,
  ContainsOperators,
} from "../../../../../constants";
import Loading from "../../../../ReusableComponents/Loading";
import Apxor from "apxor";

const TIME_SLOTS = [
  {
    label: "1 Min",
    value: 60,
  },
  {
    label: "5 Mins",
    value: 5 * 60,
  },
  {
    label: "15 Mins",
    value: 15 * 60,
  },
  {
    label: "30 Mins",
    value: 30 * 60,
  },
  {
    label: "1 Hour",
    value: 60 * 60,
  },
  {
    label: "1 Day",
    value: 24 * 60 * 60,
  },
  {
    label: "3 Days",
    value: 3 * 24 * 60 * 60,
  },
  {
    label: "1 Week",
    value: 7 * 24 * 60 * 60,
  },
  {
    label: "2 Weeks",
    value: 14 * 24 * 60 * 60,
  },
  {
    label: "3 Weeks",
    value: 21 * 24 * 60 * 60,
  },
  {
    label: "30 Days",
    value: 30 * 24 * 60 * 60,
  },
  {
    label: "60 Days",
    value: 60 * 24 * 60 * 60,
  },
  {
    label: "90 Days",
    value: 90 * 24 * 60 * 60,
  },
];

export default function FunnelHeader({
  appId,
  filters: { name, events, time },
  funnels: funnels_list,
  onFiltersChange,
  getFunnel,
  pending,
  saveNewFunnel,
  deleteFunnel,
  eventList,
}) {
  const [title, setTitle] = useState(name || "New Funnel");
  const [selectedFunnel, setSelectedFunnel] = useState("");
  const [deleteFunnelTriggered, setDeleteFunnelTriggered] = useState(false);
  const [saveFunnelTriggered, setSaveFunnelTriggered] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);
  const [funnelName, setFunnelName] = useState("");

  const prevFunnels = useRef(funnels_list);

  // This effect runs whenever a new funnel is saved or a funnel gets deleted
  // TODO: These Ref based updates are always dangerous. Keep an eye on it
  useEffect(() => {
    if (prevFunnels.current.length !== funnels_list.length) {
      const index = funnels_list.findIndex((f) => f.id === selectedFunnel);
      if (index < 0) {
        setSelectedFunnel("");
        setTitle("New Funnel");
      }
    }
    prevFunnels.current = funnels_list;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funnels_list]);

  return (
    <Box
      title={title}
      defaultExpanded
      controls={
        <>
          {selectedFunnel && (
            <IconButton
              disabled={deleteFunnelTriggered}
              style={{ marginRight: 8 }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setDeleteFunnelTriggered(true);
              }}
            >
              <DeleteIcon color="error" />
            </IconButton>
          )}
          <MultiSelect
            value={selectedFunnel}
            options={funnels_list.map((o) => {
              return { label: o.name, value: o._id };
            })}
            placeholder="Select Saved Funnel"
            single
            handleChange={(funnelId) => {
              if (selectedFunnel !== funnelId) {
                setSelectedFunnel(funnelId);
                const { events = [], name = "New Funnel", time = 5 * 60 } =
                  funnels_list.find(
                    (funnel) =>
                      funnel.id === funnelId || funnel._id === funnelId
                  ) || {};
                setTitle(name);
                if (onFiltersChange) {
                  onFiltersChange({
                    events,
                    name,
                    time,
                  });
                }
              }
            }}
            clearable
            style={{ maxWidth: 460 }}
          />

          <CustomMaterialUIDialog
            dialogActions={
              <>
                <Button
                  onClick={() => setDeleteFunnelTriggered(false)}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    if (
                      funnels_list.map((o) => o.id).includes(selectedFunnel)
                    ) {
                      deleteFunnel(selectedFunnel);
                    }
                    setDeleteFunnelTriggered(false);
                  }}
                  color="primary"
                >
                  Delete
                </Button>
              </>
            }
            dialogContent={
              <Typography variant="body2">
                Delete funnel - <b> {title} </b> ?
              </Typography>
            }
            maxWidth={"xs"}
            title={"Delete funnel"}
            titleVariant={"h4"}
            noTitleBg={true}
            openFlag={deleteFunnelTriggered}
            onDialogClose={() => setDeleteFunnelTriggered(false)}
          />
        </>
      }
      icon={<FilterIcon color={"primary"} />}
      footer={
        <Grid container spacing={8} justify="flex-end">
          <Grid item xs={6} md>
            <MultiSelect
              placeholder="Time to complete Funnel"
              value={time}
              options={TIME_SLOTS}
              handleChange={(time) => {
                if (onFiltersChange) {
                  onFiltersChange({
                    time: time,
                  });
                }
              }}
              style={{ width: 200, marginLeft: 12 }}
              single
              margin="dense"
            />
          </Grid>
          <Grid item xs={5} md={4} lg={3}>
            {
              <Button
                style={{ marginTop: 8, float: "right" }}
                size="small"
                disabled={
                  saveFunnelTriggered ||
                  !(
                    time &&
                    events.length > 1 &&
                    events[1].name &&
                    events[1].name.length > 0
                  )
                }
                variant="contained"
                color="primary"
                onClick={() => setSaveFunnelTriggered(true)}
              >
                <SaveIcon />
                Save Funnel
              </Button>
            }
            <Button
              style={{ marginTop: 8, marginRight: 8, float: "right" }}
              size="small"
              disabled={
                pending ||
                saveClicked ||
                !(
                  time &&
                  events.length > 1 &&
                  events[1].name &&
                  events[1].name.length > 0
                )
              }
              variant="contained"
              color="primary"
              onClick={() => {
                setSaveClicked(true);
                getFunnel(selectedFunnel);
                setSaveClicked(false);
                Apxor.logEvent(
                  "GetFunnelClicked",
                  {},
                  APXOR_SDK_CATEGORY_CONSTANTS.FUNNELS
                );
              }}
            >
              {!pending && (
                <>
                  <DoneIcon />
                  Get Funnel
                </>
              )}
              {pending && <Loading size={24} />}
            </Button>
          </Grid>
          <CustomMaterialUIDialog
            dialogActions={
              <>
                <Button
                  onClick={() => setSaveFunnelTriggered(false)}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    if (funnelName && funnelName.trim().length > 0) {
                      saveNewFunnel({
                        name: funnelName,
                        events,
                        time,
                      });
                      setSaveFunnelTriggered(false);
                    }
                  }}
                  color="primary"
                >
                  Save
                </Button>
              </>
            }
            dialogContent={
              <TextField
                value={funnelName || ""}
                fullWidth
                onChange={(e) => {
                  setFunnelName(e.target.value);
                }}
                label="Funnel Name"
                placeholder="Funnel Name"
                type="text"
                required
              />
            }
            maxWidth={"xs"}
            title={"Save funnel"}
            titleVariant={"h4"}
            noTitleBg={true}
            openFlag={saveFunnelTriggered}
            onDialogClose={() => setSaveFunnelTriggered(false)}
          />
        </Grid>
      }
    >
      <EventBuilder
        appId={appId}
        events={eventList}
        selectedEvents={events}
        operators={ContainsOperators}
        withBox={true}
        onQueryChange={(events) => {
          if (onFiltersChange) {
            onFiltersChange({
              events: events,
            });
          }
        }}
        showAddStepAfter={true}
        hideCondition={true}
        addButtonNode={<>Add Step</>}
      />
    </Box>
  );

}