import { useCallback, useEffect, useMemo, useState } from "react";
import { APP_PLATFORMS } from "../../../../../../constants";
import { useAuth } from "../../../../../../contexts/AuthContext";
import {
  deletePushSettings,
  getPushSettings,
  saveIosPushSettings,
  saveAndroidPushSettings,
} from "../../actions";

export default function usePushConfigKeys({ app_id, platform }) {
  const auth = useAuth();
  const [fcmKey, setFcmKey] = useState("");
  const [team_id, set_team_id] = useState("");
  const [bundle_id, set_bundle_id] = useState("");
  const [key_id, set_key_id] = useState("");
  const [auth_file, set_auth_file] = useState();

  const [android_btn_type, set_android_btn_type] = useState("save");
  const [ios_btn_type, set_ios_btn_type] = useState("save");

  useEffect(() => {
    (async () => {
      try {
        switch (platform) {
          case APP_PLATFORMS.android: {
            const res = await getPushSettings(auth, app_id);
            setFcmKey(res?.fcmKey || "");
            set_android_btn_type(res?.fcmKey ? "reset" : "save");
            break;
          }
          case APP_PLATFORMS.ios: {
            const res = await getPushSettings(
              auth,
              app_id,
              null,
              APP_PLATFORMS.ios,
            );
            set_team_id(res?.team_id || "");
            set_bundle_id(res?.bundle_id || "");
            set_key_id(res?.key_id || "");
            set_auth_file(!!res?.file_name);
            set_ios_btn_type(
              res?.team_id && res?.bundle_id && res?.key_id && res?.file_name
                ? "reset"
                : "save",
            );
            break;
          }
          case APP_PLATFORMS.omni: {
            const [androidRes, iosRes] = await Promise.all([
              getPushSettings(auth, app_id),
              getPushSettings(auth, app_id, APP_PLATFORMS.ios),
            ]);
            setFcmKey(androidRes?.fcmKey || "");
            set_android_btn_type(androidRes?.fcmKey ? "reset" : "save");

            set_team_id(iosRes?.team_id || "");
            set_bundle_id(iosRes?.bundle_id || "");
            set_key_id(iosRes?.key_id || "");
            set_auth_file(!!iosRes?.file_name);
            set_ios_btn_type(
              iosRes?.team_id &&
                iosRes?.bundle_id &&
                iosRes?.key_id &&
                iosRes?.file_name
                ? "reset"
                : "save",
            );
            break;
          }
          default:
            break;
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [app_id, auth, platform]);

  const ios_done = useMemo(
    () => !!(team_id && bundle_id && key_id && auth_file),
    [bundle_id, key_id, team_id, auth_file],
  );

  const is_save_disabled = useMemo(() => {
    switch (platform) {
      case APP_PLATFORMS.ios:
        return !ios_done;
      case APP_PLATFORMS.android:
        return !fcmKey;
      case APP_PLATFORMS.omni:
        return !(
          (fcmKey && !ios_done) ||
          (!fcmKey && ios_done) ||
          (fcmKey && ios_done)
        );
      default:
        return true;
    }
  }, [platform, ios_done, fcmKey]);

  const reset_keys = useCallback(async () => {
    switch (platform) {
      case APP_PLATFORMS.android:
        await deletePushSettings(auth, app_id, null);
        setFcmKey("");
        set_android_btn_type("save");
        break;
      case APP_PLATFORMS.ios:
        await deletePushSettings(auth, app_id, null, APP_PLATFORMS.ios);
        set_team_id("");
        set_bundle_id("");
        set_key_id("");
        set_auth_file(null);
        set_ios_btn_type("save");
        break;
      default:
        break;
    }
  }, [app_id, auth, platform]);

  const save_keys = useCallback(async () => {
    switch (platform) {
      case APP_PLATFORMS.android:
        await saveAndroidPushSettings(auth, app_id, null, { fcmKey });
        set_android_btn_type("reset");
        break;
      case APP_PLATFORMS.ios:
        await saveIosPushSettings(
          auth,
          app_id,
          {
            team_id,
            bundle_id,
            key_id,
            file_name: auth_file?.name,
          },
          auth_file,
        );
        set_ios_btn_type("reset");
        break;
      default:
        break;
    }
  }, [platform, auth, app_id, fcmKey, team_id, bundle_id, key_id, auth_file]);

  return {
    fcmKey,
    setFcmKey,
    team_id,
    set_team_id,
    bundle_id,
    set_bundle_id,
    key_id,
    set_key_id,
    auth_file,
    set_auth_file,
    reset_keys,
    save_keys,
    android_btn_type,
    ios_btn_type,
    ios_done,
    is_save_disabled,
  };
}
