import { Typography } from "@material-ui/core";
import React from "react";
import ColumnDiv from "../../../../../../../../../ReusableComponents/ColumnDiv";
import RowDiv from "../../../../../../../../../ReusableComponents/RowDiv";
import TruncatedText from "../../../../../../../../../ReusableComponents/TruncatedText";
import { DATA_TYPES } from "../../../../constants";
import { getOperatorString } from "../../../../utils";
import { useSummaryStyles } from "../../../constants";

const RenderProperty = ({ attribute, index }) => {
  const headerClasses = useSummaryStyles();

  const { name, op = "EQ", val } = attribute;

  return (
    <RowDiv center>
      <div style={{ width: 30, borderBottom: "1px solid #C5CDD2" }}></div>
      <RowDiv center applyMargin>
        <Typography className={headerClasses.body}>
          {index === 0 ? "where" : "and where"}
        </Typography>
        <TruncatedText disabled value={name} />
        <TruncatedText disabled value={getOperatorString(op)} />
        <TruncatedText
          disabled
          value={
            (Array.isArray(val) ? val.join(", ") : val) || "No value selected"
          }
        />
      </RowDiv>
    </RowDiv>
  );
};

export default function RenderPropertyRows({ info: additional_info }) {
  const additionalInfo = Array.isArray(additional_info)
    ? additional_info
    : Object.keys(additional_info).map((attr, index) => {
        let values = additional_info[attr];
        if (typeof values !== "object") {
          values = {
            op: "EQ",
            t: "s",
            tl: DATA_TYPES.STRING,
            val: values,
          };
        }
        return {
          index,
          name: attr,
          ...values,
        };
      });

  return (
    <ColumnDiv styles={{ borderLeft: "1px solid #C5CDD2" }}>
      {additionalInfo.map((attr, index) => (
        <RenderProperty key={index} attribute={attr} index={index} />
      ))}
    </ColumnDiv>
  );
}
