import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";
import DevicesIcon from "@material-ui/icons/Devices";
import { APP_PLATFORMS } from "../../../../../../constants";
import { useAuth } from "../../../../../../contexts/AuthContext";
import {
  displayTimeFromNow,
  hasAndIsDefined,
  readableDeviceName,
  roundOffNumber,
} from "../../../../../../utils";
import NickNameDialog from "../../../../../ReusableComponents/NickNameDialog";
import {
  addTestDeviceAPI,
  getTestDevicesAPI,
  removeTestDeviceAPI,
} from "../../../../common/actions";
import { renderValue } from "../constants";
import { useTrackedState } from "../../store";
import Box from "../../../../../ReusableComponents/Box";
import { useDateFilters } from "../../../../common/utils";

export const UserProfile = ({ appId, app, userId }) => {
  const auth = useAuth();
  const [dateFilters] = useDateFilters();

  const state = useTrackedState();
  const [nick_name, setNickName] = useState("");
  const [showDialog, setShowDialog] = useState(false);

  const [appTestDevices, setTestDevices] = useState([]);
  const [fetchTestDevices, setFetchTestDevices] = useState(false);

  const { dynamic_basic_info, static_basic_info } = state;
  const {
    installation_date,
    uninstall_date,
    device_info: {
      os_version,
      hardware_model,
      device_dimensions: { width = 0, height = 0 } = {},
      dpi,
      dimensions_in_pixels: { width: pWidth = 0, height: pHeight = 0 } = {},
    } = {},
    location_info: { city, countryCode } = {},
  } = static_basic_info;
  const { customerId = undefined } = dynamic_basic_info;

  const { app: { basic_info: { platform } = {} } = {} } = app;

  const isTestDevice =
    appTestDevices.findIndex((device) => device.id === userId) > -1;

  const isWeb = platform === APP_PLATFORMS.web;

  const device = appTestDevices.find((device) => device.id === userId) || {};

  const title =
    hasAndIsDefined(device, "nick_name") && device.nick_name !== ""
      ? device.nick_name
      : userId;

  const subtitle =
    hasAndIsDefined(device, "nick_name") && device.nick_name !== ""
      ? userId
      : device.nick_name;

  const onNickDone = () => {
    const hardware_model = static_basic_info.device_info.hardware_model;

    addTestDeviceAPI(auth, appId, userId, dateFilters, {
      id: userId,
      nick_name: nick_name,
      model: hardware_model,
      custom_id: customerId,
    }).then(() => setFetchTestDevices(!fetchTestDevices));
    setNickName("");
    setShowDialog(false);
  };

  useEffect(() => {
    getTestDevicesAPI(auth, appId, {}).then((response) => {
      setTestDevices(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchTestDevices]);

  return (
    <Box
      title={
        <div>
          <Typography
            variant="h5"
            noWrap
            style={{ fontWeight: 600, marginLeft: 5 }}
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography
              variant="caption"
              noWrap
              style={{ fontWeight: 600, marginLeft: 5 }}
            >
              {subtitle}
            </Typography>
          )}
        </div>
      }
      icon={
        <img
          src="/assets/img/user.png"
          alt="User Avatar"
          style={{ width: "40px" }}
        />
      }
      controls={
        <div>
          {!isTestDevice && (
            <Tooltip
              id="add-test-device"
              title="Click to add as Test Device"
              placement="top"
            >
              <IconButton
                aria-label="Add"
                onClick={() => {
                  setNickName("");
                  setShowDialog(true);
                }}
              >
                <DevicesIcon color="action" />
              </IconButton>
            </Tooltip>
          )}
          {isTestDevice && (
            <Tooltip
              id="remove-test-device"
              title="Click to remove as Test Device"
              placement="top"
            >
              <IconButton
                aria-label="Remove"
                onClick={() =>
                  removeTestDeviceAPI(auth, appId, userId).then(() =>
                    setFetchTestDevices(!fetchTestDevices)
                  )
                }
              >
                <ImportantDevicesIcon color="primary" />
              </IconButton>
            </Tooltip>
          )}
        </div>
      }
    >
      <List>
        <ListItem>
          <ListItemText
            primary={
              <span title={hardware_model}>
                Device {renderValue(readableDeviceName(hardware_model))}
              </span>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText primary={<span>OS {renderValue(os_version)}</span>} />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <span title={isWeb ? "Device Pixel Ratio" : "Dots Per Inch"}>
                {isWeb ? "DPR" : "DPI"} {renderValue(roundOffNumber(dpi))}
              </span>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <span>Resolution {renderValue(`${pWidth} X ${pHeight}`)}</span>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <span>Dimensions {renderValue(`${width} X ${height}`)}</span>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <span>Location {renderValue(`${city} (${countryCode})`)}</span>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <span>
                Installation Date{" "}
                {renderValue(displayTimeFromNow(installation_date))}
              </span>
            }
          />
        </ListItem>
        {!isWeb && (
          <ListItem>
            <ListItemText
              primary={
                <span>
                  Uninstall Date{" "}
                  {renderValue(displayTimeFromNow(uninstall_date))}
                </span>
              }
            />
          </ListItem>
        )}
      </List>
      {showDialog && (
        <NickNameDialog
          nick_name={nick_name}
          onCancel={() => {
            setNickName("");
            setShowDialog(false);
          }}
          onDone={onNickDone}
          onChange={(event) => setNickName(event.target.value)}
          model={readableDeviceName(hardware_model)}
          deviceId={userId}
        />
      )}
    </Box>
  );
};
