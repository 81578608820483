import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useIntegration from "../../../Auth/integration-experience/hooks/use-integration";
import AppUnderReviewDialog from "../../components/AppUnderReviewDialog";
import IntegrationLayout from "../../IntegrationLayout";
import { useTrackedState } from "../../store";

export default function TestConnectionsPage() {
  const { getApplicationData } = useIntegration();
  const { app_loading } = useTrackedState();
  const { appId } = useParams();
  const [showAppUnderReviewDialog, setShowAppUnderReviewDialog] = useState(
    true
  );

  useEffect(() => {
    (async () => {
      const appData = await getApplicationData(appId);
      setShowAppUnderReviewDialog(!appData?.basic_info?.approved);
    })();
  }, [appId, getApplicationData]);

  return (
    <IntegrationLayout>
      <>TestConnections Page</>
      {!app_loading && <AppUnderReviewDialog open={showAppUnderReviewDialog} />}
    </IntegrationLayout>
  );
}
