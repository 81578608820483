import React, { useEffect } from "react";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useTracked } from "../store";
import Switch from "../../../../ReusableComponents/Switch";
import {
  SET_TTL,
  PRIORITY_ENUM,
  SET_PRIORITY,
  COLLAPSING_ENUM,
  SET_IS_COLLAPSING_ENABLED,
  SET_COLLAPSING_KEY,
  SET_IS_VALID_PUSH,
} from "../constants";
import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import MomentUtils from "@date-io/moment";

import ActionsTextField from "../../../../ReusableComponents/ActionsTextField";
import { APP_PLATFORMS } from "../../../../../constants";

export function ConfigSelector({ errors }) {
  const [state, dispatch] = useTracked();
  const { push_config } = state;
  const { payload, meta = {} } = push_config;
  const { ttl, priority, is_collapsing_enabled, collapsing_key } = payload;

  useEffect(() => {
    dispatch({
      type: SET_IS_VALID_PUSH,
      value: Object.keys(errors).length === 0,
    });
  }, [dispatch, errors]);

  return (
    <Grid container>
      <Grid item xs={6} md={6}>
        <br />
        <Typography>Time To Live (TTL)</Typography>
        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
          <DateTimePicker
            value={moment(ttl)}
            disablePast
            ampm={false}
            format="LLL"
            onChange={(date) => {
              const reqDate = moment(date);
              dispatch({
                type: SET_TTL,
                value: moment(reqDate).valueOf(),
              });
            }}
          />
        </MuiPickersUtilsProvider>
        {errors?.payload?.ttl && (
          <Typography
            variant={"body2"}
            style={{ padding: "2px 4px", color: "red" }}
          >
            {errors.payload.ttl}{" "}
          </Typography>
        )}
        <Typography>Priority</Typography>
        <Switch
          data={Object.keys(PRIORITY_ENUM).map((o) => ({
            name: o,
            value: PRIORITY_ENUM[o],
          }))}
          handleChange={(priority) =>
            dispatch({ type: SET_PRIORITY, value: priority })
          }
          value={priority === 0 ? priority.toString() : priority}
          containerStyles={{ marginTop: 2 }}
        />
        {meta?.platform !== APP_PLATFORMS.ios && (
          <>
            <Typography>Collapsing Configuration</Typography>
            <Switch
              handleChange={value =>
                dispatch({ type: SET_IS_COLLAPSING_ENABLED, value: value })
              }
              data={Object.keys(COLLAPSING_ENUM).map(key => ({
                name: key,
                value: COLLAPSING_ENUM[key],
              }))}
              value={is_collapsing_enabled}
              containerStyles={{ marginTop: 2 }}
            />
            {is_collapsing_enabled === true && (
              <>
                <Typography> Collapsing Key</Typography>
                <ActionsTextField
                  type="text"
                  placeholder="Collapsing Key"
                  value={collapsing_key}
                  onChange={e =>
                    dispatch({
                      type: SET_COLLAPSING_KEY,
                      value: e.target.value,
                    })
                  }
                  fullWidth
                  margin="normal"
                />
              </>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
}
