import { Grid, Typography } from "@material-ui/core";
import React from "react";
import RowDiv from "../../../../../../../../../ReusableComponents/RowDiv";
import { useSummaryStyles } from "../../../constants";

export default function CampaignLimitsSummary({ frequency }) {
  const headerClasses = useSummaryStyles();

  return (
    <Grid container spacing={1} style={{ marginLeft: -8 }}>
      <Grid item xs={12}>
        <RowDiv center applyMargin>
          <Typography className={headerClasses.heading}>Limits</Typography>
          <Typography variant="subtitle2" style={{ fontWeight: 500 }}>
            {frequency.count} times{" "}
            {frequency.validity === "OVERALL" ? "Overall" : "Per Session"}
          </Typography>
        </RowDiv>
      </Grid>
      <Grid item xs={12}>
        {frequency.DSLimits && (
          <>
            <RowDiv center applyMargin>
              <Typography className={headerClasses.heading}>
                Day Limits
              </Typography>
              <Typography className={headerClasses.body}>
                {frequency.day_lmt} time(s) per day
              </Typography>
            </RowDiv>
            <RowDiv center applyMargin>
              <Typography className={headerClasses.heading}>
                Per Session Limits
              </Typography>
              <Typography className={headerClasses.body}>
                {frequency.ses_lmt} time(s) per session
              </Typography>
            </RowDiv>
          </>
        )}
      </Grid>
    </Grid>
  );
}
