import { makePostRequest } from "../../../common/actions";
import { RETENTION_ENUM, DAYS_ENUM_FOR_USAGE } from "./constants";

const RETENTION_APIS = {
  [RETENTION_ENUM.FTU]: "ftu-retention",
  [RETENTION_ENUM.ALL]: "all-retention",
  [RETENTION_ENUM.RETURNING]: "returning-retention",
};
const RETENTION_USAGE_API = "retention-usage";

function normalizedQuery(queryObj = {}) {
  return queryObj && queryObj.hasOwnProperty("name") ? queryObj : null;
}

export function getRetentionData(
  auth,
  appId,
  queryParams,
  filters,
  retentionType = RETENTION_ENUM.FTU,
  dayWindow,
  query
) {
  //FIXME: Correct this logic
  const getCompatibleQuery = () => {
    let compatibleQuery = {
      first_event: normalizedQuery(query.first_event),
      next_event: normalizedQuery(query.next_event),
      ...filters,
    };
    if (retentionType !== RETENTION_ENUM.FTU) {
      compatibleQuery = {
        first_event: compatibleQuery.first_event,
        ...filters,
      }; //What contract is this ? :(
    }
    return compatibleQuery;
  };
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    RETENTION_APIS[retentionType],
    auth,
    appId,
    { ...queryParams, dayWindow },
    getCompatibleQuery(),
    onSuccessCallback,
    onFailureCallback
  );
}

export function getRetentionUsage(
  auth,
  appId,
  queryParams,
  filters,
  days = DAYS_ENUM_FOR_USAGE
) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    RETENTION_USAGE_API,
    auth,
    appId,
    { ...queryParams, day: days },
    filters,
    onSuccessCallback,
    onFailureCallback
  );
}
