import {
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import {
  TimePicker,
  MuiPickersUtilsProvider,
  DateTimePicker,
} from "@material-ui/pickers";
import {
  SET_PUBLISH_END_DATE,
  SET_PUBLISH_SHOW_AT_SPECIFIC_TIME,
  SET_PUBLISH_START_DATE,
  SET_PUBLISH_TIME_LIMITS_END,
  SET_PUBLISH_TIME_LIMITS_START,
  SET_TEST_END_DATE,
  SET_TEST_SHOW_AT_SPECIFIC_TIME,
  SET_TEST_START_DATE,
  SET_TEST_TIME_LIMITS_END,
  SET_TEST_TIME_LIMITS_START,
  SET_TIME_LIMITS_END,
  SET_TIME_LIMITS_START,
  SET_TOGGLE_TIME_LIMITS_ENABLED,
  SET_VALIDITY_END,
  SET_VALIDITY_START,
  TOGGLE_SWITCH_STYLES,
  APP_PLATFORMS,
} from "../../../../constants";
import ToggleSwitch from "../../../../../../../../ReusableComponents/ToggleSwitch";
import { getTimeLimitsEnabledKey } from "../../../../reducer";
import { useTracked } from "../../../../store";
import { useTypographyStyles } from "../../../../../../utils";
import RowDiv from "../../../../../../../../ReusableComponents/RowDiv";
import ColumnDiv from "../../../../../../../../ReusableComponents/ColumnDiv";
import { isExperienceDashboard } from "../../../../../../../../../config";
import BootstrapTooltip from "../../../../../../../../ReusableComponents/BootstrapTooltip";
import LockIcon from "@material-ui/icons/Lock";

const useStyles = makeStyles((theme) => ({
  formControlLabel: (props) => ({
    marginLeft: 8,
  }),
  gridItem: (props) => ({
    display: "flex",
  }),
  uiPicker: (props) => ({
    marginLeft: 0,
  }),
}));

export default function Scheduling() {
  const classes = useStyles();
  const typographyClasses = useTypographyStyles();
  const [displayTooltip, setDisplayTooltip] = useState(false);

  const [state, dispatch] = useTracked();
  const {
    config: {
      meta: { platform, isExperiment = false },
      time_limits,
      validity: { start_date, end_date },
      validity: { start_date: test_start_date, end_date: test_end_date },
      experiment: {
        settings: { isManualExperiment } = {
          isManualExperiment: true,
        },
        publishSettings: {
          time_settings: {
            start_date: publish_start_date,
            end_date: publish_end_date,
            time_limits_in_day: publish_show_at_specific_time,
            time_limits: {
              start_time: publish_start_time,
              end_time: publish_end_time,
            },
          },
        } = {
          time_settings: {
            start_date: "",
            end_date: "",
            time_limits_in_day: false,
            time_limits: {
              start_time: "",
              end_time: "",
            },
          },
        },
      } = {},
    },
    config,
  } = state;

  if (isExperiment) {
    return (
      <Grid container spacing={2} id="schedule-campaign-ab">
        <Grid
          item
          xs={6}
          className={classes.gridItem}
          style={{ borderRight: "1px solid #CCD4DA" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.gridItem}>
              <Typography
                className={typographyClasses.h5_800}
                style={{ marginBottom: 10 }}
              >
                TEST
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <Grid container>
                <Grid item xs={4}>
                  <Typography
                    variant="h5"
                    className={typographyClasses.h5_600}
                    style={{ marginTop: 10 }}
                  >
                    Start Date <span style={{ fontSize: 12 }}>(in IST)</span>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                    <DateTimePicker
                      disabled={isExperienceDashboard()}
                      className={classes.uiPicker}
                      value={moment(test_start_date)}
                      disablePast
                      ampm={false}
                      onChange={(date) => {
                        const reqDate = moment(date);
                        dispatch({
                          type: SET_TEST_START_DATE,
                          date_time: reqDate.toISOString(),
                          date: reqDate.format("YYYY-MM-DD"),
                          time: reqDate.format("HH:mm"),
                        });
                      }}
                      style={{ maxWidth: 240 }}
                      TextFieldComponent={TextField}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" style={{ padding: 5 }}>
                            <CalendarTodayIcon color="primary" />
                            <AccessTimeIcon color="primary" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <Grid container>
                <Grid item xs={4}>
                  <Typography
                    variant="h5"
                    className={typographyClasses.h5_600}
                    style={{ marginTop: 10 }}
                  >
                    End Date <span style={{ fontSize: 12 }}>(in IST)</span>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                    <DateTimePicker
                      disabled={isExperienceDashboard()}
                      className={classes.uiPicker}
                      value={moment(test_end_date)}
                      minDate={start_date}
                      minDateMessage="End Date should not be earlier than Start Date"
                      disablePast
                      ampm={false}
                      onChange={(date) => {
                        const reqDate = moment(date);
                        dispatch({
                          type: SET_TEST_END_DATE,
                          date_time: reqDate.toISOString(),
                        });
                      }}
                      style={{ maxWidth: 240 }}
                      TextFieldComponent={TextField}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" style={{ padding: 5 }}>
                            <CalendarTodayIcon color="primary" />
                            <AccessTimeIcon color="primary" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.gridItem}
              style={{
                marginTop: config[getTimeLimitsEnabledKey(platform)] ? 0 : 9,
                display: "flex",
                flexDirection: "column",
                gap: 10,
              }}
            >
              <RowDiv center>
                <Typography variant="h5" className={typographyClasses.h5_600}>
                  Show at specific time of the day
                </Typography>
                <ToggleSwitch
                  classeName={classes.formControlLabel}
                  styles={{ marginLeft: 8 }}
                  checked={config[getTimeLimitsEnabledKey(platform)]}
                  handleChange={(checked, e) => {
                    dispatch({
                      type: SET_TEST_SHOW_AT_SPECIFIC_TIME,
                      enabled: checked,
                    });
                  }}
                  style={TOGGLE_SWITCH_STYLES()}
                />
              </RowDiv>
              {config[getTimeLimitsEnabledKey(platform)] && (
                <ColumnDiv styles={{ marginTop: 8, gap: 10 }}>
                  <Grid container>
                    <Grid item xs={4}>
                      <Typography
                        style={{ marginRight: 8 }}
                        className={typographyClasses.h5_600}
                      >
                        between
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <MuiPickersUtilsProvider
                        utils={MomentUtils}
                        moment={moment}
                      >
                        <TimePicker
                          format="hh:mm a z"
                          value={moment(time_limits.start_time, "HH:mm").utc(
                            true,
                          )}
                          onChange={(time) => {
                            const start_time = time.format("HH:mm");
                            dispatch({
                              type: SET_TEST_TIME_LIMITS_START,
                              start_hour_min: start_time,
                            });
                          }}
                          style={{ maxWidth: 155 }}
                          TextFieldComponent={TextField}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                style={{ padding: 5 }}
                              >
                                <AccessTimeIcon color="primary" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4}>
                      <Typography className={typographyClasses.h5_600}>
                        and
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <MuiPickersUtilsProvider
                        utils={MomentUtils}
                        moment={moment}
                      >
                        <TimePicker
                          format="hh:mm a z"
                          value={moment(time_limits.end_time, "HH:mm").utc(
                            true,
                          )}
                          minDate={moment(time_limits.start_time, "HH:mm").utc(
                            true,
                          )}
                          onChange={(time) => {
                            const end_time = time.format("HH:mm");
                            dispatch({
                              type: SET_TEST_TIME_LIMITS_END,
                              end_hour_min: end_time,
                            });
                          }}
                          style={{ maxWidth: 155 }}
                          TextFieldComponent={TextField}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                style={{ padding: 5 }}
                              >
                                <AccessTimeIcon color="primary" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                </ColumnDiv>
              )}
            </Grid>
          </Grid>
        </Grid>
        {!isManualExperiment && (
          <Grid
            item
            xs={6}
            className={classes.gridItem}
            style={{ paddingLeft: 12 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.gridItem}>
                <Typography
                  className={typographyClasses.h5_800}
                  style={{ marginBottom: 10 }}
                >
                  CAMPAIGN
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.gridItem}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography
                      variant="h5"
                      className={typographyClasses.h5_600}
                      style={{ marginTop: 10 }}
                    >
                      Start Date <span style={{ fontSize: 12 }}>(in IST)</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      moment={moment}
                    >
                      <DateTimePicker
                        className={classes.uiPicker}
                        value={moment(publish_start_date)}
                        disablePast
                        ampm={false}
                        minDate={test_end_date}
                        minDateMessage="Campaign Start Date should not be earlier than Test End Date"
                        onChange={(date) => {
                          const reqDate = moment(date);
                          dispatch({
                            type: SET_PUBLISH_START_DATE,
                            date_time: reqDate.toISOString(),
                            date: reqDate.format("YYYY-MM-DD"),
                            time: reqDate.format("HH:mm"),
                          });
                        }}
                        style={{ maxWidth: 240 }}
                        TextFieldComponent={TextField}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{ padding: 5 }}
                            >
                              <CalendarTodayIcon color="primary" />
                              <AccessTimeIcon color="primary" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.gridItem}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography
                      variant="h5"
                      className={typographyClasses.h5_600}
                      style={{ marginTop: 10 }}
                    >
                      End Date <span style={{ fontSize: 12 }}>(in IST)</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      moment={moment}
                    >
                      <DateTimePicker
                        className={classes.uiPicker}
                        value={moment(publish_end_date)}
                        disablePast
                        ampm={false}
                        minDate={publish_start_date}
                        minDateMessage="End Date should not be earlier than Start Date"
                        onChange={(date) => {
                          const reqDate = moment(date);
                          dispatch({
                            type: SET_PUBLISH_END_DATE,
                            date_time: reqDate.toISOString(),
                          });
                        }}
                        style={{ maxWidth: 240 }}
                        TextFieldComponent={TextField}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{ padding: 5 }}
                            >
                              <CalendarTodayIcon color="primary" />
                              <AccessTimeIcon color="primary" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                className={classes.gridItem}
                style={{
                  marginTop: publish_show_at_specific_time ? 0 : 9,
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                }}
              >
                <RowDiv center>
                  <Typography variant="h5" className={typographyClasses.h5_600}>
                    Show at specific time of the day
                  </Typography>
                  <ToggleSwitch
                    classeName={classes.formControlLabel}
                    styles={{ marginLeft: 8 }}
                    checked={publish_show_at_specific_time}
                    handleChange={(checked, e) => {
                      dispatch({
                        type: SET_PUBLISH_SHOW_AT_SPECIFIC_TIME,
                        enabled: checked,
                      });
                    }}
                    style={TOGGLE_SWITCH_STYLES()}
                  />
                </RowDiv>
                {publish_show_at_specific_time && (
                  <ColumnDiv styles={{ marginTop: 8, gap: 10 }}>
                    <Grid container>
                      <Grid item xs={4}>
                        <Typography
                          style={{ marginRight: 8 }}
                          className={typographyClasses.h5_600}
                        >
                          between
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <MuiPickersUtilsProvider
                          utils={MomentUtils}
                          moment={moment}
                        >
                          <TimePicker
                            format="hh:mm a z"
                            value={moment(publish_start_time, "HH:mm").utc(
                              true,
                            )}
                            onChange={(time) => {
                              const start_time = time.format("HH:mm");
                              dispatch({
                                type: SET_PUBLISH_TIME_LIMITS_START,
                                start_hour_min: start_time,
                              });
                            }}
                            style={{ maxWidth: 155 }}
                            TextFieldComponent={TextField}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  style={{ padding: 5 }}
                                >
                                  <AccessTimeIcon color="primary" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={4}>
                        <Typography className={typographyClasses.h5_600}>
                          and
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <MuiPickersUtilsProvider
                          utils={MomentUtils}
                          moment={moment}
                        >
                          <TimePicker
                            format="hh:mm a z"
                            value={moment(publish_end_time, "HH:mm").utc(true)}
                            minDate={moment(publish_start_time, "HH:mm").utc(
                              true,
                            )}
                            onChange={(time) => {
                              const end_time = time.format("HH:mm");
                              dispatch({
                                type: SET_PUBLISH_TIME_LIMITS_END,
                                end_hour_min: end_time,
                              });
                            }}
                            style={{ maxWidth: 155 }}
                            TextFieldComponent={TextField}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  style={{ padding: 5 }}
                                >
                                  <AccessTimeIcon color="primary" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </Grid>
                  </ColumnDiv>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }

  return (
    <div
      style={{ position: "relative" }}
      onMouseEnter={() => setDisplayTooltip(true)}
      onMouseLeave={() => setDisplayTooltip(false)}
    >
      {isExperienceDashboard() && (
        <BootstrapTooltip
          open={isExperienceDashboard() && displayTooltip}
          title={
            <>
              <b>Schedule Configuration</b> feature is disabled in the demo app
            </>
          }
          placement="bottom"
        >
          <LockIcon
            color="primary"
            style={{
              zIndex: "10",
              position: "absolute",
              top: "4px",
              right: "4px",
            }}
          />
        </BootstrapTooltip>
      )}

      <Grid
        container
        spacing={2}
        id="schedule-campaign"
        style={
          isExperienceDashboard() ? { opacity: 0.5, pointerEvents: "none" } : {}
        }
      >
        <Grid item xs={12} className={classes.gridItem}>
          <Grid container>
            <Grid item xs={2}>
              <Typography
                variant="h5"
                className={typographyClasses.h5_600}
                style={{ marginTop: 10 }}
              >
                Start Date <span style={{ fontSize: 12 }}>(in IST)</span>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                <DateTimePicker
                  disabled={isExperienceDashboard()}
                  className={classes.uiPicker}
                  value={moment(start_date)}
                  disablePast
                  ampm={false}
                  onChange={(date) => {
                    const reqDate = moment(date);
                    dispatch({
                      type: SET_VALIDITY_START,
                      date_time: reqDate.toISOString(),
                      date: reqDate.format("YYYY-MM-DD"),
                      time: reqDate.format("HH:mm"),
                    });
                  }}
                  style={{ maxWidth: 240 }}
                  TextFieldComponent={TextField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" style={{ padding: 5 }}>
                        <CalendarTodayIcon color="primary" />
                        <AccessTimeIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          <Grid container>
            <Grid item xs={2}>
              <Typography
                variant="h5"
                className={typographyClasses.h5_600}
                style={{ marginTop: 10 }}
              >
                End Date <span style={{ fontSize: 12 }}>(in IST)</span>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                <DateTimePicker
                  disabled={isExperienceDashboard()}
                  className={classes.uiPicker}
                  value={moment(end_date)}
                  disablePast
                  minDate={start_date}
                  ampm={false}
                  onChange={(date) => {
                    const reqDate = moment(date);
                    dispatch({
                      type: SET_VALIDITY_END,
                      date_time: reqDate.toISOString(),
                    });
                  }}
                  style={{ maxWidth: 240 }}
                  TextFieldComponent={TextField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" style={{ padding: 5 }}>
                        <CalendarTodayIcon color="primary" />
                        <AccessTimeIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.gridItem}
          style={{
            marginTop: config[getTimeLimitsEnabledKey(platform)] ? 0 : 9,
            alignItems: "center",
          }}
        >
          <Typography variant="h5" className={typographyClasses.h5_600}>
            Show at specific time of the day
          </Typography>
          <ToggleSwitch
            classeName={classes.formControlLabel}
            styles={{ marginLeft: 8 }}
            checked={config[getTimeLimitsEnabledKey(platform)]}
            handleChange={(checked, e) => {
              dispatch({
                type: SET_TOGGLE_TIME_LIMITS_ENABLED,
                enabled: checked,
              });
            }}
            style={TOGGLE_SWITCH_STYLES()}
          />
          {config[getTimeLimitsEnabledKey(platform)] && (
            <>
              <Typography
                style={{ marginRight: 8 }}
                className={typographyClasses.h5_600}
              >
                between
              </Typography>
              <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                <TimePicker
                  format="hh:mm a z"
                  value={moment(time_limits.start_time, "HH:mm").utc(true)}
                  onChange={(time) => {
                    const start_time = time.format("HH:mm");
                    dispatch({
                      type: SET_TIME_LIMITS_START,
                      start_hour_min: start_time,
                    });
                  }}
                  style={{ maxWidth: 155 }}
                  TextFieldComponent={TextField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" style={{ padding: 5 }}>
                        <AccessTimeIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
              </MuiPickersUtilsProvider>

              <Typography
                style={{ marginRight: 8, marginLeft: 8 }}
                className={typographyClasses.h5_600}
              >
                and
              </Typography>
              <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                <TimePicker
                  format="hh:mm a z"
                  value={moment(time_limits.end_time, "HH:mm").utc(true)}
                  minDate={moment(time_limits.start_time, "HH:mm").utc(true)}
                  onChange={(time) => {
                    const end_time = time.format("HH:mm");
                    dispatch({
                      type: SET_TIME_LIMITS_END,
                      end_hour_min: end_time,
                    });
                  }}
                  style={{ maxWidth: 155 }}
                  TextFieldComponent={TextField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" style={{ padding: 5 }}>
                        <AccessTimeIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
              </MuiPickersUtilsProvider>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

