import React from "react";
import { Grid, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import FilterIcon from "@material-ui/icons/FilterList";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "../../../../ReusableComponents/Dialog";

export default class FiltersHolder extends React.Component {
  state = {
    expand: false,
  };

  render() {
    const { children, data = [], event } = this.props;
    const { expand } = this.state;
    //const showFilters = expand || data.length === 0 || data[0].name.length === 0;
    return (
      <Grid container style={{ marginTop: 16 }}>
        <Grid item xs={12}>
          <Dialog
            status={expand}
            handleConfirm={() => {
              this.setState({ expand: false });
            }}
            title={event + " Properties"}
            handleClose={() => this.setState({ expand: false })}
            confirmLabel="Apply"
            dialogProps={{
              maxWidth: "md",
              fullWidth: true,
            }}
          >
            <div style={{ minHeight: 240 }}>{children}</div>
          </Dialog>
          {!expand && (
            <div>
              {data.map((d, i) => (
                <div key={d.name + i}>
                  <Typography>{d.name}:</Typography>
                  {
                    <Typography variant="caption">
                      {d.value.join(", ")}
                    </Typography>
                  }
                </div>
              ))}
            </div>
          )}
          <Tooltip
            id={"tooltip-filters-for-" + event}
            title="Apply Event Filters"
            placement="top"
          >
            <IconButton
              color="secondary"
              aria-label="Filters"
              onClick={() => this.setState({ expand: true })}
            >
              <FilterIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  }
}
