import { DEMO_ECOMMERCE_APP_ID } from "../../../../../config";
import {
  makePatchRequest,
  makeDeleteRequest,
  makePostRequest,
} from "../../../../common/actions";

const ART_CONFIGS = "art-configs";
const PUBLISH_CAMPAIGN = "publish";

export const getConfigType = (messageData) => {
  if (messageData.meta) return messageData.meta.config_type;
  else return "walkthrough";
};

export function publishCampaignAPI(
  auth,
  appId,
  campaignConfig,
  isExperienceDashboard = false
) {
  const onSuccessCallback = (response) => {
    return response;
  };

  const onFailureCallback = (response) => {
    return {};
  };
  const makeRequest = isExperienceDashboard
    ? makePostRequest
    : makePatchRequest;
  return makeRequest(
    isExperienceDashboard ? `demo/${PUBLISH_CAMPAIGN}` : PUBLISH_CAMPAIGN,
    auth,
    isExperienceDashboard ? DEMO_ECOMMERCE_APP_ID : appId,
    { configId: campaignConfig._id, configType: getConfigType(campaignConfig) },
    {},
    onSuccessCallback,
    onFailureCallback
  );
}

export function toggleCampaignAPI(auth, appId, configId, queryParams) {
  const onSuccessCallback = (response) => {
    return response;
  };

  const onFailureCallback = (response) => {
    return {};
  };
  return makePatchRequest(
    `${ART_CONFIGS}/${configId}`,
    auth,
    appId,
    queryParams,
    {},
    onSuccessCallback,
    onFailureCallback
  );
}

export function deleteCampaignAPI(auth, appId, configId, queryParams) {
  const onSuccessCallback = (response) => {
    return response;
  };

  const onFailureCallback = (response) => {
    return {};
  };
  return makeDeleteRequest(
    `${ART_CONFIGS}/${configId}`,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}
