import { ButtonBase, Grid, Radio } from "@material-ui/core";
import React from "react";
import FieldDescription from "./FieldDescription";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import ClassicCard from "../../../../../ReusableComponents/ClassicCard";

export default function OptionSelect({
  value,
  options,
  direction,
  cardStyles,
  noneSelectable,
  numPerRow,
  fieldTitleStyles,
  fieldDescriptionStyles = {},
  containerStyles = {},
  buttonContainerStyles = {},
  radioStyles,
  radioOptions: { hasCheckboxOrRadio, radioFirst },
  style,
  handler,
  ...props
}) {
  return (
    <div style={{ display: "inline-block", ...style }} {...props}>
      <Grid
        container
        spacing={1}
        alignItems={"stretch"}
        style={{
          ...containerStyles,
        }}
      >
        {Array.isArray(options) &&
          options.map((option, index) => {
            return (
              <Grid
                key={"option_" + index}
                item
                xs={Math.round(12 / numPerRow)}
                style={{
                  flex: "0 0 auto",
                  ...buttonContainerStyles,
                }}
              >
                <ButtonBase
                  style={{ textAlign: "initial" }}
                  disabled={option.disabled}
                  disableRipple
                  disableTouchRipple
                  onClick={() =>
                    handler(
                      index === value ? (noneSelectable ? null : index) : index
                    )
                  }
                >
                  <ClassicCard
                    style={{ margin: 0, ...cardStyles }}
                    selected={index === value}
                    disabled={option.disabled}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection:
                          direction === "vertical" ? "column" : "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {!radioFirst && (
                        <FieldDescription
                          title={option.title}
                          titleProps={{
                            marginBottom: 4,
                            ...fieldTitleStyles,
                          }}
                          description={option.description}
                        />
                      )}
                      {hasCheckboxOrRadio && (
                        <Radio
                          style={{
                            width: 18,
                            height: 18,
                            ...radioStyles,
                          }}
                          disableRipple
                          color={"primary"}
                          checked={value === index}
                          icon={<RadioButtonUncheckedIcon fontSize="small" />}
                          checkedIcon={
                            <RadioButtonCheckedIcon fontSize="small" />
                          }
                        />
                      )}
                      {radioFirst && (
                        <FieldDescription
                          title={option.title}
                          titleProps={{ ...fieldTitleStyles }}
                          descriptionProps={{
                            ...fieldDescriptionStyles,
                          }}
                          description={option.description}
                        />
                      )}
                    </div>
                  </ClassicCard>
                </ButtonBase>
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
}
