import { Grid } from "@material-ui/core";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { isExperienceDashboard } from "../../../../../../../../../../../../config";
import ActionsTextField from "../../../../../../../../../../../ReusableComponents/ActionsTextField";
import ColumnDiv from "../../../../../../../../../../../ReusableComponents/ColumnDiv";
import ToggleSwitch from "../../../../../../../../../../../ReusableComponents/ToggleSwitch";
import {
  SET_RETAINED_DAY_FROM,
  SET_RETAINED_DAY_TO,
  SET_SESSION_LIMIT_FROM,
  SET_SESSION_LIMIT_TO,
  SET_TOGGLE_RETAINED_DAY,
  SET_TOGGLE_SESSION_LIMITS,
  TOGGLE_SWITCH_STYLES,
} from "../../../../../../../constants";
import { useTracked } from "../../../../../../../store";
import { useCommonConfigStyles } from "../../../../../../../utils";

export default function ByUserTimeline() {
  const classes = useCommonConfigStyles();

  const [state, dispatch] = useTracked();
  const {
    config: { overall_cfg },
  } = state;

  const [retainedDayFrom, setRetainedDayFrom] = useState(
    overall_cfg?.ret_day?.from || 0
  );
  const [retainedDayTo, setRetainedDayTo] = useState(
    overall_cfg?.ret_day?.to || 7
  );
  const [sessionFrom, setSessionFrom] = useState(
    overall_cfg?.session?.from || 0
  );
  const [sessionTo, setSessionTo] = useState(overall_cfg?.session?.to || 7);

  return (
    <>
      <FormControlLabel
        disabled={isExperienceDashboard()}
        label={
          <Typography className={classes.typographyCaption}>
            By User Timeline
          </Typography>
        }
        control={
          <Checkbox
            color="primary"
            checked={overall_cfg.toggleRetDay || overall_cfg.toggleSession}
            onChange={(e) => {
              const checked = e.target.checked;
              dispatch({
                type: SET_TOGGLE_RETAINED_DAY,
                enabled: checked,
              });
              if (checked) {
                dispatch({
                  type: SET_RETAINED_DAY_FROM,
                  value: retainedDayFrom || 0,
                });
                dispatch({
                  type: SET_RETAINED_DAY_TO,
                  value: retainedDayTo || 7,
                });
              } else {
                dispatch({
                  type: SET_TOGGLE_SESSION_LIMITS,
                  enabled: checked,
                });
              }
            }}
          />
        }
      />
      {(overall_cfg.toggleRetDay || overall_cfg.toggleSession) && (
        <ColumnDiv styles={{ marginLeft: 20 }}>
          <Grid container spacing={0} style={{ marginTop: 8, marginBottom: 8 }}>
            <Grid item xs={3} style={{ display: "flex", alignItems: "center" }}>
              <Typography className={classes.typographyCaption}>
                Retained Day
              </Typography>
              <ToggleSwitch
                styles={{ marginLeft: 8, marginRight: 8 }}
                checked={overall_cfg.toggleRetDay}
                handleChange={(checked, e) => {
                  dispatch({
                    type: SET_TOGGLE_RETAINED_DAY,
                    enabled: checked,
                  });
                  if (checked) {
                    dispatch({
                      type: SET_RETAINED_DAY_FROM,
                      value: sessionFrom || 0,
                    });
                    dispatch({
                      type: SET_RETAINED_DAY_TO,
                      value: sessionTo || 7,
                    });
                  }
                }}
                style={TOGGLE_SWITCH_STYLES()}
              />
            </Grid>
            <Grid item xs={9} style={{ display: "flex", alignItems: "center" }}>
              {overall_cfg.toggleRetDay && (
                <>
                  <Typography className={classes.typographyCaption}>
                    between
                  </Typography>
                  <ActionsTextField
                    value={retainedDayFrom}
                    style={{
                      maxWidth: 72,
                      marginBottom: 0,
                      margin: "0 6px",
                    }}
                    InputProps={{ min: 0, max: retainedDayTo }}
                    placeholder="from"
                    type="number"
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      setRetainedDayFrom(value);
                      dispatch({
                        type: SET_RETAINED_DAY_FROM,
                        value,
                      });
                    }}
                  />
                  <Typography className={classes.typographyCaption}>
                    {" "}
                    and{" "}
                  </Typography>
                  <ActionsTextField
                    value={retainedDayTo}
                    style={{
                      maxWidth: 72,
                      marginBottom: 0,
                      margin: "0 6px",
                    }}
                    InputProps={{ min: retainedDayFrom }}
                    placeholder="to"
                    type="number"
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      setRetainedDayTo(value);
                      dispatch({
                        type: SET_RETAINED_DAY_TO,
                        value,
                      });
                    }}
                  />
                  <Typography className={classes.typographyCaption}>
                    of Timeline
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={0} center style={{ marginBottom: 8 }}>
            <Grid item xs={3} style={{ display: "flex", alignItems: "center" }}>
              <Typography className={classes.typographyCaption}>
                User Session
              </Typography>
              <ToggleSwitch
                styles={{ marginLeft: 8, marginRight: 8 }}
                checked={overall_cfg.toggleSession}
                handleChange={(checked, e) => {
                  dispatch({
                    type: SET_TOGGLE_SESSION_LIMITS,
                    enabled: checked,
                    from: sessionFrom || 0,
                    to: sessionTo || 7,
                  });
                }}
                style={TOGGLE_SWITCH_STYLES()}
              />
            </Grid>
            <Grid item xs={9} style={{ display: "flex", alignItems: "center" }}>
              {overall_cfg.toggleSession && (
                <>
                  <Typography className={classes.typographyCaption}>
                    between
                  </Typography>
                  <ActionsTextField
                    value={sessionFrom}
                    style={{
                      maxWidth: 72,
                      marginBottom: 0,
                      margin: "0 6px",
                    }}
                    InputProps={{ min: 0, max: sessionTo }}
                    placeholder="from"
                    type="number"
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      setSessionFrom(value);
                      dispatch({
                        type: SET_SESSION_LIMIT_FROM,
                        value,
                      });
                    }}
                  />
                  <Typography className={classes.typographyCaption}>
                    {" "}
                    and{" "}
                  </Typography>
                  <ActionsTextField
                    value={sessionTo}
                    style={{
                      maxWidth: 72,
                      marginBottom: 0,
                      margin: "0 6px",
                    }}
                    InputProps={{ min: sessionFrom }}
                    placeholder="to"
                    type="number"
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      setSessionTo(value);
                      dispatch({
                        type: SET_SESSION_LIMIT_TO,
                        value,
                      });
                    }}
                  />
                  <Typography className={classes.typographyCaption}>
                    of Timeline
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        </ColumnDiv>
      )}
    </>
  );
}

