/**
 * React Dates custom styles
 * @param theme
 * @returns {styles}
 */
export default (theme) => ({
  ".CalendarMonthGrid, .DayPicker__horizontal, .DateRangePickerInput, .CalendarMonth, .DateInput_input": {
    background: theme.palette.background.paper,
  },
  ".CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover": {
    background: theme.palette.primary.main + " !important",
    border: `1px double ${theme.palette.primary.main} !important`,
  },
  ".CalendarDay__selected_span, .CalendarDay__selected_span:active, .CalendarDay__selected_span:hover": {
    background: theme.palette.primary.hint,
    border: `1px double ${theme.palette.primary.main} !important`,
  },
  ".CalendarDay__hovered_span": {
    color: theme.palette.secondary.main + " !important",
    background: theme.palette.primary.hint + " !important",
    border: `1px double ${theme.palette.primary.main} !important`,
  },
  ".DateInput__small": {
    width: 85,
  },
  ".DateInput_input": {
    color: theme.palette.text.secondary,
  },
  ".DateInput_input__small": {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "20px",
    padding: "10px 8px 8px",
    textAlign: "center",
    color: theme.palette.text.primary,
  },
  ".DateRangePicker_picker": {
    zIndex: 10000,
  },
  ".DayPickerKeyboardShortcuts_show__bottomRight": {
    display: "none",
  },
  ".SingleDatePicker_picker": {
    zIndex: 10000,
  },
  ".DateRangePickerInput__withBorder": {
    border: `1px solid ${theme.palette.text.secondary}`,
    borderRadius: 3,
    width: "100% !important",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  ".DateRangePickerInput_calendarIcon_svg": {
    fill: theme.palette.primary.main,
  },
  ".DateRangePickerInput_calendarIcon": {
    padding: 5,
    margin: "0 10px 0 0",
  },
  ".DateRangePicker": {
    width: "100% !important",
  },
});
