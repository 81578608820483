import React from "react";

export default function InAppContainer({
  orientation,
  dimmed_background,
  isFooter,
  children,
}) {
  let defaultSimulatorWidth = orientation === "Portrait" ? 270 : 540;
  let defaultSimulatorHeight = orientation === "Portrait" ? 540 : 270;
  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        width: defaultSimulatorWidth,
        height: defaultSimulatorHeight,
        backgroundColor: dimmed_background ? "rgb(51, 51, 51, 0.45)" : "",
        justifyContent: isFooter ? "flex-end" : "flex-start",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {children}
    </div>
  );
}
