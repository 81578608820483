import { createTheme } from "@material-ui/core/styles";
export const darkTheme = createTheme({
  palette: {
    type: "dark",
    background: {
      default: "#002845",
      paper: "#002845",
    },
    primary: {
      main: "#3f50b5",
    },
    secondary: {
      main: "#002845",
    },
  },
  overrides: {
    MuiListItem: {
      //FIXME: Tweak for supporting sidebar menu
      gutters: {
        paddingLeft: "16px !important",
        paddingRight: "16px !important",
      },
    },
  },
});
