export const NOTIFICATION_ENUM = {
  simple: "simple",
  big_text: "big_text",
  big_image: "big_image",
};

export const NOTIFICATION_CONFIGURATIONS = {
  GENERAL: "General",
  CONFIG: "Configuration",
  PAYLOAD: "Design & Data",
  AUDIENCE: "Audience",
};

export const TYPE_ENUM = {
  int: "int",
  boolean: "boolean",
  long: "long",
  float: "float",
  double: "double",
  string: "string",
};

export const PRIORITY_ENUM = {
  MIN: -2,
  LOW: -1,
  DEFAULT: "0",
  HIGH: 1,
  MAX: 2,
};

export const REDIRECTION_TYPES = [
  { label: "Activity", key: "activity" },
  { label: "Deep Link", key: "deep_link" },
];

export const IMAGE_SOURCE = [
  { label: "Path", key: "path" },
  { label: "URL", key: "URL" },
];

export const PUSH_STATUS = {
  Draft: "Draft",
  InProgress: "InProgress",
  Scheduled: "Scheduled",
  Expired: "Expired",
  Paused: "Paused",
};

export const NOTIFICATIONS_HOLDER_TABS = {
  ALL: "All",
  ACTIVE: "Active",
  EXPIRED: "Expired",
  PAUSED: "Paused",
  SCHEDULED: "Scheduled",
  DRAFTS: "Drafts",
};

export const ICON_CONFIGURATION = [
  { label: "NONE", key: "icon" },
  { label: "LARGEICON PATH", key: "large_icon_path" },
  { label: "LARGEICON URL", key: "large_icon_url" },
];

export const SCHEDULING_ENUM = {
  NOW: "NOW",
  LATER: "LATER",
};

export const AUDIENCE_TYPES = [
  {
    title: "All Users",
    key: "ALL",
    // description: "All your users",
    disabled: false,
  },
  {
    title: "Segment",
    key: "SEGMENT",
    // description: "Select user segments",
    disabled: false,
  },
  {
    title: "Cohort",
    key: "COHORT",
    // description: "Select cohort of users",
    disabled: false,
  },
];

export const RECUR_FREQUENCY_ENUM = [
  { label: "day", key: "DAY" },
  { label: "hour", key: "HOUR" },
  { label: "seconds", key: "SECONDS" },
];

export const COLLAPSING_ENUM = {
  True: true,
  False: false,
};

export const SET_NAME = "SET_NAME";
export const SET_DESCRIPTION = "SET_DESCRIPTION";
export const SET_APP_PLATFORM = "SET_APP_PLATFORM";
export const SET_APP_EVENTS = "SET_EVENTS";
export const SET_APP_SEGMENTS = "SET_SEGMENTS";
export const SET_APP_COHORTS = "SET_COHORTS";
export const SET_APP_SCREENS = "SET_APP_SCREENS";
export const SET_APP_VERSIONS = "SET_APP_VERSIONS";
export const SET_SELECTED_VERSIONS = "SET_SELECTED_VERSIONS";
export const SET_SELECTED_SEGMENT = "SET_SELECTED_SEGMENT";
export const SET_SELECTED_COHORT = "SET_SELECTED_COHORT";
export const SET_START_TIME = "SET_START_TIME";
export const SET_END_TIME = "SET_END_TIME";
export const SET_TTL = "SET_TTL";
export const SET_PRIORITY = "SET_PRIORITY";
export const SET_NOTIFICATION_TYPE = "SET_NOTIFICATION_TYPE";
export const SET_NOTIFICATION_TITLE = "SET_NOTIFICATION_TITLE";
export const SET_NOTIFICATION_BODY = "SET_NOTIFICATION_BODY";
export const SET_ACTIVITY_NAME = "SET_ACTIVITY_NAME";
export const SET_DEEP_LINK_URI = "SET_DEEP_LINK_URI";
export const SET_CHANNEL_ID = "SET_CHANNEL_ID";
export const ADD_TO_CUSTOM_KEYS = "ADD_TO_CUSTOM_KEYS";
export const DELETE_CUSTOM_KEYS = "DELETE_CUSTOM_KEYS";
export const SET_IS_SILENT = "SET_IS_SILENT";
export const SET_BIG_IMAGE_PATH = "SET_BIG_IMAGE_PATH";
export const SET_BIG_IMAGE_URL = "SET_BIG_IMAGE_URL";
export const SET_BIG_IMAGE_CONTENT_TITLE = "SET_BIG_IMAGE_CONTENT_TITILE";
export const SET_BIG_TEXT_LONG_TEXT = "SET_BIG_TEXT_LONG_TEXT";
export const SET_ICON_PATH = "SET_ICON_PATH";
export const SET_LARGE_ICON_PATH = "SET_LARGE_ICON_PATH";
export const SET_LARGE_ICON_URL = "SET_LARGE_ICON_URL";
export const ADD_TO_BUTTON_CONFIG = "ADD_TO_BUTTON_CONFIG";
export const DELETE_BUTTON_CONFIG = "DELETE_BUTTON_CONFIG";
export const SET_NOTIFICATION_LIST = "SET_NOTIFICATION_LIST";
export const SET_FETCH_NOTIFICATION_COUNT = "SET_FETCH_NOTIFICATION_COUNT";
export const SET_PUSH_CONFIG_AS_INITIAL_STATE =
  "SET_PUSH_CONFIG_AS_INITIAL_STATE";
export const SET_SCHEDULING_TYPE = "SET_SCHEDULING_TYPE";
export const SET_SCHEDULING_START = "SET_SCHEDULING_START";
export const SET_SCHEDULING_END = "SET_SCHEDULING_END";
export const SET_SCHEDULING_TIME = "SET_SCHEDULING_TIME";
export const SET_AUDIENCE_TYPE = "SET_AUDIENCE_TYPE";
export const SET_ROLL_INTERVAL = "SET_ROLL_INTERVAL";
export const SET_TOGGLE_RECURR = "SET_TOGGLE_RECURR";
export const SET_ROLL_SEGMENT = "SET_ROLL_SEGMENT";
export const SET_ROLL_STEP = "SET_ROLL_STEP";
export const SET_ROLL_RECURR = "SET_ROLL_RECURR";
export const SET_REDIRECTION_TYPE = "SET_REDIRECTION_TYPE";
export const SET_BIG_IMAGE_SOURCE = "SET_BIG_IMAGE_SOURCE";
export const SET_ICON_CONFIG_TYPE = "SET_ICON_CONFIG_TYPE";
export const SET_IS_COLLAPSING_ENABLED = "SET_IS_COLLAPSING_ENABLED";
export const SET_COLLAPSING_KEY = "SET_COLLAPSING_KEY";
export const SET_IS_VALID_PUSH = "SET_IS_VALID_PUSH";
export const ADD_TEST_DEVICE = "ADD_TEST_DEVICE";
export const REMOVE_TEST_DEVICE = "REMOVE_TEST_DEVICE";

export const SET_TEST_DEVICES = "SET_TEST_DEVICES";