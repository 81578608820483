import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  Button,
  Box,
  Chip,
  IconButton,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import SettingsIcon from "@material-ui/icons/Settings";
import React, { useEffect, useRef, useState, useMemo } from "react";
import beautify from "js-beautify";
import ActionsTextField from "../../../../../../../../ReusableComponents/ActionsTextField";
import HTMLEditor from "../../../../../../../../ReusableComponents/HTMLEditor";
import ModuleDivider from "../../../../../../../../ReusableComponents/ModuleDivider";
import NumberWithButtons from "../../../../../../../../ReusableComponents/NumberWithButtons";
import BootstrapTooltip from "../../../../../../../../ReusableComponents/BootstrapTooltip";
import RowDiv from "../../../../../../../../ReusableComponents/RowDiv";
import ToggleSwitch from "../../../../../../../../ReusableComponents/ToggleSwitch";
import { sendEvent } from "../../../../../../../common/ApxEventHandler";
import {
  ENABLE_CONDITIONS,
  executeWithDebounce,
  TOGGLE_SWITCH_STYLES,
} from "../../../../../../../common/components/campaigncreator/constants";
import {
  useTrackedState,
  useDispatch,
} from "../../../../../../../common/components/campaigncreator/store";
import {
  DEFAULT_HTML_IMAGE_URL,
  HIDE_CONFIGURATION_TOOLBARS,
  INAPP_LAYOUT_TYPES,
  SET_BORDER_BOTTOM_RADIUS,
  SET_BUTTONS,
  SET_BUTTON_ACTION,
  SET_CAROUSEL_IMAGES,
  SET_CAROUSEL_COUNT,
  SET_DIMMED_BACKGROUND,
  SET_IMAGE,
  SET_INAPP_HTML,
  SET_MODULE_TYPE,
  SET_ONLY_IMAGE,
  TEMPLATE_BEEN_SET,
  UNSET_CAROUSAL_IMAGE,
  SET_IMAGE_ONLY_REDIRECTION_CONFIG,
  SET_VIDEO_LINK,
  SET_HEIGHT,
  SET_WIDTH,
  SET_VIDEO_LOADING_TYPE,
  SET_VIDEO_HAS_AUDIO,
  SET_VIDEO_BINDING,
  SET_VIDEO_MUTE_AUDIO,
  SET_VIDEO_AUTO_REPLAY,
  ASPECT_RATIO_ENUM,
  SET_VIDEO_ACTIVITY,
  SET_VIDEO_ISFULLSCREEN,
} from "../../../constants";
import { useTracked } from "../../../store";
import { getUI } from "../../../utils";
import { useCommonConfigStyles } from "../utils";
import ButtonAction from "./ButtonAction";
import DelayConfig from "./DelayConfig";
import ScriptToolbar from "./ScriptToolbar";
import { APP_PLATFORMS } from "../../../../../../../../../constants";
import MultiSelect from "../../../../../../../../ReusableComponents/MultiSelect";
import VideoSettingsDialog from "./VideoSettingsDialog";

const JS_BEAUTIFY_OPTIONS = {
  indent_size: "2",
  indent_char: " ",
  max_preserve_newlines: "5",
  preserve_newlines: true,
  keep_array_indentation: false,
  break_chained_methods: false,
  indent_scripts: "normal",
  brace_style: "expand",
  space_before_conditional: true,
  unescape_strings: false,
  jslint_happy: false,
  end_with_newline: false,
  wrap_line_length: "80",
  indent_inner_html: true,
  comma_first: false,
  e4x: false,
};

export default function NewInAppConfiguration({
  currentStep,
  theme,
  setCodeEmpty,
}) {
  const classes = useCommonConfigStyles();
  const editorRef = useRef();
  const [cursorRow, setCursorRow] = useState(0);
  const [cursorColumn, setCursorColumn] = useState(0);

  const parentState = useTrackedState();
  const parentDispatch = useDispatch();
  const {
    app_screens: appScreenNames = [],
    config: {
      meta: { isExperiment, platform },
    },
  } = parentState;

  const [state, dispatch] = useTracked();
  const { template_been_set } = state;
  const ui = getUI(currentStep, state.uis, isExperiment).ui || {};
  const {
    v_url,
    height,
    width,
    binding,
    add_audio,
    download,
    is_full_scr,
    mute_audio,
    auto_replay,
    activity,
    border_radius,
    buttons_config = [],
    image_only_redirection_config,
    layout_type,
    only_image,
    html_config,
    window_attributes,
    delay,
    image,
    carousel: { count, images } = {},
  } = ui;
  const [htmlData, setHtmlData] = useState(
    beautify.html_beautify(html_config?.data, JS_BEAUTIFY_OPTIONS)
  );

  // Reset the htmlData whenever the layout_type or currentStep
  // gets changed
  // whenever template is selected, send a prop saying for template and at that time only

  useEffect(() => {
    setHtmlData(html_config?.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout_type, currentStep, template_been_set]);

  const aspect_ratio = useMemo(() => {
    if (width === 1920 && height === 1080) return "16:9 (landscape)";
    else if (width === 1080 && height === 1920) return "16:9 (portrait)";
    else return "Custom";
  }, [width, height]);

  const [downloadSettingsOpen, setDownloadSettingsOpen] = useState(false);

  if (layout_type === INAPP_LAYOUT_TYPES.CUSTOM_HTML) {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <RowDiv
            center
            styles={{
              justifyContent: "space-between",
              gap: 8,
              marginBottom: 8,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                setHtmlData(
                  beautify.html_beautify(htmlData, JS_BEAUTIFY_OPTIONS)
                )
              }
            >
              Beautify
            </Button>
            <ScriptToolbar
              currentStep={currentStep}
              onAddNewSnippet={scriptName => {
                const editor = editorRef.current;
                const lines = [...editor.editor.session.doc.$lines];
                let row = lines[cursorRow];
                row =
                  row.slice(0, cursorColumn) +
                  "apx_evaluate(" +
                  scriptName +
                  ")" +
                  row.slice(cursorColumn);
                lines[cursorRow] = row;
                setHtmlData(lines.join(editor.editor.session.doc.$autoNewLine));
                dispatch({
                  type: SET_INAPP_HTML,
                  currentStep: currentStep,
                  value: lines.join(editor.editor.session.doc.$autoNewLine),
                });
              }}
              onRemoveSnippet={scriptName => {
                setHtmlData(
                  htmlData.replace("apx_evaluate(" + scriptName + ")", "")
                );
                dispatch({
                  type: SET_INAPP_HTML,
                  currentStep: currentStep,
                  value: htmlData.replace(
                    "apx_evaluate(" + scriptName + ")",
                    ""
                  ),
                });
              }}
            />
          </RowDiv>
          <HTMLEditor
            height="470px"
            value={htmlData}
            theme={theme}
            setCodeEmpty={setCodeEmpty}
            editorRef={editorRef}
            handleChange={data => {
              if (template_been_set) {
                dispatch({ type: TEMPLATE_BEEN_SET, value: false });
              }
              setHtmlData(data);
              executeWithDebounce(500)(dispatch, {
                type: SET_INAPP_HTML,
                currentStep: currentStep,
                value: data,
              });
            }}
            onCursorPositionChange={(row, column) => {
              setCursorRow(row);
              setCursorColumn(column);
            }}
          />
        </Grid>
        {platform !== APP_PLATFORMS.web && <DelayConfig delay={delay} />}
      </Grid>
    );
  }

  if (layout_type === INAPP_LAYOUT_TYPES.VIDEOS) {
    return (
      <Grid container spacing={1} className={classes.gridContainer}>
        <Grid
          item
          xs={12}
          className={classes.gridItem}
          style={{ marginTop: 8 }}
        >
          <Typography className={classes.typographySubtitle2} variant="h6">
            VIDEO LINK
          </Typography>
          <ActionsTextField
            placeholder={"Enter the video path"}
            value={v_url}
            multiline={false}
            style={{ width: "80%", marginBottom: 0 }}
            onChange={(e) => {
              dispatch({
                type: SET_VIDEO_LINK,
                value: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.gridItem}
          style={{ marginTop: -4 }}
        >
          <Typography className={classes.formControlLabel} variant="caption">
            Only mp4 video format with secure https streaming is supported
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          <Grid item xs={12} className={classes.gridItem}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                className={classes.typographyCaption}
                variant="subtitle2"
              >
                Type of Video Loading{" "}
              </Typography>
              <BootstrapTooltip
                placement="top"
                arrow
                title={
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" flexDirection="column" pb={1.5}>
                      <Typography
                        variant="h6"
                        color="secondary"
                        style={{ marginBottom: 1 }}
                      >
                        STREAM
                      </Typography>
                      Video will not be stored and will be played on demand &
                      might buffer based on resource hosted / bandwidth.
                    </Box>
                    <Box display="flex" flexDirection="column">
                      <Box display="flex" alignItems="center">
                        <Typography
                          variant="h6"
                          color="secondary"
                          style={{ marginBottom: 1 }}
                        >
                          DOWNLOAD
                        </Typography>
                        <Chip
                          label="Recommended"
                          style={{
                            marginLeft: 4,
                            color: "#002845",
                            backgroundColor: "#00BDE1",
                          }}
                          size="small"
                        />
                      </Box>
                      Video is downloaded to the device, to avoid buffering &
                      will be played only after the entire video is available.
                    </Box>
                  </Box>
                }
              >
                <HelpOutlineIcon
                  style={{ marginLeft: 6 }}
                  fontSize="small"
                  htmlColor="#8DA2B0"
                />
              </BootstrapTooltip>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <ToggleButtonGroup
                value={download ? "Download" : "Stream"}
                exclusive
                onChange={(_event, value) => {
                  if (value !== null) {
                    dispatch({
                      type: SET_VIDEO_LOADING_TYPE,
                      value: value === "Download",
                    });
                    parentDispatch({
                      type: ENABLE_CONDITIONS,
                      value: value === "Download",
                    });
                  }
                }}
              >
                <ToggleButton
                  value="Stream"
                  aria-label="1"
                  style={{ padding: 8, textTransform: "none" }}
                >
                  <span style={{ padding: "0px 8px" }}>Stream</span>
                </ToggleButton>
                <ToggleButton
                  value="Download"
                  aria-label="2"
                  style={{ padding: 8, textTransform: "none" }}
                >
                  <span style={{ padding: "0px 8px" }}>Download</span>
                </ToggleButton>
              </ToggleButtonGroup>
              {download && (
                <>
                  <IconButton
                    disableRipple
                    size="small"
                    style={{
                      marginLeft: 8,
                      padding: 6,
                      border: "1px solid #CCD4DA",
                      borderRadius: "4px",
                      color: "#002845",
                    }}
                    aria-label="open settings"
                    onClick={() => setDownloadSettingsOpen(true)}
                  >
                    <SettingsIcon
                      style={{
                        fontSize: "30px",
                      }}
                    />
                  </IconButton>
                  <VideoSettingsDialog
                    onClose={() => setDownloadSettingsOpen(false)}
                    open={downloadSettingsOpen}
                  />
                </>
              )}
            </Box>
          </Grid>
        </Grid>

        <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />

        <Grid item xs={12} className={classes.gridItem}>
          <Typography className={classes.typographySubtitle2} variant="h6">
            AUTO REPLAY
          </Typography>
          <ToggleSwitch
            className={classes.formControlLabel}
            defaultChecked
            checked={auto_replay}
            handleChange={(checked) => {
              dispatch({
                type: SET_VIDEO_AUTO_REPLAY,
                value: checked,
              });
            }}
            style={TOGGLE_SWITCH_STYLES(true)}
          />
        </Grid>

        <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />

        <Grid item xs={12} className={classes.gridItem}>
          <Typography className={classes.typographySubtitle2} variant="h6">
            VIDEO HAS AUDIO?
          </Typography>
          <ToggleSwitch
            className={classes.formControlLabel}
            checked={add_audio}
            handleChange={(checked) => {
              dispatch({
                type: SET_VIDEO_HAS_AUDIO,
                value: checked,
              });
            }}
            style={TOGGLE_SWITCH_STYLES(true)}
          />
        </Grid>
        {add_audio && (
          <Grid item xs={12} className={classes.gridItem}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  value={!mute_audio}
                  checked={!mute_audio}
                  defaultChecked={!mute_audio}
                  defaultValue={!mute_audio}
                  onChange={(e) => {
                    dispatch({
                      type: SET_VIDEO_MUTE_AUDIO,
                      value: !e.target.checked,
                    });
                    sendEvent(HIDE_CONFIGURATION_TOOLBARS);
                  }}
                />
              }
              label="Enable sound by default when the campaign starts"
            />
          </Grid>
        )}

        <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />

        <Grid item xs={12} className={classes.gridItem}>
          <Box display="flex" alignItems="center">
            <Typography className={classes.typographySubtitle2} variant="h6">
              BINDING{" "}
            </Typography>
            <BootstrapTooltip
              placement="top"
              arrow
              title="Restrict to trigger the video only when the user is on a particular screen / activity."
            >
              <HelpOutlineIcon
                style={{
                  marginLeft: 6,
                }}
                fontSize="small"
                htmlColor="#8DA2B0"
              />
            </BootstrapTooltip>
          </Box>
          <ToggleSwitch
            className={classes.formControlLabel}
            defaultChecked
            checked={binding}
            handleChange={(checked) => {
              dispatch({
                type: SET_VIDEO_BINDING,
                value: checked,
              });
            }}
            style={TOGGLE_SWITCH_STYLES(true)}
          />
        </Grid>

        {binding === true && (
          <Grid
            item
            xs={12}
            className={classes.gridItem}
            style={{ gap: 8, marginTop: 0, marginBottom: 0 }}
          >
            <Typography
              className={classes.typographyCaption}
              variant="subtitle2"
              style={{
                minWidth: 160,
              }}
            >
              Select Screen / Activity
            </Typography>
            <MultiSelect
              options={appScreenNames.map((screenName) => ({
                label: screenName,
                value: screenName,
              }))}
              style={{
                width: "100%",
              }}
              single
              value={activity}
              handleChange={(value) => {
                dispatch({
                  type: SET_VIDEO_ACTIVITY,
                  value,
                });
              }}
            />
          </Grid>
        )}

        <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />

        <Grid item xs={12} className={classes.gridItem}>
          <Typography className={classes.typographySubtitle2} variant="h6">
            INCLUDE BACKGROUND
          </Typography>
          <ToggleSwitch
            className={classes.formControlLabel}
            defaultChecked
            checked={!is_full_scr}
            handleChange={(checked) => {
              dispatch({
                type: SET_VIDEO_ISFULLSCREEN,
                value: !checked,
              });
            }}
            style={TOGGLE_SWITCH_STYLES(true)}
          />
        </Grid>

        <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />

        <Grid item xs={12} className={classes.gridItem}>
          <Typography className={classes.typographySubtitle2} variant="h6">
            BUTTON
          </Typography>
          <ToggleSwitch
            className={classes.formControlLabel}
            disabled={false}
            checked={buttons_config?.length > 0}
            handleChange={(checked) => {
              dispatch({
                type: SET_BUTTONS,
                currentStep,
                count: +checked,
                key: "buttons_config",
                isActionButtons: false,
              });
            }}
            style={TOGGLE_SWITCH_STYLES(true)}
          />
        </Grid>
        {buttons_config?.map((button, index) => (
          <ButtonAction
            key={index}
            isSingular={true}
            appScreenNames={appScreenNames}
            button={button}
            classes={classes}
            index={index}
            onActionChange={(data) => {
              dispatch({
                type: SET_BUTTON_ACTION,
                currentStep,
                keyName: button.__id,
                buttonKeyName: "buttons_config",
                data,
              });
            }}
          />
        ))}

        <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />

        <Grid item xs={12} className={classes.gridItem}>
          <Typography className={classes.typographySubtitle2} variant="h6">
            PICTURE IN PICTURE (PIP)
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          <Typography
            className={classes.typographyCaption}
            variant="subtitle2"
            style={{
              minWidth: 160,
            }}
          >
            Width x Height
          </Typography>
          <MultiSelect
            single
            clearable={false}
            isSearchable={false}
            options={Object.keys(ASPECT_RATIO_ENUM).map((ar) => ({
              label: ar,
              value: ar,
            }))}
            value={aspect_ratio}
            handleChange={(value) => {
              dispatch({
                type: SET_WIDTH,
                value: ASPECT_RATIO_ENUM[value].width,
              });
              dispatch({
                type: SET_HEIGHT,
                value: ASPECT_RATIO_ENUM[value].height,
              });
            }}
          />
        </Grid>
        {ASPECT_RATIO_ENUM[aspect_ratio] === ASPECT_RATIO_ENUM.Custom && (
          <Grid
            item
            xs={12}
            className={classes.gridItem}
            style={{ marginTop: 0, marginBottom: 0 }}
          >
            <Typography
              className={classes.typographyCaption}
              variant="subtitle2"
              style={{
                minWidth: 160,
              }}
            >
              Enter Values
            </Typography>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                width: "100%",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: 40,
                }}
              >
                <ActionsTextField
                  key="width"
                  type="number"
                  InputProps={{
                    min: 0,
                    max: 1920,
                    type: "number",
                  }}
                  value={width}
                  onChange={(event) => {
                    dispatch({
                      type: SET_WIDTH,
                      value: event.target.value,
                    });
                  }}
                  style={{
                    marginBottom: 0,
                    marginRight: 8,
                  }}
                />
                <Typography
                  className={classes.typographyCaption}
                  variant="subtitle2"
                >
                  px
                </Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ActionsTextField
                  key="height"
                  type="number"
                  InputProps={{
                    min: 0,
                    max: 1920,
                    type: "number",
                  }}
                  value={height}
                  onChange={(event) => {
                    dispatch({
                      type: SET_HEIGHT,
                      value: event.target.value,
                    });
                  }}
                  style={{
                    marginBottom: 0,
                    marginRight: 8,
                  }}
                />
                <Typography
                  className={classes.typographyCaption}
                  variant="subtitle2"
                >
                  px
                </Typography>
              </Box>
            </Box>
          </Grid>
        )}

        <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />

        <DelayConfig delay={delay} />
      </Grid>
    );
  }
  ///.........................................................
  return (
    <Grid container spacing={1} className={classes.gridContainer}>
      {layout_type !== INAPP_LAYOUT_TYPES.CAROUSEL &&
        layout_type !== INAPP_LAYOUT_TYPES.FLOATING && (
          <Grid
            item
            xs={12}
            className={classes.gridItem}
            style={{ marginTop: 8 }}
          >
            <Typography className={classes.typographySubtitle2} variant="h6">
              IMAGE
            </Typography>
            <ToggleSwitch
              disabled={
                layout_type === INAPP_LAYOUT_TYPES.POPOUT_MODAL ||
                layout_type === INAPP_LAYOUT_TYPES.FULL_SCREEN_MODAL
              }
              className={classes.formControlLabel}
              checked={image !== "NA"}
              handleChange={(checked) => {
                dispatch({
                  type: SET_IMAGE,
                  currentStep: currentStep,
                  value: checked ? DEFAULT_HTML_IMAGE_URL : "NA",
                });
                sendEvent(HIDE_CONFIGURATION_TOOLBARS);
              }}
              style={TOGGLE_SWITCH_STYLES(
                layout_type === INAPP_LAYOUT_TYPES.POPOUT_MODAL ||
                  layout_type === INAPP_LAYOUT_TYPES.FULL_SCREEN_MODAL,
              )}
            />
          </Grid>
        )}
      {layout_type !== INAPP_LAYOUT_TYPES.HEADER &&
        layout_type !== INAPP_LAYOUT_TYPES.FOOTER &&
        layout_type !== INAPP_LAYOUT_TYPES.CAROUSEL &&
        layout_type !== INAPP_LAYOUT_TYPES.FLOATING && (
          <>
            <Grid item xs={12} className={classes.gridItem}>
              <Typography
                className={classes.typographyCaption}
                variant="subtitle2"
              >
                Only Image
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={only_image}
                    onChange={(e) => {
                      dispatch({
                        type: SET_ONLY_IMAGE,
                        currentStep: currentStep,
                        value: e.target.checked,
                      });
                      dispatch({
                        type: SET_MODULE_TYPE,
                        value: "",
                      });
                      sendEvent(HIDE_CONFIGURATION_TOOLBARS);
                    }}
                    value="ImageOnly"
                  />
                }
                label="Only Image Without CTA"
              />
            </Grid>
          </>
        )}
      {layout_type !== INAPP_LAYOUT_TYPES.CAROUSEL &&
        layout_type !== INAPP_LAYOUT_TYPES.FLOATING &&
        !only_image && (
          <>
            <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
            <Grid item xs={12} className={classes.gridItem}>
              <Typography className={classes.typographySubtitle2} variant="h6">
                BUTTONS
              </Typography>
              <ToggleSwitch
                className={classes.formControlLabel}
                disabled={true}
                checked={buttons_config?.length > 0}
                handleChange={(checked, e) => null}
                style={TOGGLE_SWITCH_STYLES(true)}
              />
            </Grid>
          </>
        )}
      {layout_type !== INAPP_LAYOUT_TYPES.CAROUSEL &&
        layout_type !== INAPP_LAYOUT_TYPES.FLOATING &&
        buttons_config?.length > 0 &&
        !only_image && (
          <>
            <Grid item xs={12} className={classes.gridItem}>
              <Typography
                className={classes.typographyCaption}
                variant="subtitle2"
              >
                Number of buttons
              </Typography>
              <ToggleButtonGroup
                value={buttons_config.length + ""}
                exclusive
                onChange={(e, value) => {
                  let buttonsCount = 1;
                  if (value === "1") {
                    buttonsCount = 1;
                  } else if (value === "2") {
                    buttonsCount = 2;
                  }
                  dispatch({
                    type: SET_BUTTONS,
                    currentStep,
                    count: buttonsCount,
                    key: "buttons_config",
                    isActionButtons: false,
                  });
                  sendEvent(HIDE_CONFIGURATION_TOOLBARS);
                }}
              >
                <ToggleButton value="1" aria-label="1" style={{ padding: 8 }}>
                  <span style={{ padding: "0px 8px" }}>1</span>
                </ToggleButton>
                <ToggleButton value="2" aria-label="2" style={{ padding: 8 }}>
                  <span style={{ padding: "0px 8px" }}>2</span>
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            {buttons_config?.map((button, index) => (
              <ButtonAction
                key={index}
                appScreenNames={appScreenNames}
                button={button}
                classes={classes}
                index={index}
                onActionChange={(data) => {
                  dispatch({
                    type: SET_BUTTON_ACTION,
                    currentStep,
                    keyName: button.__id,
                    buttonKeyName: "buttons_config",
                    data,
                  });
                }}
              />
            ))}
          </>
        )}
      {layout_type !== INAPP_LAYOUT_TYPES.CAROUSEL &&
        layout_type !== INAPP_LAYOUT_TYPES.FLOATING &&
        only_image && (
          <>
            <ButtonAction
              appScreenNames={appScreenNames}
              button={{
                action_config: image_only_redirection_config,
                text_config: { text: "" },
              }}
              classes={classes}
              index={0}
              isImageOnlyRedirection={true}
              onActionChange={(data) => {
                dispatch({
                  type: SET_IMAGE_ONLY_REDIRECTION_CONFIG,
                  currentStep,
                  keyName: image_only_redirection_config?.__id,
                  buttonKeyName: "image_only_redirection_config",
                  data,
                });
              }}
            />
          </>
        )}
      {(layout_type === INAPP_LAYOUT_TYPES.HEADER ||
        layout_type === INAPP_LAYOUT_TYPES.FOOTER) && (
        <>
          <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
          <Grid item xs={12} className={classes.gridItem}>
            <Typography className={classes.typographySubtitle2} variant="h6">
              BORDER RADIUS
            </Typography>
            <NumberWithButtons
              maxValue={16}
              value={border_radius}
              handleChange={(value) =>
                dispatch({
                  type: SET_BORDER_BOTTOM_RADIUS,
                  currentStep: currentStep,
                  value: Number(value),
                })
              }
            />
          </Grid>
        </>
      )}
      {layout_type === INAPP_LAYOUT_TYPES.FLOATING && (
        <Grid item xs={12} className={classes.gridItem}>
          <Typography className={classes.typographySubtitle2} variant="h6">
            ORIENTATION
          </Typography>
          <ToggleButtonGroup
            value={""}
            exclusive
            onChange={(_, value) => {
              // setImageSize(value || "Cover");
              // if (onBgImageSizeChange) {
              //   onBgImageSizeChange(value || "Cover");
              // }
            }}
          >
            <ToggleButton
              value="Cover"
              aria-label="Cover"
              style={{ padding: 6 }}
            >
              Left
            </ToggleButton>
            <ToggleButton
              value="Contain"
              aria-label="Contain"
              style={{ padding: 6 }}
            >
              Right
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      )}

      <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
      <Grid item xs={12} className={classes.gridItem}>
        <Typography className={classes.typographySubtitle2} variant="h6">
          DIM BACKGROUND
        </Typography>
        {/* have to add the dim bacground options over here, i have to use color selector and opacity and dispatch it to store. */}
        <ToggleSwitch
          className={classes.formControlLabel}
          checked={window_attributes.dimmed_background}
          handleChange={(checked) => {
            dispatch({
              type: SET_DIMMED_BACKGROUND,
              currentStep: currentStep,
              value: checked,
            });
          }}
          style={TOGGLE_SWITCH_STYLES()}
        />
      </Grid>
      {layout_type === INAPP_LAYOUT_TYPES.CAROUSEL && (
        <>
          <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
          <Grid item xs={12} className={classes.gridItem}>
            <Typography className={classes.typographySubtitle2} variant="h6">
              CAROUSEL IMAGES
            </Typography>
            <ActionsTextField
              key="border_radius"
              type="number"
              InputProps={{
                min: 1,
                max: 5,
                type: "number",
              }}
              value={count || 1}
              onChange={(e) => {
                dispatch({
                  type: SET_CAROUSEL_COUNT,
                  value: e.target.value,
                });
                count > 1 &&
                  e.target.value < count &&
                  dispatch({
                    type: UNSET_CAROUSAL_IMAGE,
                  });
              }}
              style={{
                marginBottom: 0,
                marginRight: 1,
              }}
              withPropertySelector={true}
              isOtherUsage={true}
            />
          </Grid>
          {Array.from(
            { length: count ? count : 1 },
            (_, index) => index + 1,
          ).map((imageId) => (
            <Grid container key={imageId}>
              <Grid item xs={4} className={classes.gridItem}>
                <Typography
                  className={classes.typographyCaption}
                  variant="subtitle2"
                >
                  Carousel {imageId}
                </Typography>
              </Grid>
              <Grid item xs={8} className={classes.gridItem}>
                <ActionsTextField
                  placeholder={"https://image_url"}
                  value={(images && images[imageId - 1]) || ""}
                  multiline={false}
                  style={{ width: "100%", marginBottom: 0 }}
                  onChange={(e) => {
                    dispatch({
                      type: SET_CAROUSEL_IMAGES,
                      value: e.target.value,
                      index: imageId,
                    });
                  }}
                />
              </Grid>
            </Grid>
          ))}{" "}
        </>
      )}
      {layout_type === INAPP_LAYOUT_TYPES.FLOATING && (
        <>
          <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
          <Grid item xs={12} className={classes.gridItem}></Grid>
          <Grid container>
            <Grid item xs={4} className={classes.gridItem}>
              <Typography className={classes.typographySubtitle2} variant="h6">
                FLOATING IMAGE
              </Typography>
            </Grid>
            <Grid item xs={8} className={classes.gridItem}>
              <ActionsTextField
                placeholder={"https://image_url"}
                value={""}
                multiline={false}
                style={{ width: "100%", marginBottom: 0 }}
                onChange={(e) => {
                  //const value = e.target.value;
                  //setUrl(value);
                  // executeWithDebounce(onURLchange, {
                  //   url: value,
                  // });
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
      {platform !== APP_PLATFORMS.web && (
        <>
          <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
          <DelayConfig delay={delay} />
        </>
      )}
    </Grid>
  );

}
