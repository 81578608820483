import produce from "immer";
import {
  SET_ACCESS_CODE,
  SET_APP_ID,
  SET_AUTH_ERROR,
  SET_AUTH_LOADING,
  SET_COMPANY_NAME,
  SET_CONFIRM_PASSWORD,
  SET_EMAIL_ID,
  SET_JOB_TITLE,
  SET_NAME,
  SET_OTP,
  SET_PASSWORD,
  SET_PHONE_COUNTRYCODE,
  SET_PHONE_NUMBER,
  SET_TEST_DEVICES,
  SET_USER,
} from "./constants";

export const initial_state = {
  user: {
    name: "",
    email: "",
    phone: "",
    country_code: "+91",
    password: "",
    confirm_password: "",
    job_title: "",
    company_name: "",
    otp: "",
    access_code: "",
    demo_period_end_date: "",
    test_devices: [],
  },
  auth_loading: false,
  auth_error: "",
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case SET_APP_ID:
      draft.appId = action.payload;
      break;
    case SET_USER:
      draft.user = action.payload;
      break;
    case SET_NAME:
      draft.user.name = action.payload;
      break;
    case SET_EMAIL_ID:
      draft.user.email = action.payload;
      break;
    case SET_PHONE_NUMBER:
      draft.user.phone = action.payload;
      break;  
    case SET_PHONE_COUNTRYCODE:
      draft.user.country_code = action.payload;
    case SET_JOB_TITLE:
      draft.user.job_title = action.payload;
      break;
    case SET_COMPANY_NAME:
      draft.user.company_name = action.payload;
      break;
    case SET_PASSWORD:
      draft.user.password = action.payload;
      break;
    case SET_CONFIRM_PASSWORD:
      draft.user.confirm_password = action.payload;
      break;
    case SET_OTP:
      draft.user.otp = action.payload;
      break;
    case SET_ACCESS_CODE:
      draft.user.access_code = action.payload;
      break;
    case SET_TEST_DEVICES:
      draft.user.test_devices = action.payload;
      break;
    case SET_AUTH_LOADING:
      draft.auth_loading = action.payload;
      break;
    case SET_AUTH_ERROR:
      draft.auth_error = action.payload;
      break;
    default:
      break;
  }
});
