import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import {
  downloadJSONAsJSONFile,
  formatTime,
  roundOffNumber,
} from "../../../../../../../utils";
import { useTrackedState } from "../store";

export const Stats = ({ survey = {}, results = {} }) => {
  const { meta: { name = "Unknown" } = {} } = survey;
  const {
    start_date,
    end_date,
    avg_completion_time,
    respondents,
    completion_percent,
  } = results;

  const state = useTrackedState();
  const { others = {} } = state;

  return (
    <Grid
      container
      spacing={16}
      style={{ marginBottom: 16 }}
      justify="flex-end"
      alignContent="flex-end"
    >
      <Grid item xs={6} md={2}>
        <CustomButton textvariant="body2">
          {formatTime(start_date, "Do MMM")} - {formatTime(end_date, "Do MMM")}
        </CustomButton>
      </Grid>
      <Grid item xs={6} md={4}>
        <CustomButton>
          Avg <strong>{roundOffNumber(avg_completion_time / 60)}</strong> Mins
          for completion
        </CustomButton>
      </Grid>
      <Grid item xs={6} md={2}>
        <CustomButton>
          <strong>{respondents} </strong>&nbsp; Respondents
        </CustomButton>
      </Grid>
      <Grid item xs={6} md={2}>
        <CustomButton>
          <strong>{Math.round(completion_percent * 100) / 100} % </strong>{" "}
          Completion
        </CustomButton>
      </Grid>
      <Grid item xs={4} md={2}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            downloadJSONAsJSONFile({ results, others }, name);
          }}
        >
          Export &nbsp; <DownloadIcon />
        </Button>
      </Grid>
    </Grid>
  );
};
const CustomButton = ({
  fullWidth = true,
  buttonVariant = "outlined",
  textVariant = "subheading",
  children,
}) => (
  <Button disabled fullWidth={fullWidth} variant={buttonVariant}>
    <Typography variant={textVariant}>{children}</Typography>
  </Button>
);
