import produce from "immer";
import { SET_CONFIG, SET_OTHER_RESPONSES } from "./constants";

export const initial_state = {
  config: {},
  others: {},
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case SET_OTHER_RESPONSES:
      draft.others[action.key] = action.value;
      break;
    case SET_CONFIG:
      draft.config = action.value;
      break;
    default:
      return draft;
  }
});
