import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import countries from "country-data";
import React from "react";

export default function CountryCodeSelect({ value, onChange }) {
  return (
    <Select
      id="countryCode"
      value={value}
      onChange={onChange}
      fullWidth
      style={{
        fontSize: "15px",
        fontWeight: "600",
        padding: "6px 6px",
        borderRadius: "2px",
        borderColor: "#CCD4DA",
        marginTop: "6px",
      }}
    >
      {countries.countries.all.map((country) => (
        <MenuItem value={country.countryCallingCodes[0]}>
          {`${country.countryCallingCodes[0]} (${country.name})`}
        </MenuItem>
      ))}
    </Select>
  );
}

