import { Button, makeStyles, TextField } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";

/**
 * Options format
 * [
 *  {
 *    name: "Group Name",
 *    options: [
 *      { label: "", value: "" },
 *      { label: "", value: "" }
 *    ]
 *  },
 *  {
 *    name: "Group Name",
 *    options: [
 *      { label: "", value: "" },
 *      { label: "", value: "" }
 *    ]
 *  },
 * ]
 */

const MAX_DISPLAY_ITEMS = 30;

const useStyles = makeStyles((theme) => ({
  inputRoot: (props) => ({
    border: `1px solid #CCD4DA`,
    borderRadius: 3,
    paddingLeft: 10,
    minHeight: props.minInputHeight ? props.minInputHeight : "unset",
  }),
  root: (props) => ({
    width: "auto",
    minWidth: 100,
  }),
  popper: (props) => ({
    width: "auto !important",
    minWidth: 100,
    maxWidth: 600,
  }),
  paper: (props) => ({
    boxShadow:
      "rgb(63 63 68 / 5%) 0px 0px 4px 4px, rgb(63 63 68 / 15%) 0px 1px 3px 0px",
  }),
}));

const getFilteredOptions = (
  options,
  button = null,
  grouped = true,
  maxDisplayItems = MAX_DISPLAY_ITEMS,
  search = null
) => {
  if (!grouped) {
    let updatedOptions = options;
    if (search && typeof search === "string") {
      updatedOptions = updatedOptions.filter(
        (option) =>
          option.label.toLowerCase().includes(search.toLowerCase()) ||
          option.value.toLowerCase().includes(search.toLowerCase())
      );
    }
    const returnOptions = updatedOptions.slice(0, maxDisplayItems);
    if (button) {
      returnOptions.push(button);
    }

    return returnOptions;
  }
  const filtered = [];
  for (let i = 0; i < options.length; i++) {
    const group = options[i];
    const type = group.name;
    let updatedOptions = group.options;
    if (search && typeof search === "string") {
      updatedOptions = updatedOptions.filter(
        (option) =>
          option.label.toLowerCase().includes(search.toLowerCase()) ||
          option.value.toLowerCase().includes(search.toLowerCase())
      );
    }
    updatedOptions = updatedOptions.slice(0, maxDisplayItems);
    filtered.push(
      ...updatedOptions.map((option) => ({
        ...option,
        type: type,
      }))
    );
  }

  if (button) {
    filtered.push(button);
  }

  return filtered;
};

export default function AutocompleteWrapper({
  open = true,
  defaultValue,
  handleOnChange,
  disableClearable = true,
  grouped = false,
  disableOtherGroups = false,
  multiple = false,
  maxDisplayItems = MAX_DISPLAY_ITEMS,
  loading,
  onClose,
  options,
  styles = {},
  button = null,
  floatButton = true,
  minInputHeight = "unset",
  onInputChange = null,
  placeholder = "Search",
  ...props
}) {
  const classes = useStyles({ minInputHeight });

  const [value, setValue] = useState(defaultValue);

  const [filteredOptions, setFilteredOptions] = useState(
    getFilteredOptions(options, button, grouped, maxDisplayItems),
  );

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setFilteredOptions(
      getFilteredOptions(options, button, grouped, maxDisplayItems),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, grouped, maxDisplayItems]);

  let groupedOptions = {};
  if (grouped) {
    groupedOptions = {
      groupBy: (option) => option.type,
    };
  }

  let defaultProps = { open: true };
  if (!open) {
    defaultProps = {};
  }

  if (button) {
    defaultProps = {
      ...defaultProps,
      renderOption: (option, { selected }) => {
        let buttonStyles = {
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
        };
        if (floatButton) {
          buttonStyles = {
            ...buttonStyles,
            position: "absolute",
            bottom: -14,
            left: 0,
            background: "#fff",
            boxShadow: "0px 4px 15px 2px rgba(0, 40, 69, 0.14)",
          };
        }
        return (
          <>
            {option.isButton && (
              <>
                <Button variant="text" color="primary" style={buttonStyles}>
                  {option.label}
                </Button>
              </>
            )}
            {!option.isButton && option.label}
          </>
        );
      },
    };
  }

  if (disableOtherGroups) {
    if (Array.isArray(value) && value.length > 0) {
      defaultProps = {
        ...defaultProps,
        getOptionDisabled: (option) => option.type !== value[0].type,
      };
    }
  }

  return (
    <Autocomplete
      onInputChange={(event) => {
        if (onInputChange && event) {
          onInputChange(event.target.value);
        }
      }}
      {...defaultProps}
      classes={classes}
      style={{ ...styles }}
      multiple={multiple}
      disableClearable={disableClearable}
      popupIcon={<ExpandMoreIcon color="primary" />}
      value={value}
      loading={loading}
      options={filteredOptions}
      onClose={onClose}
      filterOptions={(_, state) => {
        return getFilteredOptions(
          options,
          button,
          grouped,
          maxDisplayItems,
          state?.inputValue,
        );
      }}
      noOptionsText="No options"
      filterSelectedOptions
      {...groupedOptions}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option, value) => {
        return multiple && Array.isArray(option) && Array.isArray(value)
          ? value.filter(
              (item) =>
                option.filter((item2) => item.value === item2.value).length > 0,
            ).length === value.length
          : option.value === value.value && option.label === value.label;
      }}
      onChange={(_, selected) => {
        setValue(selected);
        if (handleOnChange) {
          handleOnChange(selected);
        }
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            fullWidth
            autoFocus
            placeholder={placeholder}
          />
        );
      }}
      {...props}
      placeholder={placeholder}
    />
  );
}

