import React from "react";
import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import RowDiv from "../../../../../../../../../ReusableComponents/RowDiv";
import ColumnDiv from "../../../../../../../../../ReusableComponents/ColumnDiv";
import ModuleDivider from "../../../../../../../../../ReusableComponents/ModuleDivider";
import { useSummaryStyles } from "../../../constants";

export default function ABCampaignScheduleSummary({
  at_specific_time,
  meta: { platform },
  scheduling: schedulingConfig,
  time_limits,
  time_limits_in_day,
  validity: { start_date, end_date },
  config,
}) {
  const headerClasses = useSummaryStyles();
  const {
    experiment: {
      settings: { isManualExperiment },
      publishSettings: {
        time_settings: {
          start_date: publish_start_date,
          end_date: publish_end_date,
          time_limits_in_day: publish_show_at_specific_time,
          time_limits: {
            start_time: publish_start_time,
            end_time: publish_end_time,
          },
        },
      },
    },
  } = config;

  return (
    <>
      <Grid container spacing={1} style={{ marginLeft: -8 }}>
        <Grid item xs={6}>
          <Grid item xs={12}>
            <Typography className={headerClasses.subHeading}>Test:</Typography>
          </Grid>
          <Grid item xs={12}>
            <RowDiv center applyMargin>
              <Typography className={headerClasses.heading}>
                Start Date
              </Typography>
              <Typography className={headerClasses.body}>
                {moment(start_date).format("DD MMM, YYYY, HH:mm")}
              </Typography>
            </RowDiv>
          </Grid>
          <Grid item xs={12}>
            <RowDiv center applyMargin>
              <Typography className={headerClasses.heading}>
                End Date
              </Typography>
              <Typography className={headerClasses.body}>
                {moment(end_date).format("DD MMM, YYYY, HH:mm")}
              </Typography>
            </RowDiv>
          </Grid>
          {(time_limits_in_day || at_specific_time) && (
            <>
              <Grid item xs={12}>
                <RowDiv center applyMargin>
                  <Typography className={headerClasses.heading}>
                    Start Time
                  </Typography>
                  <Typography className={headerClasses.body}>
                    {moment(time_limits.start_time, "HH:mm")
                      .utc(true)
                      .format("HH:mm") + " UTC"}
                  </Typography>
                </RowDiv>
              </Grid>
              <Grid item xs={12}>
                <RowDiv center applyMargin>
                  <Typography className={headerClasses.heading}>
                    End Time
                  </Typography>
                  <Typography className={headerClasses.body}>
                    {moment(time_limits.end_time, "HH:mm")
                      .utc(true)
                      .format("HH:mm") + " UTC"}
                  </Typography>
                </RowDiv>
              </Grid>
            </>
          )}
        </Grid>
        {!isManualExperiment && (
          <Grid item xs={6}>
            <Grid item xs={12}>
              <Typography className={headerClasses.subHeading}>
                Campaign:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <RowDiv center applyMargin>
                <Typography className={headerClasses.heading}>
                  Start Date
                </Typography>
                <Typography className={headerClasses.body}>
                  {moment(publish_start_date).format("DD MMM, YYYY, HH:mm")}
                </Typography>
              </RowDiv>
            </Grid>
            <Grid item xs={12}>
              <RowDiv center applyMargin>
                <Typography className={headerClasses.heading}>
                  End Date
                </Typography>
                <Typography className={headerClasses.body}>
                  {moment(publish_end_date).format("DD MMM, YYYY, HH:mm")}
                </Typography>
              </RowDiv>
            </Grid>
            {publish_show_at_specific_time && (
              <>
                <Grid item xs={12}>
                  <RowDiv center applyMargin>
                    <Typography className={headerClasses.heading}>
                      Start Time
                    </Typography>
                    <Typography className={headerClasses.body}>
                      {moment(publish_start_time, "HH:mm")
                        .utc(true)
                        .format("HH:mm") + " UTC"}
                    </Typography>
                  </RowDiv>
                </Grid>
                <Grid item xs={12}>
                  <RowDiv center applyMargin>
                    <Typography className={headerClasses.heading}>
                      End Time
                    </Typography>
                    <Typography className={headerClasses.body}>
                      {moment(publish_end_time, "HH:mm")
                        .utc(true)
                        .format("HH:mm") + " UTC"}
                    </Typography>
                  </RowDiv>
                </Grid>
              </>
            )}
          </Grid>
        )}
      </Grid>
      <Grid container spacing={1} style={{ marginLeft: -8 }}>
        <Grid item xs={12}>
          {schedulingConfig?.isRecurEnabled && (
            <Grid item xs={12}>
              <ModuleDivider styles={{ marginBottom: 10, marginTop: 10 }} />
              <ColumnDiv>
                <Typography className={headerClasses.orangeHeading}>
                  RECUR CAMPAIGN
                </Typography>
                <RowDiv center applyMargin>
                  <Typography className={headerClasses.heading}>
                    Refresh Users in Segment
                  </Typography>
                  <Typography className={headerClasses.body}>
                    Every {schedulingConfig.step} {schedulingConfig.recur}(s)
                  </Typography>
                </RowDiv>
              </ColumnDiv>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}
