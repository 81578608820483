import React from "react";
import { Grid, TextField, Button, withStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useTracked } from "../../../store";
import {
  ANSWER_MODES,
  DEFAULT_TEXT_CONFIG,
  ADD_NEW_CHOICE,
  REMOVE_CHOICE,
  SET_QUESTION_TITLE,
} from "../../../constants";
import Choice from "../../surveysconfigsection/components/questions/components/Choice";
import TextBasedQuestionSimulator from "./textBasedSimulator/components/TextBasedQuestionSimulator";
import RatingBasedQuestionSimulator from "./ratingSimulator/components/RatingBasedQuestionSimulator";
import SuccessMessageSimulator from "./successMessageSimulator";
import RequestSurveySimulator from "./RequestSurveySimulator";

const styles = theme => ({
  formLabel: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: "700 !important",
    marginBottom: 5,
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
  },
});

const displayQuestion = answerMode => {
  return (
    answerMode !== ANSWER_MODES.success_message &&
    answerMode !== ANSWER_MODES.request_survey
  );
};

/**
 * Holds a single question.
 *
 * List of possible actions:
 * 1. Delete this question
 * 2. Move this question up or down
 * 3. Choose the Answer Mode from list of available Answer Modes
 *
 * Based on the selected Answer Mode, the rendering of the question
 * contents or choices vary.
 *
 * Single Choice:
 *    Takes the list of choices and display each choice item as a vertical
 *    list. We can delete a particular choice item using Delete icon
 *
 *    If the Survey is response based survey, each choice contains a list
 *    to choose the next question to be displayed when this gets selected.
 *
 * Multi Choice:
 *    Just like Single Choice. No additional things and response based
 *    survey doesn't applicable for this Answer Mode
 *
 * Rating:
 *    No config params for this as of now
 *
 * Plain Text:
 *    Contains config params like text color, background color, etc
 */
function QuestionSimulator({
  answer_mode = "single_choice",
  classes,
  choices = [],
  id,
  is_required,
  randomize_choices,
  text_config = DEFAULT_TEXT_CONFIG,
  title,
}) {
  /**
   * When you use `react-tracked` lib, you don't need to pass props
   *
   * Always read the latest state from the store and post the changes
   * back to the store using `dispatch`
   *
   * There's a lot of confusion whether we need to maintain the component
   * specific state or not. AFAI see, I don't face any issues or any need
   */
  const [state, dispatch] = useTracked();

  const {
    ui: { is_response_based_survey },
  } = state;

  const hasOtherChoice = [].concat(...choices).filter(item => item.is_other)
    .length;
  const showExtraOptions =
    answer_mode === ANSWER_MODES.single_choice ||
    answer_mode === ANSWER_MODES.multi_choice;
  return (
    <Grid container alignItems={"stretch"} justify={"center"}>
      {displayQuestion(answer_mode) && (
        <Grid item xs={12}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={6} className={classes.gridItem}>
              <TextField
                placeholder="Type your question"
                fullWidth
                style={{ height: 44, width: 286 }}
                variant="standard"
                value={title || ""}
                onChange={e => {
                  dispatch({
                    type: SET_QUESTION_TITLE,
                    id: id,
                    value: e.target.value,
                  });
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}

      {/**
       * Only be visible for Single and Multi Choice questions.
       * For other types, this doesn't make any sense
       */}
      {showExtraOptions && (
        <Grid item xs={12}>
          {choices.map((choice, index) => (
            <Choice
              key={`choice-${index}`}
              questionId={id}
              {...choice}
              responseBasedSurvey={false}
              isMultiChoice={answer_mode === ANSWER_MODES.multi_choice}
              handleUpdate={(type, value) => {
                dispatch({
                  type: type,
                  qid: id,
                  choiceId: choice.id,
                  value: value,
                });
              }}
              handleDelete={() => {
                // This particular choice item will be removed from this question
                if (index > -1) {
                  dispatch({
                    type: REMOVE_CHOICE,
                    qid: id,
                    choiceId: choice.id,
                  });
                }
              }}
            />
          ))}
          <br />
          <div style={{ display: "flex" }}>
            <Button
              variant="text"
              color="primary"
              disabled={!(choices.length <= 10)}
              onClick={e => {
                dispatch({
                  type: ADD_NEW_CHOICE,
                  id: id,
                  isOther: false,
                });
              }}
            >
              <AddIcon /> Add Choice
            </Button>
            {hasOtherChoice < 1 && (
              <Button
                variant="text"
                color="primary"
                onClick={e => {
                  dispatch({
                    type: ADD_NEW_CHOICE,
                    id: id,
                    isOther: true,
                  });
                }}
              >
                <AddIcon /> Add Other
              </Button>
            )}
          </div>
        </Grid>
      )}
      {/**
       * A separate component for the Text Based Question as it contains
       * lot of config parameters
       */}
      {answer_mode === ANSWER_MODES.text && (
        <TextBasedQuestionSimulator id={id} text_config={text_config} />
      )}
      {/**
       * Seperate component for Rating Section
       */}
      {answer_mode === ANSWER_MODES.rating && (
        <RatingBasedQuestionSimulator id={id} text_config={text_config} />
      )}

      {answer_mode === ANSWER_MODES.success_message && (
        <SuccessMessageSimulator id={id} />
      )}
      {answer_mode === ANSWER_MODES.request_survey && (
        <RequestSurveySimulator id={id} />
      )}
    </Grid>
  );
}

export default withStyles(styles)(QuestionSimulator);
