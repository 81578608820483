import React from "react";
import { DragSource, DropTarget } from "react-dnd";
import Tooltip from "@material-ui/core/Tooltip";
import RemoveIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/ButtonBase";
import DragHandleIcon from "@material-ui/icons/MoreVert";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { ItemTypes } from "../constants";

const dimensionSource = {
  beginDrag(props) {
    return {
      ...props,
      id: props.id,
      originalIndex: props.findDimension(props.id).index,
    };
  },

  endDrag(props, monitor) {
    const { id: droppedId, originalIndex } = monitor.getItem();
    const didDrop = monitor.didDrop();

    if (!didDrop) {
      props.moveDimension(droppedId, originalIndex);
    }
  },
};

const dimensionTarget = {
  canDrop() {
    return false;
  },

  hover(props, monitor) {
    const { id: draggedId } = monitor.getItem();
    const { id: overId } = props;

    if (draggedId !== overId) {
      const { index: overIndex } = props.findDimension(overId);
      props.moveDimension(draggedId, overIndex);
    }
  },
};

function sourceCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

function targetCollect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}

function Dimension({
  connectDragSource,
  connectDropTarget,
  handleRemove,
  id,
  dimension,
  classes,
}) {
  return connectDragSource(
    connectDropTarget(
      <div style={{ cursor: "move" }}>
        <div
          style={{
            pointerEvents: "auto",
            width: "100%",
            position: "relative",
            padding: 0,
            margin: 0,
            overflow: "hidden",
          }}
        >
          <ListItem dense disableGutters className={classes.root}>
            <ListItemIcon>
              <DragHandleIcon className={classes.listIcon} />
            </ListItemIcon>
            <ListItemText primary={dimension.name} />
            <ListItemSecondaryAction>
              <Tooltip
                id={"dimension-remove-tooltip-" + id}
                title="Remove"
                placement="top"
              >
                <IconButton
                  aria-label="Remove"
                  onClick={handleRemove}
                  style={{ height: "auto", width: "auto" }}
                >
                  <RemoveIcon color="secondary" />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        </div>
      </div>
    )
  );
}

export default DropTarget(
  ItemTypes.DIMENSION,
  dimensionTarget,
  targetCollect
)(DragSource(ItemTypes.DIMENSION, dimensionSource, sourceCollect)(Dimension));
