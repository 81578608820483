import React, { useState, useEffect, useMemo } from "react";
import BrokenImageIcon from "@material-ui/icons/BrokenImage";
import Toolbar from "./components/Toolbar";
import Placeholder from "../../../../../ReusableComponents/Placeholder";
import { useAuth } from "../../../../../../contexts/AuthContext";
import { getSegmentsAPI, getAttributes } from "../../../../../common/actions";
import { useDateFilters } from "../../../../common/utils";
import Filters from "../../../../common/components/Filters";
import { Divider } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import UserSessionDistributions from "../../../../../ReusableComponents/UserSessionDistributions";
import SegmentTimeSeries from "./components/SegmentTimeSeries";
import Loading from "../../../../../ReusableComponents/Loading";

export default function AnalyseSegment({ app }) {
  const location = useLocation();
  const state = useMemo(
    () => (location && location.state ? location.state : null),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const auth = useAuth();
  const [version, setVersion] = useState(null);
  const [dateFilters, setDateFilters] = useDateFilters();

  const filters = useMemo(
    () => ({
      user: [],
      session: [],
      event: [],
    }),
    []
  );
  const [pending, setPending] = useState(false);
  const [segments, setSegments] = useState([]);
  const [userAttributes, setUserAttributes] = useState([]);
  const [sessionAttributes, setSessionAttributes] = useState([]);
  const [segmentId, setSegmentId] = useState(
    state && state.segmentId ? state.segmentId : ""
  );
  const [group, setGroup] = useState("all_users");

  const queryParams = useMemo(
    () => ({ ...dateFilters, versions: version, group: group }),
    [dateFilters, group, version]
  );

  useEffect(() => {
    setPending(false);
    getSegmentsAPI(auth, app.app_id, dateFilters, filters)
      .then((response) => {
        setSegments(response);
        setPending(false);
      })
      .catch((err) => {
        setPending(false);
      });
    getAttributes(auth, app.app_id).then((response) => {
      setUserAttributes(response.user);
      setSessionAttributes(response.session);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, app.app_id]);

  const isValidSegment = useMemo(
    () =>
      segmentId !== "" && segments.findIndex((o) => o._id === segmentId) > -1,
    [segments, segmentId]
  );
  if (!isValidSegment) {
    return !pending && (segmentId === "" || segments.length > 0) ? (
      <section className="content">
        <Placeholder
          withIcon
          icon={
            <BrokenImageIcon
              style={{ opacity: 0.2, width: 250, height: 250 }}
            />
          }
          text="Not a valid Segment. Try coming from segments page."
        />
      </section>
    ) : (
      <section className="content">
        <Loading />
      </section>
    );
  } else {
    return (
      <>
        <Filters
          app={app}
          dateFilters={dateFilters}
          showVersion
          onVersionChange={(version) =>
            setVersion(version === "" ? null : version)
          }
          onDateFiltersChange={(start, end) =>
            setDateFilters({
              since: start,
              till: end,
            })
          }
        />
        <Divider />
        <section className="content">
          <Toolbar
            appId={app.app_id}
            filters={filters}
            queryParams={queryParams}
            segments={segments.map(({ _id, name }) => ({
              label: name,
              value: _id,
            }))}
            segmentId={segmentId || ""}
            onGroupChange={(group) => setGroup(group)}
            onSegmentChange={setSegmentId}
          />
          <UserSessionDistributions
            appId={app.app_id}
            queryParams={queryParams}
            filters={filters}
            userAttributes={userAttributes}
            sessionAttributes={sessionAttributes}
            withBox={false}
            showUserSessionSwitch={false}
            selectable={false}
          />
          <SegmentTimeSeries
            appId={app.app_id}
            queryParams={queryParams}
            filters={filters}
            segmentId={segmentId}
            segments={segments}
            attributes={[...userAttributes, ...sessionAttributes]}
          />
        </section>
      </>
    );
  }
}
