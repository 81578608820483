import React from "react";
import { useAuth } from "../../../../contexts/AuthContext";
import DevSignUpForm from "../components/forms/DevSignUpForm";
import SetPasswordForm from "../components/forms/SetPasswordForm";
import SignUpForm from "../components/forms/SignUpForm";
import { AUTH_PAGE_TYPE } from "../constants";
import AuthLayout from "../layouts/AuthLayout";

export default function SignUpPage() {
  const { pageType } = useAuth();

  function formToRender() {
    if (pageType === AUTH_PAGE_TYPE.DEV_SIGN_UP) return <DevSignUpForm />;
    else if (pageType === AUTH_PAGE_TYPE.SET_PASSWORD)
      return <SetPasswordForm />;
    else if (pageType === AUTH_PAGE_TYPE.SIGN_UP) return <SignUpForm />;
  }

  return (
    <AuthLayout
      title={
        pageType === AUTH_PAGE_TYPE.SET_PASSWORD ? "Set Password" : "Sign Up"
      }
    >
      {formToRender()}
    </AuthLayout>
  );
}
