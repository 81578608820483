/**
 * Created by Rakesh Peela
 * Date: 21-Mar-2020
 * Time: 11:55 PM
 */

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../../contexts/AuthContext";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  DialogContent,
  Typography,
  DialogActions,
  Dialog,
} from "@material-ui/core";
import Loading from "../../../ReusableComponents/Loading";
import { approveAppAPI } from "./actions";

function AppApproval({ app }) {
  const auth = useAuth();

  const history = useHistory();

  const [pending, setPending] = useState(false);
  const [failed, setFailed] = useState(false);
  const [success, setSuccess] = useState(false);

  const [waiting, setWaiting] = useState(false);
  const [showApprovalDialog, setShowApprovalDialog] = useState(false);

  const {
    basic_info: {
      customers,
      icon_url,
      approved,
      approved_by,
      ...otherInfoKeys
    },
    basic_info,
  } = app;

  useEffect(() => {
    const {
      basic_info: { approved, approved_by },
      app_id,
    } = app;
    if (approved && approved_by) {
      setWaiting(true);
      setTimeout(() => {
        history.push({
          pathname: `/apps/${app_id}/event-analysis`,
          state: {
            app: app,
          },
        });
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app]);

  return (
    <section
      style={{ width: "100%", marginTop: 100, height: "calc(100vh - 100px)" }}
    >
      <Grid container justify="center">
        <Grid item xs={4} md={4}>
          <Card>
            <CardContent>
              {waiting && <Loading size={36} />}

              {!waiting && (
                <div>
                  <Grid container spacing={4}>
                    {Object.keys(otherInfoKeys).map((key, idx) => {
                      return (
                        <Grid item xs={6} key={idx}>
                          <div style={{ fontWeight: 600, paddingRight: 12 }}>
                            {key.replace("_", " ").toUpperCase()}
                          </div>
                          <div>{basic_info[key]}</div>
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              )}
            </CardContent>
            <CardActions style={{ borderTop: "1px solid #cecece" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                {/*<Button color={"secondary"}>*/}
                {/*    Decline*/}
                {/*</Button>*/}
                <Button
                  variant={"outlined"}
                  color={"primary"}
                  onClick={() => {
                    setShowApprovalDialog(true);
                  }}
                >
                  {waiting && <Loading size={12} style={{ marginRight: 12 }} />}{" "}
                  Approve
                </Button>
              </div>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      {showApprovalDialog && (
        <Dialog
          scroll="body"
          open={showApprovalDialog}
          onClose={() => {
            setPending(false);
            setFailed(false);
            setSuccess(false);
          }}
        >
          <DialogContent>
            <Typography variant={"h6"}>
              Are you sure you want to Approve the app "
              <b>{otherInfoKeys.app_name}</b>"?
            </Typography>
          </DialogContent>
          <DialogActions>
            <div>
              <div>
                <Button
                  disabled={pending}
                  onClick={() => {
                    setShowApprovalDialog(false);
                  }}
                >
                  {" "}
                  No{" "}
                </Button>
                <Button
                  disabled={pending}
                  onClick={() => {
                    setPending(true);
                    setFailed(false);
                    approveAppAPI(auth, app.app_id)
                      .then((response) => {
                        setPending(false);
                        if (response) {
                          setSuccess(true);
                          setTimeout(() => {
                            history.push(`/apps/${app.app_id}/integration`);
                          }, 1200);
                        } else {
                          setSuccess(false);
                          setFailed(true);
                        }
                      })
                      .catch((e) => {
                        setFailed(true);
                      });
                  }}
                >
                  {pending && <Loading size={12} style={{ marginRight: 12 }} />}{" "}
                  Yes
                </Button>
              </div>
              <div>
                {failed && (
                  <Typography color={"error"}>
                    App Approval Failed. Try again after sometime.
                  </Typography>
                )}
                {success && (
                  <Typography color={"primary"}>
                    App Approval Success
                  </Typography>
                )}
              </div>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </section>
  );
}

export default AppApproval;
