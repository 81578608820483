import React from "react";
import { Button, withStyles } from "@material-ui/core";
import { useTracked } from "../../../../store";
import ColumnDiv from "../../../../../../../../../ReusableComponents/ColumnDiv";
import RowDiv from "../../../../../../../../../ReusableComponents/RowDiv";
import dialog_icon from "../../../../assets/dialog_icon.svg";
import {
  selectedColor,
  SET_DESCRIPTION_TEXT,
  SET_TITLE_TEXT,
} from "../../../../constants";
import { randomColorWithIndex } from "../../../../../../../../../../utils";
import ActionsTextField from "../../../../../../../../../ReusableComponents/ActionsTextField";

const styles = theme => ({
  gridItem: {},
});

/**
 * Contains all the config params related to Text Based Question
 */
function RequestSurveySimulator({ classes, id }) {
  const [state, dispatch] = useTracked();

  const {
    ui: {
      dialog_config: {
        icon_name = null,
        yes_text = "Yes",
        yes_button_bg_color,
        yes_button_text_color,
        no_text = "No",
        no_button_bg_color,
        no_button_text_color,
        title,
        title_color,
      },
    },
  } = state;

  return (
    <ColumnDiv styles={{ height: 300, width: 250 }}>
      <ColumnDiv styles={{ justifyContent: "center", height: "100%" }}>
        <div
          style={{
            textAlign: "center",
            // position: isOverAllSimulation ? "absolute" : "",
            bottom: 0,
          }}
        >
          {icon_name && <img src={dialog_icon} alt="Apxor" />}
          <ActionsTextField
            style={{ marginTop: 20, marginBottom: 10 }}
            fullWidth
            required
            type="text"
            value={title}
            onChange={(e) => {
              dispatch({
                type: SET_TITLE_TEXT,
                id,
                value: e.target.value,
              });
            }}
            textFieldStyle={{
              minWidth: 200,
              color: title_color,
            }}
          />
          <RowDiv center styles={{ justifyContent: "center" }}>
            <Button
              variant="contained"
              style={{
                background: yes_button_bg_color
                  ? yes_button_bg_color
                  : selectedColor(false, randomColorWithIndex(0)),
                margin: "8px 0",
                width: 102,
                height: 34,
              }}
            >
              <strong
                style={{
                  textTransform: "none",
                  color: yes_button_text_color
                    ? yes_button_text_color
                    : selectedColor(true),
                }}
              >
                {yes_text}
              </strong>
            </Button>
          </RowDiv>
          <Button
            variant="text"
            style={{
              background: no_button_bg_color ? no_button_bg_color : "#FFF",
            }}
          >
            <strong
              style={{
                textTransform: "none",
                color: no_button_text_color ? no_button_text_color : "",
              }}
            >
              {no_text}
            </strong>
          </Button>
        </div>
      </ColumnDiv>
    </ColumnDiv>
  );
}

export default withStyles(styles)(RequestSurveySimulator);
