import React, { useEffect, useState } from "react";
import { useTracked } from "../../../../../store";
import { Button, Grid, Typography } from "@material-ui/core";

import MultiSelect from "../../../../../../../../../../ReusableComponents/MultiSelect";
import {
  SET_SURVEY_RATING_BUTTONS,
  SET_SURVEY_RATING_STYLE,
  SURVEY_RATING_STYLE,
  TOGGLE_RATING_REDIRECTION,
  ADD_NEW_RULE,
  RESET_REACTIONS,
} from "../../../../../constants";
import { capitalizeEachWord } from "../../../../../../../../../../../utils";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useCommonConfigStyles } from "../../../../../../../../walkthroughs/components/design/components/configuration/utils";
import ModuleDivider from "../../../../../../../../../../ReusableComponents/ModuleDivider";
import ToggleSwitch from "../../../../../../../../../../ReusableComponents/ToggleSwitch";
import { TOGGLE_SWITCH_STYLES } from "../../../../../../../../../common/components/campaigncreator/constants";
import RatingQuestionFollowupBuilder from "./RatingQuestionFollowupBuilder";

const getAvailableOptions = (rules, rating_scale) => {
  const options = rules
    .map(everyRule => {
      const { min, max } = everyRule;
      if (!(isNaN(min) || isNaN(max))) {
        return [...Array(max - min + 1).keys()].map(x => x + min);
      } else return [];
    })
    .flat();

  const originalOptions = Array.from(
    { length: rating_scale === 5 ? rating_scale : rating_scale + 1 },
    (_, index) => index + 1
  );
  return originalOptions.filter(
    eachOption => options.indexOf(eachOption) === -1
  );
};

function RatingBasedQuestion() {
  /**
   * Used to update the changes to the local store
   */
  const [state, dispatch] = useTracked();
  const {
    currentStep,
    ui: { questions = [] },
  } = state;
  const {
    rating_style = "",
    rating_scale = "",
    advanced_options = false,
    rules,
  } = questions[currentStep];
  const classes = useCommonConfigStyles();
  const [optionsAvailable, setOptionsAvailable] = useState([]);

  useEffect(() => {
    rules && setOptionsAvailable(getAvailableOptions(rules, rating_scale));
  }, [rating_scale, rules]);

  return (
    <>
      <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
      <div style={{ padding: 8, width: "100%" }}>
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.gridItem}>
            <Typography
              className={classes.typographySubtitle2}
              variant="subtitle2"
            >
              RATING STYLE
            </Typography>
            <MultiSelect
              style={{ maxWidth: 145, minWidth: 145 }}
              single
              clearable={false}
              isSearchable={false}
              options={Object.keys(SURVEY_RATING_STYLE).map(actionType => ({
                value: SURVEY_RATING_STYLE[actionType],
                label: capitalizeEachWord(SURVEY_RATING_STYLE[actionType]),
              }))}
              value={rating_style.toUpperCase()}
              handleChange={value => {
                dispatch({
                  type: SET_SURVEY_RATING_STYLE,
                  currentStep: currentStep,
                  value: value,
                });
              }}
            />
          </Grid>
          <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
          <Grid item xs={12} className={classes.gridItem}>
            <Typography
              className={classes.typographySubtitle2}
              variant="subtitle2"
            >
              NUMBER OF RATING ICONS
            </Typography>
            <ToggleButtonGroup
              value={rating_scale + ""}
              exclusive
              onChange={(e, value) => {
                let ratingIcons = 5;
                if (value === "5") {
                  ratingIcons = 5;
                } else if (value === "10") {
                  ratingIcons = 10;
                }
                dispatch({
                  type: SET_SURVEY_RATING_BUTTONS,
                  currentStep,
                  count: ratingIcons,
                });
                dispatch({
                  type: RESET_REACTIONS,
                  currentStep: currentStep,
                });
              }}
            >
              <ToggleButton value="5" aria-label="5" style={{ padding: 8 }}>
                <span style={{ padding: "0px 8px" }}>5</span>
              </ToggleButton>
              <ToggleButton value="10" aria-label="10" style={{ padding: 8 }}>
                <span style={{ padding: "0px 8px" }}>10</span>
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <ModuleDivider styles={{ width: "100%", margin: "8px 0px" }} />
          <Grid item xs={12} className={classes.gridItem}>
            <Typography
              className={classes.typographySubtitle2}
              variant="subtitle2"
            >
              ADVANCED
            </Typography>
            <ToggleSwitch
              className={classes.formControlLabel}
              checked={advanced_options}
              handleChange={(checked, e) => {
                dispatch({
                  type: TOGGLE_RATING_REDIRECTION,
                  currentStep: currentStep,
                  value: checked,
                });
              }}
              style={TOGGLE_SWITCH_STYLES(advanced_options)}
            />
          </Grid>
          {advanced_options &&
            rules.map((eachRule, index) => {
              return (
                <RatingQuestionFollowupBuilder
                  questionIndex={eachRule.qid}
                  ruleData={eachRule}
                  rules={rules}
                  optionsAvailable={optionsAvailable}
                  updateOptionsAvailable={(min, max, operation = "remove") => {
                    if (operation === "remove" && !(isNaN(min) || isNaN(max))) {
                      let options = optionsAvailable;
                      options.splice(
                        optionsAvailable.indexOf(min),
                        max - min + 1
                      );
                      setOptionsAvailable(options);
                    } else if (
                      operation === "add" &&
                      !(isNaN(min) || isNaN(max))
                    ) {
                      setOptionsAvailable([
                        ...[...Array(max - min + 1).keys()].map(x => x + min),
                        ...optionsAvailable,
                      ]);
                    } else if (operation === "reset") {
                      setOptionsAvailable(
                        getAvailableOptions(rules, rating_scale)
                      );
                    }
                  }}
                />
              );
            })}
          {advanced_options && (
            <Button
              color={"primary"}
              disabled={!optionsAvailable.length}
              onClick={() => {
                dispatch({
                  type: ADD_NEW_RULE,
                  currentStep: currentStep,
                  value: Math.floor(Math.random() * Date.now()) % 100000,
                });
              }}
            >
              <span style={{ textTransform: "none" }}>+ Add more</span>
            </Button>
          )}
        </Grid>
      </div>
    </>
  );
}

export default RatingBasedQuestion;
