import { makeGetRequest } from "../../../common/actions";

const DASHBOARD_STATS = "art-configs/metrics";
const DASHBOARD_CAMPAIGNS = "art-configs/last";
const USER_COUNT = "active-users";

export function getCampaignCountAPI(
  auth,
  appId,
  queryParams,
  enableCancellation = true
) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return [];
  };
  return makeGetRequest(
    DASHBOARD_STATS,
    auth,
    appId,
    { configType: queryParams },
    onSuccessCallback,
    onFailureCallback,
    enableCancellation
  );
}
//configType can be walkthroughs or surveys
export function getCampaignsAPI(
  auth,
  appId,
  configType,
  enableCancellation = true
) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return [];
  };
  const queryParams = { configType: configType, limit: 3 };
  return makeGetRequest(
    DASHBOARD_CAMPAIGNS,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback,
    enableCancellation
  );
}

export function getUserCountAPI(auth, appId) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return [];
  };
  return makeGetRequest(
    USER_COUNT,
    auth,
    appId,
    {},
    onSuccessCallback,
    onFailureCallback
  );
}

export function getCampaignsReachedAPI(auth, appId, configType) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return [];
  };
  const queryParams = { configType: configType, limit: 3 };
  return makeGetRequest(
    DASHBOARD_CAMPAIGNS,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getCampaignExposedAPI(
  auth,
  appId,
  queryParams,
  apiName = "d0_uninstall"
) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return [];
  };
  return makeGetRequest(
    apiName,
    auth,
    appId,
    { configType: queryParams },
    onSuccessCallback,
    onFailureCallback
  );
}

