import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Provider, useDispatch, useTrackedState } from "./store";
import { useTracked } from "../../../../common/components/campaigncreator/store";
import { initial_state } from "./reducer";
import SurveysConfigSection from "./components/surveysconfigsection";
import SurveyFlowBuilder from "./components/flowbuilder";
import { APP_PLATFORMS } from "../../../../../../constants";
import Simulator from "./components/simulator";
import SurveyPreviewDialog from "./components/designPreviewSection/components/SurveyPreviewDialog";
import { SET_CURRENT_STEP, SET_UI_CONFIG } from "./constants";

function DesignPageWithProvider() {
  const [state] = useTracked();
  const dispatch = useDispatch();
  const localState = useTrackedState();

  const {
    config: {
      ui = {},
      meta: { config_type = "survey", platform },
      testing: { selected = "" },
    },
  } = state;

  // If there is already an `ui` object in global store,
  // update it in our local store
  useEffect(() => {
    dispatch({
      type: SET_UI_CONFIG,
      ui: Object.keys(ui).length > 0 ? ui : initial_state.ui,
    });
    dispatch({
      type: SET_CURRENT_STEP,
      value: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Left side simulator
   * Right side config section
   *
   * As we are persisting the UI config in the local store, we don't
   * have to pass any props
   */
  return (
    <div>
      <SurveyFlowBuilder />
      {localState.ui &&
        localState.ui.questions &&
        localState.ui.questions.length > 0 &&
        localState.currentStep >= 0 && (
          <Grid
            container
            spacing={0}
            style={{
              display: "flex",
              width:
                platform === APP_PLATFORMS.web ? "100%" : "calc(100% + 16px)",
              margin: platform === APP_PLATFORMS.web ? 0 : -16,
              borderRadius: 5,
              marginTop: platform === APP_PLATFORMS.web ? 0 : 6,
            }}
          >
            <Grid item xs={3}>
              <SurveyPreviewDialog isCanvas={true} />
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#f0f2f4",
                border: "1px solid #DCE0E3",
                borderRadius: 3,
              }}
            >
              <Simulator platform={platform} />
            </Grid>
            <Grid item xs={4} style={{ padding: "0px 0px 0px 4px" }}>
              <SurveysConfigSection />
            </Grid>
          </Grid>
        )}
    </div>
  );
}

/**
 * Wrap our design page with react-tracked store.
 *
 * The design of this component is as follows:
 *
 * On this design page render, we take the UI from the CampaignCreator's
 * store and persist it into our local store
 *
 * So, whenever the UI JSON gets modified instead of sending each and every
 * change to the parent store, we store that change in local store and then
 * push the entire UI (which got modified) to the global store
 *
 * UI JSON field change -> Update in local store -> Update the updated UI
 * in global store
 *
 * If we don't update the UI JSON changes in global store, we will lose
 * those when we navigate to some other step in CampaignCreator
 */
export default function DesignPage() {
  return (
    <Provider>
      <DesignPageWithProvider />
    </Provider>
  );
}
