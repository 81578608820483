import React, { useState } from "react";
import { withStyles } from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import FileDownloads from "./FileDownloads";

const styles = (theme) => ({
  content: {
    padding: "25px 15%",
    height: "auto",
  },
  tabsRoot: {
    background: "#fff",
    paddingTop: 48,
    flex: "1 0 auto",
  },
  tabsIndicator: {
    height: 3,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
  },
  tabRoot: {
    textTransform: "initial",
    minWidth: 72,
    fontSize: 14,
    fontWeight: "500 !important",
    marginRight: theme.spacing(4),
    "&:focus": {
      // color: '#40a9ff',
    },
  },
});

const DATA_SECTIONS = ["Downloads"];

function UserData({ app, classes }) {
  const [currentSection, setCurrentSection] = useState(0);

  const handleDataTabsChange = (event, value) => {
    setCurrentSection(value);
  };

  return (
    <>
      <section
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: 12,
          background: "#fff",
          borderBottom: "1px solid #e8e8e8",
          padding: "0 15%",
        }}
      >
        <Tabs
          classes={{
            root: classes.tabsRoot,
            indicator: classes.tabsIndicator,
          }}
          value={currentSection}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleDataTabsChange}
        >
          {Object.keys(DATA_SECTIONS).map((eachSection) => (
            <Tab
              disableRipple
              key={eachSection}
              classes={{ root: classes.tabRoot }}
              label={DATA_SECTIONS[eachSection]}
            />
          ))}
        </Tabs>
      </section>
      <section
        className={classes.content}
        style={{ paddingTop: 0, paddingBottom: 96 }}
      >
        {currentSection === 0 && <FileDownloads appId={app.app_id} />}
      </section>
    </>
  );
}

export default withStyles(styles)(UserData);
