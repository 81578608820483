import React, { useRef } from "react";
import { useTracked } from "../store";
import ActionsTextField from "../../../../ReusableComponents/ActionsTextField";
import {
  SET_NOTIFICATION_TITLE,
  SET_NOTIFICATION_BODY,
  SET_CHANNEL_ID,
  SET_REDIRECTION_TYPE,
  SET_ACTIVITY_NAME,
  SET_DEEP_LINK_URI,
  REDIRECTION_TYPES,
  NOTIFICATION_ENUM,
} from "../constants";
import { Grid, Typography } from "@material-ui/core";
import MultiSelect from "../../../../ReusableComponents/MultiSelect";
import { APP_PLATFORMS } from "../../../../../constants";

export function DefaultPayload({ errors, app, formik }) {
  const [state, dispatch] = useTracked();
  const { push_config, app_screens } = state;
  const { payload, meta = {} } = push_config;
  const {
    notification_title,
    notification_body,
    activity_name,
    deep_link_uri,
    channel_id,
    redirection_type,
    notification_type,
  } = payload;
  const stateRef = useRef(state.push_config);
  stateRef.current = state.push_config;

  const platform = meta?.platform;

  return (
    <Grid container spacing={8}>
      <Grid item xs>
        <>
          <Typography>Notification Title</Typography>
          <ActionsTextField
            type="text"
            value={notification_title}
            onChange={e =>
              dispatch({
                type: SET_NOTIFICATION_TITLE,
                value: e.target.value,
              })
            }
            fullWidth
            margin="normal"
            validationErrors={errors?.payload?.notification_title}
            style={{ marginBottom: 12 }}
          />
          <Typography>
            {notification_type === NOTIFICATION_ENUM.big_text
              ? "Notification Body (Long Text)"
              : "Notification Body"}
          </Typography>
          <ActionsTextField
            type="text"
            value={notification_body || ""}
            onChange={e =>
              dispatch({ type: SET_NOTIFICATION_BODY, value: e.target.value })
            }
            fullWidth
            margin="normal"
            multiline
            style={{ marginBottom: 12 }}
          />
          {platform !== APP_PLATFORMS.ios && (
            <>
              <Typography>Channel</Typography>
              <ActionsTextField
                type="text"
                value={channel_id}
                onChange={e =>
                  dispatch({ type: SET_CHANNEL_ID, value: e.target.value })
                }
                fullWidth
                validationErrors={!channel_id && "Channel is required"}
                margin="normal"
                style={{ marginBottom: 12 }}
              />
            </>
          )}
          {platform !== APP_PLATFORMS.ios && (
            <>
              <Typography>Select Redirection Criteria</Typography>
              <MultiSelect
                options={REDIRECTION_TYPES.map(o => ({
                  label: o.label,
                  value: o.key,
                }))}
                value={redirection_type}
                handleChange={redirection_value =>
                  dispatch({
                    type: SET_REDIRECTION_TYPE,
                    value: redirection_value,
                  })
                }
                single
                fullWidth
                style={{ marginBottom: 12 }}
              />
              {redirection_type === "activity" && (
                <MultiSelect
                  options={app_screens.map(o => ({ label: o, value: o }))}
                  value={activity_name}
                  placeholder="Select Activity"
                  handleChange={activity_name =>
                    dispatch({ type: SET_ACTIVITY_NAME, value: activity_name })
                  }
                  single
                  fullWidth
                />
              )}
              {redirection_type === "activity" && !activity_name && (
                <Typography
                  variant={"body2"}
                  style={{ padding: "2px 4px", color: "red" }}
                >
                  Activity is Required
                </Typography>
              )}
              {redirection_type === "deep_link" && (
                <ActionsTextField
                  placeholder="scheme://host"
                  value={deep_link_uri || ""}
                  onChange={e =>
                    dispatch({ type: SET_DEEP_LINK_URI, value: e.target.value })
                  }
                  validationErrors={!deep_link_uri && "DeepLink is Required"}
                  style={{ marginBottom: 2 }}
                />
              )}
            </>
          )}
        </>
      </Grid>
    </Grid>
  );
}


