import { makeGetRequest } from "../../../common/actions";

const DOWNLOADS_ENDPOINT = "downloadables";

export function getDownloadableFilesAPI(auth, appId) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makeGetRequest(
    DOWNLOADS_ENDPOINT,
    auth,
    appId,
    null,
    onSuccessCallback,
    onFailureCallback
  );
}
