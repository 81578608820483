import React, { useState, useEffect } from "react";
import { useAuth } from "../../../../contexts/AuthContext";
import { useDeepCompareEffect } from "../../../../utils/use-deep-compare";
import NumberBox from "../../../ReusableComponents/NumberBox";
import { formatNumber } from "../../../../utils";
import { CircularProgress } from "@material-ui/core";

export default function CountBox({
  appId,
  isUserCount,
  subheading,
  icon,
  queryParams,
  filters,
  onFetch,
  api,
}) {
  const auth = useAuth();

  const [value, setValue] = useState(0);
  const [fetching, setFetching] = useState(false);

  useDeepCompareEffect(() => {
    setFetching(true);
    api(
      auth,
      appId,
      { ...queryParams, of: isUserCount ? "users" : "sessions" },
      filters
    ).then((value) => {
      setFetching(false);
      setValue(value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, filters, auth, appId, isUserCount]);

  useEffect(() => {
    if (onFetch) {
      onFetch(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return !fetching ? (
    <NumberBox
      heading={formatNumber(value)}
      subheading={subheading}
      icon={icon}
    />
  ) : (
    <CircularProgress />
  );
}
