import { Typography } from "@material-ui/core";
import React, { useCallback } from "react";
import generateShortID from "../../../../../../../../utils";
import MultiSelect from "../../../../../../../ReusableComponents/MultiSelect";
import {
  EVENT_CONDTION_TYPES,
  SET_GOAL_EVENT,
  SET_GOAL_MET_CHECK,
  SET_REMOVE_CONDITION,
  SET_UPDATE_CONDITION,
} from "../../../constants";
import { useTracked } from "../../../store";
import { useCommonConfigStyles } from "../../../utils";

export default function GoalEvent() {
  const classes = useCommonConfigStyles();

  const [state, dispatch] = useTracked();
  const {
    app_events: appEvents,
    config: { goal_event = "", goal_met_check = false },
  } = state;

  const handleChange = useCallback(
    (value) => {
      if (!value) {
        dispatch({
          type: SET_GOAL_MET_CHECK,
        });
        dispatch({
          type: SET_REMOVE_CONDITION,
          index: 0,
          conditions_type: EVENT_CONDTION_TYPES.DELETE_CS,
        });
      } else {
        if (goal_met_check) {
          const data = {
            _id: generateShortID(),
            event_type: "app_event",
            context_rule_type: "APP",
            type: "did",
            sequence: -1,
            time_bounds: {
              lower: 0,
              upper: 6000000,
            },
            count_config: {
              count: 1,
              operator: "GTE",
            },
            combine_operator: "OR",
            activity: "",
            details: {
              additional_info: {},
              name: value,
            },
            trigger: {
              event_type: "app_start",
              activity: "",
              details: {
                name: "",
                additional_info: {},
              },
              time_bounds: {
                lower: 0,
                upper: 60000000,
              },
            },
          };
          dispatch({
            type: SET_UPDATE_CONDITION,
            index: 0,
            condition: data,
            conditions_type: EVENT_CONDTION_TYPES.DELETE_CS,
          });
        }
      }
      dispatch({
        type: SET_GOAL_EVENT,
        event: value,
      });
    },
    [dispatch, goal_met_check]
  );

  return (
    <>
      <Typography className={classes.typographyHeading}>
        Goal event of this campaign
      </Typography>
      <MultiSelect
        clearable
        handleChange={handleChange}
        options={appEvents.map((event) => {
          return { label: event, value: event };
        })}
        placeholder="Select goal event"
        single
        style={{ width: "80%", marginTop: 10 }}
        value={goal_event}
      />
    </>
  );
}
