import {
  Box,
  Button,
  CardMedia,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Radio,
  RadioGroup,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@material-ui/core";
import { ChevronLeft, HelpOutline } from "@material-ui/icons";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import RefreshIcon from "@material-ui/icons/Refresh";
import Apxor from "apxor";
import domtoimage from "dom-to-image";
import React, { useEffect, useMemo, useState } from "react";
import { isExperienceDashboard } from "../../../../../../../../config";
import { APP_PLATFORMS } from "../../../../../../../../constants";
import { APXOR_SDK_CATEGORY_CONSTANTS } from "../../../../../../../../constants/Endpoints";
import { useAuth } from "../../../../../../../../contexts/AuthContext";
import ActionsTextField from "../../../../../../../ReusableComponents/ActionsTextField";
import Autocomplete from "../../../../../../../ReusableComponents/Autocomplete";
import BootstrapTooltip from "../../../../../../../ReusableComponents/BootstrapTooltip";
import ColumnDiv from "../../../../../../../ReusableComponents/ColumnDiv";
import Loading from "../../../../../../../ReusableComponents/Loading";
import ModuleConfigSection from "../../../../../../../ReusableComponents/ModuleConfigSection";
import ModuleDivider from "../../../../../../../ReusableComponents/ModuleDivider";
import RowDiv from "../../../../../../../ReusableComponents/RowDiv";
import {
  getSignedURLToPost,
  getTestDevicesAPI,
  uploadImageUsingSignedURL,
} from "../../../../../../common/actions";
import {
  CAMPAIGN_TYPE_ENUM,
  SET_DEFAULT_TEST_DEVICES,
  SET_SELECTED_TEST_DEVICE,
} from "../../../../../../common/components/campaigncreator/constants";
import {
  useDispatch as useParentDispatch,
  useTrackedState,
} from "../../../../../../common/components/campaigncreator/store";
import DownloadApxorAppContent from "../../../../../../common/components/DownloadApxorAppContent";
import { IconImageComponent } from "../../../../../../common/utils";
import ExperienceDemoFinishStep from "../../../../../dashboard/components/sales-sandbox/ExperienceDemoFinishStep";
import mobile from "../../assets/mobile.png";
import { SET_ACTIVITY, SET_ORIENTATION, SET_SSE_DATA } from "../../constants";
import { useTracked } from "../../store";
import { generateQRCodeURL, openURLInNewTab } from "../../utils";
import { publishDesignForPreviewAPI } from "../actions";
import { ORIENTATION_ENUM } from "../simulator/utils";
import SSEDesignPreviewForWeb from "./SSEDesignPreviewForWeb";
import SSEDevicePreview from "./SSEDevicePreview";
import TreeView from "./TreeView";
import { useModuleConfigStyles } from "./utils";
import WebLaunchURL from "./WebLaunchURL";

const steps = [
  "SELECT YOUR TEST DEVICE",
  "OPEN YOUR APPLICATION",
  "CHOOSE SCREEN ON DEVICE",
  "SELECT UI ELEMENT FROM THE SCREEN",
]

const stepsForExperience = [
  "SELECT YOUR TEST DEVICE",
  "CHOOSE SCREEN ON DEVICE",
  "SELECT UI ELEMENT FROM THE SCREEN",
]

const appHelp = [
  "Open Apxor helper app",
  "> Enable overlay",
  "> Click on 'Open app'",
  "> Click on 'Apxor widget'",
  "> Select screen",
]

const helperText = [
  "",
  !isExperienceDashboard() ? "" : appHelp.join("\n"),
  !isExperienceDashboard()
    ? appHelp.join("\n")
    : "Campaign will be displayed for the UI element",
  "Campaign will be displayed for the UI element",
]

const webhelperText = [
  "",
  "Add as test device and click on Enable view selection button",
]

const useStyles = makeStyles((theme) => ({
  testButton: {
    textTransform: "none",
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.text.primary,
      color: theme.palette.secondary.main,
    },
    padding: theme.spacing(2.5, 3.5),
  },
  completed: (props) => ({
    fontWeight: "800 !important",
    color: theme.palette.text.primary,
  }),
  active: (props) => ({
    fontWeight: "800 !important",
    color: `${theme.palette.primary.main} !important`,
  }),
  label: (props) => ({
    display: "flex",
    fontWeight: "700",
    fontSize: "13px !important",
    flexDirection: "column",
  }),
  root: (props) => ({
    display: "flex",
    alignItems: "unset !important",
  }),
  downloadBackButton: (props) => ({
    alignSelf: "flex-start",
    color: theme.palette.text.primary,
  }),
  disabledText: (props) => ({
    color: `${theme.palette.text.primary} !important`,
    width: "100%",
  }),
}))

let intervalHandler

export default function ViewIDPathSelection({
  props = null,
  bundle_id = "",
  handleClose,
  handleProceed,
  saveImages = true,
  allowWebViewCheck = true,
  isDynamicText = false,
}) {
  const auth = useAuth()
  const classes = useStyles()

  const moduleConfigClasses = useModuleConfigStyles()

  const campaignCreatorState = useTrackedState()
  const campaignCreatorDispatch = useParentDispatch()
  const {
    config: {
      _id,
      testing: { selected = "", selectedLabel = "" },
      meta: { platform, experience_type = "" },
    },
    test_devices,
  } = campaignCreatorState

  const {
    search_type,
    is_wv,
    wv_tag,
    pathh,
    view_id,
    activity,
    url: propURL = "",
    from_edit = false,
  } = props || ""

  const isWeb = useMemo(() => platform === APP_PLATFORMS.web, [platform])

  const stepsByPlatform = useMemo(
    () =>
      isWeb
        ? [
            "Open this link on one of the following platforms",
            "Select UI Element",
          ]
        : isExperienceDashboard()
        ? stepsForExperience
        : steps,
    [isWeb],
  )

  const [designPageState, designPageDispatch] = useTracked()
  const { app_id, orientation, currentImage } = designPageState

  const [activeStep, setActiveStep] = useState(
    !isWeb && selected !== "" ? 1 : from_edit ? 1 : 0,
  )
  const [selectedDevice, setSelectedDevice] = useState(
    isWeb && !from_edit
      ? {
          label: "",
          value: "",
        }
      : {
          label: selectedLabel,
          value: selected,
        },
  )
  const [selectedScreen, setSelectedScreen] = useState(activity || undefined)
  const [viewId, setViewId] = useState(view_id || "")
  const [path, setPath] = useState(pathh || "")
  const [searchType, setSearchType] = useState(
    search_type || (isExperienceDashboard() ? "p" : ""),
  )
  const [isForWebView, setIsForWebView] = useState(is_wv || false)
  const [webviewTag, setWebviewTag] = useState(wv_tag || "")
  const [showTreeView, setShowTreeView] = useState(false)
  const [showDownloadContent, setShowDownloadContent] = useState(false)
  const [showTestDevicesSection, setShowTestDevicesSection] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [pending, setPending] = useState(false)
  const helper = useMemo(() => (isWeb ? webhelperText : helperText), [isWeb])

  const [url, setURL] = useState(propURL)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep >= steps.length - 1) {
        return prevActiveStep
      }
      return prevActiveStep + 1
    })
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep <= 0) {
        return prevActiveStep
      }
      return prevActiveStep - 1
    })
  }

  const handleStep = (step) => {
    if (selectedDevice.value === "" && activeStep === 0) {
      return
    }
    if (
      !selectedScreen &&
      ((isExperienceDashboard() && step >= 2) ||
        (!isExperienceDashboard() && step >= 3))
    ) {
      return
    }
    setActiveStep(step)
  }

  const handleViewSelect = (view_id, path) => {
    Apxor.logEvent(
      "ViewSelected",
      {
        "view_id":view_id,
        "path":path,
        "experience_type": experience_type,
      },
      APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS,
    )
    setPath(path)
    setViewId(view_id)
    if (path && path !== "") {
      setSearchType("p")
    } else if (view_id && view_id !== "") {
      setSearchType("")
    }
  }

  const getContainerId = (index) => {
    switch (index) {
      case 0:
        return "ui-select-test-device"
      case 1:
        return isExperienceDashboard() ? "ui-select-screen" : "ui-container"
      case 2:
        return isExperienceDashboard()
          ? "ui-select-element"
          : "ui-select-screen"
      case 3:
        return "ui-select-element"
      default:
        return "ui-container"
    }
  }

  const getStepContent = (index) => {
    switch (index) {
      case 0:
        return (
          <Box sx={{ mb: 2 }}>
            <Autocomplete
              open={false}
              button={{
                label: "+ Register New Device",
                value: "+ Register New Device",
                isButton: true,
              }}
              defaultValue={{
                label: selectedLabel,
                value: selected,
              }}
              handleOnChange={(selectedValue) => {
                if (selectedValue && selectedValue.isButton) {
                  setShowDownloadContent(true)
                  return
                }
                setSelectedDevice(selectedValue)
                Apxor.logEvent(
                  "TestDeviceEntered",
                  {
                    "device_id": selectedValue,
                    "experience_type": experience_type,
                  },
                  APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS,
                )
                campaignCreatorDispatch({
                  type: SET_SELECTED_TEST_DEVICE,
                  value: selectedValue,
                })
                handleNext()
              }}
              options={test_devices.map((device) => ({
                label: `${
                  device.hasOwnProperty("nick_name")
                    ? device.nick_name || device.model
                    : device.model
                } - ${device.id}`,
                value: device.id,
              }))}
            />
          </Box>
        )
      case 1:
        return !isExperienceDashboard() ? (
          <Box sx={{ mb: 2 }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.testButton}
              disabled={!!!selectedDevice.value}
              startIcon={<RefreshIcon color="secondary" />}
              onClick={() => {
                publishDesignForPreviewAPI(
                  auth,
                  auth.appId,
                  {
                    deviceId: selectedDevice.value,
                  },
                  {
                    type: CAMPAIGN_TYPE_ENUM.IN_LINE,
                    cmd: "show",
                  },
                )
                handleNext()
              }}
            >
              Enable Widget
            </Button>
          </Box>
        ) : (
          <Box sx={{ mb: 2 }}>
            <CardMedia
              autoPlay
              style={{
                height: 540,
                width: 260,
                marginBottom: 20,
              }}
              title="Screen Selection walkthrough"
              component={"video"}
              controls
              muted
              loop
              image={
                "https://storage.googleapis.com/apx_frontend-assets/Screen_1.mp4"
              }
            ></CardMedia>

            <Box
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "4px",
              }}
            >
              <Typography
                variant="h6"
                color="textPrimary"
                style={{
                  fontWeight: 900,
                  marginRight: "2px",
                }}
              >
                Selected Screen
              </Typography>
              <BootstrapTooltip
                title="Your screen will automatically populate here once you select screen in the helper app overlay"
                placement="bottom"
              >
                <HelpOutline style={{ fontSize: 14 }} />
              </BootstrapTooltip>
            </Box>
            <TextField
              value={selectedScreen}
              onChange={(e) => {
                setSelectedScreen(e.target.value)
                handleNext()
              }}
              disabled
              placeholder="Screen Name"
              style={{ width: "100%" }}
              InputProps={{
                classes: {
                  disabled: classes.disabledText,
                },
              }}
            />
          </Box>
        )
      case 2:
        return !isExperienceDashboard() ? (
          <Box sx={{ mb: 2 }}>
            <CardMedia
              autoPlay
              style={{
                height: 540,
                width: 260,
                marginBottom: 20,
              }}
              title="Screen Selection walkthrough"
              component={"video"}
              controls
              muted
              loop
              image={
                "https://storage.googleapis.com/apx_frontend-assets/Screen_2.mp4"
              }
            ></CardMedia>

            <Box
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "4px",
              }}
            >
              <Typography
                variant="h6"
                color="textPrimary"
                style={{
                  fontWeight: 900,
                  marginRight: "2px",
                }}
              >
                Selected Screen
              </Typography>
              <BootstrapTooltip
                title="Your screen will automatically populate here once you select screen in the helper app overlay"
                placement="bottom"
              >
                <HelpOutline style={{ fontSize: 14 }} />
              </BootstrapTooltip>
            </Box>
            <TextField
              value={selectedScreen}
              onChange={(e) => {
                setSelectedScreen(e.target.value)
                handleNext()
              }}
              disabled
              placeholder="Screen Name"
              style={{ width: "100%" }}
              InputProps={{
                classes: {
                  disabled: classes.disabledText,
                },
              }}
            />
          </Box>
        ) : (
          <Box sx={{ mb: 2 }}>
            <ColumnDiv>
              {!isExperienceDashboard() && allowWebViewCheck && (
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={isForWebView}
                      onChange={(e) => setIsForWebView(e.target.checked)}
                    />
                  }
                  label="Is this screen written in HTML?"
                  classes={{
                    root: moduleConfigClasses.moduleConfigRoot,
                  }}
                />
              )}
              <RowDiv
                center
                styles={{
                  justifyContent: "space-between",
                  marginTop: "16px",
                }}
              >
                {!isForWebView && (
                  <ModuleConfigSection
                    title="Type"
                    style={{
                      alignItems: "center",
                    }}
                    typographyClassName={moduleConfigClasses.moduleConfigRoot}
                  >
                    <RadioGroup
                      row
                      aria-label="search type"
                      name="search_type"
                      value={searchType}
                      onChange={(e) => {
                        const value = e.target.value
                        setSearchType(value)
                      }}
                      style={{
                        marginTop: "4.5px",
                        marginLeft: "-12px",
                      }}
                    >
                      <FormControlLabel
                        value="p"
                        control={<Radio color="primary" />}
                        label="Path"
                        style={{
                          fontSize: 12,
                          fontWeight: 500,
                        }}
                      />
                      <FormControlLabel
                        value=""
                        control={<Radio color="primary" />}
                        label="ID / Tag"
                        style={{
                          fontSize: 12,
                          fontWeight: 500,
                        }}
                      />
                    </RadioGroup>
                  </ModuleConfigSection>
                )}
                {isForWebView && (
                  <ModuleConfigSection
                    title="WebView Tag"
                    style={{
                      alignItems: "center",
                      gap: 2,
                    }}
                    typographyClassName={moduleConfigClasses.moduleConfigRoot}
                  >
                    <ActionsTextField
                      style={{
                        maxWidth: 360,
                        marginBottom: 0,
                      }}
                      placeholder="Tag of your WebView"
                      value={webviewTag}
                      multiline={false}
                      onChange={(e) => {
                        const value = e.target.value
                        setWebviewTag(value)
                      }}
                    />
                  </ModuleConfigSection>
                )}
                <ModuleConfigSection
                  title={
                    isForWebView ? "HTML Element ID" : "View ID / Tag / Path"
                  }
                  style={{
                    marginTop: 0,
                    alignItems: "center",
                    gap: 2,
                  }}
                  typographyClassName={moduleConfigClasses.moduleConfigRoot}
                >
                  <ActionsTextField
                    focused={!!viewId || !!path}
                    style={{
                      marginBottom: 0,
                      marginTop: "6px",
                    }}
                    placeholder={
                      isForWebView ? "HTML Element ID" : "View ID / Tag / Path"
                    }
                    value={searchType === "" ? viewId : path}
                    multiline={false}
                    onChange={(e) => setViewId(e.target.value)}
                  />
                </ModuleConfigSection>
              </RowDiv>
            </ColumnDiv>
          </Box>
        )
      case 3:
        return (
          <Box sx={{ mb: 2 }}>
            <ColumnDiv>
              {!isExperienceDashboard() && allowWebViewCheck && (
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={isForWebView}
                      onChange={(e) => setIsForWebView(e.target.checked)}
                    />
                  }
                  label="Is this screen written in HTML?"
                  classes={{
                    root: moduleConfigClasses.moduleConfigRoot,
                  }}
                />
              )}
              <RowDiv
                center
                styles={{
                  justifyContent: "space-between",
                  marginTop: "16px",
                }}
              >
                {!isForWebView && (
                  <ModuleConfigSection
                    title="Type"
                    style={{
                      alignItems: "center",
                    }}
                    typographyClassName={moduleConfigClasses.moduleConfigRoot}
                  >
                    <RadioGroup
                      row
                      aria-label="search type"
                      name="search_type"
                      value={searchType}
                      onChange={(e) => {
                        const value = e.target.value
                        setSearchType(value)
                      }}
                      style={{
                        marginTop: "4.5px",
                        marginLeft: "-12px",
                      }}
                    >
                      <FormControlLabel
                        value="p"
                        control={<Radio color="primary" />}
                        label="Path"
                        style={{
                          fontSize: 12,
                          fontWeight: 500,
                        }}
                      />
                      <FormControlLabel
                        value=""
                        control={<Radio color="primary" />}
                        label="ID / Tag"
                        style={{
                          fontSize: 12,
                          fontWeight: 500,
                        }}
                      />
                    </RadioGroup>
                  </ModuleConfigSection>
                )}
                {isForWebView && (
                  <ModuleConfigSection
                    title="WebView Tag"
                    style={{
                      alignItems: "center",
                      gap: 2,
                    }}
                    typographyClassName={moduleConfigClasses.moduleConfigRoot}
                  >
                    <ActionsTextField
                      style={{
                        maxWidth: 360,
                        marginBottom: 0,
                      }}
                      placeholder="Tag of your WebView"
                      value={webviewTag}
                      multiline={false}
                      onChange={(e) => {
                        const value = e.target.value
                        setWebviewTag(value)
                      }}
                    />
                  </ModuleConfigSection>
                )}
                <ModuleConfigSection
                  title={
                    isForWebView ? "HTML Element ID" : "View ID / Tag / Path"
                  }
                  style={{
                    marginTop: 0,
                    alignItems: "center",
                    gap: 2,
                  }}
                  typographyClassName={moduleConfigClasses.moduleConfigRoot}
                >
                  <ActionsTextField
                    focused={!!viewId || !!path}
                    style={{
                      marginBottom: 0,
                      marginTop: "6px",
                    }}
                    placeholder={
                      isForWebView ? "HTML Element ID" : "View ID / Tag / Path"
                    }
                    value={searchType === "" ? viewId : path}
                    multiline={false}
                    onChange={(e) => setViewId(e.target.value)}
                  />
                </ModuleConfigSection>
              </RowDiv>
            </ColumnDiv>
          </Box>
        )
      default:
        break
    }
  }

  const newVolatileID = useMemo(() => {
    return Math.random().toString(36).substring(2, 15)
  }, [])

  const getContentForWeb = (index) => {
    switch (index) {
      case 0:
        return (
          <WebLaunchURL
            defaultUrl={bundle_id}
            url={url}
            setURL={setURL}
            launchCallback={() => {
              openURLInNewTab(
                url,
                app_id,
                selected === "" ? newVolatileID : selected,
              )
              handleNext()
            }}
            generateQRCodeCallback={() => {
              const urlWithParams = generateQRCodeURL(
                url,
                app_id,
                selected === "" ? newVolatileID : selected,
              )
              handleNext()
              return urlWithParams
            }}
          />
        )
      case 1:
        return (
          <RowDiv
            center
            styles={{
              gap: 10,
              marginTop: 10,
            }}
          >
            <ModuleConfigSection
              title="Type"
              style={{
                alignItems: "center",
              }}
              typographyClassName={moduleConfigClasses.moduleConfigRoot}
            >
              <RadioGroup
                row
                aria-label="search type"
                name="search_type"
                value={searchType}
                onChange={(e) => {
                  const value = e.target.value
                  setSearchType(value)
                }}
              >
                <FormControlLabel
                  value=""
                  control={<Radio color="primary" />}
                  label="CSS Selector"
                  style={{
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                />
                <FormControlLabel
                  value="p"
                  control={<Radio color="primary" />}
                  label="XPath"
                  style={{
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                />
              </RadioGroup>
            </ModuleConfigSection>
            <ModuleConfigSection
              title="CSS Selector / XPath"
              style={{
                marginTop: 0,
                alignItems: "center",
                gap: 2,
              }}
              typographyClassName={moduleConfigClasses.moduleConfigRoot}
            >
              <ActionsTextField
                style={{ marginBottom: 0 }}
                placeholder="CSS Selector / XPath"
                value={searchType === "" ? viewId : path}
                multiline={false}
                onChange={(e) => setViewId(e.target.value)}
              />
            </ModuleConfigSection>
          </RowDiv>
        )
      default:
        break
    }
  }

  const handleSaveAndProceed = () => {
    Apxor.logEvent(
      "ProceedClicked",
      { "experience_type": experience_type },
      APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS,
    )
    if (!isWeb && saveImages && _id) {
      setPending(true)
      getSignedURLToPost(auth, auth.app_id, {
        campaignId: _id,
        appId: app_id,
      }).then((response) => {
        const { signed_url, blob_name, provider } = response
        domtoimage
          .toBlob(document.querySelector("#apx_screen_preview"), {
            quality: 0.5,
          })
          .then(function (blob) {
            const file = new File([blob], "image.png")
            uploadImageUsingSignedURL(
              signed_url,
              file,
              () => {
                handleProceed({
                  isForWebView,
                  path,
                  searchType,
                  viewId,
                  webviewTag,
                  blob_name,
                  provider,
                  activity: selectedScreen || "",
                  screenshot: () =>
                    domtoimage.toPng(
                      document.querySelector("#apx_screen_preview"),
                      {
                        quality: 0.5,
                      },
                    ),
                })
              },
              () => {
                // TODO: Handle error scenario
              },
            )
          })
          .catch(function (error) {
            setPending(false)
            console.error("oops, something went wrong!", error)
          })
      })
      return
    }
    const data = {
      isForWebView,
      path,
      searchType,
      viewId,
      webviewTag,
      url: selectedScreen,
    }
    if (isWeb) {
      data.webviewTag = ""
      data.activity = ""
      data.provider = ""
      data.blob_name = ""
    }
    handleProceed(data)
  }

  const backButton = (
    <>
      {isDynamicText && (
        <RowDiv
          center
          styles={{
            marginTop: -16,
            marginLeft: -16,
            marginBottom: 10,
          }}
        >
          <IconButton
            className={classes.backButton}
            onClick={() => {
              if (showTreeView) setShowTreeView(false)
              else handleClose()
            }}
          >
            <ChevronLeft color="action" style={{ color: "#002845" }} />
          </IconButton>
          <Typography
            variant="h5"
            color="textPrimary"
            style={{ fontWeight: 900 }}
          >
            Select UI Element{" "}
            <BootstrapTooltip
              title="Your campaign will be shown on that UI element"
              placement="right"
            >
              <HelpOutline style={{ fontSize: 14 }} />
            </BootstrapTooltip>
          </Typography>
        </RowDiv>
      )}
    </>
  )

  const fetchTestDevices = () => {
    setFetching(true)
    getTestDevicesAPI(auth, auth.appId, null, isExperienceDashboard())
      .then((response) => {
        const reversed = response.reverse()
        campaignCreatorDispatch({
          type: SET_DEFAULT_TEST_DEVICES,
          devices: reversed,
        })
        setFetching(false)
      })
      .catch(() => {
        setFetching(false)
      })
  }

  useEffect(() => {
    if (intervalHandler) clearInterval(intervalHandler)
    if (showTestDevicesSection) {
      intervalHandler = setInterval(() => {
        fetchTestDevices()
      }, 5000)
    }

    return () => {
      if (intervalHandler) clearInterval(intervalHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTestDevicesSection])

  const getLabelDescription = (step) => {
    switch (step) {
      case 0:
        return selectedDevice?.label ? (
          <span style={{ fontWeight: "500" }}>
            Selected Device : <b>{selectedDevice?.label}</b>
          </span>
        ) : (
          ""
        )
      case 1:
        return !isExperienceDashboard() ? (
          <>
            Please make sure you open the Application in your test device before
            enabling widget
          </>
        ) : (
          <>
            {selectedScreen && (
              <>
                Selected Screen : <b>{selectedScreen}</b>
              </>
            )}
          </>
        )
      case 2:
        return selectedScreen && !isExperienceDashboard() ? (
          <>
            Selected Screen : <b>{selectedScreen}</b>
          </>
        ) : (
          ""
        )
      default:
        return ""
    }
  }

  return (
    <>
      {!showDownloadContent && (
        <>
          <RowDiv center>
            <IconButton
              className={classes.backButton}
              onClick={() => handleClose()}
            >
              <ChevronLeft style={{ color: "#002845" }} />
            </IconButton>
            <Typography
              variant="h5"
              color="textPrimary"
              style={{ fontWeight: 900 }}
            >
              Select UI Element{" "}
              <BootstrapTooltip
                title="Your campaign will be shown on that UI element"
                placement="right"
              >
                <HelpOutline style={{ fontSize: 14 }} />
              </BootstrapTooltip>
            </Typography>
          </RowDiv>
          <Grid container spacing={1}>
            {!showTreeView && (
              <Grid
                item
                xs={isWeb ? 12 : 7}
                style={{ padding: isDynamicText ? 0 : "30px 50px" }}
              >
                {backButton}
                <Stepper
                  activeStep={activeStep}
                  orientation="vertical"
                  style={{ paddingTop: 0, paddingInline: 0 }}
                  nonLinear
                >
                  {stepsByPlatform.map((label, index) => (
                    <Step
                      id={getContainerId(index)}
                      expanded={platform === APP_PLATFORMS.web && index === 0}
                    >
                      <StepLabel
                        onClick={() => handleStep(index)}
                        classes={{
                          active: classes.active,
                          completed: classes.completed,
                          label: classes.label,
                          root: classes.root,
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                        optional={
                          <Typography variant="caption">
                            {getLabelDescription(index)}
                          </Typography>
                        }
                      >
                        <RowDiv center styles={{ gap: "5px" }}>
                          <span
                            style={{
                              textTransform: "uppercase",
                              fontWeight: "800",
                              fontSize: "12px",
                              letterSpacing: "0.11em",
                            }}
                          >
                            {label}
                          </span>
                          {helper[index] !== "" && (
                            <BootstrapTooltip
                              title={
                                <div style={{ whiteSpace: "pre-line" }}>
                                  {helper[index]}
                                </div>
                              }
                              placement="top"
                            >
                              <HelpOutline style={{ fontSize: 16 }} />
                            </BootstrapTooltip>
                          )}
                        </RowDiv>
                      </StepLabel>
                      <StepContent>
                        <Typography>
                          {isWeb
                            ? getContentForWeb(index)
                            : getStepContent(index)}
                        </Typography>
                        {index !== stepsByPlatform.length - 1 &&
                          platform !== APP_PLATFORMS.web && (
                            <Button
                              size="large"
                              variant="contained"
                              color="primary"
                              disabled={
                                index === stepsByPlatform.length - 1 ||
                                (activeStep === 0 && !selectedDevice?.value) ||
                                (activeStep === 1 && !selectedScreen) ||
                                (activeStep === 2 && !viewId && !path)
                              }
                              onClick={handleNext}
                              style={{
                                marginTop: "5px",
                                marginRight: "5px",
                              }}
                            >
                              Continue
                            </Button>
                          )}
                        {index === stepsByPlatform.length - 1 && (
                          <Button
                            size="large"
                            id="ui-proceed-button"
                            variant="contained"
                            color="primary"
                            disabled={
                              pending ||
                              (!isForWebView && viewId === "" && path === "") ||
                              (isForWebView &&
                                (webviewTag === "" || viewId === ""))
                            }
                            onClick={() => handleSaveAndProceed()}
                            style={{ marginTop: "5px", marginRight: "5px" }}
                          >
                            {!pending && "Proceed"}
                            {pending && <Loading size={18} />}
                          </Button>
                        )}
                        {index !== 0 && (
                          <Button
                            size="large"
                            disabled={index === 0}
                            onClick={handleBack}
                            style={{ marginTop: "5px" }}
                          >
                            Back
                          </Button>
                        )}
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
            )}
            {showTreeView && (
              <Grid
                item
                xs={7}
                style={{
                  padding: isDynamicText ? 0 : "30px 50px",
                }}
              >
                {backButton}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    border: "1px solid #E4E7E9",
                    borderRadius: 3,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "4px 8px",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6" style={{ fontWeight: 700 }}>
                      Tree View
                    </Typography>
                    <Button
                      variant="text"
                      style={{
                        textDecoration: "none",
                        textTransform: "none",
                        fontSize: 13,
                        fontWeight: 500,
                      }}
                      color="primary"
                      onClick={() =>
                        setShowTreeView((previousValue) => !previousValue)
                      }
                    >
                      X Close
                    </Button>
                  </div>
                  <ModuleDivider styles={{ marginTop: 0 }} />
                  <div style={{ marginLeft: 10 }}>
                    <TreeView handleViewSelect={handleViewSelect} />
                  </div>
                </div>
              </Grid>
            )}
            {!isWeb && (
              <Grid
                item
                xs={5}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingBottom: "50px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <SSEDevicePreview
                    appId={app_id}
                    platform={platform}
                    saveImages={saveImages}
                    imageConfig={{
                      width:
                        orientation === ORIENTATION_ENUM.PORTRAIT ? 270 : 540,
                      height:
                        orientation === ORIENTATION_ENUM.PORTRAIT ? 540 : 270,
                    }}
                    handleSSEDataReceive={(data) => {
                      const {
                        screen: { navigation, orientation },
                      } = data
                      designPageDispatch({
                        type: SET_SSE_DATA,
                        value: data,
                      })
                      designPageDispatch({
                        type: SET_ORIENTATION,
                        value: orientation,
                      })
                      designPageDispatch({
                        type: SET_ACTIVITY,
                        value: navigation,
                      })
                      Apxor.logEvent(
                        "ScreenSelected",
                        {
                          "screen_name": navigation,
                          "experience_type": experience_type,
                        },
                        APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS,
                      )

                      setSelectedScreen(navigation)
                      handleNext()
                    }}
                    handleViewSelect={handleViewSelect}
                    selectedDevice={selectedDevice?.value}
                  />
                  <Button
                    variant="text"
                    style={{
                      textDecoration: "underline",
                      textTransform: "none",
                    }}
                    disabled={!currentImage}
                    onClick={() => {
                      setShowTreeView((previousValue) => !previousValue)
                    }}
                  >
                    Switch To UI Hierarchy View
                  </Button>
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid #CCD4DA",
                      borderRadius: 100,
                      alignItems: "flex-end",
                      padding: "6px 10px",
                      textTransform: "capitalize",
                    }}
                  >
                    {currentImage ? (
                      <>
                        <PhoneIphoneIcon color="action" /> {orientation}
                      </>
                    ) : (
                      "Portrait"
                    )}
                  </div>
                </div>
              </Grid>
            )}
            {isWeb && (
              <SSEDesignPreviewForWeb
                appId={app_id}
                selected={selected === "" ? newVolatileID : selected}
                setSelectedDevice={(label, value) => {
                  fetchTestDevices()
                  setSelectedDevice({ label, value })
                  campaignCreatorDispatch({
                    type: SET_SELECTED_TEST_DEVICE,
                    value: { label, value },
                  })
                }}
                handleSSEDataReceive={(navigation, idAndPath) => {
                  Apxor.logEvent(
                    "ScreenSelected",
                    {
                      "screen_name": navigation,
                      "experience_type": experience_type,
                    },
                    APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS,
                  )
                  setSelectedScreen(navigation)
                  setURL(navigation)
                  const [id, path] = idAndPath.split("___")
                  handleViewSelect(id, path)
                }}
              />
            )}
          </Grid>
        </>
      )}
      {showDownloadContent && (
        <ColumnDiv
          styles={{
            padding: "50px",
            paddingTop: "0px",
          }}
        >
          <Button
            className={classes.downloadBackButton}
            onClick={() => {
              fetchTestDevices()
              if (showTestDevicesSection) {
                setShowTestDevicesSection(false)
              } else {
                setShowDownloadContent(false)
              }
            }}
            startIcon={<ChevronLeft style={{ color: "#002845" }} />}
            style={{
              marginBottom: "10px",
            }}
          >
            Back
          </Button>
          <Grid container spacing={4} style={{ padding: 20 }}>
            {isExperienceDashboard() && (
              <ExperienceDemoFinishStep
                close_dialog={() => setShowDownloadContent(false)}
                just_display_access_code={true}
              />
            )}
            {!showTestDevicesSection && !isExperienceDashboard() && (
              <Grid item xs={8}>
                <DownloadApxorAppContent
                  handleOnBackButtonClick={() => {
                    fetchTestDevices()
                  }}
                />
              </Grid>
            )}
            {showTestDevicesSection && !isExperienceDashboard() && (
              <Grid item xs={8}>
                <ColumnDiv styles={{ border: "1px solid #CCD4DA" }}>
                  <RowDiv
                    center
                    styles={{
                      justifyContent: "space-between",
                      borderBottom: "1px solid #CCD4DA",
                      padding: 10,
                    }}
                  >
                    <Typography variant="body1" style={{ fontWeight: 800 }}>
                      ALL DEVICES{" "}
                      {fetching ? (
                        <span style={{ color: "#8494A0" }}>refreshing...</span>
                      ) : (
                        ""
                      )}
                    </Typography>
                  </RowDiv>
                  <List
                    style={{
                      maxHeight: 380,
                      overflow: "scroll",
                    }}
                  >
                    {test_devices.map((device, index) => (
                      <ListItem key={index}>
                        <ListItemText>
                          {`${
                            device.hasOwnProperty("nick_name") &&
                            device.nick_name !== ""
                              ? device.nick_name
                              : device.model
                          } - ${device.id}`}
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </ColumnDiv>
              </Grid>
            )}
            {!isExperienceDashboard() && (
              <Grid
                item
                xs={4}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                {IconImageComponent(mobile, "Mobile", {
                  width: 270,
                  height: "unset",
                })}
                <Button
                  variant="text"
                  style={{
                    fontSize: 12,
                    fontWeight: 600,
                    textTransform: "none",
                    textDecoration: "underline",
                  }}
                  onClick={() => setShowTestDevicesSection(true)}
                  disabled={showTestDevicesSection}
                >
                  Check if your device is added
                </Button>
              </Grid>
            )}
          </Grid>
          {!isExperienceDashboard() && (
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={() => setShowDownloadContent(false)}
              style={{
                textTransform: "none",
                alignSelf: "flex-end",
              }}
            >
              Done
            </Button>
          )}
        </ColumnDiv>
      )}
    </>
  )
}
