import { List, ListItem, ListItemText } from "@material-ui/core";
import React from "react";
import { copyToClipboard } from "../../../../../../utils";
import Box from "../../../../../ReusableComponents/Box";

export const UserInfo = ({ data }) => {
  const keys = Object.keys(data);

  return (
    <div className="row">
      <div className="col-md-12">
        <Box collapsible defaultExpanded title="User Custom Information">
          <List>
            {keys?.map((key) => (
              <ListItem key={key}>
                <ListItemText
                  primary={
                    <span>
                      <strong>{key}</strong>
                      <span
                        title={`Click to copy: ${data[key]}`}
                        style={{
                          float: "right",
                        }}
                        onClick={() => copyToClipboard(data[key])}
                      >
                        {data[key].toString()}
                      </span>
                    </span>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </div>
    </div>
  );
};
