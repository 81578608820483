/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 **
 * Created by Rakesh Peela
 * Date: 08-Sep-2019
 * Time: 10:37 AM
 */

import { callApi } from "../../../../api";
import { makeDefaultQueryString, withQueryStrings } from "../../../../utils";
import { makePostRequest, makeGetRequest } from "../../../common/actions";

const COHORT_UPLOAD = "upload/cohort";

const GET_COHORTS_API = "cohorts";
const UPDATE_COHORT_API = "cohorts/update";

export function uploadCohortAPI(auth, appId, cohortDetails) {
  const { cohortName, cohortDescription, cohortFile } = cohortDetails;
  const url =
    makeDefaultQueryString(COHORT_UPLOAD, auth, appId) +
    withQueryStrings({
      cohortName,
      description: cohortDescription,
    });
  let formData = new FormData();
  formData.append("cohort", cohortFile, cohortFile.name);
  const config = {
    method: "POST",
    auth: auth,
    body: formData,
    headers: { "Content-Type": "multipart/form-data" },
  };
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return response;
  };
  return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function updateCohort(auth, appId, cohortId, since, till) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    UPDATE_COHORT_API,
    auth,
    appId,
    { cohortId, since, till },
    {},
    onSuccessCallback,
    onFailureCallback
  );
}

export function getCohorts(auth, appId) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makeGetRequest(
    GET_COHORTS_API,
    auth,
    appId,
    null,
    onSuccessCallback,
    onFailureCallback
  );
}
