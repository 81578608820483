import { apxTheme as theme } from "../../../styling/CustomCSS";

const drawerWidth = 100;

export const styles = () => ({
  root: {
    width: "100%",
    height: "100%",
    zIndex: 1,
    overflow: "hidden",
    flexGrow: 1,
    position: "relative",
    display: "flex",
  },
  appFrame: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  appBarShift: {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {},
  hide: {
    display: "none",
  },
  drawerPaper: {
    position: "fixed",
    zIndex: 10000,
    height: "100%",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: "hidden",
    "&:hover": {
      overflowY: "auto",
    },
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  drawerPaperClose: {
    width: 100,
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerInner: {
    width: drawerWidth,
    marginBottom: theme.spacing(4),
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 6px",
    ...theme.mixins.toolbar,
  },
  content: {
    width: "100%",
    flexGrow: 1,
    background: theme.palette.background.default,
    minHeight: "100vh",
    marginLeft: 60,
    overflow: "hidden",
  },
  pinnedContent: {
    marginLeft: drawerWidth,
  },
  list: {
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
  },
  container: {
    "&:hover": {
      maxWidth: drawerWidth,
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  menuItem: {
    "&:hover": {
      borderLeft: `4px solid ${theme.sidebar.primary.main} !important`,
    },
  },
  active: {
    borderRight: `4px solid ${theme.sidebar.primary.main} !important`,
  },
  parentActive: {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
  nested: {
    paddingLeft: `${theme.spacing(4)}px !important`,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing(1 / 4),
  },
  pinButton: {
    marginLeft: "auto",
  },
  sideLogo: {
    width: "65%",
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  sideLogoIcon: {
    width: 48,
    padding: theme.spacing(2),
  },
  appHeading: {
    marginLeft: theme.spacing(2),
    color: "#333333",
  },
})
