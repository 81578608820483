import React, { useCallback, useEffect, useRef } from "react";

export default function CustomPopper({
  anchorEl,
  setAnchorEl,
  children,
  onClose = null,
}) {
  const modalRef = useRef(null);

  const closeModal = useCallback(
    ({ target }) => {
      if (modalRef && modalRef.current && !modalRef.current.contains(target)) {
        try {
          setAnchorEl(null);
          if (onClose) onClose();
        } catch (e) {}
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [anchorEl]
  );

  useEffect(() => {
    if (anchorEl) {
      setTimeout(() => {
        document.addEventListener("click", closeModal, false);
      }, 100);
    } else {
      document.removeEventListener("click", closeModal, false);
    }

    return () => {
      document.removeEventListener("click", closeModal, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl]);

  return (
    <div ref={modalRef}>
      {anchorEl && (
        <div
          style={{
            position: "absolute",
            background: "#fff",
            zIndex: 101010,
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
}
