/**
 * Created by Rakesh Peela
 * Date: 14-Jan-2020
 * Time: 7:10 PM
 */

import React from 'react';

export class EmptyStateImage extends React.Component {
    render() {
        const {color} = this.props;
        return (
            <svg width="79" height="50" fill="none">
                <path
                    d="M.916 22.58l10.272-2.752c.228-.06.459-.054.67.009 6.44 1.024 10.907-6.94 15.091-10.894.654-.689 1.836-.406 2.087.533l2.182 8.144c5.642-.13 7.513 7.415 2.693 10.054l2.183 8.147c.237.886-.559 1.733-1.473 1.52-5.61-1.317-13.484-6.015-18.562-1.931a1.224 1.224 0 01-.6.368l-1.822.489 2.96 11.043a1.236 1.236 0 01-.875 1.513l-4.24 1.135a1.236 1.236 0 01-1.512-.874L7.012 38.042l-1.824.489a1.236 1.236 0 01-1.512-.875l-1.02-3.805-.187.05a1.05 1.05 0 01-1.282-.74L.134 29.23a1.049 1.049 0 01.74-1.282l.187-.05-1.02-3.806a1.237 1.237 0 01.875-1.513zm55.065-4.559c-1.626 0-1.626-2.473 0-2.473h14.723c1.626 0 1.626 2.473 0 2.473H55.981zm-5.969-5.265c-1.626 0-1.626-2.473 0-2.473h20.692c1.626 0 1.626 2.473 0 2.473H50.012zm-4.22-5.266c-1.625 0-1.625-2.473 0-2.473h24.912c1.626 0 1.626 2.473 0 2.473H45.793zM10.399 41.134l1.853-.496-1-3.732-1.852.496.999 3.732zm2.493 1.89l-1.853.496 1 3.732 1.853-.497-1-3.73zM7.884 23.27l2.995 11.178c.916-.246 1.832-.49 2.748-.737l-2.994-11.176-2.749.735zm.608 11.817L5.497 23.911l-2.748.737 2.994 11.175c.916-.244 1.832-.49 2.749-.736zM42.507 0H73.99c2.079 0 4.208 1.455 4.208 3.68v15.756c0 2.224-2.129 3.68-4.208 3.68h-5.573l-4.915 10.122c-.452.937-1.78.916-2.222 0l-4.915-10.122H42.507c-2.079 0-4.207-1.456-4.207-3.68V3.679C38.3 1.455 40.428 0 42.507 0zM73.99 2.473H42.507c-.675 0-1.734.392-1.734 1.206v15.757c0 .814 1.06 1.206 1.734 1.206h14.63c.459.004.899.261 1.111.701l4.143 8.534 4.107-8.458a1.238 1.238 0 011.148-.777h6.344c.674 0 1.734-.392 1.734-1.206V3.679c0-.814-1.06-1.206-1.734-1.206zM31.893 20.141l1.343 5.012c1.783-1.566 1.03-4.54-1.343-5.012zM13.16 22.414l2.717 10.144c5.416-3.107 11.878.11 17.271 1.81L27.21 12.205c-3.819 4.168-7.812 10.19-14.05 10.209z"
                    fill={color || "#3f50b5"}/>
            </svg>
        );
    }
}