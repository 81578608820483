import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
  Divider,
  Grid,
  Typography,
  Tooltip,
  IconButton,
  Snackbar,
  Fab,
  Button,
} from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import RemoveIcon from "@material-ui/icons/RemoveCircle";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Person from "@material-ui/icons/Person";
import SecurityIcon from "@material-ui/icons/Security";
import MinimizeIcon from "@material-ui/icons/Remove";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  removeCustomerAPI,
  addCustomerAPI,
  transferAppOwnershipAPI,
} from "./actions";
import CustomMaterialUIDialog from "../../../../ReusableComponents/CustomMaterialUIDialog";
import Loading from "../../../../ReusableComponents/Loading";
import { useAuth } from "../../../../../contexts/AuthContext";
import AddCustomer from "./components/AddCustomer";
import TransferAdmin from "./components/TransferAdmin";
import { isValidEmail } from "../../../../../utils";
import { getAppDataAPI } from "../../../../common/actions";

export default function IAM({ app }) {
  const auth = useAuth();
  const { app_id: appId } = app;
  const [count, setCount] = useState(0);
  const [admin, setAdmin] = useState("");
  const [customers, setCustomers] = useState([]);
  const queryParams = {};
  const [isLoading, setIsLoading] = useState(false);
  const [customerListFailed, setCustomerListFailed] = useState(false);
  const [failed, setFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [transferSuccessful, setTransferSuccessful] = useState(false);

  let history = useHistory();

  useEffect(() => {
    getAppDataAPI(auth, appId)
      .then((response) => {
        setCustomers(response.basic_info.customers || []);
        setAdmin(response.basic_info.customer_id);
        setCustomerListFailed(false);
      })
      .catch((error) => {
        setCustomerListFailed(!customerListFailed);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  const [expanded, setExpanded] = useState(false);
  const [expandedTransfer, setExpandedTransfer] = useState(false);
  const [transferTriggered, setTransferTriggered] = useState(false);
  const [deleteTriggered, setDeleteTriggered] = useState(false);
  const [removeCustomer, setRemoveCustomer] = useState("");

  const addCustomer = (email) => {
    if (!isValidEmail(email)) {
      return;
    }
    addCustomerAPI(auth, appId, queryParams, {
      customer_id: email,
    })
      .then((response) => {
        setCount(count + 1);
        setFailed(false);
        setErrorMessage("");
        setIsLoading(false);
      })
      .catch((error) => {
        setFailed(!failed);
        setIsLoading(false);
        setErrorMessage(
          "Failed to add the user! Try again Later. We apologise for the inconvinience."
        );
      });
  };

  const transferAdmin = (newOwner, continueAsUser) => {
    if (!isValidEmail(newOwner)) {
      return;
    }
    transferAppOwnershipAPI(auth, appId, {
      userId: admin,
      newOwnerId: newOwner.trim(),
      continueAsUser: continueAsUser,
    })
      .then((resp) => {
        setCount(count + 1);
        setFailed(false);
        setErrorMessage("");
        setTransferSuccessful(true);
        setTimeout(() => {
          history.push("/apps");
        }, 2000);
      })
      .catch((error) => {
        setFailed(true);
        setErrorMessage(
          "Failed to Transfer Admin! Try again Later. We apologise for the inconvinience."
        );
        setTransferSuccessful(false);
      });
  };

  return (
    <>
      <section className="content">
        <Grid container justify="center">
          <Grid item xs={12} md={8}>
            <Card>
              <CardActions style={{ padding: 16 }}>
                <Typography variant="h5">Dashboard Users</Typography>

                <div style={{ flex: "1 1 auto" }} />
                <Tooltip
                  id="tooltip-fab-add-user"
                  title={expanded ? "Close" : "Add User"}
                  placement="right"
                >
                  <Fab
                    size="small"
                    color="primary"
                    onClick={() => setExpanded(!expanded)}
                    aria-expanded={expanded}
                    aria-label="Add User"
                  >
                    {expanded && <MinimizeIcon />}
                    {!expanded && <PersonAdd />}
                  </Fab>
                </Tooltip>
              </CardActions>
              <Divider />
              {isLoading ? (
                <CardContent>
                  <Loading />
                </CardContent>
              ) : (
                <Collapse in={expanded} unmountOnExit>
                  <CardContent>
                    <AddCustomer
                      addCustomer={addCustomer}
                      setIsLoading={setIsLoading}
                    />
                  </CardContent>
                </Collapse>
              )}
              <Divider />
              <CardContent>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <SecurityIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <strong>
                          <i>{admin}</i>
                        </strong>
                      }
                      secondary="Admin"
                    />
                    <Tooltip
                      id="transfer-app"
                      title="Transfer Ownership"
                      placement="right"
                    >
                      <IconButton
                        aria-label="Transfer Ownership"
                        onClick={() => {
                          setTransferTriggered(!transferTriggered);
                          setExpandedTransfer(!expandedTransfer);
                        }}
                      >
                        <SwapHorizIcon
                          color={transferTriggered ? "primary" : "error"}
                        />
                      </IconButton>
                    </Tooltip>
                  </ListItem>
                  {transferTriggered && (
                    <Collapse
                      in={expandedTransfer}
                      unmountOnExit
                      style={{ backgroundColor: "#f5f6f8" }}
                    >
                      <br />
                      <CardContent>
                        <TransferAdmin
                          customers={customers}
                          transferAdmin={transferAdmin}
                        />
                      </CardContent>
                      <br />
                      <Divider />
                    </Collapse>
                  )}
                  {transferSuccessful && (
                    <CustomMaterialUIDialog
                      maxWidth={"sm"}
                      noTitleBg={true}
                      openFlag={transferSuccessful}
                      title="Redirection warning"
                      dialogContent={
                        <>
                          <Typography>
                            Transfer App Ownership completed{" "}
                            <strong>successfully</strong>.
                          </Typography>
                          <Typography>Redirecting you to Apps List</Typography>
                          <div style={{ textAlign: "center" }}>
                            <CircularProgress />
                          </div>
                        </>
                      }
                    />
                  )}
                  {customers.map((user, index) => (
                    <React.Fragment key={index}>
                      <ListItem>
                        <ListItemIcon>
                          <Person color="primary" />
                        </ListItemIcon>

                        <ListItemText
                          primary={<strong> {user.customer_id} </strong>}
                          secondary="Full Access"
                          style={{ paddingLeft: "6px" }}
                        />
                        <Tooltip
                          id={"remove-customer-" + user.customer_id}
                          title="Click to remove user"
                          placement="right"
                          onClick={() => {
                            setDeleteTriggered(true);
                            setRemoveCustomer(user.customer_id);
                          }}
                        >
                          <IconButton aria-label="Remove">
                            <RemoveIcon color="error" />
                          </IconButton>
                        </Tooltip>
                      </ListItem>
                    </React.Fragment>
                  ))}
                  {deleteTriggered && (
                    <CustomMaterialUIDialog
                      dialogActions={
                        <div>
                          <Button onClick={() => setDeleteTriggered(false)}>
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              setIsLoading(true);
                              const auth = { user: { email: removeCustomer } };
                              removeCustomerAPI(
                                auth,
                                appId,
                                queryParams,
                                removeCustomer
                              )
                                .then((response) => {
                                  setCount(count + 1);
                                  setFailed(false);
                                  setErrorMessage("");
                                  setIsLoading(false);
                                })
                                .catch((error) => {
                                  setFailed(true);
                                  setIsLoading(false);
                                  setErrorMessage(
                                    "Failed to Remove user! Please try again later! Sorry for the inconvinience"
                                  );
                                });
                              setDeleteTriggered(false);
                            }}
                            color="primary"
                          >
                            Ok
                          </Button>
                        </div>
                      }
                      dialogContent={
                        <Typography>
                          Are you sure want to <strong>remove</strong>{" "}
                          <strong>{removeCustomer}</strong> ?
                        </Typography>
                      }
                      maxWidth={"sm"}
                      onDialogClose={() => setDeleteTriggered(false)}
                      openFlag={deleteTriggered}
                      noTitleBg={true}
                      title="Remove Confirmation"
                    />
                  )}
                </List>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </section>
      {(failed || customerListFailed) && (
        <Snackbar open={failed}>
          <strong>{errorMessage}</strong>
        </Snackbar>
      )}
    </>
  );
}
