import React from "react";
import { Typography } from "@material-ui/core";
import { ANSWER_MODES } from "../../../constants";
import SingleChoiceSimulator from "./SingleChoiceSimulator";
import MultiChoiceSimulator from "./MultiChoiceSimulator";
import RatingSimulator from "./RatingSimulator";
import TextSimulator from "./TextSimulator";
import SuccessMessageSimulator from "./SuccessMessageSimulator";
import RequestForSurveySimulator from "./RequestForSurveySimulator";

/**
 * Render the simulator based on the `answer-mode` flag
 */
export default function    QuestionSimulator({
  currentQuestionIndex,
  setCurrentQuestionIndex,
  answer_mode,
  choices,
  is_response_based_survey = false,
  handleAnswer,
  randomize_choices = false,
  title,
  text_config = {},
  ui = {},
  styles = {},
  selectedId,
}) {
  const { question_text_color = "", question_background_color = "" } = ui;
  return (
    <div
      style={{
        height: 324,
        overflowY: "auto",
        ...styles,
      }}
    >
      <Typography
        style={{
          fontSize: 18,
          fontWeight: 600,
          color: question_text_color,
          wordWrap: "break-word",
        }}
      >
        {title}
      </Typography>
      {answer_mode === ANSWER_MODES.single_choice && (
        <SingleChoiceSimulator
          choices={choices}
          handleAnswer={handleAnswer}
          is_response_based_survey={is_response_based_survey}
          randomize_choices={randomize_choices}
          ui={ui}
          selectedId={selectedId}
        />
      )}
      {answer_mode === ANSWER_MODES.multi_choice && (
        <MultiChoiceSimulator
          choices={choices}
          handleAnswer={handleAnswer}
          randomize_choices={randomize_choices}
          ui={ui}
        />
      )}
      {answer_mode === ANSWER_MODES.rating && (
        <RatingSimulator
          currentQuestionIndex={currentQuestionIndex}
          handleAnswer={handleAnswer}
          question_text_color={question_text_color}
        />
      )}
      {answer_mode === ANSWER_MODES.text && (
        <TextSimulator text_config={text_config} handleAnswer={handleAnswer} />
      )}
      {answer_mode === ANSWER_MODES.success_message && (
        <SuccessMessageSimulator handleAnswer={handleAnswer} ui={ui} />
      )}
      {answer_mode === ANSWER_MODES.request_survey && (
        <RequestForSurveySimulator
          currentQuestionIndex={currentQuestionIndex}
          setCurrentQuestionIndex={setCurrentQuestionIndex}
          handleAnswer={handleAnswer}
          ui={ui}
        />
      )}
    </div>
  );
}
