import { Button, Grid, Typography, withStyles } from "@material-ui/core";
import React from "react";
import ExpansionModuleConfig from "../../../../../../ReusableComponents/ExpansionModuleConfig";
import DummyInsetContainer from "../../../../../common/components/campaigncreator/components/DummyInsetContainer";
import { IconImageComponent } from "../../../../../common/utils";
import { ANSWER_MODES } from "../constants";
import single_choice from "../assets/single_choice.png";
import multi_choice from "../assets/multi_choice.png";
import text from "../assets/text.png";
import rating from "../assets/rating.png";
import { useSummaryStyles } from "../../../../../common/components/campaigncreator/components/summary/constants";

const styles = theme => ({
  questionCard: {
    display: "flex",
    flexDirection: "row",
    margin: 4,
    padding: 8,
    justifyContent: "space-between",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 3,
  },
  expansionRoot: {
    backgroundColor: theme.palette.secondary.main,
  },
  questionTypography: {
    fontWeight: 700,
  },
});

function DesignSummary({ classes, config, handleSelect }) {
  const {
    ui: { questions = [] },
  } = config;
  const headerClasses = useSummaryStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} className={headerClasses.root}>
        <Typography variant="body1" className={headerClasses.summaryHeading}>
          DESIGN
        </Typography>
        {handleSelect && (
          <Button
            variant="text"
            color="primary"
            onClick={() => handleSelect(0)}
            className={headerClasses.editButton}
          >
            Edit
          </Button>
        )}
      </Grid>
      <Grid item xs={12} style={{ padding: "20px 26px" }}>
        <ExpansionModuleConfig
          title={questions.length > 0 ? ` (${questions.length} Questions)` : ""}
          classes={{
            root: classes.expansionRoot,
          }}
          defaultExpanded
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {questions.length <= 0 && (
              <Typography variant={"subtitle2"}> No Questions Added</Typography>
            )}
            {questions.length > 0 && (
              <DummyInsetContainer
                style={{ maxHeight: 480, padding: 4, overflowY: "scroll" }}
              >
                {questions.map((question, index) => {
                  return (
                    <div
                      key={`question-${index}`}
                      className={classes.questionCard}
                    >
                      {question.answer_mode === ANSWER_MODES.single_choice && (
                        <>
                          <Typography
                            variant="h6"
                            className={classes.questionTypography}
                          >
                            Single Choice
                          </Typography>
                          {IconImageComponent(single_choice, "single_choice")}
                        </>
                      )}
                      {question.answer_mode === ANSWER_MODES.multi_choice && (
                        <>
                          <Typography
                            variant="h6"
                            className={classes.questionTypography}
                          >
                            Multi Choice
                          </Typography>
                          {IconImageComponent(multi_choice, "multi_choice")}
                        </>
                      )}
                      {question.answer_mode === ANSWER_MODES.text && (
                        <>
                          <Typography
                            variant="h6"
                            className={classes.questionTypography}
                          >
                            Text Based
                          </Typography>
                          {IconImageComponent(text, "text_based")}
                        </>
                      )}
                      {question.answer_mode === ANSWER_MODES.rating && (
                        <>
                          <Typography
                            variant="h6"
                            className={classes.questionTypography}
                          >
                            Rating
                          </Typography>
                          {IconImageComponent(rating, "rating")}
                        </>
                      )}
                    </div>
                  );
                })}
              </DummyInsetContainer>
            )}
          </div>
        </ExpansionModuleConfig>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(DesignSummary);
