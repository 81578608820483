import { Box, Button, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import SaveIcon from "@material-ui/icons/SaveOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import RowDiv from "../../../../../ReusableComponents/RowDiv";
import { StyledDropzone } from "../../../cohorts/styling/StyledDropzone";
import { capitalizeEachWord } from "../../../../../../utils";
import usePushConfigKeys from "./use-push-config-keys";
import { APP_PLATFORMS } from "../../../../../../constants";
import BootstrapTooltip from "../../../../../ReusableComponents/BootstrapTooltip";

export default function IOSKeysForm({ app_id, closeDialog = null }) {
  const {
    save_keys,
    reset_keys,
    team_id,
    set_team_id,
    bundle_id,
    set_bundle_id,
    key_id,
    set_key_id,
    auth_file,
    set_auth_file,
    ios_btn_type,
    ios_done,
    is_save_disabled,
  } = usePushConfigKeys({
    app_id,
    platform: APP_PLATFORMS.ios,
  });

  const [is_btn_hovered, set_is_btn_hovered] = useState(false);

  return (
    <form
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "start",
      }}
      noValidate
      autoComplete="off"
    >
      <Box>
        <TextField
          label="Team ID"
          value={team_id}
          style={{ margin: 8 }}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={e => set_team_id(e.target.value)}
        />
        <TextField
          label="Bundle ID"
          value={bundle_id}
          style={{ margin: 8 }}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={e => set_bundle_id(e.target.value)}
        />
      </Box>
      <Box>
        <TextField
          label="Key ID"
          value={key_id}
          style={{ margin: 8 }}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={e => set_key_id(e.target.value)}
        />
      </Box>
      <Box
        style={{
          marginLeft: "0.5rem",
        }}
      >
        {!auth_file ? (
          <StyledDropzone
            text="Upload Auth Key File (.p8)"
            single
            acceptType={[".p8"]}
            onChange={file => {
              if (file && file.name) {
                set_auth_file(file);
              }
            }}
          />
        ) : (
          <RowDiv
            center
            styles={{
              background: "#f0f0f0",
              border: "1px dashed #ccc",
              borderRadius: 3,
              justifyContent: "space-between",
              padding: 6,
            }}
          >
            <Typography variant="body1">
              {auth_file?.name
                ? `${auth_file?.name} selected`
                : 'Auth file exists. Press "Reset" to remove it.'}
            </Typography>
          </RowDiv>
        )}
      </Box>
      <Box
        style={{
          alignSelf: "start",
          marginTop: 10,
          marginLeft: 8,
        }}
      >
        <BootstrapTooltip
          open={is_save_disabled && is_btn_hovered && ios_btn_type === "save"}
          arrow
          placement="top"
          title="All fields (including the file) are Required"
        >
          <Box
            onMouseOver={() => set_is_btn_hovered(true)}
            onMouseLeave={() => set_is_btn_hovered(false)}
          >
            <Button
              disabled={is_save_disabled}
              variant={ios_btn_type === "save" ? "contained" : "text"}
              color="primary"
              startIcon={
                ios_btn_type === "save" ? <SaveIcon /> : <DeleteIcon />
              }
              onClick={() => {
                if (ios_btn_type === "save" && ios_done) {
                  save_keys();
                  if (closeDialog) {
                    closeDialog();
                  }
                } else if (ios_btn_type === "reset" && ios_done) {
                  reset_keys();
                }
              }}
            >
              {capitalizeEachWord(`${ios_btn_type} key`)}
            </Button>
          </Box>
        </BootstrapTooltip>
      </Box>
    </form>
  );
}
