import React, { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import Switch from "../../../../ReusableComponents/Switch";
import Checkbox from "../../../../ReusableComponents/Checkbox";
import MultiSelect from "../../../../ReusableComponents/MultiSelect";
import { USER_GROUPS, USER_GROUP_ENUM } from "../../../../../constants";
import { isDefined } from "../../../../../utils";
import PeopleIcon from "@material-ui/icons/People";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { countAPI } from "../actions";
import CountBox from "../../../common/components/CountBox";
import { useDispatch } from "../store";
import { SET_DATA } from "../reducer";

const DAYS_FILTER_OPTIONS = [
  ...[...Array(8).keys()].map((i) => ({ label: `Day ${i}`, value: i })),
  //{label: 'Week 1 (1 - 7)', value: 7},
  { label: "Week 2 (8 - 14)", value: 14 },
  { label: "Week 3 (15 - 21)", value: 21 },
  { label: "Week 4 (22 - 28)", value: 28 },
  { label: "1st Month (1 - 30)", value: 30 },
  { label: "2nd Month (31 - 60)", value: 60 },
  { label: "3rd Month (61 - 90)", value: 90 },
];

const DAYS_FILTER_OPTIONS_VALUES = DAYS_FILTER_OPTIONS.map((o) => o.value);

const computeValue = (start, end) => {
  if (
    !isDefined(start, false) ||
    !isDefined(end, false) ||
    start < 0 ||
    end < 0
  ) {
    return [];
  }
  if (end - start === 0) {
    return [start];
  } else if (end - start < 0) {
    return [];
  }
  return [...Array(end - start + 1).keys()].map((i) => i + start);
};

const valueRenderer = (state) => {
  const { computedValues } = state;
  const size = computedValues.length;
  if (size > 0) {
    const min = Number(computedValues[0]);
    const max = Number(computedValues[size - 1]);
    if (min === max) {
      return `Day ${min}`;
    }
    return `Day ${min} - ${max}`;
  } else {
    return "No Days Selected.";
  }
};

function DaysFilter({ startDay, endDay, group, disabled, handleApply }) {
  const [state, setState] = useState({
    values: [],
    computedValues: [],
    applied: false,
  });

  const handleChange = (values = []) => {
    const sortedValues = values.sort((a, b) => a - b);
    const size = sortedValues.length;
    if (size > 0) {
      const min = Number(sortedValues[0]);
      const max = Number(sortedValues[size - 1]);
      let actualMin = min;
      if (min > 29) {
        actualMin = min - 29;
      } else if (min > 7) {
        actualMin = min - 6;
      }
      updateState(actualMin, max, false);
    } else {
      setState({ computedValues: [], values: [], applied: false });
    }
  };

  const updateState = (startDay, endDay, applied = false) => {
    const computedValues = computeValue(startDay, endDay);
    setState({
      computedValues,
      values: computedValues.filter((i) =>
        DAYS_FILTER_OPTIONS_VALUES.includes(i)
      ),
      applied,
    });
  };

  /**
   * Send back the data to the parent component
   */
  const onApplyClick = () => {
    const { computedValues = [], values } = state;
    const size = computedValues.length;
    if (size > 0) {
      handleApply({
        startDay: computedValues[0],
        endDay: computedValues[size - 1],
      });
    } else {
      handleApply({});
    }
    setState({ computedValues, values, applied: true });
  };

  useEffect(() => {
    updateState(startDay, endDay, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDay, endDay]);

  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={5}
        style={{ display: "flex", flexDirection: "column-reverse" }}
      >
        <MultiSelect
          multiple
          options={
            group === USER_GROUP_ENUM.RETURNING_USERS
              ? DAYS_FILTER_OPTIONS.slice(1)
              : DAYS_FILTER_OPTIONS
          }
          withCheckbox
          handleChange={handleChange}
          value={state.values}
          valueRenderer={() => valueRenderer(state)}
          isRangeSelection
          fullWidth
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={4}>
        <Button
          style={{ marginTop: 8 }}
          disabled={disabled || state.applied}
          variant="contained"
          color="primary"
          onClick={onApplyClick}
        >
          Apply
        </Button>
      </Grid>
    </Grid>
  );
}

/**
 * Displays a User Group switch can select on group at any point of time
 * And, a checkbox to enable/disable DayFilters start and end day
 */
function UserCountFilters() {
  const [group, setGroup] = useState("all_users");
  const [enableDays, setEnableDays] = useState(false);
  const [startDay, setStartDay] = useState(-1);
  const [endDay, setEndDay] = useState(-1);

  const dispatch = useDispatch();

  return (
    <Grid container>
      <Grid item xs>
        <Switch
          data={USER_GROUPS}
          value={group}
          handleChange={(selectedGroup) => {
            if (selectedGroup !== group) {
              setGroup(selectedGroup);
              if (selectedGroup === USER_GROUP_ENUM.INSTALL_USERS) {
                setEnableDays(false);
              }
              let updatedStartDay = startDay;
              let updatedEndDay = endDay;
              switch (selectedGroup) {
                case USER_GROUP_ENUM.RETURNING_USERS:
                  updatedStartDay = 1;
                  updatedEndDay = 1;
                  break;
                case USER_GROUP_ENUM.INSTALL_USERS:
                  updatedStartDay = -1;
                  updatedEndDay = -1;
                  break;
                default:
                  break;
              }
              setStartDay(updatedStartDay);
              setEndDay(updatedEndDay);
              dispatch({
                type: SET_DATA,
                group: selectedGroup,
                startDay: updatedStartDay,
                endDay: updatedEndDay,
              });
            }
          }}
          containerStyles={{ marginTop: 0 }}
        />
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Checkbox
              label={
                (group === USER_GROUP_ENUM.UNINSTALL_USERS
                  ? "Uninstall "
                  : "") + "Day Filter"
              }
              disabled={group === USER_GROUP_ENUM.INSTALL_USERS}
              checked={enableDays}
              handleChange={(enableDays) => {
                if (!enableDays) {
                  let sDay;
                  let eDay;
                  if (group === USER_GROUP_ENUM.INSTALL_USERS) {
                    setStartDay(0);
                    setEndDay(0);
                    sDay = eDay = 0;
                  } else {
                    setStartDay(-1);
                    setEndDay(-1);
                    sDay = eDay = -1;
                  }
                  dispatch({
                    type: SET_DATA,
                    group: group,
                    startDay: sDay,
                    endDay: eDay,
                  });
                }
                setEnableDays(enableDays);
              }}
            />
          </Grid>
          {enableDays && (
            <Grid item xs={9}>
              <DaysFilter
                startDay={startDay}
                endDay={endDay}
                handleApply={(days) => {
                  setStartDay(days.startDay);
                  setEndDay(days.endDay);
                  dispatch({
                    type: SET_DATA,
                    group: group,
                    startDay: days.startDay,
                    endDay: days.endDay,
                  });
                }}
                group={group}
                disabled={group === USER_GROUP_ENUM.INSTALL_USERS}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

/**
 * Displays user and session counts for the selected filters
 */
export default function UserCounts({
  appId,
  queryParams,
  filters,
  onUsersFetch,
  onSessionsFetch,
}) {
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6}>
          <UserCountFilters />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CountBox
            appId={appId}
            queryParams={queryParams}
            filters={filters}
            isUserCount={true}
            subheading="Users"
            api={countAPI}
            onFetch={(value) => {
              if (onUsersFetch) {
                onUsersFetch(value);
              }
            }}
            icon={<PeopleIcon />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CountBox
            appId={appId}
            queryParams={queryParams}
            filters={filters}
            isUserCount={false}
            subheading="Sessions"
            api={countAPI}
            onFetch={(value) => {
              if (onSessionsFetch) {
                onSessionsFetch(value);
              }
            }}
            icon={<AccessTimeIcon />}
          />
        </Grid>
      </Grid>
    </>
  );
}
