import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../../contexts/AuthContext";
import Box from "../../../../ReusableComponents/Box";
import Switch from "../../../../ReusableComponents/Switch";
import { Button } from "@material-ui/core";
import { getEventAttributeDistributionAPI } from "../actions";
import FilterListIcon from "@material-ui/icons/FilterList";
import PersonIcon from "@material-ui/icons/Person";
import ClearIcon from "@material-ui/icons/Clear";
import MultiSelect from "../../../../ReusableComponents/MultiSelect";
import { useDeepCompareEffect } from "../../../../../utils/use-deep-compare";
import { getEventAttributesAPI } from "../../../../common/actions";
import DonutDistributions from "../../../../ReusableComponents/DonutDistributions";
import Loading from "../../../../ReusableComponents/Loading";

const SWITCH_DATA = [
  { name: "Events", value: "event" },
  { name: "Users", value: "users" },
  { name: "Sessions", value: "sessions" },
];

const emptyAttributes = (props) => {
  const attrs = [...props];
  return {
    ...attrs.reduce((a, b) => {
      a[b] = [];
      return a;
    }, {}),
  };
};

const toAttributeFilterModel = (names = {}) => {
  const array = [];
  Object.keys(names).forEach((key) => {
    const values = names[key];
    if (values.length > 0) {
      array.push({
        name: key,
        operator: "EQ",
        value: values,
      });
    }
  });
  return array;
};

export default function EventAttributeDistributions({
  appId,
  userAttributes,
  sessionAttributes,
  filters,
  queryParams,
  eventName,
  onFiltersChange,
}) {
  const auth = useAuth();
  const [attributes, setAttributes] = useState([]);

  const [attrValueMap, setAttrValueMap] = useState(
    emptyAttributes([...userAttributes, ...sessionAttributes, ...attributes])
  );
  const [selectedProps, setSelectedProps] = useState([]);
  const [distributions, setDistributions] = useState([]);
  const [count, setCount] = useState(0);
  const [show, setShow] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [of, setOf] = useState("users");
  const [fetching, setFetching] = useState(false);

  // Fetch event attributes
  useEffect(() => {
    getEventAttributesAPI(auth, appId, {
      ...queryParams,
      event: eventName,
    }).then((values) => {
      setAttributes([...values, ...userAttributes, ...sessionAttributes]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAttrValueMap(
      emptyAttributes([...userAttributes, ...sessionAttributes, ...attributes])
    );
  }, [userAttributes, sessionAttributes, attributes]);

  // Fetch the distributions upon change of `of`, `queryParams` or `filters` props
  useDeepCompareEffect(() => {
    selectedProps.forEach((prop) => {
      const qParams = {
        ...queryParams,
        of: of,
        attribute: prop,
        event: eventName,
      };
      const postBody = filtersApplied
        ? {
            ...filters,
            event: [
              {
                name: eventName,
                count: { value: 1, operator: "GTE" },
                attributes: toAttributeFilterModel(attrValueMap),
              },
            ],
          }
        : filters;
      setFetching(true);
      getEventAttributeDistributionAPI(auth, appId, qParams, postBody)
        .then((response) => {
          setFetching(false);
          setDistributions({
            ...distributions,
            [prop]: response,
          });
        })
        .catch((err) => {
          setFetching(false);
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [of, queryParams, filters, filtersApplied]);

  // Clears the filters
  const cancelFilters = () => {
    setAttrValueMap(
      emptyAttributes([...userAttributes, ...sessionAttributes, ...attributes])
    );
    setShow(false);
    setCount(0);
    if (filtersApplied && onFiltersChange) {
      onFiltersChange([], []);
    }
    setFiltersApplied(false);
  };

  const updateFilters = () => {
    setShow(true);
    setFiltersApplied(!filtersApplied);
    if (onFiltersChange) {
      onFiltersChange(toAttributeFilterModel(attrValueMap));
    }
  };

  /**
   * Updates the respective [property]: [values] map
   *
   * If it is of user props, it updates `userAttrValueMap` map
   * If it is of session props, it updates `sessionAttrValueMap` map
   */
  const donutValueSelectHandler = (propName, data) => {
    let key = data.key || data.name || "";
    let array = attrValueMap[propName];
    if (array.indexOf(key) > -1) {
      array.splice(array.indexOf(key), 1);
      setCount(count - 1);
    } else {
      array.push(key);
      setCount(count + 1);
    }
    setShow(true);
    setFiltersApplied(false);

    setAttrValueMap({
      ...attrValueMap,
      [propName]: array,
    });
  };

  /**
   * Whenever user selects a prop or clear a prop this function gets called
   *
   * If the `attributes.length` is 0, clear the filters
   * If not, check if the selected key exists in `selected[User/Session]Props`
   *          If key doesn't exist, fetch the data and update it in respective distribution state
   *          If not, stay silent
   * @param attributes
   * @param isUser
   */
  const handleAttributeChange = (attributes = []) => {
    if (attributes.length > 0) {
      attributes.forEach((attribute) => {
        const qParams = {
          ...queryParams,
          of: of,
          attribute: attribute,
          event: eventName,
        };
        if (!selectedProps.includes(attribute)) {
          setFetching(true);
          getEventAttributeDistributionAPI(auth, appId, qParams, filters)
            .then((response) => {
              setFetching(false);
              setDistributions({
                ...distributions,
                [attribute]: response,
              });
            })
            .catch((err) => {
              setFetching(false);
            });
        }
      });
      // Cleanup distributions data and update the select props
      selectedProps.forEach((prop) => {
        if (!attributes.includes(prop)) {
          const updated = { ...distributions };
          delete updated[prop];
          setDistributions(updated);
        }
      });
    } else {
      cancelFilters();
      // Clear all distribution related data
      setDistributions({});
    }

    setSelectedProps(attributes);
  };

  return (
    <Box
      withPadding
      controls={
        count > 0 &&
        show && (
          <Button
            variant="contained"
            color={filtersApplied ? "secondary" : "primary"}
            onClick={(e) => {
              e.stopPropagation();
              if (filtersApplied) {
                cancelFilters();
              } else {
                updateFilters();
              }
            }}
          >
            {filtersApplied ? "Cancel " : "Apply "} &nbsp;
            {filtersApplied ? <ClearIcon /> : <FilterListIcon />}
          </Button>
        )
      }
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <MultiSelect
          placeholder="Select Properties"
          value={selectedProps}
          options={attributes.map((item) => ({
            label: item,
            value: item,
          }))}
          handleChange={(values) =>
            /* Should get the distributions upon every selection of property */
            handleAttributeChange(values)
          }
          style={{ maxWidth: 600 }}
        />
        <Switch
          data={SWITCH_DATA}
          value={of}
          handleChange={(value) =>
            /* Whenever the value changes, fetch all user and session distributions selected so far */
            setOf(value)
          }
          containerStyles={{ marginLeft: "auto" }}
        />
      </div>
      <Box withPadding title="Event Properties" icon={<PersonIcon />}>
        {!fetching && (
          <DonutDistributions
            messageForNoData="No Properties Selected"
            data={distributions}
            handleSelect={(item, values) =>
              donutValueSelectHandler(item, values, true)
            }
            selections={attrValueMap}
          />
        )}
        {fetching && <Loading size={36} />}
      </Box>
    </Box>
  );
}
