import {
  Button,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import ColumnDiv from "../../../../../../ReusableComponents/ColumnDiv";
import RowDiv from "../../../../../../ReusableComponents/RowDiv";
import { useSummaryStyles } from "../../../../../common/components/campaigncreator/components/summary/constants";
import { getRouteForActionType } from "../../../../../common/components/campaigncreator/utils";
import DesignPreviewSection from "./DesignPreviewSection";
import { getUI } from "../utils";

  const useStyles = makeStyles((theme) => ({
  tabsIndicator: {
    height: 3,
    borderRadius: 0,
  },
  tabRoot: {
    padding: 0,
    textTransform: "initial",
    minWidth: 72,
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "20px",
    letterSpacing: "0.01em",
    marginRight: theme.spacing(4),
    "&:focus": {
      // color: '#40a9ff',
    },
  },
}));

export default function DesignSummaryComponent({
  config,
  handleSelect,
  appId = "",
  actionType = "",
  isGuidedToursTab,
}) {
  const {
    _id,
    uis = [],
    meta: { isExperiment = false },
    experiment: { variants } = { variants: {} },
  } = config;
  const { push, location } = useHistory();

  const headerClasses = useSummaryStyles();
  const classes = useStyles();

  const [selectedVariant, setSelectedVariant] = useState(0);

  const variantCodes = useMemo(() => Object.keys(variants || {}).sort(), [
    variants,
  ]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} className={headerClasses.root}>
        <Typography variant="body1" className={headerClasses.summaryHeading}>
          Design
        </Typography>
        {handleSelect && (
          <Button
            variant="text"
            color="primary"
            onClick={() => handleSelect(0)}
            className={headerClasses.editButton}
          >
            Edit
          </Button>
        )}
      </Grid>
      <Grid item xs={12} style={{ padding: "20px 26px" }}>
        {!isExperiment && (
          <RowDiv center styles={{ gap: 10, overflow: "scroll" }}>
            {uis.map((_, index) => (
              <DesignPreviewSection
                key={index}
                campaignId={_id}
                currentStep={index}
                previewMode={true}
                ui={getUI(index, uis, isExperiment)}
                isSummary={true}
                showEdit={config?.state === "DRAFT" && !isGuidedToursTab}
                handleSelect={(initial_active_step) => {
                  const destinationURL = `/apps/${appId}/${getRouteForActionType(
                    actionType,
                  )}/${_id}/edit`;
                  if (location?.pathname === destinationURL) {
                    push({
                      state: {
                        initial_active_step,
                        step_id: index,
                      },
                    });
                  } else {
                    push({
                      pathname: destinationURL,
                      state: {
                        initial_active_step,
                        step_id: index,
                      },
                    });
                  }
                  if (handleSelect) handleSelect(0);
                }}
              />
            ))}
          </RowDiv>
        )}
        {isExperiment && (
          <ColumnDiv center styles={{ gap: 10 }}>
            <Tabs
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator,
              }}
              value={selectedVariant}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, value) => {
                setSelectedVariant(value);
              }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#002845",
                },
              }}
            >
              {variantCodes
                .filter((item) => item !== "controlGroup" && item !== "CG")
                .map((variantName, index) => (
                  <Tab
                    key={variantName}
                    label={
                      <RowDiv center styles={{ gap: 8 }}>
                        <div
                          style={{
                            fontSize: "18px",
                            fontWeight: "700",
                            fontStyle: "normal",
                            fontFamily: "Manrope",
                            background:
                              index === selectedVariant ? "#00BDE1" : "#E5E5E5",
                            padding: "8px 12px",
                            border:
                              index === selectedVariant
                                ? "none"
                                : "1px solid #DCE0E3",
                            color:
                              index === selectedVariant ? "#FFFFFF" : "#8497A5",
                            borderRadius: 2,
                          }}
                        >
                          {variantName.toUpperCase()}
                        </div>
                        <Typography color="textPrimary" variant="h6">
                          {variants[variantName].name ?? "Unset"}
                        </Typography>
                      </RowDiv>
                    }
                    classes={{ root: classes.tabRoot }}
                    disableRipple
                  />
                ))}
            </Tabs>
            <RowDiv center styles={{ gap: 10, overflow: "scroll" }}>
              {variantCodes.length > 0 &&
                variants[variantCodes[selectedVariant]].indices.map(
                  (uiIndex, index) => (
                    <DesignPreviewSection
                      key={index}
                      campaignId={_id}
                      currentStep={index}
                      previewMode={true}
                      ui={getUI(uiIndex, uis, isExperiment)}
                      isSummary={true}
                      showEdit={config?.state === "DRAFT"}
                      handleSelect={(initial_active_step) => {
                        const destinationURL = `/apps/${appId}/${getRouteForActionType(
                          actionType,
                        )}/${_id}/edit`;
                        if (location?.pathname === destinationURL) {
                          push({
                            state: {
                              initial_active_step,
                              step_id: index,
                            },
                          });
                        } else {
                          push({
                            pathname: destinationURL,
                            state: {
                              initial_active_step,
                              step_id: index,
                            },
                          });
                        }
                        if (handleSelect) handleSelect(0);
                      }}
                    />
                  ),
                )}
            </RowDiv>
          </ColumnDiv>
        )}
      </Grid>
    </Grid>
  );
}

