import { ACTION_CLASSES, COACHMARK_LAYOUT_TYPES } from "../../constants";
import { TEXT_CONFIG, CONSTANT_CONFIGS, CONTAINER_CONFIG } from "../common";

const DEFAULT_COACHMARK_CONFIG = {
  name: "",
  activity: "",
  delay: 1000,
  timeout: 30000,
  find_interval: 2000,
  display_type: "coach_mark",
  type: "IN_LINE",
  coach_mark_details: {
    arrow_color: "#F1F1F1",
    arrow_stroke_width: 10,
    has_arrow: false,
    has_ripple: true,
    type: "circle",
  },
  dim_background: true,
  text_config: {
    ...TEXT_CONFIG,
    text: "Your text goes here.",
  },
  buttons_config: [],
  ...CONTAINER_CONFIG,
  ...CONSTANT_CONFIGS,
  bg_color: "#03A9B6",
  opacity: 0.8,
};

const defaultJSON = JSON.parse(JSON.stringify(DEFAULT_COACHMARK_CONFIG));

const json = {
  ...defaultJSON,
  action_class: ACTION_CLASSES.COACHMARK,
};

const DEFAULT = {
  ...json,
  window_attributes: {
    ...json.window_attributes,
    position: "TOP",
  },
  layout_type: COACHMARK_LAYOUT_TYPES.DEFAULT,
};

export const getCoachmarkJSON = (type) => {
  switch (type) {
    case COACHMARK_LAYOUT_TYPES.DEFAULT:
      return DEFAULT;
    default:
      return DEFAULT;
  }
};
