import React, { useEffect, useState } from "react";
import { Table, Column, Cell } from "fixed-data-table-2";
import Dimensions from "react-dimensions";
import {
  SortHeaderCell,
  SortTypes,
} from "../../../../../ReusableComponents/SortableDataTable";
import Placeholder from "../../../../../ReusableComponents/Placeholder";
import Loading from "../../../../../ReusableComponents/Loading";
import {
  sortSessionList,
  roundOffNumber,
  formatTime,
} from "../../../../../../utils";
import Snackbar from "@material-ui/core/Snackbar";
import "fixed-data-table-2/dist/fixed-data-table.css";
import Chip from "@material-ui/core/Chip";

const TextCell = ({ rowIndex, data, col, ...props }) => (
  <Cell
    {...props}
    onClick={() => props.onClick(data[rowIndex])}
    style={{ cursor: "pointer" }}
  >
    {data[rowIndex][col]}
  </Cell>
);

const FloatCell = ({ rowIndex, data, col, ...props }) => (
  <Cell
    {...props}
    onClick={() => props.onClick(data[rowIndex])}
    style={{ cursor: "pointer" }}
  >
    {data[rowIndex][col] === 0 ? "NA" : roundOffNumber(data[rowIndex][col])}
  </Cell>
);

const IS_FIRST_SESSION_KEY = "is_first_session";
const IS_UNINSTALL_BEFORE_SESSION_KEY = "is_uninstall_before_session";

const TimeCell = ({ rowIndex, data, col, ...props }) => (
  <Cell
    {...props}
    onClick={() => props.onClick(data[rowIndex])}
    style={{ cursor: "pointer" }}
  >
    <span style={{ display: "flex" }}>
      {formatTime(data[rowIndex][col])}
      <span style={{ marginLeft: "10px" }}>
        {data[rowIndex][IS_FIRST_SESSION_KEY] && <Chip label="FIRST SESSION" />}
        {data[rowIndex][IS_UNINSTALL_BEFORE_SESSION_KEY] && (
          <Chip label="UNINSTALL SESSION" />
        )}
      </span>
    </span>
  </Cell>
);

const SessionsDataTable = ({ data, containerWidth = 800, onSessionClick }) => {
  const [sortedDataList, setSortedDataList] = useState([]);
  const [colSortDirs, setColSortDirs] = useState({
    time: SortTypes.ASC,
  });
  const [isEmptySession, setEmptySession] = useState(false);

  function _onSortChange(columnKey, sortDir) {
    setSortedDataList(
      sortSessionList([...sortedDataList], columnKey, sortDir, SortTypes)
    );
    setColSortDirs({ [columnKey]: sortDir });
  }

  useEffect(() => {
    setSortedDataList(
      sortSessionList([...data.session_list], "time", SortTypes.ASC, SortTypes)
    );
  }, [data]);

  function handleRowClick(rowData) {
    if (rowData.navigation_count === 0 && rowData.events === 0) {
      setEmptySession(true);
    } else {
      onSessionClick(rowData.session_id, rowData.time, rowData); //sending session id and session start time
      setEmptySession(false);
    }
  }

  const handleRequestClose = () => {
    setEmptySession(false);
  };

  let rowHeight = 0,
    height = 100,
    length = 0;
  rowHeight = 50;
  if (sortedDataList) {
    length = sortedDataList.length;
    height = rowHeight * (length > 10 ? 10 : length + 1);
  }
  return (
    <div>
      {false && <Placeholder height={200} />}
      <Table
        rowHeight={rowHeight}
        headerHeight={rowHeight}
        rowsCount={length}
        width={containerWidth || 800}
        height={height}
      >
        <Column
          fixed={true}
          columnKey="time"
          header={
            <SortHeaderCell
              columnKey="time"
              onSortChange={_onSortChange}
              sortDir={colSortDirs.time}
            >
              Time
            </SortHeaderCell>
          }
          cell={
            <TimeCell
              onClick={handleRowClick}
              data={sortedDataList}
              col="time"
            />
          }
          width={350}
        />
        <Column
          flexGrow={1}
          columnKey="length"
          header={
            <SortHeaderCell
              columnKey="length"
              onSortChange={_onSortChange}
              sortDir={colSortDirs.length}
            >
              Duration <span className="small">(Secs)</span>
            </SortHeaderCell>
          }
          cell={
            <FloatCell
              onClick={handleRowClick}
              data={sortedDataList}
              col="length"
            />
          }
          width={100}
        />
        <Column
          flexGrow={1}
          columnKey="navigation_count"
          header={
            <SortHeaderCell
              columnKey="navigation_count"
              onSortChange={_onSortChange}
              sortDir={colSortDirs.navigation_count}
            >
              Navigations
            </SortHeaderCell>
          }
          cell={
            <TextCell
              onClick={handleRowClick}
              data={sortedDataList}
              col="navigation_count"
            />
          }
          width={100}
        />
        <Column
          flexGrow={1}
          columnKey="events"
          header={
            <SortHeaderCell
              columnKey="events"
              onSortChange={_onSortChange}
              sortDir={colSortDirs.events}
            >
              Events
            </SortHeaderCell>
          }
          cell={
            <TextCell
              onClick={handleRowClick}
              data={sortedDataList}
              col="events"
            />
          }
          width={100}
        />
        <Column
          flexGrow={1}
          columnKey="crashes"
          header={
            <SortHeaderCell
              columnKey="crashes"
              onSortChange={_onSortChange}
              sortDir={colSortDirs.crashes}
            >
              Crashes/Hangs
            </SortHeaderCell>
          }
          cell={
            <TextCell
              onClick={handleRowClick}
              data={sortedDataList}
              col="crashes"
            />
          }
          width={100}
        />
      </Table>
      {false && <Loading />}
      <Snackbar
        open={isEmptySession}
        message="Timeline not available for Empty Session..!"
        autoHideDuration={4000}
        onRequestClose={handleRequestClose}
      />
    </div>
  );
};

export default Dimensions()(SessionsDataTable);
