import React, { useEffect, useRef } from "react";
import {
  Button,
  Tooltip,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import EventIcon from "@material-ui/icons/Event";
import CloseIcon from "@material-ui/icons/Close";
import deepEqual from "deep-equal";
import { Provider, useTrackedState, useDispatch } from "./store";
import {
  ACTION_ADD_EVENT,
  ACTION_SET_QUERY_PARAMS,
  SET_CONDITION,
  ACTION_REMOVE_EVENT,
  ACTION_SET_INITIAL_EVENTS,
} from "./reducer";
import Event from "./Event";
import { CONDTIONAL_OPERATORS } from "../../../constants";
import { default as MaterialTooltip } from "@material-ui/core/Tooltip";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { useDateFilters } from "../../Dashboard/common/utils";

/**
 * Reusable component maintains it's own reducer to provide a state for it's children to
 * update the state that is being maintained by this component.
 *
 * We use react-tracked library to do the hardwork under the hood
 * and also make use of the immer library to get rid of spread operator
 *
 * We need to do the following steps in our `store.js` file
 *
 * const useValue = () => useReducer(reducer, initialState);
 *
 * export const { Provider, useTracked, useTrackedState, useUpdate: useDispatch } = createContainer(
 *   useValue
 * );
 *
 */

const DefaultAddButton = (
  <>
    Add Event
    <EventIcon />
  </>
);

export default function EventBuilder({
  events,
  appId,
  operators,
  onQueryChange,
  withBox,
  countConfig = { value: 1, operator: "GTE" },
  showAddStepAfter = false,
  hideCondition = false,
  selectedEvents = [],
  addButtonNode = DefaultAddButton,
  showWhereCount = false,
  enableSingleEvent = false,
  showNumbers = false,
}) {
  return (
    <Provider>
      <EventBuilderWithProvider
        key={"EventBuilder"}
        events={events}
        operators={operators}
        appId={appId}
        withBox={withBox}
        onQueryChange={onQueryChange}
        countConfig={countConfig}
        showAddStepAfter={showAddStepAfter}
        hideCondition={hideCondition}
        selectedEvents={selectedEvents}
        addButtonNode={addButtonNode}
        showWhereCount={showWhereCount}
        enableSingleEvent={enableSingleEvent}
        showNumbers={showNumbers}
      />
    </Provider>
  );
}

function Condition({ index, condition, handleUpdate, disabled = false }) {
  const { AND, OR } = CONDTIONAL_OPERATORS;
  return (
    <Tooltip
      id={"attribute-condition-" + index}
      title="Click to change"
      placement="top"
    >
      <Button
        disabled={disabled}
        style={{ margin: "10px auto", display: "block" }}
        size="small"
        variant="contained"
        color={condition === AND ? "secondary" : "primary"}
        onClick={() => {
          handleUpdate(condition === AND ? OR : AND);
        }}
      >
        {condition}
      </Button>
    </Tooltip>
  );
}

let nextEventId = 500;
let nextAttrId = 5000;

function setSelectedEvents(selectedEvents) {
  const updatedEvents = selectedEvents.map((event) => {
    let attributes = event.attributes;
    attributes = attributes.map((attr) => ({
      id: nextAttrId++,
      ...attr,
    }));
    return { ...event, id: nextEventId++, attributes: attributes };
  });
  return updatedEvents;
}

let firstRenderDone = false;

function EventBuilderWithProvider({
  events,
  operators,
  appId,
  onQueryChange,
  countConfig,
  withBox,
  selectedEvents = [],
  // This flag enables us to add an event between two events
  showAddStepAfter = false,
  // If this flag is true, we don't have to show AND/OR buttons between events
  hideCondition = false,
  addButtonNode = DefaultAddButton,
  showWhereCount = false,
  enableSingleEvent = false,
  showNumbers = false,
}) {
  // const [state, dispatch] = useTracked();
  const state = useTrackedState();
  const dispatch = useDispatch();
  const [dateFilters] = useDateFilters();
  const previousSelectedEvents = useRef(selectedEvents);

  // Set Query Params after componentDidMount
  // Fetch events list
  useEffect(() => {
    dispatch({
      type: ACTION_SET_QUERY_PARAMS,
      appId: appId,
      since: dateFilters?.since,
      till: dateFilters?.till,
    });
    firstRenderDone = true;
    const events = setSelectedEvents(selectedEvents);
    previousSelectedEvents.current = events;
    dispatch({
      type: ACTION_SET_INITIAL_EVENTS,
      events: events,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // After this component renders, the `selectedEvents` can be passed down to thi component
  // So, whenever the `selectedEvents` get changed, we need to display
  // TODO Ref based updates are very dangerous. Re-visit this and handle properly, if you encounter any bug
  useEffect(() => {
    if (
      !deepEqual(selectedEvents, previousSelectedEvents.current, {
        strict: true,
      })
    ) {
      const events = setSelectedEvents(selectedEvents);
      previousSelectedEvents.current = events;
      dispatch({
        type: ACTION_SET_INITIAL_EVENTS,
        events: events,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEvents]);

  useEffect(() => {
    // FIXME: This is a hacky way to stop infinite re-renders due to problems in async dispatch calls
    if (firstRenderDone) {
      firstRenderDone = false;
      return;
    }

    if (onQueryChange) {
      const { events, condition } = state;
      let isValid = true;
      for (let index in events) {
        const event = events[index];
        if (event.name === "") {
          isValid = false;
          break;
        }
        const attributes = event.attributes;
        for (let i in attributes) {
          const attribute = attributes[i];
          if (attribute.name === "" || attribute.operator === "") {
            isValid = false;
            break;
          }
        }
        if (!isValid) {
          break;
        }
      }
      if (isValid) {
        onQueryChange(events, condition);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const addEvent = (index = -1) => {
    dispatch({
      type: ACTION_ADD_EVENT,
      countConfig: countConfig,
      index: index,
    });
  };

  return (
    <>
      {state.events.map(
        ({ id = nextEventId++, name, attributes, count }, index) => (
          <React.Fragment key={`Event ${name} ${index}`}>
            {!hideCondition && index !== 0 && (
              <Condition
                index={index}
                condition={state.condition}
                handleUpdate={(value) =>
                  dispatch({ type: SET_CONDITION, value: value })
                }
              />
            )}
            <Grid container spacing={1}>
              {showNumbers && (
                <Grid item style={{ marginTop: 24 }}>
                  <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                    {index + 1}.
                  </Typography>
                </Grid>
              )}
              <Grid item xs style={{ paddingLeft: 12 }}>
                <Event
                  id={id}
                  title={/*showCondition ? "Step " + (index + 1) : */ null}
                  name={name}
                  attributes={attributes}
                  count={count}
                  events={events}
                  operators={operators}
                  withBox={withBox}
                  showWhereCount={showWhereCount}
                />
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  style={{
                    display: "block",
                    margin: "0 auto",
                    color: "#A1ADB6",
                  }}
                  onClick={() => {
                    dispatch({
                      type: ACTION_REMOVE_EVENT,
                      id: id,
                    });
                  }}
                >
                  <CloseIcon />
                </IconButton>
                {showAddStepAfter &&
                  index >= 0 &&
                  index + 1 !== state.events.length && (
                    <MaterialTooltip
                      title="Add New Event Below"
                      placement="bottom"
                    >
                      <IconButton
                        style={{ display: "block", margin: "0 auto" }}
                        onClick={() => addEvent(index + 1)}
                      >
                        <PlaylistAddIcon color="primary" />
                      </IconButton>
                    </MaterialTooltip>
                  )}
              </Grid>
            </Grid>
          </React.Fragment>
        )
      )}
      {enableSingleEvent && selectedEvents.length >= 1 ? (
        <></>
      ) : (
        <Button
          color="primary"
          onClick={() => addEvent()}
          style={{ margin: "10px 0" }}
        >
          {addButtonNode}
        </Button>
      )}
    </>
  );
}

EventBuilder.whyDidYouRender = true;
