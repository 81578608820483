import React, { useState, useEffect } from "react";
import { Button, Grid } from "@material-ui/core";
import { useAuth } from "../../../../../contexts/AuthContext";
import Box from "../../../../ReusableComponents/Box";
import Snackbar from "../../../../ReusableComponents/Snackbar";
import Switch from "../../../../ReusableComponents/Switch";
import FilterIcon from "@material-ui/icons/FilterList";
import PeopleIcon from "@material-ui/icons/People";
import { RETENTION_ENUM } from "../constants";
import { RETENTION_WINDOWS } from "../constants";
import ActionsTextField from "../../../../ReusableComponents/ActionsTextField";
import EventBuilder from "../../../../ReusableComponents/EventBuilder";
import { getEventsAPI } from "../../../../common/actions";
import { ContainsOperators } from "../../../../../constants";
import { RetentionGraph } from "./RetentionGraph";
import { useTracked } from "../store";
import {
  SET_FIRST_EVENT,
  SET_NEXT_EVENT,
  SET_EVENT_LIST,
  SET_DAY_WINDOW,
  SET_DAY_WINDOW_TEXT,
  SET_RETENTION_WINDOW,
  SET_RETENTION_DATA,
  SET_RETENTION_HEADER,
} from "../constants";
import { getRetentionData } from "../actions";

export const RetentionAnalysis = ({
  appId,
  props,
  queryParams,
  dateFilters,
  filters,
}) => {
  const auth = useAuth();
  const [state, dispatch] = useTracked();
  const {
    retention: {
      query: { first_event, next_event },
    },
    eventList,
    retention_type,
    retentionWindow,
    dayWindowText,
    dayWindow,
  } = state;
  const [collapseNow, setCollapseNow] = useState(undefined);

  const handleFirstQueryChange = (firstEvent) => {
    dispatch({ type: SET_FIRST_EVENT, value: firstEvent });
  };

  const handleNextQueryChange = (nextEvent) => {
    dispatch({ type: SET_NEXT_EVENT, value: nextEvent });
  };

  // ComponentWillMount, Run only once
  useEffect(() => {
    getEventsAPI(auth, appId, queryParams).then((response) => {
      dispatch({ type: SET_EVENT_LIST, value: response });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      title="User Retention"
      icon={<PeopleIcon />}
      withPadding
      collapsible
      defaultExpanded
    >
      {retention_type !== RETENTION_ENUM.FTU &&
        (!first_event || first_event.name.length === 0) && (
          <Snackbar>Need at least one Event</Snackbar>
        )}

      {/* TODO: Limit this feature to only Veooz client */}
      {
        <Grid container>
          <Grid item xs={4}>
            <Switch
              disabled={false}
              data={RETENTION_WINDOWS}
              handleChange={(retentionWindowValue) => {
                dispatch({
                  type: SET_RETENTION_WINDOW,
                  value: retentionWindowValue,
                });
                if (retentionWindow !== "custom") {
                  dispatch({
                    type: SET_DAY_WINDOW,
                    value: retentionWindowValue,
                  });
                }
              }}
              value={dayWindow}
            />
          </Grid>
          {retentionWindow === "custom" && (
            <>
              <Grid item xs={1}>
                <ActionsTextField
                  value={dayWindowText}
                  onChange={(e) => {
                    const _dayWindow = Number(e.target.value);
                    if (!isNaN(_dayWindow)) {
                      dispatch({
                        type: SET_DAY_WINDOW_TEXT,
                        value: _dayWindow,
                      });
                      dispatch({
                        type: SET_DAY_WINDOW,
                        value: _dayWindow,
                      });
                    }
                  }}
                  fullWidth
                  label="Day window"
                />
              </Grid>
              <Grid item xs={1}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    dispatch({
                      type: SET_DAY_WINDOW,
                      value: dayWindowText,
                    });
                  }}
                  disabled={
                    dayWindowText < 1 ||
                    dayWindowText >= 365 ||
                    dayWindowText === dayWindow
                  }
                >
                  Apply
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      }

      <Box
        title="Filter by"
        collapsible
        icon={<FilterIcon />}
        defaultExpanded={retention_type !== RETENTION_ENUM.FTU}
        withPadding
        collapseNow={collapseNow}
      >
        <Box
          title={
            retention_type === RETENTION_ENUM.FTU
              ? "Users who did"
              : "Select Event"
          }
          withPadding
        >
          <EventBuilder
            appId={appId}
            events={eventList}
            selectedEvents={first_event ? [first_event] : []}
            operators={ContainsOperators}
            withBox={true}
            onQueryChange={handleFirstQueryChange}
            hideCondition={true}
            enableSingleEvent={true}
          />
        </Box>
        {retention_type === RETENTION_ENUM.FTU && (
          <Box title="Came back and did" withPadding>
            <EventBuilder
              appId={appId}
              events={eventList}
              selectedEvents={next_event ? [next_event] : []}
              operators={ContainsOperators}
              withBox={true}
              onQueryChange={handleNextQueryChange}
              hideCondition={true}
              enableSingleEvent={true}
            />
          </Box>
        )}
        <Grid container justify="flex-end">
          <Grid item xs style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              size="small"
              color="secondary"
              onClick={() => {
                dispatch({ type: SET_FIRST_EVENT, value: "" });
                dispatch({ type: SET_NEXT_EVENT, value: "" });
                getRetentionData(
                  auth,
                  appId,
                  queryParams,
                  filters,
                  retention_type,
                  "",
                  { first_event, next_event }
                ).then((response) => {
                  dispatch({
                    type: SET_RETENTION_DATA,
                    value: {
                      days: response.days,
                      weeks: response.weeks,
                      months: response.months,
                    },
                  });
                  dispatch({
                    type: SET_RETENTION_HEADER,
                    value: {
                      retention_header: response.header,
                    },
                  });
                });
                setCollapseNow(true);
              }}
              style={{ margin: "0 10px" }}
            >
              Reset
            </Button>
            <Button
              size="small"
              color="primary"
              onClick={() => {
                getRetentionData(
                  auth,
                  appId,
                  queryParams,
                  filters,
                  retention_type,
                  "",
                  { first_event, next_event }
                ).then((response) => {
                  dispatch({
                    type: SET_RETENTION_DATA,
                    value: {
                      days: response.days,
                      weeks: response.weeks,
                      months: response.months,
                    },
                  });
                  dispatch({
                    type: SET_RETENTION_HEADER,
                    value: {
                      retention_header: response.header,
                    },
                  });
                });
              }}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      </Box>
      <RetentionGraph
        title="Retention Cohort Analysis"
        switchData={[]}
        enableTooltip={false}
        dateFilters={dateFilters}
      />
    </Box>
  );
};
