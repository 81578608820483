import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { ResultsHolder } from "./components/ResultsHolder";
import Placeholder from "../../../../../ReusableComponents/Placeholder";
import BrokenImage from "@material-ui/icons/BrokenImage";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../../../contexts/AuthContext";
import { getCampaignAPI } from "../../../../common/actions";
import { surveyResultsAPI } from "./actions";
import { useDateFilters } from "../../../../common/utils";
import Filters from "../../../../common/components/Filters";
import { POST_BODY, SET_CONFIG } from "./constants";
import { Provider, useDispatch } from "./store";
import { Typography } from "@material-ui/core";

const SurveyResultsWithProvider = ({ app, config }) => {
  const auth = useAuth();
  const [survey, setSurvey] = useState(config);

  const [dateFilters, setDateFilters] = useDateFilters();
  const [surveyResults, setSurveyResults] = useState();
  const [pending, setPending] = useState(false);
  const { params } = useRouteMatch();
  const [cohort, setCohort] = useState(null);
  const [version, setVersion] = useState(null);
  const dispatch = useDispatch();

  const getSurveysResults = surveyId => {
    setPending(true);
    surveyResultsAPI(
      auth,
      params.appId,
      surveyId,
      { ...dateFilters, versions: version, cohort: cohort, readable: true },
      POST_BODY
    )
      .then(response => {
        setPending(false);
        setSurveyResults(response);
      })
      .catch(e => {
        setPending(false);
      });
  };

  useEffect(() => {
    if (!survey) {
      const campaignId = params.campaignId;
      const appId = params.appId;
      setPending(true);
      getCampaignAPI(auth, appId, campaignId, { configType: "survey" }).then(
        response => {
          setSurvey(response);
          setDateFilters({
            since: response.validity.start_date,
            till: response.validity.end_date,
          });
          dispatch({ type: SET_CONFIG, value: response });
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (survey) getSurveysResults(survey._id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilters, version, cohort]);

  const getFiltersFromConfig = () => {
    if (survey) {
      return {
        since: survey.validity.start_date,
        till: survey.validity.end_date,
      };
    } else return dateFilters;
  };

  return (
    <section className="content">
      {survey && (
        <>
          <Typography variant="h4" style={{ marginBottom: 16 }}>
            {survey?.meta?.name}
          </Typography>
          <Filters
            app={app}
            dateFilters={getFiltersFromConfig()}
            showVersion={true}
            showCohort={true}
            onVersionChange={version =>
              setVersion(version === "" ? null : version)
            }
            onCohortChange={cohortId =>
              setCohort(cohortId === "" ? null : cohortId)
            }
            onDateFiltersChange={(start, end) =>
              setDateFilters({
                since: start,
                till: end,
              })
            }
          />
        </>
      )}
      {!pending && (!survey || !Array.isArray(surveyResults?.data)) && (
        <Placeholder
          withIcon
          icon={
            <BrokenImage style={{ opacity: 0.2, width: 150, height: 150 }} />
          }
          text={
            !survey ? (
              <span>
                Not found what you are looking for.
                <Link to={`/apps/${app.app_id}/surveys`}>
                  {" "}
                  Try coming from surveys
                </Link>
              </span>
            ) : (
              <span>
                Survey results seems broken. May be edited after publishing?.
              </span>
            )
          }
        />
      )}

      <ResultsHolder
        dateFilters={dateFilters}
        survey={survey}
        results={surveyResults}
        pending={pending}
      />
    </section>
  );
};

export default function SurveyResults({ app, config }) {
  return (
    <Provider>
      <SurveyResultsWithProvider app={app} config={config} />
    </Provider>
  );
}
