import { makeGetRequest } from "../../../../common/actions";
import { makePostRequest } from "../../../../common/actions";

const UNINSTALL_SETTINGS_API = "uninstall";
const UPDATE_UNINSTALL_SETTINGS_API = "uninstall";

export function getUninstallSettings(auth, appId, filters) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makeGetRequest(
    UNINSTALL_SETTINGS_API,
    auth,
    appId,
    filters,
    onSuccessCallback,
    onFailureCallback
  );
}

export function updateUninstallSettings(auth, appId, queryParams, filters) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };
  return makePostRequest(
    UPDATE_UNINSTALL_SETTINGS_API,
    auth,
    appId,
    queryParams,
    filters,
    onSuccessCallback,
    onFailureCallback
  );
}
