import React, { useState, useEffect, useMemo } from "react";
import { Button, Grid, Typography, withStyles } from "@material-ui/core";
import "react-vis/dist/style.css";
import TextField from "@material-ui/core/TextField";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import moment from "moment";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Timelapse from "@material-ui/icons/Timelapse";
import {
  DEMO_APP_ID,
  DEMO_START_DATE,
  DEMO_END_DATE,
} from "../../../../config";
import Snackbar from "../../../ReusableComponents/Snackbar";
import ToggleSwitch from "../../../ReusableComponents/ToggleSwitch";
import MultiSelect from "../../../ReusableComponents/MultiSelect";
import DateRangePickerWrapper from "../../../ReusableComponents/DateRangePicker";
import { getTopEventsAPI, getCohorts } from "../../common/actions";
import { useDateFilters } from "../../common/utils";
import { useAuth } from "../../../../contexts/AuthContext";
import {
  getAttributes,
  getEventsAPI,
  getEventAttributesAPI,
} from "../../../common/actions";
import { preprocessConfigsAPI, savePreprocessAPI } from "./actions";
import Box from "../../../ReusableComponents/Box";
import CustomMaterialUIDialog from "../../../ReusableComponents/CustomMaterialUIDialog";

const APX_APP_OPENED = "apx_app_opened";
const APX_UNINSTALL = "apx_uninstall";

const DEFAULT_START_TIME = moment().subtract(4, "days").toISOString();
const DEFAULT_END_TIME = moment().subtract(3, "days").toISOString();

const styles = (theme) => ({
  colorPrimary: {
    color: theme.palette.text.disabled,
    backgroundColor: theme.palette.secondary.main,
  },
});

function Preprocess({ app, classes }) {
  const { app_id: appId } = app;
  const [dateFilters] = useDateFilters();
  const auth = useAuth();

  const [appEvents, setAppEvents] = useState([]);
  const [topEvents, setTopEvents] = useState([]);
  const [cohorts, setCohorts] = useState([]);
  const [attrList, setAttrList] = useState([]);
  const [eventAttributes, setEventAttributes] = useState({});
  const [savedReports, setSavedReports] = useState([]);

  // Component State
  const [name, setName] = useState("Day-0 Events Vs Day-1 Retention");
  const [startTime, setStartTime] = useState(
    appId === DEMO_APP_ID
      ? moment(DEMO_START_DATE).add(14, "days").toISOString()
      : DEFAULT_START_TIME
  );
  const [endTime, setEndTime] = useState(
    appId === DEMO_APP_ID
      ? moment(DEMO_START_DATE).add(24, "days").toISOString()
      : DEFAULT_END_TIME
  );
  const [pre, setPre] = useState({
    min: 0,
    max: 0,
  });
  const [post, setPost] = useState({
    min: 1,
    max: 1,
  });
  const [postEvent, setPostEvent] = useState(APX_APP_OPENED);
  const [events, setEvents] = useState(
    topEvents.slice(0, 20).map((o) => o.key)
  );
  const [attributes, setAtributes] = useState([]);
  const [selectedEventAttrs, setSelectedEventAttrs] = useState([]);
  const [withEventAttributes, setWithEventAttributes] = useState(false);
  const [current, setCurrent] = useState(0);
  const [cohortId, setCohortId] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState("");

  const [failed, setFailed] = useState(false);
  const [preprocessId, setPreprocessId] = useState(null);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const saveConfig = () => {
    const config = {
      name: name,
      startTime,
      endTime,
      preStart: pre.min,
      preEnd: pre.max,
      postStart: post.min,
      postEnd: post.max,
      postEvent,
      events: events,
      withEventAttributes: withEventAttributes,
      attributes: [...attributes],
    };
    if (withEventAttributes) {
      config.events = [events[0]];
      config.attributes = [...attributes, ...selectedEventAttrs];
    }

    setFailed(false);
    setPreprocessId(null);
    savePreprocessAPI(
      auth,
      appId,
      { ...dateFilters, cohortId: cohortId },
      config
    )
      .then((response) => {
        setShowSuccessDialog(true);
        setPreprocessId(response);
      })
      .catch((err) => {
        setShowSuccessDialog(false);
        setFailed(true);
      });
  };

  const handleChange = (event, current) => {
    let postEvent = APX_APP_OPENED;
    let postStart = 1,
      postEnd = 1;
    let name = "Day-0 Events Vs Day-1 Retention";
    if (current === 1) {
      postEvent = APX_UNINSTALL;
      postStart = 0;
      postEnd = 0;
      name = "Day-0 Uninstall Analysis";
    } else if (current === 2) {
      name = "Day 0 Behaviour Vs Conversion";
      postEvent = "";
    } else {
      // FIXME: Uncomment this when Apxor web SDK is integrated
      // Apxor.logEvent(
      //   "ChooseTemplate",
      //   { template_type: "Retention" },
      //   APXOR_SDK_CATEGORY_CONSTANTS.BEHAVIOUR_CORRELATION_FRAMEWORK
      // );
    }
    setCurrent(current);
    setCohortId(null);
    setName(name);
    setPostEvent(postEvent);
    setPost({
      min: postStart,
      max: postEnd,
    });
  };

  const fetchEventAttributes = (event) => {
    if (!event) {
      event = topEvents.slice(0, 1).map((o) => o.key);
    }

    getEventAttributesAPI(auth, appId, {
      ...dateFilters,
      of: "users",
      event: event,
    }).then((response) => {
      setEventAttributes({
        ...eventAttributes,
        [event]: response,
      });
    });
  };

  const eventAttributeOptions = useMemo(
    () =>
      (withEventAttributes
        ? eventAttributes[events[0]] || []
        : []
      ).map((o) => ({ label: o, value: o })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [withEventAttributes, eventAttributes]
  );

  useEffect(() => {
    getTopEventsAPI(
      auth,
      appId,
      {
        // FIXME: It's insane to pass since and till params even though
        //        there is no date filter section available at the top this page
        // FIXME: The problem of passing hard-coded values like this
        //        will result in longer response times because server
        //        is seriously considering these values. Yeah, you read
        //        that right!!. Server somehow needs to handle these
        //        of situations.
        since: "1970-01-01T00:00:00.000Z",
        till: moment().utc().endOf("day").toISOString().slice(0, 23) + "Z",
        cohortId: cohortId,
        of: "event",
        attribute: "apx_event_name",
        group: "all_users",
      },
      { event: [], user: [], session: [] }
    ).then((response) => {
      setTopEvents(response);
      setEvents(response.slice(0, 20).map((o) => o.key));
    });
    getCohorts(auth, appId).then((response) => {
      setCohorts(response);
    });
    getEventsAPI(auth, appId, dateFilters).then((response) => {
      setAppEvents(response);
    });
    getAttributes(auth, appId).then((response) => {
      setAttrList([...response.user, ...response.session]);
    });
    preprocessConfigsAPI(auth, appId, dateFilters).then((response) => {
      setSavedReports(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section class="content" style={{ margin: 8 }}>
      <Box
        title="Choose Template"
        icon={<Timelapse />}
        withPadding
        styles={{ padding: 16 }}
      >
        <CustomMaterialUIDialog
          dialogActions={
            <>
              <Button
                color="primary"
                variant="text"
                onClick={() => {
                  setShowSuccessDialog(false);
                }}
              >
                Ok
              </Button>
            </>
          }
          dialogContent={
            <>
              Analysis report is being generated. It takes a while to display
              the results! Check the report
              <Button
                variant="text"
                color="primary"
                component="a"
                onClick={() => null}
                target="_blank"
                href={`https://reports.apxor.com/apps/${appId}/preprocessed/${preprocessId}`}
                style={{ marginLeft: 5 }}
              >
                here
              </Button>
            </>
          }
          maxWidth={"xs"}
          noTitleBg={true}
          openFlag={!!preprocessId && showSuccessDialog}
          onDialogClose={() => showSuccessDialog(false)}
        />
        {failed && <Snackbar>Failed to submit, try again.</Snackbar>}
        <Box withPadding style={{ padding: 10 }}>
          <AppBar
            position="static"
            classes={{
              colorPrimary: classes.colorPrimary,
            }}
          >
            <Tabs value={current} onChange={handleChange}>
              <Tab label="Retention" />
              <Tab label="Uninstall" />
              <Tab label="Conversion" />
            </Tabs>
          </AppBar>
          <Grid container spacing={4} style={{ marginTop: 16 }}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Name"
                placeholder="Some Name"
                type="text"
                onChange={(e) => setName(e.target.value)}
                value={name}
                style={{ minWidth: 320 }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              md={3}
              style={{ display: "flex", alignItems: "flex-end" }}
            >
              <MultiSelect
                clearable={true}
                handleChange={(e) => setCohortId(e)}
                options={cohorts.map((o) => ({
                  value: o.cohort_id || null,
                  label: o.name,
                }))}
                placeholder="Select Cohort"
                single
                style={{ marginRight: 12 }}
                value={cohortId}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography>Select Cohort Install Dates</Typography>
              <DateRangePickerWrapper
                small
                disableAfter={appId !== DEMO_APP_ID}
                anchorDirection="left"
                startDate={moment(startTime)}
                endDate={moment(endTime)}
                handleChange={(startTime, endTime) => {
                  // Apxor.logEvent(
                  //   "InstallCohortDatesSet",
                  //   {
                  //     start_date: moment(startTime).format("DD-MM-YYYY"),
                  //     end_date: moment(endTime).format("DD-MM-YYYY"),
                  //   },
                  //   APXOR_SDK_CATEGORY_CONSTANTS.BEHAVIOUR_CORRELATION_FRAMEWORK
                  // );
                  setStartTime(startTime);
                  setEndTime(endTime);
                }}
                enabledBetweenDates={
                  appId === DEMO_APP_ID
                    ? [moment(DEMO_START_DATE), moment(DEMO_END_DATE)]
                    : undefined
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <div style={{ padding: "40px 0" }}>
                <Typography style={{ marginBottom: 30 }}>
                  {" "}
                  Pre (Pre Start and Pre End)
                </Typography>
                <InputRange
                  maxValue={90}
                  minValue={0}
                  value={{ min: pre.min, max: pre.max }}
                  onChange={({ min, max }) => {
                    setPre({
                      min,
                      max,
                    });
                  }}
                  allowSameValues
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div style={{ padding: "40px 0" }}>
                <Typography style={{ marginBottom: 30 }}>
                  {" "}
                  Post (Post Start and Post End)
                </Typography>
                <InputRange
                  maxValue={90}
                  minValue={0}
                  value={{ min: post.min, max: post.max }}
                  onChange={({ min, max }) => {
                    setPost({
                      min,
                      max,
                    });
                  }}
                  allowSameValues
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <ToggleSwitch
                handleChange={(withEventAttributes) => {
                  // Apxor.logEvent(
                  //   "WithEventAttributesToggle",
                  //   { selected: withEventAttributes },
                  //   APXOR_SDK_CATEGORY_CONSTANTS.BEHAVIOUR_CORRELATION_FRAMEWORK
                  // );
                  setWithEventAttributes(withEventAttributes);
                  if (withEventAttributes) {
                    if (!eventAttributes.hasOwnProperty(events[0])) {
                      fetchEventAttributes(events[0]);
                    }
                  }
                }}
                label="With Event Attributes"
                checked={withEventAttributes}
                value={withEventAttributes}
              />
            </Grid>
            <Grid item xs={12} md={withEventAttributes ? 4 : 6}>
              <MultiSelect
                withCheckbox={!withEventAttributes}
                options={topEvents.map((o) => ({
                  label: o.key,
                  value: o.key,
                }))}
                placeholder="Select Events"
                value={withEventAttributes ? events[0] : events}
                handleChange={(events) => {
                  // Apxor.logEvent(
                  //   "SelectEventsCount",
                  //   { count: events.length },
                  //   APXOR_SDK_CATEGORY_CONSTANTS.BEHAVIOUR_CORRELATION_FRAMEWORK
                  // );
                  if (withEventAttributes) {
                    setEvents([events]);
                    fetchEventAttributes(events);
                  } else {
                    setEvents(events);
                  }
                }}
                margin="normal"
                fullWidth
                single={withEventAttributes}
              />
            </Grid>
            <Grid item xs={12} md={withEventAttributes ? 4 : 6}>
              <MultiSelect
                options={attrList.map((o) => ({ label: o, value: o }))}
                placeholder="Select Attributes"
                value={attributes}
                handleChange={(attributes) => {
                  // Apxor.logEvent(
                  //   "SelectAttributeCount",
                  //   { count: attributes.length },
                  //   APXOR_SDK_CATEGORY_CONSTANTS.BEHAVIOUR_CORRELATION_FRAMEWORK
                  // );
                  setAtributes(attributes);
                }}
                margin="normal"
                fullWidth
              />
            </Grid>
            {withEventAttributes && (
              <Grid item xs={12} md={4}>
                <MultiSelect
                  options={eventAttributeOptions}
                  placeholder="Select Event Attributes"
                  value={selectedEventAttrs}
                  handleChange={(eventAttributes) => {
                    // Apxor.logEvent(
                    //   "SelectEventAttributesCount",
                    //   { count: eventAttributes.length },
                    //   APXOR_SDK_CATEGORY_CONSTANTS.BEHAVIOUR_CORRELATION_FRAMEWORK
                    // );
                    setSelectedEventAttrs(eventAttributes);
                  }}
                  margin="normal"
                  fullWidth
                />
              </Grid>
            )}
            {!withEventAttributes && (
              <Grid item xs={12} md={2}>
                <TextField
                  label="Select Top N Events"
                  placeholder="10"
                  type="number"
                  onChange={(e) => {
                    const number = Number(e.target.value);
                    if (number > 0) {
                      const events = topEvents
                        .slice(0, number)
                        .map((o) => o.key);
                      // Apxor.logEvent(
                      //   "TopEventCountChanged",
                      //   { count: number },
                      //   APXOR_SDK_CATEGORY_CONSTANTS.BEHAVIOUR_CORRELATION_FRAMEWORK
                      // );
                      setEvents(events);
                    }
                  }}
                  defaultValue={20}
                />
              </Grid>
            )}
            <Grid item xs={12} md={4}>
              <MultiSelect
                placeholder="From Saved Templates"
                options={savedReports.map((o) => ({
                  label: o.name,
                  value: o.report_id,
                }))}
                handleChange={(configId) => {
                  const config = savedReports.find(
                    (o) => o.report_id === configId
                  );
                  if (config) {
                    const events = appEvents.filter((event) =>
                      config.events.includes(event)
                    );
                    // Apxor.logEvent(
                    //   "SavedTemplateChosen",
                    //   {
                    //     template_name: getGroupNameFromList(
                    //       preprocess_configs,
                    //       configId
                    //     ),
                    //   },
                    //   APXOR_SDK_CATEGORY_CONSTANTS.BEHAVIOUR_CORRELATION_FRAMEWORK
                    // );
                    setEvents(events);
                    setSelectedTemplate(configId);
                  }
                }}
                margin="normal"
                value={selectedTemplate || ""}
                single
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MultiSelect
                options={appEvents.map((o) => ({ label: o, value: o }))}
                placeholder="Select Conversion Event"
                value={postEvent}
                handleChange={(postEvent) => {
                  // Apxor.logEvent(
                  //   "SelectConversionEvent",
                  //   { post_event: postEvent },
                  //   APXOR_SDK_CATEGORY_CONSTANTS.BEHAVIOUR_CORRELATION_FRAMEWORK
                  // );
                  setPostEvent(postEvent);
                }}
                single
                margin="normal"
                disabled={current !== 2}
              />
            </Grid>
            <Grid item xs={12} md={6}></Grid>
          </Grid>
          <Button
            style={{ margin: "30px 0 0 auto", display: "block" }}
            variant="contained"
            disabled={false}
            onClick={(e) => {
              // Apxor.logEvent(
              //   "SubmitButtonClicked",
              //   { preStart, preEnd, postStart, postEnd },
              //   APXOR_SDK_CATEGORY_CONSTANTS.BEHAVIOUR_CORRELATION_FRAMEWORK
              // );
              setShowSuccessDialog(false);
              saveConfig();
            }}
            color="primary"
          >
            Submit
          </Button>
        </Box>
      </Box>
    </section>
  );
}

export default withStyles(styles)(Preprocess);
