import React from "react";
import { ANSWER_MODES } from "../../../constants";
import { useTracked } from "../../../store";
import { onModuleSelection } from "../utils";
import MultiChoiceSimulator from "./multiChoiceSimulator";
import RatingSimulator from "./ratingSimulator";
import SingleChoiceSimulator from "./singleChoiceSimulator";
import TextBasedSimulator from "./textBasedSimulator";
import QuestionSimulator from "./QuestionSimulator";
import SurveyContainer from "./SurveyContainer";

export default function SimulatorHolder({
  moduleType,
  setTarget,
  currentQuestion,
}) {
  const [state, dispatch] = useTracked();

  const {
    currentStep,
    ui: { questions = [] },
  } = state;
  const { answer_mode } = currentQuestion;

  let child = <></>;
  switch (answer_mode) {
    case ANSWER_MODES.single_choice:
      child = (
        <SingleChoiceSimulator
          questions={questions}
          currentStep={currentStep}
          moduleType={moduleType}
          onModuleSelection={(e, moduleName) => {
            onModuleSelection(dispatch, moduleName, moduleType);
            setTarget(moduleName === moduleType ? null : e.currentTarget);
          }}
        />
      );
      break;
    case ANSWER_MODES.multi_choice:
      child = (
        <MultiChoiceSimulator
          questions={questions}
          currentStep={currentStep}
          moduleType={moduleType}
          onModuleSelection={(e, moduleName) => {
            onModuleSelection(dispatch, moduleName, moduleType);
            setTarget(moduleName === moduleType ? null : e.currentTarget);
          }}
        />
      );
      break;
    case ANSWER_MODES.text:
      child = (
        <TextBasedSimulator
          questions={questions}
          currentStep={currentStep}
          moduleType={moduleType}
          onModuleSelection={(e, moduleName) => {
            onModuleSelection(dispatch, moduleName, moduleType);
            setTarget(moduleName === moduleType ? null : e.currentTarget);
          }}
        ></TextBasedSimulator>
      );
      break;
    case ANSWER_MODES.rating:
      child = (
        <RatingSimulator
          questions={questions}
          currentStep={currentStep}
          moduleType={moduleType}
          onModuleSelection={(e, moduleName) => {
            onModuleSelection(dispatch, moduleName, moduleType);
            setTarget(moduleName === moduleType ? null : e.currentTarget);
          }}
        />
      );
      break;
    case ANSWER_MODES.success_message:
    case ANSWER_MODES.request_survey:
      child = (
        <SurveyContainer
          currentQuestion={questions[currentStep]}
          moduleType={moduleType}
          onModuleSelection={onModuleSelection}
        >
          <QuestionSimulator
            {...questions[currentStep]}
            index={currentStep}
            totalQuestions={questions.length}
            isCanvas={true}
          />
        </SurveyContainer>
      );
      break;
    default:
      break;
  }
  return child;
}
