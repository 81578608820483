import {
  Checkbox,
  FormControlLabel,
  Grid,
  withStyles,
} from "@material-ui/core";
import React from "react";
import ActionsTextField from "../../../../../../../../ReusableComponents/ActionsTextField";
import CustomColorPicker from "../../../../../../../../ReusableComponents/CustomColorPicker";
import ExpansionModuleConfig from "../../../../../../../../ReusableComponents/ExpansionModuleConfig";
import ModuleConfigSection from "../../../../../../../../ReusableComponents/ModuleConfigSection";
import MultiSelect from "../../../../../../../../ReusableComponents/MultiSelect";
import {
  SET_ONBOARDING_ANIM_DURATION,
  SET_ONBOARDING_ANIM_TYPE,
  SET_ONBOARDING_BOTTOM_PAD,
  SET_ONBOARDING_BTN_COLOR,
  SET_ONBOARDING_BTN_FONT_FAMILY,
  SET_ONBOARDING_BTN_FONT_SIZE,
  SET_ONBOARDING_BTN_FONT_STYLE,
  SET_ONBOARDING_BTN_TEXT,
  SET_ONBOARDING_DISMISS_TYPE,
  SET_ONBOARDING_DOTS_ACTIVE_COLOR,
  SET_ONBOARDING_DOTS_INACTIVE_COLOR,
  SET_ONBOARDING_FADEOUT_OPACITY,
  SET_ONBOARDING_HORIZONTAL_MARGIN,
  SET_ONBOARDING_LAST_STEP_TEXT,
  SET_ONBOARDING_LEFT_PAD,
  SET_ONBOARDING_NEXT_PREV_ENABLED,
  SET_ONBOARDING_PAGINATION_TYPE,
  SET_ONBOARDING_RIGHT_PAD,
  SET_ONBOARDING_SKIP_ENABLED,
  SET_ONBOARDING_TOP_PAD,
  SET_ONBOARDING_VERTICAL_MARGIN,
} from "../../../constants";
import { useTracked } from "../../../store";
import OnboardingGlobalConfigSimulator from "../../simulator/components/OnboardingGlobalConfigSimulator";
import { moduleConfigStyles } from "../utils";
import OnboardingButtonConfig from "./TextConfig";

const ONBOARDING_PAGINATION_ENUM = [
  { label: "Numbers with Carousel indicators", value: "number_carousal" },
  { label: "Numbers", value: "numbers" },
  { label: "Carousal", value: "carousal" },
];
const ONBOARDING_DISMISS_ENUM = [
  { label: "swipe", value: "swipe" },
  { label: "tap", value: "tap" },
];
const ONBOARDING_ANIMATION_ENUM = [
  { label: "Transition", value: "tran" },
  { label: "Fade Out", value: "fade_out" },
];

const ONBOARDING_ACTIONS = {
  SET_TEXT: SET_ONBOARDING_BTN_TEXT,
  SET_COLOR: SET_ONBOARDING_BTN_COLOR,
  SET_FONT_FAMILY: SET_ONBOARDING_BTN_FONT_FAMILY,
  SET_FONT_SIZE: SET_ONBOARDING_BTN_FONT_SIZE,
  SET_FONT_STYLE: SET_ONBOARDING_BTN_FONT_STYLE,
};

const getPaginationDefaults = (onboardingState) => {
  if (
    onboardingState.has_counter &&
    onboardingState.carousal &&
    onboardingState.carousal.dots
  )
    return "number_carousal";
  else if (
    onboardingState.has_counter &&
    onboardingState.carousal &&
    !onboardingState.carousal.dots
  )
    return "numbers";
  else return "carousal";
};

// FIXME: Whoever uses this component, must dispatch the
//        `UPDATE_ONBOARDING_STATE_IN_UIS` action.
//        Otherwise, the changes will be lost
function OnboardingConfiguration({ classes }) {
  const [state, dispatch] = useTracked();
  const { onboardingState } = state;

  return (
    <Grid container>
      <Grid
        item
        xs={6}
        style={{
          backgroundColor: "#23282C",
          border: "5px solid #F0F2F4",
        }}
      >
        <OnboardingGlobalConfigSimulator />
      </Grid>
      <Grid item xs={6} style={{ overflowX: "hidden", overflowY: "scroll" }}>
        <Grid container style={{ maxHeight: 500 }}>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <ExpansionModuleConfig
                title="Gloal Design Configuration (Applies to all steps)"
                defaultExpanded={true}
              >
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <ModuleConfigSection
                      title="Pagination type"
                      typographyClassName={classes.moduleConfigRoot}
                    >
                      <MultiSelect
                        clearable={false}
                        options={ONBOARDING_PAGINATION_ENUM}
                        value={getPaginationDefaults(onboardingState)}
                        single
                        handleChange={(value) => {
                          dispatch({
                            type: SET_ONBOARDING_PAGINATION_TYPE,
                            value: value,
                          });
                        }}
                      />
                    </ModuleConfigSection>
                  </Grid>
                  <Grid item xs={4}>
                    <ModuleConfigSection
                      title="Horizontal margin"
                      typographyClassName={classes.moduleConfigRoot}
                    >
                      <ActionsTextField
                        value={onboardingState.carousal.m_margin.hz}
                        style={{
                          marginBottom: 0,
                        }}
                        onChange={(e) => {
                          dispatch({
                            type: SET_ONBOARDING_HORIZONTAL_MARGIN,
                            value: Number(e.target.value),
                          });
                        }}
                        type="number"
                      />
                    </ModuleConfigSection>
                  </Grid>
                  <Grid item xs={4}>
                    <ModuleConfigSection
                      title="Vertical margin"
                      typographyClassName={classes.moduleConfigRoot}
                    >
                      <ActionsTextField
                        value={onboardingState.carousal.m_margin.vr}
                        style={{
                          marginBottom: 0,
                        }}
                        onChange={(e) => {
                          dispatch({
                            type: SET_ONBOARDING_VERTICAL_MARGIN,
                            value: Number(e.target.value),
                          });
                        }}
                        type="number"
                      />
                    </ModuleConfigSection>
                  </Grid>
                  <Grid item xs={4}>
                    <ModuleConfigSection
                      title="Animation type"
                      typographyClassName={classes.moduleConfigRoot}
                    >
                      <MultiSelect
                        clearable={false}
                        single
                        options={ONBOARDING_ANIMATION_ENUM}
                        value={onboardingState.anim_type}
                        handleChange={(value) => {
                          dispatch({
                            type: SET_ONBOARDING_ANIM_TYPE,
                            value: value,
                          });
                        }}
                      />
                    </ModuleConfigSection>
                  </Grid>
                  <Grid item xs={4}>
                    <ModuleConfigSection
                      title="Animation Duration"
                      typographyClassName={classes.moduleConfigRoot}
                    >
                      <ActionsTextField
                        style={{ maxWidth: 180 }}
                        value={onboardingState.duration / 1000}
                        onChange={(e) => {
                          dispatch({
                            type: SET_ONBOARDING_ANIM_DURATION,
                            value: Number(e.target.value) * 1000,
                          });
                        }}
                        InputProps={{
                          type: "number",
                        }}
                        type="number"
                      />
                    </ModuleConfigSection>
                  </Grid>
                  <Grid item xs={4}>
                    {onboardingState.anim_type === "fade_out" && (
                      <ModuleConfigSection
                        title="Fadeout Opacity"
                        typographyClassName={classes.moduleConfigRoot}
                      >
                        <ActionsTextField
                          key="opacity_fadeout"
                          title="Fadeout Opacity"
                          InputProps={{
                            min: 0,
                            max: 1,
                            step: "0.1",
                            type: "number",
                          }}
                          value={onboardingState.carousal.f_alpha}
                          onChange={(e) => {
                            dispatch({
                              type: SET_ONBOARDING_FADEOUT_OPACITY,
                              value: Number(e.target.value),
                            });
                          }}
                          type="number"
                        />
                      </ModuleConfigSection>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <ModuleConfigSection
                      title="Gesture to go to next step"
                      typographyClassName={classes.moduleConfigRoot}
                    >
                      <MultiSelect
                        clearable={false}
                        single
                        options={ONBOARDING_DISMISS_ENUM}
                        value={onboardingState.carousal.d_type}
                        handleChange={(value) => {
                          dispatch({
                            type: SET_ONBOARDING_DISMISS_TYPE,
                            value: value,
                          });
                        }}
                      />
                    </ModuleConfigSection>
                  </Grid>
                  <Grid item xs={6}>
                    <ModuleConfigSection
                      title="Last step text"
                      typographyClassName={classes.moduleConfigRoot}
                    >
                      <ActionsTextField
                        value={onboardingState.lastStepText}
                        multiline={false}
                        onChange={(e) => {
                          dispatch({
                            type: SET_ONBOARDING_LAST_STEP_TEXT,
                            value: e.target.value,
                          });
                        }}
                      />
                    </ModuleConfigSection>
                  </Grid>
                </Grid>
                <Grid container spacing={1} style={{ paddingLeft: 10 }}>
                  {onboardingState.carousal.dots && (
                    <>
                      <Grid item xs={6}>
                        <ModuleConfigSection
                          title="Carousel indicator (active)"
                          typographyClassName={classes.moduleConfigRoot}
                        >
                          <CustomColorPicker
                            color={onboardingState.carousal.d_acolor}
                            handleColorChange={(color) => {
                              dispatch({
                                type: SET_ONBOARDING_DOTS_ACTIVE_COLOR,
                                value: color.hex,
                              });
                            }}
                          />
                        </ModuleConfigSection>
                      </Grid>
                      <Grid item xs={6}>
                        <ModuleConfigSection
                          title="Carousel indicator (inactive)"
                          typographyClassName={classes.moduleConfigRoot}
                        >
                          <CustomColorPicker
                            color={onboardingState.carousal.d_icolor}
                            handleColorChange={(color) => {
                              dispatch({
                                type: SET_ONBOARDING_DOTS_INACTIVE_COLOR,
                                value: color.hex,
                              });
                            }}
                          />
                        </ModuleConfigSection>
                      </Grid>
                    </>
                  )}
                </Grid>
              </ExpansionModuleConfig>
              {getPaginationDefaults(onboardingState) !==
                ONBOARDING_PAGINATION_ENUM[1].label && (
                <>
                  <ExpansionModuleConfig
                    title="Padding for Carousal Indicators"
                    iconStyles={{ color: "#FF7F33" }}
                    rootStyles={{ border: 0 }}
                    defaultExpanded={true}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={3}>
                        <ModuleConfigSection
                          title="Top"
                          typographyClassName={classes.moduleConfigRoot}
                        >
                          <ActionsTextField
                            key="padding_top"
                            value={onboardingState.carousal.d_pad.top}
                            style={{
                              marginBottom: 0,
                            }}
                            onChange={(e) => {
                              dispatch({
                                type: SET_ONBOARDING_TOP_PAD,
                                value: Number(e.target.value),
                              });
                            }}
                            type="number"
                          />
                        </ModuleConfigSection>
                      </Grid>
                      <Grid item xs={3}>
                        <ModuleConfigSection
                          title="Left"
                          typographyClassName={classes.moduleConfigRoot}
                        >
                          <ActionsTextField
                            value={onboardingState.carousal.d_pad.left}
                            style={{
                              marginBottom: 0,
                            }}
                            onChange={(e) => {
                              dispatch({
                                type: SET_ONBOARDING_LEFT_PAD,
                                value: Number(e.target.value),
                              });
                            }}
                            type="number"
                          />
                        </ModuleConfigSection>
                      </Grid>
                      <Grid item xs={3}>
                        <ModuleConfigSection
                          title="Bottom"
                          typographyClassName={classes.moduleConfigRoot}
                        >
                          <ActionsTextField
                            value={onboardingState.carousal.d_pad.bottom}
                            style={{
                              marginBottom: 0,
                            }}
                            onChange={(e) => {
                              dispatch({
                                type: SET_ONBOARDING_BOTTOM_PAD,
                                value: Number(e.target.value),
                              });
                            }}
                            type="number"
                          />
                        </ModuleConfigSection>
                      </Grid>
                      <Grid item xs={3}>
                        <ModuleConfigSection
                          title="Right"
                          typographyClassName={classes.moduleConfigRoot}
                        >
                          <ActionsTextField
                            value={onboardingState.carousal.d_pad.right}
                            style={{
                              marginBottom: 0,
                            }}
                            onChange={(e) => {
                              dispatch({
                                type: SET_ONBOARDING_RIGHT_PAD,
                                value: Number(e.target.value),
                              });
                            }}
                            type="number"
                          />
                        </ModuleConfigSection>
                      </Grid>
                    </Grid>
                  </ExpansionModuleConfig>
                </>
              )}
              <Grid container spacing={1} style={{ paddingLeft: 10 }}>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={onboardingState.skipEnabled}
                        onChange={(e) => {
                          dispatch({
                            type: SET_ONBOARDING_SKIP_ENABLED,
                            value: e.target.checked,
                          });
                        }}
                      />
                    }
                    label="Include skip to all"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={onboardingState.nextPrevEnabled}
                        onChange={(e) => {
                          dispatch({
                            type: SET_ONBOARDING_NEXT_PREV_ENABLED,
                            value: e.target.checked,
                          });
                        }}
                      />
                    }
                    label="Include next and previous"
                  />
                </Grid>
              </Grid>
              {onboardingState.has_counter && (
                <OnboardingButtonConfig
                  title="Counter Configuration"
                  keyName="counter"
                  config={onboardingState.counter}
                  actions={ONBOARDING_ACTIONS}
                />
              )}
              {onboardingState.nextPrevEnabled &&
                onboardingState.next &&
                onboardingState.prev && (
                  <>
                    <OnboardingButtonConfig
                      title="Next Button"
                      keyName="next"
                      config={onboardingState.next}
                      actions={ONBOARDING_ACTIONS}
                    />
                    <OnboardingButtonConfig
                      title="Previous Button"
                      keyName="prev"
                      config={onboardingState.prev}
                      actions={ONBOARDING_ACTIONS}
                    />
                  </>
                )}
              {onboardingState.skipEnabled && onboardingState.skip && (
                <>
                  <OnboardingButtonConfig
                    title="Skip Button"
                    keyName="skip"
                    config={onboardingState.skip}
                    actions={ONBOARDING_ACTIONS}
                  />
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(moduleConfigStyles)(OnboardingConfiguration);
