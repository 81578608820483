import { makePostRequest } from "../../../../../common/actions";

const SURVEYS_RESULTS_API = (surveyId) => `surveys/${surveyId}/results`;

export function surveyResultsAPI(auth, appId, surveyId, queryParams, body) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };

  return makePostRequest(
    SURVEYS_RESULTS_API(surveyId),
    auth,
    appId,
    queryParams,
    body,
    onSuccessCallback,
    onFailureCallback
  );
}

export function surveyResultForOtherOptionAPI(
  auth,
  appId,
  surveyId,
  queryParams,
  body
) {
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return {};
  };

  return makePostRequest(
    SURVEYS_RESULTS_API(surveyId) + "/others",
    auth,
    appId,
    queryParams,
    body,
    onSuccessCallback,
    onFailureCallback
  );
}
