import React from "react";
import { replaceMacrosWithDefault } from "../../../../../../../../../utils";
import { ACTION_CLASSES, TOOLTIP_LAYOUT_TYPES } from "../../../constants";
import { useDispatch } from "../../../store";
import { onModuleSelection } from "../utils";
import CoachmarkSimulator from "./coachmarksimulator";
import InAppSimulator from "./inappSimulator";
import NudgeSimulator from "./nudgeSimulator";
import TooltipSimulator from "./tooltipsimulator";

export default function SimulatorHolder({
  moduleType,
  orientation,
  setTarget,
  ui,
  type,
}) {
  const dispatch = useDispatch();

  const { action_class, layout_type } = ui;

  let child = <></>;
  switch (action_class) {
    case ACTION_CLASSES.COACHMARK:
      child = (
        <CoachmarkSimulator
          moduleType={moduleType}
          onModuleSelection={(e, moduleName) => {
            onModuleSelection(dispatch, moduleName, moduleType);
            setTarget(moduleName === moduleType ? null : e.currentTarget);
          }}
          ui={ui}
        />
      );
      break;
    case ACTION_CLASSES.INAPP:
      child = (
        <InAppSimulator
          moduleType={moduleType}
          onModuleSelection={(e, moduleName) => {
            onModuleSelection(dispatch, moduleName, moduleType);
            setTarget(moduleName === moduleType ? null : e.currentTarget);
          }}
          ui={ui}
          type={type}
        />
      );
      break;
    case ACTION_CLASSES.NUDGE:
      child = <NudgeSimulator ui={ui} />;
      break;
    case ACTION_CLASSES.TOOLTIP:
      if (layout_type === TOOLTIP_LAYOUT_TYPES.HTML_OVERLAY) {
        const {
          dim_background,
          web: { data },
        } = ui;
        const defaultSimulatorWidth = orientation === "Portrait" ? 270 : 540;
        const defaultSimulatorHeight = orientation === "Portrait" ? 540 : 270;
        child = (
          <div
            style={{
              overflow: "hidden",
              width: defaultSimulatorWidth,
              height: defaultSimulatorHeight,
              borderRadius: 16,
              backgroundColor: dim_background
                ? "rgb(51, 51, 51, 0.8)"
                : "rgb(255, 255, 255, 1)",
              justifyContent: "flex-start",
              display: "flex",
              flexDirection: "column",
              backgroundImage:
                "linear-gradient(45deg, rgb(128, 128, 128) 25%, transparent 25%), linear-gradient(-45deg, rgb(128, 128, 128) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, rgb(128, 128, 128) 75%), linear-gradient(-45deg, transparent 75%, rgb(128, 128, 128) 75%)",
              backgroundSize: "20px 20px",
              backgroundPosition: "0px 0px, 0px 10px, 10px -10px, -10px 0px",
            }}
          >
            <div
              style={{ width: "100%", height: "585px" }}
              dangerouslySetInnerHTML={{
                __html: replaceMacrosWithDefault(data),
              }}
            />
          </div>
        );
      } else {
        child = (
          <TooltipSimulator
            moduleType={moduleType}
            ui={ui}
            onModuleSelection={(e, moduleName) => {
              onModuleSelection(dispatch, moduleName, moduleType);
              setTarget(moduleName === moduleType ? null : e.currentTarget);
            }}
          />
        );
      }
      break;
    default:
      break;
  }
  return child;
}
