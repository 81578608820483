import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import "./GraphCss.css";
import { getCampaignExposedAPI } from "../actions";
import { useAuth } from "../../../../../contexts/AuthContext";
import { isDemoApplicaton, isExperienceDashboard } from "../../../../../config";

const D1_RETENTION = "d1-retention";
const D0_UNINSTALL = "d0-uninstall";
const AVG_SESSIONS = "avg-sessions";
const AVG_SESSION_LENGTH = "avg-sessionlength";
const VARIANT = "/variant";

const getMetricAPI = (metric, isExperiment) => {
  return isExperiment ? metric + VARIANT : metric;
};

const getRetentionMetric = (data, metricType = "overall") => {
  const key = metricType === "overall" ? "overall" : "campaign";
  const value = (data[key]?.day1 * 100) / data[key]?.day0;
  return isNaN(value) ? 0 : value.toFixed(2);
};

const getUninstallMetric = (data, metricType = "overall") => {
  const key = metricType === "overall" ? "overall" : "campaign";
  const value = (data[key]?.uninstall * 100) / data[key]?.install;
  return isNaN(value) ? 0 : value.toFixed(2);
};

const getAvgSessionsMetric = (data, metricType = "overall") => {
  const key = metricType === "overall" ? "all" : "campaign";
  const value = data[key];
  return isNaN(value) ? 0 : value.toFixed(2);
};

const getAvgSessionsTimeMetric = (data, metricType = "overall") => {
  const key = metricType === "overall" ? "all" : "campaign";
  const value = data[key];
  return isNaN(value) ? 0 : value.toFixed(2);
};

export default function Graphs({
  appId,
  dateFilters,
  isDashboard = true,
  campaignId,
  isExperiment,
  winner_variant_suggested,
}) {
  const auth = useAuth();

  const [d1_campaign, setD1_campaign] = useState({});
  const [d0_campaign, setD0_campaign] = useState({});
  const [session_length_campaign, setSession_length_campaign] = useState({});
  const [session_campaign, setSession_campaign] = useState({});
  const day_1_retention = [
    {
      name: "Day 1 Retention",
      overall: getRetentionMetric(d1_campaign, "overall"),
      campaign: getRetentionMetric(d1_campaign, "campaign"),
      unitSuffix: "%",
    },
  ];

  const day_0_uninstalls = [
    {
      name: "Day 0 Uninstalls",
      overall: getUninstallMetric(d0_campaign, "overall"),
      campaign: getUninstallMetric(d0_campaign, "campaign"),
      unitSuffix: "%",
    },
  ];

  const avg_sessions_per_user = [
    {
      name: "Avg. Sessions per User",
      overall: getAvgSessionsMetric(session_campaign, "overall"),
      campaign: getAvgSessionsMetric(session_campaign, "campaign"),
    },
  ];

  const avg_session_length_per_user = [
    {
      name: "Avg. Session Length per User",
      overall: getAvgSessionsTimeMetric(session_length_campaign, "overall"),
      campaign: getAvgSessionsTimeMetric(session_length_campaign, "campaign"),
      unitSuffix: "s",
    },
  ];

  const textStyle = {
    fontFamily: "Manrope",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "11px",
    lineHeight: "15px",
    letterSpacing: "0.01em",
  };

  useEffect(() => {
    const additionalParams = isDashboard
      ? {}
      : isExperiment
      ? { variantCode: winner_variant_suggested, messageId: campaignId }
      : { messageId: campaignId };
    if (isExperienceDashboard() && isDemoApplicaton(appId)) {
      setD0_campaign({
        overall: {
          install: 59745,
          uninstall: 5745,
        },
        campaign: {
          install: 5553,
          uninstall: 118,
        },
      });
      setD1_campaign({
        overall: {
          day0: 59511,
          day1: 29630,
        },
        campaign: {
          day0: 5553,
          day1: 3642,
        },
      });
      setSession_campaign({
        all: 25.709636343954923,
        campaign: 74.04751294772387,
      });
      setSession_length_campaign({
        all: 6273,
        campaign: 17657,
      });
    } else {
      getCampaignExposedAPI(
        auth,
        appId,
        { ...dateFilters(-1, 29), ...additionalParams },
        getMetricAPI(D0_UNINSTALL, isExperiment),
      ).then((res) => {
        setD0_campaign(res);
      });
      getCampaignExposedAPI(
        auth,
        appId,
        { ...dateFilters(-1, 29), ...additionalParams },
        getMetricAPI(D1_RETENTION, isExperiment),
      ).then((res) => {
        setD1_campaign(res);
      });
      getCampaignExposedAPI(
        auth,
        appId,
        { ...dateFilters(-1, 29), ...additionalParams },
        getMetricAPI(AVG_SESSIONS, isExperiment),
      ).then((res) => {
        setSession_campaign(res);
      });
      getCampaignExposedAPI(
        auth,
        appId,
        { ...dateFilters(-1, 29), ...additionalParams },
        getMetricAPI(AVG_SESSION_LENGTH, isExperiment),
      ).then((res) => {
        setSession_length_campaign(res);
      });
    }
  }, []);

  function CustomChartTooltip({ active, payload }) {
    if (active && payload?.length) {
      return (
        <ul
          style={{
            backgroundColor: "#fff",
            border: "1px solid #E4E7E9",
            borderRadius: "3px",
            boxShadow: "0px 4px 24px 0px rgba(0, 40, 69, 0.17)",
            zIndex: "10",
            padding: "12px",
            minWidth: "179px",
            minHeight: "72px",
            fontSize: "12px",
          }}
        >
          {["overall", "campaign"].map((key, index) => (
            <li
              key={index}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginBottom: key === "overall" ? "4px" : "0px",
              }}
            >
              <Typography
                style={{
                  fontWeight: "500",
                  color: key === "overall" ? "#002845" : "#3895EC",
                }}
              >
                {key.charAt(0).toUpperCase() + key.slice(1)}
              </Typography>
              <Typography
                style={{
                  backgroundColor: "#E5E9EC",
                  borderRadius: "1px",
                  padding: "4px",
                  fontWeight: "700",
                  minWidth: "40%",
                }}
              >
                {payload[0].payload[key]}
                {payload[0].payload.unitSuffix}
              </Typography>
            </li>
          ))}
        </ul>
      );
    }
    return null;
  }

  const BarGraph = (data, tooltip_left = false) => {
    return (
      <BarChart
        width={313}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        style={{
          padding: 17,
          background: "#FFFFFF",
          border: "1px solid #EAECEE",
          borderRadius: 2,
        }}
      >
        <CartesianGrid
          strokeDasharray="3 3"
          style={{
            width: "100%",
            height: "80%",
          }}
        />
        <XAxis
          dataKey="name"
          type="category"
          allowDuplicatedCategory={true}
          orientation="bottom"
        />
        <YAxis
          tickFormatter={(tick) => `${tick}${data[0]?.unitSuffix ?? ""}`}
          styles={{
            fontFamily: "Manrope",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "11px",
            lineHeight: "15px",
            letterSpacing: "0.01em",
            color: "#002845",
            opacity: "0.45",
          }}
        />
        <Tooltip
          content={<CustomChartTooltip />}
          position={{
            x: tooltip_left ? -51 : 190,
            y: 70,
          }}
          isAnimationActive={false}
          isUpdateAnimationActive={false}
        />

        <Bar dataKey="overall" barSize={30} fill="#002845" />
        <Bar dataKey="campaign" barSize={30} fill="#3897EF" />
      </BarChart>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          style={{
            fontFamily: "Manrope",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "18px",
            lineHeight: "25px",
            letterSpacing: "0.01em",
            textTransform: "uppercase",
            color: "#002845",
            position: "relative",
            left: 10,
            paddingTop: 30,
            paddingBottom: 5,
          }}
        >
          Metrics
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid
            item
            md={3}
            style={{
              margin: "unset",
              justifyContent: "center",
              display: "flex",
            }}
          >
            {BarGraph(day_1_retention)}
          </Grid>
          <Grid
            item
            md={3}
            style={{
              margin: "unset",
              justifyContent: "center",
              display: "flex",
            }}
          >
            {BarGraph(day_0_uninstalls)}
          </Grid>
          <Grid
            item
            md={3}
            style={{
              margin: "unset",
              justifyContent: "center",
              display: "flex",
            }}
          >
            {BarGraph(avg_sessions_per_user)}
          </Grid>
          <Grid
            item
            md={3}
            style={{
              margin: "unset",
              justifyContent: "center",
              display: "flex",
            }}
          >
            {BarGraph(avg_session_length_per_user, true)}
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12}>
        {isDashboard && (
          <Typography
            style={{
              ...textStyle,
              fontWeight: "bold",
              fontSize: "18px",
              lineHeight: "25px",
              letterSpacing: "0.01em",
              position: "relative",
              left: "41%",
              top: 3,
              display: "inline",
            }}
          >
            Past Month Data
          </Typography>
        )}
        <Typography
          style={{
            color: "#002845",
            display: "inline",
            padding: 20,
            position: "relative",
            left: "42%",
            ...textStyle,
          }}
        >
          {"\u2B24"} All Users
        </Typography>
        <Typography
          style={{
            color: "#3897EF",
            display: "inline",
            padding: 20,
            position: "relative",
            left: "42%",
            ...textStyle,
          }}
        >
          {"\u2B24"} Campaign Variant
        </Typography>
      </Grid>
    </Grid>
  );
}

