import React, { useState } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import { isExperienceDashboard } from "../../../config";

export function useDateFilters(isDefault = false) {
  const [dateFilters, setDateFilters] = useState(
    (isDefault
      ? null
      : JSON.parse(localStorage.getItem("filters") || null)) || {
      since:
        moment()
          .add(isDefault ? -90 : -6, "days")
          .utc()
          .startOf("day")
          .toISOString()
          .slice(0, 23) + "Z",
      till:
        moment().add(-1, "day").utc().endOf("day").toISOString().slice(0, 23) +
        "Z",
    }
  );
  return [dateFilters, setDateFilters];
}

export const IconImageComponent = (imageSrc, imageAlt, styles = {}) => {
  const style = {
    width: 43,
    height: 77,
  };

  return <img src={imageSrc} alt={imageAlt} style={{ ...style, ...styles }} />;
};

export const useTypographyStyles = makeStyles((theme) => ({
  h5_800: (props) => ({
    fontWeight: 800,
    fontSize: 16,
  }),
  h5_600: (props) => ({
    fontWeight: 600,
    fontSize: 16,
  }),
  h6_800: (props) => ({
    fontWeight: 800,
    fontSize: 14,
  }),
}));

export const base64ToBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const encode = (key, data) => {
  if (!data) {
    return null;
  }

  data = xor_encrypt(key, data);
  return b64_encode(data);
};

export const decode = (key, data) => {
  data = b64_decode(data);
  return xor_decrypt(key, data);
};

const b64_table =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

function b64_encode(data) {
  var o1,
    o2,
    o3,
    h1,
    h2,
    h3,
    h4,
    bits,
    r,
    i = 0,
    enc = "";
  if (!data) {
    return data;
  }
  do {
    o1 = data[i++];
    o2 = data[i++];
    o3 = data[i++];
    bits = (o1 << 16) | (o2 << 8) | o3;
    h1 = (bits >> 18) & 0x3f;
    h2 = (bits >> 12) & 0x3f;
    h3 = (bits >> 6) & 0x3f;
    h4 = bits & 0x3f;
    enc +=
      b64_table.charAt(h1) +
      b64_table.charAt(h2) +
      b64_table.charAt(h3) +
      b64_table.charAt(h4);
  } while (i < data.length);
  r = data.length % 3;
  return (r ? enc.slice(0, r - 3) : enc) + "===".slice(r || 3);
}

function b64_decode(data) {
  var o1,
    o2,
    o3,
    h1,
    h2,
    h3,
    h4,
    bits,
    i = 0,
    result = [];
  if (!data) {
    return data;
  }
  data += "";
  do {
    h1 = b64_table.indexOf(data.charAt(i++));
    h2 = b64_table.indexOf(data.charAt(i++));
    h3 = b64_table.indexOf(data.charAt(i++));
    h4 = b64_table.indexOf(data.charAt(i++));
    bits = (h1 << 18) | (h2 << 12) | (h3 << 6) | h4;
    o1 = (bits >> 16) & 0xff;
    o2 = (bits >> 8) & 0xff;
    o3 = bits & 0xff;
    result.push(o1);
    if (h3 !== 64) {
      result.push(o2);
      if (h4 !== 64) {
        result.push(o3);
      }
    }
  } while (i < data.length);
  return result;
}

function keyCharAt(key, i) {
  return key.charCodeAt(Math.floor(i % key.length));
}

function xor_encrypt(key, data) {
  return Array.from(data).map(function (c, i) {
    return c.charCodeAt(0) ^ keyCharAt(key, i);
  });
}

function xor_decrypt(key, data) {
  return Array.from(data)
    .map(function (c, i) {
      return String.fromCharCode(c ^ keyCharAt(key, i));
    })
    .join("");
}

export const CAMPAIGNS_HOLDER_TABS = {
  ...(isExperienceDashboard() && { GUIDED_TOUR: "GUIDED TOUR" }),
  ACTIVE: "ACTIVE",
  PAUSED: "PAUSED",
  SCHEDULED: "SCHEDULED",
  COMPLETED: "COMPLETED",
  DRAFTS: "DRAFT",
};

export const STATUS_PALETTE = {
         [CAMPAIGNS_HOLDER_TABS.ACTIVE]: {
           label: CAMPAIGNS_HOLDER_TABS.ACTIVE,
           color: "#469702",
           backgroundColor: "#FFFFFF",
         },
         [CAMPAIGNS_HOLDER_TABS.SCHEDULED]: {
           label: CAMPAIGNS_HOLDER_TABS.SCHEDULED,
           color: "#366DC0",
           backgroundColor: "#FFFFFF",
         },
         [CAMPAIGNS_HOLDER_TABS.PAUSED]: {
           label: CAMPAIGNS_HOLDER_TABS.PAUSED,
           color: "#DDA502",
           backgroundColor: "#FFFFFF",
         },
         [CAMPAIGNS_HOLDER_TABS.DRAFTS]: {
           label: "Draft",
           color: "#ADBAC3",
           backgroundColor: "#FFFFFF",
         },
         [CAMPAIGNS_HOLDER_TABS.COMPLETED]: {
           label: "Completed",
           color: "#ADBAC3",
           backgroundColor: "#FFFFFF",
         },
       };

export function getCampaignStatus(campaignConfig) {
  return campaignConfig?.state || CAMPAIGNS_HOLDER_TABS.DRAFTS.toUpperCase()
}


/**
 * Leaving this code commented as these caluculations achieved a WIKI status
 */
// export function getCampaignStatus(campaignConfig) {
//   const enabled = campaignConfig.enabled,
//     published = campaignConfig.published,
//     startDate = moment(campaignConfig.validity.start_date),
//     endDate = moment(campaignConfig.validity.end_date);
//   const now = moment();
//   if (now.isAfter(endDate)) return CAMPAIGNS_HOLDER_TABS.COMPLETED;
//   else if (
//     campaignConfig.apx_status &&
//     campaignConfig.apx_status === "AB_Test_Published"
//   )
//     return CAMPAIGNS_HOLDER_TABS.EXPERIMENT_ACTIVE;
//   else if (
//     campaignConfig.apx_status &&
//     campaignConfig.apx_status === "AB_Campaign_Published"
//   )
//     return CAMPAIGNS_HOLDER_TABS.AB_CAMPAIGN_PUBLISHED;
//   else if (!published) return CAMPAIGNS_HOLDER_TABS.DRAFTS;
//   else if (enabled && published && now.isBetween(startDate, endDate))
//     return CAMPAIGNS_HOLDER_TABS.ACTIVE;
//   else if (!enabled && published && now.isBetween(startDate, endDate))
//     return CAMPAIGNS_HOLDER_TABS.PAUSED;
//   else return CAMPAIGNS_HOLDER_TABS.ACTIVE;
// }

