import React, { useEffect, useState } from "react";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import Apps from "../App";
import Dashboard from "../Dashboard";
import { useAuth } from "../../contexts/AuthContext";
import Profile from "../Profile";
import Apxor from "apxor";
import { DEMO_ECOMMERCE_APP_ID, isExperienceDashboard } from "../../config";
import IntegratePage from "../IntegrationExperience/integrate/pages/IntegratePage";
import AddNewAppPage from "../IntegrationExperience/add-application/pages/AddNewAppPage";
import TestConnectionsPage from "../IntegrationExperience/test-connection/pages/TestConnectionsPage";
import { useDeepCompareEffect } from "../../utils/use-deep-compare";

const AuthorizedRoute = (props) => {
  const { component: Component, pending, logged, ...rest } = props;
  const auth = useAuth();
  const { pathname } = useLocation();
  const [prevPathName, setPrevPathName] = useState();
  const [userId, setUserId] = useState("");

  useEffect(() => {
    if (!prevPathName || pathname !== prevPathName) {
      Apxor.logPageView(pathname);
      setPrevPathName(pathname);
    }
  }, [pathname, prevPathName]);

  useDeepCompareEffect(() => {
    if (userId === "" || (auth && auth?.user && auth?.user?.email !== userId)) {
      Apxor.setUserId(auth?.user?.email);
      setUserId(auth?.user?.email);
    }
  }, [auth]);

  return (
    <Route
      {...rest}
      render={() => {
        if (pending) return <div>Loading...</div>;
        return auth &&
          auth.user &&
          auth.user.email &&
          auth.user.email !== "" ? (
          <>
            <Switch>
              <Route exact path="/apps/create" component={AddNewAppPage} />
              <Route exact path="/profile">
                {isExperienceDashboard() ? (
                  <Redirect to={`/apps/${DEMO_ECOMMERCE_APP_ID}/dashboard`} />
                ) : (
                  <Route exact path="/profile" component={Profile} />
                )}
              </Route>
              <Route exact path="/apps">
                {isExperienceDashboard() ? (
                  <Redirect to={`/apps/${DEMO_ECOMMERCE_APP_ID}/dashboard`} />
                ) : (
                  <Route path="/apps" exact component={Apps}></Route>
                )}
              </Route>
              <Route
                path="/apps/:appId/integrate"
                exact
                component={IntegratePage}
              />
              <Route
                path="/apps/:appId/test-connection"
                exact
                component={TestConnectionsPage}
              />
              <Route path="/apps/:appId" component={Dashboard}></Route>
            </Switch>
          </>
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
};

export default AuthorizedRoute;
