import { Box, Button, TextField } from "@material-ui/core";
import React, { useState } from "react";
import SaveIcon from "@material-ui/icons/SaveOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import { capitalizeEachWord } from "../../../../../../utils";
import usePushConfigKeys from "./use-push-config-keys";
import { APP_PLATFORMS } from "../../../../../../constants";
import BootstrapTooltip from "../../../../../ReusableComponents/BootstrapTooltip";

export default function AndroidKeysForm({ app_id, closeDialog = null }) {
  const {
    save_keys,
    reset_keys,
    fcmKey,
    setFcmKey,
    is_save_disabled,
    android_btn_type,
  } = usePushConfigKeys({
    app_id,
    platform: APP_PLATFORMS.android,
  });

  const [is_btn_hovered, set_is_btn_hovered] = useState(false);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "start",
      }}
    >
      <TextField
        label="FCM Key"
        style={{ margin: 8 }}
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        value={fcmKey}
        onChange={e => setFcmKey(e.target.value)}
      />
      <Box
        style={{
          marginTop: 10,
          marginLeft: 8,
        }}
      >
        <BootstrapTooltip
          open={
            is_save_disabled && is_btn_hovered && android_btn_type === "save"
          }
          arrow
          placement="top"
          title="FCM Key is Required"
        >
          <Box
            onMouseOver={() => set_is_btn_hovered(true)}
            onMouseLeave={() => set_is_btn_hovered(false)}
          >
            <Button
              disabled={is_save_disabled}
              variant={android_btn_type === "save" ? "contained" : "text"}
              color="primary"
              startIcon={
                android_btn_type === "save" ? <SaveIcon /> : <DeleteIcon />
              }
              onClick={async () => {
                if (android_btn_type === "save" && fcmKey) {
                  await save_keys();
                  if (closeDialog) {
                    closeDialog();
                  }
                } else if (android_btn_type === "reset" && fcmKey) {
                  await reset_keys();
                }
              }}
            >
              {capitalizeEachWord(`${android_btn_type} key`)}
            </Button>
          </Box>
        </BootstrapTooltip>
      </Box>
    </Box>
  );
}
