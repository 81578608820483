import React, { useState } from "react";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import ActionsTextField from "../../../../../../../../ReusableComponents/ActionsTextField";
import RowDiv from "../../../../../../../../ReusableComponents/RowDiv";
import ColumnDiv from "../../../../../../../../ReusableComponents/ColumnDiv";
import VariantDistributions from "./components/VariantDistributions";
import { useTracked } from "../../../../store";
import {
  SET_AUTO_PUBLISH_WINNER,
  SET_TARGET_PERCENTAGE,
  SET_VARIANT_PERCENTAGE,
} from "../../../../constants";
import { useCommonConfigStyles } from "../../../../utils";

export default function ABSettingsSection() {
  const classes = useCommonConfigStyles();

  const [state, dispatch] = useTracked();
  const {
    config: {
      experiment: {
        variants = {},
        settings: { isManualExperiment, targetGroup },
      } = {
        variants: {},
        settings: { isManualExperiment: false, targetGroup: 10 },
      },
    },
  } = state;

  const [targetPercentage, setTargetPercentage] = useState(targetGroup);
  const [autoPublishWinner, setAutoPublishWinner] = useState(
    !isManualExperiment
  );

  return (
    <ColumnDiv center styles={{ gap: 5 }}>
      <RowDiv center>
        <Typography className={classes.typographyCaption}>
          To what % of target group do you to send this test?
        </Typography>
        <ActionsTextField
          value={targetPercentage}
          style={{
            maxWidth: 72,
            marginBottom: 0,
            margin: "0 6px",
          }}
          InputProps={{ min: 1 }}
          onChange={(e) => {
            const value = Number(e.target.value);
            setTargetPercentage(value);
            dispatch({
              type: SET_TARGET_PERCENTAGE,
              value: Number(value),
            });
          }}
          type="number"
        />
      </RowDiv>
      <ColumnDiv center>
        <VariantDistributions
          variants={variants}
          onPercentageChange={(variant, value) => {
            dispatch({
              type: SET_VARIANT_PERCENTAGE,
              variantName: variant,
              value,
            });
          }}
        />
      </ColumnDiv>
      <FormControlLabel
        label={
          <Typography className={classes.typographyCaption}>
            Automatically pick and publish the winner variant
          </Typography>
        }
        control={
          <Checkbox
            color="primary"
            checked={autoPublishWinner}
            onChange={(e) => {
              const { checked } = e.target;
              setAutoPublishWinner(checked);
              dispatch({
                type: SET_AUTO_PUBLISH_WINNER,
                value: !checked,
              });
            }}
            disableRipple
          />
        }
      />
      <Typography
        style={{
          fontSize: 13,
          fontWeight: 600,
          color: "#8497A5",
          marginLeft: 32,
          marginTop: -15,
        }}
      >
        {"Probability to be best: 95% , Expected loss: < 0.15%"}
      </Typography>
    </ColumnDiv>
  );
}
