import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../../contexts/AuthContext";
import { useDateFilters } from "../../../common/utils";
import { NOTIFICATIONS_HOLDER_TABS, PUSH_STATUS } from "../constants";
import { getNotificationsAPI } from "../actions";
import { Button, Grid, Tab, Tabs, withStyles } from "@material-ui/core";
import Loading from "../../../../ReusableComponents/Loading";
import Placeholder from "../../../../ReusableComponents/Placeholder";
import NotificationIcon from "@material-ui/icons/Notifications";
import Typography from "@material-ui/core/Typography";
import NotificationItem from "./NotificationItem";

const styles = (theme) => ({
  content: {
    padding: "25px 10%",
    height: "auto",
  },
  tabsRoot: {
    background: "#fff",
    flex: "1 0 auto",
  },
  tabsIndicator: {
    height: 3,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
  },
  tabRoot: {
    textTransform: "initial",
    minWidth: 72,
    fontSize: 16,
    marginRight: theme.spacing(4),
    "&:focus": {
      // color: '#40a9ff',
    },
  },
  typography: {
    fontWeight: 600,
  },
});

const getSelectedTabName = (selectedTab) => {
  return NOTIFICATIONS_HOLDER_TABS[
    Object.keys(NOTIFICATIONS_HOLDER_TABS)[selectedTab]
  ];
};

const checkNotificationStatus = (
  notification,
  selectedTab,
  notificationStatusMap
) => {
  if (getSelectedTabName(selectedTab) === NOTIFICATIONS_HOLDER_TABS.ALL)
    return true;
  if (getSelectedTabName(selectedTab) === NOTIFICATIONS_HOLDER_TABS.DRAFTS)
    return notificationStatusMap[notification._id] === PUSH_STATUS.Draft;

  if (getSelectedTabName(selectedTab) === NOTIFICATIONS_HOLDER_TABS.EXPIRED)
    return notificationStatusMap[notification._id] === PUSH_STATUS.Expired;
  if (getSelectedTabName(selectedTab) === NOTIFICATIONS_HOLDER_TABS.SCHEDULED)
    return notificationStatusMap[notification._id] === PUSH_STATUS.Scheduled;
  if (getSelectedTabName(selectedTab) === NOTIFICATIONS_HOLDER_TABS.PAUSED)
    return notificationStatusMap[notification._id] === PUSH_STATUS.Paused;
  if (getSelectedTabName(selectedTab) === NOTIFICATIONS_HOLDER_TABS.ACTIVE)
    return notificationStatusMap[notification._id] === PUSH_STATUS.InProgress;
};

const getTypoGraphy = (key, additionalStyles) => {
  return (
    <Typography
      color="textSecondary"
      variant="h6"
      gutterBottom
      style={additionalStyles}
    >
      {key}
    </Typography>
  );
};

function NotificationList({ app, isPublishDisabled }) {
  const auth = useAuth();
  const [notifications, setNotifications] = useState([]);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [dateFilters] = useDateFilters();
  const [notificationListPending, setNotificationListPending] = useState(false);
  const [notificationStatusMap, setNotificationStatusMap] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);
  const [cursor, setCursor] = useState(20);
  const [fetching, setFetching] = useState(0);

  useEffect(() => {
    setNotificationListPending(true);
    getNotificationsAPI(auth, app.app_id, dateFilters)
      .then(response => {
        setNotifications(response);
        let notificationMap = response.map(eachMessage => {
          return { [eachMessage._id]: eachMessage.status };
        });
        setNotificationStatusMap(Object.assign({}, ...notificationMap));
        setNotificationListPending(false);
      })
      .catch(() => setNotificationListPending(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetching]);

  useEffect(() => {
    const filteredItems = notifications.filter(notification =>
      checkNotificationStatus(notification, selectedTab, notificationStatusMap)
    );
    setFilteredNotifications(filteredItems);
  }, [selectedTab, notifications, notificationStatusMap]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Tabs
            classes={{
              root: styles.tabsRoot,
              indicator: styles.tabsIndicator,
            }}
            value={selectedTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, value) => {
              setSelectedTab(value);
            }}
          >
            {Object.keys(NOTIFICATIONS_HOLDER_TABS).map(eachSection => (
              <Tab
                disableRipple
                key={eachSection}
                classes={{ root: styles.tabRoot }}
                label={NOTIFICATIONS_HOLDER_TABS[eachSection]}
              />
            ))}
          </Tabs>
        </Grid>
        {!notificationListPending && filteredNotifications.length === 0 && (
          <Placeholder
            withIcon
            icon={
              <NotificationIcon
                style={{ opacity: 0.2, width: 150, height: 150 }}
              />
            }
            text="To create your first notification, click on Create New Notification button."
          />
        )}
      </Grid>
      {notificationListPending && <Loading size={100} />}
      {!notificationListPending && filteredNotifications.length !== 0 && (
        <Grid
          key="Header"
          container
          justify={"flex-start"}
          alignItems={"center"}
          style={{
            padding: "32px 18px 8px 16px",
            top: 0,
          }}
        >
          <Grid item xs={3}>
            {getTypoGraphy("Push Name", { fontWeight: 800, marginLeft: 5 })}
          </Grid>
          <Grid item xs={3}>
            {getTypoGraphy("Notification Type", { fontWeight: 800 })}
          </Grid>
          <Grid item xs={3}>
            {getTypoGraphy("Sent", { fontWeight: 800 })}
          </Grid>
          <Grid item xs={2}>
            {getTypoGraphy("Status", { fontWeight: 800 })}
          </Grid>
          <Grid item xs={1}>
            {getTypoGraphy("Actions", { fontWeight: 800 })}
          </Grid>
          {filteredNotifications.map((notification, index) => {
            if (index >= cursor) {
              return <React.Fragment key={index}></React.Fragment>;
            }
            return (
              <NotificationItem
                key={index}
                notification={notification}
                setFetching={setFetching}
                isPublishDisabled={isPublishDisabled}
              />
            );
          })}
          {filteredNotifications.length > 20 && (
            <Button
              disabled={cursor >= filteredNotifications.length}
              size="small"
              style={{ margin: "10px auto", display: "block" }}
              color="primary"
              onClick={() => {
                setCursor(cursor + 20);
              }}
            >
              {cursor >= filteredNotifications.length
                ? "You are all caught up!!"
                : "Load more..."}
            </Button>
          )}
        </Grid>
      )}
    </>
  );
}

export default withStyles(styles)(NotificationList);
